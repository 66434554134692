import React, { useState, useEffect } from "react";
import {
    Button, Col, Row, ConfigProvider,
    Form, DatePicker, List,
    AutoComplete, Input, Spin
} from 'antd';

//STATE
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setAssignmentDetailTabStatus, setAssignmentDetailOpenDraw } from '../../redux/actions/person-detail-tab-action'; // edit-draw -- save-draw
import { getParentOrganizations, getDepartments } from '../../redux/actions/organization.actions'
import { createJobRecord, getJobHistory, updateJobRecord, updateJobRecordResponseReset } from '../../redux/actions/person.actions'
import { getDataset } from '../../redux/actions/dataset-action';
import { getJobList } from '../../redux/actions/job.actions';


import { UilArrowLeft } from '@iconscout/react-unicons';
import { CloseOutlined } from '@ant-design/icons';

import moment from 'moment';

import { compareObjects, createInitialList, focusDateInput, blurDateInput } from '../../utils/commonFormFunctions'
import { dateFormat, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../utils/config';

import DynamicSelectbox from "../dynamicSelectbox/index";
import DynamicToastMessage from '../dynamicToastMessage/index';
import Can from '../../authorization/index';

function AssignmentDetails(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { getDepartments, getDataset, updateJobRecord, getJobHistory, updateJobRecordResponseReset, getJobList, jobList,
        person, remoteWorkTypes, departments, workTypes, workStates, workStatuses, jobRecord, isJobRecordLoading,
        updateJobRecordResponse, disableStatus } = props;
    const [jobRecordState, setJobRecordState] = useState({ organization: {} });
    const [workTypeList, setWorkTypeList] = useState([]);
    const [workStateList, setWorkStateList] = useState([]);
    const [workStatusList, setWorkStatusList] = useState([]);
    const [departmentList, setDepartmentList] = useState(createInitialList(jobRecord.organization));
    const [remoteWorkList, setRemoteWorkList] = useState(createInitialList(jobRecord.remoteWorkType));
    const [jobListState, setJobListState] = useState([{ 'value': jobRecord.jobTitle.displayValue }]);
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "startDate": dateFormatList,
        "contractEndDate": dateFormatList,
        "remoteWorkFinishDate": dateFormatList
    })
    useEffect(() => {
        setWorkTypeList(workTypes);
    }, [workTypes]);
    useEffect(() => {
        const tempArr = [];
        jobList.map((data) => {
            return tempArr.push({ 'value': data.name })
        })
        setJobListState(tempArr)
    }, [jobList])
    useEffect(() => {
        setWorkStateList(workStates);
    }, [workStates]);
    useEffect(() => {
        setWorkStatusList(workStatuses);
    }, [workStatuses]);
    useEffect(() => {
        setRemoteWorkList(remoteWorkTypes);
    }, [remoteWorkTypes]);
    useEffect(() => {
        setDepartmentList(departments);
    }, [departments]);
    useEffect(() => {
        setJobRecordState(jobRecord)
        let setobj = {
            "organization": jobRecord.organization,
            "department": jobRecord.department,
            "jobTitle": jobRecord.jobTitle?.displayValue,
            "workStatus": jobRecord.workStatus,
            "workType": jobRecord.workType,
            "workState": jobRecord.workState,
            "outsourcedOrganizationName": jobRecord.outsourcedOrganizationName,
            "remoteWorkType": jobRecord.remoteWorkType,
            "remoteWorkFinishDate": jobRecord.remoteWorkFinishDate,
            "contractEndDate": jobRecord.contractEndDate
        }
        if (jobRecord.startDate) {
            setobj["startDate"] = moment(jobRecord.startDate)
        }
        // if (jobRecord.appointmentDate) {
        //     setobj["appointmentDate"] = moment(jobRecord.appointmentDate)
        // }
        if (jobRecord.remoteWorkFinishDate) {
            setobj["remoteWorkFinishDate"] = moment(jobRecord.remoteWorkFinishDate)
        }
        if (jobRecord.contractEndDate) {
            setobj["contractEndDate"] = moment(jobRecord.contractEndDate)
        }
        form.setFieldsValue(setobj)
    }, [form, jobRecord])

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if ('OrganizationResourceId' === Object.keys(changedObj)[0]) {
            setDepartmentList([])
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields };
            fieldsObj["DepartmentResourceId"] = '';
            form.setFieldsValue(fieldsObj)
            setJobRecordState({ ...jobRecordState, "OrganizationResourceId": changedObj["OrganizationResourceId"], "DepartmentResourceId": "" })
        } else if ('jobTitle' === Object.keys(changedObj)[0]) {
            const jobTitleObj = { ...jobRecordState.jobTitle, "displayValue": changedObj['jobTitle'] }
            setJobRecordState({ ...jobRecordState, jobTitle: jobTitleObj })
        } else if (key === 'remoteWorkFinishDate') {
            setJobRecordState({ ...jobRecordState, 'remoteWorkFinishDate': moment(changedObj.remoteWorkFinishDate).format(dateFormat) });
        }
        // else if (key === 'appointmentDate') {
        //     setJobRecordState({ ...jobRecordState, 'appointmentDate': moment(changedObj.appointmentDate).format(dateFormat) });
        // } 
        else if (key === 'startDate') {
            setJobRecordState({ ...jobRecordState, 'startDate': moment(changedObj.startDate).format(dateFormat) });
        } else if (key === 'contractEndDate') {
            setJobRecordState({ ...jobRecordState, 'contractEndDate': moment(changedObj.contractEndDate).format(dateFormat) });
        } else if (key === 'workStatus') {
            if (changedObj.workStatus?.id === '1') {
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["contractEndDate"] = "";
                projects["outsourcedOrganizationName"] = '';
                form.setFieldsValue(projects)
                setJobRecordState({ ...jobRecordState, "workStatus": changedObj.workStatus, "contractEndDate": null, "outsourcedOrganizationName": null });
            }
            else if (changedObj.workStatus?.id === '2') {
                if (jobRecord.contractEndDate) {
                    const fields = form.getFieldsValue()
                    const projects = { ...fields };
                    projects["contractEndDate"] = moment(jobRecord.contractEndDate);
                    projects["outsourcedOrganizationName"] = '';
                    form.setFieldsValue(projects)
                    setJobRecordState({ ...jobRecordState, "workStatus": changedObj.workStatus, "contractEndDate": moment(jobRecord.contractEndDate).format(dateFormat), "outsourcedOrganizationName": null });

                } else {
                    const fields = form.getFieldsValue()
                    const projects = { ...fields };
                    projects["outsourcedOrganizationName"] = '';
                    form.setFieldsValue(projects)
                    setJobRecordState({ ...jobRecordState, "workStatus": changedObj.workStatus, "contractEndDate": null, "outsourcedOrganizationName": null });
                }

            } else if (changedObj.workStatus?.id === '3') {
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["contractEndDate"] = '';
                fieldsObj["outsourcedOrganizationName"] = '';
                form.setFieldsValue(fieldsObj)
                setJobRecordState({ ...jobRecordState, "workStatus": changedObj.workStatus, "contractEndDate": null, "outsourcedOrganizationName": jobRecord.outsourcedOrganizationName, });
            } else {
                setJobRecordState({ ...jobRecordState, 'workStatus': changedObj.workStatus });
            }
        } else {
            setJobRecordState({ ...jobRecordState, ...changedObj });
        }
    }
    const closeAssignment = () => {
        props.setAssignmentDetailOpenDraw(false);
        form.setFieldsValue({
            "organization": "",
            "department": "",
            "jobTitle": "",
            "workStatus": "",
            "workType": "",
            "workState": "",
            "outsourcedOrganizationName": "",
            "remoteWorkType": "",
            "remoteWorkFinishDate": "",
            "contractEndDate": "",
            "startDate": "",
            "remoteWorkFinishDate": "",
            "contractEndDate": ""
        })
        setJobRecordState([{ 'value': jobRecord.jobTitle.displayValue }])
    }
    const handleUpdateJobRecord = () => {
        const updateObj = {
            "OrganizationResourceId": jobRecordState.organization.id || "",
            "DepartmentResourceId": jobRecordState.department.id || "",
            "JobName": jobRecordState.jobTitle.displayValue || "",
            "StartDate": jobRecordState.startDate && jobRecordState.startDate !== 'Invalid date' ? moment(jobRecordState.startDate).format(dateFormat) : null,
            //": jobRecordState.appointmentDate && jobRecordState.appointmentDate !== 'Invalid date' ? moment(jobRecordState.appointmentDate).format(dateFormat) : null,
            "WorkStatusId": jobRecordState.workStatus?.id || null,
            "WorkTypeId": jobRecordState.workType?.id || null,
            "WorkStateId": jobRecordState.workState?.id || null,
            "ContractEndDate": jobRecordState.workStatus?.id === "2" && jobRecordState.contractEndDate && jobRecordState.contractEndDate !== 'Invalid date' ? moment(jobRecordState.contractEndDate).format(dateFormat) : null,
            "OutsourcedOrganizationName": jobRecordState.outsourcedOrganizationName || "",
            "RemoteWorkType": jobRecordState.remoteWorkType?.id || null,
            "RemoteWorkFinishDate": jobRecordState.remoteWorkType?.id === "1" && jobRecordState.remoteWorkFinishDate && jobRecordState.remoteWorkFinishDate !== 'Invalid date' ? moment(jobRecordState.remoteWorkFinishDate).format(dateFormat) : null,
        }
        let hasError = false
        let fields = []
        if (!jobRecordState.organization || jobRecordState.organization.id === '') {
            hasError = true
            fields.push('( ' + t('ORGANIZATIONS2') + ' )' + ' seçiniz')
        }
        if (!jobRecordState.department || jobRecordState.department.id === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_DEPARTMENT') + ' )' + ' seçiniz')
        }
        if (!jobRecordState.jobTitle || jobRecordState.jobTitle.displayValue === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_JOB2') + ' )' + ' seçiniz')
        }
        // if (!jobRecordState.startDate || jobRecordState.startDate === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_START_NEW_JOB_DATE2') + ' )' + ' seçiniz')
        // }
        // if (!jobRecordState.appointmentDate || jobRecordState.appointmentDate === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_APPOINTMENT_DATE') + ' )' + ' seçiniz')
        // }
        // if (!jobRecordState.salary || jobRecordState.salary === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_START_SALARY') + ' )' + ' boş bırakılamaz')
        // }
        // if (!jobRecordState.currency || jobRecordState.currency === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_CURRENCY') + ' )' + ' seçiniz')
        // }
        if (!hasError) {
            updateJobRecord({ "data": updateObj, "personResourceId": person.resourceId, "jobRecordResourceId": jobRecordState.resourceId })
        } else {
            DynamicToastMessage({
                'key': 'assignment_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    useEffect(() => {
        if (updateJobRecordResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'assignment_detail_success',
                'title': t('SUCCESS'),
                'description': updateJobRecordResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getJobHistory(person.resourceId);
            closeAssignment();
            updateJobRecordResponseReset();
        } else if (updateJobRecordResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'assignment_detail_fail',
                'title': t('ERROR'),
                'description': updateJobRecordResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            updateJobRecordResponseReset();
        }
    }, [updateJobRecordResponse]);

    return (
        <div className="table-details detail-scrollable h-100vh">
            <Col span={24}>
                <Row>
                    <Col span={18}>
                        <div className="back-drawer">
                            <Button className="transparent-btn" onClick={closeAssignment} icon={<UilArrowLeft />}>{t('PARTNER_RETURN_ASSIGNMENTS')}</Button>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="close-icon">
                            <CloseOutlined onClick={closeAssignment} />
                        </div>
                    </Col>
                </Row>
                {isJobRecordLoading ? (
                    <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                ) : (
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={handleValuesChange}
                            requiredMark={false}
                            className="editDraw"
                        >
                            <Col span={24} >
                                <div className="pad-25" />
                                <Row gutter={[32, 0]}>
                                    <Col span={12}>
                                        <div className="form-left-body">
                                            <Form.Item className="custom-select" name="department" label={t('PARTNER_DEPARTMENT') + ' *'} required >
                                                <DynamicSelectbox
                                                    optionList={departmentList}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    className={!compareObjects(jobRecord.department, jobRecordState.department) ? 'is-changed' : ''}
                                                    disabled={!jobRecordState?.organization?.id || disableStatus}
                                                    onFocus={() => getDepartments(jobRecordState?.organization?.id !== '' ? jobRecordState?.organization?.id : null)}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            <Form.Item name="jobTitle" className="auto-complete-input" label={t('PARTNER_JOB2') + ' *'} required>
                                                <AutoComplete
                                                    disabled={disableStatus}
                                                    className={!compareObjects(jobRecord.jobTitle, jobRecordState.jobTitle) ? 'is-changed' : ''}
                                                    options={jobListState}
                                                    onFocus={() => getJobList({
                                                        "searchTerm": "",
                                                        "pagination": {
                                                            "pageNumber": 1,
                                                            "pageSize": 100,
                                                            "orderBy": "Name",
                                                            "ascending": true
                                                        }
                                                    })}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item name="startDate" label={datePickerFormatType.startDate === dynamicDateInputFormatter ? t('PARTNER_APPOINTMENT_DATE') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_APPOINTMENT_DATE')}>
                                                <DatePicker
                                                    disabled={disableStatus}
                                                    className={!compareObjects(jobRecord.startDate, jobRecordState.startDate) ? 'is-changed' : ''}
                                                    locale={localeLang}
                                                    format={datePickerFormatType.startDate}
                                                    placeholder={t('PLACEHOLDER_DATE')}
                                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'startDate', dynamicDateInputFormatter)}
                                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'startDate', dateFormatList)}
                                                />
                                            </Form.Item>

                                            <Form.Item name="workType" className="custom-select" label={t('PARTNER_WORK_TYPE')}>
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    optionList={workTypeList}
                                                    className={!compareObjects(jobRecord.workType, jobRecordState.workType) ? 'is-changed' : ''}
                                                    onFocus={() => getDataset(17)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            <Form.Item name="workState" className="custom-select" label={t('PARTNER_WORK_STATE')}>
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    optionList={workStateList}
                                                    className={!compareObjects(jobRecord.workState, jobRecordState.workState) ? 'is-changed' : ''}
                                                    onFocus={() => getDataset(18)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="form-right-drawer-body">
                                            <Form.Item name="workStatus" className="custom-select" label={t('PARTNER_WORK_STATUS')}>
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    optionList={workStatusList}
                                                    className={!compareObjects(jobRecord.workStatus, jobRecordState.workStatus) ? 'is-changed' : ''}
                                                    onFocus={() => getDataset(16)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            {jobRecordState.workStatus?.id === '2' ?
                                                <Form.Item name="contractEndDate" label={datePickerFormatType.contractEndDate === dynamicDateInputFormatter ? t('CONTRACT_END_DATE') + " " + t('PLACEHOLDER_DATE') : t('CONTRACT_END_DATE')}>
                                                    <DatePicker
                                                        disabled={disableStatus}
                                                        className={!compareObjects(jobRecord.contractEndDate, jobRecordState.contractEndDate) ? 'is-changed' : ''}
                                                        locale={localeLang}
                                                        format={datePickerFormatType.contractEndDate}
                                                        placeholder={t('PLACEHOLDER_DATE')}
                                                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'contractEndDate', dynamicDateInputFormatter)}
                                                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'contractEndDate', dateFormatList)}
                                                    />
                                                </Form.Item>
                                                : ''}

                                            {jobRecordState.workStatus?.id === '3' ?
                                                <Form.Item name="outsourcedOrganizationName" label={t('PARTNER_OUTSOURCED')}>
                                                    <Input
                                                        disabled={disableStatus}
                                                        className={jobRecord.outsourcedOrganizationName !== jobRecordState.outsourcedOrganizationName ? 'is-changed' : ''}
                                                    />
                                                </Form.Item>
                                                : ''}
                                            <Form.Item name="remoteWorkType" className="custom-select" label={t('PARTNER_REMOTE_WORK_TYPE')}>
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    optionList={remoteWorkList}
                                                    onFocus={() => getDataset(10)}
                                                    className={!compareObjects(jobRecord.remoteWorkType, jobRecordState.remoteWorkType) ? 'is-changed' : ''}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            {jobRecordState.remoteWorkType?.id === '1' ?
                                                <Form.Item name="remoteWorkFinishDate" label={datePickerFormatType.remoteWorkFinishDate === dynamicDateInputFormatter ? t('PARTNER_REMOTE_WORK_END') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_REMOTE_WORK_END')}>
                                                    <DatePicker
                                                        disabled={disableStatus}
                                                        className={!compareObjects(jobRecord.remoteWorkFinishDate, jobRecordState.remoteWorkFinishDate) ? 'is-changed' : ''}
                                                        locale={localeLang}
                                                        format={datePickerFormatType.remoteWorkFinishDate}
                                                        placeholder={t('PLACEHOLDER_DATE')}
                                                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'remoteWorkFinishDate', dynamicDateInputFormatter)}
                                                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'remoteWorkFinishDate', dateFormatList)}
                                                    />
                                                </Form.Item>
                                                : ''}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            {disableStatus !== true &&
                                <div className="fixed-btn">
                                    <div className="page-end">
                                        <div className="hr-end"></div>
                                    </div>
                                    <div className="form-submit">
                                        <Button onClick={closeAssignment} className="white-btn w-180 h-40 f-14">{t('INVENTORY_CANCEL')}</Button>
                                        <div className="btn-padding" />
                                        <Can I="person.assignment.update">
                                            <Button onClick={handleUpdateJobRecord} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                                        </Can>
                                        <div className="btn-padding-end" />
                                    </div>
                                </div>
                            }
                        </Form >
                    </ConfigProvider>
                )}
            </Col>
        </div >
    )
}
const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        jobRecord: state.person.jobRecord,
        workTypes: state.dataset.workTypes,
        workStates: state.dataset.workStates,
        workStatuses: state.dataset.workStatuses,
        parentOrganizations: state.organization.parentOrganizations,
        departments: state.organization.departments,
        createJobRecordResponse: state.person.createJobRecordResponse,
        isJobRecordLoading: state.person.isJobRecordLoading,
        updateJobRecordResponse: state.person.updateJobRecordResponse,
        remoteWorkTypes: state.dataset.remoteWorkTypes,
        jobList: state.job.jobList.results
    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setAssignmentDetailTabStatusEdit: (props) => { dispatch(setAssignmentDetailTabStatus(props)); },
        setAssignmentDetailOpenDraw: (props) => { dispatch(setAssignmentDetailOpenDraw(props)); },
        getJobHistory: (personId) => { dispatch(getJobHistory(personId)) },
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getParentOrganizations: (resourceId) => { dispatch(getParentOrganizations(resourceId)) },
        getDepartments: (props) => { dispatch(getDepartments(props)) },
        createJobRecord: (props) => { dispatch(createJobRecord(props)) },
        updateJobRecord: (props) => { dispatch(updateJobRecord(props)) },
        updateJobRecordResponseReset: () => { dispatch(updateJobRecordResponseReset()) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetails);