import React, { useEffect } from 'react';
import { Layout, Row, Image, Col, Card, Button, Spin } from 'antd';
import '../../styles/login-page-v2.scss';
import '../../styles/utility.scss';
import 'react-phone-input-2/lib/style.css';
import { connect } from "react-redux";
import { signinRedirect } from '../../services/userService'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { authUrl } from '../../utils/config'; 
// import LangSelector from '../../component/langSelector/index';

function Login(props) {
    const { t } = useTranslation();
    const { auth } = props;
    const handleSignIn = () => {
        signinRedirect();
    }
    const googleSignIn = () => {
        window.location.replace(authUrl +'/account/externalLogin?provider=1&returnUrl=externalLoginCallback');
    }

    useEffect(() => {
        // if (window.location.search != '') {
        //     const success = window.location.search.split('&')[0].split('=')[1]
        //     const organization = window.location.search.split('&')[1].split('=')[1]
        //     if (success == 'true' && organization == 'true') {
        //         signinRedirect();
        //     }
        // }
        signinRedirect();
    }, [])

    return (!auth?.user?.access_token) ? (
        <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"100vh"}}>
            <Spin/>
        </div>
        // <Layout className="auth-container">
        //     <Row>
        //         <Col xs={24} sm={24} md={12} lg={12} xl={10} xxl={8} style={{ order: 2 }}>
        //             <div className="right-container">
        //                 <Card className="form-container">
        //                     <div className="auth-logo">
        //                         <Image className="img" src="./images/logo.svg" preview={false} />
        //                     </div>
        //                     <div className="card-title">
        //                         <h2>HRplan’a Hoşgeldiniz 👋🏻</h2>
        //                     </div>

        //                     <div className="my-3">
        //                         <Button onClick={() => handleSignIn()} className="btn-aqua w-100" >
        //                             {t('LOGIN_TITLE')}
        //                         </Button>
        //                     </div>

        //                     <div className="my-3">
        //                         <Button onClick={() => googleSignIn()} className="btn btn-light google-btn" >
        //                             <img
        //                                 src="https://hrplan.blob.core.windows.net/icon/google.png"
        //                                 alt="goggle"
        //                                 width="25"
        //                                 height="25"
        //                                 className='img'
        //                             />
        //                             <span className="google-span">
        //                                 Google
        //                                 <span style={{ color: 'rgb(120, 120, 120)', fontSize: '12px', fontWeight: 400 }}>
        //                                 {' '}ile Giriş Yap
        //                                 </span>
        //                             </span>
        //                         </Button>
        //                     </div>

        //                     <div className="button-under-text-container">
        //                         <div className="button-under-text-max-width">
        //                             <p className='pr-2'>Hala bir hesabın yok mu? </p> <a href={`${process.env.REACT_APP_AUTH_URL}/account/RegisterOrganization?isMobile=false`}>Kayıt Ol!</a>
        //                         </div>
        //                     </div>
        //                 </Card>
        //             </div>
        //         </Col>
        //         <Col xs={0} sm={0} md={12} lg={12} xl={14} xxl={16} style={{ order: 1 }} className="p-0">
        //             <div className="left-container">
        //                 <div className="auth-logo">
        //                     <Image className="img" src="./images/logo.svg" preview={false} />
        //                 </div>
        //                 <div className="hr-app-container pt-4">
        //                     <Image className="img" src="./images/app.svg" preview={false} />
        //                     <div className="hr-app-description-container">
        //                         <div className="hr-app-description-max-width pt-1">
        //                             <h2>
        //                                 Mobil uygulama sayesinde tüm özelliklere anında erişme imkanı !
        //                             </h2>

        //                         </div>
        //                     </div>

        //                     <div className="d-flex justify-center pt-8 app-container">
        //                         <a href="https://play.google.com/store/apps/details?id=com.hrplan.android&gl=TR"
        //                             target="_blank">
        //                             <Image className="img" src="./images/google-play.svg" preview={false} />
        //                         </a>
        //                         <a href="https://apps.apple.com/ml/app/hr-plan-personel-y%C3%B6netimi/id1640356165"
        //                             target="_blank">
        //                             <Image className="img" src="./images/app-store.svg" preview={false} />
        //                         </a>
        //                     </div>
        //                 </div>
        //             </div>
        //         </Col>
        //     </Row>
        // </Layout>
    ) : (
        <Redirect to="/" />
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);