import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setOwnerShipDetailsDrawer, setOwnerShipReturnDrawer } from '../../../redux/actions/ownership-drawer.action';
import {
    ownerShipDetailsReset, updateOwnerShip, resetUpdateOwnerShip,
    ownerShipPageListSearch, getOwnerShipDetails
} from '../../../redux/actions/ownership.actions';
import { Col, Row, ConfigProvider, Input, Form, Button, Spin, Checkbox, DatePicker, List } from 'antd';
import { dateFormat, dateFormatConvert, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../../utils/config';
import { compareDate, focusDateInput, blurDateInput } from '../../../utils/commonFormFunctions';

import { CloseOutlined } from '@ant-design/icons';
import { UilClipboardAlt } from '@iconscout/react-unicons';

import Can from "../../../authorization/index";
import DynamicToastMessage from '../../../component/dynamicToastMessage/index';
import moment from 'moment';
import 'moment/locale/tr';

function OwnerShipDetailsDrawer(props) {
    const { t } = useTranslation();
    const { ownershipDetails, ownershipDetailsLoading, ownerShipDetailsReset, selectedOwnerShip,
        resetUpdateOwnerShip, setOwnerShipDetailsDrawer, setOwnerShipReturnDrawer, searchData,
        setReturnComponentKey, ownerShipUpdateResponse, ownerShipPageListSearch, updateOwnerShip,
        getOwnerShipDetails } = props;
    const [form] = Form.useForm();
    const [ownerShipDetailsState, setOwnerShipDetailsState] = useState();
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "assigmentDate": dateFormatList,
    });
    const [updateCountState, setUpdateCountState] = useState(0);

    const close = () => {
        form.resetFields();
        setOwnerShipDetailsState({});
        ownerShipDetailsReset();
        setOwnerShipDetailsDrawer(false);
        if (updateCountState > 0) {
            ownerShipPageListSearch(searchData);
        }
    }
    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === 'assigmentDate') {
            if (changedObj.assigmentDate) {
                setOwnerShipDetailsState({ ...ownerShipDetailsState, 'assigmentDate': moment(changedObj.assigmentDate).format(dateFormat) });
            } else {
                setOwnerShipDetailsState({ ...ownerShipDetailsState, 'assigmentDate': null });
            }
        } else {
            setOwnerShipDetailsState({ ...ownerShipDetailsState, ...changedObj });
        }
    }
    const openReturnDrawer = () => {
        setOwnerShipReturnDrawer(true)
        setReturnComponentKey((i) => i + 1)
    }

    const [isUpdateOwnerShipLoading, setisUpdateOwnerShipLoading] = useState(false);

    const handleUpdateOwnerShip = () => {

        if(isUpdateOwnerShipLoading) return;
        setisUpdateOwnerShipLoading(true);
        setTimeout(() => {setisUpdateOwnerShipLoading(false);}, 1055) // double click prevent;

        const updateObj = {
            "assigmentDate": ownerShipDetailsState.assigmentDate && ownerShipDetailsState.assigmentDate !== 'Invalid date' ? moment(ownerShipDetailsState.assigmentDate).format(dateFormat) : null,
            "assigmentDescription": ownerShipDetailsState.assigmentDescription || null
        };
        let hasError = false;
        let fields = [];
        if ((!updateObj.assigmentDate || updateObj.assigmentDate === '')) {
            hasError = true
            fields.push(t('DEBIT_DATE'));
        }
        if (!hasError) {
            updateOwnerShip(selectedOwnerShip, updateObj);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    useEffect(() => {
        const setObj = {
            "inventoryType": ownershipDetails.inventoryType?.displayValue,
            "inventory": ownershipDetails.inventory?.displayValue,
            "returnDate": ownershipDetails.returnDate ? moment(ownershipDetails.returnDate).format(dateFormatConvert) : '',
            "assigmentPerson": ownershipDetails.assigmentPerson?.displayValue,
            "assigmentDescription": ownershipDetails.assigmentDescription,
            "returnPerson": ownershipDetails.returnPerson?.displayValue,
            "returnDescription": ownershipDetails.returnDescription,
            "isMaintanenceNeeded": ownershipDetails.isMaintanenceNeeded
        };
        if (ownershipDetails.assigmentDate) {
            setObj["assigmentDate"] = moment(ownershipDetails.assigmentDate)
        }
        setOwnerShipDetailsState(ownershipDetails);
        if (ownershipDetails.inventoryType?.displayValue) {
            form.setFieldsValue(setObj);
        }
    }, [ownershipDetails])

    useEffect(() => {
        if (ownerShipUpdateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'ownership_update_success_notification',
                'title': t('SUCCESS'),
                'description': ownerShipUpdateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            setUpdateCountState(updateCountState + 1);
            getOwnerShipDetails(selectedOwnerShip);
            resetUpdateOwnerShip();
        } else if (ownerShipUpdateResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'ownership_update_fail_notification',
                'title': t('ERROR'),
                'description': ownerShipUpdateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetUpdateOwnerShip();
        }
    }, [ownerShipUpdateResponse])

    return ownershipDetailsLoading ?
        <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        :
        <div className="table-details organization-drawer d-block h-100" >
            <div className="close-icon" onClick={() => close()}>
                <CloseOutlined />
            </div>
            <Col span={24}>
                <Row>
                    <Col span={17}>
                        <Row>
                            <Col span={6}>
                                <div className="custom-icon-logo">
                                    <UilClipboardAlt />
                                </div>
                            </Col>
                            <Col span={18}>
                                <div className="user-details">
                                    <h1>{ownershipDetails.assigmentPerson?.displayValue + ' ' + t('INVENTORY_TYPE_EMBEZZLED') + ' ' + ownershipDetails.inventoryType?.displayValue}</h1>
                                    <div className="info-alt d-block">
                                        {ownershipDetails.inventory?.serialNumber && <h3>{t('OWNERSHIP_INVENTORY_SERIAL_NUMBER')} : {ownershipDetails.inventory?.serialNumber}</h3>}
                                        {ownershipDetails.assigmentDate && <h3>{t('PARTNER_ASSIGNMENT_DATE')} : {moment(ownershipDetails.assigmentDate).format(dateFormatConvert)}</h3>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col offset={2} span={5}>
                        <div className="changepage">
                            <Row gutter={[0, 15]}>

                                <div className="procces">
                                    {/* <Dropdown overlay={department_details(t)} trigger={['click']}>
                                    <Button className="primary-btn w-180 h-32"> <SettingOutlined />{t('PARTNER_PROCESS')} </Button>
                                </Dropdown> */}
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>
            <div className="hr-bottom mb-20"></div>
            <div className="w-100 ownership-scrollable h-100">
                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    <Form
                        form={form}
                        onValuesChange={handleValuesChange}
                        layout="vertical"
                        requiredMark={true}
                    >
                        <Row gutter={[32, 0]}>
                            <Col span={12}>
                                <div className="form-left-body">
                                    <Form.Item name="inventoryType" label={t('DEBIT_INVENTORY_TYPE')} required>
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <Form.Item name="inventory" label={t('PARTNER_DEVICE_NAME')} required>
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <Form.Item name="assigmentDate" label={t('DEBIT_DATE') + " *"} required>
                                        <DatePicker
                                            className={!compareDate(ownershipDetails.assigmentDate, ownerShipDetailsState.assigmentDate) ? 'is-changed' : ''}
                                            format={datePickerFormatType.assigmentDate}
                                            locale={localeLang}
                                            placeholder={t('PLACEHOLDER_DATE')}
                                            onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'assigmentDate', dynamicDateInputFormatter)}
                                            onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'assigmentDate', dateFormatList)}
                                        />
                                    </Form.Item>
                                    <Form.Item name="assigmentPerson" label={t('DEBIT_FROM_TO_PERSON')} required>
                                        <Input disabled={true} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-right-body">
                                    <Form.Item name="assigmentDescription" label={t('DEBIT_DESCRIPTION')} >
                                        <Input.TextArea />
                                    </Form.Item>
                                    {
                                        ownershipDetails?.returnDate !== null &&
                                        <React.Fragment>
                                            <Form.Item name="returnPerson" label={t('PARTNER_RETURN_USER')} required>
                                                <Input disabled={true} />
                                            </Form.Item>
                                            <Form.Item name="returnDate" label={t('DEBIT_THROUGH')} required>
                                                <Input disabled={true} />
                                            </Form.Item>
                                            <Form.Item name="returnDescription" label={t('DEBIT_RETURN_DESCRIPTION')} required>
                                                <Input.TextArea disabled={true} />
                                            </Form.Item>
                                            <Form.Item name="isMaintanenceNeeded" label="" valuePropName="checked">
                                                <Checkbox disabled={true} >{t('DEBIT_MAINTANENCE_NEEDED')}</Checkbox>
                                            </Form.Item>
                                        </React.Fragment>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ConfigProvider>
            </div>
            {/* <Can I="asset.return">
                {ownershipDetails?.returnDate === null ?
                    <div className="fixed-btn">
                        <div className="page-end">
                            <div className="hr-end"></div>
                        </div>

                        <div className="flex-end margin-r-24">

                            <Button onClick={() => openReturnDrawer()} className="white-btn w-180 h-40 f-14">{t('PARTNER_GET_A_REFUND')}</Button>
                        </div>
                    </div>
                    : ''
                }
            </Can> */}
            <Can I="asset.return">
                <div className="fixed-btn">
                    <div className="page-end">
                        <div className="hr-end"></div>
                    </div>
                    <div className="form-submit-space-between">
                        <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                        <div className="btn-padding flex-start" >
                            {ownershipDetails?.returnDate === null &&
                                <Button onClick={() => openReturnDrawer()} className="white-btn w-180 h-40 f-14">{t('PARTNER_GET_A_REFUND')}</Button>
                            }
                            <div className='margin-r-12' />
                            <Button onClick={handleUpdateOwnerShip} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </div>
                    </div>
                </div>
            </Can>
        </div>
}
const mapStateToProps = (state) => {
    return {
        ownershipDetails: state.ownership.ownershipDetails,
        ownershipDetailsLoading: state.ownership.ownershipDetailsLoading,
        ownerShipUpdateResponse: state.ownership.ownerShipUpdateResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setOwnerShipDetailsDrawer: (props) => { dispatch(setOwnerShipDetailsDrawer(props)) },
        setOwnerShipReturnDrawer: (props) => { dispatch(setOwnerShipReturnDrawer(props)) },
        updateOwnerShip: (resourceId, updateData) => { dispatch(updateOwnerShip(resourceId, updateData)) },
        ownerShipPageListSearch: (searchData) => { dispatch(ownerShipPageListSearch(searchData)) },
        getOwnerShipDetails: (props) => { dispatch(getOwnerShipDetails(props)) },
        ownerShipDetailsReset: () => { dispatch(ownerShipDetailsReset()) },
        resetUpdateOwnerShip: () => { dispatch(resetUpdateOwnerShip()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OwnerShipDetailsDrawer);