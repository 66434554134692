import { OrganizationActionType } from "../actions/organization.actions";

const initialState = {
    parentOrganizations: [],
    departments: [],
    departmentsLoading: true,
    persons: [],
    organizationCreateResponse: { "status": "loading", "message": "" },
    departmentCreateResponse: { "status": "loading", "message": "" },
    searchResult: {
        totalNumberOfRecords: 0,
        results: []
    },
    organizationExportData: {},
    isSearchLoading: false,
    personOrgChart: [],
    departmentOrgChart: [],
    organizationOrgChart: [],
    departmentSearchResult: {
        totalNumberOfRecords: 0,
        results: []
    },
    departmentIsSearchLoading: false,
    connectedPersons: {
        totalNumberOfRecords: 0,
        results: []
    },
    isConnectedPersonsLoading: false,
    isOrganizationFilterLoading: false,
    islegalNameFilterLoading: false,
    organizationFilterResult: {
        results: []
    },
    legalNameFilterResult: {
        results: []
    },
    organizationLogoResponse: { "status": "loading", "message": "" },
    organizationWorkHoursLoading: false,
    organizationWorkHours: {
        allWeekTimeOffRequestIncludeSaturday: null,
        fridayTimeOffRequestIncludeSaturday: null,
        message: null,
        organizationWorkHours: [
            {
                "resourceId": "",
                "organizationId": null,
                "workDay": null,
                "startTime": null,
                "endTime": null,
                "lunchBreakStartTime": null,
                "lunchBreakEndTime": null,
                "isActive": null
            },
        ],
        status: 0,
        validationMessages: []
    },
    organizationWorkHoursCreateDrawer:false,
    organizationWorkHoursDetailDrawer:false,
    updateOrganizationWorkHoursResponse: { "status": "loading", "message": "" },
    organizationDocumentTypeLoading: false,
    organizationDocumentTypeList: [],
    organizationDocumentTypeListWithUserId: [],
    organizationDocumentTypeWithUserIdLoading:false,
    workHourDetailsByDefinitions:{
        definitions: [],
        message: null,
        status: null,
        validationMessages: []
    },
    workHourDetailsByDefinitionsLoading:true,
    workHourDetailsByDefinitionId:{
        allWeekTimeOffRequestIncludeSaturday: false,
        fridayTimeOffRequestIncludeSaturday: false,
        message: null,
        organizationWorkHourDefinition: [],
        persons:[],
        status: null,
        validationMessages: []
    },
    workHourDetailsByDefinitionIdLoading:true,
    workHourDepartmentInPerson:[],
    workHourDepartmentInPersonLoading:true,
    deleteWorkHoursResponse: { "status": "loading", "message": "" },
    makeDefaultWorkHoursResponse: { "status": "loading", "message": "" },
    workHoursControlData:[],
    workHoursControlLoading:true,
    workHoursModal:"",
    personWorkHours:{ 'id':null, 'displayValue':''},
    personWorkHoursLoading:true,
    approveOrganizationRegisterMailResponse: { status: "loading", message: "" },
    approveOrganizationRegisterMailResponseLoading: true,
    contractItems: [],
    contractItemsLoading:true,
    personOrganizationList: [],
    personOrganizationListLoading: true,
    organizationUpdatePhoneRequestResponse: { status: "loading", message: ""}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
        case OrganizationActionType.GET_PERSON_ORG_CHART_SUCCESS:
            return { ...state, personOrgChart: action.payload }

        case OrganizationActionType.GET_DEPARTMENT_ORG_CHART_SUCCESS:
            return { ...state, departmentOrgChart: action.payload }

        case OrganizationActionType.GET_ORGANIZATION_ORG_CHART_SUCCESS:
            return { ...state, organizationOrgChart: action.payload }


        case OrganizationActionType.GET_PARENT_ORGANIZATION_SUCCESS:
            return {
                ...state,
                parentOrganizations: action.payload

            }
        case OrganizationActionType.GET_DEPARTMENT_REQUEST:
            return {
                ...state,
                departmentsLoading:true
            };
        case OrganizationActionType.GET_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: action.payload,
                departmentsLoading:false
            }
        case OrganizationActionType.GET_DEPARTMENT_FAIL:
            return {
                ...state,
                departments: action.payload,
                departmentsLoading:false
            }
        case OrganizationActionType.GET_PERSONS_SUCCESS:
            return {
                ...state,
                persons: action.payload

            }
        case OrganizationActionType.CREATE_ORGANIZATION_SUCCESSS:
            return {
                ...state,
                organizationCreateResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }

            }
        case OrganizationActionType.CREATE_ORGANIZATION_FAIL:
            return {
                ...state,
                organizationCreateResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }

            }
        case OrganizationActionType.CREATE_ORGANIZATION_INPUT_ERROR:
            return {
                ...state,
                organizationCreateResponse: {
                    "status": "VALIDATION_ERROR",
                    "message": action.payload
                }

            }
        case OrganizationActionType.CREATE_ORGANIZATION_RESET_STATUS:
            return {
                ...state,
                organizationCreateResponse: { "status": "loading", "message": "" }
            }
        case OrganizationActionType.CREATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departmentCreateResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }

            }
        case OrganizationActionType.CREATE_DEPARTMENT_FAIL:
            return {
                ...state,
                departmentCreateResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }

            }
        case OrganizationActionType.CREATE_DEPARTMENT_INPUT_ERROR:
            return {
                ...state,
                departmentCreateResponse: {
                    "status": "VALIDATION_ERROR",
                    "message": action.payload
                }

            }
        case OrganizationActionType.CREATE_DEPARTMENT_RESET_STATUS:
            return {
                ...state,
                departmentCreateResponse: { "status": "loading", "message": "" }
            }
        case OrganizationActionType.ORGANIZATION_EXPORT_SUCCESS:
            return {
                ...state,
                organizationExportData: action.payload

            }
        case OrganizationActionType.ORGANIZATION_EXPORT_FAIL:
            return {
                ...state,
                organizationExportData: action.payload

            }
        case OrganizationActionType.ORGANIZATION_SEARCH_REQUEST:
            return {
                ...state,
                isSearchLoading: true
            };
        case OrganizationActionType.ORGANIZATION_SEARCH_SUCCESS:
            return {
                ...state,
                searchResult: action.payload,
                isSearchLoading: false
            }
        case OrganizationActionType.ORGANIZATION_SEARCH_FAIL:
            return {
                ...state,
                isSearchLoading: false
            }
        case OrganizationActionType.DEPARTMENT_SEARCH_REQUEST:
            return {
                ...state,
                departmentIsSearchLoading: true
            };
        case OrganizationActionType.DEPARTMENT_SEARCH_SUCCESS:
            return {
                ...state,
                departmentSearchResult: action.payload,
                departmentIsSearchLoading: false
            }
        case OrganizationActionType.DEPARTMENT_SEARCH_FAIL:
            return {
                ...state,
                departmentIsSearchLoading: false
            }
        case OrganizationActionType.GET_CONNECTED_DEPARTMENT_REQUEST:
            return {
                ...state,
                isConnectedPersonsLoading: true
            };
        case OrganizationActionType.GET_CONNECTED_DEPARTMENT_SUCCESS:
            return {
                ...state,
                connectedPersons: action.payload,
                isConnectedPersonsLoading: false
            }
        case OrganizationActionType.GET_CONNECTED_DEPARTMENT_FAIL:
            return {
                ...state,
                isConnectedPersonsLoading: false
            }
        case OrganizationActionType.ORGANIZATION_FILTER_REQUEST:
            return {
                ...state,
                isOrganizationFilterLoading: true
            };
        case OrganizationActionType.ORGANIZATION_FILTER_SUCCESS:
            return {
                ...state,
                organizationFilterResult: action.payload,
                isOrganizationFilterLoading: false
            }
        case OrganizationActionType.ORGANIZATION_FILTER_FAIL:
            return {
                ...state,
                isOrganizationFilterLoading: false
            }
        case OrganizationActionType.LEGAL_NAME_FILTER_REQUEST:
            return {
                ...state,
                islegalNameFilterLoading: true
            };
        case OrganizationActionType.LEGAL_NAME_FILTER_SUCCESS:
            return {
                ...state,
                legalNameFilterResult: action.payload,
                islegalNameFilterLoading: false
            }
        case OrganizationActionType.LEGAL_NAME_FILTER_FAIL:
            return {
                ...state,
                islegalNameFilterLoading: false

            }
        case OrganizationActionType.DELETE_LOGO_SUCCESS:
            return {
                ...state,
                organizationLogoResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case OrganizationActionType.DELETE_LOGO_FAIL:
            return {
                ...state,
                organizationLogoResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case OrganizationActionType.DELETE_LOGO_RESET:
            return {
                ...state,
                organizationLogoResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_REQUEST:
            return {
                ...state,
                organizationWorkHoursLoading: true
            };
        case OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_SUCCESS:
            return {
                ...state,
                organizationWorkHours: action.payload,
                organizationWorkHoursLoading: false

            }
        case OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_FAIL:
            return {
                ...state,
                organizationWorkHoursLoading: false
            }
        case OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_SUCCESS:
            return {
                ...state,
                updateOrganizationWorkHoursResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_FAIL:
            return {
                ...state,
                updateOrganizationWorkHoursResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case OrganizationActionType.UPDATE_ORGANIZATION_WORK_INPUT_ERROR:
            return {
                ...state,
                updateOrganizationWorkHoursResponse: {
                    "status": "INPUT_ERROR",
                    "message": action.payload
                }
            }
        case OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_RESET:
            return {
                ...state,
                updateOrganizationWorkHoursResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_REQUEST:
            return {
                ...state,
                organizationDocumentTypeLoading: true
            };
        case OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_SUCCESS:
            return {
                ...state,
                organizationDocumentTypeList: action.payload,
                organizationDocumentTypeLoading: false
            }
        case OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_FAIL:
            return {
                ...state,
                organizationDocumentTypeLoading: false
            }
        case OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_REQUEST:
            return {
                ...state,
                organizationDocumentTypeWithUserIdLoading: true
            };
        case OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_SUCCESS:
            return {
                ...state,
                organizationDocumentTypeListWithUserId: action.payload,
                organizationDocumentTypeWithUserIdLoading: false
            }
        case OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_FAIL:
            return {
                ...state,
                organizationDocumentTypeWithUserIdLoading: false
            }
        case OrganizationActionType.SET_ORGANIZATION_WORKHOURS_CREATE_DRAWER:
           return {
              ...state,
              organizationWorkHoursCreateDrawer: action.payload,
            }
        case OrganizationActionType.SET_ORGANIZATION_WORKHOURS_DETAIL_DRAWER:
            return {
               ...state,
               organizationWorkHoursDetailDrawer: action.payload,
            }
        case OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_REQUEST:
        return {
            ...state,
            personWorkHoursLoading: true,
            workHourDetailsByDefinitions:{
                definitions: [],
                message: null,
                status: null,
                validationMessages: []
            },
            workHourDetailsByDefinitionsLoading: true,
        };
        case OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_SUCCESS:
            return {
                ...state,
                personWorkHoursLoading: false,
                workHourDetailsByDefinitions: action.payload,
                workHourDetailsByDefinitionsLoading: false
            }
        case OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_FAIL:
            return {
                ...state,
                personWorkHoursLoading: false,
                workHourDetailsByDefinitions:{
                    definitions: [],
                    message: null,
                    status: null,
                    validationMessages: []
                },
                workHourDetailsByDefinitionsLoading: false
            }
            case OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_REQUEST:
                return {
                    ...state,
                    workHourDetailsByDefinitionId:{
                        allWeekTimeOffRequestIncludeSaturday: false,
                        fridayTimeOffRequestIncludeSaturday: false,
                        message: null,
                        organizationWorkHourDefinition: [],
                        persons:[],
                        status: null,
                        validationMessages: []
                    },
                    workHourDetailsByDefinitionIdLoading: true,
                };
            case OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_SUCCESS:
                return {
                    ...state,
                    workHourDetailsByDefinitionId: action.payload,
                    workHourDetailsByDefinitionIdLoading: false
                }
            case OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_FAIL:
                return {
                    ...state,
                    workHourDetailsByDefinitionId:{
                        allWeekTimeOffRequestIncludeSaturday: false,
                        fridayTimeOffRequestIncludeSaturday: false,
                        message: null,
                        organizationWorkHourDefinition: [],
                        persons:[],
                        status: null,
                        validationMessages: []
                    },
                    workHourDetailsByDefinitionIdLoading: false
                }
            case OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_REQUEST:
                return {
                    ...state,
                    workHourDepartmentInPersonLoading: true
                };
            case OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_SUCCESS:
                return {
                    ...state,
                    workHourDepartmentInPerson: action.payload,
                    workHourDepartmentInPersonLoading: false
                }
            case OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_FAIL:
                return {
                    ...state,
                    workHourDepartmentInPersonLoading: false
                }
            case OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_SUCCESS:
                return {
                    ...state,
                    deleteWorkHoursResponse: {
                        "status": "SUCCESS",
                        "message": action.payload
                    }
                }
            case OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_FAIL:
                return {
                    ...state,
                    deleteWorkHoursResponse: {
                        "status": "FAIL",
                        "message": action.payload
                    }
                }
            case OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_RESET:
                return {
                    ...state,
                    deleteWorkHoursResponse: {
                        "status": "loading",
                        "message": null
                    }
                }
            case OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_SUCCESS:
                return {
                    ...state,
                    makeDefaultWorkHoursResponse: {
                        "status": "SUCCESS",
                        "message": action.payload
                    }
                }
            case OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL:
                return {
                    ...state,
                    makeDefaultWorkHoursResponse: {
                        "status": "FAIL",
                        "message": action.payload
                    }
                }
            case OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_RESET:
                return {
                    ...state,
                    makeDefaultWorkHoursResponse: {
                        "status": "loading",
                        "message": null
                    }
                }
            case OrganizationActionType.UPDATE_WORKHOURS_PERSON_CONTROL_REQUEST:
                return {
                    ...state,
                    workHoursControlLoading: true
                };
            case OrganizationActionType.UPDATE_WORKHOURS_PERSON_CONTROL_OPEN_MODAL:
                return {
                    ...state,
                    workHoursControlData: action.payload,
                    workHoursModal:"",
                    workHoursControlLoading: false
                }
            case OrganizationActionType.GET_DELETE_PERSON_CONTROL_PASS:
                return {
                    ...state,
                    workHoursControlData: action.payload,
                    workHoursModal:"",
                    workHoursControlLoading: false
                }   
            case OrganizationActionType.UPDATE_WORKHOURS_PERSON_CONTROL_RESET:
                return {
                    ...state,
                    workHoursControlData: [],
                    workHoursModal:"close",
                    workHoursControlLoading: false
                }
            case OrganizationActionType.GET_PERSON_WORK_HOURS_REQUEST:
                return {
                    ...state,
                    personWorkHoursLoading:true
                };
            case OrganizationActionType.GET_PERSON_WORK_HOURS_SUCCESS:
                return {
                    ...state,
                    personWorkHours: action.payload,
                    personWorkHoursLoading: false
                }
            case OrganizationActionType.GET_PERSON_WORK_HOURS_FAIL:
                return {
                    ...state,
                    personWorkHoursLoading: false
                }
            case OrganizationActionType.GET_PERSON_WORK_HOURS_RESET:
                return {
                    ...state,
                    personWorkHours: {'id':null, 'displayValue':''},
                    personWorkHoursLoading: true
                }  
            case OrganizationActionType.GET_ACCOUNT_CONTRACTS_REQUEST:
                return {
                    ...state,
                    contractItemsLoading: true
                };
            case OrganizationActionType.GET_ACCOUNT_CONTRACTS_SUCCESS:
                return {
                    ...state,
                    contractItems: action?.payload,
                    contractItemsLoading: false
                }
            case OrganizationActionType.GET_ACCOUNT_CONTRACTS_FAIL:
                return {
                    ...state,
                    contractItemsLoading: false
                }            
            case OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_REQUEST:
                return {
                  ...state,
                  approveOrganizationRegisterMailResponse: {
                    status: "LOADING",
                    message: "",
                  },
                  approveOrganizationRegisterMailResponseLoading: true,
                };
              case OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_SUCCESS:
                return {
                  ...state,
                  approveOrganizationRegisterMailResponse: {
                    status: "SUCCESS",
                    message: action?.payload,
                  },
                  approveOrganizationRegisterMailResponseLoading: false,
                };
              case OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_FAIL:
                return {
                  ...state,
                  approveOrganizationRegisterMailResponse: {
                    status: "FAIL",
                    message: action?.payload,
                  },
                  approveOrganizationRegisterMailResponseLoading: false,
                };                
            case OrganizationActionType.GET_PERSON_ORGANIZATIONS_REQUEST:
                return {
                  ...state,
                  personOrganizationListLoading: true,
                };
              case OrganizationActionType.GET_PERSON_ORGANIZATIONS_SUCCESS:
                return {
                  ...state,
                  personOrganizationList: action?.payload,
                  personOrganizationListLoading: false,
                };
              case OrganizationActionType.GET_PERSON_ORGANIZATIONS_FAIL:
                return {
                  ...state,
                  personOrganizationListLoading: false,
                };  
              case OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_SUCCESS:
                return {
                    ...state,
                    organizationUpdatePhoneRequestResponse: {
                        "status": "SUCCESS",
                        "message": action.payload
                    }
                }
              case OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_FAIL:
                return {
                    ...state,
                    organizationUpdatePhoneRequestResponse: {
                        "status": "FAIL",
                        "message": action.payload
                    }
                }
                case OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_RESET:
                    return {
                        ...state,
                        organizationUpdatePhoneRequestResponse: { "status": "loading", "message": "" }
                    }            
        default: return state
            }

}
