import { NotificationActionType } from "../actions/notification.actions";

const initialState = {
  notificationUpdateResponse: { status: "loading", message: "" },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationUpdateResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL:
      return {
        ...state,
        notificationUpdateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_RESET:
      return {
        ...state,
        notificationUpdateResponse: {
          status: "loading",
          message: null,
        },
      };
    default:
      return state;
  }
}
