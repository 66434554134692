import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { setTimeOffAddDrawer, setTimeOffRequestApproveDrawer } from '../../../redux/actions/time-off-drawer-action';
import { getTimeOffRequest, getTimeOffRequestStep, getAllTimeOfRequest, timeOffDownload, timeOffCancel, resetTimeOffApproveResponseStatus, deleteTimeOffRequest, deleteTimeOffRequestReset } from '../../../redux/actions/timeOff.actions'
import { Button, Dropdown } from 'antd';
import { timeoff_dropdown } from '../../../features/global-dropdown-menu/index';
import { MoreOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../../dynamicTable/index';
import DynamicToastMessage from '../../dynamicToastMessage/index'
import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";



function AllTimeOffRequest(props) {
    const { t } = useTranslation();
    const { totalNumberOfRecords, profile, permissions, getAllTimeOfRequest, getTimeOffRequest, timeOffDownload,
        timeOffApproveResponse, deleteTimeOffRequestReset, deleteTimeOffRequestResponse, handleCloseModal, setSelectedData,
        resetTimeOffApproveResponseStatus, getTimeOffRequestStep, allTimeOffLoading, allTimeOffList, allTimeOffSearchData,
        setTimeOffRequestApproveDrawer, cancelModalData, setCancelModalData, deleteModalData, setDeleteModalData } = props
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [searchData, setSearchData] = useState(allTimeOffSearchData)
    const [tableKey, setTableKey] = useState(100);

    useEffect(() => {
        getAllTimeOfRequest(searchData)
    }, [searchData]);
    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'APPROVE_SUCCESS',
                'title': t('SUCCESS'),
                'description': timeOffApproveResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (timeOffApproveResponse.resourceId !== undefined) {
                getAllTimeOfRequest({ ...searchData, "personResourceId": profile.resourceId })
            }
            handleCloseModal();
            resetTimeOffApproveResponseStatus()

        } else if (timeOffApproveResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'APPROVE_FAIL',
                'title': t('ERROR'),
                'description': timeOffApproveResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            handleCloseModal();
            resetTimeOffApproveResponseStatus()
        }
    }, [timeOffApproveResponse])
    useEffect(() => {
        if (deleteTimeOffRequestResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            if (allTimeOffList?.length > 1) {
                getAllTimeOfRequest({ ...searchData, personResourceId: profile.resourceId });
            } else {
                setSearchData({
                    ...searchData,
                    personResourceId: profile.resourceId,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            deleteTimeOffRequestReset();
        } else if (deleteTimeOffRequestResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_FAIL',
                'title': t('ERROR'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffRequestReset()
        }
    }, [deleteTimeOffRequestResponse])

    const handleSaveGridSettings = (d) => {
        //  createGridSettings(createObj)
    }
    const handleResetAllfilters = () => {
        let key = tableKey;
        key = key + 1;
        setTableKey(key);
        setIsShowPassives(false)
        setSearchData(allTimeOffSearchData);
    }
    const handleTableFilter = (data) => {
        setSearchData({
            ...searchData,
            "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "timeOffRequestDate",
                "ascending": false
            },
            "personResourceId": profile.resourceId,
            "persons": data.persons, "stateTypes": data.stateType, "timeOffTypes": data.timeOffTypeName
        })
    }
    const showTimeOffRequestApproveDrawer = (id, timeOffRequestDate, approveSeqNumber, postId) => {
        setTimeOffRequestApproveDrawer(true);
        if (id !== undefined) {
            getTimeOffRequest(id)
            getTimeOffRequestStep(id)
            setSelectedData({ 'resourceId': id, 'requestDate': timeOffRequestDate, 'approveSeqNumber': approveSeqNumber, 'postId': postId })
        }
    }
    const timeOffCancelBtn = (id) => {
        setCancelModalData({ ...cancelModalData, "isVisible": true, "id": id })
    }
    const timeOffDeletelBtn = (id, timeOffTypeName) => {
        setDeleteModalData({ ...deleteModalData, "isVisible": true, "id": id, 'timeOffTypeName': timeOffTypeName })
    }
    const columns = [
        {
            title: `${t('TIME_OFF_NAME_SURNAME')}`,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
            render: obj =>
                <div className="imageNameActionColumn">
                    <Can I="timeoff.request.all.read">
                        <a onClick={() => showTimeOffRequestApproveDrawer(obj.id, obj.timeOffRequestDate, obj.approveSeqNumber, obj.postId)} className="details-text mar-0" >{obj.name}</a>
                    </Can>
                    <Cannot I="timeoff.request.all.read">
                        <span style={{ paddingLeft: '15px' }} >{obj.name}</span>
                    </Cannot>
                    {permissions.some(item => ["timeoff.request.cancel", "timeoff.request.export", "timeoff.request.delete"].includes(item)) &&
                        <Dropdown
                            className="action-menu"
                            overlay={timeoff_dropdown(t, timeOffCancelBtn, timeOffDownload, timeOffDeletelBtn, obj.id, obj.stateTypeId, obj.timeOffTypeName, permissions)}
                            trigger={['click']}
                        >
                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                        </Dropdown>
                    }
                </div>
        },
        {
            title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
            dataIndex: 'timeOffRequestDate',
            key: 'timeOffRequestDate',
            sorter: true,
            render: obj =>
                <div className="imageNameActionColumn">
                    <p className="mar-0" >{moment(obj).format('LL')}</p>

                </div>
        },
        {
            title: `${t('TIME_OFF_TYPE_TEXT')}`,
            dataIndex: 'timeOffTypeName',
            key: 'timeOffTypeName',
            sorter: true,
        },
        {
            title: `${t('ORGANIZATION_STATU')}`,
            dataIndex: 'stateType',
            key: 'stateType',
            sorter: true,
            render: state => {
                if (state.id === '1') {
                    return <div className='green'>{state.displayValue}</div>
                } else if (state.id === '2') {
                    return <div>{state.displayValue}</div>
                } else if (state.id === '3') {
                    return <div className='red'>{state.displayValue}</div>
                } else if (state.id === '4') {
                    return <div className='blue'>{state.displayValue}</div>
                } else {
                    return <div >{state?.displayValue}</div>
                }
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_START_DATE')}`,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            render: obj => {
                return (moment(obj).format('LL'))
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_END_DATE')}`,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
            render: obj => {
                return (moment(obj).format('LL'))
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_TIME')}`,
            dataIndex: 'balance',
            key: 'balance',
            sorter: true,
            render: obj => {
                if (obj.unitId === 1) {
                    return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj.balance })}`
                } else {
                    return `${t('TIME_OFF_REQUEST_HOURS', { "hour": obj.balance })}`
                }
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_REMAINING')}`,
            dataIndex: 'remainderBalance',
            key: 'remainderBalance',
            sorter: true,
            render: obj => {
                if (obj.unitId === 1) {
                    return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj.remainderBalance })}`
                } else {
                    return `${t('TIME_OFF_REQUEST_HOURS', { "hour": obj.remainderBalance })}`
                }
            }
        },
        {
            title: `${t('APPROVAL_PERSON_TIME_OFF')}`,
            dataIndex: "personToApprove",
            key: "personToApprove",
            sorter: true,
        }
    ];
    const data = allTimeOffList.map((row) => {
        return (
            {
                "key": row.resourceId,
                "fullName": { 'id': row.resourceId, 'name': row.fullName, 'stateTypeId': row.stateType?.id, 'timeOffRequestDate': row.timeOffRequestDate, 'approveSeqNumber': row.approveSeqNumber, 'postId': row.timeOffRequestId },
                "timeOffRequestDate": row.timeOffRequestDate,
                "timeOffTypeName": row.timeOffTypeName,
                "startDate": row.startDate,
                "endDate": row.endDate,
                "balance": { "balance": row.balance, "unitId": row.unitSetIdx },
                "remainderBalance": { "remainderBalance": row.remainderBalance, "unitId": row.unitSetIdx },
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue },
                "personToApprove": row.personToApprove
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: ['fullName', 'timeOffTypeName', 'stateType'],
        sortableColumns: ['fullName', 'timeOffTypeName', 'timeOffRequestDate', 'startDate', 'endDate', 'balance', 'remainderBalance', 'stateType', 'personToApprove'],
        visibilityOptionExcludes: ['fullName', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'remainderBalance', 'stateType', 'personToApprove'],
        gridSettings: [],
        page: "allTimeOffRequest",
        showVisibilityMenu: false,
    })
    const paginationConfig = () => ({
        current: searchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = {
            ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize,
            "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : searchData.pagination.orderBy)
        }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }

    return (
        <div className="table-container">
            <DynamicTable
                key={tableKey}
                columns={columns} dataSource={data}
                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
                searchPlaceholder=""
                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                searchBox={false}
                checkboxStatus={false}
                passiveButton={false}
                loadStatus={allTimeOffLoading}
            //initialFilterData={allTimeOffSearchData}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        allTimeOffList: state.timeOff.allTimeOffList.results,
        allTimeOffLoading: state.timeOff.allTimeOffLoading,
        totalNumberOfRecords: state.timeOff.allTimeOffList.totalNumberOfRecords,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        profile: state.profile,
        permissions: state.profile.permissions,
        deleteTimeOffRequestResponse: state.timeOff.deleteTimeOffRequestResponse,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTimeOfRequest: (searchData) => { dispatch(getAllTimeOfRequest(searchData)) },
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        setTimeOffAddDrawer: (props) => { dispatch(setTimeOffAddDrawer(props)) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        timeOffCancel: (data) => { dispatch(timeOffCancel(data)) },
        timeOffDownload: (props) => { dispatch(timeOffDownload(props)) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        deleteTimeOffRequest: (resourceId) => { dispatch(deleteTimeOffRequest(resourceId)) },
        deleteTimeOffRequestReset: () => { dispatch(deleteTimeOffRequestReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllTimeOffRequest);