import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';


const DocumentTypeActionType = {
  DOCUMENT_TYPE_LIST_REQUEST: "DOCUMENT_TYPE_LIST_REQUEST",
  DOCUMENT_TYPE_LIST_SUCCESS: "DOCUMENT_TYPE_LIST_SUCCESS",
  DOCUMENT_TYPE_LIST_FAIL: "DOCUMENT_TYPE_LIST_FAIL",
  CREATE_DOCUMENT_TYPE_SUCCESS: "CREATE_DOCUMENT_TYPE_SUCCESS",
  CREATE_DOCUMENT_TYPE_FAIL: "CREATE_DOCUMENT_TYPE_FAIL",
  CREATE_DOCUMENT_TYPE_NAME_INPUT_ERROR: "CREATE_DOCUMENT_TYPE_NAME_INPUT_ERROR",
  CREATE_DOCUMENT_TYPE_RESET: "CREATE_DOCUMENT_TYPE_RESET",
  UPDATE_DOCUMENT_TYPE_REQUEST: "UPDATE_DOCUMENT_TYPE_REQUEST",
  UPDATE_DOCUMENT_TYPE_SUCCESS: "UPDATE_DOCUMENT_TYPE_SUCCESS",
  UPDATE_DOCUMENT_TYPE_FAIL: "UPDATE_DOCUMENT_TYPE_FAIL",
  UPDATE_DOCUMENT_TYPE_INPUT_ERROR: "UPDATE_DOCUMENT_TYPE_INPUT_ERROR",
  UPDATE_DOCUMENT_TYPE_RESET: "UPDATE_DOCUMENT_TYPE_RESET",
  DELETE_DOCUMENT_TYPE_SUCCESS: "DELETE_DOCUMENT_TYPE_SUCCESS",
  DELETE_DOCUMENT_TYPE_FAIL: "DELETE_DOCUMENT_TYPE_FAIL",
  DELETE_DOCUMENT_TYPE_RESET: "DELETE_DOCUMENT_TYPE_RESET",
 
};

const getDocumentTypeList = () => {
  return async (dispatch) => {
    dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_REQUEST });
    try {
      var config = {
        method: 'get',
        url: apiUrl + '/organizationDocumentType',
        headers: {
          'Content-Type': 'application/json'
        },
      //  data: data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          // if (error.response?.status === 404 || error.response?.status === 500) {
          //   const emptyData = {
          //     "pageNumber": 1,
          //     "pageSize": 1,
          //     "totalNumberOfPages": 1,
          //     "totalNumberOfRecords": 0,
          //     "results": []
          //   }
          //   dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_SUCCESS, payload: emptyData });
          // }
          dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL, payload: error.message });
      }
    }
  };
};
// const getDocumentTypeList = (searchData) => {
//   return async (dispatch) => {
//     dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_REQUEST });
//     try {
//       const data = JSON.stringify(searchData);
//       var config = {
//         method: 'post',
//         url: apiUrl + '/documentType/search',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         data: data
//       };
//       hrApi(config)
//         .then(function (response) {
//           if (response.status === 200) {
//             dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_SUCCESS, payload: response.data.data });
//           } else {
//             dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL, payload: response.data.Message });
//           }
//         })
//         .catch(function (error) {
//           if (error.response?.status === 404 || error.response?.status === 500) {
//             const emptyData = {
//               "pageNumber": 1,
//               "pageSize": 1,
//               "totalNumberOfPages": 1,
//               "totalNumberOfRecords": 0,
//               "results": []
//             }
//             dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_SUCCESS, payload: emptyData });
//           }
//           dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL, payload: error.message });
//         });
//     } catch (error) {
//       if (error.response) {
//         dispatch({ type: DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL, payload: error.message });
//       }
//     }
//   };
// };
const createDocumentType = (createData) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createData);
      var config = {
        method: 'post',
        url: apiUrl + '/organizationDocumentType/',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: DocumentTypeActionType.CREATE_DOCUMENT_TYPE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: DocumentTypeActionType.CREATE_DOCUMENT_TYPE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: DocumentTypeActionType.CREATE_DOCUMENT_TYPE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: DocumentTypeActionType.CREATE_DOCUMENT_TYPE_FAIL, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DocumentTypeActionType.CREATE_DOCUMENT_TYPE_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateDocumentType = () => {
  return async (dispatch) => {
    dispatch({ type: DocumentTypeActionType.CREATE_DOCUMENT_TYPE_RESET, payload: {} });
  };
}
const updateDocumentType = (updateData, id) => {
  return async (dispatch) => {
    dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_REQUEST });
    try {
      const resourceId = id;
      const data = JSON.stringify(updateData);
      var config = {
        method: 'put',
        url: apiUrl + '/organizationDocumentType/' + resourceId,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_INPUT_ERROR, payload: error.response.data.message });
          }else if(error.response?.status === 409) {
            dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateDocumentType = () => {
  return async (dispatch) => {
    dispatch({ type: DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_RESET, payload: {} });
  };
}
const deleteDocumentType = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'delete',
        url: apiUrl + '/organizationDocumentType/' + resourceId,
        headers: {
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_FAIL, payload: error.response.data.message });
          }else if(error.response?.status === 404){
            dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteDocumentType = () => {
  return async (dispatch) => {
    dispatch({ type: DocumentTypeActionType.DELETE_DOCUMENT_TYPE_RESET, payload: {} });
  };
}
export {
  DocumentTypeActionType,
  getDocumentTypeList,
  createDocumentType,
  resetCreateDocumentType,
  updateDocumentType,
  resetUpdateDocumentType,
  deleteDocumentType,
  resetDeleteDocumentType,
}