import { useState, useEffect } from "react";
import { Tag } from "antd";
import TR from "antd/es/date-picker/locale/tr_TR";
import EN from 'antd/es/date-picker/locale/en_US';
import "moment/locale/tr";
import 'moment/locale/en-gb';

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const authUrl = process.env.REACT_APP_AUTH_URL;
const oidcConfig = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_LOGIN_CALLBACK,
  silent_redirect_uri : process.env.REACT_APP_REFRESH_TOKEN_CALLBACK,
  post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_CALLBACK,
};
const gtmCode = process.env.REACT_APP_GTM_CODE

const drawerWidth = 875;
const dateTimeFormat = "HH:mm";
const dateTimeFormatWithSecond = "HH:mm:ss";
const dateFormat = "YYYY-MM-DD";
const dateFormatWithHours = "YYYY-MM-DD HH:mm";
const hoursFormat = "HH:mm";
const dateFormatList = ["LL"];
const dateFormatConvert = "LL";
const numberFormatTR = Intl.NumberFormat('tr-TR');
const educationDateFormatter = "DD/MM/YYYY";
const educationShortDateFormatter = "DD/MM/YY";
const educationDateWithTimeFormatter = "DD/MM/YYYY HH:mm:ss";



const customTableScrolling = (totalNumberOfRecords, windowHeight, cssClass) => {
  if (totalNumberOfRecords >= 2 && windowHeight < 670) {
    return cssClass + " pad-right-10";
  } else if (totalNumberOfRecords > 3 && windowHeight > 671 && windowHeight < 800) {
    return cssClass + " pad-right-10";
  } else if (totalNumberOfRecords > 4 && windowHeight > 801 && windowHeight < 880) {
    return cssClass + " pad-right-10";
  } else if (totalNumberOfRecords > 5 && windowHeight > 881 && windowHeight < 930) {
    return cssClass + " pad-right-10";
  } else if (totalNumberOfRecords > 6 && windowHeight > 931 && windowHeight < 1000) {
    return cssClass + " pad-right-10";
  } else if (totalNumberOfRecords >= 7 && windowHeight >= 1000) {
    return cssClass + " pad-right-10";
  } else {
    return cssClass + " pad-right-0";
  }
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
function EducationDynamicTag(props) {
  const { statusId, t} = props;
  switch (statusId) {
    case 1:
      <Tag className="yellow-tag mt-3">{t('STARTED')}</Tag>
    case 2:
      return <Tag className="yellow-tag mt-3">{t('IN_PROGRESS')}</Tag>;
    case 4:
      return <Tag className="green-tag mt-3">{t('COMPLETE')}</Tag>
    case 16:
      return <Tag className="red-tag mt-3">{t('EXPIRED')}</Tag>;
    case 32:
      return <Tag className="gray-tag mt-3">{t('ADDED')}</Tag>;
    case '':
      return '';
    case undefined:
      return '';
    default:
      break;
  }
}
const timeFormat = (lang) => {
  if (lang === "en") {
    return "MM/DD/YYYY";
  } else if (lang === "tr-TR") {
    return "DD.MM.YYYY";
  }
};
// const localeLang = localStorage.getItem('i18nLang') === 'tr-TR' ?  TR : EN;
const localeLang = localStorage.getItem("i18nLang") === "tr-TR" ? TR : EN; // Takvim Tarih Seçimi 
const dynamicDateInputFormatter = localStorage.getItem("i18nLang") === "tr-TR" ? "DD/MM/YYYY" : "MM/DD/YYYY";
const maskInputFormatterFormLabel = localStorage.getItem("i18nLang") === "tr-TR" ? "DD/MM/YYYY" : "MM/DD/YYYY";
const dateInputMask = localStorage.getItem("i18nLang") === "tr-TR" ? "99/99/9999" : "99/99/9999";


const phoneInputConfig = {
  autoFormat: true,
  placeholder: "",
  country: localStorage.getItem("i18nLang") === "tr-TR" ? 'tr' : 'gb'
};

export {
  apiUrl,
  paymentApiUrl,
  authUrl,
  oidcConfig,
  gtmCode,
  drawerWidth,
  dateTimeFormat,
  dateTimeFormatWithSecond,
  dateFormat,
  dateFormatWithHours,
  hoursFormat,
  dateFormatList,
  educationDateFormatter,
  educationShortDateFormatter,
  educationDateWithTimeFormatter,
  phoneInputConfig,
  dateFormatConvert,
  numberFormatTR,
  EducationDynamicTag,
  timeFormat,
  localeLang,
  dynamicDateInputFormatter,
  maskInputFormatterFormLabel,
  dateInputMask,
  customTableScrolling,
  useWindowDimensions,
};
