import {CheckoutActionType} from '../actions/checkout.actions';

const initialState = {
    paymentPackages:{
        monthlyPackages:[],
        yearlyPackages:[]
    },
    paymentPackagesLoading: true,
    paymentSummary:{
        discountCode: "",
        discountPackagePrice: null,
        discountPrice: null,
        packagePrice: null,
    },
    paymentSummaryLoading: true,
    subscriptionSummary:{
        "currentPackage": {
            "id": null,
            "isYearly": null,
            "packagePaymentEndDate": null,
            "packageName": "",
            "employeeCount": "",
            "amount": null
        },
        "newPackage": {
          "id": null,
          "isYearly": null,
          "packagePaymentEndDate": null,
          "packageName": "",
          "employeeCount": "",
          "amount": null
        },
        "cardInformation": null,
        "summary": null
    },
    subscriptionSummaryLoading: true,
    organizationInvoiceAddress:{

    },
    organizationInvoiceAddressLoading:true,
    paymentTransaction:{},
    paymentTransactionLoading: true,
    subscriptionPaymentTransaction:{},
    subscriptionPaymentTransactionLoading: true,
    subscriptionFailResponse: null,
    secure3DHTMLContent: '',
    paymentCompleted:{},
    paymentcompletedLoading: true
}

export default function (state = initialState, action) {

    switch (action.type) {
        case CheckoutActionType.GET_PAYMENT_PACKAGES_REQUEST:
            return {
                ...state,
                paymentPackagesLoading:true
            };
        case CheckoutActionType.GET_PAYMENT_PACKAGES_SUCCESS:
            return {
                ...state,
                paymentPackages: action.payload,
                paymentPackagesLoading:false
            }
        case CheckoutActionType.GET_PAYMENT_PACKAGES_FAIL:
            return {
                ...state,
                paymentPackages: action.payload,
                paymentPackagesLoading:false
            }
        case CheckoutActionType.GET_PAYMENT_SUMMARY_REQUEST:
            return {
                ...state,
                paymentSummaryLoading:true
            };
        case CheckoutActionType.GET_PAYMENT_SUMMARY_SUCCESS:
            return {
                ...state,
                paymentSummary: action.payload,
                paymentSummaryLoading:false
            }
        case CheckoutActionType.GET_PAYMENT_SUMMARY_FAIL:
            return {
                ...state,
                paymentSummary: action.payload,
                paymentSummaryLoading:false
            }
        case CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_REQUEST:
            return {
                ...state,
                subscriptionSummaryLoading:true
            };
        case CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_SUCCESS:
            return {
                ...state,
                subscriptionSummary: action.payload,
                subscriptionSummaryLoading:false
            }
        case CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_FAIL:
            return {
                ...state,
                subscriptionSummary: action.payload,
                subscriptionSummaryLoading:false
            }
        case CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_REQUEST:
            return {
                ...state,
                organizationInvoiceAddressLoading:true
            };
        case CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_SUCCESS:
            return {
                ...state,
                organizationInvoiceAddress: action.payload,
                organizationInvoiceAddressLoading:false
            }
        case CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_FAIL:
            return {
                ...state,
                organizationInvoiceAddress: action.payload,
                organizationInvoiceAddressLoading:false
            }
        case CheckoutActionType.GET_PAYMENT_TRANSACTION_REQUEST:
            return {
                ...state,
                paymentTransactionLoading:true
            };
        case CheckoutActionType.GET_PAYMENT_TRANSACTION_SUCCESS:
            return {
                ...state,
                secure3DHTMLContent: action.payload?.htmlContent,
                paymentTransaction: action.payload,
                paymentTransactionLoading:false
            }
        case CheckoutActionType.GET_PAYMENT_TRANSACTION_FAIL:
            return {
                ...state,
                paymentTransaction: {"status": 400, data : action?.payload},
                paymentTransactionLoading:false
            }
        case CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_REQUEST:
            return {
                ...state,
                subscriptionPaymentTransactionLoading:true
            };
        case CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_SUCCESS:
            return {
                ...state,
                secure3DHTMLContent: action.payload?.htmlContent,
                subscriptionPaymentTransaction: action.payload,
                subscriptionPaymentTransactionLoading:false,
                subscriptionFailResponse: null
            }
        case CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL:
            return {
                ...state,
                subscriptionPaymentTransaction: false,
                subscriptionPaymentTransactionLoading:false,
                subscriptionFailResponse: action.payload
        }
        case CheckoutActionType.GET_PAYMENT_COMPLETED_REQUEST:
            return {
                ...state,
                paymentCompletedLoading:true
            };
        case CheckoutActionType.GET_PAYMENT_COMPLETED_SUCCESS:
            return {
                ...state,
                paymentCompleted: action.payload,
                paymentCompletedLoading:false
            }
        case CheckoutActionType.GET_PAYMENT_COMPLETED_FAIL:
            return {
                ...state,
                paymentCompleted: false,
                paymentCompletedLoading:false
            }
        case CheckoutActionType.RESET_PAYMENT_TRANSACTION:
            return {
                ...state,
                secure3DHTMLContent: '',
                paymentTransaction: {},
                paymentTransactionLoading:true,
                subscriptionPaymentTransaction: {},
                subscriptionPaymentTransactionLoading:true
            }
        
        default:
            return state
    }
}
