import { TaskActionType } from "../actions/task.actions";

const initialState = {
  definedTaskCreateDrawerStatus: false,
  taskCreateDrawerStatus: false,
  definedTaskDetailDrawerStatus: false,
  assignedTaskDetailDrawer: false,
  myTaskDetailDrawerStatus: false,
  updateAssignedTasksResponse: { status: "loading", message: "" },
  updateOrderAssignedTasksResponse: { status: "loading", message: "" },
  createAssignedTasksResponse: { status: "loading", message: "" },
  deleteDefinedTasksResponse: { status: "loading", message: "" },
  assignedTasksList: [],
  assignedTasksListLoading: true,
  assignedTaskDetails: {},
  assignedTaskDetailsLoading: true,
  myTasksList: {
    totalNumberOfRecords: 0,
    taskItems: [],
  },
  myTasksListLoading: true,
  tasksAssignedList: {
    totalNumberOfRecords: 0,
    assignedTaskList: [],
  },
  tasksAssignedListLoading: true,
  getStepListTasks: {},
  getStepListTasksLoading: true,
  createAssignedForm: {
    taskName: "",
    taskDescription: "",
    resourceId: "",
    ownerShipTypeId: "",
    person: {
      resourceId: null,
      displayValue: null,
      ownerShipTypeId: null,
      ownerShipName: null,
    },
    role: {
      resourceId: "",
      displayValue: "",
      ownerShipTypeId: null,
      ownerShipName: "",
    },
    attributes: [],
  },
  createAssignedFormLoading: true,
  personTaskInformation: {
    personTasks: [
      {
        taskResourceId: "",
        taskName: "",
        taskDescription: "",
        assignedInformation: {
          isCompleted: null,
          state: null,
          completedDescription: null,
          assignedDate: "",
          taskAssignedResourceId: "",
          lastProcessDatetime: null,
          attributes: [],
        },
      },
    ],
  },
  personTaskInformationLoading: true,
  assignedTaskAndAdvanceActionResponse: { status: "loading", data: "" },
  taskUpdateStatusResponse: { status: "loading", data: "" },
  assignedTaskInformation: {
    avatar: null,
    department: null,
    personName: null,
    totalTaskCount: null,
    completedTaskCount: null,
    personTasks: [
      {
        taskResourceId: null,
        taskName: null,
        appointeeName: null,
        appointeeResourceId: null,
        taskDescription: null,
        assignedInformation: {
          isCompleted: null,
          state: null,
          completedDescription: null,
          assignedDate: null,
          taskAssignedResourceId: null,
          lastProcessDatetime: null,
          attributes: [],
        },
      },
    ],
    message: null,
    validationMessages: null,
    status: null,
  },
  assignedTaskInformationLoading: true,
  completeAllTasksResponse: { status: "loading", message: "", data: "" },
  definedTaskUpdateStatusResponse: { status: "loading", data: "" },
  deleteAssignedTaskResponse: { status: "loading", message: "" },
  filterAssignedTaskStatusResult: { items: [] },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TaskActionType.SET_DEFINED_TASK_CREATE_DRAWER:
      return {
        ...state,
        definedTaskCreateDrawerStatus: action.payload,
      };
    case TaskActionType.SET_TASK_CREATE_DRAWER:
      return {
        ...state,
        taskCreateDrawerStatus: action.payload,
      };
    case TaskActionType.SET_DEFINED_TASK_DETAIL_DRAWER:
      return {
        ...state,
        definedTaskDetailDrawerStatus: action.payload,
      };
    case TaskActionType.SET_MY_TASK_DETAIL_DRAWER:
      return {
        ...state,
        myTaskDetailDrawerStatus: action.payload,
      };
    case TaskActionType.SET_ASSIGNED_TASK_DETAIL_DRAWER:
      return {
        ...state,
        assignedTaskDetailDrawer: action.payload,
      };
    case TaskActionType.ASSIGNED_TASKS_LIST_REQUEST:
      return {
        ...state,
        assignedTasksListLoading: true,
      };
    case TaskActionType.ASSIGNED_TASKS_LIST_SUCCESS:
      return {
        ...state,
        assignedTasksList: action.payload,
        assignedTasksListLoading: false,
      };
    case TaskActionType.ASSIGNED_TASKS_LIST_FAIL:
      return {
        ...state,
        assignedTasksListLoading: false,
      };
    case TaskActionType.ASSIGNED_TASK_DETAILS_REQUEST:
      return {
        ...state,
        assignedTaskDetailsLoading: true,
      };
    case TaskActionType.ASSIGNED_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        assignedTaskDetails: action.payload,
        assignedTaskDetailsLoading: false,
      };
    case TaskActionType.ASSIGNED_TASK_DETAILS_FAIL:
      return {
        ...state,
        assignedTaskDetailsLoading: false,
      };
    case TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_SUCCESS:
      return {
        ...state,
        updateOrderAssignedTasksResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL:
      return {
        ...state,
        updateOrderAssignedTasksResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_RESET:
      return {
        ...state,
        updateOrderAssignedTasksResponse: {
          status: "loading",
          message: "",
        },
      };
    case TaskActionType.CREATE_ASSIGNED_TASKS_SUCCESS:
      return {
        ...state,
        createAssignedTasksResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TaskActionType.CREATE_ASSIGNED_TASKS_FAIL:
      return {
        ...state,
        createAssignedTasksResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TaskActionType.CREATE_ASSIGNED_TASKS_RESET:
      return {
        ...state,
        createAssignedTasksResponse: {
          status: "loading",
          message: "",
        },
      };
    case TaskActionType.UPDATE_ASSIGNED_TASKS_SUCCESS:
      return {
        ...state,
        updateAssignedTasksResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TaskActionType.UPDATE_ASSIGNED_TASKS_FAIL:
      return {
        ...state,
        updateAssignedTasksResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TaskActionType.UPDATE_ASSIGNED_TASKS_RESET:
      return {
        ...state,
        updateAssignedTasksResponse: {
          status: "loading",
          message: "",
        },
      };
    case TaskActionType.DELETE_DEFINED_TASK_SUCCESS:
      return {
        ...state,
        deleteDefinedTasksResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TaskActionType.DELETE_DEFINED_TASK_FAIL:
      return {
        ...state,
        deleteDefinedTasksResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TaskActionType.DELETE_DEFINED_TASK_RESET:
      return {
        ...state,
        deleteDefinedTasksResponse: {
          status: "loading",
          message: "",
        },
      };
    case TaskActionType.GET_MY_TASKS_LIST_REQUEST:
      return {
        ...state,
        myTasksListLoading: true,
      };
    case TaskActionType.GET_MY_TASKS_LIST_SUCCESS:
      return {
        ...state,
        myTasksList: action.payload,
        myTasksListLoading: false,
      };
    case TaskActionType.GET_MY_TASKS_LIST_FAIL:
      return {
        ...state,
        myTasksListLoading: false,
      };
    case TaskActionType.GET_TASKS_ASSIGNED_LIST_REQUEST:
      return {
        ...state,
        tasksAssignedListLoading: true,
      };
    case TaskActionType.GET_TASKS_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        tasksAssignedList: action.payload,
        tasksAssignedListLoading: false,
      };
    case TaskActionType.GET_TASKS_ASSIGNED_LIST_FAIL:
      return {
        ...state,
        tasksAssignedListLoading: false,
      };
    case TaskActionType.GET_STEP_LIST_TASKS_REQUEST:
      return {
        ...state,
        getStepListTasksLoading: true,
      };
    case TaskActionType.GET_STEP_LIST_TASKS_SUCCESS:
      return {
        ...state,
        getStepListTasks: action.payload,
        getStepListTasksLoading: false,
      };
    case TaskActionType.GET_STEP_LIST_TASKS_FAIL:
      return {
        ...state,
        getStepListTasksLoading: false,
      };
    case TaskActionType.GET_STEP_LIST_TASKS_RESET:
      return {
        ...state,
        getStepListTasks: {},
        getStepListTasksLoading: false,
      };
    case TaskActionType.CREATE_ASSIGNED_FORM_REQUEST:
      return {
        ...state,
        createAssignedFormLoading: true,
      };
    case TaskActionType.CREATE_ASSIGNED_FORM_SUCCESS:
      return {
        ...state,
        createAssignedForm: action.payload,
        createAssignedFormLoading: false,
      };
    case TaskActionType.CREATE_ASSIGNED_FORM_FAIL:
      return {
        ...state,
        createAssignedFormLoading: false,
      };
    case TaskActionType.RESET_CREATE_ASSIGNED_FORM:
      return {
        ...state,
        createAssignedForm: {
          taskName: "",
          taskDescription: "",
          resourceId: "",
          ownerShipTypeId: "",
          person: {
            resourceId: null,
            displayValue: null,
            ownerShipTypeId: null,
            ownerShipName: null,
          },
          role: {
            resourceId: "",
            displayValue: "",
            ownerShipTypeId: null,
            ownerShipName: "",
          },
          attributes: [],
        },
        createAssignedFormLoading: true,
      };
    case TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_SUCCESS:
      return {
        ...state,
        assignedTaskAndAdvanceActionResponse: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_FAIL:
      return {
        ...state,
        assignedTaskAndAdvanceActionResponse: {
          status: "FAIL",
          data: action.payload,
        },
      };
    case TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_RESET:
      return {
        ...state,
        assignedTaskAndAdvanceActionResponse: {
          status: "loading",
          data: "",
        },
      };
    case TaskActionType.GET_TASK_DESCRIPTION_DETAIL_REQUEST:
      return {
        ...state,
        personTaskInformationLoading: true,
      };
    case TaskActionType.GET_TASK_DESCRIPTION_DETAIL_SUCCESS:
      return {
        ...state,
        personTaskInformation: action.payload,
        personTaskInformationLoading: false,
      };
    case TaskActionType.GET_TASK_DESCRIPTION_DETAIL_FAIL:
      return {
        ...state,
        personTaskInformation: action.payload,
        personTaskInformationLoading: false,
      };
    case TaskActionType.GET_TASK_DESCRIPTION_DETAIL_RESET:
      return {
        ...state,
        personTaskInformation: {
          personTasks: [
            {
              taskResourceId: "",
              taskName: "",
              taskDescription: "",
              assignedInformation: {
                isCompleted: null,
                state: null,
                completedDescription: null,
                assignedDate: "",
                taskAssignedResourceId: "",
                lastProcessDatetime: null,
                attributes: [],
              },
            },
          ],
        },
        personTaskInformationLoading: true,
      };
    case TaskActionType.TASK_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        taskUpdateStatusResponse: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case TaskActionType.TASK_UPDATE_STATUS_FAIL:
      return {
        ...state,
        taskUpdateStatusResponse: {
          status: "FAIL",
          data: action.payload,
        },
      };
    case TaskActionType.TASK_UPDATE_STATUS_RESET:
      return {
        ...state,
        taskUpdateStatusResponse: {
          status: "loading",
          data: "",
        },
      };
    case TaskActionType.GET_ASSIGNED_TASK_INFORMATION_REQUEST:
      return {
        ...state,
        assignedTaskInformationLoading: true,
      };
    case TaskActionType.GET_ASSIGNED_TASK_INFORMATION_SUCCESS:
      return {
        ...state,
        assignedTaskInformation: action.payload,
        assignedTaskInformationLoading: false,
      };
    case TaskActionType.GET_ASSIGNED_TASK_INFORMATION_FAIL:
      return {
        ...state,
        assignedTaskInformation: action.payload,
        assignedTaskInformationLoading: false,
      };
    case TaskActionType.GET_ASSIGNED_TASK_INFORMATION_RESET:
      return {
        ...state,
        assignedTaskInformation: {},
        assignedTaskInformationLoading: true,
      };
    case TaskActionType.COMPLETE_ALL_TASK_SUCCESS:
      return {
        ...state,
        completeAllTasksResponse: {
          status: "SUCCESS",
          message: action.payload?.message,
          data: action.payload,
        },
      };
    case TaskActionType.COMPLETE_ALL_TASK_FAIL:
      return {
        ...state,
        completeAllTasksResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TaskActionType.COMPLETE_ALL_TASK_RESET:
      return {
        ...state,
        completeAllTasksResponse: {
          status: "loading",
          message: "",
        },
      };
    case TaskActionType.DEFINED_TASK_UPDATE_SUCCESS:
      return {
        ...state,
        definedTaskUpdateStatusResponse: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case TaskActionType.DEFINED_TASK_UPDATE_FAIL:
      return {
        ...state,
        definedTaskUpdateStatusResponse: {
          status: "FAIL",
          data: action.payload,
        },
      };
    case TaskActionType.DEFINED_TASK_UPDATE_RESET:
      return {
        ...state,
        definedTaskUpdateStatusResponse: {
          status: "loading",
          data: "",
        },
      };
    case TaskActionType.DELETE_ASSIGNED_TASK_SUCCESS:
      return {
        ...state,
        deleteAssignedTaskResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TaskActionType.DELETE_ASSIGNED_TASK_FAIL:
      return {
        ...state,
        deleteAssignedTaskResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TaskActionType.DELETE_ASSIGNED_TASK_RESET:
      return {
        ...state,
        deleteAssignedTaskResponse: {
          status: "loading",
          message: "",
        },
      };
    case TaskActionType.TASK_STATES_FILTER_SUCCESS:
      return {
        ...state,
        filterAssignedTaskStatusResult: action.payload,
      };
    case TaskActionType.TASK_STATES_FILTER_FAIL:
      return {
        ...state,
        filterAssignedTaskStatusResult: { items: [] },
      };
    default:
      return state;
  }
}
