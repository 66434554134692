import "../../styles/subscription.scss";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Layout, Col, Tabs, Spin} from 'antd';
import { getSubscription } from '../../redux/actions/subscription.action';

import BreadCrump from '../../features/breadcrump/breadcrump';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';
import SubscriptionPayment from '../../component/subscription/SubscriptionPayment';
import PaymentHistory from '../../component/subscription/PaymentHistory';
import TryPaymentAgain from '../../component/subscription/TryPaymentAgain';
import CardUpdate from '../../component/subscription/CardUpdate';
import InvoiceList from '../../component/subscription/invoice-list';
import Forbidden from "../../component/forbidden";
import { signinSilent } from '../../services/userService'
const { Content } = Layout;
function Subscription({getSubscription,subscriptionResponse, user, permissions, isTryPaymentAgainLoading}) {

    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [currentTab, setCurrentTab] = useState('sub_package');
    const dispatch = useDispatch();
    const history = useHistory();

    const [isUpdateCreditCardModalOpen, setisUpdateCreditCardModalOpen] = useState({ 'isVisible': false });
    const [isTryPaymentAgainModalopen, setisTryPaymentAgainModalOpen] = useState({ 'isVisible': false });
    const [isPaymentHistoryModalOpen, setisPaymentHistoryModalOpen] = useState({ 'isVisible': false });
    const [isPaymentFailed, setisPaymentFailed] = useState(false);
    

    // Reject Modals
    const handleRejectCancelCreditCardModal = () => { setisUpdateCreditCardModalOpen({ 'isVisible': false })}
    const handleRejectTryPaymentAgainModal = () => { setisTryPaymentAgainModalOpen({ 'isVisible': false })}
    const handleRejectPaymentHistoryModal = () => { setisPaymentHistoryModalOpen({ 'isVisible': false })}

    // Open Modals
    const openCreditCardModal = () => { setisUpdateCreditCardModalOpen({ 'isVisible': true })}
    const openTryPaymentAgainModal = () => { setisTryPaymentAgainModalOpen({ 'isVisible': true })}
    const openPaymentHistroyModal = () => { setisPaymentHistoryModalOpen({ 'isVisible': true })}

    const callback = (key) => setCurrentTab(key);

    const closeTryPaymentOpenCardUpdateModal = () => {
        setisTryPaymentAgainModalOpen({ 'isVisible': false });
        setTimeout(() => {
            openCreditCardModal();
        }, 200);
    }
    const [prevIsTryPaymentAgainModalOpen, setPrevIsTryPaymentAgainModalOpen] = useState(false);

    useEffect(() => {
        if (prevIsTryPaymentAgainModalOpen && !isTryPaymentAgainModalopen.isVisible) {
            getSubscription(true/* resetLoadingStates */);
        }
        setPrevIsTryPaymentAgainModalOpen(isTryPaymentAgainModalopen.isVisible);
    }, [isTryPaymentAgainModalopen])

    useEffect(() => {
        if(isTryPaymentAgainLoading == 'success'){
            signinSilent(dispatch,history)
        }
       
    }, [isTryPaymentAgainLoading])
    

    // Check if payment is failed and remaining days are less than 15
    useEffect(() => {

        const trialExpireDate = new Date(user?.organizationEntryEndDate);
        const today = new Date();
        const diffDate = trialExpireDate - today;
        const remainingDay = Math.ceil(diffDate / 86400000);
       
        if(parseInt(user?.organizationPackageId) > 0 && remainingDay>-1 && remainingDay<16 && user?.organizationPaymentStatus == 2){
            setisPaymentFailed(true)
        }else{
            setisPaymentFailed(false)
        }
        }, [user])


    // Get Subscription Data on Page Init
    useEffect(() => { 
        if(parseInt(user?.organizationPackageId) > 0 && permissions.includes('payment')){
            getSubscription(); 
        }
    },[]);

    // Rerender Subscription Data after Credit Card Update
    const renderSubscription = () => {
        setisUpdateCreditCardModalOpen({ 'isVisible': false });
        getSubscription();
    }

    if(parseInt(user?.organizationPackageId) > 0 && permissions.includes('payment')){

        return (
            <Layout>
                {subscriptionResponse ? 
                   <Layout>
                   <Col span={24}>
                       <Content className="page-container">
                           <BreadCrump />
                           <div className="tab-page">
                               <h1 className="page-title">{t('SUBSCRIPTION')}</h1>
                               <Tabs className="pad-top-20 page-tabs" onChange={callback} defaultActiveKey="SUBSCRIPTION" activeKey={currentTab} >
    
                                   <TabPane tab={<span>{t('SUBSCRIPTION_PACKAGE_AND_PAYMENT_INFORMATION')}</span>} key="sub_package">
                                           <SubscriptionPayment 
                                           openCreditCardModal={openCreditCardModal}
                                           openTryPaymentAgainModal={openTryPaymentAgainModal}
                                           openPaymentHistroyModal={openPaymentHistroyModal}
                                           subscriptionResponse={subscriptionResponse}
                                           isPaymentFailed={isPaymentFailed}
                                           /> : 
                                   </TabPane>
    
                                   <TabPane tab={<span>{t('SUBSCRIPTION_INVOICE_INFORMATION')}</span>} key="SUBSCRIPTION_INVOICE_INFORMATION">
                                       <InvoiceList invoiceList={subscriptionResponse?.invoiceList ? subscriptionResponse?.invoiceList : [] } />
                                   </TabPane>
    
                               </Tabs>
                           </div>
                       </Content >
                   </Col>
               </Layout>
               : <Spin className="flex-center pad-top-75 " />
               }
                {isUpdateCreditCardModalOpen.isVisible && <DynamicRequestModal
                    key={'isCreditCardModal'}
                    modalVisible={isUpdateCreditCardModalOpen.isVisible}
                    setStateData={setisUpdateCreditCardModalOpen}
                    handleCancel={handleRejectCancelCreditCardModal}
                    hideButton={true}
                    isForm={<CardUpdate openTryPaymentAgainModal={openTryPaymentAgainModal} isPaymentFailed={isPaymentFailed} subscriptionResponse={subscriptionResponse} renderSubscription={renderSubscription}/>}
                    
                />}
                {isTryPaymentAgainModalopen.isVisible && <DynamicRequestModal
                    key={'isCreditCardModal'}
                    modalVisible={isTryPaymentAgainModalopen.isVisible}
                    setStateData={setisTryPaymentAgainModalOpen}
                    handleCancel={handleRejectTryPaymentAgainModal}
                    hideButton={true}
                    iconName={isTryPaymentAgainLoading == null ? 'UilInfoCircle' : isTryPaymentAgainLoading == 'loading' ? null : 
                    isTryPaymentAgainLoading == 'success' ? 'UilCheckCircle' : 'UilTimesCircle'}
                    isForm={<TryPaymentAgain subscriptionResponse={subscriptionResponse} handleRejectTryPaymentAgainModal={handleRejectTryPaymentAgainModal} closeTryPaymentOpenCardUpdateModal={closeTryPaymentOpenCardUpdateModal}/>}
                    iconColor={isTryPaymentAgainLoading == null ? '#FFE651' : isTryPaymentAgainLoading == 'loading' ? null : 
                    isTryPaymentAgainLoading == 'success' ? '#70C7C3' : '#FD766D'}
                />}
                {isPaymentHistoryModalOpen.isVisible && <DynamicRequestModal
                    key={'isCreditCardModal'}
                    modalVisible={isPaymentHistoryModalOpen.isVisible}
                    setStateData={setisPaymentHistoryModalOpen}
                    handleCancel={handleRejectPaymentHistoryModal}
                    hideButton={true}
                    isForm={<PaymentHistory handleRejectPaymentHistoryModal={handleRejectPaymentHistoryModal} subscriptionResponse={subscriptionResponse} />}
                    
                />}
            </Layout >
        )
    }else{
        return (<Forbidden size="sm" />)
    }
}


const mapStateToProps = (state) => {
    return {
        subscriptionResponse: state.subscription.subscriptionResponse,
        user: state.auth?.user?.profile,
        permissions: state.profile.permissions,
        isTryPaymentAgainLoading: state.subscription.isTryPaymentAgainLoading,

    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getSubscription: () => { dispatch(getSubscription()) },
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
