import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ownerShipPostReturn, ownerShipPostReturnReset } from '../../redux/actions/ownership.actions';
import { setOwnerShipReturnDrawer } from '../../redux/actions/ownership-drawer.action';

import { Form, Col, Button, Checkbox, ConfigProvider, Input, List, DatePicker } from 'antd';
import { dateFormat, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../utils/config';
import { focusDateInput, blurDateInput } from '../../utils/commonFormFunctions'
import { UilMultiply } from '@iconscout/react-unicons';
import DynamicToastMessage from '../dynamicToastMessage/index';

import moment from 'moment';
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir
import '../../styles/person.scss';

function OwnerShipReturn(props) {
    const { setOwnerShipReturnDrawer, selectedOwnerShip, profile, returnOwnerShipResponse,
        ownerShipPostReturn, ownerShipPostReturnReset, selectedOwnerShipAssigmentDate } = props;
    const [form] = Form.useForm()
    const { t } = useTranslation();
    const [returnState, setReturnState] = useState({})
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "returnDate": dateFormatList,
    })
    let currentDate = new Date();

    const handleValuesChange = (changedObj, all) => {
        setReturnState({ ...returnState, ...changedObj });
    }
    var data = {};

    const [isUpdateReturnLoading, setisUpdateReturnLoading] = useState(false);

    const handleUpdate = () => {

        if(isUpdateReturnLoading) return;
        setisUpdateReturnLoading(true);
        setTimeout(() => {setisUpdateReturnLoading(false);}, 1055) // double click prevent;

        for (var i = 0; i < selectedOwnerShip.length; i++) {
            data = {
                ...data, returnAssigmentItems: [{
                    resourceId: selectedOwnerShip[i],
                    returnDescription: returnState.returnDescription,
                    isMaintanenceNeeded: returnState.isMaintanenceNeeded === undefined ? false : returnState.isMaintanenceNeeded,
                    returnDate: returnState?.returnDate && returnState?.returnDate !== 'Invalid date' ? moment(returnState?.returnDate).format(dateFormat) : null,
                    returnPersonResourceId: profile.resourceId
                }]
            }
        }
        let hasError = false
        let fields = []
        if (moment(selectedOwnerShipAssigmentDate) > moment(data.returnAssigmentItems[0].returnDate)) {
            hasError = true
            fields.push(t('RETURN_DATE_OLDER_THAN_EMBEZZLEMENT'))
        }
        if (!data.returnAssigmentItems[0].returnDate) {
            hasError = true
            fields.push(t('DEBIT_THROUGH') + t('CAN_NOT_LEFT_BLANK'))
        }
        if (!hasError) {
            ownerShipPostReturn(data)
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    function destroyLastState() {
        setOwnerShipReturnDrawer(false)
        setReturnState({})
    }
    useEffect(() => {
        setReturnState({ ...returnState, 'returnDate': moment(currentDate).format(dateFormat) })
    }, []);
    useEffect(() => {
        if (returnOwnerShipResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'ownerShipReturn_success',
                'title': t('SUCCESS'),
                'description': returnOwnerShipResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            ownerShipPostReturnReset()
            setOwnerShipReturnDrawer(false)
            setReturnState({})
        } else if (returnOwnerShipResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'ownerShipReturn_error',
                'title': t('ERROR'),
                'description': returnOwnerShipResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            ownerShipPostReturnReset()
        }
    }, [returnOwnerShipResponse])
    return (
        <div className="table-details" >
            <div className="space-between w-100">
                <div className="add-global-draw-title flex-start">
                    <h1>{t('OWNERSHIP_REFUND_TITLE')}</h1>
                </div>
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => destroyLastState()} />
                </div>
            </div>
            <div className="w-100 pad-top-10">
                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    <Form
                        form={form}
                        onValuesChange={handleValuesChange}
                        layout="vertical"
                        requiredMark={true}
                    >
                        <Col span={12}>
                            <Form.Item name="returnDate" className="custom-select" label={datePickerFormatType.returnDate === dynamicDateInputFormatter ? t('PARTNER_RETURN_DATE') + " * " + t('PLACEHOLDER_DATE') : t('PARTNER_RETURN_DATE') + " *"}>
                                <DatePicker
                                    locale={localeLang}
                                    format={datePickerFormatType.returnDate}
                                    placeholder={t('PLACEHOLDER_DATE')}
                                    defaultValue={moment(currentDate, 'DD-MM-YYYY')}
                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'returnDate', dynamicDateInputFormatter)}
                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'returnDate', dateFormatList)}
                                />
                            </Form.Item>
                            <Form.Item name="returnDescription" label={t('DEBIT_RETURN_DESCRIPTION')}>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name="isMaintanenceNeeded" label="" valuePropName="checked">
                                <Checkbox >{t('DEBIT_MAINTANENCE_NEEDED')}</Checkbox>
                            </Form.Item>
                        </Col>
                        <div className="fixed-btn">
                            <div className="page-end">
                                <div className="hr-end"></div>
                            </div>

                            <div className="form-submit-space-between">
                                <Button onClick={() => destroyLastState()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                                <div className="btn-padding" />
                                <Button onClick={handleUpdate} className="primary-btn w-180 h-40 f-14">{t('PARTNER_GET_A_REFUND')}</Button>

                            </div>
                        </div>
                    </Form>
                </ConfigProvider>
            </div>
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        returnOwnerShipResponse: state.ownership.returnOwnerShipResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setOwnerShipReturnDrawer: (props) => { dispatch(setOwnerShipReturnDrawer(props)) },
        ownerShipPostReturn: (data) => { dispatch(ownerShipPostReturn(data)) },
        ownerShipPostReturnReset: () => { dispatch(ownerShipPostReturnReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OwnerShipReturn);