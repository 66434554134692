import { SET_USER_TASK_INFO, SET_CURRENT_TASK_STATE, CALL_ONBOARDING, CALL_ONBOARDING_SUCCESS, CALL_ONBOARDING_FAIL  } from '../actions/onboarding.actions';

// Initial State
const initialState = {
  userTaskInfo : {
        activeTaskPackage : 1,
        percentage : 0,
        completedTasks : [],//backendden array gelecek [0,1,2,3] 
                            // bir kullanıcı ilk defa giriyorsa [] modalı gördüğü anda [0] olacak ve 0 . görevi tamamlanacak
        initialized : false,
        onboardingLoading: false,
        onboardingError: null,
  },
  currentTaskState: null, // başlangıçta her zaman null 1-2-3-4-5-6 // Görev başlangıcı 10-20-30
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TASK_INFO:
      return {
        ...state,
        userTaskInfo: action.userTaskInfo
      };
    case SET_CURRENT_TASK_STATE:
      return {
        ...state,
        currentTaskState: action.currentTaskState
      };
    case CALL_ONBOARDING:
      return {
        ...state,
        onboardingLoading: true,
        onboardingError: null
      };
    case CALL_ONBOARDING_SUCCESS:
      return {
        ...state,
        userTaskInfo: {
          ...state.userTaskInfo,
          ...action.payload,
          initialized: true,
        }
      };
    case CALL_ONBOARDING_FAIL:
      return {
        ...state,
        onboardingLoading: false,
        onboardingError: action.payload
      };
    default:
      return state;
  }
};


export default onboardingReducer;
