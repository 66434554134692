import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";

import { Form, Input, Col, Row, ConfigProvider } from "antd";
import DynamicSelectbox from "../../dynamicSelectbox/index";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";

import { phoneInputConfig } from '../../../utils/config';

import { getDataset } from "../../../redux/actions/dataset-action";
import { getParentOrganizations } from "../../../redux/actions/organization.actions";
import { compareObjects, phoneExtMask } from "../../../utils/commonFormFunctions";

function OrganizationBasicInfo(props) {
  const { t } = useTranslation();
  const [organizationBasicinfoState, setOrganizationBasicinfoState] = useState({
    organizationType: { id: null },
    parentOrganization: { id: null },
    sector: { id: null },
    email: "",
  });
  const [form] = Form.useForm();
  const { organizationDetailsInfo, setOrganizationUpdateObj, getDataset, dataset } = props;
  const [sectorList, setSectorList] = useState([{ ...organizationDetailsInfo.sector }]);
  const [organizationTypeList, setOrganizationTypeList] = useState([{ ...organizationDetailsInfo.organizationType }]);

  useEffect(() => {
    setOrganizationBasicinfoState(organizationDetailsInfo);
    form.setFieldsValue({
      organizationType: organizationDetailsInfo.organizationType,
      name: organizationDetailsInfo.name,
      legalName: organizationDetailsInfo.legalName,
      parentOrganization: organizationDetailsInfo.parentOrganization,
      sector: organizationDetailsInfo.sector,
      telephone: organizationDetailsInfo.telephone ?? (localStorage.getItem("i18nLang") === "tr-TR" ? '90' : '44'),
      phoneExt: organizationDetailsInfo.phoneExt,
      email: organizationDetailsInfo.email,
      pageUrl: organizationDetailsInfo.pageUrl,
    });
  }, [form, organizationDetailsInfo]);

  useEffect(() => {
    setOrganizationUpdateObj(organizationBasicinfoState);
  }, [organizationBasicinfoState]);

  useEffect(() => {
    setSectorList(dataset.sectorTypes);
  }, [dataset.sectorTypes]);

  useEffect(() => {
    setOrganizationTypeList(dataset.organizationTypes);
  }, [dataset.organizationTypes]);

  const handleValuesChange = (changedObj, all) => {
    setOrganizationBasicinfoState({ ...organizationBasicinfoState, ...changedObj });
  };

  return (
    <ConfigProvider getPopupContainer={(trigger) => trigger.parentElement}>
      <Form form={form} onValuesChange={handleValuesChange} layout="vertical" requiredMark={false}>
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <div className="form-left-body">
              <Form.Item name="organizationType" label={t("ORGANIZATION_ORGANIZATION_TYPE") + "*"} className="custom-select" required>
                <DynamicSelectbox optionList={organizationTypeList} onFocus={() => getDataset(19)} className={!compareObjects(organizationDetailsInfo.organizationType, organizationBasicinfoState.organizationType) ? "is-changed" : ""} placeholder={t("PARTNER_SELECT")} orderBy={"none"} />
              </Form.Item>
              <Form.Item name="name" label={t("ORGANIZATION_NAME") + "*"} required>
                <Input className={organizationDetailsInfo.name !== organizationBasicinfoState.name ? "is-changed" : ""} />
              </Form.Item>
              <Form.Item name="legalName" label={t("ORGANIZATION_LEGAL_NAME") + "*"} required>
                <Input className={organizationDetailsInfo.legalName !== organizationBasicinfoState.legalName ? "is-changed" : ""} />
              </Form.Item>
              <Form.Item name="sector" label={t("ORGANIZATION_SECTOR")} className="custom-select">
                <DynamicSelectbox optionList={sectorList} onFocus={() => getDataset(21)} className={!compareObjects(organizationDetailsInfo.sector, organizationBasicinfoState.sector) ? "is-changed" : ""} placeholder={t("PARTNER_SELECT")} orderBy={"none"} />
              </Form.Item>
            </div>
            <div className="pad-100" />
          </Col>
          <Col span={12}>
            <div className="form-right-body">
              <Row>
                <Col span={16}>
                  <Form.Item name="telephone" label={t("ORGANIZATION_PHONE")}>
                    <PhoneInput
                      containerClass={!compareObjects(organizationDetailsInfo.telephone, organizationBasicinfoState.telephone) ? "is-changed" : ""}
                      {...phoneInputConfig}
                      placeholder={t("ENTER_PHONE_NUMBER")}
                      inputProps={{
                        name: "telephone",
                        required: true,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col offset={1} span={7}>
                  <Form.Item name="phoneExt" label={t("ORGANIZATION_PHONE_EXT")}>
                    <MaskedInput className={organizationBasicinfoState.phoneExt !== "" ? (!compareObjects(organizationDetailsInfo.phoneExt, organizationBasicinfoState.phoneExt) ? "is-changed ant-input" : "ant-input") : "ant-input"} mask={phoneExtMask} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="email" label={t("ORGANIZATION_MAIL")}>
                <MaskedInput className={!compareObjects(organizationDetailsInfo.email, organizationBasicinfoState.email) ? "is-changed ant-input" : "ant-input"} mask={emailMask} />
              </Form.Item>
              <Form.Item name="pageUrl" label={t("ORGANIZATION_WEB")}>
                <Input className={!compareObjects(organizationDetailsInfo.pageUrl, organizationBasicinfoState.pageUrl) ? "is-changed" : ""} />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </ConfigProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    dataset: state.dataset,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataset: (props) => { dispatch(getDataset(props)); },
    getParentOrganizations: (resourceId) => { dispatch(getParentOrganizations(resourceId)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationBasicInfo);
