import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    setTravelRequestCreateDrawer, setTravelDetailsDrawer, getMyTravelRequests, deleteTravelActionReset,
    getTravelApprovalRequests, getAllTravelRequests, resetTravelChangeStatus, updateTravelRequestReset
} from '../../redux/actions/travel.actions';

import { Layout, Tabs, Drawer } from 'antd';
import { ToastContainer } from 'react-toastify';

import DynamicToastMessage from '../../component/dynamicToastMessage';
import BreadCrumb from '../../features/breadcrump/breadcrump';

import TravelRequests from '../../component/travelRequests/travel_requests';
import ApprovalTravelRequests from '../../component/travelRequests/travel_approval_requests';
import AllTravelRequests from '../../component/travelRequests/all_travel_requests';

import CreateTravelRequestTabs from '../../component/travelRequests/add/create_travel_all_tabs';
import TravelDetailTabs from '../../component/travelRequests/details/travel_detail_tabs';
function Travel(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const myTravelRequestInitialSearch = {
        "SearchTerm": "",
        "ExpensesStateType": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const travelApprovalInitialSearch = {
        "SearchTerm": "",
        "ExpensesStateType": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const allTravelRequestsInitialSearch = {
        "SearchTerm": "",
        "ExpensesStateType": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const { travelRequestDrawer, setTravelRequestCreateDrawer, getMyTravelRequests, permissions, setTravelDetailsDrawer,
        updateTravelResponse, travelDeleteStatusResponse, getTravelApprovalRequests, getAllTravelRequests,
        travelChangeStatusResponse, resetTravelChangeStatus, detailsDrawer, updateTravelRequestReset, deleteTravelActionReset } = props;
    const [currentTab, setCurrentTab] = useState('TravelRequests');
    const [createTravelRequestKey, setCreateTravelRequestKey] = useState(1);
    const [detailsTravelKey, setDetailsTravelKey] = useState(100);
    const [myTravelRequestSearchState, setMyTravelRequestSearchState] = useState(myTravelRequestInitialSearch);
    const [travelApprovalSearchState, setTravelApprovalSearchState] = useState(travelApprovalInitialSearch);
    const [allTravelRequestsSearchState, setAllTravelRequestsSearchState] = useState(allTravelRequestsInitialSearch);
    const [selectTravelRequest, setSelectTravelRequest] = useState(null);

    function callback(key) {
        setCurrentTab(key);
    }
    const openCreateTravelRequestDrawer = () => {
        setTravelRequestCreateDrawer(true)
        setCreateTravelRequestKey((i) => i + 1)
    }
    const openTravelDetailsDrawer = () => {
        setTravelDetailsDrawer(true)
        setDetailsTravelKey((i) => i + 1)
    }
    useEffect(() => {
        if (currentTab === "TravelRequests") {
            getMyTravelRequests(myTravelRequestSearchState);
        } else if (currentTab === "TravelApprovalRequests") {
            getTravelApprovalRequests(travelApprovalSearchState);
        } else if (currentTab === "AllTravelRequests") {
            getAllTravelRequests(allTravelRequestsSearchState);
        }
    }, [currentTab])
    useEffect(() => {
        if (!detailsDrawer) {
            if (travelChangeStatusResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': travelChangeStatusResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                resetTravelChangeStatus()
            }
            else if (travelChangeStatusResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': travelChangeStatusResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                resetTravelChangeStatus()
            }
        } else {
            if (travelChangeStatusResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [travelChangeStatusResponse])
    useEffect(() => {
        if (!detailsDrawer) {
            if (updateTravelResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': updateTravelResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                updateTravelRequestReset()
            }
            else if (updateTravelResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': updateTravelResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                updateTravelRequestReset()
            }
        } else {
            if (updateTravelResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [updateTravelResponse])
    useEffect(() => {
        if (travelDeleteStatusResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': travelDeleteStatusResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            updateTable();
            deleteTravelActionReset()
        }
        else if (travelDeleteStatusResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': travelDeleteStatusResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTravelActionReset()
        }
    }, [travelDeleteStatusResponse])

    const updateTable = () => {
        if (currentTab === "TravelRequests") {
            getMyTravelRequests(myTravelRequestSearchState);
        } else if (currentTab === "TravelApprovalRequests") {
            getTravelApprovalRequests(travelApprovalSearchState);
        } else if (currentTab === "AllTravelRequests") {
            getAllTravelRequests(allTravelRequestsSearchState);
        }
    }
    return (
        <React.Fragment>
            <Layout>
                <Drawer key={createTravelRequestKey} title={false} width={1280} closable={false} onClose={false} placement="right" visible={travelRequestDrawer} >
                    <CreateTravelRequestTabs />
                </Drawer >
                <Drawer key={detailsTravelKey} title={false} width={1100} closable={false} onClose={false} placement="right" visible={detailsDrawer} >
                    <TravelDetailTabs selectTravelRequest={selectTravelRequest} />
                </Drawer >
                <Content className="page-container">
                    <BreadCrumb />
                    <div className="tab-menu ">
                        <h1 className="page-title">{t('TRAVEL_TRANSACTIONS')}</h1>
                        <Tabs
                            className="pad-top-20 page-tabs"
                            onChange={callback}
                            defaultActiveKey="TravelRequests"
                            activeKey={currentTab}
                        >
                            <TabPane tab={<span>{t('MY_TRAVEL_REQUESTS')}</span>} key="TravelRequests">
                                <TravelRequests
                                    openTravelDetailsDrawer={openTravelDetailsDrawer} openCreateTravelRequestDrawer={openCreateTravelRequestDrawer}
                                    myTravelRequestInitialSearch={myTravelRequestInitialSearch}
                                    myTravelRequestSearchState={myTravelRequestSearchState}
                                    setMyTravelRequestSearchState={setMyTravelRequestSearchState}
                                    setSelectTravelRequest={setSelectTravelRequest}
                                />
                            </TabPane>
                            <TabPane tab={<span>{t('TRAVEL_APPROVALS')}</span>} key="TravelApprovalRequests">
                                <ApprovalTravelRequests
                                    openTravelDetailsDrawer={openTravelDetailsDrawer} openCreateTravelRequestDrawer={openCreateTravelRequestDrawer}
                                    travelApprovalInitialSearch={travelApprovalInitialSearch}
                                    travelApprovalSearchState={travelApprovalSearchState}
                                    setTravelApprovalSearchState={setTravelApprovalSearchState}
                                    setSelectTravelRequest={setSelectTravelRequest}
                                    currentTab={currentTab}
                                />
                            </TabPane>
                            {permissions.includes("traveldefinition.list.all") && (
                                <TabPane tab={<span>{t('ALL_TRAVEL_REQUESTS')}</span>} key="AllTravelRequests">
                                    <AllTravelRequests
                                        openTravelDetailsDrawer={openTravelDetailsDrawer}
                                        allTravelRequestsInitialSearch={allTravelRequestsInitialSearch}
                                        allTravelRequestsSearchState={allTravelRequestsSearchState}
                                        setAllTravelRequestsSearchState={setAllTravelRequestsSearchState}
                                        setSelectTravelRequest={setSelectTravelRequest}
                                        currentTab={currentTab}
                                    />
                                </TabPane>
                            )}
                        </Tabs>
                    </div>
                </Content>
            </Layout>
            <ToastContainer />
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        detailsDrawer: state.travel.travelDetailDrawer,
        travelRequestDrawer: state.travel.travelRequestDrawer,
        profile: state.profile,
        permissions: state.profile.permissions,
        travelChangeStatusResponse: state.travel.travelChangeStatusResponse,
        updateTravelResponse: state.travel.updateTravelResponse,
        travelDeleteStatusResponse: state.travel.travelDeleteStatusResponse,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTravelRequestCreateDrawer: (status) => { dispatch(setTravelRequestCreateDrawer(status)) },
        setTravelDetailsDrawer: (status) => { dispatch(setTravelDetailsDrawer(status)) },
        getMyTravelRequests: (searchData) => { dispatch(getMyTravelRequests(searchData)) },
        getTravelApprovalRequests: (searchData) => { dispatch(getTravelApprovalRequests(searchData)) },
        getAllTravelRequests: (searchData) => { dispatch(getAllTravelRequests(searchData)) },
        resetTravelChangeStatus: () => { dispatch(resetTravelChangeStatus()) },
        updateTravelRequestReset: () => { dispatch(updateTravelRequestReset()) },
        deleteTravelActionReset: () => { dispatch(deleteTravelActionReset()) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Travel);