import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { UilCopyright } from '@iconscout/react-unicons'

const { Footer } = Layout;

const HrFooter = () => {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <Footer>
                <div className="footer-wrap-text">
                    {/* <Link to="/help">{t('HELP')}</Link>
                    <Link to="/privacy">{t('PRIVACY')}</Link>
                    <Link to="/contact">{t('PARTNER_CONTACT')}</Link> */}
                </div>
                <div className="footer-center-text">
                    <h1>HRplan</h1>
                    <h2><UilCopyright size="15" /> {t('COPYRIGHT')}</h2>
                </div>
            </Footer>
        </div>
    )
}
export default HrFooter;