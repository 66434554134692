import React, { useEffect } from 'react'
import { signinSilentCallback ,loadUserFromStorage } from '../../services/userService'
import { useHistory } from 'react-router-dom'
import { useDispatch} from "react-redux";
import { Spin} from 'antd'
function SilentRenew() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    async function signinAsync() {
      await signinSilentCallback()
      // await loadUserFromStorage(dispatch,history);
    }
    signinAsync()

  }, [history])

  return (
    <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"100vh"}}>
      <Spin/>
    </div>
  )
}

export default SilentRenew