import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';


import { Form, Input, Radio } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import '../../../styles/person.scss';
import { useTranslation } from 'react-i18next';
function AddInventoryBasicInfo(props) {
    const { t } = useTranslation();
    const [dynamicData, setDynamicData] = useState({});
    const inputRef = useRef([]);
    const { setCreateObject, inventoryAttribute } = props;
    const isAccessoryMap = inventoryAttribute?.attributeItems.map((data) => {
        if (data.attributeItemId === 78 || data.attributeItemId === 79)
            return true
        else
            return false
    });
    const isAccessoryType = isAccessoryMap[0];

    const handleValuesChange = (event, item) => {
        let value = '', attributeItemValueId = null;
        if (item.fieldType === 4) {
            value = event.value;
            attributeItemValueId = event.attributeItemValueId;
        } else if (item.fieldType === 5) {
            const selectedOption = item.inventoryAttributeItemValues.filter(opt => opt.attributeItemValueId === event.target.value)[0];
            value = selectedOption ? selectedOption.value : '';
            attributeItemValueId = event.target.value;
        } else if (item.fieldType === 1) {
            value = event.target.value;
            setTimeout(() => {
                inputRef.current[item.attributeItemId].focus();
            }, 0);
        }
        if (dynamicData[item.attributeItemId]) {
            if (value === '') {
                const temp = { ...dynamicData };
                delete temp[item.attributeItemId]
                setDynamicData(temp)
            } else {
                setDynamicData({
                    ...dynamicData,
                    [item.attributeItemId]: value ? {
                        attributeItemValueId: attributeItemValueId,
                        value: value,
                        item: item,
                    } : null,
                });
            }
        } else {
            setDynamicData({
                ...dynamicData,
                [item.attributeItemId]: value ? {
                    attributeItemValueId: attributeItemValueId,
                    value: value,
                    item: item,
                } : null,
            });
        }
    }

    useEffect(() => {
        setCreateObject(dynamicData);
    }, [dynamicData]);

    function GetInputByType(props) {
        const item = props.item;
        if (item.fieldType === 4) {
            return <DynamicSelectbox
                value={dynamicData[item.attributeItemId]}
                onChange={(e) => handleValuesChange(e, item)}
                optionList={item.inventoryAttributeItemValues}
                fields={{ "id": "attributeItemValueId", "displayValue": "value" }}
            />
        } else if (item.fieldType === 1) {
            return <Input
                ref={el => inputRef.current[item.attributeItemId] = el}
                value={dynamicData[item.attributeItemId]?.value}
                onChange={(e) => handleValuesChange(e, item)}
            />
        } else if (item.fieldType === 5) {
            return <Radio.Group onChange={(e) => handleValuesChange(e, item)} value={dynamicData[item.attributeItemId]?.attributeItemValueId}>
                {
                    item.inventoryAttributeItemValues.map(option => {
                        return <Radio value={option.attributeItemValueId} >{option.value}</Radio>
                    })
                }
            </Radio.Group>
        } else {
            return 'type not found';
        }
    }
    const mapFormItem = (item) => {
        if (isAccessoryType) {
            return (
                <Form.Item key={item.attributeId} className="custom-select" name={item.attributeId} label={item.name + ' *'}>
                    <GetInputByType item={item} />
                </Form.Item>
            );
        } else {
            return (
                <Form.Item key={item.attributeId} className="custom-select" name={item.attributeId} label={item.name}>
                    <GetInputByType item={item} />
                </Form.Item>
            );
        }

    };
    return (
        <Form layout="vertical">
            {
                inventoryAttribute?.attributeItems && inventoryAttribute?.attributeItems.length > 0
                    ? inventoryAttribute.attributeItems.map(mapFormItem) 
                    : t("NO_ACCESSORY_TYPE_AVAILABLE")
            }
        </Form>
    );
    
}

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryBasicInfo);