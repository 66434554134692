import React, { useEffect, useState } from 'react';
import { connect , useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";

import { Card, Typography, Button, Divider, Row, Col, Image, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';

import { getPaymentCompleted } from '../../redux/actions/checkout.actions';
import { signinSilent } from '../../services/userService'


const { Title, Text } = Typography;

const PaymentComplated = (props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const transactionId = props.match.params.transactionId;

  const { getPaymentCompleted, paymentCompleted , paymentCompletedLoading} = props;

  const [paymentDetails, setPaymentDetails] = useState({})

  const handleDownload = () => {
    const url = paymentDetails?.receiptUrl ;
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  useEffect(()=>{
    getPaymentCompleted(transactionId)
  },[transactionId])

  useEffect(()=>{
    paymentCompleted && setPaymentDetails(paymentCompleted);
    window.sendinblue?.track(
      //brevo_event_payment_complated
      'payment_completed',
    )
  },[paymentCompleted])

  useEffect(()=>{
    signinSilent(dispatch,history);
  },[])

  return (
    <Row>
      <Col xs={24} sm={12} md={10} lg={8} xl={6}
       style={{margin:'20px auto', textAlign: 'center',}} >
      {!paymentCompletedLoading && paymentCompleted ? (<Card
        style={{  borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
        headStyle={{  borderRadius: '10px 10px 0 0' }}
        title={<Title level={4}>{t("SUCCESS_PAYMENT")}</Title>}
        >
          <CheckCircleOutlined style={{ fontSize: '24px', color: 'green' }} />
          <Text style={{ display: 'block', margin: '10px 0' }}>{t("OPERATION_NUMBER")} {paymentDetails.paymentNumber}</Text>
          <Divider dashed />
          <div className="payment-completed-wrapper">
            <div><Text strong>{t("SELECTED_PACKAGE")}</Text> {paymentDetails.packageName}</div>
            <div><Text strong>{t("TYPE_OF_PAYMENT")}</Text> {paymentDetails.paymentTypeName}</div>

            <div><Text strong>{t("AMOUNT_PAID")}</Text> 
                {paymentDetails?.currencyId == 1 
                  ? paymentDetails.amount?.toLocaleString('tr-TR')+' ₺' 
                  : '$'+paymentDetails.amount?.toLocaleString('en-US').replace(',' , '')}
            </div>
          </div>
          <Divider dashed />

          <Link to="/homepage">
            <Button className="primary-btn" block style={{ margin: '10px 0' }}>
            {t("EXPLORE_APPLICATION")}
            </Button>
          </Link>
          <Button className="white-btn" block onClick={handleDownload}>
           {t("VIEW_RECEIPT")}
          </Button>
        </Card>
        ): (!paymentCompleted && !paymentCompletedLoading) ? (
          <Card
          style={{  borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', height:'377px' }}
          headStyle={{  borderRadius: '10px 10px 0 0' }}
          >
            <div className="table-empty-container">
                <div className="table-empty-image">
                    <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
                </div>
                <div className="table-empty-text">
                    <h1>{t('RESULT_NOT_FOUND')}</h1>
                </div>
            </div>
          </Card>
        ): (
          <Card
          style={{  borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', height:'377px' }}
          headStyle={{  borderRadius: '10px 10px 0 0' }}
          >
            <Spin/>
          </Card>
        )}
      </Col>
    </Row> 
    
  );
};

const mapStateToProps = (state) => {
  return {
    paymentCompleted : state.checkout.paymentCompleted,
    paymentCompletedLoading: state.checkout.paymentCompletedLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentCompleted: (props) => { dispatch(getPaymentCompleted(props)) }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentComplated);
