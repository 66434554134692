import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPersonEducationVideos, resetPersonEducationVideos } from '../../redux/actions/education.actions';

import { Layout, Row, Col, Card, Image } from 'antd';
import BreadCrumb from '../../features/breadcrump/breadcrump';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import { ToastContainer } from 'react-toastify';
import { EducationDynamicTag } from '../../utils/config';
import Forbidden from "../../component/forbidden";
import '../../styles/education.scss';

function EducationMe(props) {
    const { permissions, personEducationVideoList, personEducationVideoListLoading, getPersonEducationVideos,
        resetPersonEducationVideos } = props;
    const { t } = useTranslation();
    const { Content } = Layout;
    const history = useHistory();

    const goVideo = (resourceId) => {
        return history.push("me/details/" + resourceId);
    }
    useEffect(() => {
        getPersonEducationVideos();
    }, [])
    useEffect(() => {
        if (personEducationVideoList?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': personEducationVideoList.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetPersonEducationVideos();
        }
    }, [personEducationVideoList])
    return (
        permissions.some(item => ["education.list", "education.add", "education.delete", "education.update"].includes(item)) ?
        <>
            <Layout>
                <Content className="page-container education-container">
                    <BreadCrumb />
                    <div className="tab-page pb-4">
                        <h1 className="page-title">{t('MY_TRAININGS')}</h1>
                    </div>
                    {
                        !personEducationVideoListLoading &&
                        <div className='d-flex w-100 pb-7'>
                            <Row className="w-100" gutter={[32, 32]}>
                                {personEducationVideoList.map((data, index) => {
                                    return <Col key={index} xs={24} sm={12} md={12} lg={12} xl={8} xxl={6} >
                                        <Card
                                            onClick={() => data?.state == 16 ? {} : goVideo(data.resourceId)}
                                            className="education-me-card"
                                            cover={<Image preview={false} className="" alt={data.educationName} src={data.viewPhoto} height={300} />}
                                            hoverable
                                        >
                                            <Card.Meta title={data.educationName} />
                                            {data?.state &&
                                                <EducationDynamicTag statusId={data?.state} t={t} />
                                            }
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                        </div>
                    }
                    {
                        personEducationVideoList.length <= 0 &&
                        <div style={{
                            background: '#fff'
                        }} className="table-empty-container mt-3">
                            <div className="table-empty-image">
                                <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
                            </div>
                            <div className="table-empty-text">
                                <h1>{t('NO_DATA_TEXT')}</h1>
                            </div>
                        </div>
                    }
                </Content>
            </Layout>
            <ToastContainer />
        </>
        : <Forbidden size="sm" />
    )
}
const mapStateToProps = (state) => {
    return {
        permissions: state.profile.permissions,
        personEducationVideoList: state.education.personEducationVideoList,
        personEducationVideoListLoading: state.education.personEducationVideoListLoading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPersonEducationVideos: () => { dispatch(getPersonEducationVideos()) },
        resetPersonEducationVideos: () => { dispatch(resetPersonEducationVideos()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EducationMe);