const initialState = {
    expenseType: [],
    loading: true,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {

        case 'GET_EXPENSE_TYPE':
            return {
                ...state,
                expenseType: action.payload,
                loading: false,

            }

        default: return state
    }

}