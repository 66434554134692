import React, { useEffect, useState } from "react";
import {
  getEducationInformation, educationCreateSection, resetEducationCreateSection,
  resetEducationInformation, educationUpdateSection, resetEducationUpdateSection,
  educationDeleteSection, resetEducationDeleteSection, educationDeleteVideoSection,
  resetEducationDeleteVideoSection, educationAddSubscriber, resetEducationAddSubscriber,
  educationDeleteSubscriberRemove, resetEducationDeleteSubscriberRemove,educationVideoSortUpdate,
  resetEducationVideoSortUpdate, bulkUpdateEducation, bulkDeleteEducation
} from '../../redux/actions/education.actions';
import { getWorkHourDepartmentInPersons } from '../../redux/actions/organization.actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hrApi } from "../../utils/apiConfig";

import {
  Button, Tabs, Form, Input, Switch, Card, Drawer, DatePicker, Table, Space, Upload, Spin,TreeSelect
} from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { UploadOutlined} from '@ant-design/icons';
import { randomNumberInRange,customFilter, compareObjects } from '../../utils/commonFormFunctions';
import DynamicEducationInlineModal from './education-inline';
import DynamicEducationInlineEditModal from './education-inline-edit';
import DynamicEducationVideoModal from './education-video';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { UilPlusCircle, UilPen, UilTrashAlt, UilMultiply, UilDraggabledots, UilEditAlt } from '@iconscout/react-unicons';
import { apiUrl, localeLang, educationDateFormatter, educationShortDateFormatter, educationDateWithTimeFormatter } from '../../utils/config';
import moment from 'moment';


function DynamicEducationModal(props) {
  const {
    openState = null, allPersons, educationInformation, editResourceId, keyboard = true, modalVisible = null, modalWidth = null, setStateData = null,
    isForm = null, handleCancel = null, maskClosable = true, getEducationInformation,
    educationInformationLoading, educationCreateSection, resetEducationCreateSection, educationCreateSectionResponse,
    resetEducationInformation, educationUpdateSection, resetEducationUpdateSection, educationUpdateSectionResponse, educationDeleteSectionResponse,
    educationDeleteSection, resetEducationDeleteSection, educationDeleteVideoSectionResponse, educationDeleteVideoSection, resetEducationDeleteVideoSection,
    educationAddSubscriber, resetEducationAddSubscriber, educationAddSubscriberResponse, educationSubscriberRemoveResponse, educationDeleteSubscriberRemove,
    resetEducationDeleteSubscriberRemove,workHourDepartmentInPerson,workHourDepartmentInPersonLoading,getWorkHourDepartmentInPersons,educationVideoSortUpdate,
    educationVideoSortUpdateResponse,resetEducationVideoSortUpdate,reloadList, bulkUpdateEducation, bulkDeleteEducation, bulkUpdate, bulkDelete } = props;
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('1')
  const [inlineModalView, setInlineModalView] = useState(false)
  const [inlineModalViewEdit, setInlineModalViewEdit] = useState(false)
  const [stepList, setStepList] = useState([])
  const [subVideos, setSubVideos] = useState([])
  const [personData, setPersonData] = useState([])
  const [tableData, setTableData] = useState([]);
  const [editIndex, setEditIndex] = useState('');
  const [editData, setEditData] = useState('');
  const [editedSection, setEditedSection] = useState('');
  const [modals, setModals] = useState({});
  const [loader, setLoader] = useState(false);
  const [personTreeData, setPersonTreeData] = useState([]);
  const [changed,setChanged] = useState(false);
  const [participantsState, setParticipantsState] = useState({
    'selectedPerson': [],
    'startDate': null,
    'endDate': null
  })
  const [educationInformationState, setEducationInformationState] = useState({
    'activeEducation': null,
    'educationName': null,
    'educatorName': null,
    'description': null,
    'file': [],
  })

  // Bulk edit
  const [initialTableData, setInitialTableData] = useState([]);

  const { SHOW_CHILD } = TreeSelect;

  useEffect(() => {
    setCurrentTab(openState)
  }, [openState])

  const closeAllDrawer = () => {
    if(selectedRowKeys?.length > 0){
      saveUpdatedTableData();
    }

    if(changed){
      reloadList();
    }
    handleCancel();
    setCurrentTab('1')
    setEducationInformationState({
      'activeEducation': null,
      'educationName': null,
      'educatorName': null,
      'description': null,
      'file': []
    });
    setInlineModalView(false)
    setInlineModalViewEdit(false)
    setStepList([])
    setSubVideos([])
    setEditIndex('')
    setEditData('')
    setEditedSection('')
    setModals({});
    setPersonData([])
    setParticipantsState({
      'selectedPerson': [],
      'startDate': null,
      'endDate': null
    })
    setTableData([]);
    setInitialTableData([]);
    setChanged(false);
  }
  const getDetail = () => {
    setStepList([]);
    setSubVideos([])
    setTableData([])
    setInitialTableData([]);
    getEducationInformation(editResourceId);
  }

  useEffect(() => {
    if (educationInformation?.educationName != null && !educationInformationLoading) {
      setEducationInformationState({
        'activeEducation': educationInformation.isActive,
        'educatorName': educationInformation.educatorName,
        'educationName': educationInformation.educationName,
        'description': educationInformation.description,
        'file': []
      });

      educationInformation.sectionInformations.map((item, index) => {
        setStepList((prevArray) => [...prevArray, {
          name: item.sectionName,
          resourceId: item.sectionResourceId
        }]);

        item.contentInformation.map((subItem, i) => {
          const subVideoData = {
            imgList: '',
            videoList: '',
            videoName: subItem.videoName,
            videoDesc: '',
            index: index,
            resourceId: subItem.contentResourceId,
            videoData : subItem,
          }
          setSubVideos((prevArray) => [...prevArray, subVideoData]);
        })
      })
      educationInformation.subscribers.map((sub, index) => {
        const data = {
          key:index,
          subscriberId: sub.id,
          personId : sub.personId,
          name: sub.personName,
          startedDate: sub.startDate ? moment(sub.startDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : null,
          endDate: sub.endDate ? moment(sub.endDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : null,
          resourceId: sub.resourceId
        }
        setTableData((prevArray) => [...prevArray, data])
        setInitialTableData((prevArray) => [...prevArray, data]);
        
      });
    }
    setSelectedRowKeys([])

  }, [educationInformation, educationInformationLoading])

  const nextPage = (tabNumber) => {
    if (tabNumber == '3') {
      if (stepList.length <= 0) {
        DynamicToastMessage({
          'key': 'error_key',
          'title': t('ERROR'),
          'description': t('MANDATORY_TO_CREATE_TRAINING_ERROR'),
          'statusType': 'error',
          'position': 'bottom-right'
        });
        return false
      }
      if (subVideos.length <= 0) {
        DynamicToastMessage({
          'key': 'error_key',
          'title': t('ERROR'),
          'description': t('ADD_AT_LEAST_ONE_VIDEO_ERROR'),
          'statusType': 'error',
          'position': 'bottom-right'
        });
        return false
      }
    }
    if (educationInformationState?.educationName == '') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('EDUCATION_MAIN_TITLE_ERROR'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false
    }
    if (educationInformationState?.description == '') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('EDUCATION_MAIN_DESCRIPTION_ERROR'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false
    }
    setCurrentTab(tabNumber)
    return true
  }

  const openInlineModal = () => {
    setInlineModalView(true)
  }

  const handleCancelInlineModal = () => {
    setInlineModalView(false)
  }
  const openInlineModalEdit = (index, resourceId) => {
    setEditIndex(index)
    setEditData(stepList[index].name)
    setInlineModalViewEdit(true)
    setEditedSection(resourceId)
  }

  const handleCancelInlineModalEdit = () => {
    setInlineModalViewEdit(false)
  }

  const addDataList = (data) => {
    //setLoader(true)
    setStepList((prevArray) => [...prevArray, {
      name: data
    }]);
    const dataUpdate = {
      "educationResourceId": editResourceId,
      "sectionName": data
    }
    educationCreateSection(dataUpdate);
  }
  useEffect(() => {
    if (educationCreateSectionResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success',
        'title': t('SUCCESS'),
        'description': t('REGISTERED_SUCCESSFULLY'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      resetEducationInformation();
      getDetail();
      resetEducationCreateSection();
    }
    else if (educationCreateSectionResponse.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ERROR_WAS_ENCOUNTERED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationCreateSection();
    }
  }, [educationCreateSectionResponse])
  const addDataListEdit = (data, index) => {
    // setLoader(true)
    const editStepList = [...stepList]
    editStepList[index].name = data
    setStepList(editStepList)

    const dataUpdate = {
      "sectionName": data,
      "resourceId": editedSection
    }
    educationUpdateSection(dataUpdate);
  }
  useEffect(() => {
    if (educationUpdateSectionResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success_key',
        'title': t('SUCCESS'),
        'description': t('REGISTRATION_CREATED_SUCCESSFULLY'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      getDetail();
      setParticipantsState({
        'selectedPerson': [],
        'startDate': null,
        'endDate': null
      })
      resetEducationUpdateSection();
    } else if (educationUpdateSectionResponse.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ERROR_WAS_ENCOUNTERED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationUpdateSection();
    }
  }, [educationUpdateSectionResponse])

  const openVideoModal = (id, resourceId) => {
    setModals({ ...modals, [id]: true });
    setEditedSection(resourceId)
  }

  const handleCancelVideoModal = (id) => {
    setModals({ ...modals, [id]: false, isEditModal: false, videoData : null });
  }

  const subVideoUpdateModal = (data) => {
    setModals({
      "id" : data.contentResourceId,
      "videoModal0": true,
      "isEditModal" : true,
      "videoData" : data
    });
  }

  const videosData = (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('id', modals?.videoData?.contentId);
    formData.append('VideoName', data.videoName);
    formData.append('Description', data.videoDesc);
    formData.append('YoutubeUrl', data?.youtubeUrl);
    formData.append('SectionResourceId', editedSection);
    if (data?.selectPlatform === "hrplan") {
      formData.append('File', data.videoList[0].originFileObj);
    }
    if (data?.youtubeUrl) {
      formData.append('IsYoutubeUrl', true);
    } else {
      formData.append('IsYoutubeUrl', false);

    }
    var config = {
      method: 'post',
      url: apiUrl + '/education/' + (modals?.isEditModal ? 'update-section-video' : 'create-section-video'),
      headers: { 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' },
      data: formData
    };

    hrApi(config)
      .then(function (response) {
        DynamicToastMessage({
          'key': 'success',
          'title': t('SUCCESS'),
          'description': modals?.isEditModal ? t('VIDEO_ADDED_SUCCESS') : t('VIDEO_ADDED'),
          'statusType': 'success',
          'position': 'bottom-right'
        });
        setLoader(false);
        handleCancelVideoModal('videoModal' + data?.index);
        setSubVideos((prevArray) => [...prevArray, data]);
        getDetail();
      })

      .catch(function (error) {
        DynamicToastMessage({
          'key': 'error_key',
          'title': t('ERROR'),
          'description': t('ERROR_WAS_ENCOUNTERED'),
          'statusType': 'error',
          'position': 'bottom-right'
        });
        setLoader(false);
      });
  }
  const deleteSection = (index, resourceId) => {
    //setLoader(true)
    const sectionArr = [...stepList]
    sectionArr.splice(index, 1);
    setStepList(sectionArr)
    const sectionVideoArr = [...subVideos]
    sectionVideoArr.map((data, i) => {
      if (data.index == index) {
        sectionVideoArr.splice(i, 1)
      }
    })
    setSubVideos(sectionVideoArr)
    educationDeleteSection(resourceId);
  }
  useEffect(() => {
    if (educationDeleteSectionResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success_key',
        'title': t('SUCCESS'),
        'description': t('DELETE_SUCCESSFUL'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      getDetail();
      resetEducationDeleteSection();
    } else if (educationDeleteSectionResponse.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ERROR_WAS_ENCOUNTERED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationDeleteSection();
    }
  }, [educationDeleteSectionResponse])
  const subVideoDelete = (index, id) => {
    educationDeleteVideoSection(id, index);
  }
  useEffect(() => {
    if (educationDeleteVideoSectionResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success_key',
        'title': t('SUCCESS'),
        'description': t('DELETE_SUCCESSFUL'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      const sectionArr = [...subVideos]
      sectionArr.splice(educationDeleteVideoSectionResponse.index, 1);
      setSubVideos(sectionArr);
      getDetail();
      resetEducationDeleteVideoSection();
    } else if (educationDeleteVideoSectionResponse.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ERROR_WAS_ENCOUNTERED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationDeleteVideoSection();
    }
  }, [educationDeleteVideoSectionResponse])

  useEffect(() => {
    if (!workHourDepartmentInPersonLoading) {
        var list = [];
        workHourDepartmentInPerson.map((data) => {
            return list.push({
                'title': data.departmentName,
                'value': data.departmentId,
                'key': data.departmentId,
                'children': data.persons.map((datax) => {
                    return {
                        'title': datax.givenName + ' ' + datax.familyName,
                        'value': datax.resourceId,
                        'key': datax.resourceId,
                    }
                })
            })
        })
        setPersonTreeData(list)
    }
}, [workHourDepartmentInPerson])
  
  useEffect(() =>{
    getWorkHourDepartmentInPersons();
  },[])

  useEffect(() => {
    setPersonData([...personData, allPersons])
  }, [allPersons])

  // Persons Data
  const personsData = (value) => {
    setParticipantsState({
      ...participantsState,
      'selectedPerson': value
    });
  }
  const startDateChange = (date, dateString) => {
    setParticipantsState({
      ...participantsState,
      'startDate': dateString
    });
  }
  const endDateChange = (date, dateString) => {
    setParticipantsState({
      ...participantsState,
      'endDate': dateString
    });
  }

  //#region Bulk Update Delete
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkState, setBulkState] = useState('default');
  const [bulkUpdateDate, setBulkUpdateDate] = useState({
    'startDate': null,
    'endDate': null
  });

  const startBulkDateChange = (date, dateString) => {
    setBulkUpdateDate({
      ...bulkUpdateDate,
      'startDate': dateString
    });
  }
  const endBulkDateChange = (date, dateString) => {
    setBulkUpdateDate({
      ...bulkUpdateDate,
      'endDate': dateString
    });
  }

  const saveUpdatedTableData = () => {

    if(!bulkUpdateDate.startDate || !bulkUpdateDate.endDate){
      return DynamicToastMessage({
        'key': 'error_key',
        'description': t('EDUCATION_START_END_DATE_REQUIRED'),
        'statusType': 'warning',
        'position': 'bottom-right'
      });
    }

    const filteredTableData = tableData.filter((item, index) => selectedRowKeys.includes(index));
    const newDates = filteredTableData.map(data => {
      return {
        subscriberId : data.subscriberId,
        personId : data.personId,
        startDate: moment(bulkUpdateDate.startDate, 'DD/MM/YYYY').set({ hour: 9}).toISOString(),
        endDate : moment(bulkUpdateDate.endDate, 'DD/MM/YYYY').set({ hour: 9}).toISOString(),
      }
    })

    bulkUpdateEducation({
      "educationId": educationInformation.id,
      "subscriberList": newDates
    })
   

  }

  useEffect(() => {
    if(bulkDelete.isLoading == false && bulkDelete?.data?.status == 'SUCCESS'){
      DynamicToastMessage({
        'key': 'success_bulk_delete',
        'description': t('EDUCATION_PARTICIPANTS_LIST_UPDATED'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      resetEducationInformation();
      getDetail();
      resetEducationCreateSection();
      setSelectedRowKeys([])
    }
    if(bulkDelete.isLoading == false && bulkDelete?.data?.status == 'WARNING'){
      DynamicToastMessage({
        'key': 'warning_bulk_delete',
        'description': bulkDelete?.data?.message ? bulkDelete?.data?.message : t('EDUCATION_ERROR_OCCURRED'),
        'statusType': 'warning',
        'position': 'bottom-right'
      });
    }
    if(bulkDelete.isLoading == false && bulkDelete?.data?.status == 'FAIL'){
      DynamicToastMessage({
        'key': 'error_bulk_delete',
        'description': bulkDelete?.data?.message ? bulkDelete?.data?.message : t('EDUCATION_ERROR_OCCURRED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
    }

  }, [bulkDelete?.data])

  useEffect(() => {
    if(bulkUpdate.isLoading == false && bulkUpdate?.data?.status == 'SUCCESS'){
      DynamicToastMessage({
        'key': 'success_bulk_update',
        'description': t('EDUCATION_PARTICIPANTS_LIST_UPDATED_AGAIN'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      resetEducationInformation();
      getDetail();
      resetEducationCreateSection();
      setSelectedRowKeys([])
    }
    if(bulkUpdate.isLoading == false && bulkUpdate?.data?.status == 'WARNING'){
      DynamicToastMessage({
        'key': 'warning_bulk_update',
        'description': bulkUpdate?.data?.message ? bulkUpdate?.data?.message : t('EDUCATION_ERROR_OCCURRED'),
        'statusType': 'warning',
        'position': 'bottom-right'
      });
    }
    if(bulkUpdate.isLoading == false && bulkUpdate?.data?.status == 'FAIL'){
      DynamicToastMessage({
        'key': 'error_bulk_update',
        'description': bulkUpdate?.data?.message ? bulkUpdate?.data?.message : t('EDUCATION_ERROR_OCCURRED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
    }

  }, [bulkUpdate?.data])

  const bulkDeleteAction = () => {

    const filteredTableData = tableData.filter((item, index) => selectedRowKeys.includes(index));
    const newDates = filteredTableData.map(data => {
      return {
        subscriberId : data.subscriberId,
        personId : data.personId,
        startedDate: moment(bulkUpdateDate.startDate, 'DD/MM/YYYY').set({ hour: 21}).toISOString(),
        endDate : moment(bulkUpdateDate.endDate, 'DD/MM/YYYY').set({ hour: 21}).toISOString(),
      }
    })
    bulkDeleteEducation({
      "subscriberIdList": newDates.map(x => x.subscriberId)
    })
  }

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  useEffect(() => {
    if(selectedRowKeys?.length == 0){
      setBulkState('default')
      setBulkUpdateDate({
        'startDate': null,
        'endDate': null
      })
    }
  },[selectedRowKeys])


  const rowSelection = {  selectedRowKeys, onChange: onSelectChange };

  // Table
  const columns = [
    {
      title: t('PARTNER_NAME_SURNAME2'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('SETTINGS_START_DATE'),
      dataIndex: 'startedDate',
      key: 'startedDate',
      render: (text, record) => text
      /*{
        const isSelected = selectedRowKeys.includes(record.key);
        const dateValue = record.startedDate ? moment(record.startedDate, educationDateFormatter) : null;
        const validDate = dateValue && dateValue.isValid() ? dateValue : null;
        return isSelected ? (
          <DatePicker
            locale={localeLang}
            onChange={(date, dateString) => handleDateChange(date, dateString, record, 'startedDate')}
            format={educationDateFormatter}
            value={validDate}
          />
        ) : (
          text
        );
      }*/,
    },
    {
      title: t('SETTINGS_END_DATE'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text, record) => text
      /*{
        const isSelected = selectedRowKeys.includes(record.key);
        const dateValue = record.endDate ? moment(record.endDate, educationDateFormatter) : null;
        const validDate = dateValue && dateValue.isValid() ? dateValue : null;
        return isSelected ? (
          <DatePicker
            locale={localeLang}
            onChange={(date, dateString) => handleDateChange(date, dateString, record, 'endDate')}
            format={educationDateFormatter}
            value={validDate}
          />
        ) : (
          text
        );
      },
      */
    },
  ];
  

  const dataWithKeys = tableData.map((item, index) => ({
    ...item,
    key: item.id || index, // key unique id
  }));

  //#endregion 

  const pushDataTable = () => {
    if(!participantsState?.startDate || !participantsState?.endDate){
      return DynamicToastMessage({
        'key': 'error_key',
        'description': t('EDUCATION_START_END_DATE_REQUIRED'),
        'statusType': 'warning',
        'position': 'bottom-right'
      });
    }
    //setLoader(true)
    const requestData = {
      "personResourceId": participantsState?.selectedPerson,
      "educationResourceId": educationInformation?.educationResourceId,
      "startDate": moment(participantsState?.startDate, educationDateWithTimeFormatter).format(),
      "endDate": moment(participantsState?.endDate, educationDateWithTimeFormatter).format()
    }
    educationAddSubscriber(requestData);
  }
  useEffect(() => {
    if (educationAddSubscriberResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success_key',
        'title': t(educationAddSubscriberResponse.message.status),
        'description': educationAddSubscriberResponse.message.message,
        'statusType': educationAddSubscriberResponse.message.status == 'WARNING' ? 'warning':'success',
        'position': 'bottom-right',
        'autoClose': 10000
      });
      getDetail();
      setParticipantsState({
        'selectedPerson': [],
        'startDate': null,
        'endDate': null
      })
      resetEducationAddSubscriber();
    } else if (educationAddSubscriberResponse.status === 'FAIL') {
      let message = t('ERROR_WAS_ENCOUNTERED')
      if(educationAddSubscriberResponse.message?.errorMessages?.length > 0){
        message = educationAddSubscriberResponse.message?.message
      }else if(educationAddSubscriberResponse.message?.validationMessages?.length > 0){
        message = educationAddSubscriberResponse.message?.validationMessages?.join(',')
      }
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': message,
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationAddSubscriber();
    }
  }, [educationAddSubscriberResponse])

  useEffect(() => {
    if (educationSubscriberRemoveResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success_key',
        'title': t('SUCCESS'),
        'description': t('DELETE_SUCCESSFUL'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      const tableDataDelete = [...tableData]
      tableData.map((item, index) => {
        if (item == educationSubscriberRemoveResponse.id) {
          tableDataDelete.splice(index, 1)
        }
      })
      setTableData(tableDataDelete);
      getDetail();
      resetEducationDeleteSubscriberRemove();
    } else if (educationSubscriberRemoveResponse.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ERROR_WAS_ENCOUNTERED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationDeleteSubscriberRemove();
    }
  }, [educationSubscriberRemoveResponse])

  useEffect(() =>{
    if(educationVideoSortUpdateResponse.status === "SUCCESS"){
      resetEducationVideoSortUpdate();
    }else if(educationVideoSortUpdateResponse.status === "FAIL"){
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': educationVideoSortUpdateResponse?.message,
        'statusType': 'error',
        'position': 'bottom-right'
      });
      getDetail();
      resetEducationVideoSortUpdate();
    }
  },[educationVideoSortUpdateResponse])

  const updateEducation = (nextPageId) => {
    if(nextPageId === '2'){
      if(!educationInformationState.description){
        DynamicToastMessage({
          'key': 'error_key',
          'title': t('ERROR'),
          'description': t('EDUCATION_MAIN_DESCRIPTION_ERROR'),
          'statusType': 'error',
          'position': 'bottom-right'
        });
        return false;
      }
    }
    if (nextPageId === '3') {
      if (stepList.length <= 0) {
        DynamicToastMessage({
          'key': 'error_key',
          'title': t('ERROR'),
          'description': t('MANDATORY_TO_CREATE_TRAINING_ERROR'),
          'statusType': 'error',
          'position': 'bottom-right'
        });
        return false;
      }
      if (subVideos.length <= 0) {
        DynamicToastMessage({
          'key': 'error_key',
          'title': t('ERROR'),
          'description': t('ADD_AT_LEAST_ONE_VIDEO_ERROR'),
          'statusType': 'error',
          'position': 'bottom-right'
        });
        return false;
      }
      nextPage(nextPageId);
    } else {
      const tempObj  = {
        'activeEducation': educationInformation.isActive,
        'educatorName': educationInformation.educatorName,
        'educationName': educationInformation.educationName,
        'description': educationInformation.description,
        'file': [],
      };

      if(!compareObjects(tempObj,educationInformationState)){
        const formData = new FormData();
        formData.append('IsActive', educationInformationState?.activeEducation);
        formData.append('File', educationInformationState?.file.length > 0 ? educationInformationState?.file[0].originFileObj : '');
        formData.append('EducationName', educationInformationState.educationName);
        formData.append('Description', educationInformationState.description);
        formData.append('EducatorName', educationInformationState.educatorName);
        formData.append('ResourceId', editResourceId);
  
        var config = {
          method: 'put',
          url: apiUrl + '/education/update-form',
          headers: { 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' },
          data: formData
        };
  
        hrApi(config)
          .then(function (response) {
            DynamicToastMessage({
              'key': 'success',
              'title': t('SUCCESS'),
              'description': t('REGISTERED_SUCCESSFULLY'),
              'statusType': 'success',
              'position': 'bottom-right'
            });
            //setLoader(false)
            getDetail();
            nextPage(nextPageId);
            setChanged(true);
          })
  
          .catch(function (error) {
            //setLoader(false)
            DynamicToastMessage({
              'key': 'error_key',
              'title': t('ERROR'),
              'description': t('ERROR_WAS_ENCOUNTERED'),
              'statusType': 'error',
              'position': 'bottom-right'
            });
          });
      }else{
        setCurrentTab(nextPageId)
      }
    }
      
  }
  const beforeUploadImage = (file) => {
    const isImg = file?.type?.startsWith('image/');
    const isSizeValid = file?.size <= 5 * 1024 * 1024;

    if (!isImg) {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('SHOULD_NOT_EXCEED_IMG'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
    }

    if (!isSizeValid) {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('IMAGE_FILE_MAX_5MB'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
    }

    if (educationInformationState?.file.length >= 1) {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ONLY_ONE_FILE_ERROR'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false;
    }
    return false;
  };


  const loaderStyle = {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "rgba(0,0,0,0.2)",
    zIndex: "1000"
  }
  const DragHandle = SortableHandle(({ style }) => (
    <span style={style} className="sortable-list-icon"><UilDraggabledots /></span>)
  );
  const SortableItem = SortableElement(({ value }) => {
    return <div className="sortable-list-item">
        <Card key={value?.data?.index} className="dragger-table-card" >
            <div className="flex-1">
                <div className="d-flex">
                    <DragHandle />
                    <a className="details-text pl-2 align-self-center">{t('VIDEO_NAME') + " : " + value?.data?.videoName}</a>
                </div>
            </div>
            <div className="mr-1" onClick={() => subVideoUpdateModal(value?.data?.videoData)} style={{ cursor: 'pointer' }}>
              <UilEditAlt />
            </div>
            <div onClick={() => subVideoDelete(value?.data?.index, value?.data?.resourceId)} style={{ cursor: 'pointer' }}>
              <UilTrashAlt />
            </div>
        </Card>
    </div>
  });
const SortableList = SortableContainer(({ items,dataIndex }) => {
  return (
      <ul className="sortable-list-container">
          {items.length > 0 && items.map((value, index) => {
            if(value?.index === dataIndex){
             return <SortableItem key={`item-${value.resourceId+'-'+index}}`} index={index} value={{ 'data': value, 'index': index }} />
            }
          })}
      </ul>
  );
});
const onSortEnd = ({ oldIndex, newIndex }) => {
  var requestArr = [];
  const resultData = arrayMoveImmutable(subVideos, oldIndex, newIndex);
  resultData.map((data, index) => requestArr.push({ resourceId: data.resourceId, order: index }));
  setSubVideos(items => arrayMoveImmutable(items, oldIndex, newIndex));
  educationVideoSortUpdate({ "orders" : requestArr} );
};
const callback  = (key) => {
  if(key !== "3"){
   setCurrentTab(key);
  }else{
    if(subVideos.length > 0) {
      setCurrentTab(key);
    }else{
      return false;
    }
  }
}
const updateEducationInformation = (name, val, type) =>{
  if(type === "image"){
    const updatedFileList = val.fileList.filter((file) =>
      file.type.startsWith('image/')
    );
    setEducationInformationState({ ...educationInformationState, [name]: updatedFileList });

  }else{
    setEducationInformationState({ ...educationInformationState, [name]: val });
  }
}
  return (
    <Drawer
      key={() => randomNumberInRange(1, 5000000)}
      visible={modalVisible}
      className="delete-confirm-modal"
      onCancel={() => isForm ? handleCancel() : setStateData({ "isVisible": false })}
      maskClosable={maskClosable ? true : false}
      width={modalWidth ? modalWidth : 520}
      keyboard={keyboard}
      closable={false}
      footer={[]}>
      {
        educationInformationLoading &&
        <div style={loaderStyle} className="loader d-flex align-items-center justify-content-center">
          <div className="w-100">
            <Spin />
          </div>
        </div>
      }
      <div>
        <div className="table-details">
          <div className="add-global-close-icon">
            <UilMultiply onClick={() => closeAllDrawer()} />
          </div>
          <div className="add-global-draw-title flex-start">
            <h1>{educationInformation?.educationName ? educationInformation?.educationName : '-' }</h1>
          </div>
        </div>
          <div className="table-details">
            <Tabs
              activeKey={currentTab}
              className="w-100 education-draw-scrollable-tab"
              onChange={callback}
            >
              <TabPane tab={t('EDUCATION_INFORMATION')} key="1">
                <>
                  <div className="w-100 pt-4">
                    <Form layout="vertical">
                      <div className="form-left-body">
                        <div className="mb-8 mt-4">
                          <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }} htmlFor=""><strong>{t('EDUCATION_MAIN_TITLE')} *</strong></label>
                          <Input style={{
                            height: '40px',
                            width: '100%'
                          }} onChange={(e) => updateEducationInformation("educationName", e.target.value, "input")} value={educationInformationState?.educationName} placeholder={t('ENTER_TRAINING_NAME')} />
                        </div>
                        <div className="d-flex mb-2 mt-2">
                          <span className="pr-2">{`${t('SETTINGS_ACTIVE')} / ${t('SETTINGS_PASSIVE')}`}</span>
                          <Switch defaultChecked onChange={(e) => updateEducationInformation("activeEducation", e, "switch")}  />
                        </div>
                        <div className="mb-8 mt-4">
                          <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }} htmlFor=""><strong>{t('INSTRUCTOR')}</strong></label>
                          <Input style={{
                            height: '40px',
                            width: '100%'
                          }} placeholder={t('ENTER_INSTRUCTOR_NAME')} onChange={(e) => updateEducationInformation("educatorName", e.target.value, "input")} value={educationInformationState?.educatorName} />
                        </div>
                        <div className="mb-8 mt-4">
                          <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }} htmlFor=""><strong>{t('DOWNLOAD_EDUCATION_IMAGE')} *</strong></label>
                          <span>
                            <Upload multiple={false} beforeUpload={beforeUploadImage} fileList={educationInformationState?.file} onChange={(e) => updateEducationInformation("file", e, "image")}>
                              <Button icon={<UploadOutlined />}>{t('UPLOAD')}</Button>
                            </Upload>
                          </span>

                        </div>
                        <div className="mb-8 mt-4">
                          <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }}><strong>{t('TRAINING_DESCRIPTION')} *</strong></label>
                          <Input.TextArea style={{
                            height: '90px',
                            width: '100%'
                          }} onChange={(e) => updateEducationInformation("description", e.target.value, "textarea")} value={educationInformationState?.description} placeholder={t('PARTNER_TEXT_DESCRIPTION')} />
                        </div>
                      </div>
                    </Form>
                  </div>
                </>
                <div style={{
                  marginLeft: '-24px'
                }} className="add-user-draw-footer">
                  <Button key="back" className="ant-btn white-btn w-25 h-40" onClick={closeAllDrawer}>
                    {t('PARTNER_CLOSE')}
                  </Button>
                  <div className="add-user-draw-footer-end">
                    <div className="pad-left-24" />
                    <Button key="submit" className="ant-btn primary-btn h-40" onClick={() => updateEducation('2')}>
                      {t('SAVE_AND_PROCEED')}
                    </Button>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={t('CONTENT_INFORMATION')} key="2">
                <div  className="content ">
                  <Button onClick={openInlineModal} className="ant-btn white-btn w-25 h-40">
                    {t('ADD_SECTION')}
                  </Button>
                  <DynamicEducationInlineModal
                    modalVisible={inlineModalView}
                    handleCancel={handleCancelInlineModal}
                    modalWidth={480}
                    dataInline={addDataList}
                    key="modal"
                  />
                  <DynamicEducationInlineEditModal
                    modalVisible={inlineModalViewEdit}
                    handleCancel={handleCancelInlineModalEdit}
                    modalWidth={480}
                    dataInline={addDataListEdit}
                    editIndex={editIndex}
                    editData={editData}
                    key="modalEdit"
                  />
                  {stepList.map((item, index) => (
                    <div className="position-relative" key={index}>
                      <Card
                        className="mt-3"
                        title={item.name}
                      >
                        <SortableList 
                          items={subVideos}
                          dataIndex={index}
                          onSortEnd={onSortEnd}
                          axis="y"
                          helperClass="sortable-list-tab sortable-list-detail-container"
                          lockAxis="y"
                          distance={10} 
                        />
                      </Card>
                      <DynamicEducationVideoModal
                        loader={loader}
                        dataGet={videosData}
                        id={'videoModal' + index}
                        isEditModal={modals?.isEditModal}
                        editModalData={modals?.videoData}
                        modalVisible={modals['videoModal' + index]}
                        handleCancel={() => handleCancelVideoModal('videoModal' + index)}
                        modalWidth={480}
                        dataInline={addDataList}
                        key={'videoModal' + index}
                        index={index}
                      />
                      <div style={{
                        zIndex: '100',
                        right: '20px',
                        top: '18px'
                      }} className="position-absolute">
                        <div className="d-flex">
                          <div onClick={() => openVideoModal('videoModal' + index, item.resourceId)} style={{ marginRight: '10px', cursor: 'pointer' }}>
                            <UilPlusCircle />
                          </div>
                          <div onClick={() => openInlineModalEdit(index, item.resourceId)} style={{ marginRight: '10px', cursor: 'pointer' }}>
                            <UilPen />
                          </div>
                          <div onClick={() => deleteSection(index, item.resourceId)} style={{ cursor: 'pointer' }}>
                            <UilTrashAlt />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{
                  marginLeft: '-24px'
                }} className="add-user-draw-footer">
                  <Button key="back" className="ant-btn white-btn w-25 h-40" onClick={() => nextPage('1')}>
                    {t('INVENTORY_BACK')}
                  </Button>
                  <div className="add-user-draw-footer-end">
                    <div className="pad-left-24" />
                    <Button key="submit" className="ant-btn primary-btn h-40" onClick={() => updateEducation('3')}>
                      {t('INVENTORY_NEXT')}
                    </Button>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={t('PARTICIPANTS')} key="3" disabled={!subVideos?.length > 0}>
                <div className="content">
                  <div>
                    <label htmlFor=""><strong>{t('PARTICIPANTS')}</strong></label>
                      <TreeSelect
                        className="custom-select"
                        treeData={personTreeData}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_CHILD}
                        placeholder={t('PARTNER_SELECT')}
                        filterTreeNode={(search, item) => customFilter(search, item)}
                        onChange={personsData}
                        value={participantsState?.selectedPerson}
                      />
                  </div>
                  <div className="d-flex mt-8">
                    <div className="mr-6">
                      <label htmlFor=""><strong>{t('SETTINGS_START_DATE')}</strong></label>
                      <DatePicker
                        locale={localeLang}
                        onChange={startDateChange}
                        format={[educationDateFormatter, educationShortDateFormatter]}
                        value={participantsState?.startDate && moment(participantsState?.startDate, educationDateFormatter)}
                      />
                    </div>
                    <div className="mr-6">
                      <label htmlFor=""><strong>{t('SETTINGS_END_DATE')}</strong></label>
                      <DatePicker
                        locale={localeLang}
                        onChange={endDateChange}
                        format={[educationDateFormatter, educationShortDateFormatter]}
                        value={participantsState?.endDate && moment(participantsState?.endDate, educationDateFormatter)}
                      />
                    </div>
                    <Button style={{
                      marginTop: '22px'
                    }} onClick={pushDataTable} className="ant-btn primary-btn h-40">
                      {t('PARTNER_ADD')}
                    </Button>
                  </div>
                  {(dataWithKeys.length > 0) && <div className="mt-8 position-relative">
                    {selectedRowKeys && selectedRowKeys?.length > 0 && <div className="education-edit-absolute">
                      <div className="eduction-edit-date-box ant-select fw-600">
                        {t('EDUCATION_ITEMS_SELECTED', {number: selectedRowKeys?.length})}
                      </div>
                      
                      {bulkState == 'edit' && 
                      (<>
                      <DatePicker
                        className="education-bulk-date-pick-start"
                        locale={localeLang}
                        onChange={startBulkDateChange}
                        placeholder={t('EDUCATION_START_DATE')} 
                        format={[educationDateFormatter, educationShortDateFormatter]}
                        value={bulkUpdateDate?.startDate && moment(bulkUpdateDate?.startDate, educationDateFormatter)} ></DatePicker>
                      <DatePicker
                        className="education-bulk-date-pick-start"
                        locale={localeLang}
                        onChange={endBulkDateChange}
                        placeholder={t('EDUCATION_END_DATE')} 
                        format={[educationDateFormatter, educationShortDateFormatter]}
                        value={bulkUpdateDate?.endDate && moment(bulkUpdateDate?.endDate, educationDateFormatter)} ></DatePicker>
                        </>)
                        }

                        {bulkState !== 'edit' && 
                        <div onClick={() => setBulkState('edit')} className="d-flex eduction-edit-date-box edit education-edit-date-pen primary-green-font-color">
                          <UilPen style={{ width: 18, height: 18 }} /> <div>{t('EDUCATION_EDIT')}</div>
                        </div>
                        }
                        {bulkState == 'edit' && 
                        <div onClick={saveUpdatedTableData} className="d-flex eduction-edit-date-box edit education-edit-date-pen primary-green-font-color">
                           <div>{t('EDUCATION_SAVE')}</div>
                        </div>
                        }

                        <div onClick={() => setBulkState('delete')} className="eduction-edit-date-box edit secondary-dark-font-color"><UilTrashAlt style={{ width: 18, height: 18 }}  /></div>

                        {bulkState == 'delete' && 
                          (<>
                          <div onClick={bulkDeleteAction} className="d-flex eduction-edit-date-box remove education-edit-date-pen secondary-dark-font-color">
                            <div>{t('EDUCATION_CONFIRM_DELETE')}</div>
                          </div>
                          <div onClick={() => setBulkState('default')} className="d-flex eduction-edit-date-box edit education-edit-date-pen secondary-dark-font-color">
                            <div>{t('EDUCATION_CANCEL_DELETE')}</div>
                          </div>
                          </>)
                        }

                    </div>}
                    <Table className="education-edit-table" columns={columns} rowSelection={rowSelection} dataSource={dataWithKeys} />
                  </div>}
                </div>
                <div style={{
                  marginLeft: '-24px'
                }} className="add-user-draw-footer">
                  <Button key="back" className="ant-btn white-btn w-25 h-40" onClick={() => nextPage('2')}>
                    {t('INVENTORY_BACK')}
                  </Button>
                  <div className="add-user-draw-footer-end">
                    <div className="pad-left-24" />
                    <Button key="submit" className="ant-btn primary-btn h-40" onClick={closeAllDrawer}>
                      {t('SAVE_AND_CLOSE')}
                    </Button>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
    </Drawer>
  )
}
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    educationInformation: state.education.educationInformation,
    educationInformationLoading: state.education.educationInformationLoading,
    educationCreateSectionResponse: state.education.educationCreateSectionResponse,
    educationUpdateSectionResponse: state.education.educationUpdateSectionResponse,
    educationDeleteSectionResponse: state.education.educationDeleteSectionResponse,
    educationDeleteVideoSectionResponse: state.education.educationDeleteVideoSectionResponse,
    educationAddSubscriberResponse: state.education.educationAddSubscriberResponse,
    educationSubscriberRemoveResponse: state.education.educationSubscriberRemoveResponse,
    workHourDepartmentInPerson: state.organization.workHourDepartmentInPerson,
    workHourDepartmentInPersonLoading: state.organization.workHourDepartmentInPersonLoading,
    educationVideoSortUpdateResponse: state.education.educationVideoSortUpdateResponse,
    permissions: state.profile.permissions,
    bulkUpdate : state.education.bulkUpdate,
    bulkDelete: state.education.bulkDelete,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getEducationInformation: (resourceId) => { dispatch(getEducationInformation(resourceId)) },
    resetEducationInformation: () => { dispatch(resetEducationInformation()) },
    educationCreateSection: (data) => { dispatch(educationCreateSection(data)) },
    resetEducationCreateSection: () => { dispatch(resetEducationCreateSection()) },
    educationUpdateSection: (data) => { dispatch(educationUpdateSection(data)) },
    resetEducationUpdateSection: () => { dispatch(resetEducationUpdateSection()) },
    educationDeleteSection: (resourceId) => { dispatch(educationDeleteSection(resourceId)) },
    resetEducationDeleteSection: () => { dispatch(resetEducationDeleteSection()) },
    educationDeleteVideoSection: (resourceId, index) => { dispatch(educationDeleteVideoSection(resourceId, index)) },
    resetEducationDeleteVideoSection: () => { dispatch(resetEducationDeleteVideoSection()) },
    educationAddSubscriber: (requestData) => { dispatch(educationAddSubscriber(requestData)) },
    resetEducationAddSubscriber: () => { dispatch(resetEducationAddSubscriber()) },
    educationDeleteSubscriberRemove: (resourceId) => { dispatch(educationDeleteSubscriberRemove(resourceId)) },
    resetEducationDeleteSubscriberRemove: () => { dispatch(resetEducationDeleteSubscriberRemove()) },
    getWorkHourDepartmentInPersons: () => { dispatch(getWorkHourDepartmentInPersons()) },
    educationVideoSortUpdate: (data) => { dispatch(educationVideoSortUpdate(data)) },
    resetEducationVideoSortUpdate:() => { dispatch(resetEducationVideoSortUpdate()) },
    bulkUpdateEducation: (data) => { dispatch(bulkUpdateEducation(data)) },
    bulkDeleteEducation: (data) => { dispatch(bulkDeleteEducation(data)) },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicEducationModal);