import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";

const TaskActionType = {
  GET_CONNECTED_TAG_REQUEST: "GET_CONNECTED_TAG_REQUEST",
  GET_CONNECTED_TAG_SUCCESS: "GET_CONNECTED_TAG_SUCCESS",
  GET_CONNECTED_TAG_FAIL: "GET_CONNECTED_TAG_FAIL",
  SET_DEFINED_TASK_CREATE_DRAWER: "SET_DEFINED_TASK_CREATE_DRAWER",
  SET_DEFINED_TASK_DETAIL_DRAWER: "SET_DEFINED_TASK_DETAIL_DRAWER",
  SET_MY_TASK_DETAIL_DRAWER: "SET_MY_TASK_DETAIL_DRAWER",
  SET_ASSIGNED_TASK_DETAIL_DRAWER: "SET_ASSIGNED_TASK_DETAIL_DRAWER",
  UPDATE_ASSIGNED_TASKS_LIST_REQUEST: "UPDATE_ASSIGNED_TASKS_LIST_REQUEST",
  UPDATE_ASSIGNED_TASKS_LIST_SUCCESS: "UPDATE_ASSIGNED_TASKS_LIST_SUCCESS",
  UPDATE_ASSIGNED_TASKS_LIST_FAIL: "UPDATE_ASSIGNED_TASKS_LIST_FAIL",
  UPDATE_ASSIGNED_TASKS_LIST_RESET: "UPDATE_ASSIGNED_TASKS_LIST_RESET",
  ASSIGNED_TASKS_LIST_REQUEST: "ASSIGNED_TASKS_LIST_REQUEST",
  ASSIGNED_TASKS_LIST_SUCCESS: "ASSIGNED_TASKS_LIST_SUCCESS",
  ASSIGNED_TASKS_LIST_FAIL: "ASSIGNED_TASKS_LIST_FAIL",
  UPDATE_ASSIGNED_TASKS_SUCCESS: "UPDATE_ASSIGNED_TASKS_SUCCESS",
  UPDATE_ASSIGNED_TASKS_FAIL: "UPDATE_ASSIGNED_TASKS_FAIL",
  UPDATE_ASSIGNED_TASKS_RESET: "UPDATE_ASSIGNED_TASKS_RESET",
  CREATE_ASSIGNED_TASKS_SUCCESS: "CREATE_ASSIGNED_TASKS_SUCCESS",
  CREATE_ASSIGNED_TASKS_FAIL: "CREATE_ASSIGNED_TASKS_FAIL",
  CREATE_ASSIGNED_TASKS_RESET: "CREATE_ASSIGNED_TASKS_RESET",
  ASSIGNED_TASK_DETAILS_REQUEST: "ASSIGNED_TASK_DETAILS_REQUEST",
  ASSIGNED_TASK_DETAILS_SUCCESS: "ASSIGNED_TASK_DETAILS_SUCCESS",
  ASSIGNED_TASK_DETAILS_FAIL: "ASSIGNED_TASK_DETAILS_FAIL",
  UPDATE_ORDER_ASSIGNED_TASKS_LIST_SUCCESS: "UPDATE_ORDER_ASSIGNED_TASKS_LIST_SUCCESS",
  UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL: "UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL",
  UPDATE_ORDER_ASSIGNED_TASKS_LIST_RESET: "UPDATE_ORDER_ASSIGNED_TASKS_LIST_RESET",
  DELETE_DEFINED_TASK_SUCCESS: "DELETE_DEFINED_TASK_SUCCESS",
  DELETE_DEFINED_TASK_FAIL: "DELETE_DEFINED_TASK_FAIL",
  DELETE_DEFINED_TASK_RESET: "DELETE_DEFINED_TASK_RESET",
  GET_MY_TASKS_LIST_REQUEST: "GET_MY_TASKS_LIST_REQUEST",
  GET_MY_TASKS_LIST_SUCCESS: "GET_MY_TASKS_LIST_SUCCESS",
  GET_MY_TASKS_LIST_FAIL: "GET_MY_TASKS_LIST_FAIL",
  SET_TASK_CREATE_DRAWER: "SET_TASK_CREATE_DRAWER",
  GET_STEP_LIST_TASKS_REQUEST: "GET_STEP_LIST_TASKS_REQUEST",
  GET_STEP_LIST_TASKS_SUCCESS: "GET_STEP_LIST_TASKS_SUCCESS",
  GET_STEP_LIST_TASKS_FAIL: "GET_STEP_LIST_TASKS_FAIL",
  CREATE_ASSIGNED_FORM_REQUEST: "CREATE_ASSIGNED_FORM_REQUEST",
  CREATE_ASSIGNED_FORM_SUCCESS: "CREATE_ASSIGNED_FORM_SUCCESS",
  CREATE_ASSIGNED_FORM_FAIL: "CREATE_ASSIGNED_FORM_FAIL",
  RESET_CREATE_ASSIGNED_FORM: "RESET_CREATE_ASSIGNED_FORM",
  ASSIGNED_TASK_AND_ADVANCE_ACTION_SUCCESS: "ASSIGNED_TASK_AND_ADVANCE_ACTION_SUCCESS",
  ASSIGNED_TASK_AND_ADVANCE_ACTION_FAIL: "ASSIGNED_TASK_AND_ADVANCE_ACTION_FAIL",
  ASSIGNED_TASK_AND_ADVANCE_ACTION_RESET: "ASSIGNED_TASK_AND_ADVANCE_ACTION_RESET",
  GET_TASKS_ASSIGNED_LIST_REQUEST: "GET_TASKS_ASSIGNED_LIST_REQUEST",
  GET_TASKS_ASSIGNED_LIST_SUCCESS: "GET_TASKS_ASSIGNED_LIST_SUCCESS",
  GET_TASKS_ASSIGNED_LIST_FAIL: "GET_TASKS_ASSIGNED_LIST_FAIL",
  GET_TASK_DESCRIPTION_DETAIL_REQUEST: "GET_TASK_DESCRIPTION_DETAIL_REQUEST",
  GET_TASK_DESCRIPTION_DETAIL_SUCCESS: "GET_TASK_DESCRIPTION_DETAIL_SUCCESS",
  GET_TASK_DESCRIPTION_DETAIL_FAIL: "GET_TASK_DESCRIPTION_DETAIL_FAIL",
  GET_TASK_DESCRIPTION_DETAIL_RESET: "GET_TASK_DESCRIPTION_DETAIL_RESET",
  TASK_UPDATE_STATUS_SUCCESS: "TASK_UPDATE_STATUS_SUCCESS",
  TASK_UPDATE_STATUS_FAIL: "TASK_UPDATE_STATUS_FAIL",
  TASK_UPDATE_STATUS_RESET: "TASK_UPDATE_STATUS_RESET",
  GET_ASSIGNED_TASK_INFORMATION_REQUEST: "GET_ASSIGNED_TASK_INFORMATION_REQUEST",
  GET_ASSIGNED_TASK_INFORMATION_SUCCESS: "GET_ASSIGNED_TASK_INFORMATION_SUCCESS",
  GET_ASSIGNED_TASK_INFORMATION_FAIL: "GET_ASSIGNED_TASK_INFORMATION_FAIL",
  GET_ASSIGNED_TASK_INFORMATION_RESET: "GET_ASSIGNED_TASK_INFORMATION_RESET",
  COMPLETE_ALL_TASK_SUCCESS: "COMPLETE_ALL_TASK_SUCCESS",
  COMPLETE_ALL_TASK_FAIL: "COMPLETE_ALL_TASK_FAIL",
  COMPLETE_ALL_TASK_RESET: "COMPLETE_ALL_TASK_RESET",
  DEFINED_TASK_UPDATE_SUCCESS: "DEFINED_TASK_UPDATE_SUCCESS",
  DEFINED_TASK_UPDATE_FAIL: "DEFINED_TASK_UPDATE_FAIL",
  DEFINED_TASK_UPDATE_RESET: "DEFINED_TASK_UPDATE_RESET",
  GET_STEP_LIST_TASKS_RESET: "GET_STEP_LIST_TASKS_RESET",
  DELETE_ASSIGNED_TASK_SUCCESS: "DELETE_ASSIGNED_TASK_SUCCESS",
  DELETE_ASSIGNED_TASK_FAIL: "DELETE_ASSIGNED_TASK_FAIL",
  DELETE_ASSIGNED_TASK_RESET: "DELETE_ASSIGNED_TASK_RESET",
  GET_ASSIGNED_STATUS_FILTER_REQUEST: "GET_ASSIGNED_STATUS_FILTER_REQUEST",
  GET_ASSIGNED_STATUS_FILTER_SUCCESS: "GET_ASSIGNED_STATUS_FILTER_SUCCESS",
  GET_ASSIGNED_STATUS_FILTER_FAIL: "GET_ASSIGNED_STATUS_FILTER_FAIL",
  TASK_STATES_FILTER_SUCCESS: "TASK_STATES_FILTER_SUCCESS",
  TASK_STATES_FILTER_FAIL: "TASK_STATES_FILTER_FAIL",
};

const setCreateDefinedTaskDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: TaskActionType.SET_DEFINED_TASK_CREATE_DRAWER,
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const setCreateTaskDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: TaskActionType.SET_TASK_CREATE_DRAWER,
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const setDetailDefinedTaskDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: TaskActionType.SET_DEFINED_TASK_DETAIL_DRAWER,
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const setMyTaskDetailDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: TaskActionType.SET_MY_TASK_DETAIL_DRAWER,
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const setAssignedTaskDetailDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: TaskActionType.SET_ASSIGNED_TASK_DETAIL_DRAWER,
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const getAssignedTasksList = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.ASSIGNED_TASKS_LIST_REQUEST });
    try {
      var config = {
        method: "post",
        url: apiUrl + "/task/gettasks",
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.ASSIGNED_TASKS_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.ASSIGNED_TASKS_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.ASSIGNED_TASKS_LIST_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.ASSIGNED_TASKS_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const updateOrderAssignedTasksList = (updateData) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(updateData);
      var config = {
        method: "put",
        url: apiUrl + "/task/updateorder",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({
              type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL,
              payload: error.response.data.message,
            });
          } else if (error.response?.status === 409) {
            dispatch({
              type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL,
              payload: error.response.data.message,
            });
          } else {
            dispatch({ type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateOrderAssignedTasksList = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.UPDATE_ORDER_ASSIGNED_TASKS_LIST_RESET, payload: {} });
  };
};
const getAssignedTaskDetails = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.ASSIGNED_TASK_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/task/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.ASSIGNED_TASK_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.ASSIGNED_TASK_DETAILS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.ASSIGNED_TASK_DETAILS_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.ASSIGNED_TASK_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};
const updateAssignedTasks = (updateData) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(updateData);
      var config = {
        method: "put",
        url: apiUrl + "/task/updatetask",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_FAIL, payload: error.response.data.message });
          } else if (error.response?.status === 409) {
            dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateAssignedTasks = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.UPDATE_ASSIGNED_TASKS_RESET, payload: {} });
  };
};
const createAssignedTasks = (updateData) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(updateData);
      var config = {
        method: "post",
        url: apiUrl + "/task/createtask",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_FAIL, payload: error.response.data.message });
          } else if (error.response?.status === 409) {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateAssignedTasks = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.CREATE_ASSIGNED_TASKS_RESET, payload: {} });
  };
};
const deleteDefinedTasks = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/task/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.DELETE_DEFINED_TASK_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TaskActionType.DELETE_DEFINED_TASK_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TaskActionType.DELETE_DEFINED_TASK_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TaskActionType.DELETE_DEFINED_TASK_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.DELETE_DEFINED_TASK_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteDefinedTasks = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.DELETE_DEFINED_TASK_RESET, payload: {} });
  };
};
const getMyTasksList = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_MY_TASKS_LIST_REQUEST });
    const data = JSON.stringify(searchData);
    try {
      var config = {
        method: "post",
        url: apiUrl + "/task/mytasks",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.GET_MY_TASKS_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.GET_MY_TASKS_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TaskActionType.GET_MY_TASKS_LIST_FAIL, payload: emptyData });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.GET_MY_TASKS_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const getTaskAssignedList = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_TASKS_ASSIGNED_LIST_REQUEST });
    const data = JSON.stringify(searchData);
    try {
      var config = {
        method: "post",
        url: apiUrl + "/task/assignedtasklist",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.GET_TASKS_ASSIGNED_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.GET_TASKS_ASSIGNED_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404 || error.response?.status === 500) {
            const emptyData = {
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TaskActionType.GET_TASKS_ASSIGNED_LIST_FAIL, payload: emptyData });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.GET_TASKS_ASSIGNED_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const getAssignedTasksStepList = (resourceId) => {
  const id = resourceId !== undefined && resourceId !== "" && typeof resourceId !== "undefined" ? resourceId : "";
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_STEP_LIST_TASKS_REQUEST });
    try {
      var config = {
        method: "GET",
        url: apiUrl + "/task/persontasks?resourceId=" + id,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.GET_STEP_LIST_TASKS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.GET_STEP_LIST_TASKS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.GET_STEP_LIST_TASKS_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.GET_STEP_LIST_TASKS_FAIL, payload: error.message });
      }
    }
  };
};
const resetAssignedTasksStepList = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_STEP_LIST_TASKS_RESET, payload: {} });
  };
};
const getCreateAssignedForm = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.CREATE_ASSIGNED_FORM_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/task/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_FORM_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.CREATE_ASSIGNED_FORM_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.CREATE_ASSIGNED_FORM_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.CREATE_ASSIGNED_FORM_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateAssignedForm = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.RESET_CREATE_ASSIGNED_FORM, payload: {} });
  };
};
const assignedTaskAndAdvanceAction = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/task/assigned",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_FAIL, payload: error.message });
      }
    }
  };
};
const resetAssignedTaskAndAdvanceAction = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.ASSIGNED_TASK_AND_ADVANCE_ACTION_RESET, payload: {} });
  };
};

const getTaskDescriptionDetail = (groupId, personResourceId, ownerShipTypeId) => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_TASK_DESCRIPTION_DETAIL_REQUEST });
    try {
      var config = {
        method: "get",
        url:
          apiUrl +
          "/task/persontaskinformation/" +
          groupId +
          "/?personResourceId=" +
          personResourceId +
          "&ownerShipTypeId=" +
          ownerShipTypeId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.GET_TASK_DESCRIPTION_DETAIL_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.GET_TASK_DESCRIPTION_DETAIL_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.GET_TASK_DESCRIPTION_DETAIL_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.GET_TASK_DESCRIPTION_DETAIL_FAIL, payload: error.message });
      }
    }
  };
};
const resetGetTaskDescriptionDetail = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_TASK_DESCRIPTION_DETAIL_RESET, payload: {} });
  };
};
const taskUpdateStatus = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/task/state-update",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.TASK_UPDATE_STATUS_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: TaskActionType.TASK_UPDATE_STATUS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.TASK_UPDATE_STATUS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.TASK_UPDATE_STATUS_FAIL, payload: error.message });
      }
    }
  };
};
const resetTaskUpdateStatus = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.TASK_UPDATE_STATUS_RESET, payload: {} });
  };
};
const getAssignedTaskInformation = (
  groupId,
  personResourceId,
  ownerResourceId,
  appointedResourceId,
  ownerShipTypeId,
) => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_ASSIGNED_TASK_INFORMATION_REQUEST });
    try {
      var config = {
        method: "get",
        url:
          apiUrl +
          "/task/assignedtaskinformation/" +
          groupId +
          "?personResourceId=" +
          personResourceId +
          "&ownerShipResourceId=" +
          ownerResourceId +
          "&appointedResourceId=" +
          appointedResourceId +
          "&ownerShipTypeId=" +
          ownerShipTypeId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.GET_ASSIGNED_TASK_INFORMATION_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.GET_ASSIGNED_TASK_INFORMATION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.GET_ASSIGNED_TASK_INFORMATION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.GET_ASSIGNED_TASK_INFORMATION_FAIL, payload: error.message });
      }
    }
  };
};
const resetGetAssignedTaskInformation = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.GET_ASSIGNED_TASK_INFORMATION_RESET, payload: {} });
  };
};
const completeAllTasks = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/task/completed-all-task",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.COMPLETE_ALL_TASK_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: TaskActionType.COMPLETE_ALL_TASK_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.COMPLETE_ALL_TASK_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.COMPLETE_ALL_TASK_FAIL, payload: error.message });
      }
    }
  };
};
const resetCompleteAllTasks = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.COMPLETE_ALL_TASK_RESET, payload: {} });
  };
};
const definedTaskUpdate = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/task/update-assigned-task-option",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.DEFINED_TASK_UPDATE_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: TaskActionType.DEFINED_TASK_UPDATE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.DEFINED_TASK_UPDATE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.DEFINED_TASK_UPDATE_FAIL, payload: error.message });
      }
    }
  };
};
const resetDefinedTaskUpdate = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.DEFINED_TASK_UPDATE_RESET, payload: {} });
  };
};
const deleteAssignedTasks = (confirmData) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url:
          apiUrl +
          "/task/deleteassignedtask/" +
          confirmData.groupId +
          "?personResourceId=" +
          confirmData.personResourceId +
          "&ownerShipResourceId=" +
          confirmData.ownerResourceId +
          "&appointedResourceId=" +
          confirmData.appointResourceId +
          "&ownerShipTypeId=" +
          confirmData.ownerShipTypeId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TaskActionType.DELETE_ASSIGNED_TASK_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TaskActionType.DELETE_ASSIGNED_TASK_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TaskActionType.DELETE_ASSIGNED_TASK_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TaskActionType.DELETE_ASSIGNED_TASK_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.DELETE_ASSIGNED_TASK_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteAssignedTasks = () => {
  return async (dispatch) => {
    dispatch({ type: TaskActionType.DELETE_ASSIGNED_TASK_RESET, payload: {} });
  };
};
const taskStatesFilter = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "get",
        url: apiUrl + "/task/states",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TaskActionType.TASK_STATES_FILTER_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TaskActionType.TASK_STATES_FILTER_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TaskActionType.TASK_STATES_FILTER_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TaskActionType.TASK_STATES_FILTER_FAIL, payload: error.message });
      }
    }
  };
};
export {
  TaskActionType,
  setCreateDefinedTaskDrawer,
  setCreateTaskDrawer,
  setDetailDefinedTaskDrawer,
  setAssignedTaskDetailDrawer,
  setMyTaskDetailDrawer,
  getAssignedTasksList,
  updateOrderAssignedTasksList,
  resetUpdateOrderAssignedTasksList,
  updateAssignedTasks,
  resetUpdateAssignedTasks,
  getAssignedTaskDetails,
  createAssignedTasks,
  resetCreateAssignedTasks,
  deleteDefinedTasks,
  resetDeleteDefinedTasks,
  getMyTasksList,
  getTaskAssignedList,
  getAssignedTasksStepList,
  resetAssignedTasksStepList,
  getCreateAssignedForm,
  resetCreateAssignedForm,
  assignedTaskAndAdvanceAction,
  resetAssignedTaskAndAdvanceAction,
  getTaskDescriptionDetail,
  resetGetTaskDescriptionDetail,
  taskUpdateStatus,
  resetTaskUpdateStatus,
  getAssignedTaskInformation,
  resetGetAssignedTaskInformation,
  completeAllTasks,
  resetCompleteAllTasks,
  definedTaskUpdate,
  resetDefinedTaskUpdate,
  deleteAssignedTasks,
  resetDeleteAssignedTasks,
  taskStatesFilter,
};
