const initialState = {
    countries: [],
    provinces: [],
    districts:[],
    neighborhoods:[],
    nationalities:[],
    TaxId:[],
    provinceFilterResult:{
        results:[]
    },
    districtFilterResult:{
        results:[]
    },
    isDistrictFilterLoading:false,
    isProvinceFilterLoading:false,

}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {

        case 'GET_COUNTRY_SUCCESS':
            return {
                ...state,
                countries: action.payload,
        }
        case 'GET_PROVINCES_SUCCESS':
            return {
                ...state,
                provinces: action.payload,
        }
        case 'GET_DISTRICTS_SUCCESS':
            return {
                ...state,
                districts: action.payload,
        }
        case 'GET_NEIGBORHOODS_SUCCESS':
            return {
                ...state,
                neighborhoods: action.payload,
        }
        case 'GET_NATIONALITIES_SUCCESS':
            return {
                ...state,
                nationalities: action.payload,
        }
        case 'GET_TAX__OFFICIES_SUCCESS':
            return {
                ...state,
                TaxId: action.payload,
        }
        case 'PROVINCE_FILTER_REQUEST':
            return {
                ...state,
                isProvinceFilterLoading: true
            };
        case 'PROVINCE_FILTER_SUCCESS':
            return {
                ...state,
                provinceFilterResult: action.payload,
                isProvinceFilterLoading: false
            }
        case 'PROVINCE_FILTER_FAIL':
            return {
                ...state,
                isProvinceFilterLoading: false
            }
        case 'DISTRICT_FILTER_SUCCESS':
            return {
                ...state,
                districtFilterResult: action.payload,
                isDistrictFilterLoading: false
            }
        case 'DISTRICT_FILTER_FAIL':
            return {
                ...state,
                isDistrictFilterLoading: false
            }     
        case 'RESET_ALL_ADDRESS':
            return initialState;
            
        default: return state
    }

}
