import { useState } from 'react';
import { Layout, Row, Col, Input, Button } from 'antd';
import { SearchOutlined, UserAddOutlined, PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ArrowDownIcon } from '../../features/Icon/Icons';
import BreadCrump from '../../features/breadcrump/breadcrump';
import SafeBtn from '../../features/helper/safe-btn';
import CallBtn from '../../features/helper/call-btn';
import HelperCard from '../../features/helper/card';
import Collapse from '../../features/Collapse/Collapse'
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import '../../styles/header.scss';
import '../../styles/helper.scss';
const { Content } = Layout;


function Help(props) {
    const [isopen1, setOpen1] = useState(false);
    const [isopen2, setOpen2] = useState(false);

    return (


        <Col span={24}>
            <Content className="page-container">
                <Row>
                    <Col span={6}>
                        <BreadCrump />
                    </Col>
                    <Col span={18}>
                        <SafeBtn />
                    </Col>
                </Row>
                <h1 className="page-title-1">Yardım</h1>
                <Row>
                    <Col span={21}>
                        <div className="helper-text">
                            <h1>Size yardımcı olabiliriz. Çözmenize yardımcı olabilmemiz için bize sorununuz hakkında bilgi verin.</h1>
                        </div>
                        <div className="page-search">

                            {/* <Input placeholder="Kişi, Özellik, Envantere Ara" prefix={<SearchOutlined />} /> */}
                        </div>
                    </Col>
                    <Col span={3}>
                        {/* <CallBtn /> */}
                    </Col>
                </Row>
                <div className="pad-21" />
                <HelperCard />
                <div className="collapse">
                    <div onClick={() => setOpen1(!isopen1)} className="collapse-title">
                        <h1>Yeni Kişi Nasıl Eklerim? </h1>
                    </div>
                    <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => setOpen1(!isopen1)} className={isopen1 === true ? "arrow-up" : "arrow"} /></div>
                </div>
                <Collapse isOpen={isopen1}>

                    {isopen1 === true &&
                        <div className="collapse-desc ">
                            <p>Ekranın sol kısmında yer alan menü içindeki <Button className="white-btn w-178 h-32 f-14 margin-left-12 margin-r-12 " icon={<UserAddOutlined style={{ fontSize: '15px' }} />}>Kişi Ekle</Button>
                                butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ <br /> üstte yer alan   <Button className="primary-btn w-178 h-32 f-1 4 margin-left-12 margin-r-12" icon={<PlusOutlined />}>Yeni Çalışan Oluştur</Button>
                                butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz.
                            </p>
                            <p>
                                Yeni kullanıcı oluşturmak için <a href="#add">tıklayınız</a>
                            </p>
                            <h1>
                                Bu bilgi size yardımcı oldu mu?
                                <Button className="transparent-btn f-16 collapse-question-text-success">Evet <CheckOutlined /></Button>
                                <Button className="transparent-btn f-16 collapse-question-text-error">Hayır  <CloseOutlined /></Button>
                            </h1>
                        </div>
                    }
                </Collapse>
                <div className="collapse">
                    <div onClick={() => setOpen2(!isopen2)} className="collapse-title">
                        <h1>Nasıl Yeni Envanter Tipi Oluşturabilirim? </h1>
                    </div>
                    <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => setOpen2(!isopen2)} className={isopen2 === true ? "arrow-up" : "arrow"} /></div>
                </div>

                <Collapse isOpen={isopen2}>

                    {isopen2 === true &&
                        <div className="collapse-desc ">
                            <p>TEST  </p>

                            <h1>
                                Bu bilgi size yardımcı oldu mu?
                                <Button className="transparent-btn f-16 collapse-question-text-success">Evet <CheckOutlined /></Button>
                                <Button className="transparent-btn f-16 collapse-question-text-error">Hayır  <CloseOutlined /></Button>
                            </h1>
                        </div>
                    }
                </Collapse>









                <div className="pad-55" />
            </Content >
        </Col>




    )

}
export default Help;
