import { Inventory } from "../actions/inventory.actions";
var initialState = {
  inventorySelectData: [],
  inventoryList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  isInventoryListLoading: false,
  addInventoryDrawer: false,
  inventoryAttribute: {},
  inventoryBrand: [],
  inventoryBrandLoading: true,
  inventoryCreateResponse: { status: "loading", message: "" },
  inventoryDetailsDrawer: false,
  inventoryDetailsInfo: {
    model: "",
    serialNumber: "",
    barcodeNumber: "",
    name: "",
    description: "",
    manufacturerName: "",
    statusId: null,
    inventoryType: {
      inventoryTypes: [],
      setInventoryType: {
        id: "",
        displayValue: "",
      },
    },
    inventoryAttributeResult: {
      attributeId: null,
      attributeName: "",
      status: null,
      attributeItems: [],
    },
    brand: {
      id: "",
      displayValue: "",
    },
    sku: "",
    mpn: "",
    nsn: "",
    isAccessory: null,
    expirationDate: "",
  },
  inventoryDetailsLoading: false,
  inventoryDetailsOwnerShipLoading: false,
  inventoryDetailsOwnerShipList: [],
  updateInventoryResponse: { status: "loading", message: "" },
  inventoryStatusResponse: { status: "loading", message: "" },
  deleteInventoryResponse: { status: "loading", message: "" },
  inventoryBrandCreateResponse: { status: "loading", message: "" },
  inventoryImportResponse: { status: "loading", data: "", errorIds: [] },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case Inventory.GET_INVENTORY_LIST_REQUEST:
      return {
        ...state,
        isInventoryListLoading: true,
      };
    case Inventory.GET_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        inventoryList: action.payload,
        isInventoryListLoading: false,
      };
    case Inventory.GET_INVENTORY_LIST_FAIL:
      return {
        ...state,
        isInventoryListLoading: false,
      };
    case Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_SUCCESS:
      return {
        ...state,
        inventorySelectData: action.payload,
      };
    case Inventory.GET_INVENTORY_BRAND_REQUEST:
      return {
        ...state,
        inventoryBrandLoading: true,
      };
    case Inventory.GET_INVENTORY_BRAND_SUCCESS:
      return {
        ...state,
        inventoryBrand: action.payload,
        inventoryBrandLoading: false,
      };
    case Inventory.GET_INVENTORY_BRAND_FAIL:
      return {
        ...state,
        inventoryBrandLoading: false,
      };
    case Inventory.RESET_INVENTORY_BRAND:
      return {
        ...state,
        inventoryBrand: [],
      };
    case Inventory.CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventoryCreateResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case Inventory.CREATE_INVENTORY_FAIL:
      return {
        ...state,
        inventoryCreateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case Inventory.CREATE_INVENTORY_INPUT_ERROR:
      return {
        ...state,
        inventoryCreateResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case Inventory.CREATE_INVENTORY_RESET:
      return {
        ...state,
        inventoryCreateResponse: {
          status: "loading",
          message: null,
        },
      };
    case Inventory.GET_INVENTORY_DETAILS_REQUEST:
      return {
        ...state,
        inventoryDetailsLoading: true,
      };
    case Inventory.GET_INVENTORY_DETAILS_SUCCESS:
      return {
        ...state,
        inventoryDetailsInfo: action.payload,
        inventoryDetailsLoading: false,
      };
    case Inventory.GET_INVENTORY_DETAILS_FAIL:
      return {
        ...state,
        inventoryDetailsLoading: false,
      };
    case Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_REQUEST:
      return {
        ...state,
        inventoryDetailsOwnerShipLoading: true,
      };
    case Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_SUCCESS:
      return {
        ...state,
        inventoryDetailsOwnerShipList: action.payload,
        inventoryDetailsOwnerShipLoading: false,
      };
    case Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_FAIL:
      return {
        ...state,
        inventoryDetailsOwnerShipLoading: false,
      };
    case Inventory.UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        updateInventoryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case Inventory.UPDATE_INVENTORY_FAIL:
      return {
        ...state,
        updateInventoryResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case Inventory.UPDATE_INVENTORY_INPUT_ERROR:
      return {
        ...state,
        updateInventoryResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case Inventory.UPDATE_INVENTORY_RESET:
      return {
        ...state,
        updateInventoryResponse: {
          status: "loading",
          message: null,
        },
      };
    case Inventory.INVENTORY_STATUS_SUCCESS:
      return {
        ...state,
        inventoryStatusResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case Inventory.INVENTORY_STATUS_FAIL:
      return {
        ...state,
        inventoryStatusResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case Inventory.INVENTORY_STATUS_RESET:
      return {
        ...state,
        inventoryStatusResponse: {
          status: "loading",
          message: null,
        },
      };
    case Inventory.DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        deleteInventoryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case Inventory.DELETE_INVENTORY_FAIL:
      return {
        ...state,
        deleteInventoryResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case Inventory.DELETE_INVENTORY_RESET:
      return {
        ...state,
        deleteInventoryResponse: {
          status: "loading",
          message: null,
        },
      };
    case "SET_ADD_INVENTORY_DRAWER":
      return {
        ...state,
        addInventoryDrawer: action.payload,
      };
    case "SET_INVENTORY_DETAILS_DRAWER":
      return {
        ...state,
        inventoryDetailsDrawer: action.payload,
      };
    case Inventory.GET_INVENTORY_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        inventoryAttribute: action.payload,
      };
    case Inventory.CREATE_INVENTORY_BRAND_SUCCESS:
      return {
        ...state,
        inventoryBrandCreateResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case Inventory.CREATE_INVENTORY_BRAND_FAIL:
      return {
        ...state,
        inventoryBrandCreateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case Inventory.CREATE_INVENTORY_BRAND_INPUT_ERROR:
      return {
        ...state,
        inventoryBrandCreateResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case Inventory.CREATE_INVENTORY_BRAND_RESET:
      return {
        ...state,
        inventoryBrandCreateResponse: {
          status: "loading",
          message: null,
        },
      };
    case Inventory.IMPORT_INVENTORY_SUCCESS:
      return {
        ...state,
        inventoryImportResponse: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case Inventory.IMPORT_INVENTORY_FAIL:
      return {
        ...state,
        inventoryImportResponse: {
          status: "FAIL",
          data: action.payload,
        },
      };
    case Inventory.IMPORT_INVENTORY_RESET:
      return {
        ...state,
        inventoryImportResponse: {
          status: "loading",
          data: null,
        },
      };
    default:
      return state;
  }
}
