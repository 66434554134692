import { OrganizationDetailActionType } from "../actions/organizationDetail.actions";

const organizationDetailState = {
  organizationDetailsInfo: {
    organizationType: {
      id: "",
      displayValue: "",
    },
    name: "",
    legalName: "",
    parentOrganization: {
      id: "",
      displayValue: "",
    },
    sector: {
      id: "",
      displayValue: "",
    },
    manager: {
      id: "",
      displayValue: "",
    },
    telephone: "",
    phoneExt: "",
    email: "",
    pageUrl: "",
    state: {
      id: "",
      displayValue: "",
    },
    domainName: "",
    address: {
      country: {
        id: "",
        displayValue: "",
      },
      province: {
        id: "",
        displayValue: "",
      },
      district: {
        id: "",
        displayValue: "",
      },
      neighborhood: {
        id: "",
        displayValue: "",
      },
      addressLine: "",
      postalCode: "",
      geo: null,
      locality: "",
      region: "",
    },
    billingAddress: {
      country: {
        id: "",
        displayValue: "",
      },
      province: {
        id: "",
        displayValue: "",
      },
      district: {
        id: "",
        displayValue: "",
      },
      neighborhood: {
        id: "",
        displayValue: "",
      },
      taxOffice: {
        id: "",
        displayValue: "",
      },
    },
  },
  updateResponse: { status: "loading", message: "" },
  organizationDetailsInfoIsFetching: true,
};

const organizationDetailsReducer = (state = organizationDetailState, action) => {
  switch (action.type) {
    case OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_REQUEST:
      return {
        ...state,
        organizationDetailsInfoIsFetching: true,
      };

    case OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_SUCCESS:
      return {
        ...state,
        organizationDetailsInfo: action.payload,
        organizationDetailsInfoIsFetching: false,
      };
    case OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_FAIL:
      return {
        ...state,
        organizationDetailsInfoIsFetching: false,
      };
    case OrganizationDetailActionType.GET_ORGANIZATION_RESET:
      return {
        ...state,
        organizationDetailsInfoIsFetching: true,
        organizationDetailsInfo: {
          organizationType: {
            id: "",
            displayValue: "",
          },
          name: "",
          legalName: "",
          parentOrganization: {
            id: "",
            displayValue: "",
          },
          sector: {
            id: "",
            displayValue: "",
          },
          manager: {
            id: "",
            displayValue: "",
          },
          telephone: "",
          phoneExt: "",
          email: "",
          pageUrl: "",
          state: {
            id: "",
            displayValue: "",
          },
          domainName: "",
          address: {
            country: {
              id: "",
              displayValue: "",
            },
            province: {
              id: "",
              displayValue: "",
            },
            district: {
              id: "",
              displayValue: "",
            },
            neighborhood: {
              id: "",
              displayValue: "",
            },
            addressLine: "",
            postalCode: "",
            geo: null,
            locality: "",
            region: "",
          },
          billingAddress: {
            country: {
              id: "",
              displayValue: "",
            },
            province: {
              id: "",
              displayValue: "",
            },
            district: {
              id: "",
              displayValue: "",
            },
            neighborhood: {
              id: "",
              displayValue: "",
            },
            taxOffice: {
              id: "",
              displayValue: "",
            },
          },
        },
      };
    case OrganizationDetailActionType.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        updateResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case OrganizationDetailActionType.UPDATE_ORGANIZATION_FAIL:
      return {
        ...state,
        updateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case OrganizationDetailActionType.UPDATE_ORGANIZATION_INPUT_ERROR:
      return {
        ...state,
        updateResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case OrganizationDetailActionType.UPDATE_ORGANIZATION_RESET_STATUS:
      return {
        ...state,
        updateResponse: { status: "loading", message: "" },
      };

    default:
      return state;
  }
};

export default organizationDetailsReducer;
