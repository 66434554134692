import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteOrganizationWorkHours, deleteOrganizationWorkHoursReset, getWorkHourDetailsByDefinitions, makeDefaultOrganizationWorkHours, makeDefaultOrganizationWorkHourReset } from '../../redux/actions/organization.actions';

import { Card, Col, Row, Button, List, Dropdown, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UilCheck } from '@iconscout/react-unicons'
import { MoreOutlined } from '@ant-design/icons';
import { noData } from '../../utils/commonFormFunctions';
import { workHoursListDropdown } from '../../features/global-dropdown-menu/index';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';
import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";
import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';

function JobList(props) {
    const { workHourDetailsByDefinitions, workHourDetailsByDefinitionsLoading, createWorkingHoursDraw, detailWorkingHoursDraw, permissions,
        deleteWorkHoursResponse, deleteOrganizationWorkHours, deleteOrganizationWorkHoursReset, getWorkHourDetailsByDefinitions, makeDefaultOrganizationWorkHours,
        makeDefaultOrganizationWorkHourReset, makeDefaultWorkHoursResponse } = props;
    const { t } = useTranslation();
    const [deleteModalState, setDeleteModalState] = useState({ 'isVisible': false })
    const [makeDefaultState, setMakeDefaultState] = useState({ 'isVisible': false })
    const deleteRequest = (id, name) => {
        setDeleteModalState({ ...deleteModalState, 'id': id, 'name': name, 'isVisible': true })
    }
    const makeDefault = (id, name) => {
        setMakeDefaultState({ ...makeDefaultState, 'id': id, 'name': name, 'isVisible': true })
    }
    useEffect(() => {
        if (deleteWorkHoursResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': deleteWorkHoursResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getWorkHourDetailsByDefinitions();
            deleteOrganizationWorkHoursReset()
        } else if (deleteWorkHoursResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': deleteWorkHoursResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteOrganizationWorkHoursReset();
        }
    }, [deleteWorkHoursResponse])
    useEffect(() => {
        if (makeDefaultWorkHoursResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': makeDefaultWorkHoursResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getWorkHourDetailsByDefinitions();
            makeDefaultOrganizationWorkHourReset()
        } else if (makeDefaultWorkHoursResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': makeDefaultWorkHoursResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            makeDefaultOrganizationWorkHourReset();
        }
    }, [makeDefaultWorkHoursResponse])


        // #region OnBoarding
        const [popoverVisible, setPopoverVisible] = useState(false);
        const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
        const handlePopoverVisibleChange = (visible) => {
            if(visible == false){ props.setCurrentTaskState(null); }
            setPopoverVisible(visible); 
        };
        
        OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'workHoursTab' );
          
        useEffect(() => {
            if(props.currentTab == 'WorkHours' && props.currentTaskState == 41 && workHourDetailsByDefinitionsLoading){
                setPopoverVisible(true)
            }
            if(props.currentTaskState == 42 && workHourDetailsByDefinitions?.definitions?.length > 0){
                detailWorkingHoursDraw(workHourDetailsByDefinitions?.definitions[0]?.definitionId);
                setPopoverVisible(false)
            }
        }, [workHourDetailsByDefinitionsLoading,props.currentTab, props.currentTaskState])

        // #endregion

        


    
    //
    // const handleTableChange = (data) => {
    //const paginationObj = { ...searchData.pagination, 'pageNumber': data.page, 'pageSize': data.pageSize }
    //setSearchData({ ...searchData, 'pagination': paginationObj })
    // }
    // const customTableChange = (page, pageSize) => {
    //     handleTableChange({ "page": page, "pageSize": pageSize })
    // }
    // const paginationConfig = () => ({
    //     total: dummyPageData.totalNumberOfRecords,
    //     showSizeChanger: true,
    //     current: dummyPageData.pageNumber,
    //     pageSize: dummyPageData.pageSize,
    //     locale: { items_per_page: "/ " + t('PAGE') },
    //     onChange: (a, b) => customTableChange(a, b),
    //     position: dummyPageData.totalNumberOfRecords < 10 ? ['none', 'none'] : ''
    // })
    return (
        <div key={props.currentTab} className="custom-table-card-container job-list-container workhours-container">
            <DynamicRequestModal
                modalVisible={deleteModalState?.isVisible}
                setStateData={setDeleteModalState}
                postAction={deleteOrganizationWorkHours}
                confirmData={deleteModalState?.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_WORKHOURS_MODAL', { "name": deleteModalState?.name })}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <DynamicRequestModal
                modalVisible={makeDefaultState?.isVisible}
                setStateData={setMakeDefaultState}
                postAction={makeDefaultOrganizationWorkHours}
                confirmData={makeDefaultState?.id}
                iconName='UilQuestionCircle'
                modalTitle={t('DEFAULT_TIME')}
                description={t('MAKE_DEFAULT_WORKHOURS_MODAL', { "name": makeDefaultState?.name })}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('MAKE_DEFAULT')}
            />
            <div className="custom-table-card-add">
                {permissions.includes('organization.workhour.update') &&
                    <Button onClick={() => createWorkingHoursDraw()} className="primary-btn m-w-180 h-32"><PlusOutlined /> {t('CREATE_NEW_WORKING_HOUR')}</Button>
                }
            </div>

            <div className="custom-table-card-class">

                {workHourDetailsByDefinitions?.definitions?.length > 0 ?
                    <div className="custom-table-card-title-class workhours-list-column">
                        <Col span={24}>
                            <Row>
                                <Col span={14}>
                                    <h1>{t('WORKING_HOUR_NAME')}</h1>
                                </Col>
                                <Col span={6}>
                                    <h1 className="flex-center">{t('DEFAULT_TIME')}</h1>
                                </Col>
                                <Col span={4} />
                            </Row>
                        </Col>
                    </div>
                    : ''}
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={null}
                    dataSource={workHourDetailsByDefinitions?.definitions}
                    loading={workHourDetailsByDefinitionsLoading}
                    className={workHourDetailsByDefinitions?.definitions?.length === 0 ? 'table-border-bottom-0' : 'custom-table-list'}
                    locale={{ emptyText: (<div className="loading-container">{noData(t)}</div>) }}
                    renderItem={(data,index) => (
                        <List.Item
                            key={data?.definitionId + 'fs'}
                            className="job-list-item"
                        >
                            <Card key={data?.resourceId} className="custom-table-card workhours-list-card" >
                                <Col span={24}>
                                    <Row>
                                        <Col span={14}>
                                            <p onClick={() => detailWorkingHoursDraw(data?.definitionId)} className="workhours-title">{data?.name}</p>
                                        </Col>

                                        <Col span={6}>
                                            <div className="workhours-check-class">
                                                {data.isDefault && <UilCheck />}
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            {permissions.includes('organization.workhour.update') &&
                                                <div className="custom-table-card-icon  clickable">
                                                {popoverVisible && index == 0 ? 
                                                    <Popover
                                                    key={'work-hours-tab'}
                                                    overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : 'onboard-nav-popover-wrapper'}
                                                    content={<PopoverContent key={props.currentTaskState} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                                    title={false}
                                                    placement="left"
                                                    trigger="click"
                                                    visible={popoverVisible}
                                                    onVisibleChange={handlePopoverVisibleChange}
                                                >
                                                        <Dropdown
                                                            visible={true}
                                                            className="action-menu"
                                                            overlay={workHoursListDropdown(t, detailWorkingHoursDraw, makeDefault, deleteRequest, data?.name, data?.definitionId, data.isDefault, true)}
                                                            trigger={['click']}
                                                        >
                                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                        </Dropdown>
                                                </Popover>
                                                 : 
                                                 <Dropdown
                                                            className="action-menu"
                                                            overlay={workHoursListDropdown(t, detailWorkingHoursDraw, makeDefault, deleteRequest, data?.name, data?.definitionId, data.isDefault)}
                                                            trigger={['click']}
                                                        >
                                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                </Dropdown>
                                                }
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        permissions: state.profile.permissions,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions,
        workHourDetailsByDefinitionsLoading: state.organization.workHourDetailsByDefinitionsLoading,
        deleteWorkHoursResponse: state.organization.deleteWorkHoursResponse,
        makeDefaultWorkHoursResponse: state.organization.makeDefaultWorkHoursResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteOrganizationWorkHours: (resourceId) => { dispatch(deleteOrganizationWorkHours(resourceId)) },
        makeDefaultOrganizationWorkHours: (resourceId) => { dispatch(makeDefaultOrganizationWorkHours(resourceId)) },
        makeDefaultOrganizationWorkHourReset: () => { dispatch(makeDefaultOrganizationWorkHourReset()) },
        deleteOrganizationWorkHoursReset: () => { dispatch(deleteOrganizationWorkHoursReset()) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobList);