const initialState = {
    paymentTypes:[],
    genders:[],
    militaryServiceTypes:[],
    unitTypes:[],
    accrualDateTypes:[],
    accrualUnitTypes:[],
    accrualDurationTypes:[],
    transferTypes:[],
    renewalTypes:[],
    stateTypes:[],
    remoteWorkTypes:[],
    maritalStatusTypes:[],
    coupleWorkStatuses:[],
    disabilityDegries:[],
    bloodGroups:[],
    educationDegries:[],
    workStatuses:[],
    workTypes:[],
    workStates: [],
    organizationTypes:[],
    ownertypes:[],
    sectorTypes:[],
    languageTypes:[],
    currencyTypes:[],
    personStatusTypes:[],
    salaryReasonTypes:[],
    assigmentStateTypes:[],
    inventoryStateTypes:[],
    inventoryFullStateTypes:[],
    emergencyContactDegreeList:[],
    drivingLicenceTypes:[],
    timeOffStateType:{
        totalNumberOfRecords:0,
        results:[]
    },
    dismissalTypes:[],
    expenseTypes:[],
    processType:[],
    processPersonType:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case 'GET_PAYMENT_TYPE_SUCCESS':
            return {
                ...state,
                paymentTypes: action.payload,
            }
        case 'GET_GENDER_SUCCESS':
            return {
                ...state,
                genders: action.payload,
            }
        case 'GET_MILITARY_SERVICE_TYPE_SUCCESS':
            return {
                ...state,
                militaryServiceTypes: action.payload,
            }
        case 'GET_UNIT_TYPE_SUCCESS':
            return {
                ...state,
                unitTypes: action.payload,
            }
        case 'GET_ACCRUAL_DATE_TYPE_SUCCESS':
            return {
                ...state,
                accrualDateTypes: action.payload,
            }
        case 'GET_ACCRUAL_DURATION_TYPE_SUCCESS':
            return {
                ...state,
                accrualDurationTypes: action.payload,
            }
        case 'GET_ACCRUAL_UNIT_TYPE_SUCCESS':
            return {
                ...state,
                accrualUnitTypes: action.payload,
            }
        case 'GET_TRANSFER_TYPE_SUCCESS':
            return {
                ...state,
                transferTypes: action.payload,
            }
        case 'GET_RENEWAL_TYPE_SUCCESS':
            return {
                ...state,
                renewalTypes: action.payload,
            }
        case 'GET_STATE_TYPE_SUCCESS':
            return {
                ...state,
                stateTypes: action.payload,
            }
        case 'GET_REMOTE_WORK_TYPE_SUCCESS':
            return {
                ...state,
                remoteWorkTypes: action.payload,
            }
        case 'GET_MARITAL_STATUS_TYPE_SUCCESS':
            return {
                ...state,
                maritalStatusTypes: action.payload,
            }
        case 'GET_COUPLE_WORK_STATUS_SUCCESS':
            return {
                ...state,
                coupleWorkStatuses: action.payload,
            }
        case 'GET_DISABILITY_DEGREE_SUCCESS':
            return {
                ...state,
                disabilityDegries: action.payload,
            }
        case 'GET_BLOOD_GROUP_SUCCESS':
            return {
                ...state,
                bloodGroups: action.payload,
            }
        case 'GET_EDUCATION_DEGREE_SUCCESS':
            return {
                ...state,
                educationDegries: action.payload,
            }
        case 'GET_WORK_STATUS_SUCCESS':
            return {
                ...state,
                workStatuses: action.payload,
            }
        case 'GET_WORK_TYPE_SUCCESS':
            return {
                ...state,
                workTypes: action.payload,
            }
        case 'GET_WORK_STATE_SUCCESS':
            return {
                ...state,
                workStates: action.payload,
            }
        case 'GET_ORGANIZATION_TYPE_SUCCESS':
            return {
                ...state,
                organizationTypes: action.payload,
            }
        case 'GET_OWNER_TYPE_SUCCESS':
            return {
                ...state,
                ownertypes: action.payload,
            }
        case 'GET_SECTOR_TYPE_SUCCESS':
            return {
                ...state,
                sectorTypes: action.payload,
            }
        case 'GET_LANGUAGE_TYPE_SUCCESS':
            return {
                ...state,
                languageTypes: action.payload,
            }
        case 'GET_CURRENCY_TYPE_SUCCESS':
            return {
                ...state,
                currencyTypes: action.payload,
            }
        case 'GET_PERSON_STATUS_TYPE_SUCCESS':
            return {
                ...state,
                personStatusTypes: action.payload,
            }
        case 'GET_SALARY_REASON_TYPE_SUCCESS':
            return {
                ...state,
                salaryReasonTypes: action.payload,
            }
        case 'GET_ASSIGMENT_STATE_TYPE_SUCCESS':
            return{
                ...state,
                assigmentStateTypes: action.payload,
            }
        case 'GET_INVENTORY_STATE_TYPE_SUCCESS':
            return{
                ...state,
                inventoryStateTypes: action.payload,
            }
        case 'GET_INVENTORY_ALL_STATE_TYPE_SUCCESS':
            return{
                ...state,
                inventoryFullStateTypes: action.payload,
            }    
        case 'GET_TIME_OFF_STATE_TYPE_SUCCESS':
            return {
                ...state,
                timeOffStateType: action.payload,
            }
        case 'GET_DISMISSAL_SUCCESS':
            return {
                ...state,
                dismissalTypes: action.payload,
            }
        case 'GET_EMERGENCY_DEGREE_LIST_SUCCESS':
            return {
                ...state,
                emergencyContactDegreeList: action.payload,
            }
        case 'GET_DRIVING_LICENCE_LIST_SUCCESS':
            return {
                ...state,
                drivingLicenceTypes: action.payload,
            } 
        case 'GET_EXPENSE_TYPE_LIST_SUCCESS':
            return {
                ...state,
                expenseTypes: action.payload,
            } 
        case 'GET_PROCESS_TYPE_SUCCESS':
            return {
                ...state,
                processType: action.payload,
            } 
        case 'GET_PROCESS_PERSON_TYPE_SUCCESS':
            return {
                ...state,
                processPersonType: action.payload,
            } 
        case 'DATASET_RESET':
            return initialState
            //DATASET_RESET
        default: return state
    }

}
