import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";

import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import {
    getPerson, updatePerson, getSalaryHistory, getJobHistory, updatePersonResponseReset,
    deletePerson, ownerShipSearch, getUserRoles, deleteAvatar, deleteAvatarResponseReset,
    dismissalPerson, search, getFileUploadList, doActivePerson, deleteAssignmentReset,
    resetUpdateDocumentKVKKAprove
} from '../../redux/actions/person.actions';

import { resetAction } from '../../redux/actions/personDetail.actions';
import { getPersonOrgChart, getOrganizationDocumentType, getWorkHourDetailsByDefinitions } from '../../redux/actions/organization.actions';
import { resetAddress } from '../../redux/actions/address.actions';
import { timeOffApprove, timeOffReject, timeOffCancel } from '../../redux/actions/timeOff.actions'
import {
    setAssignmentDetailTabStatus,
    setAssignmentDetailAddTabStatus, setAssignmentDetailAddOpenDraw,
    setSalaryDetailsOpenDraw, setSalaryAddOpenDraw, setAdvanceDetailOpenDraw,
    setAdvanceAddOpenDraw, setPersonAddOpenDraw, setCreateOwnerShipDrawer, setOwnerShipDetailsDrawer
} from '../../redux/actions/person-detail-tab-action';
import { setCreateTaskDrawer } from "../../redux/actions/task.actions";
import { getProfile } from "../../redux/actions/profile.actions";
import { getDataset } from "../../redux/actions/dataset-action";



import {
    Button, Image, Drawer, Col, Row, List, DatePicker,
    Tabs, Dropdown, Spin, Form, ConfigProvider, Modal, Popover
} from 'antd';
import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { UilEditAlt, UilTrash, UilUniversity, UilPhoneAlt, UilPostcard, UilEnvelope, UilTimesCircle, UilQuestionCircle, UilCheckCircle } from '@iconscout/react-unicons'
import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir

import { drawerWidth } from '../../utils/config';
import { personDetailsDropdown } from '../../features/global-dropdown-menu/index';
import { deepDiffMapper, MergeObjects, resetScrollPosition, regex } from '../../utils/commonFormFunctions'
import { dateFormat, dateFormatList, maskInputFormatterFormLabel, dateFormatConvert, apiUrl } from '../../utils/config';


// Tüm person details tabları
import BasicInformationTab from '../../component/personTabs/basic-information-tab';
import Authorization from '../../component/personTabs/authorization';
import PersonInformation from '../../component/personTabs/person-information-tab';
import OwnerShipList from '../../component/personTabs/owner-ship-list';
import OwnerShipAddTabs from '../../component/ownerShipDrawerTabs/add/ownership-all-tabs'
import AssignmentList from '../../component/personTabs/assignment-list.tab';
import AssignmentDetails from '../../component/personTabs/assignment-details-form';
import AssignmentDetailsAdd from '../../component/personTabs/assignment-details-add-form';
import SalaryInformation from '../../component/personTabs/salary-information-list';
import SalaryDetails from '../../component/personTabs/salary-information-details';
import AddSalaryInformation from '../../component/personTabs/salary-information-add';
import AdvanceRequestDetails from '../../component/personTabs/advance-request-details';
import AdvanceRequestAdd from '../../component/personTabs/advance-request-add';
import OwnerShipDetails from '../../component/personTabs/ownership-details';
import OwnerShipReturn from '../../component/ownerShipDrawerTabs/returnDrawer';
import DocumentsTab from '../../component/personTabs/documents-tab';
import EducationTab from '../../component/personTabs/education';
import PersonContact from '../../component/personTabs/person-contact-list';
import SocialMediaTab from '../../component/personTabs/socialmedia-tab';
import TimeOffTab from "../../component/personTabs/time-off-tab";
import TimeOffAproveTabs from '../../component/timeOffTabs/timeOffRequestPerson/timeOffApproveDrawer/timeOffApproveTabs';
import NewTimeOffRequestTabs from '../../component/timeOffTabs/add/time-off-all-tabs';

import AddUserTabs from '../../component/personTabs/add-user/add-user-show-all-tabs';
import PersonActionDrawer from '../../component/personTabs/delete-user/person-delete-drawer';
import ConnectedPersons from '../../component/personTabs/connectedPersons';
import ConnectedDepartment from '../../component/departmentDrawerTabs/connectedDepartment';
import ConnectedJobDrawer from '../../component/settingTabs/connectedJobDrawer';
import ConnectedTagDrawer from "../../component/settingTabs/connectedTagDrawer";
import Can from '../../authorization/index';
import Cannot from '../../authorization/cannot';

import DynamicRequestModal from '../../component/dynamicModal/requestModal'
import DynamicSelectbox from "../../component/dynamicSelectbox/index";
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import DynamicTimeOffModal from '../../component/dynamicTimeOffModal';


import OpenCreateTaskDrawer from "../../component/taskTabs/assignedTasks/add/create_task_all_tabs";

import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";
import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';

function PersonDrawerTabs(props) {
    const perm = 1; //Yetki'ye göre label veya input gelmesi 
    const [form] = Form.useForm()
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();

    const { TabPane } = Tabs;
    const [dismisAllForm] = Form.useForm()
    const {
        tabreducer, isPersonDetailDrawerVisible, timeOffDrawerReducer, deleteModalData, setDeleteModalData,
        selectedPersonId, getDataset, dismissalTypes, updatePersonResponseReset, resetAction, getPerson,
        getUserRoles, organizationTabreducer, personDeleteDrawer, updatePerson, person, isPersonLoading,
        updateResponse, getSalaryHistory, salaryHistory, getJobHistory, dismissalPerson, jobHistory, doActivePersonResponse,
        search, searchData, resetAddress, deletePerson, deletePersonResponse, getPersonOrgChart, profile, getProfile,
        ownerShipSearch, permissions, auth, ownershipTabReducer, deleteAvatar, deleteAvatarResponseReset, deleteAvatarResponse, jobConnectDrawer, connectTagDrawer,
        timeOffApprove, timeOffReject, timeOffCancel, deleteAssignmentReset, deleteAssignmentResponse,
        getOrganizationDocumentType, getWorkHourDetailsByDefinitions } = props;
    const { canDelete, canPassive, canDismall, canActive, doActivePerson, resetUpdateDocumentKVKKAprove,
        updateDocumentKVKKAproveResponse } = props;
    const { setCreateOwnerShipDrawer, setIsPersonDetailDrawerVisible,
        setAdvanceDetailOpenDraw, setAssignmentDetailAddTabStatus, setOwnerShipDetailsDrawer,
        taskCreateDrawerStatus, setCreateTaskDrawer } = props
    const access_token = auth.user?.access_token;

    const [currentTab, setCurrentTab] = useState("PersonBasicInfo");
    const [componentKey, setcomponentKey] = useState(1);
    const [addOwnerShip, setAddOwnerShip] = useState(700);
    const [focusOnEmail, setFocusOnEmail] = useState(false);
    const [selectedOwnerShipResourceId, setSelectedOwnerShipResourceId] = useState(null);
    const [selectedOwnerShipAssigmentDate, setSelectedOwnerShipAssigmentDate] = useState(null);
    const [returnComponentKey, setReturnComponentKey] = useState(500);
    const [connectedDepartmentKey, setConnectedDepartmentKey] = useState(100);
    const [connectJobKey, setConnectJobKey] = useState(300);
    const [connectTagKey, setConnectTagKey] = useState(400);
    const [connectedPersonKey, setConnectedPersonKey] = useState(1000);
    const [deleteAvatarModalData, setDeleteAvatarModalData] = useState({ "isVisible": false });
    const [leaveResonType, setLeaveResonType] = useState([])
    const [dissmissalState, setDismissalState] = useState({})
    const [typeData, setTypeData] = useState(null);
    const [personUpdateObj, setPersonUpdateObj] = useState({})
    const [personRef, setpersonRef] = useState("{}");
    const [selectedTimeOff, setSelectedTimeOff] = useState();
    const [deleteTimeOffModalData, setDeleteTimeOffModalData] = useState({ "isVisible": false });
    const [ApproveModalData, setApproveModalData] = useState({ "isVisible": false });
    const [cancelModalData, setCancelModalData] = useState({ "isVisible": false });
    const [statusModalData, setStatusModalData] = useState({ "isVisible": false, "isAccept": null });
    const [componentKeyAdd, setComponentKeyAdd] = useState(500);
    const [hasChanged, setHasChanged] = useState(false);
    const [taskCreateDrawerKey, setTaskCreateDrawerKey] = useState(10000);
    const [addUserPersonId, setAddUserPersonId] = useState("");
    const [showUpdateHireDateWarning,setShowHireDateUpdateWarning] = useState(false);
    const key = "timeOffDrawerTabs";
    const ownerShipInitialData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "Name",
            "ascending": true
        },
        "inventoryTypes": [],
        "status": "",
    }

    const handleApproveFormValuesChange = (changedObj, all) => {
        setApproveModalData((prevState) => ({
            ...prevState,
            'description': changedObj['description']
        }));
    }
    const handleConfirm = () => {
        if (ApproveModalData.confirmBtnFunc === 'SINGLE_APPROVE') {
            const approveObj = {
                "processStepList":
                    [
                        {
                            "timeOffRequestId": ApproveModalData.id,
                            "approveSeqNumber": ApproveModalData.approveSeqNumber,
                            "description": ApproveModalData.description
                        }
                    ]
            }
            timeOffApprove({ "approveData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'SINGLE_REJECT') {
            const approveObj = {
                "processStepList":
                    [
                        {
                            "timeOffRequestId": ApproveModalData.id,
                            "approveSeqNumber": ApproveModalData.approveSeqNumber,
                            "description": ApproveModalData.description
                        }
                    ]
            }
            timeOffReject({ "rejectData": approveObj, "key": key });
        } else if (ApproveModalData.confirmBtnFunc === 'SINGLE_CANCEL') {
            timeOffCancel({ "resourceId": selectedTimeOff.resourceId, "key": key },);
        }
    }
    const handleCloseModal = () => {
        setApproveModalData({ ...ApproveModalData, "isVisible": false, "description": null });
        form.setFieldsValue({ 'description': null });
    }
    const handleTimeOffChangeStatus = (statusObj, decision) => {
        if (decision === 'APPROVE') {
            var modalDesc = '';
            if (statusObj.personResourceId === profile.resourceId) {
                modalDesc = t('TIMEOFF_SINGLE_APPROVE_TEXT', { "person": statusObj.fullName, "requestDate": moment(statusObj.requestDate).format(dateFormatConvert), "timeOffTypeName": statusObj.timeOffTypeName })
            } else {
                modalDesc = t('TIMEOFF_SINGLE_APPROVE_TEXT_NEW', { "person": statusObj.fromName });
            }
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilCheckCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('TIME_OFF_REQUEST_CONFIRM'),
                "confirmBtnFunc": "SINGLE_APPROVE",
                "modalTitle": t('TIME_OFF_REQUEST_CONFIRM'),
                "modalDesc": modalDesc,
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj.id,
                "approveSeqNumber": statusObj.approveSeqNumber,
                "description": null,
                'descriptionShow': true
            });
        } else if (decision === 'REJECT') {
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilTimesCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('TIME_OFF_REQUEST_REJECT'),
                "confirmBtnFunc": "SINGLE_REJECT",
                "modalTitle": t('TIME_OFF_REQUEST_REJECT'),
                "modalDesc": t('TIMEOFF_SINGLE_REJECT_TEXT', { "person": statusObj.fullName, "requestDate": moment(statusObj.requestDate).format(dateFormatConvert), "timeOffTypeName": statusObj.timeOffTypeName }),
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj.id,
                "approveSeqNumber": statusObj.approveSeqNumber,
                "description": null,
                'descriptionShow': true
            });
        } else if (decision === 'CANCEL') {
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilQuestionCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_NO_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('PARTNER_YES_CANCEL'),
                "confirmBtnFunc": "SINGLE_CANCEL",
                "modalTitle": t('TIME_OFF_CANCEL_BTN'),
                "modalDesc": "",
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj,
                "approveSeqNumber": null,
                "description": null,
                'descriptionShow': false
            });
        }
    }
    const handleSetTypeData = (d) => {
        setTypeData(d);
    }
    useEffect(() => {
        setLeaveResonType(dismissalTypes)
    }, [dismissalTypes]);

    useEffect(() => {
        if (tabreducer.personAddOpenDraw) {
            setComponentKeyAdd((i) => i + 1)
        }
    }, [tabreducer])

    function callback(key) {
        setCurrentTab(key);
        resetAddress()
        if (key === 'ownerShip') {
            ownerShipSearch(selectedPersonId, ownerShipInitialData);
        } else if (key === 'timeOffTab') {

        } else if (key === 'Salary') {
            getSalaryHistory(selectedPersonId)
        } else if (key === 'Roles') {
            // getUserRoles(selectedPersonId)
        } else if (key === 'JobHistory') {
            getJobHistory(selectedPersonId)
            getSalaryHistory(selectedPersonId)
        }
        if (key !== 'PersonBasicInfo' || key !== "contactTab") {
            setFocusOnEmail(false)
        }
        resetScrollPosition('.drawer-scrollable-tab > .ant-tabs-content-holder');
    }

    useEffect(() => {
        if (deletePersonResponse.status === 'SUCCESS') {
            hidePersonDetailDrawer()
        }
    }, [deletePersonResponse])

    useEffect(() => {
        hidePersonDetailDrawer();
    }, [doActivePersonResponse])

    useEffect(() => {
        setpersonRef(JSON.stringify({ ...person }))
    }, [person])

    useEffect(() => {
        setPersonUpdateObj(JSON.parse(personRef))
    }, [personRef])

    const hidePersonDetailDrawer = () => {
        setIsPersonDetailDrawerVisible(false)
        resetAction();
        resetAddress();
        setCurrentTab('PersonBasicInfo');
        getPersonOrgChart(profile.organization?.resourceId);
    }
    const goToCorporateEmail = () => {
        setCurrentTab('PersonBasicInfo');
        setFocusOnEmail(true)
    }
    const setPersonUpdateObject = (o) => {
        const diffObj = deepDiffMapper.map(JSON.parse(personRef), o, ["languages", "tags", "roles"]);
        
        if('hireDate' in diffObj){
            setShowHireDateUpdateWarning(true)
        }else{
            setShowHireDateUpdateWarning(false)
        }
        const mergedObj = MergeObjects(personUpdateObj, diffObj)
        setPersonUpdateObj(mergedObj)
        const hasChanged = document.getElementsByClassName("is-changed")[0] !== undefined || document.getElementsByClassName("is-checked")[0] !== undefined;
        setHasChanged(hasChanged);
    }
    const addOwnerShipDrawer = () => {
        setCreateOwnerShipDrawer(true)
        setAddOwnerShip((i) => i + 1)
    }
    const openOwnerShipDetails = () => {
        setcomponentKey((i) => i + 1)
    }
    const openCreateTaskDrawer = () => {
        setCreateTaskDrawer(true)
        setTaskCreateDrawerKey((i) => i + 1)
    }
    useEffect(() => {
        if (updateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'person_update_success_notification',
                'title': t('SUCCESS'),
                'description': updateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            if (personUpdateObj.status?.id !== person.status?.id) {
                hidePersonDetailDrawer();
            } else {
                getPerson(selectedPersonId)
                getUserRoles(selectedPersonId)
            }
            updatePersonResponseReset();
            search(searchData);
            getPersonOrgChart(profile.organization?.resourceId);
            if (selectedPersonId === profile.resourceId) {
                getProfile();
            }
        } else if (updateResponse.message.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'person_update_fail_notification',
                'title': t('ERROR'),
                'description': updateResponse.message.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            updatePersonResponseReset();
        } else if (updateResponse.message.status === 'FAILED') {
            DynamicToastMessage({
                'key': 'person_update_failed_notification',
                'title': t('ERROR'),
                'description': updateResponse.message.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            updatePersonResponseReset()
        } else if (updateResponse.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'person_update_validation_error_notification',
                'title': t('ERROR'),
                'description': <List>{updateResponse.message.validationMessages.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            updatePersonResponseReset();
        }
    }, [updateResponse])

    const confirmChanges = () =>{
        if(showUpdateHireDateWarning){
            Modal.confirm({
                title: t('DO_YOU_CONFIRM_THIS_ACTION'),
                content: t('CHANGING_EMPLOYMENT_START_DATE_WARNING'),
                okText: t('TIME_OFF_REQUEST_CONFIRM'),
                okType: 'danger',
                cancelText: t('QUICK_ACTION_CANCEL'),
                onOk() {
                  // Kullanıcı "Onayla" butonuna tıkladığında çalışacak kod
                  
                  handleUpdatePerson()
                  // İşleminizi burada gerçekleştirin (örneğin, bir API isteği gönderme)
                },
                onCancel() {
                  // Kullanıcı "İptal" butonuna tıkladığında çalışacak kod
                  
                  return false
                },
              });
        }else{
            handleUpdatePerson()
        }
        
        
    }

    const handleUpdatePerson = () => {
        const careerStartDate = personUpdateObj.careerStartDate ?
            localStorage.getItem('i18nLang') === 'tr-TR' ?
                personUpdateObj.careerStartDate.split('/').reverse().join('-')
                :
                moment(personUpdateObj.careerStartDate).format(dateFormat)
            : null;
        const birthday = personUpdateObj.birthday ?
            localStorage.getItem('i18nLang') === 'tr-TR' ?
                personUpdateObj.birthday.split('/').reverse().join('-')
                :
                moment(personUpdateObj.birthday).format(dateFormat)
            : null;
        const data = {
            "identityNumber": personUpdateObj.identityNumber || null,
            "givenName": personUpdateObj.givenName || null,
            "familyName": personUpdateObj.familyName || null,
            "corporateEmail": personUpdateObj.corporateEmail || null,
            "photo": personUpdateObj.photo || process.env.REACT_APP_URL + 'images/pp2.png',
            "personalEmail": personUpdateObj.personalEmail || null,
            "cellPhone": personUpdateObj.cellPhone || null,
            "officePhone": personUpdateObj.officePhone || null,
            "JobName": personUpdateObj.job?.displayValue || null,
            "OrganizationResourceId": personUpdateObj.organization?.id,
            "DepartmentResourceId": personUpdateObj.department?.id,
            "OrganizationWorkDefinitionIdx": personUpdateObj.organizationWorkDefinition?.id || null,
            "ManagerResourceId": personUpdateObj.manager?.id || null,
            "workPhoneExt": personUpdateObj.workPhoneExt || null,
            "transferorBalance": personUpdateObj.transferorBalance || null,
            "hireDate": personUpdateObj.hireDate && personUpdateObj.hireDate !== 'Invalid date' ? moment(personUpdateObj.hireDate).format(dateFormat) : null,
            "trialPeriodDate": personUpdateObj.trialPeriodDate && personUpdateObj.trialPeriodDate !== 'Invalid date' ? moment(personUpdateObj.trialPeriodDate).format(dateFormat) : null,
            "birthday": personUpdateObj.birthday ? birthday : null,
            "careerStartDate": personUpdateObj.careerStartDate ? careerStartDate : null,
            "isStudent": personUpdateObj.isStudent || false,
            "ibanNumber": personUpdateObj.ibanNumber || null,
            "NationalIdentityNumber": personUpdateObj.nationalIdentityNumber || null,
            "PassportNumber": personUpdateObj.passportNumber || null,
            "leaveReason": personUpdateObj.leaveReason?.id || null,
            "leaveDate": personUpdateObj.leaveDate && personUpdateObj.leaveDate !== 'Invalid date' ? moment(personUpdateObj.leaveDate).format(dateFormat) : null,
            "twitterLink": personUpdateObj.socialMediaAccounts.find((item) => item.type === "Twitter")?.link || null,
            "facebookName": personUpdateObj.socialMediaAccounts.find((item) => item.type === "Facebook")?.link || null,
            "instagramName": personUpdateObj.socialMediaAccounts.find((item) => item.type === "Instagram")?.link || null,
            "linkedinUrl": personUpdateObj.socialMediaAccounts.find((item) => item.type === "Linkedin")?.link || null,
            "personalPageUrl": personUpdateObj.personalPageUrl || null,
            "maritalStatus": personUpdateObj.maritalStatus?.id || null,
            "coupleWorkStatus": personUpdateObj.maritalStatus?.id === "2" ? personUpdateObj.coupleWorkStatus?.id : null,
            "gender": personUpdateObj.gender?.id || null,
            "disabilityDegree": personUpdateObj.disabilityDegree?.id || null,
            "nationalityId": personUpdateObj.nationality?.id || null,
            "childrenCount": personUpdateObj.childrenCount || null,
            "bloodGroup": personUpdateObj.bloodGroup?.id || null,
            "educationDegree": personUpdateObj.educationDegree?.id || null,
            "Address": {
                "CountryId": personUpdateObj.address?.country?.id || null,
                "ProvinceId": personUpdateObj.address?.province?.id || null,
                "Province": personUpdateObj.address?.province || null,
                "DistrictId": personUpdateObj.address?.district?.id || null,
                "NeighborhoodId": personUpdateObj.address?.neighborhood?.id || null,
                "AddressLine": personUpdateObj.address?.addressLine || null
            },
            "status": personUpdateObj.status?.id,
            "Roles": personUpdateObj.roles.roles || null,
            "canLogin": personUpdateObj.canLogin || false,
            "emergencyContactPersonDegreeOfProximity": personUpdateObj.emergencyContactPersonDegreeOfProximity?.id || null,
            "emergencyContactPersonName": personUpdateObj.emergencyContactPersonName || null,
            "emergencyContactPersonPhone": personUpdateObj.emergencyContactPersonPhone || null,
            "drivingLicenceType": personUpdateObj.drivingLicenceType?.id || null,
            "drivingLicenceNumber": personUpdateObj.drivingLicenceType?.id ? personUpdateObj.drivingLicenceNumber : null,
            "militaryStatusType": personUpdateObj.gender?.id === "1" ? personUpdateObj.militaryStatusType?.id : null,
            "coupleBirthDate": (personUpdateObj.maritalStatus?.id === "2" && personUpdateObj.coupleBirthDate && personUpdateObj.coupleBirthDate !== 'Invalid date') ? moment(personUpdateObj.coupleBirthDate).format(dateFormat) : null,
            "maritalDate": (personUpdateObj.maritalStatus?.id === "2" && personUpdateObj.maritalDate && personUpdateObj.maritalDate !== 'Invalid date') ? moment(personUpdateObj.maritalDate).format(dateFormat) : null,
            "languages": personUpdateObj.languages && personUpdateObj.languages.map(item => item.id) || null,
            "Tags": personUpdateObj.tags && personUpdateObj.tags.map(item => item.id) || null,
        }
        const resourceId = personUpdateObj.resourceId
        let hasError = false
        let fields = []
        if ((personUpdateObj.corporateEmail && regex.test(personUpdateObj.corporateEmail) === false)) {
            hasError = true
            fields.push('( ' + t('PARTNER_CORPORATE_MAIL') + ' ) ' + t('PLEASE_ENTER_VALID_MAIL_ADDRESS'))
        }
        if ((personUpdateObj.personalEmail && regex.test(personUpdateObj.personalEmail) === false)) {
            hasError = true
            fields.push('( ' + t('PARTNER_PERSONEL_MAIL') + ' ) ' + t('PLEASE_ENTER_VALID_MAIL_ADDRESS'))
        }
        if ((!personUpdateObj.givenName || personUpdateObj.givenName === '')) {
            hasError = true
            fields.push('( ' + t('PARTNER_GIVENNAME') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }
        if (personUpdateObj.birthday && person.birthday !== personUpdateObj.birthday) {
            const today = moment(moment())
            const startDate = moment(personUpdateObj.birthday, maskInputFormatterFormLabel)
            const result = today.diff(startDate, 'years');
            const resultSecond = today.diff(startDate, 'second');
            if (result < 16 || resultSecond < 1 || isNaN(result)) {
                hasError = true
                fields.push('( ' + t('PARTNER_BIRTHDAY') + ' ) ' + t('INVALID_DATE'))
            }
        }
        if (personUpdateObj.careerStartDate && person.careerStartDate !== personUpdateObj.careerStartDate) {
            const today = moment(moment())
            const inputDate = moment(personUpdateObj.careerStartDate, maskInputFormatterFormLabel)
            const result = today.diff(inputDate, 'days');
            const resultSecond = today.diff(inputDate, 'second');
            if (result < 0 || resultSecond < 1 || isNaN(result)) {
                hasError = true
                fields.push('( ' + t('PARTNER_CAREER_START_DATE') + ' ) ' + t('INVALID_DATE'))
            }
        }
        if ((!personUpdateObj.familyName || personUpdateObj.familyName === '')) {
            hasError = true
            fields.push('( ' + t('PARTNER_FAMILYNAME') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }
        if ((!personUpdateObj.department?.id || personUpdateObj.department?.id === '')) {
            hasError = true
            fields.push('( ' + t('PARTNER_DEPARTMENT') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }
        if ((personUpdateObj.status?.id === "3" && (!personUpdateObj.leaveDate || personUpdateObj.leaveDate === "Invalid date"))) {
            hasError = true
            fields.push('( ' + t('PARTNER_LEAVE_DATE') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }
        if ((personUpdateObj.status?.id === "3" && (personUpdateObj.leaveReason === "" || !personUpdateObj.leaveReason))) {
            hasError = true
            fields.push('( ' + t('PARTNER_LEAVE_REASON') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }
        if (personUpdateObj.roles?.roles?.filter(item => item.hasAssign).length > 0 && personUpdateObj.corporateEmail === null) {
            hasError = true
            fields.push('( ' + t('CORPATE_EMAIL_ADDRESS_FOR_AUTH') + ' )')
        }
        if ((!personUpdateObj.hireDate || personUpdateObj.hireDate === "Invalid date")) {
            hasError = true
            fields.push('( ' + t('PARTNER_START_DATE_OF_COMPANY') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }
        if (!hasError) {
            updatePerson({ "resourceId": resourceId, "data": data })

            window.sendinblue?.track(
                //brevo_event_update_person
                'update_person',
            )

        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const uploadImgAction = (values) => {
        if (values?.file?.response) {
            if (values?.file?.response?.status === "SUCCESS") {
                DynamicToastMessage({
                    'key': 'upload_image_response_success',
                    'title': t('SUCCESS'),
                    'description': values?.file?.response?.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                if (selectedPersonId === profile.resourceId) {
                    getProfile()
                }
                getPersonOrgChart(profile.organization?.resourceId);
                search(searchData)
                getPerson(selectedPersonId)
            } else {
                DynamicToastMessage({
                    'key': 'upload_image_response_error',
                    'title': t('ERROR'),
                    'description': values?.file?.response?.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
            }
        }
    }
    useEffect(() => {
        if (deleteAvatarResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteAvatarResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (selectedPersonId === profile.resourceId) {
                getProfile()
            }
            search(searchData)
            getPerson(selectedPersonId)
            getUserRoles(selectedPersonId)
            getPersonOrgChart(profile.organization?.resourceId);
            deleteAvatarResponseReset()
        } else if (deleteAvatarResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': deleteAvatarResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteAvatarResponseReset()
        }
    }, [deleteAvatarResponse])
    useEffect(() => {
        if (deleteAssignmentResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteAssignmentResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getJobHistory(selectedPersonId);
            getSalaryHistory(selectedPersonId);
            deleteAssignmentReset();
        } else if (deleteAssignmentResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': deleteAssignmentResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteAssignmentReset();
        }
    }, [deleteAssignmentResponse])
    useEffect(() => {
        if (updateDocumentKVKKAproveResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'UPDATE_DOCUMENT_KVKK_SUCCESS',
                'title': t('SUCCESS'),
                'description': updateDocumentKVKKAproveResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getPerson(selectedPersonId);
            resetUpdateDocumentKVKKAprove();
        } else if (updateDocumentKVKKAproveResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'UPDATE_DOCUMENT_KVKK_FAIL',
                'title': t('ERROR'),
                'description': updateDocumentKVKKAproveResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetUpdateDocumentKVKKAprove();
        }
    }, [updateDocumentKVKKAproveResponse])
    const onOpenPersonDetail = (id) => {
        setIsPersonDetailDrawerVisible(true, id)
        setConnectedPersonKey((i) => i + 1)
        setConnectedDepartmentKey((i) => i + 1)
        setConnectJobKey((i) => i + 1)
        setConnectTagKey((i) => i + 1)
    }
    const renderActionModal = () => {
        const deleteRequestData = {
            "IsDeleted": deleteModalData?.deleteStatus,
            "ProcessActions": [],
            "DepartmentActions": []
        };
        const dismissallRequestData = {
            "ProcessActions": [],
            "DepartmentActions": [],
            "DismissalTypeId": 0,
            "Description": null,
        };
        const handleValuesChange = (changedObj, all) => {
            const changedValue = Object.keys(changedObj)[0]
            if (changedValue == 'dismissalDate') {
                setDismissalState({ ...dissmissalState, 'dismissalDate': moment(changedObj.dismissalDate).format(dateFormat) });
            } else {
                setDismissalState({ ...dissmissalState, ...changedObj });
            }
        }
        const handleCancel = () => {
            setDismissalState({});
            setDeleteModalData({ "isVisible": false })
            const fields = dismisAllForm.getFieldsValue()
            const projects = { ...fields };
            projects["dismissalDate"] = "";
            projects["dismissalType"] = { id: null, name: null };
            dismisAllForm.setFieldsValue(projects)
        }
        const handleConfirm = () => {
            let hasError = false
            let fields = []
            if (!dissmissalState.dismissalDate || dissmissalState.dismissalDate === 'Invalid date') {
                hasError = true
                fields.push(t('PARTNER_LEAVE_DATE'))
            }
            if (!dissmissalState.dismissalType) {
                hasError = true
                fields.push(t('PARTNER_LEAVE_REASON'))
            }
            if (!hasError) {
                const data = {
                    "dismissalDate": dissmissalState.dismissalDate && dissmissalState.dismissalDate !== 'Invalid date' ? moment(dissmissalState.dismissalDate).format(dateFormat) : null,
                    "dismissalTypeId": dissmissalState?.dismissalType?.id || null,
                    "ProcessActions": [],
                    "DepartmentActions": []
                }
                dismissalPerson(data, deleteModalData?.id)
                setDeleteModalData({ "isVisible": false })
                setDismissalState({});
                const fields = dismisAllForm.getFieldsValue()
                const projects = { ...fields };
                projects["dismissalDate"] = "";
                projects["dismissalType"] = { id: null, name: null };
                dismisAllForm.setFieldsValue(projects)
            } else {
                DynamicToastMessage({
                    'key': 'warning',
                    'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                    'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }
        }
        const dismissallModalForm = (
            <div className="table-details pad-0">
                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    <Form
                        form={dismisAllForm}
                        onValuesChange={handleValuesChange}
                        layout="vertical"
                    >
                        <div className="form-left-body max-w-478">
                            <Form.Item name="dismissalDate" label={t('PARTNER_LEAVE_DATE') + " *"}>
                                <DatePicker
                                    locale={TR}
                                    format={dateFormatList}
                                    placeholder={t('PARTNER_SELECT_DATE') + " *"}
                                    inputReadOnly={true}
                                />
                            </Form.Item>
                            <Form.Item className="custom-select text-left" name="dismissalType" label={t('PARTNER_LEAVE_REASON') + " *"}  >
                                <DynamicSelectbox
                                    optionList={leaveResonType}
                                    onFocus={() => getDataset(36)}
                                    placeholder={t('PARTNER_SELECT')}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </ConfigProvider>
            </div>
        )
        if (deleteModalData?.modalType === 'delete') {
            return (
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deletePerson}
                    confirmData={deleteModalData?.id}
                    isMultipleData={deleteRequestData}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_EMPLOYEE_MODAL', { "name": deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
            )
        } else if (deleteModalData?.modalType === 'passive') {
            return (
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deletePerson}
                    confirmData={deleteModalData?.id}
                    isMultipleData={deleteRequestData}
                    iconName='UilBan'
                    modalTitle={t('PARTNER_DO_PASSIVE')}
                    description={t('PASSIVE_EMPLOYEE_WITH_NAME', { "name": deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('MAKE_IT_PASSIVE')}
                />
            )
        } else if (deleteModalData?.modalType === 'active') {
            return (
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={doActivePerson}
                    confirmData={deleteModalData?.id}
                    iconName='UilBan'
                    modalTitle={t('DO_ACTIVE')}
                    description={t('ACTIVE_EMPLOYEE_WITH_NAME', { "name": deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('MAKE_IT_ACTIVE')}
                />
            )
        } else if (deleteModalData?.modalType === 'dismall') {
            return (
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    handleConfirm={handleConfirm}
                    handleCancel={handleCancel}
                    confirmData={deleteModalData?.id}
                    isMultipleData={dismissallRequestData}
                    isForm={dismissallModalForm}
                    iconName='UilUserMinus'
                    modalTitle={t('DISMISS_PERSON')}
                    description={t('DISMISSAL_EMPLOYEE_WITH_NAME', { "name": deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DISMISS')}
                />
            )
        }
    }
    const statusChangeConfirm = () => {
        return setStatusModalData({ "isVisible": false, "isAccept": true });
    }
    const statusChangeCancel = () => {
        return setStatusModalData({ "isVisible": false, "isAccept": false });
    }

    

    // #region OnBoarding
    
    const [popoverVisible, setPopoverVisible] = useState(false);
    const hide = () => { 
        setPopoverVisible(false); 
        setCurrentTaskState(33);  };
    const handlePopoverVisibleChange = (visible) => {
        if(visible == false){ props.setCurrentTaskState(33); }
        if(props.currentTaskState !== null && props.currentTaskState !== 31 && props.currentTaskState !== 33 && props.currentTaskState !== 63 && props.currentTaskState !== 83){
            setPopoverVisible(visible);
        } 
    };
    
    OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'personDrawerTabs' );

    useEffect(() => { 
            if(props.currentTaskState == 32){  
            setPopoverVisible(true);
            }else{
            setPopoverVisible(false);
            }
        }, [props.currentTaskState]); 

    useEffect(() => {
        if(isPersonDetailDrawerVisible == true && isPersonLoading == false && props.currentTaskState == 32){
            setCurrentTab('Roles')
        }
        if(isPersonDetailDrawerVisible == true && isPersonLoading == false && props.currentTaskState == 82){
            setCurrentTab('ownerShip')
        }
    }, [isPersonLoading])

    useEffect(() => {
        if(currentTab == 'Roles' && isPersonLoading == false && props.currentTaskState == 33){
            setPopoverVisible(false);
        }
        if(currentTab == 'ownerShip' && isPersonLoading == false && props.currentTaskState == 83){
            setPopoverVisible(false);
        }
    }, [currentTab])

    // #endregion
      
    useEffect(() => {
        if(tabreducer.personAddOpenDraw){
            getOrganizationDocumentType();
            getWorkHourDetailsByDefinitions();
        }
    }, [tabreducer.personAddOpenDraw])
                
    return (
        <div>
            {renderActionModal()}
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} visible={tabreducer.personAddOpenDraw} zIndex={1002}>
                <AddUserTabs key={componentKeyAdd} searchData={searchData} returnShow={true} openCreateTaskDrawer={openCreateTaskDrawer} addUserPersonId={addUserPersonId} setAddUserPersonId={setAddUserPersonId} />
            </Drawer>
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setAssignmentDetailAddTabStatus(false)} visible={tabreducer.openAssignmentDraw} zIndex={1002}>
                <AssignmentDetails person={person} disableStatus={person.status?.id !== "1" ? true : false} />
            </Drawer>
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setAssignmentDetailAddTabStatus(false)} visible={tabreducer.openAssignmentAddDraw} zIndex={1002}>
                <AssignmentDetailsAdd salaryHistory={salaryHistory} person={person} />
            </Drawer>
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={setSalaryDetailsOpenDraw} visible={tabreducer.salaryDetailsDraw} zIndex={1002}>
                <SalaryDetails personResourceId={person.resourceId} disableStatus={person.status?.id !== "1" ? true : false} />
            </Drawer>
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={setSalaryAddOpenDraw} visible={tabreducer.salaryAddDraw} zIndex={1002}>
                <AddSalaryInformation person={person} />
            </Drawer>
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setAdvanceDetailOpenDraw(false)} visible={tabreducer.advanceDetailsDraw} zIndex={1002}>
                <AdvanceRequestDetails />
            </Drawer>
            <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setAdvanceAddOpenDraw(false)} visible={tabreducer.advanceAddDraw} zIndex={1002}>
                <AdvanceRequestAdd />
            </Drawer>
            <Drawer key={addOwnerShip} title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setCreateOwnerShipDrawer(false)} visible={tabreducer.createOwnerShipDrawer} zIndex={1002}>
                <OwnerShipAddTabs filterService={true} organizationId={person.organization?.id} isOwnerShipPage={false} selectedPersonId={selectedPersonId} />
            </Drawer>
            <Drawer key={componentKey + '_drawer'} title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setOwnerShipDetailsDrawer(false)} visible={tabreducer.ownerShipDetailsDrawer} zIndex={1002}>
                <OwnerShipDetails ownerShipInitialData={ownerShipInitialData} selectedPersonId={selectedPersonId} selectedOwnerShipResourceId={selectedOwnerShipResourceId} setReturnComponentKey={setReturnComponentKey} disableStatus={person.status?.id !== "1" ? true : false} />
            </Drawer>
            <Drawer
                key={componentKey}
                title={false}
                width={drawerWidth}
                closable={false}
                onClose={false}
                placement="right"
                visible={timeOffDrawerReducer.timeOffRequestAddDrawer}
                zIndex={1002}
            >
                <NewTimeOffRequestTabs typeData={typeData} />
            </Drawer >
            <Drawer
                title={false}
                width={drawerWidth}
                closable={false}
                onClose={false}
                placement="right"
                visible={timeOffDrawerReducer.timeOffRequestApproveDrawer}
                zIndex={1002}
            >
                <TimeOffAproveTabs handleTimeOffChangeStatus={handleTimeOffChangeStatus} selectedData={selectedTimeOff} page="timeOffMy" />
            </Drawer >
            <Drawer
                key={returnComponentKey}
                title={false} width={drawerWidth}
                closable={false} onClose={false}
                placement="right"
                visible={ownershipTabReducer.ownerShipReturnDrawer}
                zIndex={1002}
            >
                <OwnerShipReturn selectedOwnerShip={[selectedOwnerShipResourceId]} selectedOwnerShipAssigmentDate={selectedOwnerShipAssigmentDate} />
            </Drawer >
            <Drawer
                key={personDeleteDrawer?.componentKey}
                className="delete-person-drawer"
                title={false} width={drawerWidth}
                closable={false}
                onClose={false}
                placement="right"
                visible={personDeleteDrawer?.status}
                zIndex={1002}
            >
                <PersonActionDrawer />
            </Drawer>
            <Drawer
                title={false}
                width={drawerWidth}
                placement="right"
                closable={false}
                maskClosable={false}
                keyboard={false}
                onClose={hidePersonDetailDrawer}
                visible={isPersonDetailDrawerVisible}
                className="ant-drawer-without-shadow"
                zIndex={1001}
            >
                {isPersonLoading ?
                    <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                    :
                    <div className="table-details ">
                        <div className="close-icon">
                            <CloseOutlined onClick={hidePersonDetailDrawer} />
                        </div>
                        <Col span={24}>
                            <DynamicRequestModal
                                modalVisible={deleteAvatarModalData.isVisible}
                                setStateData={setDeleteAvatarModalData}
                                postAction={deleteAvatar}
                                confirmData={selectedPersonId}
                                iconName='UilTrash'
                                modalTitle={t('PARTNER_DELETE')}
                                description={t('DELETE_PICTURE_DESC')}
                                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                                successBtnTxt={t('PARTNER_YES_DELETE')}
                            />
                            <DynamicRequestModal
                                modalVisible={statusModalData?.isVisible}
                                setStateData={setStatusModalData}
                                handleConfirm={statusChangeConfirm}
                                handleCancel={statusChangeCancel}
                                confirmData={statusModalData?.id}
                                isForm={' '}
                                iconName='UilInfoCircle'
                                modalTitle={t('WARNING')}
                                description={t('ACCOUNT_ACTIVE_MESSAGE')}
                                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                                successBtnTxt={t('YES')}
                            />
                            <Row>
                                <Col span={18}>
                                    <Row>
                                        <Col span={5}>
                                            <div className="avatar">
                                                <Image src={person.photo || process.env.REACT_APP_URL + 'images/avatar_' + person.gender?.id + '.png'} className="rounded-img" preview={false} />
                                            </div>
                                        </Col>
                                        <Col span={19}>
                                            <div className="user-details">
                                                <h1>{person.givenName + " " + person.familyName}</h1>
                                                <div className="info-alt">

                                                    <div className="info-alt-item">
                                                        <div className="info-alt-item-icon">
                                                            <UilUniversity />
                                                        </div>
                                                        <div className="info-alt-item-text">
                                                            <span>
                                                                {person.job?.displayValue !== undefined ? person.job?.displayValue : ''}
                                                                {person.job?.displayValue !== undefined && person.department?.displayValue !== undefined ? ' - ' : ''}
                                                                {person.department?.displayValue !== undefined ? person.department?.displayValue : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {person.officePhone &&
                                                        <div className="info-alt-item">
                                                            <div className="info-alt-item-icon">
                                                                <UilPhoneAlt />
                                                            </div>
                                                            <div className="info-alt-item-text">
                                                                <span>{person.officePhone}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {person.identityNumber &&
                                                        <div className="info-alt-item">
                                                            <div className="info-alt-item-icon">
                                                                <UilPostcard />
                                                            </div>
                                                            <div className="info-alt-item-text">
                                                                <span>{person.identityNumber}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {person.corporateEmail &&
                                                        <div className="info-alt-item">
                                                            <div className="info-alt-item-icon">
                                                                <UilEnvelope />
                                                            </div>
                                                            <div className="info-alt-item-text">
                                                                <span>{person.corporateEmail}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Can I="person.update">
                                            <div className="avatar">
                                                <div className="change-avatar">
                                                    <ImgCrop
                                                        shape="round"
                                                        modalTitle={t('UPLOAD_PICTURE')}
                                                        modalOk={t('UPLOAD')}
                                                        modalCancel={t('INVENTORY_CANCEL')}
                                                    >
                                                        <Upload
                                                            disabled={person.status?.id !== "1"}
                                                            action={apiUrl + "/persons/" + selectedPersonId + "/uploadPersonProfilePhoto"}
                                                            headers={
                                                                {
                                                                    "Authorization": `Bearer ${access_token}`
                                                                }
                                                            }
                                                            multiple={false}
                                                            showUploadList={false}
                                                            onChange={(e) => uploadImgAction(e)}
                                                        >
                                                            <UilEditAlt /> {person?.photo ? t('PARTNER_CHANGE') : t('UPLOAD_PICTURE')}
                                                        </Upload>
                                                    </ImgCrop>
                                                    {person?.photo ?
                                                        <span onClick={() => setDeleteAvatarModalData({ ...deleteAvatarModalData, "isVisible": true })} className="pad-left-10"><UilTrash />  {t('PARTNER_DELETE')}</span> : ''}
                                                </div>
                                            </div>
                                        </Can>
                                        <Cannot I="person.update">
                                            <div className="pad-15" />
                                        </Cannot>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <div className="changepage">
                                        <Row gutter={[0, 15]}>
                                            <div className="procces">
                                                <Can I="person.delete">
                                                    <Dropdown overlay={personDetailsDropdown(t, canDelete, canPassive, canDismall, canActive, person.resourceId, person.givenName + " " + person.familyName, permissions, person?.status?.id)} trigger={['click']}>
                                                        <Button className="primary-btn w-180 h-32"> <SettingOutlined />{t('PARTNER_PROCESS')} </Button>
                                                    </Dropdown>
                                                </Can>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <div className="hr-bottom"></div>
                        <Tabs className="custom-tab update-user-draw drawer-scrollable-tab"
                            onChange={callback}
                            defaultActiveKey="PersonBasicInfo"
                            activeKey={currentTab}
                        >
                            <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key="PersonBasicInfo">
                                {perm === 1 &&
                                    <BasicInformationTab
                                        focusOnEmail={focusOnEmail}
                                        disableStatus={person.status?.id !== "1" ? true : false}
                                        personBasicInfo={person}
                                        personBasicInfoIsFetching={isPersonLoading}
                                        getPersonId={selectedPersonId}
                                        getUpdateObj={(o) => setPersonUpdateObject(o)}
                                        statusModalData={statusModalData}
                                        setStatusModalData={setStatusModalData}
                                    />
                                }
                            </TabPane>
                            {permissions.includes("timeoff.request.list") && (
                                <TabPane tab={`${t('HOMEPAGE_PROCEDURES')}`} key="timeOffTab">
                                    <TimeOffTab
                                        disableStatus={person.status?.id !== "1" ? true : false}
                                        person={person}
                                        selectedTimeOff={selectedTimeOff}
                                        setSelectedTimeOff={setSelectedTimeOff}
                                        handleCloseModal={handleCloseModal}
                                        ApproveModalData={ApproveModalData}
                                        setApproveModalData={setApproveModalData}
                                        cancelModalData={cancelModalData}
                                        setCancelModalData={setCancelModalData}
                                        deleteModalData={deleteTimeOffModalData}
                                        setDeleteModalData={setDeleteTimeOffModalData}
                                        handleSetTypeData={(d) => handleSetTypeData(d)} />
                                </TabPane>
                            )}
                            {permissions.includes("person.role.list") && (
                                
                                <TabPane tab={
                                    <Popover
                                    key={'auth.person'}
                                    overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                                    content={<PopoverContent key={'auth.person'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                    title={false}
                                    placement="rightTop"
                                    trigger="click"
                                    visible={popoverVisible && props.currentTaskState !== 31 && props.currentTaskState !== 33 && props.currentTaskState !== 63 && props.currentTaskState !== 83 && props.currentTaskState !== null}
                                    onVisibleChange={handlePopoverVisibleChange}
                                     >
                                        <div className={popoverVisible && props.currentTaskState !== 31 && props.currentTaskState !== 33 && props.currentTaskState !== 63 && props.currentTaskState !== 83 && props.currentTaskState !== null ? 'onboarding-drawer-tab-border' : ''}>{t('PARTNER_AUTH2')}</div>
                                    </Popover> 
                                
                                } key="Roles" forceRender={false} >
                                    <Authorization
                                        disableStatus={person.status?.id !== "1" ? true : false}
                                        personInformation={person}
                                        corporateEmail={personUpdateObj.corporateEmail}
                                        goToCorporateEmail={() => goToCorporateEmail()}
                                        getUpdateObj={(o) => setPersonUpdateObject(o)}
                                    />
                                </TabPane>
                                
                            )}
                            {/* <TabPane tab={`${t('PARTNER_JOB_INFORMATION')}`} key="PersonJobHistory"  >
                                <PersonJobHistory disableStatus={false} personJobHistory={person} personJobHistoryIsFetching={isPersonLoading} getPersonId={selectedPersonId} getUpdateObj={(o) => setPersonUpdateObject(o)} />
                            </TabPane> */}
                            <TabPane tab={`${t('PARTNER_PERSONEL_INFORMATION')}`} key="PersonelInformation" >
                                <PersonInformation
                                    disableStatus={person.status?.id !== "1" ? true : false}
                                    personInformation={person}
                                    personInformationIsFetching={isPersonLoading}
                                    getPersonId={selectedPersonId}
                                    getUpdateObj={(o) => setPersonUpdateObject(o)}
                                />
                            </TabPane>
                            {permissions.includes("person.asset.list") && (
                                <TabPane tab={`${t('PARTNER_OWNERSHIP')}`} key={"ownerShip"} >
                                    <OwnerShipList
                                        currentTab={currentTab}
                                        disableStatus={person.status?.id !== "1" ? true : false}
                                        setReturnComponentKey={setReturnComponentKey}
                                        setSelectedOwnerShipResourceId={setSelectedOwnerShipResourceId}
                                        setSelectedOwnerShipAssigmentDate={setSelectedOwnerShipAssigmentDate}
                                        selectedOwnerShipResourceId={selectedOwnerShipResourceId}
                                        openOwnerShipDetails={openOwnerShipDetails}
                                        addOwnerShipDrawer={addOwnerShipDrawer}
                                        selectedPersonId={selectedPersonId}
                                        ownerShipInitialData={ownerShipInitialData}
                                    />
                                </TabPane>
                            )}
                            {permissions.includes("person.salary.list") && (
                                <TabPane tab={`${t('PARTNER_SALARY_INFORMATION2')}`} key={"Salary"}>
                                    <SalaryInformation
                                        disableStatus={person.status?.id !== "1" ? true : false}
                                        person={person}
                                        salaryHistory={salaryHistory}
                                        getUpdateObj={(o) => setPersonUpdateObject(o)}
                                    />
                                </TabPane>
                            )}
                            {permissions.includes("person.assignment.list") && (
                                <TabPane tab={`${t('PARTNER_APPOINTMENTS')}`} key={"JobHistory"}>
                                    <AssignmentList
                                        disableStatus={person.status?.id !== "1" ? true : false}
                                        person={person}
                                        salaryHistory={salaryHistory}
                                        jobHistory={jobHistory}
                                    />
                                </TabPane>
                            )}
                            <TabPane tab={`${t('PARTNER_CONTACT')}`} key="contactTab">
                                <PersonContact
                                    disableStatus={person.status?.id !== "1" ? true : false}
                                    focusOnEmail={focusOnEmail}
                                    personContactDetails={person}
                                    personContactIsFetching={isPersonLoading}
                                    getUpdateObj={(o) => setPersonUpdateObject(o)}
                                />
                            </TabPane>
                            {/* <TabPane tab={`${t('PARTNER_ADVANCE_REQUESTS')}`} key={"AdvanceRequest"}>
                                <AdvanceRequestList />
                                <Comingsoon size="sm" />
                            </TabPane> */}
                            {/* <TabPane tab={`${t('PARTNER_REQUEST_EXPENSE')}`} key={"12"}>
                                <Comingsoon size="sm" />
                            </TabPane> */}
                            <TabPane tab={`${t('PARTNER_CUSTOM_FIELDS')}`} key="socialmediaTab">
                                <SocialMediaTab
                                    disableStatus={person.status?.id !== "1" ? true : false}
                                    socialmedia={person}
                                    personSocialIsFetching={isPersonLoading}
                                    getUpdateObj={(o) => setPersonUpdateObject(o)}
                                />
                            </TabPane>
                            {/* <TabPane tab={`${t('PARTNER_GROUP_MEMBERSHIPS')}`} key="GroupMemberShip">
                                <GroupMemberShip />
                                <Comingsoon size="sm" />
                            </TabPane> */}
                            <TabPane tab={`${t('PARTNER_DOCUMENTS')}`} key={"DocumentTab"}>
                                <DocumentsTab
                                    disableStatus={person.status?.id !== "1" ? true : false}
                                    person={person}
                                    selectedPersonId={selectedPersonId}
                                />
                            </TabPane>
                            <TabPane tab={t('EDUCATION')} key={"Education"}>
                                <EducationTab personResourceId={person.resourceId} />
                            </TabPane>
                            {/* <TabPane tab={`${t('PARTNER_TRANSACTION_HISTORY')}`} key={"16"}>
                                <Comingsoon size="sm" />
                            </TabPane> */}
                            {/* <TabPane tab={`${t('PARTNER_LICENSES')}`} key={"17"}>
                                <Comingsoon size="sm" />
                            </TabPane> */} 
                        </Tabs>
                        {hasChanged && (
                            <div className="fixed-btn">
                                <div className="page-end">
                                    <div className="hr-end"></div>
                                </div>
                                <div className="add-user-draw-footer">
                                    <Button onClick={hidePersonDetailDrawer} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                                    <div className="add-user-draw-footer-end " >
                                        <Can I="person.update">
                                            <Button onClick={confirmChanges} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_SAVE_CHANGES')}</Button>
                                        </Can>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </Drawer >
            <Drawer key={connectedPersonKey} title={false} width={drawerWidth} placement="right" closable={false} visible={tabreducer.connectedPersonsOpenDraw.status}>
                <ConnectedPersons onOpenPersonDetail={(id) => onOpenPersonDetail(id)} />
            </Drawer>
            <Drawer key={connectedDepartmentKey} title={false} width={drawerWidth} placement="right" closable={false} visible={organizationTabreducer.departmentConnectDrawer.status}>
                <ConnectedDepartment onOpenPersonDetail={(id) => onOpenPersonDetail(id)} />
            </Drawer>
            <Drawer key={connectJobKey} title={false} width={drawerWidth} placement="right" closable={false} visible={jobConnectDrawer?.status}>
                <ConnectedJobDrawer onOpenPersonDetail={(id) => onOpenPersonDetail(id)} />
            </Drawer>
            <Drawer key={connectTagKey} title={false} width={drawerWidth} placement="right" closable={false} visible={connectTagDrawer?.status}>
                <ConnectedTagDrawer onOpenPersonDetail={(id) => onOpenPersonDetail(id)} />
            </Drawer>
            <Drawer key={taskCreateDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={taskCreateDrawerStatus} >
                <OpenCreateTaskDrawer personId={addUserPersonId} />
            </Drawer>
            <DynamicTimeOffModal form={form} ApproveModalData={ApproveModalData} handleConfirm={() => handleConfirm()} />
        </div >
    )
}
const mapStateToProps = function (state) {
    return {
        person: state.person.person,
        isPersonLoading: state.person.isPersonLoading,
        updateResponse: state.person.updateResponse,
        salaryHistory: state.person.salaryHistory,
        isSalaryHistoryLoading: state.person.isSalaryHistoryLoading,
        jobHistory: state.person.jobHistory,
        tabreducer: state.tabreducer,
        organizationTabreducer: state.organizationTabreducer,
        deletePersonResponse: state.person.deletePersonResponse,
        profile: state.profile,
        permissions: state.profile.permissions,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        auth: state.auth,
        ownershipTabReducer: state.ownershipTabReducer,
        deleteAvatarResponse: state.person.deleteAvatarResponse,
        personDeleteDrawer: state.tabreducer.personDeleteDrawer,
        dismissalTypes: state.dataset.dismissalTypes,
        jobConnectDrawer: state.job.jobConnectDrawer,
        connectTagDrawer: state.tag.connectTagDrawer,
        doActivePersonResponse: state.person.doActivePersonResponse,
        deleteAssignmentResponse: state.person.deleteAssignmentResponse,
        taskCreateDrawerStatus: state.taskReducer.taskCreateDrawerStatus,
        updateDocumentKVKKAproveResponse: state.person.updateDocumentKVKKAproveResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setAssignmentDetailTabStatusEdit: (props) => { dispatch(setAssignmentDetailTabStatus(props)); },
        setAssignmentDetailAddTabStatus: (props) => { dispatch(setAssignmentDetailAddTabStatus(props)); },
        setAssignmentDetailAddOpenDraw: (props) => { dispatch(setAssignmentDetailAddOpenDraw(props)); },
        setSalaryDetailsOpenDraw: (props) => { dispatch(setSalaryDetailsOpenDraw(props)); },
        setSalaryAddOpenDraw: (props) => { dispatch(setSalaryAddOpenDraw(props)); },
        setAdvanceDetailOpenDraw: (props) => { dispatch(setAdvanceDetailOpenDraw(props)); },
        setAdvanceAddOpenDraw: (props) => { dispatch(setAdvanceAddOpenDraw(props)); },
        setCreateOwnerShipDrawer: (props) => { dispatch(setCreateOwnerShipDrawer(props)) },
        setOwnerShipDetailsDrawer: (props) => { dispatch(setOwnerShipDetailsDrawer(props)) },
        setPersonAddOpenDraw: (props) => { dispatch(setPersonAddOpenDraw(props)) },
        resetAction: () => { dispatch(resetAction()) },
        getPersonOrgChart: (id) => { dispatch(getPersonOrgChart(id)) },
        getPerson: (id) => { dispatch(getPerson(id)) },
        ownerShipSearch: (selectedPersonId, searchData) => { dispatch(ownerShipSearch(selectedPersonId, searchData)) },
        getProfile: () => { dispatch(getProfile()) },
        updatePerson: (id) => { dispatch(updatePerson(id)) },
        getSalaryHistory: (id) => { dispatch(getSalaryHistory(id)) },
        getUserRoles: (id) => { dispatch(getUserRoles(id)) },
        getJobHistory: (id) => { dispatch(getJobHistory(id)) },
        updatePersonResponseReset: () => { dispatch(updatePersonResponseReset()) },
        search: (searchData) => { dispatch(search(searchData)) },
        resetAddress: () => { dispatch(resetAddress()) },
        deletePerson: (data, id) => { dispatch(deletePerson(data, id)) },
        deleteAvatar: (props) => { dispatch(deleteAvatar(props)) },
        deleteAvatarResponseReset: () => { dispatch(deleteAvatarResponseReset()) },
        dismissalPerson: (data, id) => { dispatch(dismissalPerson(data, id)) },
        getDataset: (props) => { dispatch(getDataset(props)) },
        getFileUploadList: (resourceId) => { dispatch(getFileUploadList(resourceId)) },
        timeOffApprove: (approveData) => { dispatch(timeOffApprove(approveData)) },
        timeOffReject: (approveData) => { dispatch(timeOffReject(approveData)) },
        timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)) },
        doActivePerson: (resourceId) => { dispatch(doActivePerson(resourceId)) },
        deleteAssignmentReset: () => { dispatch(deleteAssignmentReset()) },
        setCreateTaskDrawer: (status) => { dispatch(setCreateTaskDrawer(status)) },
        resetUpdateDocumentKVKKAprove: () => { dispatch(resetUpdateDocumentKVKKAprove()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) },
        getOrganizationDocumentType: () => { dispatch(getOrganizationDocumentType()) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PersonDrawerTabs)