var initialState = {
    organizationaddDrawer: false,
    organizationDetailsDrawer: false,
    departmentAddDrawer:false,
    departmentDetailsDrawer:false,
    departmentConnectDrawer:{
      status:false,
      id:null
    },
    departmentControlDrawer:{
      status:false,
      modalType:null,
      id:null,
      name:null,
      componentKey:30
    }   
  };
  
// ORGANIZASYON VE DEPARTMAN DRAWER STATELERI BURADA OLMAKTADIR

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
  
      case "SET_ORGANIZATION_ADD_DRAWER":
        const organizationaddDrawerState = {
          ...state,
          organizationaddDrawer: action.payload,
        };
  
        return organizationaddDrawerState;
  
      case "SET_ORGANIZATION_DETAILS_DRAWER":
        const organizationDetailsDrawerState = {
          ...state,
          organizationDetailsDrawer: action.payload,
        };
        return organizationDetailsDrawerState;
  
        case "SET_DEPARTMENT_ADD_DRAWER":
          const departmentAddDrawerState = {
            ...state,
            departmentAddDrawer: action.payload,
          };
          return departmentAddDrawerState;

          case "SET_DEPARTMENT_DETAILS_DRAWER":
            const departmentDetailsDrawerState = {
              ...state,
              departmentDetailsDrawer: action.payload,
            };
            return departmentDetailsDrawerState;
  
          case "SET_DEPARTMENT_CONNECT_DRAWER":
            const departmentConnectDrawerState = {
              ...state,
              departmentConnectDrawer: action.payload,
            };
            return departmentConnectDrawerState;
          
          case "SET_DEPARTMENT_CONTROL_DRAWER":
            const departmentControlDrawerState = {
              ...state,
              departmentControlDrawer: action.payload,
            };
            return departmentControlDrawerState;
        
      default:return state;
    }
  }
  