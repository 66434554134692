import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UilUsersAlt, UilInfoCircle, UilSync } from '@iconscout/react-unicons';

import { Form, Input, Col, Row, Checkbox, Spin, ConfigProvider, Card, Button, Divider, Typography, Tooltip, notification, Alert, Image  } from 'antd';

import { useLocation,useHistory  } from 'react-router-dom';
import '../../styles/checkout.scss'

import { getSubscriptionSummary, getOrganizationInvoiceAddress, getSubscriptionPayment} from '../../redux/actions/checkout.actions';

function NewPackagePayment(props) {
    
    const history = useHistory();

    const {
        getSubscriptionSummary, subscriptionSummary, subscriptionSummaryLoading, getOrganizationInvoiceAddress, 
        organizationInvoiceAddress, organizationInvoiceAddressLoading, getSubscriptionPayment, subscriptionFailResponse, subscriptionPaymentTransaction, organizationPackageId
    } = props;

    const location = useLocation();

    const [subscriptionSummaryPayload,setsubscriptionSummaryPayload] = useState({"packageId": null, "discountCode": ""})

    const { t } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nLang') || 'en');

    const [paymentPayload, setPaymentPayload] = useState({
        "creditCard": {
          "cardHolderName": null,
          "cardNumber": null,
          "expireMonth": null,
          "expireYear": null,
          "cvc": null,
          "secure3D": true, //
          "saveCard": true,
          "cardToken": null, //
          "binNumber": null //
        },
        "newPackageId": 0,
        "paymentTypeId": 0
      })

    const [subscriptionSummaryState, setsubscriptionSummaryState] = useState(
        {
            "packagePrice": 2750,
            "discountPrice": 0,
            "discountPackagePrice": 2750,
            "discountCodeMessage": null
        }
    )

    const [form] = Form.useForm()

    const { Text } = Typography;

    const [CCVError, setCCVError] = useState('');
    const [isAmex , setIsAmex] = useState(false);
    
    const [checkStatus, setCheckStatus] = useState(false);
    const [saveCardInfo, setSaveCardInfo] = useState(false);

    const [currentPackage, setCurrentPackage] = useState()

    const [newPackage, setNewPackage] = useState()

    const [savedCard, setSavedCard] = useState()

    const [showNewCard,setShowNewCard] = useState(false)


    const luhnCheck = (value) => {
        // accept only digits, dashes or spaces
        // if (/[^0-9-\s]+/.test(value)) return false;
        if (/[^0-9-\s]+/.test(value)) return t('ENTER_VALID_CARD_NUMBER');
      
        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0,
          nDigit = 0,
          bEven = false;
        value = value.replace(/\D/g, "");
      
        for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);
      
          if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
          }
      
          nCheck += nDigit;
          bEven = !bEven;
        }
      
        return nCheck % 10 == 0 ? null : t('ENTER_VALID_CARD_NUMBER');
    }

    const validateExpiry = (value) => {

        const [month, year] = value.split('/');
        const currentYear = new Date().getFullYear() % 100; // Yılın son iki basamağı
        const currentLongYear = new Date().getFullYear(); // Yılın son iki basamağı
        const currentMonth = new Date().getMonth() + 1; // Ay 0'dan başladığı için 1 ekliyoruz

        const inputYear = parseInt(year, 10);
        const inputLongYear = parseInt(year);
        const inputMonth = parseInt(month, 10);

        const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2}|[0-9]{4})$/;
        if (!regex.test(value)) {
            return t('ENTER_VALID_EXPIRATION_DATE')
        }else if ( inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth) || 
            (year?.length == 4 && (inputLongYear < currentLongYear  || (inputLongYear === currentLongYear && inputMonth < currentMonth)))
            ) {
            return t('EXPIRED_CARD_VALIDATION')
        }else{
            return null;
        }
    };

    const validateCCV = (value) => {
        const regex = /^[0-9]{3,4}$/;
        if (!regex.test(value)) { 
            return t('ENTER_VALID_SECURITY_CODE')
        }else{
            return null
        }
    };

    const allowOnlyNumericKeyPress = (e) => {
        // Sadece sayıları kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericPaste = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const cardNumberKeypressFilter = (e) => {
        // Sadece sayıları kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/[0-9\s]/.test(key)) {
          e.preventDefault();
        }
    };

    const cardNumberPasteFilter = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9\s]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleCardHolderKeyPress = (e) => {
        // Sadece harf, boşluk ve bazı özel karakterleri kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/^[A-Za-z\sÀ-ÖØ-öø-ÿÇçĞğİıÖöŞşÜü]*$/.test(key)) {
          e.preventDefault();
        }
    };

    const handleCardHolderPaste = (e) => {
        // Sadece harf, boşluk ve bazı özel karakterleri kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[A-Za-z\sÀ-ÖØ-öø-ÿ]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleExpireDateKeypress = (e) => {
        const key = e.key;
        if (!/[0-9\/]/.test(key)) {
          e.preventDefault();
        }
    }

    const handleExpireDatePaste = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9\/]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    useEffect(() => {

        form.setFieldsValue({...paymentPayload, saveCard : paymentPayload.creditCard.saveCard})
        setPaymentPayload({...paymentPayload})
    
    }, [form,organizationInvoiceAddress])

    useEffect(() => {

        const query = new URLSearchParams(location.search);

        const activePackage = query.get('packageId');

        if (activePackage) {
            if(organizationPackageId == activePackage){
                history.push('/prices')
            }

            setsubscriptionSummaryPayload({...subscriptionSummaryPayload, packageId : activePackage})

            setPaymentPayload({...paymentPayload, newPackageId : activePackage})

        }else{
            history.push('/prices')
        }

        const paymentError = query.get('error');
        if(paymentError){
            notification['error']({
                message: t("ERROR"),
                description: t(paymentError),
                placement:'topRight',
                duration: 15
            });
        }
        
    }, [location]);

    useEffect(() => {

        setsubscriptionSummaryState(subscriptionSummary.summary)
        setCurrentPackage(subscriptionSummary.currentPackage)
        setNewPackage(subscriptionSummary.newPackage)
        setSavedCard(subscriptionSummary.cardInformation)

        if(!subscriptionSummary.cardInformation){
            setShowNewCard(true)
        }else{
            setShowNewCard(false)
        }

        const query = new URLSearchParams(location.search);

        const activePackage = query.get('packageId');

        if(activePackage == 1 || activePackage == 5){
            setPaymentPayload({...paymentPayload, paymentTypeId : 0, newPackageId : activePackage})
        }else if(subscriptionSummary?.summary?.lastPrice > 0 ){
            setPaymentPayload({...paymentPayload, paymentTypeId : 1, newPackageId : activePackage})
        }else if(subscriptionSummary?.summary?.lastPrice == 0 ){
            setPaymentPayload({...paymentPayload, paymentTypeId : 3, newPackageId : activePackage})
        }
        
        
    }, [subscriptionSummary]);

    useEffect(() => {

        subscriptionSummaryPayload.packageId && getSubscriptionSummary(subscriptionSummaryPayload)
        
    }, [subscriptionSummaryPayload]);


    useEffect(()=>{
        getOrganizationInvoiceAddress()
    },[])

    useEffect(()=>{
        if(subscriptionPaymentTransaction.htmlContent && paymentPayload.paymentTypeId == 1){
            subscriptionPaymentTransaction && history.push('/secure3DCallback')
        }else if(subscriptionPaymentTransaction.paymentNumber){
            history.push('/paymentCompleted/'+subscriptionPaymentTransaction.paymentNumber)
        }
    },[subscriptionPaymentTransaction])

    useEffect(()=>{
        if(subscriptionFailResponse && subscriptionFailResponse?.errorMessages?.length > 0){
            notification['error']({
                message: t("ERROR"),
                description: subscriptionFailResponse?.errorMessages[0],
                placement:'topRight',
                duration: 15
            });
        }
    },[subscriptionFailResponse])

    

    const [fieldsWithError, setFieldsWithError] = useState([]);

    useEffect(() => {
        if (fieldsWithError.length > 0) {
        const fieldElement = document.getElementById(fieldsWithError[0]);
        if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        }
    }, [fieldsWithError]);

    const onFinishFailed = (errorInfo) => {
        const fieldNames = errorInfo.errorFields.map(field => field.name[0]);
        setFieldsWithError(fieldNames);
    };

    const complatePayment = () =>{
        
        const paymentObject = JSON.parse(JSON.stringify(paymentPayload))

        if(paymentObject.paymentTypeId != 1){
            paymentObject['creditCard']=null;
        }else if(showNewCard){
            const cardInfo = {
                ...paymentObject['creditCard'],
                cardToken : null,
                binNumber: null
            }
            paymentObject['creditCard'] = cardInfo ;
        }else{
            const cardInfo = {
                ...paymentObject['creditCard'],
                "cardHolderName": null,
                "cardNumber": null,
                "expireMonth": null,
                "expireYear": null,
                "cvc": null,
                "secure3D": true, 
                "cardToken": savedCard.cardToken,
                "binNumber": savedCard.binNumber
            }
            paymentObject['creditCard'] = cardInfo
        }


        getSubscriptionPayment(paymentObject)
    }

    const handleValuesChange = (changedObj, all) => {
        if ('cardHolderName' === Object.keys(changedObj)[0]) {
            const creditCard = { ...paymentPayload.creditCard, 'cardHolderName': changedObj["cardHolderName"] };
            setPaymentPayload({ ...paymentPayload, creditCard: creditCard });
        } else if ('cardNumber' === Object.keys(changedObj)[0]){
            if(changedObj['cardNumber'] && !luhnCheck(changedObj['cardNumber'].replace(/\s+/g, ''))){
                setSaveCardInfo(true)
                const re = new RegExp("^(34|37)");
                const condition = changedObj['cardNumber'].match(re) != null;
                if (condition) {
                    setIsAmex(true)
                    //return value.length < 15 ? "Kart Numarası en az 15 hane olmalıdır" : true;
                } else {
                    setIsAmex(false)
                    //return value.length < 16 ? "Kart Numarası en az 16 hane olmalıdır" : true;
                }
            }else{
                setSaveCardInfo(false)

            }

            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields, 'cardNumber' : changedObj['cardNumber'].replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim(), CCV:''};
            form.setFieldsValue(fieldsObj)

            const creditCard = {...paymentPayload.creditCard, cardNumber : changedObj['cardNumber'].replace(/\s+/g, '')}
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
            
            
        } else if('expireDate' === Object.keys(changedObj)[0]){
            
            let inputExpiry = changedObj['expireDate'];
            if(inputExpiry.length ==1 && parseInt(inputExpiry, 10) > 1){
                inputExpiry = '0'+inputExpiry
            }  
            const cleanedExpiry = inputExpiry.replace(/[^0-9]/g, ''); // Sadece rakamları al


            const fields = form.getFieldsValue()

            // Eğer tam olarak 6 karakter varsa (MMYYYY formatı), MM/YY formatına çevir
            if (cleanedExpiry.length === 6) {
            const formattedExpiry = `${cleanedExpiry.slice(0, 2)}/${cleanedExpiry.slice(2)}`;

            const fieldsObj = { ...fields, 'expireDate' : formattedExpiry };
            form.setFieldsValue(fieldsObj)
            } else if (cleanedExpiry.length === 4) {
            // Eğer tam olarak 4 karakter varsa (MMYY formatı), MM/YY formatına çevir
            const formattedExpiry = `${cleanedExpiry.slice(0, 2)}/${cleanedExpiry.slice(2)}`;
            const fieldsObj = { ...fields, 'expireDate' : formattedExpiry };
            form.setFieldsValue(fieldsObj)
            } else {
            // Diğer durumlar için girilen değeri kullan
            const fieldsObj = { ...fields, 'expireDate' : inputExpiry };
            form.setFieldsValue(fieldsObj)

            }

            const expireMonth = cleanedExpiry.slice(0, 2);
            const expireYear = cleanedExpiry.slice(2).length == 2 ? '20'+cleanedExpiry.slice(2) : cleanedExpiry.slice(2); 

            const creditCard = {...paymentPayload.creditCard, expireMonth : expireMonth , expireYear: expireYear }

            setPaymentPayload({...paymentPayload, creditCard : creditCard})

        } else if('CCV' === Object.keys(changedObj)[0]){

            const creditCard = {...paymentPayload.creditCard, cvc : changedObj['CCV']  }
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
        } else if('saveCard' === Object.keys(changedObj)[0]){

            const creditCard = {...paymentPayload.creditCard, saveCard : changedObj['saveCard']  }
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
        } else {
            setPaymentPayload({ ...paymentPayload, ...changedObj });
        }

    }

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={false}
                style={{paddingTop:'32px',paddingRight:'0px'}}
                onFinish={complatePayment}
                onFinishFailed={onFinishFailed}
            >
              <Row gutter={[16, 16]} justify="center">
                <Col span={23} sm={22} lg={18} xl={14} xxl={11}>
                  <Card className="fill-info-card">
                    <Text className="payment-card-title">{t("CURRENT_PACKAGE")}</Text>
                    { !subscriptionSummaryLoading ? ( <Row gutter={[32, 0]}>
                        <div className="package-wrapper">
                            <div>
                                <div className="package-name">{currentPackage?.packageName}</div>
                                <div className="employee-label"><UilUsersAlt /> {currentPackage?.employeeCount} {t('EMPLOYEE_COUNT_LBL')}</div>
                            </div>
                            <div>
                                <span className="price">
                                    {selectedLanguage == 'tr-TR' 
                                                ? currentPackage?.amountInCurrency?.toLocaleString('tr-TR') + ' ₺'
                                                : '$'+ currentPackage?.amount?.toLocaleString('en-US').replace(',' , '')
                                    }
                                </span>
                                <span className="price-ext">/{ currentPackage?.isYearly ? t('PACKAGE_PERIOD_YEAR') : t('PACKAGE_PERIOD_MONTH')}</span>
                            </div>
                        </div>
                    </Row>):(
                        <div className="spinner-wrapper">
                            <Spin/>
                        </div>
                    )}
                    <Divider />
                    <Text className="payment-card-title">{t("NEW_PACKAGE")}</Text>
                    { !subscriptionSummaryLoading ? ( <Row gutter={[32, 0]}>
                            <div className="package-wrapper">
                                <div>
                                    <div className="package-name">{newPackage?.packageName}</div>
                                    <div className="employee-label"><UilUsersAlt /> {newPackage?.employeeCount} {t('EMPLOYEE_COUNT_LBL')}</div>
                                </div>
                                <div>
                                    <span className="price">
                                        {selectedLanguage == 'tr-TR' 
                                            ? newPackage?.amountInCurrency?.toLocaleString('tr-TR') + ' ₺'
                                            : '$'+ newPackage?.amount?.toLocaleString('en-US').replace(',' , '')
                                        }
                                    </span>
                                    <span className="price-ext">/{newPackage?.isYearly ? t('PACKAGE_PERIOD_YEAR') : t('PACKAGE_PERIOD_MONTH')}</span>
                                </div>
                            </div>
                    </Row>):(
                        <div className="spinner-wrapper">
                            <Spin/>
                        </div>
                    )}

                    {subscriptionSummaryState?.paymentMessage && <Alert className="info-alert-box" message={<div dangerouslySetInnerHTML={{ __html: subscriptionSummaryState?.paymentMessage }} />} type="info" showIcon /> }

                    <Divider />
                    <Text className="payment-card-title">{t("INVOICE_INFO")}</Text>
                    { !organizationInvoiceAddressLoading ? ( <Row gutter={[32, 0]}>
                        <Col span={24} md={12}>
                            <div className="form-left-body">

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_COUNTRY')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.country?.displayValue}</div>
                                </div>

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_PROVINCE')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.province?.displayValue}</div>
                                </div>

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_DISTRICT')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.district?.displayValue}</div>
                                </div>

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_ADDRESS')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.addressLine}</div>
                                </div>
                                

                            </div>

                        </Col>
                        <Col span={24} md={12}>
                            <div className="form-right-body">

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_PERSON_NAME')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.companyName}</div>
                                </div>

                                <div className="addressField">
                                    <div className="title">{t('INVOICE_EMAIL')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.email}</div>
                                </div>

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_VKNTCKN')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.vknTckn}</div>
                                </div>

                                <div className="addressField">
                                    <div className="title">{t('ORGANIZATION_TAX_OFFICE')}</div>
                                    <div className="value">{organizationInvoiceAddress.billingAddress?.taxOffice?.displayValue}</div>
                                </div>

                            </div>
                        </Col>
                    </Row>):(
                        <div style={{height:'344px',display:'flex',alignItems:'center',justifyContent:"center"}}><Spin/></div>
                    )}
                    {subscriptionSummaryState?.lastPrice > 0 && <Divider /> }
                    
                    {subscriptionSummaryState?.lastPrice > 0 && <Text className="payment-card-title">{t('CARD_INFO')}</Text> }

                    {(subscriptionSummaryState?.lastPrice > 0 && subscriptionSummary.cardInformation) && <span className="card-toggle" onClick={()=>setShowNewCard(!showNewCard)}><UilSync /> { !showNewCard ? t("USE_ANOTHER_CARD") : t("USE_SAVED_CARD")} </span> }

                    {   ( showNewCard && subscriptionSummaryState?.lastPrice > 0 ) ? (
                    <Row gutter={[32, 0]}>
                      <Col  span={24} md={12}> 
                        <Form.Item name="cardHolderName" label={t('CARD_HOLDER_NAME')} rules={[{ required: showNewCard, message: t('REQUIRED_FIELD') }]}>
                            <Input id="cardHolderName" placeholder={t('CARD_HOLDER_NAME_PH')} maxLength={100} onKeyPress={handleCardHolderKeyPress} onPaste={handleCardHolderPaste}   />
                        </Form.Item>
                        <Form.Item name="cardNumber" label={t('CARD_NUMBER')} rules={[{ required: showNewCard, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && luhnCheck(value)) ? Promise.reject(new Error(luhnCheck(value))) : Promise.resolve()}]}>
                            <Input id="cardNumber" placeholder={t('CARD_NUMBER_PH')} maxLength={19} onKeyPress={cardNumberKeypressFilter} onPaste={cardNumberPasteFilter} />
                        </Form.Item>
                      </Col>
                      <Col  span={24} md={12}> 
                        <Row gutter={[32, 0]}>
                            <Col span={12}>
                                <Form.Item name="expireDate" label={t('CARD_EXPIRE')} rules={[{ required: showNewCard, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && validateExpiry(value)) ? Promise.reject(new Error(validateExpiry(value))) : Promise.resolve()}]}>
                                    <Input  id="expireDate" placeholder={t('CARD_EXPIRE_PH')} maxLength={7} onKeyPress={handleExpireDateKeypress} onPaste={handleExpireDatePaste} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="CCV" label={t('CARD_CCV')} rules={[{ required: showNewCard, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && validateCCV(value)) ? Promise.reject(new Error(validateCCV(value))) : Promise.resolve()}]}>
                                    <Input id="CCV" placeholder={t('CARD_CCV_PH')} maxLength={isAmex ? 4 : 3} onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} disabled={ !saveCardInfo }/>
                                </Form.Item>
                            </Col>
                        </Row>
                      </Col>
                    </Row>
                    ): subscriptionSummaryState?.lastPrice > 0 && (
                    <Row>
                        <Col>
                        {!subscriptionSummaryLoading ? (
                            savedCard ? (
                                <div className="saved-card-wrapper">
                                <div><Image src={savedCard?.cardAssociationLogo} preview={false} /></div>
                                <div className="card-info">
                                    <div className="card-name">**** *****{savedCard?.lastFourDigits}</div>
                                    <div>{savedCard?.cardAlias}</div>
                                </div>
                            </div>
                            ) : (
                                <div>
                                    {t("NO_CREDIT_CARD_FOUND")}
                                </div>
                            )
                        ) : (
                            <div className="spinner-wrapper">
                                <Spin/>
                            </div>
                        )}
                        </Col>
                    </Row>
                    )}
                    
                    { (saveCardInfo && paymentPayload?.paymentTypeId == 1 ) && (
                    <Row>
                        <Col className="save-card-info" span={24}>
                            <Form.Item name="saveCard" valuePropName="checked" noStyle>
                                <Checkbox checked>{t('CARD_SAVE_INFO_1')}</Checkbox>
                            </Form.Item>
                            <Tooltip title={t('CARD_SAVE_INFO_2')}>
                                <UilInfoCircle />
                            </Tooltip>
                        </Col>
                        { paymentPayload?.creditCard?.saveCard && (
                        <Col>
                            <Alert className="warning-alert-box" message={t("SAVE_CARD_INFO_3")} type="info" showIcon />
                        </Col>
                        )}
                    </Row>
                    )}
                    
                  </Card>
                </Col>
                <Col span={23} sm={22} lg={18} xl={8} xxl={7}>
                    {!subscriptionSummaryLoading ? (
                    <Card className="basket-summary-card">
                        <Text className="payment-card-title">{t('PAYMENT_SUMMARY')}</Text>
                        
                        {selectedLanguage == 'tr-TR'  && 
                            <div className="tl-price-payment">
                                    {subscriptionSummaryState?.packagePrice?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        <div className="price-row" >
                        <Text strong>{t('PACKAGE_PRICE')}</Text>
                        <Text className="price-value">
                            {selectedLanguage == 'tr-TR' 
                                ? subscriptionSummaryState?.packagePriceInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : subscriptionSummaryState?.packagePrice?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }
                        </Text>
                        </div>

                        {selectedLanguage == 'tr-TR'  && 
                            <div className="tl-price-payment">
                                    {subscriptionSummaryState?.remainingCredit?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        <div className="price-row" >
                        <Text strong>{t('REMAINING_CREDIT')} 
                            <Tooltip title={t('UPGRADE_TEXT_REMAINING_CREDIT')}>
                                <UilInfoCircle />
                            </Tooltip>
                        </Text>
                        <Text className="price-value">
                            {selectedLanguage == 'tr-TR' 
                                ? subscriptionSummaryState?.remainingCreditInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : subscriptionSummaryState?.remainingCredit?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }
                        </Text>
                        </div>

                        {selectedLanguage == 'tr-TR'  && 
                            <div className="tl-price-payment">
                                    {subscriptionSummaryState?.periodicPrice?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        <div className="price-row" >
                        <Text strong>{t('PERIODIC_PRICE',{'period': newPackage?.isYearly ? t('YEARLY') : t('MONTHLY')})}
                            <Tooltip title={t('PERIODIC_PRICE_DESC',{'date':moment(subscriptionSummaryState?.newPackageEndDate).format('LL'),'period':newPackage?.isYearly ? t('YEAR') : t('MONTH')})}>
                                <UilInfoCircle />
                            </Tooltip>
                        </Text>
                        <Text className="price-value">
                            {selectedLanguage == 'tr-TR' 
                                ? subscriptionSummaryState?.periodicPriceInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : subscriptionSummaryState?.periodicPrice?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }    
                        </Text>
                        </div>

                        <Divider/>
                        
                        {selectedLanguage == 'tr-TR'  && 
                            <div className="tl-price-payment">
                                    {subscriptionSummaryState?.lastPrice?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        <div className="price-row" >
                            <Text strong>{t('TOTAL_DISCOUNTED_PRICE')}</Text>
                            <Text className="price-value">
                                {selectedLanguage == 'tr-TR' 
                                ? subscriptionSummaryState?.lastPriceInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : subscriptionSummaryState?.lastPrice?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }    
                            </Text>
                        </div>
                        

                        <Form.Item name="agreement" valuePropName="checked" rules={[{ validator: (_, value) => value  ? Promise.resolve() : Promise.reject(new Error(t('REQUIRED_CHECKBOX')))}]}>
                        <Checkbox id="agreement">
                        {selectedLanguage == 'tr-TR' 
                                    ?<>
                                        <a href='https://www.hrplan.net/kullanici-sozlesmesi/' target="_blank">{t('USER_AGREEMENT')}</a>{t('AGREEMENTS_EXT_1')},&nbsp;
                                        <a href='https://www.hrplan.net/aydinlatma-metni/' target="_blank">{t('USER_INFORM_TEXT')}</a>{t('AGREEMENTS_EXT_2')},&nbsp;
                                        <a href='https://www.hrplan.net/acik-riza-metni/' target="_blank">{t('USER_DATA_AGREEMENT')}</a> {t('AGREEMENTS_EXT_3')}
                                    </>
                                    :<>
                                        <a href='https://www.hrplan.net/en/user-agreement/' target="_blank">{t('USER_AGREEMENT')}</a>{t('AGREEMENTS_EXT_1')},&nbsp;
                                        <a href='https://www.hrplan.net/en/customer-information-text/' target="_blank">{t('USER_INFORM_TEXT')}</a>{t('AGREEMENTS_EXT_2')},&nbsp;
                                        <a href='https://www.hrplan.net/en/customer-overseas-data-transfer-explicit-consent-statement/' target="_blank">{t('USER_DATA_AGREEMENT')}</a> {t('AGREEMENTS_EXT_3')}
                                    </>
                        }

                        </Checkbox>
                        </Form.Item>
                        <Form.Item>
                        <Button htmlType="submit" block className={'primary-btn'}>
                            {t('COMPLETE_PAYMENT')}
                        </Button>
                        </Form.Item>
                    </Card>
                  ):( <Card className="basket-summary-card" style={{height:'341px',display:'flex',alignItems:'center',justifyContent:"center"}}><Spin/></Card>)}
                </Col>
              </Row>
              
            </Form>
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        addressReducer: state.addressReducer,
        subscriptionSummary: state.checkout.subscriptionSummary,
        subscriptionSummaryLoading: state.checkout.subscriptionSummaryLoading,
        organizationInvoiceAddress : state.checkout.organizationInvoiceAddress,
        organizationInvoiceAddressLoading : state.checkout.organizationInvoiceAddressLoading,
        subscriptionPaymentTransaction : state.checkout.subscriptionPaymentTransaction,
        subscriptionFailResponse : state.checkout.subscriptionFailResponse,
        organizationPackageId: state.auth?.user?.profile?.organizationPackageId

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSubscriptionSummary: (props) => { dispatch(getSubscriptionSummary(props)) },
        getOrganizationInvoiceAddress : () => { dispatch(getOrganizationInvoiceAddress()) },
        getSubscriptionPayment: (props) => { dispatch(getSubscriptionPayment(props)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPackagePayment);