

import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';
const FileDownload = require('js-file-download');

const AdvanceActionType = {
    SET_ADVANCE_CREATE_DRAWER:"SET_ADVANCE_CREATE_DRAWER",
    SET_ADVANCE_DETAILS_DRAWER:"SET_ADVANCE_DETAILS_DRAWER",
    MY_ADVANCE_LIST_REQUESTS: "MY_ADVANCE_LIST_REQUESTS",
    MY_ADVANCE_LIST_SUCCESS: "MY_ADVANCE_LIST_SUCCESS",
    MY_ADVANCE_LIST_FAIL: "MY_ADVANCE_LIST_FAIL",
    CREATE_ADVANCE_REQUEST: "CREATE_ADVANCE_REQUEST",
    CREATE_ADVANCE_SUCCESS: "CREATE_ADVANCE_SUCCESS",
    CREATE_ADVANCE_FAIL: "CREATE_ADVANCE_FAIL",
    CREATE_ADVANCE_RESET:"CREATE_ADVANCE_RESET",
    GET_ADVANCE_DETAILS_REQUEST:"GET_ADVANCE_DETAILS_REQUEST",
    GET_ADVANCE_DETAILS_SUCCESS:"GET_ADVANCE_DETAILS_SUCCESS",
    GET_ADVANCE_DETAILS_FAIL:"GET_ADVANCE_DETAILS_FAIL",
    GET_ADVANCE_DETAIL_STEP_REQUEST: "GET_ADVANCE_DETAIL_STEP_REQUEST",
    GET_ADVANCE_DETAIL_STEP_SUCCESS: "GET_ADVANCE_DETAIL_STEP_SUCCESS",
    GET_ADVANCE_DETAIL_STEP_FAIL: "GET_ADVANCE_DETAIL_STEP_FAIL",
    ADVANCE_CHANGE_STATUS_SUCCESS: "ADVANCE_CHANGE_STATUS_SUCCESS",
    ADVANCE_CHANGE_STATUS_FAIL: "ADVANCE_CHANGE_STATUS_FAIL",
    ADVANCE_CHANGE_STATUS_RESET:"ADVANCE_CHANGE_STATUS_RESET",
    ADVANCE_EXPORT_SUCCESS: "ADVANCE_EXPORT_SUCCESS",
    ADVANCE_EXPORT_FAIL: "ADVANCE_EXPORT_FAIL",
    RESET_ADVANCE_DOWNLOAD: "RESET_ADVANCE_DOWNLOAD",
    DELETE_ADVANCE_SUCCESS:"DELETE_ADVANCE_SUCCESS",
    DELETE_ADVANCE_FAIL:"DELETE_ADVANCE_FAIL",
    DELETE_ADVANCE_RESET:"DELETE_ADVANCE_RESET",
    ADVANCE_MANAGER_LIST_REQUESTS:"ADVANCE_MANAGER_LIST_REQUESTS",
    ADVANCE_MANAGER_LIST_SUCCESS:"ADVANCE_MANAGER_LIST_SUCCESS",
    ADVANCE_MANAGER_LIST_FAIL:"ADVANCE_MANAGER_LIST_FAIL",
    ALL_ADVANCE_LIST_REQUEST:"ALL_ADVANCE_LIST_REQUEST",
    ALL_ADVANCE_LIST_SUCCESS:"ALL_ADVANCE_LIST_SUCCESS",
    ALL_ADVANCE_LIST_FAIL:"ALL_ADVANCE_LIST_FAIL",
    ADVANCE_DETAILS_TABLE_LIST_REQUESTS:"ADVANCE_DETAILS_TABLE_LIST_REQUESTS",
    ADVANCE_DETAILS_TABLE_LIST_SUCCESS:"ADVANCE_DETAILS_TABLE_LIST_SUCCESS",
    ADVANCE_DETAILS_TABLE_LIST_FAIL:"ADVANCE_DETAILS_TABLE_LIST_FAIL",
    GET_ADVANCE_APPROVE_ON_MAIL_REQUEST:"GET_ADVANCE_APPROVE_ON_MAIL_REQUEST",
    GET_ADVANCE_APPROVE_ON_MAIL_SUCCESS:"GET_ADVANCE_APPROVE_ON_MAIL_SUCCESS",
    GET_ADVANCE_APPROVE_ON_MAIL_FAIL:"GET_ADVANCE_APPROVE_ON_MAIL_FAIL",
    UPDATE_ADVANCE_SUCCESS:"UPDATE_ADVANCE_SUCCESS",
    UPDATE_ADVANCE_FAIL:"UPDATE_ADVANCE_FAIL",
    UPDATE_ADVANCE_RESET:"UPDATE_ADVANCE_RESET",
    ADVANCE_APPROVALS_EXPORT_SUCCESS:"ADVANCE_APPROVALS_EXPORT_SUCCESS",
    ADVANCE_APPROVALS_EXPORT_FAIL:"ADVANCE_APPROVALS_EXPORT_FAIL",
    RESET_ADVANCE_APPROVALS_EXPORT:"RESET_ADVANCE_APPROVALS_EXPORT"
  };

  const setAdvanceCreateDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: AdvanceActionType.SET_ADVANCE_CREATE_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
  };
  const setAdvanceDetailsDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: AdvanceActionType.SET_ADVANCE_DETAILS_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
  };

  const getMyAdvanceRequests = (resourceId,searchData) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.MY_ADVANCE_LIST_REQUESTS });
      const data = JSON.stringify(searchData);
  
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/advances/search/'+resourceId,
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
  
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: AdvanceActionType.MY_ADVANCE_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: AdvanceActionType.MY_ADVANCE_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                totalNumberOfRecords:0,
                results:[]
            }
              dispatch({ type: AdvanceActionType.MY_ADVANCE_LIST_SUCCESS, payload: emptyData });
            }
          });
  
         
      } catch (error) {
        if (error.response) {  
            dispatch({ type: AdvanceActionType.MY_ADVANCE_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getAdvanceDetailsTable = (resourceId,searchData) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_REQUESTS });
      const data = JSON.stringify(searchData);
  
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/advances/search/'+resourceId,
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
  
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_FAIL, payload: error.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getAdvanceManager = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.ADVANCE_MANAGER_LIST_REQUESTS });
      const data = JSON.stringify(searchData);
  
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/advances/search/manager',
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
  
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: AdvanceActionType.ADVANCE_MANAGER_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: AdvanceActionType.ADVANCE_MANAGER_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                totalNumberOfRecords:0,
                results:[]
            }
              dispatch({ type: AdvanceActionType.ADVANCE_MANAGER_LIST_SUCCESS, payload: emptyData });
            }
          });
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.MY_ADVANCE_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getAllAdvanceRequests = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.ALL_ADVANCE_LIST_REQUEST });
      const data = JSON.stringify(searchData);
  
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/advances/search',
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
  
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: AdvanceActionType.ALL_ADVANCE_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: AdvanceActionType.ALL_ADVANCE_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                totalNumberOfRecords:0,
                results:[]
            }
              dispatch({ type: AdvanceActionType.ALL_ADVANCE_LIST_SUCCESS, payload: emptyData });
            }
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.ALL_ADVANCE_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const createAdvance = (createObj) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.CREATE_ADVANCE_REQUEST });
      try {

          var data = JSON.stringify(createObj);
          
          var config = {
            method: 'post',
            url: apiUrl+'/advances',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===201){
              dispatch({ type: AdvanceActionType.CREATE_ADVANCE_SUCCESS, payload: response.data });
            }else{
                dispatch({ type: AdvanceActionType.CREATE_ADVANCE_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            if(error.response.status === 422){
              dispatch({ type: AdvanceActionType.CREATE_ADVANCE_FAIL, payload: error.response.data.message });
  
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.CREATE_ADVANCE_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetCreateAdvanceResponse = () => {    
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.CREATE_ADVANCE_RESET, payload:{} });
      };
  }
  
  const getAdvanceDetails = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAILS_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/advances/'+resourceId,
            headers: { },

          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAILS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAILS_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAILS_FAIL, payload: error.message });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAILS_FAIL, payload: error.message });
        }
      }
    };
  };
  const getAdvanceDetailStep = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAIL_STEP_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/advances/approvalList/'+resourceId,
            headers: { },

          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAIL_STEP_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAIL_STEP_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAIL_STEP_FAIL, payload: error.message });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.GET_ADVANCE_DETAIL_STEP_FAIL, payload: error.message });
        }
      }
    };
  };
  const advanceChangeStatus = (data,type) => {

    const changeMethod = type === 'canceled' ? 'delete' : 'post';

    return async (dispatch) => {
      try {
           var config = {

            method: changeMethod,
            url: apiUrl+'/advances/'+data.resourceId+'/'+type,
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data.description
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200 || response.status===201){
                dispatch({ type: AdvanceActionType.ADVANCE_CHANGE_STATUS_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: AdvanceActionType.ADVANCE_CHANGE_STATUS_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: AdvanceActionType.ADVANCE_CHANGE_STATUS_FAIL, payload: error.response.data.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.ADVANCE_CHANGE_STATUS_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetAdvanceChangeStatus = () => {    
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.ADVANCE_CHANGE_STATUS_RESET, payload:{} });
      };
  }
  const advanceDownload  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);

      try {
          var config = {
            method: 'post',
            url: apiUrl+'/advances/export',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          
          hrApi(config)
          .then(function (response,typeId)  {
  
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
 
               FileDownload(response.data, 'advance_'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: AdvanceActionType.ADVANCE_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: AdvanceActionType.ADVANCE_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.ADVANCE_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetAdvanceDownload = () => {
    
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.RESET_ADVANCE_DOWNLOAD, payload:{} });
      };
  }
  const advanceApprovalsDownload  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);
      try {
          var config = {
            method: 'post',
            url: apiUrl+'/advances/exportmanager/',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          hrApi(config)
          .then(function (response,typeId)  {
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
               FileDownload(response.data, 'advance_approvals_'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: AdvanceActionType.ADVANCE_APPROVALS_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: AdvanceActionType.ADVANCE_APPROVALS_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: AdvanceActionType.ADVANCE_APPROVALS_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetAdvanceApprovalsDownload = () => {
    return async (dispatch) => {
      dispatch({ type: AdvanceActionType.RESET_ADVANCE_APPROVALS_EXPORT, payload:{} });
      };
  }
const deleteAdvance = (resourceId) => {
  return async (dispatch) => {
    try {
      
      var config = {
        method: 'delete',
        url: apiUrl+'/advances/'+resourceId,
        headers: { 
        },
      };
      
      hrApi(config)
      .then(function (response) {

        if(response.status===200){
            dispatch({ type: AdvanceActionType.DELETE_ADVANCE_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: AdvanceActionType.DELETE_ADVANCE_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 422){
          dispatch({ type: AdvanceActionType.DELETE_ADVANCE_FAIL, payload: error.response.data.message});
        }else{
          dispatch({ type: AdvanceActionType.DELETE_ADVANCE_FAIL, payload: error.message });
        }
      });

       
    } catch (error) {
      if (error.response) {
          dispatch({ type: AdvanceActionType.DELETE_ADVANCE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteAdvanceReset = () => {
  
  return async (dispatch) => {
    dispatch({ type: AdvanceActionType.DELETE_ADVANCE_RESET, payload:{} });
    };
}
const getApproveAdvanceOnMail = (data) => {
  return async (dispatch) => {
    dispatch({ type: AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_REQUEST });
    try {
         var config = {
          method: 'get',
          url: apiUrl+'/advances/approveOnMail?data='+data,
          headers: { }
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_SUCCESS, payload: response.data.message });
          }else{
              dispatch({ type: AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_FAIL, payload: error.response.data.message });
        });

       
    } catch (error) {
      if (error.response) {
          dispatch({ type: AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_FAIL, payload: error.message });
      }
    }
  };
};
const updateAdvance = (data,resourceId) => {
  return async (dispatch) => {
    try {
         var config = {
          method: 'put',
          url: apiUrl+'/advances/'+resourceId,
          headers: { 
            'Content-Type': 'application/json'
          },
          data:data
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: AdvanceActionType.UPDATE_ADVANCE_SUCCESS, payload: response.data.message });
          }else{
              dispatch({ type: AdvanceActionType.UPDATE_ADVANCE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: AdvanceActionType.UPDATE_ADVANCE_FAIL, payload: error.message });
        });

       
    } catch (error) {
      if (error.response) {
          dispatch({ type: AdvanceActionType.UPDATE_ADVANCE_FAIL, payload: error.message });
      }
    }
  };
};
const updateAdvanceReset = () => {
  return async (dispatch) => {
    dispatch({ type: AdvanceActionType.UPDATE_ADVANCE_RESET, payload:{} });
    };
}
export {
    AdvanceActionType,
    setAdvanceCreateDrawer,
    setAdvanceDetailsDrawer,
    getMyAdvanceRequests,
    getAdvanceDetailsTable,
    getAdvanceManager,
    getAllAdvanceRequests,
    createAdvance,
    resetCreateAdvanceResponse,
    getAdvanceDetails,
    getAdvanceDetailStep,
    advanceChangeStatus,
    resetAdvanceChangeStatus,
    advanceDownload,
    resetAdvanceDownload,
    advanceApprovalsDownload,
    resetAdvanceApprovalsDownload,
    deleteAdvance,
    deleteAdvanceReset,
    getApproveAdvanceOnMail,
    updateAdvance,
    updateAdvanceReset
};