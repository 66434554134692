import { hrApi } from "../../utils/apiConfig";
import { paymentApiUrl } from '../../utils/config';


const SubscriptionActionType = {
    GET_SUBSCRIPTION_REQUEST: "GET_SUBSCRIPTION_REQUEST",
    GET_SUBSCRIPTION_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
    GET_SUBSCRIPTION_FAIL: "GET_SUBSCRIPTION_FAIL",

    SAVE_CARD_SUBSCRIPTION_REQUEST: "SAVE_CARD_SUBSCRIPTION_REQUEST",
    SAVE_CARD_SUBSCRIPTION_SUCCESS: "SAVE_CARD_SUBSCRIPTION_SUCCESS",
    SAVE_CARD_SUBSCRIPTION_FAIL: "SAVE_CARD_SUBSCRIPTION_FAIL",

    DELETE_CARD_SUBSCRIPTION_REQUEST: "DELETE_CARD_SUBSCRIPTION_REQUEST",
    DELETE_CARD_SUBSCRIPTION_SUCCESS: "DELETE_CARD_SUBSCRIPTION_SUCCESS",
    DELETE_CARD_SUBSCRIPTION_FAIL: "DELETE_CARD_SUBSCRIPTION_FAIL",

    TRY_PAYMENT_AGAIN_SUBSCRIPTION_REQUEST: "TRY_PAYMENT_AGAIN_SUBSCRIPTION_REQUEST",
    TRY_PAYMENT_AGAIN_SUBSCRIPTION_SUCCESS: "TRY_PAYMENT_AGAIN_SUBSCRIPTION_SUCCESS",
    TRY_PAYMENT_AGAIN_SUBSCRIPTION_FAIL: "TRY_PAYMENT_AGAIN_SUBSCRIPTION_FAIL",

};

const getSubscription = (resetLoadingStates = null) => {
    return async (dispatch) => {
      dispatch({ type: SubscriptionActionType.GET_SUBSCRIPTION_REQUEST });

      if(resetLoadingStates){ return; }

      try {
        var config = {
          method: "get",
          url: paymentApiUrl + "/Subscription/packageAndPaymentInfo",
          headers: {},
        };
        hrApi(config)
          .then(function (response) {
            if (response.status === 200) {
              dispatch({ type: SubscriptionActionType.GET_SUBSCRIPTION_SUCCESS, payload: response.data.data });
            } else {
              dispatch({ type: SubscriptionActionType.GET_SUBSCRIPTION_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: SubscriptionActionType.GET_SUBSCRIPTION_FAIL, payload: error.message });
          });
      } catch (error) {
        if (error.response) {
          dispatch({ type: SubscriptionActionType.GET_SUBSCRIPTION_FAIL, payload: error.message });
        }
      }
    };
  };
const addOrUpdateCard = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_REQUEST });
    try {
        var data = JSON.stringify(payload);
        var config = {
          method: 'put',
          url: paymentApiUrl+'/Subscription/addOrUpdateCard',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_SUCCESS, payload: response?.data?.data });
          }else{
              dispatch({ type: SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 422){
            //dispatch({ type: SubscriptionActionType.GET_PAYMENT_TRANSACTION_INPUT_ERROR, payload: error.response.data.validationMessages });
          }else{
              dispatch({ type: SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_FAIL, payload: error.message });
      }
    }
  };
};
const deleteCard = () => {
  return async (dispatch) => {
    dispatch({ type: SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_REQUEST });
    try {
      var config = {
        method: "delete",
        url: paymentApiUrl + "/Subscription/deleteCard",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_FAIL, payload: error.message });
      }
    }
  };
};

const tryPaymentAgain = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_REQUEST });
    try {
        var data = JSON.stringify(payload);
        var config = {
          method: 'post',
          url: paymentApiUrl+'/Subscription/retryPayment',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_SUCCESS, payload: response?.data?.data });
          }else{
              dispatch({ type: SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 422){
            //dispatch({ type: SubscriptionActionType.GET_PAYMENT_TRANSACTION_INPUT_ERROR, payload: error.response.data.validationMessages });
          }else{
              dispatch({ type: SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_FAIL, payload: error.message });
      }
    }
  };
};


  export {
    getSubscription,
    SubscriptionActionType,
    addOrUpdateCard,
    deleteCard,
    tryPaymentAgain,
  }
