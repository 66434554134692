import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, Col, Row, Button, Steps, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'

import CreateDepartmentBasicInfo from './department-add-basic-info';
import CreateDepartmentAddress from './department-add-address-info';
import { setDepartmentAddDraw } from '../../../redux/actions/person-organization-drawer-action'
import { createDepartment, resetCreateDepartmentResponse, departmentSearch, getDepartmentOrgChart, resetPersonWorkHours, getPersonWorkHours } from '../../../redux/actions/organization.actions'
import Successful from "../../successful";
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import { sendGtmEvent } from '../../../utils/gtmFunction';
import { setCurrentTaskState, setUserTaskInfo } from "../../../redux/actions/onboarding.actions";

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

function CreateDepartmentTabs(props) {
   
    const { setDepartmentAddDraw, createDepartment, organization, profile, resetPersonWorkHours, getPersonWorkHours,
        resetCreateDepartmentResponse, getDepartmentOrgChart, departmentSearch, searchData, returnShow } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [createDepartmentObj, setCreateDepartmentObj] = useState({});

    const { Step } = Steps;
    const { t } = useTranslation();
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const { TabPane } = Tabs;


    function callback(key) {
        checkRequiredFields(() => setCurrentTab(parseInt(key)));

    }
    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function nextButton() {
        checkRequiredFields(() => setCurrentTab(currentTab + 1));
    }
    function departmentCloseControl() {
        if (currentTab !== 3 && (createDepartmentObj.DepartmentManagerResourceId || createDepartmentObj.Name || createDepartmentObj.ParentDepartmentResourceId)) {
            setConfirmModalData({ 'isVisible': true })
        } else {
            destroyLastState()
        }
    }
    function destroyLastState() {
        setCurrentTab(1);
        setDepartmentAddDraw(false)
        setCreateDepartmentObj({});
        resetPersonWorkHours();
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateDepartmentObj({});
        resetPersonWorkHours();
        getPersonWorkHours();
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createDepartmentObj, obj)
        setCreateDepartmentObj(mergedObj)
    }

    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []
        if (!createDepartmentObj.Name || createDepartmentObj.Name === "") {
            hasError = true
            fields.push(t('DEPARTMENT_NAME'))
        }


        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'add_departments_warning_text',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const handleCreateOrganization = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        checkRequiredFields(() => createDepartment(createDepartmentObj))

        window.sendinblue?.track(
            //brevo_event_create_department
            'create_department',
        )
    }
    useEffect(() => {
        if (organization.departmentCreateResponse.status === 'SUCCESS') {
            setCurrentTab(currentTab + 1);
            resetCreateDepartmentResponse()
            departmentSearch(searchData)
            getDepartmentOrgChart(profile.organization?.resourceId);
            sendGtmEvent("department_create_success");
            resetPersonWorkHours();
            //onboarding
                if(props.currentTaskState == 13 && !props.userTaskInfo.completedTasks.includes(1)){
                    destroyLastState();
                    setTimeout(() => { props.setCurrentTaskState(14); }, 200);
                }
                if(props.currentTaskState !== 13 && !props.userTaskInfo.completedTasks.includes(1)){                    
                    destroyLastState();
                    setTimeout(() => { props.setCurrentTaskState(1004);}, 200);
                }
                
        } else if (organization.departmentCreateResponse.status === 'FAIL') {
            setCurrentTab(2);
            resetCreateDepartmentResponse()
            DynamicToastMessage({
                'key': 'error',
                'title': t('ERROR'),
                'description': organization.departmentCreateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateDepartmentResponse()
        } else if (organization.departmentCreateResponse.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'error2',
                'title': t('ERROR'),
                'description': <List>{organization.departmentCreateResponse.message.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateDepartmentResponse()
        }

    }, [organization.departmentCreateResponse])

    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })


    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={destroyLastState}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => departmentCloseControl()} />
                </div>
                {currentTab !== 3 ?
                    <React.Fragment>
                        <Col offset={6} span={16}>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('CREATE_NEW_DEPARTMENTS')}</h1>
                            </div>
                        </Col>
                        <div className="w-100">
                            <Row>
                                <Col span={6}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('ORGANIZATION_BASIC_INFORMATION')} description="" />
                                        <Step title={t('ORGANIZATION_ADDRESS_INFO')} />
                                        <Step title={t('DEPARTMENT_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={17}>

                                    <Tabs className="custom-tab add-user-draw"
                                        onChange={callback}
                                        activeKey={currentTab.toString()}
                                        defaultActiveKey={currentTab.toString()}
                                    >
                                        <TabPane tab={`${t('ORGANIZATION_BASIC_INFORMATION')}`} key={1}>
                                            <CreateDepartmentBasicInfo setCreateObject={(o) => setCreateObject(o)} />
                                        </TabPane>
                                        <TabPane tab={t('ORGANIZATION_ADDRESS_INFO')} key={2}>
                                            <CreateDepartmentAddress setCreateObject={(o) => setCreateObject(o)} />
                                        </TabPane>


                                    </Tabs>


                                </Col>
                            </Row>

                        </div>
                    </React.Fragment>
                    :
                    <Successful message={t('DEPARTMENT_COMPLETE_MESSAGE')} />

                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 3 ?
                    <Button onClick={() => departmentCloseControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                {/* clear state */}
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 3 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 3 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('CREATE_NEW_DEPARTMENTS')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 3 ?
                        currentTab === 2 ?
                            <Button onClick={() => handleCreateOrganization()} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('DEPARTMENT_NEW2')}</Button>
                            :
                            <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_NEXT')}</Button>
                        :
                        returnShow ?
                            <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                            :
                            <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('RETURN_HOMEPAGE')}</Button>

                        //clear state
                    }
                </div>

            </div>
        </div>
    )

}



const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        profile: state.profile,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDepartmentAddDraw: (props) => { dispatch(setDepartmentAddDraw(props)) },
        createDepartment: (props) => { dispatch(createDepartment(props)) },
        resetCreateDepartmentResponse: () => { dispatch(resetCreateDepartmentResponse()) },
        departmentSearch: (searchData) => { dispatch(departmentSearch(searchData)) },
        getDepartmentOrgChart: (id) => { dispatch(getDepartmentOrgChart(id)) },
        resetPersonWorkHours: () => { dispatch(resetPersonWorkHours()) },
        getPersonWorkHours: () => { dispatch(getPersonWorkHours()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDepartmentTabs);
