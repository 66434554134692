import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';


const Dashboard = {
    GET_TIME_OFF_CONFIRMED_PERSONS_REQUEST : "GET_TIME_OFF_CONFIRMED_PERSONS_REQUEST",
    GET_TIME_OFF_CONFIRMED_PERSONS_SUCCESS : "GET_TIME_OFF_CONFIRMED_PERSONS_SUCCESS",
    GET_TIME_OFF_CONFIRMED_PERSONS_FAIL : "GET_TIME_OFF_CONFIRMED_PERSONS_FAIL",
    GET_CANGRATULATIONS_REQUEST:"GET_CANGRATULATIONS_REQUEST",
    GET_CANGRATULATIONS_SUCCESS:"GET_CANGRATULATIONS_SUCCESS",
    GET_CANGRATULATIONS_FAIL:"GET_CANGRATULATIONS_FAIL",
    GET_TIME_OFF_LIST_BY_PERSON_REQUEST:"GET_TIME_OFF_LIST_BY_PERSON_REQUEST",
    GET_TIME_OFF_LIST_BY_PERSON_SUCCESS:"GET_TIME_OFF_LIST_BY_PERSON_SUCCESS",
    GET_TIME_OFF_LIST_BY_PERSON_FAIL:"GET_TIME_OFF_LIST_BY_PERSON_FAIL",
    GET_NUMBER_OF_FEMALE_AND_MALE_SUCCESS:"GET_NUMBER_OF_FEMALE_AND_MALE_SUCCESS",
    GET_NUMBER_OF_FEMALE_AND_MALE_FAIL:"GET_NUMBER_OF_FEMALE_AND_MALE_FAIL",
    GET_NUMBER_OF_FEMALE_AND_MALE_REQUEST:"GET_NUMBER_OF_FEMALE_AND_MALE_REQUEST",
    GET_NUMBER_OF_MARRIED_AND_SINGLE_REQUEST:"GET_NUMBER_OF_MARRIED_AND_SINGLE_REQUEST",
    GET_NUMBER_OF_MARRIED_AND_SINGLE_SUCCESS:"GET_NUMBER_OF_MARRIED_AND_SINGLE_SUCCESS",
    GET_NUMBER_OF_MARRIED_AND_SINGLE_FAIL:"GET_NUMBER_OF_MARRIED_AND_SINGLE_FAIL",
    GET_EMPLOYEE_EXPERIENCE_RATES_REQUEST:"GET_EMPLOYEE_EXPERIENCE_RATES_REQUEST",
    GET_EMPLOYEE_EXPERIENCE_RATES_SUCCESS:"GET_EMPLOYEE_EXPERIENCE_RATES_SUCCESS",
    GET_EMPLOYEE_EXPERIENCE_RATES_FAIL:"GET_EMPLOYEE_EXPERIENCE_RATES_FAIL",
    GET_EMPLOYEES_WORKING_TIME_COMPANY_REQUEST:"GET_EMPLOYEES_WORKING_TIME_COMPANY_REQUEST",
    GET_EMPLOYEES_WORKING_TIME_COMPANY_SUCCESS:"GET_EMPLOYEES_WORKING_TIME_COMPANY_SUCCESS",
    GET_EMPLOYEES_WORKING_TIME_COMPANY_FAIL:"GET_EMPLOYEES_WORKING_TIME_COMPANY_FAIL",
    GET_NUMBER_OF_CHILDREN_EMPLOYEES_REQUEST:"GET_NUMBER_OF_CHILDREN_EMPLOYEES_REQUEST",
    GET_NUMBER_OF_CHILDREN_EMPLOYEES_SUCCESS:"GET_NUMBER_OF_CHILDREN_EMPLOYEES_SUCCESS",
    GET_NUMBER_OF_CHILDREN_EMPLOYEES_FAIL:"GET_NUMBER_OF_CHILDREN_EMPLOYEES_FAIL",
    GET_DISTRIBUTION_OF_EMPLOYEES_REQUEST:"GET_DISTRIBUTION_OF_EMPLOYEES_REQUEST",
    GET_DISTRIBUTION_OF_EMPLOYEES_SUCCESS:"GET_DISTRIBUTION_OF_EMPLOYEES_SUCCESS",
    GET_DISTRIBUTION_OF_EMPLOYEES_FAIL:"GET_DISTRIBUTION_OF_EMPLOYEES_FAIL",
    GET_PENDING_APPROVAL_TIME_OFF_REQUEST:"GET_PENDING_APPROVAL_TIME_OFF_REQUEST",
    GET_PENDING_APPROVAL_TIME_OFF_SUCCESS:"GET_PENDING_APPROVAL_TIME_OFF_SUCCESS",
    GET_PENDING_APPROVAL_TIME_OFF_FAIL:"GET_PENDING_APPROVAL_TIME_OFF_FAIL",
    GET_PENDING_MY_APPROVAL_REQUEST:"GET_PENDING_MY_APPROVAL_REQUEST",
    GET_PENDING_MY_APPROVAL_SUCCESS:"GET_PENDING_MY_APPROVAL_SUCCESS",
    GET_PENDING_MY_APPROVAL_FAIL:"GET_PENDING_MY_APPROVAL_FAIL",
    GET_DASHBOARD_TIME_OFF_REQUEST:"GET_DASHBOARD_TIME_OFF_REQUEST",
    GET_DASHBOARD_TIME_OFF_SUCCESS:"GET_DASHBOARD_TIME_OFF_SUCCESS",
    GET_DASHBOARD_TIME_OFF_FAIL:"GET_DASHBOARD_TIME_OFF_FAIL",
    GET_DASHBOARD_TIME_OFF_RESET:"GET_DASHBOARD_TIME_OFF_RESET"
}

  const getTimeOffRequestConfirmedPersons = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/timeOffRequestConfirmedPersons',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_FAIL, payload: error.message });
        }
      }
    };
  };

  const getTimeOffRequestListByPerson = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_TIME_OFF_LIST_BY_PERSON_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/timeOffRequestListByPerson',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_TIME_OFF_LIST_BY_PERSON_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_TIME_OFF_LIST_BY_PERSON_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_TIME_OFF_LIST_BY_PERSON_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_TIME_OFF_LIST_BY_PERSON_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_TIME_OFF_LIST_BY_PERSON_FAIL, payload: error.message });
        }
      }
    };
  };
  const getCongratulations = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_CANGRATULATIONS_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/congratulations',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_CANGRATULATIONS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_CANGRATULATIONS_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_CANGRATULATIONS_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_CANGRATULATIONS_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_CANGRATULATIONS_FAIL, payload: error.message });
        }
      }
    };
  };

  const getNumberOfFemaleAndMaleEmployees = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/numberOfFemaleAndMaleEmployees',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_FAIL, payload: error.message });
        }
      }
    };
  };
  
  const getNumberOfMarriedAndSingleEmployees = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/numberOfMarriedAndSingleEmployees',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_FAIL, payload: error.message });
        }
      }
    };
  };
  
  const getEmployeeExperienceRates = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/employeeExperienceRates',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_FAIL, payload: error.message });
        }
      }
    };
  };

  const getEmployeesWorkingTimeInCompany = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/employeesWorkingTimeInTheCompany',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_FAIL, payload: error.message });
        }
      }
    };
  };

  const getNumberOfChildrenEmployees = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/numberOfChildrenEmployees',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_FAIL, payload: error.message });
        }
      }
    };
  };

  const getDistributionOfEmployeesByDepartments = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/distributionOfEmployeesByDepartments',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_FAIL, payload: error.message });
        }
      }
    };
  };


  const getPendingApprovalTimeOffRequest = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_PENDING_APPROVAL_TIME_OFF_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/pendingApprovalTimeOffRequest',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_PENDING_APPROVAL_TIME_OFF_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_PENDING_APPROVAL_TIME_OFF_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_PENDING_APPROVAL_TIME_OFF_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_PENDING_APPROVAL_TIME_OFF_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_PENDING_APPROVAL_TIME_OFF_FAIL, payload: error.message });
        }
      }
    };
  };

  const getRequestsPendingMyApproval = () => {
    return async (dispatch) => {
      dispatch({ type: Dashboard.GET_PENDING_MY_APPROVAL_REQUEST });
      try {
  
          var config = {
            method: 'get',
            url: apiUrl+'/dashboards/requestsPendingMyApproval',
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: Dashboard.GET_PENDING_MY_APPROVAL_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: Dashboard.GET_PENDING_MY_APPROVAL_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: Dashboard.GET_PENDING_MY_APPROVAL_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: Dashboard.GET_PENDING_MY_APPROVAL_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: Dashboard.GET_PENDING_MY_APPROVAL_FAIL, payload: error.message });
        }
      }
    };
  };



const getTimeOffDashboardPaiChartData = (personResourceId,timeOffResourceId,clear) => {
  return async (dispatch) => {
    //TODO : this state clearing solution must apply on all actions
    if(clear!==false){
      dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_SUCCESS, payload: [] });
    }
    dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/timeOffRequests/remainingTimeOffRequest/'+personResourceId+'/'+timeOffResourceId,
          headers: { 
            'Content-Type': 'application/json'
          },
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if(error.response.status === 422){
            dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_FAIL, payload: error.response.data });

          }
        });

       
    } catch (error) {
      if (error.response) {
          dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_FAIL, payload: error.message });
      }
    }
  };
};
const resetTimeOffDashboardPaiChartData = () => {
  return async (dispatch) => {
    dispatch({ type: Dashboard.GET_DASHBOARD_TIME_OFF_RESET, payload:{} });
    };
}
  export {
    Dashboard,
    getTimeOffRequestConfirmedPersons,
    getTimeOffRequestListByPerson,
    getCongratulations,
    getNumberOfFemaleAndMaleEmployees,
    getNumberOfMarriedAndSingleEmployees,
    getEmployeeExperienceRates,
    getEmployeesWorkingTimeInCompany,
    getNumberOfChildrenEmployees,
    getDistributionOfEmployeesByDepartments,
    getPendingApprovalTimeOffRequest,
    getRequestsPendingMyApproval,
    getTimeOffDashboardPaiChartData,
    resetTimeOffDashboardPaiChartData

}