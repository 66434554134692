const initialState = {
    parentOrganization: [],
    managerOrganization: [],
    parentDepartment: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {

        case 'GET_PARENT_ORGANIZATION_SUCCESS':
            return {
                ...state,
                parentOrganization: action.payload,
        }
        case 'GET_MANAGER_ORGANIZATION_SUCCESS':
            return {
                ...state,
                managerOrganization: action.payload,
        }
        case 'GET_PARENT_DEPARTMENT_SUCCESS':
            return {
                ...state,
                parentDepartment: action.payload,
        }
        default: return state
    }

}
