import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Card, Button, Layout, Typography, Image, Row, Col, Spin } from 'antd';
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Header from "../features/header";
import Footer from "../features/footer";

import Can from '../authorization/index';
import Cannot from '../authorization/cannot';
import { signoutRedirect } from '../services/userService'

import '../styles/header.scss';
import '../styles/global.scss';
import '../styles/footer.scss';
import '../styles/trial-expired.scss';
const TrialExpiredLayout = (props) => {
  
    const { Content } = Layout;
    const { Paragraph } = Typography;
    const profile = useSelector(state => state.auth?.user?.profile);
    const user = useSelector(state => state.auth?.user);
    const history = useHistory();
    const { t } = useTranslation();

    const handleGoPrices  = () =>{
        history.push('/prices')
    }

    useEffect(()=>{
        if(profile.organizationPackageId==undefined){
            signoutRedirect(user)
        }
    },[])

    return (
        <Layout >
            <Header />
            <Content>
            {profile.organizationPackageId == undefined ? (
                <Row className="trial-expired-content">
                    <Col style={{textAlign:'center'}}>
                        <Spin/>
                    </Col>
                </Row>
            ):(
                <Row className="trial-expired-content">
                    <Col xs={24} sm={15} lg={10} xl={8} xxl={6} >
                        
                        <Card style={{ minHeight: 332, textAlign: 'center'  }} >
                            <Image src={process.env.REACT_APP_URL + "images/trial-expired-sad.png"} alt={t("TRIAL_PERIOD_EXPIRED")} preview={false} />
                            <h4>{t("HELLO")} {profile?.givenName}!</h4>
                            <Can I="payment">
                                <Paragraph style={{margin:'20px 0px'}}>
                                    {profile.organizationPackageId == 0 ? t("TRIAL_PERIOD_EXPIRED") : t("SUBSCRIPTION_SUSPENDED")}
                                </Paragraph>
                                <Button type="primary" size="large" block onClick={handleGoPrices} >
                                   {t("GO_TO_PACKAGES")}
                                </Button>
                            </Can>
                            <Cannot I="payment">
                                <Paragraph style={{margin:'20px 0px'}}>
                                   {profile.organizationPackageId == 0 ? t("TRIAL_PERIOD_EXPIRED_2") : t("SUBSCRIPTION_SUSPENDED_2")}
                                </Paragraph>
                            </Cannot>
                        </Card>
                        
                    </Col>
                </Row>
            )}
            </Content>
            <Footer/>
        </Layout>
    );
};
export default connect((state) => state)(TrialExpiredLayout);