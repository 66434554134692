import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tabs, Image, Upload, Button, Spin, List } from 'antd';
import ImgCrop from 'antd-img-crop';
// ICONS
import {
    CloseOutlined
} from '@ant-design/icons';
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons';
import { setOrganizationDetailsDrawer } from '../../../redux/actions/person-organization-drawer-action';
import { resetAction, updateOrganization, resetUpdateOrganization, getOrganizationDetailsInfo } from '../../../redux/actions/organizationDetail.actions';
import { organizationSearch, getOrganizationOrgChart, deleteLogoOrganization, deleteOrganizationLogoResponseReset } from '../../../redux/actions/organization.actions';
import { resetAddress } from '../../../redux/actions/address.actions';
import OrganizationBasicInfo from './organization-basic-info';
import OrganizationAddressInfo from './organization-address-info';
import OrganizationBillingInfo from './organization-billing-info';
import DynamicRequestModal from '../../dynamicModal/requestModal'
import DynamicToastMessage from '../../dynamicToastMessage/index';

import { deepDiffMapper, MergeObjects, resetScrollPosition } from '../../../utils/commonFormFunctions'
import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";

function OrganizationDetailsDraw(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [currentTab, setCurrentTab] = useState("OrganizationBasicInfo");

    const { organizationDetailsInfo, getOrganizationDetailsInfo, organizationDetailsInfoIsFetching, setOrganizationDetailsDrawer, resetAction, organizationLogoResponse,
        organizationSearch, resetAddress, resetUpdateOrganization, updateOrganization, updateResponse, searchData, auth, deleteLogoOrganization, deleteOrganizationLogoResponseReset } = props;
    const [organizationRef, setOrganizationRef] = useState("{}")
    const [organizationUpdateDataObj, setOrganizationUpdateDataObj] = useState({})
    const [deleteLogoModalData, setDeleteLogoModalData] = useState({ "isVisible": false })
    const access_token = auth.user?.access_token;

    const setOrganizationUpdateObj = (o) => {
        const diffObj = deepDiffMapper.map(JSON.parse(organizationRef), o);
        const mergedObj = MergeObjects(organizationUpdateDataObj, diffObj)
        setOrganizationUpdateDataObj(mergedObj)
    }

    useEffect(() => {
        setOrganizationRef(JSON.stringify({ ...organizationDetailsInfo }))
    }, [organizationDetailsInfo])

    useEffect(() => {
        setOrganizationUpdateDataObj(JSON.parse(organizationRef))
    }, [organizationRef])

    useEffect(() => {
        if (updateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'UPDATE_ORGANIZATION_SUCCESS',
                'title': t('SUCCESS'),
                'description': updateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            resetUpdateOrganization()
            organizationSearch(searchData)
            getOrganizationDetailsInfo(organizationDetailsInfo.resourceId);
        } else if (updateResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'UPDATE_ORGANIZATION_ERROR',
                'title': t('ERROR'),
                'description': updateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateOrganization()
        } else if (updateResponse.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'UPDATE_ORGANIZATION_ERROR2',
                'title': t('ERROR'),
                'description': <List>{updateResponse.message.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateOrganization()
        }
    }, [updateResponse]);

    function callback(key) {
        setCurrentTab(key);
        resetAddress()
        resetScrollPosition('.drawer-scrollable-tab > .ant-tabs-content-holder');
    }
    function close() {
        setOrganizationDetailsDrawer(false);
        setCurrentTab("OrganizationBasicInfo");
        resetAction();
        resetAddress()
        setOrganizationUpdateDataObj({});
    }

    const [isDrawerActionSaveOrganizationLoading, setisDrawerActionSaveOrganizationLoading] = useState(false);

    const handleUpdateOrganization = () => {

        if(isDrawerActionSaveOrganizationLoading) return;
        setisDrawerActionSaveOrganizationLoading(true);
        setTimeout(() => {setisDrawerActionSaveOrganizationLoading(false);}, 1055) // double click prevent;
        const data = {
            "ParentOrganizationResourceId": organizationDetailsInfo.parentOrganization?.id || null,
            "OrganizationType": organizationUpdateDataObj.organizationType.id || null,
            "Name": organizationUpdateDataObj.name || null,
            "LegalName": organizationUpdateDataObj.legalName || null,
            "Telephone": organizationUpdateDataObj.telephone || null,
            "PhoneExt": organizationUpdateDataObj.phoneExt || null,
            "Email": organizationUpdateDataObj.email || null,
            "PageUrl": organizationUpdateDataObj.pageUrl || null,
            "SectorId": organizationUpdateDataObj.sector?.id || null,
            "ImageLogoPath": null,
            "Address": {
                "CountryId": organizationUpdateDataObj.address?.country?.id || null,
                "ProvinceId": organizationUpdateDataObj.address?.province?.id || null,
                "Province": organizationUpdateDataObj.address?.province || null,
                "DistrictId": organizationUpdateDataObj.address?.district?.id || null,
                "NeighborhoodId": organizationUpdateDataObj.address?.neighborhood?.id || null,
                "PostalCode": organizationUpdateDataObj.address?.postalCode || null,
                "AddressLine": organizationUpdateDataObj.address?.addressLine || null,
                "Geo": {
                    "Latitude": 38.8951,
                    "Longitude": -77.0364
                },
                "Locality": "",
            },
            "BillingAddress": {
                "CountryId": organizationUpdateDataObj.billingAddress?.country?.id || null,
                "Province": organizationUpdateDataObj.billingAddress?.province || null,
                "DistrictId": organizationUpdateDataObj.billingAddress?.district?.id || null,
                "NeighborhoodId": organizationUpdateDataObj.billingAddress?.neighborhood?.id || null,
                "PostalCode": organizationUpdateDataObj.billingAddress?.postalCode || null,
                "AddressLine": organizationUpdateDataObj.billingAddress?.addressLine || null,
                "Locality": "",
                "CompanyName": organizationUpdateDataObj.billingAddress?.companyName || null,
                "TaxOfficeResourceId": organizationUpdateDataObj.billingAddress?.taxOffice?.id || null,
                "TaxOfficeId": Number(organizationUpdateDataObj.billingAddress?.taxOffice?.id) || 0,
                "TaxId": organizationUpdateDataObj.billingAddress?.taxId || null,
                "Tckn": organizationUpdateDataObj.billingAddress?.tckn || null,
                "AuthorizedPerson": organizationUpdateDataObj.billingAddress?.authorizedPerson || null,
                "Email": organizationUpdateDataObj.billingAddress?.email || null,
                "Telephone": organizationUpdateDataObj.billingAddress?.telephone || null
            }
        }

        let hasError = false
        let fields = []
        if ((!data.OrganizationType || data.OrganizationType === '')) {
            hasError = true
            fields.push('( ' + t('SELECT_ORGANIZATION_TYPE') + ' )')
        }
        if ((!data.Name || data.Name === '')) {
            hasError = true
            fields.push('( ' + t('ORGANIZATION_NAME') + ' )' + ' ' + t('CANNOT_BE_EMPTY'))
        }
        if ((!data.LegalName || data.NLegalNameame === '')) {
            hasError = true
            fields.push('( ' + t('ORGANIZATION_LEGAL_NAME') + ' )' + ' ' + t('CANNOT_BE_EMPTY'))
        }
        if (!hasError) {
            const resourceId = organizationDetailsInfo.resourceId
            updateOrganization({ "resourceId": resourceId, "data": data });
            resetAddress();
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const uploadImgAction = (values) => {
        if (values?.file?.response) {
            if (values?.file?.response?.status === "SUCCESS") {
                DynamicToastMessage({
                    'key': 'upload_image_response_success',
                    'title': t('SUCCESS'),
                    'description': values?.file?.response?.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                organizationSearch(searchData)
                getOrganizationDetailsInfo(organizationDetailsInfo.resourceId);
            } else {
                DynamicToastMessage({
                    'key': 'upload_image_response_error',
                    'title': t('ERROR'),
                    'description': values?.file?.response?.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
            }
        }
    }
    useEffect(() => {
        if (organizationLogoResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': organizationLogoResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getOrganizationDetailsInfo(organizationDetailsInfo.resourceId)
            deleteOrganizationLogoResponseReset()

        } else if (organizationLogoResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': organizationLogoResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteOrganizationLogoResponseReset()

        }
    }, [organizationLogoResponse])

    return (
        organizationDetailsInfoIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <div className="table-details organization-drawer">
                <div className="close-icon" onClick={() => close()}>
                    <CloseOutlined />
                </div>
                <DynamicRequestModal
                    modalVisible={deleteLogoModalData.isVisible}
                    setStateData={setDeleteLogoModalData}
                    postAction={deleteLogoOrganization}
                    confirmData={organizationDetailsInfo.resourceId}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_LOGO_DESC')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <Col span={24}>
                    <Row>
                        <Col span={18}>

                            <Row>
                                <Col span={5}>
                                    <div className="avatar">
                                        <Image src={organizationDetailsInfo?.imageLogoPath ? organizationDetailsInfo?.imageLogoPath : process.env.REACT_APP_URL + "images/org_undefined.png"} className="rounded-img" preview={false} />
                                    </div>
                                </Col>
                                <Col span={19}>
                                    <div className="user-details">
                                        <h1>{organizationDetailsInfo.name}</h1>
                                        <div className="info-alt">
                                            <h3>{organizationDetailsInfo.legalName}</h3>
                                            <h3>{organizationDetailsInfo.telephone}</h3>
                                            <h3>
                                                {organizationDetailsInfo.address?.province?.displayValue !== undefined ?
                                                    organizationDetailsInfo.address?.province?.displayValue + '/' + organizationDetailsInfo.address?.district?.displayValue
                                                    : ''}
                                            </h3>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Can I="organization.update">
                                <div className="avatar">
                                    <div className="change-avatar">

                                        <ImgCrop
                                            shape="round"
                                            modalTitle={t('UPLOAD_PICTURE')}
                                            modalOk={t('UPLOAD')}
                                            modalCancel={t('INVENTORY_CANCEL')}
                                        >
                                            <Upload
                                                action={"https://api.hrplan.net/hr-service/organizations/" + organizationDetailsInfo.resourceId + "/uploadOrganizationLogo"}
                                                headers={
                                                    {
                                                        "Authorization": `Bearer ${access_token}`
                                                    }
                                                }
                                                multiple={false}
                                                showUploadList={false}
                                                onChange={(e) => uploadImgAction(e)}
                                            >
                                                <UilEditAlt /> {organizationDetailsInfo?.imageLogoPath ? t('PARTNER_CHANGE') : t('UPLOAD_LOGO')}
                                            </Upload>
                                        </ImgCrop>
                                        {organizationDetailsInfo?.imageLogoPath ?
                                            <span onClick={() => setDeleteLogoModalData({ ...deleteLogoModalData, "isVisible": true })} className="pad-left-10"><UilTrash />  {t('PARTNER_DELETE')}</span> : ''}
                                    </div>
                                </div>
                            </Can>
                            <Cannot I="organization.update">
                                <div className="pad-15" />
                            </Cannot>
                        </Col>
                        <Col span={6}>
                            <div className="changepage">
                                <Row gutter={[0, 15]}>

                                    <div className="procces">
                                        {/* <Dropdown overlay={organization_details(t)} trigger={['click']}>
                                            <Button className="primary-btn w-180 h-32"> <SettingOutlined />{t('PARTNER_PROCESS')} </Button>
                                        </Dropdown> */}
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <div className="hr-bottom"></div>
                <Tabs
                    className="custom-tab w-100 drawer-scrollable-tab"
                    onChange={callback}
                    activeKey={currentTab}
                    defaultActiveKey={currentTab}
                >
                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key="OrganizationBasicInfo">
                        <OrganizationBasicInfo organizationDetailsInfo={organizationDetailsInfo} setOrganizationUpdateObj={(o) => setOrganizationUpdateObj(o)} />
                    </TabPane>
                    <TabPane tab={`${t('ORGANIZATION_ADDRESS_INFO')}`} key="OrganizationAdressInfo">
                        <OrganizationAddressInfo organizationDetailsInfo={organizationDetailsInfo} setOrganizationUpdateObj={(o) => setOrganizationUpdateObj(o)} />
                    </TabPane>
                    <TabPane tab={`${t('ORGANIZATION_INVOICE_INFORMATION')}`} key="OrganizationInvoiceInfo">
                        <OrganizationBillingInfo key={organizationUpdateDataObj.billingAddress?.taxOffice?.displayValue} organizationDetailsInfo={organizationDetailsInfo} setOrganizationUpdateObj={(o) => setOrganizationUpdateObj(o)} />
                    </TabPane>
                </Tabs>
                <div className="fixed-btn">
                    <div className="page-end">
                        <div className="hr-end"></div>
                    </div>

                    <div className="form-submit-space-between">
                        <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                        <div className="btn-padding" />
                        <Can I="organization.update">
                            <Button onClick={handleUpdateOrganization} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </Can>
                    </div>
                </div>
            </div >
        )
    )
}

const mapStateToProps = (state) => {
    return {
        updateResponse: state.organizationDetailsReducer.updateResponse,
        profile: state.profile,
        auth: state.auth,
        organizationLogoResponse: state.organization.organizationLogoResponse
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOrganizationDetailsDrawer: (props) => { dispatch(setOrganizationDetailsDrawer(props)) },
        organizationSearch: (searchData) => { dispatch(organizationSearch(searchData)) },
        resetAction: () => { dispatch(resetAction()) },
        resetUpdateOrganization: () => { dispatch(resetUpdateOrganization()) },
        updateOrganization: (props) => { dispatch(updateOrganization(props)) },
        resetAddress: () => { dispatch(resetAddress()) },
        getOrganizationDetailsInfo: (id) => { dispatch(getOrganizationDetailsInfo(id)) },
        getOrganizationOrgChart: (props) => { dispatch(getOrganizationOrgChart(props)) },
        deleteLogoOrganization: (props) => { dispatch(deleteLogoOrganization(props)) },
        deleteOrganizationLogoResponseReset: () => { dispatch(deleteOrganizationLogoResponseReset()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetailsDraw);