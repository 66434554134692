import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPersons } from '../../../redux/actions/organization.actions';
import {
    getRemainingTimeOffRequest, deleteExtraValue, deleteExtraValueReset,
    createExtraValue, createExtraValueReset, exportRemainderYears, exportRemainderYearsReset,
    getExtraValueDetail, deleteTimeOffAdjustsHistory, deleteTimeOffAdjustsHistoryReset, updateTimeOffAdjustsHistory, updateTimeOffAdjustsHistoryReset
} from '../../../redux/actions/timeOff.actions'
import { getTimeOffDashboardPaiChartData, resetTimeOffDashboardPaiChartData } from '../../../redux/actions/dashboard.actions';
import { addExtraValue, signedNumberMask } from '../../../utils/commonFormFunctions';
import { localeLang, dateFormatList } from '../../../utils/config';
import { Button, Form, Radio, List, ConfigProvider, Table, Spin, Input, DatePicker } from 'antd';
import { UilMinus, UilPlus, UilInfoCircle, UilEdit, UilTrash } from '@iconscout/react-unicons';

import moment from 'moment';
import 'moment/locale/tr';

import ExportModal from '../../dynamicModal/exportModal';
import MaskedInput from 'react-text-mask'
import DynamicTable from '../../dynamicTable/index';
import DynamicSelectbox from '../../dynamicSelectbox';
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import Can from "../../../authorization/index";

import '../../../styles/time-off.scss'

function TimeOffRemainderYears(props) {
    const { t } = useTranslation();
    const { totalNumberOfRecords, remainderYearsInitialSearchData, getRemainingTimeOffRequest,
        createExtraValueResponse, exportRemainderYears, exportRemainderYearsReset, remainderYearsExportResponse,
        deleteExtraValue, deleteExtraValueReset, getPersons, createExtraValue, createExtraValueReset,
        getTimeOffDashboardPaiChartData, pieChartData, resetTimeOffDashboardPaiChartData, getExtraValueDetail } = props
    const { gridSettings, remainingTimeOffRequestList, remainingTimeOffRequestLoading, deleteExtraValueResponse,
        persons, profile, extraValueDetail, extraValueDetailLoading, deleteTimeOffAdjustsHistory, deleteTimeOffAdjustsHistoryReset,
        deleteTimeOffAdjustsHistoryResponse, updateTimeOffAdjustsHistory, updateTimeOffAdjustsHistoryReset, updateTimeOffAdjustsHistoryResponse } = props
    const initialGetGridSettingData = {
        pageId: 5, //timeoff 
        gridId: 7, //timeoff request remainder years grid
    }
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData)
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [searchData, setSearchData] = useState(remainderYearsInitialSearchData)
    const [deleteExtraValueModalData, setDeleteExtraValueModalData] = useState({ 'isVisible': false });
    const [deleteExtraValueDetailModalData, setDeleteExtraValueDetailModalData] = useState({ 'isVisible': false });

    const [extraValueModalData, setExtraValueModalData] = useState({ 'isVisible': false, 'modalType': '', 'detailData': [] });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableKey, setTableKey] = useState(7530);
    const [addExtraForm] = Form.useForm();
    const [updateExtraTimeOffForm] = Form.useForm();
    const [personList, setPersonList] = useState([]);
    const [selectExtraValueResourceId, setSelectExtraValueResourceId] = useState(null);
    const [selectExtraValueState, setSelectExtraValueState] = useState({
        'actionDate': '',
        'extraValue': null,
        'actionUser': '',
        'description': '',
        'type': '',
    });
    const [addExtraValueState, setAddExtraValueState] = useState({
        "personId": null,
        "extraValue": null,
        "extraValueTypeId": null,
        "extraValueValidTypeId": null
    })
    const initialExportRemainderYears = {
        "searchTerm": "",
        "persons": [],
        "departments": [],
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "fullName",
            "ascending": true
        },
    };
    const OrganizationResourceId = profile.organization?.resourceId;
    function handleExportDocument(props) {
        initialExportRemainderYears.ExportType = props;
        exportRemainderYears(initialExportRemainderYears)
        setIsModalVisible(false);
    }
    const handleSaveGridSettings = (d) => { }
    const handleResetAllfilters = () => {
        let key = tableKey;
        key = key + 1;
        setTableKey(key);
        setIsShowPassives(false);
        setSearchData(remainderYearsInitialSearchData);
        getRemainingTimeOffRequest(remainderYearsInitialSearchData);
    }
    const handleTableFilter = (data) => {
        setSearchData({ ...searchData, "persons": data.persons, "departments": data.departmentName })
    }
    const columns = [
        {
            title: `${t('PARTNER_NAME_SURNAME')}`,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
            render: obj =>
                <div className="table-center-text">{obj}</div>
        },
        {
            title: `${t('PARTNER_START_DATE_OF_JOB')}`,
            dataIndex: 'workStartDate',
            key: 'workStartDate',
            sorter: true,
        },
        {
            title: `${t('PARTNER_DEPARTMENT')}`,
            dataIndex: 'departmentName',
            key: 'departmentName',
            sorter: true,
        },
        {
            title: `${t('DURATION_OF_ANNUAL_LEAVE')}`,
            dataIndex: 'accrualBalanceYearly',
            key: 'accrualBalanceYearly',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('EXTRA_TIME_OFF')}`,
            dataIndex: 'extraValue',
            key: 'extraValue',
            sorter: true,
            render: obj => {
                return (
                    <div className="imageNameActionColumn">
                        {t('TIME_OFF_REQUEST_DAYS', { "day": obj.count })}
                        <Button className="transparent-btn pad-0 svg-class flex-align-center" onClick={() => openTimeOffDuration('detail', obj.id)} icon={<UilInfoCircle />} />
                        {/* <span style={{ paddingLeft: '15px' }} />
                        {obj.count > 0 &&
                            <Can I="timeoff.remaining.delete">
                                <Button onClick={() => deleteExtraValueOpenModal(obj.id)} className="transparent-btn pad-0 red" >{t('RESET')}</Button>
                            </Can>
                        } */}
                    </div>
                )
            }
        },
        {
            title: `${t('DEFERRED_LEAVE_DURATION')}`,
            dataIndex: 'transferorBalance',
            key: 'transferorBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('TOTAL_DURATION_OF_LEAVE')}`,
            dataIndex: 'sumBalance',
            key: 'sumBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_TIME_LEAVE_USED')}`,
            dataIndex: 'usedBalance',
            key: 'usedBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_REMAINING_TIME')}`,
            dataIndex: 'remainderBalance',
            key: 'remainderBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
    ];
    const data = remainingTimeOffRequestList.map((row) => {
        return (
            {
                "key": row.person.resourceId,
                "fullName": row.person?.displayValue,
                "workStartDate": row.workStartDate ? moment(row.workStartDate).format('LL') : '',
                "departmentName": row.department?.displayValue,
                "accrualBalanceYearly": row.accrualBalanceYearly,
                "extraValue": { 'id': row.person.resourceId, 'count': row.extraValue },
                "transferorBalance": row.transferorBalance,
                "sumBalance": row.sumBalance,
                "usedBalance": row.usedBalance,
                "remainderBalance": row.remainderBalance
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: ['fullName', 'departmentName'],
        sortableColumns: ['fullName', 'workStartDate', 'departmentName', 'accrualBalanceYearly', 'extraValue', 'transferorBalance', 'sumBalance', 'usedBalance', 'remainderBalance'],
        visibilityOptionExcludes: ['fullName', 'workStartDate', 'departmentName', 'accrualBalanceYearly', 'extraValue', 'transferorBalance', 'sumBalance', 'usedBalance', 'remainderBalance'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "timeOffRemainderYears",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    });
    const leftActionMenu = () => {
        return (
            <div className="timeoff-person-table-action-buttons remainder-action">
                {/* <Can I="timeoff.extratime.add">
                    <Button onClick={() => openTimeOffDuration('reset')} className="flex-class svg-class primary-btn m-w-180 h-32 w-auto" icon={<UilHistory />}>{t('RESET_REMAINING_LEAVE_TIME')}</Button>
                </Can> */}
                <Can I="timeoff.extratime.add">
                    <div className=''>
                        <Button onClick={() => openTimeOffDuration('decrease')} className="flex-class svg-class white-btn m-w-180 h-32 w-auto" icon={<UilMinus />}>{t('DECREASE_PERMISSION_PERIOD')}</Button>
                    </div>
                </Can>
                <Can I="timeoff.extratime.add">
                    <div className='remainder-increase-button'>
                        <Button onClick={() => openTimeOffDuration('increase')} className="flex-class svg-class white-btn m-w-180 h-32 w-auto" icon={<UilPlus />}>{t('INCREASE_PERMISSION_PERIOD')}</Button>
                    </div>
                </Can>
                {/* <Can I="timeoff.remaining.list">
                    <div className='remainder-export-button'>
                        <Button onClick={() => setIsModalVisible(true)} icon={<UilExport />} className="flex-class svg-class white-btn m-w-180 h-32 w-auto">{t('SETTINGS_EXPORT')}</Button>
                    </div>
                </Can> */}
            </div >
        )
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = {
            ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize,
            "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : searchData.pagination.orderBy)
        }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }
    useEffect(() => {
        getRemainingTimeOffRequest(searchData)
    }, [searchData]);
    useEffect(() => {
        if (remainderYearsExportResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'person_remiunder_years_fail',
                'title': t('SUCCESS'),
                'description': remainderYearsExportResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            exportRemainderYearsReset();
        }
    }, [remainderYearsExportResponse])
    // Delete Extra Value
    const deleteExtraValueOpenModal = (id) => {
        setDeleteExtraValueModalData({ ...deleteExtraValueModalData, 'id': id, 'isVisible': true })
    }
    const [extraValueTableDataState, setExtraValueTableDataState] = useState([]);

    useMemo(() => {
        if (extraValueDetail?.histories?.length > 0) {
            var tableData = [];
            setExtraValueModalData({ ...extraValueModalData, 'detailData': extraValueDetail?.histories });
            extraValueDetail?.histories.map((row, index) => {
                return tableData.push(
                    {
                        "key": index,
                        "actionDate": row.actionDate,
                        "extraValue": row.extraValue,
                        "actionUser": row.actionUser,
                        "description": row.description,
                        "type": row.extraValueValidTypeIdx,
                        "edit": { 'index': index, 'id': row.resourceId },
                        "delete": row.resourceId
                    }
                )
            });
            setExtraValueTableDataState(tableData);
        } else {
            setExtraValueTableDataState([]);
        }
    }, [extraValueDetail])
    useEffect(() => {
        if (deleteExtraValueResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_EXTRA_VALUE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteExtraValueResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getRemainingTimeOffRequest(searchData)
            deleteExtraValueReset();
        } else if (deleteExtraValueResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_EXTRA_VALUE_FAIL',
                'title': t('ERROR'),
                'description': deleteExtraValueResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteExtraValueReset();
        }
    }, [deleteExtraValueResponse])

    useEffect(() => {
        if (deleteTimeOffAdjustsHistoryResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_EXTRA_VALUE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteTimeOffAdjustsHistoryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getRemainingTimeOffRequest(searchData);
            getExtraValueDetail(selectExtraValueResourceId);
            deleteTimeOffAdjustsHistoryReset();
        } else if (deleteTimeOffAdjustsHistoryResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_EXTRA_VALUE_FAIL',
                'title': t('ERROR'),
                'description': deleteTimeOffAdjustsHistoryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffAdjustsHistoryReset();
        }
    }, [deleteTimeOffAdjustsHistoryResponse])
    useEffect(() => {
        if (updateTimeOffAdjustsHistoryResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'UPDATE_EXTRA_VALUE_SUCCESS',
                'title': t('SUCCESS'),
                'description': updateTimeOffAdjustsHistoryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getRemainingTimeOffRequest(searchData);
            getExtraValueDetail(selectExtraValueResourceId);
            updateTimeOffAdjustsHistoryReset();
            setSelectExtraValueState({
                'actionDate': '',
                'extraValue': null,
                'actionUser': '',
                'description': '',
                'type': '',
            });
            updateExtraTimeOffForm.resetFields();
        } else if (updateTimeOffAdjustsHistoryResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'UPDATE_EXTRA_VALUE_FAIL',
                'title': t('ERROR'),
                'description': updateTimeOffAdjustsHistoryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateTimeOffAdjustsHistoryReset();
        }
    }, [updateTimeOffAdjustsHistoryResponse])

    useEffect(() => {
        if (createExtraValueResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'CREATE_EXTRA_VALUE_SUCCESS',
                'title': t('SUCCESS'),
                'description': createExtraValueResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (pieChartData.length > 0) {
                resetTimeOffDashboardPaiChartData()
            }
            setExtraValueModalData({ 'isVisible': false, 'modalType': '', 'detailData': [] })
            setAddExtraValueState({});
            const fields = addExtraForm.getFieldsValue()
            const projects = { ...fields };
            projects["extraValue"] = "";
            projects["extraValueTypeId"] = "";
            projects["person"] = { id: null, name: null };
            projects["extraValueValidTypeId"] = "";
            addExtraForm.setFieldsValue(projects)
            getRemainingTimeOffRequest(searchData)
            createExtraValueReset()
        } else if (createExtraValueResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'CREATE_EXTRA_VALUE_FAIL',
                'title': t('ERROR'),
                'description': createExtraValueResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createExtraValueReset()
        }
    }, [createExtraValueResponse])

    useEffect(() => {
        setPersonList(persons)
    }, [persons]);

    const openTimeOffDuration = (modalType, resourceId) => {
        var title = "";
        if (modalType === 'increase') {
            title = t('INCREASE_PERMISSION_PERIOD');
        } else if (modalType === 'decrease') {
            title = t('DECREASE_PERMISSION_PERIOD');
        } else if (modalType === 'detail') {
            title = t('EXTRA_TIME_OFF');
            getExtraValueDetail(resourceId);
            setSelectExtraValueResourceId(resourceId);
        } else {
            title = t('RESET_REMAINING_LEAVE_TIME');
        }
        setExtraValueModalData({ ...extraValueModalData, 'isVisible': true, 'modalType': modalType, 'title': title, 'detailData': [] })
    }

    const [ishandleAddExtraValueConfirmLoading, setishandleAddExtraValueConfirmLoading] = useState(false);

    const handleAddExtraValueConfirm = () => {

        if(ishandleAddExtraValueConfirmLoading) return;
        setishandleAddExtraValueConfirmLoading(true);
        setTimeout(() => {setishandleAddExtraValueConfirmLoading(false);}, 1055) // double click prevent;

        let hasError = false
        let fields = []
        if (extraValueModalData.modalType !== 'reset') {

            if (!addExtraValueState.extraValue || addExtraValueState.extraValue === '0') {
                hasError = true
                fields.push(t('ENTER_TIME_OFF_DURATION'))
            }
            if (!addExtraValueState.extraValueTypeId) {
                hasError = true
                fields.push(t('SETTINGS_SELECT_USER'))
            }
            if (addExtraValueState.extraValueTypeId === 1 && !addExtraValueState?.person?.id) {
                hasError = true
                fields.push(t('SETTINGS_SELECT_USER'))
            }
            if (!addExtraValueState.extraValueValidTypeId) {
                hasError = true
                fields.push(t('CHOOSE_TIME'))
            }
        } else {
            if (!addExtraValueState?.person?.id) {
                hasError = true
                fields.push(t('SETTINGS_SELECT_USER'))
            }
            else {
                if (pieChartData[0].remainingValue <= 0) {
                    hasError = true
                    fields.push(t('RESET_TIME_OFF_REQUEST_ERROR_MESSAGE'))
                    DynamicToastMessage({
                        'key': 'warning',
                        'title': t('WARNING'),
                        'description': t('RESET_TIME_OFF_REQUEST_ERROR_MESSAGE'),
                        'statusType': 'warning',
                        'position': 'bottom-right'
                    });
                }
            }
        }
        if (!hasError) {
            var data = {
                "personId": addExtraValueState?.person?.id !== undefined ? addExtraValueState?.person?.id : null,
            };
            if (extraValueModalData.modalType === 'reset') {
                data = {
                    ...data,
                    "extraValue": -pieChartData[0].remainingValue,
                    "extraValueTypeId": 1,
                    "extraValueValidTypeId": 1,
                    "description": addExtraValueState?.description
                }
            } else {
                data = {
                    ...data,
                    "extraValue": extraValueModalData.modalType === 'increase' ? addExtraValueState?.extraValue.replace(',', '.') : -addExtraValueState?.extraValue.replace(',', '.'),
                    "extraValueTypeId": addExtraValueState?.extraValueTypeId,
                    "extraValueValidTypeId": addExtraValueState?.extraValueValidTypeId,
                    "description": addExtraValueState?.description
                }
            }
            createExtraValue(data)
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        }
    }
    const handleAddExtraValueCancel = () => {
        setAddExtraValueState({});
        resetExtraValueDetailForm();
        setExtraValueModalData({ 'isVisible': false, 'modalType': '', 'detailData': [] })
        setSelectExtraValueResourceId(null);
        if (pieChartData.length > 0) {
            resetTimeOffDashboardPaiChartData()
        }
        const fields = addExtraForm.getFieldsValue()
        const projects = { ...fields };
        projects["extraValue"] = "";
        projects["extraValueTypeId"] = "";
        projects["person"] = { id: null, name: null };
        projects["extraValueValidTypeId"] = "";
        projects["description"] = "";
        addExtraForm.setFieldsValue(projects)
    }
    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (extraValueModalData.modalType === 'reset') {
            if (key === 'person') {
                getTimeOffDashboardPaiChartData(changedObj.person.id, 'FH25DW56DYU5')
            }
        }
        setAddExtraValueState({ ...addExtraValueState, ...changedObj });
    }
    const extraValueTableColumn = [
        {
            title: `${t('MODIFIED_DATE')}`,
            dataIndex: 'actionDate',
            key: 'actionDate',
            render: obj =>
                <div className="table-center-text">{moment(obj).format('LL')}</div>
        },
        {
            title: `${t('SETTINGS_TIME')}`,
            dataIndex: 'extraValue',
            key: 'extraValue',
            render: obj => <span>{t("TIME_OFF_REQUEST_DAYS", { "day": obj })}</span>
        },
        {
            title: `${t('TIME_OFF_REQUEST_NAME_SURNAME')}`,
            dataIndex: 'actionUser',
            key: 'actionUser',
            render: obj => <span className={!obj ? 'pl-8' : ''}>{obj ? obj : '-'}</span>
        },
        {
            title: `${t('PARTNER_DESCRIPTION')}`,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: `${t('TYPE')}`,
            dataIndex: 'type',
            key: 'type',
            render: obj => <span>{getTypeName(obj)}</span>

        },
        {
            title: ``,
            dataIndex: 'delete',
            key: 'delete',
            render: data => <Button className="transparent-btn pad-0 svg-class flex-align-center" onClick={() => deleteExtraValueInTable(data)} icon={<UilTrash />} />
        },
        {
            title: ``,
            dataIndex: 'edit',
            key: 'edit',
            render: obj => <Button className="transparent-btn pad-0 svg-class flex-align-center" onClick={() => selectExtraValue(obj)} icon={<UilEdit />} />
        },
    ];
    const getTypeName = (typeId) => {
        switch (typeId) {
            case 0:
                return <span className='pl-3'>-</span>;
            case 1:
                return t('ONLY_FOR_THIS_YEAR');
            case 2:
                return t('CARRY_OVER_TO_THE_NEXT_YEAR');
            default:
                return <span className='pl-3'>-</span>;
        }
    }
    const resetExtraValueDetailForm = () => {
        updateExtraTimeOffForm.resetFields();
        setSelectExtraValueState({
            'actionDate': '',
            'extraValue': null,
            'actionUser': '',
            'description': '',
            'type': '',
        });
    }
    const deleteExtraValueInTable = (id) => {
        setDeleteExtraValueDetailModalData({ ...deleteExtraValueDetailModalData, 'id': id, 'isVisible': true });
    }
    const selectExtraValue = (obj) => {
        const index = obj.index;
        const id = obj.id
        const result = extraValueTableDataState.filter(filterData => filterData.key === index)[0];
        if (selectExtraValueState.actionDate) {
            resetExtraValueDetailForm();
        }
        const extraValue = result.extraValue;
        const formattedExtraValue = extraValue.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        setSelectExtraValueState({
            'actionDate': result.actionDate,
            'extraValue': processData(formattedExtraValue),
            'actionUser': result.actionUser,
            'description': result.description,
            'type': result.type,
            'resourceId': id
        });
        updateExtraTimeOffForm.setFieldsValue({
            'actionDate': moment(result.actionDate),
            'extraValue': processData(formattedExtraValue),
            'actionUser': result.actionUser,
            'description': result.description,
            'type': result.type,
        });
    }
    const cancelTimeOffYears = () => {
        updateExtraTimeOffForm.resetFields();
        setSelectExtraValueState({
            'actionDate': '',
            'extraValue': null,
            'actionUser': '',
            'description': '',
            'type': '',
        });
    }
    const updateTimeOffYears = () => {
        const extraValue = selectExtraValueState?.extraValue;
        const num = parseFloat(extraValue.replace(',', '.'));
        const updateData = {
            Description: selectExtraValueState?.description,
            ExtraValue: isNaN(num) ? 0 : num,
            ExtraValueValidTypeIdx: selectExtraValueState.type
        }
        updateTimeOffAdjustsHistory(updateData, selectExtraValueState.resourceId);
    }
    const handleUpdateExtraValuesChange = (changedObj) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'extraValue') {
            if (changedObj.extraValue !== "") {
                const extraValue = changedObj.extraValue !== "" ? changedObj.extraValue : "0";
                const parsedExtraValue = parseFloat(extraValue.replace(',', '.')); // Replace comma with period to parse correctly
                if (!isNaN(parsedExtraValue)) {
                    const roundedExtraValue = Math.floor(parsedExtraValue * 2) / 2; // Use Math.floor() to always round down
                    const formattedExtraValue = roundedExtraValue.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }); // Use US locale for period decimal separator
                    setSelectExtraValueState({ ...selectExtraValueState, extraValue: formattedExtraValue });

                } else {
                    setSelectExtraValueState({ ...selectExtraValueState, extraValue: changedObj.extraValue })
                }
            } else {
                setSelectExtraValueState({ ...selectExtraValueState, extraValue: changedObj.extraValue })
            }
        } else {
            setSelectExtraValueState({ ...selectExtraValueState, ...changedObj });
        }
    }
    const handleUpdateExtraValuesInputChange = (form, state) => {
        const extraValue = state.extraValue !== "" ? state.extraValue : "0";
        if (extraValue !== "" && extraValue) {
            const parsedExtraValue = parseFloat(extraValue.replace(',', '.'));
            if (!isNaN(parsedExtraValue)) {
                const fields = form.getFieldsValue();
                const projects = { ...fields };
                projects["extraValue"] = formatValue(parsedExtraValue);
                form.setFieldsValue(projects);
            }
        }
    }
    function processData(data) {
        if (data && data.match(/^[-]?\d+,0$/)) {
            return data.split(",")[0];
        } else if (data && data.match(/^[-]?\d+,[+-]?\d+$/)) {
            return data;
        } else if (data && data.match(/^[-]?\d+$/)) {
            return data;
        } else {
            return null;
        }
    }
    function formatValue(value) {
        const sign = value >= 0 ? 1 : -1;
        const absValue = Math.abs(value);
        const roundedValue = Math.floor(absValue);
        const decimal = absValue - roundedValue;
        let formattedValue;
        if (decimal <= 0.1) {
            formattedValue = roundedValue;
        } else if (decimal >= 0.6) {
            formattedValue = roundedValue + 1;
        } else {
            formattedValue = roundedValue + 0.5;
        }
        return (sign * formattedValue).toString().replace('.', ',');
    }
    const extraValueForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                {extraValueModalData.modalType !== 'detail' ?
                    <Form
                        form={addExtraForm}
                        onValuesChange={handleValuesChange}
                        layout="vertical"
                    >
                        <div className="time-off-extra-value-modal">
                            {extraValueModalData.modalType === 'reset' ?
                                <>
                                    <Form.Item className="custom-select text-left" name="person" label={t('SETTINGS_SELECT_USER') + " *"}  >
                                        <DynamicSelectbox
                                            optionList={personList}
                                            onFocus={() => getPersons(OrganizationResourceId)}
                                            placeholder={t('PARTNER_SELECT')}
                                        />
                                    </Form.Item>
                                    {(pieChartData.length > 0) &&
                                        <div className='text-left'>
                                            <p className='f-16 black fw-600'>{t('TIME_OFF_REQUEST_REMAINING_TIME')} : <span className='f-w-400'>{t('TIME_OFF_REQUEST_DAYS', { "day": pieChartData[0].remainingValue })}</span></p>
                                        </div>
                                    }
                                    <Form.Item name="description" label={t('PARTNER_DESCRIPTION')}  >
                                        <Input.TextArea />
                                    </Form.Item>
                                </>
                                :
                                <>
                                    <Form.Item className="custom-select text-left" name="extraValue" label={t('ENTER_TIME_OFF_DURATION') + " *"}  >
                                        <MaskedInput className="ant-input" mask={addExtraValue} onBlur={() => handleUpdateExtraValuesInputChange(addExtraForm, addExtraValueState)} />
                                    </Form.Item>
                                    <Form.Item className="custom-select text-left pad-0 mar-0" name="extraValueTypeId" label={t('SETTINGS_SELECT_USER') + " *"}  >
                                        <Radio.Group options={
                                            [
                                                { label: `${t('APPLY_TO_ALL_USERS')}`, value: 2 },
                                                { label: `${t('SPECIFIC_USER')}`, value: 1 },
                                            ]
                                        } />
                                    </Form.Item>
                                    {addExtraValueState?.extraValueTypeId === 1 &&
                                        <Form.Item className="custom-select text-left" name="person" >
                                            <DynamicSelectbox
                                                optionList={personList}
                                                onFocus={() => getPersons(OrganizationResourceId)}
                                                placeholder={t('PARTNER_SELECT')}
                                            />
                                        </Form.Item>}
                                    <Form.Item className="custom-select text-left pad-0 mar-0" name="extraValueValidTypeId" label={t('CHOOSE_TIME') + " *"}  >
                                        <Radio.Group options={
                                            [
                                                { label: `${t('ONLY_FOR_THIS_YEAR')}`, value: 1 },
                                                { label: `${t('CARRY_OVER_TO_THE_NEXT_YEAR')}`, value: 2 },
                                            ]
                                        } />
                                    </Form.Item>
                                    <Form.Item name="description" label={t('PARTNER_DESCRIPTION')}  >
                                        <Input.TextArea />
                                    </Form.Item>
                                </>
                            }
                        </div>
                    </Form>
                    :
                    extraValueDetailLoading ?
                        <Spin className="tab-loading w-100" /> :
                        extraValueTableDataState.length > 0 ?
                            <>
                                {selectExtraValueState?.actionDate &&

                                    <div className='time-off-years-edit-modal'>
                                        <div className="filter-container table-details">
                                            <Form
                                                form={updateExtraTimeOffForm}
                                                onValuesChange={handleUpdateExtraValuesChange}
                                                requiredMark={true}
                                                layout="vertical"
                                            >
                                                <Form.Item name="actionDate" label={t('MODIFIED_DATE')} >
                                                    <DatePicker
                                                        disabled
                                                        locale={localeLang}
                                                        placeholder=''
                                                        format={dateFormatList}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="extraValue" label={t('SETTINGS_TIME')} >
                                                    <MaskedInput mask={signedNumberMask} guide={false} placeholderChar={"\u2000"} className="ant-input" onBlur={() => handleUpdateExtraValuesInputChange(updateExtraTimeOffForm, selectExtraValueState)} disabled={!selectExtraValueState.actionDate} />
                                                </Form.Item>
                                                <Form.Item name="actionUser" label={t('TIME_OFF_REQUEST_NAME_SURNAME')} >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item className="custom-select text-left pad-0 mar-0" name="type" label={t('CHOOSE_TIME')}  >
                                                    <Radio.Group
                                                        options={
                                                            [
                                                                { label: `${t('ONLY_FOR_THIS_YEAR')}`, value: 1 },
                                                                { label: `${t('CARRY_OVER_TO_THE_NEXT_YEAR')}`, value: 2 },
                                                            ]
                                                        } />
                                                </Form.Item>
                                                <Form.Item name="description" label={t('PARTNER_DESCRIPTION')}  >
                                                    <Input.TextArea disabled={!selectExtraValueState.actionDate} />
                                                </Form.Item>

                                            </Form>
                                            {selectExtraValueState.actionDate &&

                                                <div className='d-flex flex-end align-items-center w-100'>
                                                    <Button onClick={cancelTimeOffYears} className='w-180 h-40 f-14 font-w-700 white-btn mr-2'>{t('PARTNER_GIVE_UP')}</Button>
                                                    <Button onClick={updateTimeOffYears} className='w-180 h-40 f-14 font-w-700 primary-btn'>{t('PARTNER_SAVE')}</Button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="table-container w-100">
                                    <Table className={extraValueTableDataState.length === 0 ? 'table-border-bottom-0' : ''}
                                        loading={extraValueDetailLoading}
                                        bordered={true}
                                        columns={extraValueTableColumn}
                                        dataSource={extraValueTableDataState}
                                        pagination={false}
                                    />
                                </div>
                            </>
                            :
                            <label className="f-16 grey-color w-100 align-center" >{t('NO_HISTORICAL_INFORMATION_AVAILABLE')}</label>
                }
            </ConfigProvider>
        </div >
    )
    return (
        <div className="table-container">
            <DynamicRequestModal
                modalVisible={deleteExtraValueModalData?.isVisible}
                setStateData={setDeleteExtraValueModalData}
                postAction={deleteExtraValue}
                confirmData={deleteExtraValueModalData?.id}
                iconName='UilTrash'
                modalTitle={t('SETTINGS_DELETE')}
                description={t('DELETE_THE_TIME_OFF_DEFINED_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <DynamicRequestModal
                modalVisible={deleteExtraValueDetailModalData?.isVisible}
                setStateData={setDeleteExtraValueDetailModalData}
                postAction={deleteTimeOffAdjustsHistory}
                confirmData={deleteExtraValueDetailModalData?.id}
                iconName='UilTrash'
                modalTitle={t('SETTINGS_DELETE')}
                description={t('DELETE_THE_TIME_OFF_DEFINED_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <DynamicRequestModal
                key={extraValueModalData.title}
                modalVisible={extraValueModalData?.isVisible}
                setStateData={setExtraValueModalData}
                handleConfirm={handleAddExtraValueConfirm}
                handleCancel={handleAddExtraValueCancel}
                confirmData={extraValueModalData?.id}
                isForm={extraValueForm}
                modalWidth={extraValueModalData.modalType === 'detail' ? 1200 : 520}
                iconName=''
                modalTitle={extraValueModalData.title}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                hideButton={extraValueModalData.modalType === 'detail'}
                successBtnTxt={extraValueModalData.modalType === 'reset' ? t('RESET') : t('PARTNER_SAVE')}
            />
            <ExportModal
                onCancel={() => { setIsModalVisible(false) }}
                status={isModalVisible}
                handleExportDocument={handleExportDocument}
            />
            <DynamicTable
                key={tableKey}
                columns={columns} dataSource={data}
                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
                searchPlaceholder=""
                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                searchBox={false}
                checkboxStatus={false}
                passiveButton={false}
                loadStatus={remainingTimeOffRequestLoading}
            //initialFilterData={remainderYearsInitialSearchData}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        pieChartData: state.dashboard.timeOffPaiChartData,
        gridSettings: state.setting.gridSettings,
        remainingTimeOffRequestList: state.timeOff.remainingTimeOffRequestList?.results,
        remainingTimeOffRequestLoading: state.timeOff.remainingTimeOffRequestLoading,
        totalNumberOfRecords: state.timeOff.remainingTimeOffRequestList.totalNumberOfRecords,
        deleteExtraValueResponse: state.timeOff.deleteExtraValueResponse,
        createExtraValueResponse: state.timeOff.createExtraValueResponse,
        persons: state.organization.persons,
        profile: state.profile,
        remainderYearsExportResponse: state.timeOff.remainderYearsExportResponse,
        extraValueDetail: state.timeOff.extraValueDetail,
        extraValueDetailLoading: state.timeOff.extraValueDetailLoading,
        deleteTimeOffAdjustsHistoryResponse: state.timeOff.deleteTimeOffAdjustsHistoryResponse,
        updateTimeOffAdjustsHistoryResponse: state.timeOff.updateTimeOffAdjustsHistoryResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getRemainingTimeOffRequest: (searchData) => { dispatch(getRemainingTimeOffRequest(searchData)) },
        deleteExtraValue: (resourceId) => { dispatch(deleteExtraValue(resourceId)) },
        deleteExtraValueReset: () => { dispatch(deleteExtraValueReset()) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        createExtraValue: (createData) => { dispatch(createExtraValue(createData)) },
        createExtraValueReset: () => { dispatch(createExtraValueReset()) },
        getTimeOffDashboardPaiChartData: (resourceId, type) => { dispatch(getTimeOffDashboardPaiChartData(resourceId, type)) },
        resetTimeOffDashboardPaiChartData: () => { dispatch(resetTimeOffDashboardPaiChartData()) },
        exportRemainderYears: (initialData) => { dispatch(exportRemainderYears(initialData)) },
        exportRemainderYearsReset: () => { dispatch(exportRemainderYearsReset()) },
        getExtraValueDetail: (resourceId) => { dispatch(getExtraValueDetail(resourceId)) },
        deleteTimeOffAdjustsHistory: (resourceId) => { dispatch(deleteTimeOffAdjustsHistory(resourceId)) },
        deleteTimeOffAdjustsHistoryReset: () => { dispatch(deleteTimeOffAdjustsHistoryReset()) },
        updateTimeOffAdjustsHistory: (data, resourceId) => { dispatch(updateTimeOffAdjustsHistory(data, resourceId)) },
        updateTimeOffAdjustsHistoryReset: () => { dispatch(updateTimeOffAdjustsHistoryReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffRemainderYears);