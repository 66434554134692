import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Modal, Button, Popover } from 'antd';
import PopoverContent from './PopoverContent';
import OnboardingMapModal from './OnboardingMapModal';
import { useTranslation } from 'react-i18next';

const OnboardingHeader = ({currentTaskState,setCurrentTaskState,nextTaskID,nextTaskPackage,userTaskInfo,width,
  permissions, pathname, percentage, user, popoverVisible, handlePopoverVisibleChange, customSetCurrentTaskState, hide}) => {

const { t } = useTranslation();

const progressStyle = {'--progress-percentage': `${percentage}%`};

  return (
    <div className="d-flex">
      <Link to="/homepage" className="logo-url">
        <div className="logo d-block">
          <Image src={process.env.REACT_APP_URL + "images/hrplan-logo.svg"} preview={false} />
        </div>
      </Link>
      
      <Modal
        key={'welcome'}
        visible={currentTaskState === 0}
        footer={null}
        onCancel={() => setCurrentTaskState(1)}
      >
        <div className="onboarding-welcome-main">
          <Image className="img" src="../images/rocket.svg" preview={false} />
          <div className="rocket-box-wrapper">
            <div className="rocket-box rocket-box-arrow secondary-dark-text">
                {t('ONBOARDING_WELCOME_MESSAGE')}
            </div>
          </div>
        </div>
        <div className="d-flex w-100">
          <Button
            className="white-btn w-178 h-32 f-14"
            onClick={() => setCurrentTaskState(1)}
          >
            {t('ONBOARDING_SKIP')}
          </Button>
          <Button
            className="primary-btn w-100 h-32 f-14 ml-4"
            onClick={() => setCurrentTaskState(1000)}
          >
            {t('ONBOARDING_CONTINUE')}
          </Button>
        </div>
      </Modal>

      <Modal
        key={'first-mission'}
        visible={currentTaskState === 10}
        footer={null}
        onCancel={() => setCurrentTaskState(null)}
      >
        <div className="onboarding-first-mission-wrapper">
          <div className="mission-title">{t('ONBOARDING_FIRST_MISSION_TITLE', { name: user?.profile?.givenName })}</div>
          <Image className="img" src="./images/onboarding-first-mission.png" preview={false} />
          <div className="mission-description">{t('ONBOARDING_FIRST_MISSION_DESCRIPTION')}</div>
          <div className="space-between w-100">
            <Button
              className="white-btn h-32 f-14"
              onClick={() => setCurrentTaskState(null)}
            >
              {t('ONBOARDING_LATER')}
            </Button>
            <Button
              className="primary-btn h-32 f-14 ml-4"
              onClick={() => setCurrentTaskState(11)}
            >
              {t('ONBOARDING_CONTINUE')}
            </Button>
          </div>
        </div>
      </Modal>

      {/* Onboarding Task Completed Map + Header HR-Rocket */}
      <OnboardingMapModal
        nextTaskID={nextTaskID}
        nextTaskPackage={nextTaskPackage}
        completedTasks={userTaskInfo.completedTasks}
        currentTaskState={currentTaskState}
        setCurrentTaskState={setCurrentTaskState}
      />
      {(userTaskInfo.initialized && width >= 992 && permissions?.includes('access_authorization')
        && permissions.includes('onboarding') && pathname !== '/prices' && !pathname.includes('/payment') && !pathname.includes('/newPackagePayment')) && 
        <Popover
          key={'header'}
          overlayClassName={(currentTaskState == 1005 || currentTaskState == 1004 || currentTaskState == 1003 || currentTaskState == 999 || currentTaskState == 1 || currentTaskState == null) ? currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper" : 'onboarding-popover-hidden' }
          content={
            <PopoverContent
              percentage={percentage}
              type={currentTaskState}
              nextTaskID={nextTaskID}
              isVisible={popoverVisible}
              nextTaskPackage={nextTaskPackage}
              navSubsciption={parseInt(user?.profile?.organizationPackageId) > 0 && permissions.includes('payment')}
              onClose={hide}
              OnchangeTaskState={(state, showTaskList) => customSetCurrentTaskState(state, showTaskList)}
            />
          }
          title={false}
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={handlePopoverVisibleChange}
        >
          <div className="d-flex align-items-center margin-left-18 f-14 cursor-pointer">
            <div className="progress-border" style={progressStyle}>
              <Image className="img" src="../images/rocket.svg" preview={false} />
            </div>
            <div className="d-flex flex-column logo-text lh-normal">
              <div className="secondary-dark-text">{t('ONBOARDING_HELLO')} </div>
              <div className="primary-green-font-color">
                {nextTaskPackage === 'done' ? t('ONBOARDING_ALL_TASKS_COMPLETED') : t('ONBOARDING_PENDING_TASKS')}
              </div>
            </div>
          </div>
        </Popover>
      }
    </div>
  );
};

export default OnboardingHeader;