import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import { Tabs, Col, Row, Button, Steps, List, Checkbox, Alert } from 'antd';
import { UilMultiply, UilArrowLeft } from '@iconscout/react-unicons'
import moment from 'moment';

import { createPerson, createPersonResponseReset, getRoles, search } from "../../../redux/actions/person.actions";
import { getAssignedTasksStepList } from "../../../redux/actions/task.actions";
import { setPersonAddOpenDraw } from '../../../redux/actions/person-detail-tab-action'; // Drawer Statusler
import { getPersonOrgChart, resetPersonWorkHours, getPersonWorkHours } from '../../../redux/actions/organization.actions';
import { resetScrollPosition, regex } from "../../../utils/commonFormFunctions";
import { dateFormat } from '../../../utils/config';

import AddUserBasicInformation from './add-user-basic-information';
import Authorization from "./add-authorization";
import AddUserDetailsJobInformation from './add-user-job-information';
import AddPersonInformation from './add-user-personel-information';
import AddPersonContact from './add-user-contact';
import AddUserDocument from './add-user-document';
import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import Can from '../../../authorization/index';
import Cannot from '../../../authorization/cannot';
import Forbidden from '../../forbidden/index';
import Successful from "../../successful";

import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import { sendGtmEvent } from '../../../utils/gtmFunction';

import { setCurrentTaskState, setUserTaskInfo } from "../../../redux/actions/onboarding.actions";

function AddUserTabs(props) {
    const [currentTab, setCurrentTab] = useState(1);
    const [subCurrentTab, setSubCurrentTab] = useState(1);
    const [createPersonObject, setCreatePersonObject] = useState({
       // "ExplicitConsentText": false,
       // "KVKK": false,
       // "UserAggrement": false
    });

    

    const { Step } = Steps;
    const { t } = useTranslation();
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const { TabPane } = Tabs;
    const { setPersonAddOpenDraw, createPerson, createPersonResponse, createPersonResponseReset, getPersonWorkHours,
        getPersonOrgChart, search, profile, searchData, returnShow, getRoles, resetPersonWorkHours, openCreateTaskDrawer,
        addUserPersonId, setAddUserPersonId, getAssignedTasksStepList, auth } = props;
    const organizationResourceId = profile.organization?.resourceId;
    const [focusOnEmail, setFocusOnEmail] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })

    const [addPersonResponseMessage, setAddPersonResponseMessage] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const [showPersonLimitWarning , setShowPersonLimitWarning] = useState(false)


    function callback(key) {
        checkRequiredFields(() => setCurrentTab(parseInt(key)));
        resetScrollPosition('.add-draw-scrollable-tab > .ant-tabs-content-holder');
        if (key === 2) {
            getRoles();
        }
        if (key !== 1) {
            setFocusOnEmail(false);
        }
    }
    function callback2(key) {
        setSubCurrentTab(key);
        resetScrollPosition('.add-draw-scrollable-tab > .ant-tabs-content-holder');
    }
    function prevButton() {
        if (currentTab > 1) {
            if (currentTab === 4 && subCurrentTab > 1) {
                setSubCurrentTab(subCurrentTab - 1)
            } else {
                setCurrentTab(currentTab - 1);
            }
        }
        resetScrollPosition('.add-draw-scrollable-tab > .ant-tabs-content-holder');
    }
    function nextButton() {
        if (currentTab === 4 && subCurrentTab < 3) {
            setSubCurrentTab(subCurrentTab + 1);
        } else {
            checkRequiredFields(() => setCurrentTab(currentTab + 1))
        }
        resetScrollPosition('.add-draw-scrollable-tab > .ant-tabs-content-holder');
    }
    function closeDrawerControl() {
        if (currentTab !== 5 && (createPersonObject?.givenName || createPersonObject?.familyName || createPersonObject?.DepartmentResourceId || createPersonObject?.hireDate || createPersonObject?.jobName || createPersonObject?.officePhone || createPersonObject?.workPhoneExt || createPersonObject?.identityNumber)) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal()
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setSubCurrentTab(1);
        setPersonAddOpenDraw(false);
        setCreatePersonObject({});
        createPersonResponseReset();
        resetPersonWorkHours();
    }
    function createNewOne() {
        setCurrentTab(1);
        setSubCurrentTab(1);
        createPersonResponseReset();
        setCreatePersonObject({});
        resetPersonWorkHours();
        getPersonWorkHours();
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createPersonObject, obj)
        const createTempObj = JSON.parse(JSON.stringify(mergedObj))
        if (!createTempObj.hasOwnProperty('Roles') || createTempObj.Roles?.length === 0) {
            createTempObj['Roles'] = null
        }
        const careerStartDate = createTempObj.careerStartDate ?
            localStorage.getItem('i18nLang') === 'tr-TR' ?
                createTempObj.careerStartDate.split('/').reverse().join('-')
                :
                moment(createTempObj.careerStartDate).format(dateFormat)
            : null
        const birthday = createTempObj.birthday ?
            localStorage.getItem('i18nLang') === 'tr-TR' ?
                createTempObj.birthday.split('/').reverse().join('-')
                :
                moment(createTempObj.birthday).format(dateFormat)
            : null
        createTempObj.careerStartDate = careerStartDate;
        createTempObj.birthday = birthday;

        const createObj = { ...createTempObj, OrganizationResourceId: organizationResourceId }
        setCreatePersonObject(createObj)
    }
    if (createPersonObject.hireDate === "Invalid date") {
        setCreatePersonObject({ ...createPersonObject, 'hireDate': null })
    }
    if (createPersonObject.trialPeriodDate === "Invalid date") {
        setCreatePersonObject({ ...createPersonObject, 'trialPeriodDate': null })
    }
    if (createPersonObject.startDate === "Invalid date") {
        setCreatePersonObject({ ...createPersonObject, 'startDate': null })
    }
    if (createPersonObject.remoteWorkFinishDate === "Invalid date") {
        setCreatePersonObject({ ...createPersonObject, 'remoteWorkFinishDate': null })
    }
    const checkRequiredFields = (nextFunc, isCreate) => {
        let hasError = false
        let fields = []
        if (!createPersonObject.givenName || createPersonObject.givenName === "") {
            hasError = true
            fields.push(t('PARTNER_GIVENNAME'))
        }
        if (!createPersonObject.familyName || createPersonObject.familyName === "") {
            hasError = true
            fields.push(t('PARTNER_FAMILYNAME'))
        }
        if (!createPersonObject.DepartmentResourceId || createPersonObject.DepartmentResourceId === "") {
            hasError = true
            fields.push(t('PARTNER_DEPARTMENT'))
        }
        if ((!createPersonObject.hireDate || createPersonObject.hireDate === "Invalid date")) {
            hasError = true
            fields.push(t('PARTNER_START_DATE_OF_COMPANY'))
        }
        if (isCreate && createPersonObject.Roles && !createPersonObject.corporateEmail) {
            hasError = true
            fields.push('( Yetkilendirme için İş E-Posta Adresi girmelisiniz! )')
        }
        if ((createPersonObject.corporateEmail && regex.test(createPersonObject.corporateEmail) === false)) {
            hasError = true
            fields.push('( ' + t('PARTNER_CORPORATE_MAIL') + ' ) ' + t('PLEASE_ENTER_VALID_MAIL_ADDRESS'))
        }
        if ((createPersonObject.personalEmail && regex.test(createPersonObject.personalEmail) === false)) {
            hasError = true
            fields.push('( ' + t('PARTNER_PERSONEL_MAIL') + ' ) ' + t('PLEASE_ENTER_VALID_MAIL_ADDRESS'))
        }
        if (createPersonObject?.job?.WorkStatusId === "2" && (!createPersonObject?.job.ContractEndDate || createPersonObject?.job.ContractEndDate === "Invalid date")) {
            hasError = true
            fields.push('( ' + t('CONTRACT_END_DATE') + ' )' + t('CAN_NOT_LEFT_BLANK'))
        }

        if (createPersonObject.birthday) {
            const today = moment(moment());
            const startDate = moment(createPersonObject.birthday).format(dateFormat);
            const result = today.diff(startDate, 'years');
            const resultSecond = today.diff(startDate, 'second');
            if (result < 16 || resultSecond < 1 || isNaN(result)) {
                hasError = true;
                fields.push('( ' + t('PARTNER_BIRTHDAY') + ' ) ' + t('INVALID_DATE'));
            }
        }
        if (createPersonObject.careerStartDate) {
            const today = moment(moment())
            const inputDate = moment(createPersonObject.careerStartDate).format(dateFormat);
            const result = today.diff(inputDate, 'days');
            const resultSecond = today.diff(inputDate, 'second');
            if (result < 0 || resultSecond < 1 || isNaN(result)) {
                hasError = true;
                fields.push('( ' + t('PARTNER_CAREER_START_DATE') + ' ) ' + t('INVALID_DATE'));
            }
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleCreatePerson = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        let fields = [];
        let hasError = false
        // if (!createPersonObject?.KVKK || createPersonObject.KVKK === false) {
        //     fields.push('Üye Kişisel Veri Aydınlatma Metni');
        //     hasError = true;
        // }
        // if (!createPersonObject?.ExplicitConsentText || createPersonObject.ExplicitConsentText === false) {
        //     fields.push('Açık Rıza Metni');
        //     hasError = true;
        // }
        // if (!createPersonObject?.UserAggrement || createPersonObject.UserAggrement === false) {
        //     fields.push('Üyelik Sözleşmesi');
        //     hasError = true;
        // }
        if (hasError) {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('WARNING'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        } else {
            checkRequiredFields(() =>
                createPerson(createPersonObject), true);

                window.sendinblue?.track(
                    //brevo_event_create_person
                    'create_person',
                )
        }
    }
    const goToCorporateEmail = () => {
        setCurrentTab(1);
        setFocusOnEmail(true)
    }
    const goToCreateTaskDrawer = () => {
        getAssignedTasksStepList(addUserPersonId);
        openCreateTaskDrawer();
        closeAddModal();
    }
    useEffect(() => {
        setAddPersonResponseMessage('')
        if (createPersonResponse.status === 'SUCCESS') {
            setAddPersonResponseMessage(createPersonResponse.message);
            setCurrentTab(5);
            search(searchData);
            getPersonOrgChart(organizationResourceId);
            setAddUserPersonId(createPersonResponse.resourceId);
            sendGtmEvent("person_create_success");

            if(props.currentTaskState == 22 && !props.userTaskInfo.completedTasks.includes(2)){
                closeAddModal();
                setTimeout(() => { props.setCurrentTaskState(23);
                }, 200);
            }
            if(props.currentTaskState !== 22 && !props.userTaskInfo.completedTasks.includes(2)){                    
                closeAddModal();
                setTimeout(() => { props.setCurrentTaskState(1005); }, 200);
            }
        } else if (createPersonResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'person_create_fail_notification',
                'title': t('ERROR'),
                'description': createPersonResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createPersonResponseReset()
        } else if (createPersonResponse.message.status === 'FAILED') {
            DynamicToastMessage({
                'key': 'person_create_failed_notification',
                'title': t('ERROR'),
                'description': createPersonResponse.message.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createPersonResponseReset()
        }
        else if (createPersonResponse.message.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'person_create_validation_notification',
                'title': t('ERROR'),
                'description': <List>{createPersonResponse.message.validationMessages.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createPersonResponseReset()
        }
    }, [createPersonResponse])
    const termsOnChange = (e, type) => {
        const val = e.target.checked;
        setCreatePersonObject({ ...createPersonObject, [type]: val })
    }
    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="add-user-close-icon">
                <UilMultiply onClick={() => closeDrawerControl()} />
            </div>
            {currentTab !== 5 ?
                <div className="table-details">
                    {!returnShow ?
                        <div className="add-user-draw-header">
                            <div className="add-user-draw-title pad-right-200">
                                <h1>{t('PERSON_CREATE_NEW')}</h1>
                            </div>
                        </div> :
                        <div className="add-user-draw-header">
                            <div onClick={() => closeDrawerControl()} className="add-user-draw-back-text">
                                <UilArrowLeft /><span>{t('PERSON_RETURN_PEOPLE_LIST')}</span>
                            </div>
                            <div className="add-user-draw-title pad-right-360">
                                <h1>{t('PERSON_CREATE_NEW')}</h1>
                            </div>
                        </div>
                    }
                    {showPersonLimitWarning && (<Alert className="error-alert-box" message={t("ADD_PERSON_LIMIT_WARNING")} type="error" showIcon />)}
                    <div className="w-100">
                        <Row>
                            <Col span={6}>
                                <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                    <Step title={t('PARTNER_BASIC_INFORMATION')} description="" />
                                    <Step title={t('PARTNER_AUTH2')} description="" />
                                    <Step title={t('PARTNER_DOCUMENTS')} description="" />
                                    <Step
                                        title={t('PARTNER_DETAILS_INFORMATION')}
                                        description={
                                            currentTab === 4 &&
                                            <ul className="sub-tab">
                                                <li className={subCurrentTab === 1 ? "sub-tab-active" : ''}>{t('PARTNER_JOB_INFORMATION')}</li>
                                                <li className={subCurrentTab === 2 ? "sub-tab-active" : ''}>{t('PARTNER_PERSONAL_INFORMATION')}</li>
                                                <li className={subCurrentTab === 3 ? "sub-tab-active" : ''}>{t('PARTNER_CONTACT')}</li>
                                            </ul>

                                        }
                                    />
                                    {/* 
                                    <Step title={t('PARTNER_LICENSES')} description="" />
                                    <Step title={t('PARTNER_GROUP_MEMBERSHIP')} description="" />
                                    */}
                                    <Step title={t('PARTNER_COMPLETE')} description="" />
                                </Steps>
                            </Col>
                            <Col span={17}>


                                <Tabs className="custom-tab add-draw-scrollable-tab"
                                    onChange={callback}
                                    activeKey={currentTab.toString()}
                                    defaultActiveKey={currentTab.toString()}
                                >
                                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key={1}>
                                        <AddUserBasicInformation focusOnEmail={focusOnEmail} setCreateObject={(o) => setCreateObject(o)} createPersonObject={createPersonObject} />
                                    </TabPane>
                                    <TabPane tab={t('PARTNER_AUTH2')} key={2}>
                                        {/* <Comingsoon size="sm" /> */}
                                        <Can I="person.role.list">
                                            <Authorization
                                                corporateEmail={createPersonObject.corporateEmail}
                                                goToCorporateEmail={() => goToCorporateEmail()}
                                                setCreateObject={(o) => setCreateObject(o)}
                                            />
                                        </Can>
                                        <Cannot I="person.role.list">
                                            <Forbidden size="sm"></Forbidden>
                                        </Cannot>
                                    </TabPane>
                                    <TabPane tab={t('PARTNER_DOCUMENTS')} key={3}>
                                        <AddUserDocument setCreateObject={(o) => setCreateObject(o)} />
                                        {/* <Comingsoon size="sm" /> */}
                                    </TabPane>

                                    <TabPane tab={t('PARTNER_DETAILS_INFORMATION')} key={4}>
                                        <Tabs id="scrltab" className="hide-tab "
                                            onChange={callback2}
                                            activeKey={subCurrentTab.toString()}
                                            defaultActiveKey={subCurrentTab.toString()}
                                        >
                                            <TabPane tab={t('PARTNER_JOB_INFORMATION')} key={1}>
                                                <AddUserDetailsJobInformation setCreateObject={(o) => setCreateObject(o)} />
                                            </TabPane>

                                            <TabPane tab={t('PARTNER_PERSONAL_INFORMATION')} key={2}>
                                                <AddPersonInformation setCreateObject={(o) => setCreateObject(o)} />
                                            </TabPane>
                                            <TabPane tab={t('PARTNER_CONTACT')} key={3}>
                                                <AddPersonContact setCreateObject={(o) => setCreateObject(o)} />
                                            </TabPane>
                                        </Tabs>
                                    </TabPane>
                                    {/* <TabPane tab={t('PARTNER_LICENSES')} key={3}>
                                        <Comingsoon size="sm" />
                                    </TabPane> */}
                                    {/* <TabPane tab={t('PARTNER_GROUP_MEMBERSHIP')} key={5}>
                                        <Comingsoon size="sm" />
                                    </TabPane> */}

                                </Tabs>

                            </Col>
                        </Row>

                    </div>
                </div>
                :
                <Successful
                    message={addPersonResponseMessage}
                    whiteBtn={<Button onClick={() => closeDrawerControl()} className="w-200 h-40 f-14 font-w-700 white-btn ">{t('INVENTORY_RETURN_LIST')}</Button>}
                    primaryBtn={<Button onClick={() => goToCreateTaskDrawer()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('ASSIGN_TASK')}</Button>}
                />
            }

             {currentTab !== 5 &&
             <></>
                // <div className="d-flex add-user-terms-container">
                //     <div>
                //         <Checkbox onChange={(e) => termsOnChange(e, 'KVKK')} /><a href="https://www.hrplan.net/aydinlatma-metni/" target="_blank" className="ml-1 terms-text-link">Üye Kişisel Veri Aydınlatma Metni</a><span>’ni okudum.</span>
                //     </div>
                //     <div>
                //         <Checkbox onChange={(e) => termsOnChange(e, 'ExplicitConsentText')} /><span className="ml-1">Verilerin işlenmesine, depolanmasına ve aktarılmasına yönelik</span><a href="https://www.hrplan.net/aydinlatma-metni/" target="_blank" className="ml-1 terms-text-link">Açık Rıza Metni</a><span>’ni okudum ve onayladım.</span>
                //     </div>
                //      <div>
                //         <Checkbox onChange={(e) => termsOnChange(e, 'UserAggrement')} /><a href="https://www.hrplan.net/kullanici-sozlesmesi/" target="_blank" className="ml-1 terms-text-link">Üyelik Sözleşmesi</a><span>‘ni okudum ve kabul ediyorum</span>
                //     </div>
                // </div>
            }
            <div className="add-user-draw-footer">
                {currentTab !== 5 &&
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                }
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 5 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 5 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('PARTNER_CRATE_A_NEW')}</Button>
                    }
                    <div className="pad-left-24" />
                    {currentTab < 5 ?
                        currentTab === 4 && subCurrentTab === 3 ?
                            <Button onClick={() => handleCreatePerson()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_CREATE_NEW_PERSON')}</Button>
                            :
                            <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_NEXT')}</Button>
                        :
                        returnShow ?
                            <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                            :
                            <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('RETURN_HOMEPAGE')}</Button>
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        tabreducer: state.tabreducer,
        createPersonResponse: state.person.createPersonResponse,
        profile: state.profile,
        auth: state.auth,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setPersonAddOpenDraw: (props) => { dispatch(setPersonAddOpenDraw(props)) },
        createPerson: (obj) => { dispatch(createPerson(obj)) },
        createPersonResponseReset: () => { dispatch(createPersonResponseReset()) },
        getPersonOrgChart: (id) => { dispatch(getPersonOrgChart(id)) },
        search: (searchData) => { dispatch(search(searchData)) },
        getRoles: () => { dispatch(getRoles()) },
        resetPersonWorkHours: () => { dispatch(resetPersonWorkHours()) },
        getPersonWorkHours: () => { dispatch(getPersonWorkHours()) },
        getAssignedTasksStepList: (resourceId) => { dispatch(getAssignedTasksStepList(resourceId)) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUserTabs);