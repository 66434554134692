import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    searchPeopleOnLeave, getTimeOffRequest, getTimeOffRequestStep, resetTimeOffApproveResponseStatus, timeOffRequestUpdateReset
} from '../../../redux/actions/timeOff.actions';
import { setTimeOffRequestApproveDrawer } from '../../../redux/actions/time-off-drawer-action';

import { Button, Dropdown } from 'antd';
import { department_details } from '../../../features/global-dropdown-menu/index';
import { dateFormatConvert } from '../../../utils/config';
import DynamicTable from '../../dynamicTable/index';
import moment from 'moment'
import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";

function PeopleOnLeaveTable(props) {
    const { t } = useTranslation();
    const { gridSettings, searchPeopleOnLeave, searchPeopleOnLeaveResult, totalNumberOfRecords, timeOffApproveResponse, peopleOnLeaveInitialSearchData, peopleOnLeaveSearchData, setPeopleOnLeaveSearchData,
        timeOffApproveUpdateStatus, setTimeOffRequestApproveDrawer, isSearchPeopleOnLeaveLoading, getTimeOffRequest, getTimeOffRequestStep, resetTimeOffApproveResponseStatus, timeOffRequestUpdateReset, setSelectedData } = props
    const initialGetGridSettingData = {
        pageId: 5, //timeoff request people on leave 
        gridId: 3, //timeoff grid
    }
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData)
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [tableKey, setTableKey] = useState(4570);

    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            if (searchPeopleOnLeaveResult?.length > 1) {
                searchPeopleOnLeave(peopleOnLeaveSearchData);
            } else {
                setPeopleOnLeaveSearchData({
                    ...peopleOnLeaveSearchData,
                    pagination: {
                        pageNumber: peopleOnLeaveSearchData.pagination.pageNumber > 0 ? peopleOnLeaveSearchData.pagination.pageNumber - 1 : 0, pageSize: peopleOnLeaveSearchData.pagination.pageSize, orderBy: peopleOnLeaveSearchData.pagination.orderBy
                    }
                });
            }
            resetTimeOffApproveResponseStatus();
        }
    }, [timeOffApproveResponse])
    useEffect(() => {
        if (timeOffApproveUpdateStatus.status === 'SUCCESS') {
            if (searchPeopleOnLeaveResult?.length > 1) {
                searchPeopleOnLeave(peopleOnLeaveSearchData);
            } else {
                setPeopleOnLeaveSearchData({
                    ...peopleOnLeaveSearchData,
                    pagination: {
                        pageNumber: peopleOnLeaveSearchData.pagination.pageNumber > 0 ? peopleOnLeaveSearchData.pagination.pageNumber - 1 : 0, pageSize: peopleOnLeaveSearchData.pagination.pageSize, orderBy: peopleOnLeaveSearchData.pagination.orderBy
                    }
                });
            }
            timeOffRequestUpdateReset();
        }
    }, [timeOffApproveUpdateStatus])
    useEffect(() => {
        searchPeopleOnLeave(peopleOnLeaveSearchData);
    }, [peopleOnLeaveSearchData])

    const data = searchPeopleOnLeaveResult.map((row) => {
        return (
            {
                "key": row.resourceId,
                "requestDate": { "text": moment(row.requestDate).format(dateFormatConvert), "id": row.resourceId },
                "fullName": row.fullName,
                "timeOffTypeName": row.timeOffTypeName,
                "startDate": moment(row.startDate).format(dateFormatConvert),
                "endDate": moment(row.endDate).format(dateFormatConvert),
                "balance": row.unitSetIdx === 1 ? t('TIME_OFF_REQUEST_DAYS', { "day": row.balance }) : t('TIME_OFF_REQUEST_HOURS', { "hour": row.balance }),
                "WorkStartDate": moment(row.workStartDate).format(dateFormatConvert),
            }
        );
    })
    const showTimeOffRequestApproveDrawer = (id) => {
        setTimeOffRequestApproveDrawer(true);
        if (id !== undefined) {
            getTimeOffRequest(id)
            getTimeOffRequestStep(id)
            setSelectedData({ 'resourceId': id })
        }
    }
    const handleSaveGridSettings = () => { }

    const handleTableFilter = (data) => {
        setPeopleOnLeaveSearchData({ ...peopleOnLeaveSearchData, "persons": data.persons, "timeOffTypes": data.timeOffTypeName, 'stateTypes': data.stateType })
    }
    const columns = [
        {
            title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
            dataIndex: 'requestDate',
            key: 'requestDate',
            sorter: true,
            render: obj =>
                <div className="imageNameActionColumn">
                    <Can I="timeoff.request.all.read">
                        <a onClick={() => showTimeOffRequestApproveDrawer(obj.id, obj.requestDate, obj.timeOffTypeName, obj.approveSeqNumber, obj.fullName, obj.postId)} className="details-text mar-0" >{obj.text}</a>
                    </Can>
                    <Cannot I="timeoff.request.all.read">
                        <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                    </Cannot>
                    <Dropdown className="action-menu" overlay={department_details(t)} trigger={['click']}>
                        <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                    </Dropdown>
                </div>
        },
        {
            title: `${t('TIME_OFF_REQUEST_NAME_SURNAME')}`,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
        },
        {
            title: `${t('TIME_OFF_REQUEST_TIME_OFF_TYPE')}`,
            dataIndex: 'timeOffTypeName',
            key: 'timeOffTypeName',
            sorter: true,
        },
        {
            title: `${t('TIME_OFF_REQUEST_START_DATE')}`,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
        },
        {
            title: `${t('TIME_OFF_REQUEST_END_DATE')}`,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
        },
        {
            title: `${t('TIME_OFF_REQUEST_TIME')}`,
            dataIndex: 'balance',
            key: 'balance',
            sorter: true,
        },
        {
            title: `${t('TIME_OFF_REQUEST_RETURN_DATE')}`,
            dataIndex: 'WorkStartDate',
            key: 'WorkStartDate',
            sorter: true,
        },
    ];
    const tableConfig = () => ({
        filterableColumns: ['fullName', 'timeOffTypeName'],
        sortableColumns: ['requestDate', 'fullName', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'WorkStartDate'],
        visibilityOptionExcludes: ['requestDate', 'fullName', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'WorkStartDate'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "employeeTimeOff",
        showVisibilityMenu: false,
    })
    const paginationConfig = () => ({
        current: peopleOnLeaveSearchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...peopleOnLeaveSearchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : peopleOnLeaveSearchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setPeopleOnLeaveSearchData({ ...peopleOnLeaveSearchData, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }

    const handleResetAllfilters = () => {
        let key = tableKey;
        key = key + 1;
        setTableKey(key);
        setIsShowPassives(false);
        setPeopleOnLeaveSearchData(peopleOnLeaveInitialSearchData);
        searchPeopleOnLeave(peopleOnLeaveInitialSearchData);
    }
    return (
        <DynamicTable
            key={tableKey}
            columns={columns} dataSource={data}
            loadStatus={isSearchPeopleOnLeaveLoading}
            tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
            onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
            searchPlaceholder=""
            onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
            onSaveGridSettings={(d) => handleSaveGridSettings(d)}
            searchBox={false}
            checkboxStatus={false}
            passiveButton={false}
        // initialFilterData={peopleOnLeaveInitialSearchData}
        />
    )
}
const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        searchPeopleOnLeaveResult: state.timeOff.searchPeopleOnLeaveResult.results,
        totalNumberOfRecords: state.timeOff.searchPeopleOnLeaveResult.totalNumberOfRecords,
        isSearchPeopleOnLeaveLoading: state.timeOff.isSearchPeopleOnLeaveLoading,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        searchPeopleOnLeave: (peopleOnLeaveSearchData) => { dispatch(searchPeopleOnLeave(peopleOnLeaveSearchData)) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        timeOffRequestUpdateReset: () => { dispatch(timeOffRequestUpdateReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PeopleOnLeaveTable);