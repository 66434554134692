import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Input, Button, Dropdown, Spin, Switch, List, ConfigProvider, Checkbox, Modal } from 'antd';
// ICONS
import {
    CloseOutlined, SettingOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { UilUniversity } from '@iconscout/react-unicons';
import { departmentDetailsDropdown } from '../../../features/global-dropdown-menu/index'; // process detail dropdown
import { getDistricts, getProvinces, getCountry, getNeighborhoods } from '../../../redux/actions/address.actions';
import { getPersons, getDepartments, getWorkHourDetailsByDefinitions } from '../../../redux/actions/organization.actions';
import { updateDepartment, resetAction, getHeadDepartment } from '../../../redux/actions/department.action';
import { setDepartmentDetailsDraw } from '../../../redux/actions/person-organization-drawer-action';
import { compareObjects, deepDiffMapper, MergeObjects } from '../../../utils/commonFormFunctions'
import { resetAddress } from '../../../redux/actions/address.actions';

import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';

import Can from "../../../authorization/index";
import '../../../styles/person.scss';

function DepartmentDetailsDraw(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { departmentDetailInfo, departmentDetailInfoIsFetching, updateDepartment, getNeighborhoods,
        neighborhoods, getPersons, getDepartments, departments, persons, resetAddress,
        setDepartmentDetailsDraw, districts, countries, provinces, getDistricts, getCountry, getProvinces, resetAction,
        profile, getHeadDepartment, getHeadDepartmentLoading, headDepartment } = props;
    const { canPassive, canDelete, canActive, permissions, getWorkHourDetailsByDefinitions, workHourDetailsByDefinitions } = props;
    const [departmentDetailState, setDepartmentDetailState] = useState({
        "address": { "district": { "id": null }, "country": { "id": null }, "province": { "id": null }, "neighborhood": { "id": null } },
        "parentDepartment": { "id": null },
        "departmentManager": { "id": null },
        "departmentHrManager": { "id": null }
    });
    const organizationResourceId = profile.organization?.resourceId;
    const [departmentUpdateObj, setDepartmentUpdateObj] = useState({})
    const [workHourList, setWorkHourList] = useState([
        {
            'id': parseInt(departmentDetailInfo?.organizationWorkDefinition?.id),
            'displayValue': departmentDetailInfo?.organizationWorkDefinition?.displayValue
        }
    ])
    const [countryList, setCountryList] = useState([
        { ...departmentDetailInfo?.address?.country }
    ]);
    const [districtList, setDistrictList] = useState([
        { ...departmentDetailInfo?.address?.district }
    ]);
    const [provinceList, setProvinceList] = useState([
        { ...departmentDetailInfo?.address?.province }
    ]);
    const [neighborhoodList, setNeighborhoodList] = useState([
        { ...departmentDetailInfo?.address?.neighborhood }
    ]);
    const [DepartmentManagerResourceIdList, setDepartmentManagerResourceIdList] = useState([
        { ...departmentDetailInfo?.departmentManager }
    ]);
    const [departmentList, setDepartmentList] = useState([
        { ...departmentDetailInfo?.departmentManager }
    ]);
    useEffect(() => {
        setCountryList(countries);
    }, [countries]);
    useEffect(() => {
        setProvinceList(provinces);
    }, [provinces]);
    useEffect(() => {
        setDistrictList(districts);
    }, [districts]);
    useEffect(() => {
        setNeighborhoodList(neighborhoods);
    }, [neighborhoods]);
    useEffect(() => {
        var tempData = workHourDetailsByDefinitions.definitions.map((data) => {
            return {
                'id': data.definitionId,
                'displayValue': data.name
            }
        })
        setWorkHourList(tempData)
    }, [workHourDetailsByDefinitions])

    useEffect(() => {
        setDepartmentManagerResourceIdList(persons);
    }, [persons]);
    useEffect(() => {
        setDepartmentList(departments.filter(filterData => !filterData.id.includes(departmentDetailInfo.resourceId)))

    }, [departments]);

    useEffect(() => {
        setDepartmentList([]);  
        setDepartmentDetailState(departmentDetailInfo);
        form.setFieldsValue({
            "name": departmentDetailInfo?.name,
            "parentDepartment": departmentDetailInfo?.parentDepartment,
            "departmentManager": departmentDetailInfo?.departmentManager,
            "floor": departmentDetailInfo?.floor,
            "country": departmentDetailInfo?.address?.country,
            "district": departmentDetailInfo?.address?.district,
            "province": departmentDetailInfo?.address?.province,
            "neighborhood": departmentDetailInfo?.address?.neighborhood,
            "addressLine": departmentDetailInfo?.address?.addressLine,
            "isActive": departmentDetailInfo?.isActive,
            "isHeadDepartment": departmentDetailInfo?.isHeadDepartment,
            "organizationWorkDefinition": departmentDetailInfo?.organizationWorkDefinition?.id ? { "id": parseInt(departmentDetailInfo?.organizationWorkDefinition?.id), "displayValue": departmentDetailInfo?.organizationWorkDefinition?.displayValue } : null,
        });
        
    }, [form, departmentDetailInfo])

    const [changeHeadDepartmentConfirmModalData, setchangeHeadDepartmentConfirmModalData] = useState({ "isVisible": false })

    const handleCancelChangeHeadDepartment = () => {
        const fields = form.getFieldsValue()
        const fieldsObj = { ...fields };
        fieldsObj["isHeadDepartment"] = false;
        fieldsObj["parentDepartment"] = departmentDetailInfo.parentDepartment;
        form.setFieldsValue(fieldsObj)
        setDepartmentDetailState({ ...departmentDetailState, "parentDepartment": departmentDetailInfo.parentDepartment, "isHeadDepartment": false })
        setchangeHeadDepartmentConfirmModalData({ "isVisible": false })
    }

    useEffect(() => {
        if (headDepartment.name) {
            const message = t('CHANGE_HEAD_DEPARTMENT_CONFIRM', { "currentHeadDepartment": headDepartment.name, "newHeadDepartment": departmentDetailInfo.name })
            setchangeHeadDepartmentConfirmModalData({ "isVisible": true, "message": message })
        }
    }, [headDepartment])

    const handleValuesChange = (neu, all) => {
        if (Object.keys(neu)[0] === 'parentDepartment') {
            setDepartmentDetailState({ ...departmentDetailState, parentDepartment: neu["parentDepartment"] })
        } else if (Object.keys(neu)[0] === 'departmentManager') {
            setDepartmentDetailState({ ...departmentDetailState, departmentManager: neu["departmentManager"] })
        }
        if ('country' === Object.keys(neu)[0]) {
            if (JSON.stringify(neu.country) !== null) {
                // ülke değişti
                setProvinceList([])//il listesini sıfırla
                setDistrictList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//ilçe listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["district"] = '';//ilçeyi sıfırla
                fieldsObj["province"] = '';//il sıfırla
                fieldsObj["neighborhood"] = '';//il sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = {
                    ...departmentDetailState.address, "country": neu['country'],
                    "province": "", "district": "", "neighborhood": ""
                }
                setDepartmentDetailState({ ...departmentDetailState, "address": addressObj })
            } else {
                const addressObj = { ...departmentDetailState.address, 'country': neu["country"] };
                setDepartmentDetailState({ ...departmentDetailState, address: addressObj });
            }
        } else if ('province' === Object.keys(neu)[0]) {
            if (JSON.stringify(neu.province) !== null) {
                // il değişti
                setDistrictList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//ilçe listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["district"] = '';//ilçeyi sıfırla
                fieldsObj["neighborhood"] = '';//ilçeyi sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...departmentDetailState.address, "province": neu['province'], "district": "", "neighborhood": "" }
                setDepartmentDetailState({ ...departmentDetailState, address: addressObj })
            } else {
                const addressObj = { ...departmentDetailState.address, 'province': neu["province"] };
                setDepartmentDetailState({ ...departmentDetailState, address: addressObj });
            }
        } else if (Object.keys(neu)[0] === 'district') {
            setNeighborhoodList([])//ilçe listesini sıfırla
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields };
            fieldsObj["neighborhood"] = '';//ilçeyi sıfırla
            form.setFieldsValue(fieldsObj)
            const addressObj = { ...departmentDetailState.address, 'district': neu["district"], "neighborhood": "" };
            setDepartmentDetailState({ ...departmentDetailState, address: addressObj });
        } else if (Object.keys(neu)[0] === 'neighborhood') {
            const addressObj = { ...departmentDetailState.address, 'neighborhood': neu["neighborhood"] };
            setDepartmentDetailState({ ...departmentDetailState, address: addressObj });
        } else if (Object.keys(neu)[0] === 'addressLine') {
            const addressObj = { ...departmentDetailState.address, 'addressLine': neu["addressLine"] };
            setDepartmentDetailState({ ...departmentDetailState, address: addressObj })
        } else if (Object.keys(neu)[0] === 'isHeadDepartment') {
            if (neu.isHeadDepartment === true) {
                getHeadDepartment()
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["parentDepartment"] = '';
                form.setFieldsValue(fieldsObj)
                setDepartmentDetailState({ ...departmentDetailState, "parentDepartment": "", "isHeadDepartment": neu["isHeadDepartment"] })
            } else {
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["parentDepartment"] = departmentDetailInfo.parentDepartment;
                form.setFieldsValue(fieldsObj)
                setDepartmentDetailState({ ...departmentDetailState, "parentDepartment": departmentDetailInfo.parentDepartment, "isHeadDepartment": neu["isHeadDepartment"] })
            }
        } else {
            setDepartmentDetailState({ ...departmentDetailState, ...neu });
        }
    }

    const setDepartmentUpdateObject = (o) => {
        //gelen datayı diff et
        const diffObj = deepDiffMapper.map(departmentDetailInfo, o);
        // // çıkan diffi update objecte merge et
        const mergedObj = MergeObjects(departmentUpdateObj, diffObj)
        setDepartmentUpdateObj(mergedObj)
    }

    function close() {
        setDepartmentDetailsDraw(false);
        setDepartmentDetailState({
            "address": { "district": { "id": null }, "country": { "id": null }, "province": { "id": null }, "neighborhood": { "id": null } },
            "parentDepartment": { "id": null },
            "departmentManager": { "id": null },
            "departmentHrManager": { "id": null }
        });
        resetAction();
        resetAddress();
    }
  
    useEffect(() => {
        setDepartmentUpdateObject(departmentDetailState);
        const refObj = JSON.stringify({ ...departmentDetailState })
        setDepartmentUpdateObj(JSON.parse(refObj))
    }, [departmentDetailState]);

    const [isDrawerActionSaveDepartmentLoading, setisDrawerActionSaveDepartmentLoading] = useState(false);

    const handleUpdateDepartment = () => {

        if(isDrawerActionSaveDepartmentLoading) return;
        setisDrawerActionSaveDepartmentLoading(true);
        setTimeout(() => {setisDrawerActionSaveDepartmentLoading(false);}, 1055) // double click prevent;

        let hasError = false;
        let fields = [];
        const data = {
            "Name": departmentUpdateObj.name || null,
            "ParentDepartmentResourceId": departmentUpdateObj.parentDepartment && departmentUpdateObj.parentDepartment.id || null,
            "DepartmentManagerResourceId": departmentUpdateObj.departmentManager && departmentUpdateObj.departmentManager.id || null,
            "Floor": departmentUpdateObj.floor || null,
            "IsActive": departmentUpdateObj.isActive || false,
            "IsHeadDepartment": departmentUpdateObj.isHeadDepartment || false,
            "OrganizationWorkDefinitionIdx": departmentUpdateObj.organizationWorkDefinition?.id || null,
            "Address": {
                "DistrictId": departmentUpdateObj.address && departmentUpdateObj.address.district?.id || null,
                "CountryId": departmentUpdateObj.address && departmentUpdateObj.address.country?.id || null,
                "ProvinceId": departmentUpdateObj.address && departmentUpdateObj.address.province?.id || null,
                "Province": departmentUpdateObj.address && departmentUpdateObj.address.province || null,
                "NeighborhoodId": departmentUpdateObj.address && departmentUpdateObj.address.neighborhood?.id || null,
                "AddressLine": departmentUpdateObj.address && departmentUpdateObj.address.addressLine || null,
            },
        }
        if ((!data.Name || data.Name === '')) {
            hasError = true;
            fields.push('( ' + t('DEPARTMENT_NAME') + ' )' + ' boş bırakılamaz');
        }
        if (!hasError) {
            const resourceId = departmentDetailInfo.resourceId;
            updateDepartment({ "resourceId": resourceId, "data": data });

            window.sendinblue?.track(
                //brevo_event_update_department
                'update_department',
            )

        } else {
            DynamicToastMessage({
                'key': 'details_warning_text',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        }
    }
    return (
        departmentDetailInfoIsFetching || getHeadDepartmentLoading ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <div className="table-details organization-drawer" >
                <div className="close-icon" onClick={() => close()}>
                    <CloseOutlined />
                </div>
                <Col span={24}>
                    <Row>
                        <Col span={17}>
                            <Row>
                                <Col span={6}>
                                    <div className="custom-icon-logo">
                                        <UilUniversity />
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div className="user-details">
                                        <h1>{departmentDetailInfo?.name}</h1>
                                        <div className="info-alt department-person-information">
                                            <h3>{departmentDetailInfo?.departmentManager ? t('DEPARTMENT_MANAGER') + ' : ' + departmentDetailInfo?.departmentManager.displayValue : ''}</h3>
                                            <h3>{departmentDetailInfo?.address ? departmentDetailInfo?.address.district ?  t('LOCATION') + ' : ' + departmentDetailInfo?.address.district.displayValue : '' : ''}</h3>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col offset={2} span={5}>
                            <div className="changepage">
                                <Row gutter={[0, 15]}>
                                    <div className="procces">
                                        <Can I="department.read">
                                            <Dropdown
                                                overlay={departmentDetailsDropdown(t, canDelete, canPassive, canActive, departmentDetailInfo.isActive, departmentDetailInfo.resourceId, departmentDetailInfo.name, departmentDetailState.isHeadDepartment, permissions)}
                                                trigger={['click']}>
                                                <Button className="primary-btn w-180 h-32"> <SettingOutlined />{t('PARTNER_PROCESS')} </Button>
                                            </Dropdown>
                                        </Can>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <div className="hr-bottom mb-20"></div>
                <div className="w-100 department-scrollable">
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            onValuesChange={handleValuesChange}
                            layout="vertical"
                            requiredMark={true}
                        >
                            <Row gutter={[32, 0]}>
                                <Col span={12}>
                                    <div className="form-left-body">
                                        <Form.Item className="custom-select" name="name" label={t('DEPARTMENT_NAME') + " *"} required>
                                            <Input className={!compareObjects(departmentDetailInfo?.name, departmentDetailState?.name) ? 'is-changed' : ''} />
                                        </Form.Item>
                                        <Form.Item name="isHeadDepartment" valuePropName="checked">
                                            <Checkbox disabled={departmentDetailInfo?.isHeadDepartment}>{t('IS_HEAD_DEPARTMENT_CHECKBOX_LBL')}</Checkbox>
                                        </Form.Item>
                                        {!departmentDetailState?.isHeadDepartment &&
                                            <Form.Item name="parentDepartment" className="custom-select" label={t('DEPARTMENT_PARENT_DEPARTMENT')}>
                                                <DynamicSelectbox
                                                    placeholder={t('PARTNER_SELECT')}
                                                    optionList={departmentList}
                                                    onFocus={() => getDepartments(organizationResourceId)}
                                                    className={!compareObjects(departmentDetailInfo?.parentDepartment, departmentDetailState?.parentDepartment) ? 'is-changed' : ''}
                                                    disabled={departmentDetailState?.isHeadDepartment}
                                                />
                                            </Form.Item>
                                        }
                                        <Form.Item name="departmentManager" className="custom-select" label={t('DEPARTMENT_MANAGER')}>
                                            <DynamicSelectbox
                                                placeholder={t('PARTNER_SELECT')}
                                                optionList={DepartmentManagerResourceIdList}
                                                onFocus={() => getPersons(organizationResourceId)}
                                                className={!compareObjects(departmentDetailInfo?.departmentManager, departmentDetailState?.departmentManager) ? 'is-changed' : ''}
                                            />
                                        </Form.Item>
                                        <Form.Item name="organizationWorkDefinition" className="custom-select" label={t('CHOOSE_WORKING_HOURS')}>
                                            <DynamicSelectbox
                                                placeholder={t('PARTNER_SELECT')}
                                                optionList={workHourList}
                                                disallowClear={true}
                                                onFocus={() => getWorkHourDetailsByDefinitions()}
                                                className={!compareObjects(departmentDetailInfo?.organizationWorkDefinition, departmentDetailState?.organizationWorkDefinition) ? 'is-changed' : ''}
                                            />
                                        </Form.Item>
                                        {!departmentDetailState?.organizationWorkDefinition?.id &&
                                            <p className="black f-14">{t('EMPTY_DEPARTMENT_WORK_HOURS', { "name": departmentDetailInfo?.name })}</p>
                                        }
                                        <Form.Item className="pad-0 mar-0" label={t('DEPARTMENT_STATU')} name="isActive" valuePropName="checked" >
                                            <Switch disabled={departmentDetailInfo.isHeadDepartment} />
                                        </Form.Item>
                                        {departmentDetailState?.isActive ?
                                            <div className="green toggle-text">{t('DEPARTMENT_ACTIVE')}</div>
                                            :
                                            <div className="red toggle-text">{t('DEPARTMENT_PASSIVE')}</div>
                                        }
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-right-body">
                                        <Form.Item className="custom-select" name="country" label={t('ORGANIZATION_COUNTRY')} required>
                                            <DynamicSelectbox
                                                optionList={countryList}
                                                onFocus={() => getCountry()}
                                                className={!compareObjects(departmentDetailInfo?.address?.country, departmentDetailState?.address?.country) ? 'is-changed' : ''}
                                                placeholder={t('COUNTRY_PLACEHOLDER')}
                                            />
                                        </Form.Item>
                                        <Form.Item className="custom-select" name="province" label={t('ORGANIZATION_PROVINCE')} required>
                                                <Input disabled={!departmentDetailState?.address?.country} />
                                        </Form.Item>
                                        {
                                            /* 
                                            <Form.Item className="custom-select" name="province" label={t('ORGANIZATION_PROVINCE')} required>
                                                <DynamicSelectbox
                                                    optionList={provinceList}
                                                    onFocus={() => getProvinces(departmentDetailState?.address.country?.id)}
                                                    className={!compareObjects(departmentDetailInfo?.address?.province, departmentDetailState?.address?.province) ? 'is-changed' : ''}
                                                    disabled={!departmentDetailState?.address?.country}
                                                    placeholder={t('PROVINCE_PLACEHOLDER')}
                                                />
                                            </Form.Item>
                                            <Form.Item className="custom-select" name="district" label={t('ORGANIZATION_DISTRICT')} required>
                                                <DynamicSelectbox
                                                    optionList={districtList}
                                                    onFocus={() => getDistricts(departmentDetailState?.address?.province.id)}
                                                    className={!compareObjects(departmentDetailInfo?.address?.district, departmentDetailState?.address?.district) ? 'is-changed' : ''}
                                                    disabled={!departmentDetailState?.address?.province}
                                                    placeholder={t('DISTRICT_PLACEHOLDER')}

                                                />
                                            </Form.Item>
                                            <Form.Item className="custom-select" name="neighborhood" label={t('ORGANIZATION_REGION')}  >
                                                <DynamicSelectbox
                                                    optionList={neighborhoodList}
                                                    onFocus={() => getNeighborhoods(departmentDetailState.address.district.id)}
                                                    className={!compareObjects(departmentDetailInfo?.address?.neighborhood, departmentDetailState?.address?.neighborhood) ? 'is-changed' : ''}
                                                    disabled={!departmentDetailState?.address?.province}
                                                    placeholder={t('REGION_PLACEHOLDER')}
                                                />
                                            </Form.Item>
                                            */
                                        }

                                        <Form.Item name="addressLine" label={t('ORGANIZATION_ADDRESS')}>
                                            <Input.TextArea className={departmentDetailInfo?.address?.addressLine !== departmentDetailState?.address?.addressLine ? 'is-changed' : ''} />
                                        </Form.Item>
                                        <Form.Item name="floor" className="custom-select" label={t('DEPARTMENT_FLOOR')}>
                                            <Input className={!compareObjects(departmentDetailInfo?.floor, departmentDetailState?.floor) ? 'is-changed' : ''} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ConfigProvider>
                </div>
                <div className="fixed-btn">
                    <div className="page-end">
                        <div className="hr-end"></div>
                    </div>
                    <div className="form-submit-space-between">
                        <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>
                        <div className="btn-padding" />
                        <Can I="department.update">
                            <Button onClick={handleUpdateDepartment} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </Can>
                    </div>
                </div>
                <Modal visible={changeHeadDepartmentConfirmModalData.isVisible} className="delete-confirm-modal"
                    onCancel={() => handleCancelChangeHeadDepartment()}
                    footer={[
                        <Row gutter={24}>
                            <Col span="12">
                                <Button key="back" className="ant-btn white-btn w-100 h-40" onClick={() => handleCancelChangeHeadDepartment()}>
                                    {t('PARTNER_CANCEL')}
                                </Button>
                            </Col>
                            <Col span="12">
                                <Button key="submit" className="ant-btn primary-btn w-100 h-40" onClick={() => setchangeHeadDepartmentConfirmModalData({ "isVisible": false })}>
                                    {t('YES')}
                                </Button>
                            </Col>
                        </Row>,
                    ]}>
                    <div >
                        <div><ExclamationCircleOutlined className="dynamic-modal-icon" style={{ "fontSize": "48px" }} /></div>
                        <div className="title">{t('WARNING')}</div>
                        <div className="desc mb-20">{changeHeadDepartmentConfirmModalData.message}</div>
                    </div>
                </Modal>
            </div >
        )
    )
}

const mapStateToProps = (state) => {
    return {
        neighborhoods: state.addressReducer.neighborhoods,
        districts: state.addressReducer.districts,
        provinces: state.addressReducer.provinces,
        countries: state.addressReducer.countries,
        persons: state.organization.persons,
        departments: state.organization.departments,
        profile: state.profile,
        getHeadDepartmentLoading: state.departmentReducer.getHeadDepartmentLoading,
        headDepartment: state.departmentReducer.headDepartment,
        departmentDetailInfo: state.departmentReducer.departmentDetailInfo,
        departmentDetailInfoIsFetching: state.departmentReducer.departmentDetailInfoIsFetching,
        permissions: state.profile.permissions,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDepartmentDetailsDraw: (props) => { dispatch(setDepartmentDetailsDraw(props)) },
        resetAction: () => { dispatch(resetAction()) },
        getDistricts: (provinceId) => { dispatch(getDistricts(provinceId)) },
        getNeighborhoods: (districtId) => { dispatch(getNeighborhoods(districtId)) },
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (props) => { dispatch(getProvinces(props)) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        getDepartments: (id) => { dispatch(getDepartments(id)) },
        updateDepartment: (id) => { dispatch(updateDepartment(id)) },
        resetAddress: () => { dispatch(resetAddress()) },
        getHeadDepartment: () => { dispatch(getHeadDepartment()) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDetailsDraw);