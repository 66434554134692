import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getPersonTabEducationList, resetPersonTabEducationList } from '../../redux/actions/education.actions';

import { Table, Space, Spin } from 'antd';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

function PersonContact(props) {
  const { resetPersonTabEducationList, personResourceId, getPersonTabEducationList, personTabEducationList, personTabEducationListLoading } = props;
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setTableData([]);
    getPersonTabEducationList(personResourceId);
  }, [])
  useEffect(() => {
    if (personTabEducationList.status === 'SUCCESS') {
      personTabEducationList.data.map((item, index) => {
        const startedDate = item.startDate
        const endDate = item.endDate
        const data = {
          key: index,
          name: item.educationName,
          startedDate: startedDate,
          endDate: endDate,
          state: item.state,
          resourceId: item.resourceId
        }
        setTableData((prevArray) => [...prevArray, data])
      })
    }
    else if (personTabEducationList.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': personTabEducationList.message,
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetPersonTabEducationList();
    }
  }, [personTabEducationList])

  const columns = [
    {
      title: t('PARTNER_NAME_SURNAME2'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('TIME_OFF_REQUEST_START_DATE'),
      dataIndex: 'startedDate',
      key: 'startedDate',
    },
    {
      title: t('TIME_OFF_REQUEST_END_DATE'),
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: t('TIME_OFF_REQUEST_STATUS'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {
            record.state == 32 &&
            <span>{t('ADDED')}</span>
          }
          {
            record.state == 16 &&
            <span>{t('EXPIRED')}</span>
          }
          {
            record.state == 2 &&
            <span>{t('PROGRESSING')}</span>
          }
          {
            record.state == 1 &&
            <span>{t('STARTED2')}</span>
          }
        </Space>
      ),
    },
  ];

  return (
    personTabEducationListLoading ?
      <Spin className="w-100 d-flex justify-center align-center h-100" />
      :
      <div>
        <div className="content">
          <div className="mt-8">
            <Table columns={columns} dataSource={tableData} />
          </div>
          <div>
          </div>
        </div>
      </div>
  )
}
const mapStateToProps = (state) => {
  return {
    personTabEducationList: state.education.personTabEducationList,
    personTabEducationListLoading: state.education.personTabEducationListLoading
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPersonTabEducationList: (resourceId) => { dispatch(getPersonTabEducationList(resourceId)) },
    resetPersonTabEducationList: () => { dispatch(resetPersonTabEducationList()) }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonContact);