import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

const ApprovalProcesses = {
    SET_APPROVAL_PROCESSES_CREATE_DRAWER:"SET_APPROVAL_PROCESSES_CREATE_DRAWER",
    SET_APPROVAL_PROCESSES_DETAIL_DRAWER:"SET_APPROVAL_PROCESSES_DETAIL_DRAWER",
    GET_PROCESSES_DETAIL_REQUEST:"GET_PROCESSES_DETAIL_REQUEST",
    GET_PROCESSES_DETAIL_SUCCESS:"GET_PROCESSES_DETAIL_SUCCESS",
    GET_PROCESSES_DETAIL_FAIL:"GET_PROCESSES_DETAIL_FAIL",
    CREATE_APPROVAL_PROCESSES_REQUEST:"CREATE_APPROVAL_PROCESSES_REQUEST",
    CREATE_APPROVAL_PROCESSES_SUCCESS:"CREATE_APPROVAL_PROCESSES_SUCCESS",
    CREATE_APPROVAL_PROCESSES_FAIL:"CREATE_APPROVAL_PROCESSES_FAIL",
    CREATE_APPROVAL_PROCESSES_VALIDATION_FAIL:"CREATE_APPROVAL_PROCESSES_VALIDATION_FAIL",
    CREATE_APPROVAL_PROCESSES_RESET:"CREATE_APPROVAL_PROCESSES_RESET",
    GET_ALL_PROCESS_REQUEST:"GET_ALL_PROCESS_REQUEST",
    GET_ALL_PROCESS_SUCCESS:"GET_ALL_PROCESS_SUCCESS",
    GET_ALL_PROCESS_FAIL:"GET_ALL_PROCESS_FAIL",
    DELETE_APPROVAL_PROCESSES_SUCCESS: "DELETE_APPROVAL_PROCESSES_SUCCESS",
    DELETE_APPROVAL_PROCESSES_FAIL: "DELETE_APPROVAL_PROCESSES_FAIL",
    DELETE_APPROVAL_PROCESSES_RESET: "DELETE_APPROVAL_PROCESSES_RESET",
    UPDATE_APPROVAL_PROCESSES_TYPE_SUCCESS:"UPDATE_APPROVAL_PROCESSES_TYPE_SUCCESS",
    UPDATE_APPROVAL_PROCESSES_TYPE_FAIL:"UPDATE_APPROVAL_PROCESSES_TYPE_FAIL",
    UPDATE_APPROVAL_PROCESSES_TYPE_RESET:"UPDATE_APPROVAL_PROCESSES_TYPE_RESET",
    UPDATE_APPROVAL_PROCESSES_REQUEST:"UPDATE_APPROVAL_PROCESSES_REQUEST",
    UPDATE_APPROVAL_PROCESSES_SUCCESS:"UPDATE_APPROVAL_PROCESSES_SUCCESS",
    UPDATE_APPROVAL_PROCESSES_FAIL:"UPDATE_APPROVAL_PROCESSES_FAIL",
    UPDATE_APPROVAL_PROCESSES_VALIDATION_FAIL:"UPDATE_APPROVAL_PROCESSES_VALIDATION_FAIL",
    UPDATE_APPROVAL_PROCESSES_RESET:"UPDATE_APPROVAL_PROCESSES_RESET",
    GET_CHECK_DEFAULT_REQUEST:"GET_CHECK_DEFAULT_REQUEST",
    GET_CHECK_DEFAULT_PROCESSES_SUCCESS:"GET_CHECK_DEFAULT_PROCESSES_SUCCESS",
    GET_CHECK_DEFAULT_PROCESSES_FAIL:"GET_CHECK_DEFAULT_PROCESSES_FAIL",
    SET_CHECK_DEFAULT_PROCESSES:"SET_CHECK_DEFAULT_PROCESSES"
}

const setApprovalProcessesCreateDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: ApprovalProcesses.SET_APPROVAL_PROCESSES_CREATE_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
};
const setApprovalProcessesDetailDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: ApprovalProcesses.SET_APPROVAL_PROCESSES_DETAIL_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
};
const getAllProcess = () => {
    return async (dispatch) => {
      dispatch({ type: ApprovalProcesses.GET_ALL_PROCESS_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/process/GetAll',
            headers: { 
              'Content-Type': 'application/json',
            },
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: ApprovalProcesses.GET_ALL_PROCESS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: ApprovalProcesses.GET_ALL_PROCESS_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
           
              dispatch({ type: ApprovalProcesses.GET_ALL_PROCESS_FAIL, payload: error.response?.data });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: ApprovalProcesses.GET_ALL_PROCESS_FAIL, payload: error.message });
        }
      }
    };
  };
const createApprovalProcesses = (createObj) => {
    return async (dispatch) => {
      dispatch({ type: ApprovalProcesses.CREATE_APPROVAL_PROCESSES_REQUEST });
      try {

          var data = JSON.stringify(createObj);
          
          var config = {
            method: 'post',
            url: apiUrl+'/process',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
              dispatch({ type: ApprovalProcesses.CREATE_APPROVAL_PROCESSES_SUCCESS, payload: response.data });
            }else{
                dispatch({ type: ApprovalProcesses.CREATE_APPROVAL_PROCESSES_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            if(error.response.status === 422){
              dispatch({ type: ApprovalProcesses.CREATE_APPROVAL_PROCESSES_VALIDATION_FAIL, payload: error.response.data?.data });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: ApprovalProcesses.CREATE_APPROVAL_PROCESSES_FAIL, payload: error.message });
        }
      }
    };
  };
  const getProcessesDetail = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: ApprovalProcesses.GET_PROCESSES_DETAIL_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/process/'+resourceId,
            headers: { },

          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: ApprovalProcesses.GET_PROCESSES_DETAIL_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: ApprovalProcesses.GET_PROCESSES_DETAIL_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: ApprovalProcesses.GET_PROCESSES_DETAIL_FAIL, payload: error.message });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: ApprovalProcesses.GET_PROCESSES_DETAIL_FAIL, payload: error.message });
        }
      }
    };
  };

const resetCreateApprovalProcesses = () => {    
  return async (dispatch) => {
    dispatch({ type: ApprovalProcesses.CREATE_APPROVAL_PROCESSES_RESET, payload:{} });
    };
}
const deleteApprovalProcesses = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'delete',
        url: apiUrl + '/process/' + resourceId,
        headers: {
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ApprovalProcesses.DELETE_APPROVAL_PROCESSES_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ApprovalProcesses.DELETE_APPROVAL_PROCESSES_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: ApprovalProcesses.DELETE_APPROVAL_PROCESSES_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: ApprovalProcesses.DELETE_APPROVAL_PROCESSES_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ApprovalProcesses.DELETE_APPROVAL_PROCESSES_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteApprovalProcesses = () => {
  return async (dispatch) => {
    dispatch({ type: ApprovalProcesses.DELETE_APPROVAL_PROCESSES_RESET, payload: {} });
  };
}
const updateApprovalProcessesStatus = (resourceId,status,index) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'post',
        url: apiUrl + '/process/ChangeActive/' + resourceId+'/'+status,
        headers: {
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_SUCCESS, payload: {'message':response.data.message, 'index':index} });
          } else {
            dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_FAIL, payload: {'message':response.data.message, 'index':index} });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422 || error.response?.status === 404 ) {
            dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_FAIL, payload: {'message':error.response.data.message, 'index':index}  });
          } else {
            dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_FAIL, payload: {'message':error.message, 'index':index}  });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_FAIL, payload: {'message':error.message, 'index':index} });
      }
    }
  };
};
const resetUpdateApprovalProcessesStatus = () => {
  return async (dispatch) => {
    dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_RESET, payload: {} });
  };
}
const updateApprovalProcesses = (updateData,resourceId) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(updateData);
      var config = {
        method: 'put',
        url: apiUrl+'/process/'+resourceId,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
            dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 422){
          dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_VALIDATION_FAIL, payload: error.response.data?.data });
        }else{
          dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_FAIL, payload: error.message });
        }
      });
       
    } catch (error) {
      if (error.response) {
          dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateApprovalProcesses = () => {
  return async (dispatch) => {
    dispatch({ type: ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_RESET, payload:{} });
    };
}
const getCheckDefaultProcess = (type) => {
  return async (dispatch) => {
    dispatch({ type: ApprovalProcesses.GET_CHECK_DEFAULT_REQUEST });
    try {
         var config = {
          method: 'post',
          url: apiUrl+'/process/CheckDefaultProcess/'+type,
          headers: { 
            'Content-Type': 'application/json',
          },
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: ApprovalProcesses.GET_CHECK_DEFAULT_PROCESSES_SUCCESS, payload: response.data });
          }else{
              dispatch({ type: ApprovalProcesses.GET_CHECK_DEFAULT_PROCESSES_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
         
            dispatch({ type: ApprovalProcesses.GET_CHECK_DEFAULT_PROCESSES_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: ApprovalProcesses.GET_CHECK_DEFAULT_PROCESSES_FAIL, payload: error.message });
      }
    }
  };
};
const setCheckDefaultProcess = (status) => async dispatch => {
  try {
      dispatch({
          type: ApprovalProcesses.SET_CHECK_DEFAULT_PROCESSES,
          payload: status,
      });
  } catch (e) {
  }
};
export {
    ApprovalProcesses,
    setApprovalProcessesCreateDrawer,
    setApprovalProcessesDetailDrawer,
    createApprovalProcesses,
    resetCreateApprovalProcesses,
    resetDeleteApprovalProcesses,
    getProcessesDetail,
    getAllProcess,
    deleteApprovalProcesses,
    updateApprovalProcessesStatus,
    resetUpdateApprovalProcessesStatus,
    updateApprovalProcesses,
    resetUpdateApprovalProcesses,
    getCheckDefaultProcess,
    setCheckDefaultProcess
}