import { storeUserError, storeUser, userSignedOut } from "../redux/actions/auth.actions";
import { getPermissionsViaLogin } from "../redux/actions/profile.actions";
import { oidcConfig } from "../utils/config";
import { authApi } from "../utils/apiConfig";
import getStore from "../redux/store";

import userManager from "../utils/userManager";

let isRefresh = false;


userManager.events.addUserLoaded(async(user) => {
  const {store} = getStore();
  isRefresh && await fetchAndUpdateUserInfo(store.dispatch, null, user)
});

userManager.events.addUserUnloaded(async(user) => {
  localStorage.clear("persist:root");
});

userManager.events.addAccessTokenExpiring(async() => {
  isRefresh = true;
});

userManager.events.addSilentRenewError((error) =>{
  userManager.signoutRedirect();
})


const setUserAction = (user, history) => {
  return (dispatch) =>{
    return new Promise((resolve) =>{
      if (!user) {
        dispatch(storeUserError());
        return resolve(history.push("/signin"));
      }
      dispatch(storeUser(user));
      if (user?.permissions) {
        dispatch(getPermissionsViaLogin(user?.permissions));
      }
      return resolve();
    })
  }
}

export async function loadUserFromUserInfo(dispatch, history, user, redirectUrl) {

  new Promise( async (resolve,reject) =>{
    try {

        Promise.all([
          dispatch(setUserAction(user,history))
        ]).then(() => {
          
          if(redirectUrl && history){
            return resolve(history.push(redirectUrl))
          }else if(redirectUrl){
            return resolve(window.location.href = redirectUrl)
          }else{
            return resolve()
          }

        }).catch((error) => {
          console.error('Bir hata oluştu', error);
        });

        
      
    } catch (e) {
      console.error(`User not found: ${e}`);
      dispatch(storeUserError());
      return reject()
    }
  })
}

export async function removeUserFromStorage(dispatch, history) {
  const i18nLangCookie = document.cookie.split('; ').find(row => row.startsWith('i18nLang='))?.split('=')[1];
  dispatch(userSignedOut());
  if(i18nLangCookie == 'en'){
    window.location.href = "/signin?lang=en"
  }else{
    window.location.href = "/signin?lang=tr-TR"
  }
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export async function signinSilentCallback() {
  return userManager.signinSilentCallback().then(async (user) =>  {
  }).catch(error => {
    console.error(error);
  });
}

export async function  fetchAndUpdateUserInfo (dispatch, history, user , redirectUrl){

  new Promise( async (resolve,reject) =>{
    try {
      
        if( redirectUrl || isRefresh ){
          const response = await authApi.get(oidcConfig.authority+'/connect/userinfo', {
            headers: { 'Authorization': `Bearer ${user.access_token}` }
          });

          let profile = JSON.parse(JSON.stringify(user.profile))
          let userInfoProfile = response.data 
          profile = {...profile, ...userInfoProfile }
          user['profile']=profile;
          user['permissions'] = profile.permissions;
        }

        isRefresh = false;

        return resolve(await loadUserFromUserInfo(dispatch,history, user, redirectUrl))
        
    } catch (error) {
        console.error('Kullanıcı bilgileri alınırken hata:', error);
        return reject()
    }
  })
}

export async function signinSilent(dispatch, history) {
  userManager.signinSilent()
  .then(async (user) =>  {
    isRefresh = true;
    await fetchAndUpdateUserInfo(dispatch, history, user)
  })
  .catch(err => {
      userManager.signoutRedirect();
  });
}

export function signoutRedirect(user) {
  document.cookie = 'i18nLang=' + (localStorage.getItem('i18nLang') || '') + '; path=/; expires=' + (new Date(Date.now() + 7 * 864e5)).toUTCString();
  return userManager.signoutRedirect({ id_token_hint: user.id_token });
}

export function signoutRedirectCallback() {
  userManager.removeUser();
  userManager.revokeAccessToken();
  userManager.clearStaleState();
  return userManager.signoutRedirectCallback();
}

export default userManager;
