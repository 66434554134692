import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spin, Radio, ConfigProvider, List } from 'antd';
// ICONS
import {
    CloseOutlined
} from '@ant-design/icons';
import { getDepartments } from '../../redux/actions/organization.actions';
import { departmentMoreAction, resetDepartmentControl } from '../../redux/actions/department.action';
import { setDepartmentControlDrawer } from '../../redux/actions/person-organization-drawer-action';

import DynamicSelectbox from "../dynamicSelectbox/index";
import DynamicToastMessage from '../../component/dynamicToastMessage/index';


import '../../styles/person.scss';

function DepartmentActionDrawer(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm()

    const { departmentMoreAction, getDepartments, profile, departments, setDepartmentControlDrawer,
        departmentControlDrawer, departmentControlData, departmentControlLoading, resetDepartmentControl } = props;

    const [departmentActionState, setDepartmentActionState] = useState({
        "departmentActionList": {}
    })
    const [departmentList, setDepartmentList] = useState([])
    const [drawerTxtFromStatus, setDrawerTxtFromStatus] = useState({
        "drawerTitle": "",
        "isHavePersonDescription": "",
        "departmentDescription": "",
        "buttonTxt": ""
    });
    const OrganizationResourceId = profile.organization?.resourceId;
    const isHavePersonKey = departmentControlData?.isHavePersons ? 1 : 0;
    const parentDepartmentName = departmentControlData?.parentDepartmentName ? departmentControlData?.parentDepartmentName : '';

    useEffect(() => {
        setDepartmentList(departments.filter(filterData => !filterData.id.includes(departmentControlDrawer.id)))
    }, [departments]);

    useEffect(() => {
        if (departmentControlDrawer?.modalType === 'delete') {
            setDrawerTxtFromStatus({
                ...drawerTxtFromStatus,
                "drawerTitle": t('DEPARTMENT_DELETE', { "departmentName": departmentControlDrawer.name }),
                "isHavePersonDescription": t('DEPARTMENT_WITH_PEOPLE_WHAT_DO_U_DO'),
                "departmentDescription": t('DEPARTMENT_DELETE_QUESTION'),
                "buttonTxt": t('DEPARTMENT_DELETE_BUTTON')
            })
        } else if (departmentControlDrawer?.modalType === 'disable') {
            setDrawerTxtFromStatus({
                ...drawerTxtFromStatus,
                "drawerTitle": t('DEPARTMENT_DO_PASSIVE', { "departmentName": departmentControlDrawer.name }),
                "isHavePersonDescription": t('DEPARTMENT_WITH_PEOPLE_WHAT_DO_U_DO'),
                "departmentDescription": t('DEPARTMENT_DELETE_OTHER_DEPARTMENT_QUESTION'),
                "buttonTxt": t('DEPARTMENT_DO_PASSIVE_BUTTON')
            })
        }
    }, [departmentControlDrawer])



    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []

        if (departmentControlData?.isHavePersons === true) {

            if (!departmentActionState.departmentActionList[0]?.actionState_0) {
                hasError = true
                fields.push(t('DEPARTMENT_WITH_PEOPLE'))
            }
            if (departmentActionState.departmentActionList[0]?.actionState_0 === "2" && !departmentActionState.departmentActionList[0]?.destinationDepartment_0?.id) {
                hasError = true;
                fields.push(t('DEPARTMENT_CONTACT_ANOTHER'))
            }
        }
        if (departmentControlData?.isHaveChildDepartments === true) {
            if (!departmentActionState.departmentActionList[isHavePersonKey]?.['actionState_' + isHavePersonKey]) {
                hasError = true
                fields.push(t('DEPARTMENT_HIERARCHY'))
            }
            if (departmentActionState.departmentActionList[isHavePersonKey]?.['actionState_' + isHavePersonKey] === "2" && !departmentActionState.departmentActionList[isHavePersonKey]?.['destinationDepartment_' + isHavePersonKey]?.id) {
                hasError = true;
                fields.push(t('DEPARTMENT_CONTACT_ANOTHER'))
            }
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [isDrawerActionLoading, setisDrawerActionLoading] = useState(false);

    const handleUpdateAction = () => {

        if(isDrawerActionLoading) return;
        setisDrawerActionLoading(true);
        setTimeout(() => {setisDrawerActionLoading(false);}, 1055) // double click prevent;

        let departmentActions = [];

        Object.entries(departmentActionState?.departmentActionList).map((value) => {
            const departmentElement = JSON.parse(value[1][["actionState_" + value[0]]]);
            const departmentDestinationUserElement = value[1][["destinationDepartment_" + value[0]]];
            departmentActions.push({
                "ActionState": departmentElement,
                "DestinationDepartmentResourceId": departmentDestinationUserElement?.id,
            })
        })
        const requestObj = {
            "ResourceId": departmentControlDrawer.id,
            "DeleteOptions": departmentActions
        }
        checkRequiredFields(() => departmentMoreAction({ 'id': departmentControlDrawer.id, 'requestObj': requestObj, 'type': departmentControlDrawer.modalType }))
    }

    function close() {
        setDepartmentControlDrawer({
            status: false, //Drawer status
            id: null,
            name: null,
            modalType: null,
            componentKey: departmentControlDrawer?.componentKey

        })
        resetDepartmentControl()
    }

    const handleValuesChange = (changedObj, all) => {

        const changedValue = Object.keys(changedObj)[0] // test_0
        const type = changedValue.split('_')[0]; // test
        const key = changedValue.split('_')[1]; // 0

        if (type === 'actionState' || type === 'destinationDepartment') {
            if (type === 'actionState' && changedObj[changedValue] === "1") {

                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["destinationDepartment_" + key] = "";
                form.setFieldsValue(fieldsObj)

                let tempItem = { ...departmentActionState.departmentActionList[key], [changedValue]: changedObj[changedValue], ["destinationDepartment_" + key]: { 'id': null, 'displayValue': null } };
                let tempProcessObj = { ...departmentActionState.departmentActionList, [key]: tempItem }
                setDepartmentActionState({ ...departmentActionState, departmentActionList: tempProcessObj });

            } else {
                let tempItem = { ...departmentActionState.departmentActionList[key], [changedValue]: changedObj[changedValue] };
                let tempProcessObj = { ...departmentActionState.departmentActionList, [key]: tempItem }
                setDepartmentActionState({ ...departmentActionState, departmentActionList: tempProcessObj });
            }
        } else {
            setDepartmentActionState({ ...departmentActionState, ...changedObj });
        }
    }



    return (
        departmentControlLoading ? <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin> :
            <div className="table-details delete-person-container" >

                <div className="back-drawer pad-top-10 pad-21">
                    <h1 className="back-drawer-title">
                        {drawerTxtFromStatus?.drawerTitle}
                    </h1>
                </div>
                <div className="close-icon" onClick={() => close()}>
                    <CloseOutlined />
                </div>
                <div className="w-100 delete-person-form-container">
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            onValuesChange={handleValuesChange}
                            layout="vertical"
                            requiredMark={true}
                        >
                            <div className="form-left-body">


                                {departmentControlData?.isHavePersons === true &&
                                    <div className="delete-drawer-information-class">
                                        <div className="delete-drawer-information-title">
                                            <h1>{t('DEPARTMENT_WITH_PEOPLE') + " *"}</h1>
                                        </div>

                                        <div className="delete-drawer-information-item">
                                            <label className='delete-drawer-information-description'>
                                                {drawerTxtFromStatus.isHavePersonDescription}
                                            </label>
                                        </div>
                                        <div className="delete-drawer-information-item pad-top-10">

                                            <React.Fragment >
                                                <Form.Item className="margin-b-12 delete-draw-label" name="actionState_0"  >
                                                    <Radio.Group options={
                                                        [
                                                            { label: `${t('DEPARTMENT_CONTACT_TOP') + parentDepartmentName}`, value: "1", disabled: parentDepartmentName ? false : true },
                                                            { label: `${t('DEPARTMENT_CONTACT_ANOTHER')}`, value: "2" },
                                                        ]
                                                    } />
                                                </Form.Item>
                                                {departmentActionState.departmentActionList[0]?.actionState_0 === "2" ?
                                                    <Form.Item className='custom-select pad-12' name="destinationDepartment_0" required >
                                                        <DynamicSelectbox
                                                            optionList={departmentList}
                                                            onFocus={() => getDepartments(OrganizationResourceId)}
                                                            placeholder={t('PARTNER_SELECT')}
                                                        />
                                                    </Form.Item>
                                                    : ''}
                                            </React.Fragment>


                                        </div>




                                    </div>
                                }

                                {departmentControlData?.isHaveChildDepartments === true &&
                                    <div className="delete-drawer-information-class">
                                        <div className="delete-drawer-information-title">
                                            <h1>{t('DEPARTMENT_HIERARCHY') + " *"}</h1>
                                        </div>

                                        <div className="delete-drawer-information-item">
                                            <label className='delete-drawer-information-description'>
                                                {drawerTxtFromStatus.departmentDescription}
                                            </label>
                                        </div>
                                        <div className="delete-drawer-information-item pad-top-10">

                                            <React.Fragment >
                                                <Form.Item className="margin-b-12 delete-draw-label" name={"actionState_" + isHavePersonKey}  >
                                                    <Radio.Group options={
                                                        [
                                                            { label: `${t('DEPARTMENT_CONTACT_TOP') + parentDepartmentName}`, value: "1", disabled: parentDepartmentName ? false : true },
                                                            { label: `${t('DEPARTMENT_CONTACT_ANOTHER')}`, value: "2" },
                                                        ]
                                                    } />
                                                </Form.Item>

                                                {departmentActionState.departmentActionList[isHavePersonKey]?.['actionState_' + isHavePersonKey] === "2" ?
                                                    <Form.Item className='custom-select pad-12' name={"destinationDepartment_" + isHavePersonKey} required >
                                                        <DynamicSelectbox
                                                            optionList={departmentList}
                                                            onFocus={() => getDepartments(OrganizationResourceId)}
                                                            placeholder={t('PARTNER_SELECT')}
                                                        />
                                                    </Form.Item>
                                                    : ''}
                                            </React.Fragment>


                                        </div>




                                    </div>

                                }

                            </div>
                        </Form>
                    </ConfigProvider>
                </div>

                <div className="fixed-btn">
                    <div className="page-end">
                        <div className="hr-end"></div>
                    </div>

                    <div className="form-submit-space-between">
                        <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                        <div className="btn-padding" />
                        <Button onClick={handleUpdateAction} className="primary-btn w-180 h-40 f-14">{drawerTxtFromStatus?.buttonTxt}</Button>
                    </div>
                </div>
            </div >

        // )
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        departmentControlDrawer: state.organizationTabreducer.departmentControlDrawer,
        departmentControlData: state.departmentReducer.departmentControlData,
        departmentControlLoading: state.departmentReducer.departmentControlLoading,
        departments: state.organization.departments
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDepartments: (id) => { dispatch(getDepartments(id)) },
        departmentMoreAction: (data) => { dispatch(departmentMoreAction(data)) },
        setDepartmentControlDrawer: (props) => { dispatch(setDepartmentControlDrawer(props)) },
        resetDepartmentControl: () => { dispatch(resetDepartmentControl()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentActionDrawer);