import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Layout, Spin } from "antd";
import Content from "./Content";
import Header from "../features/header";
import NavMenu from "../features/nav-menu";
import Footer from "../features/footer";
import '../styles/header.scss';
import '../styles/nav-menu.scss';
import '../styles/global.scss';
import '../styles/footer.scss';

import { useLocation } from "react-router-dom";

const Main = (props) => {
  const { Sider } = Layout;
  const [collapsed, setCollapse] = useState(false);
  const [isBarVisible,setIsBarVisible] = useState(false);
  const [isBarPaymentFailVisible,setIsBarPaymentFailVisible] = useState(false);

  const user = useSelector( state => state?.auth?.user?.profile);
  const isChangingOrganization = useSelector( state =>  state?.changingOrganization?.isChangingOrganization)

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const trialExpireDate = new Date(user?.organizationEntryEndDate);
    const today = new Date();
    const diffDate = trialExpireDate - today;
    const remainingDay = Math.ceil(diffDate / 86400000);

    if(
        !['/prices','/payment','/paymentCompleted'].some(path => pathname?.includes(path)) &&
        user?.organizationPackageId == 0
    ){
        setIsBarVisible(true);
    }else{
        setIsBarVisible(false);
    }
    if(!['/prices','/payment','/paymentCompleted'].some(path => pathname?.includes(path)) &&
            parseInt(user?.organizationPackageId) > 0 &&
            remainingDay>-1 && remainingDay<16 && user?.organizationPaymentStatus == 2){
        setIsBarPaymentFailVisible(true);
    }else{
        setIsBarPaymentFailVisible(false);
    }
    }, [user])



  const toggleMenu = () => {
    setCollapse(!collapsed);
  };
  return (
    <Layout >
      <Header />
      <Layout hasSider>
        <Sider
          collapsed={collapsed}
          onCollapse={() => setCollapse(!collapsed)}
          breakpoint="lg"
          collapsedWidth={0}
          width={240}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'absolute',
            left: 0,
            top: 68,
            bottom: 0,
          }}
          className={(isBarVisible || isBarPaymentFailVisible) && 'sider-with-bar'}
        >
          <NavMenu toggleMenu={toggleMenu} collapsed={collapsed} />
        </Sider >
        <Layout style={{ marginLeft: collapsed ? 70 : 240 }} className={(isBarVisible || isBarPaymentFailVisible) && 'content-with-bar'}>
          <Content />
          <Footer />
        </Layout>
      </Layout>
      {isChangingOrganization && <div className="organization-changing"><Spin /></div> }
    </Layout>
  );
};
export default connect((state) => state)(Main);