import React, { useEffect, useState } from "react";
import { Modal, Table, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { randomNumberInRange } from '../../utils/commonFormFunctions';
import moment from 'moment';

const DynamicEducationModal = ({ allPersons, modalVisible = null, handleCancel = null, maskClosable = true }) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    allPersons.map((item, index) => {
      const startedDate = item.startDate
      const endDate = item.endDate
      const data = {
        name: item.personName,
        startedDate: startedDate,
        endDate: endDate,
        state: item.state,
        departmentName: item.departmentName,
        educationStartTime: item.educationStartTime,
        completedTime: item.completedTime
      }
      setTableData((prevArray) => [...prevArray, data])

    })
  }, [allPersons])

  const closeModal = () => {
    setTableData([])
    handleCancel()
  }

  const columns = [
    {
      title: t('PARTNER_NAME_SURNAME2'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('PARTNER_DEPARTMENT'),
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: t('EDUCATION_START_END_DATE'),
      dataIndex: 'educationStartTime',
      key: 'educationStartTime',
      render: (_, record) => (
        <span>
          {
            record.startedDate != null ? moment(record.startedDate).format('DD/MM/YYYY') : ' - '
          } - {
            record.endDate != null ? moment(record.endDate).format('DD/MM/YYYY') : ' - '
          }
        </span>
      ),
    },
    {
      title: t('PARTNER_STATUS'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {
            record.state == 32 &&
            <span>{t('ADDED')}</span>
          }
          {
            record.state == 16 &&
            <span>{t('EXPIRED')}</span>
          }
          {
            record.state == 4 &&
            <span>{t('COMPLETED')}</span>
          }
          {
            record.state == 2 &&
            <span>{t('PROGRESSING')}</span>
          }
          {
            record.state == 1 &&
            <span>{t('STARTED2')}</span>
          }

        </Space>
      ),
    },
    {
      title: t('EDUCATION_HISTORY_DATE'),
      dataIndex: 'educationStartTime',
      key: 'educationStartTime',
      render: (_, record) => (
        <span>
          {
            record.educationStartTime != null ? moment(record.educationStartTime).format('DD/MM/YYYY') : ' - '
          } - {
            record.completedTime != null ? moment(record.completedTime).format('DD/MM/YYYY') : ' - '
          }
        </span>
      ),
    },
  ];
  return (
    <Modal
      key={() => randomNumberInRange(1, 5000000)}
      visible={modalVisible}
      className="delete-confirm-modal"
      onCancel={closeModal}
      maskClosable={maskClosable ? true : false}
      width={1000}
      keyboard={true}
      closable={true}
      centered
      footer={[]}>
      <div>
        <div className="page-title"><h1>{t('PARTICIPANT_REPORT')}</h1></div>
        <Table columns={columns} dataSource={tableData} />
      </div>
    </Modal>)
}

export default DynamicEducationModal   