import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Col, Row, Button, Steps, List, Form } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import { setTimeOffAddDrawer } from '../../../redux/actions/time-off-drawer-action'
import { createTimeOffRequest, resetCreateTimeOffRequestResponse, getTimeOffRequestStep, resetWorkHourByDate, resetTimeOffRequestInfo } from '../../../redux/actions/timeOff.actions'
import { regex } from "../../../utils/commonFormFunctions";
import { dateFormat, dateFormatWithHours } from "../../../utils/config";

import CreateTimeOffForm from './time-off-form'
import Successful from "../../successful";

import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';



import moment from 'moment'


function CreateTimeOffRequestTabs(props) {
    const { Step } = Steps;
    const { t } = useTranslation();
    const [form] = Form.useForm()

    const { setTimeOffAddDrawer, createTimeOffRequest, resetCreateTimeOffRequestResponse, createTimeOffRequestResponse, typeData, returnShow, getTimeOffRequestStep, timeOffRequestStep, workHourStartByDate,
        workHourEndByDate, resetWorkHourByDate, resetTimeOffRequestInfo, timeOffInfoByType } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [createTimeOffRequestObj, setCreateTimeOffRequestObj] = useState({});
    const [componentKey, setcomponentKey] = useState(0)
    const [fileUploadList, setFileUploadList] = useState([]);

    const [isCreateLoading, setisCreateLoading] = useState(false);

    function closeTimeOffDrawerControl() {
        if (currentTab !== 2 && (createTimeOffRequestObj.StartDate || createTimeOffRequestObj.endDate || createTimeOffRequestObj.contactPhones || createTimeOffRequestObj.description || createTimeOffRequestObj.groupEmailAddr || createTimeOffRequestObj.personResourceId || createTimeOffRequestObj.timeOffTypeId)) {
            setConfirmModalData({ 'isVisible': true })
        } else {
            destroyLastState()
        }
    }
    function destroyLastState() {
        setCurrentTab(1);
        setTimeOffAddDrawer(false)
        setCreateTimeOffRequestObj({
            "timeOffTypeId": { "id": null, "displayValue": "" },
            "description": "",
            "StartDate": "",
            "StartDateChooseDayType": "",
            "StartDayType": "",
            "EndDate": "",
            "EndDateChooseDayType": "",
            "EndDayType": "",
            "groupEmailAddr": "",
            "contactPhones": [],
        });
        form.setFieldsValue({
            "timeOffTypeId": { "id": null, "displayValue": "" },
            "description": "",
            "StartDate": "",
            "StartDateChooseDayType": "",
            "StartDayType": "",
            "EndDate": "",
            "EndDateChooseDayType": "",
            "EndDayType": "",
            "groupEmailAddr": "",
            "contactPhones": [],
        });
        resetCreateTimeOffRequestResponse()
        resetTimeOffRequestInfo();
        resetWorkHourByDate('startEnd');
        resetWorkHourByDate('start');
        resetWorkHourByDate('end');
        setFileUploadList([]);
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateTimeOffRequestObj({});
        form.setFieldsValue({
            "timeOffTypeId": { "id": null, "displayValue": "" },
            "description": "",
            "StartDate": "",
            "StartDateChooseDayType": "",
            "StartDayType": "",
            "EndDate": "",
            "EndDateChooseDayType": "",
            "EndDayType": "",
            "groupEmailAddr": "",
            "contactPhones": [],
        })
        setcomponentKey((i) => i + 1)
        resetCreateTimeOffRequestResponse();
        resetTimeOffRequestInfo();
        setFileUploadList([]);
    }
    const setCreateObject = (obj) => {
        if (obj.StartDate) {
            const StartDate = moment(obj.StartDate).format(dateFormat);
            var startDateWithHours = moment(StartDate + ' ' + obj.StartDateTime).format(dateFormatWithHours);
        }
        if (obj.EndDate) {
            const EndDate = moment(obj.EndDate).format(dateFormat);
            var endDateWithHours = moment(EndDate + ' ' + obj.EndDateTime).format(dateFormatWithHours);
        }

        const createObj = {
            "personResourceId": obj.personResourceId?.id,
            "timeOffTypeId": obj.timeOffTypeId?.id,
            "StartDate": obj.StartDate ? obj.unitId === 3 ? startDateWithHours : moment(obj.StartDate).format(dateFormat) : '',
            "StartDateChooseDayType": obj.StartDateChooseDayType,
            "StartDayType": obj.StartDayType,
            "endDate": obj.EndDate ? obj.unitId === 3 ? endDateWithHours : moment(obj.EndDate).format(dateFormat) : '',
            "EndDateChooseDayType": obj.EndDateChooseDayType,
            "EndDayType": obj.EndDayType,
            "description": obj.description,
            "groupEmailAddr": obj.groupEmailAddr,
            "contactPhones": obj.contactPhones,
            "Documents": obj.Documents
        }
        setCreateTimeOffRequestObj(createObj)
    }

    const handleCreateTimeOffRequest = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        
        let hasError = false
        let fields = []
        if (!createTimeOffRequestObj.personResourceId) {
            hasError = true
            fields.push(t('TIMEOFF_SELECTED_PERSON'))
        }
        if (!createTimeOffRequestObj.timeOffTypeId) {
            hasError = true
            fields.push(t('TIME_OFF_REQUEST_REQUIRED_TYPE'))
        }
        if (!createTimeOffRequestObj.StartDate || createTimeOffRequestObj.StartDate === "") {
            hasError = true
            fields.push(t('TIME_OFF_REQUEST_START_TIME'))
        }
        if (!createTimeOffRequestObj.endDate || createTimeOffRequestObj.endDate === "") {
            hasError = true
            fields.push(t('TIME_OFF_REQUEST_END_TIME'))
        }
        if ((createTimeOffRequestObj.groupEmailAddr && regex.test(createTimeOffRequestObj.groupEmailAddr) === false)) {
            hasError = true
            fields.push(t('PLEASE_ENTER_VALID_MAIL_ADDRESS'))
        }
        
        if(timeOffInfoByType.isRequiredDocument && createTimeOffRequestObj.Documents?.length == 0){
            hasError = true
            fields.push(t('TIMEOFF_REQUIRED_DOC_UPLOAD_WARN'))
        }
        
        if(timeOffInfoByType.isRequiredDescription && !createTimeOffRequestObj.description){
            hasError = true
            fields.push(t('TIMEOFF_REQUIRED_DESCRIPTION_WARN'))
        }

        if(timeOffInfoByType.isRequiredContactInfo && (!createTimeOffRequestObj.groupEmailAddr && (!createTimeOffRequestObj.contactPhones || createTimeOffRequestObj.contactPhones?.length == 0))){
            hasError = true
            fields.push(t('TIMEOFF_CONTACT_INFO_OPT_REQUIRED_WARN'))
        }


        if (!hasError) {
            createTimeOffRequest(createTimeOffRequestObj)

            window.sendinblue?.track(
                //brevo_event_create_time_off_request
                'create_time_off_request',
            )

        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    useEffect(() => {
        if (createTimeOffRequestResponse.status === 'SUCCESS') {
            setCurrentTab(2);
            getTimeOffRequestStep(createTimeOffRequestResponse.data)
        }
        else if (createTimeOffRequestResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': <div>
                    {createTimeOffRequestResponse?.message && <div>{createTimeOffRequestResponse.message}</div>}
                    {createTimeOffRequestResponse?.validationMessages.length >0 && 
                            createTimeOffRequestResponse?.validationMessages.map((error) => <div>{error}</div>)
                    }
                </div>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateTimeOffRequestResponse()
        }
    }, [createTimeOffRequestResponse])
    useEffect(() => {
        if (workHourStartByDate.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': workHourStartByDate.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetWorkHourByDate('start')
        }
    }, [workHourStartByDate])
    useEffect(() => {
        if (workHourEndByDate.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': workHourEndByDate.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetWorkHourByDate('end')
        }
    }, [workHourEndByDate])
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })


    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={destroyLastState}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeTimeOffDrawerControl()} />
                </div>
                {currentTab !== 2 ?
                    <React.Fragment>
                        <Col offset={5} span={16}>
                            <div className="add-global-draw-title flex-start" style={{ "marginLeft": "24px" }}>
                                <h1>{t('TIME_OFF_REQUEST_TITLE')}</h1>
                            </div>
                        </Col>
                        <div className="w-100">
                            <Row>
                                <Col span={5}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('TIME_OFF_REQUEST_CREATE')} description="" />
                                        <Step title={t('PARTNER_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={19}>
                                    <CreateTimeOffForm
                                        form={form}
                                        key={componentKey}
                                        typeData={typeData}
                                        setCreateObject={(o) => setCreateObject(o)}
                                        fileUploadList={fileUploadList}
                                        setFileUploadList={setFileUploadList} />
                                </Col>
                            </Row>

                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Successful message={t('HEADER_CREATE_NEW_TIME_REQUEST_SUCCESS')} />
                        <Col span={22} offset={1}>
                            <div className="pad-55" />
                            {timeOffRequestStep.length > 0 &&
                                <Steps className="left-step horizontal-step" direction="horizontal" size="middle" current={timeOffRequestStep.filter(filterData => filterData.isSelected === true)[0]?.sortOrder - 1 || timeOffRequestStep.length}>
                                    {timeOffRequestStep.map((data) => {
                                        return (
                                            <Step key={data.sortOrder} title={data.stepName}
                                                description={

                                                    <ul className="sub-tab-hide-icon">
                                                        <li className={data.isSelected ? "sub-tab-active" : ''}>
                                                            {timeOffRequestStep.length > 0 ? data.name : ''} <br />
                                                            {timeOffRequestStep.length > 0 ? data?.date ? moment(data?.date).format('L') : '' : ''}
                                                        </li>
                                                    </ul>
                                                } />
                                        )
                                    })}
                                </Steps>
                            }
                        </Col>
                        <Col span={1} />
                    </React.Fragment>

                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab === 1 ?
                    <Button onClick={() => closeTimeOffDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('QUICK_ACTION_CANCEL')}</Button>
                    : ''}
                <div className="add-user-draw-footer-end">
                    <div className="pad-left-24" />
                    {currentTab === 2 ?
                        <div>
                            <Button onClick={() => createNewOne()} className="h-40 f-14 font-w-700 primary-btn margin-r-12">{t('HEADER_CREATE_NEW_TIME_REQUEST')}</Button>
                            {returnShow ?
                                <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                                :
                                <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('RETURN_HOMEPAGE')}</Button>
                            }
                        </div>
                        :
                        <Button onClick={() => handleCreateTimeOffRequest()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('HEADER_CREATE_TIME_REQUEST')}</Button>
                    }
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        timeOffReducer: state.timeOffReducer,
        timeOffInfoByTypeDate: state.timeOff.timeOffInfoByTypeDate,
        timeOffInfoByType: state.timeOff.timeOffInfoByType,
        timeOffRequestStep: state.timeOff.timeOffRequestStep.timeOffApproveList,
        createTimeOffRequestResponse: state.timeOff.createTimeOffRequestResponse,
        workHourStartByDate: state.timeOff.workHourStartByDate,
        workHourEndByDate: state.timeOff.workHourEndByDate,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTimeOffAddDrawer: (props) => { dispatch(setTimeOffAddDrawer(props)) },
        createTimeOffRequest: (props) => { dispatch(createTimeOffRequest(props)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        resetCreateTimeOffRequestResponse: () => { dispatch(resetCreateTimeOffRequestResponse()) },
        resetWorkHourByDate: (status) => { dispatch(resetWorkHourByDate(status)) },
        resetTimeOffRequestInfo: () => { dispatch(resetTimeOffRequestInfo()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTimeOffRequestTabs);
