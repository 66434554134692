var initialState = {
    ownerShipAddDrawer: false,
    ownerShipReturnDrawer:false,
    ownerShipDetailsDrawer:false,
  };
  

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
  
      case "SET_OWNERSHIP_ADD_DRAWER":
        const ownerShipAddDrawerState = {
          ...state,
          ownerShipAddDrawer: action.payload,
        };
        return ownerShipAddDrawerState;

        case "SET_OWNERSHIP_DETAILS_DRAWER":
          const ownerShipDetailsDrawerState = {
            ...state,
            ownerShipDetailsDrawer: action.payload,
          };
          return ownerShipDetailsDrawerState;
        case "SET_OWNERSHIP_RETURN_DRAWER":
          const ownerShipReturnDrawerState = {
            ...state,
            ownerShipReturnDrawer: action.payload,
          };
        return ownerShipReturnDrawerState;
     
      default:return state;
    }
  }
  