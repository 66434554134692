import { DocumentTypeActionType } from "../actions/document-type.action";

const initialState = {

    documentTypeList: {
        results: [],
        totalNumberOfRecords:0,
        pageNumber:0,
    },
    documentTypeListLoading: true,
    createDocumentTypeResponse: { "status": "loading", "message": "" },
    updateDocumentTypeResponse: { "status": "loading", "message": "" },
    deleteDocumentTypeResponse: { "status": "loading", "message": "" },
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
        case DocumentTypeActionType.DOCUMENT_TYPE_LIST_REQUEST:
            return {
                ...state,
                documentTypeListLoading: true
            };
        case DocumentTypeActionType.DOCUMENT_TYPE_LIST_SUCCESS:
            return {
                ...state,
                documentTypeList: {results:action.payload,totalNumberOfRecords:action.payload.length,pageNumber:1},
                documentTypeListLoading: false
            }
        case DocumentTypeActionType.DOCUMENT_TYPE_LIST_FAIL:
            return {
                ...state,
                documentTypeListLoading: false
            }
        case DocumentTypeActionType.CREATE_DOCUMENT_TYPE_SUCCESS:
            return {
                ...state,
                createDocumentTypeResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case DocumentTypeActionType.CREATE_DOCUMENT_TYPE_FAIL:
            return {
                ...state,
                createDocumentTypeResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        
        case DocumentTypeActionType.CREATE_DOCUMENT_TYPE_RESET:
            return {
                ...state,
                createDocumentTypeResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_SUCCESS:
            return {
                ...state,
                updateDocumentTypeResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_FAIL:
            return {
                ...state,
                updateDocumentTypeResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_INPUT_ERROR:
            return {
                ...state,
                updateDocumentTypeResponse: {
                    "status": "VALIDATION_ERROR",
                    "message": action.payload
                }
            }
        case DocumentTypeActionType.UPDATE_DOCUMENT_TYPE_RESET:
            return {
                ...state,
                updateDocumentTypeResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case DocumentTypeActionType.DELETE_DOCUMENT_TYPE_SUCCESS:
            return {
                ...state,
                deleteDocumentTypeResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case DocumentTypeActionType.DELETE_DOCUMENT_TYPE_FAIL:
            return {
                ...state,
                deleteDocumentTypeResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case DocumentTypeActionType.DELETE_DOCUMENT_TYPE_RESET:
            return {
                ...state,
                deleteDocumentTypeResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        default: return state
    }
}