
export const setOrganizationAddDrawer = (organizationaddDrawer) => async dispatch => {
    console.log(organizationaddDrawer);
    try {
        dispatch({
            type: 'SET_ORGANIZATION_ADD_DRAWER',
            payload: organizationaddDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};

export const setOrganizationDetailsDrawer = (organizationDetailsDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ORGANIZATION_DETAILS_DRAWER',
            payload: organizationDetailsDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};

export const setDepartmentAddDraw = (departmentAddDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_DEPARTMENT_ADD_DRAWER',
            payload: departmentAddDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};


export const setDepartmentDetailsDraw = (departmentDetailsDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_DEPARTMENT_DETAILS_DRAWER',
            payload: departmentDetailsDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};


export const setDepartmentConnectDraw = (departmentConnectDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_DEPARTMENT_CONNECT_DRAWER',
            payload: departmentConnectDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};
export const setDepartmentControlDrawer = (departmentControlDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_DEPARTMENT_CONTROL_DRAWER',
            payload: departmentControlDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};