import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getInventoryTypes } from "../../../redux/actions/inventoryType.action";
import { getInventoryAttributes, getBrandList, createInventoryBrand, resetCreateInventoryBrand } from "../../../redux/actions/inventory.actions";
import { getDataset } from '../../../redux/actions/dataset-action';
import { Form, Input, DatePicker, Radio, Row, Col, Divider, Button, List, Spin } from 'antd';
import { UilPlus } from '@iconscout/react-unicons';
import MaskedInput from "react-text-mask";
import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR';
import 'moment/locale/tr';

import { dateFormatList, dateFormat } from '../../../utils/config';
import { priceDecimalWithDot } from '../../../utils/commonFormFunctions';

import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import '../../../styles/person.scss';

function AddInventoryBasicInfo(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [addInventoryBrandForm] = Form.useForm();
    const { TextArea } = Input;
    const { setCreateObject, getInventoryTypes, inventoryTypes, getInventoryAttributes, createInventoryBrand, resetCreateInventoryBrand,
        getBrandList, inventoryBrand, requiredArray, getDataset, currencyTypes, inventoryBrandLoading, inventoryBrandCreateResponse } = props;
    const initialInventoryTypesSearchData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 999,
            "orderBy": "DisplayValue",
            "ascending": true
        }
    }
    const radioData = [
        { label: `${t('INVENTORY_TYPE_WORKS')}`, value: '1', disabled: false },
        { label: `${t('INVENTORY_TYPE_SCRAP')}`, value: '3', disabled: false },
        { label: `${t('INVENTORY_TYPE_DEFECT')}`, value: '2', disabled: false },
    ];
    const [inventoryAddBasicInfoState, setInventoryAddBasicInfoState] = useState();
    const [inventoryTypesSearchData, setInventoryTypesSearchData] = useState(initialInventoryTypesSearchData);
    const [inventoryTypeList, setInventoryTypeList] = useState([]);
    const [currencyList, setCurrencyList] = useState();
    const [brandList, setBrandList] = useState();
    const [requiredInput, setRequiredInput] = useState({
        'brand': true,
        'serialNumber': true
    });
    const [addInventoryBrandState, setAddInventoryBrandState] = useState({ 'isVisible': false, 'name': '' });

    useEffect(() => {
        getInventoryTypes(inventoryTypesSearchData)
    }, [inventoryTypesSearchData])
    useEffect(() => {
        setInventoryTypeList(inventoryTypes);
    }, [inventoryTypes]);
    useEffect(() => {
        setBrandList(inventoryBrand)
    }, [inventoryBrand])
    useEffect(() => {
        var list = currencyTypes?.length > 0 ?
            currencyTypes.filter((filterData) => filterData.id === '1' || filterData.id === '2' || filterData.id === '3').map((data) => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                }
            }) : [];
        setCurrencyList(list)
    }, [currencyTypes])
    const handleValuesChange = (changedObj, all) => {
        const changedItemLanguageKey = (inventoryTypes?.find(x => x.id == changedObj['inventoryType']?.id))?.languageKey
        const key = Object.keys(changedObj)[0]
        if (key === 'inventoryType') {
            getInventoryAttributes(changedItemLanguageKey)
            setInventoryAddBasicInfoState({ ...inventoryAddBasicInfoState, inventoryType: changedObj['inventoryType'], languageKey : changedItemLanguageKey , "brand": null });
            if (!requiredArray.includes(changedObj?.inventoryType?.id)) {
                if (changedObj?.inventoryType?.id === 6) { //CAR
                    setRequiredInput({ ...requiredInput, 'brand': true, 'serialNumber': false })
                } else {
                    setRequiredInput({ ...requiredInput, 'brand': true, 'serialNumber': true })
                }
            } else {
                setRequiredInput({ ...requiredInput, 'brand': false, 'serialNumber': false })
            }
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields };
            fieldsObj["brand"] = "";
            form.setFieldsValue(fieldsObj);
            setBrandList();
        } else if (key == 'deviceExpirationDate') {
            setInventoryAddBasicInfoState({ ...inventoryAddBasicInfoState, 'deviceExpirationDate': moment(changedObj.deviceExpirationDate).format(dateFormat) });
        } else {
            setInventoryAddBasicInfoState({ ...inventoryAddBasicInfoState, ...changedObj });
        }
    }
    useEffect(() => {
        setCreateObject(inventoryAddBasicInfoState)
    }, [inventoryAddBasicInfoState]);
    useEffect(() => {
        if (inventoryBrandCreateResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': inventoryBrandCreateResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getBrandList(
                {
                    "searchTerm": "",
                    "pagination": {
                        "pageNumber": 1,
                        "pageSize": 20,
                        "orderBy": "DisplayValue",
                        "ascending": true
                    }
                }, inventoryAddBasicInfoState?.inventoryType?.id
            );
            resetCreateInventoryBrand();
        } else if (inventoryBrandCreateResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': inventoryBrandCreateResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateInventoryBrand();
        }
    }, [inventoryBrandCreateResponse]);

    const addInventoryBrand = () => {
        setAddInventoryBrandState({ addInventoryBrandState, 'isVisible': true })
    }
    const handleAddInventoryModalValuesChange = (changedObj) => {
        setAddInventoryBrandState({ ...addInventoryBrandState, 'name': changedObj.name });
    }
    const handleAddCancel = () => {
        setAddInventoryBrandState({ "isVisible": false, 'name': '' });
        const fields = addInventoryBrandForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        addInventoryBrandForm.setFieldsValue(projects)
    }
    const handleAddConfirm = () => {
        let hasError = false
        let fields = []
        if (!addInventoryBrandState.name) {
            hasError = true
            fields.push(t('PARTNER_TYPE_DOCUMENTS'))
        }

        if (!hasError) {
            const data = {
                "BrandName": addInventoryBrandState.name,
                "InventoryTypeId": inventoryAddBasicInfoState?.languageKey
            }
            createInventoryBrand(data)
            setAddInventoryBrandState({ "isVisible": false, 'name': '' });
            const fields = addInventoryBrandForm.getFieldsValue();
            const projects = { ...fields };
            projects["name"] = "";
            addInventoryBrandForm.setFieldsValue(projects);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const addModalForm = (
        <div className="table-details pad-0">
            <Form
                form={addInventoryBrandForm}
                onValuesChange={handleAddInventoryModalValuesChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('BRAND_NAME')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
    return (
        <>
            <DynamicRequestModal
                modalVisible={addInventoryBrandState.isVisible}
                setStateData={setAddInventoryBrandState}
                isForm={addModalForm}
                handleConfirm={handleAddConfirm}
                handleCancel={handleAddCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('ADD_BRAND')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('SETTINGS_CREATE')}
            />
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
            >
                <Form.Item className="custom-select" name="inventoryType" label={t('INVENTORY_TYPE') + ' *'}>
                    <DynamicSelectbox
                        optionList={inventoryTypeList}
                        onFocus={() => getInventoryTypes(inventoryTypesSearchData)}
                    />
                </Form.Item>
                <Form.Item className="custom-select" name="brand" label={requiredInput.brand ? t('INVENTORY_BRAND') + ' *' : t('INVENTORY_BRAND')}>
                    <DynamicSelectbox
                        placeholder={t('SELECT_DOCUMENT_TYPE')}
                        optionList={brandList}
                        defaultSpin={false}
                        onFocus={() => getBrandList(
                            {
                                "searchTerm": "",
                                "pagination": {
                                    "pageNumber": 1,
                                    "pageSize": 20,
                                    "orderBy": "DisplayValue",
                                    "ascending": true
                                }
                            }, inventoryAddBasicInfoState?.languageKey
                        )
                        }
                        orderBy={'none'}
                        disabled={!inventoryAddBasicInfoState?.languageKey}
                        dynamiCreateBtnStatus={true}
                        dynamicCreateBtnCode={
                            !inventoryBrandLoading ?
                                <>
                                    <Divider
                                        className='my-1'
                                    />
                                    <div
                                        className='w-100 px-2'
                                    >
                                        <Button className="primary-btn w-100 flex-class svg-class" icon={<UilPlus />} onClick={() => addInventoryBrand()}>
                                            {t('ADD_BRAND')}
                                        </Button>
                                    </div>
                                </>
                                : <Spin className="w-100 d-flex justify-center align-center pt-2" />
                        }
                    />
                </Form.Item>

                <Form.Item name="serialNumber" className="custom-select" label={requiredInput.serialNumber ? t('INVENTORY_SERIAL_NUMBER') + ' *' : t('INVENTORY_SERIAL_NUMBER')}>
                    <Input />
                </Form.Item>
                <Form.Item name="name" className="custom-select" label={t('PARTNER_DEVICE_NAME') + ' *'}>
                    <Input />
                </Form.Item>
                <Form.Item name="status" label={t('INVENTORY_STATU') + ' *'} className="margin-b-12">
                    <Radio.Group options={radioData} />
                </Form.Item>
                <Form.Item name="model" label={t('INVENTORY_MODEL')}>
                    <Input />
                </Form.Item>

                <Form.Item name="barcodeNumber" className="custom-select" label={t('PARTNER_BARCODE_NUMBER')}>
                    <Input />
                </Form.Item>
                <Row gutter={[24, 0]}>
                    <Col span={15}>
                        <Form.Item name="amount" label={t('PARTNER_AMOUNT2')}>
                            <MaskedInput
                                className="ant-input"
                                placeholder={t('PARTNER_AMOUNT2')}
                                mask={priceDecimalWithDot}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name="currency" className="custom-select" label={t('PARTNER_CURRENCY')}>
                            <DynamicSelectbox style={{ marginTop: '3px' }}
                                optionList={currencyList}
                                onFocus={() => getDataset(23)}
                                placeholder={t('PARTNER_SELECT')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="description" label={t('PARTNER_RETURN_DESCRIPTION')}>
                    <TextArea />
                </Form.Item>
                <Form.Item name="deviceExpirationDate" label={t('INVENTORY_DEVICE_EXPIRATION_DATE')}>
                    <DatePicker
                        locale={TR}
                        format={dateFormatList}
                        placeholder={t('PARTNER_SELECT_DATE')}
                        inputReadOnly={true}
                    />
                </Form.Item>
            </Form>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        inventoryTypes: state.inventoryType.inventoryTypes.results,
        inventoryBrand: state.inventory.inventoryBrand?.results,
        inventoryBrandLoading: state.inventory.inventoryBrandLoading,
        currencyTypes: state.dataset.currencyTypes,
        inventoryBrandCreateResponse: state.inventory.inventoryBrandCreateResponse,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInventoryTypes: (props) => { dispatch(getInventoryTypes(props)) },
        getInventoryAttributes: (props) => { dispatch(getInventoryAttributes(props)) },
        getBrandList: (searchData, id) => { dispatch(getBrandList(searchData, id)) },
        getDataset: (type) => { dispatch(getDataset(type)) },
        createInventoryBrand: (data) => { dispatch(createInventoryBrand(data)) },
        resetCreateInventoryBrand: () => { dispatch(resetCreateInventoryBrand()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryBasicInfo);