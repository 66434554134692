import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getProcessesDetail, deleteApprovalProcesses, resetDeleteApprovalProcesses, getAllProcess,
    updateApprovalProcessesStatus, resetUpdateApprovalProcessesStatus
} from '../../../redux/actions/approval-processes.actions';
import { Button, Collapse, ConfigProvider, Steps, Row, Col, Switch, Dropdown, Spin, Popover } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { UilAngleUp, UilAngleDown, UilUser, UilUserCheck, UilCheckCircle } from '@iconscout/react-unicons'
import { approvalProcessesListDropdown } from '../../../features/global-dropdown-menu/index';
import DynamicToastMessage from '../../../component/dynamicToastMessage/index';
import DynamicRequestModal from '../../../component/dynamicModal/requestModal';
import '../../../styles/approval-processes.scss';

import { setCurrentTaskState, setUserTaskInfo } from "../../../redux/actions/onboarding.actions";
import PopoverContent from '../../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../../component/onBoarding/OnboardingBackdropHook';
function ApprovalProcessesList(props) {
    const { createApprovalProcessesDraw, detailApprovalProcessesDraw, getProcessesDetail, approvalProcessList,
        approvalProcessListLoading, deleteApprovalProcesses, resetDeleteApprovalProcesses, deleteApprovalProcessResponse,
        getAllProcess, updateApprovalProcessesTypeResponse, updateApprovalProcessesStatus, resetUpdateApprovalProcessesStatus,
        approvalIsOpenDrawer, setApprovalIsOpenDrawer, setSelectApprovalProcess } = props;
    const { t } = useTranslation();
    const { Panel } = Collapse;
    const { Step } = Steps;
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false });
    const [listState, setListState] = useState([]);
    const canDelete = (id, name) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'isVisible': true });
    }
    const getApprovalIcon = (currentIndex, length) => {
        if (currentIndex === 0) {
            return <UilUser />
        } else if (currentIndex !== 0 && currentIndex !== length) {
            return <UilUserCheck />
        } else {
            return <UilCheckCircle />
        }
    }
    const statusOnChange = (status, resourceId, index) => {
        var newList = Object.assign(listState);
        newList[index].isActive = status ? 1 : 0;
        setListState(newList);
        if (status === false) {
            updateApprovalProcessesStatus(resourceId, 0, index);
        } else {
            updateApprovalProcessesStatus(resourceId, 1, index);
        }
    }
    const openDetailDraw = (resourceId) => {
        setSelectApprovalProcess(resourceId);
        detailApprovalProcessesDraw();
        getProcessesDetail(resourceId);
        setApprovalIsOpenDrawer(true);
    }
    useEffect(() => {
        if (approvalIsOpenDrawer === false) {
            if (deleteApprovalProcessResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'DELETE_APPROVAL_SUCCESS',
                    'title': t('SUCCESS'),
                    'description': deleteApprovalProcessResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                getAllProcess();
                resetDeleteApprovalProcesses();
            } else if (deleteApprovalProcessResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'DELETE_APPROVAL_FAIL',
                    'title': t('ERROR'),
                    'description': deleteApprovalProcessResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                resetDeleteApprovalProcesses();
            }
        }
    }, [deleteApprovalProcessResponse])
    useEffect(() => {
        if (updateApprovalProcessesTypeResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'UPDATE_APPROVAL_TYPE_SUCCESS',
                'title': t('SUCCESS'),
                'description': updateApprovalProcessesTypeResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            //   getAllProcess();
            resetUpdateApprovalProcessesStatus();
        } else if (updateApprovalProcessesTypeResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'UPDATE_APPROVAL_TYPE_FAIL',
                'title': t('ERROR'),
                'description': updateApprovalProcessesTypeResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            var newList = Object.assign(listState);
            newList[updateApprovalProcessesTypeResponse.selectIndex].isActive = true;
            setListState(newList);
            resetUpdateApprovalProcessesStatus();
        }
    }, [updateApprovalProcessesTypeResponse])
    useEffect(() => {
        if (!approvalProcessListLoading) {
            setListState(approvalProcessList);
        }
    }, [approvalProcessListLoading])


            // #region OnBoarding
            const [popoverVisible, setPopoverVisible] = useState(false);
            const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
            const handlePopoverVisibleChange = (visible) => {
                if(visible == false){ props.setCurrentTaskState(null); }
                setPopoverVisible(visible); 
            };
            
            OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'approvalProcessesList' );

            useEffect(() => {
                if(props.currentTab == 'approvalProcesses' && props.currentTaskState == 51 && approvalProcessListLoading){
                    setPopoverVisible(true)
                }
                if(props.currentTaskState == 52 && listState.length > 0){
                    openDetailDraw(listState[0].resourceId);
                    setPopoverVisible(false)
                }
            }, [approvalProcessListLoading,props.currentTab, props.currentTaskState])

            
    
            // #endregion


    return (
        <>
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteApprovalProcesses}
                confirmData={deleteModalData?.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_APPROVAL_PROCESSES_DESCRIPTION', { 'name': deleteModalData?.name })}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <div className="approval-processes-list-container approval-custom-collapse approval-custom-steps">
                    <div className="pb-3">
                        <Button className="w-180 primary-btn" onClick={() => createApprovalProcessesDraw()} icon={<PlusOutlined />}>{t('CREATE_NEW_PROCESSES')}</Button>
                    </div>
                    {approvalProcessListLoading ? <Spin className="tab-loading" /> :

                        listState.map((data, index) => {
                            const personCount = data.processAssign?.filter(filterData => filterData.entityType?.id === "1").length;
                            const departmentCount = data.processAssign?.filter(filterData => filterData.entityType?.id === "2").length;
                            var processStep = [...data.processStep];
                            processStep.push(
                                {
                                    "resourceId": "-",
                                    "seqNumber": processStep.length + 1,
                                    "processPersonType": {
                                        "id": "0",
                                        "displayValue": t("APPROVAL"),
                                        "sortOrder": 0
                                    },
                                    "anotherUser": null
                                }
                            );
                            return (
                                <Collapse
                                    key={"approval" + index}
                                    defaultActiveKey={index + '_collapse'}
                                    expandIconPosition="right"
                                    collapsible="icon"
                                    className="my-3"
                                    expandIcon={({ isActive }) => isActive ? <div className="collapse-icon"><UilAngleUp size="40" /></div> : <div className="collapse-icon"><UilAngleDown size="40" /></div>}
                                >
                                    <Panel header={(
                                        <span className="approval-processes-list-title">
                                            {data?.processName} {data?.isDefault && ` (${t('SETTINGS_DEFAULT_PROCESS')})`}
                                        </span>
                                    )} key={index + '_collapse'} >
                                        <div className="approval-processes-list-body mt-6" >

                                            <Row>
                                                <Col span={data?.isDefault ? 22 : 20}>
                                                    {data.processStep.length > 0 &&
                                                        <Steps
                                                            labelPlacement='vertical'
                                                            current={null}
                                                        >
                                                            {processStep.map((processListItem, index) => {
                                                                return <Step
                                                                    key={processListItem.resourceId}
                                                                    title={processListItem.anotherUser ? processListItem.anotherUser?.givenName + " " + processListItem.anotherUser?.familyName : processListItem.processPersonType?.displayValue}
                                                                    icon={<div className="processes-icon">{getApprovalIcon(index, data.processStep.length)}</div>}
                                                                />
                                                            }
                                                            )}
                                                            {data.processAssign.length > 0 &&
                                                                <Step
                                                                    className="result-step"
                                                                    key={'result'}
                                                                    title={<div className="w-100 d-flex flex-wrap processes-detail flex-start">
                                                                        {departmentCount > 0 && <label>{departmentCount} Departman</label>}
                                                                        {personCount > 0 && <label>{personCount} Çalışan</label>}
                                                                        <label>{data?.processType?.displayValue}</label>
                                                                    </div>}
                                                                    icon={<div className="d-none">
                                                                    </div>}

                                                                />
                                                            }
                                                        </Steps>
                                                    }
                                                </Col>
                                                {!data?.isDefault &&
                                                    <Col offset={1} span={1}>
                                                        <div className="mt-2 d-flex flex-wrap flex-center">
                                                            <Switch onChange={(e) => statusOnChange(e, data?.resourceId, index)} checked={data.isActive} />
                                                            {data.isActive ?
                                                                <div className="green toggle-text">{t('DEPARTMENT_ACTIVE')}</div>
                                                                :
                                                                <div className="red toggle-text">{t('DEPARTMENT_PASSIVE')}</div>
                                                            }
                                                        </div>
                                                    </Col>
                                                }
                                                <Col offset={1} span={1}>
                                                    {popoverVisible && index == 0 ?  
                                                    <Popover
                                                    key={'approval-process-tab'}
                                                    overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : 'onboard-nav-popover-wrapper'}
                                                    content={<PopoverContent key={props.currentTaskState} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                                    title={false}
                                                    placement="top"
                                                    trigger="click"
                                                    visible={popoverVisible}
                                                    onVisibleChange={handlePopoverVisibleChange}
                                                    >
                                                    <Dropdown visible={true} className="action-menu" overlay={approvalProcessesListDropdown(t, canDelete, data.resourceId, data.processName, data?.isDefault, openDetailDraw, true)} trigger={['click']}>
                                                        <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                    </Dropdown>
                                                    </Popover>
                                                    : 
                                                    <Dropdown className="action-menu" overlay={approvalProcessesListDropdown(t, canDelete, data.resourceId, data.processName, data?.isDefault, openDetailDraw)} trigger={['click']}>
                                                        <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                    </Dropdown>
                                                    }
                                                    
                                                </Col>
                                            </Row>
                                        </div>
                                    </Panel>
                                </Collapse>
                            )
                        })}
                </div>
            </ConfigProvider>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        approvalProcessList: state.approvalProcesses.approvalProcessList,
        approvalProcessListLoading: state.approvalProcesses.approvalProcessListLoading,
        deleteApprovalProcessResponse: state.approvalProcesses.deleteApprovalProcessResponse,
        updateApprovalProcessesTypeResponse: state.approvalProcesses.updateApprovalProcessesTypeResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProcessesDetail: (resourceId) => { dispatch(getProcessesDetail(resourceId)) },
        deleteApprovalProcesses: (resourceId) => { dispatch(deleteApprovalProcesses(resourceId)) },
        resetDeleteApprovalProcesses: () => { dispatch(resetDeleteApprovalProcesses()) },
        getAllProcess: () => { dispatch(getAllProcess()) },
        updateApprovalProcessesStatus: (resourceId, status, index) => { dispatch(updateApprovalProcessesStatus(resourceId, status, index)) },
        resetUpdateApprovalProcessesStatus: () => { dispatch(resetUpdateApprovalProcessesStatus()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApprovalProcessesList);