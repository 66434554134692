import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    setCreateDefinedTaskDrawer, setDetailDefinedTaskDrawer, getAssignedTasksList,
    deleteDefinedTasks, resetDeleteDefinedTasks, getMyTasksList, setCreateTaskDrawer,
    getTaskAssignedList, setMyTaskDetailDrawer, setAssignedTaskDetailDrawer, deleteAssignedTasks
} from '../../redux/actions/task.actions';
import {
    BrowserRouter as Router,
    useLocation,
    useHistory
} from "react-router-dom";

import { Drawer, Tabs, Layout } from 'antd';
import { ToastContainer } from 'react-toastify';
import { drawerWidth } from '../../utils/config';
import DynamicToastMessage from '../../component/dynamicToastMessage';

import BreadCrumb from '../../features/breadcrump/breadcrump';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';

import MyTasks from '../../component/taskTabs/myTasks';
import AssignedTasks from '../../component/taskTabs/assignedTasks';
import DefinedTasks from '../../component/taskTabs/definedTasks';

import DetailDefinedTasks from '../../component/taskTabs/definedTasks/detail';
import MyTasksDetailAllTabs from '../../component/taskTabs/myTasks/detail/my_task_detail_all_tabs';
import CreateDefinedTasks from '../../component/taskTabs/definedTasks/add/index';
import CreateTaskTabs from '../../component/taskTabs/assignedTasks/add/create_task_all_tabs';
import DetailAssignedTaskTabs from '../../component/taskTabs/assignedTasks/details/detail_task_all_tabs';
import Forbidden from "../../component/forbidden";

function Tasks(props) {
    const { definedTaskCreateDrawerStatus, getAssignedTasksList, definedTaskDetailDrawerStatus, setCreateDefinedTaskDrawer,
        setDetailDefinedTaskDrawer, createAssignedTasksResponse, updateAssignedTasksResponse, deleteDefinedTasksResponse,
        deleteDefinedTasks, taskCreateDrawerStatus, setCreateTaskDrawer, setMyTaskDetailDrawer, myTaskDetailDrawerStatus,
        assignedTaskDetailDrawer, setAssignedTaskDetailDrawer, resetDeleteDefinedTasks, permissions, deleteAssignedTasks,
    } = props;
    // const getMyTaskInitialSearchData = {
    //     "page": 1,
    //     "pageLength": 10,
    //     "search": "",
    //     "personResourceId": "",
    //     "taskState": ""
    // }
    const getMyTaskInitialSearchData =
    {
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "ascending": true,
            "orderName": "personName"
        },
        "search": "",
        "personResourceId": [],
        "taskGroupState": []
    }

    const taskAssignedInitialSearchData = {
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "ascending": true,
            "orderName": "personName"
        },
        "search": "",
        "personResourceId": [],
        "taskGroupState": [],
        "assignedPersonId": [],
        "appointeeId": []
    }
    const history = useHistory();
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const location = useLocation();

    const [currentTab, setCurrentTab] = useState(location.pathname.split('/tasks/')[1]);
    const [deleteDefinedTasksModalData, setDeleteDefinedTasksModalData] = useState({ 'isVisible': false });
    const [deleteAssignedTaskModalData, setDeleteAssignedTaskModalData] = useState({ 'isVisible': false });
    const [myTaskSearchState, setMyTaskSearchState] = useState(getMyTaskInitialSearchData);
    const [assignedTaskRequestState, setAssignedTaskRequestState] = useState(taskAssignedInitialSearchData);
    const [definedTaskCreateDrawerKey, setDefinedTaskCreateDrawerKey] = useState(1);
    const [definedTaskDetailDrawerKey, setDefinedTaskDetailDrawerKey] = useState(1000);
    const [taskCreateDrawerKey, setTaskCreateDrawerKey] = useState(2000);
    const [myTaskDetailDrawerKey, setMyTaskDetailDrawerKey] = useState(3000);
    const [assignedTaskDetailDrawerKey, setAssignedTaskDetailDrawerKey] = useState(4000);
    const openCreateDefinedTaskDrawer = () => {
        setCreateDefinedTaskDrawer(true)
        setDefinedTaskCreateDrawerKey((i) => i + 1)
    }
    const openDetailDefinedTaskDrawer = () => {
        setDetailDefinedTaskDrawer(true);
        setDefinedTaskDetailDrawerKey((i) => i + 1)
    }
    const openCreateTaskDrawer = () => {
        setCreateTaskDrawer(true)
        setTaskCreateDrawerKey((i) => i + 1)
    }
    const openMyTaskDetailDrawer = () => {
        setMyTaskDetailDrawer(true)
        setMyTaskDetailDrawerKey((i) => i + 1)
    }
    const openAssignedTaskDetailDrawer = () => {
        setAssignedTaskDetailDrawer(true)
        setAssignedTaskDetailDrawerKey((i) => i + 1)
    }
    function callback(key) {
        setCurrentTab(key);
    }
    useEffect(() => {
        setCurrentTab(location.pathname.split('/tasks/')[1]);
    }, [location]);
    useEffect(() => {
        if (currentTab === 'description') {
            getAssignedTasksList();
        }
    }, [currentTab])
    useEffect(() => {
        if (currentTab === "description" && createAssignedTasksResponse.status === "SUCCESS") {
            getAssignedTasksList();
        }
    }, [createAssignedTasksResponse, currentTab]);
    useEffect(() => {
        if (currentTab === "description" && updateAssignedTasksResponse.status === "SUCCESS") {
            getAssignedTasksList();
        }
    }, [updateAssignedTasksResponse, currentTab]);
    useEffect(() => {
        if (currentTab === "description" && deleteDefinedTasksResponse.status === "SUCCESS") {
            DynamicToastMessage({
                'key': 'delete_success_key',
                'title': t('SUCCESS'),
                'description': deleteDefinedTasksResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            resetDeleteDefinedTasks();
            getAssignedTasksList();
        } else if (deleteDefinedTasksResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'delete_error_key',
                'title': t('ERROR'),
                'description': deleteDefinedTasksResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetDeleteDefinedTasks();
        }
    }, [deleteDefinedTasksResponse])


    return (
        permissions.some(item => ["task.list", "task.create", "task.update", "task.delete", "task.assignedlist"].includes(item)) ? 
        <>
            <Layout>
                <DynamicRequestModal
                    key={deleteDefinedTasksModalData?.id+'-DELETE_DEFINED_TASK_MODAL'}
                    modalVisible={deleteDefinedTasksModalData?.isVisible}
                    setStateData={setDeleteDefinedTasksModalData}
                    postAction={deleteDefinedTasks}
                    confirmData={deleteDefinedTasksModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_DEFINED_TASK_MODAL', { 'name': deleteDefinedTasksModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <DynamicRequestModal
                    key={deleteAssignedTaskModalData.groupId+'-PARTNER_DELETE'}
                    modalVisible={deleteAssignedTaskModalData?.isVisible}
                    setStateData={setDeleteAssignedTaskModalData}
                    postAction={deleteAssignedTasks}
                    confirmData={{
                        groupId: deleteAssignedTaskModalData.groupId,
                        personResourceId: deleteAssignedTaskModalData.personResourceId,
                        ownerResourceId: deleteAssignedTaskModalData.ownerResourceId,
                        appointResourceId: deleteAssignedTaskModalData.appointResourceId,
                        ownerShipTypeId: deleteAssignedTaskModalData.ownerShipTypeId
                    }}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_ASSIGNED_TASK')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <Content className="page-container">
                    <BreadCrumb />
                    <div className="tab-menu ">
                        <h1 className="page-title">{t('TASK_ASSIGNMENT_PROCEDURES')}</h1>
                        <Tabs
                            className="pad-top-20 page-tabs"
                            onChange={key => {
                                callback(key);
                                history.push(`/tasks/${key}`);
                            }}
                            defaultActiveKey={location.pathname.split('/tasks/')[1]}
                            activeKey={currentTab}
                        >
                            {
                                permissions.includes('task.list') &&
                                <TabPane tab={<span>{t('DEFINED_TASKS')}</span>} key="description">
                                    <DefinedTasks
                                        openCreateDefinedTaskDrawer={openCreateDefinedTaskDrawer}
                                        openDetailDefinedTaskDrawer={openDetailDefinedTaskDrawer}
                                        deleteDefinedTasksModalData={deleteDefinedTasksModalData}
                                        setDeleteDefinedTasksModalData={setDeleteDefinedTasksModalData}
                                    />
                                </TabPane>
                            }
                            <TabPane tab={<span>{t('MY_TASKS')}</span>} key="me">
                                <MyTasks
                                    getMyTaskInitialSearchData={getMyTaskInitialSearchData}
                                    myTaskSearchState={myTaskSearchState}
                                    setMyTaskSearchState={setMyTaskSearchState}
                                    openMyTaskDetailDrawer={openMyTaskDetailDrawer}
                                />
                            </TabPane>
                            {
                                permissions.includes('task.assignedlist') &&
                                <TabPane tab={<span>{t('ASSIGNED_TASKS')}</span>} key="assigned">
                                    <AssignedTasks
                                        taskAssignedInitialSearchData={taskAssignedInitialSearchData}
                                        openCreateTaskDrawer={openCreateTaskDrawer}
                                        openAssignedTaskDetailDrawer={openAssignedTaskDetailDrawer}
                                        assignedTaskRequestState={assignedTaskRequestState}
                                        setAssignedTaskRequestState={setAssignedTaskRequestState}
                                        deleteAssignedTaskModalData={deleteAssignedTaskModalData}
                                        setDeleteAssignedTaskModalData={setDeleteAssignedTaskModalData}
                                    />
                                </TabPane>
                            }
                        </Tabs>
                    </div>
                </Content>
            </Layout>
            <Drawer key={definedTaskCreateDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={definedTaskCreateDrawerStatus} >
                <CreateDefinedTasks />
            </Drawer >
            <Drawer key={definedTaskDetailDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={definedTaskDetailDrawerStatus} >
                <DetailDefinedTasks />
            </Drawer>
            <Drawer key={myTaskDetailDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={myTaskDetailDrawerStatus} >
                <MyTasksDetailAllTabs
                    myTaskSearchState={myTaskSearchState}
                    setMyTaskSearchState={setMyTaskSearchState}
                />
            </Drawer>
            <Drawer key={taskCreateDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={taskCreateDrawerStatus} >
                <CreateTaskTabs
                    assignedTaskRequestState={assignedTaskRequestState}
                />
            </Drawer>
            <Drawer key={assignedTaskDetailDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={assignedTaskDetailDrawer} >
                <DetailAssignedTaskTabs
                    setAssignedTaskRequestState={setAssignedTaskRequestState}
                    assignedTaskRequestState={assignedTaskRequestState}
                />
            </Drawer>
            <ToastContainer />
        </> : <Forbidden size="sm" />
    )
}
const mapStateToProps = (state) => {
    return {
        definedTaskCreateDrawerStatus: state.taskReducer.definedTaskCreateDrawerStatus,
        definedTaskDetailDrawerStatus: state.taskReducer.definedTaskDetailDrawerStatus,
        myTaskDetailDrawerStatus: state.taskReducer.myTaskDetailDrawerStatus,
        taskCreateDrawerStatus: state.taskReducer.taskCreateDrawerStatus,
        assignedTaskDetailDrawer: state.taskReducer.assignedTaskDetailDrawer,
        createAssignedTasksResponse: state.taskReducer.createAssignedTasksResponse,
        updateAssignedTasksResponse: state.taskReducer.updateAssignedTasksResponse,
        deleteDefinedTasksResponse: state.taskReducer.deleteDefinedTasksResponse,
        permissions: state.profile.permissions
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCreateDefinedTaskDrawer: (status) => { dispatch(setCreateDefinedTaskDrawer(status)) },
        setDetailDefinedTaskDrawer: (status) => { dispatch(setDetailDefinedTaskDrawer(status)) },
        setCreateTaskDrawer: (status) => { dispatch(setCreateTaskDrawer(status)) },
        setMyTaskDetailDrawer: (status) => { dispatch(setMyTaskDetailDrawer(status)) },
        setAssignedTaskDetailDrawer: (status) => { dispatch(setAssignedTaskDetailDrawer(status)) },
        deleteDefinedTasks: (resourceId) => { dispatch(deleteDefinedTasks(resourceId)) },
        getAssignedTasksList: () => { dispatch(getAssignedTasksList()) },
        resetDeleteDefinedTasks: () => { dispatch(resetDeleteDefinedTasks()) },
        getMyTasksList: (searchData) => { dispatch(getMyTasksList(searchData)) },
        getTaskAssignedList: (searchData) => { dispatch(getTaskAssignedList(searchData)) },
        deleteAssignedTasks: (data) => { dispatch(deleteAssignedTasks(data)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);