import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTravelRequestCreateDrawer, createTravel, resetCreateTravelResponse } from '../../../redux/actions/travel.actions';

import { Col, Row, Button, Steps, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import moment from 'moment'


import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import '../../../styles/table.scss';
import Successful from "../../successful";

import CreateTravelRequestsTab from './create_travel_requests_tab';
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import { dateFormat, dateFormatWithHours, dynamicDateInputFormatter } from "../../../utils/config";

function CreateTravelRequestTabs(props) {

    const { createTravel, setTravelRequestCreateDrawer, createTravelRequests, resetCreateTravelResponse, page } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [createTravelObj, setCreateTravelObj] = useState({
        'estimatedAmount': [],
    });
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false });
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const { Step } = Steps;
    const { t } = useTranslation();

    useEffect(() => {
        if (createTravelRequests.status === 'SUCCESS') {
            setCurrentTab(2);
            resetCreateTravelResponse()
        }
        else if (createTravelRequests.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': createTravelRequests.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateTravelResponse()
        }
    }, [createTravelRequests])


    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function closeDrawerControl() {
        if (currentTab !== 2 && (createTravelObj?.travelDescription || createTravelObj?.estimatedAmount?.length > 0)) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal();
            setCurrentTab(1);
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setTravelRequestCreateDrawer(false);
        setCreateTravelObj({
            'estimatedAmount': [],
        });
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateTravelObj({
            'estimatedAmount': [],
        });
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createTravelObj, obj)
        setCreateTravelObj(mergedObj)

    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []

        if (!createTravelObj.departureDatePicker || createTravelObj.departureDatePicker === "" || createTravelObj.departureDatePicker === null) {
            hasError = true
            fields.push(t('DEPARTURE_DATE'))
        }
        if (!createTravelObj.returnDatePicker || createTravelObj.returnDatePicker === "" || createTravelObj.returnDatePicker === null) {
            hasError = true
            fields.push(t('DATE_OF_RETURN'))
        }

        if (!createTravelObj.country?.id) {
            hasError = true
            fields.push(t('ORGANIZATION_COUNTRY'))
        }
        if (!createTravelObj.province) {
            hasError = true;
            fields.push(t('ORGANIZATION_PROVINCE'));
        }
        //
        if (createTravelObj.estimatedAmount.length > 0) {
            createTravelObj.estimatedAmount.map((data, index) => {
                if ((!data.Amount || data.Amount === "")) {
                    hasError = true;
                    fields.push(t('ADVANCE_AMOUNT'))
                }
                if (!data.expenseTypeIdx) {
                    hasError = true;
                    fields.push(t('ESTIMATED_BUDGET'))
                }
            })
        } else {
            hasError = true
            fields.push(t('ESTIMATED_BUDGET'))
        }
        if (!hasError) {

            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleCreateTravelRequests = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        var travelDescription = null

        if (createTravelObj?.country?.displayValue && createTravelObj?.province && createTravelObj?.departureDatePicker) {
            travelDescription = moment(createTravelObj?.departureDatePicker).format(dynamicDateInputFormatter) + " " + createTravelObj?.country?.displayValue + "-" + createTravelObj?.province
        }


        if (createTravelObj.returnDatePicker) {
            const returnDatePicker = moment(createTravelObj.returnDatePicker).format(dateFormat);
            var returnDateWithHours = moment(returnDatePicker + ' ' + '00:00:00').format(dateFormatWithHours);
        }
        if (createTravelObj.departureDatePicker) {
            const departureDatePicker = moment(createTravelObj.departureDatePicker).format(dateFormat);
            var departureDateWithHours = moment(departureDatePicker + ' ' + '00:00:00').format(dateFormatWithHours);
        }
        const data = {
            'travelDescription': travelDescription,
            'departureDate': departureDateWithHours,
            'returnDate': returnDateWithHours,
            'CityName': createTravelObj?.province,
            'CountryIdx': createTravelObj?.country?.id,
            'CountryName': createTravelObj?.country?.displayValue,
            'estimatedAmount': createTravelObj?.estimatedAmount,
            'estimatedTotal': createTravelObj?.estimatedTotal,
            'notes': createTravelObj?.notes ? createTravelObj.notes : null,
            'traveledPersons': createTravelObj?.traveledPersons?.length > 0 ? createTravelObj?.traveledPersons : []
        }

        checkRequiredFields(() => createTravel(data))
    }

    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {currentTab !== 2 ?
                    <React.Fragment>
                        <Col offset={4} span={16}>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('CREATE_NEW_TRAVEL_REQUEST')}</h1>
                            </div>
                        </Col>
                        <div className="w-100 ">
                            <Row>
                                <Col span={4}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('HOMEPAGE_CREATE_REQUEST')} description="" />
                                        <Step title={t('DEPARTMENT_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={20}>
                                    <CreateTravelRequestsTab setCreateObject={setCreateObject} />
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                    :
                    <Successful message={t('TRAVEL_REQUEST_SUCCESS')} />
                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 2 ?
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                {/* clear state */}
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 2 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 2 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-200 h-40 f-14 font-w-700 white-btn">{t('CREATE_NEW_TRAVEL_REQUEST')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 2 ?
                        currentTab === 1 &&
                        <Button onClick={() => handleCreateTravelRequests()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('CREATE_NEW_TRAVEL_REQUEST')}</Button>
                        :
                        <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{page ? t('RETURN_HOMEPAGE') : t('INVENTORY_RETURN_LIST')}</Button>
                        //clear state
                    }
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        createTravelRequests: state.travel.createTravelRequests
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTravelRequestCreateDrawer: (status) => { dispatch(setTravelRequestCreateDrawer(status)) },
        createTravel: (data) => { dispatch(createTravel(data)) },
        resetCreateTravelResponse: () => { dispatch(resetCreateTravelResponse()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTravelRequestTabs);
