import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setApprovalProcessesCreateDrawer, createApprovalProcesses, resetCreateApprovalProcesses, getAllProcess } from '../../../../redux/actions/approval-processes.actions'; // Drawer Statusler

import { Col, Row, Button, Steps, List, Tabs } from 'antd';
import { UilMultiply, UilArrowLeft, UilUser } from '@iconscout/react-unicons'

import CreateApprovalProcessesBasicStepForm from './createApprovalProcessesBasicStepForm';
import CreateApprovalProcessesUnitsStepForm from './createApprovalProcessesUnitsStepForm';
import Successful from "../../../successful";

import DynamicRequestModal from '../../../dynamicModal/requestModal';
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/left-step.scss';

function CreateApprovalProcessesTabList(props) {
    const { setApprovalProcessesCreateDrawer, createApprovalProcessesResponse, createApprovalProcesses, resetCreateApprovalProcesses, getAllProcess } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const defaultData = {
        'IsDefault': false,
        'ProcessName': "",
        'ProcessType': null,
        'ProcessStep': [],
        'ProcessAssign': [],
        'approvalListState': []
    }
    const [createApprovalProcessesObject, setCreateApprovalProcessesObject] = useState(defaultData);
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false });
    const [errorIdListState, setErrorIdListState] = useState([]);
    const { Step } = Steps;
    const { TabPane } = Tabs;
    const { t } = useTranslation();
    function callback(key) {
        checkRequiredFields(() => setCurrentTab(parseInt(key)));
    }
    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function nextButton() {
        checkRequiredFields(() => setCurrentTab(currentTab + 1));
    }
    function closeDrawerControl() {
        if (currentTab !== 3 && (createApprovalProcessesObject?.ProcessName || createApprovalProcessesObject?.ProcessType || createApprovalProcessesObject?.approvalListState.length > 1 || createApprovalProcessesObject?.ProcessAssign.length > 1)) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal()
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setApprovalProcessesCreateDrawer(false);
        setCreateApprovalProcessesObject(defaultData);
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateApprovalProcessesObject(defaultData);
    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false;
        let fields = [];
        if (!createApprovalProcessesObject.ProcessName || createApprovalProcessesObject.ProcessName === "") {
            hasError = true;
            fields.push(t('NAME_YOUR_PROCESS'));
        }
        if (!createApprovalProcessesObject.ProcessType || createApprovalProcessesObject.ProcessType === "") {
            hasError = true;
            fields.push(t('PROCESSES_TYPE'));
        }
        if (createApprovalProcessesObject.approvalListState.length === 0) {
            hasError = true;
            fields.push(t('PROCESSES_APPROVE_PROCESSES'));
        }
        if (!hasError) {
            nextFunc();
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        }
    }

    const [isCreateLoading, setisCreateLoading] = useState(false);

    const handleCreateApprovalProcesses = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        const ProcessStep = createApprovalProcessesObject.approvalListState.map((data, index) => {
            return {
                'SeqNumber': index + 1,
                'ProcessPersonType': data.id,
                'AnotherUserResourceId': data?.secondApproval?.id ? data?.secondApproval?.id : ""
            }
        })
        const data = {
            'ProcessName': createApprovalProcessesObject.ProcessName,
            'IsDefault': createApprovalProcessesObject?.IsDefault ? createApprovalProcessesObject?.IsDefault : false,
            'IsActive': true,
            'ProcessType': createApprovalProcessesObject.ProcessType,
            'AddedProcessStep': ProcessStep,
            'ProcessAssign': !createApprovalProcessesObject?.IsDefault ? createApprovalProcessesObject.ProcessAssign : []
        }
        if ((!createApprovalProcessesObject.IsDefault && createApprovalProcessesObject.ProcessAssign) && createApprovalProcessesObject.ProcessAssign.length === 0) {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': t('CHOOSE_PARTNER_APPLIED_DEPARTMENT_OR_APPLIED_PERSON'),
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        } else {
            checkRequiredFields(() =>
                createApprovalProcesses(data), true);
                window.sendinblue?.track(
                    //brevo_event_create_approval_process
                    'create_approval_process',
                )
        }
    }
    useEffect(() => {
        if (createApprovalProcessesResponse.status === 'SUCCESS') {
            setCurrentTab(3);
            getAllProcess();
            setErrorIdListState([]);
            resetCreateApprovalProcesses();
        } else if (createApprovalProcessesResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'approval_processes_create_fail_notification',
                'title': t('ERROR'),
                'description': createApprovalProcessesResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateApprovalProcesses();
        } else if (createApprovalProcessesResponse.status === 'VALIDATION_ERROR') {
            setErrorIdListState(createApprovalProcessesResponse?.errorIds);
            DynamicToastMessage({
                'key': 'approval_processes_create_fail_notification',
                'title': t('ERROR'),
                'description': createApprovalProcessesResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateApprovalProcesses();
        }
    }, [createApprovalProcessesResponse])
    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW_PROCESSES')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="add-user-close-icon">
                <UilMultiply onClick={() => closeDrawerControl()} />
            </div>
            {currentTab !== 3 ?
                <div className="table-details">
                    <div className="d-flex w-100">
                        <div onClick={() => closeDrawerControl()} className="add-user-draw-back-text">
                            <UilArrowLeft /><span>{t('BACK_TO_SETTINGS')}</span>
                        </div>
                    </div>
                    <div className="w-100">
                        <Row>
                            <Col span={6}>
                                <div className="pt-12" />
                                <Steps className="left-step create-processes-step pr-4" direction="vertical" size="middle" current={currentTab - 1}>
                                    <Step title={t('PARTNER_BASIC_INFORMATION')} description={
                                        <div className="sub-tab w-100 d-flex flex-wrap">
                                            {createApprovalProcessesObject.approvalListState && createApprovalProcessesObject.approvalListState.map((data, index) => {
                                                if (data.id) {
                                                    return <div key={index} className={currentTab === 1 ? 'create-processes-step-item' : 'create-processes-step-item create-processes-step-item-disabled'}><UilUser /><span>{data.id === 6 ? data.secondApproval.displayValue : data.displayValue}</span></div>
                                                }
                                            })}
                                        </div>
                                    } />
                                    <Step title={t('PARTNER_UNITS_WHICH')} description="" />
                                    <Step title={t('PARTNER_COMPLETE')} description="" />
                                </Steps>
                            </Col>
                            <Col span={17}>
                                <div className="add-user-draw-title pb-4">
                                    <h1 className="mb-0">{t('PROCESSES_NEW')}</h1>
                                </div>
                                <Tabs className="custom-tab add-processes-draw-scrollable-tab"
                                    onChange={callback}
                                    activeKey={currentTab.toString()}
                                    defaultActiveKey={currentTab.toString()}
                                >
                                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key={1}>
                                        <CreateApprovalProcessesBasicStepForm setCreateApprovalProcessesObject={(o) => setCreateApprovalProcessesObject(o)} createApprovalProcessesObject={createApprovalProcessesObject} />
                                    </TabPane>
                                    <TabPane tab={t('PARTNER_UNITS_WHICH')} key={2}>
                                        <CreateApprovalProcessesUnitsStepForm setCreateApprovalProcessesObject={(o) => setCreateApprovalProcessesObject(o)} createApprovalProcessesObject={createApprovalProcessesObject} errorIdListState={errorIdListState} />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                </div>
                :
                <Successful message={t('ADD_NEW_PROCESSES_SUCCESS')} />
            }
            <div className="add-user-draw-footer space-between pr-4">
                {currentTab > 1 && currentTab < 3 ?
                    <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                    :
                    ''
                }
                {currentTab === 3 &&
                    <Button onClick={() => createNewOne()} className="pad-0 w-210 h-40 f-14 font-w-700 white-btn">{t('PROCESSES_CRATE_A_NEW')}</Button>
                }
                <div className="pad-left-24" />
                {currentTab < 3 ?
                    currentTab === 2 ?
                        <Button onClick={() => handleCreateApprovalProcesses()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_COMPLETE')}</Button>
                        :
                        <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_NEXT')}</Button>
                    :
                    <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('RETURN_HOMEPAGE')}</Button>
                }
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        createApprovalProcessesResponse: state.approvalProcesses.createApprovalProcessesResponse,
        profile: state.profile
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setApprovalProcessesCreateDrawer: (status) => { dispatch(setApprovalProcessesCreateDrawer(status)) },
        createApprovalProcesses: (data) => { dispatch(createApprovalProcesses(data)) },
        resetCreateApprovalProcesses: () => { dispatch(resetCreateApprovalProcesses()) },
        getAllProcess: () => { dispatch(getAllProcess()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateApprovalProcessesTabList);