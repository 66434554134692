import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDataset } from "../../../redux/actions/dataset-action";
import { getCountry, getProvinces } from '../../../redux/actions/address.actions.js';
import { Row, Col, Form, DatePicker, TimePicker, ConfigProvider, Input, Button, List } from 'antd';

import MaskedInput from 'react-text-mask';
import moment from 'moment';

import { dynamicDateInputFormatter, localeLang, dateFormatList, dateFormat, dateTimeFormat } from "../../../utils/config";
import { numberMaskWithSeperator, focusDateInput, blurDateInput } from '../../../utils/commonFormFunctions'

import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';


import '../../../styles/person.scss';
import '../../../styles/travel.scss';


function CreateTravelRequestsTab(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [makeTravelNameState, setMakeTravelNameState] = useState(false)
    const [createTravelRequest, setCreateTravelRequest] = useState({
    });
    const [travelAmountList, setTravelAmountList] = useState([
        {
            'expenseGroup': null,
            'amount': null
        }
    ]);
    const [travelAmounTotal, setTravelAmounTotal] = useState(0);
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "departureDatePicker": dateFormatList,
        "returnDatePicker": dateFormatList
    })

    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [expenseGroupList, setExpenseGroupList] = useState([]);
    const [personAddList, setPersonAddList] = useState([]);
    const [generateTitleState, setGenerateTitleState] = useState("");
    const { setCreateObject, getDataset, getCountry, getProvinces, countries, provinces, expenseTypes } = props;

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === 'departureDatePicker') {
            if (changedObj.departureDatePicker) {
                setCreateTravelRequest({ ...createTravelRequest, 'departureDatePicker': moment(changedObj.departureDatePicker).format(dateFormat) });
            } else {
                setCreateTravelRequest({ ...createTravelRequest, 'departureDatePicker': null });

            }
        } else if (key === 'returnDatePicker') {
            if (changedObj.returnDatePicker) {
                setCreateTravelRequest({ ...createTravelRequest, 'returnDatePicker': moment(changedObj.returnDatePicker).format(dateFormat) });
            } else {
                setCreateTravelRequest({ ...createTravelRequest, 'returnDatePicker': null });
            }
        } else if (key === 'country') {
            setCreateTravelRequest({ ...createTravelRequest, ...changedObj });

        } else if (key === 'province') {
            setCreateTravelRequest({ ...createTravelRequest, ...changedObj });

        } else {
            setCreateTravelRequest({ ...createTravelRequest, ...changedObj });
        }
    }

    useEffect(() => {
        setCreateObject(createTravelRequest);
    }, [createTravelRequest]);
    useEffect(() => {
        setCreateObject({ ...createTravelRequest, 'travelDescription': generateTitleState })
    }, [generateTitleState])
    useEffect(() => {
        setCountryList(countries);
    }, [countries]);
    useEffect(() => {
        setProvinceList(provinces);
    }, [provinces]);
    useEffect(() => {
        setExpenseGroupList(
            expenseTypes.map((data) => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                }
            })
        );
    }, [expenseTypes])
    useEffect(() => {
        getDataset(28);
    }, [])

    const handleRemoveClick = index => {
        const list = [...travelAmountList];
        list.splice(index, 1);
        setTravelAmountList(list);

    };

    const handleExpenseGroupList = (e, index, key, inputType) => {
        const list = [...travelAmountList];
        switch (inputType) {
            case 'select':
                list[index][key] = { 'id': e.id, 'displayValue': e.displayValue };

                return setTravelAmountList(list);

            case 'price':
                const val = e.target.value;
                var replaceVal = val.replaceAll('.', '');
                list[index][key] = replaceVal;

                return setTravelAmountList(list);
            default:
                return null;
        }
    }
    function handleAddNewExpense() {
        const newRowList = [...travelAmountList];
        let requiredFields = {
            'expenseGroup': false,
            'amount': false,
        }
        let hasError = false
        let fields = []

        newRowList.map((data) => {
            if (!data.expenseGroup) {
                requiredFields.expenseGroup = true;
            }
            if ((!data.amount || data.amount === "0")) {
                requiredFields.amount = true;
            }
        });
        if (requiredFields.expenseGroup) {
            hasError = true
            fields.push(t('ESTIMATED_BUDGET'));
        }
        if (requiredFields.amount) {
            hasError = true;
            fields.push(t('PARTNER_AMOUNT2'));
        }
        newRowList.push(
            {
                'expenseGroup': null,
                'amount': null
            }
        );
        if (!hasError) {
            setTravelAmountList(newRowList);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }

    }

    const handleAddPersonListInputChange = (e, index, key, inputType) => {
        const list = [...personAddList];
        switch (inputType) {
            case 'text':
                list[index][key] = e.target.value;
                return setPersonAddList(list);
            default:
                return null;
        }
    }
    useEffect(() => {
        var totalAmount = 0;
        let travelObj = travelAmountList.map((data) => {
            totalAmount += parseFloat(data?.amount);
            return {
                'Amount': data?.amount,
                'expenseTypeIdx': data?.expenseGroup?.id && parseInt(data?.expenseGroup?.id)
            }
        })
        setCreateTravelRequest({ ...createTravelRequest, estimatedAmount: travelObj })
        setTravelAmounTotal(totalAmount);
        var selectData = travelAmountList.map((data) => { return data.expenseGroup?.id });
        const findSelectData = expenseGroupList.filter(filterData => selectData.includes(filterData.id));
        const findSelectDataID = findSelectData.map((data) => data.id);
        var newExpenseGroupData = expenseGroupList.map((data) => {
            return {
                'id': data.id,
                'displayValue': data.displayValue,
                'disabled': findSelectDataID.includes(data.id)
            }
        });
        setExpenseGroupList(newExpenseGroupData)
    }, [travelAmountList])

    useEffect(() => {
        setCreateTravelRequest({ ...createTravelRequest, 'estimatedTotal': travelAmounTotal })
    }, [travelAmounTotal])

    useEffect(() => {
        setCreateTravelRequest({ ...createTravelRequest, traveledPersons: personAddList })
    }, [personAddList])

    const handleAddPersonList = (index) => {
        const list = [...personAddList];
        list.splice(index, 1);
        setPersonAddList(list);
    };
    const handleAddPersonRow = () => {

        var requiredFields = {
            "name": false,
            "companyName": false
        }
        let hasError = false
        let fields = []
        var list = [];

        list = [...personAddList];


        list.map((data) => {
            if (!data.name) {
                requiredFields.name = true;
            }
            if (!data.companyName) {
                requiredFields.companyName = true;
            }
        });
        if (requiredFields.name) {
            hasError = true
            fields.push(t('PARTNER_NAME_SURNAME'));
        }
        if (requiredFields.companyName) {
            hasError = true;
            fields.push(t('ORGANIZATION_COMPANY_NAME'));
        }

        if (!hasError) {

            list.push({
                "name": null,
                "companyName": null
            })
            requiredFields = {
                "name": false,
                "companyName": false
            }
            setPersonAddList(list);


        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };

    const disabledEndDate = (current) => {
        return current < moment(createTravelRequest?.departureDatePicker)
    }

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical travel-scrollable travel-container"
                requiredMark={true}
            >
                <Row>
                    <Col span={12}>
                        <div className="travel-drawer-left">

                            <Form.Item name="departureDatePicker" label={t('DEPARTURE_DATE') + " *"}>
                                <DatePicker
                                    locale={localeLang}
                                    format={datePickerFormatType.departureDatePicker}
                                    placeholder={t('PLACEHOLDER_DATE')}
                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'departureDatePicker', dynamicDateInputFormatter)}
                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'departureDatePicker', dateFormatList)}
                                />
                            </Form.Item>

                            <Form.Item name="returnDatePicker" label={t('DATE_OF_RETURN') + " *"}>
                                <DatePicker
                                    locale={localeLang}
                                    format={datePickerFormatType.returnDatePicker}
                                    placeholder={t('PLACEHOLDER_DATE')}
                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'returnDatePicker', dynamicDateInputFormatter)}
                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'returnDatePicker', dateFormatList)}
                                    disabledDate={disabledEndDate}
                                    disabled={!createTravelRequest?.departureDatePicker}
                                />
                            </Form.Item>

                            <Form.Item name="country" className="custom-select" label={t('ORGANIZATION_COUNTRY') + " *"}>
                                <DynamicSelectbox
                                    optionList={countryList}
                                    onFocus={() => getCountry()}
                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                ></DynamicSelectbox>
                            </Form.Item>
                            <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                <Input disabled={!createTravelRequest.country?.id}  ></Input>
                            </Form.Item>
                        </div>

                    </Col>
                    <Col span={12}>
                        <div className="travel-drawer-right">
                            <>
                                <Row>
                                    <Col className="pad-12" span={24}>
                                        <Row>
                                            <Col span={8} >
                                                <h3 className="black f-16 fw-600">{t('ESTIMATED_BUDGET') + ' *'}</h3>
                                            </Col>

                                        </Row>
                                        {
                                            travelAmountList.map((item, index) => {
                                                return (
                                                    <Row key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": '30px' }} >
                                                        <Col span={10} >
                                                            <div className="custom-select">
                                                                <DynamicSelectbox className="w-100" style={{ marginTop: '3px' }}
                                                                    value={item.expenseGroup}
                                                                    optionList={expenseGroupList}
                                                                    onChange={(e) => handleExpenseGroupList(e, index, 'expenseGroup', 'select')}
                                                                    placeholder={t('PARTNER_SELECT')}
                                                                />
                                                            </div>


                                                        </Col>
                                                        <Col span={10} offset={1}>
                                                            <MaskedInput
                                                                className="ant-input"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                mask={numberMaskWithSeperator}
                                                                value={item.amount}
                                                                onChange={(e) => handleExpenseGroupList(e, index, 'amount', 'price')}
                                                            />
                                                        </Col>
                                                        {index > 0 &&
                                                            <Col offset={1} span={2}>
                                                                <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                )
                                            })
                                        }
                                        <div className="w-100 ">
                                            <Row>
                                                <Col span={11}>
                                                    <a
                                                        className="transparent-btn f-16 pad-0"
                                                        onClick={handleAddNewExpense}
                                                        style={{
                                                            marginBottom: 16,
                                                        }}
                                                    >
                                                        {t('INVENTORY_ADD_TEXT')}
                                                    </a>
                                                </Col>
                                                <Col span={10}>
                                                    <div className="black f-16 fw-600"> {travelAmounTotal > 0 && parseFloat(travelAmounTotal).toLocaleString('tr-TR') + ' TL'}</div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>

                                </Row>
                            </>
                            <br />
                            <Form.Item name="notes" className="custom-select" label={t('NOTES')}>
                                <Input.TextArea />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className="pad-top-40 expense-drawer">
                    <div className="table-container  table-hide-all-buttons blue-table">
                        <div className="w-100 flex-end">
                            <Button
                                onClick={() => handleAddPersonRow()}
                                className="primary-btn"
                                style={{
                                    marginBottom: 5,
                                }}
                            >
                                {t('INVENTORY_ADD_TEXT')}
                            </Button>
                        </div>
                        <div className="person-add-list">
                            <div className="row">
                                <label className="name m-r-10">  {t('PARTNER_NAME_SURNAME')} </label>
                                <label className="companyName m-r-10">  {t('PARTNER_COMPANY_NAME')} </label>
                            </div>
                            {
                                personAddList.map((item, index) => {

                                    return (
                                        <div className="editable" key={index} >
                                            <Input
                                                className="name m-r-10"
                                                value={item.name}
                                                onChange={(e) => handleAddPersonListInputChange(e, index, 'name', 'text')}
                                            />
                                            <Input className="companyName m-r-10"
                                                value={item.companyName}
                                                onChange={(e) => handleAddPersonListInputChange(e, index, 'companyName', 'text')}
                                            />

                                            <label className="delete" onClick={() => handleAddPersonList(index)}>
                                                {t('PARTNER_DELETE')}
                                            </label>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>
                </div>
            </Form >

        </ConfigProvider >
    )
}

const mapStateToProps = (state) => {
    return {
        countries: state.addressReducer.countries,
        provinces: state.addressReducer.provinces,
        expenseTypes: state.dataset.expenseTypes
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (type) => { dispatch(getDataset(type)) },
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTravelRequestsTab);