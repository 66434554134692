import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getDataset } from '../../../redux/actions/dataset-action'
import { getTagList } from '../../../redux/actions/tag.actions';
import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir
import { dateFormat, dateFormatList, maskInputFormatterFormLabel, dateInputMask, localeLang, dynamicDateInputFormatter } from '../../../utils/config';
import { inputMaskDateValidation, inputMaskShowFormatter, focusDateInput, blurDateInput, disabledDateWithToday } from '../../../utils/commonFormFunctions'
import { UilExclamationTriangle } from '@iconscout/react-unicons'



import { Form, Input, DatePicker, ConfigProvider } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import InputMask from "react-input-mask";

import '../../../styles/person.scss';


function AddUserDetailsJobInformation(props) {
    const { t } = useTranslation();
    const [createPersonState, setCreatePersonState] = useState({});
    const [form] = Form.useForm()
    const { setCreateObject, getDataset } = props;
    const { workTypes, workStates, workStatuses, remoteWorkTypes, profile, tagList, getTagList } = props;

    const [workTypeList, setWorkTypeList] = useState([]);
    const [workStateList, setWorkStateList] = useState([]);
    const [workStatusList, setWorkStatusList] = useState([]);
    const [remoteWorkTypeList, setRemoteWorkTypeList] = useState([]);

    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "startDate": dateFormatList,
        "contractEndDate": dateFormatList,
        "RemoteWorkFinishDate": dateFormatList
    })
    const [customDateInputPlaceHolder, setCustomDateInputPlaceHolder] = useState({
        "careerStartDate": false
    })
    const [customDateInputErrorStatus, setCustomDateInputErrorStatus] = useState({
        "careerStartDate": false
    })
    const [tagListState, setTagListState] = useState([]);


    const handleValuesChange = (changedObj, all) => {

        const key = Object.keys(changedObj)[0]
        if (key === 'startDate') {
            const startObj = { ...createPersonState.job, 'StartDate': moment(changedObj['startDate']).format(dateFormat) }
            setCreatePersonState({ ...createPersonState, job: startObj });
        } else if (key === 'contractEndDate') {
            const startObj = { ...createPersonState.job, 'ContractEndDate': moment(changedObj['contractEndDate']).format(dateFormat) }
            setCreatePersonState({ ...createPersonState, job: startObj });
        } else if (key === 'careerStartDate') {
            setCreatePersonState({ ...createPersonState, careerStartDate: changedObj['careerStartDate'] });
        } else if (key === 'RemoteWorkFinishDate') {
            const remoteWorkFinishDateObj = { ...createPersonState.job, 'RemoteWorkFinishDate': moment(changedObj['RemoteWorkFinishDate']).format(dateFormat) }
            setCreatePersonState({ ...createPersonState, job: remoteWorkFinishDateObj })
        } else if (key === 'workType') {
            const jobObj = { ...createPersonState.job, 'WorkTypeId': changedObj['workType']["id"] }
            setCreatePersonState({ ...createPersonState, job: jobObj });
        } else if (key === 'workState') {
            const jobObj = { ...createPersonState.job, 'WorkStateId': changedObj['workState']["id"] }
            setCreatePersonState({ ...createPersonState, job: jobObj });
        } else if (key === 'workStatus') {

            if (changedObj.workStatus?.id === '2') {
                const jobObj = { ...createPersonState.job, 'WorkStatusId': changedObj['workStatus']["id"], "OutsourcedOrganizationName": '' }
                setCreatePersonState({ ...createPersonState, job: jobObj });
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["OutsourcedOrganizationName"] = '';
                form.setFieldsValue(fieldsObj)
            } else if (changedObj.workStatus?.id === '3') {
                const jobObj = { ...createPersonState.job, 'WorkStatusId': changedObj['workStatus']["id"], "ContractEndDate": null }
                setCreatePersonState({ ...createPersonState, job: jobObj });
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["contractEndDate"] = '';
                form.setFieldsValue(fieldsObj)
            } else {
                const jobObj = { ...createPersonState.job, 'WorkStatusId': changedObj['workStatus']["id"] }
                setCreatePersonState({ ...createPersonState, job: jobObj });
            }

        } else if (key === 'RemoteWorkType') {

            if (changedObj.RemoteWorkType?.id === '1') {
                const remoteObj = { ...createPersonState.job, 'RemoteWorkType': changedObj['RemoteWorkType']["id"] }
                setCreatePersonState({ ...createPersonState, job: remoteObj });

            } else {
                const remoteObj = { ...createPersonState.job, 'RemoteWorkType': changedObj['RemoteWorkType']["id"], 'RemoteWorkFinishDate': null }
                setCreatePersonState({ ...createPersonState, job: remoteObj });
                if (createPersonState?.job?.RemoteWorkFinishDate !== null || createPersonState?.job?.RemoteWorkFinishDate !== undefined || createPersonState?.job?.RemoteWorkFinishDate !== "") {
                    const fields = form.getFieldsValue()
                    const fieldsObj = { ...fields };
                    fieldsObj["RemoteWorkFinishDate"] = '';
                    form.setFieldsValue(fieldsObj)
                }
            }
        } else if (key === 'OutsourcedOrganizationName') {
            const jobObj = { ...createPersonState.job, 'OutsourcedOrganizationName': changedObj['OutsourcedOrganizationName'] }
            setCreatePersonState({ ...createPersonState, job: jobObj });
        } else if (key === 'Tags') {
            const TagsArray = changedObj["Tags"].map((item) => item.id)
            setCreatePersonState({ ...createPersonState, "Tags": TagsArray });
        } else {
            setCreatePersonState({ ...createPersonState, ...changedObj });
        }

    }


    useEffect(() => {
        setWorkTypeList(workTypes);
    }, [workTypes]);

    useEffect(() => {
        setWorkStateList(workStates);
    }, [workStates]);

    useEffect(() => {
        setWorkStatusList(workStatuses);
    }, [workStatuses]);

    useEffect(() => {
        setRemoteWorkTypeList(remoteWorkTypes);
    }, [remoteWorkTypes]);

    useEffect(() => {
        setCreateObject(createPersonState)
    }, [createPersonState]);
    useEffect(() => {
        const tempArr = tagList.map((data) => {
            return {
                'id': data.id,
                'displayValue': data.name
            }
        })
        setTagListState(tempArr);
    }, [tagList]);

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
                requiredMark={false}
            >
                <Form.Item name="careerStartDate" label={customDateInputPlaceHolder.careerStartDate ? t('PARTNER_CAREER_START_DATE') + " " + maskInputFormatterFormLabel : t('PARTNER_CAREER_START_DATE')} className={customDateInputErrorStatus.careerStartDate && "table-details-error-form-class"}>
                    <InputMask
                        className="ant-input"
                        mask={dateInputMask}
                        onFocus={() => inputMaskShowFormatter('careerStartDate', customDateInputPlaceHolder, setCustomDateInputPlaceHolder)}
                        onBlur={() => inputMaskDateValidation('careerStartDate', customDateInputPlaceHolder, setCustomDateInputPlaceHolder, customDateInputErrorStatus, setCustomDateInputErrorStatus, null, createPersonState.careerStartDate, maskInputFormatterFormLabel)}
                        placeholder={t('PLACEHOLDER_DATE')}
                    />
                </Form.Item>
                {customDateInputErrorStatus.careerStartDate &&
                    <div className="table-details-error-text">
                        <UilExclamationTriangle /> <span>{t('INVALID_DATE')}</span>
                    </div>
                }
                <Form.Item name="workType" className="custom-select" label={t('PARTNER_WORK_TYPE')}>
                    <DynamicSelectbox optionList={workTypeList} onFocus={() => getDataset(17)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="workState" className="custom-select" label={t('PARTNER_WORK_STATE')}>
                    <DynamicSelectbox optionList={workStateList} onFocus={() => getDataset(18)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="workStatus" className="custom-select" label={t('PARTNER_WORK_STATUS')}>
                    <DynamicSelectbox optionList={workStatusList} onFocus={() => getDataset(16)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                {createPersonState.job?.WorkStatusId === '2' ?
                    <Form.Item name="contractEndDate" label={datePickerFormatType.contractEndDate === dynamicDateInputFormatter ? t('CONTRACT_END_DATE') + " " + t('PLACEHOLDER_DATE') : t('CONTRACT_END_DATE')}>
                        <DatePicker
                            locale={localeLang}
                            format={datePickerFormatType.contractEndDate}
                            placeholder={t('PLACEHOLDER_DATE')}
                            onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'contractEndDate', dynamicDateInputFormatter)}
                            onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'contractEndDate', dateFormatList)}
                        />
                    </Form.Item> : ''}
                {createPersonState.job?.WorkStatusId === '3' ?
                    <Form.Item name="OutsourcedOrganizationName" label={t('PARTNER_OUTSOURCED')}>
                        <Input />
                    </Form.Item>
                    : ''}
                <Form.Item name="RemoteWorkType" className="custom-select" label={t('PARTNER_REMOTE_WORK_TYPE')}>
                    <DynamicSelectbox optionList={remoteWorkTypeList} onFocus={() => getDataset(10)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                {createPersonState.job?.RemoteWorkType === '1' ?
                    <Form.Item name="RemoteWorkFinishDate" label={datePickerFormatType.contractEndDate === dynamicDateInputFormatter ? t('PARTNER_REMOTE_WORK_END') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_REMOTE_WORK_END')}>
                        <DatePicker
                            locale={localeLang}
                            format={datePickerFormatType.RemoteWorkFinishDate}
                            placeholder={t('PLACEHOLDER_DATE')}
                            onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'RemoteWorkFinishDate', dynamicDateInputFormatter)}
                            onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'RemoteWorkFinishDate', dateFormatList)}
                        />
                    </Form.Item> : ''}
                <Form.Item name="Tags" className="custom-select" label={t('PARTNER_TAGS')}>
                    <DynamicSelectbox
                        isMultiple={true}
                        optionList={tagListState} onFocus={() => getTagList({
                            "searchTerm": "",
                            "pagination": {
                                "pageNumber": 1,
                                "pageSize": 10,
                                "orderBy": "Name",
                                "ascending": true
                            }
                        })}
                        orderBy={'none'}
                        placeholder={t('PARTNER_SELECT')}
                    ></DynamicSelectbox>
                </Form.Item>

            </Form>
        </ConfigProvider>
    )


}



const mapStateToProps = (state) => {
    return {
        workTypes: state.dataset.workTypes,
        workStates: state.dataset.workStates,
        workStatuses: state.dataset.workStatuses,
        remoteWorkTypes: state.dataset.remoteWorkTypes,
        profile: state.profile,
        tagList: state.tag.tagList.results,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getTagList: (searchData) => { dispatch(getTagList(searchData)) }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserDetailsJobInformation);