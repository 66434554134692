import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { getEmployeesRoleList } from '../../redux/actions/settings-page.action';

import { Button, Dropdown } from 'antd';
import Media from 'react-media';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import { useWindowDimensions } from '../../utils/config';

function EmployeeesRoleTab(props) {
    const { t } = useTranslation();
    const [isShowPassives, setIsShowPassives] = useState(false);
    const { width } = useWindowDimensions();
    const { totalNumberOfRecords, employeesRoleList, employeesRoleLoading, roleListSearchData, getEmployeesRoleList } = props;

    const [roleState, setRolState] = useState(roleListSearchData);
    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setIsShowPassives(false)
    }
    const handleTableFilter = (data) => {
    }
    useEffect(() => {
        if (roleListSearchData !== roleState) {
            getEmployeesRoleList(roleState);
        }
    }, [roleState]);
    const data = totalNumberOfRecords > 0 ? employeesRoleList.map((row) => {
        return (
            {
                "key": row?.resourceId,
                "givenName": { "text": row?.givenName + row?.familyName, "id": row.resourceId },
                "departmentName": row.departmentName,
                "roles": row.roles,
                "jobName": row.jobName
            }
        );
    }) : [];

    const tableConfig = () => ({
        filterableColumns: width >= 875 ? [] : [],
        sortableColumns: ['givenName', 'departmentName', 'roles', 'jobName'],
        visibilityOptionExcludes: ['givenName', 'departmentName', 'roles', 'jobName'],
        page: "PersonRoles",
        leftActionMenu: null,
        showVisibilityMenu: false,
    });
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    });

    const handleTableChange = (data) => {
        const paginationObj = { ...roleState.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data?.sorter?.field ? data?.sorter?.field : roleState?.pagination?.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setRolState({ ...roleState, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...roleState.pagination, "pageNumber": 1 }
        setRolState({ ...roleState, "SearchTerm": e, "pagination": pagingObj })
    }
    const handleShowPassivesToogle = () => { }

    return (
        <div className="table-container">
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('PARTNER_DEPARTMENT')}`,
                            dataIndex: 'departmentName',
                            key: 'departmentName',
                            sorter: true,
                        },
                        {
                            title: `${t('SETTINGS_ROLES')}`,
                            dataIndex: 'roles',
                            key: 'roles',
                            sorter: true,
                            render: obj => {
                                if (obj === " " || obj === null) {
                                    return "";
                                } else {
                                    const filterEmptyData = obj.split(',').map((val) => val.trim()).filter(val => val !== '');
                                    const filterSameData = filterEmptyData.filter(function (item, pos, self) {
                                        return self.indexOf(item) == pos;
                                    })
                                    return filterSameData.join(', ');
                                }
                            }
                        },
                        {
                            title: `${t('PARTNER_JOB')}`,
                            dataIndex: 'jobName',
                            key: 'jobName',
                            sorter: true,
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('PARTNER_NAME_SURNAME')}`,
                                dataIndex: 'givenName',
                                key: 'givenName',
                                sorter: true,
                                render: obj => {
                                    return (
                                        <div className="imageNameActionColumn">
                                            <span>{obj.text}</span>
                                            <Dropdown className="action-menu" overlay={null} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                            </Dropdown>
                                        </div>
                                    )
                                }
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={employeesRoleLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        employeesRoleLoading: state.settingsPage.employeesRoleLoading,
        employeesRoleList: state.settingsPage.employeesRoleList.results,
        totalNumberOfRecords: state.settingsPage.employeesRoleList.totalNumberOfRecords,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getEmployeesRoleList: (searchData) => { dispatch(getEmployeesRoleList(searchData)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeesRoleTab);