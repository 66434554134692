import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import { dateFormatConvert } from '../../utils/config';

const PaymentHistory = ({handleRejectPaymentHistoryModal,subscriptionResponse}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="subscription-container">
                <h6 className="subscription-card-title">{t("PAYMENT_HISTORY")}</h6>
                <div className="subscription-payment-information-container">
                    {subscriptionResponse?.invoiceList?.map((item, index) => (
                        <div key={index}>
                            <div className="d-flex space-between">
                                <div className="d-flex flex-column subscription-payment-gap">
                                    <div className="subscription-card-title md">
                                        {moment(item.paymentDate).format(dateFormatConvert)}
                                    </div>
                                </div>
                                <div className="subscription-package-name grey">
                                {item?.currencyId == 1 
                                                    ? item?.amount?.toLocaleString('tr-TR') + ' ₺'
                                                    : '$'+item?.amount?.toLocaleString('en-US').replace(',' , '')
                                }
                                </div>
                            </div>
                            <div className="subscription-hr mt-3"></div>
                        </div>
                    ))}
                </div>
            </div>
            <Button onClick={() => handleRejectPaymentHistoryModal()} key="submit-1" className="ant-btn primary-btn w-100 h-40 mt-3">
                {t("PARTNER_CLOSE")}
            </Button>
        </>
    )
}

export default PaymentHistory;