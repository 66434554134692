import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getApproveOrganizationRegisterMail } from '../../redux/actions/organization.actions';
import { signinRedirect } from '../../services/userService';
import { useLocation } from 'react-router';
import { UilExclamationTriangle } from '@iconscout/react-unicons';
import { Spin,Button } from 'antd';
import { sendGtmEvent } from '../../utils/gtmFunction';
import '../../styles/approveOnMail.scss';
import '../../styles/global.scss';

function ApproveOrganizationRegisterMail(props) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userId = query.get('u');
    //const confirmToken = query.get('confirmToken');
    const { getApproveOrganizationRegisterMail, approveOrganizationRegisterMailResponse, approveOrganizationRegisterMailResponseLoading } = props;
    const { t } = useTranslation();
    useEffect(() => {
       getApproveOrganizationRegisterMail({ 'userId': !isNaN(parseInt(userId)) ? parseInt(userId) : null  });
    }, [userId])

    useEffect(() => {
        if(approveOrganizationRegisterMailResponse.status === "SUCCESS"){
            sendGtmEvent("confirm_account");
        }
    },[approveOrganizationRegisterMailResponse])
    
    return (
        approveOrganizationRegisterMailResponseLoading ?
            <Spin className="tab-loading pad-top-75" size="large" />
            :
            <div className="approve-on-mail-container">
                <div className="approve-on-mail-card-container">
                    <div className="approve-on-mail-card">
                        <div className='approve-on-mail-icon-container'>
                            {approveOrganizationRegisterMailResponse?.status === "SUCCESS" ?
                                <img src={process.env.REACT_APP_URL + 'images/successful.gif'} />
                                :
                                <UilExclamationTriangle />
                            }
                        </div>
                        <h1 className={approveOrganizationRegisterMailResponse?.status === "SUCCESS" ? 'success' : 'warning'}>
                            {approveOrganizationRegisterMailResponse?.status === "SUCCESS" ? t('SUCCESSFUL') : t('WARNING')}
                        </h1>
                        <p>{approveOrganizationRegisterMailResponse?.message}</p>
                     {approveOrganizationRegisterMailResponse?.status === "SUCCESS" && <Button className="w-auto h-40 primary-btn f-16" onClick={() => signinRedirect()} >{t('LOGIN_TITLE')}</Button>}
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        approveOrganizationRegisterMailResponse: state.organization.approveOrganizationRegisterMailResponse,
        approveOrganizationRegisterMailResponseLoading: state.organization.approveOrganizationRegisterMailResponseLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApproveOrganizationRegisterMail: (data) => { dispatch(getApproveOrganizationRegisterMail(data)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveOrganizationRegisterMail);