import "../../styles/subscription.scss";
import { Col, Button, Spin, ConfigProvider, Form, Row, Input, Tooltip,Checkbox} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';
import { addOrUpdateCard,deleteCard,tryPaymentAgain } from '../../redux/actions/subscription.action';
import DeleteCard from './DeleteCard';

import { UilInfoCircle } from '@iconscout/react-unicons';


const UpdateCard = ({tryPaymentAgain,openTryPaymentAgainModal, addOrUpdateCard, deleteCard, isCardDeleteLoading,isCardSaveLoading,subscriptionResponse, renderSubscription, isPaymentFailed}) => {

    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [isDeleteCardModalopen, setisDeleteCardModalOpen] = useState({ 'isVisible': false });
    const [cardInformationState, setCardInformationState] = useState(0);
    const [isAmex , setIsAmex] = useState(false);
    const [paymentPayload, setPaymentPayload] = useState({
        "creditCard": {
          "cardHolderName": "",
          "cardNumber": "",
          "expireMonth": "",
          "expireYear": "",
          "cvc": "",
          "secure3D": true,
          "saveCard": true,
        },
    })

    const handleRejectDeleteCardModal = () => { setisDeleteCardModalOpen({ 'isVisible': false })}
    const openDeleteCardModal = () => { setisDeleteCardModalOpen({ 'isVisible': true })}


    useEffect(() => {
        
        if(!subscriptionResponse.cardInformation || isPaymentFailed){
            setCardInformationState(1)
        }
        
    }, []);
    
    useEffect(() => {
        
        if(isCardDeleteLoading === 'success'){
            setisDeleteCardModalOpen({ 'isVisible': false })
            renderSubscription();
        }
        
    }, [isCardDeleteLoading]);
    
    useEffect(() => {
        if(isCardSaveLoading === 'success' && !isPaymentFailed){
            setisDeleteCardModalOpen({ 'isVisible': false })
            renderSubscription();
        }
        if(isCardSaveLoading === 'success' && isPaymentFailed){
            setisDeleteCardModalOpen({ 'isVisible': false })
            renderSubscription();
            tryPaymentAgain();
            openTryPaymentAgainModal();
        }
    }, [isCardSaveLoading]);

    
    const saveCard = () => { 
        addOrUpdateCard(paymentPayload)
    }
    const deleteCardAction = () => { 
        deleteCard();
     }

    //#region Validation
    const luhnCheck = (value) => {
        // accept only digits, dashes or spaces
        // if (/[^0-9-\s]+/.test(value)) return false;
        if (/[^0-9-\s]+/.test(value)) return t('ENTER_VALID_CARD_NUMBER');
      
        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0,
          nDigit = 0,
          bEven = false;
        value = value.replace(/\D/g, "");
      
        for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);
      
          if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
          }
      
          nCheck += nDigit;
          bEven = !bEven;
        }
      
        return nCheck % 10 == 0 ? null : t('ENTER_VALID_CARD_NUMBER');
    }

    const handleValuesChange = (changedObj, all) => {
        if ('cardHolderName' === Object.keys(changedObj)[0]) {
            const creditCard = { ...paymentPayload.creditCard, 'cardHolderName': changedObj["cardHolderName"] };
            setPaymentPayload({ ...paymentPayload, creditCard: creditCard });
        } else if ('cardNumber' === Object.keys(changedObj)[0]){
            if(changedObj['cardNumber'] && !luhnCheck(changedObj['cardNumber'].replace(/\s+/g, ''))){
                const re = new RegExp("^(34|37)");
                const condition = changedObj['cardNumber'].match(re) != null;
                if (condition) {
                    setIsAmex(true)
                    //return value.length < 15 ? "Kart Numarası en az 15 hane olmalıdır" : true;
                } else {
                    setIsAmex(false)
                    //return value.length < 16 ? "Kart Numarası en az 16 hane olmalıdır" : true;
                }
            }

            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields, 'cardNumber' : changedObj['cardNumber'].replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim(), CCV:''};
            form.setFieldsValue(fieldsObj)

            const creditCard = {...paymentPayload.creditCard, cardNumber : changedObj['cardNumber'].replace(/\s+/g, '')}
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
            
            
        } else if('expireDate' === Object.keys(changedObj)[0]){
            
            let inputExpiry = changedObj['expireDate'];
            if(inputExpiry.length ==1 && parseInt(inputExpiry, 10) > 1){
                inputExpiry = '0'+inputExpiry
            }  
            const cleanedExpiry = inputExpiry.replace(/[^0-9]/g, ''); // Sadece rakamları al


            const fields = form.getFieldsValue()

            // Eğer tam olarak 6 karakter varsa (MMYYYY formatı), MM/YY formatına çevir
            if (cleanedExpiry.length === 6) {
            const formattedExpiry = `${cleanedExpiry.slice(0, 2)}/${cleanedExpiry.slice(2)}`;

            const fieldsObj = { ...fields, 'expireDate' : formattedExpiry };
            form.setFieldsValue(fieldsObj)
            } else if (cleanedExpiry.length === 4) {
            // Eğer tam olarak 4 karakter varsa (MMYY formatı), MM/YY formatına çevir
            const formattedExpiry = `${cleanedExpiry.slice(0, 2)}/${cleanedExpiry.slice(2)}`;
            const fieldsObj = { ...fields, 'expireDate' : formattedExpiry };
            form.setFieldsValue(fieldsObj)
            } else {
            // Diğer durumlar için girilen değeri kullan
            const fieldsObj = { ...fields, 'expireDate' : inputExpiry };
            form.setFieldsValue(fieldsObj)

            }

            const expireMonth = cleanedExpiry.slice(0, 2);
            const expireYear = cleanedExpiry.slice(2).length == 2 ? '20'+cleanedExpiry.slice(2) : cleanedExpiry.slice(2); 

            const creditCard = {...paymentPayload.creditCard, expireMonth : expireMonth , expireYear: expireYear }

            setPaymentPayload({...paymentPayload, creditCard : creditCard})

        } else if('CCV' === Object.keys(changedObj)[0]){

            const creditCard = {...paymentPayload.creditCard, cvc : changedObj['CCV']  }
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
        } else if('saveCard' === Object.keys(changedObj)[0]){

            const creditCard = {...paymentPayload.creditCard, saveCard : changedObj['saveCard']  }
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
        } else {
            setPaymentPayload({ ...paymentPayload, ...changedObj });
        }

    }
    
    const validateExpiry = (value) => {

        const [month, year] = value.split('/');
        const currentYear = new Date().getFullYear() % 100; // Yılın son iki basamağı
        const currentLongYear = new Date().getFullYear(); // Yılın son iki basamağı
        const currentMonth = new Date().getMonth() + 1; // Ay 0'dan başladığı için 1 ekliyoruz

        const inputYear = parseInt(year, 10);
        const inputLongYear = parseInt(year);
        const inputMonth = parseInt(month, 10);

        const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2}|[0-9]{4})$/;
        if (!regex.test(value)) {
            return t('ENTER_VALID_EXPIRATION_DATE')
        }else if ( inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth) || 
            (year?.length == 4 && (inputLongYear < currentLongYear  || (inputLongYear === currentLongYear && inputMonth < currentMonth)))
            ) {
            return t('EXPIRED_CARD_VALIDATION')
        }else{
            return null;
        }
    };

    const validateCCV = (value) => {
        const regex = /^[0-9]{3,4}$/;
        if (!regex.test(value)) { 
            return t('ENTER_VALID_SECURITY_CODE')
        }else{
            return null
        }
    };

    const allowOnlyNumericKeyPress = (e) => {
        // Sadece sayıları kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericPaste = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const cardNumberKeypressFilter = (e) => {
        // Sadece sayıları kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/[0-9\s]/.test(key)) {
          e.preventDefault();
        }
    };

    const cardNumberPasteFilter = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9\s]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleCardHolderKeyPress = (e) => {
        // Sadece harf, boşluk ve bazı özel karakterleri kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/^[A-Za-z\sÀ-ÖØ-öø-ÿÇçĞğİıÖöŞşÜü]*$/.test(key)) {
          e.preventDefault();
        }
    };

    const handleCardHolderPaste = (e) => {
        // Sadece harf, boşluk ve bazı özel karakterleri kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[A-Za-z\sÀ-ÖØ-öø-ÿ]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleExpireDateKeypress = (e) => {
        const key = e.key;
        if (!/[0-9\/]/.test(key)) {
          e.preventDefault();
        }
    }

    const handleExpireDatePaste = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9\/]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };
    //#endregion


    return (
        <>   
            {cardInformationState == 0  && 
                <Col gutter={[24, 24]} justify="center">
                    <h6 className="subscription-card-title">{t('CARD_INFO')}</h6>
                    <Button onClick={() => setCardInformationState(1)} block className="ant-btn primary-btn w-100 h-40" style={{marginTop:'24px'}}>
                                {t("UPDATE_CARD")}
                    </Button>
                    <Button onClick={() => openDeleteCardModal()} block className="ant-btn white-btn w-100 h-40 mt-5">
                                {t("DELETE_CARD")}
                    </Button>
                </Col>
            }
            
            {cardInformationState == 1 && (isCardSaveLoading == 'loading') && <Spin className="my-15" size="medium"></Spin>} 

            {cardInformationState == 1 && (isCardSaveLoading !== 'loading') &&  (
                <div>
                    <h6 className="subscription-card-title">{t('CARD_INFO')}</h6>
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            onValuesChange={handleValuesChange}
                            layout="vertical"
                            requiredMark={false}
                            style={{paddingTop:'24px',paddingRight:'0px'}}
                            onFinish={saveCard}
                            onFinishFailed={() => {}}
                        >
                        <Row span={24} gutter={[24, 24]}> 
                            <Col span={12}>
                                <Form.Item name="cardNumber" label={t('CARD_NUMBER')} rules={[{ required: true, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && luhnCheck(value)) ? Promise.reject(new Error(luhnCheck(value))) : Promise.resolve()}]}>
                                    <Input id="cardNumber" placeholder={t('CARD_NUMBER_PH')} maxLength={19} onKeyPress={cardNumberKeypressFilter} onPaste={cardNumberPasteFilter} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="cardHolderName" label={t('CARD_HOLDER_NAME')} rules={[{ required: true, message: t('REQUIRED_FIELD') }]}>
                                    <Input id="cardHolderName" placeholder={t('CARD_HOLDER_NAME_PH')}
                                        maxLength={100} onKeyPress={handleCardHolderKeyPress} onPaste={handleCardHolderPaste} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row span={24} gutter={[24, 24]}> 
                            <Col span={12}>
                                <Form.Item name="CCV" label={t('CARD_CCV')} rules={[{ required: true, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && validateCCV(value)) ? Promise.reject(new Error(validateCCV(value))) : Promise.resolve()}]} >
                                    <Input id="CCV" placeholder={t('CARD_CCV_PH')} maxLength={isAmex ? 4 : 3} onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="expireDate" label={t('CARD_EXPIRE')} rules={[{ required: true, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && validateExpiry(value)) ? Promise.reject(new Error(validateExpiry(value))) : Promise.resolve()}]} >
                                    <Input  id="expireDate" placeholder={t('CARD_EXPIRE_PH')} maxLength={7} onKeyPress={handleExpireDateKeypress} onPaste={handleExpireDatePaste} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="save-card-info mb-2">
                                <Checkbox defaultChecked disabled className="text-start">{t('CARD_SAVE_INFO_1')}
                                <Tooltip title={t('CARD_SAVE_INFO_2')}>
                                    <UilInfoCircle style={{ width: 16, height: 16 }} />
                                </Tooltip>
                                </Checkbox>
                                
                            </Col>
                        </Row>

                        <Button htmlType="submit" block className="ant-btn primary-btn w-100 h-40">
                            {subscriptionResponse.cardInformation ? isPaymentFailed 
                                ? t("UPDATE_CARD_AND_TRY_PAYMENT_AGAIN") : t("UPDATE_CARD") : t("SAVE_CARD")}
                        </Button>
                        {(subscriptionResponse.cardInformation && !isPaymentFailed) && 
                        <Button onClick={() => setCardInformationState(0)} block className="ant-btn white-btn w-100 h-40 mt-3">
                            {t("QUICK_ACTION_CANCEL")}
                        </Button>} 

                            
                        </Form>
                    </ConfigProvider>
                </div>
            ) 
            }
           
            
            {isDeleteCardModalopen.isVisible && <DynamicRequestModal
                key={'isCreditCardModal'}
                modalVisible={isDeleteCardModalopen.isVisible}
                setStateData={setisDeleteCardModalOpen}
                handleCancel={handleRejectDeleteCardModal}
                hideButton={true}
                iconName={isCardDeleteLoading !== 'loading' ? 'UilInfoCircle' :  null}
                isForm={<DeleteCard isCardDeleteLoading={isCardDeleteLoading} deleteCardAction={deleteCardAction} handleRejectDeleteCardModal={handleRejectDeleteCardModal} />}
                iconColor={'#FFE651'}
            />}
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        isCardDeleteLoading: state.subscription.isCardDeleteLoading,
        isCardSaveLoading: state.subscription.isCardSaveLoading,
        isTryPaymentAgainLoading: state.subscription.isTryPaymentAgainLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        addOrUpdateCard: (payload) => { dispatch(addOrUpdateCard(payload)) },
        tryPaymentAgain: () => { dispatch(tryPaymentAgain()) },
        deleteCard: () => { dispatch(deleteCard()) },
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateCard);