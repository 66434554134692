import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    definedTaskUpdate, resetDefinedTaskUpdate, setAssignedTaskDetailDrawer, resetTaskUpdateStatus, taskUpdateStatus, getTaskAssignedList
} from "../../../../redux/actions/task.actions";

import { Col, Row, Button, List, Avatar, Spin, Input } from 'antd';
import { UilMultiply, UilAngleRightB, UilAngleLeftB, UilSortAmountUp, UilSortAmountDown } from '@iconscout/react-unicons';

import Successful from "../../../successful";
import DetailTaskRequestsTab from './detail_task_requests_tab';
import DynamicToastMessage from '../../../dynamicToastMessage/index';
import { WaitIcon, SuccessIcon } from "../../../../features/Icon/Icons";
import { MergeObjects, turkishCharacterRegex } from "../../../../utils/commonFormFunctions";

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/table.scss'
import '../../../../styles/task-drawer.scss';

function DetailAssignedTaskTabs(props) {
    const { t } = useTranslation();
    const { setAssignedTaskDetailDrawer,
        definedTaskUpdate, resetDefinedTaskUpdate,
        definedTaskUpdateStatusResponse, assignedTaskInformation,
        assignedTaskInformationLoading, getTaskAssignedList, assignedTaskRequestState, tasksAssignedList,
        resetTaskUpdateStatus, taskUpdateStatus, taskUpdateStatusResponse, setAssignedTaskRequestState } = props;

    const [currentTab, setCurrentTab] = useState(0); // tab state
    const [pageIndex, setPageIndex] = useState(1); //check complete index
    const [formStatus, setFormStatus] = useState("wait");
    const [assignedTasksListState, setAssignedTasksListState] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [detailAssignedTaskObj, setDetailAssignedTaskObj] = useState({
        data: {}
    });
    const [taskCount, setTaskCount] = useState({
        assignedTaskCount: 0,
        totalTaskCount: 0
    });
    const [sortStatus, setSortStatus] = useState(true);
    useEffect(() => {
        if (assignedTaskInformation?.personTasks?.length > 0) {
            var list = [];
            assignedTaskInformation?.personTasks.map(data => {
                if (data.taskResourceId)
                    return list.push({
                        'resourceId': data.taskResourceId,
                        'name': data.taskName,
                        'isAssigned': true,
                        'isCompleted': data?.assignedInformation?.isCompleted,
                        'taskAssignedResourceId': data?.assignedInformation?.taskAssignedResourceId,
                        'show': true
                    });
            });
            setAssignedTasksListState(list);
            setTaskCount({
                assignedTaskCount: assignedTaskInformation?.completedTaskCount,
                totalTaskCount: assignedTaskInformation?.totalTaskCount
            });
        };
    }, [assignedTaskInformation])

    useEffect(() => {
        if (definedTaskUpdateStatusResponse.status === "SUCCESS") {
            nextBtn();
            DynamicToastMessage({
                'key': 'defined_task_update_status_response_success_key',
                'title': t('SUCCESS'),
                'description': definedTaskUpdateStatusResponse.data?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            setUpdateCount(updateCount + 1);
            resetDefinedTaskUpdate();
        } else if (definedTaskUpdateStatusResponse?.status === "FAIL") {
            DynamicToastMessage({
                'key': 'defined_task_update_status_response_error_key',
                'title': t('ERROR'),
                'description': definedTaskUpdateStatusResponse.data?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetDefinedTaskUpdate();
        }
    }, [definedTaskUpdateStatusResponse])

    useEffect(() => {
        if (taskUpdateStatusResponse.status === "SUCCESS") {
            setTaskCount({
                assignedTaskCount: taskCount.assignedTaskCount,
                totalTaskCount: taskCount.totalTaskCount - 1
            });
            var list = [...assignedTasksListState];
            list.splice(currentTab, 1);
            setAssignedTasksListState(list);
            tabOnClick(0);
            setUpdateCount(updateCount + 1);
            DynamicToastMessage({
                'key': 'task_update_status_response_success_key',
                'title': t('SUCCESS'),
                'description': taskUpdateStatusResponse.data?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            resetTaskUpdateStatus();
        } else if (taskUpdateStatusResponse.status === "FAIL") {
            DynamicToastMessage({
                'key': 'task_update_status_response_error_key',
                'title': t('ERROR'),
                'description': taskUpdateStatusResponse.data?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTaskUpdateStatus();
        }
    }, [taskUpdateStatusResponse])

    const resetForm = () => {
        setDetailAssignedTaskObj({
            data: {}
        });
        setPageIndex(1);
        setTaskCount({
            assignedTaskCount: 0,
            totalTaskCount: 0
        });
        setAssignedTasksListState([]);
        setFormStatus("wait");
        setCurrentTab(0);
        if (updateCount > 0) {
            updateTable();
        }
    }
    const updateTable = () => {
        if (tasksAssignedList?.length > 0 && assignedTaskInformation?.personTasks?.length > 0) {
            getTaskAssignedList(assignedTaskRequestState);
        } else {
            setAssignedTaskRequestState({
                ...assignedTaskRequestState,
                pagination: {
                    pageNumber: assignedTaskRequestState.pagination.pageNumber > 0 ? assignedTaskRequestState.pagination.pageNumber - 1 : 0, pageSize: assignedTaskRequestState.pagination.pageSize, orderBy: assignedTaskRequestState.pagination.orderBy
                }
            });
        }
    }
    const closeDrawerControl = () => {
        setAssignedTaskDetailDrawer(false);
        resetForm();
    }
    const setDetailObject = (obj) => {
        const mergedObj = MergeObjects(detailAssignedTaskObj, obj);
        setDetailAssignedTaskObj(mergedObj);
    }
    const nextBtn = () => {
        if (currentTab + 1 !== assignedTaskInformation.totalTaskCount) {
            setCurrentTab((i) => i + 1);
        }
    }
    const prevBtn = () => {
        if (currentTab > 0) {
            setCurrentTab((i) => i - 1);
        }
    }
    const tabOnClick = (index) => {
        setCurrentTab(index);
    }
    const updateAssignedTask = (type) => {
        let updateObj = {}
        var attributeList = [];
        if (type === "saveTask") {
            detailAssignedTaskObj.data[assignedTasksListState[currentTab]?.resourceId].map(data =>
                data.resourceId === "description_" + assignedTasksListState[currentTab]?.resourceId ?
                    updateObj = { ...updateObj, 'description': data.value } :
                    data.resourceId !== "missionDescription_" + assignedTasksListState[currentTab]?.resourceId &&
                    attributeList.push({
                        "attributeResourceId": "",
                        "attributeValue": data.value,
                        "attributeValueResourceId": data.resourceId
                    })
            );
            updateObj = {
                ...updateObj,
                resourceId: assignedTasksListState[currentTab]?.taskAssignedResourceId,
                attributes: attributeList,
            }
            definedTaskUpdate(updateObj);
        } else {
            let description = detailAssignedTaskObj.data[assignedTasksListState[currentTab]?.resourceId].filter(filterData => filterData.resourceId === "description_" + assignedTasksListState[currentTab]?.resourceId)[0].value;
            updateObj = {
                state: 8,
                resourceId: assignedTasksListState[currentTab]?.taskAssignedResourceId,
                description: description
            };
            taskUpdateStatus(updateObj);
        }
        attributeList = [];
    }
    const handleSearch = (e) => {
        const searchText = e.target.value;
        const modifiedSearchText = turkishCharacterRegex(searchText);
        const updatedData = assignedTasksListState.map(item => {
            const modifiedItemName = turkishCharacterRegex(item.name);
            if (modifiedItemName.toLowerCase().includes(modifiedSearchText.toLowerCase())) {
                return { ...item, show: true };
            } else {
                return { ...item, show: false };
            }
        });
        setAssignedTasksListState(updatedData);
    };
    const sortAscending = () => {
        const sortList = [...assignedTasksListState].sort((a, b) => a.name.localeCompare(b.name));
        setAssignedTasksListState(sortList);
    };

    const sortDescending = () => {
        const sortList = [...assignedTasksListState].sort((a, b) => b.name.localeCompare(a.name));
        setAssignedTasksListState(sortList);
    };
    const handleSortClick = () => {
        if (sortStatus) {
            sortAscending();
        } else {
            sortDescending();
        }
        setSortStatus(!sortStatus);
        setCurrentTab(0);

    };
    return (
        <div className="task-drawer">
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {pageIndex === 1 ?
                    <>
                        <div className="add-global-draw-title flex-start">
                            <h1>{t('TASK_ASSIGNMENT_PROCEDURES')}</h1>
                        </div>
                        <h3 className="pt-4 fw-600 black f-18">{t('EMPLOYEE_INFORMATION')}</h3>

                        <div className="d-flex w-100 justify-space-between ">
                            {assignedTaskInformation?.personName ?
                                <>
                                    <div className="d-flex">
                                        <Avatar size="large" src={<img src={assignedTaskInformation?.avatar !== "" ? assignedTaskInformation?.avatar : process.env.REACT_APP_URL + 'images/avatar_0.png'} alt="avatar" />} />
                                        <div className="d-block pl-2  mt-1">
                                            <p className="f-16 black fw-600 line-10">{assignedTaskInformation?.personName} </p>
                                            <p className="f=14 grey12 line-10">{assignedTaskInformation?.department} </p>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="pb-8" >{t('PLEASE_SELECT_EMPLOYEE')}</div>
                            }
                        </div>
                        <div className="w-100 ">
                            <Row>
                                <Col span={8}>
                                    <div className="d-inline-flex pt-1">
                                        <h3 className="fw-600 black f-18">{t('TASKS')}
                                        </h3>
                                        <span className="mt-1 pl-2">({taskCount?.assignedTaskCount}/{taskCount?.totalTaskCount})</span>
                                        {assignedTasksListState.length > 0 &&
                                            <Button onClick={handleSortClick} className="transparent-btn ">{sortStatus ? <UilSortAmountUp /> : <UilSortAmountDown />}</Button>
                                        }
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="d-inline-flex pl-3 mt-2">
                                        {currentTab > 0 &&
                                            <Button onClick={prevBtn} icon={<UilAngleLeftB />} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn prev-btn ">{t('INVENTORY_BACK')} </Button>
                                        }
                                        {(taskCount?.totalTaskCount > currentTab + 1 && currentTab > 0) &&
                                            <div className="buttons-border mx-4" />
                                        }
                                        {taskCount?.totalTaskCount > currentTab + 1 &&
                                            <Button onClick={nextBtn} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn next-btn">{t('INVENTORY_NEXT')} <UilAngleRightB /></Button>
                                        }
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="flex-end">
                                        {!assignedTasksListState[currentTab]?.isCompleted &&
                                            <div className="d-flex">
                                                <Button onClick={() => updateAssignedTask("undoTask")} className="w-180 h-40 f-14 font-w-700 grey-btn">{t('UNDO_MISSION')}</Button>
                                                <Button onClick={() => updateAssignedTask("saveTask")} className="w-200 h-40 f-14 font-w-700 ml-3 primary-btn">{t('EDIT_TASK_AND_CONTINUE')}</Button>
                                            </div>
                                        }

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <div className="task-step-search" >
                                        <Input onChange={handleSearch} placeholder={t('SEARCH_TASK_NAME')} />
                                    </div>
                                    <List
                                        className="task-step-list scrollable assigned-task-drawer"
                                        itemLayout="horizontal"
                                        loading={assignedTaskInformationLoading}
                                        dataSource={assignedTasksListState}
                                        renderItem={(data, index) => (
                                            data.show &&
                                            <List.Item onClick={() => tabOnClick(index)} key={index} className={index === currentTab ? "task-item-active" : ""}>
                                                <List.Item.Meta
                                                    className="cursor-pointer"
                                                    avatar={data.isAssigned ? <SuccessIcon /> : <WaitIcon />}
                                                    title={<label className={data.isAssigned ? "cursor-pointer active-text" : "cursor-pointer"}>{data.name}</label>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                <Col span={16} >
                                    <DetailTaskRequestsTab
                                        assignedTasksListState={assignedTasksListState}
                                        setDetailObject={setDetailObject}
                                        currentTab={currentTab}
                                        detailAssignedTaskObj={detailAssignedTaskObj}
                                        setDetailAssignedTaskObj={setDetailAssignedTaskObj}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    formStatus === "wait" ?
                        <Spin className="tab-loading w-100 flex-center" />
                        :
                        taskCount?.assignedTaskCount > 0 ?
                            <Successful
                                whiteBtn={<Button onClick={() => closeDrawerControl()} className="w-200 h-40 f-14 font-w-700 white-btn ">{t('INVENTORY_RETURN_LIST')}</Button>}
                                primaryBtn={null}
                                dynamicMessage={t('ASSIGNED_TASK_SUCCESS', { "count": taskCount?.assignedTaskCount })}
                            />
                            :
                            <Successful
                                whiteBtn={null}
                                primaryBtn={<Button onClick={() => closeDrawerControl()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('INVENTORY_RETURN_LIST')}</Button>}
                                dynamicMessage={taskCount?.completedTaskCount === taskCount?.totalTaskCount ? t('HOMEPAGE_CONGATULATIONS') : t('MISSIONS_SUCCESSFULLY_UPDATED')}
                                dynamicIcon={taskCount?.completedTaskCount === taskCount?.totalTaskCount ? process.env.REACT_APP_URL + "images/award.png" : process.env.REACT_APP_URL + "images/hourglass.png"}
                                message={taskCount?.completedTaskCount === taskCount?.totalTaskCount ? t('COMPLETE_ALL_TASK') : <div>
                                    <span>{t('FORWARD_ASSIGNED_AGAIN', { count: taskCount?.totalTaskCount - taskCount?.completedTaskCount })}</span>
                                </div>}
                            />
                }
            </div>

            <div className="pad-left-24" />
            {pageIndex === 1 &&
                <div className="add-user-draw-footer">
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    <div className="add-user-draw-footer-end">
                        <div className="pad-left-24" />
                        <Button onClick={() => closeDrawerControl()} className="w-180 h-40 f-14 font-w-700 transparent-btn ">{t('END_PROCESSES')}</Button>
                    </div>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        assignedTaskInformation: state.taskReducer.assignedTaskInformation,
        assignedTaskInformationLoading: state.taskReducer.assignedTaskInformationLoading,
        definedTaskUpdateStatusResponse: state.taskReducer.definedTaskUpdateStatusResponse,
        taskUpdateStatusResponse: state.taskReducer.taskUpdateStatusResponse,
        tasksAssignedList: state.taskReducer.tasksAssignedList.assignedTaskList,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAssignedTaskDetailDrawer: (status) => { dispatch(setAssignedTaskDetailDrawer(status)) },
        getTaskAssignedList: (searchData) => { dispatch(getTaskAssignedList(searchData)) },
        definedTaskUpdate: (data) => { dispatch(definedTaskUpdate(data)) },
        resetDefinedTaskUpdate: () => { dispatch(resetDefinedTaskUpdate()) },
        resetTaskUpdateStatus: () => { dispatch(resetTaskUpdateStatus()) },
        taskUpdateStatus: (data) => { dispatch(taskUpdateStatus(data)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailAssignedTaskTabs);