import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getFileUploadList, deleteDocument, deleteDocumentReset, createDocumentRequests,
    createDocumentRequestsReset, deleteDocumentRequests, deleteDocumentRequestReset,
} from '../../redux/actions/person.actions';
import { getOrganizationDocumentType, getOrganizationDocumentTypeWithUserId } from '../../redux/actions/organization.actions';
import { createDocumentType, resetCreateDocumentType } from '../../redux/actions/document-type.action';
import {
    Row, Col, Button, Upload, Card, Spin, Form, ConfigProvider, Checkbox, List, Divider,
    Input, Tooltip
} from 'antd';
import { UilTrashAlt, UilFileDownload, UilTrash, UilPlus } from '@iconscout/react-unicons';
import { FileAltIcon } from '../../features/Icon/Icons';
import moment from 'moment';

import { dateFormatConvert, customTableScrolling, useWindowDimensions, apiUrl } from '../../utils/config';
import DynamicToastMessage from '../dynamicToastMessage/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicSelectbox from '../dynamicSelectbox/index';

import 'moment/locale/tr';
import '../../styles/person-documents-tab.scss';

function DocumentsTab(props) {
    const { getFileUploadList, deleteDocument, deleteDocumentReset, deleteDocumentResponse, fileUploadList, fileUploadListLoading, person, auth, deleteDocumentRequests,
        deleteDocumentRequestReset, deleteDocumentRequestResponse, getOrganizationDocumentType, organizationDocumentTypeList, selectedPersonId, createDocumentRequests,
        createDocumentRequestsReset, createDocumentResponse, getOrganizationDocumentTypeWithUserId, organizationDocumentTypeListWithUserId, createDocumentType,
        resetCreateDocumentType, createDocumentTypeResponse, disableStatus,  } = props;
    const { t } = useTranslation();
    const { height } = useWindowDimensions();
    const [addDocumentUseForm] = Form.useForm();
    const [selectDocumentResourceId, setSelectDocumentResourceId] = useState();
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false });
    const [addDocumentModalData, setAddDocumentModalData] = useState({ 'isVisible': false, 'documentRequest': [] });
    const [addDocumentConfigState, setAddDocumentConfigState] = useState({ 'dynamicSelectClass': 0, })
    const [documentListState, setDocumentListState] = useState([]);
    const [documentTypeState, setDocumentTypeState] = useState([]);
    const [deleteRequestModalData, setDeleteRequestModalData] = useState({ 'isVisible': false });
    const [addModalState, setAddModalState] = useState({ 'isVisible': false });
    const [addModalFormData, setAddModalFormData] = useState({});
    const [addForm] = Form.useForm();
    const [checkboxState, setCheckboxState] = useState({
        "indeterminate": false,
        "isChecked": false
    });
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nLang') || 'en');
    const access_token = auth.user?.access_token;
    const downloadAbleFileLength = fileUploadList.length > 0 && fileUploadList.filter(item => !item.isUploaded && item.isInitialRequest).length
    const scrollLength = downloadAbleFileLength > 0 ? fileUploadList.length + 3 : fileUploadList.length - 1;
    const uploadProgress = {
        name: 'file',
        disabled: selectDocumentResourceId ? false : true,
        action: apiUrl + '/persons/' + person.resourceId + ' /uploadRequestedDocument/' + selectDocumentResourceId,
        headers: {
            "Authorization": `Bearer ${access_token}`,
            "Accept-Language": localStorage.getItem('i18nLang')
        },
        listType: 'picture',
        accept: ".xlsx,.pdf,.docx,.png,.jpg,.jpeg",
        maxCount: 1,
        showUploadList: { showRemoveIcon: true, removeIcon: <div className='error-message'><span>{t('ERROR')}</span><UilTrashAlt /></div> },
        iconRender: () => {
            return <FileAltIcon />
        },
        onChange(info) {
            if (info.file.status === 'done') {
                DynamicToastMessage({ 'key': 'upload_document_success', 'title': t('SUCCESS'), 'description': info.file.response.message, 'statusType': 'success', 'position': 'bottom-right' })
                getFileUploadList(person.resourceId)
                setKvkkStatus(false)
                setSelectDocumentResourceId(null)
            } else if (info.file.status === 'error') {
                DynamicToastMessage({ 'key': 'upload_document_error', 'title': t('ERROR'), 'description': info.file.response.message, 'statusType': 'error', 'position': 'bottom-right' })
            }
        },
        progress: {
            strokeColor: {
                '0%': '#7CC8C8',
                '100%': '#7CC8C8',
            },
            strokeWidth: 8,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    function changeDocumentType(value) {
        setSelectDocumentResourceId(value)
    }
    const uploadMissingFile = (resourceId) => {
        /* if (!disableStatus && person?.kvkkDocumentApprove) {
            
        } */

        if (resourceId) { 
            var filterData = fileUploadList?.filter(x => x?.organizationDocumentResourceId === resourceId && x.isInitialRequest)
            if (filterData.length > 0) {
                setSelectDocumentResourceId(filterData[0].organizationDocumentResourceId)
            } else {
                setSelectDocumentResourceId(resourceId)
            }
            setTimeout(() => {
                document.getElementById("upload-btn").click()
            }, 500);
        } else {
            DynamicToastMessage({ 'key': 'upload_document_warning', 'title': t('WARNING'), 'description': t('SELECT_DOCUMENT_TYPE'), 'statusType': 'warning', 'position': 'bottom-right' })
        }
    }
    const downloadDocument = (link) => {
        /* if (person?.kvkkDocumentApprove) {
            
        } */

        return window.open(link, '_blank', 'noopener,noreferrer')
    }
    const deleteDocumentModal = (documentId, documentName) => {
        setDeleteModalData({ ...deleteModalData, 'isVisible': true, 'id': documentId, 'name': documentName })
    }
    useEffect(() => {
        if (deleteDocumentResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'delete_document_success', 'title': t('SUCCESS'), 'description': deleteDocumentResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            deleteDocumentReset();
            getFileUploadList(person.resourceId)
            setKvkkStatus(false)
            setSelectDocumentResourceId(null)
        } else if (deleteDocumentResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'delete_document_error', 'title': t('ERROR'), 'description': deleteDocumentResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            deleteDocumentReset();
            setSelectDocumentResourceId(null)
        }
    }, [deleteDocumentResponse])
    useEffect(() => {
        if (deleteDocumentRequestResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'delete_document_success', 'title': t('SUCCESS'), 'description': deleteDocumentRequestResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            deleteDocumentRequestReset();
            getFileUploadList(person.resourceId)
            setKvkkStatus(false)
            setSelectDocumentResourceId(null)
        } else if (deleteDocumentRequestResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'delete_document_error', 'title': t('ERROR'), 'description': deleteDocumentRequestResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            deleteDocumentRequestReset();
            setSelectDocumentResourceId(null)
        }
    }, [deleteDocumentRequestResponse])
    useEffect(() => {
        if (createDocumentResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'create_document_success', 'title': t('SUCCESS'), 'description': createDocumentResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            createDocumentRequestsReset();
            setAddDocumentModalData({ "isVisible": false })
            setAddDocumentConfigState({ 'dynamicSelectClass': 0 })
            const fields = addDocumentUseForm.getFieldsValue()
            const projects = { ...fields };
            projects["document"] = [];
            addDocumentUseForm.setFieldsValue(projects)
            getFileUploadList(person.resourceId)
            setKvkkStatus(false)
            setSelectDocumentResourceId(null)
            setCheckboxState({
                "indeterminate": false,
                "isChecked": false
            })
        } else if (createDocumentResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'create_document_success', 'title': t('ERROR'), 'description': createDocumentResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            createDocumentRequestsReset();
            setSelectDocumentResourceId(null)
        }
    }, [createDocumentResponse])
    //Document Type List Add
    useEffect(() => {
        if (createDocumentTypeResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': createDocumentTypeResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getFileUploadList(person.resourceId);
            setKvkkStatus(false)
            resetCreateDocumentType();
        } else if (createDocumentTypeResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': createDocumentTypeResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateDocumentType();
        }
    }, [createDocumentTypeResponse])
    useEffect(() => {
        getOrganizationDocumentType();
    }, [])
    useEffect(() => {
        const tempArr = organizationDocumentTypeList.filter(filterData => !filterData.resourceId.includes('DD1')).sort((a, b) => a.documentTypeName > b.documentTypeName ? 1 : -1).map((data) => {
            return {
                'displayValue': data.documentTypeName,
                'id': data.resourceId
            }
        })
        const otherData = organizationDocumentTypeList?.filter(filterData => filterData?.resourceId.includes('DD1'))
        if (otherData[0]?.resourceId) {
            tempArr.push({
                'displayValue': otherData[0].documentTypeName,
                'id': otherData[0].resourceId
            })
        }
        setDocumentTypeState(tempArr)
    }, [organizationDocumentTypeList])
    useEffect(() => {
        const tempArr = organizationDocumentTypeListWithUserId.filter(filterData => !filterData.resourceId.includes('DD1')).sort((a, b) => a.documentTypeName > b.documentTypeName ? 1 : -1).map((data) => {
            return {
                'displayValue': data.documentTypeName,
                'id': data.resourceId
            }
        })
        const otherData = organizationDocumentTypeListWithUserId?.filter(filterData => filterData?.resourceId.includes('DD1'))
        if (otherData[0]?.resourceId) {
            tempArr.push({
                'displayValue': otherData[0].documentTypeName,
                'id': otherData[0].resourceId
            })
        }
        setDocumentListState(tempArr)
    }, [organizationDocumentTypeListWithUserId])
    useEffect(() => {
        if (selectedPersonId !== null) {
            getFileUploadList(selectedPersonId)
            setKvkkStatus(false)
        }
    }, [selectedPersonId])
    const openAddDocumentRequestModal = () => {
        /* if (!disableStatus && person?.kvkkDocumentApprove) {
            
        } */

        setAddDocumentModalData({ ...addDocumentModalData, 'isVisible': true })
        getOrganizationDocumentTypeWithUserId(selectedPersonId);
    }
    const handleValuesChange = (value) => {
        const tempArr = value.map((data) => {
            return { 'documentResourceId': data.id }
        })
        if (value.length === 0) {
            setCheckboxState({ ...checkboxState, 'indeterminate': false, 'isChecked': false })
        } else if (value.length > 0 && value.length !== documentListState.length) {
            setCheckboxState({ ...checkboxState, 'indeterminate': true })
        } else {
            setCheckboxState({ ...checkboxState, 'indeterminate': false, 'isChecked': true })
        }
        setAddDocumentModalData({ ...addDocumentModalData, 'documentRequest': tempArr });
    }
    const selectAllOnChange = (event) => {
        const status = event.target.checked;
        if (status) {
            const setObj = { "document": documentListState }
            addDocumentUseForm.setFieldsValue(setObj)
            const tempArr = documentListState.map((data) => {
                return { 'documentResourceId': data.id }
            })
            setAddDocumentModalData({ ...addDocumentModalData, 'documentRequest': tempArr });
        } else {
            const setObj = { "document": [] }
            addDocumentUseForm.setFieldsValue(setObj)
            setAddDocumentModalData({ ...addDocumentModalData, 'documentRequest': [] });
        }
        setCheckboxState({ ...checkboxState, 'indeterminate': false, 'isChecked': status })
    }
    const selectBoxOpen = () => {
        const dropdownElement = document.querySelector('.add-document-select-dropdown');
        const dropdownHeight = dropdownElement.offsetHeight;
        if (dropdownHeight !== 0) {
            setAddDocumentConfigState({ ...addDocumentConfigState, 'dynamicSelectClass': dropdownHeight, 'dropdownTempHeight': dropdownHeight })
        }
    }
    const selectBoxClose = () => {
        setTimeout(
            function () {
                setAddDocumentConfigState({ ...addDocumentConfigState, 'dynamicSelectClass': 0 })
            }, 300);
    }
    const dropdownStatus = (isOpen) => {
        if (isOpen) {
            const dropdownHeight = addDocumentConfigState.dropdownTempHeight;
            if (dropdownHeight !== 0) {
                setAddDocumentConfigState({ ...addDocumentConfigState, 'dynamicSelectClass': dropdownHeight })
            }
        } else {
            setTimeout(
                function () {
                    setAddDocumentConfigState({ ...addDocumentConfigState, 'dynamicSelectClass': 0 })
                }, 300);
        }
    }
    const addDocumentForm = (
        <div className="table-details pad-0 ">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={addDocumentUseForm}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item style={{ paddingBottom: addDocumentConfigState.dynamicSelectClass }} className="custom-select text-left mb-10" name="document" label=''  >
                            <DynamicSelectbox
                                optionList={documentListState}
                                isMultiple={true}
                                onFocus={() => selectBoxOpen()}
                                onBlur={() => selectBoxClose()}
                                dropdownClassName="add-document-select-dropdown"
                                onDropdownVisibleChange={(status) => dropdownStatus(status)}
                                onChange={handleValuesChange}
                                placeholder={t('SELECT_DOCUMENT_TYPE')}
                                orderBy={'none'}

                            />
                        </Form.Item>
                        <Form.Item className="text-left mar-0" name="checkboxStatus" >
                            <Checkbox indeterminate={checkboxState.indeterminate} onChange={selectAllOnChange} checked={checkboxState.isChecked} >{t('SELECT_ALL')}</Checkbox>
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    )
    const handleCancel = () => {
        setAddDocumentModalData({ ...addDocumentModalData, 'isVisible': false, 'documentRequest': [] })
        setAddDocumentConfigState({ 'dynamicSelectClass': 0 })
        setCheckboxState({
            "indeterminate": false,
            "isChecked": false
        })
        const fields = addDocumentUseForm.getFieldsValue()
        const projects = { ...fields };
        projects["document"] = [];
        addDocumentUseForm.setFieldsValue(projects)

    }
    const handleConfirm = () => {
        let hasError = false
        let fields = []
        if (addDocumentModalData.documentRequest.length === 0) {
            hasError = true
            fields.push(t('SELECT_DOCUMENT_TYPE'))
        }
        if (!hasError) {
            const data = {
                "documentRequest": addDocumentModalData.documentRequest,
            }
            createDocumentRequests(data, selectedPersonId)
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const deleteDocumentRequestModal = (name, resourceId) => {
        if (!disableStatus) {
            return setDeleteRequestModalData({ 'isVisible': true, 'name': name, 'id': resourceId })
        }
    }
    const addDocumentType = () => {
        setAddModalState({ addModalState, 'isVisible': true })
    }
    const handleValuesAddChange = (changedObj, all) => {
        setAddModalFormData({ ...addModalFormData, ...changedObj });
    }
    const addModalForm = (
        <div className="table-details pad-0">
            <Form
                form={addForm}
                onValuesChange={handleValuesAddChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_TYPE_DOCUMENTS')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
    const handleAddCancel = () => {
        setAddModalFormData({});
        setAddModalState({ "isVisible": false })
        const fields = addForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        addForm.setFieldsValue(projects)
    }
    const handleAddConfirm = () => {
        let hasError = false
        let fields = []
        if (!addModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_TYPE_DOCUMENTS'))
        }

        if (!hasError) {
            const data = {
                "documentTypeName": addModalFormData.name,
                "isRequired": false
            }
            createDocumentType(data)
            setAddModalState({ "isVisible": false })
            setAddModalFormData({});
            const fields = addForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            addForm.setFieldsValue(projects)
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [kvkkStatus, setKvkkStatus] = useState(false)

    const kvkkControl = () => {
        if(kvkkStatus){
            setKvkkStatus(false)
        }else{
            setKvkkStatus(true)
        }
    }

    return (
        fileUploadListLoading ?
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
            :
            <div className='document-container pr-3'>
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteDocument}
                    confirmData={deleteModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_DOCUMENT_MODAL', { "name": deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <DynamicRequestModal
                    modalVisible={deleteRequestModalData?.isVisible}
                    setStateData={setDeleteRequestModalData}
                    postAction={deleteDocumentRequests}
                    confirmData={deleteRequestModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_DOCUMENT_REQUEST_MODAL', { "name": deleteRequestModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <DynamicRequestModal
                    modalVisible={addDocumentModalData?.isVisible}
                    setStateData={setAddDocumentModalData}
                    handleConfirm={handleConfirm}
                    handleCancel={handleCancel}
                    isForm={addDocumentForm}
                    singleButton={true}
                    maskClosable={false}
                    iconName='UilFilePlusAlt'
                    modalTitle={t('CREATE_DOCUMENT_REQUEST')}
                    successBtnTxt={t('CREATE_DOCUMENT_REQUEST2')}
                    description={''}
                />
                <DynamicRequestModal
                    modalVisible={addModalState.isVisible}
                    setStateData={setAddModalState}
                    isForm={addModalForm}
                    handleConfirm={handleAddConfirm}
                    handleCancel={handleAddCancel}
                    postAction={''}
                    confirmData={''}
                    iconName=''
                    modalTitle={t('CREATE_DOCUMENT_TYPE')}
                    description={''}
                    cancelBtnTxt={t('PARTNER_CANCEL')}
                    successBtnTxt={t('SETTINGS_CREATE')}
                />
                <div className={customTableScrolling(scrollLength, height, "w-100")}>
                    <div className='d-flex space-between'>
                        <div className='d-flex flex-wrap pad-top-10'>
                            {
                                !kvkkStatus ?
                                <Tooltip id='selectTooltip' title={t('CONSENT_REQUIRED_TO_UPLOAD_DOCUMENT')}>
                                <div className='mb-2' style={
                                    {
                                        width: '180px',
                                        height: '34px',
                                        border: '1px solid #d9d9d9',
                                        marginRight: '11px',
                                        cursor: 'not-allowed'
                                    }
                                }>
                                    
                                </div>
                            </Tooltip>
                                
                            :
                            
                            <DynamicSelectbox
                                disabled={disableStatus || !kvkkStatus}
                                placeholder={"Seçiniz"}
                                onChange={changeDocumentType}
                                optionList={documentTypeState}
                                onFocus={() => getOrganizationDocumentType()}
                                orderBy={'none'}
                                dynamiCreateBtnStatus={true}
                                dynamicCreateBtnCode={
                                    <>
                                        <Divider className='my-1' /> 
                                        <div className='w-100 px-2'
                                        disabled={disableStatus || !kvkkStatus} >
                                            <Button className="primary-btn w-100 flex-class svg-class" icon={<UilPlus />} onClick={() => addDocumentType()}>
                                                {t('CREATE_NEW')} { }
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                            
                            

                            }
                            
                            <div className='py-2'>
                                <Checkbox checked={kvkkStatus} onClick={() => kvkkControl()}>
                                    {selectedLanguage == 'tr-TR'
                                        ? <a href="https://www.hrplan.net/sozlesmeler-ve-politikalar/" target="_blank" className="terms-text-link">
                                            {t('KVKK_SCOPE')}
                                        </a>
                                        : <a href="https://www.hrplan.net/sozlesmeler-ve-politikalar/" target="_blank" className="terms-text-link">
                                            {t('KVKK_SCOPE')}
                                        </a>
                                    }
                                    
                                    <span> {t('CONSENT_FORM')}</span>
                                </Checkbox>
                            </div>
                            {
                                !kvkkStatus ? 
                                <Tooltip id='tooltip' className='editToolTip' title={t('CONSENT_REQUIRED_TO_UPLOAD_DOCUMENT')}>
                            
                                <Button style={
                                    {
                                        width: '180px',
                                        height: '34px'
                                    }
                                } disabled={!kvkkStatus} className='primary-btn unicButton' onClick={() => uploadMissingFile(selectDocumentResourceId?.id)} >{t('UPLOAD_DOCUMENT')}</Button>
                                </Tooltip>
                                :
                                <Button className='primary-btn w-180 unicButton' onClick={() => uploadMissingFile(selectDocumentResourceId?.id)} >{t('UPLOAD_DOCUMENT')}</Button>

                            }
                            

                        </div>
                        <div className={!kvkkStatus ? 'create-document-request-button disabled w-180' : 'create-document-request-button w-180'} onClick={() => openAddDocumentRequestModal()}>
                            <span>{t('CREATE_DOCUMENT_REQUEST')}</span>
                        </div>
                    </div>
                    {downloadAbleFileLength > 0 &&
                        <div className='error-container'>
                            <h1 className='error-containter-title'>{t('MISSING_DOCUMENTS')}</h1>
                            {fileUploadList.filter(item => !item.isUploaded && item.isInitialRequest).map((data) => {
                                return <React.Fragment>
                                    <div className='error-container-text error-container-table-title'><span>{data.documentTypeName}</span></div>
                                    <div className='error-container-text clickable'><span className={disableStatus ? "green disabled" : "green"} onClick={() => uploadMissingFile(data.organizationDocumentResourceId)}>{t('UPLOAD')}</span></div>
                                    <div className='error-container-text clickable pad-left-16'><span className={disableStatus ? "red disabled" : "red"} onClick={() => deleteDocumentRequestModal(data.documentTypeName, data.personDocumentRequestResourceId)}>{t('CANCEL_DOCUMENT_REQUEST')}</span></div>
                                </React.Fragment>
                            })}
                        </div>
                    }
                    <Upload {...uploadProgress} >
                        <span id="upload-btn"></span>
                    </Upload >
                    <div className='document-upload-card-container'>
                        {fileUploadList.length > 0 && fileUploadList.filter(item => item.isUploaded).map((data, index) => {
                            return <Card key={index} className='document-upload-card my-3'>
                                <Row>
                                    <Col span={1}>
                                        <FileAltIcon />
                                    </Col>
                                    <Col span={19}>
                                        <Row gutter={[0, 10]}>
                                            <Col span={24}>
                                                <span className='document-text'>{data.documentTypeName}</span>
                                            </Col>
                                            <Col span={24}>
                                                <span className='document-text'>{t('FILE_NAME') + ' : ' + data.organizationDocumentName}</span>
                                            </Col>
                                            <Col span={24}>
                                                <span className='document-text'>{data.uploadedDate && t('UPLOAD_DATE') + ' : ' + moment(data.uploadedDate).format(dateFormatConvert)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <Col span={24}>
                                            <div className='document-action-buttons'>
                                                <Button className="w-180-h-40 transparent-btn" onClick={() => downloadDocument(data.fileName)}><UilFileDownload /></Button>
                                                <Button disabled={disableStatus} className="w-180-h-40 transparent-btn" onClick={() => deleteDocumentModal(data.uploadedResourceId, data.organizationDocumentName)}><UilTrash /></Button>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </Card>
                        })}
                    </div>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        fileUploadList: state.person.fileUploadList,
        organizationDocumentTypeList: state.organization.organizationDocumentTypeList,
        organizationDocumentTypeListWithUserId: state.organization.organizationDocumentTypeListWithUserId,
        auth: state.auth,
        fileUploadListLoading: state.person.fileUploadListLoading,
        deleteDocumentResponse: state.person.deleteDocumentResponse,
        createDocumentResponse: state.person.createDocumentResponse,
        deleteDocumentRequestResponse: state.person.deleteDocumentRequestResponse,
        createDocumentTypeResponse: state.documentType.createDocumentTypeResponse,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getFileUploadList: (resourceId) => { dispatch(getFileUploadList(resourceId)) },
        getOrganizationDocumentType: () => { dispatch(getOrganizationDocumentType()) },
        getOrganizationDocumentTypeWithUserId: (resourceId) => { dispatch(getOrganizationDocumentTypeWithUserId(resourceId)) },
        deleteDocument: (documentRequestResourceId) => { dispatch(deleteDocument(documentRequestResourceId)) },
        deleteDocumentReset: () => { dispatch(deleteDocumentReset()) },
        createDocumentRequests: (createData, resourceId) => { dispatch(createDocumentRequests(createData, resourceId)) },
        createDocumentRequestsReset: () => { dispatch(createDocumentRequestsReset()) },
        deleteDocumentRequests: (documentRequestResourceId) => { dispatch(deleteDocumentRequests(documentRequestResourceId)) },
        deleteDocumentRequestReset: () => { dispatch(deleteDocumentRequestReset()) },
        createDocumentType: (data) => { dispatch(createDocumentType(data)) },
        resetCreateDocumentType: () => { dispatch(resetCreateDocumentType()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab);