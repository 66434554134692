import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config'; 
const FileDownload = require('js-file-download');

const ReportAction = {
    
    EMPLOYEE_FILTER_SUCCESS: "EMPLOYEE_FILTER_SUCCESS",
    EMPLOYEE_FILTER_FAIL: "EMPLOYEE_FILTER_FAIL",
    EMPLOYEE_FILTER_REQUESTS:"EMPLOYEE_FILTER_REQUESTS",
    REPORT_PERSON_EXPORT_FAIL:"REPORT_PERSON_EXPORT_FAIL",
    REPORT_PERSON_EXPORT_RESET:"REPORT_PERSON_EXPORT_RESET",
    TIME_OFF_ALL_REPORT_FILTER_REQUESTS:"TIME_OFF_ALL_REPORT_FILTER_REQUESTS",
    TIME_OFF_ALL_REPORT_FILTER_SUCCESS:"TIME_OFF_ALL_REPORT_FILTER_SUCCESS",
    TIME_OFF_ALL_REPORT_FILTER_FAIL:"TIME_OFF_ALL_REPORT_FILTER_FAIL",
    TIME_OFF_ALL_REPORT_EXPORT_FAIL:"TIME_OFF_ALL_REPORT_EXPORT_FAIL",
    TIME_OFF_ALL_REPORT_EXPORT_RESET:"TIME_OFF_ALL_REPORT_EXPORT_RESET",
    TIME_OFF_YEARS_REPORT_FILTER_REQUESTS:"TIME_OFF_YEARS_REPORT_FILTER_REQUESTS",
    TIME_OFF_YEARS_REPORT_FILTER_SUCCESS:"TIME_OFF_YEARS_REPORT_FILTER_SUCCESS",
    TIME_OFF_YEARS_REPORT_FILTER_FAIL:"TIME_OFF_YEARS_REPORT_FILTER_FAIL",
    TIME_OFF_YEARS_REPORT_EXPORT_FAIL:"TIME_OFF_YEARS_REPORT_EXPORT_FAIL",
    TIME_OFF_YEARS_REPORT_EXPORT_RESET:"TIME_OFF_YEARS_REPORT_EXPORT_RESET"
  };


const employeeTable = (searchData) => {
  return async (dispatch) => {
    
    dispatch({ type: ReportAction.EMPLOYEE_FILTER_REQUESTS });
    
    try {
        const data = JSON.stringify(searchData);
        var config = {
          method: 'post',
          url: apiUrl+'/persons/searchDetail',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
  
              dispatch({ type: ReportAction.EMPLOYEE_FILTER_SUCCESS, payload: response.data.data });
          }else{
  
              dispatch({ type: ReportAction.EMPLOYEE_FILTER_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
               "results": []
            };
            dispatch({ type: ReportAction.EMPLOYEE_FILTER_SUCCESS, payload: emptyData });
          }
          dispatch({ type: ReportAction.EMPLOYEE_FILTER_FAIL, payload: error.message });
        });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: ReportAction.EMPLOYEE_FILTER_FAIL, payload: error.message });
        }
      }
    };
  };
  const employeeReportDownload  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);

      try {
          var config = {
            method: 'post',
            url: apiUrl+'/persons/exportDetail',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          
          hrApi(config)
          .then(function (response,typeId)  {
  
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
 
               FileDownload(response.data, 'personExport_'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: ReportAction.REPORT_PERSON_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: ReportAction.REPORT_PERSON_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: ReportAction.REPORT_PERSON_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetEmployeeReportDownload = () => {
    
    return async (dispatch) => {
      dispatch({ type: ReportAction.REPORT_PERSON_EXPORT_RESET, payload:{} });
      };
  }
  const getTimeOffAllReportFilter = (searchData) => {
    return async (dispatch) => {
      
      dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_FILTER_REQUESTS });
      
      try {
          const data = JSON.stringify(searchData);
          var config = {
            method: 'post',
            url: apiUrl+'/timeOffRequests/search/detail',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
    
                dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_FILTER_SUCCESS, payload: response.data.data });
            }else{
    
                dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_FILTER_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                "pageNumber": 1,
                "pageSize": 1,
                "totalNumberOfPages": 1,
                "totalNumberOfRecords": 0,
                 "results": []
              };
              dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_FILTER_SUCCESS, payload: emptyData });
            }
            dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_FILTER_FAIL, payload: error.message });
          });
    
           
        } catch (error) {
          if (error.response) {
              dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_FILTER_FAIL, payload: error.message });
          }
        }
      };
    };
    const timeOffAllReportDownload  = (props) => {
      let newDate = Date.now();
      return async (dispatch) => {
        var data = JSON.stringify(props);
  
        try {
            var config = {
              method: 'post',
              url: apiUrl+'/timeOffRequests/search/detail/export',
              headers: { 
                'Content-Type': 'application/json; charset=utf8'
              },
              data : data,
              responseType: 'blob', // Important
            };
            
            hrApi(config)
            .then(function (response,typeId)  {
    
              if(response.status===200){
                const exportTypes = {
                  0: "xlsx",
                  1:"csv",
                  2:"pdf"
                };
   
                 FileDownload(response.data, 'timeOffAllExport_'+newDate+'.'+ exportTypes[props.exportType]);
              }else{
                var reader = new FileReader();
                reader.onload = function() {
                  var renderData = reader.result;
                  dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_EXPORT_FAIL, payload: renderData });
                }
                reader.readAsText(response.data);
              }
            })
            .catch(function (error) {
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(error.response.data);
            });
    
           
        } catch (error) {
          if (error.response) {
              dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_EXPORT_FAIL, payload: error.message });
          }
        }
      };
    };
    const resetTimeOffAllReportDownload = () => {
      
      return async (dispatch) => {
        dispatch({ type: ReportAction.TIME_OFF_ALL_REPORT_EXPORT_RESET, payload:{} });
        };
    }

    const getTimeOffYearsReportFilter = (searchData) => {
      return async (dispatch) => {
        
        dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_FILTER_REQUESTS });
        
        try {
            const data = JSON.stringify(searchData);
            var config = {
              method: 'post',
              url: apiUrl+'/timeOffRequests/search/remainingTimeOffRequest',
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            hrApi(config)
            .then(function (response) {
              if(response.status===200){
      
                  dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_FILTER_SUCCESS, payload: response.data.data });
              }else{
      
                  dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_FILTER_FAIL, payload: response.data.Message });
              }
            })
            .catch(function (error) {
              if(error.response?.status === 404){
                const emptyData = {
                  "pageNumber": 1,
                  "pageSize": 1,
                  "totalNumberOfPages": 1,
                  "totalNumberOfRecords": 0,
                   "results": []
                };
                dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_FILTER_SUCCESS, payload: emptyData });
              }
              dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_FILTER_FAIL, payload: error.message });
            });
      
             
          } catch (error) {
            if (error.response) {
                dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_FILTER_FAIL, payload: error.message });
            }
          }
        };
      };
      const timeOffYearsReportDownload  = (props) => {
        let newDate = Date.now();
        return async (dispatch) => {
          var data = JSON.stringify(props);
    
          try {
              var config = {
                method: 'post',
                url: apiUrl+'/timeOffRequests/search/remainingTimeOffRequest/export',
                headers: { 
                  'Content-Type': 'application/json; charset=utf8'
                },
                data : data,
                responseType: 'blob', // Important
              };
              
              hrApi(config)
              .then(function (response,typeId)  {
      
                if(response.status===200){
                  const exportTypes = {
                    0: "xlsx",
                    1:"csv",
                    2:"pdf"
                  };
     
                   FileDownload(response.data, 'timeOffYears'+newDate+'.'+ exportTypes[props.exportType]);
                }else{
                  var reader = new FileReader();
                  reader.onload = function() {
                    var renderData = reader.result;
                    dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_EXPORT_FAIL, payload: renderData });
                  }
                  reader.readAsText(response.data);
                }
              })
              .catch(function (error) {
                var reader = new FileReader();
                reader.onload = function() {
                  var renderData = reader.result;
                  dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_EXPORT_FAIL, payload: renderData });
                }
                reader.readAsText(error.response.data);
              });
      
             
          } catch (error) {
            if (error.response) {
                dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_EXPORT_FAIL, payload: error.message });
            }
          }
        };
      };
      const resetTimeOffYearsReportDownload = () => {
        
        return async (dispatch) => {
          dispatch({ type: ReportAction.TIME_OFF_YEARS_REPORT_EXPORT_RESET, payload:{} });
          };
      }
  export {
    ReportAction,
    employeeTable,
    employeeReportDownload,
    resetEmployeeReportDownload,
    getTimeOffAllReportFilter,
    timeOffAllReportDownload,
    resetTimeOffAllReportDownload,
    getTimeOffYearsReportFilter,
    timeOffYearsReportDownload,
    resetTimeOffYearsReportDownload
  };