import { connect } from 'react-redux';

function Can(props) {
    const I = props.I;
    const permissions = props.permissions;
    const canDoIt = permissions.includes(I);
    return (canDoIt) ? props.children : '';
}

const mapStateToProps = function (state) {
    return {
        permissions: state.profile.permissions,
    }
}

export default connect(mapStateToProps, null)(Can);
