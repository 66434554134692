import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetTimeOffInfoNotifications } from '../../../redux/actions/timeOff.actions';
import { getDataset } from "../../../redux/actions/dataset-action";
import { getPersonTravel } from '../../../redux/actions/travel.actions';

import { Form, ConfigProvider, Badge, Checkbox, Button, DatePicker, Input, Row, Col, List } from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import TR from 'antd/es/date-picker/locale/tr_TR';

import MaskedInput from "react-text-mask";
import DynamicTable from '../../dynamicTable/index';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';

import { dynamicDateInputFormatter, localeLang, dateFormatList, dateFormatConvert, numberFormatTR, dateFormat } from "../../../utils/config";
import { priceDecimalWithDot, focusDateInput, blurDateInput, NumberList, repaymentOptions } from '../../../utils/commonFormFunctions'
import '../../../styles/advances.scss';
import '../../../styles/person.scss';
import '../../../styles/expense.scss';

function AdvanceDetailApproval(props) {
    const { t } = useTranslation();
    const { status, dynamicStepKey, advanceDetails, advanceDetailsTable, advanceDetailsTableLoading, form, setAdvanceRequestObj,
        editPaymentTableStatus, advancePayState, setAdvancePayState, profile, getDataset, getPersonTravel, personTravel, currencyTypes,
        advanceRequestState, setAdvanceRequestState, advanceAmountList, setAdvanceAmountList } = props;
    var numberOptions = NumberList(12, true);

    const [showAdvanceTable, setShowAdvanceTable] = useState(true);
    const [showPaymentTable, setShowPaymentTable] = useState(true);
    const [searchData, setSearchData] = useState();
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        'advanceNeedDate': dateFormatConvert
    })
    const [numberOfInstallmentState, setNumberOfInstallmentState] = useState([]);
    const [personTravelList, setPersonTravelList] = useState([]);
    const [currencyList, setCurrencyList] = useState();


    const numberOfInstallmentsColumn = [
        {
            title: `${t('NUMBER_OF_INSTALLMENTS')}`,
            dataIndex: 'numberOfInstallments',
            key: 'numberOfInstallments',
        },
        {
            title: `${t('PARTNER_AMOUNT2')}`,
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    useEffect(() => {
        var arr = [];
        personTravel.length > 0 &&
            personTravel.map((data) =>
                arr.push({
                    'id': data.travelDefinitionId,
                    'displayValue': data.travelDescription
                })
            );
        setPersonTravelList(arr);
    }, [personTravel])
    useEffect(() => {
        var arr = [];
        currencyTypes.length > 0 &&
            currencyTypes.map((data) =>
                arr.push({
                    'id': parseInt(data.id),
                    'displayValue': data.displayValue
                }))
        setCurrencyList(arr);
    }, [currencyTypes])



    useEffect(() => {
        if (advanceDetailsTable.length === 0) {
            setShowAdvanceTable(false)
        } else {
            setShowAdvanceTable(true)
        }
    }, [advanceDetailsTable])
    useEffect(() => {
        const replaceArr = numberOfInstallmentState.length > 0 && numberOfInstallmentState.map((data) => {
            return {
                'installmentNumber': data.numberOfInstallments,
                'amount': data.amountData,
                'currencyName': advanceAmountList[0].currency?.displayValue,
                'isPayment': false
            }
        })
        setAdvanceRequestObj({ ...advanceRequestState, 'PaymentList': replaceArr });
    }, [numberOfInstallmentState])
    useEffect(() => {
        if (advanceRequestState?.RepaymentType?.id === 1 && advanceRequestState?.NumberOfInstallments?.id > 0 && advanceRequestState?.FirstInstallmentPaymentMonth) {
            var data = [];
            const total = advanceAmountList[0].amount;
            const currency = advanceAmountList[0].currency?.displayValue;

            for (var i = 1; i <= advanceRequestState?.NumberOfInstallments?.id; i++) {
                data.push({
                    'numberOfInstallments': i,
                    'amount': parseFloat(total / advanceRequestState?.NumberOfInstallments?.id).toLocaleString('tr-TR') + " " + currency,
                    'amountData': parseFloat(total / advanceRequestState?.NumberOfInstallments?.id)
                });
            }
            setNumberOfInstallmentState(data);
        }
        setAdvanceRequestObj(advanceRequestState);
    }, [advanceRequestState])


    useEffect(() => {
        let advanceObj = advanceAmountList.map((data) => {
            return {
                'Amount': data?.amount,
                'CurrencyId': data?.currency?.id && parseInt(data?.currency?.id)
            }
        });
        setAdvanceRequestState({ ...advanceRequestState, Amounts: advanceObj })
    }, [advanceAmountList])

    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        visibilityOptionExcludes: ['needDate', 'advanceType', 'amountsList', 'stateType'],
        page: "advance",
        showVisibilityMenu: false
    })
    const onChange = (e, index) => {
        var list = [...advancePayState];
        list[index].isPayment = e.target.checked;
        setAdvancePayState(list);
    };
    const columns = [
        {
            title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
            dataIndex: 'needDate',
            key: 'needDate',
            sorter: true,
        },
        {
            title: `${t('ORGANIZATION_STATU')}`,
            dataIndex: 'stateType',
            key: 'stateType',
            sorter: true,
            render: state => {
                if (state.id === '1') {
                    if (state.id === '1') {
                        return <div className='yellow'>
                            {state.displayValue}
                        </div>
                    } else if (state.id === '2') {
                        return <div>{state.displayValue}</div>
                    } else if (state.id === '3') {
                        return <div className='red'>{state.displayValue}</div>
                    } else if (state.id === '4') {
                        return <div className=''>{state.displayValue}</div>
                    } else {
                        return <div>{state?.displayValue}</div>
                    }
                }
                else if (state.id === '2') {
                    return <div>{state.displayValue}</div>
                } else if (state.id === '3') {
                    return <div className='red'>{state.displayValue}</div>
                } else if (state.id === '4') {
                    return <div className=''>{state.displayValue}</div>
                } else {
                    return <div>{state?.displayValue}</div>
                }
            }
        },
        {
            title: `${t('ADVANCE_TYPE')}`,
            dataIndex: 'advanceType',
            key: 'advanceType',
            sorter: true,
        },
        {
            title: `${t('PARTNER_AMOUNT2')}`,
            dataIndex: 'amountsList',
            key: 'amountsList',
            sorter: true,
            render: state => {
                return Array.isArray(state) ? state.length > 0 ?
                    state.map((data) => numberFormatTR.format(data.amount) + ' ' + data.currencyName).join(', ')
                    :
                    ' ' : ''
            }
        },
    ];
    const paginationConfig = () => ({
        total: 3,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: 3 < 10 ? ['none', 'none'] : ['none', 'bottomLeft'],
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const tableData = advanceDetailsTable.map((row) => {
        return (
            {
                "key": row.resourceId,
                "needDate": moment(row.needDate).format(dateFormatConvert),
                // "advanceType": row.advanceType?.displayValue,
                "advanceType": row.advanceMasterTypeId === 1 ? t('JOB_ADVANCE') : t('SALARY_ADVANCE'),
                "amountsList": row.amountsList,
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue },
            }
        );
    });
    const handleRemoveClick = index => {
        const list = [...advanceAmountList];
        list.splice(index, 1);
        setAdvanceAmountList(list);
    };
    const handleAddNewRow = (page) => {
        var requiredFields = {};
        let hasError = false
        let fields = []
        var list = [...advanceAmountList];
        requiredFields = {
            'amount': false,
            'currency': false,
        }
        list.map((data) => {
            if (!data.amount) {
                requiredFields.amount = true;
            }
            if (!data.currency?.id) {
                requiredFields.currency = true;
            }
        });
        if (requiredFields.amount) {
            hasError = true
            fields.push(t('ADVANCE_AMOUNT'));
        }
        if (requiredFields.currency) {
            hasError = true;
            fields.push(t('PARTNER_CURRENCY'));
        }
        if (!hasError) {
            list.push({
                'amount': null,
                'amountXWrite': null,
                'currency': null,
            })
            requiredFields = {
                "amount": false,
                'currency': false,
            }
            setAdvanceAmountList(list);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };
    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === 'RepaymentType') {
            const fields = form.getFieldsValue()
            const formFields = { ...fields };
            formFields["NumberOfInstallments"] = { id: null, name: null };
            formFields["FirstInstallmentPaymentMonth"] = null;
            form.setFieldsValue(formFields);
            setAdvanceRequestState({
                ...advanceRequestState,
                'RepaymentType': changedObj.RepaymentType,
                'NumberOfInstallments': null,
                'FirstInstallmentPaymentMonth': null,
            });
            setNumberOfInstallmentState([]);
        }
        else if (key === 'AdvanceNeedDate') {
            if (changedObj.AdvanceNeedDate) {
                setAdvanceRequestState({ ...advanceRequestState, 'AdvanceNeedDate': moment(changedObj.AdvanceNeedDate).format(dateFormat) });
            } else {
                setAdvanceRequestState({ ...advanceRequestState, 'AdvanceNeedDate': null });
            }
        } else {
            setAdvanceRequestState({ ...advanceRequestState, ...changedObj });
        }
    }
    const handleDynamicListInputChange = (e, index, key, inputType) => {
        var list = [...advanceAmountList];
        switch (inputType) {
            case 'select':
                list[index][key] = e;
                return setAdvanceAmountList(list);
            case 'priceInput':
                const val = e.target.value;
                const replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                const parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                list[index][key + "XWrite"] = val;
                return setAdvanceAmountList(list);
            default:
                return null;
        }
    };
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <div className="pad-top-20">
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical advance-scrollable"
                    requiredMark={true}
                >
                    {(!status && dynamicStepKey === 1) ?
                        <>
                            <Form.Item label={t('ADVANCE_REQUESTER')}>
                                <p className="black f-16">
                                    {advanceDetails.person?.fullName}
                                </p>
                            </Form.Item>
                            <Form.Item label={t('PARTNER_DEPARTMENT')}>
                                <p className="black f-16">
                                    {advanceDetails.departmentName}
                                </p>
                            </Form.Item>
                            <Form.Item label={t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}>
                                <p className="black f-16">
                                    {moment(advanceDetails.needDate).format(dateFormatConvert)}
                                </p>
                            </Form.Item>
                            <Form.Item label={t('ADVANCE_TYPE')}>
                                <p className="black f-16">
                                    {advanceDetails.advanceMasterTypeId === 1 ? t('JOB_ADVANCE') : t('SALARY_ADVANCE')}
                                </p>
                            </Form.Item>
                            <Form.Item label={t('TOTAL_AMOUNT')} >
                                <p className="black f-16">
                                    {
                                        Array.isArray(advanceDetails.amountsList) ?
                                            advanceDetails.amountsList.map((data) => {

                                                const writeData = numberFormatTR.format(data.amount) + ' ' + data.currencyName;
                                                return writeData
                                            }).join(', ')
                                            :
                                            advanceDetails.amount + ' ' + advanceDetails.currencyName
                                    }
                                </p>
                            </Form.Item>
                            {advanceDetailsTable.length > 0 &&
                                <Form.Item>
                                    {showAdvanceTable ?
                                        <p onClick={() => setShowAdvanceTable(false)} className="show-other-inventory pad-0 mar-0">{t('HIDE_OLD_ADVANCES')}</p>
                                        :
                                        <p onClick={() => setShowAdvanceTable(true)} className="show-other-inventory pad-0 ">{t('SHOW_OLD_ADVANCES')}</p>
                                    }
                                    {showAdvanceTable ?
                                        <div className="advance-details-table">
                                            <div className="table-container table-hide-all-buttons blue-table" >
                                                <DynamicTable columns={columns} dataSource={tableData} hideScroll={true}
                                                    tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={() => null}
                                                    onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                    isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={advanceDetailsTableLoading} />
                                            </div>
                                        </div>
                                        : ''}
                                </Form.Item>
                            }
                            {advanceDetails?.returnPaymentTypeId === 1 &&
                                <>
                                    <Form.Item label={t('FIRST_INSTALLMENT_PAYMENT_MONTH')} className="mar-0 pad-6">
                                        <p className="black f-16">
                                            {advanceDetails.firstPaymentMount}
                                        </p>
                                    </Form.Item>
                                    {advanceDetails?.returnPaymentTypeId === 1 &&
                                        <>
                                            <Form.Item>
                                                {showPaymentTable ?
                                                    <p onClick={() => setShowPaymentTable(false)} className="show-other-inventory pad-0 mar-0">{t('PAYMENT_HIDE_PLAN')}</p>
                                                    :
                                                    <p onClick={() => setShowPaymentTable(true)} className="show-other-inventory pad-0 mar-0">{t('PAYMENT_SHOW_PLAN')}</p>
                                                }
                                            </Form.Item>
                                            {showPaymentTable &&
                                                <div className="expense-drawer pad-30">
                                                    <div className="table-hide-all-buttons blue-table">
                                                        <div className="expense-drawer-expense-card-table">
                                                            <div className="row">
                                                                <label className={"installment m-r-10"}> {t('NUMBER_OF_INSTALLMENTS')}</label>
                                                                <label className="advancePay m-r-10"> {t('PARTNER_AMOUNT2')}</label>
                                                                <label className="checkstatus m-r-10"> {t('ORGANIZATION_STATU')}</label>
                                                            </div>
                                                            {advancePayState.map((data, index) => {
                                                                return (<div className="editable advance-details-table" key={index} >
                                                                    <label className="installment m-r-10">{data.installmentNumber}</label>
                                                                    <label className="advancePay m-r-10">{parseFloat(data.amount).toLocaleString('tr-TR') + " " + data.currencyName}</label>
                                                                    <label className="checkstatus m-r-10">
                                                                        <Checkbox checked={data.isPayment} disabled={!editPaymentTableStatus} onChange={(e) => onChange(e, index)}>
                                                                            {t('PAID')}
                                                                        </Checkbox>
                                                                    </label>
                                                                </div>);
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                        </>
                                    }
                                </>
                            }
                            <Form.Item label={t('OTHER_INFORMATION_ON_REQUEST')} style={{ wordBreak: 'break-word' }} >
                                <p className="black f-16" >
                                    {advanceDetails.description ? <div><span className="">{t('PARTNER_RETURN_DESCRIPTION')} : </span> {advanceDetails.description} </div> : ''}
                                    {advanceDetails.relatedTravelIdx ? <div><span className="">{t('TRAVEL_NOTES')} : </span> {advanceDetails.relatedTravelName} </div> : ''}
                                    {advanceDetails.notes ? <div><span className="">{t('NOTES')} : </span> {advanceDetails.notes} </div> : ''}
                                    {(!advanceDetails.description && !advanceDetails.relatedTravelName && !advanceDetails.notes) && '-'}
                                </p>
                            </Form.Item>
                            <Form.Item style={{ wordBreak: 'break-word' }} label={t('TIME_OFF_APPROVAL_NOTES')}>
                                {
                                    advanceDetails.approvalDescriptions.length > 0 ?
                                        advanceDetails.approvalDescriptions.map((approvalDescriptions) => {
                                            return (
                                                <p className="black f-16">
                                                    {moment(approvalDescriptions?.approvalDate).format('LL')} - {approvalDescriptions?.personName} - {approvalDescriptions?.description}
                                                </p>
                                            )
                                        })
                                        :
                                        <p className="black f-16">
                                            {t('TIME_OFF_THERE_IS_NO_APPROVAL_NOTE')}
                                        </p>
                                }
                            </Form.Item>
                            {
                                parseInt(advanceDetails.stateTypeId) > 1 &&
                                <Form.Item label={t('ORGANIZATION_STATU')} style={{ wordBreak: 'break-word' }} >
                                    <div className="global-error-badge">
                                        <Badge style={{ backgroundColor: '#52c41a' }} >{advanceDetails.stateTypeName}</Badge>
                                    </div>
                                </Form.Item>
                            }
                        </>
                        :
                        <>
                            <Form.Item name="AdvanceTypes" label={t('CHOOSE_ADVANCE_TYPE') + " *"} >
                                <p className="f-16 black">
                                    {advanceDetails.advanceMasterTypeId === 1 ? t('JOB_ADVANCE') : t('SALARY_ADVANCE')}
                                </p>
                            </Form.Item>
                            <Form.Item name="AdvanceNeedDate" className="custom-select pad-top-10" label={t('PARTNER_NEED_DATE') + ' *'}>
                                <DatePicker
                                    locale={localeLang}
                                    format={datePickerFormatType.advanceNeedDate}
                                    placeholder={t('PLACEHOLDER_DATE')}
                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'advanceNeedDate', dynamicDateInputFormatter)}
                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'advanceNeedDate', dateFormatList)}
                                />
                            </Form.Item>
                            <>
                                <Row>
                                    <Col className="pad-12" span={24}>
                                        <Row>
                                            <Col span={10} >
                                                <h3 className="black f-16 fw-600">{t('ADVANCE_AMOUNT') + ' *'}</h3>
                                            </Col>
                                            <Col span={13} offset={1}>
                                                <h3 className="black f-16 fw-600">{t('PARTNER_CURRENCY') + ' *'}</h3>
                                            </Col>
                                        </Row>
                                        {
                                            advanceAmountList.map((item, index) => {
                                                return (
                                                    <Row key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": '15px' }} >
                                                        <Col span={10} >
                                                            <MaskedInput
                                                                className="ant-input"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                mask={priceDecimalWithDot}
                                                                value={item.amountXWrite}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'amount', 'priceInput')}
                                                            />
                                                        </Col>
                                                        <Col span={6} offset={1}>
                                                            <div className="custom-select">
                                                                <DynamicSelectbox className="w-100" style={{ marginTop: '3px' }}
                                                                    value={item.currency}
                                                                    optionList={currencyList}
                                                                    onFocus={() => getDataset(23)}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'currency', 'select')}
                                                                    placeholder={t('PARTNER_SELECT')}
                                                                />
                                                            </div>
                                                        </Col>
                                                        {index > 0 &&
                                                            <Col offset={1} span={2}>
                                                                <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                )
                                            })
                                        }
                                        <div>
                                            {advanceDetails.advanceMasterTypeId === 1 &&
                                                <div className="link-text my-4" onClick={() => handleAddNewRow()}>{t('TIME_OFF_REQUEST_NEW_ADD')}</div>
                                            }
                                        </div>
                                    </Col>

                                </Row>
                                {advanceDetails.advanceMasterTypeId === 1 &&
                                    <Form.Item name="RequestCreditCard" valuePropName="checked">
                                        <Checkbox>{t('I_REQUEST_A_COMPANY_CREDIT_CARD')}</Checkbox>
                                    </Form.Item>
                                }
                                {advanceDetails.advanceMasterTypeId === 2 &&
                                    <>
                                        <Form.Item name="RepaymentType" className="custom-select" label={t('FORM_OF_REPAYMENT')}>
                                            <DynamicSelectbox className="custom-select"
                                                optionList={repaymentOptions}
                                                orderBy={'asc'}
                                                placeholder={t('PARTNER_SELECT')}
                                            />
                                        </Form.Item>
                                        {advanceRequestState.RepaymentType?.id === 1 &&
                                            <Form.Item name="NumberOfInstallments" className="custom-select" label={t('NUMBER_OF_INSTALLMENTS')}>
                                                <DynamicSelectbox className="custom-select"
                                                    optionList={numberOptions}
                                                    orderBy={'asc'}
                                                    placeholder={t('PARTNER_SELECT')}
                                                />
                                            </Form.Item>
                                        }
                                        {advanceRequestState.RepaymentType?.id === 1 &&
                                            <Form.Item name="FirstInstallmentPaymentMonth" className="custom-select" label={t('FIRST_INSTALLMENT_PAYMENT_MONTH')}>
                                                <DatePicker
                                                    locale={TR}
                                                    format={'MMMM'}
                                                    picker="month"
                                                    placeholder={t('SELECT_MONTH')}
                                                />
                                            </Form.Item>
                                        }
                                        {numberOfInstallmentState.length > 0 &&
                                            <Form.Item label={t('PAYMENT_PLAN')}>
                                                <div className="advance-table">
                                                    <DynamicTable columns={numberOfInstallmentsColumn} dataSource={numberOfInstallmentState} center={true}
                                                        tableConfig={tableConfig()} paginationConfig={paginationConfig(numberOfInstallmentState.length)} onFilter={() => null}
                                                        onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                        isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true} />
                                                </div>
                                            </Form.Item>
                                        }
                                    </>
                                }
                                {advanceRequestState?.AdvanceTypes === 1 &&
                                    <Form.Item name="RelatedTravelIdx" className="custom-select" label={t('TRAVEL_NOTES')}>
                                        <DynamicSelectbox className="custom-select"
                                            optionList={personTravelList}
                                            onFocus={() => getPersonTravel(profile?.resourceId)}
                                            placeholder={t('PARTNER_SELECT')}
                                        />
                                    </Form.Item>
                                }
                                <Form.Item name="Description" label={t('ADVANCE_DESCRIPTION')}>
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item name="Notes" className="custom-select" label={t('NOTES')}>
                                    <Input.TextArea />
                                </Form.Item>
                            </>
                        </>
                    }
                </Form>
            </div>
        </ConfigProvider >
    )
}
const mapStateToProps = (state) => {
    return {
        timeOffInfoByTypeDate: state.timeOff.timeOffInfoByTypeDate,
        timeOffRequestDetails: state.timeOff.timeOffRequestDetails,
        timeOffInfoByTypeDateWarning: state.timeOff.timeOffInfoByTypeDateWarning,
        advanceDetails: state.advance.advanceDetails,
        advanceDetailsLoading: state.advance.advanceDetailsLoading,
        advanceDetailsTable: state.advance.advanceDetailsTable.results,
        advanceDetailsTableLoading: state.advance.advanceDetailsTableLoading,
        personTravel: state.travel.personTravel,
        currencyTypes: state.dataset.currencyTypes,
        profile: state.profile
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        resetTimeOffInfoNotifications: () => { dispatch(resetTimeOffInfoNotifications()) },
        getDataset: (type) => { dispatch(getDataset(type)) },
        getPersonTravel: (resourceId) => { dispatch(getPersonTravel(resourceId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceDetailApproval);