import React from "react";
import { CheckboxIcon } from '../Icon/Icons';
const PasswordStrengthIndicator = ({
    validity: { minChar, number, specialChar, upperLowerCase }
}) => {
    return (

        <div className="password-strength">
            <PasswordStrengthIndicatorItem
                isValid={minChar}
                text="En az 8 karakter"
            />


            <PasswordStrengthIndicatorItem
                isValid={number}
                text="En az 1 rakam"
            />

            <PasswordStrengthIndicatorItem
                isValid={specialChar}
                text="En az 1 simge"
            />

            <PasswordStrengthIndicatorItem
                isValid={upperLowerCase}
                text="Büyük/Küçük harf"
            />
        </div>
    );
};

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
    const highlightClass = isValid
        ? <CheckboxIcon style={{ fontSize: '14px', color: 'transparent' }} />
        : isValid !== null
            ? ""
            : "";
    return <div className="check-password">
        {highlightClass}<p>{text}</p>
    </div>
        ;
};

export default PasswordStrengthIndicator;