import { TagActionType } from "../actions/tag.actions";

const initialState = {

    tagList: {
        results: []
    },
    tagListLoading: true,
    tagCreateResponse: { "status": "loading", "message": "" },
    updateTagNameResponse: { "status": "loading", "message": "" },
    deleteTagNameResponse: { "status": "loading", "message": "" },
    connectedTagList: [],
    connectedTagLoading: true,
    connectTagDrawer: {
        status: false,
        id: null
    },
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
        case TagActionType.TAGS_LIST_REQUEST:
            return {
                ...state,
                tagListLoading: true
            };
        case TagActionType.TAGS_LIST_SUCCESS:
            return {
                ...state,
                tagList: action.payload,
                tagListLoading: false
            }
        case TagActionType.TAGS_LIST_FAIL:
            return {
                ...state,
                tagListLoading: false
            }
        case TagActionType.CREATE_TAG_NAME_SUCCESS:
            return {
                ...state,
                tagCreateResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case TagActionType.CREATE_TAG_NAME_FAIL:
            return {
                ...state,
                tagCreateResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case TagActionType.CREATE_TAG_NAME_INPUT_ERROR:
            return {
                ...state,
                tagCreateResponse: {
                    "status": "VALIDATION_ERROR",
                    "message": action.payload
                }
            }
        case TagActionType.CREATE_TAG_NAME_RESET:
            return {
                ...state,
                tagCreateResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case TagActionType.UPDATE_TAG_NAME_SUCCESS:
            return {
                ...state,
                updateTagNameResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case TagActionType.UPDATE_TAG_NAME_FAIL:
            return {
                ...state,
                updateTagNameResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case TagActionType.UPDATE_TAG_NAME_INPUT_ERROR:
            return {
                ...state,
                updateTagNameResponse: {
                    "status": "VALIDATION_ERROR",
                    "message": action.payload
                }
            }
        case TagActionType.UPDATE_TAG_NAME_RESET:
            return {
                ...state,
                updateTagNameResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case TagActionType.DELETE_TAG_NAME_SUCCESS:
            return {
                ...state,
                deleteTagNameResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case TagActionType.DELETE_TAG_NAME_FAIL:
            return {
                ...state,
                deleteTagNameResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case TagActionType.DELETE_TAG_NAME_RESET:
            return {
                ...state,
                deleteTagNameResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case TagActionType.GET_CONNECTED_TAG_REQUEST:
            return {
                ...state,
                connectedTagLoading: true
            };
        case TagActionType.GET_CONNECTED_TAG_SUCCESS:
            return {
                ...state,
                connectedTagList: action.payload,
                connectedTagLoading: false
            }
        case TagActionType.GET_CONNECTED_TAG_FAIL:
            return {
                ...state,
                connectedTagLoading: false
            }
        case "SET_TAG_CONNECT_DRAWER":
            const connectTagDrawerState = {
                ...state,
                connectTagDrawer: action.payload,
            };
            return connectTagDrawerState;

        default: return state
    }
}