import { TimeoffTypes } from "../actions/timeoffTypes.action";

const initialState = {
    timeoffTypeCrateDrawer:false,
    timeoffTypeDetailDrawer:false,
    organizationTimeoffTypeList :[],
    timeoffTypeDetailResponse : {"status": "", "message": "", "data":{}},
    organizationBirthdayRule : null,
    organizationTimeoffTypeListLoading: false,
    createTimeoffTypeResponse: { "status": "loading", "message": "" },
    updateTimeoffTypeResponse: { "status": "", "message": "" },
    updateStatusTimeoffTypeResponse: { "status": "loading", "message": "" },
    deleteTimeoffTypeResponse: { "status": "loading", "message": "" },
    copyTimeoffTypeResponse: { "status": "loading", "message": "" },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TimeoffTypes.SET_ADD_TIMEOFF_TYPE_DRAWER:
      return {
        ...state,
        timeoffTypeCrateDrawer: action.payload,
      };
    case TimeoffTypes.SET_UPDATE_TIMEOFF_TYPE_DRAWER:
      return {
        ...state,
        timeoffTypeDetailDrawer: action.payload,
      };
    case TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_REQUEST:
        return{
            ...state,
            organizationTimeoffTypeListLoading: true
        }
    case TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_SUCCESS:
        return{
            ...state,
            organizationTimeoffTypeList: action.payload?.timeOffTypes,
            organizationBirthdayRule: action.payload?.birthDayRuleId,
            organizationTimeoffTypeListLoading: false
        }
    case TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_FAIL:
        return{
            ...state,
            organizationTimeoffTypeListLoading: false
        }
    case TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_REQUEST:
        return{
            ...state,
            timeoffTypeDetailResponse: {
                ...state.timeoffTypeDetailResponse,
                "status" : "loading"
            }
        }
    case TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_SUCCESS:
        return{
            ...state,
            timeoffTypeDetailResponse: {
                ...state.timeoffTypeDetailResponse,
                "status": "SUCCESS",
                "message": "",
                "data":{
                    ...action.payload, 
                    accrualDateType: action.payload.accrualDateType?.id,
                    genderType: action.payload.genderType?.id
                }
            }
        }
    case TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_FAIL:
        return{
            ...state,
            timeoffTypeDetailResponse: {
                ...state.timeoffTypeDetailResponse,
                "status": "FAIL",
                "message": action.payload,
                "data":{}
            }
        }
    case TimeoffTypes.CREATE_TIMEOFF_TYPE_SUCCESS:
        return{
            ...state,
            createTimeoffTypeResponse: {
              status: "SUCCESS",
              message: action.payload.message
            }
        }
    case TimeoffTypes.CREATE_TIMEOFF_TYPE_FAIL:
        return{
            ...state,
            createTimeoffTypeResponse: {
              status: "FAIL",
              message: action.payload
            }
        }
    case TimeoffTypes.UPDATE_TIMEOFF_TYPE_REQUEST:
        return{
            ...state,
            updateTimeoffTypeResponse: {
              status: "loading",
            }
        }
    case TimeoffTypes.UPDATE_TIMEOFF_TYPE_SUCCESS:
        return{
            ...state,
            updateTimeoffTypeResponse: {
              status: "SUCCESS",
              message: action.payload.message
            }
        }
    case TimeoffTypes.UPDATE_TIMEOFF_TYPE_FAIL:
        return{
            ...state,
            updateTimeoffTypeResponse: {
              status: "FAIL",
              message: action.payload
            }
        }
    case TimeoffTypes.UPDATE_TIMEOFF_STATUS_SUCCESS:
        return{
            ...state,
            updateStatusTimeoffTypeResponse: {
              status: "SUCCESS",
              message: action.payload.message
            }
        }
    case TimeoffTypes.UPDATE_TIMEOFF_STATUS_FAIL:
        return{
            ...state,
            updateStatusTimeoffTypeResponse: {
              status: "FAIL",
              message: action.payload
            }
        }
    case TimeoffTypes.DELETE_TIMEOFF_TYPE_SUCCESS:
        return{
            ...state,
            deleteTimeoffTypeResponse: {
              status: "SUCCESS",
              message: action.payload
            }
        }
    case TimeoffTypes.DELETE_TIMEOFF_TYPE_FAIL:
        return{
            ...state,
            deleteTimeoffTypeResponse: {
              status: "FAIL",
              message: action.payload
            }
        }
    case TimeoffTypes.COPY_TIMEOFF_TYPE_SUCCESS:
        return{
            ...state,
            copyTimeoffTypeResponse: {
              status: "SUCCESS",
              message: action.payload
            }
        }
    case TimeoffTypes.COPY_TIMEOFF_TYPE_FAIL:
        return{
            ...state,
            copyTimeoffTypeResponse: {
              status: "FAIL",
              message: action.payload
            }
        }

    case TimeoffTypes.RESET_TIMEOFF_TYPE_RESPONSES:
        return{
            ...state,
            createTimeoffTypeResponse: { "status": "loading", "message": "" },
            updateStatusTimeoffTypeResponse: { "status": "loading", "message": "" },
            deleteTimeoffTypeResponse: { "status": "loading", "message": "" },
            copyTimeoffTypeResponse: { "status": "loading", "message": "" },
            timeoffTypeDetailResponse: { ...state.timeoffTypeDetailResponse, "status": "", "message": "" },
            updateTimeoffTypeResponse: { "status": "", "message": "" },
        }
        
    default:
      return state;
  }
}

