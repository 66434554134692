import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
const FileDownload = require("js-file-download");

const TimeOff = {
  TIME_OFF_REQUEST_LIST_SEARCH_REQUEST: "TIME_OFF_REQUEST_LIST_SEARCH_REQUEST",
  TIME_OFF_REQUEST_LIST_SEARCH_SUCCESS: "TIME_OFF_REQUEST_LIST_SEARCH_SUCCESS",
  TIME_OFF_REQUEST_LIST_SEARCH_FAIL: "TIME_OFF_REQUEST_LIST_SEARCH_FAIL",
  TIME_OFF_REQUEST_LIST_SEARCH_ME_REQUEST: "TIME_OFF_REQUEST_LIST_SEARCH_ME_REQUEST",
  TIME_OFF_REQUEST_LIST_SEARCH_ME_SUCCESS: "TIME_OFF_REQUEST_LIST_SEARCH_ME_SUCCESS",
  TIME_OFF_REQUEST_LIST_SEARCH_ME_FAIL: "TIME_OFF_REQUEST_LIST_SEARCH_ME_FAIL",
  TIME_OFF_REQUEST_DETAILS_REQUEST: "TIME_OFF_REQUEST_DETAILS_REQUEST",
  TIME_OFF_REQUEST_DETAILS_SUCCESS: "TIME_OFF_REQUEST_DETAILS_SUCCESS",
  TIME_OFF_REQUEST_DETAILS_FAIL: "TIME_OFF_REQUEST_DETAILS_FAIL",
  TIME_OFF_REQUEST_DETAILS_STEP_REQUEST: "TIME_OFF_REQUEST_DETAILS_STEP_REQUEST",
  TIME_OFF_REQUEST_DETAILS_STEP_SUCCESS: "TIME_OFF_REQUEST_DETAILS_STEP_SUCCESS",
  TIME_OFF_REQUEST_DETAILS_STEP_FAIL: "TIME_OFF_REQUEST_DETAILS_STEP_FAIL",
  SEARCH_TIMEOFF_REQUEST_MANAGER_REQUEST: "SEARCH_TIMEOFF_REQUEST_MANAGER_REQUEST",
  SEARCH_TIMEOFF_REQUEST_MANAGER_SUCCESS: "SEARCH_TIMEOFF_REQUEST_MANAGER_SUCCESS",
  SEARCH_TIMEOFF_REQUEST_MANAGER_FAIL: "SEARCH_TIMEOFF_REQUEST_MANAGER_FAIL",
  SEARCH_TIMEOFF_REQUEST_REQUEST: "SEARCH_TIMEOFF_REQUEST_REQUEST",
  SEARCH_TIMEOFF_REQUEST_SUCCESS: "SEARCH_TIMEOFF_REQUEST_SUCCESS",
  SEARCH_TIMEOFF_REQUEST_FAIL: "SEARCH_TIMEOFF_REQUEST_FAIL",
  TIMEOFF_APPROVE_SUCCESS: "TIMEOFF_APPROVE_SUCCESS",
  TIMEOFF_APPROVE_FAIL: "TIMEOFF_APPROVE_FAIL",
  TIMEOFF_APPROVE_REQUEST: "TIMEOFF_APPROVE_REQUEST",
  TIMEOFF_REJECT_SUCCESS: "TIMEOFF_REJECT_SUCCESS",
  TIMEOFF_REJECT_FAIL: "TIMEOFF_REJECT_FAIL",
  TIMEOFF_REJECT_REQUEST: "TIMEOFF_REJECT_REQUEST",
  GET_TIME_OFF_TYPES_REQUEST: "GET_TIME_OFF_TYPES_REQUEST",
  GET_TIME_OFF_TYPES_SUCCESS: "GET_TIME_OFF_TYPES_SUCCESS",
  GET_TIME_OFF_TYPES_FAIL: "GET_TIME_OFF_TYPES_FAIL",
  UPDATE_TIME_OFF_TYPE_STATUS_REQUEST: "UPDATE_TIME_OFF_TYPE_STATUS_REQUEST",
  UPDATE_TIME_OFF_TYPE_STATUS_SUCCESS: "UPDATE_TIME_OFF_TYPE_STATUS_SUCCESS",
  UPDATE_TIME_OFF_TYPE_STATUS_FAIL: "UPDATE_TIME_OFF_TYPE_STATUS_FAIL",
  UPDATE_TIME_OFF_TYPE_STATUS_RESET: "UPDATE_TIME_OFF_TYPE_STATUS_RESET",
  GET_TIMEOFF_INFO_BY_TYPE_REQUEST: "GET_TIMEOFF_INFO_BY_TYPE_REQUEST",
  GET_TIMEOFF_INFO_BY_TYPE_SUCCESS: "GET_TIMEOFF_INFO_BY_TYPE_SUCCESS",
  GET_TIMEOFF_INFO_BY_TYPE_WARNING: "GET_TIMEOFF_INFO_BY_TYPE_WARNING",
  GET_TIMEOFF_INFO_BY_TYPE_FAIL: "GET_TIMEOFF_INFO_BY_TYPE_FAIL",
  GET_TIMEOFF_INFO_BY_TYPE_DATE_REQUEST: "GET_TIMEOFF_INFO_BY_TYPE_DATE_REQUEST",
  GET_TIMEOFF_INFO_BY_TYPE_DATE_SUCCESS: "GET_TIMEOFF_INFO_BY_TYPE_DATE_SUCCESS",
  GET_TIMEOFF_INFO_BY_TYPE_DATE_WARNING: "GET_TIMEOFF_INFO_BY_TYPE_DATE_WARNING",
  GET_TIMEOFF_INFO_BY_TYPE_DATE_FAIL: "GET_TIMEOFF_INFO_BY_TYPE_DATE_FAIL",
  CREATE_TIMEOFF_REQUEST_REQUEST: "CREATE_TIMEOFF_REQUEST_REQUEST",
  CREATE_TIMEOFF_REQUEST_SUCCESS: "CREATE_TIMEOFF_REQUEST_SUCCESS",
  CREATE_TIMEOFF_REQUEST_FAIL: "CREATE_TIMEOFF_REQUEST_FAIL",
  CREATE_TIMEOFF_REQUEST_RESET_STATUS: "CREATE_TIMEOFF_REQUEST_RESET_STATUS",
  RESET_TIMEOFF_INFO_NOTIFICATIONS: "RESET_TIMEOFF_INFO_NOTIFICATIONS",
  RESET_TIMEOFF_APPROVE_NOTIFOCATIONS: "RESET_TIMEOFF_APPROVE_NOTIFOCATIONS",
  UPDATE_TIMEOFF_APPROVE_REQUEST: "UPDATE_TIMEOFF_APPROVE_REQUEST",
  UPDATE_TIMEOFF_APPROVE_SUCCESS: "UPDATE_TIMEOFF_APPROVE_SUCCESS",
  UPDATE_TIMEOFF_APPROVE_FAIL: "UPDATE_TIMEOFF_APPROVE_FAIL",
  UPDATE_TIMEOFF_APPROVE_RESET: "UPDATE_TIMEOFF_APPROVE_RESET",
  TIMEOFF_CANCEL_REQUEST: "TIMEOFF_CANCEL_REQUEST",
  TIMEOFF_CANCEL_SUCCESS: "TIMEOFF_CANCEL_SUCCESS",
  TIMEOFF_CANCEL_FAIL: "TIMEOFF_CANCEL_FAIL",
  RESET_TIMEOFF_REQUEST_DETAILS: "RESET_TIMEOFF_REQUEST_DETAILS",
  GET_FILTER_TIME_OFF_TYPE_SUCCESS: "GET_FILTER_TIME_OFF_TYPE_SUCCESS",
  GET_FILTER_TIME_OFF_TYPE_FAIL: "GET_FILTER_TIME_OFF_TYPE_FAIL",
  GET_TIMEOFF_PAI_CHART_DATA_REQUEST: "GET_TIMEOFF_PAI_CHART_DATA_REQUEST",
  GET_TIMEOFF_PAI_CHART_DATA_SUCCESS: "GET_TIMEOFF_PAI_CHART_DATA_SUCCESS",
  GET_TIMEOFF_PAI_CHART_DATA_FAIL: "GET_TIMEOFF_PAI_CHART_DATA_FAIL",
  GET_TIMEOFF_PAI_CHART_DATA_WARNING: "GET_TIMEOFF_PAI_CHART_DATA_WARNING",
  TIMEOFF_EXPORT_SUCCESS: "TIMEOFF_EXPORT_SUCCESS",
  REMAINDER_TIMEOFF_REQUEST_REQUEST: "REMAINDER_TIMEOFF_REQUEST_REQUEST",
  REMAINDER_TIMEOFF_REQUEST_SUCCESS: "REMAINDER_TIMEOFF_REQUEST_SUCCESS",
  REMAINDER_TIMEOFF_REQUEST_FAIL: "REMAINDER_TIMEOFF_REQUEST_FAIL",
  DELETE_EXTRA_VALUE_SUCCESS: "DELETE_EXTRA_VALUE_SUCCESS",
  DELETE_EXTRA_VALUE_FAIL: "DELETE_EXTRA_VALUE_FAIL",
  DELETE_EXTRA_VALUE_RESET: "DELETE_EXTRA_VALUE_RESET",
  CREATE_EXTRA_VALUE_REQUEST: "CREATE_EXTRA_VALUE_REQUEST",
  CREATE_EXTRA_VALUE_SUCCESS: "CREATE_EXTRA_VALUE_SUCCESS",
  CREATE_EXTRA_VALUE_FAIL: "CREATE_EXTRA_VALUE_FAIL",
  CREATE_EXTRA_VALUE_RESET: "CREATE_EXTRA_VALUE_RESET",
  TIMEOFF_EXPORT_FAIL: "TIMEOFF_EXPORT_FAIL",
  GET_ALL_TIME_OFF_REQUEST: "GET_ALL_TIME_OFF_REQUEST",
  GET_ALL_TIME_OFF_SUCCESS: "GET_ALL_TIME_OFF_SUCCESS",
  GET_ALL_TIME_OFF_FAIL: "GET_ALL_TIME_OFF_FAIL",
  DELETE_TIME_OFF_REQUEST_SUCCESS: "DELETE_TIME_OFF_REQUEST_SUCCESS",
  DELETE_TIME_OFF_REQUEST_FAIL: "DELETE_TIME_OFF_REQUEST_FAIL",
  DELETE_TIME_OFF_REQUEST_RESET: "DELETE_TIME_OFF_REQUEST_RESET",
  EXPORT_REMAINDER_YEARS_FAIL: "EXPORT_REMAINDER_YEARS_FAIL",
  EXPORT_REMAINDER_YEARS_RESET: "EXPORT_REMAINDER_YEARS_RESET",
  GET_WORK_HOUR_START_BY_DATE_REQUEST: "GET_WORK_HOUR_START_BY_DATE_REQUEST",
  GET_WORK_HOUR_START_BY_DATE_SUCCESS: "GET_WORK_HOUR_START_BY_DATE_SUCCESS",
  GET_WORK_HOUR_START_BY_DATE_FAIL: "GET_WORK_HOUR_START_BY_DATE_FAIL",
  GET_WORK_HOUR_END_BY_DATE_REQUEST: "GET_WORK_HOUR_END_BY_DATE_REQUEST",
  GET_WORK_HOUR_END_BY_DATE_SUCCESS: "GET_WORK_HOUR_END_BY_DATE_SUCCESS",
  GET_WORK_HOUR_END_BY_DATE_FAIL: "GET_WORK_HOUR_END_BY_DATE_FAIL",
  GET_TIME_OFF_INFO_REQUEST: "GET_TIME_OFF_INFO_REQUEST",
  GET_TIME_OFF_INFO_SUCCESS: "GET_TIME_OFF_INFO_SUCCESS",
  GET_TIME_OFF_INFO_FAIL: "GET_TIME_OFF_INFO_FAIL",
  GET_TIME_OFF_INFO_RESET: "GET_TIME_OFF_INFO_RESET",
  START_WORK_HOUR_START_BY_DATE_RESET: "START_WORK_HOUR_START_BY_DATE_RESET",
  END_WORK_HOUR_START_BY_DATE_RESET: "END_WORK_HOUR_START_BY_DATE_RESET",
  GET_TIMEOFF_APPROVE_ON_MAIL_REQUEST: "GET_TIMEOFF_APPROVE_ON_MAIL_REQUEST",
  GET_TIMEOFF_APPROVE_ON_MAIL_SUCCESS: "GET_TIMEOFF_APPROVE_ON_MAIL_SUCCESS",
  GET_TIMEOFF_APPROVE_ON_MAIL_FAIL: "GET_TIMEOFF_APPROVE_ON_MAIL_FAIL",
  RESET_TIMEOFF_DOWNLOAD: "RESET_TIMEOFF_DOWNLOAD",
  GET_WORK_HOUR_START_END_BY_DATE_REQUEST: "GET_WORK_HOUR_START_END_BY_DATE_REQUEST",
  GET_WORK_HOUR_START_END_BY_DATE_SUCCESS: "GET_WORK_HOUR_START_END_BY_DATE_SUCCESS",
  GET_WORK_HOUR_START_END_BY_DATE_FAIL: "GET_WORK_HOUR_START_END_BY_DATE_FAIL",
  START_END_WORK_HOUR_START_BY_DATE_RESET: "START_END_WORK_HOUR_START_BY_DATE_RESET",
  AGREEMENT_FORM_SUCCESS: "AGREEMENT_FORM_SUCCESS",
  AGREEMENT_FORM_FAIL: "AGREEMENT_FORM_FAIL",
  AGREEMENT_FORM_RESET: "AGREEMENT_FORM_RESET",
  GET_EXTRA_VALUE_REQUEST: "GET_EXTRA_VALUE_REQUEST",
  GET_EXTRA_VALUE_SUCCESS: "GET_EXTRA_VALUE_SUCCESS",
  GET_EXTRA_VALUE_FAIL: "GET_EXTRA_VALUE_FAIL",
  DELETE_TIME_OFF_ADJUST_HISTORY_SUCCESS: "DELETE_TIME_OFF_ADJUST_HISTORY_SUCCESS",
  DELETE_TIME_OFF_ADJUST_HISTORY_FAIL: "DELETE_TIME_OFF_ADJUST_HISTORY_FAIL",
  DELETE_TIME_OFF_ADJUST_HISTORY_RESET: "DELETE_TIME_OFF_ADJUST_HISTORY_RESET",
  UPDATE_TIME_OFF_ADJUST_HISTORY_SUCCESS: "UPDATE_TIME_OFF_ADJUST_HISTORY_SUCCESS",
  UPDATE_TIME_OFF_ADJUST_HISTORY_FAIL: "UPDATE_TIME_OFF_ADJUST_HISTORY_FAIL",
  UPDATE_TIME_OFF_ADJUST_HISTORY_RESET: "UPDATE_TIME_OFF_ADJUST_HISTORY_RESET",
};
const timeOffRequestSearch = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/search/person",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_FAIL, payload: error.message });
      }
    }
  };
};
const timeOffRequestSearchMe = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/search/me",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffRequest = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffRequestMe = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/" + resourceId + "/me",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffRequestStep = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/approvalList/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffRequestStepMe = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/approvalList/" + resourceId + "/me",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL, payload: error.message });
      }
    }
  };
};
const searchTimeOffRequestManager = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/search/manager",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_FAIL, payload: error.message });
      }
    }
  };
};
const searchPeopleOnLeave = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/search",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.SEARCH_TIMEOFF_REQUEST_FAIL, payload: error.message });
      }
    }
  };
};
const timeOffApprove = (approveObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIMEOFF_APPROVE_REQUEST });
    try {
      let approveData = approveObj.approveData;
      var data = JSON.stringify(approveData);
      var config = {
        method: "put",
        url: apiUrl + "/timeOffRequests/approve",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: TimeOff.TIMEOFF_APPROVE_SUCCESS,
              payload: { message: response.data.message, resourceId: response.data.data[0], key: approveObj.key },
            });
          } else {
            dispatch({ type: TimeOff.TIMEOFF_APPROVE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if(error.response.status === 422){
            dispatch({ type: TimeOff.TIMEOFF_APPROVE_FAIL, payload: error.response.data.message });
          }else{
            dispatch({ type: TimeOff.TIMEOFF_APPROVE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIMEOFF_APPROVE_FAIL, payload: error.message });
      }
    }
  };
};
const timeOffReject = (approveObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIMEOFF_REJECT_REQUEST });
    try {
      let rejectData = approveObj.rejectData;
      var data = JSON.stringify(rejectData);
      var config = {
        method: "put",
        url: apiUrl + "/timeOffRequests/reject",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: TimeOff.TIMEOFF_REJECT_SUCCESS,
              payload: { message: response.data.message, resourceId: response.data.data[0], key: approveObj.key },
            });
          } else {
            dispatch({ type: TimeOff.TIMEOFF_REJECT_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.TIMEOFF_REJECT_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIMEOFF_REJECT_FAIL, payload: error.message });
      }
    }
  };
};
const timeOffCancel = (approveObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.TIMEOFF_CANCEL_REQUEST });
    try {
      var config = {
        method: "put",
        url: apiUrl + "/timeOffRequests/cancel/" + approveObj.resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          description: approveObj.description,
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: TimeOff.TIMEOFF_CANCEL_SUCCESS,
              payload: { message: response.data.message, resourceId: response.data.data, key: approveObj.key },
            });
          } else {
            dispatch({ type: TimeOff.TIMEOFF_CANCEL_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.TIMEOFF_CANCEL_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TimeOff.TIMEOFF_CANCEL_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIMEOFF_CANCEL_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffTypesList = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIME_OFF_TYPES_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffTypes",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIME_OFF_TYPES_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_TIME_OFF_TYPES_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.GET_TIME_OFF_TYPES_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIME_OFF_TYPES_FAIL, payload: error.message });
      }
    }
  };
};
const updateTimeOffTypeStatus = (status, resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_REQUEST });
    try {
      var config = {
        method: "put",
        url: apiUrl + "/timeOffTypes/" + status + "/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_FAIL, payload: error.message });
      }
    }
  };
};
const updateTimeOffTypeStatusReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_RESET, payload: {} });
  };
};
const resetTimeOffApproveResponseStatus = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.RESET_TIMEOFF_APPROVE_NOTIFOCATIONS, payload: {} });
  };
};
const getTimeOffInfoByTimeOffType = (TimeOffType_ResourceId, personResourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/" + TimeOffType_ResourceId + "/info/" + personResourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_SUCCESS, payload: response.data.data });
          } else if (response.status === 422) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_WARNING, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_WARNING, payload: error.response.data });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffInfoByTimeOffTypeAndDate = (getObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_REQUEST });
    try {
      let TimeOffType_ResourceId = getObj.TimeOffType_ResourceId;
      let startDate = getObj.startDate;
      let endDate = getObj.endDate;
      let personResourceId = getObj.personResourceId;
      var config = {
        method: "get",
        url:
          apiUrl +
          "/timeOffRequests/" +
          TimeOffType_ResourceId +
          "/info/" +
          personResourceId +
          "/" +
          startDate +
          "/" +
          endDate,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_WARNING, payload: error.response.data });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_FAIL, payload: error.message });
      }
    }
  };
};
const getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId = (getObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_REQUEST });
    try {
      let TimeOffType_ResourceId = getObj.TimeOffType_ResourceId;
      let startDate = getObj.startDate;
      let endDate = getObj.endDate;
      let personResourceId = getObj.personResourceId;
      let timeOffResourceId = getObj.timeOffResourceId;
      var config = {
        method: "get",
        url:
          apiUrl +
          "/timeOffRequests/" +
          TimeOffType_ResourceId +
          "/info/" +
          personResourceId +
          "/" +
          startDate +
          "/" +
          endDate +
          "/" +
          timeOffResourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_WARNING, payload: error.response.data });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_FAIL, payload: error.message });
      }
    }
  };
};
const createTimeOffRequest = (createObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.CREATE_TIMEOFF_REQUEST_REQUEST });
    try {
      var data = JSON.stringify(createObj);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TimeOff.CREATE_TIMEOFF_REQUEST_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: TimeOff.CREATE_TIMEOFF_REQUEST_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.CREATE_TIMEOFF_REQUEST_FAIL, payload: error.response.data });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.CREATE_TIMEOFF_REQUEST_FAIL, payload: error.message });
      }
    }
  };
};
const updateTimeOffRequest = (createObj, resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.UPDATE_TIMEOFF_APPROVE_REQUEST });
    try {
      var data = JSON.stringify(createObj);
      var config = {
        method: "put",
        url: apiUrl + "/timeOffRequests/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TimeOff.UPDATE_TIMEOFF_APPROVE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.UPDATE_TIMEOFF_APPROVE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.UPDATE_TIMEOFF_APPROVE_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.UPDATE_TIMEOFF_APPROVE_FAIL, payload: error.response.data });
      }
    }
  };
};
const resetCreateTimeOffRequestResponse = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.CREATE_TIMEOFF_REQUEST_RESET_STATUS, payload: {} });
  };
};
const timeOffRequestUpdateReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.UPDATE_TIMEOFF_APPROVE_RESET, payload: {} });
  };
};
const resetTimeOffInfoNotifications = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.RESET_TIMEOFF_INFO_NOTIFICATIONS, payload: {} });
  };
};
const resetTimeOffRequestDetails = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.RESET_TIMEOFF_REQUEST_DETAILS, payload: {} });
  };
};
export const getFilterTimeOffType = (searchData) => async (dispatch) => {
  try {
    const data = JSON.stringify(searchData);
    var config = {
      method: "post",
      url: apiUrl + "/timeOffTypes/filter",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    hrApi(config)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: TimeOff.GET_FILTER_TIME_OFF_TYPE_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: TimeOff.GET_FILTER_TIME_OFF_TYPE_FAIL,
            payload: response.data.message,
          });
        }
      })
      .catch(function (error) {
        if (error.response?.status === 404) {
          const emptyData = {
            pageNumber: 1,
            pageSize: 0,
            totalNumberOfPages: 0,
            totalNumberOfRecords: 0,
            results: [],
          };
          dispatch({ type: TimeOff.GET_FILTER_TIME_OFF_TYPE_SUCCESS, payload: emptyData });
        }
        dispatch({ type: TimeOff.GET_FILTER_TIME_OFF_TYPE_FAIL, payload: error.message });
      });
  } catch (error) {
    if (error.response) {
      dispatch({ type: TimeOff.GET_FILTER_TIME_OFF_TYPE_FAIL, payload: error.message });
    }
  }
};
const getTimeOffPaiChartData = (personResourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIMEOFF_PAI_CHART_DATA_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/remainingTimeOffRequest/" + personResourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIMEOFF_PAI_CHART_DATA_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_TIMEOFF_PAI_CHART_DATA_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.GET_TIMEOFF_PAI_CHART_DATA_WARNING, payload: error.response.data });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIMEOFF_PAI_CHART_DATA_FAIL, payload: error.message });
      }
    }
  };
};
const timeOffDownload = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/document/export",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: {
          exportType: 3,
          timeOffResourceId: props,
        },
        responseType: "blob", // Important
      };
      hrApi(config)
        .then(function (response, typeId) {
          if (response.status === 200) {
            FileDownload(response.data, "timeOff_" + newDate + ".pdf");
          } else {
            dispatch({ type: TimeOff.TIMEOFF_EXPORT_SUCCESS, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: TimeOff.TIMEOFF_EXPORT_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.TIMEOFF_EXPORT_FAIL, payload: error.message });
      }
    }
  };
};
const resetTimeOffDownload = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.RESET_TIMEOFF_DOWNLOAD, payload: {} });
  };
};
const getRemainingTimeOffRequest = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.REMAINDER_TIMEOFF_REQUEST_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/search/remainingTimeOffRequest",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.REMAINDER_TIMEOFF_REQUEST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.REMAINDER_TIMEOFF_REQUEST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TimeOff.REMAINDER_TIMEOFF_REQUEST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TimeOff.REMAINDER_TIMEOFF_REQUEST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.REMAINDER_TIMEOFF_REQUEST_FAIL, payload: error.message });
      }
    }
  };
};
const deleteExtraValue = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/timeOffAdjusts/extraValue/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.DELETE_EXTRA_VALUE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.DELETE_EXTRA_VALUE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TimeOff.DELETE_EXTRA_VALUE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TimeOff.DELETE_EXTRA_VALUE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.DELETE_EXTRA_VALUE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteExtraValueReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.DELETE_EXTRA_VALUE_RESET, payload: {} });
  };
};
const createExtraValue = (createObj) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.CREATE_EXTRA_VALUE_REQUEST });
    try {
      var data = JSON.stringify(createObj);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffAdjusts/extraValue",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TimeOff.CREATE_EXTRA_VALUE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.CREATE_EXTRA_VALUE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: TimeOff.CREATE_EXTRA_VALUE_FAIL, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.CREATE_EXTRA_VALUE_FAIL, payload: error.message });
      }
    }
  };
};
const createExtraValueReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.CREATE_EXTRA_VALUE_RESET, payload: {} });
  };
};
const getAllTimeOfRequest = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_ALL_TIME_OFF_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/search/all",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_ALL_TIME_OFF_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_ALL_TIME_OFF_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404 || error.response?.status === 500) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: TimeOff.GET_ALL_TIME_OFF_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TimeOff.GET_ALL_TIME_OFF_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_ALL_TIME_OFF_FAIL, payload: error.message });
      }
    }
  };
};
const deleteTimeOffRequest = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/timeOffRequests/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_REQUEST_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_REQUEST_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_REQUEST_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_REQUEST_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.DELETE_TIME_OFF_REQUEST_FAIL, payload: error.message });
      }
    }
  };
};
const deleteTimeOffRequestReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.DELETE_TIME_OFF_REQUEST_RESET, payload: {} });
  };
};
const exportRemainderYears = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffRequests/export/remainingTimeOffRequest",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob",
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };
            FileDownload(response.data, "time_off_remainder_" + newDate + "." + exportTypes[props.ExportType]);
          } else {
            dispatch({ type: TimeOff.EXPORT_REMAINDER_YEARS_FAIL, payload: response.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.EXPORT_REMAINDER_YEARS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.EXPORT_REMAINDER_YEARS_FAIL, payload: error.message });
      }
    }
  };
};
const exportRemainderYearsReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.EXPORT_REMAINDER_YEARS_RESET, payload: {} });
  };
};
const getWorkHourByDate = (organizationResourceId, date, dayType, inputType, personId) => {
  return async (dispatch) => {
    if (inputType === "start") {
      dispatch({ type: TimeOff.GET_WORK_HOUR_START_BY_DATE_REQUEST });
    } else if (inputType === "end") {
      dispatch({ type: TimeOff.GET_WORK_HOUR_END_BY_DATE_REQUEST });
    } else {
      dispatch({ type: TimeOff.GET_WORK_HOUR_START_END_BY_DATE_REQUEST });
    }
    try {
      var config = {
        method: "get",
        url:
          apiUrl +
          "/timeOffRequests/getWorkHourByDate/" +
          organizationResourceId +
          "/" +
          date +
          "/" +
          dayType +
          "/" +
          personId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            if (inputType === "start") {
              dispatch({ type: TimeOff.GET_WORK_HOUR_START_BY_DATE_SUCCESS, payload: response.data.data });
            } else if (inputType === "end") {
              dispatch({ type: TimeOff.GET_WORK_HOUR_END_BY_DATE_SUCCESS, payload: response.data.data });
            } else {
              dispatch({ type: TimeOff.GET_WORK_HOUR_START_END_BY_DATE_SUCCESS, payload: response.data.data });
            }
          } else {
            if (inputType === "start") {
              dispatch({ type: TimeOff.GET_WORK_HOUR_START_BY_DATE_FAIL, payload: response.data });
            } else if (inputType === "end") {
              dispatch({ type: TimeOff.GET_WORK_HOUR_END_BY_DATE_FAIL, payload: response.data });
            } else {
              dispatch({ type: TimeOff.GET_WORK_HOUR_START_END_BY_DATE_FAIL, payload: response.data });
            }
          }
        })
        .catch(function (error) {
          if (error?.response?.status === 404) {
            if (inputType === "start") {
              dispatch({ type: TimeOff.GET_WORK_HOUR_START_BY_DATE_FAIL, payload: error.response.data.message });
            } else if (inputType === "end") {
              dispatch({ type: TimeOff.GET_WORK_HOUR_END_BY_DATE_FAIL, payload: error.response.data.message });
            } else {
              dispatch({ type: TimeOff.GET_WORK_HOUR_START_END_BY_DATE_FAIL, payload: error.response.data.message });
            }
          }
        });
    } catch (error) {
      if (error.response) {
        if (inputType === "start") {
          dispatch({ type: TimeOff.GET_WORK_HOUR_START_BY_DATE_FAIL, payload: error.message });
        } else if (inputType === "end") {
          dispatch({ type: TimeOff.GET_WORK_HOUR_END_BY_DATE_FAIL, payload: error.message });
        } else {
          dispatch({ type: TimeOff.GET_WORK_HOUR_START_END_BY_DATE_FAIL, payload: error.message });
        }
      }
    }
  };
};
const resetWorkHourByDate = (type) => {
  return async (dispatch) => {
    if (type === "start") {
      dispatch({ type: TimeOff.START_WORK_HOUR_START_BY_DATE_RESET, payload: {} });
    } else if (type === "end") {
      dispatch({ type: TimeOff.END_WORK_HOUR_START_BY_DATE_RESET, payload: {} });
    } else {
      dispatch({ type: TimeOff.START_END_WORK_HOUR_START_BY_DATE_RESET, payload: {} });
    }
  };
};
const getTimeOffRequestInfo = (data) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIME_OFF_INFO_REQUEST });
    try {
      var config = {
        method: "get",
        url:
          apiUrl +
          "/timeOffRequests/infoV2/" +
          data.startDate +
          "/" +
          data.startDayType +
          "/" +
          data.endDate +
          "/" +
          data.endDayType +
          "/" +
          data.TimeOffTypeResourceId +
          "/" +
          data.personResourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIME_OFF_INFO_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TimeOff.GET_TIME_OFF_INFO_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.GET_TIME_OFF_INFO_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIME_OFF_INFO_FAIL, payload: error.message });
      }
    }
  };
};
const resetTimeOffRequestInfo = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIME_OFF_INFO_RESET, payload: {} });
  };
};
const getTimeOffApproveOnMail = (data) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/approveOnMail?data=" + data,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_FAIL, payload: error.message });
      }
    }
  };
};
const downloadAgreementForm = (resourceId) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffRequests/AgreementForm/" + resourceId,
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        responseType: "blob", // Important
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            FileDownload(response.data, "agreementForm_" + newDate + ".pdf");
          } else {
            dispatch({ type: TimeOff.AGREEMENT_FORM_SUCCESS, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: TimeOff.AGREEMENT_FORM_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.AGREEMENT_FORM_FAIL, payload: error.message });
      }
    }
  };
};
const resetDownloadAgreementForm = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.AGREEMENT_FORM_RESET, payload: {} });
  };
};
const getExtraValueDetail = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.GET_EXTRA_VALUE_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffAdjustsHistory/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeOff.GET_EXTRA_VALUE_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: TimeOff.GET_EXTRA_VALUE_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.GET_EXTRA_VALUE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.GET_EXTRA_VALUE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteTimeOffAdjustsHistory = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/timeOffAdjustsHistory/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: error.message });
      }
    }
  };
};
const deleteTimeOffAdjustsHistoryReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_RESET, payload: {} });
  };
};
const updateTimeOffAdjustsHistory = (data, resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/timeOffAdjustsHistory/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_FAIL, payload: error.message });
      }
    }
  };
};
const updateTimeOffAdjustsHistoryReset = () => {
  return async (dispatch) => {
    dispatch({ type: TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_RESET, payload: {} });
  };
};
export {
  TimeOff,
  resetTimeOffApproveResponseStatus,
  getTimeOffTypesList,
  getTimeOffRequest,
  getTimeOffRequestMe,
  getTimeOffRequestStep,
  getTimeOffRequestStepMe,
  updateTimeOffTypeStatus,
  updateTimeOffTypeStatusReset,
  getTimeOffInfoByTimeOffType,
  getTimeOffInfoByTimeOffTypeAndDate,
  getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId,
  createTimeOffRequest,
  resetCreateTimeOffRequestResponse,
  resetTimeOffInfoNotifications,
  timeOffRequestSearch,
  timeOffRequestSearchMe,
  searchTimeOffRequestManager,
  searchPeopleOnLeave,
  timeOffApprove,
  timeOffCancel,
  timeOffReject,
  updateTimeOffRequest,
  timeOffRequestUpdateReset,
  resetTimeOffRequestDetails,
  getTimeOffPaiChartData,
  getRemainingTimeOffRequest,
  deleteExtraValue,
  deleteExtraValueReset,
  createExtraValue,
  createExtraValueReset,
  timeOffDownload,
  resetTimeOffDownload,
  getAllTimeOfRequest,
  deleteTimeOffRequest,
  deleteTimeOffRequestReset,
  exportRemainderYears,
  exportRemainderYearsReset,
  getWorkHourByDate,
  getTimeOffRequestInfo,
  resetTimeOffRequestInfo,
  resetWorkHourByDate,
  getTimeOffApproveOnMail,
  downloadAgreementForm,
  resetDownloadAgreementForm,
  getExtraValueDetail,
  deleteTimeOffAdjustsHistory,
  deleteTimeOffAdjustsHistoryReset,
  updateTimeOffAdjustsHistory,
  updateTimeOffAdjustsHistoryReset,
};
