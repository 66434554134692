import { useEffect, useRef } from 'react';

const OnboardingBackdropHook = (popoverVisible, setPopoverVisible, backdropLocation) => {
  const backdropRef = useRef(null);

  useEffect(() => {
    
    const createBackdrop = () => {
      const backdrop = document.createElement('div');
      backdrop.className = 'backdrop';
      backdrop.style.position = 'fixed';
      backdrop.style.top = '0';
      backdrop.style.left = '0';
      backdrop.style.width = '100%';
      backdrop.style.height = '100%';
      backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      backdrop.style.zIndex = '1000';
      backdrop.addEventListener('click', handleClick);
      document.body.appendChild(backdrop);
      backdropRef.current = backdrop;
    };

    const removeBackdrop = () => {
      if (backdropRef.current) {
        backdropRef.current.removeEventListener('click', handleClick);
        document.body.removeChild(backdropRef.current);
        backdropRef.current = null;
      }
    };

    const handleClick = () => setPopoverVisible(false);

    if (popoverVisible) {
      createBackdrop();
    } else {
      removeBackdrop();
    }

    return () => removeBackdrop();
  }, [popoverVisible, setPopoverVisible]);
};

export default OnboardingBackdropHook;
