import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import {
    getInventoryList, setInventoryAddDrawer, resetInventoryStatus,
    setInventoryDetailsDrawer, getInventoryDetails, getInventoryOwnerShipDetails,
    deleteInventoryReset, inventoryListExport, deleteInventory, updateStatusInventory
} from '../../redux/actions/inventory.actions';
import { getGridSetting, createGridSettings } from '../../redux/actions/setting.actions';
import { setCreateOwnerShipDrawer } from '../../redux/actions/person-detail-tab-action';

import { Layout, Col, Dropdown, Button, Drawer, Popover } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { UilPanoramaHAlt, UilWindow, UilBookAlt, UilBed, UilFlask, UilMouse, UilUserSquare, UilCreditCard, UilScrew, UilHdd, UilBuilding, UilFilesLandscapesAlt, UilBriefcase,UilFolderQuestion, UilDesktop, UilDesktopCloudAlt, UilLaptop, UilDesktopAlt, UilPhone, UilCar, UilMobileAndroid, UilFileExport, UilFileImport } from '@iconscout/react-unicons';
import moment from 'moment';
import Media from "react-media";
import { ToastContainer } from 'react-toastify';

import InventoryDetailTabs from '../../component/inventoryTabs/details/inventory-details-draw'
import AddInventoryTabs from '../../component/inventoryTabs/add/inventory-add-all-tabs';
import OwnerShipReturn from '../../component/ownerShipDrawerTabs/returnDrawer';
import OwnerShipAddTabs from '../../component/ownerShipDrawerTabs/add/ownership-all-tabs'
import DynamicTable from '../../component/dynamicTable/index';
import BreadCrump from '../../features/breadcrump/breadcrump';
import DynamicRequestModal from '../../component/dynamicModal/requestModal'
import ExportModal from '../../component/dynamicModal/exportModal';
import InventoryImportModal from '../../component/modals/inventory/import';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../../component/forbidden/index";
import { dateFormatConvert, useWindowDimensions } from '../../utils/config';
import { drawerWidth } from '../../utils/config';
import { inventoryListDropdown } from '../../features/global-dropdown-menu/index';

import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';
import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";

function InventoryPage(props) {
    const { t } = useTranslation();
    const { Content } = Layout;
    const { isInventoryListLoading, inventoryList, totalNumberOfRecords, gridSettings, inventoryStatusResponse, inventoryCreateResponse, addInventoryStatus, detailInventoryStatus } = props;
    const { getInventoryList, getGridSetting, createGridSettings, setInventoryAddDrawer, updateInventoryResponse, deleteInventoryResponse, ownershipCreateResponse,
        setInventoryDetailsDrawer, getInventoryOwnerShipDetails, getInventoryDetails, resetInventoryStatus, ownershipTabReducer, permissions,
        deleteInventoryReset, returnOwnerShipResponse, inventoryListExport, deleteInventory, updateStatusInventory, setCreateOwnerShipDrawer, tabreducer,
        inventoryImportResponse } = props;
    const initialGetGridSettingData = {
        pageId: 6, //envanter
        gridId: 1, //envanter grid
    }
    const initialSearchData = {
        "searchTerm": "",
        "inventoryTypes": [],
        "brands": [],
        "persons": [],
        "statuses": [],
        "models": [],
        "serialNumbers": [],
        "showPassiveRecords": false,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "SerialNumber",
            "ascending": true
        }
    }
    const pdfInitialData = {
        "searchTerm": "",
        "inventoryTypes": [],
        "brands": [],
        "persons": [],
        "statuses": [],
        "models": [],
        "serialNumbers": [],
        "showPassiveRecords": false,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "SerialNumber",
            "ascending": true
        }
    }
    const [searchData, setSearchData] = useState(initialSearchData);
    const [exportData, setExportData] = useState(pdfInitialData);
    const [importData, setImportData] = useState({
        'key': 10000,
        'isVisible': false
    });
    const [selectedId, setSelectedid] = useState(null);
    const [selectedOwnerShipResourceId, setSelectedOwnerShipResourceId] = useState(null);
    const [selectedOwnerShipAssigmentDate, setSelectedOwnerShipAssigmentDate] = useState(null);
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData);
    const [isShowPassives, setIsShowPassives] = useState(false);
    const [inventoryDetailsComponentKey, setInventoryDetailsComponentKey] = useState(12);
    const [returnComponentKey, setReturnComponentKey] = useState(221);
    const [addComponentKey, setAddComponentKey] = useState(20022);
    const [addInventoryComponentKey, setAddInventoryComponentKey] = useState(2022);
    const [isExportModalVisible, setIsExportModalVisible] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState({ "isVisible": false });
    const [doPassiveModalData, setDoPassiveModalData] = useState({ "isVisible": false });
    const [doActiveModalData, setDoActiveModalData] = useState({ "isVisible": false });
    const [ownerShipData, setOwnerShipData] = useState({});
    const { width } = useWindowDimensions();

    const handleSaveGridSettings = (d) => {
        let createObj = { "data": d, "pageId": getGridSettingsData.pageId, "gridId": getGridSettingsData.gridId }
        createGridSettings(createObj)
    }
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['InventoryTypeName', 'state', 'personName'] : [],
        sortableColumns: ['InventoryTypeName', 'brand', 'model', 'serialNumber', 'state', 'personName', 'assigmentDate'],
        visibilityOptionExcludes: ['InventoryTypeName'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "inventory"
    })
    const paginationConfig = () => ({
        current: searchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj });
        setExportData({ ...exportData, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 }
        setSearchData({ ...searchData, "searchTerm": e, "pagination": pagingObj });
        setExportData({ ...exportData, "searchTerm": e });
    }
    const handleTableFilter = (data) => {
        setSearchData({
            ...searchData,
            "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "SerialNumber",
                "ascending": true
            },
            "inventoryTypes": data.InventoryTypeName,
            "statuses": data.state,
            "persons": data.personName
        });
        setExportData({
            ...exportData,
            "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "SerialNumber",
                "ascending": true
            },
            "inventoryTypes": data.InventoryTypeName,
            "statuses": data.state,
            "persons": data.personName
        });
    }
    const handleShowPassivesToogle = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 };
        setIsShowPassives(e)
        setSearchData({ ...searchData, "showPassiveRecords": e, "pagination": pagingObj });
        setExportData({ ...exportData, "showPassiveRecords": e, "pagination": pagingObj });
    }
    const handleResetAllfilters = () => {
        setIsShowPassives(false)
        setSearchData(initialSearchData);
        setExportData(pdfInitialData);
    }
    useEffect(() => {
        if (ownershipTabReducer.ownerShipReturnDrawer === true) {
            setReturnComponentKey((i) => i + 1)
        }
    }, [ownershipTabReducer.ownerShipReturnDrawer])
    useEffect(() => {
        getInventoryList(searchData);
    }, [searchData]);
    useEffect(() => {
        if(inventoryImportResponse.status === "SUCCESS"){
            getInventoryList(searchData);
        }
    },[inventoryImportResponse])
    useEffect(() => {
        getGridSetting(getGridSettingsData);
    }, [getGridSettingsData])
    useEffect(() => {
        if (inventoryCreateResponse?.status === 'SUCCESS') {
            getInventoryList(searchData);
        }
    }, [inventoryCreateResponse])
    useEffect(() => {
        if (ownershipCreateResponse?.status === 'SUCCESS') {
            getInventoryList(searchData);
        }
    }, [ownershipCreateResponse])
    useEffect(() => {
        if (inventoryStatusResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'PASSIVE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': inventoryStatusResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            if (selectedId) {
                getInventoryDetails(selectedId);
            }
            if (inventoryList?.length > 1) {
                getInventoryList(searchData);
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
                setExportData({
                    ...exportData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            resetInventoryStatus();
        } else if (inventoryStatusResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'PASSIVE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': inventoryStatusResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetInventoryStatus();
        }
    }, [inventoryStatusResponse])
    useEffect(() => {
        if (returnOwnerShipResponse.status === 'SUCCESS') {
            getInventoryDetails(selectedId);
            getInventoryOwnerShipDetails(selectedId);
            getInventoryList(initialSearchData);
        }
    }, [returnOwnerShipResponse])
    useEffect(() => {
        if (deleteInventoryResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteInventoryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            setInventoryDetailsDrawer(false);
            if (inventoryList?.length > 1) {
                getInventoryList(searchData);
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
                setExportData({
                    ...exportData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            deleteInventoryReset();
        } else if (deleteInventoryResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': deleteInventoryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteInventoryReset();
        }
    }, [deleteInventoryResponse])
    useEffect(() => {
        if (updateInventoryResponse?.status === 'SUCCESS') {
            getInventoryList(searchData);
        }
    }, [updateInventoryResponse]);

    const getIcon = (id) => {
        if (id === 1) {
            return <UilLaptop />
        } else if (id === 2) {
            return <UilDesktop />
        } else if (id === 3) {
            return <UilMobileAndroid />
        } else if (id === 4) {
            return <UilDesktopAlt />
        } else if (id === 5) {
            return <UilDesktopAlt />
        } else if (id === 6) {
            return <UilCar />
        } else if (id === 7) {
            return <UilDesktopAlt />
        } else if (id === 18) {
            return <UilDesktopCloudAlt />
        } else if (id === 15) {
            return <UilBriefcase />
        } else if (id === 19) {
            return <UilFilesLandscapesAlt />
        } else if (id === 20) {
            return <UilBuilding />
        } else if (id === 13) {
            return <UilHdd />
        } else if (id === 11) {
            return <UilScrew />
        } else if (id === 9) {
            return <UilCreditCard />
        } else if (id === 12) {
            return <UilUserSquare />
        } else if (id === 14) {
            return <UilMouse />
        } else if (id === 21) {
            return <UilFlask />
        } else if (id === 22) {
            return <UilBed />
        } else if (id === 16) {
            return <UilDesktop />
        } else if (id === 23) {
            return <UilBookAlt />
        } else if (id === 24) {
            return <UilCar />
        } else if (id === 8) {
            return <UilMobileAndroid />
        } else if (id === 25) {
            return <UilWindow />
        } else if (id === 17) {
            return <UilPanoramaHAlt />
        } else {
            return <UilFolderQuestion />
        }
    }
    const addInventory = () => {
        setInventoryAddDrawer(true);
        setAddInventoryComponentKey((i) => i + 1);
    }
    const openInventoryDetails = (id, assigmentResourceId, assigmentDate) => {
        setSelectedid(id);
        setSelectedOwnerShipResourceId(assigmentResourceId);
        setSelectedOwnerShipAssigmentDate(assigmentDate);
        getInventoryDetails(id);
        getInventoryOwnerShipDetails(id);
        setInventoryDetailsDrawer(true);
        setInventoryDetailsComponentKey((i) => i + 1);
    }
    const moreIconDetails = (e, id, serialNumber, inventoryTypeName, inventoryTypeId, inventoryObj, statusName, inventoryName) => {
        e.preventDefault();
        setDeleteModalData({ ...deleteModalData, 'id': id, 'serialNumber': serialNumber, 'inventoryName': inventoryName });
        setDoPassiveModalData({ ...doPassiveModalData, 'id': id, 'serialNumber': serialNumber, 'inventoryType': inventoryTypeName });
        setDoActiveModalData({ ...doActiveModalData, 'id': id, 'serialNumber': serialNumber, 'inventoryType': inventoryTypeName });
        setOwnerShipData({ inventoryType: { 'id': inventoryTypeId, 'displayValue': inventoryTypeName }, inventory: { 'id': inventoryObj.id, 'displayValue': inventoryObj.displayValue + ' - ' + serialNumber }, statusName: statusName });
        setAddComponentKey((i) => i + 1);
    }
    const data = inventoryList.map((row) => {
        return (
            {
                "key": row.resourceId,
                "InventoryTypeName": {
                    "text": row.inventoryType?.displayValue,
                    "inventoryTypeId": row.inventoryType?.id,
                    "id": row.resourceId, "serial": row.serialNumber,
                    "assigmentResourceId": row.assigmentResourceId,
                    "inventory": row.inventory,
                    "statusName": row.status,
                    "statusId": row.statusId,
                    "isActive": row.isActive,
                    "assigmentDate": row.assigmentDate,
                    "languageKey": row.inventoryType.languageKey,
                },
                "brand": row.brand,
                "model": row.model,
                "serialNumber": row.serialNumber,
                "state": row.status,
                "personName": row.personName,
                "assigmentDate": row.assigmentDate ? moment(row.assigmentDate).format(dateFormatConvert) : '',
            }
        );
    })
    function handleExportDocument(props) {
        exportData.exportType = props;
        inventoryListExport(exportData);
        setIsExportModalVisible(false);
    }
    const canDelete = (assigmentResourceId) => {
        if (assigmentResourceId) {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': t('RESOURCE_CAN_NOT_BE_DELETED'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
        } else {
            setDeleteModalData({ ...deleteModalData, "isVisible": true });
        }
    }
    const canPassive = (assigmentResourceId) => {
        if (assigmentResourceId) {
            DynamicToastMessage({
                'key': 'PASSIVE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': t('RESOURCE_CAN_NOT_BE_PASSIVE'),
                'statusType': 'error',
                'position': 'bottom-right'
            })
        } else {
            setDoPassiveModalData({ ...doPassiveModalData, "isVisible": true })
        }
    }
    const openImportModal = () => {
        setImportData({ ...importData, 'key': importData?.key + 1, 'isVisible': true });

    }
    const closeImportModal = () => {
        setImportData({ ...importData, 'isVisible': false });
    }


          // #region OnBoarding
          const [popoverVisible, setPopoverVisible] = useState(false);
          const hide = () => { 
            setPopoverVisible(false);
            if(props.currentTaskState == 91){
                return props.setCurrentTaskState(92); 
            } 
            props.setCurrentTaskState(null);  
        };
          const handlePopoverVisibleChange = (visible) => {
              if(visible == false){ 
                if(props.currentTaskState == 91){
                        props.setCurrentTaskState(92); 
                    }else{
                        props.setCurrentTaskState(null); 
                    }
                }
              if(props.currentTaskState !== null){
                  setPopoverVisible(visible);
              } 
          };
          
        OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'pages/inventory/index' );
        
        useEffect(() => { 
            if(props.currentTaskState == 71){
                setPopoverVisible(true)
            }
            if(props.currentTaskState == 72){
                addInventory()
            }
            if(props.currentTaskState == 73){
                addInventory()
            }
            if(props.currentTaskState == 91){
                setPopoverVisible(true)
            }

        }, [props.currentTaskState]); 
    
        useEffect(() => {
            if(addInventoryStatus == false && props.currentTaskState == 73){
                props.setCurrentTaskState(74);
            }
            if(addInventoryStatus == false && props.currentTaskState == 72){
                props.setCurrentTaskState(null);
            }
            if(addInventoryStatus == false && props.currentTaskState == 1006){
                props.setCurrentTaskState(1007);
            }
        }, [addInventoryStatus])

    

        // #endregion

    return (
        <React.Fragment>
            <Can I="inventory.list">
                <Layout>
                    <ExportModal
                        onCancel={() => { setIsExportModalVisible(false) }}
                        status={isExportModalVisible}
                        handleExportDocument={handleExportDocument}
                    />
                    <DynamicRequestModal
                        key={deleteModalData?.id}
                        modalVisible={deleteModalData.isVisible}
                        setStateData={setDeleteModalData}
                        postAction={deleteInventory}
                        confirmData={deleteModalData.id}
                        iconName='UilTrash'
                        modalTitle={t('PARTNER_DELETE')}
                        description={t('ARE_YOU_SURE_DELETE_INVENTORY', { "serialNumber": deleteModalData?.serialNumber, "inventoryName": deleteModalData?.inventoryName })}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('PARTNER_YES_DELETE')}
                    />
                    <DynamicRequestModal
                        key={doPassiveModalData.id+'-passive'}
                        modalVisible={doPassiveModalData.isVisible}
                        setStateData={setDoPassiveModalData}
                        postAction={updateStatusInventory}
                        confirmData={doPassiveModalData.id}
                        isMultipleData={'passive'}
                        iconName='UilBan'
                        modalTitle={t('PARTNER_DO_PASSIVE')}
                        description={t('ARE_YOU_SURE_DO_PASSIVE_DESC', { "serialNumber": doPassiveModalData?.serialNumber, "inventoryType": doPassiveModalData?.inventoryType })}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('MAKE_IT_PASSIVE')}
                    />
                    <DynamicRequestModal
                        key={doPassiveModalData.id+'-active'}
                        modalVisible={doActiveModalData.isVisible}
                        setStateData={setDoActiveModalData}
                        postAction={updateStatusInventory}
                        confirmData={doPassiveModalData.id}
                        isMultipleData={'active'}
                        iconName='UilQuestionCircle'
                        modalTitle={t('DO_ACTIVE')}
                        description={t('ARE_YOU_SURE_DO_ACTIVE_DESC', { "serialNumber": doActiveModalData?.serialNumber, "inventoryType": doActiveModalData?.inventoryType })}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('MAKE_IT_ACTIVE')}
                    />
                    <InventoryImportModal
                        key={importData?.key}
                        modalState={importData}
                        cancelBtnFunction={closeImportModal}
                        modalType={'inventory'}
                    />
                    <Layout>
                        <Col span={24}>
                            <Content className="page-container inventory-container">
                                <BreadCrump />
                                <div className='space-between'>
                                    <div className="tab-menu">
                                        <h1 className="page-title">{t('DEVICES')}({totalNumberOfRecords})</h1>
                                    </div>
                                    <div className="tab-btn ">
                                        <Can I="inventory.export">
                                            <Button onClick={openImportModal} className="white-btn flex-align-center svg-class f-14 mr-2 import-btn" icon={<UilFileImport />}>{t('SETTINGS_IMPORT')}</Button>
                                        </Can>
                                        <Can I="inventory.export">
                                            <Popover
                                                key={'inventory.add'}
                                                overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                                                content={<PopoverContent key={'inventory.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                                title={false}
                                                placement="left"
                                                trigger="click"
                                                visible={popoverVisible && props.currentTaskState == 91 && props.currentTaskState !== null}
                                                onVisibleChange={handlePopoverVisibleChange}
                                            >
                                                
                                             <Button onClick={() => (popoverVisible && props.currentTaskState == 91 && props.currentTaskState !== null) ? {} : setIsExportModalVisible(true)} 
                                                className={`white-btn flex-align-center svg-class f-14 mr-3 export-btn ${(popoverVisible && props.currentTaskState == 91 && props.currentTaskState !== null) ? 'onboarding-button-border bg-white' : ''}`} 
                                                icon={<UilFileExport />}>{t('SETTINGS_EXPORT')}</Button>
                                            </Popover>
                                        </Can>
                                        <Popover
                                            key={'inventory.add'}
                                            overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                                            content={<PopoverContent key={'inventory.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                            title={false}
                                            placement="left"
                                            trigger="click"
                                            visible={popoverVisible && props.currentTaskState == 71 && props.currentTaskState !== null}
                                            onVisibleChange={handlePopoverVisibleChange}
                                        >
                                        <Button onClick={() => addInventory()}
                                        className={`primary-btn w-230 h-32 f-14 ${(popoverVisible && props.currentTaskState == 71) ? 'onboarding-button-border' : ''}`}
                                        icon={<PlusOutlined />}>
                                            {/* <Button onClick={() => addInventory()} className="primary-btn px-15  h-32 f-14 margin-r-24" icon={<PlusOutlined />}> */}
                                            {t('INVENTORY_DEVICE')}
                                        </Button>
                                        </Popover>

                                    </div>
                                </div>
                                <div className="table-container">
                                    <Media query="(min-width: 875px)" >
                                        {matches => {
                                            const columns = [
                                                {
                                                    title: `${t('INVENTORY_TYPE')}`,
                                                    dataIndex: 'InventoryTypeName',
                                                    key: 'InventoryTypeName',
                                                    sorter: true,
                                                    render: obj =>
                                                        <div className="imageNameActionColumn">
                                                            <span className='inventory-text'>
                                                                {getIcon(obj.languageKey)}
                                                            </span>
                                                            <Can I="inventory.read">
                                                                {width >= 875 ?
                                                                    <React.Fragment>
                                                                        <a onClick={() => openInventoryDetails(obj.id, obj.assigmentResourceId, obj.assigmentDate)}
                                                                            className="details-text" >
                                                                            {obj.text}</a>
                                                                        {(permissions.includes('inventory.delete') || permissions.includes('inventory.canpassive') || permissions.includes('inventory.canassign')) &&
                                                                            <Dropdown
                                                                                className="action-menu"
                                                                                overlay={inventoryListDropdown(t, canDelete, canPassive, setDoActiveModalData, doActiveModalData, setCreateOwnerShipDrawer, obj.assigmentResourceId, obj.isActive, obj.statusId, permissions)}
                                                                                trigger={['click']}
                                                                            >
                                                                                <Button onClick={e => moreIconDetails(e, obj.id, obj.serial, obj.text, obj.inventoryTypeId, obj.inventory, obj.statusName, obj.inventory?.displayValue)} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                                            </Dropdown>
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                                                }
                                                            </Can>
                                                            <Cannot I="inventory.read">
                                                                <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                                            </Cannot>
                                                        </div>
                                                },
                                                {
                                                    title: `${t('INVENTORY_PERSON_DEPARTMENT')}`,
                                                    dataIndex: 'personName',
                                                    key: 'personName',
                                                    sorter: true,
                                                },
                                                {
                                                    title: `${t('INVENTORY_ASSIGNMENT_DATE')}`,
                                                    dataIndex: 'assigmentDate',
                                                    key: 'assigmentDate',
                                                    sorter: true,
                                                },
                                            ];
                                            if (matches) {
                                                columns.splice(1, 0,
                                                    {
                                                        title: `${t('INVENTORY_BRAND')}`,
                                                        dataIndex: 'brand',
                                                        key: 'brand',
                                                        sorter: true,
                                                    }
                                                );
                                                columns.splice(2, 0,
                                                    {
                                                        title: `${t('INVENTORY_MODEL')}`,
                                                        dataIndex: 'model',
                                                        key: 'model',
                                                        sorter: true,
                                                    }
                                                );
                                                columns.splice(3, 0,
                                                    {
                                                        title: `${t('INVENTORY_SERIAL_NUMBER')}`,
                                                        dataIndex: 'serialNumber',
                                                        key: 'serialNumber',
                                                        sorter: true,
                                                    }
                                                );
                                                columns.splice(4, 0,
                                                    {
                                                        title: `${t('INVENTORY_STATU')}`,
                                                        dataIndex: 'state',
                                                        key: 'state',
                                                        sorter: true,
                                                    }
                                                );
                                            }
                                            return <DynamicTable
                                                columns={columns} dataSource={data}
                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                                                onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                                                searchPlaceholder={t('INVENTORY_SEARCH_TEXT')}
                                                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                                                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                                loadStatus={isInventoryListLoading}
                                                checkboxStatus={false}
                                            />
                                        }}
                                    </Media>
                                </div>
                                <Drawer key={addInventoryComponentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={addInventoryStatus} >  {/* EKLE DRAWI */}
                                    <AddInventoryTabs addComponentKey={addInventoryComponentKey} />
                                </Drawer>
                                <Drawer key={inventoryDetailsComponentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={detailInventoryStatus} >  {/* EKLE DRAWI */}
                                    <InventoryDetailTabs selectedId={selectedId} selectedOwnerShip={selectedOwnerShipResourceId} />
                                </Drawer>
                                <Drawer key={returnComponentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={ownershipTabReducer.ownerShipReturnDrawer} > {/* Iade DRAWI */}
                                    <OwnerShipReturn selectedOwnerShip={[selectedOwnerShipResourceId]} selectedOwnerShipAssigmentDate={selectedOwnerShipAssigmentDate} />
                                </Drawer >
                                <Drawer key={addComponentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={tabreducer.createOwnerShipDrawer} > {/* Ekle DRAWI */}
                                    <OwnerShipAddTabs ownerShipData={ownerShipData} isOwnerShipPage={true} />
                                </Drawer >
                            </Content >
                        </Col>
                    </Layout>
                </Layout >
                <ToastContainer />
            </Can>
            <Cannot I="inventory.list">
                <Forbidden />
            </Cannot>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        isInventoryListLoading: state.inventory.isInventoryListLoading,
        inventoryList: state.inventory.inventoryList.results,
        totalNumberOfRecords: state.inventory.inventoryList.totalNumberOfRecords,
        gridSettings: state.setting.gridSettings,
        createGridSettingsResponse: state.setting.createGridSettingsResponse,
        inventoryCreateResponse: state.inventory.inventoryCreateResponse,
        updateInventoryResponse: state.inventory.updateInventoryResponse,
        inventoryStatusResponse: state.inventory.inventoryStatusResponse,
        deleteInventoryResponse: state.inventory.deleteInventoryResponse,
        ownershipTabReducer: state.ownershipTabReducer,
        returnOwnerShipResponse: state.ownership.returnOwnerShipResponse,
        tabreducer: state.tabreducer,
        addInventoryStatus: state.inventory.addInventoryDrawer,
        detailInventoryStatus: state.inventory.inventoryDetailsDrawer,
        ownershipCreateResponse: state.ownership.ownershipCreateResponse,
        inventoryImportResponse: state.inventory.inventoryImportResponse,
        permissions: state.profile.permissions,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getInventoryList: (searchData) => { dispatch(getInventoryList(searchData)) },
        getGridSetting: (props) => { dispatch(getGridSetting(props)) },
        createGridSettings: (props) => { dispatch(createGridSettings(props)) },
        setInventoryAddDrawer: (props) => { dispatch(setInventoryAddDrawer(props)) },
        setInventoryDetailsDrawer: (props) => { dispatch(setInventoryDetailsDrawer(props)) },
        setInventoryDetailsDrawer: (props) => { dispatch(setInventoryDetailsDrawer(props)) },
        getInventoryDetails: (resourceId) => { dispatch(getInventoryDetails(resourceId)) },
        getInventoryOwnerShipDetails: (resourceId) => { dispatch(getInventoryOwnerShipDetails(resourceId)) },
        resetInventoryStatus: () => { dispatch(resetInventoryStatus()) },
        deleteInventoryReset: () => { dispatch(deleteInventoryReset()) },
        inventoryListExport: (props) => { dispatch(inventoryListExport(props)) },
        deleteInventory: (resourceId) => { dispatch(deleteInventory(resourceId)) },
        updateStatusInventory: (status, resourceId) => { dispatch(updateStatusInventory(status, resourceId)) },
        setCreateOwnerShipDrawer: (props) => { dispatch(setCreateOwnerShipDrawer(props)) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryPage);