import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Button, Form, Input, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons'
import { noData } from '../../utils/commonFormFunctions';
import { dateFormatConvert } from '../../utils/config';
import {
    createJobName, resetCreateJobName, getJobList,
    updateJobName, resetUpdateJobName, deleteJobName,
    resetDeleteJobName, getPersonsJobsConnect, setJobConnectDraw
} from '../../redux/actions/job.actions';

import moment from 'moment'
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir

import Can from '../../authorization/index';


import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';


function JobList(props) {
    const { totalNumberOfRecords, jobSearchData, pageNumber, getJobList, updateJobNameResponse, setJobConnectDraw,
        updateJobName, resetUpdateJobName, jobList, jobListLoading, createJobName, jobCreateResponse, getPersonsJobsConnect,
        resetCreateJobName, deleteJobName, resetDeleteJobName, deleteJobNameResponse, jobConnectSearchData
    } = props;
    const { t } = useTranslation();
    const [searchData, setSearchData] = useState(jobSearchData)
    const [heightState, setHeightState] = useState(window.innerHeight);


    const [addModalState, setAddModalState] = useState({ 'isVisible': false })
    const [addModalFormData, setAddModalFormData] = useState({})
    const [addForm] = Form.useForm();

    const [editModalState, setEditModalState] = useState({ 'isVisible': false })
    const [editModalFormData, setEditModalFormData] = useState({})
    const [editForm] = Form.useForm();

    const [deleteModalState, setDeleteModalState] = useState({ 'isVisible': false })



    //Table


    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.page, 'pageSize': data.pageSize }

        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const customTableChange = (page, pageSize) => {
        handleTableChange({ "page": page, "pageSize": pageSize })
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        current: pageNumber,
        pageSize: searchData.pagination.pageSize,
        locale: { items_per_page: "/ " + t('PAGE') },
        onChange: (a, b) => customTableChange(a, b),
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ''
    })

    useEffect(() => {
        if (searchData !== null) {
            getJobList(searchData);
        }
    }, [searchData])

    //Table


    //Job List Add

    useEffect(() => {
        if (jobCreateResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': jobCreateResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            setAddModalState({ "isVisible": false })
            setAddModalFormData({});
            const fields = addForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            addForm.setFieldsValue(projects)

            getJobList(jobSearchData)
            resetCreateJobName();


        } else if (jobCreateResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': jobCreateResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateJobName();
        }
    }, [jobCreateResponse])


    const addJobTitle = () => {
        setAddModalState({ addModalState, 'isVisible': true })
    }
    const handleValuesAddChange = (changedObj, all) => {
        setAddModalFormData({ ...addModalFormData, ...changedObj });
    }
    const addModalForm = (
        <div className="table-details pad-0">
            <Form
                form={addForm}
                onValuesChange={handleValuesAddChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_JOB_NAME')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
    const handleAddCancel = () => {
        setAddModalFormData({});
        setAddModalState({ "isVisible": false })
        const fields = addForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        addForm.setFieldsValue(projects)
    }

    const [isCreateLoading, setisCreateLoading] = useState(false);

    const handleAddConfirm = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        let hasError = false
        let fields = []
        if (!addModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_JOB_NAME'))
        }

        if (!hasError) {
            const data = {
                "name": addModalFormData.name,
            }
            createJobName(data)


        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    //Job List Add


    //Job List Edit

    useEffect(() => {
        if (updateJobNameResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': updateJobNameResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            setEditModalState({ "isVisible": false })
            setEditModalFormData({});
            const fields = editForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            editForm.setFieldsValue(projects)

            getJobList(jobSearchData)
            resetUpdateJobName();

        } else if (updateJobNameResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateJobNameResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateJobName();
        }
    }, [updateJobNameResponse])

    const editJobList = (id, name) => {
        setEditModalState({ ...editModalState, 'isVisible': true, 'id': id })
        setEditModalFormData({ ...editModalFormData, 'name': name })
        const setObj = {
            "name": name
        }
        editForm.setFieldsValue(setObj)
    }
    const handleValuesEditChange = (changedObj, all) => {
        setEditModalFormData({ ...addModalFormData, ...changedObj });
    }
    const editModalForm = (
        <div className="table-details pad-0">
            <Form
                form={editForm}
                onValuesChange={handleValuesEditChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_JOB_NAME')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    )

    const handleEditConfirm = () => {
        let hasError = false
        let fields = []
        if (!editModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_JOB_NAME'))
        }

        if (!hasError) {
            const data = {
                "name": editModalFormData.name,
            }
            updateJobName(data, editModalState.id)


        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleEditCancel = () => {
        setEditModalFormData({});
        setEditModalState({ "isVisible": false })
        const fields = editForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        editForm.setFieldsValue(projects)
    }

    //Job List Edit


    //Job List Delete
    useEffect(() => {
        if (deleteJobNameResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': deleteJobNameResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getJobList(searchData)
            resetDeleteJobName();

        } else if (deleteJobNameResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': deleteJobNameResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetDeleteJobName();
        }
    }, [deleteJobNameResponse])

    const deleteJobNameModal = (id) => {
        setDeleteModalState({ ...deleteModalState, 'isVisible': true, 'id': id })
    }

    //Job List Delete


    //Job list person
    const showConnectedJobDrawer = (id) => {
        getPersonsJobsConnect(jobConnectSearchData, id);
        setJobConnectDraw({ "status": true, "id": id });
    }

    return (
        <div className="custom-table-card-container job-list-container">

            <DynamicRequestModal
                modalVisible={addModalState.isVisible}
                setStateData={setAddModalState}
                isForm={addModalForm}
                handleConfirm={handleAddConfirm}
                handleCancel={handleAddCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('ADD_NEW_TITLES')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('SETTINGS_CREATE')}
            />

            <DynamicRequestModal
                modalVisible={editModalState.isVisible}
                setStateData={setEditModalState}
                isForm={editModalForm}
                handleConfirm={handleEditConfirm}
                handleCancel={handleEditCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('PARTNER_EDIT')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('PARTNER_SAVE')}
            />

            <DynamicRequestModal
                modalVisible={deleteModalState.isVisible}
                setStateData={setDeleteModalState}
                postAction={deleteJobName}
                confirmData={deleteModalState.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_JOB_NAME_DESCRIPTION')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />


            <div className="custom-table-card-add">
                <Can I="jobtitle.add">
                    <Button onClick={() => addJobTitle()} className="primary-btn m-w-180 h-32"><PlusOutlined /> {t('ADD_NEW_TITLES')}</Button>
                </Can>
            </div>

            <div className="custom-table-card-class">

                {totalNumberOfRecords > 0 ?
                    <div className="custom-table-card-title-class job-list-column">
                        <Col span={24}>
                            <Row>
                                <Col span={6}>
                                    <h1>{t('PARTNER_JOB_NAME')}</h1>
                                </Col>
                                <Col span={6}>
                                    <h1>{t('CREATE_DATE')}</h1>
                                </Col>

                            </Row>
                        </Col>
                    </div>
                    : ''}
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={totalNumberOfRecords > 10 ? paginationConfig() : null}
                    dataSource={jobList}
                    loading={jobListLoading}
                    className={jobList.length === 0 ? 'table-border-bottom-0' : 'custom-table-list'}
                    locale={{ emptyText: (<div className="loading-container">{noData(t)}</div>) }}
                    renderItem={data => (
                        <List.Item
                            key={data?.resourceId + 'fs'}
                            className="job-list-item"


                        >
                            <Card key={data?.resourceId} className="custom-table-card job-list-card" >
                                <Col span={24}>
                                    <Row>
                                        <Col span={6}>
                                            <p>{data?.name}</p>
                                        </Col>
                                        <Col span={6}>
                                            <p>{data.dateCreated ? moment(data.dateCreated).format(dateFormatConvert) : ''}</p>
                                        </Col>
                                        <Col span={6}>
                                            {data.personCount > 0 ?
                                                <a onClick={() => showConnectedJobDrawer(data.resourceId)} className="details-text">
                                                    {data.personCount + ' ' + t('DEBIT_PERSON')}
                                                </a>
                                                :
                                                <p className="details-empty-text">{t('NONE')}</p>
                                            }
                                        </Col>
                                        <Col span={4}>

                                        </Col>
                                        <Col span={1}>
                                            <Can I="jobtitle.update">
                                                <div onClick={() => editJobList(data.resourceId, data.name)} className="custom-table-card-icon flex-end clickable">
                                                    <UilEditAlt />
                                                </div>
                                            </Can>
                                        </Col>
                                        <Col span={1}>
                                            <Can I="jobtitle.delete">
                                                <div onClick={() => deleteJobNameModal(data.resourceId)} className="custom-table-card-icon flex-end clickable">
                                                    <UilTrash />
                                                </div>
                                            </Can>
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </List.Item>
                    )}
                />



            </div>



        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        jobList: state.job.jobList.results,
        totalNumberOfRecords: state.job.jobList.totalNumberOfRecords,
        pageNumber: state.job.jobList.pageNumber,
        jobListLoading: state.job.jobListLoading,
        jobCreateResponse: state.job.jobCreateResponse,
        updateJobNameResponse: state.job.updateJobNameResponse,
        deleteJobNameResponse: state.job.deleteJobNameResponse
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createJobName: (data) => { dispatch(createJobName(data)) },
        resetCreateJobName: () => { dispatch(resetCreateJobName()) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) },
        updateJobName: (data, id) => { dispatch(updateJobName(data, id)) },
        resetUpdateJobName: () => { dispatch(resetUpdateJobName()) },
        deleteJobName: (id) => { dispatch(deleteJobName(id)) },
        resetDeleteJobName: () => { dispatch(resetDeleteJobName()) },
        getPersonsJobsConnect: (searchData, resourceId) => { dispatch(getPersonsJobsConnect(searchData, resourceId)) },
        setJobConnectDraw: (props) => { dispatch(setJobConnectDraw(props)) },



    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobList);