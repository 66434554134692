import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Spin, Dropdown, Image, List, Button, Table } from 'antd';
import { UilEllipsisV, UilImport, UilInfoCircle } from '@iconscout/react-unicons';

import { setTimeOffAddDrawer, setTimeOffRequestApproveDrawer } from '../../redux/actions/time-off-drawer-action'
import {
    getTimeOffPaiChartData, getTimeOffTypesList, getFilterTimeOffType, timeOffRequestSearch, timeOffDownload, timeOffCancel,
    resetTimeOffApproveResponseStatus, getTimeOffRequest, getTimeOffRequestStep, deleteTimeOffRequestReset, deleteTimeOffRequest,
    timeOffRequestUpdateReset, downloadAgreementForm, getExtraValueDetail
} from '../../redux/actions/timeOff.actions';
import {
    getTimeOffDashboardPaiChartData
} from '../../redux/actions/dashboard.actions';

import { getTimeOffStateType } from "../../redux/actions/dataset-action";
import { timeoff_dropdown } from "../../features/global-dropdown-menu/index";

import moment from 'moment'
import DynamicSelectbox from "../dynamicSelectbox";
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { dateFormatConvert } from '../../utils/config';

function TimeOffTab(props) {
    const { t } = useTranslation();
    const { getTimeOffDashboardPaiChartData, paiChartLoading, timeOffStatuses, getFilterTimeOffType, timeOffDownload,
        paiChartData, timeOffRequestSearch, timeOffRequestSearchIsLoading, timeOffRequestList, timeOffRequestUpdateReset,
        getTimeOffTypesList, person, getTimeOffStateType, totalNumberOfRecords, pageNumber, timeOffCancel, resetTimeOffApproveResponseStatus,
        timeOffApproveResponse, permissions, setTimeOffRequestApproveDrawer, getTimeOffRequest, getTimeOffRequestStep,
        deleteTimeOffRequestResponse, deleteTimeOffRequest, deleteTimeOffRequestReset, downloadAgreementForm, getExtraValueDetail } = props;
    const { handleCloseModal, cancelModalData, setCancelModalData, deleteModalData, setDeleteModalData, timeOffApproveUpdateStatus,
        setSelectedTimeOff, extraValueDetailLoading, extraValueDetail, disableStatus } = props;
    const key = "timeOffDrawerTabs";
    const statusClass = { "1": "green", "2": "black", "3": "red", "4": "blue" };
    const initialSearchData = {
        "searchTerm": "",
        "timeOffTypes": [],
        "personResourceId": null,
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "startDate",
            "ascending": false
        }
    }
    const initialGetStateTypeFilterData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 999,
            "orderBy": "StateTypeName",
            "ascending": true
        }
    }
    const initialGetTimeOffTypeFilterData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 999,
            "orderBy": "TimeOffTypeName",
            "ascending": true
        }
    }
    const [searchData, setSearchData] = useState({ ...initialSearchData, personResourceId: person.resourceId })
    const [timeOffStatusList, setTimeOffStatusList] = useState([]);
    const [timeOffYearsInfoModal, setTimeOffYearsInfoModal] = useState({ 'isVisible': false, 'detailData': [] });
    const [filterData, setFilterData] = useState(null);
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        current: pageNumber,
        pageSize: searchData.pagination.pageSize,
        locale: { items_per_page: "/ " + t('PAGE') },
        onChange: (a, b) => customTableChange(a, b),
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ''
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.page, 'pageSize': data.pageSize }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const customTableChange = (page, pageSize) => {
        handleTableChange({ "page": page, "pageSize": pageSize })
    }
    const noData = () => {
        return (
            <div className="table-empty-container">
                <div className="table-empty-image">
                    <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
                </div>
                <div className="table-empty-text">
                    <h1> {t('NO_DATA_TEXT')}</h1>
                </div>
            </div>
        )
    }
    const filterByTimeOffType = (e) => {
        if (e.resourceId == "") {
            setSearchData({ ...searchData, 'stateTypes': [] })
            setFilterData(null);
        } else {
            setSearchData({ ...searchData, 'stateTypes': [e.resourceId] });
            setFilterData(e);
        }
    }
    const timeOffCancelBtn = (id) => {
        setCancelModalData({ ...cancelModalData, "isVisible": true, "id": id })
    }
    const timeOffDeletelBtn = (id, timeOffTypeName) => {
        setDeleteModalData({ ...deleteModalData, "isVisible": true, "id": id, 'timeOffTypeName': timeOffTypeName })
    }
    const openTimeOffDetailsDrawer = (id, requestDate, timeOfffTypeName, approveSeqNumber, postId) => {
        setSelectedTimeOff({ 'resourceId': id, 'requestDate': requestDate, 'timeOfffTypeName': timeOfffTypeName, 'approveSeqNumber': approveSeqNumber, 'postId': postId, 'fullName': person.givenName + " " + person.familyName })
        setTimeOffRequestApproveDrawer(true);
        if (id !== undefined) {
            getTimeOffRequest(id);
            getTimeOffRequestStep(id);
        }
    }
    useEffect(() => {
        getFilterTimeOffType(initialGetTimeOffTypeFilterData)
    }, [])
    useEffect(() => {
        if (searchData.personResourceId !== null) {
            timeOffRequestSearch(searchData)
        }
    }, [searchData]);
    useEffect(() => {
        setTimeOffStatusList(timeOffStatuses);
    }, [timeOffStatuses])
    useEffect(() => {
        if (deleteTimeOffRequestResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            setSearchData({ ...initialSearchData, personResourceId: person.resourceId })
            timeOffRequestSearch(searchData)
            getTimeOffPaiChartData(person.resourceId)
            deleteTimeOffRequestReset()
        } else if (deleteTimeOffRequestResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_FAIL',
                'title': t('ERROR'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffRequestReset()
        }
    }, [deleteTimeOffRequestResponse])
    useEffect(() => {
        getTimeOffDashboardPaiChartData(person.resourceId, 'FH25DW56DYU5')
        getTimeOffTypesList()
    }, [])
    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'APPROVE_SUCCESS',
                'title': t('SUCCESS'),
                'description': timeOffApproveResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (timeOffApproveResponse.resourceId !== undefined) {
                getTimeOffRequest(timeOffApproveResponse.resourceId)
                getTimeOffRequestStep(timeOffApproveResponse.resourceId)
            }
            timeOffRequestSearch(searchData)
            getTimeOffPaiChartData(person.resourceId)
            handleCloseModal();
            resetTimeOffApproveResponseStatus()
        }
    }, [timeOffApproveResponse])
    useEffect(() => {
        if (timeOffApproveUpdateStatus.status === 'SUCCESS') {
            timeOffRequestSearch(searchData)
            timeOffRequestUpdateReset();
        }
    }, [timeOffApproveUpdateStatus]);
    useEffect(() => {
        if (extraValueDetail?.histories?.length > 0) {
            setTimeOffYearsInfoModal({ ...timeOffYearsInfoModal, 'detailData': extraValueDetail?.histories });
        }
    }, [extraValueDetail])
    const timeOffYearsInfoTableColumn = [
        {
            title: `${t('MODIFIED_DATE')}`,
            dataIndex: 'actionDate',
            key: 'actionDate',
            render: obj =>
                <div className="table-center-text">{moment(obj).format('LL')}</div>
        },
        {
            title: `${t('SETTINGS_TIME')}`,
            dataIndex: 'extraValue',
            key: 'extraValue',
        },
        {
            title: `${t('TIME_OFF_REQUEST_NAME_SURNAME')}`,
            dataIndex: 'actionUser',
            key: 'actionUser',
            render: obj => <span className={!obj ? 'pl-8' : ''}>{obj ? obj : '-'}</span>
        },
        {
            title: `${t('PARTNER_DESCRIPTION')}`,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: `${t('TYPE')}`,
            dataIndex: 'type',
            key: 'type',

        },
    ];
    const getTypeName = (typeId) => {
        switch (typeId) {
            case 0:
                return <span className='pl-3'>-</span>;
            case 1:
                return t('ONLY_FOR_THIS_YEAR');
            case 2:
                return t('CARRY_OVER_TO_THE_NEXT_YEAR');
            default:
                return <span className='pl-3'>-</span>;
        }
    }
    const timeOffYearsInfoTableData = timeOffYearsInfoModal.detailData.map((row, index) => {
        return (
            {
                "key": index,
                "actionDate": row.actionDate,
                "extraValue": t("TIME_OFF_REQUEST_DAYS", { "day": row.extraValue }),
                "actionUser": row.actionUser,
                "description": row.description,
                "type": getTypeName(row.extraValueValidTypeIdx)
            }
        )
    });
    const openTimeOffDuration = () => {
        getExtraValueDetail(person.resourceId);
        setTimeOffYearsInfoModal({ ...timeOffYearsInfoModal, 'isVisible': true, 'title': t('EXTRA_TIME_OFF'), 'detailData': [] })
    }
    const timeOffYearsInfoModalCancel = () => {
        setTimeOffYearsInfoModal({ 'isVisible': false, 'detailData': [] })
    }
    const timeOffYearsInfoContent = (
        extraValueDetailLoading ?
            <Spin className="tab-loading w-100" /> :
            timeOffYearsInfoModal.detailData.length > 0 ?
                <div className="table-container w-100">
                    <Table className={timeOffYearsInfoTableData.length === 0 ? 'table-border-bottom-0' : ''}
                        loading={false}
                        bordered={true}
                        columns={timeOffYearsInfoTableColumn}
                        dataSource={timeOffYearsInfoTableData}
                        pagination={false}
                    />
                </div>
                :
                <label className="f-16 grey-color w-100 align-center" >{t('NO_HISTORICAL_INFORMATION_AVAILABLE')}</label>
    )
    return (
        !paiChartLoading && !timeOffRequestSearchIsLoading ?
            <div className="permission-body">
                <DynamicRequestModal
                    modalVisible={timeOffYearsInfoModal?.isVisible}
                    setStateData={setTimeOffYearsInfoModal}
                    handleCancel={timeOffYearsInfoModalCancel}
                    confirmData={timeOffYearsInfoModal?.id}
                    isForm={timeOffYearsInfoContent}
                    modalWidth={1200}
                    iconName=''
                    modalTitle={timeOffYearsInfoModal.title}
                    description={''}
                    cancelBtnTxt={t('PARTNER_CANCEL')}
                    hideButton={true}
                />
                <div className="remaining-timeoff-card-wrapper">
                    <div className="space-between mb-4">
                        <div className="d-flex svg-class">
                            <span className="f-16 grey-color">{t('EXTRA_TIME_OFF')} :</span>
                            <Button disabled={disableStatus} className="transparent-btn pad-0 svg-class flex-align-center" onClick={() => openTimeOffDuration()} icon={<UilInfoCircle />} />
                        </div>
                        <Button onClick={() => downloadAgreementForm(person.resourceId)} className="flex-class svg-class primary-btn h-32 w-auto" icon={<UilImport />}>{t('DOWNLOAD_RECONCILIATION_FORM')}</Button>
                    </div>
                    {
                        paiChartData.map((data, index) => {
                            return (
                                <Card key={index} className="timeoff-rights-card">
                                    <h1>{data.timeOffTypeName}</h1>
                                    <div className="timeoff-person-drawer-card-container">
                                        <div className="timeoff-person-drawer-card-item">
                                            <div className="timeoff-person-drawer-card-label">
                                                {t("TIME_OFF_REQUEST_REMAINING_TIME") + " : "}
                                            </div>
                                            <div className="timeoff-person-drawer-card-text">
                                                {t("TIME_OFF_REQUEST_DAYS", { "day": data.remainingValue })}
                                            </div>
                                        </div>
                                        <div className="timeoff-person-drawer-card-item">
                                            <div className="timeoff-person-drawer-card-label">
                                                {t("DURATION_OF_ANNUAL_LEAVE") + " : "}
                                            </div>
                                            <div className="timeoff-person-drawer-card-text">
                                                {t("TIME_OFF_REQUEST_DAYS", { "day": data.accrualBalanceYearly })}
                                            </div>
                                        </div>
                                        <div className="timeoff-person-drawer-card-item">
                                            <div className="timeoff-person-drawer-card-label">
                                                {t("NEXT_TIME_OFF_ACCRUAL_DURATION") + " : "}
                                            </div>
                                            <div className="timeoff-person-drawer-card-text">
                                                {data.newYearlyTimeOffRequestDate && moment(data.newYearlyTimeOffRequestDate).format(dateFormatConvert)}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="trc-row">

                                        <div className="info-column-primary sm">

                                            <div className="trc-column-row">
                                                <div className="trc-label">{t("TIME_OFF_REQUEST_REMAINING_TIME")}</div>
                                                <div>{t("TIME_OFF_REQUEST_DAYS", { "day": data.remainingValue })}</div>
                                            </div>
                                            <div className="trc-column-row">
                                                <div className="trc-label">{t("DURATION_OF_ANNUAL_LEAVE")}</div>
                                                <div>{t("TIME_OFF_REQUEST_DAYS", { "day": data.accrualBalanceYearly })}</div>
                                            </div>
                                            <div className="trc-column-row">
                                                <div className="trc-label">{t("NEXT_TIME_OFF_ACCRUAL_DURATION")}</div>
                                                <div>{data.newYearlyTimeOffRequestDate && moment(data.newYearlyTimeOffRequestDate).format(dateFormatConvert)}</div>
                                            </div>
                                        </div>
                                    </div> */}
                                </Card>
                            )
                        })
                    }
                </div>
                <div className="permission-status">
                    <div className="d-flex w-100 filter custom-select">
                        <DynamicSelectbox
                            optionList={timeOffStatusList}
                            placeholder={t('PARTNER_FILTER_TIMEOFF_REQUEST')}
                            onFocus={() => getTimeOffStateType(initialGetStateTypeFilterData)}
                            onChange={(e) => filterByTimeOffType(e)}
                            fields={{ 'id': 'resourceId', 'displayValue': 'stateTypeName' }}
                            value={filterData}
                        />
                    </div>
                    <div className="d-block w-100 permission-info filter">
                        <List
                            itemLayout="vertical"
                            size="large"
                            pagination={totalNumberOfRecords > 10 ? paginationConfig() : null}
                            dataSource={timeOffRequestList}
                            className={timeOffRequestList.length === 0 ? 'custom-table-list table-border-bottom-0' : 'custom-table-list'} locale={{ emptyText: (noData()) }}
                            renderItem={data => (
                                <List.Item key={data.resourceId + 'fs'} >
                                    <Card className="permission-info-card">
                                        <p className={permissions.includes("timeoff.request.all.read") ? "clickable" : ""} onClick={() => permissions.includes("timeoff.request.all.read") ? openTimeOffDetailsDrawer(data.resourceId, data?.requestDate, data?.timeOffTypeName, data?.approveSeqNumber, data.timeOffRequestId) : null}>{data.timeOffTypeName}</p>
                                        <div className="perm-info-card-desc">
                                            <Row>
                                                <Col onClick={() => permissions.includes("timeoff.request.all.read") ? openTimeOffDetailsDrawer(data.resourceId, data?.requestDate, data?.timeOffTypeName, data?.approveSeqNumber, data.timeOffRequestId) : null} span={10}>
                                                    <h1 className={permissions.includes("timeoff.request.all.read") ? "clickable" : ""} >{moment(data.startDate).format('DD')}-{moment(data.endDate).format('DD')} {moment(data.startDate).format('MMMM YYYY')}</h1>
                                                </Col>
                                                <Col onClick={() => permissions.includes("timeoff.request.all.read") ? openTimeOffDetailsDrawer(data.resourceId, data?.requestDate, data?.timeOffTypeName, data?.approveSeqNumber, data.timeOffRequestId) : null} span={5}>
                                                    <h1 className={permissions.includes("timeoff.request.all.read") ? "clickable" : ""} >
                                                        {data.unitSetIdx === 1 ? t('TIME_OFF_REQUEST_DAYS', { "day": data.balance }) : t('TIME_OFF_REQUEST_HOURS', { "hour": data.balance })}
                                                    </h1>
                                                </Col>
                                                <Col onClick={() => permissions.includes("timeoff.request.all.read") ? openTimeOffDetailsDrawer(data.resourceId, data?.requestDate, data?.timeOffTypeName, data?.approveSeqNumber, data.timeOffRequestId) : null} span={7}>
                                                    <h1 className={statusClass[data?.stateType?.id] + ' clickable'}>{data?.stateType?.displayValue}</h1>
                                                </Col>
                                                <Col span={2}>
                                                    <div className="more">
                                                        {permissions.some(item => ["timeoff.request.cancel", "timeoff.request.export", "timeoff.request.delete"].includes(item)) &&
                                                            <Dropdown disabled={disableStatus} overlay={timeoff_dropdown(t, timeOffCancelBtn, timeOffDownload, timeOffDeletelBtn, data.resourceId, data?.stateType?.id, data?.timeOffTypeName, permissions)} trigger={['click']}>
                                                                <UilEllipsisV />
                                                            </Dropdown>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                <DynamicRequestModal
                    key={cancelModalData?.id+'-TIME_OFF_CANCEL_BTN'}
                    modalVisible={cancelModalData?.isVisible}
                    setStateData={setCancelModalData}
                    postAction={timeOffCancel}
                    confirmData={{ "resourceId": cancelModalData?.id, "key": key }}
                    iconName='UilQuestionCircle'
                    modalTitle={t('TIME_OFF_CANCEL_BTN')}
                    description={t('')}
                    cancelBtnTxt={t('PARTNER_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_CANCEL')}
                />
                <DynamicRequestModal
                    key={deleteModalData?.id+'-SETTINGS_DELETE'}
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteTimeOffRequest}
                    confirmData={deleteModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('SETTINGS_DELETE')}
                    description={t('TIMEOFF_DELETE_MODAL_DESCRIPTION', { "timeOffType": deleteModalData?.timeOffTypeName })}
                    cancelBtnTxt={t('PARTNER_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
            </div>
            :
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
    )
}
const mapStateToProps = (state) => {
    return {
        paiChartData: state.dashboard.timeOffPaiChartData,
        paiChartLoading: state.dashboard.timeOffPaiChartDataLoading,
        profile: state.profile,
        permissions: state.profile.permissions,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        timeOffRequestList: state.timeOff.timeOffRequestSearchResult.results,
        timeOffRequestSearchIsLoading: state.timeOff.timeOffRequestSearchIsLoading,
        timeOffStatuses: state.dataset.timeOffStateType.results,
        totalNumberOfRecords: state.timeOff.timeOffRequestSearchResult.totalNumberOfRecords,
        pageNumber: state.timeOff.timeOffRequestSearchResult.pageNumber,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        deleteTimeOffRequestResponse: state.timeOff.deleteTimeOffRequestResponse,
        timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus,
        extraValueDetailLoading: state.timeOff.extraValueDetailLoading,
        extraValueDetail: state.timeOff.extraValueDetail,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        timeOffCancel: (data) => { dispatch(timeOffCancel(data)) },
        timeOffRequestSearch: (searchData) => { dispatch(timeOffRequestSearch(searchData)) },
        setTimeOffAddDrawer: (props) => { dispatch(setTimeOffAddDrawer(props)) },
        getTimeOffTypesList: () => { dispatch(getTimeOffTypesList()) },
        getFilterTimeOffType: (searchData) => { dispatch(getFilterTimeOffType(searchData)) },
        getTimeOffStateType: (searchData) => { dispatch(getTimeOffStateType(searchData)) },
        timeOffDownload: (props) => { dispatch(timeOffDownload(props)) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        deleteTimeOffRequest: (resourceId) => { dispatch(deleteTimeOffRequest(resourceId)) },
        deleteTimeOffRequestReset: () => { dispatch(deleteTimeOffRequestReset()) },
        getTimeOffDashboardPaiChartData: (personResourceId, timeOffResourceId) => { dispatch(getTimeOffDashboardPaiChartData(personResourceId, timeOffResourceId)) },
        timeOffRequestUpdateReset: () => { dispatch(timeOffRequestUpdateReset()) },
        downloadAgreementForm: (resourceId) => { dispatch(downloadAgreementForm(resourceId)) },
        getExtraValueDetail: (resourceId) => { dispatch(getExtraValueDetail(resourceId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffTab);