import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId, resetTimeOffInfoNotifications, resetWorkHourByDate, getTimeOffRequestInfo, getWorkHourByDate } from '../../../../redux/actions/timeOff.actions'
import { deleteUploadFileWithType, deleteUploadFileWithTypeResponseReset } from '../../../../redux/actions/person.actions';

import { Form, DatePicker, TimePicker, ConfigProvider, Row, Col, Radio, Upload, Button } from 'antd';
import moment from 'moment';

import { UilTrashAlt } from '@iconscout/react-unicons';
import { FileAltIcon } from '../../../../features/Icon/Icons';
import { dateFormatWithHours, dateFormat, hoursFormat, localeLang, apiUrl } from '../../../../utils/config';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import DynamicToastMessage from '../../../dynamicToastMessage/index';
import DynamicRequestModal from "../../../dynamicModal/requestModal";

import '../../../../styles/person.scss';

function TimeOffWaitingApprove(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const defaultStartDayType = 0;
    const defaultEndDayType = 2;
    const { status, getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId, resetTimeOffInfoNotifications, workHourStartEndByDate, workHourStartEndByDateLoading,
        timeOffRequestDetails, getUpdateObj, declineButtonRef, timeOffInfoByTypeDateWarning, selectID, workHourStartByDate, profile,
        workHourStartByDateLoading, workHourEndByDate, workHourEndByDateLoading, resetWorkHourByDate, getTimeOffRequestInfo, getWorkHourByDate,
        timeOffRequestInfo, timeOffRequestInfoLoading, waitingApproveState, setWaitingApproveState, auth, deleteFileWithTypeResponse, deleteUploadFileWithType,
        deleteUploadFileWithTypeResponseReset, timeOffRequestStep } = props;
    const organizationResourceId = profile.organization?.resourceId;
    const [disableFormState, setDisableFormState] = useState({
        'dayRadioDisableStatus': true,
        'timeStartButtonDisableStatus': false,
        'timeEndButtonDisableStatus': false,
        'timePickerDisableStatus': true,
        'chooseFullDay': false,
    });
    const [defaultFormObj, setDefaultFormObj] = useState({});
    const [defaultStateObj, setDefaultStateObj] = useState({});
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

    const startTimeX = timeOffRequestDetails.startDate;
    const startTimeXArray = startTimeX.split(" ");
    const endDateTimeX = timeOffRequestDetails.endDate;
    const endDateTimeXArray = endDateTimeX.split(" ");
    const access_token = auth.user?.access_token;
    const documentDetailUploadProgress = {
        name: 'file',
        action: apiUrl + '/expenses/upload/',
        headers: {
            "Authorization": `Bearer ${access_token}`,
            "Accept-Language": localStorage.getItem('i18nLang')
        },
        listType: 'picture',
        accept: ".xlsx,.pdf,.docx,.png,.jpg,.jpeg",
        maxCount: 100,
        showUploadList: { showRemoveIcon: true, removeIcon: <div className='error-message'><span>{t('ERROR')}</span><UilTrashAlt /></div> },
        iconRender: () => {
            return <FileAltIcon />
        },
        onChange(info) {
            if (info.file.status === 'done') {
                DynamicToastMessage({ 'key': 'detail_upload_document_success', 'title': t('SUCCESS'), 'description': info.file.response.message, 'statusType': 'success', 'position': 'bottom-right' })
                const documentsArr = Array.isArray(waitingApproveState.Documents) ? waitingApproveState.Documents : [];
                var stateData = [...documentsArr];
                var documentUploadedDate = moment().format(dateFormatWithHours);
                stateData.push({
                    'documentName': info.file.name,
                    'documentUrl': info.file?.response?.data,
                    'uploadDate': documentUploadedDate
                });
                setWaitingApproveState({ ...waitingApproveState, 'Documents': stateData });
            } else if (info.file.status === 'error') {
                DynamicToastMessage({ 'key': 'detail_upload_document_fail', 'title': t('ERROR'), 'description': info.file.response.message, 'statusType': 'error', 'position': 'bottom-right' })
            }
        },
        progress: {
            strokeColor: {
                '0%': '#7CC8C8',
                '100%': '#7CC8C8',
            },
            strokeWidth: 8,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    function requestGetTimeOffInfo() {
        let obj = {}
        if (timeOffRequestDetails.unitId === 1) {
            obj = {
                "startDate": moment(timeOffRequestDetails.startDate).format(dateFormat),
                'startDayType': timeOffRequestDetails.startDayType,
                'endDate': moment(timeOffRequestDetails.endDate).format(dateFormat),
                'endDayType': timeOffRequestDetails.endDayType,
                'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                'personResourceId': timeOffRequestDetails?.person?.resourceId
            }
        } else {
            obj = {
                "startDate": moment(timeOffRequestDetails.startDate).format(dateFormatWithHours),
                'startDayType': timeOffRequestDetails.startDayType,
                'endDate': moment(timeOffRequestDetails.endDate).format(dateFormatWithHours),
                'endDayType': timeOffRequestDetails.endDayType,
                'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                'personResourceId': timeOffRequestDetails?.person?.resourceId
            }
        }
        getTimeOffRequestInfo(obj)
    }
    useEffect(() => {
        if (timeOffRequestDetails.timeOffType?.resourceId !== null) {
            setDefaultFormObj({
                "TimeOffType_ResourceId": timeOffRequestDetails.timeOffType?.resourceId,
                "StartDate": moment(timeOffRequestDetails.startDate, dateFormat),
                "StartDateTime": moment(startTimeXArray[1], hoursFormat),
                "EndDate": moment(timeOffRequestDetails.endDate, dateFormat),
                "EndDateTime": moment(endDateTimeXArray[1], hoursFormat),
                "contactMail": timeOffRequestDetails.groupEmailAddr,
                "contactPhones": timeOffRequestDetails.contactPhones,
                "description": timeOffRequestDetails.description,
                "balance": timeOffRequestDetails.balance,
                "balanceWeekend": timeOffRequestDetails.balanceWeekend,
                "startWorkDate": timeOffRequestDetails.startWorkDate,
                "StartDayType": timeOffRequestDetails.startDayType,
                "StartDateChooseDayType": timeOffRequestDetails.startDateChooseDayType,
                "EndDateChooseDayType": timeOffRequestDetails.endDateChooseDayType,
                "EndDayType": timeOffRequestDetails.endDayType,
                "Documents": timeOffRequestDetails.documents
            });
            setDefaultStateObj({
                "TimeOffType_ResourceId": timeOffRequestDetails.timeOffType?.resourceId,
                "StartDate": moment(timeOffRequestDetails.startDate, dateFormat),
                "StartDateTime": startTimeXArray[1],
                "EndDate": moment(timeOffRequestDetails.endDate, dateFormat),
                "EndDateTime": endDateTimeXArray[1],
                "contactMail": timeOffRequestDetails.groupEmailAddr,
                "contactPhones": timeOffRequestDetails.contactPhones,
                "description": timeOffRequestDetails.description,
                "balance": timeOffRequestDetails.balance,
                "balanceWeekend": timeOffRequestDetails.balanceWeekend,
                "startWorkDate": timeOffRequestDetails.startWorkDate,
                "StartDayType": timeOffRequestDetails.startDayType,
                "StartDateChooseDayType": timeOffRequestDetails.startDateChooseDayType,
                "EndDateChooseDayType": timeOffRequestDetails.endDateChooseDayType,
                "EndDayType": timeOffRequestDetails.endDayType,
                "unitId": timeOffRequestDetails.unitId,
                "Documents": timeOffRequestDetails.documents
            });
            if (moment(timeOffRequestDetails.startDate).isValid()) {
                requestGetTimeOffInfo()
                const startDatex = moment(timeOffRequestDetails.startDate);
                const endDatex = moment(timeOffRequestDetails.endDate);
                const result = endDatex.diff(startDatex, 'days');
                if (result <= 0) {
                    setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true, 'chooseFullDay': true })
                }
            }
        }
    }, [timeOffRequestDetails])
    useEffect(() => {
        setWaitingApproveState(defaultStateObj)
    }, [defaultStateObj])
    useEffect(() => {
        form.setFieldsValue(defaultFormObj)
    }, [defaultFormObj])
    useEffect(() => {
        if (status === true) {
            const getObj = {
                "TimeOffType_ResourceId": timeOffRequestDetails.timeOffType?.resourceId,
                "endDate": moment(timeOffRequestDetails.endDate).format(dateFormat),
                "startDate": moment(timeOffRequestDetails.startDate).format(dateFormat),
                "personResourceId": timeOffRequestDetails.person?.resourceId,
                "timeOffResourceId": selectID
            }
            getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId(getObj)
            requestGetTimeOffInfo()
        }
    }, [status])
    useEffect(() => {
        getUpdateObj(waitingApproveState)
    }, [waitingApproveState]);

    useEffect(() => {
        if (declineButtonRef === true) {
            setWaitingApproveState(defaultFormObj)
            form.setFieldsValue(defaultFormObj)
        }
    }, [declineButtonRef])

    const getDisabledTime = (start, end, exclude) => {
        const result = [];
        for (let i = start; i <= end; i++) {
            if (!exclude.includes(i)) {
                result.push(i);
            }
        }
        return result;
    }
    const range = (start, end) => {
        const result = [];
        for (let i = start; i <= end; i++) {
            result.push(i);
        }
        return result;
    }
    useEffect(() => {
        function updateStartDateTime() {
            if (!workHourStartByDateLoading) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                if (workHourStartByDate.data !== "") {
                    fields['StartDateTime'] = moment(workHourStartByDate.data, hoursFormat);
                    form.setFieldsValue(fields)
                    setWaitingApproveState({ ...waitingApproveState, 'StartDateTime': workHourStartByDate.data })
                } else {
                    fields['StartDateTime'] = ""
                    form.setFieldsValue(fields)
                    setWaitingApproveState({ ...waitingApproveState, 'StartDateTime': "" })
                }
            }
        }
        function updateTimeOffRequestInfo() {
            if (!workHourStartByDateLoading && (moment(waitingApproveState.StartDate).isValid() && moment(waitingApproveState.EndDate).isValid())) {
                let infoObj = {};
                if (waitingApproveState.unitId === 1) {
                    infoObj = {
                        "startDate": moment(waitingApproveState.StartDate).format(dateFormat),
                        'startDayType': waitingApproveState.StartDayType,
                        'endDate': moment(waitingApproveState.EndDate).format(dateFormat),
                        'endDayType': waitingApproveState.EndDayType,
                        'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                        'personResourceId': timeOffRequestDetails?.person?.resourceId
                    }
                    getTimeOffRequestInfo(infoObj)
                } else {
                    const StartDate = moment(waitingApproveState.StartDate).format(dateFormat);
                    var startDateWithHours = moment(StartDate + ' ' + workHourStartByDate.data).format(dateFormatWithHours);

                    const EndDate = moment(waitingApproveState.EndDate).format(dateFormat);
                    var endDateWithHours = moment(EndDate + ' ' + waitingApproveState.EndDateTime).format(dateFormatWithHours);

                    infoObj = {
                        "startDate": moment(startDateWithHours).format(dateFormatWithHours),
                        'startDayType': waitingApproveState.StartDayType,
                        'endDate': moment(endDateWithHours).format(dateFormatWithHours),
                        'endDayType': waitingApproveState.EndDayType,
                        'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                        'personResourceId': timeOffRequestDetails?.person?.resourceId
                    }
                    getTimeOffRequestInfo(infoObj)
                }
            }
        }
        async function dataUpdate() {
            if (workHourStartByDate.status === "SUCCESS" && status) {
                await updateStartDateTime();
                await updateTimeOffRequestInfo();
            }
            else if (workHourStartByDate.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': workHourStartByDate.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['StartDate'] = "";
                fields['StartDateTime'] = "";
                form.setFieldsValue(fields)
                setWaitingApproveState({ ...waitingApproveState, 'StartDate': null, 'StartDateTime': "" })
                resetWorkHourByDate('start')
            }
        }
        dataUpdate();
    }, [workHourStartByDate])
    useEffect(() => {
        function updateStartDateTime() {
            if (!workHourStartEndByDateLoading) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                if (workHourStartEndByDate.data !== "") {
                    fields['StartDateTime'] = moment(workHourStartEndByDate.data, hoursFormat);
                    form.setFieldsValue(fields)
                    setWaitingApproveState({ ...waitingApproveState, 'StartDateTime': workHourStartEndByDate.data })
                } else {
                    fields['StartDateTime'] = ""
                    form.setFieldsValue(fields)
                    setWaitingApproveState({ ...waitingApproveState, 'StartDateTime': "" })
                }
            }
        }
        function checkEndDate() {
            if (!workHourStartEndByDateLoading && (moment(waitingApproveState.StartDate).isValid() && moment(waitingApproveState.EndDate).isValid())) {
                getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), 2, 'end', timeOffRequestDetails?.person?.resourceId)
            }
        }
        async function dataUpdate() {
            if (workHourStartEndByDate.status === "SUCCESS") {
                await updateStartDateTime();
                await checkEndDate();
            }
            else if (workHourStartEndByDate.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': workHourStartEndByDate.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['StartDate'] = "";
                fields['StartDateTime'] = "";
                form.setFieldsValue(fields)
                setWaitingApproveState({ ...waitingApproveState, 'StartDate': null, 'StartDateTime': "" })
                resetWorkHourByDate('startEnd')
            }
        }
        dataUpdate();
    }, [workHourStartEndByDate])

    useEffect(() => {
        function updateEndDateTime() {
            if (!workHourEndByDateLoading) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                if (workHourEndByDate.data !== "") {
                    fields['EndDateTime'] = moment(workHourEndByDate.data, hoursFormat);
                    form.setFieldsValue(fields)
                    setWaitingApproveState({ ...waitingApproveState, 'EndDateTime': workHourEndByDate.data })
                } else {
                    fields['EndDateTime'] = ""
                    form.setFieldsValue(fields)
                    setWaitingApproveState({ ...waitingApproveState, 'EndDateTime': "" })
                }
            }
        }
        function updateTimeOffRequestInfo() {
            const startDate = moment(waitingApproveState.StartDate);
            const endDate = moment(waitingApproveState.EndDate);
            const result = endDate.diff(startDate, 'days');
            const StartDate = moment(waitingApproveState.StartDate).format(dateFormat);
            const startDateWithHours = moment(StartDate + ' ' + waitingApproveState.StartDateTime).format(dateFormatWithHours);
            const EndDate = moment(waitingApproveState.EndDate).format(dateFormat);
            const endDateWithHours = moment(EndDate + ' ' + workHourEndByDate.data).format(dateFormatWithHours);
            let infoObj = {}
            if (result <= 0 && waitingApproveState.StartDayType === 1) {
                if (waitingApproveState.unitId === 1) {
                    infoObj = {
                        "startDate": startDateWithHours,
                        'startDayType': waitingApproveState.StartDayType,
                        'endDate': endDateWithHours,
                        'endDayType': 2,
                        'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                        'personResourceId': timeOffRequestDetails?.person?.resourceId
                    }
                } else {
                    infoObj = {
                        "startDate": startDateWithHours,
                        'startDayType': waitingApproveState.StartDayType,
                        'endDate': endDateWithHours,
                        'endDayType': 2,
                        'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                        'personResourceId': timeOffRequestDetails?.person?.resourceId
                    }
                }
                getTimeOffRequestInfo(infoObj)
            } else {
                if (!workHourEndByDateLoading && (moment(waitingApproveState.StartDate).isValid() && moment(waitingApproveState.EndDate).isValid())) {
                    if (waitingApproveState.unitId === 3) {
                        infoObj = {
                            "startDate": startDateWithHours,
                            'startDayType': waitingApproveState.StartDayType,
                            'endDate': endDateWithHours,
                            'endDayType': 1,
                            'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                            'personResourceId': timeOffRequestDetails?.person?.resourceId
                        }
                    } else {
                        infoObj = {
                            "startDate": moment(waitingApproveState.StartDate).format(dateFormat),
                            'startDayType': waitingApproveState.StartDayType,
                            'endDate': moment(waitingApproveState.EndDate).format(dateFormat),
                            'endDayType': waitingApproveState.EndDayType,
                            'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                            'personResourceId': timeOffRequestDetails?.person?.resourceId
                        }
                    }
                    getTimeOffRequestInfo(infoObj)
                }
            }
        }
        async function dataUpdate() {
            if (workHourEndByDate.status === "SUCCESS" && status) {
                await updateEndDateTime();
                await updateTimeOffRequestInfo();
            } else if (workHourEndByDate.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': workHourEndByDate.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['EndDate'] = "";
                fields['EndDateTime'] = "";
                form.setFieldsValue(fields)
                setWaitingApproveState({ ...waitingApproveState, 'EndDate': null, 'EndDateTime': "" })
                resetWorkHourByDate('end')
            }
        }
        dataUpdate();
    }, [workHourEndByDate])
    const handleValuesChange = (changedObj, all) => {
        let key = Object.keys(changedObj)[0];
        if (key === 'StartDate') {
            if (waitingApproveState.EndDate && changedObj['StartDate'] !== null) {
                const getObj = {
                    "TimeOffType_ResourceId": timeOffRequestDetails.timeOffType?.resourceId,
                    "endDate": moment(waitingApproveState.EndDate).format(dateFormat),
                    "startDate": moment(changedObj['StartDate']).format(dateFormat),
                    "personResourceId": timeOffRequestDetails.person?.resourceId,
                    "timeOffResourceId": selectID
                }
                getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId(getObj)

                const startDatex = moment(changedObj['StartDate']);
                const endDatex = moment(waitingApproveState.EndDate);
                const result = endDatex.diff(startDatex, 'days');
                if (result <= 0) {
                    const fieldsTemp = form.getFieldsValue()
                    const fields = { ...fieldsTemp };
                    if (waitingApproveState.StartDayType === 1) {
                        fields['EndDayType'] = 2;
                        form.setFieldsValue(fields);
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true, 'chooseFullDay': false })
                        setWaitingApproveState({ ...waitingApproveState, 'EndDayType': 2 })
                        getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), 2, 'end', timeOffRequestDetails?.person?.resourceId)
                    } else {
                        //result === 0 
                        fields['StartDateChooseDayType'] = 2;
                        fields['EndDateChooseDayType'] = 2;
                        form.setFieldsValue(fields);
                        getWorkHourByDate(organizationResourceId, moment(changedObj['StartDate']).format(dateFormat), waitingApproveState.StartDayType, 'start', timeOffRequestDetails?.person?.resourceId);
                        getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), waitingApproveState.EndDayType, 'end', timeOffRequestDetails?.person?.resourceId);
                        setWaitingApproveState({ ...waitingApproveState, "StartDate": changedObj['StartDate'], "StartDateChooseDayType": 2, "EndDateChooseDayType": 2 });
                        setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': false, 'timeEndButtonDisableStatus': false, 'chooseFullDay': true })
                    }
                } else {
                    setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false, 'chooseFullDay': false })
                    getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), waitingApproveState.StartDayType, 'start', timeOffRequestDetails?.person?.resourceId)
                    setWaitingApproveState({ ...waitingApproveState, "StartDate": changedObj['StartDate'] });
                }
            } else if (changedObj['StartDate'] === null) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['EndDate'] = ''
                fields['StartDateChooseDayType'] = 1
                fields['StartDayType'] = defaultStartDayType;
                form.setFieldsValue(fields)
                setDisableFormState({ ...disableFormState, 'chooseFullDay': false })
                setWaitingApproveState({ ...waitingApproveState, "StartDate": changedObj['StartDate'], EndDate: null, "StartDateChooseDayType": 1, 'StartDayType': defaultStartDayType });
            } else {
                if (!waitingApproveState.StartDayType) {
                    getWorkHourByDate(organizationResourceId, moment(changedObj['StartDate']).format(dateFormat), defaultStartDayType, 'start', timeOffRequestDetails?.person?.resourceId);
                }
                setWaitingApproveState({ ...waitingApproveState, "StartDate": changedObj['StartDate'] });
            }
        } else if (key === 'EndDate') {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            if (waitingApproveState.StartDate && changedObj['EndDate'] !== null) {
                const getObj = {
                    "TimeOffType_ResourceId": timeOffRequestDetails.timeOffType?.resourceId,
                    "startDate": moment(waitingApproveState.StartDate).format(dateFormat),
                    "endDate": moment(changedObj['EndDate']).format(dateFormat),
                    "personResourceId": timeOffRequestDetails.person?.resourceId,
                    "timeOffResourceId": selectID
                }
                getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId(getObj)

                const startDate = moment(waitingApproveState.StartDate);
                const endDate = moment(changedObj['EndDate']);
                const result = endDate.diff(startDate, 'days');

                if (result <= 0) {
                    if (waitingApproveState.StartDayType === 1) {
                        fields['EndDayType'] = 2;
                        form.setFieldsValue(fields);
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true, 'chooseFullDay': false });
                        setWaitingApproveState({ ...waitingApproveState, 'EndDayType': 2, "EndDate": changedObj['EndDate'] });
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), 2, 'end', timeOffRequestDetails?.person?.resourceId);
                    } else {
                        //result === 0 
                        fields['StartDateChooseDayType'] = 2;
                        fields['EndDateChooseDayType'] = 2;
                        form.setFieldsValue(fields);
                        getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), waitingApproveState.StartDayType, 'start', timeOffRequestDetails?.person?.resourceId);
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), waitingApproveState.EndDayType, 'end', timeOffRequestDetails?.person?.resourceId);
                        setWaitingApproveState({ ...waitingApproveState, "EndDate": changedObj['EndDate'], "StartDateChooseDayType": 2, "EndDateChooseDayType": 2 });
                        setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': false, 'timeEndButtonDisableStatus': false, 'chooseFullDay': true });
                    }
                } else {
                    setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false, 'chooseFullDay': false });
                    if (waitingApproveState.EndDateChooseDayType === 1) {
                        fields['EndDayType'] = defaultEndDayType;
                        form.setFieldsValue(fields);
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), defaultEndDayType, 'end', timeOffRequestDetails?.person?.resourceId);
                    } else {
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), waitingApproveState.EndDayType, 'end', timeOffRequestDetails?.person?.resourceId);
                    }
                    setWaitingApproveState({ ...waitingApproveState, "EndDate": changedObj['EndDate'] });
                }
            } else if (changedObj['EndDate'] === null) {
                fields['EndDateChooseDayType'] = 1
                form.setFieldsValue(fields)
                setWaitingApproveState({ ...waitingApproveState, "EndDate": changedObj['EndDate'], "EndDateChooseDayType": 1, 'EndDayType': defaultEndDayType });
            }
        } else if (key === "StartDateChooseDayType") {
            if (changedObj['StartDateChooseDayType'] === 1) {
                setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': true })
                setWaitingApproveState({ ...waitingApproveState, "StartDateChooseDayType": changedObj['StartDateChooseDayType'], "StartDayType": defaultStartDayType });
                getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), defaultStartDayType, 'start', timeOffRequestDetails?.person?.resourceId);
            } else {
                if (!waitingApproveState?.StartDayType) {
                    const fieldsTemp = form.getFieldsValue()
                    const fields = { ...fieldsTemp };
                    fields['StartDayType'] = defaultStartDayType
                    form.setFieldsValue(fields)
                    getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), defaultStartDayType, 'start', timeOffRequestDetails?.person?.resourceId);
                    setWaitingApproveState({ ...waitingApproveState, "StartDateChooseDayType": changedObj['StartDateChooseDayType'], 'StartDayType': defaultStartDayType });
                } else {
                    getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), waitingApproveState.StartDayType, 'start', timeOffRequestDetails?.person?.resourceId);
                    setWaitingApproveState({ ...waitingApproveState, "StartDateChooseDayType": changedObj['StartDateChooseDayType'] });
                }
                setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': false })
            }
        } else if (key === 'StartDayType') {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['StartDateTime'] = ''
            form.setFieldsValue(fields)
            if (changedObj['StartDayType'] === 3) {
                getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), defaultStartDayType, 'start', timeOffRequestDetails?.person?.resourceId)
                setWaitingApproveState({ ...waitingApproveState, "StartDayType": changedObj['StartDayType'] });
            } else {
                if (waitingApproveState.EndDate !== null) {
                    const startDatex = moment(waitingApproveState.StartDate);
                    const endDatex = moment(waitingApproveState.EndDate);
                    const result = endDatex.diff(startDatex, 'days');
                    if (result <= 0 && changedObj['StartDayType'] === 1) {
                        const fieldsTemp = form.getFieldsValue()
                        const fields = { ...fieldsTemp };
                        fields['EndDayType'] = 2;
                        form.setFieldsValue(fields);
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true })
                        setWaitingApproveState({ ...waitingApproveState, 'EndDayType': 2, 'StartDayType': changedObj['StartDayType'] })
                        //  getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), 2, 'end', timeOffRequestDetails?.person?.resourceId)
                        getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), changedObj['StartDayType'], 'startEnd', timeOffRequestDetails?.person?.resourceId)

                    } else {
                        setWaitingApproveState({ ...waitingApproveState, "StartDayType": changedObj['StartDayType'], "StartDateTime": '' });
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false })
                        getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), changedObj['StartDayType'], 'start', timeOffRequestDetails?.person?.resourceId)
                    }
                } else {
                    getWorkHourByDate(organizationResourceId, moment(waitingApproveState.StartDate).format(dateFormat), changedObj['StartDayType'], 'start', timeOffRequestDetails?.person?.resourceId)
                    setWaitingApproveState({ ...waitingApproveState, "StartDayType": changedObj['StartDayType'], "StartDateTime": '' });
                }
            }
        } else if (key === "EndDateChooseDayType") {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['EndDayType'] = defaultEndDayType;
            form.setFieldsValue(fields)
            getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), defaultEndDayType, 'end', timeOffRequestDetails?.person?.resourceId)
            setWaitingApproveState({ ...waitingApproveState, "EndDateChooseDayType": changedObj['EndDateChooseDayType'], "EndDayType": defaultEndDayType });
        } else if (key === 'EndDayType') {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['EndDateTime'] = ''
            form.setFieldsValue(fields)
            if (changedObj['EndDayType'] === 3) {
                getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), defaultEndDayType, 'end', timeOffRequestDetails?.person?.resourceId)
                setWaitingApproveState({ ...waitingApproveState, "EndDayType": changedObj['EndDayType'] });
            } else {
                setWaitingApproveState({ ...waitingApproveState, "EndDayType": changedObj['EndDayType'], "EndDateTime": '' });
                getWorkHourByDate(organizationResourceId, moment(waitingApproveState.EndDate).format(dateFormat), changedObj['EndDayType'], 'end', timeOffRequestDetails?.person?.resourceId)
            }
        } else if (key === 'StartDateTime') {
            if (waitingApproveState.EndDate !== null) {
                let infoObj = {};
                if (waitingApproveState.unitId === 1) {
                    infoObj = {
                        "startDate": moment(waitingApproveState.StartDate).format(dateFormat),
                        'startDayType': waitingApproveState.StartDayType,
                        'endDate': moment(waitingApproveState.EndDate).format(dateFormat),
                        'endDayType': waitingApproveState.EndDayType,
                        'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                        'personResourceId': timeOffRequestDetails?.person?.resourceId
                    }
                    getTimeOffRequestInfo(infoObj)
                } else {
                    const StartDate = moment(waitingApproveState.StartDate).format(dateFormat);
                    var startDateTime = moment(changedObj['StartDateTime']).format("HH:mm:ss")
                    var startDateWithHours = moment(StartDate + ' ' + startDateTime).format(dateFormatWithHours);

                    const EndDate = moment(waitingApproveState.EndDate).format(dateFormat);
                    var endDateWithHours = moment(EndDate + ' ' + waitingApproveState.EndDateTime).format(dateFormatWithHours);

                    infoObj = {
                        "startDate": startDateWithHours,
                        'startDayType': waitingApproveState.StartDayType,
                        'endDate': endDateWithHours,
                        'endDayType': waitingApproveState.EndDayType,
                        'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                        'personResourceId': timeOffRequestDetails?.person?.resourceId
                    }
                    getTimeOffRequestInfo(infoObj)
                }
            }
            setWaitingApproveState({ ...waitingApproveState, "StartDateTime": moment(changedObj['StartDateTime']).format("HH:mm:ss") });
        } else if (key === 'EndDateTime') {
            let infoObj = {};
            if (waitingApproveState.unitId === 1) {
                infoObj = {
                    "startDate": moment(waitingApproveState.StartDate).format(dateFormat),
                    'startDayType': waitingApproveState.StartDayType,
                    'endDate': moment(waitingApproveState.EndDate).format(dateFormat),
                    'endDayType': waitingApproveState.EndDayType,
                    'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                    'personResourceId': timeOffRequestDetails?.person?.resourceId
                }
            } else {
                const StartDate = moment(waitingApproveState.StartDate).format(dateFormat);
                var startDateWithHours = moment(StartDate + ' ' + waitingApproveState.StartDateTime).format(dateFormatWithHours);

                const EndDate = moment(waitingApproveState.EndDate).format(dateFormat);
                var endDateTime = moment(changedObj['EndDateTime']).format("HH:mm:ss")
                var endDateWithHours = moment(EndDate + ' ' + endDateTime).format(dateFormatWithHours);

                infoObj = {
                    "startDate": startDateWithHours,
                    'startDayType': waitingApproveState.StartDayType,
                    'endDate': endDateWithHours,
                    'endDayType': waitingApproveState.EndDayType,
                    'TimeOffTypeResourceId': timeOffRequestDetails.timeOffType?.resourceId,
                    'personResourceId': timeOffRequestDetails?.person?.resourceId
                }
            }
            getTimeOffRequestInfo(infoObj)
            setWaitingApproveState({ ...waitingApproveState, "EndDateTime": moment(changedObj['EndDateTime']).format("HH:mm:ss") });
        } else {
            setWaitingApproveState({ ...waitingApproveState, ...changedObj });
        }
    }
    useEffect(() => {
        if (timeOffInfoByTypeDateWarning.message) {
            DynamicToastMessage({
                'key': 'type_date_info_warning',
                'title': t('WARNING'),
                'description': timeOffInfoByTypeDateWarning.message,
                'statusType': 'warning',
                'position': 'bottom-right'
            })

            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['EndDate'] = ''
            form.setFieldsValue(fields)
            setWaitingApproveState({ ...waitingApproveState, EndDate: null });
            resetTimeOffInfoNotifications()
        }
    }, [timeOffInfoByTypeDateWarning])

    const disabledDate = (current) => {
        // let disabled = false
        // if(!timeOffInfoByType.oldEntryAllowed?.isOldEntryAllowed){
        //    // disabled = current < moment().subtract(1,"days");
        // }
        // return  current < moment().subtract(timeOffInfoByType.oldEntryAllowed?.maxOldEntryDuration,"days")
        return current > moment(waitingApproveState.EndDate)
    }
    const disabledEndDate = (current) => {
        return current < moment(waitingApproveState.StartDate)
    }
    const disabledDateTime = () => {
        return {
            disabledHours: () => getDisabledTime(0, 23, range(7, 21)),
            disabledMinutes: () => getDisabledTime(0, 60, [0, 30])
        };
    }
    useEffect(() => {
        if (deleteFileWithTypeResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'delete_file_success', 'title': t('SUCCESS'), 'description': deleteFileWithTypeResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            const documentsArr = waitingApproveState.Documents;
            const list = [...documentsArr];
            list.splice(deleteFileWithTypeResponse.index, 1);
            setWaitingApproveState({ ...waitingApproveState, 'Documents': list });
            deleteUploadFileWithTypeResponseReset();
        } else if (deleteFileWithTypeResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'delete_file_error', 'title': t('ERROR'), 'description': deleteFileWithTypeResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            deleteUploadFileWithTypeResponseReset();
        }
    }, [deleteFileWithTypeResponse])

    const uploadAction = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const viewDocument = (link) => {
        return window.open(link, '_blank', 'noopener,noreferrer')
    }
    const deleteDocumentModal = (documentUrl, documentName, index) => {
        setDeleteModalData({ ...deleteModalData, 'isVisible': true, "index": index, 'documentUrl': documentUrl, 'name': documentName })
    }
    const uploadFile = () => {
        setTimeout(() => {
            document.getElementById("detail-upload-btn").click()
        }, 500);
    }
    return (
        <>
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteUploadFileWithType}
                confirmData={{ 'index': deleteModalData.index, 'documentUrl': deleteModalData?.documentUrl, resourceId: selectID, type: 0 }}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_TIME_OFF_DOCUMENT_MODAL', { "name": deleteModalData?.name })}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <p className="black f-16 fw-600">{t('TIME_OFF_TYPE_TEXT')}</p>
                <div className="space-between">
                    <p className="black f-16">
                        {timeOffRequestDetails.timeOffType?.displayValue}
                    </p>
                    <div className="pad-right-12">
                        {timeOffRequestDetails?.timeOffType?.id === 1 &&
                            <>
                                <p className="line-10 flex-end">{t('TIME_OFF_REQUEST_ANNUAL_LEAVE_PERIOD', { "timeOffTypeName": timeOffRequestDetails?.timeOffType?.displayValue })}: <b>{t('TIME_OFF_REQUEST_DAYS', { "day": timeOffRequestDetails?.timeOffInfo?.timeOffBalance })}</b></p>
                                <p className="line-10 flex-end">{t('TIME_OFF_REQUEST_TIME_LEAVE_USED2')}: <b>{t('TIME_OFF_REQUEST_DAYS', { "day": timeOffRequestDetails?.timeOffInfo?.usedTimeOffBalance })}</b></p>
                                <p className="line-10 flex-end">{t('TIME_OFF_REQUEST_REMAINING_TIME')}: <b>{t('TIME_OFF_REQUEST_DAYS', { "day": timeOffRequestDetails?.timeOffInfo?.remainderTimeOffBalance })}</b></p>
                            </>
                        }
                    </div>
                </div>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                    className="time-off-request-form"
                >
                    {status ?
                        <div>
                            <Row>
                                <Col span={14}>
                                    <Form.Item name="StartDate" className={waitingApproveState?.StartDateChooseDayType === 2 ? "mar-0" : ""} label={t('TIME_OFF_REQUEST_START_TIME') + " *"} required>
                                        <DatePicker
                                            locale={localeLang}
                                            format={"LL"}
                                            disabledDate={disabledDate}
                                            disabledTime={disabledDateTime}
                                            disabled={false}
                                            hideDisabledOptions={true}
                                            showNow={false}
                                            inputReadOnly={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    {timeOffRequestDetails?.timeOffType?.id &&
                                        <div className="time-off-choosen-day-type">
                                            <Form.Item name="StartDateChooseDayType" label='' >
                                                <Radio.Group >
                                                    <Radio disabled={waitingApproveState.StartDate && disableFormState.chooseFullDay} value={1}>{t('FULL_DAY')}</Radio>
                                                    <Radio disabled={!waitingApproveState.StartDate} value={2}>{t('HALF_DAY')}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    }
                                </Col>
                                {timeOffRequestDetails?.timeOffType?.id &&
                                    <Col span={24}>
                                        {waitingApproveState?.StartDateChooseDayType === 2 &&
                                            <div className="time-off-choosen-day-button">
                                                <Form.Item name="StartDayType" label='' >
                                                    <Radio.Group>
                                                        <Radio.Button value={0} disabled={disableFormState.timeStartButtonDisableStatus}>{t('MORNING')}</Radio.Button>
                                                        <Radio.Button value={1} disabled={disableFormState.timeStartButtonDisableStatus}>{t('AFTERNOON')}</Radio.Button>
                                                        <Radio.Button value={3} disabled={timeOffRequestDetails.unitId === 1}>{t('SPECIAL_CLOCK')}</Radio.Button>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item className="time-off-choosen-start-time-picker" name="StartDateTime" label='' >
                                                    <TimePicker
                                                        locale={localeLang}
                                                        disabled={waitingApproveState.StartDayType !== 3}
                                                        popupClassName='time-off-choosen-start-time-picker-dropdown'
                                                        clearText={t('SETTINGS_DELETE')}
                                                        minuteStep={15}
                                                        inputReadOnly={true}
                                                        format={hoursFormat} />
                                                </Form.Item>
                                            </div>
                                        }
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col span={waitingApproveState.StartDate ? 14 : 24}>
                                    <Form.Item name="EndDate" className={waitingApproveState.EndDateChooseDayType === 2 ? "mar-0" : ""} label={t('TIME_OFF_REQUEST_END_TIME') + " *"} required>
                                        <DatePicker
                                            locale={localeLang}
                                            format={"LL"}
                                            disabledDate={disabledEndDate}
                                            disabledTime={disabledDateTime}
                                            disabled={!waitingApproveState?.StartDate}
                                            hideDisabledOptions={true}
                                            inputReadOnly={true}
                                            showNow={false}
                                            placeholder=''
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    {waitingApproveState.StartDate &&
                                        <div className="time-off-choosen-day-type">
                                            <Form.Item name="EndDateChooseDayType" label='' >
                                                <Radio.Group >
                                                    <Radio disabled={waitingApproveState.EndDate && disableFormState.chooseFullDay} value={1}>{t('FULL_DAY')}</Radio>
                                                    <Radio disabled={!waitingApproveState.EndDate} value={2}>{t('HALF_DAY')}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    }
                                </Col>
                                {waitingApproveState.StartDate &&
                                    <Col span={24}>
                                        {waitingApproveState.EndDateChooseDayType === 2 &&

                                            <div className="time-off-choosen-day-button">
                                                <Form.Item name="EndDayType" label='' >
                                                    <Radio.Group >
                                                        <Radio.Button value={1} disabled={disableFormState.timeEndButtonDisableStatus}>{t('AFTERNOON')}</Radio.Button>
                                                        <Radio.Button value={2} >{t('EVENING')}</Radio.Button>
                                                        <Radio.Button value={3} disabled={waitingApproveState.unitId === 1}>{t('SPECIAL_CLOCK')}</Radio.Button>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item className="time-off-choosen-end-time-picker" name="EndDateTime" label='' >
                                                    <TimePicker
                                                        locale={localeLang}
                                                        className="time-off-choosen-end-time-picker"
                                                        disabled={waitingApproveState.EndDayType !== 3}
                                                        popupClassName='time-off-choosen-end-time-picker-dropdown'
                                                        minuteStep={15}
                                                        inputReadOnly={true}
                                                        format={hoursFormat} />
                                                </Form.Item>
                                            </div>
                                        }
                                    </Col>
                                }
                            </Row>
                        </div>
                        :
                        <Form.Item label={t('TIME_OFF_REQUEST_REQUEST_DATE')} className="margin-b-0" >
                            <p className="black f-16 ">{moment(timeOffRequestDetails?.startDate).format('LLL')} - {moment(timeOffRequestDetails?.endDate).format('LLL')}</p>
                            <p></p>
                        </Form.Item>
                    }
                    <div>
                        {(!timeOffRequestInfoLoading && timeOffRequestInfo?.details?.length > 0 && timeOffRequestDetails?.endDate) &&
                            <Form.Item name="" label={t('TIME_OFF_DAYS_HOURS')} className="mar-0 pad-0">
                                <div className="time-off-request-info-class">
                                    {timeOffRequestInfo.details.map((data) => {
                                        return <h3>{data}</h3>
                                    })}
                                </div>
                            </Form.Item>
                        }
                        {(!timeOffRequestInfoLoading && timeOffRequestInfo?.details?.length > 0 && timeOffRequestDetails?.endDate) ?
                            (
                                <div className="pad-27">
                                    <div className="time-off-request-summary-row space-between w-100">
                                        <div className="green-text f-24"> {timeOffRequestInfo.totalInfo}</div>
                                        <div className="green-text f-24"> {timeOffRequestInfo.startDate}</div>
                                    </div>
                                    <Row>
                                        <Col span={timeOffRequestInfo.totalInfo !== "" ? 12 : 0}>
                                            <div className="w-100">{t('TIME_OFF_DURATION')}</div>
                                        </Col>
                                        <Col span={timeOffRequestInfo.totalInfo !== "" ? 12 : 24}>
                                            <div className="w-100 flex-end">{t('TIME_OFF_REQUEST_RETURN_DATE')}</div>
                                        </Col>
                                    </Row>
                                    <div className="pad-6" />
                                    {timeOffRequestInfo?.weekendDetail?.length > 0 && timeOffRequestInfo.weekendDetail.map((data) => {
                                        return <div className="w-100 green">{data}</div>
                                    })}

                                </div>
                            ) : ''
                        }
                    </div>
                    <Form.Item style={{ wordBreak: 'break-word' }} label={status ? t('PARTNER_TEXT_DESCRIPTION') : t('PARTNER_DESCRIPTION')} required>
                        <p className="black f-16"> {timeOffRequestDetails.description ? timeOffRequestDetails.description : t('NONE')}</p>
                    </Form.Item>
                    <Form.Item className="document-container" name="Documents" label={t('TIME_OFF_REQUEST_FILES')} valuePropName="fileList" getValueFromEvent={uploadAction}>
                        {Array.isArray(waitingApproveState?.Documents) ? waitingApproveState?.Documents.map((data, index) => {
                            return <div key={index} className="d-block pb-2 w-100">
                                <Row>
                                    <Col span={18}>
                                        <span className='document-name'>{data.documentName}</span>
                                    </Col>
                                    <Col span={6}>
                                        <div className='d-flex pl-4'>
                                            <Button className="transparent-btn f-16" onClick={() => viewDocument(data.documentUrl)}>{t('VIEW')}</Button>
                                            {status && <Button className="transparent-btn f-16 " onClick={() => deleteDocumentModal(data.documentUrl.split("https://ofixdisktest.blob.core.windows.net/hrplan-test/").join(''), data.documentName, index)}>{t('SETTINGS_DELETE')}</Button>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }) : ''}
                        <Upload name="document" {...documentDetailUploadProgress} >
                            <span id="detail-upload-btn"></span>
                        </Upload >
                        {status &&
                            <Button onClick={() => uploadFile()} className="transparent-btn pad-0 f-16">{t('UPLOAD_DOCUMENT')}</Button>
                        }

                    </Form.Item>
                    {status ?
                        <Form.Item name="contactMail" label={t('TIME_OFF_REQUEST_CONTACT_MAIL')}>
                            <MaskedInput className="ant-input" mask={emailMask} />
                        </Form.Item>
                        :
                        <Form.Item name="contactMail" label={t('TIME_OFF_REQUEST_CONTACT_MAIL')}>
                            <p className="black f-16"> {waitingApproveState.contactMail ? waitingApproveState.contactMail : t('NONE')}</p>
                        </Form.Item>
                    }
                    <Form.Item label={t('TIME_OFF_REQUEST_ENTER_CONTACT_INFORMATION')}>
                        {
                            timeOffRequestDetails.contactPhones.length > 0 ? timeOffRequestDetails.contactPhones.map((phone) => {
                                return (<p className="black f-16">{t('ORGANIZATION_PHONE') + ' : '} {phone.displayValue}</p>)
                            }) : <p className="black f-16">{t('NONE')}</p>}
                    </Form.Item>
                    <Form.Item label={t('TIME_OFF_REQUEST_STATUS')}>
                        <p className="black f-16">{timeOffRequestDetails.stateType?.displayValue}</p>
                    </Form.Item>
                    <Form.Item style={{ wordBreak: 'break-word' }} label={t('TIME_OFF_APPROVAL_NOTES')}>
                        {
                            timeOffRequestDetails.approvalDescriptions.length > 0 ?
                                timeOffRequestDetails.approvalDescriptions.map((approvalDescriptions) => {

                                    return (
                                        <p className="black f-16">
                                            {moment(approvalDescriptions?.approvalDate).format('LL')} - {approvalDescriptions?.personName} - {approvalDescriptions?.description}
                                        </p>
                                    )
                                })
                                :
                                <p className="black f-16">
                                    {t('TIME_OFF_THERE_IS_NO_APPROVAL_NOTE')}
                                </p>
                        }
                    </Form.Item>
                    {timeOffRequestStep[timeOffRequestStep.length - 1]?.description &&
                        <Form.Item label={t('CANCELLATION_DESCRIPTION')}>
                            <p className="black f-16">{timeOffRequestStep[timeOffRequestStep.length - 1].description}</p>
                        </Form.Item>
                    }
                </Form>
            </ConfigProvider >
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        timeOffInfoByTypeDate: state.timeOff.timeOffInfoByTypeDate,
        timeOffRequestDetails: state.timeOff.timeOffRequestDetails,
        timeOffRequestStep: state.timeOff.timeOffRequestStep.timeOffApproveList,
        timeOffInfoByTypeDateWarning: state.timeOff.timeOffInfoByTypeDateWarning,
        workHourStartByDate: state.timeOff.workHourStartByDate,
        workHourStartByDateLoading: state.timeOff.workHourStartByDateLoading,
        workHourEndByDate: state.timeOff.workHourEndByDate,
        workHourEndByDateLoading: state.timeOff.workHourEndByDateLoading,
        timeOffRequestInfo: state.timeOff.timeOffRequestInfo,
        timeOffRequestInfoLoading: state.timeOff.timeOffRequestInfoLoading,
        profile: state.profile,
        workHourStartEndByDate: state.timeOff.workHourStartEndByDate,
        workHourStartEndByDateLoading: state.timeOff.workHourStartEndByDateLoading,
        deleteFileWithTypeResponse: state.person.deleteFileWithTypeResponse,
        auth: state.auth,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId: (resourceId) => { dispatch(getTimeOffInfoByTimeOffTypeAndDateUpdateWithResourceId(resourceId)) },
        resetTimeOffInfoNotifications: () => { dispatch(resetTimeOffInfoNotifications()) },
        getTimeOffRequestInfo: (data) => { dispatch(getTimeOffRequestInfo(data)) },
        resetWorkHourByDate: (type) => { dispatch(resetWorkHourByDate(type)) },
        getWorkHourByDate: (organizationResourceId, date, dayType, type, personId) => { dispatch(getWorkHourByDate(organizationResourceId, date, dayType, type, personId)) },
        deleteUploadFileWithType: (obj) => { dispatch(deleteUploadFileWithType(obj)) },
        deleteUploadFileWithTypeResponseReset: () => { dispatch(deleteUploadFileWithTypeResponseReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffWaitingApprove);