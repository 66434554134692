import React from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Spin } from 'antd';
import { checkTextIsEmpty } from '../../utils/commonFormFunctions';
import PhoneInput from 'react-phone-input-2';
import { phoneInputConfig } from '../../utils/config';


function ProfileContact(props) {

    const { t } = useTranslation();
    const { personContactDetails, personContactIsFetching, editable, } = props;


    return (
        personContactIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <Form layout="vertical pad-top-10" >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                        <div className="form-left-body view-mode profile-col-1">
                            <Form.Item label={t('EMERGENCY_CONTACT_NAME')} required >
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.emergencyContactPersonName)}</p>
                            </Form.Item>
                            <Form.Item className="custom-select" label={t('EMERGENCY_CONTACT_DEGREE')}>
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.emergencyContactPersonDegreeOfProximity?.displayValue)}</p>
                            </Form.Item>
                            <Form.Item label={t('EMERGENCY_CONTACT_PHONE')}>
                                {personContactDetails.emergencyContactPersonPhone ?
                                    <PhoneInput
                                        {...phoneInputConfig}
                                        value={personContactDetails.emergencyContactPersonPhone}
                                        disableDropdown={true}
                                        disabled={true}
                                    />
                                    : ' - '}
                            </Form.Item>





                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                        <div className="form-left-body view-mode profile-col-2">

                            <Form.Item className="custom-select" label={t('ORGANIZATION_COUNTRY')}>
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.address?.country?.displayValue)}</p>
                            </Form.Item>
                            <Form.Item className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.address?.province?.displayValue)}</p>
                            </Form.Item>
                            <Form.Item className="custom-select" label={t('ORGANIZATION_DISTRICT')}>
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.address?.district?.displayValue)}</p>
                            </Form.Item>



                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                        <div className="form-left-body view-mode profile-col-3">

                            <Form.Item className="custom-select" label={t('ORGANIZATION_REGION')}>
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.address?.neighborhood?.displayValue)}</p>
                            </Form.Item>
                            <Form.Item label={t('ORGANIZATION_ADDRESS')}>
                                <p className="form-text">{checkTextIsEmpty(personContactDetails.address?.addressLine)}</p>
                            </Form.Item>

                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                        <div className="form-left-body view-mode profile-col-4">
                            <Form.Item label={t('PARTNER_PERSONEL_MAIL')} required >
                                <p className="form-text email">{checkTextIsEmpty(personContactDetails.personalEmail)}</p>
                            </Form.Item>
                            <Form.Item label={t('PARTNER_CELL_PHONE')}>
                                {personContactDetails.cellPhone ?
                                    <PhoneInput
                                        {...phoneInputConfig}
                                        value={personContactDetails.cellPhone}
                                        disableDropdown={true}
                                        disabled={true}
                                    />
                                    : ' - '}
                            </Form.Item>


                        </div>
                    </Col>


                </Row>


            </Form >
        )
    )
}
const mapStateToProps = (state) => {
    return {

    };
}


const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContact);
