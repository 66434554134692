import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Input, Form, Row, Col } from 'antd';


const DynamicTimeOffModal = ({ ApproveModalData, handleConfirm, form }) => {
    const { t } = useTranslation();
    return (
        <Modal visible={ApproveModalData.isVisible} className="timeoff-modal"
            onCancel={ApproveModalData.onModalCancel}
            footer={[
                <Row gutter={24}>
                    <Col span="12">
                        <Button key="back" className="ant-btn white-btn w-100 h-40" onClick={ApproveModalData.cancelBtnFunc}>
                            {ApproveModalData.cancelBtnLbl !== undefined ? ApproveModalData.cancelBtnLbl : t('PARTNER_CANCEL')}
                        </Button>
                    </Col>
                    <Col span="12">
                        <Button key="submit" className="ant-btn primary-btn w-100 h-40" onClick={() => handleConfirm()}>
                            {ApproveModalData.confirmBtnLbl}
                        </Button>
                    </Col>
                </Row>,
            ]}>
            <div >
                <div>{ApproveModalData.icon}</div>
                <div className="title">{ApproveModalData.modalTitle}</div>
                <div className="desc">{ApproveModalData.modalDesc}</div>
                {ApproveModalData?.balanceDesc && <div className="desc fw-600">{ApproveModalData.balanceDesc}</div>}
                <Form
                    form={form}
                    onValuesChange={ApproveModalData.handleValuesChange}
                >
                    {ApproveModalData.descriptionShow ?
                        <Form.Item name="description">
                            <Input.TextArea rows={3} placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')} />
                        </Form.Item> : ''
                    }

                </Form>
            </div>
        </Modal>
    );
};

export default DynamicTimeOffModal;