import React from 'react';
import {
    Button, Col, Row,
    Form, DatePicker,
    Input, InputNumber,
    Select, ConfigProvider
} from 'antd';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

//STATE
import { setAdvanceDetailOpenDraw, setAdvanceDetailTabStatus } from '../../redux/actions/person-detail-tab-action'; // edit-draw -- save-draw
import { getExpenseType } from '../../redux/actions/expense-type'; //Avans tipi  listeler

import { UilArrowLeft } from '@iconscout/react-unicons';
import { CloseOutlined } from '@ant-design/icons';


import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir

function AdvanceRequestDetails(props) {

    const { t } = useTranslation();
    const [FormData] = Form.useForm();
    const dateFormatList = ['DD/MMM/YYYY'];
    const { Option } = Select;
    const { TextArea } = Input;

    const getExpenseTypeList = () => {
        props.getExpenseType();
    }


    const closeSalary = () => {
        props.setAdvanceDetailOpenDraw(false);
        props.setAdvanceDetailTabStatus(false);

    }
    function onChange() {
        //Onchange tetiklendiğinde kaydet veya iptal butonunu göstermeyi sağlıyor.
        props.setAdvanceDetailTabStatus(true);

    }

    return (


        <div className="table-details">
            <Col span={24}>
                <Row>
                    <Col span={18}>
                        <div className="back-drawer">
                            <Button className="transparent-btn" onClick={closeSalary} icon={<UilArrowLeft />}>{t('PARTNER_RETURN_ADVANCE_REQUEST_LIST')}</Button>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="close-icon">
                            <CloseOutlined onClick={closeSalary} />
                        </div>
                    </Col>

                </Row>

                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    <Form
                        form={FormData}
                        layout="vertical"
                        requiredMark={false}
                        className="editDraw"
                    >
                        <Col span={24}>
                            <div className="pad-25" />
                            <Row>
                                <Col span={12}>
                                    <Form.Item label={t('PARTNER_ADVANCE_TYPE')} required >
                                        <div className="custom-select">
                                            {/* <CustomSelectbox selectId={1} selectName="Seyehat Avansı" optionList={props.expenseType.expenseType} onChange={() => onChange()} onFocus={() => getExpenseTypeList()} /> */}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={t('PARTNER_NEED_DATE')} required >
                                        <DatePicker locale={TR} defaultValue={moment()} format={dateFormatList} placeholder={t('PARTNER_SELECT_DATE')} />
                                    </Form.Item>
                                    <Form.Item label={t('PARTNER_AMOUNT')} required >
                                        <div className="custom-select">
                                            <Row>
                                                <Col span={17}>
                                                    <div className="custom-input-number" >
                                                        <InputNumber
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={3000}
                                                            placeholder=""
                                                            onChange={onChange}
                                                            maxLength={23} />
                                                    </div>
                                                </Col>
                                                <Col offset={1} span={6}>
                                                    {/* <CustomSelectbox selectId={1} selectName="TL" optionList={props.currencys.currencys} onChange={() => onChange()} onFocus={null} /> */}

                                                </Col>
                                            </Row>
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={t('PARTNER_MANAGER2')} required >
                                        <div className="custom-select">
                                            <Select defaultValue={7} onChange={onChange}>
                                                <Option value={7}>Alper Tunç</Option>
                                                <Option value={9}>Alptekin Yıldız</Option>
                                            </Select>
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={t('PARTNER_STATU')} required >
                                        <div className="custom-select">
                                            <Select defaultValue={1} onChange={onChange}>
                                                <Option value={1}>Ödendi</Option>
                                                <Option value={2}>Ödenmedi</Option>
                                            </Select>
                                        </div>
                                    </Form.Item>
                                    <Form.Item label={t('PARTNER_DESCRIPTION')} required >
                                        <TextArea rows={4} onChange={onChange} />
                                    </Form.Item>
                                </Col>


                            </Row>






                        </Col>
                        {
                            props.tabreducer.advanceDetailsHasChange === true ? (
                                <div className="fixed-btn">
                                    <div className="page-end">
                                        <div className="hr-end"></div>
                                    </div>

                                    <div className="form-submit">
                                        <Button onClick={closeSalary} className="white-btn w-180 h-40 f-14">{t('INVENTORY_CANCEL')}</Button>

                                        <div className="btn-padding" />
                                        <Button className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                                        <div className="btn-padding-end" />

                                    </div>




                                </div>) : ""
                        }

                    </Form >
                </ConfigProvider>
            </Col>
        </div >



    )
}
const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        expenseType: state.expenseType,
        currencys: state.currencys

    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setAdvanceDetailOpenDraw: (props) => {
            dispatch(setAdvanceDetailOpenDraw(props));
        },
        setAdvanceDetailTabStatus: (props) => {
            dispatch(setAdvanceDetailTabStatus(props));
        },
        getExpenseType: () => {
            dispatch(getExpenseType());
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceRequestDetails);


