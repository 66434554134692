import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, ConfigProvider, TreeSelect } from 'antd';



import { getTagList } from '../../../../redux/actions/tag.actions';
import { getWorkHourDepartmentInPersons } from '../../../../redux/actions/organization.actions';


import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/timeoffTypes.scss';

import { compareObjects} from '../../../../utils/commonFormFunctions'

function AddTimeoffTypeAppliedPersons(props) {
    const { t } = useTranslation();

    const [form] = Form.useForm()
    const { getWorkHourDepartmentInPersons, workHourDepartmentInPerson, workHourDepartmentInPersonLoading, getTagList, tagList, setUpdateTimeoffTypeObject, timeoffType } = props;


    const [personTreeData, setPersonTreeData] = useState([])
    const [tagListState, setTagListState] = useState([]);
    const { SHOW_CHILD } = TreeSelect;

    const [basicInfoState, setBasicInfoState] = useState({});

    useEffect(() => {
        setBasicInfoState({
            ...timeoffType
        })
        form.setFieldsValue({
            ...timeoffType
        })

    }, [form,timeoffType])

    useEffect(() => {
        setUpdateTimeoffTypeObject(basicInfoState)
    }, [basicInfoState]);

    useEffect(() => {
        const tempArr = tagList.map((data) => {
            return {
                'id': data.id,
                'displayValue': data.name
            }
        })
        setTagListState(tempArr);
    }, [tagList]);

    useEffect(() => {
        if (!workHourDepartmentInPersonLoading) {
            var list = [];
            workHourDepartmentInPerson.map((data) => {
                return list.push({
                    'title': data.departmentName,
                    'value': data.departmentId,
                    'key': data.departmentId,
                    'children': data.persons.map((datax) => {
                        return {
                            'title': datax.givenName + ' ' + datax.familyName,
                            'value': datax.id,
                            'key': datax.id,
                        }
                    })
                })
            })
            setPersonTreeData(list)
        }
    }, [workHourDepartmentInPerson])

    const turkishCharacterRegex = keyword => keyword
        .replace(/[ıİiI]/g, '[ıİiI]')
        .replace(/[şŞsS]/g, '[şŞsS]')
        .replace(/[çÇcC]/g, '[çÇcC]')
        .replace(/[ğĞgG]/g, '[ğĞgG]')
        .replace(/[öÖoO]/g, '[öÖoO]')
        .replace(/[üÜuU]/g, '[üÜuU]');
    const customFilter = (searchText, option) => ((turkishCharacterRegex(option.title)).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase()));



    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
 
        setBasicInfoState({ ...basicInfoState, ...changedObj });
        
       
    }

    useEffect(() => {
        getWorkHourDepartmentInPersons();
    }, [])

    return (
        
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <Form.Item name="tagList" className="custom-select" label={t('TIMEOFF_APPLIED_TAGS')}>
                        <DynamicSelectbox
                            isMultiple={true}
                            optionList={tagListState} onFocus={() => getTagList({
                                "searchTerm": "",
                                "pagination": {
                                    "pageNumber": 1,
                                    "pageSize": 10,
                                    "orderBy": "Name",
                                    "ascending": true
                                }
                            })}
                            orderBy={'none'}
                            placeholder={t('PARTNER_SELECT')}
                            className={!compareObjects(timeoffType?.tagList, basicInfoState?.tagList) ? 'is-changed' : ''}
                        ></DynamicSelectbox>
                    </Form.Item>

                    <Form.Item className="w-100 custom-select" name="personList" label={t('TIMEOFF_APPLIED_PERSONS')} >
                        <TreeSelect
                            treeData={personTreeData}
                            treeCheckable={true}
                            showCheckedStrategy={SHOW_CHILD}
                            placeholder={t('PARTNER_SELECT')}
                            filterTreeNode={(search, item) => customFilter(search, item)}
                            className={JSON.stringify(timeoffType.personList) != JSON.stringify(basicInfoState.personList) ? 'is-changed' : ''}
                        />
                    </Form.Item>
                </Form>
            </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        workHourDepartmentInPerson: state.organization.workHourDepartmentInPerson,
        workHourDepartmentInPersonLoading: state.organization.workHourDepartmentInPersonLoading,
        tagList: state.tag.tagList.results,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getWorkHourDepartmentInPersons: () => { dispatch(getWorkHourDepartmentInPersons()) },
        getTagList: (searchData) => { dispatch(getTagList(searchData)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTimeoffTypeAppliedPersons);