import Icon from '@ant-design/icons';

import { ReactComponent as TwitterSvg } from '../../assets/svg/twitter.svg';
import { ReactComponent as InstagramSvg } from '../../assets/svg/instagram.svg';
import { ReactComponent as FacebookSvg } from '../../assets/svg/facebook.svg';
import { ReactComponent as LinkedlnSvg } from '../../assets/svg/linkedln.svg';
import { ReactComponent as WebSvg } from '../../assets/svg/webLink.svg';
import { ReactComponent as ArrowDownSvg } from '../../assets/svg/arrow-down.svg';
import { ReactComponent as GreenCheckboxSvg } from '../../assets/svg/green-checkbox.svg';
import { ReactComponent as WaitingSvg } from '../../assets/svg/waiting.svg';
import { ReactComponent as ErrorSvg } from '../../assets/svg/error.svg';
import { ReactComponent as FileAltSvg } from '../../assets/svg/fileAlt.svg';
import { ReactComponent as WaitIconSvg } from '../../assets/svg/wait-icon.svg';
import { ReactComponent as SuccessIconSvg } from '../../assets/svg/success.svg';
import { ReactComponent as CupIconSvg } from '../../assets/svg/cup.svg';
import { ReactComponent as DownIconSvg } from '../../assets/svg/down.svg';

export const TwitterIcon = (props) => (
    <Icon component={TwitterSvg} {...props} />
);
export const InstagramIcon = (props) => (
    <Icon component={InstagramSvg} {...props} />
);
export const FacebookIcon = (props) => (
    <Icon component={FacebookSvg} {...props} />
);
export const LinkedlnIcon = (props) => (
    <Icon component={LinkedlnSvg} {...props} />
);
export const WebIcon = (props) => (
    <Icon component={WebSvg} {...props} />
);
export const ArrowDownIcon = (props) => (
    <Icon component={ArrowDownSvg} {...props} />
);
export const CheckboxIcon = (props) => (
    <Icon component={GreenCheckboxSvg} {...props} />
);
export const WaitingIcon = (props) => (
    <Icon component={WaitingSvg} {...props} />
);
export const ErrorIcon = (props) => (
    <Icon component={ErrorSvg} {...props} />
);
export const FileAltIcon = (props) => (
    <Icon component={FileAltSvg} {...props} />
);
export const WaitIcon = (props) => (
    <Icon component={WaitIconSvg} {...props} />
);
export const SuccessIcon = (props) => (
    <Icon component={SuccessIconSvg} {...props} />
);
export const CupIcon = (props) => (
    <Icon component={CupIconSvg} {...props} />
);
export const DownIcon = (props) => (
    <Icon component={DownIconSvg} {...props} />
);