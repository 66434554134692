import React from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Input, ConfigProvider, Radio, Checkbox } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';


import '../../../../styles/timeoffTypes.scss';

function AddTimeoffTypeDetailInfo(props) {
    const { t } = useTranslation();

    const [form] = Form.useForm()
    const { setCreateTimeoffTypeObject, createTimeoffTypeObject } = props;


    const allowOnlyNumericKeyPress = (e) => {
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericPaste = (e) => {
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };


    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if(key === 'isExceedable'){
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'isExceedable': changedObj["isExceedable"], 'maxExceedBalance': null, 'isExceedableInform': false });
            form.setFieldsValue({
                "maxExceedBalance": null,
                'isExceedableInform': false
            })
        }
        else if(key === 'isGenderType'){
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'isGenderType': changedObj["isGenderType"], 'genderTypeSetIdx': null });
            form.setFieldsValue({
                "genderTypeSetIdx": null
            })
        }
        else if(key === 'isAppearContactInfo'){
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'isAppearContactInfo': changedObj["isAppearContactInfo"], 'isRequiredContactInfo': null });
            form.setFieldsValue({
                "isRequiredContactInfo": null
            })
        }
        else {
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, ...changedObj });
        }
    }


    return (
        
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <Form.Item name="isTransferable" label={t('TIMEOFF_TRANSFER')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_TRANSFER_DESC')}</Checkbox>
                    </Form.Item>
                    <Form.Item name="isExceedable" label={t('TIMEOFF_TIMEOUT')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_TIMEOUT_DESC')}</Checkbox>
                    </Form.Item>
                    { createTimeoffTypeObject?.isExceedable == true &&
                    <Form.Item className="margin-b-12 delete-draw-label custom-input-group" label={t('TIMEOFF_TIMEOUT_MAX')} name="maxExceedBalance">
                        <Input style={{maxWidth: '245px'}} addonAfter={t('PARTNER_DAY')} onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} placeholder={t("TIMEOFF_LIMITED_PLACEHOLDER")} /> 
                    </Form.Item>
                    }
                    <Form.Item name="isExceedableInform" label={t('TIMEOFF_TIMEOUT_INFO')} valuePropName="checked">
                        <Checkbox disabled={!createTimeoffTypeObject?.isExceedable && 'disabled' }>{t('TIMEOFF_TIMEOUT_INFO_DESC')}</Checkbox>
                    </Form.Item>
                    <Form.Item name="isGenderType" className="custom-label-tooltip" label={t('TIMEOFF_ACCORDING_GENDER')} valuePropName="checked" tooltip={{
                    title: t('TIMEOFF_ACCORDING_GENDER_TOOLTIP'),
                    icon: <InfoCircleOutlined />,
                    placement:'top'
                    }}>
                        <Checkbox >{t('TIMEOFF_ACCORDING_GENDER_DESC')}</Checkbox>
                    </Form.Item>
                    { createTimeoffTypeObject?.isGenderType == true &&
                    <Form.Item className="margin-b-12" name="genderTypeSetIdx" >
                        <Radio.Group options={ // lookup 2
                            [
                                { label: `${t('PARTNER_WOMAN')}`, value: 2 }, //KAdın 2
                                { label: `${t('PARTNER_MAN')}`, value: 1 }, // Erkek 1
                            ]
                        } />
                    </Form.Item>
                    }
                    <Form.Item name="isRenewalableBalance" label={t('TIMEOFF_RENEWAL')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_RENEWAL_DESC')}</Checkbox>
                    </Form.Item>
                    <Form.Item name="isRequiredDocument" label={t('TIMEOFF_REQUIRED_DOC_UPLOAD')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_REQUIRED_DOC_UPLOAD_DESC')}</Checkbox>
                    </Form.Item>
                    <Form.Item name="isAppearContactInfo" label={t('TIMEOFF_CONTACT_INFO')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_CONTACT_INFO_DESC')}</Checkbox>
                    </Form.Item>
                    { createTimeoffTypeObject?.isAppearContactInfo == true &&
                    <Form.Item className="margin-b-12" name="isRequiredContactInfo" >
                        <Radio.Group options={ // lookup yok
                            [
                                { label: `${t('TIMEOFF_CONTACT_INFO_OPT_REQUIRED')}`, value: 1 }, //zorunlu 1
                                { label: `${t('TIMEOFF_CONTACT_INFO_OPT_NOT_REQUIRED')}`, value: 0 }, // olmasın 0 
                            ]
                        } />
                    </Form.Item>
                    }
                    <Form.Item name="isRequiredDescription" label={t('TIMEOFF_REQUIRED_DESCRIPTION')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_REQUIRED_DESCRIPTION_DESC')}</Checkbox>
                    </Form.Item>
                </Form>
            </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTimeoffTypeDetailInfo);