import React from 'react';
import { Modal, Image, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OnboardingMapModal = ({ currentTaskState, setCurrentTaskState, completedTasks, nextTaskID, nextTaskPackage }) => {
    
    const { t } = useTranslation();

    const taskStates = [14, 23, 34, 43, 53, 64, 74, 84, 92, 103, 112, 114];

    const isTaskDone = (taskNumber) => completedTasks.includes(taskNumber);

    const location = useLocation();
    
    return (
        <>
            <Modal 
                className="onboarding-map-modal" 
                key={`task-map-${currentTaskState}`} 
                visible={taskStates.includes(currentTaskState) && location.pathname !=='/prices' } 
                footer={null} 
                onCancel={() => setCurrentTaskState(null)}
            >
                <div className="onboarding-map-modal-wrapper">
                    <div className="onboarding-mission-start">
                        <Image className="img" src="../images/rocket.svg" preview={false} />
                    </div>
                    <div className={`onboarding-mission-1 ${isTaskDone(1) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(1) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-2 ${isTaskDone(2) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(2) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-3 ${isTaskDone(3) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(3) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className="onboarding-mission-first">
                        <span className="onboarding-done-number">2</span>
                    </div>
                    <div className={`onboarding-mission-4 ${isTaskDone(4) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(4) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-5 ${isTaskDone(5) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(5) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-6 ${isTaskDone(6) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(6) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className="onboarding-mission-second">
                        <span className="onboarding-done-number">3</span>
                    </div>
                    <div className={`onboarding-mission-7 ${isTaskDone(7) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(7) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-8 ${isTaskDone(8) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(8) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-9 ${isTaskDone(9) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(9) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className="onboarding-mission-third">
                        <span className="onboarding-done-number">4</span>
                    </div>
                    <div className={`onboarding-mission-10 ${isTaskDone(10) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(10) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className={`onboarding-mission-11 ${isTaskDone(11) ? 'onboard-check' : ''}`}>
                        <Image className="img" src={isTaskDone(11) ? "./images/onboard-check.png" : "./images/hourglass2.png"} preview={false} />
                    </div>
                    <div className="onboarding-mission-end">
                        {completedTasks.length == 13 
                            ? <Image className="img" src="./images/star-fill.png" preview={false} />
                            : <Image className="img" src="./images/star-gray.png" preview={false} />
                        }
                    </div>

                    {completedTasks.length === 3 && 
                        <div className="onboarding-modal-bubble">
                        <h2 className='onboard-popover-title green'>{t('ONBOARDING_CONGRATS_FIRST_TASK')}</h2>
                        <div className="f-14 primary-dark-font-color">{t('ONBOARDING_MORE_TASKS')}</div>
                        </div>
                    }
                    {(completedTasks.length !== 13 && completedTasks.length !== 3) && 
                        <div className="onboarding-modal-bubble">
                        <h2 className='onboard-popover-title green'>{t('ONBOARDING_TASK_COMPLETED_2')}</h2>
                        <div className="f-14 primary-dark-font-color">{t('ONBOARDING_MORE_TASKS')}</div>
                        </div>
                    }
                    {completedTasks.length === 13 && 
                        <>
                            <div className="onboarding-modal-bubble">
                            <h2 className='onboard-popover-title green'>{t('ONBOARDING_CONGRATS_ALL_TASKS')}</h2>
                            </div>
                            <div className="onboarding-next-mission">
                            <Link to="/homepage">
                            <Button onClick={() => setCurrentTaskState(null)} className={`ml-auto f-14 m-w-75 white-btn`}>
                                {t('ONBOARDING_CLOSE')} 
                            </Button>
                            </Link>
                            </div>
                        </>
                    }
                    {(completedTasks.length !== 13 && location.pathname !=='/prices') && 
                    <div className="onboarding-next-mission">
                        <Button onClick={() => nextTaskID*10 == 10 ? setCurrentTaskState(11) : setCurrentTaskState(nextTaskID*10)} className={`ml-auto f-14 m-w-75 white-btn`}>
                            {t('ONBOARDING_CONTINUE_NEXT_TASK')} 
                        </Button> 
                        </div>
                    }
                </div>
            </Modal>
        </>
    );
};

export default OnboardingMapModal;
