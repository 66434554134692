import { PersonDetailActionType } from "../actions/personDetail.actions";

const personDetailState = {
  "personBasicInfo":{
    "id": 0,
    "photo": "",
    "givenName": "" ,
    "familyName": "",
    "jobTitle": {
        "id": "",
        "name": ""
    },
    "officePhone": "",
    "workPhoneExt": "",
    "identityNumber": "",
    "startDate": "",
    "corporateEmail": "",
    "socialMedia":[],
    "permission":{
        "page":{
            "name":"",
            "component" : [] 
        }
    }
  },
  "personBasicInfoIsFetching":false,
  "personInformation":{
    "id":null,
    "birthday": "01.01.2021",
    "gender":
    {
      "id": null,
      "name": ""
    },
    "nationalityId": "",
    "nationality":
    {
      "id": null,
      "name": "",
      "shortName":""
    },
    "educationDegreeSetid":{
      "id": null,
      "name": ""
    },
    "numberofChildren":0,
    "coupleWork":
    {
      "id": null,
      "name": ""
    },
       "bloodGroup":{
        "id": null,
        "name": ""
    },
    "disabiltyDegree":
    {
      "id": null,
      "name": ""
    },
    "marital":
    {
        "id": null,
        "name": ""
    },
    "language": [
      
        
    ],
    "isStudent":false 

},
  "personInformationIsFetching":false,
  "personJobHistoryIsFetching":false,

  "personJobHistory":{
    
    "message": "",
    "workType": {
        "id": null,
        "name": ""
    },
    
    "workState": {
        "id": null,
        "name": ""
    },
    "workStatus": {
        "id": null,
        "name": ""
    },
    "remoteWork": {
      "id": null,
      "name": ""
    },
    "manager":{
      "id":null,
      "name":""
  },
  "organization": {
    "id":null,
    "name":""
  },
  "parentDepartment": {
  "id":null,
  "name":""
  },
    "person":{
        "id": null,
        "photo": "",
        "startDate": "",
        "hiringDate": "",
        "corporateEmail": "",
        "jobTitle":"",
        "department":  "",
        "officePhone": "",
        "leaveDate": "01.01.2020",
        "leaveReason": "",
        "isActive":true,
     }
  },
  "personDebitDetailsIsFetching":false,
  "personDebitDetails":{
    
      "id": null,
      "inventory": {
          "id": null,
          "name": ""
      },
      "inventoryType" : {
          "id":0,
          "name":""
      },
      "createdBy": {
          "id": null,
          "givenName": "",
          "familyName": ""
      },
      
      "person": {
          "id": null,
          "givenName": "",
          "familyName": ""
      },
      "personReceivingReturn": {
          "id": null,
          "givenName": "",
          "familyName": "",
          "jobName": ""
      },
      "assignment_date": "",
      "returnDate": "",
      "serialNumber":"",
      "organization":{
          "id":null,
          "name":""
      },
      "document": [
          {
              "id": null,
              "documentName": "",
              "url": ""
          }
      ],
      "itemCondition" : {
          "id":null,
          "name":""
      },
      "assignemtDescription": "",
      "needMaintanence": true,
      "returnDescription": "",
      "accessory":[
          {
              "id":null,
              "serialNumber" : "",
              "name":""
          },
         
      ]
  },
  "personContactIsFetching":false,
  "personContactDetails":{
    
      "id":0,
      "personalEmail" : "",
      "cellPhoneNumber" : "",
      "workPhoneNumberExt" : "",
      "facebookName" : "",
      "instagramName" : "",
      "linkedinUrl" : "",
      "twitterLink" : "",
      "personalPageUrl" : "",
  
  
      "adressProvince" :{
          "id":null,
          "name":""
      },
      "addressDistirct" : {
          "id" : null,
          "name" : ""
      },
      "addressRegion" : {
          "id" : null,
          "name" : ""
      },
      "streetAddress": ""
  },
  "personSocialIsFetching":false,
   "socialmedia": {
        "twitter_link":"",
        "facebook_name":"",
        "instagram_name":"",
        "linkedin_url":"",
        "personal_page_url":""
    }
};

const personDetailReducer = (state = personDetailState , action) => {
  switch (action.type) {
    case PersonDetailActionType.GET_PERSON_BASIC_INFO_REQUEST:
      const personListRequestState = {
        ...state,
        personBasicInfoIsFetching: true
      };
      return personListRequestState;

    case PersonDetailActionType.GET_PERSON_BASIC_INFO_SUCCESS:
      const basicInfoSuccessState = {
        ...state,
        personBasicInfo: action.payload,
        personBasicInfoIsFetching: false
      };
      return basicInfoSuccessState;

      case PersonDetailActionType.GET_PERSON_JOB_HISTORY_REQUEST:
        const jobHistoryRequestState = {
          ...state,
          personJobHistoryIsFetching: true
        };
        return jobHistoryRequestState;
  
    case PersonDetailActionType.GET_PERSON_JOB_HISTORY_SUCCESS:
      const jobHistorySuccessState = {
        ...state,
        personJobHistory: action.payload,
        personJobHistoryIsFetching: false
      };
      return jobHistorySuccessState;

      case PersonDetailActionType.GET_PERSON_RESET:
      return personDetailState;


    case PersonDetailActionType.GET_PERSON_PERSON_DETAILS_REQUEST:
      const personDetailsRequestState = {
        ...state,
        personInformationIsFetching:true
      };
      return personDetailsRequestState;

      case PersonDetailActionType.GET_PERSON_PERSON_DETAILS_SUCCESS:

      const personDetailsSuccessState = {
        ...state,
        personInformation: action.payload,
        personInformationIsFetching:false
      };
      return personDetailsSuccessState;


      case PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_REQUEST:
        const personDebitDetailsRequestState = {
          ...state,
          personDebitDetailsIsFetching: true
        };
        return personDebitDetailsRequestState;
  
      
      case PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_SUCCESS:

        const personDebitDetailsState = {
          ...state,
          personDebitDetails: action.payload,
          personDebitDetailsIsFetching: false
        };
        return personDebitDetailsState;

        case PersonDetailActionType.GET_PERSON_CONTACT_REQUEST:
          const personDebitContactRequestState = {
            ...state,
            personContactIsFetching: true
          };
          return personDebitContactRequestState;
    

        case PersonDetailActionType.GET_PERSON_CONTACT_SUCCESS:

          const personContactState = {
            ...state,
            personContactDetails: action.payload,
            personContactIsFetching: false
          };
          return personContactState;

          case PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_REQUEST:
            const personDebitSocialRequestState = {
              ...state,
              personSocialIsFetching: true
            };
            return personDebitSocialRequestState;
          
      
          case PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                socialmedia: action.payload,
                personSocialIsFetching:false
            }
           
    default:
      return state;
  }
};

export default personDetailReducer;
