import React, { useState } from "react";
import { Modal, Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { randomNumberInRange } from '../../utils/commonFormFunctions';

//confirmData == id
//isMultipleData post işleminde 2.bir data alıyorsa kullanilıyor
const DynamicEducationInlineModal = ({ dataInline, keyboard = true, modalVisible = null, modalWidth = null, setStateData = null, isForm = null, handleCancel = null, maskClosable = true }) => {
  const { t } = useTranslation();
  const [stepName, setStepName] = useState('')

  const setData = () => {
    dataInline(stepName)
    setStepName('')
    handleCancel()
  }
  const changeStepName = (e) => {
    setStepName(e.target.value)
  }
  return (
    <Modal
      key={() => randomNumberInRange(1, 5000000)}
      visible={modalVisible}
      className="delete-confirm-modal"
      onCancel={() => isForm ? handleCancel() : setStateData({ "isVisible": false })}
      maskClosable={maskClosable ? true : false}
      width={modalWidth ? modalWidth : 520}
      keyboard={keyboard}
      closable={false}
      centered
      footer={[]}>
      <div>
        <Form className="mt-3 mb-3">
          <label htmlFor="stepName"><strong>{t('SECTION_NAME')}</strong></label>
          <Input onChange={changeStepName} value={stepName} name="stepName" id="stepName" placeholder={t('SECTION_NAME_ENTER_TITLE')} />
        </Form>
        <div className="d-flex justify-space-between">
          <Button className="ant-btn white-btn mr-3 w-25 h-20" onClick={handleCancel}>
            {t('PARTNER_CLOSE')}
          </Button>
          <Button onClick={setData} className="ant-btn primary-btn h-20">
            {t('PARTNER_SAVE')}
          </Button>
        </div>

      </div>
    </Modal>
  )
}
export default DynamicEducationInlineModal   