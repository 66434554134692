import { Roles } from "../actions/roles.actions";

const initialState = {
    roles:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
       
        case Roles.GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload
            }
            
        default: return state
    }

}