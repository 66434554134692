import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, Image } from "antd";
import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";
import { UilTimes, UilPaperclip, UilUsersAlt, UilClock, UilAngleRight, UilAngleLeft, UilFileAlt, UilUser, UilFileMedical,
         UilCalender, UilTag, UilShoppingBag, UilCheckCircle} from '@iconscout/react-unicons';

import { useTranslation } from 'react-i18next';

const PopoverContent = (props) => {

  const { t } = useTranslation();

  let content;
  const [activeListIndex, setActiveListIndex] = useState(0); // Başlangıçta ilk task listesi aktif

  useEffect(() =>{
    if(props.isVisible == true){
        if(props.nextTaskPackage == 1){ setActiveListIndex(0) }
        if(props.nextTaskPackage == 2){ setActiveListIndex(1) }
        if(props.nextTaskPackage == 3){ setActiveListIndex(2) }
        if(props.nextTaskPackage == 4){ setActiveListIndex(3) }
        if(props.nextTaskPackage == 'done'){ setActiveListIndex(3) }
    }
  }, [props.isVisible]);

  const taskLists = [
    {
      id : 1,
      tasks : [
        {
          id : 1,
          taskID : 1,
          icon: <UilPaperclip height={24} width={24} />,
          isCompleted : true,
          text: t('DEPARTMENT_NEW2'),
          onClick: () => (props?.userTaskInfo?.completedTasks?.includes(1) || props?.userTaskInfo?.completedTasks?.length > 2) ? changeTaskState(11) : changeTaskState(10)
        },
        {
          id : 2,
          taskID : 1,
          icon: <UilUsersAlt height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_CREATE_EMPLOYEE'),
          onClick: () => changeTaskState(20)
        },
        {
          id : 3,
          taskID : 1,
          icon: <UilUser height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_EDIT_EMPLOYEE_PERMISSIONS'),
          onClick: () => changeTaskState(30)
        }
      ]
    },
    {
      id : 2,
      tasks : [
        {
          id : 4,
          taskID : 2,
          icon: <UilClock height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_EDIT_WORKING_HOURS'),
          onClick: () => changeTaskState(40)
        },
        {
          id : 5,
          taskID : 2,
          icon: <UilFileAlt height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_EDIT_APPROVAL_PROCESSES'),
          onClick: () => changeTaskState(50)
        },
        {
          id : 6,
          taskID : 2,
          icon: <UilUsersAlt height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_SET_ANNUAL_LEAVE'),
          onClick: () => changeTaskState(60)
        }
      ]
    },
    {
      id : 3,
      tasks : [
        {
          id : 7,
          taskID : 3,
          icon: <UilFileAlt height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_CREATE_NEW_INVENTORY'),
          onClick: () => changeTaskState(70)
        },
        {
          id : 8,
          taskID : 3,
          icon: <UilUser height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_ASSIGN_INVENTORY_TO_EMPLOYEE'),
          onClick: () => changeTaskState(80)
        },
        {
          id : 9,
          taskID : 3,
          icon: <UilFileMedical height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_EXPORT_INVENTORY_LIST'),
          onClick: () => changeTaskState(90)
        }
      ]
    },
    {
      id : 4,
      tasks : [
       /* {
          id: 10,
          taskID : 4,
          icon: <UilCalender height={24} width={24} />,
          isCompleted : false,
          text: 'Yeni izin tipi oluştur',
          onClick: () => changeTaskState(100)
        },
        */
        {
          id: 10,
          taskID : 4,
          icon: <UilTag height={24} width={24} />,
          isCompleted : false,
          text: t('ONBOARDING_CREATE_EXPENSE_REQUEST'),
          onClick: () => changeTaskState(100)
        },
        {
          id: 11,
          taskID : 4,
          icon: <UilShoppingBag height={24} width={24} />,
          isCompleted : false,
          text: props.navSubsciption ? t('ONBOARDING_VIEW_SUBSCRIPTIONS') : t('ONBOARDING_VIEW_PACKAGES'),
          onClick: () => props.navSubsciption ? changeTaskState(113) : changeTaskState(110),
        }
      ]
    }
  ];

  const goToPreviousList = () => {if (activeListIndex > 0) { setActiveListIndex(activeListIndex - 1); }};
  const goToNextList = () => { if (activeListIndex < taskLists.length - 1) { setActiveListIndex(activeListIndex + 1); } };

  const progressStyle = {'--progress-percentage': `${props.percentage}%`};


  const changeTaskState = (state,showTaskList = false) => { 
    if (props.OnchangeTaskState) { props.onClose(); props.OnchangeTaskState(state, showTaskList); 
  }};


  if (props.type === 999) {
      content = <div style={{ width: '437px', maxWidth: '437px'}}>
                     <div className="onboarding-welcome-modal">
                        <div className="onboarding-welcome-main">
                          
                          <Image className="img" src="../images/rocket.svg" preview={false} />
                          <div className="rocket-box-wrapper">
                            <div className="rocket-box rocket-box-arrow secondary-dark-text">
                                {t("ONBOARDING_GREETING_MESSAGE")}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex w-100">
                            <Button
                                className="primary-btn w-100 h-32 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_CONFIRMATION")}
                            </Button>
                        </div>
                  </div>
                </div>;
  } else if (props.type === 1) {
    content = <div style={{ width: '437px', maxWidth: '437px'}}>
                  <h2 className='onboard-popover-title'>{t("ONBOARDING_FIRST_TASK_WAITING")}</h2>
                  <div className="mt-4">{t("ONBOARDING_MORE_INFO")}</div>
                  <Button onClick={() => changeTaskState(null)} className="white-btn w-100 h-32 f-14 mt-4">
                    {t("ONBOARDING_CONFIRMATION")}
                  </Button>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 1000) {
    content = <div className="" style={{ width: '400px'}}>
                  <h2 className='onboard-popover-title'>{t("ONBOARDING_DEPARTMENTS")}</h2>
                  <div className="onboarding-welcome-main flex-reverse">
                          
                          <Image className="img rocket-box-no-arrow-img" src="../images/rocket.svg" preview={false} />
                          <div className="rocket-box-no-arrow secondary-dark-text">
                             {t("ONBOARDING_DEPARTMENT_PAGE_INFO")}
                          </div>
                  </div>
                  <div className="d-flex w-100">
                      <Button
                                className="white-btn w-178 h-32 f-14"
                                onClick={() => changeTaskState(1)}
                            >
                                {t("ONBOARDING_SKIP")}
                      </Button>
                      <Button
                          className="primary-btn w-100 h-32 f-14 ml-4"
                          onClick={() => changeTaskState(1001)}
                      >
                          {t("ONBOARDING_CONTINUE")}
                      </Button>
                  </div>
              </div>;
  } else if (props.type === 1001) {
    content = <div className="" style={{ width: '400px'}}>
                  <h2 className='onboard-popover-title'>{t("ONBOARDING_ORGANIZATION_INFO")}</h2>
                  <div className="onboarding-welcome-main flex-reverse">
                          
                          <Image className="img rocket-box-no-arrow-img" src="../images/rocket.svg" preview={false} />
                          <div className="rocket-box-no-arrow secondary-dark-text">
                            {t("ONBOARDING_ORGANIZATION_PAGE_INFO")}
                          </div>
                  </div>
                  <div className="d-flex w-100">
                      <Button
                                className="white-btn w-178 h-32 f-14"
                                onClick={() => changeTaskState(1)}
                            >
                                {t("ONBOARDING_SKIP")}
                      </Button>
                      <Button
                          className="primary-btn w-100 h-32 f-14 ml-4"
                          onClick={() => changeTaskState(1002)}
                      >
                          {t("ONBOARDING_CONTINUE")}
                      </Button>
                  </div>
              </div>;
  } else if (props.type === 1002) {
    content = <div className="" style={{ width: '400px'}}>
                  <h2 className='onboard-popover-title'>{t("ONBOARDING_USER_INFO")}</h2>
                  <div className="onboarding-welcome-main flex-reverse">
                          
                          <Image className="img rocket-box-no-arrow-img" src="../images/rocket.svg" preview={false} />
                          <div className="rocket-box-no-arrow secondary-dark-text">
                             {t("ONBOARDING_EMPLOYEE_PAGE_INFO")}
                          </div>
                  </div>
                  <div className="d-flex w-100">
                      <Button
                                className="white-btn w-178 h-32 f-14"
                                onClick={() => changeTaskState(1)}
                            >
                                {t("ONBOARDING_SKIP")}
                      </Button>
                      <Button
                          className="primary-btn w-100 h-32 f-14 ml-4"
                          onClick={() => changeTaskState(1003)}
                      >
                          {t("ONBOARDING_CONTINUE")}
                      </Button>
                  </div>
              </div>;
  } else if (props.type === 1003) {
    content = <div style={{ width: '437px', maxWidth: '437px'}}>
                  <h2 className='onboard-popover-title'>{t("ONBOARDING_FIRST_TASK_READY")}</h2>
                  <div className="mt-3">{t("ONBOARDING_MORE_INFO_START")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(1)}
                            >
                                {t("ONBOARDING_CLOSE")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(10)}
                      >
                              {t("ONBOARDING_START")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 1004 || props.type === 1005 || props.type === 1007) {
    content = <div style={{ width: '363px', maxWidth: '363px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_TASK_COMPLETED")}</h2>
                  <div className="mt-3 d-flex">
                    <div>{t("ONBOARDING_HELP_PROGRESS")}</div>
                    <Image className="img rocket-img" src="../images/rocket.svg" preview={false} />
                  </div>
                  
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-100 f-14"
                                onClick={() => changeTaskState(null, true)}
                            >
                                {t("ONBOARDING_VIEW_OTHER_TASKS")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null, true)}></UilTimes>
              </div>;
  } else if (props.type === 11) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_DEPARTMENTS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_DEPARTMENT_PAGE_INFO_2")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(12)}
                      >
                              {t("ONBOARDING_CREATE_DEPARTMENT")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 12) {
    content = <div style={{ width: '334px', maxWidth: '334px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_DEPARTMENTS")}</h2>
                    <div className="mt-3 f-14 primary-dark-font-color">
                            {t("ONBOARDING_NEW_DEPARTMENT_MODAL_INFO")}
                    </div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(13)}
                      >
                              {t("ONBOARDING_CREATE_DEPARTMENT")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 20) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_CREATE_EMPLOYEE")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_EMPLOYEE_PAGE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(21)}
                      >
                              {t("ONBOARDING_CREATE_EMPLOYEE")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 21) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_NEW_EMPLOYEE_CREATE")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_NEW_EMPLOYEE_MODAL_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(22)}
                      >
                              {t("ONBOARDING_CREATE_EMPLOYEE")} 
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 30) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_EMPLOYEE_PERMISSIONS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_EDIT_EMPLOYEE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(31)}
                      >
                              {t("ONBOARDING_GO_TO_EMPLOYEES_PAGE")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 31) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_EMPLOYEE_PERMISSIONS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_VIEW_EMPLOYEE_DETAILS")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(32)}
                      >
                              {t("ONBOARDING_EDIT_PERMISSIONS")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 32) {
      content = <div style={{ width: '328px', maxWidth: '328px'}}>
                    <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_EMPLOYEE_PERMISSIONS")} </h2>
                    <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_SELECT_EMPLOYEE_ROLE")}</div>
                    <div className="d-flex w-100 mt-4">
                        <Button
                            className="primary-btn w-100 f-14"
                            onClick={() => changeTaskState(33)}
                        >
                                {t("ONBOARDING_CONFIRMATION_2")}
                        </Button>
                    </div>
                    <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(33)}></UilTimes>
                </div>;
  } else if (props.type === 40) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_WORKING_HOURS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_WORKING_HOURS_PAGE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(41)}
                      >
                              {t("ONBOARDING_GO_TO_WORKING_HOURS")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 41) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_WORKING_HOURS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_WORKING_HOURS_MODAL_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(42)}
                      >
                              {t("ONBOARDING_EDIT_WORKING_HOURS")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 50) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_APPROVAL_PROCESSES")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_APPROVAL_PROCESSES_PAGE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(51)}
                      >
                              {t("ONBOARDING_EDIT_APPROVAL_PROCESSES")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 51) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EDIT_GENERAL_APPROVAL_PROCESS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_VIEW_DETAILS_BUTTON_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(52)}
                      >
                              {t("ONBOARDING_VIEW_DETAILS")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 60) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_SET_ENTRY_PERMISSION")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_SET_ENTRY_PERMISSION_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(61)}
                      >
                              {t("ONBOARDING_GO_TO_EMPLOYEES_PAGE")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 61) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_SET_ENTRY_PERMISSION")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_VIEW_EMPLOYEE_DETAILS")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(62)}
                      >
                              {t("ONBOARDING_SET_ENTRY_PERMISSION")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 62) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_SET_ENTRY_PERMISSION")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_SET_ENTRY_PERMISSION_FROM_TAB")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                          className="primary-btn w-100 f-14"
                          onClick={() => changeTaskState(63)}
                      >
                              {t("ONBOARDING_CONFIRMATION_2")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(63)}></UilTimes>
              </div>;
  } else if (props.type === 70) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_CREATE_NEW_INVENTORY")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_INVENTORY_PAGE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(71)}
                      >
                              {t("ONBOARDING_GO_TO_INVENTORY")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 71) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_CREATE_NEW_INVENTORY")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_CREATE_INVENTORY_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(72)}
                      >
                              {t("ONBOARDING_CREATE_INVENTORY")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 80) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_ASSIGN_INVENTORY_TO_EMPLOYEE")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_ADD_ASSIGNMENT_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(81)}
                      >
                              {t("ONBOARDING_GO_TO_EMPLOYEES_PAGE")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 81) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_ASSIGN_INVENTORY_TO_EMPLOYEE")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_ASSIGNMENT_TAB_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(82)}
                      >
                              {t("ONBOARDING_GO_TO_EMPLOYEES_PAGE")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 82) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_ASSIGN_INVENTORY_TO_EMPLOYEE")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_CREATE_ASSIGNMENT_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                          className="primary-btn w-100 f-14"
                          onClick={() => changeTaskState(83)}
                      >
                              {t("ONBOARDING_CONFIRMATION_2")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(83)}></UilTimes>
              </div>;
  } else if (props.type === 90) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EXPORT_INVENTORY_LIST")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_EXPORT_ASSIGNMENTS_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(91)}
                      >
                              {t("ONBOARDING_GO_TO_INVENTORY")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 91) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_EXPORT_INVENTORY_LIST")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_EXPORT_INVENTORY_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                          className="primary-btn w-100 f-14"
                          onClick={() => changeTaskState(92)}
                      >
                              {t("ONBOARDING_CONFIRMATION_2")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(92)}></UilTimes>
              </div>;
  } else if (props.type === 100) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_CREATE_EXPENSE_REQUEST")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">
                    {t('ONBOARDING_EXPENSE_PAGE_TEXT_1')}
                    <span>{t('ONBOARDING_EXPENSE')}</span>
                    {t('ONBOARDING_EXPENSE_PAGE_TEXT_2')}
                    <span>{t('ONBOARDING_ALL_EXPENSE_REQUESTS')}</span>
                    {t('ONBOARDING_EXPENSE_PAGE_TEXT_3')}
                </div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(101)}
                      >
                              {t("ONBOARDING_GO_TO_EXPENSES")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 101) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_CREATE_NEW_EXPENSE")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_CREATE_EXPENSE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(102)}
                      >
                              {t("ONBOARDING_ADD_NEW_EXPENSE")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 110) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_VIEW_PACKAGES")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_PURCHASE_PACKAGE_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(111)}
                      >
                              {t("ONBOARDING_BROWSE_PACKAGES")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === 113) {
    content = <div style={{ width: '328px', maxWidth: '328px'}}>
                  <h2 className='onboard-popover-title green'>{t("ONBOARDING_VIEW_SUBSCRIPTIONS")}</h2>
                  <div className="mt-3 f-14 primary-dark-font-color">{t("ONBOARDING_VIEW_SUBSCRIPTIONS_INFO")}</div>
                  <div className="d-flex w-100 mt-4">
                      <Button
                                className="white-btn w-178 f-14"
                                onClick={() => changeTaskState(null)}
                            >
                                {t("ONBOARDING_LATER")}
                      </Button>
                      <Button
                          className="primary-btn w-100 f-14 ml-4"
                          onClick={() => changeTaskState(114)}
                      >
                              {t("ONBOARDING_BROWSE_PACKAGES")}
                      </Button>
                  </div>
                  <UilTimes className="onboard-popover-close left" onClick={() => changeTaskState(null)}></UilTimes>
              </div>;
  } else if (props.type === null) {
    content = <div style={{ width: '400px', maxWidth: '400px', zIndex:'9'}}>
    <h2 className='onboard-popover-title px-4 py-4'>{t("ONBOARDING_PACKAGE")}  {taskLists[activeListIndex].id}</h2>
    <div className="d-flex align-items-center cursor-pointer px-4 pb-4">
            <div className="progress-border progress-border-popover z-10 mx-0 my-0" style={progressStyle}>
                <div className="onboard-popover-modal-percentage">
                    {props.percentage}%
                </div>
            </div>
            <div className="d-flex flex-column logo-text lh-normal f-16">
                    {props.nextTaskPackage == 1 && t('ONBOARDING_TASK_STATUS_NOT_COMPLETED_1')}
                    {props.nextTaskPackage == 2 && t('ONBOARDING_TASK_STATUS_NOT_COMPLETED_2')}
                    {props.nextTaskPackage == 3 && t('ONBOARDING_TASK_STATUS_NOT_COMPLETED_3')}
                    {props.nextTaskPackage == 4 && t('ONBOARDING_TASK_STATUS_NOT_COMPLETED_4')}
                    {props.nextTaskPackage == 'done' && t('ONBOARDING_TASK_STATUS_COMPLETED')}
                {/* props.nextTaskID +'-'+ props.nextTaskPackage */} 
            </div>
    </div>
    <div className="onboard-popover-task-wrapper">
        {taskLists[activeListIndex].tasks.map((task, index) => (
          <div key={index} onClick={task.onClick} className={(task.id == props.nextTaskID) ? 'onboard-popover-task-single active' : 'onboard-popover-task-single' }>
            <div className="onboard-task-icon">{task.icon}</div>
            <div>{task.text}</div>
            {props?.userTaskInfo?.completedTasks?.includes(task.id) ? (
                // Görev tamamlandıysa
                <div className="ml-auto d-flex">
                     <UilCheckCircle color="#47AC34" size="24"  />
                    <div className="ml-1 secondary-dark-font">{t("ONBOARDING_COMPLETED")} </div>
                </div>
            ) : (
                // Görev tamamlanmadıysa
                <Button
                    onClick={task.onClick}
                    className={`ml-auto f-14 m-w-75 ${
                        (task.id == props.nextTaskID) ? 'primary-btn' : 'white-btn'
                    }`}
                >
                    {t("ONBOARDING_START")} 
                </Button>
            )}
         
          </div>
        ))}
      <div className="onboard-next-back-section">
        {activeListIndex !== 0 ? 
          <div onClick={goToPreviousList} className="onboard-next-back">
            <UilAngleLeft />
            <div>{t("ONBOARDING_PREVIOUS")} </div>
          </div> : <div></div>
        }
        {activeListIndex !== 3 ? 
        <div onClick={goToNextList} className="onboard-next-back">
          <div>{t("ONBOARDING_NEXT")} </div>
          <UilAngleRight />
        </div> : <div></div>
        }
      </div>
    </div>
  
    <UilTimes className="onboard-popover-close" onClick={() => changeTaskState(null)}></UilTimes>
</div>;
  } 

  return (
    <div className="popover-content">
      {content}
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
      userTaskInfo: state.onboarding.userTaskInfo,
      currentTaskState: state.onboarding.currentTaskState,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {

      setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
      setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(PopoverContent);
