import { Layout, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import '../../styles/comingsoon.scss'


const { Content } = Layout;


function Comingsoon(props) {

    const { size } = props

    const { t } = useTranslation();

    const getClassNames = (classList) => {
        // return list + ' '+ size === 'sm' ? size : ''
        let sizeClass = size === 'sm' ? size : ''
        return classList + ' ' + sizeClass
    }

    return (

        <Layout>

            <Row align="center">
                <Col className="comingsoon-wrapper">
                    <div>
                        <img src={process.env.REACT_APP_URL + "images/comingsoon.png"} className={getClassNames()} />
                    </div>
                    <div className={getClassNames('comingsoon-header-text')}>
                        {t('COMINGSOON_HEADER_TEXT')}
                    </div>
                    <div className={getClassNames('comingsoon-header-helper-text')}>
                        {t('COMINGSOON_HEADER_HELPER_TEXT')}
                    </div>
                </Col>
            </Row>

        </Layout >
    )

}
export default Comingsoon;
