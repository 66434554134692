import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getTravelApprovalRequests, travelChangeStatus, getTravelDetail, getTravelDetailSteps, travelApprovalsTableExport,
    resetTravelApprovalsTableExport
} from '../../redux/actions/travel.actions';

import { Button, Dropdown, Form, Input, ConfigProvider } from 'antd';
import { UilCheckCircle, UilMinusCircle, UilImport } from '@iconscout/react-unicons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage';
import ExportModal from '../dynamicModal/exportModal';
import Can from '../../authorization/index';

import { dateFormatConvert, useWindowDimensions, numberFormatTR } from '../../utils/config';
import { IsJsonString } from '../../utils/commonFormFunctions';

function ApprovalTravelRequests(props) {
    const { t } = useTranslation();
    const { getTravelApprovalRequests, travelApprovalInitialSearch, travelChangeStatus, currentTab,
        getTravelDetail, getTravelDetailSteps, travelApprovalSearchState, setTravelApprovalSearchState,
        travelApprovalRequestListLoading, travelApprovalRequestList, totalNumberOfRecords,
        travelChangeStatusResponse, openTravelDetailsDrawer, setSelectTravelRequest, travelApprovalsTableExport,
        travelApprovalsExportDataResponse, resetTravelApprovalsTableExport } = props

    const { width } = useWindowDimensions();
    const isShowPassives = false;

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false })
    const [rejectState, setRejectState] = useState({})

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false })
    const [approveState, setApproveState] = useState({})

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportState, setExportState] = useState({ ...travelApprovalSearchState, exportType: 0 });

    const handleApproveConfirm = () => {
        travelChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        travelChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const handleTravelChangeStatus = (status, resourceId) => {
        if (status === "APPROVE") {
            setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': resourceId })
        } else {
            setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': resourceId })
        }
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setExportState({ ...travelApprovalInitialSearch, exportType: 0 });
        setTravelApprovalSearchState({ ...travelApprovalInitialSearch });
    }
    const handleTableFilter = (data) => {
        setTravelApprovalSearchState({ ...travelApprovalSearchState, "stateTypes": data.stateType, "persons": data.persons });
        setExportState({ ...exportState, "stateTypes": data.stateType, "persons": data.persons });
    }
    const showTravelDetails = (id) => {
        if (id !== undefined) {
            openTravelDetailsDrawer();
            setSelectTravelRequest(id);
            getTravelDetail(id);
            getTravelDetailSteps(id);
        }
    }
    useEffect(() => {
        if (travelApprovalSearchState !== travelApprovalInitialSearch) {
            getTravelApprovalRequests(travelApprovalSearchState);
        }
    }, [travelApprovalSearchState]);
    useEffect(() => {
        if (travelApprovalsExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': IsJsonString(travelApprovalsExportDataResponse.message) ? JSON.parse(travelApprovalsExportDataResponse.message).message : t('ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTravelApprovalsTableExport();
        }
    }, [travelApprovalsExportDataResponse])
    useEffect(() => {
        if (currentTab === "TravelApprovalRequests") {
            if (travelChangeStatusResponse.status === 'SUCCESS') {

                setRejectModalData({ 'isVisible': false })
                setApproveModalData({ 'isVisible': false })
                const fields = approveForm.getFieldsValue()
                const projects = { ...fields };
                projects["description"] = "";
                approveForm.setFieldsValue(projects)
                const fields2 = rejectForm.getFieldsValue()
                const projects2 = { ...fields2 };
                projects["description"] = "";
                rejectForm.setFieldsValue(projects2)
            }
        }
    }, [travelChangeStatusResponse])

    const data = travelApprovalRequestList?.map((row) => {
        return (
            {
                "key": row.resourceId,
                "travelDescription": { "text": row.travelDescription, "id": row.resourceId },
                "departureDate": moment(row.departureDate).format(dateFormatConvert),
                "returnDate": moment(row.returnDate).format(dateFormatConvert),
                'fullName': row.fullName,
                "countryName": row.countryName,
                "cityName": row.cityName,
                "stateType": { "id": row.stateTypeId, "displayValue": row.stateTypeName, "resourceId": row.resourceId, "showConfirmButton": row.showConfirmButton },
                "estimatedTotal": row.estimatedTotal,
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['fullName', 'stateType'] : [],
        sortableColumns: ['travelDescription', 'departureDate', 'returnDate', 'countryName', 'fullName', 'cityName', 'stateType', 'estimatedTotal'],
        visibilityOptionExcludes: ['travelDescription', 'departureDate', 'returnDate', 'countryName', 'fullName', 'cityName', 'stateType', 'estimatedTotal'],
        gridSettings: null,
        page: "ApprovalTravelRequests",
        leftActionMenu: totalNumberOfRecords > 0 ? leftActionMenu() : null,
        showVisibilityMenu: false,
    })
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...travelApprovalSearchState.pagination, 'isChanged': true, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : travelApprovalSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setTravelApprovalSearchState({ ...travelApprovalSearchState, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...travelApprovalSearchState.pagination, "pageNumber": 1 }
        setTravelApprovalSearchState({ ...travelApprovalSearchState, "SearchTerm": e, "pagination": pagingObj });
        setExportState({ ...exportState, "SearchTerm": e });
    }
    const handleShowPassivesToogle = (e) => { }
    function handleExportDocument(props) {
        travelApprovalsTableExport({
            ...exportState,
            'exportType': props,
            'pagination': {
                "pageNumber": 1,
                "pageSize": 10000,
                "orderBy": "NeedDate",
                "ascending": exportState.pagination.ascending
            }
        });
        setIsModalVisible(false);
    }
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.export">
                        <Button onClick={() => setIsModalVisible(true)} className="flex-class svg-class white-btn h-32 w-auto m-w-180"><UilImport />  {t('SETTINGS_EXPORT')}</Button>
                    </Can>
                }
            </div>
        )
    }
    return (
        <div className="table-container">
            <ExportModal
                onCancel={() => { setIsModalVisible(false) }}
                status={isModalVisible}
                handleExportDocument={handleExportDocument}
            />
            <DynamicRequestModal
                modalVisible={rejectModalData?.isVisible}
                setStateData={setRejectModalData}
                handleConfirm={handleRejectConfirm}
                handleCancel={handleRejectCancel}
                confirmData={rejectModalData?.id}
                isForm={rejectModalForm}
                iconName='UilTimesCircle'
                modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                description={t('TRAVEL_REJECT_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
            />
            <DynamicRequestModal
                modalVisible={approveModalData?.isVisible}
                setStateData={setApproveModalData}
                handleConfirm={handleApproveConfirm}
                handleCancel={handleApproveCancel}
                confirmData={approveModalData?.id}
                isForm={approveModalForm}
                iconName='UilCheckCircle'
                modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                description={t('TRAVEL_APPROVE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
            />
            <Media query="(min-width: 1000px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('DEPARTURE_DATE')}`,
                            dataIndex: 'departureDate',
                            key: 'departureDate',
                            sorter: true,
                        },
                        {
                            title: `${t('DATE_OF_RETURN')}`,
                            dataIndex: 'returnDate',
                            key: 'returnDate',
                            sorter: true,
                        },
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === 1) {
                                    return <div className="flex-class w-100" >
                                        <span className='yellow  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                        {state.showConfirmButton &&
                                            <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                                <Button className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleTravelChangeStatus('APPROVE', state.resourceId)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                                <Button className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleTravelChangeStatus('REJECT', state.resourceId)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                            </div>
                                        }
                                    </div>
                                }
                                else if (state.id === 2) {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === 3) {
                                    return <div className='red'>{state.displayValue}</div>
                                } else if (state.id === 4) {
                                    return <div className=''>{state.displayValue}</div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('EMPLOYEE')}`.charAt(0).toUpperCase() + `${t('EMPLOYEE')}`.slice(1).toLowerCase(),
                            dataIndex: 'fullName',
                            key: 'fullName',
                            sorter: false,
                        },
                        {
                            title: `${t('ORGANIZATION_COUNTRY')}`,
                            dataIndex: 'countryName',
                            key: 'countryName',
                            sorter: true,
                        },
                        {
                            title: `${t('ORGANIZATION_PROVINCE')}`,
                            dataIndex: 'cityName',
                            key: 'cityName',
                            sorter: true,
                        },
                        {
                            title: `${t('ESTIMATED_BUDGET')}`,
                            dataIndex: 'estimatedTotal',
                            key: 'estimatedTotal',
                            sorter: true,
                            render: data => {
                                return numberFormatTR.format(data) + ' ' + 'TL'
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('REASON_FOR_TRAVEL')}`,
                                dataIndex: 'travelDescription',
                                key: 'travelDescription',
                                sorter: true,
                                render: obj =>
                                    <div className="imageNameActionColumn">
                                        <a onClick={() => showTravelDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                        <Dropdown className="action-menu" overlay={null} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} className="transparent-btn"></Button>
                                        </Dropdown>
                                    </div>
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={travelApprovalRequestListLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        travelApprovalRequestListLoading: state.travel.travelApprovalRequestListLoading,
        travelApprovalRequestList: state.travel.travelApprovalRequestList.results,
        totalNumberOfRecords: state.travel.travelApprovalRequestList.totalNumberOfRecords,
        permissions: state.profile.permissions,
        travelChangeStatusResponse: state.travel.travelChangeStatusResponse,
        travelApprovalsExportDataResponse: state.travel.travelApprovalsExportDataResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTravelApprovalRequests: (searchData) => { dispatch(getTravelApprovalRequests(searchData)) },
        travelChangeStatus: (data, type) => { dispatch(travelChangeStatus(data, type)) },
        getTravelDetail: (id) => { dispatch(getTravelDetail(id)) },
        getTravelDetailSteps: (id) => { dispatch(getTravelDetailSteps(id)) },
        travelApprovalsTableExport: (props) => { dispatch(travelApprovalsTableExport(props)) },
        resetTravelApprovalsTableExport: () => { dispatch(resetTravelApprovalsTableExport()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApprovalTravelRequests);