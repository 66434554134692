import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    setExpenseCreateDrawer, setExpenseDetailsDrawer, resetCurrencyWithIdResponse, getMyExpenseRequests, getExpenseManager,
    getAllExpenseRequests, resetExpenseChangeStatus, updateExpenseWithCashActionReset, updateExpenseProcessedReset,
    resetExpenseExportDetailPage
} from '../../redux/actions/expense.action';

import { Layout, Col, Tabs, Drawer } from 'antd';
import { ToastContainer } from 'react-toastify';

import BreadCrumb from '../../features/breadcrump/breadcrump';

import MyExpense from '../../component/expenseTabs/my_expense';
import ApproveExpense from '../../component/expenseTabs/approve_expense';
import AllExpenseRequest from '../../component/expenseTabs/all_expense_request';

import DynamicToastMessage from '../../component/dynamicToastMessage/index'
import ExpenseAdd from '../../component/expenseTabs/add/expense_add';
import ExpenseDetails from '../../component/expenseTabs/details/expense_details';

import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";

function Expense(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const [currentTab, setCurrentTab] = useState('MyExpenses');
    const [createExpenseKey, setCreateExpenseKey] = useState(1);
    const { setExpenseCreateDrawer, expenseCreateDrawer, permissions, expenseChangeStatusResponse, resetExpenseChangeStatus, detailExpenseRequests, updateExpenseWithCashActionReset,
        resetCurrencyWithIdResponse, getMyExpenseRequests, getExpenseManager, getAllExpenseRequests, expenceDetailDrawer, setExpenseDetailsDrawer, updateExpenseProcessedReset,
        updateExpenseProcessedResponse, resetExpenseExportDetailPage, expenseExportDetailFilesResponse, expenseEditRequestRepsonse } = props;
    const myExpenseRequestInitialSearch = {
        "SearchTerm": "",
        "ExpensesStateType": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const expenseManagerInitialSearch = {
        "SearchTerm": "",
        "ExpensesStateType": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        },
        key:0
    };
    const allExpenseRequestInitialSearch = {
        "SearchTerm": "",
        "ExpensesStateType": [],
        "persons": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const [myExpenseRequestSearchState, setMyExpenseRequestSearchState] = useState(myExpenseRequestInitialSearch);
    const [expenseManagerSearchState, setExpenseManagerSearchState] = useState(expenseManagerInitialSearch);
    const [allExpenseRequestSearchState, setAllExpenseRequestSearchState] = useState(allExpenseRequestInitialSearch);
    const [selectExpense, setSelectExpense] = useState(null);
    const [detailExpenseKey, setDetailExpenseKey] = useState(100);
    const [processedButtonStatus, setProcessedButtonStatus] = useState(false);

    function callback(key) {
        setCurrentTab(key);
    }
    const openCreateExpenseDrawer = (val) => {
        if(val){ setTimeout(() => { props.setCurrentTaskState(102);}, 100);}
        setExpenseCreateDrawer(true)
        setCreateExpenseKey((i) => i + 1)
    }
    const openExpenseDetailsDrawer = () => {
        setExpenseDetailsDrawer(true)
        setDetailExpenseKey((i) => i + 1)
    }
    useEffect(() => {
        if(props.currentTaskState == 101 && expenseCreateDrawer === true){
            props.setCurrentTaskState(102);
        }
        if(props.currentTaskState == 102 && expenseCreateDrawer === false){
            props.setCurrentTaskState(103);
        }
        if (expenseCreateDrawer === false) {
            resetCurrencyWithIdResponse();
        }
    }, [expenseCreateDrawer])

    const updateTable = () => {
        if (currentTab === "MyExpenses") {
            getMyExpenseRequests(myExpenseRequestInitialSearch);
        } else if (currentTab === "ExpenseRequests") {
            getExpenseManager(expenseManagerInitialSearch);
        } else if (currentTab === "AllExpenseRequest") {
            getAllExpenseRequests(allExpenseRequestInitialSearch);
        }
    }

    useEffect(() => {
        updateTable();
        if (currentTab === "MyExpenses") {
            setProcessedButtonStatus(false);
        } else if (currentTab === "ExpenseRequests") {
            setProcessedButtonStatus(false);
        } else if (currentTab === "AllExpenseRequest") {
            setProcessedButtonStatus(true);
        }
    }, [currentTab])

    useEffect(() => {
        if (!expenceDetailDrawer) {

            if (updateExpenseProcessedResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': updateExpenseProcessedResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                updateExpenseProcessedReset()
            }
            else if (updateExpenseProcessedResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': updateExpenseProcessedResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                updateExpenseProcessedReset()
            }
        } else {
            if (updateExpenseProcessedResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [updateExpenseProcessedResponse])

    useEffect(() => {
        if (expenseEditRequestRepsonse.status === 'SUCCESS') {
            updateTable();
        }
    }, [expenseEditRequestRepsonse])

    useEffect(() => {

        if (!expenceDetailDrawer) {

            if (detailExpenseRequests.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': detailExpenseRequests.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                updateExpenseWithCashActionReset()
            }
            else if (detailExpenseRequests.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': detailExpenseRequests.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                updateExpenseWithCashActionReset()
            }
        } else {
            if (detailExpenseRequests.status === 'SUCCESS') {
                updateTable();
            }
        }

    }, [detailExpenseRequests])

    useEffect(() => {
        if (expenseExportDetailFilesResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': expenseExportDetailFilesResponse?.message ? JSON.parse(expenseExportDetailFilesResponse.message).message : null,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetExpenseExportDetailPage();
        }
    }, [expenseExportDetailFilesResponse])
    return (
        <React.Fragment>
            <Layout>
                <Drawer key={createExpenseKey} title={false} width={1280} closable={false} onClose={false} placement="right" visible={expenseCreateDrawer} >
                    <ExpenseAdd />
                </Drawer >
                <Drawer key={detailExpenseKey} title={false} width={1280} closable={false} onClose={false} placement="right" visible={expenceDetailDrawer} >
                    <ExpenseDetails selectExpense={selectExpense} processedButtonStatus={processedButtonStatus} />
                </Drawer >
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrumb />
                        <div className="tab-menu ">
                            <h1 className="page-title">{t('PARTNER_REQUEST_EXPENSE')} </h1>
                            <Tabs
                                className="pad-top-20 page-tabs"
                                onChange={callback}
                                defaultActiveKey="MyExpenses"
                                activeKey={currentTab}
                            >
                                <TabPane tab={<span>{t('MY_EXPENSES')}</span>} key="MyExpenses">
                                    <MyExpense
                                        openCreateExpenseDrawer={openCreateExpenseDrawer}
                                        openExpenseDetailsDrawer={openExpenseDetailsDrawer}
                                        myExpenseRequestInitialSearch={myExpenseRequestInitialSearch}
                                        myExpenseRequestSearchState={myExpenseRequestSearchState}
                                        setMyExpenseRequestSearchState={setMyExpenseRequestSearchState}
                                        setSelectExpense={setSelectExpense}
                                    />
                                </TabPane>
                                <TabPane tab={<span>{t('EXPENSE_REQUESTS')}</span>} key="ExpenseRequests">
                                    <ApproveExpense
                                        openExpenseDetailsDrawer={openExpenseDetailsDrawer}
                                        expenseManagerInitialSearch={expenseManagerInitialSearch}
                                        expenseManagerSearchState={expenseManagerSearchState}
                                        setExpenseManagerSearchState={setExpenseManagerSearchState}
                                        setSelectExpense={setSelectExpense}
                                        currentTab={currentTab}
                                    />
                                </TabPane>
                                {permissions.includes("expense.list.all") && (
                                    <TabPane tab={<span>{t('EXPENSE_ALL')}</span>} key="AllExpenseRequest">
                                        <AllExpenseRequest
                                            openExpenseDetailsDrawer={openExpenseDetailsDrawer}
                                            allExpenseRequestInitialSearch={allExpenseRequestInitialSearch}
                                            allExpenseRequestSearchState={allExpenseRequestSearchState}
                                            setAllExpenseRequestSearchState={setAllExpenseRequestSearchState}
                                            setSelectExpense={setSelectExpense}
                                            currentTab={currentTab}
                                        />
                                    </TabPane>
                                )}
                            </Tabs>

                        </div>
                    </Content>
                </Col>

            </Layout>
            <ToastContainer />
        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        expenseCreateDrawer: state.expense.expenseCreateDrawer,
        expenceDetailDrawer: state.expense.expenceDetailDrawer,
        expenseChangeStatusResponse: state.expense.expenseChangeStatusResponse,
        permissions: state.profile.permissions,
        detailExpenseRequests: state.expense.detailExpenseRequests,
        updateExpenseProcessedResponse: state.expense.updateExpenseProcessedResponse,
        expenseEditRequestRepsonse: state.expense.expenseEditRequestRepsonse,
        expenseExportDetailFilesResponse: state.expense.expenseExportDetailFilesResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMyExpenseRequests: (searchData) => { dispatch(getMyExpenseRequests(searchData)) },
        getExpenseManager: (searchData) => { dispatch(getExpenseManager(searchData)) },
        getAllExpenseRequests: (searchData) => { dispatch(getAllExpenseRequests(searchData)) },
        setExpenseCreateDrawer: (status) => { dispatch(setExpenseCreateDrawer(status)) },
        setExpenseDetailsDrawer: (status) => { dispatch(setExpenseDetailsDrawer(status)) },
        resetCurrencyWithIdResponse: () => { dispatch(resetCurrencyWithIdResponse()) },
        resetExpenseChangeStatus: () => { dispatch(resetExpenseChangeStatus()) },
        updateExpenseWithCashActionReset: () => { dispatch(updateExpenseWithCashActionReset()) },
        updateExpenseProcessedReset: () => { dispatch(updateExpenseProcessedReset()) },
        resetExpenseExportDetailPage: () => { dispatch(resetExpenseExportDetailPage()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Expense);