import { Layout, Card, Button, Tooltip, Spin, List } from 'antd';
import { UilEditAlt, UilTrash, UilFileGraph, UilUserPlus, UilFolderPlus, UilDraggabledots } from '@iconscout/react-unicons';
import { ToastContainer } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {
  getEducationList, deleteEducation, resetDeleteEducation, getEducationInformation, resetEducationInformation,
  getEducationReport, resetEducationReport
} from '../../redux/actions/education.actions';
import { getPersons } from '../../redux/actions/organization.actions';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import DynamicEducationModal from '../../component/dynamicModal/education';
import DynamicEducationModalReport from '../../component/dynamicModal/education-report';
import DynamicEducationModalEdit from '../../component/dynamicModal/education-edit';
import Forbidden from "../../component/forbidden";

import { hrApi } from '../../utils/apiConfig';
import { apiUrl } from '../../utils/config';
import { useMutation } from '@tanstack/react-query';

const DragHandle = SortableHandle(() => (
  <span className="sortable-list-icon">
    <UilDraggabledots className="mr-2" />
  </span>
));

const SortableItem = SortableElement(({ item, openEditEducation, deleteEducation, getEducationReport, t }) => (
  <List.Item key={item.resourceId} className="job-list-item w-100">
    <Card key={item.resourceId} className="w-100 custom-table-card">
      <div className="d-flex">
        <DragHandle />
        <div className="flex-1">
          <a onClick={() => openEditEducation(item.resourceId, '1')} className="details-text">{item.educationName}</a>
        </div>
        <div className="flex-1">
          {item.isActive ? <span>{t('PARTNER_ACTIVE')}</span> : <span>{t('PARTNER_PASSIVE')}</span>}
        </div>
        <div className='flex-1'>
          <div className="flex-1 flex-end pr-6">
            <div onClick={() => openEditEducation(item.resourceId, '2')} className="custom-table-card-icon flex-end clickable">
              <Tooltip title={t('ADD_VIDEO')}><UilFolderPlus /></Tooltip>
            </div>
            <div onClick={() => openEditEducation(item.resourceId, '3')} className="custom-table-card-icon flex-end clickable pl-4">
              <Tooltip title={t('ADD_PARTICIPANT')}><UilUserPlus /></Tooltip>
            </div>
            <div onClick={() => openEditEducation(item.resourceId, '1')} className="custom-table-card-icon flex-end clickable pl-4">
              <Tooltip title={t('PARTNER_EDIT')}><UilEditAlt /></Tooltip>
            </div>
            <div onClick={() => deleteEducation(item.resourceId)} className="custom-table-card-icon flex-end clickable pl-4">
              <Tooltip title={t('PARTNER_DELETE')}><UilTrash /></Tooltip>
            </div>
            <div onClick={() => getEducationReport(item.resourceId)} className="custom-table-card-icon flex-end clickable pl-4">
              <Tooltip title={t('PARTICIPANT_REPORT')}><UilFileGraph /></Tooltip>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </List.Item>
));


const SortableList = SortableContainer(({ items, openEditEducation, deleteEducation, getEducationReport, t }) => {
  return (
    <List
      itemLayout="vertical"
      size="large"
      className="w-100"
      dataSource={items}
      renderItem={(item, index) => (
        <SortableItem
          key={`item-${item.resourceId}`}
          index={index}
          item={item}
          openEditEducation={openEditEducation}
          deleteEducation={deleteEducation}
          getEducationReport={getEducationReport}
          t={t}
        />
      )}
    />
  );
});

function EducationList(props) {
  const {
    permissions, getPersons, profile, persons, educationList, educationListLoading, getEducationList, deleteEducation, deleteEducationResponse, resetDeleteEducation,
    getEducationInformation, resetEducationInformation, getEducationReport, personEducationReportList, resetEducationReport
  } = props;
  
  const organizationResourceId = profile.organization?.resourceId;
  const { t } = useTranslation();
  const { Content } = Layout;
  const [modalView, setModalView] = useState(false);
  const [modalViewEdit, setModalViewEdit] = useState(false);
  const [editEduId, setEditEduId] = useState('');
  const [allPersons, setAllPersons] = useState([]);
  const [openState, setOpenState] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [educationData, setEducationData] = useState([]);

  const updateOrderMainList = async (body) => {
    const config = {
      method: "post",
      url: `${apiUrl}/education/update-order-main-list`,
      headers: {},
      data: body
    };
    const { data } = await hrApi(config);
    return data;
  };

  const { mutate } = useMutation(updateOrderMainList);

  const OpenList = () => {
    setModalView(true)
  }
  const cancelModal = () => {
    setModalView(false)
    getEducationList();
  }
  const cancelModalReport = () => {
    setReportModal(false);
    setReportData([]);
    resetEducationReport();
  }
  const openAuto = (id) => {
    setEditEduId(id)
    setOpenState('1')
    getEducationInformation(id)
    setModalViewEdit(true)
  }
  const reloadList = () => {
    getEducationList();
  }
  const cancelModalEdit = () => {
    setEditEduId('');
    setOpenState(null);
    setModalViewEdit(false);
    resetEducationInformation();
  }
  const openEditEducation = (id, state) => {
    setEditEduId(id)
    setOpenState(state)
    getEducationInformation(id)
    setModalViewEdit(true)
  }

  useEffect(() => {
    getEducationList();
    getPersons(organizationResourceId);
  }, [])

  useEffect(() => {
    setAllPersons(persons)
  }, [persons]);

  useEffect(() => {
    if (!educationListLoading) {
      setEducationData(educationList);
    }
  }, [educationList, educationListLoading])
  useEffect(() => {
    if (deleteEducationResponse.status === 'SUCCESS') {
      DynamicToastMessage({
        'key': 'success_key',
        'title': t('SUCCESS'),
        'description': t('DELETE_SUCCESSFUL'),
        'statusType': 'success',
        'position': 'bottom-right'
      });
      getEducationList();
      resetDeleteEducation();
    }
    else if (deleteEducationResponse.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('EDUCATION_DELETE_FAIL_MESSAGE'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetDeleteEducation();
    }
  }, [deleteEducationResponse])

  useEffect(() => {
    if (personEducationReportList.status === 'SUCCESS') {
      setReportData(personEducationReportList.data)
      setReportModal(true);
    }
    else if (personEducationReportList.status === 'FAIL') {
      DynamicToastMessage({
        'key': 'error_key',
        'description': personEducationReportList.message,
        'statusType': 'error',
        'position': 'bottom-right'
      });
      resetEducationReport();
    }
  }, [personEducationReportList])

  const [isSorted, setIsSorted] = useState(false);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setEducationData((items) => arrayMoveImmutable(items, oldIndex, newIndex));
    setIsSorted(true);
  };
  
  useEffect(() => {
    if (isSorted) {
      const sort = {
        orders: educationData.map((x, i) => ({
          resourceId: x.resourceId,
          order: i + 1,
        })),
      };
      mutate(sort);
      setIsSorted(false);
    }
  }, [educationData, isSorted]);


  return (
    permissions.some(item => ["education.list", "education.add", "education.delete", "education.update"].includes(item)) ? (
      <Content className="page-container">
        <div className="tab-menu">
          <h1 className="page-title">{t('TRAINING_LIST')}</h1>
          <div className="w-100 d-flex justify-space-between pad-top-20">
            <Button onClick={OpenList} className="flex-class svg-class primary-btn h-32 w-auto m-w-180">{t('ADD_NEW_TRAINING')}</Button>
          </div>
        </div>
        <DynamicEducationModal
          modalVisible={modalView}
          modalWidth={720}
          key="modal"
          handleCancel={cancelModal}
          allPersons={allPersons}
          openAuto={openAuto}
        />
        <DynamicEducationModalEdit
          editResourceId={editEduId}
          modalVisible={modalViewEdit}
          modalWidth={720}
          key="modalEdit"
          handleCancel={cancelModalEdit}
          allPersons={allPersons}
          openState={openState}
          reloadList={reloadList}
        />
        <DynamicEducationModalReport
          modalVisible={reportModal}
          modalWidth={720}
          key="modalReport"
          handleCancel={cancelModalReport}
          allPersons={reportData}
        />
        <ToastContainer />
        {educationListLoading ? (
          <Spin className="flex-center pad-top-40" />
        ) : (
          <div className="education-list custom-table-card-class pb-2">
            <div className="custom-table-card-title-class pt-5">
              <div className='d-flex pl-6 py-1'>
                <div className="flex-1">
                  <span>{t('TRAINING_NAME')}</span>
                </div>
                <div className="flex-1">
                  <span>{t('PARTNER_STATUS')}</span>
                </div>
                <div className="flex-1 pl-5" />
              </div>
            </div>
            <div className="custom-table-list custom-table-card-container job-list-container">
              <SortableList
                items={educationData}
                openEditEducation={openEditEducation}
                deleteEducation={deleteEducation}
                getEducationReport={getEducationReport}
                onSortEnd={onSortEnd}
                t={t}
                distance={10}
                useDragHandle
              />
            </div>
          </div>
        )}
      </Content>
    ) : <Forbidden size="sm" />
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.profile.permissions,
    profile: state.profile,
    persons: state.organization.persons,
    educationList: state.education.educationList,
    educationListLoading: state.education.educationListLoading,
    deleteEducationResponse: state.education.deleteEducationResponse,
    educationInformation: state.education.educationInformation,
    educationInformationLoading: state.education.educationInformationLoading,
    personEducationReportList: state.education.personEducationReportList,
    personEducationReportListLoading: state.education.personEducationReportListLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPersons: (id) => { dispatch(getPersons(id)) },
    getEducationList: () => { dispatch(getEducationList()) },
    deleteEducation: (id) => { dispatch(deleteEducation(id)) },
    resetDeleteEducation: () => { dispatch(resetDeleteEducation()) },
    getEducationInformation: (id) => { dispatch(getEducationInformation(id)) },
    resetEducationInformation: () => { dispatch(resetEducationInformation()) },
    getEducationReport: (id) => { dispatch(getEducationReport(id)) },
    resetEducationReport: () => { dispatch(resetEducationReport()) }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EducationList);