import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPersons } from "../../../../redux/actions/organization.actions";
import { getAllDepartmentsTreeView } from "../../../../redux/actions/department.action";

import { Checkbox, Form, ConfigProvider, Spin, Alert } from 'antd';
import { getDepartmentsTreeViewCount, departmentExtractResourceIds } from "../../../../utils/commonFormFunctions";
import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/person.scss';

function CreateApprovalProcessesUnitsStepForm(props) {
    const { setCreateApprovalProcessesObject, createApprovalProcessesObject, getAllDepartmentsTreeView, profile, departmentTreeViewList,
        departmentTreeViewLoading, getPersons, persons, errorIdListState } = props;
    const { t } = useTranslation();
    const [checkboxState, setCheckboxState] = useState({
        "indeterminate": false,
        "isChecked": false
    });
    const [form] = Form.useForm();
    const [personListState, setPersonListState] = useState([]);
    const [unitsFormState, setUnitsFormState] = useState({
        departmentRequest: []
    });

    const onCheckAllChange = e => {
        const status = e.target.checked;
        let indeterminateStatus = status === 0 ? false : true;
        setUnitsFormState(status ? { ...unitsFormState, 'departmentRequest': departmentExtractResourceIds(departmentTreeViewList) } : { ...unitsFormState, 'departmentRequest': [] });
        setCheckboxState({ ...checkboxState, indeterminate: indeterminateStatus ? false : true, checkAll: status })
        form.setFieldsValue({
            "departmentRequest": status ? departmentExtractResourceIds(departmentTreeViewList) : null,
        });
    };
    useEffect(() => {
        getAllDepartmentsTreeView(profile.organization?.resourceId);
    }, []);
    useEffect(() => {
        setPersonListState(persons)
    }, [persons]);
    useEffect(() => {
        var ProcessPerson = unitsFormState?.personList && unitsFormState.personList.map((data) => {
            return {
                'EntityTypeIdx': 1,
                'EntityResourceIdx': data?.id
            }
        });
        var ProcessAssign = unitsFormState?.departmentRequest ? unitsFormState.departmentRequest.map((data) => {
            return {
                'EntityTypeIdx': 2,
                'EntityResourceIdx': data
            }
        }) : [];
        ProcessAssign = unitsFormState?.personList ? ProcessAssign.concat(ProcessPerson) : ProcessAssign;
        setCreateApprovalProcessesObject({
            ...createApprovalProcessesObject,
            "ProcessAssign": ProcessAssign,

        });
        if (unitsFormState?.departmentRequest.length !== 0 && unitsFormState?.departmentRequest.length === getDepartmentsTreeViewCount(departmentTreeViewList)) {
            setCheckboxState({ ...checkboxState, indeterminate: false, checkAll: true })
        } else if (unitsFormState?.departmentRequest.length === 0) {
            setCheckboxState({ ...checkboxState, indeterminate: false, checkAll: false })
        } else if (getDepartmentsTreeViewCount(departmentTreeViewList) > unitsFormState?.departmentRequest.length && unitsFormState?.departmentRequest.length !== 0) {
            setCheckboxState({ ...checkboxState, indeterminate: true, checkAll: false })
        }
    }, [unitsFormState])
    const handleValuesChange = (changedObj) => {
        if (Object.keys(changedObj)[0] === "departmentRequest") {
            //we don't need to use
        } else {
            setUnitsFormState({ ...unitsFormState, ...changedObj });
        }
    }
    const onDepartmentChange = (department, checked) => {
        setUnitsFormState(prevState => {
            let newDepartmentRequest = [...prevState.departmentRequest];
            const updateDepartmentRequest = (dept, isChecked) => {
                if (isChecked && !newDepartmentRequest.includes(dept.resourceId)) {
                    newDepartmentRequest.push(dept.resourceId);
                } else if (!isChecked) {
                    newDepartmentRequest = newDepartmentRequest.filter(id => id !== dept.resourceId);
                }
                if (dept.subDepartments) {
                    dept.subDepartments.forEach(subDept => updateDepartmentRequest(subDept, isChecked));
                }
            };
            updateDepartmentRequest(department, checked);
            return {
                ...prevState,
                departmentRequest: newDepartmentRequest
            };
        });
    };

    const renderDepartments = (departments) => {
        return departments.map((dept, index) => {
            const hasError = errorIdListState?.includes(dept?.resourceId);
            return <div key={index} className="ml-5">
                <Checkbox
                    className={hasError ? 'processes-form-checkbox-error' : ''}
                    disabled={createApprovalProcessesObject?.IsDefault || unitsFormState?.personList?.length > 0}
                    checked={unitsFormState.departmentRequest.includes(dept.resourceId)}
                    onChange={(e) => onDepartmentChange(dept, e.target.checked)}
                >
                    {dept.name}
                </Checkbox>
                {dept.subDepartments && renderDepartments(dept.subDepartments)}
            </div>
        });
    };

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
            >
                <div className="processes-create-form">
                    <div className="processes-create-form-item pb-4" >
                        <label className="label-border-under">{t('PARTNER_APPLIED_DEPARTMENT')}</label>
                    </div>
                    {departmentTreeViewLoading ?
                        <Spin /> :
                        <>
                            {createApprovalProcessesObject?.IsDefault &&
                                <Alert
                                    message={t('DEFAULT_PROCESS_EXISTS')}
                                    type="warning"
                                    className="mb-2"
                                    closable
                                />
                            }
                            <Form.Item className="text-left mar-0 add-user-department-checkbox-all" name="checkboxStatus" >
                                <Checkbox disabled={createApprovalProcessesObject?.IsDefault || unitsFormState?.personList?.length > 0} indeterminate={checkboxState.indeterminate} onChange={onCheckAllChange} checked={checkboxState.checkAll}>{t('SELECT_ALL')}</Checkbox>
                            </Form.Item>
                            <Form.Item className="add-user-department-checkbox-list recursive-checkbox w-100" name="departmentRequest" label=''>
                                {renderDepartments(departmentTreeViewList)}
                            </Form.Item>
                        </>
                    }
                    <div className="processes-create-form-item pb-4" >
                        <label className="label-border-under">{t('APPLIED_PERSON')}</label>
                    </div>
                </div>

                <Form.Item className="custom-select" name="personList">
                    <DynamicSelectbox
                        isMultiple='true'
                        disabled={createApprovalProcessesObject?.IsDefault || unitsFormState?.departmentRequest?.length > 0}
                        optionList={personListState}
                        onFocus={() => getPersons(profile.organization?.resourceId)}
                        placeholder={t('PARTNER_SELECT')}
                    />
                </Form.Item>
            </Form>
        </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        departmentTreeViewList: state.departmentReducer.departmentTreeViewList,
        departmentTreeViewLoading: state.departmentReducer.departmentTreeViewLoading,
        persons: state.organization.persons,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllDepartmentsTreeView: () => { dispatch(getAllDepartmentsTreeView()) },
        getPersons: (id) => { dispatch(getPersons(id)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateApprovalProcessesUnitsStepForm);