import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

export const getManagerOrganization = (resourceId) => async dispatch => {
    
    try {
        var config = {
        method: 'get',
        url: apiUrl+'/organizations/'+ resourceId + '/persons',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {
  
                dispatch({
                    type: 'GET_MANAGER_ORGANIZATION_SUCCESS',
                    payload: response.data.data
                });
             
        })
  
        .catch(function (error) {
            console.log(error);
        });
  
    } catch (e) {
        dispatch({
            type: 404,
            payload: console.log(e)
        });
    }
  };
  export const getParentDepartmentList = (resourceId) => async dispatch => {
    
    try {
        var config = {
        method: 'get',
        url: apiUrl+'/organizations/'+ resourceId + '/departments',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {
  
                dispatch({
                    type: 'GET_PARENT_DEPARTMENT_SUCCESS',
                    payload: response.data.data
                });
             
        })
  
        .catch(function (error) {
            console.log(error);
        });
  
    } catch (e) {
        dispatch({
            type: 404,
            payload: console.log(e)
        });
    }
  };