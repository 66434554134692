import React, { useState, useEffect } from 'react';
import {
    Button, Col, Row, ConfigProvider,
    Form, DatePicker,
    Spin, List
} from 'antd';
import MaskedInput from 'react-text-mask'
import { compareObjects, price, focusDateInput, blurDateInput } from '../../component/../utils/commonFormFunctions';

import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

//STATE
import { setSalaryDetailsOpenDraw, setSalaryDetailEditTabStatus } from '../../redux/actions/person-detail-tab-action'; // edit-draw -- save-draw
import { getDataset } from '../../redux/actions/dataset-action'; //para birimi listeler
import { updateSalary, updateSalaryResponseReset, getSalaryHistory } from '../../redux/actions/person.actions';

//SELECT
import DynamicSelectbox from '../dynamicSelectbox/index';

import { UilArrowLeft } from '@iconscout/react-unicons';
import { CloseOutlined } from '@ant-design/icons';

import moment from 'moment';
import { dateFormat, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../utils/config';

import DynamicToastMessage from '../dynamicToastMessage/index'
import Can from "../../authorization/index";

function SalaryDetails(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { setSalaryDetailsOpenDraw, setSalaryDetailEditTabStatus, getDataset, currencyTypes, salary, updateSalary,
        salaryReasonTypes, updateSalaryResponseReset, personResourceId, updateSalaryResponse, getSalaryHistory, isSalaryLoading,
        disableStatus } = props;
    const [salaryCurrencyList, setCurrencyList] = useState([])
    const [salaryChangeReasonTypeList, setSalaryChangeReasonTypeList] = useState([])
    const [updateSalaryState, setUpdateSalaryState] = useState({})
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "startDate": dateFormatList,
    })
    const closeSalary = () => {
        setSalaryDetailsOpenDraw(false);
        setSalaryDetailEditTabStatus(false);
    }

    useEffect(() => {
        setCurrencyList(currencyTypes)
    }, [currencyTypes])
    useEffect(() => {
        setSalaryChangeReasonTypeList(salaryReasonTypes)
    }, [salaryReasonTypes])

    useEffect(() => {
        const setObj = {
            "salary": salary.salary,
            "salaryCurrency": salary.salaryCurrency,
            "additionalPaymentAmount": salary.additionalPaymentAmount,
            "additionalPaymentAmountCurrency": salary.additionalPaymentAmountCurrency,
            "salaryChangeReasonType": salary.salaryChangeReasonType
        }
        if (salary.startDate) {
            setObj["startDate"] = moment(salary.startDate)
        }
        setUpdateSalaryState(salary)
        form.setFieldsValue(setObj)
    }, [salary])

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'salaryCurrency') {
            setUpdateSalaryState({ ...updateSalaryState, "salaryCurrency": changedObj["salaryCurrency"] });
        } else if (key === 'additionalPaymentAmountCurrency') {
            setUpdateSalaryState({ ...updateSalaryState, "additionalPaymentAmountCurrency": changedObj["additionalPaymentAmountCurrency"] });
        } else if (key === 'salaryChangeReasonType') {
            setUpdateSalaryState({ ...updateSalaryState, "salaryChangeReasonType": changedObj["salaryChangeReasonType"] });
        } else if (key === 'startDate') {
            setUpdateSalaryState({ ...updateSalaryState, "startDate": moment(changedObj.startDate).format(dateFormat) });
        } else {
            setUpdateSalaryState({ ...updateSalaryState, ...changedObj });
        }
        setSalaryDetailEditTabStatus(true);
    }
    const handleUpdateSalary = () => {
        const updateObj = {
            "Salary": updateSalaryState.salary || "",
            "SalaryCurrencyId": updateSalaryState.salaryCurrency?.id || "",
            "AdditionalPaymentAmount": updateSalaryState.additionalPaymentAmount || "",
            "AdditionalPaymentAmountCurrencyId": updateSalaryState.additionalPaymentAmountCurrency?.id || "",
            "SalaryChangeReasonTypeId": updateSalaryState.salaryChangeReasonType?.id || "",
            "StartDate": updateSalaryState.startDate && updateSalaryState.startDate !== 'Invalid date' ? moment(updateSalaryState.startDate).format(dateFormat) : null,
        }
        let hasError = false
        let fields = []
        if (!updateSalaryState.salary || updateSalaryState.salary === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_SALARY_TOTAL') + ' )' + ' ' + t('ENTER_A_VALUE'))
        }
        if (!updateSalaryState.salaryCurrency || updateSalaryState.salaryCurrency.id === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_SALARY_CURRENCY') + ' )' + ' '+ t('CANNOT_BE_EMPTY'))
        }
        if (!updateSalaryState.startDate || updateSalaryState.startDate === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_DATE_OF_VALIDITY') + ' )' + ' '+ t('CANNOT_BE_EMPTY'))
        }
        if (updateSalaryState?.additionalPaymentAmountCurrency?.id && !updateSalaryState?.additionalPaymentAmount) {
            hasError = true
            fields.push('( ' + t('PARTNER_ADDITIONAL_PAYMENT') + ' )' + '  ' + t('ENTER_A_VALUE'))
        }
        if (updateSalaryState?.additionalPaymentAmount && !updateSalaryState?.additionalPaymentAmountCurrency?.id) {
            hasError = true
            fields.push('( ' + t('PARTNER_ADDITIONAL_CURRENCY') + ' )' + '  '+ t('CANNOT_BE_EMPTY'))
        }
        if (!hasError) {
            updateSalary({ "data": updateObj, "personResourceId": personResourceId, "salaryResourceId": updateSalaryState.resourceId })
        } else {
            DynamicToastMessage({
                'key': 'salary_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    useEffect(() => {
        if (updateSalaryResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'salary_success',
                'title': t('SUCCESS'),
                'description': updateSalaryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getSalaryHistory(personResourceId);
            props.setSalaryDetailsOpenDraw(false);
            updateSalaryResponseReset();
        } else if (updateSalaryResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'salary_fail',
                'title': t('ERROR'),
                'description': updateSalaryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            updateSalaryResponseReset()
        }
    }, [updateSalaryResponse]);

    return (
        <div className="table-details detail-scrollable h-70vh">
            <Col span={24}>
                <Row>
                    <Col span={18}>
                        <div className="back-drawer">
                            <Button className="transparent-btn" onClick={closeSalary} icon={<UilArrowLeft />}>{t('PARTNER_RETURN_SALARY_INFORMATION')}</Button>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="close-icon">
                            <CloseOutlined onClick={closeSalary} />
                        </div>
                    </Col>
                </Row>
                {isSalaryLoading ? (
                    <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                ) : (
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark={false}
                            onValuesChange={handleValuesChange}
                        >
                            <Col span={24}>
                                <div className="pad-25" />
                                <Row gutter={[32, 0]}>
                                    <Col span={12}>
                                        <div className="form-left-body">

                                            <Form.Item name="salary" label={t('PARTNER_SALARY_TOTAL') + ' *'} required >
                                                <MaskedInput
                                                    disabled={disableStatus}
                                                    className={salary.salary !== updateSalaryState.salary ? 'is-changed ant-input' : 'ant-input'}
                                                    mask={price}
                                                />
                                            </Form.Item>
                                            <Form.Item name="salaryChangeReasonType" className="custom-select" label={t('CHANGE_REASON')}>
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    className={!compareObjects(salary.salaryChangeReasonType, updateSalaryState.salaryChangeReasonType) ? 'is-changed' : ''}
                                                    optionList={salaryChangeReasonTypeList}
                                                    onFocus={() => getDataset(27)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                />
                                            </Form.Item>
                                            <Form.Item name="additionalPaymentAmount" label={updateSalaryState?.additionalPaymentAmountCurrency?.id ? t('PARTNER_ADDITIONAL_PAYMENT') + ' *' : t('PARTNER_ADDITIONAL_PAYMENT')} required >
                                                <MaskedInput
                                                    disabled={disableStatus}
                                                    className={salary.additionalPaymentAmount !== updateSalaryState.additionalPaymentAmount ? 'is-changed ant-input' : 'ant-input'}
                                                    mask={price}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="form-right-drawer-body">
                                            <Form.Item name="salaryCurrency" className="custom-select" label={t('PARTNER_SALARY_CURRENCY') + ' *'} required >
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    className={!compareObjects(salary.salaryCurrency, updateSalaryState.salaryCurrency) ? 'is-changed' : ''}
                                                    optionList={salaryCurrencyList}
                                                    onFocus={() => getDataset(23)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            <Form.Item name="startDate" label={datePickerFormatType.startDate === dynamicDateInputFormatter ? t('PARTNER_DATE_OF_VALIDITY') + " * " + t('PLACEHOLDER_DATE') : t('PARTNER_DATE_OF_VALIDITY') + " *"} required >
                                                <DatePicker
                                                    disabled={disableStatus}
                                                    className={!compareObjects(salary.startDate, updateSalaryState.startDate) ? 'is-changed' : ''}
                                                    locale={localeLang}
                                                    format={datePickerFormatType.startDate}
                                                    placeholder={t('PLACEHOLDER_DATE')}
                                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'startDate', dynamicDateInputFormatter)}
                                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'startDate', dateFormatList)}
                                                />
                                            </Form.Item>
                                            <Form.Item name="additionalPaymentAmountCurrency" className="custom-select" label={updateSalaryState?.additionalPaymentAmount ? t('PARTNER_ADDITIONAL_CURRENCY') + ' *' : t('PARTNER_ADDITIONAL_CURRENCY')} required >
                                                <DynamicSelectbox
                                                    disabled={disableStatus}
                                                    className={!compareObjects(salary.additionalPaymentAmountCurrency, updateSalaryState.additionalPaymentAmountCurrency) ? 'is-changed' : ''}
                                                    optionList={salaryCurrencyList}
                                                    onFocus={() => getDataset(23)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {disableStatus !== true &&
                                <div className="fixed-btn">
                                    <div className="page-end">
                                        <div className="hr-end"></div>
                                    </div>
                                    <div className="form-submit">
                                        <Button onClick={closeSalary} className="white-btn w-180 h-40 f-14">{t('INVENTORY_CANCEL')}</Button>
                                        <div className="btn-padding" />
                                        <Can I="person.salary.update">
                                            <Button onClick={handleUpdateSalary} className="primary-btn w-180 h-40 f-14">{t('QUICK_ACTION_SAVE')}</Button>
                                        </Can>
                                        <div className="btn-padding-end" />
                                    </div>
                                </div>
                            }
                        </Form >
                    </ConfigProvider>
                )}
            </Col>
        </div >
    )
}
const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        currencyTypes: state.dataset.currencyTypes,
        salaryReasonTypes: state.dataset.salaryReasonTypes,
        salary: state.person.salary,
        updateSalaryResponse: state.person.updateSalaryResponse,
        isSalaryLoading: state.person.isSalaryLoading
    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setSalaryDetailsOpenDraw: (props) => { dispatch(setSalaryDetailsOpenDraw(props)); },
        setSalaryDetailEditTabStatus: (props) => { dispatch(setSalaryDetailEditTabStatus(props)); },
        getDataset: (id) => { dispatch(getDataset(id)); },
        updateSalary: (id) => { dispatch(updateSalary(id)); },
        getSalaryHistory: (personResourceId) => { dispatch(getSalaryHistory(personResourceId)); },
        updateSalaryResponseReset: () => { dispatch(updateSalaryResponseReset()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalaryDetails);