import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

export const getExpenseType = () => async dispatch => {
    try {
        const res = await hrApi.get(apiUrl + "/expensetype");
        var userList = res.data.map((row, index) => ({
            i: index,
            id: row.id,
            name: row.name
        }))
      

        dispatch({
            type: 'GET_EXPENSE_TYPE',
            payload: userList,
        });
    } catch (e) {
        dispatch({
            type: 'GET_EXPENSE_TYPE_ERROR',
            payload: console.log(e)
        });
    }
};
