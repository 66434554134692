
export const setOwnerShipAddDrawer = (ownerShipAddDrawer) => async dispatch => {

    try {
        dispatch({
            type: 'SET_OWNERSHIP_ADD_DRAWER',
            payload: ownerShipAddDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};

export const setOwnerShipDetailsDrawer = (ownerShipDetailsDrawer) => async dispatch => {

    try {
        dispatch({
            type: 'SET_OWNERSHIP_DETAILS_DRAWER',
            payload: ownerShipDetailsDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};
export const setOwnerShipReturnDrawer = (ownerShipReturnDrawer) => async dispatch => {

    try {
        dispatch({
            type: 'SET_OWNERSHIP_RETURN_DRAWER',
            payload: ownerShipReturnDrawer,
        });
    } catch (e) {
        console.log(e);
    }

};
