import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, Col, Row, Button, Steps, Spin, List, Form, Input, ConfigProvider } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'

import { resetScrollPosition, repaymentOptions, monthOwerRideListTR } from "../../../utils/commonFormFunctions";
import {
    setAdvanceDetailsDrawer, advanceChangeStatus, resetAdvanceChangeStatus, getAdvanceDetails,
    getAdvanceDetailStep, getAdvanceDetailsTable, updateAdvance, updateAdvanceReset
} from '../../../redux/actions/advance.actions'

import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicRequestModal from '../../dynamicModal/requestModal'
import moment from 'moment';
import 'moment/locale/tr';

import AdvanceDetailApproval from "./advanceDetailApproval";

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';
import '../../../styles/custom-step-drawer.scss';
import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/expense.scss';
import '../../../styles/utility.scss';

function AdvanceDetailsTabs(props) {
    const [status, setStatus] = useState(false);
    const { Step } = Steps;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const { setAdvanceDetailsDrawer, advanceDetailStepList, advanceDetailStepListLoading, getAdvanceDetails, getAdvanceDetailStep, getAdvanceDetailsTable,
        advanceDetailsLoading, advanceDetails, advanceChangeStatus, resetAdvanceChangeStatus, advanceChangeStatusResponse, selectAdvance, searchData,
        profile, updateAdvance, updateAdvanceReset, advanceUpdateResponse } = props;
    const [form] = Form.useForm();
    const [currentTab, setCurrentTab] = useState(0);
    const [dynamicStepKey, setDynamicStepKey] = useState(1)
    const [advanceUpdateObj, setAdvanceUpdateObj] = useState({});
    const [advanceRequestApproveRef, setAdvanceRequestApproveRef] = useState("{}");
    const [declineButtonRef, setDeclineButtonRef] = useState(false);
    const [waitingApproveState, setWaitingApproveState] = useState({});
    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false });
    const [rejectState, setRejectState] = useState({});

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false });
    const [approveState, setApproveState] = useState({});

    const [cancelModalData, setCancelModalData] = useState({ 'isVisible': false });
    const [closeAdvanceModalData, setCloseAdvanceModalData] = useState({ 'isVisible': false });

    const [editPaymentTableStatus, setEditPaymentTableStatus] = useState(false);
    const [advancePayState, setAdvancePayState] = useState([]);

    const [advanceRequestState, setAdvanceRequestState] = useState();
    const [advanceAmountList, setAdvanceAmountList] = useState([{
        "amount": null,
        "amountXWrite": null,
        "currency": null,
    }]);
    const handleApproveConfirm = () => {
        advanceChangeStatus({ 'resourceId': selectAdvance, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        advanceChangeStatus({ 'resourceId': selectAdvance, 'description': rejectState }, 'reject');
    }
    const handleCloseApproveConfirm = () => {
        advanceChangeStatus({ 'resourceId': selectAdvance }, 'close');
    }
    const handleCancelConfirm = () => {
        advanceChangeStatus({ 'resourceId': selectAdvance }, 'canceled');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleLeaveCancel = () => {
        setCancelModalData({ 'isVisible': false })
    }
    const handleCloseApproveCancel = () => {
        setCloseAdvanceModalData({ 'isVisible': false })
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }
    const rejectModalForm = (
        <div className="table-details pa-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pa-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    useEffect(() => {
        if (advanceChangeStatusResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': advanceChangeStatusResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getAdvanceDetails(selectAdvance);
            getAdvanceDetailStep(selectAdvance);
            setRejectModalData({ 'isVisible': false })
            setApproveModalData({ 'isVisible': false })
            setCancelModalData({ 'isVisible': false })
            setCloseAdvanceModalData({ 'isVisible': false });
            const fields = approveForm.getFieldsValue()
            const projects = { ...fields };
            projects["description"] = "";
            approveForm.setFieldsValue(projects)
            const fields2 = rejectForm.getFieldsValue()
            const projects2 = { ...fields2 };
            projects["description"] = "";
            rejectForm.setFieldsValue(projects2)
            resetAdvanceChangeStatus()

        }
        else if (advanceChangeStatusResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': advanceChangeStatusResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetAdvanceChangeStatus()
        }
    }, [advanceChangeStatusResponse])

    useEffect(() => {
        if (advanceUpdateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': advanceUpdateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getAdvanceDetails(selectAdvance);
            getAdvanceDetailStep(selectAdvance);
            setEditPaymentTableStatus(false);
            setStatus(false);
            updateAdvanceReset();

        }
        else if (advanceUpdateResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': advanceUpdateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateAdvanceReset();
        }
    }, [advanceUpdateResponse])

    useEffect(() => {
        setCurrentTab(advanceDetailStepList.filter(filterData => filterData.isSelected === true)[0]?.sortOrder || advanceDetailStepList.length + 1)
    }, [advanceDetailStepList])

    useEffect(() => {
        setAdvanceRequestApproveRef(JSON.stringify({ ...advanceDetails }))
        if (advanceDetails?.personResourceId !== "" && advanceDetailsLoading === false) {
            getAdvanceDetailsTable(advanceDetails?.personResourceId, searchData);
        }
    }, [advanceDetails])
    useEffect(() => {
        if (!advanceDetailsLoading) {
            if (advanceDetails?.paymentInfo?.length > 0) {
                var list = [];
                advanceDetails?.paymentInfo.map((data) => {
                    return list.push({
                        'installmentNumber': data.installmentNumber,
                        'amount': parseFloat(data.amount),
                        'currencyName': data.currencyName,
                        'isPayment': data.isPayment
                    });
                });
                setAdvancePayState(list);
            }
        };
        //monthOwerRideListTR
        const repaymentList = [...repaymentOptions];
        const payMonth = advanceDetails?.returnPaymentTypeId === 1 ? advanceDetails?.firstPaymentMount ? monthOwerRideListTR.filter(filterData => filterData.text === advanceDetails?.firstPaymentMount)[0].value : null : null;
        const repayment = advanceDetails?.advanceMasterTypeId === 2 ? repaymentList.filter(filterData => filterData.id === advanceDetails?.returnPaymentTypeId)[0] : null;
        var amountList = [];

        let obj = {
            'AdvanceTypes': advanceDetails?.advanceMasterTypeId,
            'Description': advanceDetails?.description,
            'RepaymentType': repayment,
            'NumberOfInstallments': advanceDetails?.returnPaymentTypeId === 1 ? advanceDetails.installmentCount ? { 'id': advanceDetails.installmentCount, 'displayValue': advanceDetails.installmentCount.toString() } : null : null,
            'RequestCreditCard': advanceDetails?.advanceMasterTypeId === 1 ? advanceDetails?.requestCreditCard : false,
            'RelatedTravelIdx': advanceDetails?.advanceMasterTypeId === 1 ? { 'id': advanceDetails?.relatedTravelIdx, 'displayValue': advanceDetails?.relatedTravelName } : null,
            'notes': advanceDetails?.notes,
        }
        let formObj = {
            ...obj
        }
        if (advanceDetails?.needDate) {
            formObj["AdvanceNeedDate"] = moment(advanceDetails?.needDate);
            formObj["FirstInstallmentPaymentMonth"] = payMonth ? moment(new Date().getFullYear().toString() + '-' + payMonth + '-01 00:00:00') : null;
        }
        let stateObj = {
            ...obj,
            'AdvanceNeedDate': advanceDetails?.needDate,
            'FirstInstallmentPaymentMonth': payMonth ? new Date().getFullYear().toString() + '-' + payMonth + '-01 00:00:00' : null
        }
        advanceDetails.amountsList.map((data) => {
            return amountList.push({
                'amount': data.amount,
                'amountXWrite': parseFloat(data.amount),
                'currency': { 'id': data.currencyId, 'displayValue': data.currencyName }
            })
        })
        setAdvanceAmountList(amountList);
        form.setFieldsValue(formObj);
        setAdvanceRequestState(stateObj);
    }, [advanceDetails])
    useEffect(() => {
        setAdvanceUpdateObj(JSON.parse(advanceRequestApproveRef))
    }, [advanceRequestApproveRef])

    const setAdvanceRequestObj = (obj) => {
        setAdvanceUpdateObj(obj);
    }
    function callback(key) {
        setCurrentTab(parseInt(key));
        resetScrollPosition('.timeoff-draw-scrollable-tab > .ant-tabs-content-holder');
    }
    function destroyLastState() {
        setCurrentTab(1);
        setAdvanceDetailsDrawer(false)
        setStatus(false)
    }
    function cancel() {
        setDeclineButtonRef(true)
        setStatus(false);
        setAdvanceUpdateObj({})
    }
    function edit() {
        setStatus(true)
        setDeclineButtonRef(false)
    }
    function paymentInfoUpdate() {
        setEditPaymentTableStatus(true);
    }
    function paymentInfoClose() {
        setEditPaymentTableStatus(false);
        getAdvanceDetails(selectAdvance);
    }
    function paymentInfoSaveChange() {
        //save methods
        var amountData = [];
        advanceDetails.amountsList.map((data) => {
            return amountData.push({
                'Amount': data.amount,
                'CurrencyId': data.currencyId
            })
        })
        const data = {
            'AdvanceNeedDate': advanceDetails.needDate,
            'Amounts': amountData,
            'AdvanceTypeId': 1,
            'Description': advanceDetails.description ? advanceDetails.description : '',
            'Notes': advanceDetails.notes ? advanceDetails.notes : '',
            'RequestCreditCard': advanceDetails?.advanceMasterTypeId === 1 ? advanceDetails?.requestCreditCard ? advanceDetails?.requestCreditCard : false : false,
            'RelatedTravelIdx': advanceDetails?.advanceMasterTypeId === 1 ? advanceDetails?.relatedTravelIdx ? advanceDetails?.relatedTravelIdx : null : null,
            'AdvanceMasterTypeId': advanceDetails?.advanceMasterTypeId,
            'ReturnPaymentTypeId': advanceDetails?.advanceMasterTypeId === 2 ? advanceDetails?.returnPaymentTypeId ? advanceDetails?.returnPaymentTypeId : null : null,
            'InstallmentCount': advanceDetails?.advanceMasterTypeId === 2 ? advanceDetails?.installmentCount : null,
            'FirstPaymentMount': advanceDetails?.advanceMasterTypeId === 2 ? advanceDetails?.firstPaymentMount : null,
            'PaymentInfo': advanceDetails?.advanceMasterTypeId === 2 ? advancePayState : []
        }
        updateAdvance(data, selectAdvance);
    }
    function advanceClose() {
        setCloseAdvanceModalData({ ...closeAdvanceModalData, 'isVisible': true, 'id': selectAdvance })

    }
    function advanceCancel() {
        setCancelModalData({ ...cancelModalData, 'isVisible': true, 'id': selectAdvance })
    }
    function approve() {
        setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': selectAdvance })
    }
    function decline() {
        setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': selectAdvance })
    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []
        if (!advanceUpdateObj.AdvanceNeedDate || advanceUpdateObj.AdvanceNeedDate === "" || advanceUpdateObj.AdvanceNeedDate === null) {
            hasError = true
            fields.push(t('PARTNER_NEED_DATE'))
        }
        if (advanceUpdateObj.Amounts.length > 0) {
            advanceUpdateObj.Amounts.map((data, index) => {
                if ((!data.Amount || data.Amount === "")) {
                    hasError = true;
                    fields.push(t('ADVANCE_AMOUNT'))
                }
                if (!data.CurrencyId) {
                    hasError = true;
                    fields.push(t('PARTNER_CURRENCY'))
                }
            })
        } else {
            hasError = true
            fields.push(t('ADVANCE_AMOUNT'))
        }
        if (!advanceUpdateObj?.AdvanceTypes || advanceUpdateObj?.AdvanceTypes === null) {
            hasError = true
            fields.push(t('CHOOSE_ADVANCE_TYPE'))
        }
        if ((advanceUpdateObj?.AdvanceTypes === 2) && (!advanceUpdateObj?.RepaymentType || advanceUpdateObj?.RepaymentType?.id === null || advanceUpdateObj?.RepaymentType?.id === "")) {
            hasError = true
            fields.push(t('FORM_OF_REPAYMENT'))
        }
        if ((advanceUpdateObj?.RepaymentType?.id === 1) && (!advanceUpdateObj?.NumberOfInstallments || advanceUpdateObj?.NumberOfInstallments?.id === null || advanceUpdateObj?.NumberOfInstallments?.id === "")) {
            hasError = true
            fields.push(t('NUMBER_OF_INSTALLMENTS'))
        }
        if ((advanceUpdateObj?.RepaymentType?.id === 1) && (!advanceUpdateObj.FirstInstallmentPaymentMonth || advanceUpdateObj.FirstInstallmentPaymentMonth === "" || advanceUpdateObj.FirstInstallmentPaymentMonth === null)) {
            hasError = true
            fields.push(t('FIRST_INSTALLMENT_PAYMENT_MONTH'))
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    function saveChanges() {
        var amountData = [];
        advanceUpdateObj.amountsList?.length > 0 &&
            advanceUpdateObj.amountsList.map((data) => {
                return amountData.push({
                    'Amount': data.amount,
                    'CurrencyId': data.currencyId
                })
            })
        const data = {
            'AdvanceNeedDate': advanceUpdateObj.AdvanceNeedDate,
            'Amounts': advanceUpdateObj.Amounts,
            'AdvanceTypeId': 1,
            'Description': advanceUpdateObj.Description ? advanceUpdateObj.Description : '',
            'Notes': advanceUpdateObj.Notes ? advanceUpdateObj.Notes : '',
            'RequestCreditCard': advanceUpdateObj.RequestCreditCard ? advanceUpdateObj.RequestCreditCard : false,
            'RelatedTravelIdx': advanceUpdateObj.RelatedTravelIdx?.id ? advanceUpdateObj.RelatedTravelIdx?.id : '',
            'AdvanceMasterTypeId': advanceDetails?.advanceMasterTypeId,
            'ReturnPaymentTypeId': advanceUpdateObj?.RepaymentType?.id,
            'InstallmentCount': advanceUpdateObj?.NumberOfInstallments?.id,
            'FirstPaymentMount': advanceUpdateObj?.FirstInstallmentPaymentMonth ? moment(advanceUpdateObj.FirstInstallmentPaymentMonth).format('MMMM') : null,
            'PaymentInfo': advanceDetails?.advanceMasterTypeId === 2 ? advanceUpdateObj?.PaymentList : []

        }
        checkRequiredFields(() => updateAdvance(data, selectAdvance))
    }

    const GetButtonWithCurrentTabSaveChanges = () => {
        if (!advanceDetailStepListLoading && !advanceDetailsLoading) {

            if (!status) {
                if (advanceDetailStepList[0].editableStatus === 3) {
                    return (
                        <>
                            <Button onClick={() => edit()} className="w-120 h-40 transparent-btn transparent-import pa-0">{t('PARTNER_EDIT')}</Button>
                            <div className="w-100 flex-end mr-3">
                                <Button onClick={() => decline()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_DECLINE')}</Button>
                                <div className="mr-3" />
                                <Button onClick={() => approve()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_APPROVE')}</Button>
                            </div>
                        </>
                    )
                } else if (advanceDetailStepList[0].editableStatus === 2) {
                    return (
                        <div className="w-100 space-between mr-3" >
                            <Button onClick={() => edit(false)} className="w-120 h-40 transparent-import fw-600 transparent-btn pa-0">{t('PARTNER_EDIT')}</Button>
                            <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('INVENTORY_RETURN_LIST')}</Button>
                        </div>
                    );
                } else if (profile.resourceId === advanceDetails.person?.resourceId && (parseInt(advanceDetails.stateTypeId) !== 2 && parseInt(advanceDetails.stateTypeId) !== 3) && parseInt(advanceDetails.stateTypeId) !== 4) {
                    return (
                        <>
                            <div className="w-100 space-between mr-3">
                                <Button onClick={() => advanceCancel()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_CANCEL')}</Button>
                                <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                            </div>
                        </>
                    )
                } else if (advanceDetailStepList[0].closableStatus && !editPaymentTableStatus) {
                    return (
                        <>
                            {(advanceDetails?.advanceMasterTypeId === 2 && advanceUpdateObj?.RepaymentType?.id === 1 && advanceDetails?.paymentInfo?.length > 0) &&
                                <Button onClick={() => paymentInfoUpdate()} className="w-200 h-40 f-14 font-w-700 white-btn">{t('ENTER_PAYMENT_INFORMATION')}</Button>
                            }
                            <div className="w-100 flex-end mr-3">
                                <Button onClick={() => advanceCancel()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_CANCEL')}</Button>
                                <div className="mr-3" />
                                <Button onClick={() => advanceClose()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('ADVANCE_CLOSE')}</Button>
                            </div>
                        </>
                    )
                } else if (advanceDetailStepList[0].closableStatus && editPaymentTableStatus) {
                    return (
                        <div className="w-100 space-between mr-3">
                            <Button onClick={() => paymentInfoClose()} className=" w-120 h-40 f-14 font-w-700 transparent-btn">{t('INVENTORY_CANCEL')}</Button>
                            <Button onClick={() => paymentInfoSaveChange()} className=" w-180 h-40 f-14 font-w-700 primary-btn ">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </div>
                    );
                } else {
                    return (
                        <div className="w-100 flex-end mr-3">
                            <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="w-100 space-between mr-3">
                        <Button onClick={() => cancel()} className="w-120 h-40 f-14 font-w-700 transparent-btn">{t('INVENTORY_CANCEL')}</Button>
                        <Button onClick={() => saveChanges()} className="w-180 h-40 f-14 font-w-700 primary-btn  ">{t('PARTNER_SAVE_CHANGES')}</Button>
                    </div>
                )
            }
        } else {
            return <></>
        }
    }
    return (
        <div>
            {(advanceDetailStepListLoading && advanceDetailsLoading) ?
                <Spin className="flex-center pad-top-75 " />
                :
                <div>
                    <div className="custom-step-drawer hide-tabs">
                        <DynamicRequestModal
                            modalVisible={rejectModalData?.isVisible}
                            setStateData={setRejectModalData}
                            handleConfirm={handleRejectConfirm}
                            handleCancel={handleRejectCancel}
                            confirmData={rejectModalData?.id}
                            isForm={rejectModalForm}
                            iconName='UilTimesCircle'
                            modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                            description={t('ADVANCE_REJECT_MESSAGE')}
                            cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                            successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
                        />
                        <DynamicRequestModal
                            modalVisible={approveModalData?.isVisible}
                            setStateData={setApproveModalData}
                            handleConfirm={handleApproveConfirm}
                            handleCancel={handleApproveCancel}
                            confirmData={approveModalData?.id}
                            isForm={approveModalForm}
                            iconName='UilCheckCircle'
                            modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                            description={t('ADVANCE_APPROVE_MESSAGE')}
                            cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                            successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
                        />
                        <DynamicRequestModal
                            modalVisible={cancelModalData?.isVisible}
                            setStateData={setCancelModalData}
                            handleConfirm={handleCancelConfirm}
                            handleCancel={handleLeaveCancel}
                            confirmData={approveModalData?.id}
                            isForm={true}
                            iconName='UilQuestionCircle'
                            modalTitle={t('ADVANCE_CANCEL')}
                            description={t('')}
                            cancelBtnTxt={t('PARTNER_CANCEL')}
                            successBtnTxt={t('PARTNER_YES_CANCEL')}
                        />
                        <DynamicRequestModal
                            modalVisible={closeAdvanceModalData?.isVisible}
                            setStateData={setCloseAdvanceModalData}
                            handleConfirm={handleCloseApproveConfirm}
                            handleCancel={handleCloseApproveCancel}
                            confirmData={approveModalData?.id}
                            isForm={true}
                            iconName='UilQuestionCircle'
                            modalTitle={t('ADVANCE_CLOSE')}
                            description={t('CLOSE_ADVANCE_REQUEST')}
                            cancelBtnTxt={t('PARTNER_CANCEL')}
                            successBtnTxt={t('YES')}
                        />
                        <div className="w-100">
                            <Row>
                                <Col span={6}>
                                    <Steps className="left-step time-off-step pad-top-40" direction="vertical" size="middle" current={advanceDetailStepList.filter(filterData => filterData.isSelected === true)[0]?.sortOrder - 1 || advanceDetailStepList.length}>
                                        {advanceDetailStepList.map((data) => {
                                            return (
                                                <Step key={data.sortOrder} title={data.stepName}
                                                    description={

                                                        <ul className="sub-tab-hide-icon">
                                                            <li className={data.isSelected ? "sub-tab-active" : ''}>
                                                                {advanceDetailStepList.length > 0 ? data.name : ''} <br />
                                                                {advanceDetailStepList.length > 0 ? data?.date ? moment(data?.date).format('L') : '' : ''}
                                                            </li>
                                                        </ul>
                                                    } />
                                            )
                                        })}
                                    </Steps>
                                </Col>
                                <Col span={18}>
                                    <div className="custom-step-drawer-header pad-right-12">

                                        <div className="custom-step-drawer-title">
                                            <h1>{t('ADVANCE_REQUEST_DETAIL')}</h1>
                                        </div>
                                        <div className="custom-step-drawer-close-icon ">
                                            <UilMultiply onClick={() => destroyLastState()} />
                                        </div>
                                    </div>
                                    <Tabs className="custom-tab timeoff-draw-scrollable-tab table-details pad-right-12"
                                        onChange={callback}
                                        activeKey={currentTab.toString()}
                                        defaultActiveKey={currentTab.toString()}
                                    >
                                        {advanceDetailStepList.map((data) => {
                                            return (
                                                <TabPane key={data.sortOrder}>
                                                    <AdvanceDetailApproval
                                                        form={form}
                                                        waitingApproveState={waitingApproveState}
                                                        setWaitingApproveState={setWaitingApproveState}
                                                        status={status}
                                                        setAdvanceRequestObj={(o) => setAdvanceRequestObj(o)}
                                                        declineButtonRef={declineButtonRef}
                                                        dynamicStepKey={dynamicStepKey}
                                                        editPaymentTableStatus={editPaymentTableStatus}
                                                        advancePayState={advancePayState}
                                                        setAdvancePayState={setAdvancePayState}
                                                        advanceRequestState={advanceRequestState}
                                                        setAdvanceRequestState={setAdvanceRequestState}
                                                        advanceAmountList={advanceAmountList}
                                                        setAdvanceAmountList={setAdvanceAmountList}
                                                    />
                                                </TabPane>
                                            )
                                        })}
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="add-user-draw-footer">
                        <GetButtonWithCurrentTabSaveChanges />
                    </div>
                </div >}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        permissions: state.profile.permissions,
        advanceDetailStepList: state.advance.advanceDetailStepList,
        advanceDetailStepListLoading: state.advance.advanceDetailStepListLoading,
        advanceDetails: state.advance.advanceDetails,
        advanceDetailsLoading: state.advance.advanceDetailsLoading,
        advanceChangeStatusResponse: state.advance.advanceChangeStatusResponse,
        advanceUpdateResponse: state.advance.advanceUpdateResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAdvanceDetailsDrawer: (props) => { dispatch(setAdvanceDetailsDrawer(props)) },
        advanceChangeStatus: (data, type) => { dispatch(advanceChangeStatus(data, type)) },
        getAdvanceDetails: (resourceId) => { dispatch(getAdvanceDetails(resourceId)) },
        getAdvanceDetailStep: (resourceId) => { dispatch(getAdvanceDetailStep(resourceId)) },
        getAdvanceDetailsTable: (resourceId, searchData) => { dispatch(getAdvanceDetailsTable(resourceId, searchData)) },
        resetAdvanceChangeStatus: () => { dispatch(resetAdvanceChangeStatus()) },
        updateAdvance: (data, resourceId) => { dispatch(updateAdvance(data, resourceId)) },
        updateAdvanceReset: () => { dispatch(updateAdvanceReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceDetailsTabs);