import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

function CallBtn() {

    const { t } = useTranslation();

    return (
        <div>
            <div className="flex-end">
                <Button className="primary-btn w-178 h-32 f-14">{t('CALL_YOU')}</Button>
            </div>
            <div className="pad-12" />
            <div className="flex-end">
                <Button className="white-btn w-178 h-32 f-14">{t('SEND_MESSAGE')}</Button>
            </div>
        </div>
    );
}
export default CallBtn;
