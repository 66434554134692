import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";

// Action Types
export const SET_USER_TASK_INFO = 'SET_USER_TASK_INFO';
export const SET_CURRENT_TASK_STATE = 'SET_CURRENT_TASK_STATE';
export const CALL_ONBOARDING = 'CALL_ONBOARDING';
export const CALL_ONBOARDING_SUCCESS = 'CALL_ONBOARDING_SUCCESS';
export const CALL_ONBOARDING_FAIL = 'CALL_ONBOARDING_FAIL';

export const setUserTaskInfo = (userTaskInfo) => ({
  type: SET_USER_TASK_INFO,
  userTaskInfo
});

export const setCurrentTaskState = (currentTaskState) => ({
  type: SET_CURRENT_TASK_STATE,
  currentTaskState
});

export const getOnboarding = (id /* ID varsa tamamlanan görevi "done" durumuna getirir "görevleri döner" , yoksa "görevleri döner" */) => {
  return async (dispatch) => {
    dispatch({ type: CALL_ONBOARDING });
    try {
      const config = {
        method: "get",
        url: (id || id == 0 || id == -1) ? (apiUrl + "/ApplyAndGetOnBoarding?taskId="+id) : (apiUrl + "/ApplyAndGetOnBoarding"),
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            dispatch({ type: CALL_ONBOARDING_SUCCESS, payload: response.data.data });
          } else {
            dispatch({
              type: CALL_ONBOARDING_FAIL,
              payload: response.data.errorMessages,
            });
          }
        })
        .catch(function (error) {
            dispatch({
              type: CALL_ONBOARDING_FAIL,
              payload: error,
            });
        });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: CALL_ONBOARDING_FAIL,
          payload: error,
        });
      }
    }
  };
};


