import { useTranslation } from 'react-i18next';
import { Button,Spin } from 'antd';

const DeleteCard = ({handleRejectDeleteCardModal , deleteCardAction,isCardDeleteLoading}) => {
        
    const { t } = useTranslation();

        if (isCardDeleteLoading !== 'loading') {
            return (
                <>
                        <div className="subscription-modal-description mt-2">
                            {t("DELETE_CARD_ASK")}
                        </div>
                        <Button onClick={() => deleteCardAction()} className="ant-btn primary-btn w-100 h-40 mt-3">
                            {t("DELETE_CARD_CONFIRM")}
                        </Button>
                        <Button onClick={() => handleRejectDeleteCardModal()} className="ant-btn white-btn w-100 h-40 mt-3">
                            {t("QUICK_ACTION_CANCEL")}
                        </Button>
                </> 
               
            );
        }else {
            return (
                <Spin className="my-15" size="medium"></Spin>
            );
        }
}

export default DeleteCard;