import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRoles } from '../../../../redux/actions/roles.actions';
import { getWorkHourDepartmentInPersons } from "../../../../redux/actions/organization.actions";
import { setCreateDefinedTaskDrawer, createAssignedTasks, resetCreateAssignedTasks } from '../../../../redux/actions/task.actions';

import { Button, List, Form, ConfigProvider, Input, TreeSelect } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import DynamicSelectbox from "../../../dynamicSelectbox/index";
import { customFilter } from "../../../../utils/commonFormFunctions";
import Successful from "../../../successful";
import DynamicRequestModal from '../../../dynamicModal/requestModal';
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/left-step.scss';
import '../../../../styles/table.scss';

function CreateDefinedTasks(props) {
    const { setCreateDefinedTaskDrawer, getRoles, roles, getWorkHourDepartmentInPersons, createAssignedTasks,
        createAssignedTasksResponse, resetCreateAssignedTasks, workHourDepartmentInPerson } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { SHOW_CHILD } = TreeSelect;
    const [currentTab, setCurrentTab] = useState(1);
    const [createDefinedTaskRequestState, setCreateDefinedTaskRequestState] = useState({
        taskName: "",
        roleType: { "id": null, "displayValue": "" },
        taskDescription: "",
        taskPerson: ""
    });
    const [featureListState, setFeatureListState] = useState([]);
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false });
    const [taskOwnerListState, setTaskOwnerListState] = useState([]);
    const [personListState, setPersonListState] = useState([]);

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === "roleType") {
            if (changedObj.roleType?.id) {
                if (changedObj.roleType?.id === "1") {
                    setCreateDefinedTaskRequestState({ ...createDefinedTaskRequestState, roleType: changedObj['roleType'], ownerShipType: "1" });
                } else {
                    setCreateDefinedTaskRequestState({ ...createDefinedTaskRequestState, roleType: changedObj['roleType'], ownerShipType: "2" });
                }
            } else {
                setCreateDefinedTaskRequestState({ ...createDefinedTaskRequestState, roleType: null, ownerShipType: "" });
                const fields = form.getFieldsValue()
                const formFields = { ...fields };
                formFields["roleType"] = null;
                form.setFieldsValue(formFields);
            }
        } else {
            setCreateDefinedTaskRequestState({ ...createDefinedTaskRequestState, ...changedObj });
        }
    }
    const addFeature = () => {
        const newFeatureList = [...featureListState];
        let requiredFields = {
            'featureName': false,
        }
        let hasError = false;
        let fields = [];

        newFeatureList.map((data) => {
            if (!data.name) {
                requiredFields.featureName = true;
            }
        });
        if (requiredFields.featureName) {
            hasError = true
            fields.push(t('FEATURE_NAME'));
        }
        newFeatureList.push({ "resourceId": "", "name": "" });
        if (!hasError) {
            setFeatureListState(newFeatureList);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleRemoveClick = (index) => {
        const list = [...featureListState];
        if (list.length > 0) {
            list.splice(index, 1);
            setFeatureListState(list);
        } else {
            list[0].name = "";
            setFeatureListState(list);
        }
    }
    const handleFeatureListChange = (e, index) => {
        const list = [...featureListState];
        const val = e.target.value;
        list[index].name = val;
        return setFeatureListState(list);
    }
    function closeDrawerControl() {
        if (currentTab !== 2 && (createDefinedTaskRequestState.taskName || createDefinedTaskRequestState.roleType?.id || createDefinedTaskRequestState.taskPerson || createDefinedTaskRequestState.taskDescription || createDefinedTaskRequestState.taskDescription || (featureListState.length > 0 && featureListState[0]?.name !== ""))) {
            setConfirmModalData({ 'isVisible': true });
        } else {
            destroyLastState();
        }
    }
    function destroyLastState() {
        setCurrentTab(1);
        setCreateDefinedTaskDrawer(false);
        setCreateDefinedTaskRequestState({
            taskName: "",
            roleType: { "id": null, "displayValue": "" },
            taskDescription: "",
            taskPerson: ""
        });
        setFeatureListState([{ "resourceId": "", "name": "" }]);
        form.resetFields();
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateDefinedTaskRequestState({
            taskName: "",
            roleType: { "id": null, "displayValue": "" },
            taskDescription: "",
            taskPerson: ""
        });
        setFeatureListState([{ "resourceId": "", "name": "" }]);
        form.resetFields();
    }

    const [isCreateLoading, setisCreateLoading] = useState(false);

    const handleCreateTaskRequest = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        let hasError = false
        let fields = []
        if (!createDefinedTaskRequestState.taskName) {
            hasError = true
            fields.push(t('TASK_NAME'))
        }
        if (!createDefinedTaskRequestState.roleType?.id) {
            hasError = true
            fields.push(t('TASK_OWNER'))
        }
        if (!createDefinedTaskRequestState.taskPerson && createDefinedTaskRequestState.roleType?.id === "1") {
            hasError = true
            fields.push(t('PERSON_CHOOSE_PERSON'))
        }
        if (featureListState.length > 0 && featureListState.some(item => !item.name)) {
            hasError = true
            fields.push(t('ENTER_FEATURE_NAME'))
        }
        if (!hasError) {
            const requestData = {
                taskName: createDefinedTaskRequestState.taskName,
                ownerShipType: createDefinedTaskRequestState.ownerShipType,
                roleResourceId: createDefinedTaskRequestState.roleType?.id,
                personResourceId: createDefinedTaskRequestState.taskPerson ?? "",
                taskDescription: createDefinedTaskRequestState.taskDescription,
                attributes: featureListState.length > 0 ? featureListState : null,
            }
            createAssignedTasks(requestData);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    useEffect(() => {
        if (createAssignedTasksResponse.status === 'SUCCESS') {
            setCurrentTab(2);
            resetCreateAssignedTasks();
        }
        else if (createAssignedTasksResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': createAssignedTasksResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetCreateAssignedTasks();
        }
    }, [createAssignedTasksResponse])
    useEffect(() => {
        if (roles.length > 0) {
            var list = [];
            roles.map(data => data.resourceId !== "p3PmYkNDeb8tvEs" && list.push({ id: data.resourceId, displayValue: data.roleName }));
            list.push({ id: "1", displayValue: "Kişi Seçimi" });
            setTaskOwnerListState(list)
        }
    }, [roles])
    useEffect(() => {
        var newList = [];
        if (workHourDepartmentInPerson.length > 0) {
            workHourDepartmentInPerson.map(data => newList.push({
                title: data.departmentName,
                value: data.departmentId,
                key: data.departmentId,
                disabled: true,
                children: data.persons.map((datax) => {
                    return {
                        title: datax.givenName + ' ' + datax.familyName,
                        value: datax.resourceId,
                        key: datax.resourceId
                    }
                })
            }));
            setPersonListState(newList);
        }
    }, [workHourDepartmentInPerson])
    useEffect(() => {
        form.setFieldsValue({
            taskName: "",
            roleType: null,
            taskDescription: "",
            taskPerson: ""
        });
    }, [])
    return (
        <>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={destroyLastState}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {currentTab !== 2 ?
                    <>
                        <div className="add-global-draw-title flex-start">
                            <h1>{t('JOB_DESCRIPTION')}</h1>
                        </div>
                        <div className="w-100 pt-4">
                            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                                <Form
                                    form={form}
                                    onValuesChange={handleValuesChange}
                                    layout="vertical travel-scrollable travel-host-container"
                                    requiredMark={true}
                                >
                                    <Form.Item name="taskName" label={t('TASK_NAME') + " *"} >
                                        <Input placeholder={t('ENTER_TASK_NAME')} />
                                    </Form.Item>
                                    <Form.Item name="roleType" className="custom-select" label={t('TASK_OWNER') + " *"} >
                                        <DynamicSelectbox
                                            optionList={taskOwnerListState}
                                            placeholder={t('SELECT_TASK_OWNER')}
                                            onFocus={() => getRoles()}
                                        />
                                    </Form.Item>
                                    {createDefinedTaskRequestState?.roleType?.id === "1" &&
                                        <Form.Item name="taskPerson" className="custom-select" label={t('PERSON_CHOOSE_PERSON') + " *"} >
                                            <TreeSelect
                                                showSearch={true}
                                                treeData={personListState}
                                                onFocus={() => getWorkHourDepartmentInPersons()}
                                                showCheckedStrategy={SHOW_CHILD}
                                                placeholder={t('PARTNER_SELECT')}
                                                treeDefaultExpandAll={true}
                                                filterTreeNode={(search, item) => customFilter(search, item)}
                                            />
                                        </Form.Item>
                                    }
                                    <Form.Item name="taskDescription" className="custom-select" label={t('MISSION_DESCRIPTION')} >
                                        <Input.TextArea placeholder={t('ENTER_TASK_DESCRIPTION')} />
                                    </Form.Item>

                                    <Button onClick={addFeature} className="transparent-btn w-180 f-16 pa-0 ma-0 text-start">{t('ADD_FEATURE')}</Button>
                                    {featureListState.length > 0 &&
                                        <div className="w-100 d-flex pt-4 ant-form-item-label">
                                            <label>{t('FEATURE_NAME')}</label>
                                        </div>
                                    }
                                    {
                                        featureListState.map((item, index) => {
                                            return (
                                                <div key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": '30px' }} >
                                                    <Input
                                                        value={item?.name}
                                                        onChange={(e) => handleFeatureListChange(e, index)}
                                                        placeholder={t('ENTER_FEATURE_NAME')}
                                                    />
                                                    {featureListState.length > 0 && <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>}
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <div className="d-flex flex-end">
                                        <Button className="pad-0 f-16 transparent-btn">{t('PARTNER_ADD')}</Button>
                                    </div> */}
                                </Form>
                            </ConfigProvider>
                        </div>
                    </>
                    :
                    <Successful message={t('TASK_CREATED_SUCCESS')} />
                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 2 ?
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                <div className="add-user-draw-footer-end">
                    {currentTab === 2 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-200 h-40 f-14 font-w-700 white-btn">{t('CREATE_NEW_TASK')}</Button>
                    }
                    <div className="pad-left-24" />
                    {currentTab < 2 ?
                        currentTab === 1 &&
                        <Button onClick={handleCreateTaskRequest} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('PARTNER_SAVE')}</Button>
                        :
                        <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                    }
                </div>

            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        roles: state.roles.roles,
        createAssignedTasksResponse: state.taskReducer.createAssignedTasksResponse,
        workHourDepartmentInPerson: state.organization.workHourDepartmentInPerson,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCreateDefinedTaskDrawer: (props) => { dispatch(setCreateDefinedTaskDrawer(props)) },
        getRoles: () => { dispatch(getRoles()) },
        getWorkHourDepartmentInPersons: () => { dispatch(getWorkHourDepartmentInPersons()) },
        createAssignedTasks: (data) => { dispatch(createAssignedTasks(data)) },
        resetCreateAssignedTasks: () => { dispatch(resetCreateAssignedTasks()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateDefinedTasks);