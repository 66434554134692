import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTimeOffYearsReportFilter, timeOffYearsReportDownload, resetTimeOffYearsReportDownload } from '../../redux/actions/report.actions';
import { getGridSetting, createGridSettings, settingsReset } from '../../redux/actions/setting.actions';

import { Button, Form, ConfigProvider, Spin, Layout } from 'antd';

import moment from 'moment';
import 'moment/locale/tr';

import BreadCrumb from '../../features/breadcrump/breadcrump';
import MaskedInput from 'react-text-mask'
import DynamicTable from '../../component/dynamicTable';
import DynamicSelectbox from '../../component/dynamicSelectbox';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

import { addExtraValue } from '../../utils/commonFormFunctions'

import '../../styles/time-off.scss'

function TimeOffYearsSummary(props) {

    const { t } = useTranslation();
    const { totalNumberOfRecords, getTimeOffYearsReportFilter, settingsReset, getGridSetting, createGridSettings, timeOffYearsReportDownload, resetTimeOffYearsReportDownload } = props
    const { gridSettings, timeOffYearsFilter, timeOffYearsFilterLoading, createGridSettingsResponse, exportReportTimeOffYearsDataResponse } = props
    const [form] = Form.useForm();
    const remainderYearsOption = [
        {
            'id': 1,
            'displayValue': t('ALL')
        },
        {
            'id': 2,
            'displayValue': t('GREATER_THAN')
        },
        {
            'id': 3,
            'displayValue': t('LESS_THAN')
        },
        {
            'id': 4,
            'displayValue': t('EQUALS')
        },
    ]

    const initialGetGridSettingData = {
        pageId: 11, //timeoff 
        gridId: 1, //timeoff request remainder years grid
    }
    const emptyData = {
        "searchTerm": "",
        "persons": [],
        "caseId": "",
        "CaseValue": "",
        "departments": [],
        "showPassiveRecords": true,
    }
    const initialSearchData = {
        ...emptyData, "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "fullName",
            "ascending": true
        }
    }
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData)
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [searchData, setSearchData] = useState(initialSearchData);

    const [pageSize, setPageSize] = useState(10);
    const [filterState, setFilterState] = useState(emptyData);
    const { Content } = Layout;

    const handleSaveGridSettings = (d) => {
        let createObj = { "data": d, "pageId": getGridSettingsData.pageId, "gridId": getGridSettingsData.gridId }
        createGridSettings(createObj)
    }

    useEffect(() => {
        if (createGridSettingsResponse.status === 'SUCCESS') {
            getTimeOffYearsReportFilter(searchData);
            settingsReset();

        }
    }, [createGridSettingsResponse])
    useEffect(() => {
        getGridSetting(getGridSettingsData)
    }, [getGridSettingsData])

    useEffect(() => {
        if (exportReportTimeOffYearsDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': exportReportTimeOffYearsDataResponse?.message ? JSON.parse(exportReportTimeOffYearsDataResponse.message).message : null,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTimeOffYearsReportDownload();
        }
    }, [exportReportTimeOffYearsDataResponse])
    const handleResetAllfilters = () => { }
    const handleTableFilter = (data) => { }

    const columns = [
        {
            title: `${t('PARTNER_NAME_SURNAME')}`,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
            render: obj =>
                <div className="table-center-text">{obj}</div>
        },
        {
            title: `${t('PARTNER_START_DATE_OF_JOB')}`,
            dataIndex: 'workStartDate',
            key: 'workStartDate',
            sorter: true,

        },
        {
            title: `${t('PARTNER_DEPARTMENT')}`,
            dataIndex: 'departmentName',
            key: 'departmentName',
            sorter: true,
        },
        {
            title: `${t('DURATION_OF_ANNUAL_LEAVE')}`,
            dataIndex: 'accrualBalanceYearly',
            key: 'accrualBalanceYearly',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('EXTRA_TIME_OFF')}`,
            dataIndex: 'extraValue',
            key: 'extraValue',
            sorter: true,
            render: obj => {
                return (
                    <div className="imageNameActionColumn">
                        {t('TIME_OFF_REQUEST_DAYS', { "day": obj.count })}
                        <span style={{ paddingLeft: '15px' }} />
                    </div>
                )
            }
        },
        {
            title: `${t('DEFERRED_LEAVE_DURATION')}`,
            dataIndex: 'transferorBalance',
            key: 'transferorBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('TOTAL_DURATION_OF_LEAVE')}`,
            dataIndex: 'sumBalance',
            key: 'sumBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_TIME_LEAVE_USED')}`,
            dataIndex: 'usedBalance',
            key: 'usedBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_REMAINING_TIME')}`,
            dataIndex: 'remainderBalance',
            key: 'remainderBalance',
            sorter: true,
            render: obj => {
                return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj })}`
            }
        },
    ];
    const data = timeOffYearsFilter.map((row) => {
        return (
            {
                "key": row.person.resourceId,
                "fullName": row.person?.displayValue,
                "workStartDate": row.workStartDate ? moment(row.workStartDate).format('LL') : '',
                "departmentName": row.department?.displayValue,
                "accrualBalanceYearly": row.accrualBalanceYearly,
                "extraValue": { 'id': row.person.resourceId, 'count': row.extraValue },
                "transferorBalance": row.transferorBalance,
                "sumBalance": row.sumBalance,
                "usedBalance": row.usedBalance,
                "remainderBalance": row.remainderBalance
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: ['fullName', 'workStartDate', 'departmentName', 'accrualBalanceYearly', 'extraValue', 'transferorBalance', 'sumBalance', 'usedBalance', 'remainderBalance'],
        visibilityOptionExcludes: ['fullName', 'workStartDate', 'departmentName', 'accrualBalanceYearly', 'extraValue', 'transferorBalance', 'sumBalance', 'usedBalance', 'remainderBalance'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "TimeOffYearsSummary",
        leftActionMenu: null,

    })

    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: totalNumberOfRecords > 10 ? true : false,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'bottomLeft'] : ['none', 'bottomLeft'],
        showTotal: total => t('TOTAL_RECORDS_FOUND', {total : total})
    })

    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }

    const handleTableChange = (data) => {

        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : searchData.pagination.orderBy) }

        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }

    const handleShowPassivesToogle = (e) => {
    }
    useEffect(() => {
        if (searchData) {
            getTimeOffYearsReportFilter(searchData)
        }
    }, [searchData]);


    const handleValuesChange = (changedObj, all) => {
        setFilterState({ ...filterState, ...changedObj });
    }
    const downloadFile = (type) => {
        timeOffYearsReportDownload({ ...searchData, 'exportType': type })
    }
    const searchAction = () => {
        const caseValue = filterState?.caseValue.replace(',', '.');
        const filterData = {
            'caseId': filterState?.case?.id ? parseInt(filterState?.case?.id) : null,
            'caseValue': parseFloat(caseValue),
            'persons': [],
            'departments': [],
        }
        setSearchData({
            ...filterData, "pagination": initialSearchData.pagination,
        });
    }
    const resetFilter = () => {
        form.resetFields();
        setFilterState(emptyData)
        setSearchData({
            ...emptyData, "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "fullName",
                "ascending": true
            }
        });
    }
    const isChanged = () => {
        if ((searchData.case?.id !== "" && searchData.case?.id) || (searchData.caseValue !== "" && searchData.caseValue)) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <Layout>
            <Content className="page-container report-container">
                <BreadCrumb />
                <div className="tab-menu">
                    <h1 className="page-title">{t('REMAINING_DURATION')}</h1>
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <div className="w-100 flex-end pad-12">
                            <Button onClick={() => downloadFile(0)} className="w-120 white-btn margin-r-12">Excel</Button>
                            <Button onClick={() => downloadFile(2)} className="w-120 white-btn">PDF</Button>
                        </div>
                        <div className="filter-container table-details">
                            {timeOffYearsFilterLoading ?
                                <Spin className="filter-loading" ></Spin> :
                                <>
                                    <Form
                                        form={form}
                                        onValuesChange={handleValuesChange}
                                        requiredMark={true}
                                        layout="vertical"
                                    >
                                        <Form.Item name="case" className="custom-select" label={t('TIME_OFF_REQUEST_REMAINING_TIME')}>
                                            <DynamicSelectbox
                                                optionList={remainderYearsOption}
                                                placeholder={t('TIME_OFF_REQUEST_REMAINING_TIME')}
                                                orderBy={'none'}
                                            />
                                        </Form.Item>
                                        <Form.Item name="caseValue" label={t('PARTNER_DAY')} >
                                            <MaskedInput className="ant-input" mask={addExtraValue} />

                                        </Form.Item>
                                    </Form>
                                    <div className="filter-button">
                                        <Button onClick={searchAction} className="w-120 primary-btn margin-r-12">{t('SEARCH')}</Button>
                                        {isChanged() &&
                                            <Button onClick={resetFilter} className="w-120 white-btn margin-r-12">{t('PARTNER_CLEAR')}</Button>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </ConfigProvider>
                    <div className="table-container">
                        <DynamicTable
                            columns={columns} dataSource={data}
                            tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                            onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
                            searchPlaceholder=""
                            onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                            onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                            searchBox={false}
                            checkboxStatus={false}
                            passiveButton={false}
                            loadStatus={timeOffYearsFilterLoading}
                        />
                    </div>
                </div>
            </Content>
        </Layout >
    )
}

const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        timeOffYearsFilter: state.report.timeOffYearsFilter?.results,
        timeOffYearsFilterLoading: state.report.timeOffYearsFilterLoading,
        totalNumberOfRecords: state.report.timeOffYearsFilter.totalNumberOfRecords,
        profile: state.profile,
        createGridSettingsResponse: state.setting.createGridSettingsResponse,
        exportReportTimeOffYearsDataResponse: state.report.exportReportTimeOffYearsDataResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffYearsReportFilter: (searchData) => { dispatch(getTimeOffYearsReportFilter(searchData)) },
        getGridSetting: (props) => { dispatch(getGridSetting(props)) },
        createGridSettings: (props) => { dispatch(createGridSettings(props)) },
        settingsReset: () => { dispatch(settingsReset()) },
        timeOffYearsReportDownload: (data) => { dispatch(timeOffYearsReportDownload(data)) },
        resetTimeOffYearsReportDownload: () => { dispatch(resetTimeOffYearsReportDownload()) }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeOffYearsSummary);
