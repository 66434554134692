import { TravelActionType } from "../actions/travel.actions";

const initialState = {
  travelRequestDrawer: false,
  travelDetailDrawer: false,
  createTravelRequests: { status: "loading", message: "", data: "" },
  myTravelRequestList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  myTravelRequestListLoading: true,
  travelApprovalRequestList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  travelApprovalRequestListLoading: true,
  allTravelRequestsList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  allTravelRequestsListLoading: true,
  personTravel: [],
  personTravelLoading: true,
  travelChangeStatusResponse: { status: "loading", message: "" },
  travelApproveOnMailResponse: { status: "loading", message: "" },
  travelApproveOnMailResponseLoading: true,
  travelDetail: {
    travelDescription: null,
    departureDate: null,
    returnDate: null,
    countryIdx: null,
    cityIdx: null,
    estimatedAmount: null,
    notes: null,
    traveledPersons: null,
    stateTypeSetIdx: null,
    personIdx: null,
    estimatedTotal: null,
    cityName: null,
    countryName: null,
    organizationIdx: null,
    processIdx: null,
    estimatedAmounts: [],
    traveledPersonsModels: [],
  },
  travelDetailLoading: true,
  travelDetailStep: [],
  travelDetailStepLoading: true,
  updateTravelResponse: { status: "loading", message: "" },
  travelDeleteStatusResponse: { status: "loading", message: "" },
  travelExportData: {},
  travelExportDataResponse: { status: "loading", message: "" },
  travelApprovalsExportData: {},
  travelApprovalsExportDataResponse: { status: "loading", message: "" },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TravelActionType.SET_TRAVEL_REQUEST_CREATE_DRAWER:
      return {
        ...state,
        travelRequestDrawer: action.payload,
      };
    case TravelActionType.SET_TRAVEL_REQUEST_DETAILS_DRAWER:
      return {
        ...state,
        travelDetailDrawer: action.payload,
      };
    case TravelActionType.CREATE_TRAVEL_SUCCESS:
      return {
        ...state,
        createTravelRequests: {
          status: "SUCCESS",
          message: action.payload.message,
          data: action.payload.data.resourceId,
        },
      };
    case TravelActionType.CREATE_TRAVEL_FAIL:
      return {
        ...state,
        createTravelRequests: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TravelActionType.CREATE_TRAVEL_RESET:
      return {
        ...state,
        createTravelRequests: { status: "loading", message: "" },
      };
    case TravelActionType.MY_TRAVEL_LIST_REQUESTS:
      return {
        ...state,
        myTravelRequestListLoading: true,
      };
    case TravelActionType.MY_TRAVEL_LIST_SUCCESS:
      return {
        ...state,
        myTravelRequestList: action.payload,
        myTravelRequestListLoading: false,
      };
    case TravelActionType.MY_TRAVEL_LIST_FAIL:
      return {
        ...state,
        myTravelRequestListLoading: false,
      };
    case TravelActionType.TRAVEL_APPROVAL_LIST_REQUESTS:
      return {
        ...state,
        travelApprovalRequestListLoading: true,
      };
    case TravelActionType.TRAVEL_APPROVAL_LIST_SUCCESS:
      return {
        ...state,
        travelApprovalRequestList: action.payload,
        travelApprovalRequestListLoading: false,
      };
    case TravelActionType.TRAVEL_APPROVAL_LIST_FAIL:
      return {
        ...state,
        travelApprovalRequestListLoading: false,
      };
    case TravelActionType.ALL_TRAVEL_REQUESTS_REQUESTS:
      return {
        ...state,
        allTravelRequestsListLoading: true,
      };
    case TravelActionType.ALL_TRAVEL_REQUESTS_SUCCESS:
      return {
        ...state,
        allTravelRequestsList: action.payload,
        allTravelRequestsListLoading: false,
      };
    case TravelActionType.ALL_TRAVEL_REQUESTS_FAIL:
      return {
        ...state,
        allTravelRequestsListLoading: false,
      };
    case TravelActionType.PERSON_TRAVEL_LIST_REQUEST:
      return {
        ...state,
        personTravelLoading: true,
      };
    case TravelActionType.PERSON_TRAVEL_LIST_SUCCESS:
      return {
        ...state,
        personTravel: action.payload,
        personTravelLoading: false,
      };
    case TravelActionType.PERSON_TRAVEL_LIST_FAIL:
      return {
        ...state,
        personTravelLoading: false,
      };
    case TravelActionType.TRAVEL_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        travelChangeStatusResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TravelActionType.TRAVEL_CHANGE_STATUS_FAIL:
      return {
        ...state,
        travelChangeStatusResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TravelActionType.TRAVEL_CHANGE_STATUS_RESET:
      return {
        ...state,
        travelChangeStatusResponse: { status: "loading", message: "" },
      };
    case TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_REQUEST:
      return {
        ...state,
        travelApproveOnMailResponse: {
          status: "LOADING",
          message: "",
        },
        travelApproveOnMailResponseLoading: true,
      };
    case TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_SUCCESS:
      return {
        ...state,
        travelApproveOnMailResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
        travelApproveOnMailResponseLoading: false,
      };
    case TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_FAIL:
      return {
        ...state,
        travelApproveOnMailResponse: {
          status: "FAIL",
          message: action.payload,
        },
        travelApproveOnMailResponseLoading: false,
      };
    case TravelActionType.GET_TRAVEL_DETAIL_REQUEST:
      return {
        ...state,
        travelDetailLoading: true,
      };
    case TravelActionType.GET_TRAVEL_DETAIL_SUCCESS:
      return {
        ...state,
        travelDetail: action.payload,
        travelDetailLoading: false,
      };
    case TravelActionType.GET_TRAVEL_DETAIL_FAIL:
      return {
        ...state,
        travelDetailLoading: false,
      };
    case TravelActionType.UPDATE_TRAVEL_DETAIL_SUCCESS:
      return {
        ...state,
        updateTravelResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TravelActionType.UPDATE_TRAVEL_DETAIL_FAIL:
      return {
        ...state,
        updateTravelResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TravelActionType.UPDATE_TRAVEL_DETAIL_RESET:
      return {
        ...state,
        updateTravelResponse: { status: "loading", message: "" },
      };
    case TravelActionType.GET_TRAVEL_DETAIL_STEPS_REQUEST:
      return {
        ...state,
        travelDetailStepLoading: true,
      };
    case TravelActionType.GET_TRAVEL_DETAIL_STEPS_SUCCESS:
      return {
        ...state,
        travelDetailStep: action.payload,
        travelDetailStepLoading: false,
      };
    case TravelActionType.GET_TRAVEL_DETAIL_STEPS_FAIL:
      return {
        ...state,
        travelDetailStepLoading: false,
      };
    case TravelActionType.DELETE_TRAVEL_REQUEST_SUCCESS:
      return {
        ...state,
        travelDeleteStatusResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TravelActionType.DELETE_TRAVEL_REQUEST_FAIL:
      return {
        ...state,
        travelDeleteStatusResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TravelActionType.DELETE_TRAVEL_REQUEST_RESET:
      return {
        ...state,
        travelDeleteStatusResponse: { status: "loading", message: "" },
      };
    case TravelActionType.TRAVEL_TABLE_EXPORT_SUCCESS:
      return {
        ...state,
        travelExportDataResponse: action.payload,
      };
    case TravelActionType.TRAVEL_TABLE_EXPORT_FAIL:
      return {
        ...state,
        travelExportData: {},
        travelExportDataResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TravelActionType.TRAVEL_TABLE_EXPORT_RESET:
      return {
        ...state,
        travelExportData: {},
        travelExportDataResponse: { status: "loading", message: "" },
      };
    case TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_SUCCESS:
      return {
        ...state,
        travelApprovalsExportDataResponse: action.payload,
      };
    case TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_FAIL:
      return {
        ...state,
        travelApprovalsExportData: {},
        travelApprovalsExportDataResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_RESET:
      return {
        ...state,
        travelApprovalsExportData: {},
        travelApprovalsExportDataResponse: { status: "loading", message: "" },
      };
    default:
      return state;
  }
}
