import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPermissions } from "../redux/actions/profile.actions"
import { resetDeletePersonControl } from "../redux/actions/person.actions";
import { timeoffListPermissions } from "../utils/timeoffPermissions";
import { setCurrentTaskState, setUserTaskInfo } from "../redux/actions/onboarding.actions";

import { Menu, Button, Popover } from 'antd';
import { Link, useHistory } from "react-router-dom";
import {
    DoubleLeftOutlined, DoubleRightOutlined
} from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';

import PopoverContent from '../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../component/onBoarding/OnboardingBackdropHook';

function NavMenu(props) {
    const { SubMenu } = Menu;
    const history = useHistory();
    const { t } = useTranslation();
    const menuList = [];
    const getMenuIcon = {
        'UilEstate': <Unicons.UilEstate />,
        'UilChartPieAlt': <Unicons.UilChartPieAlt />,
        'UilUserSquare': <Unicons.UilUserSquare />,
        'UilShoppingBag': <Unicons.UilShoppingBag />,
        'UilRss': <Unicons.UilRss />,
        'UilUsersAlt': <Unicons.UilUsersAlt />,
        'UilFileCheckAlt': <Unicons.UilFileCheckAlt />,
        'UilInvoice': <Unicons.UilInvoice />,
        'UilWallet': <Unicons.UilWallet />,
        'UilCog': <Unicons.UilCog />,
        'UilQuestionCircle': <Unicons.UilQuestionCircle />,
        'UilPlaneDeparture': <Unicons.UilPlaneDeparture />,
        'UilGameStructure': <Unicons.UilGameStructure />,
        'UilUniversity': <Unicons.UilUniversity />,
        'UilPlaneFly': <Unicons.UilPlaneFly />,
        'UilChatBubbleUser': <Unicons.UilChatBubbleUser />,
        'UilHome': <Unicons.UilHome />,
        'UilCell': <Unicons.UilCell />,
        'UilSpinnerAlt': <Unicons.UilSpinnerAlt className="commingsoon-icon" width="16" height="16" />,
        'UilPresentationPlay': <Unicons.UilPresentationPlay />
    }
    const { getPermissions, permissionList, resetDeletePersonControl, collapsed, toggleMenu, user } = props;
    var currentPath = history.location.pathname;
    const [selectedKey, setSelectedKey] = useState(currentPath);
    const rootSubmenuKeys = menuList.filter((menuData) => { return menuData.submenu.length !== 0 }).map(item => 'menu_' + item.id)
    const [openKeys, setOpenKeys] = React.useState([]);
    const [permissions, setPermissions ] = React.useState(permissionList)
    history.listen((location) => {
        setSelectedKey(location.pathname)
    });

    useEffect(()=>{
        setPermissions(permissionList)
    },[JSON.stringify(permissionList)])

    const hasAnyPermissions = permissions.filter((permission) => timeoffListPermissions.includes(permission)).length > 0;
    let timeOffList = [];
    let taskList = [];
    let educationList = [];
    if (hasAnyPermissions) {
        menuList.push({
            "id": 1,
            "name": t('HOMEPAGE'),
            "link": "/homepage",
            "icon": "UilEstate",
            "submenu": [],
            "isPublished": true
        });
        menuList.push({
            "id": 3,
            "name": t('HEADER_INFORMATION'),
            "link": "/profile",
            "icon": "UilUserSquare",
            "submenu": [],
            "isPublished": true
        });
        if(parseInt(user?.organizationPackageId) > 0 && permissions.includes('payment')){
            menuList.push({
                "id": 18,
                "name": t('SUBSCRIPTION'),
                "link": "/subscription",
                "icon": "UilShoppingBag",
                "submenu": [],
                "isPublished": true
            });
        }
        timeOffList.push(
            {
                "id": 1,
                "name": t('TIME_OFF_REQUEST_MY'),
                "link": "/time-off/me",
                "isPublished": true
            }
        );
        timeOffList.push(
            {
                "id": 2,
                "name": t('TIME_OFF_REQUEST_PERSON'),
                "link": "/time-off/person",
                "isPublished": true
            }
        );
        if (permissions.includes('timeoff.leave.list')) {
            timeOffList.push(
                {
                    "id": 3,
                    "name": t('TIME_OFF_REQUEST_PEOPLE_ON_LEAVE'),
                    "link": "/time-off/people",
                    "isPublished": true
                }
            );
        }
        if (permissions.includes('timeoff.remaining.list')) {
            timeOffList.push(
                {
                    "id": 4,
                    "name": t('REMAINING_DURATION'),
                    "link": "/time-off/remainder-years",
                    "isPublished": true
                }
            );
        }
        if (permissions.includes('timeoff.request.all.list')) {
            timeOffList.push(
                {
                    "id": 5,
                    "name": t('ALL_TIME_OFF_REQUEST'),
                    "link": "/time-off/all",
                    "isPublished": true
                }
            );
        }
        if (timeOffList.length > 0) {
            menuList.push(
                {
                    "id": 14,
                    "name": t('SETTINGS_TIME_PROCEDURES'),
                    "link": "#",
                    "icon": "UilPlaneDeparture",
                    "submenu": timeOffList,
                    "isPublished": true
                }
            );
        };
    }
    if (permissions.includes('person.list')) {
        menuList.push(
            {
                "id": 6,
                "name": t('PEOPLE'),
                "link": "/person",
                "icon": "UilUsersAlt",
                "submenu": [],
                "isPublished": true
            }
        );
    }
    if (permissions.includes('department.list')) {
        menuList.push(
            {
                "id": 7,
                "name": t('DEPARTMENTS'),
                "link": "/department",
                "icon": "UilUniversity",
                "submenu": [],
                "isPublished": true
            }
        );
    }
    if (permissions.includes('organization.list')) {
        menuList.push(
            {
                "id": 5,
                "name": t('MY_ORGANIZATION'),
                "link": "/organization",
                "icon": "UilGameStructure",
                "submenu": [],
                "isPublished": true
            }
        );
    }
    let inventorySubMenuList = [];
    let advanceExpenseSubmenuList = [];
    let reportSubMenuList = [];
    if (permissions.includes('inventory.list')) {
        inventorySubMenuList.push(
            {
                "id": 1,
                "name": t('DEVICES'),
                "link": "/inventory",
                "isPublished": true
            }
        );
    }
    if (permissions.includes('inventory.type.list')) {
        inventorySubMenuList.push(
            {
                "id": 2,
                "name": t('TYPES_OF_DEVICE'),
                "link": "/inventory-type",
                "isPublished": true
            }
        );
    }
    if (permissions.includes('asset.list')) {
        inventorySubMenuList.push(
            {
                "id": 3,
                "name": t('EMBEZZLEMENT'),
                "link": "/ownership-info",
                "isPublished": true
            }
        );
    }
    advanceExpenseSubmenuList.push(
        {
            "id": 5,
            "name": t('ADVANCE_PROCEDURES_HEADER'),
            "link": "/advance",
            "isPublished": true
        },
    );
    advanceExpenseSubmenuList.push(
        {
            "id": 6,
            "name": t('PARTNER_REQUEST_EXPENSE'),
            "link": "/expense",
            "submenu": [],
            "isPublished": true
        },
    );
    if (inventorySubMenuList.length > 0) {
        menuList.push(
            {
                "id": 17,
                "name": t('DEVICE_AND_OWNERSHIP'),
                "link": "#",
                "icon": "UilFileCheckAlt",
                "submenu": inventorySubMenuList,
                "isPublished": false
            }
        );
    };
    menuList.push(
        {
            "id": 2,
            "name": t('TRAVEL_TRANSACTIONS'),
            "link": "/travel-requests",
            "icon": "UilPlaneFly",
            "submenu": [],
            "isPublished": true
        }
    );
    if (advanceExpenseSubmenuList.length > 0) {
        menuList.push(
            {
                "id": 9,
                "name": t('ADVANCE_AND_EXPENDITURE'),
                "link": "#",
                "icon": "UilInvoice",
                "submenu": advanceExpenseSubmenuList,
                "isPublished": false
            }
        );
    }
    reportSubMenuList.push(
        {
            "id": 1,
            "name": t('SUMMARY_REPORT'),
            "link": "/reports",
            "icon": "UilChatBubbleUser",
            "submenu": [],
            "isPublished": true
        }

    );
    if (permissions.includes('organization.workhour.list')) {
        reportSubMenuList.push(
            {
                "id": 6,
                "name": t('EMPLOYEE_SUMMARY'),
                "link": "/employee-summary",
                "submenu": [],
                "isPublished": true
            },
        );
        reportSubMenuList.push(
            {
                "id": 7,
                "name": t('REMAINING_DURATION'),
                "link": "/time-off-years-summary",
                "submenu": [],
                "isPublished": true
            },
        );
        reportSubMenuList.push(
            {
                "id": 8,
                "name": t('ALL_PERMISSIONS_REPORT'),
                "link": "/time-off-all-summary",
                "submenu": [],
                "isPublished": true
            },
        );
    }
    if (permissions.includes('travelhostrequest.list.all')) {
        menuList.push(
            {
                "id": 3,
                "name": t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST'),
                "link": "/travel-host-request",
                "icon": "UilHome",
                "submenu": [],
                "isPublished": true
            }
        );
    }
    if (permissions.some(item => ["education.list", "education.add", "education.delete", "education.update"].includes(item))) {
        menuList.push(
            {
                "id": 16,
                "name": t('TRAINING_OPERATIONS'),
                "link": "#",
                "icon": "UilPresentationPlay",
                "submenu": educationList,
                "isPublished": true
            }
        );
    }
    if (reportSubMenuList.length > 0) {
        menuList.push(
            {
                "id": 4,
                "name": t('REPORTS'),
                "link": "#",
                "icon": "UilChartPieAlt",
                "submenu": reportSubMenuList,
                "isPublished": false
            }
        );
    }
    if (permissions.includes('task.list')) {
        taskList.push(
            {
                "id": 1,
                "name": t('DEFINED_TASKS'),
                "link": "/tasks/description",
                "isPublished": true
            }
        );
    }
    taskList.push(
        {
            "id": 2,
            "name": t('MY_TASKS'),
            "link": "/tasks/me",
            "isPublished": true
        }
    );
    if (permissions.includes('task.assignedlist')) {
        taskList.push(
            {
                "id": 3,
                "name": t('ASSIGNED_TASKS'),
                "link": "/tasks/assigned",
                "isPublished": true
            }
        );
    }
    if (taskList.length > 0) {
            if (permissions.some(item => ["task.list", "task.create", "task.update", "task.delete", "task.assignedlist"].includes(item))) {
            menuList.push(
                {
                    "id": 15,
                    "name": t('TASK_ASSIGNMENT_PROCEDURES'),
                    "link": "#",
                    "icon": "UilCell",
                    "submenu": taskList,
                    "isPublished": true
                }
            );
        };
    }
    if (permissions.some(item => ["setting", "workhour.list", "jobtitle.list", "tag.list"].includes(item))) {
        menuList.push(
            {
                "id": 8,
                "name": t('SETTINGS'),
                "link": "/settings",
                "icon": "UilCog",
                "submenu": [],
                "isPublished": true
            }
        );
    }
   
    if (permissions.some(item => ["education.add", "education.delete", "education.update"].includes(item))) {
        educationList.push(
            {
                "id": 1,
                "name": t('TRAINING_LIST'),
                "link": "/education/education-list",
                "isPublished": true
            }
        );
    }

    educationList.push(
        {
            "id": 2,
            "name": t('MY_TRAININGS'),
            "link": "/education/me",
            "isPublished": true
        }
    );

    const getComingsoonIcon = (menuData) => {
        return menuData.isPublished ? '' : <Unicons.UilSpinnerAlt className="commingsoon-icon" width="16" height="16" />
    }
    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys([latestOpenKey]);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const handleMenuClick = () => {
        getPermissions()
        resetDeletePersonControl()
    }

    useEffect(() => {
        const currentOpen = menuList.filter((menuData) => {
            return menuData.submenu.filter(menu => { return menu.link === currentPath }).length > 0
        }).map(item => 'menu_' + item.id)
        setOpenKeys(currentOpen)
    }, []);


        // #region OnBoarding
        const [popoverMenuID, setpopoverMenuID] = useState(null);
        const [popoverVisible, setPopoverVisible] = useState(false);
        const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
        const handlePopoverVisibleChange = (visible) => {
            if(visible == false){ props.setCurrentTaskState(null); }
            if(props.currentTaskState !== null){
                setPopoverVisible(visible);
            } 
        };
        
        OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'nav-menu' );

        useEffect(() => { 

            setOpenKeys([]);

            if(props.currentTaskState == 1000 || props.currentTaskState == 11){  // Department
                if(props.currentTaskState == 11){ 
                    history.push('/department');
                    return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(7);}, 50);
                }
                setPopoverVisible(true); 
                setpopoverMenuID(7);
            }
            
            if(props.currentTaskState == 1001){ setPopoverVisible(true); setpopoverMenuID(5);}

            if(props.currentTaskState == 1002 || // Çalışan
                props.currentTaskState == 20 || props.currentTaskState == 30 || props.currentTaskState == 60 || props.currentTaskState == 80){  
                if(props.currentTaskState == 20 || props.currentTaskState == 30 || props.currentTaskState == 60 || props.currentTaskState == 80){ 
                    history.push('/person');
                    return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(6);}, 50);
                }
                setPopoverVisible(true); 
                setpopoverMenuID(6);
            }
            if(props.currentTaskState == 40){  // Ayarlar
                history.push('/settings');
                const sideBar = document.querySelector(".ant-layout-sider.ant-layout-sider-dark");
                if (sideBar) {
                    sideBar.scrollTop = sideBar.scrollHeight;
                }
                return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(8);}, 50);
            }
            if(props.currentTaskState == 50){  // Ayarlar
                 history.push('/settings');
                 const sideBar = document.querySelector(".ant-layout-sider.ant-layout-sider-dark");
                 if (sideBar) {
                     sideBar.scrollTop = sideBar.scrollHeight;
                 }
                return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(8);}, 50);
            }
            if(props.currentTaskState == 70){  // Envanter
                history.push('/inventory');
                setOpenKeys(['menu_17'])
               return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(17);}, 50);
            }
            if(props.currentTaskState == 90){  // Envanter
                history.push('/inventory');
                setOpenKeys(['menu_17'])
               return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(17);}, 50);
            }
            if(props.currentTaskState == 100){  // Harcama İşlemleri
                history.push('/expense');
                setOpenKeys(['menu_9'])
               return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(9);}, 50);
            }
            if(props.currentTaskState == 113){  // Abonelikler
                history.push('/subscription');
               return setTimeout(() => { setPopoverVisible(true);setpopoverMenuID(18);}, 50);
            }


        }, [props.currentTaskState]); 
                  
        // #endregion

        

    return (
        <div className='left-menu'>
            <div className={collapsed ? "collapse-btn-pad center" : "collapse-btn-pad right"} >
                <Button className="transparent-btn" onClick={toggleMenu}>
                    {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                </Button>
            </div>
            <Menu
                // defaultSelectedKeys={["/homepage"]}
                selectedKeys={[selectedKey]}
                mode="inline"
                theme="dark"
                inlineIndent={48}
                openKeys={openKeys} onOpenChange={onOpenChange}
            >
                {
                    menuList.map((menuData) => {
                    
                        if (menuData.submenu.length === 0) {
                            const menuKey = menuData.link;
                            return (
                                (menuData.id === popoverMenuID && popoverVisible) ? (
                                    <Menu.Item className="onboarding-nav-item" key={menuKey} icon={getMenuIcon[menuData.icon]}>
                                        <Popover
                                            key={'nav-menu' + menuKey}
                                            overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : 'onboard-nav-popover-wrapper'}
                                            content={<PopoverContent key={menuKey} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                            title={false}
                                            placement={(props.currentTaskState == 40 || props.currentTaskState == 50) ? 'rightTop' : 'right'}
                                            trigger="click"
                                            visible={menuData.id === popoverMenuID && popoverVisible}
                                            onVisibleChange={handlePopoverVisibleChange}
                                        >
                                            <Link to={menuData.link} onClick={handleMenuClick}>
                                                {menuData.name} {getComingsoonIcon(menuData)}
                                            </Link>
                                        </Popover>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key={menuKey} icon={getMenuIcon[menuData.icon]}>
                                        <Link to={menuData.link} onClick={handleMenuClick}>
                                            {menuData.name} {getComingsoonIcon(menuData)}
                                        </Link>
                                    </Menu.Item>
                                )
                            );
                        } else {
                            return (<SubMenu key={"menu_" + menuData.id} icon={getMenuIcon[menuData.icon]} title={menuData.name}>
                                {menuData.submenu.map((subMenu,index) => {
                                    var submenuKey = subMenu.link;
                                    var submenuTitle = subMenu.name;
                                    return (
                                        ((menuData.id === popoverMenuID && index == (menuData.id == 9 ? 1 : 0)) && popoverVisible) ? (
                                            <Menu.Item className={menuData.id == 9 && index == 0 ? '' : "onboarding-nav-item" }key={submenuKey}>
                                                <Popover
                                                    key={'nav-menu' + submenuKey}
                                                    overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : 'onboard-nav-popover-wrapper'}
                                                    content={<PopoverContent key={submenuKey} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                                    title={false}
                                                    placement="right"
                                                    trigger="click"
                                                    visible={(menuData.id === popoverMenuID && index == (menuData.id == 9 ? 1 : 0)) && popoverVisible}
                                                    onVisibleChange={handlePopoverVisibleChange}
                                                >
                                                    <Link to={subMenu.link} onClick={handleMenuClick}>
                                                        {submenuTitle} {getComingsoonIcon(subMenu)}
                                                    </Link>
                                                </Popover>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item key={submenuKey}>
                                                <Link to={subMenu.link} onClick={handleMenuClick}>
                                                        {submenuTitle} {getComingsoonIcon(subMenu)}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    )
                                })}
                            </SubMenu>)
                        }
                    })
                }
            </Menu>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        navMenu: state.navMenu,
        permissionList: state.profile.permissions,
        user: state.auth?.user?.profile,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPermissions: () => { dispatch(getPermissions()) },
        resetDeletePersonControl: () => { dispatch(resetDeletePersonControl()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavMenu)