import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, ConfigProvider, TreeSelect, Switch, Row, Col, Tag } from 'antd';



import { getTagList } from '../../../../redux/actions/tag.actions';
import { getWorkHourDepartmentInPersons } from '../../../../redux/actions/organization.actions';


import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/timeoffTypes.scss';

function AddTimeoffTypeAppliedPersons(props) {
    const { t } = useTranslation();

    const [form] = Form.useForm()
    const { getWorkHourDepartmentInPersons, workHourDepartmentInPerson, workHourDepartmentInPersonLoading, getTagList, dataset, tagList, setCreateTimeoffTypeObject, createTimeoffTypeObject } = props;


    const [personTreeData, setPersonTreeData] = useState([])
    const [tagListState, setTagListState] = useState([]);
    const { SHOW_CHILD } = TreeSelect;


    useEffect(() => {
        const tempArr = tagList.map((data) => {
            return {
                'id': data.id,
                'displayValue': data.name
            }
        })
        setTagListState(tempArr);
    }, [tagList]);

    useEffect(() => {
        if (!workHourDepartmentInPersonLoading) {
            var list = [];
            workHourDepartmentInPerson.map((data) => {
                return list.push({
                    'title': data.departmentName,
                    'value': data.departmentId,
                    'key': data.departmentId,
                    'children': data.persons.map((datax) => {
                        return {
                            'title': datax.givenName + ' ' + datax.familyName,
                            'value': datax.resourceId,
                            'key': datax.resourceId,
                        }
                    })
                })
            })
            setPersonTreeData(list)
        }
    }, [workHourDepartmentInPerson])

    function splitObjectIntoTwoArrays(obj) {
        const keys = Object.keys(obj).filter(value => 
            !['unitSetIdx','accrualUnitSetIdx','accrualDurationTypeSetIdx','isActive','accrualDuration',
            'genderTypeSetIdx','isRequiredContactInfo'].includes(value)
             &&  (createTimeoffTypeObject[value] != null && createTimeoffTypeObject[value] != false));               // Nesnenin anahtarlarını al
        
        const middleIndex = Math.ceil(keys.length / 2); // Ortalama indexi hesapla
        const firstHalfKeys = keys.slice(0, middleIndex); // İlk yarının anahtarlarını al
        const secondHalfKeys = keys.slice(middleIndex);  // İkinci yarının anahtarlarını al
    
        const firstHalfArray = [];  // İlk yarı için boş dizi
        const secondHalfArray = []; // İkinci yarı için boş dizi
    
        // İlk yarı için anahtar-değer çiftlerini diziye ekle
        firstHalfKeys.forEach(key => {
            firstHalfArray.push({ key: key, value: obj[key] });
        });
    
        // İkinci yarı için anahtar-değer çiftlerini diziye ekle
        secondHalfKeys.forEach(key => {
            secondHalfArray.push({ key: key, value: obj[key] });
        });
    
        return [firstHalfArray, secondHalfArray]; // İki dizi olarak döndür
    }

    const [firstHalfArray, secondHalfArray] = splitObjectIntoTwoArrays(createTimeoffTypeObject);


    const handleValuesChange = (changedObj, all) => {

        setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, ...changedObj });
        
    }

    useEffect(() => {
        getWorkHourDepartmentInPersons();
    }, [])


    const getValue = (k,v) =>{
        if(k == 'paymentSetIdx'){
            const value =  dataset.paymentTypes.filter( item => item.id == v)[0].displayValue;
            return value
        }else if(k == 'isBalanceUnlimited'){
            if(v==1){
                return t('TIMEOFF_LIMITLESS')
            }else{
                return t('TIMEOFF_LIMITED')
            }
        }else if(k == 'accrualDateSetIdx'){
            if(v==1){
                return t('TIMEOFF_STARTING_TIME_OPTION_1')
            }else if(v==3){
                const accrualDurationTypeSet = dataset.accrualDurationTypes?.filter( item => item.id == createTimeoffTypeObject?.accrualDurationTypeSetIdx)[0]?.displayValue;
                return t("TIMEOFF_START_TIME_FROM")+" "+createTimeoffTypeObject?.accrualDuration+" "+ accrualDurationTypeSet +" "+t("TIMEOFF_START_TIME_AFTER")
            }
        }else if(k == 'maxBalance'){
            const accrualUnitType = dataset.accrualUnitTypes?.filter( item => item.id == createTimeoffTypeObject?.accrualUnitSetIdx)[0]?.displayValue;
            const unitSet = dataset.unitTypes?.filter( item => item.id == createTimeoffTypeObject?.unitSetIdx)[0]?.displayValue;
            return accrualUnitType +" "+v+" "+unitSet+" "+t('TIMEOFF_BALANCE_LIMIT_POSTFIX')
        }else if(k == 'isTransferable'){
            return t('TIMEOFF_TRANSFER_DESC')
        }else if(k == 'isExceedable'){
            return t('TIMEOFF_TIMEOUT_DESC')
        }else if(k == 'maxExceedBalance'){
            return v+" "+t('PARTNER_DAY')
        }else if(k == 'isExceedableInform'){
            return t('TIMEOFF_TIMEOUT_INFO_DESC')
        }else if(k == 'isGenderType'){
            return t('TIMEOFF_ACCORDING_GENDER_DESC_SUMMARY',{'gender':createTimeoffTypeObject?.genderTypeSetIdx == 1 ? t('PARTNER_MAN') : t('PARTNER_WOMAN')})
        }else if(k == 'isRenewalableBalance'){
            return t('TIMEOFF_RENEWAL_DESC')
        }else if(k == 'isRequiredDocument'){
            return t('TIMEOFF_REQUIRED_DOC_UPLOAD_DESC')
        }else if(k == 'isAppearContactInfo'){
            const isRequired = createTimeoffTypeObject?.isRequiredContactInfo == 1 ? t('TIMEOFF_CONTACT_INFO_OPT_REQUIRED') : t('TIMEOFF_CONTACT_INFO_OPT_NOT_REQUIRED')
            return t('TIMEOFF_CONTACT_INFO_DESC') +" ("+isRequired+")"
        }else if(k == 'isRequiredDescription'){
            return t('TIMEOFF_REQUIRED_DESCRIPTION_DESC')
        }else if(k == 'personList'){
            const allPersons = workHourDepartmentInPerson?.flatMap(department => department.persons);
            const names = v.map(id => {
                const person = allPersons.find(person => person.id === id);
                return person ? `${person.givenName} ${person.familyName}` : 'Kişi bulunamadı';
              });
            return names
        }else if(k == 'tagList'){
            const names = tagList.filter(item => createTimeoffTypeObject.tagList.includes(item.id)).map(item => item.name);
            return names
        }else{
            return v
        }
        
    }

    const fieldNameDict = {
        "name" : t('TIMEOFF_NAME'),
        "paymentSetIdx": t('TIMEOFF_TYPE'),
        "description": t('TIMEOFF_DESCRIPTION'),
        "isBalanceUnlimited": t('TIMEOFF_DURATION'),
        "maxBalance": t('TIMEOFF_LIMITED_LIMIT'),
        "accrualDateSetIdx": t('TIMEOFF_STARTING_TIME'),
        "isTransferable": t('TIMEOFF_TRANSFER'),
        "isExceedable": t('TIMEOFF_TIMEOUT'),
        "maxExceedBalance": t('TIMEOFF_TIMEOUT_MAX'),
        "isExceedableInform": t('TIMEOFF_TIMEOUT_INFO'),
        "isGenderType": t('TIMEOFF_ACCORDING_GENDER'),
        "isRenewalableBalance": t('TIMEOFF_RENEWAL'),
        "isRequiredDocument": t('TIMEOFF_REQUIRED_DOC_UPLOAD'),
        "isAppearContactInfo": t('TIMEOFF_CONTACT_INFO'),
        "isRequiredDescription": t('TIMEOFF_REQUIRED_DESCRIPTION'),
        "personList": t('TIMEOFF_APPLIED_PERSONS'),
        "tagList": t('TIMEOFF_APPLIED_TAGS')
    }

    return (
        <div>
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <div className="text-switch-container">
                        <span>{t("IS_TIMEOFf_TYPE_ACTIVE_SWITCH_DESC",{name:createTimeoffTypeObject.name,status:createTimeoffTypeObject.isActive ? t('TIMEOFF_ACTIVE') : t("TIMEOFF_PASSIVE")})}</span>
                        <Form.Item className="pad-0 mar-0"  name="isActive" valuePropName="checked" >
                            <Switch  />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>

            <hr/>
            <Row>
                <Col className="summary-left-col">
                {
                    firstHalfArray?.map((field) => {
                        return (
                            <div>
                                <div className="field-name">{fieldNameDict[field.key]}</div>
                                <div className="field-value">
                                    {
                                        (field.key === 'personList' || field.key === 'tagList') ?
                                        field.key && getValue(field.key, field.value)?.map((p) => {
                                            return <Tag>{p}</Tag>;
                                        }) 
                                        :
                                        (field.key && getValue(field.key, field.value))
                                    }
                                </div>
                            </div>
                        );
                    })
                }

                </Col>
                <Col className="summary-right-col">
                    { secondHalfArray?.map((field) =>{
                        return (
                            <div>
                                <div className="field-name">{fieldNameDict[field.key]}</div>
                                <div className="field-value">
                                    {
                                        (field.key === 'personList' || field.key === 'tagList') ?
                                        field.key && getValue(field.key, field.value)?.map((p) => {
                                            return <Tag>{p}</Tag>;
                                        }) 
                                        :
                                        (field.key && getValue(field.key, field.value))
                                    }
                                </div>
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
        workHourDepartmentInPerson: state.organization.workHourDepartmentInPerson,
        workHourDepartmentInPersonLoading: state.organization.workHourDepartmentInPersonLoading,
        tagList: state.tag.tagList.results,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getWorkHourDepartmentInPersons: () => { dispatch(getWorkHourDepartmentInPersons()) },
        getTagList: (searchData) => { dispatch(getTagList(searchData)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTimeoffTypeAppliedPersons);