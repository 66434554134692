import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const Secure3DCallback = () => {
    const htmlContent = useSelector(state => state.checkout.secure3DHTMLContent);
    const containerRef = useRef(null);

    const submitForm = () => {
        const form = containerRef.current.querySelector('form');
        if (form) {
            form.submit();
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            const scripts = containerRef.current.querySelectorAll('script');
            scripts.forEach(script => {
                const newScript = document.createElement('script');
                newScript.text = script.innerText;
                document.body.appendChild(newScript);
                document.body.removeChild(newScript);
            });
            submitForm();
        }
    }, [htmlContent]);

    return (
        <div ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default Secure3DCallback;
