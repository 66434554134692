import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

import auth from "./reducers/auth.reducer";
import personDetailReducer from "./reducers/personDetail.reducer";
import organizationDetailsReducer from "./reducers/organizationDetail.reducer";
import departmentReducer from "./reducers/department.reducer";
import organizationSelectReducer from "./reducers/organization-select.reducer";
import dataset from "./reducers/dataset-reducer";
import dashboard from "./reducers/dashboard.reducer";
import addressReducer from "./reducers/address.reducer";
import tabreducer from "./reducers/person-detail-tab-reducer";
import organizationTabreducer from "./reducers/person-organization-drawer-reducer";
import timeOffDrawerReducer from "./reducers/time-off-drawer.reducer";
import expenseType from "./reducers/expense-type-reducer";
import person from "./reducers/person.reducer";
import ownershipTabReducer from "./reducers/ownership-drawer.reducer";
import ownership from "./reducers/ownership.reducer";
import job from "./reducers/job.reducer";
import setting from "./reducers/setting.reducer";
import settingsPage from "./reducers/settings-page-reducer";
import timeOff from "./reducers/timeOff.reducer";
import timeoffTypes from "./reducers/timeoffTypes.reducer";
import profile from "./reducers/profile.reducer";
import organization from "./reducers/organization.reducer";
import organizations from "./reducers/organization-list-reducer";
import inventoryType from "./reducers/inventoryType.reducer";
import roles from "./reducers/roles.reducer";
import tag from "./reducers/tag.reducer";
import documentType from "./reducers/document-type.reducer";
import inventory from "./reducers/inventory.reducer";
import advance from "./reducers/advance.reducer";
import expense from "./reducers/expense.reducer";
import travel from "./reducers/travel.reducer";
import report from "./reducers/report.reducer";
import travelHostReducer from "./reducers/travelHost.reducer";
import approvalProcesses from "./reducers/approval-processes.reducer";
import notificationReducer from "./reducers/notification.reducer";
import taskReducer from "./reducers/task.reducer";
import education from "./reducers/education.reducer";
import checkout from "./reducers/checkout.reducer";
import subscription from "./reducers/subscription.reducer";
import changingOrganization from "./reducers/change-organization.reducer";
import onboarding from "./reducers/onboarding.reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "profile", "roles"],
  blacklist: ["advance"],
  //blacklist:['person', 'tabreducer','departmentReducer','organizationTabreducer','timeOffDrawerReducer']
};

const rootReducer = combineReducers({
  auth: auth,
  personDetailReducer: personDetailReducer,
  organizationDetailsReducer: organizationDetailsReducer,
  organizationSelectReducer: organizationSelectReducer,
  departmentReducer: departmentReducer,
  organizations: organizations,
  organization: organization,
  roles: roles,
  inventory: inventory,
  inventoryType: inventoryType,
  addressReducer: addressReducer,
  dataset: dataset,
  tabreducer: tabreducer,
  organizationTabreducer: organizationTabreducer,
  timeOffDrawerReducer: timeOffDrawerReducer,
  expenseType: expenseType,
  person: person,
  ownershipTabReducer: ownershipTabReducer,
  ownership: ownership,
  job: job,
  advance: advance,
  expense: expense,
  tag: tag,
  documentType: documentType,
  setting: setting,
  settingsPage: settingsPage,
  timeOff: timeOff,
  timeoffTypes: timeoffTypes,
  profile: profile,
  dashboard: dashboard,
  travel: travel,
  report: report,
  travelHostReducer: travelHostReducer,
  approvalProcesses: approvalProcesses,
  notificationReducer: notificationReducer,
  taskReducer: taskReducer,
  education: education,
  checkout:checkout,
  subscription:subscription,
  changingOrganization:changingOrganization,
  onboarding,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
  let persistor = persistStore(store);
  return { store, persistor };
};
