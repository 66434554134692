import { DepartmentDetailActionType } from "../actions/department.action";

const departmentDetailState = {
  departmentDetailInfo: {
    resourceId: "",
    organization: {
      id: null,
      displayValue: "",
    },
    name: "",
    parentDepartment: null,
    departmentManager: {
      id: "",
      displayValue: "",
    },
    departmentHrManager: null,
    floor: "",
    address: {
      country: {
        id: null,
        displayValue: "",
      },
      province: {
        id: null,
        displayValue: "",
      },
      district: {
        id: null,
        displayValue: "",
      },
      addressLine: "",
    },
    status: null,
  },
  updateResponse: { status: "loading", message: "" },
  departmentExportData: {},
  departmentFilterResult: {
    results: [],
  },
  parentDepartmentFilterResult: {
    results: [],
  },
  isDepartmentFilterLoading: false,
  isParentDepartmentFilterLoading: false,
  getHeadDepartmentLoading: false,
  headDepartment: {},
  departmentControlLoading: false,
  departmentControlData: {
    isHaveChildDepartments: null,
    isHavePersons: null,
  },
  departmentMoreActionResponse: { status: "loading", message: "" },
  departmentTreeViewList: [],
  departmentTreeViewLoading: true,
  departmentImportResponse: { status: "loading", data: "", errorIds: [] },

};

const departmentReducer = (state = departmentDetailState, action) => {
  switch (action.type) {
    case DepartmentDetailActionType.GET_HEAD_DEPARTMENT_REQUEST:
      return {
        ...state,
        getHeadDepartmentLoading: true,
      };
    case DepartmentDetailActionType.GET_HEAD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        headDepartment: action.payload,
        getHeadDepartmentLoading: false,
      };
    case DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_REQUEST:
      const departmentDetailInfoRequestState = {
        ...state,
        departmentDetailInfoIsFetching: true,
      };
      return departmentDetailInfoRequestState;

    case DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_SUCCESS:
      const departmentDetailsSuccessState = {
        ...state,
        departmentDetailInfo: action.payload,
        departmentDetailInfoIsFetching: false,
      };
      return departmentDetailsSuccessState;

    case DepartmentDetailActionType.UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        updateResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case DepartmentDetailActionType.UPDATE_DEPARTMENT_FAIL:
      return {
        ...state,
        updateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case DepartmentDetailActionType.UPDATE_DEPARTMENT_INPUT_ERROR:
      return {
        ...state,
        updateResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case DepartmentDetailActionType.ORGANIZATION_EXPORT_SUCCESS:
      return {
        ...state,
        departmentExportData: action.payload,
      };
    case DepartmentDetailActionType.ORGANIZATION_EXPORT_FAIL:
      return {
        ...state,
        departmentExportData: action.payload,
      };
    case DepartmentDetailActionType.GET_DEPARTMENT_RESET:
      return departmentDetailState;

    case DepartmentDetailActionType.UPDATE_DEPARTMENT_RESPONSE_RESET:
      return {
        ...state,
        updateResponse: action.payload,
      };
    case DepartmentDetailActionType.DEPARTMENT_FILTER_REQUEST:
      return {
        ...state,
        isDepartmentFilterLoading: true,
      };
    case DepartmentDetailActionType.DEPARTMENT_FILTER_SUCCESS:
      return {
        ...state,
        departmentFilterResult: action.payload,
        isDepartmentFilterLoading: false,
      };
    case DepartmentDetailActionType.DEPARTMENT_FILTER_FAIL:
      return {
        ...state,
        isDepartmentFilterLoading: false,
      };
    case DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_REQUEST:
      return {
        ...state,
        isParentDepartmentFilterLoading: true,
      };
    case DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_SUCCESS:
      return {
        ...state,
        parentDepartmentFilterResult: action.payload,
        isParentDepartmentFilterLoading: false,
      };
    case DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_FAIL:
      return {
        ...state,
        isParentDepartmentFilterLoading: false,
      };
    case DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_REQUEST:
      return {
        ...state,
        departmentControlLoading: true,
      };
    case DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_SUCCESS:
      return {
        ...state,
        departmentControlData: action.payload,
        departmentControlLoading: false,
      };
    case DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_FAIL:
      return {
        ...state,
        departmentControlLoading: false,
      };
    case DepartmentDetailActionType.RESET_DEPARTMENT_CONTROL:
      return {
        ...state,
        departmentControlData: {
          IsHaveChildDepartments: null,
          IsHavePersons: null,
        },
        departmentControlLoading: false,
      };
    case DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_SUCCESS:
      return {
        ...state,
        departmentMoreActionResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_FAIL:
      return {
        ...state,
        departmentMoreActionResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_RESET:
      return {
        ...state,
        departmentMoreActionResponse: { status: "loading", message: "" },
      };
    case DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_REQUEST:
      return {
        ...state,
        departmentTreeViewLoading: true,
      };
    case DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_SUCCESS:
      return {
        ...state,
        departmentTreeViewList: action.payload,
        departmentTreeViewLoading: false,
      };
    case DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_FAIL:
      return {
        ...state,
        departmentTreeViewLoading: false,
      };
    case DepartmentDetailActionType.IMPORT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departmentImportResponse: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case DepartmentDetailActionType.IMPORT_DEPARTMENT_FAIL:
      return {
        ...state,
        departmentImportResponse: {
          status: "FAIL",
          data: action.payload,
        },
      };
    case DepartmentDetailActionType.IMPORT_DEPARTMENT_RESET:
      return {
        ...state,
        departmentImportResponse: {
          status: "loading",
          data: null,
        },
      };
    default:
      return state;
  }
};

export default departmentReducer;
