import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import { getInventoryTypeList, updateInventoryTypeStatus, updateInventoryTypeStatusReset } from '../../redux/actions/inventoryType.action'
import { noData } from '../../utils/commonFormFunctions'


import { Layout, Col, List, Card, Row, Switch } from 'antd';

import BreadCrump from '../../features/breadcrump/breadcrump';

import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../../component/forbidden/index";
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

function InventoryType(props) {

    const { Content } = Layout;
    const { t } = useTranslation();
    const { getInventoryTypeList, inventoryTypeList, inventoryTypeListLoading,
        updateInventoryTypeStatus, updateInventoryTypeStatusReset, updateInventoryTypeResponse,
        updateInventoryTypeLoading } = props

    useEffect(() => {

        getInventoryTypeList()
    }, [])
    useEffect(() => {
        if (updateInventoryTypeResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'updateInventoryTypeStatusSuccess',
                'title': t('SUCCESS'),
                'description': updateInventoryTypeResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            updateInventoryTypeStatusReset()
            getInventoryTypeList()
        } else if (updateInventoryTypeResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'updateInventoryTypeStatusFail',
                'title': t('ERROR'),
                'description': updateInventoryTypeResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateInventoryTypeStatusReset()
        }
    }, [updateInventoryTypeResponse]);

    function onChange(status, resourceId) {
        updateInventoryTypeStatus(status, resourceId)
    }

    return (
        <React.Fragment>
            <Can I="inventory.type.list">
                <Layout>
                    <Layout>
                        <Col span={24}>
                            <Content className="page-container">
                                <BreadCrump />
                                <div className="tab-page">
                                    <h1 className="page-title">{t('TYPES_OF_DEVICE')}</h1>
                                </div>
                                <div className="timeOffType-container">


                                    <Col span={24}>
                                        <div className="timeOffType-table pad-30">

                                            <List
                                                className="w-100 custom-table-list"
                                                itemLayout="vertical"
                                                size="large"
                                                dataSource={inventoryTypeList}
                                                loading={inventoryTypeListLoading}
                                                locale={{ emptyText: (noData(t)) }}
                                                renderItem={data => (
                                                    <List.Item
                                                        key={data.resourceId + 'fs'}

                                                    >
                                                        <Card key={data.resoruceId} className="timeOffType-card"  >
                                                            <Row>
                                                                <Col span={7}>
                                                                    <h1>{data.name}</h1>
                                                                </Col>
                                                                <Col span={7}>
                                                                    <div className="custom-table-card-multiple-text m-t-10">
                                                                        <h1>{data?.stateInfo?.activeCount + ' ' + t('INVENTORY_TYPE_WORKS')}</h1>
                                                                        <h1>{data?.stateInfo?.faultyCount + ' ' + t('INVENTORY_TYPE_DEFECT')}</h1>
                                                                        <h1>{data?.stateInfo?.scrapCount + ' ' + t('INVENTORY_TYPE_SCRAP')}</h1>
                                                                    </div>

                                                                </Col>
                                                                {/* <Col span={7}> */}
                                                                <Col offset={3} span={7} >
                                                                    <div className="custom-table-card-multiple-text m-t-24">
                                                                        <h1>{data?.assigmentInfo?.freeCount + ' ' + t('INVENTORY_TYPE_EMBEZZLED_AVAIBLE')}</h1>
                                                                        <h1>{data?.assigmentInfo?.assigmentCount + ' ' + t('INVENTORY_TYPE_EMBEZZLED_FREE')}</h1>
                                                                    </div>
                                                                </Col>

                                                                {/* <Col span={3}>
                                                                    <Can I="inventory.type.list">
                                                                        <div className="global-switch">
                                                                            <Switch onChange={(e) => onChange(e, data.resourceId)} defaultChecked={data.isActive} />
                                                                            {data.isActive ?
                                                                                <div className="green toggle-text">{t('DEPARTMENT_ACTIVE')}</div>
                                                                                :
                                                                                <div className="red toggle-text">{t('DEPARTMENT_PASSIVE')}</div>
                                                                            }
                                                                        </div>
                                                                    </Can>
                                                                </Col> */}
                                                            </Row>
                                                        </Card>
                                                    </List.Item>
                                                )}
                                            />





                                        </div>
                                    </Col>
                                </div>

                            </Content >
                        </Col>
                    </Layout>
                </Layout >
                <ToastContainer />
            </Can>
            <Cannot I="inventory.type.list">
                <Forbidden />
            </Cannot>
        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        inventoryTypeList: state.inventoryType.inventoryTypeList,
        inventoryTypeListLoading: state.inventoryType.inventoryTypeListLoading,
        updateInventoryTypeResponse: state.inventoryType.updateInventoryTypeResponse,
        updateInventoryTypeLoading: state.inventoryType.updateInventoryTypeLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInventoryTypeList: () => { dispatch(getInventoryTypeList()) },
        updateInventoryTypeStatus: (status, resourceId) => { dispatch(updateInventoryTypeStatus(status, resourceId)) },
        updateInventoryTypeStatusReset: () => { dispatch(updateInventoryTypeStatusReset()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryType);

//inventoryType