import { React, useState, useEffect } from 'react';
import { Layout, Image, Card, Progress } from 'antd';
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import '../../styles/button.scss';
import '../../styles/all-login-page.scss';
import { useHistory } from 'react-router'

function SuccessPasswordChange() {

    const [value, setValue] = useState(0);
    const history = useHistory();

    useEffect(() => {
        let interval = null;
        if (value < 100) {
            interval = setInterval(() => {
                setValue(value => value + 3);
            }, 100);
        } else {
            clearInterval(interval);
            history.push('/signin');
        }
        return () => clearInterval(interval);
    }, [value]);

    return (
        <Layout>
            <div className="netHR-container">
                <div className="logo">
                    <Image src={process.env.REACT_APP_URL + "images/ofixpro45.png"} preview={false} />
                </div>
                <Card className="netHR-success-card">
                    <div className="success-image" >
                        <Image src={process.env.REACT_APP_URL + "images/success.png"} preview={false} />
                    </div>
                    <h1>Şifreniz başarıyla güncellendi.<br />
                        Giriş sayfasına yönlendiriliyorsunuz</h1>

                    <div className="success-bar">
                        <Progress percent={value} showInfo={false} />
                    </div>
                </Card>
            </div>
        </Layout>
    )


}
export default SuccessPasswordChange;