import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Form, Input, Col, Row, Checkbox, Spin, ConfigProvider } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";

import PhoneInput from 'react-phone-input-2';
import { phoneInputConfig } from '../../../utils/config';
import { getTaxOffices, getDistricts, getProvinces, getCountry, getNeighborhoods } from '../../../redux/actions/address.actions';

import { compareObjects } from '../../../utils/commonFormFunctions';


function OrganizationBillingInfo(props) {

    const { t } = useTranslation();
    const [organizationBillingInfoState, setOrganizationBillingInfoState] = useState({});

    const [form] = Form.useForm()
    const updateObj = {}
    const { organizationDetailsInfo, setOrganizationUpdateObj, getTaxOffices, getCountry, getProvinces, getDistricts, addressReducer, getNeighborhoods, neighborhoods } = props;
    const [checkStatus, setCheckStatus] = useState(false);
    const [taxIdList, setTaxIdList] = useState([
        { ...organizationDetailsInfo.billingAddress?.taxOffice }
    ]);
    const [countryList, setCountryList] = useState([

        { ...organizationDetailsInfo.billingAddress?.country }
    ]);
    const [districtList, setDistrictList] = useState([
        { ...organizationDetailsInfo.billingAddress?.district }
    ]);
    const [provinceList, setProvinceList] = useState([
        { ...organizationDetailsInfo.billingAddress?.province }
    ]);
    const [neighborhoodList, setNeighborhoodList] = useState([
        { ...organizationDetailsInfo.billingAddress?.neighborhood }
    ]);
    useEffect(() => {


        if (checkStatus === false) {

            // form.setFieldsValue({
            //     "country": organizationDetailsInfo.billingAddress?.country,
            //     "province": organizationDetailsInfo.billingAddress?.province,
            //     "district": organizationDetailsInfo.billingAddress?.district,
            //     "neighborhood": organizationDetailsInfo.billingAddress?.neighborhood,
            //     "addressLine": organizationDetailsInfo.billingAddress?.addressLine,
            //     "postalCode": organizationDetailsInfo.billingAddress?.postalCode,
            //     "Locatiy": "",
            //     "companyName": organizationDetailsInfo.billingAddress?.companyName,
            //     "taxOffice": organizationDetailsInfo.billingAddress?.taxOffice,
            //     "taxId": organizationDetailsInfo.billingAddress?.taxId,
            //     "tckn": organizationDetailsInfo.billingAddress?.tckn,
            //     "authorizedPerson": organizationDetailsInfo.billingAddress?.authorizedPerson,
            //     "email": organizationDetailsInfo.billingAddress?.email,
            //     "telephone": organizationDetailsInfo.billingAddress?.telephone,
            // })
            setOrganizationBillingInfoState(organizationDetailsInfo)

            const addressObj = {
                "country": organizationDetailsInfo.billingAddress?.country,
                "province": organizationDetailsInfo.billingAddress?.province,
                "district": organizationDetailsInfo.billingAddress?.district,
                "neighborhood": organizationDetailsInfo.billingAddress?.neighborhood,
                "addressLine": organizationDetailsInfo.billingAddress?.addressLine,
                "postalCode": organizationDetailsInfo.billingAddress?.postalCode,
                "Locatiy": "",
                "companyName": organizationDetailsInfo.billingAddress?.companyName,
                "taxOffice": organizationDetailsInfo.billingAddress?.taxOffice,
                "taxId": organizationDetailsInfo.billingAddress?.taxId,
                "tckn": organizationDetailsInfo.billingAddress?.tckn,
                "authorizedPerson": organizationDetailsInfo.billingAddress?.authorizedPerson,
                "email": organizationDetailsInfo.billingAddress?.email,
                "telephone": organizationDetailsInfo.billingAddress?.telephone ?? (localStorage.getItem("i18nLang") === "tr-TR" ? '90' : '44'),
            }
            form.setFieldsValue(addressObj)
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj })

        } else {
            setOrganizationBillingInfoState(organizationDetailsInfo)
            const addressObj = {
                "country": organizationDetailsInfo.address?.country,
                "province": organizationDetailsInfo.address?.province,
                "district": organizationDetailsInfo.address?.district,
                "neighborhood": organizationDetailsInfo.address?.neighborhood,
                "addressLine": organizationDetailsInfo.address?.addressLine,
                "postalCode": organizationDetailsInfo.address?.postalCode,
                "Locatiy": "",
                "companyName": organizationDetailsInfo.legalName,
                "taxOffice": organizationDetailsInfo.billingAddress?.taxOffice,
                "taxId": organizationDetailsInfo.billingAddress?.taxId,
                "tckn": organizationDetailsInfo.billingAddress?.tckn,
                "authorizedPerson": organizationDetailsInfo.billingAddress?.authorizedPerson,
                "email": organizationDetailsInfo.email,
                "telephone": organizationDetailsInfo.telephone ?? (localStorage.getItem("i18nLang") === "tr-TR" ? '90' : '44'),
            }
            form.setFieldsValue(addressObj)
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj })

        }

    }, [form, organizationDetailsInfo, checkStatus])

    useEffect(() => {
        setOrganizationUpdateObj(organizationBillingInfoState)
    }, [organizationBillingInfoState]);


    useEffect(() => {
        setTaxIdList(addressReducer.TaxId);
    }, [addressReducer.TaxId]);
    useEffect(() => {
        setCountryList(addressReducer.countries);
    }, [addressReducer.countries]);
    useEffect(() => {
        setProvinceList(addressReducer.provinces);
    }, [addressReducer.provinces]);
    useEffect(() => {
        setDistrictList(addressReducer.districts);
    }, [addressReducer.districts]);
    useEffect(() => {
        setNeighborhoodList(addressReducer.neighborhoods);
    }, [addressReducer.neighborhoods]);

    const handleValuesChange = (changedObj, all) => {



        if ('country' === Object.keys(changedObj)[0]) {
            if (JSON.stringify(changedObj.country) !== null) {
                // ülke değişti
                setProvinceList([])//il listesini sıfırla
                setDistrictList([])//ilçe listesini sıfırla
                setTaxIdList([])//vergi dairesi listesini sıfırla
                setNeighborhoodList([])
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["district"] = '';//ilçeyi sıfırla
                fieldsObj["province"] = '';//il sıfırla
                fieldsObj["taxOffice"] = '';//Vergi Dairesini sıfırla
                fieldsObj["neighborhood"] = '';
                form.setFieldsValue(fieldsObj)
                const addressObj = {
                    ...organizationBillingInfoState.billingAddress, "country": changedObj['country'],
                    "taxOffice": "", "province": "", "district": "", "neighborhood": ""
                }
                setOrganizationBillingInfoState({ ...organizationBillingInfoState, "billingAddress": addressObj })

            } else {
                const addressObj = { ...organizationBillingInfoState.billingAddress, 'country': changedObj["country"] };
                setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
            }

        } 
         else if ('province' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'province': changedObj["province"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('district' === Object.keys(changedObj)[0]) {
            setNeighborhoodList([])
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields };
            fieldsObj["neighborhood"] = '';
            form.setFieldsValue(fieldsObj)
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'district': changedObj["district"], "neighborhood": "" };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('neighborhood' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'neighborhood': changedObj["neighborhood"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('addressLine' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'addressLine': changedObj["addressLine"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('postalCode' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'postalCode': changedObj["postalCode"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('companyName' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'companyName': changedObj["companyName"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('telephone' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'telephone': '+' + changedObj["telephone"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('email' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'email': changedObj["email"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('authorizedPerson' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'authorizedPerson': changedObj["authorizedPerson"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('taxOffice' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'taxOffice': changedObj["taxOffice"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('tckn' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'tckn': changedObj["tckn"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        } else if ('taxId' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationBillingInfoState.billingAddress, 'taxId': changedObj["taxId"] };
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, billingAddress: addressObj });
        }
        //

        else {
            setOrganizationBillingInfoState({ ...organizationBillingInfoState, ...changedObj });

        }
    }
    function changeCheckStatus(e) {

        setCheckStatus(e.target.checked)

    }
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={false}
            >

                <Row gutter={[32, 0]}>
                    <div className="w-100">
                        <Checkbox onChange={changeCheckStatus} className="flex-end" >{t('USE_CURRENT_ORGANIZATION_INFO')}</Checkbox>
                    </div>
                    <Col span={12}>
                        <div className="form-left-body">

                            <Form.Item name="companyName" label={t('ORGANIZATION_COMPANY_NAME')} required>
                                <Input className={organizationDetailsInfo.billingAddress?.companyName !== organizationBillingInfoState.billingAddress?.companyName ? 'is-changed' : ''} />
                            </Form.Item>

                            <Form.Item name="taxId" label={t('ORGANIZATION_TAX_NUMBER')} required>
                                <Input className={organizationDetailsInfo.billingAddress?.taxId !== organizationBillingInfoState.billingAddress?.taxId ? 'is-changed' : ''} />
                            </Form.Item>
                            <Form.Item name="authorizedPerson" label={t('ORGANIZATION_ADMIN_PERSON')}  >
                                <Input className={organizationDetailsInfo.billingAddress?.authorizedPerson !== organizationBillingInfoState.billingAddress?.authorizedPerson ? 'is-changed' : ''} />
                            </Form.Item>
                            <Form.Item name="email" label={t('ORGANIZATION_E_MAIL')}  >
                                <Input className={organizationDetailsInfo.billingAddress?.email !== organizationBillingInfoState.billingAddress?.email ? 'is-changed' : ''} />
                            </Form.Item>
                            <Form.Item name="telephone" label={t('ORGANIZATION_PHONE')}>
                                <PhoneInput
                                    containerClass={!compareObjects(organizationDetailsInfo.billingAddress?.telephone, organizationBillingInfoState.billingAddress?.telephone) ? 'is-changed' : ''}
                                    {...phoneInputConfig}
                                    inputProps={{
                                        name: 'telephone',
                                        required: true
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="pad-100" />

                    </Col>
                    <Col span={12}>
                        <div className="form-right-body">

                            <Form.Item className="custom-select" name="country" label={t('ORGANIZATION_COUNTRY')} required>
                                <DynamicSelectbox
                                    optionList={countryList}
                                    onFocus={() => getCountry()}
                                    className={!compareObjects(organizationDetailsInfo.billingAddress?.country, organizationBillingInfoState.billingAddress?.country) ? 'is-changed' : ''}
                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                />
                            </Form.Item>
                            <Form.Item 
                                name="province" 
                                label={t('ORGANIZATION_PROVINCE')} 
                                required
                            >
                                <Input
                                    className={!compareObjects(organizationDetailsInfo.billingAddress?.province, organizationBillingInfoState.billingAddress?.province) ? 'is-changed' : ''}
                                    disabled={!organizationBillingInfoState.billingAddress?.country}
                                    placeholder={t('PROVINCE_PLACEHOLDER')}
                                />
                            </Form.Item>
                            <Form.Item name="addressLine" label={t('ORGANIZATION_ADDRESS')}>
                                <Input.TextArea className={organizationDetailsInfo.billingAddress?.addressLine !== organizationBillingInfoState.billingAddress?.addressLine ? 'is-changed' : ''} />
                            </Form.Item>
                            <Form.Item name="postalCode" label={t('ORGANIZATION_POST_CODE')}>
                                <Input className={organizationDetailsInfo.billingAddress?.postalCode !== organizationBillingInfoState.billingAddress?.postalCode ? 'is-changed' : ''} />
                            </Form.Item>

                        </div>
                    </Col>
                </Row>
            </Form>
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        addressReducer: state.addressReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTaxOffices: (props) => { dispatch(getTaxOffices(props)) },
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (props) => { dispatch(getProvinces(props)) },
        getDistricts: (props) => { dispatch(getDistricts(props)) },
        getNeighborhoods: (props) => { dispatch(getNeighborhoods(props)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationBillingInfo);