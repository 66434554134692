import { AdvanceActionType } from "../actions/advance.actions";

const initialState = {
    advanceCreateDrawer:false,
    advanceDetailsDrawer:false,
    myAdvanceList:{
        totalNumberOfRecords:0,
        results:[]
    },
    myAdvanceListLoading:true,
    advanceDetailsTable:{
        totalNumberOfRecords:0,
        results:[]
    },
    advanceDetailsTableLoading:true,
    advanceManagerList:{
        totalNumberOfRecords:0,
        results:[]
    },
    advanceManagerListLoading:true,
    allAdvanceList:{
        totalNumberOfRecords:0,
        results:[]
    },
    allAdvanceListLoading:true,
    createAdvanceRequests:{"status":"loading","message":"","data":""},
    advanceDetails:{
        "advanceType": null,
        "currency": {
          "id": "",
          "displayValue": ""
        },
        "managerPerson": null,
        "role": null,
        "stateType": null,
        "approvalDescriptions": [],
        "person": {
          "id": null,
          "resourceId": "",
          "fullName": ""
        },
        "resourceId": "",
        "advanceTypeName": null,
        "advanceTypeId": null,
        "needDate": "",
        "amount": null,
        "currencyName": null,
        "currencyId": null,
        "managerNamex": null,
        "managerIdx": null,
        "roleName": null,
        "roleId": null,
        "description": "",
        "stateTypeName": null,
        "stateTypeId": null,
        "totalRowCount": 0,
        "requestDate": "",
        "givenName": "",
        "familyName": "",
        "personResourceId": "",
        "departmentName": "",
        "amounts": null,
        "FirstInstallmentPaymentMonth":"",
        "amountsList": []
    },
    advanceDetailsLoading:true,
    advanceDetailStepList:
        [
            {
              "name": "",
              "authResourceId": "",
              "stepName": "",
              "date": "",
              "sortOrder": null,
              "isSelected": null,
              "description": null,
              "stepListId": null
            },
          ],
    advanceDetailStepListLoading:true,
    advanceChangeStatusResponse:{"status":"loading","message":""},
    advanceExportData:{},
    advanceExportDataResponse:{"status":"loading","message":""},
    advanceApprovalExportData:{},
    advanceApprovalExportDataResponse:{"status":"loading","message":""},
    deleteAdvanceResponse:{"status":"loading","message":""},
    advanceApproveOnMailResponse:{"status":"loading","message":""},
    advanceApproveOnMailResponseLoading:true,
    advanceUpdateResponse:{"status":"loading","message":""},
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case AdvanceActionType.SET_ADVANCE_CREATE_DRAWER:
            return {
                ...state,
                advanceCreateDrawer:action.payload
            };  
        case AdvanceActionType.SET_ADVANCE_DETAILS_DRAWER:
            return {
                ...state,
                advanceDetailsDrawer:action.payload
            };  
        case AdvanceActionType.CREATE_ADVANCE_SUCCESS:
            return {
                ...state,
                createAdvanceRequests:{
                    "status":"SUCCESS",
                    "message":action.payload.message,
                    "data": action.payload.data.resourceId
                }
            }
        case AdvanceActionType.CREATE_ADVANCE_FAIL:
            return {
                ...state,
                createAdvanceRequests:{
                    "status":"FAIL",
                    "message":action.payload
                }
            }
        case AdvanceActionType.CREATE_ADVANCE_RESET:
            return{
                ...state,
                createAdvanceRequests:{"status":"loading","message":""}
            } 
        case AdvanceActionType.MY_ADVANCE_LIST_REQUESTS:
            return {
                ...state,
                myAdvanceListLoading: true
            };
        case AdvanceActionType.MY_ADVANCE_LIST_SUCCESS:
            return {
                ...state,
                myAdvanceList: action.payload,
                myAdvanceListLoading: false
            }
        case AdvanceActionType.MY_ADVANCE_LIST_FAIL:
            return {
                ...state,
                myAdvanceListLoading: false,
            }
        case AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_REQUESTS:
            return {
                ...state,
                advanceDetailsTableLoading: true
            };
        case AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_SUCCESS:
            return {
                ...state,
                advanceDetailsTable: action.payload,
                advanceDetailsTableLoading: false
            }
        case AdvanceActionType.ADVANCE_DETAILS_TABLE_LIST_FAIL:
            return {
                ...state,
                advanceDetailsTableLoading: false,
            }
        case AdvanceActionType.ADVANCE_MANAGER_LIST_REQUESTS:
            return {
                ...state,
                advanceManagerListLoading: true
            };
        case AdvanceActionType.ADVANCE_MANAGER_LIST_SUCCESS:
            return {
                ...state,
                advanceManagerList: action.payload,
                advanceManagerListLoading: false
            }
        case AdvanceActionType.ADVANCE_MANAGER_LIST_FAIL:
            return {
                ...state,
                advanceManagerListLoading: false,
                advanceManagerList:{
                    totalNumberOfRecords:0,
                    results:[]
                },

            }  
        case AdvanceActionType.ALL_ADVANCE_LIST_REQUEST:
            return {
                ...state,
                allAdvanceListLoading: true
            };
        case AdvanceActionType.ALL_ADVANCE_LIST_SUCCESS:
            return {
                ...state,
                allAdvanceList: action.payload,
                allAdvanceListLoading: false
            }
        case AdvanceActionType.ALL_ADVANCE_LIST_FAIL:
            return {
                ...state,
                allAdvanceListLoading: false
            }
       
        case AdvanceActionType.GET_ADVANCE_DETAILS_REQUEST:
            return {
                ...state,
                advanceDetailsLoading: true
            };
        case AdvanceActionType.GET_ADVANCE_DETAILS_SUCCESS:
            return {
                ...state,
                advanceDetails: action.payload,
                advanceDetailsLoading: false
            }
        case AdvanceActionType.GET_ADVANCE_DETAILS_FAIL:
            return {
                ...state,
                advanceDetailsLoading: false
            } 
        case AdvanceActionType.GET_ADVANCE_DETAIL_STEP_REQUEST:
            return {
                ...state,
                advanceDetailStepListLoading: true
            };
        case AdvanceActionType.GET_ADVANCE_DETAIL_STEP_SUCCESS:
            return {
                ...state,
                advanceDetailStepList: action.payload,
                advanceDetailStepListLoading: false
            }
        case AdvanceActionType.GET_ADVANCE_DETAIL_STEP_FAIL:
            return {
                ...state,
                advanceDetailStepListLoading: false
            } 
        case AdvanceActionType.ADVANCE_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                advanceChangeStatusResponse:{
                    "status":"SUCCESS",
                    "message":action.payload,
                }
            }
        case AdvanceActionType.ADVANCE_CHANGE_STATUS_FAIL:
            return {
                ...state,
                advanceChangeStatusResponse:{
                    "status":"FAIL",
                    "message":action.payload,
                }
            }
        case AdvanceActionType.ADVANCE_CHANGE_STATUS_RESET:
            return{
                ...state,
                advanceChangeStatusResponse:{"status":"loading","message":""}
        }
        case AdvanceActionType.ADVANCE_EXPORT_SUCCESS:
            return {
                ...state,
                advanceExportData : action.payload
        }
        case AdvanceActionType.ADVANCE_EXPORT_FAIL:
            return {
                ...state,
                advanceExportData : {},
                advanceExportDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
        }
        case AdvanceActionType.RESET_ADVANCE_DOWNLOAD:
            return {
                ...state,
                advanceExportData : {},
                advanceExportDataResponse:{"status":"loading","message":""},
                
        }
        case AdvanceActionType.ADVANCE_APPROVALS_EXPORT_SUCCESS:
            return {
                ...state,
                advanceApprovalExportData : action.payload
        }
        case AdvanceActionType.ADVANCE_APPROVALS_EXPORT_FAIL:
            return {
                ...state,
                advanceApprovalExportData : {},
                advanceApprovalExportDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
        }
        case AdvanceActionType.RESET_ADVANCE_APPROVALS_EXPORT:
            return {
                ...state,
                advanceApprovalExportData : {},
                advanceApprovalExportDataResponse:{"status":"loading","message":""},
        }
        case AdvanceActionType.DELETE_ADVANCE_SUCCESS:
            return {
               ...state,
               deleteAdvanceResponse: {
                   "status": "SUCCESS",
                   "message": action.payload
               }
            }
        case AdvanceActionType.DELETE_ADVANCE_FAIL:
            return {
                ...state,
                deleteAdvanceResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
             }
        case AdvanceActionType.DELETE_ADVANCE_RESET:
            return{
                ...state,
                deleteAdvanceResponse: {
                    "status":"loading",
                    "message":null
                }
            }   
        case AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_REQUEST:
            return {
                ...state,
                advanceApproveOnMailResponse:{
                    "status":"LOADING",
                    "message":"",
                  },
                advanceApproveOnMailResponseLoading: true,
            };
        case AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_SUCCESS:
            return {
                ...state,
                advanceApproveOnMailResponse:{
                    "status":"SUCCESS",
                    "message":action.payload,
                  },
                advanceApproveOnMailResponseLoading: false,
            }
        case AdvanceActionType.GET_ADVANCE_APPROVE_ON_MAIL_FAIL:
            return {
                ...state,
                advanceApproveOnMailResponse:{
                    "status":"FAIL",
                    "message":action.payload,
                },
                advanceApproveOnMailResponseLoading: false,
            }  
        case AdvanceActionType.UPDATE_ADVANCE_SUCCESS:
        return {
            ...state,
            advanceUpdateResponse:{
                "status":"SUCCESS",
                "message":action.payload,
              },
        }
        case AdvanceActionType.UPDATE_ADVANCE_FAIL:
            return {
                ...state,
                advanceUpdateResponse:{
                    "status":"FAIL",
                    "message":action.payload,
                },
            }  
        case AdvanceActionType.UPDATE_ADVANCE_RESET:
            return {
                ...state,
                advanceUpdateResponse:{
                    "status":"LOADING",
                    "message":"",
                },
        }  
        default:
            return state;
    }
}