import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

const TimeoffTypes = {
    SET_ADD_TIMEOFF_TYPE_DRAWER:"SET_ADD_TIMEOFF_TYPE_DRAWER",
    SET_UPDATE_TIMEOFF_TYPE_DRAWER:"SET_UPDATE_TIMEOFF_TYPE_DRAWER",
    GET_ORGANIZATION_TIMEOFF_TYPE_LIST_REQUEST: "GET_ORGANIZATION_TIMEOFF_TYPE_LIST_REQUEST",
    GET_ORGANIZATION_TIMEOFF_TYPE_LIST_SUCCESS: "GET_ORGANIZATION_TIMEOFF_TYPE_LIST_SUCCESS",
    GET_ORGANIZATION_TIMEOFF_TYPE_LIST_FAIL: "GET_ORGANIZATION_TIMEOFF_TYPE_LIST_FAIL",
    GET_TIMEOFF_TYPE_DETAIL_REQUEST: "GET_TIMEOFF_TYPE_DETAIL_REQUEST",
    GET_TIMEOFF_TYPE_DETAIL_SUCCESS: "GET_TIMEOFF_TYPE_DETAIL_SUCCESS",
    GET_TIMEOFF_TYPE_DETAIL_FAIL: "GET_TIMEOFF_TYPE_DETAIL_FAIL",
    CREATE_TIMEOFF_TYPE_REQUEST: "CREATE_TIMEOFF_TYPE_REQUEST",
    CREATE_TIMEOFF_TYPE_SUCCESS: "CREATE_TIMEOFF_TYPE_SUCCESS",
    CREATE_TIMEOFF_TYPE_FAIL: "CREATE_TIMEOFF_TYPE_FAIL",
    UPDATE_TIMEOFF_TYPE_REQUEST: "UPDATE_TIMEOFF_TYPE_REQUEST",
    UPDATE_TIMEOFF_TYPE_SUCCESS: "UPDATE_TIMEOFF_TYPE_SUCCESS",
    UPDATE_TIMEOFF_TYPE_FAIL: "UPDATE_TIMEOFF_TYPE_FAIL",
    UPDATE_TIMEOFF_STATUS_REQUEST:"UPDATE_TIMEOFF_STATUS_REQUEST",
    UPDATE_TIMEOFF_STATUS_SUCCESS:"UPDATE_TIMEOFF_STATUS_SUCCESS",
    UPDATE_TIMEOFF_STATUS_FAIL:"UPDATE_TIMEOFF_STATUS_FAIL",
    DELETE_TIMEOFF_TYPE_REQUEST: "DELETE_TIMEOFF_TYPE_REQUEST",
    DELETE_TIMEOFF_TYPE_SUCCESS: "DELETE_TIMEOFF_TYPE_SUCCESS",
    DELETE_TIMEOFF_TYPE_FAIL: "DELETE_TIMEOFF_TYPE_FAIL",
    COPY_TIMEOFF_TYPE_REQUEST: "COPY_TIMEOFF_TYPE_REQUEST",
    COPY_TIMEOFF_TYPE_SUCCESS: "COPY_TIMEOFF_TYPE_SUCCESS",
    COPY_TIMEOFF_TYPE_FAIL: "COPY_TIMEOFF_TYPE_FAIL",
    RESET_TIMEOFF_TYPE_RESPONSES : "RESET_TIMEOFF_TYPE_RESPONSES"
}

const setAddTimeoffTypeDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: TimeoffTypes.SET_ADD_TIMEOFF_TYPE_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
};
const setDetailTimeoffTypeDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: TimeoffTypes.SET_UPDATE_TIMEOFF_TYPE_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
};

const getOrganizationTimeoffTypeList = () => {
    return async (dispatch) => {
      dispatch({ type: TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_REQUEST });
      try {
        var config = {
          method: "get",
          url: apiUrl + "/timeOffTypes/timeOffTypeList",
          headers: {},
        };
        hrApi(config)
          .then(function (response) {
            if (response.status === 200) {
              dispatch({ type: TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_SUCCESS, payload: response?.data?.data });
            } else {
              dispatch({ type: TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_FAIL, payload: response?.data?.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_FAIL, payload: error?.message });
          });
      } catch (error) {
        if (error.response) {
          dispatch({ type: TimeoffTypes.GET_ORGANIZATION_TIMEOFF_TYPE_LIST_FAIL, payload: error?.message });
        }
      }
    };
};

const getTimeoffTypeDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffTypes/detail/"+ id,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_SUCCESS, payload: response?.data?.data });
          } else {
            dispatch({ type: TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_FAIL, payload: response?.data?.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_FAIL, payload: error?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeoffTypes.GET_TIMEOFF_TYPE_DETAIL_FAIL, payload: error?.message });
      }
    }
  };
};

const createTimeoffType = (createData) => {
  return async (dispatch) => {
    dispatch({ type: TimeoffTypes.CREATE_TIMEOFF_TYPE_REQUEST });
    try {
      const data = JSON.stringify(createData);
      var config = {
        method: "post",
        url: apiUrl + "/timeOffTypes/create",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TimeoffTypes.CREATE_TIMEOFF_TYPE_SUCCESS, payload: response?.data?.data });
          } else {
            dispatch({ type: TimeoffTypes.CREATE_TIMEOFF_TYPE_REQUEST, payload: response?.data?.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeoffTypes.CREATE_TIMEOFF_TYPE_FAIL, payload: error?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeoffTypes.CREATE_TIMEOFF_TYPE_FAIL, payload: error?.message });
      }
    }
  };
};


const resetTimeoffTypeResponses = () => {    
  return async (dispatch) => {
    dispatch({ type: TimeoffTypes.RESET_TIMEOFF_TYPE_RESPONSES, payload:{} });
  };
}

const updateTimeoffType = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_TYPE_REQUEST });
    try {
      var data = JSON.stringify(updateData);
      
      var config = {
        method: "put",
        url: apiUrl + "/timeOffTypes/update/"+updateData.id,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_TYPE_SUCCESS, payload: response?.data });
          } else {
            dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_TYPE_FAIL, payload: response?.data });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_TYPE_FAIL, payload: error?.response?.data?.validationMessages[0] ? error?.response?.data?.validationMessages[0] : error?.response?.data?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_TYPE_FAIL, payload: error?.response?.data });
      }
    }
  };
};

const changeTimeoffTypeStatus = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_STATUS_REQUEST });
    try {
      var data = JSON.stringify(updateData);
      
      var config = {
        method: "put",
        url: apiUrl + "/timeOffTypes/changeStatus",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_STATUS_SUCCESS, payload: response?.data });
          } else {
            dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_STATUS_FAIL, payload: response?.data });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_STATUS_FAIL, payload: error?.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeoffTypes.UPDATE_TIMEOFF_STATUS_FAIL, payload: error?.response?.data });
      }
    }
  };
};

const deleteTimeoffType = (id) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'delete',
        url: apiUrl + '/timeOffTypes/delete/' + id,
        headers: {
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeoffTypes.DELETE_TIMEOFF_TYPE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TimeoffTypes.DELETE_TIMEOFF_TYPE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TimeoffTypes.DELETE_TIMEOFF_TYPE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TimeoffTypes.DELETE_TIMEOFF_TYPE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeoffTypes.DELETE_TIMEOFF_TYPE_FAIL, payload: error.message });
      }
    }
  };
};

const copyTimeoffType = (id) => {
  return async (dispatch) => {
    dispatch({ type: TimeoffTypes.COPY_TIMEOFF_TYPE_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/timeOffTypes/copy/"+id,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TimeoffTypes.COPY_TIMEOFF_TYPE_SUCCESS, payload: response?.data?.message });
          } else {
            dispatch({ type: TimeoffTypes.COPY_TIMEOFF_TYPE_FAIL, payload: response?.data?.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: TimeoffTypes.COPY_TIMEOFF_TYPE_FAIL, payload: error?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TimeoffTypes.COPY_TIMEOFF_TYPE_FAIL, payload: error?.message });
      }
    }
  };
};

export {
    TimeoffTypes,
    setAddTimeoffTypeDrawer,
    setDetailTimeoffTypeDrawer,
    getOrganizationTimeoffTypeList,
    getTimeoffTypeDetail,
    createTimeoffType,
    updateTimeoffType,
    changeTimeoffTypeStatus,
    deleteTimeoffType,
    copyTimeoffType,
    resetTimeoffTypeResponses
};
  