
import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';


export const getCountry = (props) => async dispatch => {

    try {
        var config = {
        method: 'get',
        url: apiUrl+'/address/countries',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {

                dispatch({
                    type: 'GET_COUNTRY_SUCCESS',
                    payload: response.data.data,
                });
             
        })

        .catch(function (error) {

        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};


export const getProvinces = (resourceId) => async dispatch => {
    
    try {
        var config = {
        method: 'get',
        url: apiUrl+'/address/'+ resourceId + '/provinces',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {

                dispatch({
                    type: 'GET_PROVINCES_SUCCESS',
                    payload: response.data.data
                });
             
        })

        .catch(function (error) {
            
        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};

export const getDistricts = (resourceId) => async dispatch => {

    try {
        var config = {
        method: 'get',
        url: apiUrl+'/address/'+ resourceId + '/districts',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {

                dispatch({
                    type: 'GET_DISTRICTS_SUCCESS',
                    payload: response.data.data
                });
             
        })

        .catch(function (error) {

        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};

export const getNeighborhoods = (resourceId) => async dispatch => {

    try {
        var config = {
            method: 'get',
            url: apiUrl+'/address/'+ resourceId + '/neighborhoods',
            headers: { }
        };

        hrApi(config)
        .then(function (response) {

                dispatch({
                    type: 'GET_NEIGBORHOODS_SUCCESS',
                    payload: response.data.data
                });
             
        })

        .catch(function (error) {

        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};

export const getNationalities = () => async dispatch => {

    try {
        var config = {
        method: 'get',
        url: apiUrl+'/address/nationalities',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {

                dispatch({
                    type: 'GET_NATIONALITIES_SUCCESS',
                    payload: response.data.data,
                });
             
        })

        .catch(function (error) {
            
        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};

export const getTaxOffices = (resourceId) => async dispatch => {
    try {
        var config = {
        method: 'get',
        url: apiUrl+'/address/'+ resourceId +'/taxoffices',
        headers: {'Content-Type': 'application/json' },
        };
        hrApi(config)
        .then(function (response) {

                dispatch({
                    type: 'GET_TAX__OFFICIES_SUCCESS',
                    payload: response.data.data,
                });
             
        })

        .catch(function (error) {
            
        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};

export const getProvinceFilter = (filterData) => {
    return async (dispatch) => {
        dispatch({ type: 'PROVINCE_FILTER_REQUEST' });
      try {
  
        const data = JSON.stringify(filterData);
  
  
        var config = {
          method: 'post',
          url: apiUrl+'/address/provinces/filter',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
                dispatch({ type: 'PROVINCE_FILTER_SUCCESS', payload: response.data.data });
          }else{
              dispatch({ type: 'PROVINCE_FILTER_FAIL', payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
               "results": []
            }
              dispatch({ type: 'PROVINCE_FILTER_SUCCESS', payload: emptyData });
          }
            dispatch({ type: 'PROVINCE_FILTER_FAIL', payload: error.message });
        });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: 'PROVINCE_FILTER_FAIL', payload: error.message });
        }
      }
    };
  };

  export const getDistrictFilter = (filterData) => {
    return async (dispatch) => {
        dispatch({ type: 'DISTRICT_FILTER_REQUEST' });
      try {
  
        const data = JSON.stringify(filterData);
  
  
        var config = {
          method: 'post',
          url: apiUrl+'/address/districts/filter',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
                dispatch({ type: 'DISTRICT_FILTER_SUCCESS', payload: response.data.data });
          }else{
              dispatch({ type: 'DISTRICT_FILTER_FAIL', payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
               "results": []
            }
              dispatch({ type: 'DISTRICT_FILTER_SUCCESS', payload: emptyData });
          }
            dispatch({ type: 'DISTRICT_FILTER_FAIL', payload: error.message });
        });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: 'DISTRICT_FILTER_FAIL', payload: error.message });
        }
      }
    };
  };

export const resetAddress = () => {
    
    return async (dispatch) => {
      dispatch({ type: 'RESET_ALL_ADDRESS', payload:{} });
      };
  }