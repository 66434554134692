import { InventoryType } from "../actions/inventoryType.action";
const initialState = {
    inventoryTypes:{
        totalNumberOfRecords:0,
        results:[]
    },
    inventoryTypeList:[],
    inventoryTypeListLoading:false,
    updateInventoryTypeResponse:{"status":"loading","message":""},
    updateInventoryTypeLoading:true,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case InventoryType.GET_INVENTORY_TYPE_SUCCESS:
            return {
                ...state,
                inventoryTypes:action.payload
            };
        case InventoryType.GET_INVENTORY_TYPE_LIST_REQUEST:
            return {
                ...state,
                inventoryTypeListLoading:true
            };
        case InventoryType.GET_INVENTORY_TYPE_LIST_SUCCESS:
            return {
                ...state,
                inventoryTypeList:action.payload,
                inventoryTypeListLoading:false
            };
        case InventoryType.GET_INVENTORY_TYPE_LIST_FAIL:
            return {
                ...state,
                inventoryTypeListLoading:false,
            };
        case InventoryType.UPDATE_INVENTORY_TYPE_STATUS_REQUEST:
            return {
                ...state,
                updateInventoryTypeLoading: true
            };   
        case InventoryType.UPDATE_INVENTORY_TYPE_STATUS_SUCCESS:
            return {
                ...state,
                updateInventoryTypeResponse: {
                    "status":"SUCCESS",
                    "message":action.payload
                 },
                 updateInventoryTypeLoading: false
            }
        case InventoryType.UPDATE_INVENTORY_TYPE_STATUS_FAIL:
            return {
                ...state,
                updateInventoryTypeResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
                 updateInventoryTypeLoading: false
            }
        case InventoryType.UPDATE_INVENTORY_TYPE_STATUS_RESET:{
            return {
                ...state,
                updateInventoryTypeResponse : {"status":"loading","message":""}
                
            } 
        }  
        default: return state
    }
}
