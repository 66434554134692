const initialState = {
    organizations: [],
    loading: true,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {

        case 'GET_ORGANIZATIONS':
            return {
                ...state,
                organizations: action.payload,
                loading: false,

            }
        default: return state
    }

}