import { Menu, Button } from 'antd';
import React from 'react';

import {
    UilKeySkeleton, UilUserCircle, UilUserMinus, UilBan, UilTrash, UilImport, UilRedo, UilClipboardAlt, UilTimes, UilEdit,
    UilEditAlt, UilCheckCircle, UilSun, UilPlaneFly, UilBill, UilMoneyInsert, UilHouseUser, UilNewspaper, UilSync, UilCopy
} from '@iconscout/react-unicons';
import '../../styles/personel-page.scss';

export const other_proccess = (t, showModal) => (
    <Menu className="other-proccess-menu">
        <Menu.Item key="export"><Button onClick={() => showModal()} className="transparent-btn"><UilImport /> {t('SETTINGS_EXPORT')} </Button></Menu.Item>
    </Menu>
)
export const personListDropdown = (t, canDelete, canPassive, canDismall, canActive, id, name, permissions, status) => (
    <Menu key={id} className="table-detail-menu">
        {permissions.includes('person.delete') &&
            <Menu.Item onClick={() => canDelete(id, name)} key={"delete-"+id}><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
        {permissions.includes('person.canpassive') && status === 1 ?

            <Menu.Item onClick={() => canPassive(id, name)} key={"passive-"+id}><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilBan />}>{t('PARTNER_DO_PASSIVE')}</Button></Menu.Item>

            : ''}
        {permissions.includes('person.canpassive') && status === 2 ?

            <Menu.Item onClick={() => canActive(id, name)} key={"active-"+id}><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilBan />}>{t('DO_ACTIVE')}</Button></Menu.Item>

            : ''}
        {permissions.includes('person.candismissal') && status === 1 ?
            <Menu.Item onClick={() => canDismall(id, name)} key={"candismissal-"+id}><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilUserMinus />}>{t('DISMISS_PERSON')}</Button></Menu.Item>
            : ""}
    </Menu>
)
export const personDetailsDropdown = (t, canDelete, canPassive, canDismall, canActive, id, name, permissions, status) => (
    // Bu kısım sağda açılan menü içerisinde > işlemler > dropdown menü'ye gitmektedir.
    <Menu className="settings-dropdown">
        {permissions.includes('person.delete') &&
            <Menu.Item onClick={() => canDelete(id, name)} key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
        {permissions.includes('person.canpassive') && status === "1" ?
            <Menu.Item onClick={() => canPassive(id, name)} key="do_passive"><Button className="transparent-btn pad-0"><UilBan /> {t('PARTNER_DO_PASSIVE')}</Button></Menu.Item>
            : ""}
        {permissions.includes('person.canpassive') && status === "2" ?
            <Menu.Item onClick={() => canActive(id, name)} key="do_active"><Button className="transparent-btn pad-0"><UilBan /> {t('DO_ACTIVE')}</Button></Menu.Item>
            : ""}
        {permissions.includes('person.candismissal') && status === "1" ?
            <Menu.Item onClick={() => canDismall(id, name)} key="dismiss"><Button className="transparent-btn pad-0"><UilUserMinus />{t('DISMISS_PERSON')}</Button></Menu.Item>
            : ""}

    </Menu>
)
export const department_table_dropdown = (t, canDelete, canPassive, canActive, isActive, id, name, isHead, permissions) => (
    <Menu className="table-list-dropdown">
        {permissions?.includes('department.delete') &&
            <Menu.Item key="delete" onClick={() => canDelete(id, name)} ><Button className="transparent-btn"><UilTrash /> {t('SETTINGS_DELETE')} </Button></Menu.Item>
        }
        {(permissions?.includes('department.canpassive') && !isHead && isActive === true) &&
            <Menu.Item key="passive" onClick={() => canPassive(id, name)}><Button className="transparent-btn"><UilBan /> {t('PARTNER_DO_PASSIVE')} </Button></Menu.Item>
        }
        {(permissions?.includes('department.canpassive') && !isHead && isActive === false) &&
            <Menu.Item key="active" onClick={() => canActive(id, name)}><Button className="transparent-btn"><UilBan /> {t('DO_ACTIVE')} </Button></Menu.Item>
        }
    </Menu>
)

export const departmentDetailsDropdown = (t, canDelete, canPassive, canActive, isActive, id, name, isHeadDepartment, permissions) => (
    <Menu className="settings-dropdown">
        {permissions.includes('department.delete') &&
            <Menu.Item onClick={() => canDelete(id, name)} key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
        {(permissions.includes('department.canpassive') && !isHeadDepartment && isActive) &&
            <Menu.Item onClick={() => canPassive(id, name)} key="do_passive"><Button className="transparent-btn pad-0"><UilBan /> {t('PARTNER_DO_PASSIVE')}</Button></Menu.Item>
        }
        {(permissions?.includes('department.canpassive') && !isHeadDepartment && !isActive) &&
            <Menu.Item key="active" onClick={() => canActive(id, name)}><Button className="transparent-btn pad-0"><UilBan /> {t('DO_ACTIVE')} </Button></Menu.Item>
        }
    </Menu>
)
export const ownerShipListDropdown = (t, openReturnDrawer, resourceId, assigmentDate) => (
    <Menu className="table-list-dropdown">
        <Menu.Item onClick={() => openReturnDrawer(resourceId, assigmentDate)} key="return"><Button className="transparent-btn pad-0"><UilRedo /> {t('PARTNER_GET_A_REFUND')}</Button></Menu.Item>
    </Menu>
)
export const ownerShipPageListDropdown = (t, openReturnDrawer, exportPersonOwnerShip, personId, id, permissions, assigmentDate) => (
    <Menu className="table-list-dropdown">
        {permissions.includes('asset.return') &&
            <Menu.Item onClick={() => openReturnDrawer(id, assigmentDate)} key="return"><Button className="transparent-btn pad-0"><UilSync /> {t('PARTNER_GET_A_REFUND')}</Button></Menu.Item>
        }
        {permissions.includes('person.asset.list') &&
            <Menu.Item onClick={() => exportPersonOwnerShip(personId)} key="export"><Button className="transparent-btn pad-0"><UilImport /> {t('SETTINGS_EXPORT')}</Button></Menu.Item>
        }
    </Menu>
)
export const inventoryPageDropdown = (t, showModal) => (
    <Menu className="other-proccess-menu">
        <Menu.Item onClick={() => showModal()} key="export"><Button className="transparent-btn pad-0"><UilImport />  {t('SETTINGS_EXPORT')}</Button></Menu.Item>
    </Menu>
)
export const inventoryListDropdown = (t, canDelete, canPassive, setDoActiveModalData, doActiveModalData, setCreateOwnerShipDrawer, assigmentResourceId, isActive, statusId, permissions) => (
    <Menu className="table-detail-menu">
        {permissions.includes('inventory.delete') &&
            <Menu.Item onClick={() => canDelete(assigmentResourceId)} key="delete"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
        {permissions.includes('inventory.canpassive') &&
            (
                isActive ?
                    <Menu.Item onClick={() => canPassive(assigmentResourceId)} key="passive"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilBan />}>{t('PARTNER_DO_PASSIVE')}</Button></Menu.Item>
                    :
                    <Menu.Item onClick={() => setDoActiveModalData({ ...doActiveModalData, "isVisible": true })} key="active"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilBan />}>{t('DO_ACTIVE')}</Button></Menu.Item>
            )
        }
        {permissions.includes('inventory.canassign') &&
            (assigmentResourceId === null && statusId === 1 && isActive ?
                <Menu.Item onClick={() => setCreateOwnerShipDrawer(true)} key="ownership"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilClipboardAlt />}>{t('MAP_ASSET')}</Button></Menu.Item>
                :
                ''
            )
        }
    </Menu>
)
export const inventoryDetailsList = (t, canPassive, canDelete, setOwnerShipReturnDrawer, assigmentStatus, permissions) => (
    <Menu className="other-proccess-menu">
        {permissions.includes('inventory.delete') &&
            <Menu.Item onClick={() => canDelete(assigmentStatus)} key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('PARTNER_DELETE')}</Button></Menu.Item>
        }
        {permissions.includes('inventory.canpassive') &&

            <Menu.Item onClick={() => canPassive(assigmentStatus)} key="passive"><Button className="transparent-btn pad-0"><UilBan /> {t('PARTNER_DO_PASSIVE')}</Button></Menu.Item>
        }
        {permissions.includes('asset.return') &&
            (assigmentStatus === "2" ?
                <Menu.Item onClick={() => setOwnerShipReturnDrawer(true)} key="return"><Button className="transparent-btn pad-0"><UilRedo /> {t('PARTNER_GET_A_REFUND')}</Button></Menu.Item>
                : ''
            )}
    </Menu>
)
export const inventoryDetailsPassiveList = (t, setDoActiveModalData, canDelete, assigmentStatus, permissions) => (
    <Menu className="other-proccess-menu">
        {permissions.includes('inventory.canpassive') &&
            <Menu.Item onClick={() => setDoActiveModalData({ "isVisible": true })} key="active"><Button className="transparent-btn pad-0"><UilBan /> {t('DO_ACTIVE')}</Button></Menu.Item>
        }
        {permissions.includes('inventory.delete') &&
            <Menu.Item onClick={() => canDelete(assigmentStatus)} key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('PARTNER_DELETE')}</Button></Menu.Item>
        }
    </Menu>
)
export const salaryListDropdown = (t, canDelete, salaryId, salaryCount, permissions) => (
    <Menu className="table-detail-menu">
        {permissions.includes('person.salary.update') &&
            <Menu.Item onClick={() => canDelete(salaryId, salaryCount)} key="delete"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
    </Menu>
)
export const organization_details = (t) => (
    <Menu className="settings-dropdown">
        {/* <Menu.Item key="delete"><DeleteOutlined /> {t('SETTINGS_DELETE')}</Menu.Item>
        <Menu.Item key="do_passive"><StopOutlined /> {t('PARTNER_DO_PASSIVE')}</Menu.Item> */}
    </Menu>
)


export const organization_dropdown = (t, showModal) => (
    <Menu className="other-proccess-menu">
        <Menu.Item key="export"><Button onClick={() => showModal()} className="transparent-btn"><UilImport /> {t('SETTINGS_EXPORT')} </Button></Menu.Item>
    </Menu>
)
export const embezzlement_dropdown = (t, showModal) => (
    <Menu className="table-list-dropdown">
        <Menu.Item key="export"><Button onClick={() => showModal()} className="transparent-btn"><UilImport /> {t('SETTINGS_EXPORT')} </Button></Menu.Item>
    </Menu>
)

export const timeoff_dropdown = (t, timeOffCancelBtn, downloadAction, timeOffDeletelBtn, id, stateTypeId, timeOffTypeName, permissions) => (
    <Menu className="table-list-dropdown">
        {permissions.includes('timeoff.request.delete') &&
            <Menu.Item key="delete" onClick={() => timeOffDeletelBtn(id, timeOffTypeName)} ><Button className="transparent-btn"><UilTrash /> {t('SETTINGS_DELETE')} </Button></Menu.Item>
        }
        {((stateTypeId === "1" || stateTypeId === "2") && (permissions.includes('timeoff.request.cancel'))) &&
            <Menu.Item key="cancel" onClick={() => timeOffCancelBtn(id)} ><Button className="transparent-btn"><UilTimes /> {t('TIME_OFF_CANCEL_BTN')} </Button></Menu.Item>
        }
        {permissions.includes("timeoff.request.export") &&
            <Menu.Item key="download" onClick={() => downloadAction(id)} ><Button className="transparent-btn"><UilImport /> {t('TIME_OFF_DOWNLOAD')} </Button></Menu.Item>
        }
    </Menu>
)
export const expense_dropdown = (t, deleteModal, id) => (
    <Menu className="table-list-dropdown">
        <Menu.Item key="delete" onClick={() => deleteModal(id)} ><Button className="transparent-btn"><UilTrash /> {t('SETTINGS_DELETE')} </Button></Menu.Item>
    </Menu>
)
export const department_details = (t) => (
    <Menu className="table-list-dropdown">
    </Menu>
)
export const inventory_dropdown = (t, showModal) => (
    <Menu className="settings-dropdown">
        <Menu.Item key="reset_password"><Button className="transparent-btn pad-0"><UilKeySkeleton /> {t('RESET_PASSWORD')}</Button></Menu.Item>
        <Menu.Item key="export"><Button onClick={() => showModal()} className="transparent-btn pad-0"><UilImport /> {t('SETTINGS_EXPORT')} </Button></Menu.Item>
        <Menu.Item key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('SETTINGS_DELETE')}</Button></Menu.Item>
        <Menu.Item key="do_passive"><Button className="transparent-btn pad-0"><UilBan /> {t('PARTNER_DO_PASSIVE')}</Button></Menu.Item>
        <Menu.Item key="get_fired"><Button className="transparent-btn pad-0"><UilUserMinus />{t('DISMISS_PERSON')}</Button></Menu.Item>
        <Menu.Item key="login_with"><Button className="transparent-btn pad-0"><UilUserCircle /> {t('SIGN_WITH_PERSON')}</Button></Menu.Item>
    </Menu>
)
export const workHoursListDropdown = (t, edit, makeDefault, deleteRequest, name, id, isDefault, popover = false) => (
    <Menu className={popover ? 'settings-dropdown translate-y-20' : 'settings-dropdown'}>
        <Menu.Item className={popover ? 'settings-dropdown dropdown-popover' : 'settings-dropdown'} onClick={() => { edit(id) }} key="edit"><Button className="transparent-btn pad-0"><UilEditAlt /> {t('PARTNER_EDIT')}</Button></Menu.Item>
        {!isDefault &&
            <React.Fragment>
                <Menu.Item onClick={() => { makeDefault(id, name) }} key="make_default"><Button className="transparent-btn pad-0"><UilCheckCircle /> {t('MAKE_DEFAULT')}</Button></Menu.Item>
                <Menu.Item onClick={() => { deleteRequest(id, name) }} key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('SETTINGS_DELETE')}</Button></Menu.Item>
            </React.Fragment>
        }
    </Menu>
)
export const tableDeletelistDropdown = (t, canDelete, advanceId) => (
    <Menu className="table-detail-menu">
        <Menu.Item onClick={() => canDelete(advanceId)} key="delete"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
    </Menu>
);
export const travelListDropdown = (t, canDelete, resourceId) => (
    <Menu className="table-detail-menu">
        <Menu.Item onClick={() => canDelete(resourceId)} key="delete"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
    </Menu>
);
export const expenseListDropdown = (t, canDelete, downloadExpenseDetail, expenseId, stateTypeId, permissions) => (
    <Menu className="table-list-dropdown">
        {(permissions.includes('timeoff.request.delete')) &&
            <Menu.Item onClick={() => canDelete(expenseId)} key="delete"><Button className="transparent-btn" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
        {(stateTypeId === "2" || stateTypeId === "5") &&
            <Menu.Item key="download" onClick={() => downloadExpenseDetail(expenseId)} ><Button className="transparent-btn"><UilImport /> {t('SETTINGS_EXPORT')} </Button></Menu.Item>
        }
    </Menu>
)
export const myExpenseListDropdown = (t, canDelete, downloadExpenseDetail, expenseId, stateTypeId, isDraft) => (
    <Menu className="table-list-dropdown">
        {isDraft &&
            <Menu.Item onClick={() => canDelete(expenseId)} key="delete"><Button className="transparent-btn" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
        {(stateTypeId === "2" || stateTypeId === "5") &&
            <Menu.Item key="download" onClick={() => downloadExpenseDetail(expenseId)} ><Button className="transparent-btn"><UilImport /> {t('SETTINGS_EXPORT')} </Button></Menu.Item>

        }
    </Menu>
)
export const myTravelHostDropdown = (t, canDelete, resourceId) => (
    <Menu className="table-list-dropdown">
        <Menu.Item onClick={() => canDelete(resourceId)} key="delete"><Button className="transparent-btn" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
    </Menu>
)
export const travelHostListDropdown = (t, canDelete, resourceId, permissions) => (
    <Menu className="table-list-dropdown">
        {permissions.includes('timeoff.request.delete') &&
            <Menu.Item onClick={() => canDelete(resourceId)} key="delete"><Button className="transparent-btn" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
    </Menu>
)
export const advanceListDropdown = (t, canDelete, advanceId, permissions) => (
    <Menu className="table-detail-menu">
        {permissions.includes('timeoff.request.delete') &&
            <Menu.Item onClick={() => canDelete(advanceId)} key="delete"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilTrash />}>{t('SETTINGS_DELETE')}</Button></Menu.Item>
        }
    </Menu>
)
export const homePageDropdown = (t, permissions, setTimeOffAddDrawer, createTravelRequest, createAdvanceRequest, createExpenseRequest, createTravelHostRequest) => (
    <Menu className="homepage-dropdown-menu">
        {permissions.includes('timeoff.request.add') &&
            <Menu.Item onClick={() => setTimeOffAddDrawer(true)} key="createTimeOff"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilSun />}>{t('TIME_OFF_REQUEST_TITLE')}</Button></Menu.Item>
        }
        {permissions.includes('timeoff.request.add') &&
            <Menu.Item onClick={() => createTravelRequest()} key="createTravel"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilPlaneFly />}>{t('TRAVEL_REQUEST')}</Button></Menu.Item>
        }
        {permissions.includes('timeoff.request.add') &&
            <Menu.Item onClick={() => createAdvanceRequest()} key="createAdvanceRequest"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilMoneyInsert />}>{t('ADVANCE_REQUEST')}</Button></Menu.Item>
        }
        {permissions.includes('timeoff.request.add') &&
            <Menu.Item onClick={() => createExpenseRequest()} key="createExpenseRequest"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilBill />}>{t('EXPENSE_REQUEST')}</Button></Menu.Item>
        }
        {permissions.includes('timeoff.request.add') &&
            <Menu.Item onClick={() => createTravelHostRequest()} key="createTravelHostRequest"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilHouseUser />}>{t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST')}</Button></Menu.Item>
        }

    </Menu>
)
export const approvalProcessesListDropdown = (t, deleteModal, id, name, isDefault, openDetailDraw, popover = false) => (
    <Menu className={popover ? 'table-list-dropdown popover' : 'table-list-dropdown'} >
        <Menu.Item key="view" onClick={() => openDetailDraw(id)} ><Button className="transparent-btn"><UilNewspaper /> {t('VIEW_DETAIL')}</Button></Menu.Item>
        {!isDefault &&
            <Menu.Item key="delete" onClick={() => deleteModal(id, name)} ><Button className="transparent-btn"><UilTrash /> {t('SETTINGS_DELETE')} </Button></Menu.Item>
        }
    </Menu >
)
export const approvalProcessesDetailDropdown = (t, deleteModal, id, name) => (
    <Menu className="other-proccess-menu">
        <Menu.Item onClick={() => deleteModal(id, name)} key="delete"><Button className="transparent-btn pad-0"><UilTrash /> {t('PARTNER_DELETE')}</Button></Menu.Item>
    </Menu>
)
export const timeoffTypeDropdown = (t, menuItems, confirmDeleteModal, timeoffTypeId, copyTimeoffType, editTimeoffType, editSettingsTimeoffType) => (
    <Menu className="table-detail-menu timeoff-types-table-dropdown-menu">
        {menuItems.includes('edit') && <Menu.Item onClick={() => editTimeoffType(timeoffTypeId)} key="edit"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilEditAlt />}>{t('TIMEOFF_TYPE_EDIT')}</Button></Menu.Item>}
        {menuItems.includes('editSettings') && <Menu.Item onClick={() => editSettingsTimeoffType(timeoffTypeId)} key="editSettings"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilEdit />}>{t('TIMEOFF_TYPE_EDIT_SETTINGS')}</Button></Menu.Item>}
        {menuItems.includes('copy') && <Menu.Item onClick={() => copyTimeoffType(timeoffTypeId)} key="copy"><Button className="transparent-btn pad-0 unicons-page-dropdown" icon={<UilCopy />}>{t('TIMEOFF_TYPE_COPY')}</Button></Menu.Item>}
        {menuItems.includes('delete') && <Menu.Item onClick={() => confirmDeleteModal(timeoffTypeId)} key="delete"><Button className="transparent-btn pad-0 delete-btn unicons-page-dropdown" icon={<UilTrash />}>{t('TIMEOFF_TYPE_DELETE')}</Button></Menu.Item>}
    </Menu>
);
