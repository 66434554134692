import HomePage from './pages/homepage/index';
import Reports from './pages/reports/index';
import Person from './pages/person/index';
import Users from './pages/users/index';
import Subscription from './pages/subscription/index';
import EmailSignatureTemplates from './pages/email-signature-template/index';
import Organization from './pages/organization/index';
import Department from './pages/department/index';
import OwnerShipInfo from './pages/ownership-info/index';
import TimeOff from './pages/time-off/index';
import Advance from './pages/advance/index';
import Expense from './pages/expense/index';
import InventoryType from './pages/inventory-type/index';
import Service from './pages/service/index';
import Invoice from './pages/invoice/index';
import Bank from './pages/bank/index';
import Profile from './pages/profile/index';
import Privacy from './pages/privacy/index';
import Help from './pages/help/index';
import HelpDetail from './pages/help/detail';
import Contact from './pages/contact/index';
import Account from './pages/account/index';
import Inventory from './pages/inventory/index';
import Travel from './pages/travel-requests/index';
import Health from './pages/health/index';
import Settings from './pages/settings/index';
import EmployeeSummary from './pages/employee-summary/index';
import TimeOffAllSummary from './pages/time-off-all-summary';
import TimeOffYearsSummary from './pages/time-off-years-summary';
import TravelHostRequest from './pages/travelHostRequest'
import Tasks from './pages/task-tabs';
import EducationMe from './pages/education/education-me';
import EducationDetails from './pages/education/education-details';
import EducationList from './pages/education/education-list';
import Prices from './pages/checkout/prices';
import Payment from './pages/checkout/payment';
import NewPackagePayment from './pages/checkout/newPackagePayment';
import PaymentCompleted from './pages/checkout/paymentCompleted';

const routeList = [
  { path: "/homepage", component: HomePage },
  { path: "/reports", component: Reports },
  { path: "/profile", component: Profile },
  { path: "/person", component: Person },
  { path: "/users", component: Users },
  { path: "/subscription", component: Subscription },
  { path: "/template", component: EmailSignatureTemplates },
  { path: "/department", component: Department },
  { path: "/organization", component: Organization },
  { path: "/ownership-info", component: OwnerShipInfo },
  { path: "/time-off/", component: TimeOff },
  { path: "/time-off/me", component: TimeOff },
  { path: "/time-off/person", component: TimeOff },
  { path: "/time-off/people", component: TimeOff },
  { path: "/time-off/remainder-years", component: TimeOff },
  { path: "/time-off/all", component: TimeOff },
  { path: "/advance", component: Advance },
  { path: "/expense", component: Expense },
  { path: "/travel-requests", component: Travel },
  { path: "/inventory-type", component: InventoryType },
  { path: "/service", component: Service },
  { path: "/invoice", component: Invoice },
  { path: "/bank", component: Bank },
  { path: "/help/", component: Help },
  { path: "/help/detail", component: HelpDetail },
  { path: "/contact", component: Contact },
  { path: "/account", component: Account },
  { path: "/inventory", component: Inventory },
  { path: "/privacy", component: Privacy },
  { path: "/health", component: Health },
  { path: "/settings", component: Settings },
  { path: "/employee-summary", component: EmployeeSummary },
  { path: "/time-off-all-summary", component: TimeOffAllSummary },
  { path: "/time-off-years-summary", component: TimeOffYearsSummary },
  { path: "/travel-host-request", component: TravelHostRequest },
  { path: "/tasks/description", component: Tasks },
  { path: "/tasks/me", component: Tasks },
  { path: "/tasks/assigned", component: Tasks },
  { path: "/education/education-list", component: EducationList },
  { path: "/education/me", component: EducationMe },
  { path: "/education/me/details/:resourceId", component: EducationDetails },
  { path: "/prices", component: Prices },
  { path: "/payment", component: Payment },
  { path: "/newPackagePayment", component: NewPackagePayment },
  { path: "/paymentCompleted/:transactionId", component: PaymentCompleted },
]

export default routeList
