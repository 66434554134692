import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRoles } from '../../../../redux/actions/roles.actions';
import { getWorkHourDepartmentInPersons } from "../../../../redux/actions/organization.actions";
import { setDetailDefinedTaskDrawer, updateAssignedTasks, resetUpdateAssignedTasks, getAssignedTaskDetails } from '../../../../redux/actions/task.actions';

import { Button, List, Form, ConfigProvider, Input, TreeSelect, Spin } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import DynamicSelectbox from "../../../dynamicSelectbox/index";
import { customFilter } from "../../../../utils/commonFormFunctions";
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/left-step.scss';
import '../../../../styles/table.scss';

function DetailDefinedTasks(props) {
    const { setDetailDefinedTaskDrawer, getRoles, roles, getWorkHourDepartmentInPersons, updateAssignedTasks,
        updateAssignedTasksResponse, resetUpdateAssignedTasks, assignedTaskDetails, assignedTaskDetailsLoading,
        workHourDepartmentInPerson, getAssignedTaskDetails } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { SHOW_CHILD } = TreeSelect;
    const [detailDefinedTaskRequestState, setDetailDefinedTaskRequestState] = useState({
        taskName: "",
        taskDescription: "",
        resourceId: "",
        ownerName: "",
        ownerType: 0,
        role: {
            "resourceId": "",
            "displayValue": "",
            "ownerShipTypeId": 0,
            "ownerShipName": ""
        },
        person: {
            "resourceId": null,
            "displayValue": null,
            "ownerShipTypeId": 0,
            "ownerShipName": null
        },
        attributes: [],
    });
    const [featureListState, setFeatureListState] = useState({ data: [], delete: [] });
    const [taskOwnerListState, setTaskOwnerListState] = useState([]);
    const [personListState, setPersonListState] = useState([]);

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === "roleType") {
            if (changedObj.roleType?.id) {
                if (changedObj.roleType?.id === "1") {
                    setDetailDefinedTaskRequestState({ ...detailDefinedTaskRequestState, roleType: changedObj['roleType'], ownerShipType: "1" });
                } else {
                    setDetailDefinedTaskRequestState({ ...detailDefinedTaskRequestState, roleType: changedObj['roleType'], ownerShipType: "2" });
                }
            } else {
                setDetailDefinedTaskRequestState({ ...detailDefinedTaskRequestState, roleType: null, ownerShipType: "" });
                const fields = form.getFieldsValue()
                const formFields = { ...fields };
                formFields["roleType"] = null;
                form.setFieldsValue(formFields);
            }
        } else {
            setDetailDefinedTaskRequestState({ ...detailDefinedTaskRequestState, ...changedObj });
        }
    }
    const addFeature = () => {
        const newFeatureList = [...featureListState.data];
        let requiredFields = {
            'featureName': false,
        }
        let hasError = false
        let fields = []
        newFeatureList.map((data) => {
            if (!data.name) {
                requiredFields.featureName = true;
            }
        });
        if (requiredFields.featureName) {
            hasError = true
            fields.push(t('FEATURE_NAME'));
        }
        newFeatureList.push({ "resourceId": "", "name": "" });
        if (!hasError) {
            setFeatureListState({ ...featureListState, data: newFeatureList });
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleRemoveClick = (index) => {
        const list = [...featureListState.data];
        const deleteList = [...featureListState.delete];
        if (list.length > 1) {
            deleteList.push(list[index]);
            list.splice(index, 1);
        } else {
            deleteList.push(list[index]);
            list.splice(index, 1);
        }
        setFeatureListState({ data: list, delete: deleteList });
    }

    const handleFeatureListChange = (e, index) => {
        const list = [...featureListState.data];
        const val = e.target.value;
        list[index].name = val;
        return setFeatureListState({ ...featureListState, data: list });
    }
    function destroyLastState() {
        setDetailDefinedTaskDrawer(false);
        setDetailDefinedTaskRequestState({
            taskName: "",
            roleType: { "id": null, "displayValue": "" },
            taskDescription: "",
            taskPerson: ""
        });
        setFeatureListState({ data: [], delete: [] });
        form.resetFields();
    }

    const [isUpdateLoading, setisUpdateLoading] = useState(false);

    const handleCreateTaskRequest = () => {

        if(isUpdateLoading) return;
        setisUpdateLoading(true);
        setTimeout(() => {setisUpdateLoading(false);}, 1055) // double click prevent;

        let hasError = false
        let fields = []
        if (!detailDefinedTaskRequestState.taskName) {
            hasError = true
            fields.push(t('TASK_NAME'))
        }
        if (!detailDefinedTaskRequestState.roleType?.id) {
            hasError = true
            fields.push(t('TASK_OWNER'))
        }
        if (!detailDefinedTaskRequestState.taskPerson && detailDefinedTaskRequestState.roleType?.id === "1") {
            hasError = true
            fields.push(t('PERSON_CHOOSE_PERSON'))
        }
        if (featureListState?.data.length > 0 && featureListState?.data.some(item => !item.name)) {
            hasError = true
            fields.push(t('ENTER_FEATURE_NAME'))
        }
        if (!hasError) {
            const requestData = {
                taskResourceId: detailDefinedTaskRequestState.resourceId,
                taskName: detailDefinedTaskRequestState.taskName,
                ownerShipType: detailDefinedTaskRequestState.ownerShipType,
                roleResourceId: detailDefinedTaskRequestState.roleType?.id,
                personResourceId: detailDefinedTaskRequestState.taskPerson ?? "",
                taskDescription: detailDefinedTaskRequestState.taskDescription,
                attributes: featureListState.data.length > 0 ? featureListState.data : [],
                deletedAttributes: featureListState.delete
            }
            updateAssignedTasks(requestData);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    useEffect(() => {
        if (updateAssignedTasksResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': updateAssignedTasksResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            getAssignedTaskDetails(detailDefinedTaskRequestState.resourceId);
            resetUpdateAssignedTasks();
        }
        else if (updateAssignedTasksResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateAssignedTasksResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetUpdateAssignedTasks();
        }
    }, [updateAssignedTasksResponse])
    useEffect(() => {
        if (workHourDepartmentInPerson.length === 0) {
            getWorkHourDepartmentInPersons();
        }
    }, [workHourDepartmentInPerson])
    useEffect(() => {
        if (roles.length > 0) {
            var list = [];
            roles.map(data => data.resourceId !== "p3PmYkNDeb8tvEs" && list.push({ id: data.resourceId, displayValue: data.roleName }));
            list.push({ id: "1", displayValue: t('SELECT_PERSON') });
            setTaskOwnerListState(list)
        }
    }, [roles])
    useEffect(() => {
        if (assignedTaskDetails.taskName) {
            const data = {
                taskName: assignedTaskDetails.taskName,
                taskDescription: assignedTaskDetails.taskDescription,
                roleType: { id: assignedTaskDetails.role?.resourceId, displayValue: assignedTaskDetails.role?.displayValue },
                taskPerson: assignedTaskDetails?.person?.resourceId ?? "",
                ownerShipType: assignedTaskDetails?.ownerShipTypeId,
                resourceId: assignedTaskDetails.resourceId
            };
            setDetailDefinedTaskRequestState(data);
            form.setFieldsValue(data);
            var newFeatureList = [];
            assignedTaskDetails.attributes.length > 0 && assignedTaskDetails.attributes.map(data => newFeatureList.push({ resourceId: data.resourceId, name: data.attributeName }))
            setFeatureListState({ ...featureListState, data: newFeatureList });
        }
    }, [assignedTaskDetails])
    useEffect(() => {
        var newList = [];
        if (workHourDepartmentInPerson.length > 0) {
            workHourDepartmentInPerson.map(data => newList.push({
                title: data.departmentName,
                value: data.departmentId,
                key: data.departmentId,
                disabled: true,
                children: data.persons.map((datax) => {
                    return {
                        title: datax.givenName + ' ' + datax.familyName,
                        value: datax.resourceId,
                        key: datax.resourceId
                    }
                })
            }));
            setPersonListState(newList);
        }
    }, [workHourDepartmentInPerson])
    return (
        assignedTaskDetailsLoading ?
            <Spin className="tab-loading" />
            :
            <>
                <div className="table-details">
                    <div className="add-global-close-icon">
                        <UilMultiply onClick={() => destroyLastState()} />
                    </div>
                    <>
                        <div className="add-global-draw-title flex-start">
                            <h1>{t('JOB_DESCRIPTION')}</h1>
                        </div>
                        <div className="w-100 pt-4">
                            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                                <Form
                                    form={form}
                                    onValuesChange={handleValuesChange}
                                    layout="vertical travel-scrollable travel-host-container"
                                    requiredMark={true}
                                >
                                    <Form.Item name="taskName" label={t('TASK_NAME') + " *"} >
                                        <Input placeholder={t('ENTER_TASK_NAME')} />
                                    </Form.Item>
                                    <Form.Item name="roleType" className="custom-select" label={t('TASK_OWNER') + " *"} >
                                        <DynamicSelectbox
                                            optionList={taskOwnerListState}
                                            placeholder={t('SELECT_TASK_OWNER')}
                                            onFocus={() => getRoles()}
                                        />
                                    </Form.Item>
                                    {detailDefinedTaskRequestState?.roleType?.id === "1" &&
                                        <Form.Item name="taskPerson" className="custom-select" label={t('PERSON_CHOOSE_PERSON') + " *"} >
                                            <TreeSelect
                                                showSearch={true}
                                                treeData={personListState}
                                                showCheckedStrategy={SHOW_CHILD}
                                                placeholder={t('PARTNER_SELECT')}
                                                treeDefaultExpandAll={true}
                                                filterTreeNode={(search, item) => customFilter(search, item)}
                                            />
                                        </Form.Item>
                                    }
                                    <Form.Item name="taskDescription" className="custom-select" label={t('MISSION_DESCRIPTION')} >
                                        <Input.TextArea placeholder={t('ENTER_TASK_DESCRIPTION')} />
                                    </Form.Item>

                                    <Button onClick={addFeature} className="transparent-btn w-180 f-16 pa-0 ma-0 text-start">{t('ADD_FEATURE')}</Button>
                                    {featureListState.data?.length > 0 &&
                                        <div className="w-100 d-flex pt-4 ant-form-item-label">
                                            <label>{t('FEATURE_NAME')}</label>
                                        </div>
                                    }
                                    {
                                        featureListState.data.map((item, index) => {
                                            return (
                                                <div key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": '30px' }} >
                                                    <Input
                                                        value={item?.name}
                                                        onChange={(e) => handleFeatureListChange(e, index)}
                                                        placeholder={t('ENTER_FEATURE_NAME')}
                                                    />
                                                    <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <div className="d-flex flex-end">
                                        <Button onClick={addFeature} className="pad-0 f-16 transparent-btn">{t('PARTNER_ADD')}</Button>
                                    </div> */}
                                </Form>
                            </ConfigProvider>
                        </div>
                    </>
                </div>
                <div className="add-user-draw-footer">
                    <Button onClick={() => destroyLastState()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    <div className="add-user-draw-footer-end">
                        <div className="pad-left-24" />
                        <Button onClick={handleCreateTaskRequest} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('PARTNER_SAVE')}</Button>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        roles: state.roles.roles,
        updateAssignedTasksResponse: state.taskReducer.updateAssignedTasksResponse,
        assignedTaskDetails: state.taskReducer.assignedTaskDetails,
        assignedTaskDetailsLoading: state.taskReducer.assignedTaskDetailsLoading,
        workHourDepartmentInPerson: state.organization.workHourDepartmentInPerson,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setDetailDefinedTaskDrawer: (props) => { dispatch(setDetailDefinedTaskDrawer(props)) },
        getRoles: () => { dispatch(getRoles()) },
        getWorkHourDepartmentInPersons: () => { dispatch(getWorkHourDepartmentInPersons()) },
        updateAssignedTasks: (data) => { dispatch(updateAssignedTasks(data)) },
        resetUpdateAssignedTasks: () => { dispatch(resetUpdateAssignedTasks()) },
        getAssignedTaskDetails: (resourceId) => { dispatch(getAssignedTaskDetails(resourceId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailDefinedTasks);