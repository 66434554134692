import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir


import { Form, Input, DatePicker, ConfigProvider } from 'antd';
import Checkbox from "antd/lib/checkbox/Checkbox";
import MaskedInput from 'react-text-mask'
import InputMask from "react-input-mask";


import DynamicSelectbox from "../../dynamicSelectbox/index";
import { nationalIdentityNumberMask, NumberList, inputMaskDateBirthValidation, inputMaskShowFormatter, focusDateInput, blurDateInput } from '../../../utils/commonFormFunctions'
import { dateFormat, dateFormatList, maskInputFormatterFormLabel, dateInputMask, localeLang, dynamicDateInputFormatter } from '../../../utils/config';
import { UilExclamationTriangle } from '@iconscout/react-unicons'


import { getDataset } from '../../../redux/actions/dataset-action';
import { getNationalities } from '../../../redux/actions/address.actions';


function AddPersonInformation(props) {

    const [createPersonState, setCreatePersonState] = useState({});
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { setCreateObject, getDataset, getNationalities, genders, nationalities, maritalStatusTypes,
        educationDegries, coupleWorkStatuses, bloodGroups, disabilityDegries, languageTypes,
        militaryServiceTypes, drivingLicenceTypes } = props;

    // SELECT DATA

    const [genderList, setGenderList] = useState([]);
    const [nationalityList, setNationalityList] = useState([]);
    const [maritalList, setMaritalList] = useState([]);
    const [educationDegreeList, setEducationDegreeList] = useState([]);
    const [coupleWorkList, setCoupleWorkList] = useState([]);
    const [bloodGroupList, setBloodGroupList] = useState([]);
    const [disabiltyDegreeList, setDisabiltyDegreeList] = useState([]);
    const [langList, setLangList] = useState([]);

    const [childirenList, setChildirenList] = useState(NumberList(10));

    const [drivingLicenceTypesList, setDrivingLicenceTypesList] = useState([]);
    const [militaryServiceTypesList, setMilitaryServiceTypesList] = useState([]);
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "MaritalDate": dateFormatList,
        "CoupleBirthDate": dateFormatList,
    })
    const [customDateInputPlaceHolder, setCustomDateInputPlaceHolder] = useState({
        "birthday": false,
    })
    const [customDateInputErrorStatus, setCustomDateInputErrorStatus] = useState({
        "birthday": false,
    })

    function disabledDate(current) {
        //return current && current < moment().endOf('day');
        return current && current > moment().add(-1, 'day');
    }
    useEffect(() => {
        setGenderList(genders);
    }, [genders]);

    useEffect(() => {
        setNationalityList(nationalities);
    }, [nationalities]);

    useEffect(() => {
        setMaritalList(maritalStatusTypes);
    }, [maritalStatusTypes]);

    useEffect(() => {
        setEducationDegreeList(educationDegries);
    }, [educationDegries]);

    useEffect(() => {
        setCoupleWorkList(coupleWorkStatuses);
    }, [coupleWorkStatuses]);

    useEffect(() => {
        setBloodGroupList(bloodGroups);
    }, [bloodGroups]);

    useEffect(() => {
        setDisabiltyDegreeList(disabilityDegries);
    }, [disabilityDegries]);

    useEffect(() => {
        setLangList(languageTypes);
    }, [languageTypes]);

    useEffect(() => {
        setMilitaryServiceTypesList(militaryServiceTypes);
    }, [militaryServiceTypes]);

    useEffect(() => {
        setDrivingLicenceTypesList(drivingLicenceTypes);
    }, [drivingLicenceTypes]);


    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'gender') {
            if (changedObj.gender?.id === '1') {
                setCreatePersonState({ ...createPersonState, "gender": changedObj["gender"]["id"] });
            } else {
                setCreatePersonState({ ...createPersonState, "gender": changedObj["gender"]["id"], militaryStatusType: null });
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["militaryStatusType"] = { 'id': null, 'displayValue': null };
                form.setFieldsValue(fieldsObj)
            }
        } else if (key === 'nationalityId') {
            setCreatePersonState({ ...createPersonState, "nationalityId": changedObj["nationalityId"]["id"] });
        } else if (key === 'maritalStatus') {
            if (changedObj.maritalStatus?.id === '2') {
                setCreatePersonState({ ...createPersonState, "maritalStatus": changedObj["maritalStatus"]["id"] });
            } else {
                setCreatePersonState({
                    ...createPersonState,
                    'maritalStatus': changedObj['maritalStatus']["id"],
                    'coupleWorkStatus': null,
                    'MaritalDate': null,
                    'CoupleBirthDate': null
                });
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["coupleWorkStatus"] = { 'id': null, 'displayValue': null };
                fieldsObj["MaritalDate"] = null;
                fieldsObj["CoupleBirthDate"] = null;
                fieldsObj["maritalStatus"] = { 'id': changedObj.maritalStatus?.id, 'displayValue': changedObj.maritalStatus?.displayValue };
                form.setFieldsValue(fieldsObj)
            }
        } else if (key === 'educationDegree') {
            setCreatePersonState({ ...createPersonState, "educationDegree": changedObj["educationDegree"]["id"] });
        } else if (key === 'childrenCount') {
            setCreatePersonState({ ...createPersonState, "childrenCount": changedObj["childrenCount"]["id"] });
        } else if (key === 'coupleWorkStatus') {
            setCreatePersonState({ ...createPersonState, "coupleWorkStatus": changedObj["coupleWorkStatus"]["id"] });
        } else if (key === 'bloodGroup') {
            setCreatePersonState({ ...createPersonState, "bloodGroup": changedObj["bloodGroup"]["id"] });
        } else if (key === 'disabilityDegree') {
            setCreatePersonState({ ...createPersonState, "disabilityDegree": changedObj["disabilityDegree"]["id"] });
        } else if (key === 'languages') {
            const langArray = changedObj["languages"].map((item) => item.id)
            setCreatePersonState({ ...createPersonState, "languages": langArray });
        } else if (key === 'birthday') {
            setCreatePersonState({ ...createPersonState, birthday: changedObj['birthday'] });
        } else if (key === "militaryStatusType") {
            setCreatePersonState({ ...createPersonState, "militaryStatusType": changedObj["militaryStatusType"]["id"] });
        } else if (key === "drivingLicenceType") {
            setCreatePersonState({ ...createPersonState, "drivingLicenceType": changedObj["drivingLicenceType"]["id"] });
        } else if (key === 'CoupleBirthDate') {
            setCreatePersonState({ ...createPersonState, "CoupleBirthDate": moment(changedObj['CoupleBirthDate']).format(dateFormat) });
        } else if (key === 'MaritalDate') {
            setCreatePersonState({ ...createPersonState, "MaritalDate": moment(changedObj['MaritalDate']).format(dateFormat) });
        } else {
            setCreatePersonState({ ...createPersonState, ...changedObj });
        }



    }

    useEffect(() => {
        setCreateObject(createPersonState)
    }, [createPersonState]);

    useEffect(() => {
        setCreatePersonState({ "nationalityId": "224" })
        form.setFieldsValue({
            "nationalityId": { "id": "224", "displayValue": "Türkiye" },
        })

    }, [form])

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
                requiredMark={false}
            >
                <Form.Item name="birthday" label={customDateInputPlaceHolder.birthday ? t('PARTNER_BIRTHDAY') + " " + maskInputFormatterFormLabel : t('PARTNER_BIRTHDAY')} className={customDateInputErrorStatus.birthday && "table-details-error-form-class"} required >
                    <InputMask
                        className="ant-input"
                        onFocus={() => inputMaskShowFormatter('birthday', customDateInputPlaceHolder, setCustomDateInputPlaceHolder)}
                        onBlur={() => inputMaskDateBirthValidation('birthday', customDateInputPlaceHolder, setCustomDateInputPlaceHolder, customDateInputErrorStatus, setCustomDateInputErrorStatus, null, createPersonState.birthday, maskInputFormatterFormLabel)}
                        mask={dateInputMask} placeholder={t('PLACEHOLDER_DATE')} />
                </Form.Item>
                {customDateInputErrorStatus.birthday &&
                    <div className="table-details-error-text">
                        <UilExclamationTriangle /> <span>{t('INVALID_DATE')}</span>
                    </div>
                }
                <Form.Item name="gender" className="custom-select" label={t('PARTNER_GENDER')}>
                    <DynamicSelectbox optionList={genderList} onFocus={() => getDataset(2)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                {createPersonState?.gender === "1" &&
                    <Form.Item name="militaryStatusType" className="custom-select" label={t('MILITARY_STATUS_TYPE')}>
                        <DynamicSelectbox optionList={militaryServiceTypesList} onFocus={() => getDataset(33)} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                    </Form.Item>
                }
                <Form.Item name="nationalityId" className="custom-select" label={t('PARTNER_NATIONALITY')}>
                    <DynamicSelectbox optionList={nationalityList} onFocus={() => getNationalities()} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="NationalIdentityNumber" label={t('PARTNER_NATIONAL_ID')}>
                    <MaskedInput className="ant-input" mask={nationalIdentityNumberMask} />
                </Form.Item>
                <Form.Item name="PassportNumber" label={t('PARTNER_PASSAPORT_NUMBER')}>
                    <Input placeholder="" maxLength={11} />
                </Form.Item>
                <Form.Item name="ibanNumber" label={t('PARTNER_IBAN')}>
                    <Input placeholder="" maxLength={26} />
                </Form.Item>
                <Form.Item name="educationDegree" className="custom-select" label={t('PARTNER_EDUCATION_STATUS')}>
                    <DynamicSelectbox optionList={educationDegreeList} onFocus={() => getDataset(15)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>

                <Form.Item name="drivingLicenceType" className="custom-select" label={t('DRIVING_LICENCE_TYPE')}>
                    <DynamicSelectbox optionList={drivingLicenceTypesList} onFocus={() => getDataset(32)} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>

                {createPersonState?.drivingLicenceType &&
                    <Form.Item name="DrivingLicenceNumber" label={t('DRIVING_LICENCE_NUMBER')}>
                        <Input placeholder="" maxLength={11} />
                    </Form.Item>
                }

                <Form.Item name="maritalStatus" className="custom-select" label={t('PARTNER_MARITAL_STATUS')}>
                    <DynamicSelectbox optionList={maritalList} onFocus={() => getDataset(11)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                {createPersonState?.maritalStatus === "2" && (
                    <React.Fragment>
                        <Form.Item name="MaritalDate" label={datePickerFormatType.MaritalDate === dynamicDateInputFormatter ? t('MARITAL_DATE') + " " + t('PLACEHOLDER_DATE') : t('MARITAL_DATE')}>
                            <DatePicker
                                locale={localeLang}
                                format={datePickerFormatType.MaritalDate}
                                disabledDate={disabledDate}
                                showToday={false}
                                placeholder={t('PLACEHOLDER_DATE')}
                                onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'MaritalDate', dynamicDateInputFormatter)}
                                onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'MaritalDate', dateFormatList)}
                            />
                        </Form.Item>
                        <Form.Item name="CoupleBirthDate" label={datePickerFormatType.CoupleBirthDate === dynamicDateInputFormatter ? t('COUPLE_BIRTH_DATE') + " " + t('PLACEHOLDER_DATE') : t('COUPLE_BIRTH_DATE')}>
                            <DatePicker
                                locale={localeLang}
                                format={datePickerFormatType.CoupleBirthDate}
                                disabledDate={disabledDate}
                                showToday={false}
                                placeholder={t('PLACEHOLDER_DATE')}
                                onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'CoupleBirthDate', dynamicDateInputFormatter)}
                                onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'CoupleBirthDate', dateFormatList)}
                            />
                        </Form.Item>
                        <Form.Item name="coupleWorkStatus" className="custom-select" label={t('PARTNER_COUPLE_WORK')}>
                            <DynamicSelectbox optionList={coupleWorkList} onFocus={() => getDataset(12)} orderBy={'none'} placeholder={t('PARTNER_SELECT')}></DynamicSelectbox>
                        </Form.Item>
                    </React.Fragment>
                )}
                <Form.Item name="childrenCount" className="custom-select" label={t('PARTNER_CHILDREN_COUNT')}>
                    <DynamicSelectbox optionList={childirenList} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="bloodGroup" className="custom-select" label={t('PARTNER_BLOOD_GROUP')}>
                    <DynamicSelectbox optionList={bloodGroupList} onFocus={() => getDataset(14)} orderBy={'none'} placeholder={t('PARTNER_SELECT')}></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="disabilityDegree" className="custom-select" label={t('PARTNER_DISABILITY_DEGREE')}>
                    <DynamicSelectbox optionList={disabiltyDegreeList} onFocus={() => getDataset(13)} orderBy={'none'} placeholder={t('PARTNER_SELECT')}></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="languages" className="custom-select" label={t('PARTNER_LANGUAGE')}>
                    <DynamicSelectbox isMultiple={true} optionList={langList} onFocus={() => getDataset(22)} orderBy={'none'} placeholder={t('PARTNER_SELECT')} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="isStudent" valuePropName="checked">
                    <Checkbox >{t('PARTNER_STUDENT')}</Checkbox>
                </Form.Item>
            </Form>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        genders: state.dataset.genders,
        nationalities: state.addressReducer.nationalities,
        maritalStatusTypes: state.dataset.maritalStatusTypes,
        educationDegries: state.dataset.educationDegries,
        coupleWorkStatuses: state.dataset.coupleWorkStatuses,
        bloodGroups: state.dataset.bloodGroups,
        disabilityDegries: state.dataset.disabilityDegries,
        languageTypes: state.dataset.languageTypes,
        militaryServiceTypes: state.dataset.militaryServiceTypes,
        drivingLicenceTypes: state.dataset.drivingLicenceTypes

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getNationalities: () => { dispatch(getNationalities()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPersonInformation);
