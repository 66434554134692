import { ExpenseActionType } from "../actions/expense.action";

const initialState = {
  expenseCreateDrawer: false,
  expenceDetailDrawer: false,
  currencyWithId: { status: "loading", data: null },
  multipleCurrencyWithId: { status: "loading", cashed: null, converted: null },
  creatExpenseRequests: { status: "loading", message: "", data: "" },
  creatExpenseWithCashRequests: { status: "loading", message: "", data: "" },
  openAdvanceList: { status: "loading", data: null },
  myExpenseList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  myExpenseListLoading: true,

  expenseManagerList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  expenseManagerListLoading: true,
  allExpenseList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  allExpenseListLoading: true,
  expenseDetails: {
    resourceId: null,
    createdDate: null,
    person: {
      id: null,
      displayValue: null,
    },
    expenseDetails: [
      {
        expenseResourceId: null,
        expenseType: {
          id: null,
          displayValue: null,
        },
        currency: {
          id: null,
          displayValue: null,
        },
        expenseDate: null,
        expenseAmount: null,
        invoiceNo: null,
        description: null,
        expenseDocuments: [],
      },
    ],
    expenseConfirmHistory: [],
    exchanceExpense: [
      {
        exchangeDate: null,
        exchangeAmount: null,
        exchangeCurrencyId: null,
        convertAmount: null,
        convertCurrencyId: null,
        exchangeExpense: null,
        invoiceNumber: null,
        invoiceTitle: null,
        invoiceImage: null,
      },
    ],
    returnAmountList: [
      {
        currencyId: null,
        currencyName: null,
        returnAmount: null,
      },
    ],
    closedAdvance: [],
    expenseMasterTypeId: null,
  },
  expenseDetailsLoading: true,
  expenseDetailStep: [
    {
      editableStatus: null,
      closableStatus: null,
      name: null,
      authResourceId: null,
      stepName: null,
      date: null,
      sortOrder: null,
      isSelected: null,
      description: null,
      stepListId: null,
    },
  ],
  expenseDetailStepLoading: true,
  expenseChangeStatusResponse: { status: "loading", message: "" },
  detailExpenseRequests: { status: "loading", message: "" },
  expenseApproveOnMailResponse: { status: "loading", message: "" },
  expenseApproveOnMailResponseLoading: true,
  expenseTableExportData: {},
  expenseTableExportDataResponse: { status: "loading", message: "" },
  expenseApprovalTableExportData: {},
  expenseApprovalTableExportDataResponse: { status: "loading", message: "" },
  expenseExportAllFilesResponse: { status: "loading", message: "" },
  updateExpenseProcessedResponse: { status: "loading", message: "" },
  expenseExportDetailFilesResponse: { status: "loading", message: "" },
  expenseCurrencyExportDetailFilesResponse: { status: "loading", message: "" },
  expenseEditRequestRepsonse: { status: "loading", message: "" },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ExpenseActionType.SET_EXPENSE_CREATE_DRAWER:
      return {
        ...state,
        expenseCreateDrawer: action.payload,
      };
    case ExpenseActionType.SET_EXPENSE_DETAILS_DRAWER:
      return {
        ...state,
        expenceDetailDrawer: action.payload,
      };
    case ExpenseActionType.GET_CURRENCY_WITH_ID_SUCCESS:
      return {
        ...state,
        currencyWithId: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case ExpenseActionType.RESET_CURRENCY_WITH_ID:
      return {
        ...state,
        currencyWithId: { status: "loading", data: null },
      };
    case ExpenseActionType.GET_MUTLIPLE_CURRENCY_WITH_ID_SUCCESS:
      return {
        ...state,
        multipleCurrencyWithId: {
          status: "SUCCESS",
          cashed: action.payload[0],
          converted: action.payload[1],
        },
      };
    case ExpenseActionType.RESET_GET_MUTLIPLE_CURRENCY_WITH_ID:
      return {
        ...state,
        multipleCurrencyWithId: { status: "loading", cashed: null, converted: null },
      };
    case ExpenseActionType.GET_OPEN_ADVANCE_REQUEST:
      return {
        ...state,
        openAdvanceList: {
          status: "loading",
          data: null,
        },
      };
    case ExpenseActionType.GET_OPEN_ADVANCE_SUCCESS:
      return {
        ...state,
        openAdvanceList: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case ExpenseActionType.GET_OPEN_ADVANCE_FAIL:
      return {
        ...state,
        openAdvanceList: {
          status: "ERROR",
          data: action.payload,
        },
      };
    case ExpenseActionType.CREATE_EXPENSE_SUCCESS:
      return {
        ...state,
        creatExpenseRequests: {
          status: "SUCCESS",
          message: action.payload.message,
          data: action.payload.data.resourceId,
        },
      };
    case ExpenseActionType.CREATE_EXPENSE_FAIL:
      return {
        ...state,
        creatExpenseRequests: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.CREATE_EXPENSE_RESET:
      return {
        ...state,
        creatExpenseRequests: { status: "loading", message: "" },
      };
    case ExpenseActionType.CREATE_EXPENSE_WITH_CASH_SUCCESS:
      return {
        ...state,
        creatExpenseWithCashRequests: {
          status: "SUCCESS",
          message: action.payload.message,
          data: action.payload.data.resourceId,
        },
      };
    case ExpenseActionType.CREATE_EXPENSE_WITH_CASH_FAIL:
      return {
        ...state,
        creatExpenseWithCashRequests: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.CREATE_EXPENSE_WITH_CASH_RESET:
      return {
        ...state,
        creatExpenseWithCashRequests: { status: "loading", message: "" },
      };
    case ExpenseActionType.GET_EXPENSE_DETAILS_REQUEST:
      return {
        ...state,
        expenseDetailsLoading: true,
      };
    case ExpenseActionType.GET_EXPENSE_DETAILS_SUCCESS:
      return {
        ...state,
        expenseDetails: action.payload,
        expenseDetailsLoading: false,
      };
    case ExpenseActionType.GET_EXPENSE_DETAILS_FAIL:
      return {
        ...state,
        expenseDetailsLoading: false,
      };
    case ExpenseActionType.GET_EXPENSE_DETAILS_STEP_REQUEST:
      return {
        ...state,
        expenseDetailStepLoading: true,
      };
    case ExpenseActionType.GET_EXPENSE_DETAILS_STEP_SUCCESS:
      return {
        ...state,
        expenseDetailStep: action.payload,
        expenseDetailStepLoading: false,
      };
    case ExpenseActionType.GET_EXPENSE_DETAILS_STEP_FAIL:
      return {
        ...state,
        expenseDetailStepLoading: false,
      };
    case ExpenseActionType.MY_EXPENSE_LIST_REQUESTS:
      return {
        ...state,
        myExpenseListLoading: true,
      };
    case ExpenseActionType.MY_EXPENSE_LIST_SUCCESS:
      return {
        ...state,
        myExpenseList: action.payload,
        myExpenseListLoading: false,
      };
    case ExpenseActionType.MY_EXPENSE_LIST_FAIL:
      return {
        ...state,
        myExpenseListLoading: false,
      };
    case ExpenseActionType.EXPENSE_MANAGER_LIST_REQUESTS:
      return {
        ...state,
        expenseManagerListLoading: true,
      };
    case ExpenseActionType.EXPENSE_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        expenseManagerList: action.payload,
        expenseManagerListLoading: false,
      };
    case ExpenseActionType.EXPENSE_MANAGER_LIST_FAIL:
      return {
        ...state,
        expenseManagerListLoading: false,
        expenseManagerList: {
          totalNumberOfRecords: 0,
          results: [],
        },
      };
    case ExpenseActionType.ALL_EXPENSE_LIST_REQUEST:
      return {
        ...state,
        allExpenseListLoading: true,
      };
    case ExpenseActionType.ALL_EXPENSE_LIST_SUCCESS:
      return {
        ...state,
        allExpenseList: action.payload,
        allExpenseListLoading: false,
      };
    case ExpenseActionType.ALL_EXPENSE_LIST_FAIL:
      return {
        ...state,
        allExpenseListLoading: false,
      };
    case ExpenseActionType.EXPENSE_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        expenseChangeStatusResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_CHANGE_STATUS_FAIL:
      return {
        ...state,
        expenseChangeStatusResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_CHANGE_STATUS_RESET:
      return {
        ...state,
        expenseChangeStatusResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.UPDATE_EXPENSE_SUCCESS:
      return {
        ...state,
        detailExpenseRequests: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case ExpenseActionType.UPDATE_EXPENSE_FAIL:
      return {
        ...state,
        detailExpenseRequests: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.UPDATE_EXPENSE_RESET:
      return {
        ...state,
        detailExpenseRequests: { status: "loading", message: "" },
      };
    case ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_REQUEST:
      return {
        ...state,
        expenseApproveOnMailResponse: {
          status: "LOADING",
          message: "",
        },
        expenseApproveOnMailResponseLoading: true,
      };
    case ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_SUCCESS:
      return {
        ...state,
        expenseApproveOnMailResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
        expenseApproveOnMailResponseLoading: false,
      };
    case ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_FAIL:
      return {
        ...state,
        expenseApproveOnMailResponse: {
          status: "FAIL",
          message: action.payload,
        },
        expenseApproveOnMailResponseLoading: false,
      };
    case ExpenseActionType.EXPENSE_EXPORT_SUCCESS:
      return {
        ...state,
        expenseTableExportData: action.payload,
      };
    case ExpenseActionType.EXPENSE_EXPORT_FAIL:
      return {
        ...state,
        expenseTableExportData: {},
        expenseTableExportDataResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_EXPORT_RESET:
      return {
        ...state,
        expenseTableExportData: {},
        expenseTableExportDataResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.EXPENSE_APPROVALS_EXPORT_SUCCESS:
      return {
        ...state,
        expenseApprovalTableExportData: action.payload,
      };
    case ExpenseActionType.EXPENSE_APPROVALS_EXPORT_FAIL:
      return {
        ...state,
        expenseApprovalTableExportData: {},
        expenseApprovalTableExportDataResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_APPROVALS_EXPORT_RESET:
      return {
        ...state,
        expenseApprovalTableExportData: {},
        expenseApprovalTableExportDataResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.EXPENSE_EXPORT_ALL_FILES_FAIL:
      return {
        ...state,
        expenseExportAllFilesResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_EXPORT_ALL_FILES_RESET:
      return {
        ...state,
        expenseExportAllFilesResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.UPDATE_PROCESSED_EXPENSE_SUCCESS:
      return {
        ...state,
        updateExpenseProcessedResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case ExpenseActionType.UPDATE_PROCESSED_EXPENSE_FAIL:
      return {
        ...state,
        updateExpenseProcessedResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.UPDATE_PROCESSED_EXPENSE_RESET:
      return {
        ...state,
        updateExpenseProcessedResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.EXPENSE_EXPORT_DETAIL_FILES_FAIL:
      return {
        ...state,
        expenseExportDetailFilesResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_EXPORT_DETAIL_FILES_RESET:
      return {
        ...state,
        expenseExportDetailFilesResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_SUCCESS:
      return {
        ...state,
        expenseEditRequestRepsonse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_FAIL:
      return {
        ...state,
        expenseEditRequestRepsonse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_RESET:
      return {
        ...state,
        expenseEditRequestRepsonse: { status: "loading", message: "" },
      };
    case ExpenseActionType.EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_FAIL:
      return {
        ...state,
        expenseCurrencyExportDetailFilesResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case ExpenseActionType.EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_RESET:
      return {
        ...state,
        expenseCurrencyExportDetailFilesResponse: { status: "loading", message: "" },
      };
    case ExpenseActionType.RESET_EXPENSE_DETAILS_STATE:
      return {
        ...state,
        expenseDetails: {
          resourceId: null,
          createdDate: null,
          person: {
            id: null,
            displayValue: null,
          },
          expenseDetails: [
            {
              expenseResourceId: null,
              expenseType: {
                id: null,
                displayValue: null,
              },
              currency: {
                id: null,
                displayValue: null,
              },
              expenseDate: null,
              expenseAmount: null,
              invoiceNo: null,
              description: null,
              expenseDocuments: [],
            },
          ],
          expenseConfirmHistory: [],
          exchanceExpense: [
            {
              exchangeDate: null,
              exchangeAmount: null,
              exchangeCurrencyId: null,
              convertAmount: null,
              convertCurrencyId: null,
              exchangeExpense: null,
              invoiceNumber: null,
              invoiceTitle: null,
              invoiceImage: null,
            },
          ],
          returnAmountList: [
            {
              currencyId: null,
              currencyName: null,
              returnAmount: null,
            },
          ],
          closedAdvance: [],
          expenseMasterTypeId: null,
        },
        expenseDetailsLoading: true,
        expenseDetailStep: [
          {
            editableStatus: null,
            closableStatus: null,
            name: null,
            authResourceId: null,
            stepName: null,
            date: null,
            sortOrder: null,
            isSelected: null,
            description: null,
            stepListId: null,
          },
        ],
        expenseDetailStepLoading: true,
      };
    default:
      return state;
  }
}
