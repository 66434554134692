import { SettingActionTypes } from "../actions/setting.actions";

const initialState = {
    gridSettings : {
        //person
        1 : {
            //grid1
            1:[
                {
                    "columnName": "fullName",
                    "isVisible": true
                },
                {
                    "columnName": "jobTitle",
                    "isVisible": true
                },
                {
                    "columnName": "department",
                    "isVisible": true
                },
                {
                    "columnName": "corporateEmail",
                    "isVisible": true
                },
                {
                    "columnName": "officePhone",
                    "isVisible": true
                },
                {
                    "columnName": "hireDate",
                    "isVisible": true
                },
                {
                    "columnName": "connectedPersonsCount",
                    "isVisible": true
                },
                {
                    "columnName": "reportsTo",
                    "isVisible": true
                }
            ]
        },
        //organization
        2:{1:[
            {"columnName":"organizationName","isVisible":true},
            {"columnName":"organizationType","isVisible":true},
            {"columnName":"legalName","isVisible":true},
            {"columnName":"sector","isVisible":true},
            {"columnName":"telephone","isVisible":true},
            {"columnName":"province","isVisible":true},
            {"columnName":"district","isVisible":true},
            {"columnName":"state","isVisible":true},
        ]},
        //department
        3:{1:[
            {"columnName":"departmentName","isVisible":true},
            {"columnName":"isHeadDepartment","isVisible":true},
            {"columnName":"parentDepartment","isVisible":true},
            {"columnName":"departmentManager","isVisible":true},
            {"columnName":"personsCount","isVisible":true},
        ]},
        //zimmetler
        4:{1:[
            {"columnName":"serialNumber","isVisible":true},
            {"columnName":"inventoryType","isVisible":true},
            {"columnName":"personName","isVisible":true},
            {"columnName":"assigmentDate","isVisible":true},
            {"columnName":"returnDate","isVisible":true},
            {"columnName":"assigmentDescription","isVisible":true},
        ]},
        //timeoff request my 
        5:{1:[
            {"columnName":"timeOffRequestDate","isVisible":true},
            {"columnName":"timeOffTypeName","isVisible":true},
            {"columnName":"startDate","isVisible":true},
            {"columnName":"endDate","isVisible":true},
            {"columnName":"balance","isVisible":true},
            {"columnName":"stateType","isVisible":true},
        ]},
        6:{1:[
            {"columnName":"InventoryTypeName","isVisible":true},
            {"columnName":"brand","isVisible":true},
            {"columnName":"model","isVisible":true},
            {"columnName":"serialNumber","isVisible":true},
            {"columnName":"state","isVisible":true},
            {"columnName":"personName","isVisible":true},
            {"columnName":"assigmentDate","isVisible":true},
        ]},
        7:{1:[
            {"columnName":"requestDate","isVisible":true},
            {"columnName":"fullName","isVisible":true},
            {"columnName":"timeOffTypeName","isVisible":true},
            {"columnName":"startDate","isVisible":true},
            {"columnName":"endDate","isVisible":true},
            {"columnName":"balance","isVisible":true},
            {"columnName":"workDateStart","isVisible":true},]
        },
        8:{1:[
            {"columnName":"requestDate","isVisible":true},
            {"columnName":"employee","isVisible":true},
            {"columnName":"advanceType","isVisible":true},
            {"columnName":"balance","isVisible":true},
            {"columnName":"stateType","isVisible":true},
         ]
        },
        9 : {
            //employee summary
            1:[
                {"columnName": "personName","isVisible": true},
                {"columnName":"statusName","isVisible":true},
                {"columnName": "jobTitle","isVisible": true},
                {"columnName": "departmentName","isVisible": true},
                {"columnName": "corporateEmail","isVisible": true},
                {"columnName": "officePhone","isVisible": true},
                {"columnName": "hireDate","isVisible": true},
                {"columnName": "managerPerson","isVisible": true},
                {"columnName": "connectedPersonCount","isVisible": true}
            ]
        },
        10 : {
            //timeOffAll report
            1:[
                {"columnName": "fullName","isVisible": true},
                {"columnName": "timeOffRequestDate","isVisible": true},
                {"columnName": "timeOffTypeName","isVisible": true},
                {"columnName": "departmentName","isVisible": true},
                {"columnName": "startDate","isVisible": true},
                {"columnName": "endDate","isVisible": true},
                {"columnName": "balance","isVisible": true},
                {"columnName": "remainderBalance","isVisible": true},
                {"columnName": "stateType","isVisible": true},
                {"columnName": "personToApprove","isVisible": true}
            ]
        },
        11 : {
            //timeOffYears report
            1:[
                {"columnName": "fullName","isVisible": true},
                {"columnName": "workStartDate","isVisible": true},
                {"columnName": "departmentName","isVisible": true},
                {"columnName": "accrualBalanceYearly","isVisible": true},
                {"columnName": "extraValue","isVisible": true},
                {"columnName": "transferorBalance","isVisible": true},
                {"columnName": "sumBalance","isVisible": true},
                {"columnName": "usedBalance","isVisible": true},
                {"columnName": "remainderBalance","isVisible": true},
            ]
        }
       
        
    },
    createGridSettingsResponse:{
        "status":"",
        "message":""
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
       
        case SettingActionTypes.GET_GRID_SETTINGS_SUCCESS:
        
            let gridId = action.payload.gridId;
            let gridList = action.payload.data?action.payload.data : action.payload;
            let pageId = action.payload.pageId;
            let gridObj = {...state.gridSettings[pageId],[gridId]:gridList}
            let pageObj = {...state.gridSettings,[pageId]:gridList.length === 0 ? state.gridSettings[pageId] : gridObj}
            return {
                ...state,
                gridSettings : pageObj
            }
        case SettingActionTypes.CREATE_GRID_SETTING_SUCCESS:
            return {
                ...state,
                createGridSettingsResponse: {
                   "status":"SUCCESS",
                   "message":action.payload
                }
            }
        case SettingActionTypes.CREATE_GRID_SETTING_FAIL:
            return {
                ...state,
                createGridSettingsResponse: {
                   "status":"FAIL",
                   "message":action.payload
                }
            }
            case SettingActionTypes.GRID_SETTINGS_RESPONSE_RESET:
                return{
                    ...state,
                    createGridSettingsResponse: {
                        "status":"",
                        "message":""
                     }
                }

        
            
        default: return state
    }

}