import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UilMultiply } from '@iconscout/react-unicons'
import { SearchOutlined, PhoneOutlined } from '@ant-design/icons';

import { getPersonsTagConnect, setTagConnectDraw } from '../../redux/actions/tag.actions';
import {
    Col, Row, Card, Input, Spin, Image
} from 'antd';

import '../../styles/person.scss'
import Can from '../../authorization/index';
import Cannot from '../../authorization/cannot';
import Forbidden from "../forbidden";

function ConnectedTagDrawer(props) {

    const { setTagConnectDraw, connectedTagList, getPersonsTagConnect,
        permissions, tagResourceId, connectedTagLoading, onOpenPersonDetail } = props;

    const getSearchInputTimeout = useRef(null);
    const [searchText, setSearchText] = useState('');
    const { t } = useTranslation();

    const jobConnectSearchData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "fullName",
            "ascending": true
        }
    }

    const [searchData, setSearchData] = useState(jobConnectSearchData)


    const closeDrawer = () => {
        setTagConnectDraw({ "status": false, "id": null })
    }

    useEffect(() => {
        if (searchData && jobConnectSearchData !== searchData) {
            getPersonsTagConnect(searchData, tagResourceId);
        }
    }, [searchData])

    const handleSearchConnectedpersons = (e) => {

        const searchKey = e.target.value
        setSearchText(searchKey)
        if (getSearchInputTimeout.current !== null) {
            clearTimeout(getSearchInputTimeout.current);
        }

        getSearchInputTimeout.current = setTimeout(() => {
            getSearchInputTimeout.current = null;

            // arama kutusuna yazılan text 3 karakterden sonra 1 saniye gecikmeden sonra ws ye istek yapacak
            if (searchKey.length > 1 || searchKey.length === 0) {
                // bu nesne ile istek yapılacak

                setSearchData({ ...searchData, "searchTerm": searchKey })

            }
        }, 1000);
    }

    const showPersonDetailDrawer = (id) => {
        onOpenPersonDetail(id);
    }

    return (
        <div className="connectedPersonsDrawer">
            <div className="connectedPersonsHeader">
                <h1>{t('PEOPLE')} {!connectedTagLoading ? '(' + connectedTagList.length + ')' : " "}</h1>
                <div className="add-user-close-icon">
                    <UilMultiply onClick={() => closeDrawer()} />
                </div>
            </div>
            <Can I="person.list">
                <div className="connected-drawer-search-input">
                    <Input allowClear value={searchText} onChange={(e) => handleSearchConnectedpersons(e)} placeholder={t('SEARCH_EMPLOYEES')} suffix={<SearchOutlined />} />
                </div>

                <div className="connectedPersonCardsWrapper">

                    {connectedTagLoading ? <Spin className="tab-loading" /> :
                        <Row gutter={[24, 24]}>
                            {
                                connectedTagList.map((item) => {
                                    return (
                                        <Col key={item.resourceId} span={8} onClick={() => permissions.includes("person.read") ? showPersonDetailDrawer(item.resourceId) : null}>
                                            <Card className={permissions.includes("person.read") ? "connectedPersonCard" : "connectedPersonCard no-pointer"}>
                                                <Image preview={false} src={item.photo || process.env.REACT_APP_URL + 'images/avatar_undefined.png'} className="rounded-img" />
                                                <div className="name">{item.fullName}</div>
                                                <div>{item.jobTitle}</div>
                                                <div>{item.corporateEmail}</div>
                                                <div><PhoneOutlined /> {item.mobilePhone}</div>
                                                <div>{item.identityNumber}</div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    }

                </div>
            </Can>
            <Cannot I="person.list">
                <Forbidden size="sm" />
            </Cannot>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        connectedTagList: state.tag.connectedTagList.results,
        tagResourceId: state.tag.connectTagDrawer.id,
        connectedTagLoading: state.tag.connectedTagLoading,
        permissions: state.profile.permissions
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTagConnectDraw: (props) => { dispatch(setTagConnectDraw(props)) },
        getPersonsTagConnect: (searchData, id) => { dispatch(getPersonsTagConnect(searchData, id)) },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedTagDrawer);