import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Form, Spin, ConfigProvider } from 'antd';

function SocialMediaTab(props) {
    const [form] = Form.useForm()
    const { t } = useTranslation();
    const [socialMediaState, setSocialMediaState] = useState({ "socialMediaAccounts": [] });
    const { socialmedia, personSocialIsFetching, getUpdateObj, disableStatus } = props;

    useEffect(() => {
        getUpdateObj(socialMediaState)
    }, [socialMediaState])
    useEffect(() => {
        setSocialMediaState(socialmedia);
        const twitter = socialmedia.socialMediaAccounts.find((item) => item.type === "Twitter")
        const facebook = socialmedia.socialMediaAccounts.find((item) => item.type === "Facebook")
        const insta = socialmedia.socialMediaAccounts.find((item) => item.type === "Instagram")
        const linkedin = socialmedia.socialMediaAccounts.find((item) => item.type === "Linkedin")
        const setObj = {
            "Twitter": twitter ? twitter.link : "",
            "Facebook": facebook ? facebook.link : "",
            "Instagram": insta ? insta.link : "",
            "Linkedin": linkedin ? linkedin.link : "",
            "personalPageUrl": socialmedia.personalPageUrl
        }
        form.setFieldsValue(setObj)
    }, [form, socialmedia])

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (["Twitter", "Facebook", "Instagram", "Linkedin"].includes(key)) {
            const index = socialMediaState.socialMediaAccounts.findIndex((item) => item.type == key)
            const arr = [...socialMediaState.socialMediaAccounts]
            arr[index] = { "type": key, "link": changedObj[key] }
            setSocialMediaState({ ...socialMediaState, "socialMediaAccounts": [...arr] });
        } else {
            setSocialMediaState({ ...socialMediaState, ...changedObj });
        }
    }
    return (
        personSocialIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                    requiredMark={false}
                >
                    <div className="form-left-body">
                        <Form.Item name="Twitter" label={t('PARTNER_TWITTER_USERNAME')} required >
                            <Input disabled={disableStatus} className={socialMediaState.socialMediaAccounts.find((item) => item.type === "Twitter") && socialmedia.socialMediaAccounts.find((item) => item.type === "Twitter").link !== socialMediaState.socialMediaAccounts.find((item) => item.type === "Twitter").link ? 'is-changed' : ''} />
                        </Form.Item>
                        <Form.Item name="Instagram" label={t('PARTNER_INSTAGRAM_USERNAME')} required >
                            <Input disabled={disableStatus} className={socialMediaState.socialMediaAccounts.find((item) => item.type === "Instagram") && socialmedia.socialMediaAccounts.find((item) => item.type === "Instagram").link !== socialMediaState.socialMediaAccounts.find((item) => item.type === "Instagram").link ? 'is-changed' : ''} />
                        </Form.Item>
                        <Form.Item name="Facebook" label={t('PARTNER_FACEBOOK_USERNAME')} required >
                            <Input disabled={disableStatus} className={socialMediaState.socialMediaAccounts.find((item) => item.type === "Facebook") && socialmedia.socialMediaAccounts.find((item) => item.type === "Facebook").link !== socialMediaState.socialMediaAccounts.find((item) => item.type === "Facebook").link ? 'is-changed' : ''} />
                        </Form.Item>
                        <Form.Item name="Linkedin" label={t('PARTNER_LINKEDIN_USERNAME')} required >
                            <Input disabled={disableStatus} className={socialMediaState.socialMediaAccounts.find((item) => item.type === "Linkedin") && socialmedia.socialMediaAccounts.find((item) => item.type === "Linkedin").link !== socialMediaState.socialMediaAccounts.find((item) => item.type === "Linkedin").link ? 'is-changed' : ''} />
                        </Form.Item>
                        <Form.Item name="personalPageUrl" label={t('PARTNER_PERSONEL_PAGE')} required >
                            <Input disabled={disableStatus} className={socialmedia.personalPageUrl !== socialMediaState.personalPageUrl ? 'is-changed' : ''} />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        )
    )
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaTab);