
/* Burasi Atamalar duzenle drawindaki stateleri duzenliyor */
export const setAssignmentDetailTabStatus = (assignmentChangeStatus) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ASSIGMENT_EDIT_CHANGE_STATUS',
            payload: assignmentChangeStatus,
        });
    } catch (e) {
    }
};
export const setAssignmentDetailOpenDraw = (openAssignmentDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ASSIGMENT_EDIT_OPEN__DRAW',
            payload: openAssignmentDraw,
        });
    } catch (e) {
    }
};
/* Burasi Atamalar ekle drawer'indaki stateleri ekliyor */
export const setAssignmentDetailAddTabStatus = (assignmentAddHasChange) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ASSIGMENT_ADD_CHANGE_STATUS',
            payload: assignmentAddHasChange,
        });
    } catch (e) {
    }
};

export const setAssignmentDetailAddOpenDraw = (openAssignmentAddDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ASSIGMENT_ADD_OPEN__DRAW',
            payload: openAssignmentAddDraw,
        });
    } catch (e) {
    }
};
/* MAAS BILGISI ISLEMLERI */
/* Burasi Maas bilgisi duzenle drawer'indaki stateleri ekliyor */
export const setSalaryDetailsOpenDraw = (salaryDetailsDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_SALERY_EDIT_OPEN_DRAW',
            payload: salaryDetailsDraw,
        });
    } catch (e) {
    }
};
export const setSalaryDetailEditTabStatus = (salaryDetailsHasChange) => async dispatch => {
    try {
        dispatch({
            type: 'SET_SALARY_EDIT_CHANGE_STATUS',
            payload: salaryDetailsHasChange,
        });
    } catch (e) {
    }
};
/* Burasi Maas bilgisi ekle drawer'indaki stateleri ekliyor */
export const setSalaryAddOpenDraw = (salaryAddDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_SALERY_ADD_OPEN_DRAW',
            payload: salaryAddDraw,
        });
    } catch (e) {
    }
};
export const setSalaryDetailAddTabStatus = (salaryAddHasChange) => async dispatch => {
    try {
        dispatch({
            type: 'SET_SALARY_ADD_CHANGE_STATUS',
            payload: salaryAddHasChange,
        });
    } catch (e) {
    }
};
/* Burasi Avans Talepleri duzenle drawer'indaki stateleri ekliyor */
export const setAdvanceDetailTabStatus = (advanceDetailsHasChange) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ADVANCE_EDIT_CHANGE_STATUS',
            payload: advanceDetailsHasChange,
        });
    } catch (e) {
    }

};
export const setAdvanceDetailOpenDraw = (advanceDetailsDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ADVANCE_EDIT_OPEN_DRAW',
            payload: advanceDetailsDraw,
        });
    } catch (e) {
    }

};
/* Burasi Avans Talepleri  ekle drawer'indaki stateleri ekliyor */
export const setAdvanceAddTabStatus = (advanceAddHasChange) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ADVANCE_ADD_CHANGE_STATUS',
            payload: advanceAddHasChange,
        });
    } catch (e) {
    }
};
export const setAdvanceAddOpenDraw = (advanceAddDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_ADVANCE_ADD_OPEN_DRAW',
            payload: advanceAddDraw,
        });
    } catch (e) {
    }
};
export const setCreateOwnerShipDrawer = (createOwnerShipDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_CREATE_OWNER_SHIP_DRAW',
            payload: createOwnerShipDrawer,
        });
       
    } catch (e) {
    }

};
export const setOwnerShipDetailsDrawer = (ownerShipDetailsDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_OWNER_SHIP_DETAILS_DRAW',
            payload: ownerShipDetailsDrawer,
        });
       
    } catch (e) {
    }

};
/* Çalışan Oluştur */
export const setPersonAddOpenDraw = (personAddOpenDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_PERSON_ADD_OPEN_STATUS',
            payload: personAddOpenDraw,
        });
    } catch (e) {
    }
};
export const setConnectedpersonsOpenDraw = (connectedPersonsOpenDraw) => async dispatch => {
    try {
        dispatch({
            type: 'SET_CONNECTED_PERSON_OPEN_STATUS',
            payload: connectedPersonsOpenDraw,
        });
    } catch (e) {
    }
};
export const setPersonDeleteDrawer = (personDeleteDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_PERSON_DELETE_DRAWER',
            payload: personDeleteDrawer,
        });
    } catch (e) {
    }
};
