import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
import { OrganizationActionType } from "./organization.actions";

const FileDownload = require("js-file-download");

const PersonActionType = {
  CREATE_PERSON_SUCCESS: "CREATE_PERSON_SUCCESS",
  CREATE_PERSON_FAIL: "CREATE_PERSON_FAIL",
  CREATE_PERSON_INPUT_ERROR: "CREATE_PERSON_INPUT_ERROR",
  CREATE_PERSON_RESPONSE_RESET: "CREATE_PERSON_RESPONSE_RESET",
  GET_PERSON_REQUEST: "GET_PERSON_REQUEST",
  GET_PERSON_SUCCESS: "GET_PERSON_SUCCESS",
  GET_PERSON_FAIL: "GET_PERSON_FAIL",
  GET_PERSON_ME_REQUEST: "GET_PERSON_ME_REQUEST",
  GET_PERSON_ME_SUCCESS: "GET_PERSON_ME_SUCCESS",
  GET_PERSON_ME_FAIL: "GET_PERSON_ME_FAIL",
  UPDATE_PERSON_REQUEST: "UPDATE_PERSON_REQUEST",
  UPDATE_PERSON_SUCCESS: "UPDATE_PERSON_SUCCESS",
  UPDATE_PERSON_FAIL: "UPDATE_PERSON_FAIL",
  UPDATE_PERSON_INPUT_ERROR: "UPDATE_PERSON_INPUT_ERROR",
  UPDATE_PERSON_RESPONSE_RESET: "UPDATE_PERSON_RESPONSE_RESET",
  GET_SALARY_HISTORY_REQUEST: "GET_SALARY_HISTORY_REQUEST",
  GET_SALARY_HISTORY_SUCCESS: "GET_SALARY_HISTORY_SUCCESS",
  GET_SALARY_HISTORY_FAIL: "GET_SALARY_HISTORY_FAIL",
  CREATE_SALARY_HISTORY_RESPONSE_RESET: "CREATE_SALARY_HISTORY_RESPONSE_RESET",
  CREATE_SALARY_HISTORY_SUCCESS: "CREATE_SALARY_HISTORY_SUCCESS",
  CREATE_SALARY_HISTORY_FAIL: "CREATE_SALARY_HISTORY_FAIL",
  GET_SALARY_REQUEST: "GET_SALARY_REQUEST",
  GET_SALARY_SUCCESS: "GET_SALARY_SUCCESS",
  GET_SALARY_FAIL: "GET_SALARY_FAIL",
  UPDATE_SALARY_HISTORY_RESPONSE_RESET: "UPDATE_SALARY_HISTORY_RESPONSE_RESET",
  UPDATE_SALARY_REQUEST: "UPDATE_SALARY_REQUEST",
  UPDATE_SALARY_SUCCESS: "UPDATE_SALARY_SUCCESS",
  UPDATE_SALARY_FAIL: "UPDATE_SALARY_FAIL",
  GET_JOB_HISTORY_REQUEST: "GET_JOB_HISTORY_REQUEST",
  GET_JOB_HISTORY_SUCCESS: "GET_JOB_HISTORY_SUCCESS",
  GET_JOB_HISTORY_FAIL: "GET_JOB_HISTORY_FAIL",
  CREATE_JOB_RECORD_RESPONSE_RESET: "CREATE_JOB_RECORD_RESPONSE_RESET",
  CREATE_JOB_RECORD_SUCCESS: "CREATE_JOB_RECORD_SUCCESS",
  CREATE_JOB_RECORD_FAIL: "CREATE_JOB_RECORD_FAIL",
  GET_JOB_RECORD_REQUEST: "GET_JOB_RECORD_REQUEST",
  GET_JOB_RECORD_SUCCESS: "GET_JOB_RECORD_SUCCESS",
  GET_JOB_RECORD_FAIL: "GET_JOB_RECORD_FAIL",
  UPDATE_JOB_RECORD_REQUEST: "UPDATE_JOB_RECORD_REQUEST",
  UPDATE_JOB_RECORD_SUCCESS: "UPDATE_JOB_RECORD_SUCCESS",
  UPDATE_JOB_RECORD_FAIL: "UPDATE_JOB_RECORD_FAIL",
  UPDATE_JOB_RECORD_RESPONSE_RESET: "UPDATE_JOB_RECORD_RESPONSE_RESET",
  SEARCH_REQUEST: "SEARCH_REQUEST",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_FAIL: "SEARCH_FAIL",
  GET_CONNECTED_PERSONS_REQUEST: "GET_CONNECTED_PERSONS_REQUEST",
  GET_CONNECTED_PERSONS_SUCCESS: "GET_CONNECTED_PERSONS_SUCCESS",
  GET_CONNECTED_PERSONS_FAIL: "GET_CONNECTED_PERSONS_FAIL",
  EXPORT_PERSONS_SUCCESS: "EXPORT_PERSONS_SUCCESS",
  EXPORT_PERSONS_FAIL: "EXPORT_PERSONS_FAIL",
  PERSON_FILTER_REQUEST: "PERSON_FILTER_REQUEST",
  PERSON_FILTER_SUCCESS: "PERSON_FILTER_SUCCESS",
  PERSON_FILTER_FAIL: "PERSON_FILTER_FAIL",
  CORPORATE_EMAIL_FILTER_REQUEST: "CORPORATE_EMAIL_FILTER_REQUEST",
  CORPORATE_EMAIL_FILTER_SUCCESS: "CORPORATE_EMAIL_FILTER_SUCCESS",
  CORPORATE_EMAIL_FILTER_FAIL: "CORPORATE_EMAIL_FILTER_FAIL",
  UPDATE_PERSON_DATA: "UPDATE_PERSON_DATA",
  DELETE_PERSON_SUCCESS: "DELETE_PERSON_SUCCESS",
  DELETE_PERSON_FAIL: "DELETE_PERSON_FAIL",
  DELETE_PERSON_RESPONSE_RESET: "DELETE_PERSON_RESPONSE_RESET",
  GET_SALARY_LIST_RESET: "GET_SALARY_LIST_RESET",
  GET_JOB_HISTORY_RESET: "GET_JOB_HISTORY_RESET",
  OWNER_SHIP_REQUEST: "OWNER_SHIP_REQUEST",
  OWNER_SHIP_SUCCESS: "OWNER_SHIP_SUCCESS",
  OWNER_SHIP_FAIL: "OWNER_SHIP_FAIL",
  GET_USER_ROLES_SUCCESS: "GET_USER_ROLES_SUCCESS",
  GET_USER_ROLES_REQUEST: "GET_USER_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  DELETE_AVATAR_SUCCESS: "DELETE_AVATAR_SUCCESS",
  DELETE_AVATAR_FAIL: "DELETE_AVATAR_FAIL",
  DELETE_AVATAR_RESET: "DELETE_AVATAR_RESET",
  GET_DELETE_PERSON_CONTROL_REQUEST: "GET_DELETE_PERSON_CONTROL_REQUEST",
  GET_DELETE_PERSON_CONTROL_OPEN_DRAWER: "GET_DELETE_PERSON_CONTROL_OPEN_DRAWER",
  GET_DELETE_PERSON_CONTROL_OPEN_MODAL: "GET_DELETE_PERSON_CONTROL_OPEN_MODAL",
  RESET_GET_DELETE_PERSON_CONTROL: "RESET_GET_DELETE_PERSON_CONTROL",
  OWNER_SHIP_DETAILS_DRAW_TABLE_REQUEST: "OWNER_SHIP_DETAILS_DRAW_TABLE_REQUEST",
  OWNER_SHIP_DETAILS_DRAW_TABLE_SUCCESS: "OWNER_SHIP_DETAILS_DRAW_TABLE_SUCCESS",
  OWNER_SHIP_DETAILS_DRAW_TABLE_FAIL: "OWNER_SHIP_DETAILS_DRAW_TABLE_FAIL",
  GET_FILE_UPLOAD_LIST_REQUEST: "GET_FILE_UPLOAD_LIST_REQUEST",
  GET_FILE_UPLOAD_LIST_SUCCESS: "GET_FILE_UPLOAD_LIST_SUCCESS",
  GET_FILE_UPLOAD_LIST_FAIL: "GET_FILE_UPLOAD_LIST_FAIL",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAIL: "DELETE_DOCUMENT_FAIL",
  DELETE_DOCUMENT_RESET: "DELETE_DOCUMENT_RESET",
  CREATE_DOCUMENT_REQUESTS_SUCCESS: "CREATE_DOCUMENT_REQUESTS_SUCCESS",
  CREATE_DOCUMENT_REQUESTS_FAIL: "CREATE_DOCUMENT_REQUESTS_FAIL",
  CREATE_DOCUMENT_REQUESTS_RESET: "CREATE_DOCUMENT_REQUESTS_RESET",
  DELETE_DOCUMENT_REQUEST_SUCCESS: "DELETE_DOCUMENT_REQUEST_SUCCESS",
  DELETE_DOCUMENT_REQUEST_FAIL: "DELETE_DOCUMENT_REQUEST_FAIL",
  DELETE_DOCUMENT_REQUEST_RESET: "DELETE_DOCUMENT_REQUEST_RESET",
  PERSON_ACTIVE_SUCCESS: "PERSON_ACTIVE_SUCCESS",
  PERSON_ACTIVE_FAIL: "PERSON_ACTIVE_FAIL",
  PERSON_ACTIVE_RESET: "PERSON_ACTIVE_RESET",
  RESEND_CONFIRM_MAIL_SUCCESS: "RESEND_CONFIRM_MAIL_SUCCESS",
  RESEND_CONFIRM_MAIL_FAIL: "RESEND_CONFIRM_MAIL_FAIL",
  RESEND_CONFIRM_MAIL_RESET: "RESEND_CONFIRM_MAIL_RESET",
  DELETE_SALARY_SUCCESS: "DELETE_SALARY_SUCCESS",
  DELETE_SALARY_FAIL: "DELETE_SALARY_FAIL",
  DELETE_SALARY_RESET: "DELETE_SALARY_RESET",
  DELETE_ASSIGNMENT_SUCCESS: "DELETE_ASSIGNMENT_SUCCESS",
  DELETE_ASSIGNMENT_FAIL: "DELETE_ASSIGNMENT_FAIL",
  DELETE_ASSIGNMENT_RESET: "DELETE_ASSIGNMENT_RESET",
  DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
  DELETE_FILE_FAIL: "DELETE_FILE_FAIL",
  DELETE_FILE_RESET: "DELETE_FILE_RESET",
  DELETE_FILE_WITH_TYPE_SUCCESS: "DELETE_FILE_WITH_TYPE_SUCCESS",
  DELETE_FILE_WITH_TYPE_FAIL: "DELETE_FILE_WITH_TYPE_FAIL",
  DELETE_FILE_WITH_TYPE_RESET: "DELETE_FILE_WITH_TYPE_RESET",
  DELETE_ASSIGNMENT_FILE_SUCCESS: "DELETE_ASSIGNMENT_FILE_SUCCESS",
  DELETE_ASSIGNMENT_FILE_FAIL: "DELETE_ASSIGNMENT_FILE_FAIL",
  DELETE_ASSIGNMENT_FILE_RESET: "DELETE_ASSIGNMENT_FILE_RESET",
  UPDATE_DOCUMENT_KVKK_APROVE_SUCCESS: "UPDATE_DOCUMENT_KVKK_APROVE_SUCCESS",
  UPDATE_DOCUMENT_KVKK_APROVE_FAIL: "UPDATE_DOCUMENT_KVKK_APROVE_FAIL",
  UPDATE_DOCUMENT_KVKK_APROVE_RESET: "UPDATE_DOCUMENT_KVKK_APROVE_RESET",
  GET_PERSON_ORGANIZATIONS_REQUEST: "GET_PERSON_ORGANIZATIONS_REQUEST",
  GET_PERSON_ORGANIZATIONS_SUCCESS: "GET_PERSON_ORGANIZATIONS_SUCCESS",
  GET_PERSON_ORGANIZATIONS_FAIL: "GET_PERSON_ORGANIZATIONS_FAIL",
  IMPORT_PERSON_SUCCESS: "IMPORT_PERSON_SUCCESS",
  IMPORT_PERSON_FAIL: "IMPORT_PERSON_FAIL",
  IMPORT_PERSON_RESET: "IMPORT_PERSON_RESET",
};

const createPerson = (createPersonState) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createPersonState);
      var config = {
        method: "post",
        url: apiUrl + "/persons",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.CREATE_PERSON_SUCCESS, payload: response.data });
            dispatch({ type: OrganizationActionType.GET_PERSONS_SUCCESS, payload: [] });
          } else {
            dispatch({ type: PersonActionType.CREATE_PERSON_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422 || error.response?.status === 409) {
            dispatch({ type: PersonActionType.CREATE_PERSON_INPUT_ERROR, payload: error.response.data });
          } else {
            dispatch({ type: PersonActionType.CREATE_PERSON_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.CREATE_PERSON_FAIL, payload: error.message });
      }
    }
  };
};
const createPersonResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.CREATE_PERSON_RESPONSE_RESET, payload: {} });
  };
};
const getPerson = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_PERSON_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/persons/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_PERSON_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_PERSON_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.GET_PERSON_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_PERSON_FAIL, payload: error.message });
      }
    }
  };
};
const getPersonMe = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_PERSON_ME_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/persons/me",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_PERSON_ME_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_PERSON_ME_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.GET_PERSON_ME_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_PERSON_ME_FAIL, payload: error.message });
      }
    }
  };
};

const updatePerson = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_PERSON_REQUEST });
    try {
      const resourceId = updateData.resourceId;
      const data = JSON.stringify(updateData.data);

      var config = {
        method: "put",
        url: apiUrl + "/persons/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.UPDATE_PERSON_SUCCESS, payload: response.data.message });
            // dispatch({ type: 'STORE_USER', payload: updateData.data });
          } else {
            dispatch({ type: PersonActionType.UPDATE_PERSON_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422 || error.response?.status === 409) {
            dispatch({ type: PersonActionType.UPDATE_PERSON_INPUT_ERROR, payload: error.response.data });
          } else {
            dispatch({ type: PersonActionType.UPDATE_PERSON_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.UPDATE_PERSON_FAIL, payload: error.message });
      }
    }
  };
};
const updatePersonResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_PERSON_RESPONSE_RESET, payload: {} });
  };
};
const deletePerson = (deleteData, deletePersonId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/" + deletePersonId + "/delete",
        headers: {},
        data: deleteData,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.DELETE_PERSON_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_PERSON_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_PERSON_FAIL, payload: error.response.data.validationMessages[0] });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_PERSON_FAIL, payload: error.message });
      }
    }
  };
};
const deletePersonResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_PERSON_RESPONSE_RESET, payload: {} });
  };
};

const dismissalPerson = (data, id) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/" + id + "/dismissal",
        headers: {},
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.DELETE_PERSON_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_PERSON_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_PERSON_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_PERSON_FAIL, payload: error.message });
      }
    }
  };
};
const getUserRoles = (resourceId) => async (dispatch) => {
  dispatch({ type: PersonActionType.GET_USER_ROLES_REQUEST });
  try {
    var config = {
      method: "get",
      url: apiUrl + "/personOrganizationRoleList/" + resourceId ,
      //url: 'https://auth.hrplan.net/users/'+resourceId+'/roles/1',
      headers: {},
    };
    hrApi(config)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: PersonActionType.GET_USER_ROLES_SUCCESS,
            payload: response.data.data,
          });
        }
      })
      .catch(function (error) {
        if (error.response?.status === 404) {
          const emptyData = [];
          dispatch({ type: PersonActionType.GET_USER_ROLES_SUCCESS, payload: emptyData });
        }
      });
  } catch (e) {}
};

const getRoles = (resourceId) => async (dispatch) => {
  try {
    var config = {
      method: "get",
      url: apiUrl + "/roles",
      headers: {},
    };
    hrApi(config)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: PersonActionType.GET_ROLES_SUCCESS,
            payload: response.data.data,
          });
        }
      })
      .catch(function (error) {});
  } catch (e) {}
};
const getSalaryHistory = (resourceId, page) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_SALARY_HISTORY_REQUEST });
    try {
      const url =
        page === "profile"
          ? apiUrl + "/persons/me/salary-history"
          : apiUrl + "/persons/" + resourceId + "/salary-history";
      var config = {
        method: "get",
        url: url,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_SALARY_HISTORY_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_SALARY_HISTORY_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.GET_SALARY_HISTORY_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_SALARY_HISTORY_FAIL, payload: error.message });
      }
    }
  };
};
const createSalaryHistoryResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.CREATE_SALARY_HISTORY_RESPONSE_RESET, payload: {} });
  };
};
const resetSalaryList = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_SALARY_LIST_RESET, payload: {} });
  };
};
const createSalaryHistory = (createSalaryHistoryData) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createSalaryHistoryData.data);
      var config = {
        method: "post",
        url: apiUrl + "/persons/" + createSalaryHistoryData.resourceId + "/salary",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.CREATE_SALARY_HISTORY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.CREATE_SALARY_HISTORY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.CREATE_SALARY_HISTORY_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.CREATE_SALARY_HISTORY_FAIL, payload: error.message });
      }
    }
  };
};
const getSalary = (salaryData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_SALARY_REQUEST });
    try {
      const resourceId = salaryData.personResourceId;
      const salaryResourceId = salaryData.salaryResourceId;
      var config = {
        method: "get",
        url: apiUrl + "/persons/" + resourceId + "/salary/" + salaryResourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_SALARY_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_JOB_HISTORY_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.GET_JOB_HISTORY_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_JOB_HISTORY_FAIL, payload: error.message });
      }
    }
  };
};
const updateSalaryResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_SALARY_HISTORY_RESPONSE_RESET, payload: {} });
  };
};
const updateSalary = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_SALARY_REQUEST });
    try {
      const personResourceId = updateData.personResourceId;
      const salaryResourceId = updateData.salaryResourceId;
      const data = JSON.stringify(updateData.data);
      var config = {
        method: "put",
        url: apiUrl + "/persons/" + personResourceId + "/salary/" + salaryResourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.UPDATE_SALARY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.UPDATE_SALARY_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.UPDATE_SALARY_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.UPDATE_SALARY_FAIL, payload: error.message });
      }
    }
  };
};
const getJobHistory = (resourceId, page) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_JOB_HISTORY_REQUEST });
    try {
      const url =
        page === "profile" ? apiUrl + "/persons/me/job-history" : apiUrl + "/persons/" + resourceId + "/job-history";
      var config = {
        method: "get",
        url: url,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_JOB_HISTORY_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_JOB_HISTORY_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = [];
            dispatch({ type: PersonActionType.GET_JOB_HISTORY_SUCCESS, payload: emptyData });
          } else {
            dispatch({ type: PersonActionType.GET_JOB_HISTORY_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_JOB_HISTORY_FAIL, payload: error.message });
      }
    }
  };
};
const resetJobHistory = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_JOB_HISTORY_RESET, payload: {} });
  };
};
const createJobRecordResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.CREATE_JOB_RECORD_RESPONSE_RESET, payload: {} });
  };
};
const createJobRecord = (createJobRecordData) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createJobRecordData.data);
      var config = {
        method: "post",
        url: apiUrl + "/persons/" + createJobRecordData.resourceId + "/job-history",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.CREATE_JOB_RECORD_SUCCESS, payload: response.data.Message });
          } else {
            dispatch({ type: PersonActionType.CREATE_JOB_RECORD_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.CREATE_JOB_RECORD_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.CREATE_JOB_RECORD_FAIL, payload: error.message });
      }
    }
  };
};
const getJobRecord = (jobRecordData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_JOB_RECORD_REQUEST });
    try {
      const resourceId = jobRecordData.resourceId;
      const jobRecordResourceId = jobRecordData.jobRecordResourceId;
      var config = {
        method: "get",
        url: apiUrl + "/persons/" + resourceId + "/job-history/" + jobRecordResourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_JOB_RECORD_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_JOB_RECORD_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.GET_JOB_RECORD_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_JOB_RECORD_FAIL, payload: error.message });
      }
    }
  };
};
const updateJobRecordResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_JOB_RECORD_RESPONSE_RESET, payload: {} });
  };
};
const updateJobRecord = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_JOB_RECORD_REQUEST });
    try {
      const personResourceId = updateData.personResourceId;
      const jobRecordResourceId = updateData.jobRecordResourceId;
      const data = JSON.stringify(updateData.data);
      var config = {
        method: "put",
        url: apiUrl + "/persons/" + personResourceId + "/job-history/" + jobRecordResourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.UPDATE_JOB_RECORD_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.UPDATE_JOB_RECORD_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.UPDATE_JOB_RECORD_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.UPDATE_JOB_RECORD_FAIL, payload: error.message });
      }
    }
  };
};
const search = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.SEARCH_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/persons/search",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.SEARCH_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.SEARCH_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: PersonActionType.SEARCH_SUCCESS, payload: emptyData });
          }
          dispatch({ type: PersonActionType.SEARCH_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.SEARCH_FAIL, payload: error.message });
      }
    }
  };
};
const getConnectedPersons = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_CONNECTED_PERSONS_REQUEST });
    try {
      const data = JSON.stringify(searchData.data);
      const resourceId = searchData.resourceId;
      var config = {
        method: "post",
        url: apiUrl + "/persons/" + resourceId + "/connected-persons",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_CONNECTED_PERSONS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_CONNECTED_PERSONS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 0,
              totalNumberOfPages: 0,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: PersonActionType.GET_CONNECTED_PERSONS_SUCCESS, payload: emptyData });
          }
          dispatch({ type: PersonActionType.GET_CONNECTED_PERSONS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_CONNECTED_PERSONS_FAIL, payload: error.message });
      }
    }
  };
};
const exportPersons = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);
      var config = {
        method: "post",
        url: apiUrl + "/persons/export",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob",
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };
            FileDownload(response.data, "person_" + newDate + "." + exportTypes[props.exportType]);
          } else {
            dispatch({ type: PersonActionType.EXPORT_PERSONS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.EXPORT_PERSONS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.EXPORT_PERSONS_FAIL, payload: error.message });
      }
    }
  };
};
const getPersonFilter = (filterData) => {
  return async (dispatch) => {
    const column = filterData.column;
    if (column === "person") {
      dispatch({ type: PersonActionType.PERSON_FILTER_REQUEST });
    } else if (column === "corporateEmail") {
      dispatch({ type: PersonActionType.CORPORATE_EMAIL_FILTER_REQUEST });
    }
    try {
      const data = JSON.stringify(filterData);
      var config = {
        method: "post",
        url: apiUrl + "/persons/filter",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            if (column === "person") {
              dispatch({ type: PersonActionType.PERSON_FILTER_SUCCESS, payload: response.data.data });
            } else if (column === "corporateEmail") {
              dispatch({ type: PersonActionType.CORPORATE_EMAIL_FILTER_SUCCESS, payload: response.data.data });
            }
          } else {
            if (column === "person") {
              dispatch({ type: PersonActionType.PERSON_FILTER_FAIL, payload: response.data.Message });
            } else if (column === "corporateEmail") {
              dispatch({ type: PersonActionType.CORPORATE_EMAIL_FILTER_FAIL, payload: response.data.Message });
            }
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            if (column === "person") {
              dispatch({ type: PersonActionType.PERSON_FILTER_SUCCESS, payload: emptyData });
            } else if (column === "corporateEmail") {
              dispatch({ type: PersonActionType.CORPORATE_EMAIL_FILTER_SUCCESS, payload: emptyData });
            }
          }
          if (column === "person") {
            dispatch({ type: PersonActionType.PERSON_FILTER_FAIL, payload: error.message });
          } else if (column === "corporateEmail") {
            dispatch({ type: PersonActionType.CORPORATE_EMAIL_FILTER_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        if (column === "person") {
          dispatch({ type: PersonActionType.PERSON_FILTER_FAIL, payload: error.message });
        } else if (column === "corporateEmail") {
          dispatch({ type: PersonActionType.CORPORATE_EMAIL_FILTER_FAIL, payload: error.message });
        }
      }
    }
  };
};
const ownerShipSearch = (resourceId, searchData, page) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.OWNER_SHIP_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      const url =
        page === "profile" ? apiUrl + "/persons/me/assets" : apiUrl + "/persons/" + resourceId + "/assigments";
      var config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.OWNER_SHIP_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.OWNER_SHIP_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: PersonActionType.OWNER_SHIP_SUCCESS, payload: emptyData });
          }
          dispatch({ type: PersonActionType.OWNER_SHIP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.OWNER_SHIP_FAIL, payload: error.message });
      }
    }
  };
};
const ownerShipDetailsDrawTable = (resourceId, searchData) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/persons/" + resourceId + "/assigments",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_SUCCESS, payload: emptyData });
          }
          dispatch({ type: PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteAvatar = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/" + resourceId + "/deletePersonProfilePhoto",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.DELETE_AVATAR_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_AVATAR_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: PersonActionType.DELETE_AVATAR_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_AVATAR_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_AVATAR_FAIL, payload: error.message });
      }
    }
  };
};
const deleteAvatarResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_AVATAR_RESET, payload: {} });
  };
};
const deletePersonControl = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_DELETE_PERSON_CONTROL_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/persons/" + resourceId + "/deletePersonControl",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.GET_DELETE_PERSON_CONTROL_OPEN_MODAL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            dispatch({
              type: PersonActionType.GET_DELETE_PERSON_CONTROL_OPEN_DRAWER,
              payload: error.response.data.data,
            });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_DELETE_PERSON_CONTROL_OPEN_MODAL, payload: error.data.data });
      }
    }
  };
};
const resetDeletePersonControl = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.RESET_GET_DELETE_PERSON_CONTROL, payload: {} });
  };
};
const getFileUploadList = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_FILE_UPLOAD_LIST_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/persons/" + resourceId + "/getFileUploadList",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.GET_FILE_UPLOAD_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_FILE_UPLOAD_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = [];
            dispatch({ type: PersonActionType.GET_FILE_UPLOAD_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: PersonActionType.GET_FILE_UPLOAD_LIST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_FILE_UPLOAD_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const deleteDocument = (documentRequestResourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/documents/" + documentRequestResourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.DELETE_DOCUMENT_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_DOCUMENT_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_DOCUMENT_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_DOCUMENT_FAIL, payload: error.message });
      }
    }
  };
};
const deleteDocumentReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_DOCUMENT_RESET, payload: {} });
  };
};
const createDocumentRequests = (createData, resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/persons/" + resourceId + "/documentRequests",
        headers: {
          "Content-Type": "application/json",
        },
        data: createData,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.CREATE_DOCUMENT_REQUESTS_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.CREATE_DOCUMENT_REQUESTS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.CREATE_DOCUMENT_REQUESTS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.CREATE_DOCUMENT_REQUESTS_FAIL, payload: error.message });
      }
    }
  };
};
const createDocumentRequestsReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.CREATE_DOCUMENT_REQUESTS_RESET, payload: {} });
  };
};
const deleteDocumentRequests = (documentRequestResourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/documentRequests/" + documentRequestResourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.DELETE_DOCUMENT_REQUEST_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_DOCUMENT_REQUEST_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_DOCUMENT_REQUEST_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_DOCUMENT_REQUEST_FAIL, payload: error.message });
      }
    }
  };
};

const deleteDocumentRequestReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_DOCUMENT_REQUEST_RESET, payload: {} });
  };
};
const doActivePerson = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/persons/" + resourceId + "/active",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.PERSON_ACTIVE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.PERSON_ACTIVE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.PERSON_ACTIVE_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.PERSON_ACTIVE_FAIL, payload: error.message });
      }
    }
  };
};
const doActivePersonReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.PERSON_ACTIVE_RESET, payload: {} });
  };
};
const resendConfirmMail = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/user/resendConfirmEmail",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.RESEND_CONFIRM_MAIL_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.RESEND_CONFIRM_MAIL_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422 || error.response?.status === 404) {
            dispatch({ type: PersonActionType.RESEND_CONFIRM_MAIL_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: PersonActionType.RESEND_CONFIRM_MAIL_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.RESEND_CONFIRM_MAIL_FAIL, payload: error.message });
      }
    }
  };
};
const resendConfirmMailReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.RESEND_CONFIRM_MAIL_RESET, payload: {} });
  };
};
const deleteSalary = (SalaryResourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/" + SalaryResourceId + "/salary",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.DELETE_SALARY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_SALARY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_SALARY_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_SALARY_FAIL, payload: error.message });
      }
    }
  };
};
const deleteSalaryReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_SALARY_RESET, payload: {} });
  };
};
const deleteJobHistory = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/persons/job-history/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FAIL, payload: error.message });
      }
    }
  };
};
const deleteAssignmentReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_RESET, payload: {} });
  };
};
const deleteUploadFile = (obj) => {

  const index = obj.index;
  const deleteUrl = obj.documentUrl?.substring(obj.documentUrl?.lastIndexOf('/') + 1);
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/File/DeleteFile/"+ deleteUrl,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: PersonActionType.DELETE_FILE_SUCCESS,
              payload: { message: response.data.message, index: index },
            });
          } else {
            dispatch({ type: PersonActionType.DELETE_FILE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_FILE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_FILE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteUploadFileResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_FILE_RESET, payload: {} });
  };
};
const deleteUploadFileWithType = (obj) => {
  const index = obj.index;
  const deleteUrl = obj.documentUrl;
  const resourceId = obj.resourceId;
  const type = obj.type;
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/File/DeleteFile/" + deleteUrl + "/" + resourceId + "/" + type,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: PersonActionType.DELETE_FILE_WITH_TYPE_SUCCESS,
              payload: { message: response.data.message, index: index },
            });
          } else {
            dispatch({ type: PersonActionType.DELETE_FILE_WITH_TYPE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.DELETE_FILE_WITH_TYPE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_FILE_WITH_TYPE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteUploadFileWithTypeResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_FILE_WITH_TYPE_RESET, payload: {} });
  };
};
const deleteAssignmentFile = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/Person/DeleteAssignmentFile/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FILE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FILE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422 || error.response?.status === 404) {
            dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FILE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FILE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FILE_FAIL, payload: error.message });
      }
    }
  };
};
const deleteAssignmentFileReset = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.DELETE_ASSIGNMENT_FILE_RESET, payload: {} });
  };
};
const updateDocumentKVKKAprove = (personResourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/persons/KVKKAprove/" + personResourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_FAIL, payload: error.response.data.message });
          } else if (error.response?.status === 409) {
            dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateDocumentKVKKAprove = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_RESET, payload: {} });
  };
};
const getPersonOrganizations = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.GET_PERSON_ORGANIZATIONS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/persons/organizations",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.GET_PERSON_ORGANIZATIONS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: PersonActionType.GET_PERSON_ORGANIZATIONS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.GET_PERSON_ORGANIZATIONS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.GET_PERSON_ORGANIZATIONS_FAIL, payload: error.message });
      }
    }
  };
};
const importPerson = (data) => {
  const formData = new FormData();
  formData.append('formFile', data);
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/persons/import",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: PersonActionType.IMPORT_PERSON_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: PersonActionType.IMPORT_PERSON_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: PersonActionType.IMPORT_PERSON_FAIL, payload: error.response.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: PersonActionType.IMPORT_PERSON_FAIL, payload: error.message });
      }
    }
  };
};
const resetImportPerson = () => {
  return async (dispatch) => {
    dispatch({ type: PersonActionType.IMPORT_PERSON_RESET, payload: {} });
  };
};
export {
  PersonActionType,
  createPerson,
  createPersonResponseReset,
  updatePerson,
  updatePersonResponseReset,
  deletePerson,
  deletePersonResponseReset,
  getPerson,
  getPersonMe,
  getSalaryHistory,
  resetSalaryList,
  createSalaryHistory,
  createSalaryHistoryResponseReset,
  getSalary,
  getUserRoles,
  getRoles,
  updateSalary,
  updateSalaryResponseReset,
  getJobHistory,
  resetJobHistory,
  createJobRecordResponseReset,
  createJobRecord,
  getJobRecord,
  updateJobRecord,
  updateJobRecordResponseReset,
  search,
  getConnectedPersons,
  exportPersons,
  getPersonFilter,
  ownerShipSearch,
  ownerShipDetailsDrawTable,
  deleteAvatar,
  deleteAvatarResponseReset,
  deletePersonControl,
  resetDeletePersonControl,
  dismissalPerson,
  getFileUploadList,
  deleteDocument,
  deleteDocumentReset,
  createDocumentRequests,
  createDocumentRequestsReset,
  deleteDocumentRequests,
  deleteDocumentRequestReset,
  doActivePerson,
  doActivePersonReset,
  resendConfirmMail,
  resendConfirmMailReset,
  deleteSalary,
  deleteSalaryReset,
  deleteJobHistory,
  deleteAssignmentReset,
  deleteUploadFile,
  deleteUploadFileResponseReset,
  deleteUploadFileWithType,
  deleteUploadFileWithTypeResponseReset,
  deleteAssignmentFile,
  deleteAssignmentFileReset,
  updateDocumentKVKKAprove,
  resetUpdateDocumentKVKKAprove,
  getPersonOrganizations,
  importPerson,
  resetImportPerson
};
