import React, { useLayoutEffect, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OrgChart } from 'd3-org-chart';
import '../../styles/treeview.scss';

export const TreeView = (props, ref) => {
  const { t } = useTranslation();
  const d3Container = useRef(null);
  let chart = null;

  function addNode(node) {
    chart.addNode(node);
  }

  props.setClick(addNode);

  useLayoutEffect(() => {
    try {
      if (props.data && d3Container.current) {
        if (!chart) {
          chart = new OrgChart();
        }
        let nodeHeight = 225;
        if (props.page === 'department') {
          nodeHeight = 205
        }
        chart
          .container(d3Container.current)
          .data(props.data)
          .onNodeClick((d, i, arr) => {
            props.onNodeClick(d);
          })
          .nodeWidth(d => 350)
          .initialZoom(0.7)
          .nodeHeight(d => nodeHeight)
          .childrenMargin(d => 50)
          .compactMarginBetween(d => 15)
          .compactMarginPair(d => 80)
          .nodeContent(function (d, i, arr, state) {

            if (props.page === 'department') {
              return `
              <div class="treeview-card-wrapper department" >
                  <div class="treeview-card-container" >
                      
                      
                      <div class="treeview-card-line"></div>
      
                      <div class="treeview-card-content">
                          <div class="treeview-card-name" > ${d.data.departmentName || '-'} </div>
                          <div class="treeview-card-position" > ${t('PARTNER_MANAGER2')} : ${d.data.departmentManager || '-'}  </div>
                      </div>
                      <div class="treeview-card-footer-wrapper">
                        <div class="treeview-card-footer">
                            <div > ${t('SUB_DEPARTMENT')} : ${d.data._directSubordinates} </div>  
                            <div > ${t('PARTNER_TOTAL')} : ${d.data._totalSubordinates} </div>    
                        </div>
                      </div>
                  </div>     
              </div>
              `;
            } else if (props.page === 'organization' || (d.data.isVirtualParent && props.page === 'person')) {
              return `
                <div class="treeview-card-wrapper" >
                    <div class="treeview-card-container" >
                        <img src="${d.data.profileImgUrl || process.env.REACT_APP_URL + 'images/avatar_undefined.png'}" class="treeview-card-img" />
                        
                        <div class="treeview-card-line"></div>
        
                        <div class="treeview-card-content">
                            <div class="treeview-card-name" > ${d.data.organizationName || '-'} </div>
                            <div class="treeview-card-position" > ${d.data.organizationLegalName || '-'} </div>
                            <div class="treeview-card-position" > ${d.data.organizationManager || '-'}  </div>
                        </div>
                        <div class="treeview-card-footer-wrapper">
                          <div class="treeview-card-footer">
                              <div >  ${t('DEBIT_PERSON')} : ${d.data._directSubordinates} </div>  
                              <div >  ${t('PARTNER_TOTAL')} : ${d.data._totalSubordinates} </div>    
                          </div>
                      </div>
                    </div>     
                </div>
                `;

            } else if (props.page === 'person') {
              return `
              <div class="treeview-card-wrapper" >
                  <div class="treeview-card-container" >
                      <img src="${d.data.profileImgUrl || process.env.REACT_APP_URL + 'images/avatar_undefined.png'}" class="treeview-card-img" />
                      
                      <div class="treeview-card-line"></div>
      
                      <div class="treeview-card-content">
                          <div class="treeview-card-name" > ${d.data.fullName || '-'} </div>
                          <div class="treeview-card-position" > ${d.data.jobTitle || '-'} </div>
                          <div class="treeview-card-position" > ${d.data.email || '-'} </div>
                          <div class="treeview-card-position" > ${d.data.phone || '-'}  </div>
                      </div>
                      <div class="treeview-card-footer-wrapper">
                          <div class="treeview-card-footer">
                              <div >  ${t('DEBIT_PERSON')} : ${d.data._directSubordinates} </div>  
                              <div >  ${t('PARTNER_TOTAL')} : ${d.data._totalSubordinates} </div>    
                          </div>
                      </div>
                  </div>     
              </div>
              `;
            }

          })
          .render();
      }
    } catch (e) {
      console.log(e)
    }
  }, [props.data, d3Container.current]);

  return (
    <div>
      <div ref={d3Container} />
    </div>
  );
};
