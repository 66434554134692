var initialState = {
    timeOffRequestAddDrawer: false,
    timeOffTypeDetailDrawer: false,
    timeOffRequestApproveDrawer: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {

        case "SET_TIME_OFF_ADD_DRAWER":
            return {
                ...state,
                timeOffRequestAddDrawer: action.payload,
            }
        case "SET_TIME_OFF_TYPE_DETAILS_DRAWER":
            return {
                ...state,
                timeOffTypeDetailDrawer: action.payload,
            }
        case "SET_TIME_OFF_REQUEST_APPROVE_DRAWER":
            return {
                ...state,
                timeOffRequestApproveDrawer: action.payload,
            }    
    

        default:return state;
    }
}


   