import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    setAdvanceCreateDrawer, setAdvanceDetailsDrawer, getMyAdvanceRequests,
    getAdvanceManager, getAllAdvanceRequests, resetAdvanceChangeStatus, updateAdvanceReset
} from '../../redux/actions/advance.actions';

import { Layout, Col, Tabs, Drawer } from 'antd';
import { ToastContainer } from 'react-toastify';
import { drawerWidth } from '../../utils/config';

import BreadCrumb from '../../features/breadcrump/breadcrump';
import AdvanceRequests from '../../component/advanceTabs/advance_requests';
import ApproveAdvance from '../../component/advanceTabs/approve_advance';
import AllAdvanceRequests from '../../component/advanceTabs/all_advance_request';
import CreateAdvanceTabs from '../../component/advanceTabs/add/create_advance_all_tabs';
import AdvanceDetailsTab from '../../component/advanceTabs/details/advanceDetailsTab';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

function Advance(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const myAdvanceRequestInitialSearch = {
        "SearchTerm": "",
        "advanceType": [],
        "managerPerson": [],
        "role": [],
        "stateTypes": [],
        "showPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "NeedDate",
            "ascending": false
        }
    };
    const advanceManagerInitialSearch = {
        "SearchTerm": "",
        "advanceType": [],
        "managerPerson": [],
        "role": [],
        "stateTypes": [],
        "createdBy": [],
        "showPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "NeedDate",
            "ascending": false
        }
    };
    const allAdvanceRequestInitialSearch = {
        "SearchTerm": "",
        "advanceType": [],
        "managerPerson": [],
        "role": [],
        "stateTypes": [],
        "showPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "NeedDate",
            "ascending": false
        }
    };
    const { advanceCreateDrawer, advanceDetailsDrawer, setAdvanceCreateDrawer, setAdvanceDetailsDrawer,
        getMyAdvanceRequests, profile, permissions, getAdvanceManager,
        getAllAdvanceRequests, advanceChangeStatusResponse,
        resetAdvanceChangeStatus, advanceUpdateResponse, updateAdvanceReset } = props;
    const [currentTab, setCurrentTab] = useState('AdvanceRequests');
    const [createAdvanceKey, setCreateAdvanceKey] = useState(1);
    const [detailsAdvanceKey, setDetailsAdvanceKey] = useState(100);
    const [myAdvanceRequestSearchState, setMyAdvanceRequestSearchState] = useState(myAdvanceRequestInitialSearch);
    const [advanceManagerSearchState, setAdvanceManagerSearchState] = useState(advanceManagerInitialSearch);
    const [allAdvanceRequestSearchState, setAllAdvanceRequestSearchState] = useState(allAdvanceRequestInitialSearch);
    const [selectAdvance, setSelectAdvance] = useState(null);

    function callback(key) {
        setCurrentTab(key);
    }
    const openCreateAdvanceDrawer = () => {
        setAdvanceCreateDrawer(true)
        setCreateAdvanceKey((i) => i + 1)
    }
    const openAdvanceDetailsDrawer = () => {
        setAdvanceDetailsDrawer(true)
        setDetailsAdvanceKey((i) => i + 1)
    }
    const updateTable = () => {
        if (currentTab === "AdvanceRequests") {
            getMyAdvanceRequests(profile?.resourceId, myAdvanceRequestSearchState);
        } else if (currentTab === "ApproveAdvance") {
            getAdvanceManager(advanceManagerSearchState);
        } else if (currentTab === "AllAdvanceRequests") {
            getAllAdvanceRequests(allAdvanceRequestSearchState);
        }
    }
    useEffect(() => {
        updateTable();
    }, [currentTab])
    useEffect(() => {
        if (!advanceDetailsDrawer) {
            if (advanceChangeStatusResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': advanceChangeStatusResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                resetAdvanceChangeStatus();
            }
            else if (advanceChangeStatusResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': advanceChangeStatusResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                resetAdvanceChangeStatus()
            }
        } else {
            if (advanceChangeStatusResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [advanceChangeStatusResponse])
    useEffect(() => {
        if (!advanceDetailsDrawer) {
            if (advanceUpdateResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': advanceUpdateResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                updateAdvanceReset();
            }
            else if (advanceUpdateResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': advanceUpdateResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                resetAdvanceChangeStatus()
            }
        } else {
            if (advanceUpdateResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [advanceUpdateResponse])
    return (
        <React.Fragment>
            <Layout>
                <Drawer key={createAdvanceKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={advanceCreateDrawer} >
                    <CreateAdvanceTabs />
                </Drawer >
                <Drawer key={detailsAdvanceKey} title={false} width={1000} closable={false} onClose={false} placement="right" visible={advanceDetailsDrawer} >
                    <AdvanceDetailsTab selectAdvance={selectAdvance} searchData={myAdvanceRequestInitialSearch} />
                </Drawer >
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrumb />
                        <div className="tab-menu ">
                            <h1 className="page-title">{t('ADVANCE_PROCEDURES_HEADER')}</h1>
                            <Tabs
                                className="pad-top-20 page-tabs"
                                onChange={callback}
                                defaultActiveKey="AdvanceRequests"
                                activeKey={currentTab}
                            >
                                <TabPane tab={<span>{t('ADVANCE_REQUESTS')}</span>} key="AdvanceRequests">
                                    <AdvanceRequests
                                        openAdvanceDetailsDrawer={openAdvanceDetailsDrawer} openCreateAdvanceDrawer={openCreateAdvanceDrawer}
                                        myAdvanceRequestInitialSearch={myAdvanceRequestInitialSearch}
                                        myAdvanceRequestSearchState={myAdvanceRequestSearchState}
                                        setMyAdvanceRequestSearchState={setMyAdvanceRequestSearchState}
                                        setSelectAdvance={setSelectAdvance}
                                    />
                                </TabPane>
                                <TabPane tab={<span>{t('APPROVE_ADVANCE')}</span>} key="ApproveAdvance">
                                    <ApproveAdvance
                                        openAdvanceDetailsDrawer={openAdvanceDetailsDrawer}
                                        advanceManagerInitialSearch={advanceManagerInitialSearch}
                                        advanceManagerSearchState={advanceManagerSearchState}
                                        setAdvanceManagerSearchState={setAdvanceManagerSearchState}
                                        setSelectAdvance={setSelectAdvance}
                                        currentTab={currentTab}
                                    />
                                </TabPane>
                                {permissions.includes("advance.list.all") && (
                                    <TabPane tab={<span>{t('ALL_ADVANCE_REQUESTS')}</span>} key="AllAdvanceRequests">
                                        <AllAdvanceRequests
                                            openAdvanceDetailsDrawer={openAdvanceDetailsDrawer}
                                            allAdvanceRequestInitialSearch={allAdvanceRequestInitialSearch}
                                            allAdvanceRequestSearchState={allAdvanceRequestSearchState}
                                            setAllAdvanceRequestSearchState={setAllAdvanceRequestSearchState}
                                            setSelectAdvance={setSelectAdvance}
                                            currentTab={currentTab}
                                        />
                                    </TabPane>
                                )}
                            </Tabs>
                        </div>
                    </Content>
                </Col>

            </Layout>
            <ToastContainer />
        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        advanceCreateDrawer: state.advance.advanceCreateDrawer,
        advanceDetailsDrawer: state.advance.advanceDetailsDrawer,
        profile: state.profile,
        advanceExportData: state.advance.advanceExportData,
        permissions: state.profile.permissions,
        advanceChangeStatusResponse: state.advance.advanceChangeStatusResponse,
        advanceUpdateResponse: state.advance.advanceUpdateResponse
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAdvanceCreateDrawer: (status) => { dispatch(setAdvanceCreateDrawer(status)) },
        setAdvanceDetailsDrawer: (status) => { dispatch(setAdvanceDetailsDrawer(status)) },
        getMyAdvanceRequests: (resourceId, searchData) => { dispatch(getMyAdvanceRequests(resourceId, searchData)) },
        getAdvanceManager: (searchData) => { dispatch(getAdvanceManager(searchData)) },
        getAllAdvanceRequests: (searchData) => { dispatch(getAllAdvanceRequests(searchData)) },
        resetAdvanceChangeStatus: () => { dispatch(resetAdvanceChangeStatus()) },
        updateAdvanceReset: () => { dispatch(updateAdvanceReset()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Advance);