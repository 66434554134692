import { paymentApi } from "../../utils/apiConfig";
import { paymentApiUrl } from '../../utils/config';

const CheckoutActionType = {
    GET_PAYMENT_PACKAGES_REQUEST: "GET_PAYMENT_PACKAGES_REQUEST",
    GET_PAYMENT_PACKAGES_SUCCESS: "GET_PAYMENT_PACKAGES_SUCCESS",
    GET_PAYMENT_PACKAGES_FAIL: "GET_PAYMENT_PACKAGES_FAIL",
    GET_PAYMENT_SUMMARY_REQUEST: "GET_PAYMENT_SUMMARY_REQUEST",
    GET_PAYMENT_SUMMARY_SUCCESS: "GET_PAYMENT_SUMMARY_SUCCESS",
    GET_PAYMENT_SUMMARY_FAIL: "GET_PAYMENT_SUMMARY_FAIL",
    GET_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST: "GET_SUBSCRIPTION_PAYMENT_SUMMARY_REQUEST",
    GET_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS: "GET_SUBSCRIPTION_PAYMENT_SUMMARY_SUCCESS",
    GET_SUBSCRIPTION_PAYMENT_SUMMARY_FAIL: "GET_SUBSCRIPTION_PAYMENT_SUMMARY_FAIL",
    GET_SUBSCRIPTION_SUMMARY_REQUEST: "GET_SUBSCRIPTION_SUMMARY_REQUEST",
    GET_SUBSCRIPTION_SUMMARY_SUCCESS: "GET_SUBSCRIPTION_SUMMARY_SUCCESS",
    GET_SUBSCRIPTION_SUMMARY_FAIL: "GET_SUBSCRIPTION_SUMMARY_FAIL",
    GET_ORGANIZATION_INVOICE_ADDRESS_REQUEST: "GET_ORGANIZATION_INVOICE_ADDRESS_REQUEST",
    GET_ORGANIZATION_INVOICE_ADDRESS_SUCCESS: "GET_ORGANIZATION_INVOICE_ADDRESS_SUCCESS",
    GET_ORGANIZATION_INVOICE_ADDRESS_FAIL: "GET_ORGANIZATION_INVOICE_ADDRESS_FAIL",
    GET_PAYMENT_TRANSACTION_REQUEST: "GET_PAYMENT_TRANSACTION_REQUEST",
    GET_PAYMENT_TRANSACTION_SUCCESS: "GET_PAYMENT_TRANSACTION_SUCCESS",
    GET_PAYMENT_TRANSACTION_FAIL: "GET_PAYMENT_TRANSACTION_FAIL",
    GET_SUBSCRIPTION_PAYMENT_TRANSACTION_REQUEST: "GET_SUBSCRIPTION_PAYMENT_TRANSACTION_REQUEST",
    GET_SUBSCRIPTION_PAYMENT_TRANSACTION_SUCCESS: "GET_SUBSCRIPTION_PAYMENT_TRANSACTION_SUCCESS",
    GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL: "GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL",
    GET_PAYMENT_COMPLETED_REQUEST: "GET_PAYMENT_COMPLETED_REQUEST",
    GET_PAYMENT_COMPLETED_SUCCESS: "GET_PAYMENT_COMPLETED_SUCCESS",
    GET_PAYMENT_COMPLETED_FAIL: "GET_PAYMENT_COMPLETED_FAIL",
    RESET_PAYMENT_TRANSACTION: "RESET_PAYMENT_TRANSACTION"
}


const getPaymentPackages = () => {
    return async (dispatch) => {
      dispatch({ type: CheckoutActionType.GET_PAYMENT_PACKAGES_REQUEST });
      try {
          var config = {
            method: 'get',
            url: paymentApiUrl+'/Payment/paymentPackages',
          };
          paymentApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: CheckoutActionType.GET_PAYMENT_PACKAGES_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: CheckoutActionType.GET_PAYMENT_PACKAGES_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: CheckoutActionType.GET_PAYMENT_PACKAGES_FAIL, payload: error.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: CheckoutActionType.GET_PAYMENT_PACKAGES_FAIL, payload: error.message });
        }
      }
    };
};

const getPaymentSummary = (payload) => {
    return async (dispatch) => {
      try {
          var data = JSON.stringify(payload);
          var config = {
            method: 'post',
            url: paymentApiUrl+'/Payment/paymentSummary',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          paymentApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: CheckoutActionType.GET_PAYMENT_SUMMARY_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: CheckoutActionType.GET_PAYMENT_SUMMARY_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 422){
              //dispatch({ type: CheckoutActionType.GET_PAYMENT_SUMMARY_INPUT_ERROR, payload: error.response.data.validationMessages });
            }else{
              dispatch({ type: CheckoutActionType.GET_PAYMENT_SUMMARY_FAIL, payload: error.message });
            }
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: CheckoutActionType.GET_PAYMENT_SUMMARY_FAIL, payload: error.message });
        }
      }
    };
};

const getSubscriptionSummary = (payload) => {
  return async (dispatch) => {
    try {
        
        var config = {
          method: 'get',
          url: paymentApiUrl+'/Subscription/subscriptionSummary/'+payload?.packageId,
          headers: { 
            'Content-Type': 'application/json'
          }
        };
        paymentApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 422){
            //dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_INPUT_ERROR, payload: error.response.data.validationMessages });
          }else{
            dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_SUMMARY_FAIL, payload: error.message });
      }
    }
  };
};

const getOrganizationInvoiceAddress = () => {
    return async (dispatch) => {
      dispatch({ type: CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_REQUEST });
      try {
          var config = {
            method: 'get',
            url: paymentApiUrl+'/Payment/organizationLastInvoiceAddress',
          };
          paymentApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_FAIL, payload: error.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: CheckoutActionType.GET_ORGANIZATION_INVOICE_ADDRESS_FAIL, payload: error.message });
        }
      }
    };
};

const getPaymentTransaction = (payload) => {
    return async (dispatch) => {
      try {
          var data = JSON.stringify(payload);
          var config = {
            method: 'post',
            url: paymentApiUrl+'/Payment/paymentTransaction',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          paymentApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: CheckoutActionType.GET_PAYMENT_TRANSACTION_SUCCESS, payload: response?.data?.data });
            }else{
              //   dispatch({ type: CheckoutActionType.GET_PAYMENT_TRANSACTION_FAIL, payload: response.data.errorMessages });
            }
          })
          .catch(function (error) {
            
            if(error.response.status === 400){
              dispatch({ type: CheckoutActionType.GET_PAYMENT_TRANSACTION_FAIL, payload: error?.response?.data?.errorMessages});
            }
            
          });
      } catch (error) {
        // if (error.response) {
        //     dispatch({ type: CheckoutActionType.GET_PAYMENT_TRANSACTION_FAIL, payload: error?.errorMessages });
        // }
      }
    };
};

const getSubscriptionPayment = (payload) => {
  return async (dispatch) => {
    try {
        var data = JSON.stringify(payload);
        var config = {
          method: 'post',
          url: paymentApiUrl+'/Subscription/subscriptionPayment',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        paymentApi(config)
        .then(function (response) {
          dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL, payload: null });
          if(response.status===200){
              dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_SUCCESS, payload: response?.data?.data });
          }else{
              dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL, payload: null });
          if(error.response?.status === 422){
            //dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_INPUT_ERROR, payload: error.response.data.validationMessages });
          }else{
              dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL, payload: error.response.data });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: CheckoutActionType.GET_SUBSCRIPTION_PAYMENT_TRANSACTION_FAIL, payload: error.response.data });
      }
    }
  };
};

const getPaymentCompleted = (payload) => {
    return async (dispatch) => {
      dispatch({ type: CheckoutActionType.GET_PAYMENT_COMPLETED_REQUEST });
      dispatch({ type: CheckoutActionType.RESET_PAYMENT_TRANSACTION });
      try {
          var config = {
            method: 'get',
            url: paymentApiUrl+'/Payment/paymentCompleted/'+payload,
          };
          paymentApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: CheckoutActionType.GET_PAYMENT_COMPLETED_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: CheckoutActionType.GET_PAYMENT_COMPLETED_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: CheckoutActionType.GET_PAYMENT_COMPLETED_FAIL, payload: error.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: CheckoutActionType.GET_PAYMENT_COMPLETED_FAIL, payload: error.message });
        }
      }
    };
};

const resetPaymentTransaction= (payload) => {
  return async (dispatch) => {
    dispatch({ type: CheckoutActionType.RESET_PAYMENT_TRANSACTION });
  }
}

export {
    CheckoutActionType,
    getPaymentPackages,
    getPaymentSummary,
    getSubscriptionSummary,
    getOrganizationInvoiceAddress,
    getPaymentTransaction,
    getSubscriptionPayment,
    getPaymentCompleted,
    resetPaymentTransaction
}