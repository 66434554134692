import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, Col, Row, Button, Steps, Image, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import AddOrganizationBasicInfo from './organization-add-basic-info';
import AddOrganizationAdressInfo from './organization-add-adress-info';
import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import { setOrganizationAddDrawer } from '../../../redux/actions/person-organization-drawer-action';
import { createOrganization, resetCreateOrganizationResponse, getOrganizationOrgChart, organizationSearch } from "../../../redux/actions/organization.actions";
import { resetScrollPosition } from '../../../utils/commonFormFunctions';

import DynamicToastMessage from '../../dynamicToastMessage/index';

function AddOrganizationTabs(props) {
    const [currentTab, setCurrentTab] = useState(1);
    const [createOrganizationObj, setCreateOrganizationObj] = useState({});
    const { setOrganizationAddDrawer, createOrganization, organization, resetCreateOrganizationResponse,
        getOrganizationOrgChart, profile, organizationSearch, searchData } = props;
    const { Step } = Steps;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    function callback(key) {
        setCurrentTab(parseInt(key));
        resetScrollPosition('.add-org-draw-scrollable-tab > .ant-tabs-content-holder');


    }
    function prevButton() {
        setCurrentTab(currentTab - 1);
        resetScrollPosition('.add-org-draw-scrollable-tab > .ant-tabs-content-holder');
    }
    function nextButton() {
        setCurrentTab(currentTab + 1);
        resetScrollPosition('.add-org-draw-scrollable-tab > .ant-tabs-content-holder');
    }
    function destroyLastState() {
        setCurrentTab(1);
        setOrganizationAddDrawer(false)
        setCreateOrganizationObj({});
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateOrganizationObj({});
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createOrganizationObj, obj)
        setCreateOrganizationObj(mergedObj)
    }

    const handleCreateOrganization = () => {
        let hasError = false
        let fields = []
        if (!createOrganizationObj.OrganizationType || createOrganizationObj.OrganizationType === "") {
            hasError = true
            fields.push(t('ORGANIZATION_ORGANIZATION_TYPE'))
        }
        if (!createOrganizationObj.Name || createOrganizationObj.Name === "") {
            hasError = true
            fields.push(t('ORGANIZATION_NAME'))
        }
        if (!createOrganizationObj.LegalName || createOrganizationObj.LegalName === "") {
            hasError = true
            fields.push(t('ORGANIZATION_LEGAL_NAME'))
        }

        if (!hasError) {
            createOrganization(createOrganizationObj)
        } else {

            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }


    }
    useEffect(() => {
        if (organization.organizationCreateResponse.status === 'SUCCESS') {
            setCurrentTab(currentTab + 1);
            resetCreateOrganizationResponse()
            organizationSearch(searchData)
            getOrganizationOrgChart(profile.organization?.resourceId)

        } else if (organization.organizationCreateResponse.status === 'FAIL') {
            setCurrentTab(2);
            DynamicToastMessage({
                'key': 'inventory_add_failed_notification',
                'title': t('ERROR'),
                'description': organization.organizationCreateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateOrganizationResponse()

        } else if (organization.organizationCreateResponse.status === 'VALIDATION_ERROR') {
            setCurrentTab(2);
            DynamicToastMessage({
                'key': 'inventory_add_failed_notification',
                'title': t('ERROR'),
                'description': <List>{organization.organizationCreateResponse.message.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateOrganizationResponse()

        }

    }, [organization.organizationCreateResponse])


    return (
        <div>
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => destroyLastState()} />
                </div>
                <div className="add-global-draw-title">
                    <h1>Yeni Organizasyon Oluşturma Talebi</h1>
                </div>
                <div className="w-100">
                    <Row>
                        <Col span={6}>
                            <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                <Step title={t('PARTNER_BASIC_INFORMATION')} description="" />
                                <Step title={t('ORGANIZATION_ADDRESS_INFO')} />
                                <Step title={t('PARTNER_COMPLETE')} description="" />
                            </Steps>
                        </Col>
                        <Col span={17}>
                            {currentTab !== 3 ?

                                <Tabs className="custom-tab add-org-draw-scrollable-tab"
                                    onChange={callback}
                                    activeKey={currentTab.toString()}
                                    defaultActiveKey={currentTab.toString()}
                                >
                                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key={1}>
                                        <AddOrganizationBasicInfo setCreateObject={(o) => setCreateObject(o)} />
                                    </TabPane>
                                    <TabPane tab={t('ORGANIZATION_ADDRESS_INFO')} key={2}>
                                        <AddOrganizationAdressInfo setCreateObject={(o) => setCreateObject(o)} />
                                    </TabPane>


                                </Tabs>
                                :
                                <div className="success">
                                    <Image src={process.env.REACT_APP_URL + "images/success.png"} preview={false} />
                                    <h1 className="w-100 flex-center pad-top-10 green f-16 ">Organizasyon oluşturma talebiniz alındı.</h1>
                                </div>
                            }

                        </Col>
                    </Row>

                </div>
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 3 ?
                    <Button onClick={() => destroyLastState()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                {/* clear state */}
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 3 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 3 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('CREATE_NEW_ORGANIZATION')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 3 ?
                        currentTab === 2 ?
                            <Button onClick={() => handleCreateOrganization()} className="w-100 h-40 f-14 font-w-700 primary-btn pad-0">{t('SEND_REQUEST_ORGANIZATION')}</Button>
                            :
                            <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_NEXT')}</Button>
                        :

                        <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        //clear state
                    }
                </div>

            </div>
        </div>
    )

}



const mapStateToProps = (state) => {
    return {
        organizationTabreducer: state.organizationTabreducer,
        organization: state.organization,
        profile: state.profile

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOrganizationAddDrawer: (props) => { dispatch(setOrganizationAddDrawer(props)) },
        createOrganization: (props) => { dispatch(createOrganization(props)) },
        organizationSearch: (searchData) => { dispatch(organizationSearch(searchData)) },
        resetCreateOrganizationResponse: () => { dispatch(resetCreateOrganizationResponse()) },
        getOrganizationOrgChart: (props) => { dispatch(getOrganizationOrgChart(props)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganizationTabs);
