import { EducationActionType } from "../actions/education.actions";

const initialState = {
  educationList: [],
  educationListLoading: true,
  bulkUpdate: {isLoading : false, status: "" , data : null},
  bulkDelete: {isLoading : false, status: "" , data : null},
  personEducationVideoList: [],
  personEducationVideoListLoading: true,
  personEducationReportList: { status: "", data: [] },
  personEducationReportListLoading: true,
  deleteEducationResponse: { status: "loading", message: "" },
  educationInformation: {
    educationName: null,
    educatorName: "",
    educationResourceId: "",
    isActive: null,
    description: "",
    educationState: null,
    startDate: null,
    endDate: null,
    viewPhoto: "",
    sectionInformations: [],
    subscribers: [],
  },
  educationInformationLoading: true,
  personEducationDetail: {
    educationName: "",
    educatorName: "",
    educationResourceId: null,
    isActive: false,
    description: "",
    educationState: null,
    startDate: null,
    endDate: null,
    viewPhoto: null,
    sectionInformations: [],
    subscribers: [],
    status: "loading",
  },
  personEducationDetailLoading: true,
  personTabEducationList: { status: "loading", message: " ", data: [] },
  personTabEducationListLoading: true,
  educationVideoUpdateStatusResponse: { status: "loading", message: "" },
  educationCreateSectionResponse: { status: "loading", message: "" },
  educationUpdateSectionResponse: { status: "loading", message: "" },
  educationDeleteSectionResponse: { status: "loading", message: "" },
  educationDeleteVideoSectionResponse: { status: "loading", message: "", index: null },
  educationAddSubscriberResponse: { status: "loading", message: ""},
  educationSubscriberRemoveResponse: { status: "loading", message: "", id: null },
  educationVideoSortUpdateResponse: { status: "loading", message: ""},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case EducationActionType.EDUCATION_LIST_REQUEST:
      return {
        ...state,
        educationListLoading: true,
      };
    case EducationActionType.EDUCATION_LIST_SUCCESS:
      return {
        ...state,
        educationList: action.payload,
        educationListLoading: false,
      };
    case EducationActionType.EDUCATION_LIST_FAIL:
      return {
        ...state,
        educationListLoading: false,
      };
      //
    case EducationActionType.BULK_EDUCATION_LIST_EDIT_REQUEST:
      return {
        ...state,
        bulkUpdate: {...state.bulkUpdate, data: null, isLoading:true},
      };
    case EducationActionType.BULK_EDUCATION_LIST_EDIT_SUCCESS:
      return {
        ...state,
        bulkUpdate: {...state.bulkUpdate, data : action.payload, isLoading:false},
      };
    case EducationActionType.BULK_EDUCATION_LIST_EDIT_FAIL:
      return {
        ...state,
        bulkUpdate: {...state.bulkUpdate, data : {...action.payload, status: 'FAIL'}, isLoading:false},
        };
    case EducationActionType.BULK_EDUCATION_LIST_DELETE_REQUEST:
      return {
        ...state,
        bulkDelete: {...state.bulkDelete, data: null, isLoading:true},
      };
    case EducationActionType.BULK_EDUCATION_LIST_DELETE_SUCCESS:
      return {
        ...state,
         bulkDelete: {...state.bulkDelete, data : action.payload, isLoading:false},
      };
    case EducationActionType.BULK_EDUCATION_LIST_DELETE_FAIL:
      return {
        ...state,
         bulkDelete: {...state.bulkDelete, data : {...action.payload, status: 'FAIL'}, isLoading:false},
      };
        //
    case EducationActionType.GET_PERSON_EDUCATION_VIDEOS_REQUEST:
      return {
        ...state,
        personEducationVideoListLoading: true,
      };
    case EducationActionType.GET_PERSON_EDUCATION_VIDEOS_SUCCESS:
      return {
        ...state,
        personEducationVideoList: action.payload,
        personEducationVideoListLoading: false,
      };
    case EducationActionType.GET_PERSON_EDUCATION_VIDEOS_FAIL:
      return {
        ...state,
        personEducationVideoList: { status: "FAIL", message: action.payload },
        personEducationVideoListLoading: false,
      };
    case EducationActionType.GET_PERSON_EDUCATION_VIDEOS_RESET:
      return {
        ...state,
        personEducationVideoList: [],
        personEducationVideoListLoading: true,
      };
    case EducationActionType.DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        deleteEducationResponse: { status: "SUCCESS", message: action.payload },
      };
    case EducationActionType.DELETE_EDUCATION_FAIL:
      return {
        ...state,
        deleteEducationResponse: { status: "FAIL", message: action.payload },
      };
    case EducationActionType.DELETE_EDUCATION_RESET:
      return {
        ...state,
        deleteEducationResponse: { status: "loading", message: "" },
      };
    case EducationActionType.GET_EDUCATION_INFORMATION_REQUEST:
      return {
        ...state,
        educationInformationLoading: true,
      };
    case EducationActionType.GET_EDUCATION_INFORMATION_SUCCESS:
      return {
        ...state,
        educationInformation: action.payload,
        educationInformationLoading: false,
      };
    case EducationActionType.GET_EDUCATION_INFORMATION_FAIL:
      return {
        ...state,
        educationInformationLoading: false,
      };
    case EducationActionType.GET_EDUCATION_INFORMATION_RESET:
      return {
        ...state,
        educationInformation: {
          educationName: null,
          educatorName: "",
          educationResourceId: "",
          isActive: null,
          description: "",
          educationState: null,
          startDate: null,
          endDate: null,
          viewPhoto: "",
          sectionInformations: [],
          subscribers: [],
        },
      };
    case EducationActionType.EDUCATION_REPORT_REQUEST:
      return {
        ...state,
        personEducationReportListLoading: true,
      };
    case EducationActionType.EDUCATION_REPORT_SUCCESS:
      return {
        ...state,
        personEducationReportList: { status: "SUCCESS", data: action.payload },
        personEducationReportListLoading: false,
      };
    case EducationActionType.EDUCATION_REPORT_FAIL:
      return {
        ...state,
        personEducationReportList: { status: "FAIL", message: action.payload },
        personEducationReportListLoading: false,
      };
    case EducationActionType.EDUCATION_REPORT_RESET:
      return {
        ...state,
        personEducationReportList: { status: "", data: [] },
        personEducationReportListLoading: true,
      };
    case EducationActionType.GET_PERSON_EDUCATION_DETAIL_REQUEST:
      return {
        ...state,
        personEducationVideoListLoading: true,
      };
    case EducationActionType.GET_PERSON_EDUCATION_DETAIL_SUCCESS:
      return {
        ...state,
        personEducationDetail: { ...action.payload, status: "SUCCESS" },
        personEducationDetailLoading: false,
      };
    case EducationActionType.GET_PERSON_EDUCATION_DETAIL_FAIL:
      return {
        ...state,
        personEducationDetail: { status: "FAIL", message: action.payload },
        personEducationDetailLoading: false,
      };
    case EducationActionType.GET_PERSON_EDUCATION_DETAIL_RESET:
      return {
        ...state,
        personEducationDetail: {
          educationName: "",
          educatorName: "",
          educationResourceId: null,
          isActive: false,
          description: "",
          educationState: null,
          startDate: null,
          endDate: null,
          viewPhoto: null,
          sectionInformations: [],
          subscribers: [],
          status: "loading",
        },
        personEducationDetailLoading: true,
      };
    case EducationActionType.PERSON_TAB_EDUCATION_LIST_REQUEST:
      return {
        ...state,
        personTabEducationListLoading: true,
      };
    case EducationActionType.PERSON_TAB_EDUCATION_LIST_SUCCESS:
      return {
        ...state,
        personTabEducationList: { data: action.payload, status: "SUCCESS" },
        personTabEducationListLoading: false,
      };
    case EducationActionType.PERSON_TAB_EDUCATION_LIST_FAIL:
      return {
        ...state,
        personTabEducationList: { message: action.payload, status: "FAIL" },
        personTabEducationListLoading: false,
      };
    case EducationActionType.PERSON_TAB_EDUCATION_LIST_RESET:
      return {
        ...state,
        personTabEducationList: { data: [], message: "", status: "loading" },
        personTabEducationListLoading: true,
      };
    case EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        educationVideoUpdateStatusResponse: { status: "SUCCESS", message: action.payload },
      };
    case EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_FAIL:
      return {
        ...state,
        educationVideoUpdateStatusResponse: { status: "FAIL", message: action.payload },
      };
    case EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_RESET:
      return {
        ...state,
        educationVideoUpdateStatusResponse: { status: "loading", message: "" },
      };
    case EducationActionType.EDUCATION_CREATE_SECTION_SUCCESS:
      return {
        ...state,
        educationCreateSectionResponse: { status: "SUCCESS", message: action.payload },
      };
    case EducationActionType.EDUCATION_CREATE_SECTION_FAIL:
      return {
        ...state,
        educationCreateSectionResponse: { status: "FAIL", message: action.payload },
      };
    case EducationActionType.EDUCATION_CREATE_SECTION_RESET:
      return {
        ...state,
        educationCreateSectionResponse: { status: "loading", message: "" },
      };
    case EducationActionType.EDUCATION_UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        educationUpdateSectionResponse: { status: "SUCCESS", message: action.payload },
      };
    case EducationActionType.EDUCATION_UPDATE_SECTION_FAIL:
      return {
        ...state,
        educationUpdateSectionResponse: { status: "FAIL", message: action.payload },
      };
    case EducationActionType.EDUCATION_UPDATE_SECTION_RESET:
      return {
        ...state,
        educationUpdateSectionResponse: { status: "loading", message: "" },
      };
    case EducationActionType.EDUCATION_DELETE_SECTION_SUCCESS:
      return {
        ...state,
        educationDeleteSectionResponse: { status: "SUCCESS", message: action.payload },
      };
    case EducationActionType.EDUCATION_DELETE_SECTION_FAIL:
      return {
        ...state,
        educationDeleteSectionResponse: { status: "FAIL", message: action.payload },
      };
    case EducationActionType.EDUCATION_DELETE_SECTION_RESET:
      return {
        ...state,
        educationDeleteSectionResponse: { status: "loading", message: "" },
      };
    case EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_SUCCESS:
      return {
        ...state,
        educationDeleteVideoSectionResponse: {
          status: "SUCCESS",
          index: action.payload.index,
          message: action.payload.data,
        },
      };
    case EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_FAIL:
      return {
        ...state,
        educationDeleteVideoSectionResponse: { status: "FAIL", message: action.payload, index: null },
      };
    case EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_RESET:
      return {
        ...state,
        educationDeleteVideoSectionResponse: { status: "loading", message: "", index: null },
      };
    case EducationActionType.EDUCATION_ADD_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        educationAddSubscriberResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case EducationActionType.EDUCATION_ADD_SUBSCRIBER_FAIL:
      return {
        ...state,
        educationAddSubscriberResponse: { status: "FAIL", message: action.payload},
      };
    case EducationActionType.EDUCATION_ADD_SUBSCRIBER_RESET:
      return {
        ...state,
        educationAddSubscriberResponse: { status: "loading", message: ""},
      };
    case EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_SUCCESS:
      return {
        ...state,
        educationVideoSortUpdateResponse: { status: "SUCCESS", message: action.payload }
      };
    case EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_FAIL:
      return {
        ...state,
        educationVideoSortUpdateResponse: { status: "FAIL", message: action.payload }
      };
    case EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_RESET:
      return {
        ...state,
        educationVideoSortUpdateResponse: { status: "loading", message: "" },
      };
    default:
      return state;
  }
}
