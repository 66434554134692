import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getRoles, search } from '../../redux/actions/person.actions';
import { getGridSetting, createGridSettings, settingsReset } from '../../redux/actions/setting.actions';
import {
  getPerson, getUserRoles, getConnectedPersons, exportPersons, deletePersonResponseReset,
  resetSalaryList, resetJobHistory, deletePersonControl, doActivePersonReset
} from '../../redux/actions/person.actions';
import { getPersonOrgChart, getPersonWorkHours } from '../../redux/actions/organization.actions';
import { setConnectedpersonsOpenDraw, setPersonAddOpenDraw, setPersonDeleteDrawer } from '../../redux/actions/person-detail-tab-action';
import { Tabs, Button, Image, Dropdown, Layout, Popover } from 'antd';
import { ToastContainer } from 'react-toastify';
import { UnorderedListOutlined, PlusOutlined, ApartmentOutlined, MoreOutlined } from '@ant-design/icons';
import { UilFileExport, UilFileImport } from '@iconscout/react-unicons';

import Media from "react-media";
import moment from 'moment';

import BreadCrumb from '../../features/breadcrump/breadcrump';
import DynamicTable from '../../component/dynamicTable/index';
import DrawerTabs from './personDrawerTabs';
import ExportModal from '../../component/dynamicModal/exportModal';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import InventoryImportModal from '../../component/modals/inventory/import';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../../component/forbidden/index";
import { personListDropdown } from '../../features/global-dropdown-menu/index';
import { formatPhoneNumber } from '../../utils/commonFormFunctions';
import { TreeView } from '../../component/treeView';
import { useWindowDimensions } from '../../utils/config';
import '../../styles/inventory.scss';
import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';
import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";
function Person(props) {
  const { t } = useTranslation();


  const { TabPane } = Tabs;
  const { Content } = Layout;
  const { personImportResponse, setPersonAddOpenDraw, getPerson, getUserRoles, getRoles, search, getGridSetting, createGridSettings, deletePersonResponse, deletePersonResponseReset,
    createGridSettingsResponse, gridSettings, personList, totalNumberOfRecords, setConnectedpersonsOpenDraw, resetSalaryList, resetJobHistory,
    getConnectedPersons, getPersonOrgChart, personOrgChart, exportPersons, settingsReset, profile, personLoading, permissions, getPersonWorkHours,
    deletePersonControl, deletePersonControlResponse, setPersonDeleteDrawer, personDeleteDrawer, doActivePersonResponse, doActivePersonReset } = props;
  const [isPersonDetailDrawerVisible, setIsPersonDetailDrawerVisible] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

  const [importData, setImportData] = useState({
    'key': 10000,
    'isVisible': false
});

  const [isShowPassives, setIsShowPassives] = useState(false)
  const { width } = useWindowDimensions();
  const componentKey = 0;
  const initialSearchData = {
    "searchTerm": "",
    "persons": [],
    "reportsTos": [],
    "jobTitles": [],
    "departments": [],
    "showPassiveRecords": false,
    "pagination": {
      "pageNumber": 1,
      "pageSize": 10,
      "orderBy": "fullName",
      "ascending": true
    },
    'statusId': 1
  }
  const initialGetGridSettingData = {
    pageId: 1, //persons
    gridId: 1, //persons grid
  }
  const [searchData, setSearchData] = useState(initialSearchData)
  const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData)
  const [treeViewData, setTreeViewData] = useState(null);
  let addNodeChildFunc = null;

  function addNode() {
    const node = {
      nodeId: 'new Node',
      parentNodeId: 'O-1'
    };
    addNodeChildFunc(node);
  }
  function onNodeClick(nodeId) {
    if (permissions.includes("person.read") && nodeId !== "virtualParent") {
      const resourceId = treeViewData.find((item) => { return item.nodeId === nodeId }).personResourceId;
      showPersonDetailDrawer(resourceId);
    }
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const exportInitialData = {
    "searchTerm": "",
    "fullName": "",
    "jobTitle": "",
    "department": "",
    "corporateEmail": "",
    "showPassiveRecords": true,
    "exportType": 1,
    "pagination": {
      "pageNumber": 1,
      "pageSize": 25,
      "orderBy": "CorporateEmail",
      "ascending": true
    },
    "statusId": 1
  }
  function handleExportDocument(props) {
    exportInitialData.exportType = props;
    exportPersons(exportInitialData)
    setIsModalVisible(false);
  }
  function callback(key) {
    if (key === '2') {
      getPersonOrgChart(profile.organization.resourceId);
    }
  }
  const multipleRootFilter = (data, type) => {
    const root = data.filter((item) => { return item.parentNodeId === null })
    if (root.length > 1) {
      const virtualParent = {
        "isVirtualParent": true,
        "nodeId": "virtualParent",
        "parentNodeId": null,
        "personResourceId": "",
        "organizationName": profile.organization?.name,
        "organizationLegalName": profile.organization?.legalName,
        "organizationManager": profile.organization?.manager?.displayValue,
        "profileImgUrl": null
      }
      const transformednulls = data.map((item) => {
        if (item.parentNodeId === null) {
          return { ...item, "parentNodeId": "virtualParent" }
        } else {
          return item
        }
      })
      const modifiedList = [virtualParent, ...transformednulls]
      return modifiedList
    } else {
      return data
    }
  }

  useEffect(() => {
    let filteredData = multipleRootFilter(personOrgChart, "person");
    setTreeViewData(filteredData);
  }, [personOrgChart]);
  useEffect(() => {
    search(searchData)
  }, [searchData]);

  useEffect(() => {
    getGridSetting(getGridSettingsData)
  }, [getGridSettingsData])

  useEffect(() => {
    if (deletePersonControlResponse?.status === "openModal") {
      setDeleteModalData({ ...deleteModalData, 'isVisible': true })
    } else if (deletePersonControlResponse?.status === "openDrawer") {
      setPersonDeleteDrawer({
        status: true, //Drawer status
        deleteStatus: deleteModalData.deleteStatus,
        id: deleteModalData.id,
        name: deleteModalData.name,
        componentKey: personDeleteDrawer?.componentKey !== undefined ? personDeleteDrawer?.componentKey + 1 : 30
      })
    }
  }, [deletePersonControlResponse])

  const showPersonDetailDrawer = (id,task) => {
    setIsPersonDetailDrawerVisible(true);
    setSelectedPersonId(id);
    getPerson(id);
    getUserRoles(id);
    getRoles();
    resetSalaryList()
    resetJobHistory()
    if(task){
      setTimeout(() => {
          task == 31 && props.setCurrentTaskState(32);
          task == 61 && props.setCurrentTaskState(62);
          task == 81 && props.setCurrentTaskState(82);
      }, 200);
    }
  }
  const handleSetIsPersonDetailDrawerVisible = (status, id) => {
    setIsPersonDetailDrawerVisible(status)
    if (status === true) {
      getPerson(id);
      getUserRoles(id);
      getRoles();
    }
  }
  const showConnectedPersonsDrawer = (id) => {
    const searchData = {
      "searchTerm": "",
      "pagination": {
        "pageNumber": 1,
        "pageSize": 1000,
        "orderBy": "fullName",
        "ascending": true
      }
    }
    getConnectedPersons({ "data": searchData, "resourceId": id });
    setConnectedpersonsOpenDraw({ "status": true, "id": id });
  }
  const canDelete = (id, name) => {
    if(profile.resourceId == id){
      return DynamicToastMessage({ 'key': 'delete_person_error', 'title': t('ERROR'), 'description': t('DELETESELFERROR'), 'statusType': 'error', 'position': 'bottom-right' })
    }

    deletePersonControl(id)
    setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': true, 'modalType': 'delete' })
  }
  const canPassive = (id, name) => {
    deletePersonControl(id)
    setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': false, 'modalType': 'passive' })
  }
  const canDismall = (id, name) => {
    deletePersonControl(id)
    setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': null, 'modalType': 'dismall' })
  }
  const canActive = (id, name) => {
    setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': null, 'isVisible': true, 'modalType': 'active' })
  }
  const data = personList.map((row) => {
    return (
      {
        "key": row.resourceId,
        "fullName": { "text": row.fullName, "id": row.resourceId, "photo": row.photo, "status": row.statusId },
        "jobTitle": row.jobTitle,
        "department": row.department,
        "corporateEmail": row.corporateEmail,
        "officePhone": formatPhoneNumber(row.officePhone),
        "hireDate": row.hireDate,
        "reportsTo": { "text": row.reportsTo ? row.reportsTo.displayValue : "", "id": row.reportsTo ? row.reportsTo.id : null },
        "connectedPersonsCount": { "text": row.connectedPersonsCount, "id": row.resourceId }
      }
    );
  })
  const tableConfig = () => ({
    filterableColumns: width >= 875 ? ['fullName', 'jobTitle', 'department', 'reportsTo'] : [],
    sortableColumns: ['fullName', 'jobTitle', 'department', 'corporateEmail', 'officePhone', 'hireDate', 'reportsTo', 'connectedPersonsCount'],
    visibilityOptionExcludes: ['profileImgUrl', 'actionMenu'],
    gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
    page: "person"
  })
  const paginationConfig = () => ({
    current: searchData.pagination.pageNumber,
    total: totalNumberOfRecords,
    showSizeChanger: true,
    locale: { items_per_page: "/ " + t('PAGE') },
    position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
  })
  const handleTableChange = (data) => {
    const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
    if (data.sorter.order) {
      paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
    }
    setSearchData({ ...searchData, 'pagination': paginationObj })
  }
  const handleTableSearch = (e) => {
    const pagingObj = { ...searchData.pagination, "pageNumber": 1 }
    setSearchData({ ...searchData, "searchTerm": e, "pagination": pagingObj })
  }
  const handleTableFilter = (data) => {
    setSearchData({
      ...searchData, "pagination": {
        "pageNumber": 1,
        "pageSize": 10,
        "orderBy": "fullName",
        "ascending": true
      },
      "persons": data.persons, "jobTitles": data.jobTitles, "departments": data.departments, "reportsTos": data.reportsTo,
    })
  }
  const handleShowPassivesToogle = (e) => {
    const pagingObj = { ...searchData.pagination, "pageNumber": 1 };
    setIsShowPassives(e);
    setSearchData({ ...searchData, "showPassiveRecords": e, "pagination": pagingObj });
  }
  const handleResetAllfilters = () => {
    setIsShowPassives(false)
    setSearchData(initialSearchData)
  }
  const handleSaveGridSettings = (d) => {
    let createObj = { "data": d, "pageId": getGridSettingsData.pageId, "gridId": getGridSettingsData.gridId }
    createGridSettings(createObj)
  }

  useEffect(() => {
    if (createGridSettingsResponse.status === 'SUCCESS') {
      search(searchData);
      settingsReset();
    }
  }, [createGridSettingsResponse])

  useEffect(() => {
    if (deletePersonResponse.status === 'SUCCESS') {
      DynamicToastMessage({ 'key': 'delete_person_success', 'title': t('SUCCESS'), 'description': deletePersonResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
      deletePersonResponseReset();
      if (personList?.length > 1) {
        search(searchData);
      } else {
        setSearchData({
          ...searchData,
          pagination: {
            pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
          }
        });
      }
      getPersonOrgChart(profile.organization.resourceId);
      setPersonDeleteDrawer({
        status: false,
        deleteStatus: null,
        id: null,
        name: null,
        componentKey: personDeleteDrawer?.componentKey,
      });
    } else if (deletePersonResponse.status === 'FAIL') {
      DynamicToastMessage({ 'key': 'delete_person_error', 'title': t('ERROR'), 'description': deletePersonResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
      deletePersonResponseReset();
    }
  }, [deletePersonResponse])

  useEffect(() => {
    if (doActivePersonResponse.status === 'SUCCESS') {
      DynamicToastMessage({ 'key': 'active_person_success', 'title': t('SUCCESS'), 'description': doActivePersonResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
      doActivePersonReset();
      search(searchData);
      getPersonOrgChart(profile.organization.resourceId);

    } else if (doActivePersonResponse.status === 'FAIL') {
      DynamicToastMessage({ 'key': 'delete_person_error', 'title': t('ERROR'), 'description': doActivePersonResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
      deletePersonResponseReset();
    }
  }, [doActivePersonResponse])

  const addPerson = () => {
    setPersonAddOpenDraw(true)
  }

  // New Import Modal 

  useEffect(() => {
      if(personImportResponse.status === "SUCCESS"){
        setIsShowPassives(false)
        setSearchData(initialSearchData)
      }
  },[personImportResponse])

  const openImportModal = () => {
    setImportData({ ...importData, 'key': importData?.key + 1, 'isVisible': true });

  }
  const closeImportModal = () => {
      setImportData({ ...importData, 'isVisible': false });
  }

      // #region OnBoarding
      const [popoverVisible, setPopoverVisible] = useState(false);
      const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
      const handlePopoverVisibleChange = (visible) => {
          if(visible == false){ props.setCurrentTaskState(null); }
          if(props.currentTaskState !== null){
              setPopoverVisible(visible);
          } 
      };
      
      OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'pages/person/index' );
      
      useEffect(() => { 
          if(props.currentTaskState == 21 || props.currentTaskState == 31 || props.currentTaskState == 61 || props.currentTaskState == 81){
              setPopoverVisible(true); 
          }
          if(props.currentTaskState == 32 || props.currentTaskState == 62 || props.currentTaskState == 82){ 
            const onboardingItem = document.getElementById('onboarding-active-list-item');
              if (onboardingItem) { onboardingItem?.click(); }
          }
          if(props.currentTaskState == 22){ 
              setPopoverVisible(false); 
              addPerson(true);
          }
  
      }, [props.currentTaskState]); 
  
      useEffect(() => {
          if(props.tabreducer.personAddOpenDraw == false && props.currentTaskState == 22){
              props.setCurrentTaskState(null);
          }
      }, [props.tabreducer.personAddOpenDraw])

      useEffect(() => {
        if(isPersonDetailDrawerVisible == false && (props.currentTaskState == 32 || props.currentTaskState == 33 || props.currentTaskState == 63 || props.currentTaskState == 83)){
          
          if(props.currentTaskState == 33){
            return props.setCurrentTaskState(34);
          }
          if(props.currentTaskState == 63){
            return props.setCurrentTaskState(64);
          }
          if(props.currentTaskState == 83){
            return props.setCurrentTaskState(84);
          }
            props.setCurrentTaskState(null);
        }
      }, [isPersonDetailDrawerVisible])

      // #endregion

      

  
  const OperationsSlot = {
    left: <h1 className="page-title">{t('SETTINGS_PEOPLE') + " "} <span className="count-info">({totalNumberOfRecords})</span> </h1>,
    right: <div className="tab-btn">
      <Can I="inventory.export">
          <Button onClick={openImportModal} className="white-btn flex-align-center svg-class f-14 mr-2 import-button" icon={<UilFileImport />}>{t('SETTINGS_IMPORT')}</Button>
      </Can>
      <Can I="person.add">
          <Popover
              key={'person.add'}
              overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
              content={<PopoverContent key={'person.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
              title={false}
              placement="left"
              trigger="click"
              visible={popoverVisible && props.currentTaskState !== 31 && props.currentTaskState !== 61 && props.currentTaskState !== 81 && props.currentTaskState !== null}
              onVisibleChange={handlePopoverVisibleChange}
          >
        <Button
          onClick={() => addPerson()}
          className={`primary-btn w-230 h-32 f-14 ${(popoverVisible && props.currentTaskState == 21) ? 'onboarding-button-border' : ''}`} icon={<PlusOutlined />}
        // className="primary-btn w-180 h-32 f-14 margin-r-24" icon={<PlusOutlined />}
        >
          {t('PARTNER_CREATE_NEW_PERSON')}
        </Button>
        </Popover>
      </Can>
      {/* <Can I="person.export">
        <Dropdown overlayClassName="person-table-responsive-dropdown" overlay={other_proccess(t, showModal)} trigger={['click']}>
          <Button className="white-btn w-178 h-32 f-14 " icon={<MoreOutlined style={{ fontSize: '14px' }} />}>{t('PARTNER_OTHER_OPEARTIONS')}</Button>
        </Dropdown>
      </Can> */}
    </div>,
  };

  const changStatusSelect = (value) => {
    setSearchData({ ...searchData, 'statusId': value })
  }

  const [viewRoleList, setViewRoleList] = useState(false)

  useEffect(() => {
    if (permissions.includes("person.role.list")) {
      setViewRoleList(true)
    }
  }, [])


  return (
    <>
      <Can I="person.list">
        <Layout>
          <ExportModal
            onCancel={() => { setIsModalVisible(false) }}
            status={isModalVisible}
            handleExportDocument={handleExportDocument}
          />
          <ToastContainer />
          <Layout>
            <Content className="page-container person-container">
              <BreadCrumb />
              <div className="tab-menu">
                <Tabs tabBarExtraContent={OperationsSlot} onChange={callback}>
                  <TabPane tab={<span><UnorderedListOutlined />{t('PARTNER_LIST')}</span>} key="1">
                    <div className="table-container">
                      <Media query="(min-width: 875px)" >
                        {matches => {
                          const columns = [
                            {
                              title: `${t('PARTNER_NAME_SURNAME')}`,
                              dataIndex: 'fullName',
                              key: 'fullName',
                              sorter: true,
                              render: (obj, record, index) =>
                                <div className="imageNameActionColumn">
                                  <Image className="table-avatar" src={obj.photo || process.env.REACT_APP_URL + 'images/avatar_' + obj.gender?.id + '.png'} preview={false} />
                                  <Can I="person.read">
                                    {
                                      width >= 875 ?
                                        <React.Fragment>
                                          {(props.currentTaskState == 31 || props.currentTaskState == 61 || props.currentTaskState == 81)  && index == 0
                                          ? 
                                          <Popover
                                          key={'person.edit'}
                                          overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                                          content={<PopoverContent key={'person.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                          title={false}
                                          placement="right"
                                          trigger="click"
                                          visible={popoverVisible && props.currentTaskState !== null}
                                          onVisibleChange={handlePopoverVisibleChange}
                                          >
                                            <a id={`${index == 0 ? 'onboarding-active-list-item' : ''}`} className={`details-text ${index == 0 ? 'onboarding-nav-item onboarding-in-list' : ''}`} onClick={() => showPersonDetailDrawer(obj.id,props.currentTaskState)}>{obj.text} </a>
                                          </Popover>
                                          : <a id={`${index == 0 ? 'onboarding-active-list-item' : ''}`} className={`details-text`} onClick={() => showPersonDetailDrawer(obj.id)}>{obj.text} </a>
                                          }
                                          <Dropdown
                                            className="action-menu"
                                            overlay={personListDropdown(t, canDelete, canPassive, canDismall, canActive, obj.id, obj.text, permissions, obj.status)}
                                            trigger={['click']}
                                          >
                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                          </Dropdown>
                                        </React.Fragment>
                                        :
                                        <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                    }
                                  </Can>
                                  <Cannot I="person.read">
                                    <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                  </Cannot>
                                </div>
                            },
                            {
                              title: `${t('PARTNER_JOB')}`,
                              dataIndex: 'jobTitle',
                              key: 'jobTitle',
                              sorter: true,
                            },
                            {
                              title: `${t('PARTNER_DEPARTMENT')}`,
                              dataIndex: 'department',
                              key: 'department',
                              sorter: true,
                            },
                            {
                              title: `${t('REPORTS_TO')}`,
                              dataIndex: 'reportsTo',
                              key: 'reportsTo',
                              sorter: true,
                              render: obj =>
                                <div>
                                  <Can I="person.read">
                                    {
                                      width >= 875 ?
                                        <React.Fragment>
                                          <a className="details-text" onClick={() => showPersonDetailDrawer(obj.id)}>{obj.text}</a>
                                        </React.Fragment>
                                        :
                                        <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                    }
                                  </Can>
                                  <Cannot I="person.read"><span style={{ paddingLeft: '15px' }} >{obj.text}</span></Cannot>
                                </div>
                            },
                          ];
                          if (matches) {
                            columns.splice(3, 0,
                              {
                                title: `${t('ORGANIZATION_E_MAIL')}`,
                                dataIndex: 'corporateEmail',
                                key: 'corporateEmail',
                                sorter: true,
                              }
                            );
                            columns.splice(4, 0,
                              {
                                title: `${t('PARTNER_OFFICE_PHONE')}`,
                                dataIndex: 'officePhone',
                                key: 'officePhone',
                                sorter: true,
                              },
                            );
                            columns.splice(5, 0,
                              {
                                title: `${t('PARTNER_START_DATE_OF_COMPANY')}`,
                                dataIndex: 'hireDate',
                                key: 'hireDate',
                                sorter: true,
                                render: obj => {
                                  return (obj ? moment(obj).format('LL') : '')
                                }
                              }
                            );
                            columns.push({
                              title: `${t('PERSON_WHO_RECEIVED_REPORTS')}`,
                              dataIndex: 'connectedPersonsCount',
                              key: 'connectedPersonsCount',
                              sorter: true,
                              render: connectedPersonsCount => { return (connectedPersonsCount.text > 0 ? <a onClick={() => showConnectedPersonsDrawer(connectedPersonsCount.id)} className="details-text">{connectedPersonsCount.text + ' ' + t('DEBIT_PERSON')}</a> : t('NONE')) },
                            });
                          }
                          return <DynamicTable columns={columns} dataSource={data}
                            tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                            onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                            onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives} searchPlaceholder={t('PARTNER_SEARCH_BY_DESC_NO')}
                            onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                            loadStatus={personLoading}
                            checkboxStatus={false}
                            onChangeSelect={changStatusSelect}
                            listView={viewRoleList}
                          />;
                        }
                        }
                      </Media >
                    </div>
                  </TabPane>
                  <TabPane tab={<span><ApartmentOutlined />{t('PARTNER_TREEVIEW')}</span>} key="2">
                    <Can I="person.orgchart">
                      <TreeView
                        setClick={click => (addNodeChildFunc = click)}
                        onNodeClick={onNodeClick}
                        data={treeViewData}
                        page="person"
                      />
                    </Can>
                    <Cannot I="person.orgchart">
                      <Forbidden />
                    </Cannot>
                  </TabPane>
                </Tabs>
                <DrawerTabs
                  key={componentKey}
                  searchData={searchData}
                  selectedPersonId={selectedPersonId}
                  isPersonDetailDrawerVisible={isPersonDetailDrawerVisible}
                  setIsPersonDetailDrawerVisible={(status, id) => handleSetIsPersonDetailDrawerVisible(status, id)}
                  deleteModalData={deleteModalData}
                  setDeleteModalData={setDeleteModalData}
                  canDelete={canDelete}
                  canPassive={canPassive}
                  canDismall={canDismall}
                  canActive={canActive}
                />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Can>
      <Cannot I="person.list">
        <Forbidden />
      </Cannot>
      <InventoryImportModal
          key={importData?.key}
          modalState={importData}
          cancelBtnFunction={closeImportModal}
          modalType={'person'}
      />
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    personList: state.person.searchResult.results,
    personLoading: state.person.isSearchLoading,
    totalNumberOfRecords: state.person.searchResult.totalNumberOfRecords,
    personOrgChart: state.organization.personOrgChart,
    gridSettings: state.setting.gridSettings,
    createGridSettingsResponse: state.setting.createGridSettingsResponse,
    deletePersonResponse: state.person.deletePersonResponse,
    profile: state.profile,
    permissions: state.profile.permissions,
    deletePersonControlLoading: state.person.deletePersonControlLoading,
    deletePersonControlResponse: state.person.deletePersonControlResponse,
    personDeleteDrawer: state.tabreducer.personDeleteDrawer,
    doActivePersonResponse: state.person.doActivePersonResponse,
    personImportResponse: state.person.personImportResponse,
    userTaskInfo: state.onboarding.userTaskInfo,
    currentTaskState: state.onboarding.currentTaskState,
    tabreducer: state.tabreducer,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    search: (searchData) => { dispatch(search(searchData)) },
    getPerson: (id) => { dispatch(getPerson(id)) },
    getUserRoles: (id) => { dispatch(getUserRoles(id)) },
    getRoles: () => { dispatch(getRoles()) },
    setConnectedpersonsOpenDraw: (props) => { dispatch(setConnectedpersonsOpenDraw(props)) },
    getConnectedPersons: (id) => { dispatch(getConnectedPersons(id)) },
    getPersonOrgChart: (id) => { dispatch(getPersonOrgChart(id)) },
    exportPersons: (data) => { dispatch(exportPersons(data)) },
    setPersonAddOpenDraw: (props) => { dispatch(setPersonAddOpenDraw(props)) },
    getGridSetting: (props) => { dispatch(getGridSetting(props)) },
    createGridSettings: (props) => { dispatch(createGridSettings(props)) },
    settingsReset: () => { dispatch(settingsReset()) },
    deletePersonResponseReset: () => { dispatch(deletePersonResponseReset()) },
    resetSalaryList: () => { dispatch(resetSalaryList()) },
    resetJobHistory: () => { dispatch(resetJobHistory()) },
    deletePersonControl: (resourceId) => { dispatch(deletePersonControl(resourceId)) },
    setPersonDeleteDrawer: (drawerData) => { dispatch(setPersonDeleteDrawer(drawerData)) },
    doActivePersonReset: () => { dispatch(doActivePersonReset()) },
    getPersonWorkHours: () => { dispatch(getPersonWorkHours()) },
    setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
    setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Person);