import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { educationVideoUpdateStatus, resetEducationVideoUpdateStatus } from '../../redux/actions/education.actions';

import ReactPlayer from 'react-player';
import { Button } from 'antd';
import { UilPlayCircle, UilPauseCircle, UilVolume, UilVolumeMute, UilExpandArrowsAlt } from '@iconscout/react-unicons'
import '../../styles/global.scss';


function CustomPlayer({ videoCompleted, url, pageId, videoId, status, educationVideoUpdateStatus, resetEducationVideoUpdateStatus, educationVideoUpdateStatusResponse, videoDurationSecond, isYoutubeUrl, personEducationDetailLoading }) {
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentSecond, setCurrentSecond] = useState(videoDurationSecond ? videoDurationSecond : 0);
    const [volume, setVolume] = useState(0.8);
    const [lastVolume, setLastVolume] = useState(volume);
    const [ready, setReady] = useState(false);
    const [muted, setMuted] = useState(false);
    const playerRef = useRef(null);
    const intervalIdRef = useRef(null);
    const currentSecondRef = useRef(currentSecond);

    const handleProgress = state => {
        setProgress(state?.played * 100);
        setCurrentSecond(state?.playedSeconds);
    };
    const handleReady = () => {
        setReady(true);
    };
    const togglePlayPause = () => {
        setPlaying(prev => !prev);
    };
    const toggleMute = () => {
        if (muted) {
            setVolume(lastVolume);
            setMuted(false);
        } else {
            setLastVolume(volume);
            setVolume(0);
            setMuted(true);
        }
    };
    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);

        if (newVolume > 0) {
            setLastVolume(newVolume);
            setMuted(false);
        } else {
            setMuted(true);
        }
    };
    const handleSeekBarClick = (e) => {
        const progressBar = e.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const percentage = offsetX / rect.width;

        if (percentage * 100 <= progress) {
            playerRef.current.seekTo(percentage);
            setProgress(percentage * 100);
        }
    };
    const toggleFullscreen = () => {
        const wrapperEl = document.querySelector('.react-player-wrapper');
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            wrapperEl.requestFullscreen();
        }
    };
    const updateState = (type) => {
        let data = {};
        if (status === 1 && type === 'end') {
            data = {
                "videoStateResourceId": videoId,
                "personEducationResourceId": pageId,
                "state": 2,
                "LastSecond": 0

            }
         
        } else if (status !== 2 && type === 'newType') {
            data = {
                "videoStateResourceId": videoId,
                "personEducationResourceId": pageId,
                "state": 1,
                "LastSecond": videoDurationSecond ? videoDurationSecond : 0
            }
        }
        if ((status === 1 && type === 'end') || (status !== 2 && type === 'newType')) {
            educationVideoUpdateStatus(data);
        }
    }
    useEffect(() => {
        if (educationVideoUpdateStatusResponse.message.state == 2) {
            videoCompleted()
            resetEducationVideoUpdateStatus();
        }
        else if (educationVideoUpdateStatusResponse.status === 'FAIL') {
            resetEducationVideoUpdateStatus();
        }
    }, [educationVideoUpdateStatusResponse])

    useEffect(() => {
        currentSecondRef.current = parseInt(currentSecond + 1);
    }, [currentSecond]);

    useEffect(() => {
        function updateVideoStatus() {
            const data = {
                "videoStateResourceId": videoId,
                "personEducationResourceId": pageId,
                "state": 1,
                "LastSecond": currentSecondRef?.current
            }
            educationVideoUpdateStatus(data);
        }
        if (playing) {
            intervalIdRef.current = setInterval(updateVideoStatus, 60000);
        } else if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, [playing]);

    useEffect(() => {
        if (ready && playerRef.current && !isYoutubeUrl && !personEducationDetailLoading) {
                playerRef.current.seekTo(videoDurationSecond, 'seconds');
        }
    }, [ready, playerRef,videoDurationSecond, personEducationDetailLoading, isYoutubeUrl]);

    return (
        <div className="react-player-wrapper">
            <ReactPlayer
               ref={playerRef}
                url={url}
                playing={playing}
                volume={volume}
                muted={muted}
                onProgress={handleProgress}
                onReady={handleReady}
                width="100%"
                height="100%"
                className="react-player"
                config={{ youtube: { playerVars: { disablekb: 1, start: videoDurationSecond } } }}
                onPlay={() => updateState('newType')}
                onEnded={() => updateState('end')}
            />
            <div className="center-icon" onClick={togglePlayPause}>
                {playing ? <UilPauseCircle /> : <UilPlayCircle />}
            </div>
            <div className="video-settings-container">
                <div className='video-bar'>
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={progress}
                        readOnly
                        onClick={handleSeekBarClick}
                    />
                </div>
                <div className='w-100 d-flex space-between'>
                    <div className='d-flex'>
                        <Button className="white-btn" onClick={togglePlayPause}>
                            {playing ? <UilPauseCircle /> : <UilPlayCircle />}
                        </Button>
                        <Button className="white-btn" onClick={toggleMute}>
                            {muted ? <UilVolumeMute /> : <UilVolume />}
                        </Button>
                        <div className='d-flex'>
                            <input
                                type="range"
                                min={0}
                                max={1}
                                step={0.05}
                                value={volume}
                                onChange={handleVolumeChange}
                            />
                        </div>
                    </div>
                    <Button className="white-btn flex-end" onClick={toggleFullscreen}>
                        <UilExpandArrowsAlt />
                    </Button>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        educationVideoUpdateStatusResponse: state.education.educationVideoUpdateStatusResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        educationVideoUpdateStatus: (data) => { dispatch(educationVideoUpdateStatus(data)) },
        resetEducationVideoUpdateStatus: () => { dispatch(resetEducationVideoUpdateStatus()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomPlayer);