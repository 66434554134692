
import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

export const SettingActionTypes = {
    "GET_GRID_SETTINGS_SUCCESS":"GET_GRID_SETTINGS_SUCCESS",
    "CREATE_GRID_SETTING_SUCCESS":"CREATE_GRID_SETTING_SUCCESS",
    "CREATE_GRID_SETTING_FAIL":"CREATE_GRID_SETTING_FAIL",
    "GRID_SETTINGS_RESPONSE_RESET":"GRID_SETTINGS_RESPONSE_RESET"

}
export const getGridSetting = (props) => async dispatch => {
    const pageId = props.pageId;
    const gridId = props.gridId;
    try {
        var config = {
            method: 'get',
            url: apiUrl+'/settings/pages/'+pageId+'/grids/'+gridId,
            headers: { }
        };
        hrApi(config)
        .then(function (response) {
            if(response.status===200){
                dispatch({
                    type: SettingActionTypes.GET_GRID_SETTINGS_SUCCESS,
                    payload: {"data":response.data.data,"pageId":pageId,"gridId":gridId},
                });
            }
        })

        .catch(function (error) {
            if(error.response?.status === 404){
                const emptyData = []
                dispatch({ type: SettingActionTypes.GET_GRID_SETTINGS_SUCCESS, payload: emptyData });
              }
        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};


export const createGridSettings = (props) => async dispatch => {
    
    try {
        const data = JSON.stringify({"columns":props.data});
        const pageId = props.pageId;
        const gridId = props.gridId;

        var config = {
            method: 'post',
            url: apiUrl+'/settings/pages/'+pageId+'/grids/'+gridId,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };
        hrApi(config)
        .then(function (response) {

            if(response.status===200){
                dispatch({ type: SettingActionTypes.CREATE_GRID_SETTING_SUCCESS, payload: response.data.message });
            }
            else{
                dispatch({ type: SettingActionTypes.CREATE_GRID_SETTING_FAIL, payload: response.data.message });
            }
             
        })

        .catch(function (error) {
            
        });

    } catch (e) {
        dispatch({
            type: 404,
            payload: []
        });
    }
};

export const settingsReset = () => {
    
    return async (dispatch) => {
      dispatch({ type: SettingActionTypes.GRID_SETTINGS_RESPONSE_RESET, payload:{} });
      };
  }
