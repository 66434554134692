import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getMyExpenseRequests, getExpenseDetails, getExpenseDetailsStep, expenseChangeStatus, expenseExportDetailPage, resetExpenseExportDetailPage
} from '../../redux/actions/expense.action';
import { Button, Dropdown, Popover } from 'antd';
import { UilPlus } from '@iconscout/react-unicons';
import { MoreOutlined } from '@ant-design/icons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal'

import { useWindowDimensions, dateFormatConvert } from '../../utils/config';
import { myExpenseListDropdown } from '../../features/global-dropdown-menu/index';

import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';

import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";

function MyExpense(props) {
    const { t } = useTranslation();
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })
    const { openCreateExpenseDrawer, myExpenseRequestInitialSearch, myExpenseRequestSearchState, setMyExpenseRequestSearchState, openExpenseDetailsDrawer, getExpenseDetails,
        setSelectExpense, getMyExpenseRequests, myExpenseListLoading, myExpenseList, totalNumberOfRecords, creatExpenseRequests, creatExpenseWithCashRequests, expenseChangeStatus,
        getExpenseDetailsStep, expenseExportDetailPage } = props;

    useEffect(() => {
        if (myExpenseRequestSearchState !== myExpenseRequestInitialSearch) {
            getMyExpenseRequests(myExpenseRequestSearchState);
        }
    }, [myExpenseRequestSearchState]);
    useEffect(() => {
        if (creatExpenseRequests.status === "SUCCESS") {
            getMyExpenseRequests(myExpenseRequestSearchState);
        }
    }, [creatExpenseRequests])

    useEffect(() => {
        if (creatExpenseWithCashRequests.status === "SUCCESS") {
            getMyExpenseRequests(myExpenseRequestSearchState);
        }
    }, [creatExpenseWithCashRequests])
    const canDelete = (id) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'isVisible': true })
    }
    const handleSaveGridSettings = () => { }
    const handleResetAllfilters = () => {
        setMyExpenseRequestSearchState({ ...myExpenseRequestInitialSearch });
    }
    const handleTableFilter = (data) => {
        setMyExpenseRequestSearchState({ ...myExpenseRequestSearchState, "stateTypes": data.stateType })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...myExpenseRequestSearchState.pagination, "pageNumber": 1 }
        setMyExpenseRequestSearchState({ ...myExpenseRequestSearchState, "SearchTerm": e, "pagination": pagingObj })
    }
    const showExpenseDetails = (id) => {
        if (id !== undefined) {
            setSelectExpense({ 'resourceId': id })
            openExpenseDetailsDrawer();
            getExpenseDetails(id);
            getExpenseDetailsStep(id);
        }
    }
    const data = myExpenseList.length > 0 ? myExpenseList.map((row) => {
        return (
            {
                "key": row.expenseResourceId,
                "createdDate": { 'text': moment(row.createdDate).format(dateFormatConvert), 'id': row.expenseResourceId, 'stateTypeId': row.stateType?.id, 'isDraft': row.isDraft },
                "detailCount": row.detailCount,
                "expenseExchangeList": row.expenseExchangeList,
                "expenseMasterTypeName": row.expenseMasterTypeName,
                "totalExpenseTL": row.totalExpenseTL + ' TL',
                "differentAmount": row.differentAmount,
                "stateType": { 'id': row.stateType?.id, 'displayValue': row.stateType?.displayValue, 'resourceId': row.expenseResourceId, 'isDraft': row.isDraft, 'isProcessed': row.isProcessed, },
            }
        );
    }) : [];
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType'] : [],
        sortableColumns: ['stateType', 'detailCount', 'expenseMasterTypeName', 'createdDate'],
        visibilityOptionExcludes: ['stateType', 'detailCount', 'expenseExchangeList', 'expenseMasterTypeName', 'totalExpenseTL', 'differentAmount', 'createdDate'],
        page: "MyExpenses",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    })
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Popover
                        key={'my.expense.add'}
                        overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                        content={<PopoverContent key={'my.expense.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                        title={false}
                        placement="left"
                        trigger="click"
                        visible={popoverVisible && props.currentTaskState !== 31 && props.currentTaskState !== 61 && props.currentTaskState !== 81 && props.currentTaskState !== null}
                        onVisibleChange={handlePopoverVisibleChange}
                    >

                    <Button onClick={() => openCreateExpenseDrawer(popoverVisible && props.currentTaskState == 101)} 
                    className={`flex-class svg-class primary-btn h-32 w-230 ${(popoverVisible && props.currentTaskState == 101) ? 'onboarding-button-border' : ''}`} 
                    icon={<UilPlus />}>{t('PARTNER_NEW_EXPENSE_REQUEST')}</Button>
                    </Popover>
                }
            </div>
        )
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeName'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...myExpenseRequestSearchState.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : myExpenseRequestSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setMyExpenseRequestSearchState({ ...myExpenseRequestSearchState, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }
    const downloadExpenseDetail = (id) => {
        expenseExportDetailPage(id);
    }

    // #region OnBoarding
    const [popoverVisible, setPopoverVisible] = useState(false);
    const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
    const handlePopoverVisibleChange = (visible) => {
        if(visible == false){ props.setCurrentTaskState(null); }
        if(props.currentTaskState !== null){
            setPopoverVisible(visible);
        } 
    };
          
    OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'my_expense' );

    
    useEffect(() => { 
    if(props.currentTaskState == 101){
        setPopoverVisible(true); 
    }
    if(props.currentTaskState == 102){
        openCreateExpenseDrawer(true);
    }
    }, [props.currentTaskState]); 

    // #endregion



    return (
        <div className="table-container">
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={expenseChangeStatus}
                confirmData={'delete'}
                isMultipleData={{ 'resourceId': deleteModalData.id, 'description': null }}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_EXPENSE_REQUEST')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.isDraft) {
                                    return <span>Taslak</span>
                                } else {
                                    if (state.id === '1') {
                                        return <div className='yellow'>{state.displayValue}</div>

                                    } else if (state.id === '2') {
                                        if (state.isProcessed) {
                                            return <span>{t('PROCESSED')}</span>
                                        } else {
                                            return <span>{state.displayValue}</span>
                                        }
                                    } else if (state.id === '3') {
                                        return <div className="flex-class w-100" >
                                            <span className='red flex-align-center'>
                                                {state.displayValue}
                                            </span>
                                        </div>
                                    } else if (state.id === '4') {
                                        return <div className="flex-class w-100" >
                                            <span className='red  flex-align-center'>
                                                {state.displayValue}
                                            </span>
                                        </div>
                                    } else {
                                        return <div>{state?.displayValue}</div>
                                    }
                                }
                            }
                        },
                        {
                            title: `${t('PARTNER_EXPENSE_TYPE')}`,
                            dataIndex: 'expenseMasterTypeName',
                            key: 'expenseMasterTypeName',
                            sorter: true,
                        },
                        {
                            title: `${t('NUMBER_OF_EXPENDITURE_ITEMS')}`,
                            dataIndex: 'detailCount',
                            key: 'detailCount',
                            sorter: true,
                        },
                        {
                            title: `${t('CURRENCY_AMOUNT')}`,
                            dataIndex: 'expenseExchangeList',
                            key: 'expenseExchangeList',
                            sorter: true,
                        },
                        {
                            title: `${t('TL_AMOUNT')}`,
                            dataIndex: 'totalExpenseTL',
                            key: 'totalExpenseTL',
                            sorter: true,
                            render: total => {
                                return parseFloat(total).toLocaleString('tr-TR') + ' TL'
                            }
                        },
                        {
                            title: `${t('OPEN_ADVANCE_DIFFERENCE')}`,
                            dataIndex: 'differentAmount',
                            key: 'differentAmount',
                            sorter: true,
                            render: total => {
                                return parseFloat(total).toLocaleString('tr-TR') + ' TL'
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('EXPENSE_ENTRY_DATE')}`,
                                dataIndex: 'createdDate',
                                key: 'createdDate',
                                sorter: true,
                                render: obj => {
                                    if (obj.stateTypeId !== "1" || obj.isDraft) {
                                        return (
                                            <div className="imageNameActionColumn">
                                                <a onClick={() => showExpenseDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                                <Dropdown className="action-menu" overlay={myExpenseListDropdown(t, canDelete, downloadExpenseDetail, obj.id, obj.stateTypeId, obj.isDraft)} trigger={['click']}>
                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                </Dropdown>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="imageNameActionColumn">
                                                <a onClick={() => showExpenseDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                                <Dropdown
                                                    className="action-menu"
                                                    overlay={null}
                                                    trigger={['click']}
                                                >
                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                                </Dropdown>
                                            </div>
                                        )
                                    }
                                }
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={myExpenseListLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        myExpenseListLoading: state.expense.myExpenseListLoading,
        myExpenseList: state.expense.myExpenseList.results,
        totalNumberOfRecords: state.expense.myExpenseList.totalNumberOfRecords,
        creatExpenseRequests: state.expense.creatExpenseRequests,
        creatExpenseWithCashRequests: state.expense.creatExpenseWithCashRequests,
        expenseChangeStatusResponse: state.expense.expenseChangeStatusResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMyExpenseRequests: (searchData) => { dispatch(getMyExpenseRequests(searchData)) },
        getExpenseDetails: (resourceId) => { dispatch(getExpenseDetails(resourceId)) },
        expenseChangeStatus: (data, type) => { dispatch(expenseChangeStatus(data, type)) },
        getExpenseDetailsStep: (resourceId) => { dispatch(getExpenseDetailsStep(resourceId)) },
        expenseExportDetailPage: (id) => { dispatch(expenseExportDetailPage(id)) },
        resetExpenseExportDetailPage: () => { dispatch(resetExpenseExportDetailPage()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyExpense);