import { useState } from 'react';
import { Layout, Row, Col, Input, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ArrowDownIcon } from '../../features/Icon/Icons';
import PageHead from '../../features/header';
import NavMenu from '../../features/nav-menu';
import HrFooter from '../../features/footer';
import BreadCrump from '../../features/breadcrump/breadcrump';
import SafeBtn from '../../features/helper/safe-btn';
import CallBtn from '../../features/helper/call-btn';
import HelperCard from '../../features/helper/card';
import Collapse from '../../features/Collapse/Collapse'
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import '../../styles/header.scss';
import '../../styles/helper.scss';
import '../../styles/helper-tab.scss';
import '../../styles/button.scss';
import '../../styles/pagination.scss';

const { Content } = Layout;
const { TabPane } = Tabs;


function HelpDetail() {

    const collapseInventoryData = [
        { id: 0, title: "Envanterler Sayfasına Nasıl Erişebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 1, title: "Yeni Envanter Nasıl Ekleyebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 2, title: "Envanter Detay Bilgilerine Nasıl Erişebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 3, title: "Envanter Tiplerine Nasıl Ulaşabilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 4, title: "Yeni Envanter Tipi  Nasıl Ekleyebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 5, title: "Envanter Bilgilerini Nasıl Düzenlerim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 6, title: "Envanteri Nasıl Silebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " }
    ];
    const collapseTimeOffData = [
        { id: 7, title: "İzin Geçmişimi Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 8, title: "Yeni İzin Talebi Nasıl Oluşturabilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 9, title: "İzin Taleplerimi Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 10, title: "İzin Haklarımı Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 11, title: "Kişilerin İzin Haklarını Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 12, title: "İzindeki Kişileri Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 13, title: "Onay Bekleyen İzinleri Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 14, title: "İzin Taleplerimi Nasıl Düzenleyebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 15, title: "Çalışanlarımın İzin Taleplerini Nasıl Onaylayabilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 16, title: "Çalışanlarımın İzin Taleplerini Nasıl Reddedebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 17, title: "İzin Taleplerimi Nasıl İptal Edebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " }
    ];
    const collapsePersonnData = [
        { id: 18, title: "Kişi Bilgilerine Nasıl Erişebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 19, title: "Kişi Bilgilerini Nasıl Düzenleyebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 20, title: "Yeni Kişi Nasıl Eklerim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 21, title: "Kişiyi Nasıl İşten Çıkartırım?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 22, title: "Kişiyi Nasıl Silebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 23, title: "Kişi Bilgilerini Nasıl İndirebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 24, title: "Kişi Bilgilerini Toplu Şekilde Nasıl Güncellerim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 25, title: "Kişilere Nasıl Yönetici Atarım?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
    ];
    const collapseOrganizationData = [
        { id: 26, title: "Organizasyon Bilgilerini Nasıl Görebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 27, title: "Organizasyon Bilgilerini Nasıl Güncellerim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 28, title: "Yeni Departman Nasıl Eklerim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 29, title: "Yeni Şube Nasıl Eklerim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " }
    ];
    const collapseEmbezzledData = [
        { id: 30, title: "Zimmet İşlemleri Sayfasına Nasıl Erişebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 31, title: "Yeni Zimmet Nasıl Ekleyebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 32, title: "Zimmet Detay Bilgilerine Nasıl Erişebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 33, title: "Zimmetleri Nasıl İptal Edebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 34, title: "Zimmet Belgelerini Nasıl İndirebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " },
        { id: 35, title: "İmzalı Zimmet Belgelerini Nasıl Yükleyebilirim?", text: "Ekranın sol kısmında yer alan menü içindeki ...... butonuna tıklayarak ya da Kişiler sayfasına eriştiğinizde sağ üstte yer alan .... butonuna tıklayarak yeni kullanıcı oluşturma sayfasına ilerleyebilirsiniz. " }
    ];
    //Envanter İşlemleri collapslerin toplamı kadar
    const [collapseArray, setCollapseArray] = useState([
        false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false,
        false, false, false, false,
        false, false, false, false, false, false
    ]);

    const changeCollapse = (index) => {
        setCollapseArray(prevState => prevState.map((item, idx) => idx === index ? !item : item))
    };
    return (

        <Layout>
            <PageHead />

            <NavMenu />
            {/* Nav menu içerisindeki kodlardan kaynaklı consol'a birçok hata geliyor */}
            <Layout>
                <Col span={24}>
                    <Content className="page-container">
                        <Row>
                            <Col span={6}>
                                <BreadCrump />
                            </Col>
                            <Col span={18}>
                                <SafeBtn />
                            </Col>
                        </Row>
                        <h1 className="page-title-1">Yardım</h1>
                        <Row>
                            <Col span={21}>
                                <div className="helper-text">
                                    <h1>Size yardımcı olabiliriz. Çözmenize yardımcı olabilmemiz için bize sorununuz hakkında bilgi verin.</h1>
                                </div>
                                <div className="page-search">

                                    <Input placeholder="Kişi, Özellik, Envantere Ara" prefix={<SearchOutlined />} />
                                </div>
                            </Col>
                            <Col span={3}>
                                <CallBtn />
                            </Col>
                        </Row>
                        <div className="pad-21" />
                        <HelperCard />
                        <div className="pad-21" />

                        <div className="helper-tab">
                            <Tabs className="custom-tab" defaultActiveKey="1" >
                                <TabPane tab={"Envanter İşlemleri"} key={"envanterIslemleri"}>

                                    {
                                        collapseInventoryData.map((cData) => {
                                            return (
                                                <div>
                                                    <div className="collapse">
                                                        <div onClick={() => changeCollapse(cData.id)} className="collapse-title">
                                                            <h1>{cData.title}</h1>
                                                        </div>
                                                        <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => changeCollapse(cData.id)} className={collapseArray[cData.id] === true ? "arrow-up" : "arrow"} /></div>
                                                    </div>
                                                    <Collapse isOpen={collapseArray[cData.id]}>

                                                        {collapseArray[cData.id] === true &&
                                                            <div className="collapse-desc-helper ">
                                                                <p>
                                                                    {cData.text}
                                                                </p>

                                                            </div>
                                                        }
                                                    </Collapse>
                                                </div>
                                            )
                                        })
                                    }


                                </TabPane>
                                <TabPane tab={"İzin İşlemleri"} key={"izinIslemleri"}>
                                    {
                                        collapseTimeOffData.map((cData) => {
                                            return (
                                                <div>
                                                    <div className="collapse">
                                                        <div onClick={() => changeCollapse(cData.id)} className="collapse-title">
                                                            <h1>{cData.title}</h1>
                                                        </div>
                                                        <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => changeCollapse(cData.id)} className={collapseArray[cData.id] === true ? "arrow-up" : "arrow"} /></div>
                                                    </div>
                                                    <Collapse isOpen={collapseArray[cData.id]}>

                                                        {collapseArray[cData.id] === true &&
                                                            <div className="collapse-desc-helper ">
                                                                <p>
                                                                    {cData.text}
                                                                </p>

                                                            </div>
                                                        }
                                                    </Collapse>
                                                </div>
                                            )
                                        })
                                    }

                                </TabPane>
                                <TabPane tab={"Kişi İşlemleri"} key={"kisiIslemleri"}>
                                    {
                                        collapsePersonnData.map((cData) => {
                                            return (
                                                <div>
                                                    <div className="collapse">
                                                        <div onClick={() => changeCollapse(cData.id)} className="collapse-title">
                                                            <h1>{cData.title}</h1>
                                                        </div>
                                                        <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => changeCollapse(cData.id)} className={collapseArray[cData.id] === true ? "arrow-up" : "arrow"} /></div>
                                                    </div>
                                                    <Collapse isOpen={collapseArray[cData.id]}>

                                                        {collapseArray[cData.id] === true &&
                                                            <div className="collapse-desc-helper ">
                                                                <p>
                                                                    {cData.text}
                                                                </p>

                                                            </div>
                                                        }
                                                    </Collapse>
                                                </div>
                                            )
                                        })
                                    }
                                </TabPane>
                                <TabPane tab={"Organizasyon İşlemleri"} key={"organizasyonIslemleri"}>
                                    {
                                        collapseOrganizationData.map((cData) => {
                                            return (
                                                <div>
                                                    <div className="collapse">
                                                        <div onClick={() => changeCollapse(cData.id)} className="collapse-title">
                                                            <h1>{cData.title}</h1>
                                                        </div>
                                                        <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => changeCollapse(cData.id)} className={collapseArray[cData.id] === true ? "arrow-up" : "arrow"} /></div>
                                                    </div>
                                                    <Collapse isOpen={collapseArray[cData.id]}>

                                                        {collapseArray[cData.id] === true &&
                                                            <div className="collapse-desc-helper ">
                                                                <p>
                                                                    {cData.text}
                                                                </p>

                                                            </div>
                                                        }
                                                    </Collapse>
                                                </div>
                                            )
                                        })
                                    }

                                </TabPane>
                                <TabPane tab={"Zimmet İşlemleri"} key={"zimmetIslemleri"}>
                                    {
                                        collapseEmbezzledData.map((cData) => {
                                            return (
                                                <div>
                                                    <div className="collapse">
                                                        <div onClick={() => changeCollapse(cData.id)} className="collapse-title">
                                                            <h1>{cData.title}</h1>
                                                        </div>
                                                        <div className="flex-end collapse-icon"><ArrowDownIcon onClick={() => changeCollapse(cData.id)} className={collapseArray[cData.id] === true ? "arrow-up" : "arrow"} /></div>
                                                    </div>
                                                    <Collapse isOpen={collapseArray[cData.id]}>

                                                        {collapseArray[cData.id] === true &&
                                                            <div className="collapse-desc-helper ">
                                                                <p>
                                                                    {cData.text}
                                                                </p>

                                                            </div>
                                                        }
                                                    </Collapse>
                                                </div>
                                            )
                                        })
                                    }
                                </TabPane>

                            </Tabs>
                        </div>











                        <div className="pad-55" />
                    </Content >
                </Col>
                <HrFooter />

            </Layout>
        </Layout >



    )

}
export default HelpDetail;
