import React, { useEffect } from 'react'
import { signinRedirectCallback, fetchAndUpdateUserInfo, signinSilent} from '../../services/userService'
import { useHistory } from 'react-router-dom'
import {useSelector, useDispatch} from "react-redux";
import { Spin} from 'antd'
function LoginCallback() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(async () => {
    async function signinAsync() {
      try {
        const user = await signinRedirectCallback();
        await fetchAndUpdateUserInfo(dispatch, history, user, '/');
        window.sendinblue?.track(
          //brevo_event_login
          'login',
        );
      } catch (error) {
        console.error('Error during signin callback:', error);
        await signinSilent(dispatch, history);
      }
    }
    await signinAsync();
  }, [history, dispatch]);

  return (
    <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"100vh"}}>
      <Spin/>
    </div>
  )
}

export default LoginCallback