import { TravelHostActionTypes } from "../actions/travelHost.actions";

const initialState = {
    travelHostCreateDrawer:false,
    travelHostDetailsDrawer:false,
    createTravelHostResponse:{"status":"loading","message":"","data":""},
    myTravelHostList:{
        totalNumberOfRecords:0,
        results:[]
    },
    myTravelHostLoading:true,
    travelHostApprovalList:{
        totalNumberOfRecords:0,
        results:[]
    },
    travelHostApprovalLoading:true,
    allTravelHostList:{
        totalNumberOfRecords:0,
        results:[]
    },
    allTravelHostLoading:true,
    travelHostResponse:{"status":"loading","message":""},
    travelHostResponseLoading:true,
    travelHostDetails:{
            "travelHostId": null,
            "travelHostResourceId":null,
            "requestType": null,
            "relatedTravelIdx": null,
            "travelDescription": null,
            "hostDescription": null,
            "travelDetails": [],
            "hostDetails": [ ]
    },
    travelHostDetailsLoading:true,
    travelHostUpdateResponse:{"status":"loading","message":""},
    travelHostChangeStatusResponse:{"status":"loading","message":""},
    travelHostDetailStep:[
        {
          "editableStatus": null,
          "closableStatus": null,
          "name": null,
          "authResourceId": null,
          "stepName":null,
          "date": null,
          "sortOrder": null,
          "isSelected": null,
          "description": null,
          "stepListId": null
        },
      ],
    travelHostDetailStepLoading:true,
    travelHostExportData:{},
    travelHostExportDataResponse:{"status":"loading","message":""},
    travelHostApprovalExportData:{},
    travelHostApprovalExportDataResponse:{"status":"loading","message":""},
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case TravelHostActionTypes.SET_TRAVEL_HOST_CREATE_DRAWER:
            return {
                ...state,
                travelHostCreateDrawer:action.payload
            }; 
        case TravelHostActionTypes.SET_TRAVEL_HOST_DETAILS_DRAWER:
            return {
                ...state,
                travelHostDetailsDrawer:action.payload
            }; 
        case TravelHostActionTypes.CREATE_TRAVEL_HOST_SUCCESS:
            return {
                ...state,
                createTravelHostResponse:{
                    "status":"SUCCESS",
                    "message":action.payload.message,
                    "data": action.payload.data.resourceId
                }
            }
        case TravelHostActionTypes.CREATE_TRAVEL_HOST_FAIL:
            return {
                ...state,
                createTravelHostResponse:{
                    "status":"FAIL",
                    "message":action.payload
                }
            }
        case TravelHostActionTypes.CREATE_TRAVEL_HOST_RESET:
            return{
                ...state,
                createTravelHostResponse:{"status":"loading","message":""}
            }
        case TravelHostActionTypes.MY_TRAVEL_HOST_LIST_REQUESTS:
            return {
                ...state,
                myTravelHostLoading: true
            };
        case TravelHostActionTypes.MY_TRAVEL_HOST_LIST_SUCCESS:
            return {
                ...state,
                myTravelHostList: action.payload,
                myTravelHostLoading: false
            }
        case TravelHostActionTypes.MY_TRAVEL_HOST_LIST_FAIL:
            return {
                ...state,
                myTravelHostLoading: false,
                myTravelHostList:{
                    totalNumberOfRecords:0,
                    results:[]
                }
            }
        case TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_REQUEST:
            return {
                ...state,
                travelHostResponse:{
                    "status":"LOADING",
                    "message":"",
                  },
                travelHostResponseLoading: true,
            };
        case TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_SUCCESS:
            return {
                ...state,
                travelHostResponse:{
                    "status":"SUCCESS",
                    "message":action.payload,
                  },
                travelHostResponseLoading: false,
            }
        case TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_FAIL:
            return {
                ...state,
                travelHostResponse:{
                    "status":"FAIL",
                    "message":action.payload,
                },
                travelHostResponseLoading: false,
            }
        case TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_REQUEST:
            return {
                ...state,
                travelHostDetailsLoading: true
            };
        case TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_SUCCESS:
            return {
                ...state,
                travelHostDetails: action.payload,
                travelHostDetailsLoading: false
            }
        case TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_FAIL:
            return {
                ...state,
                travelHostDetailsLoading: false
            } 
        case TravelHostActionTypes.UPDATE_TRAVEL_HOST_SUCCESS:
            return {
                ...state,
                travelHostUpdateResponse:{
                    "status":"SUCCESS",
                    "message":action.payload,
                }
            }
        case TravelHostActionTypes.UPDATE_TRAVEL_HOST_FAIL:
            return {
                ...state,
                travelHostUpdateResponse:{
                    "status":"FAIL",
                    "message":action.payload
                }
            }
        case TravelHostActionTypes.UPDATE_TRAVEL_HOST_RESET:
            return{
                ...state,
                travelHostUpdateResponse:{"status":"loading","message":""}
            }
        case TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                travelHostChangeStatusResponse:{
                    "status":"SUCCESS",
                    "message":action.payload,
                }
            }
        case TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_FAIL:
            return {
                ...state,
                travelHostChangeStatusResponse:{
                    "status":"FAIL",
                    "message":action.payload,
                }
            }
        case TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_RESET:
            return{
                ...state,
                travelHostChangeStatusResponse:{"status":"loading","message":""}
            }
        case TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_REQUESTS:
            return {
                ...state,
                travelHostApprovalLoading: true
            };
        case TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_SUCCESS:
            return {
                ...state,
                travelHostApprovalList: action.payload,
                travelHostApprovalLoading: false
            }
        case TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_FAIL:
            return {
                ...state,
                travelHostApprovalLoading: false,
            }
        case TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_REQUESTS:
            return {
                ...state,
                allTravelHostLoading: true
            };
        case TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_SUCCESS:
            return {
                ...state,
                allTravelHostList: action.payload,
                allTravelHostLoading: false
            }
        case TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_FAIL:
            return {
                ...state,
                allTravelHostLoading: false,
            }
        case TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_REQUEST:
            return {
                ...state,
                travelHostDetailStepLoading: true
            };
        case TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_SUCCESS:
            return {
                ...state,
                travelHostDetailStep: action.payload,
                travelHostDetailStepLoading: false
            }
        case TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_FAIL:
            return {
                ...state,
                travelHostDetailStepLoading: false
            }
        case TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_SUCCESS:
            return {
                ...state,
                travelHostExportDataResponse : action.payload
            }
        case TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_FAIL:
            return {
                ...state,
                travelHostExportData : {},
                travelHostExportDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
            }
        case TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_RESET:
            return {
                ...state,
                travelHostExportData : {},
                travelHostExportDataResponse:{"status":"loading","message":""},
            }

        case TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_SUCCESS:
            return {
                ...state,
                travelHostApprovalExportDataResponse : action.payload
            }
        case TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_FAIL:
            return {
                ...state,
                travelHostApprovalExportData : {},
                travelHostApprovalExportDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
            }
        case TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_RESET:
            return {
                ...state,
                travelHostApprovalExportData : {},
                travelHostApprovalExportDataResponse:{"status":"loading","message":""},
            }
        default:
            return state;
    }
}