
import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

export const DatasetActionType = {
    "1":"GET_PAYMENT_TYPE_SUCCESS",
    "2":"GET_GENDER_SUCCESS",
    "3":"GET_MILITARY_SERVICE_TYPE_SUCCESS",
    "4":"GET_UNIT_TYPE_SUCCESS",
    "5":"GET_ACCRUAL_DATE_TYPE_SUCCESS",
    "6":"GET_ACCRUAL_UNIT_TYPE_SUCCESS",
    "7":"GET_TRANSFER_TYPE_SUCCESS",
    "8":"GET_RENEWAL_TYPE_SUCCESS",
    "9":"GET_STATE_TYPE_SUCCESS",
    "10":"GET_REMOTE_WORK_TYPE_SUCCESS",
    "11":"GET_MARITAL_STATUS_TYPE_SUCCESS",
    "12":"GET_COUPLE_WORK_STATUS_SUCCESS",
    "13":"GET_DISABILITY_DEGREE_SUCCESS",
    "14":"GET_BLOOD_GROUP_SUCCESS",
    "15":"GET_EDUCATION_DEGREE_SUCCESS",
    "16":"GET_WORK_STATUS_SUCCESS",
    "17":"GET_WORK_TYPE_SUCCESS",
    "18":"GET_WORK_STATE_SUCCESS",
    "19":"GET_ORGANIZATION_TYPE_SUCCESS",
    "20":"GET_OWNER_TYPE_SUCCESS",
    "21":"GET_SECTOR_TYPE_SUCCESS",
    "22":"GET_LANGUAGE_TYPE_SUCCESS",
    "23":"GET_CURRENCY_TYPE_SUCCESS",
    "24":"GET_PERSON_STATUS_TYPE_SUCCESS",
    "25":"GET_ASSIGMENT_STATE_TYPE_SUCCESS",
    "26":"GET_INVENTORY_ALL_STATE_TYPE_SUCCESS",
    "32":"GET_DRIVING_LICENCE_LIST_SUCCESS",
    "33":"GET_MILITARY_SERVICE_TYPE_SUCCESS",
    "34":"GET_EMERGENCY_DEGREE_LIST_SUCCESS",
    "35":"GET_INVENTORY_STATE_TYPE_SUCCESS",
    "27":"GET_SALARY_REASON_TYPE_SUCCESS",
    "28":"GET_EXPENSE_TYPE_LIST_SUCCESS",
    "36":"GET_DISMISSAL_SUCCESS",
    "37":"GET_PROCESS_TYPE_SUCCESS",
    "38":"GET_PROCESS_PERSON_TYPE_SUCCESS",
    "42":"GET_ACCRUAL_DURATION_TYPE_SUCCESS",
    "GET_TIME_OFF_STATE_TYPE_SUCCESS":"GET_TIME_OFF_STATE_TYPE_SUCCESS",
    "GET_TIME_OFF_STATE_TYPE_FAIL":"GET_TIME_OFF_STATE_TYPE_FAIL",
    "GET_TIME_OFF_STATE_TYPE_WARNING":"GET_TIME_OFF_STATE_TYPE_WARNING",
    "DATASET_RESET":"DATASET_RESET"
}

export const getDataset = (lookupId) => async dispatch => {
    let lookupIdList = []
    if(typeof(lookupId) === 'object'){
        lookupIdList = lookupId
    }else{
        lookupIdList = [lookupId]
    }
    try {
        var data = JSON.stringify({
            "LookupTypeIds": lookupIdList
          });

        var config = {
        method: 'post',
        url: apiUrl+'/lookup',
        headers: {'Content-Type': 'application/json' },
        data : data
        };

        hrApi(config)
        .then(function (response) {

            lookupIdList.forEach((item,index)=>{
                dispatch({
                    type : DatasetActionType[item],
                    payload : response.data.data[index].items,
                })
            })
            
        })
        .catch(function (error) {
        });
 
        
    } catch (e) {
        dispatch({
            type: 404,
            payload: console.log(e)
        });
    }
};


export const datasetReset = () => {
    return async (dispatch) => {
        dispatch({ type:DatasetActionType.DATASET_RESET, payload:{} });
        };
};


export const getInventoryTypes = () => async dispatch => {
    try {
        const res = await hrApi.get(apiUrl + "/inventory/types");
        var inventoryTypes = res.data.map((row, index) => ({
            i: index,
            id: row.inventoryTypeId,
            name: row.inventoryTypeName
        })) 
        dispatch({
            type: 'GET_INVENTORY_TYPES',
            payload: inventoryTypes,
        });
    } catch (e) {
        dispatch({
            type: 'GET_INVENTORY_TYPES_ERROR',
            payload: console.log(e)
        });
    }
};

export const getAccessory = () => async dispatch => {
    try {
        const res = await hrApi.get(apiUrl + "/inventory/devices");
        var devicesList = res.data.filter((row, index) => {
            return row.isAccessory === true
        }).map((row, index) => (
            {
                i: index,
                id : row.brand.id,
                name : row.serialNumber + ' - ' + row.brand.name,
            }
        )) 
        dispatch({
            type: 'GET_INVENTORY_DEVICES_LIST',
            payload: devicesList,
        });
    } catch (e) {
        dispatch({
            type: 'GET_INVENTORY_DEVICES_LIST_ERROR',
            payload: console.log(e)
        });
    }
};

export const getPersonReceivingReturn = () => async dispatch => {
    try {
        const res = await hrApi.get(apiUrl + "/people/1");
        var personReceivingReturn = res.data.map((row, index) => (
            {
                i: index,
                id : row.id,
                name : row.person.givenName + " " + row.person.familyName + " - " +  row.jobTitle.name,
            }
        )) 
        dispatch({
            type: 'GET_PERSON_RECEIVING_RETURN',
            payload: personReceivingReturn,
        });
    } catch (e) {
        dispatch({
            type: 'GET_PERSON_RECEIVING_RETURN_ERROR',
            payload: console.log(e)
        });
    }
};

export const getTimeOffStateType = (searchData) => async dispatch => {
    try {
     
        const data = JSON.stringify(searchData);
  
        var config = {
        method: 'post',
        url: apiUrl+'/lookup/filter/stateType',
        headers: {'Content-Type': 'application/json' },
        data : data
        };

        hrApi(config)
        .then(function (response) {
            if(response.status===200){
                dispatch({
                    type : DatasetActionType.GET_TIME_OFF_STATE_TYPE_SUCCESS,
                    payload : response.data.data,
                })
               }else{
                dispatch({
                    type : DatasetActionType.GET_TIME_OFF_STATE_TYPE_FAIL,
                    payload : response.data.message,
                })
               }
              
            
        })
        .catch(function (error) {
            if(error.response?.status === 404){
                const emptyData = {
                    "pageNumber": 1,
                    "pageSize": 0,
                    "totalNumberOfPages": 0,
                    "totalNumberOfRecords": 0,
                    "results": []
                }
              dispatch({ type: DatasetActionType.GET_TIME_OFF_STATE_TYPE_SUCCESS, payload: emptyData });
            }
        dispatch({ type: DatasetActionType.GET_TIME_OFF_STATE_TYPE_FAIL, payload: error.message });

          });
 
        
    } catch (error) {
        if (error.response) {
            dispatch({ type: DatasetActionType.GET_TIME_OFF_STATE_TYPE_FAIL, payload: error.message });
        }
    }
};
