import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPersons } from "../../../../redux/actions/organization.actions";
import { getDataset } from "../../../../redux/actions/dataset-action";
import { getCheckDefaultProcess, setCheckDefaultProcess } from "../../../../redux/actions/approval-processes.actions";

import { Input, ConfigProvider, Row, Col, Button, List, Checkbox } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { UilDraggabledots, UilTrashAlt } from '@iconscout/react-unicons'

import DynamicToastMessage from "../../../dynamicToastMessage";
import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/person.scss';

function DetailApprovalProcessesBasicForm(props) {
    const { basicTabState, setBasicTabState, processType, getDataset,
        getPersons, persons, profile, processPersonType, approvalProcessesDetailInfo,
        checkDefaultProcessResponse, getCheckDefaultProcess, setCheckDefaultProcess, approvalFormData, setApprovalFormData } = props;
    const { t } = useTranslation();
    //Delete which on approval list data 
    const [deleteApprovalStatus, setDeleteApprovalStatus] = useState([]);
    //ApprovalDesk
    const [approvalListState, setApprovalListState] = useState();
    //processTypeInputOptions
    const [processTypeState, setProcessTypeState] = useState([]);
    //processPersonTypeOptions
    const [processPersonTypeState, setProcessPersonTypeState] = useState([]);

    useEffect(() => {
        if (approvalProcessesDetailInfo?.processName) {
            setApprovalFormData({
                "IsDefault": approvalProcessesDetailInfo?.isDefault,
                "ProcessName": approvalProcessesDetailInfo?.processName,
                "ProcessType": { "id": parseInt(approvalProcessesDetailInfo?.processType?.id), "displayValue": approvalProcessesDetailInfo?.processType?.displayValue },
            });
            const approvalList = approvalProcessesDetailInfo?.processStep.map((data) => {
                return {
                    "resourceId": data.resourceId,
                    "id": parseInt(data.processPersonType?.id),
                    "displayValue": data.processPersonType?.displayValue,
                    "secondApproval": parseInt(data.processPersonType?.id) === 6 ? { "id": data?.anotherUser?.resourceId, "displayValue": data?.anotherUser?.displayValue } : { "id": null, "displayValue": null },
                    "status": "default"
                }
            });
            setApprovalListState(approvalList);
        }
    }, [approvalProcessesDetailInfo]);
    useEffect(() => {
        setBasicTabState({ ...basicTabState, "ProcessStep": approvalListState })
    }, [approvalListState]);
    useEffect(() => {
        setBasicTabState({ ...basicTabState, "IsDefault": approvalFormData?.IsDefault, "ProcessName": approvalFormData?.ProcessName, "ProcessType": approvalFormData?.ProcessType })
    }, [approvalFormData])
    useEffect(() => {
        setBasicTabState({ ...basicTabState, "DeleteProcessStep": deleteApprovalStatus })
    }, [deleteApprovalStatus])
    useEffect(() => {
        getDataset(38);
        getPersons(profile.organization?.resourceId);
    }, []);
    useEffect(() => {
        var arr = [];
        processType.length > 0 &&
            processType.map((data) =>
                arr.push({
                    'id': parseInt(data.id),
                    'displayValue': data.displayValue
                }))
        setProcessTypeState(arr);
    }, [processType])
    useEffect(() => {
        var arr = [];
        processPersonType.length > 0 &&
            processPersonType.map((data) =>
                arr.push({
                    'id': parseInt(data.id),
                    'displayValue': data.displayValue
                }))
        setProcessPersonTypeState(arr);
    }, [processPersonType])

    const DragHandle = SortableHandle(({ style }) => (
        <span style={style} className="sortable-list-icon"><UilDraggabledots /></span>)
    );
    const SortableItem = SortableElement(({ value }) => {
        return <div className="sortable-list-item">
            <div className="processes-detail-form-item">
                <label >{t('PROCESSES_APPROVE', { 'n': value.index + 1 })}  {approvalListState.length !== 1 && <span className="sortable-list-delete" onClick={() => deleteApprovalProcesses(value.index, value.data.status)}><UilTrashAlt /></span>}</label>
            </div>
            <Row>
                <Col span={1}>
                    <DragHandle />
                </Col>
                <Col span={23}>
                    <div className="processes-detail-form-item" >
                        <div className="approval-processes-select" >
                            <Row>
                                <Col span={value.data.id === 6 ? 12 : 24}>
                                    <div className={value.data.id === 6 ? "pr-3" : ""}>
                                        <DynamicSelectbox
                                            key={value.index + 100}
                                            onChange={(e) => handleApprovalProcessesChange(e, value.index, 'approval')}
                                            optionList={processPersonTypeState}
                                            placeholder={t('PARTNER_SELECT')}
                                            orderBy={'none'}
                                            value={{ 'id': value.data.id, 'displayValue': value.data.displayValue }}
                                        />
                                    </div>
                                </Col>
                                {value.data.id === 6 &&
                                    <Col span={12}>
                                        <div className="pl-3">
                                            <DynamicSelectbox
                                                key={value.index + 100}
                                                onChange={(e) => handleApprovalProcessesChange(e, value.index, 'secondApproval')}
                                                optionList={persons}
                                                placeholder={t('PARTNER_SELECT')}
                                                orderBy={'none'}
                                                value={{ 'id': value.data?.secondApproval?.id, 'displayValue': value.data?.secondApproval?.displayValue }}
                                            />
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    });
    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul className="sortable-list-container">
                {items.length > 0 && items.map((value, index) => (
                    <SortableItem key={`item-${value.id}}`} index={index} value={{ 'data': value, 'index': index }} />
                ))}
            </ul>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newList = [...approvalListState];
        if (newList[oldIndex].status === 'default') {
            newList[oldIndex].status = 'update';
        }
        setApprovalListState(newList);
        setApprovalListState(items => arrayMoveImmutable(items, oldIndex, newIndex));
    };
    const addApprovalProcesses = () => {
        const list = [...approvalListState];
        let hasError = false;
        let fields = [];
        var requiredFields = {
            "processes": false,
            'processesOther': false
        };
        list.map((data) => {
            if (!data.id && data.id !== 6) {
                requiredFields.processes = true;
            }
            if (data.id === 6 && !data.secondApproval.id) {
                requiredFields.processesOther = true;
            }
        });
        if (requiredFields.processes) {
            hasError = true;
            fields.push(t('PROCESSES_APPROVE_PROCESSES'));
        }
        if (requiredFields.processesOther) {
            hasError = true;
            fields.push(t('PERSON_CHOOSE_USER'));
        }
        if (!hasError) {
            requiredFields = {
                "processes": false,
                'processesOther': false
            };
            list.push(
                {
                    'resourceId': null,
                    'id': null,
                    'displayValue': null,
                    "secondApproval": { "id": null, "displayValue": "" },
                    "SeqNumber": approvalListState.length + 1,
                    "status": "add"
                }
            );
            setApprovalListState(list);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const deleteApprovalProcesses = (index, status) => {
        const list = [...approvalListState];
        if (status === 'default' || status === 'update') {
            const deleteData = {
                'ResourceId': list[index].resourceId,
                'SeqNumber': index + 1,
                'ProcessPersonType': list[index]?.id,
                'AnotherUserResourceId': list[index]?.id === 6 ? list[index]?.secondApproval?.id : null
            }
            const deleteList = [...deleteApprovalStatus];
            deleteList.push(deleteData);
            setDeleteApprovalStatus(deleteList);
        }
        list.splice(index, 1);
        setApprovalListState(list);
    }
    const handleApprovalProcessesChange = (e, index, type) => {
        const list = [...approvalListState];
        if (list[index].status === 'default') {
            list[index].status = 'update';
        }
        if (type === "approval") {
            list[index]["id"] = e.id;
            list[index]["displayValue"] = e.displayValue;
            if (list[index]["secondApproval"].id) {
                list[index]["secondApproval"] = { "id": null, "displayValue": "" }
            }
        } else {
            list[index]["secondApproval"] = { 'id': e.id, 'displayValue': e.displayValue };
        }
        setApprovalListState(list);
    }
    const processesChangeStaticInput = (type, e) => {
        if (type === 'checkbox') {
            if (e.target.checked) {
                getCheckDefaultProcess(approvalFormData.ProcessType?.id);
            } else {
                setCheckDefaultProcess();
            }
            setApprovalFormData({ ...approvalFormData, 'IsDefault': e.target.checked });
        } else if (type === 'input') {
            setApprovalFormData({ ...approvalFormData, 'ProcessName': e.target.value });
        } else {
            if (approvalFormData.IsDefault) {
                getCheckDefaultProcess(e.id);
            }
            setApprovalFormData({ ...approvalFormData, 'ProcessType': e });
        }
    }
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>

            <div className="ant-form processes-detail-form ">
                <div className="ant-item mb-2 my-0 add-user-department-checkbox-all">
                    <Checkbox
                        checked={approvalFormData.IsDefault}
                        disabled={approvalProcessesDetailInfo?.isDefault}
                        onChange={(e) => processesChangeStaticInput('checkbox', e)}
                    >
                        {t('SETTINGS_DEFAULT_PROCESS')}</Checkbox>
                </div>
                <div className="processes-detail-form-item">
                    <label>{t('NAME_YOUR_PROCESS') + " *"} </label>
                    <Input
                        value={approvalFormData.ProcessName}
                        onChange={(e) => processesChangeStaticInput('input', e)}
                    />
                </div>
                <div className="processes-detail-form-item" >
                    <label >{t('PROCESSES_TYPE') + " *"}</label>
                    <div className="approval-processes-select">
                        <DynamicSelectbox
                            disabled={approvalProcessesDetailInfo?.isDefault}
                            value={approvalFormData.ProcessType}
                            onChange={(e) => processesChangeStaticInput('select', e)}
                            optionList={processTypeState}
                            onFocus={() => getDataset(37)}
                            placeholder={t('PARTNER_SELECT')}
                            orderBy={'none'}
                        />
                    </div>
                </div>
                <div className="processes-detail-form-item py-2 " >
                    <label>{t('PROCESSES_APPROVE_PROCESSES')}</label>
                </div>
                {approvalListState?.length > 0 &&
                    <SortableList
                        items={approvalListState}
                        onSortEnd={onSortEnd}
                        axis="y"
                        helperClass="sortable-list-tab sortable-list-detail-container"
                        lockAxis="y"
                        distance={10}
                    />
                }
                <Button className="transparent-btn pad-0 f-14" onClick={() => addApprovalProcesses()}>{t('PROCESSES_NEW_STEP')}</Button>
            </div>
        </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        processType: state.dataset.processType,
        processPersonType: state.dataset.processPersonType,
        checkDefaultProcessResponse: state.approvalProcesses.checkDefaultProcessResponse,
        persons: state.organization.persons,
        profile: state.profile
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (type) => { dispatch(getDataset(type)) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        getCheckDefaultProcess: (type) => { dispatch(getCheckDefaultProcess(type)) },
        setCheckDefaultProcess: () => { dispatch(setCheckDefaultProcess()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailApprovalProcessesBasicForm);