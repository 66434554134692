import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useLocation } from 'react-router-dom';

import {Steps} from "antd";


const { Step } = Steps;


function PaymentSteps(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const [activeStep, setActiveStep] = useState(null);

    useEffect(() => {
        if(location.pathname =='/prices'){
            setActiveStep(0)
        }else if(location.pathname =='/payment' || location.pathname.includes('/newPackagePayment')){
            setActiveStep(1)
        }else if(location.pathname.includes('/paymentCompleted')){
            setActiveStep(2)
        }
    }, [location]);

    const stepList = [
        {
            key:0,
            title: t('CHOOSE_PACKAGE')
        },
        {
            key:1,
            title: t('PAYMENT_INFO')
        },
        {
            key:2,
            title: t('PAYMENT_SUMMARY')
        },
    ];

    return (
        <div className="steps-wrapper">
            <Steps 
            current={activeStep} 
            >
                {
                    stepList.map((item)=>{
                        return(
                            <Step 
                            title={item.title}
                            key={item.key}
                            />
                        )
                    })
                }
            </Steps>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSteps);