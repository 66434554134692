import React, { useState } from 'react';
import { Modal, Button, Tooltip, TreeSelect, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@tanstack/react-query';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import LangSelector from '../langSelector';
import { hrApi } from '../../utils/apiConfig';
import { apiUrl } from '../../utils/config';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { signinSilent } from "../../services/userService";
const { SHOW_PARENT } = TreeSelect;

async function fetchSystemSettings() {

    const config = {
        method: "get",
        url: `${apiUrl}/systemSettings`,
    };
    const { data } = await hrApi(config);
    return data;
}

async function createSystemSettings(settings) {
    const config = {
        method: "post",
        url: `${apiUrl}/createSystemSettings`,
        data: settings,
    };
    const { data } = await hrApi(config);
    return data;
}

const OrganizationCultureModal = ({ isVisible, onClose }) => {
    const { t } = useTranslation();
    const [publicHolidayList, setPublicHolidayList] = useState([]);
    const [selectedCulture, setSelectedCulture] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nLang') || 'en');
    
    const { data: systemSettings, isLoading: systemSettingsLoading } = useQuery(
        ['systemSettings'],
        fetchSystemSettings,
        { 
            enabled: isVisible, 
            refetchOnWindowFocus: false,
        }
    );

    const dispatch = useDispatch();
    const history = useHistory();

    const mutation = useMutation(createSystemSettings, {
        onSuccess: () => {
            signinSilent(dispatch,history)
        },
        onError: (error) => {
            if(error?.response?.data?.status == "FAILED" && error?.response?.data?.message){
                DynamicToastMessage({
                    key: 'error_key',
                    title: t('ERROR'),
                    description: error?.response?.data?.message,
                    statusType: 'error',
                    position: 'bottom-right'
                });
            }else{
                DynamicToastMessage({
                    key: 'error_key',
                    title: t('ERROR'),
                    description: t('EDUCATION_ERROR_OCCURRED'),
                    statusType: 'error',
                    position: 'bottom-right'
                });
            }
        },
    });

    const handlePublicHolidayChange = (newValue) => {
        setPublicHolidayList(newValue);
    };

    const handleCultureChange = (value) => {
        setSelectedCulture(value);
    };

    const handleSubmit = () => {
        const settings = {
            cultureSetIdx: selectedCulture,
            publicHolidayList: publicHolidayList,
        };
        mutation.mutate(settings);
    };

    const publicHolidayOptions = systemSettings?.data?.publicHolidayList?.map(item => ({
        title: item.displayValue,
        value: Number(item.id),
        key: item.id,
    })) || [];

    const leaveTypeOptions = systemSettings?.data?.cultureList?.map(item => ({
        value: Number(item.id),
        label: item.displayValue,
    })) || [];

    const treeSelectProps = {
        treeData: selectedLanguage == 'en' ? publicHolidayOptions?.reverse() : publicHolidayOptions,
        value: selectedLanguage == 'en' ? publicHolidayList?.reverse() : publicHolidayList,
        onChange: handlePublicHolidayChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: t("TIMEOFF_SELECT_PLACEHOLDER"),
        style: { width: '100%' },
    };

    return (
        <Modal 
            className="culture-select-modal" 
            visible={isVisible} 
            onCancel={onClose}
            footer={null} 
            closable={false}
            zIndex={1000}
        >   
            <div className="culture-select-modal-wrapper">
                <div className="culture-select-modal-header">
                    <h2>{t('LAST_STEP')}</h2>
                    <LangSelector />
                </div>
                <div className="culture-select-modal-select">
                    <div className="mb-6">
                        <div className="d-flex align-items-center">
                            <div className="culture-input-label-text">{t('PUBLIC_HOLIDAYS')}</div>
                            <Tooltip placement="rightTop" title={t('PUBLIC_HOLIDAYS_TOOLTIP')}>
                                <InfoCircleOutlined className="ml-2 mb-1" />
                            </Tooltip>
                        </div>
                        <div className="mt-2">
                            <TreeSelect {...treeSelectProps} />
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="d-flex align-items-center">
                            <div className="culture-input-label-text">{t('LEAVE_TYPES')}</div>
                            <Tooltip placement="rightTop" title={t('LEAVE_TYPES_TOOLTIP')}>
                                <InfoCircleOutlined className="ml-2 mb-1" />
                            </Tooltip>
                        </div>
                        <div className="mt-2">
                            <Select
                                showSearch
                                placeholder={t("TIMEOFF_SELECT_PLACEHOLDER")}
                                optionFilterProp="label"
                                onChange={handleCultureChange}
                                options={selectedLanguage == 'en' ? leaveTypeOptions?.reverse() : leaveTypeOptions}
                                className="w-100"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Button
                className="ant-btn primary-btn h-32 f-14 w-100"
                onClick={handleSubmit}
                disabled={mutation.isLoading || (publicHolidayList.length == 0 || !selectedCulture)}
            >
                 { mutation.isLoading ? <Spin /> : t('ONBOARDING_CONTINUE')}
            </Button>
        </Modal>
    );
};

export default OrganizationCultureModal;