import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Input, ConfigProvider, Radio, Checkbox } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import '../../../../styles/timeoffTypes.scss';

function AddTimeoffTypeDetailInfo(props) {
    const { t } = useTranslation();

    const [form] = Form.useForm()
    const { setUpdateTimeoffTypeObject, timeoffType } = props;


    const [basicInfoState, setBasicInfoState] = useState({});


    useEffect(() => {
        setBasicInfoState({
            ...timeoffType
        })
        form.setFieldsValue({
            ...timeoffType
        })

    }, [form,timeoffType])

    useEffect(() => {
        setUpdateTimeoffTypeObject(basicInfoState)
    }, [basicInfoState]);

    const allowOnlyNumericKeyPress = (e) => {
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericPaste = (e) => {
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if(key === 'isExceedable'){
            if(changedObj["isExceedable"] != timeoffType.isExceedable){
                setBasicInfoState({ ...basicInfoState, 'isExceedable': changedObj["isExceedable"], 'maxExceedBalance': null, 'isExceedableInform': false });
                form.setFieldsValue({
                    "maxExceedBalance": null,
                    'isExceedableInform': false
                })
            }else{
                setBasicInfoState({ ...basicInfoState, 'isExceedable': changedObj["isExceedable"], 'maxExceedBalance': timeoffType.maxExceedBalance, 'isExceedableInform': timeoffType.isExceedableInform });
                form.setFieldsValue({
                    "maxExceedBalance": timeoffType.maxExceedBalance,
                    'isExceedableInform': timeoffType.isExceedableInform
                })
            }
        }
        else if(key === 'isGenderType'){
            if(changedObj["isGenderType"] != timeoffType.isGenderType){
                setBasicInfoState({ ...basicInfoState, 'isGenderType': changedObj["isGenderType"], 'genderType': null });
                form.setFieldsValue({
                    "genderType": null
                })
            }else{
                setBasicInfoState({ ...basicInfoState, 'isGenderType': changedObj["isGenderType"], 'genderType': timeoffType.genderType });
                form.setFieldsValue({
                    "genderType": timeoffType.genderType
                })
            }
        }
        else if(key === 'isAppearContactInfo'){
            if(changedObj["isAppearContactInfo"] != timeoffType.isAppearContactInfo){
                setBasicInfoState({ ...basicInfoState, 'isAppearContactInfo': changedObj["isAppearContactInfo"], 'isRequiredContactInfo': null });
                form.setFieldsValue({
                    "isRequiredContactInfo": null
                })
            }else{
                setBasicInfoState({ ...basicInfoState, 'isAppearContactInfo': changedObj["isAppearContactInfo"], 'isRequiredContactInfo': timeoffType.isRequiredContactInfo  });
                form.setFieldsValue({
                    "isRequiredContactInfo": timeoffType.isRequiredContactInfo 
                })
            }
        }
        else {
            setBasicInfoState({ ...basicInfoState, ...changedObj });
        }
    }


    return (
        
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <div className={timeoffType.isTransferable !== basicInfoState?.isTransferable ? 'is-checked' : ''}>
                    <Form.Item name="isTransferable" label={t('TIMEOFF_TRANSFER')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_TRANSFER_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    <div className={timeoffType.isExceedable !== basicInfoState?.isExceedable ? 'is-checked' : ''}>
                    <Form.Item name="isExceedable" label={t('TIMEOFF_TIMEOUT')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_TIMEOUT_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    { basicInfoState?.isExceedable == true &&
                    <Form.Item className="margin-b-12 delete-draw-label custom-input-group" label={t('TIMEOFF_TIMEOUT_MAX')} name="maxExceedBalance">
                        <Input 
                        style={{maxWidth: '245px'}} addonAfter={t('PARTNER_DAY')} 
                        onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} 
                        placeholder={t("TIMEOFF_LIMITED_PLACEHOLDER")} 
                        className={timeoffType.maxExceedBalance != basicInfoState.maxExceedBalance ? 'is-changed' : ''}
                        /> 
                    </Form.Item>
                    }
                    <div className={timeoffType.isExceedableInform !== basicInfoState?.isExceedableInform ? 'is-checked' : ''}>
                    <Form.Item name="isExceedableInform" label={t('TIMEOFF_TIMEOUT_INFO')} valuePropName="checked">
                        <Checkbox disabled={!basicInfoState?.isExceedable && 'disabled' }>{t('TIMEOFF_TIMEOUT_INFO_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    <div className={timeoffType.isGenderType !== basicInfoState?.isGenderType ? 'is-checked' : ''}>
                    <Form.Item name="isGenderType" className="custom-label-tooltip" label={t('TIMEOFF_ACCORDING_GENDER')} valuePropName="checked" tooltip={{
                    title: t('TIMEOFF_ACCORDING_GENDER_TOOLTIP'),
                    icon: <InfoCircleOutlined />,
                    placement:'top'
                    }}>
                        <Checkbox >{t('TIMEOFF_ACCORDING_GENDER_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    { basicInfoState?.isGenderType == true &&
                    <Form.Item className="margin-b-12" name="genderType" >
                        <Radio.Group>
                            <Radio value="2" className={timeoffType.genderType !== basicInfoState.genderType ? 'is-changed-radio' : ''}>{t('PARTNER_WOMAN')}</Radio>
                            <Radio value="1" className={timeoffType.genderType !== basicInfoState.genderType ? 'is-changed-radio' : ''}>{t('PARTNER_MAN')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    }
                    <div className={timeoffType.isRenewalableBalance !== basicInfoState?.isRenewalableBalance ? 'is-checked' : ''}>
                    <Form.Item name="isRenewalableBalance" label={t('TIMEOFF_RENEWAL')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_RENEWAL_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    <div className={timeoffType.isRequiredDocument !== basicInfoState?.isRequiredDocument ? 'is-checked' : ''}>
                    <Form.Item name="isRequiredDocument" label={t('TIMEOFF_REQUIRED_DOC_UPLOAD')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_REQUIRED_DOC_UPLOAD_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    <div className={timeoffType.isAppearContactInfo !== basicInfoState?.isAppearContactInfo ? 'is-checked' : ''}>
                    <Form.Item name="isAppearContactInfo" label={t('TIMEOFF_CONTACT_INFO')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_CONTACT_INFO_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                    { basicInfoState?.isAppearContactInfo == true &&
                    <Form.Item className="margin-b-12" name="isRequiredContactInfo" >
                        <Radio.Group>
                            <Radio value={true} className={timeoffType.isRequiredContactInfo !== basicInfoState.isRequiredContactInfo ? 'is-changed-radio' : ''}>{t('TIMEOFF_CONTACT_INFO_OPT_REQUIRED')}</Radio>
                            <Radio value={false} className={timeoffType.isRequiredContactInfo !== basicInfoState.isRequiredContactInfo ? 'is-changed-radio' : ''}>{t('TIMEOFF_CONTACT_INFO_OPT_NOT_REQUIRED')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    }
                    <div className={timeoffType.isRequiredDescription !== basicInfoState?.isRequiredDescription ? 'is-checked' : ''}>
                    <Form.Item name="isRequiredDescription" label={t('TIMEOFF_REQUIRED_DESCRIPTION')} valuePropName="checked">
                        <Checkbox >{t('TIMEOFF_REQUIRED_DESCRIPTION_DESC')}</Checkbox>
                    </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTimeoffTypeDetailInfo);