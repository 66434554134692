import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    resetTaskUpdateStatus, setMyTaskDetailDrawer, taskUpdateStatus,
    getMyTasksList, completeAllTasks, resetCompleteAllTasks, myTasksList
} from "../../../../redux/actions/task.actions";

import { Col, Row, Button, List, Avatar, Spin } from 'antd';
import { UilMultiply, UilAngleRightB, UilAngleLeftB } from '@iconscout/react-unicons';
import { WaitIcon, SuccessIcon, CupIcon, DownIcon } from "../../../../features/Icon/Icons";
import { MergeObjects } from "../../../../utils/commonFormFunctions";

import Successful from "../../../successful";
import MyTasksDetailDynamicTab from './my_task_detail_dynamic_tab';
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/table.scss'
import '../../../../styles/task-drawer.scss';

function MyTasksDetailAllTabs(props) {
    const { t } = useTranslation();
    const { setMyTaskDetailDrawer, personTaskInformation, personTaskInformationLoading, resetTaskUpdateStatus,
        taskUpdateStatusResponse, taskUpdateStatus, getMyTasksList, myTaskSearchState, setMyTaskSearchState,
        completeAllTasksResponse, completeAllTasks, resetCompleteAllTasks, myTasksList } = props;
    const [currentTab, setCurrentTab] = useState(0); // tab state
    const [pageIndex, setPageIndex] = useState(1); //check complete index
    const [updateCount, setUpdateCount] = useState(0);
    const [assignedTasksListState, setAssignedTasksListState] = useState([]);
    const [taskCount, setTaskCount] = useState({
        completedTaskCount: 0,
        totalTaskCount: 0
    });
    const [dynamicTitle, setDynamicTitle] = useState("")
    const [createTaskObj, setCreateTaskObj] = useState({
        personResourceId: "",
        data: {},
        label: {},
        attribute: {}
    });
    useEffect(() => {
        if (personTaskInformation?.personTasks?.length > 0) {
            var list = [];
            personTaskInformation?.personTasks.map(data => {
                if (data.taskResourceId)
                    return list.push({
                        'resourceId': data.taskResourceId,
                        'name': data.taskName,
                        'description': data.taskDescription,
                        'assignedInformation': {
                            'assignedDate': data.assignedInformation.assignedDate,
                            'attributes': data.assignedInformation.attributes,
                            'lastProcessDatetime': data.assignedInformation.lastProcessDatetime,
                            'taskAssignedResourceId': data.assignedInformation.taskAssignedResourceId,
                            'completedDescription': data.assignedInformation.completedDescription
                        },
                        'isAssigned': data.assignedInformation?.isCompleted,
                        'appointeeName': data.appointeeName,
                        'appointeeResourceId': data.appointeeResourceId
                    });
            });
            setAssignedTasksListState(list);
            setTaskCount({
                completedTaskCount: personTaskInformation?.completedTaskCount ?? 0,
                totalTaskCount: personTaskInformation?.totalTaskCount,
            });
            setDynamicTitle(personTaskInformation?.displayMessage);
        };
    }, [personTaskInformation])
    useEffect(() => {
        if (completeAllTasksResponse.status === "SUCCESS") {
            setPageIndex(2);
            setTaskCount({
                completedTaskCount: completeAllTasksResponse?.data?.data?.completedTaskCount,
                totalTaskCount: completeAllTasksResponse?.data?.data?.totalTaskCount
            });
            setUpdateCount(updateCount + 1);
            resetCompleteAllTasks();
        } else if (completeAllTasksResponse.status === "FAIL") {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': completeAllTasksResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetCompleteAllTasks();
        }
    }, [completeAllTasksResponse])

    useEffect(() => {
        if (taskUpdateStatusResponse.status === "SUCCESS") {
            var list = [...assignedTasksListState];
            list[currentTab].isAssigned = !list[currentTab].isAssigned;
            setAssignedTasksListState(list);
            setDynamicTitle(taskUpdateStatusResponse?.data?.data?.displayMessage);
            setTaskCount({
                completedTaskCount: taskUpdateStatusResponse?.data?.data?.completedTaskCount,
                totalTaskCount: taskUpdateStatusResponse?.data?.data?.totalTaskCount
            });
            if (taskUpdateStatusResponse?.data?.data?.totalTaskCount > 0 && taskUpdateStatusResponse?.data?.data?.completedTaskCount === taskUpdateStatusResponse?.data?.data?.totalTaskCount) {
                setPageIndex(2);
            }
            setUpdateCount(updateCount + 1);
            resetTaskUpdateStatus();
        } else if (taskUpdateStatusResponse.status === "FAIL") {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': taskUpdateStatusResponse.data?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTaskUpdateStatus();
        }
    }, [taskUpdateStatusResponse])

    const closeDrawerControl = () => {
        setMyTaskDetailDrawer(false)
        setCreateTaskObj({
            personResourceId: "",
            data: {},
            label: {},
            attribute: {}
        });
        setPageIndex(1);
        setTaskCount({
            completedTaskCount: 0,
            totalTaskCount: 0
        })
        setAssignedTasksListState([]);
        resetTaskUpdateStatus();
        setCurrentTab(0);
        if (updateCount > 0) {
            if (myTasksList?.length > 0 && personTaskInformation?.personTasks?.length > 0) {
                getMyTasksList(myTaskSearchState);
            } else {
                setMyTaskSearchState({
                    ...myTaskSearchState,
                    pagination: {
                        pageNumber: myTaskSearchState.pagination.pageNumber > 0 ? myTaskSearchState.pagination.pageNumber - 1 : 0, pageSize: myTaskSearchState.pagination.pageSize, orderBy: myTaskSearchState.pagination.orderBy
                    }
                });
            }
        }
    }
    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createTaskObj, obj)
        setCreateTaskObj(mergedObj)
    }
    const nextBtn = () => {
        if (currentTab !== personTaskInformation.totalTaskCount) {
            setCurrentTab((i) => i + 1);
        }
    }
    const prevBtn = () => {
        if (currentTab > 0) {
            setCurrentTab((i) => i - 1);
        }
    }
    const tabOnClick = (index) => {
        setCurrentTab(index)
    }
    const closeTaskRequest = () => {
        setPageIndex(2);
    }
    const completeTasks = () => {
        var resourceIdList = [];
        assignedTasksListState.map((data) => resourceIdList.push(data.assignedInformation?.taskAssignedResourceId));
        completeAllTasks(resourceIdList);
    }
    const taskBtnStatus = (statusId) => {
        let updateObj = {}
        updateObj = {
            state: statusId,
            resourceId: assignedTasksListState[currentTab]?.assignedInformation?.taskAssignedResourceId,
            description: createTaskObj.data[assignedTasksListState[currentTab]?.resourceId][0]?.value
        }
        taskUpdateStatus(updateObj);
    }
    return (
        personTaskInformationLoading ? <Spin className="tab-loading" /> :
            <div className="task-drawer">
                <div className="table-details">

                    <div className="add-global-close-icon">
                        <UilMultiply onClick={() => closeDrawerControl()} />
                    </div>
                    {pageIndex === 1 ?
                        <>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('MY_TASKS')}</h1>
                            </div>
                            <h3 className="pt-4 fw-600 black f-18">{t('EMPLOYEE_INFORMATION')}</h3>
                            <div className="d-flex w-100 justify-space-between ">
                                {personTaskInformation?.personName ?
                                    <>
                                        <div className="d-flex">
                                            <Avatar size="large" src={<img src={personTaskInformation?.avatar !== "" ? personTaskInformation?.avatar : process.env.REACT_APP_URL + 'images/avatar_0.png'} alt="avatar" />} />
                                            <div className="d-block pl-2  mt-1">
                                                <p className="f-16 black fw-600 line-10">{personTaskInformation?.personName} </p>
                                                <p className="f=14 grey12 line-10">{personTaskInformation?.department} </p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="pb-10" >-</div>
                                }
                                <div>
                                    <div className="flex-end pb-2">
                                        <div className="step-achivement">
                                            {assignedTasksListState.map((data, index) => {
                                                return <div key={index} className="step-achivement-item">
                                                    <div className="step-achivement-icons">
                                                        {assignedTasksListState.length !== index + 1 && <DownIcon className={currentTab !== index ? 'hide-icon' : ''} />}
                                                        {assignedTasksListState.length === index + 1 && <CupIcon />}
                                                    </div>
                                                    <span className={data.isAssigned ? "achivement-bar active" : "achivement-bar"}> </span>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <label className="d-block text-end f-18 fw-500 black w-200">{dynamicTitle}</label>
                                </div>
                            </div>
                            <div className="w-100 ">
                                <Row>
                                    <Col span={8}>
                                        <div className="d-inline-flex pt-1">
                                            <h3 className="fw-600 black f-18">{t('TASKS')}
                                            </h3>
                                            <span className="mt-1 pl-2">({taskCount?.completedTaskCount}/{taskCount?.totalTaskCount})</span>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="d-inline-flex pl-3">
                                            {currentTab > 0 &&
                                                <Button onClick={prevBtn} icon={<UilAngleLeftB />} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn prev-btn ">{t('INVENTORY_BACK')} </Button>
                                            }
                                            {(taskCount?.totalTaskCount - 1 > currentTab && currentTab > 0) &&
                                                <div className="buttons-border mx-4" />
                                            }
                                            {taskCount?.totalTaskCount - 1 > currentTab &&
                                                <Button onClick={nextBtn} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn next-btn">{t('INVENTORY_NEXT')} <UilAngleRightB /></Button>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="flex-end">
                                            {assignedTasksListState[currentTab]?.isAssigned ?
                                                <Button onClick={() => taskBtnStatus(1)} className="w-200 h-40 f-14 font-w-700 grey-btn">{t('UNDO_MISSION')}</Button>
                                                :
                                                <Button onClick={() => taskBtnStatus(4)} className="w-200 h-40 f-14 font-w-700 primary-btn">{t('MARK_AS_COMPLETE')}</Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <List
                                            className="task-step-list drawer-scrollable my-task-drawer"
                                            itemLayout="horizontal"
                                            loading={personTaskInformationLoading}
                                            dataSource={assignedTasksListState}
                                            renderItem={(data, index) => (
                                                <List.Item onClick={() => tabOnClick(index)} key={index} className={index === currentTab ? data.isAssigned ? "task-item-active" : "task-item-current" : ""}>
                                                    <List.Item.Meta
                                                        className="cursor-pointer"
                                                        avatar={data.isAssigned ? <SuccessIcon /> : <WaitIcon />}
                                                        title={<label className={data.isAssigned ? "cursor-pointer active-text" : "cursor-pointer"}>{data.name}</label>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                    <Col span={16} >
                                        <MyTasksDetailDynamicTab
                                            assignedTasksListState={assignedTasksListState}
                                            setCreateObject={setCreateObject}
                                            currentTab={currentTab}
                                            createTaskObj={createTaskObj}
                                            setCreateTaskObj={setCreateTaskObj}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>
                        :
                        <Successful
                            whiteBtn={null}
                            primaryBtn={<Button onClick={() => closeDrawerControl()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('INVENTORY_RETURN_LIST')}</Button>}
                            dynamicMessage={taskCount?.completedTaskCount === taskCount?.totalTaskCount ? t('HOMEPAGE_CONGATULATIONS') : t('MISSIONS_SUCCESSFULLY_UPDATED')}
                            dynamicIcon={taskCount?.completedTaskCount === taskCount?.totalTaskCount ? process.env.REACT_APP_URL + "images/award.png" : process.env.REACT_APP_URL + "images/hourglass.png"}
                            message={taskCount?.completedTaskCount === taskCount?.totalTaskCount ? t('COMPLETE_ALL_TASK') : <div>
                                <span>{t('AGAIN_FOR_UNFINISHED_TASKS')}</span>
                                <br />
                                <span>{t('REMAINING_NUMBER_OF_MISSIONS', { count: taskCount?.totalTaskCount - taskCount?.completedTaskCount })}</span>
                            </div>}
                        />
                    }
                </div>
                <div className="add-user-draw-footer">
                    <div className="add-user-draw-footer-end">
                        <div className="pad-left-24" />
                        {pageIndex === 1 &&
                            <>
                                <Button disabled={taskCount?.totalTaskCount === taskCount?.completedTaskCount} onClick={() => completeTasks()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('COMPLETE_ALL_MISSION')}</Button>
                                <Button onClick={() => closeTaskRequest()} className="w-180 h-40 f-14 font-w-700 transparent-btn ">{t('END_PROCESSES')}</Button>

                            </>
                        }
                    </div>
                </div>
                {pageIndex === 1 &&
                    <div className="add-user-draw-footer">
                        <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                        <div className="add-user-draw-footer-end">
                            <div className="pad-left-24" />
                            <>
                                <Button disabled={taskCount?.totalTaskCount === taskCount?.completedTaskCount} onClick={() => completeTasks()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('COMPLETE_ALL_MISSION')}</Button>
                                <Button onClick={() => closeTaskRequest()} className="w-180 h-40 f-14 font-w-700 transparent-btn ">{t('END_PROCESSES')}</Button>
                            </>
                        </div>
                    </div>
                }
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        personTaskInformation: state.taskReducer.personTaskInformation,
        personTaskInformationLoading: state.taskReducer.personTaskInformationLoading,
        taskUpdateStatusResponse: state.taskReducer.taskUpdateStatusResponse,
        completeAllTasksResponse: state.taskReducer.completeAllTasksResponse,
        myTasksList: state.taskReducer.myTasksList.taskItems,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setMyTaskDetailDrawer: (status) => { dispatch(setMyTaskDetailDrawer(status)) },
        resetTaskUpdateStatus: () => { dispatch(resetTaskUpdateStatus()) },
        taskUpdateStatus: (data) => { dispatch(taskUpdateStatus(data)) },
        getMyTasksList: (searchData) => { dispatch(getMyTasksList(searchData)) },
        completeAllTasks: (data) => { dispatch(completeAllTasks(data)) },
        resetCompleteAllTasks: () => { dispatch(resetCompleteAllTasks()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyTasksDetailAllTabs);