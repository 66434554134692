import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Button, Form, Input, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons';
import moment from 'moment';
import { noData } from '../../utils/commonFormFunctions';

import { getDocumentTypeList, createDocumentType, resetCreateDocumentType, updateDocumentType, resetUpdateDocumentType, deleteDocumentType, resetDeleteDocumentType } from '../../redux/actions/document-type.action';

import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { dateFormatConvert } from "../../utils/config";

function DocumentTypeListTab(props) {
    const { totalNumberOfRecords, getDocumentTypeList, updateDocumentTypeResponse,
        updateDocumentType, resetUpdateDocumentType, documentTypeList, documentTypeListLoading, createDocumentType, createDocumentTypeResponse,
        resetCreateDocumentType, deleteDocumentType, resetDeleteDocumentType, deleteDocumentTypeResponse, profile
    } = props;
    const { t } = useTranslation();

    const [addModalState, setAddModalState] = useState({ 'isVisible': false })
    const [addModalFormData, setAddModalFormData] = useState({})
    const [addForm] = Form.useForm();

    const [editModalState, setEditModalState] = useState({ 'isVisible': false })
    const [editModalFormData, setEditModalFormData] = useState({})
    const [editForm] = Form.useForm();

    const [deleteModalState, setDeleteModalState] = useState({ 'isVisible': false })

    const addDocumentType = () => {
        setAddModalState({ addModalState, 'isVisible': true })
    }
    const handleValuesAddChange = (changedObj, all) => {
        setAddModalFormData({ ...addModalFormData, ...changedObj });
    }
    const addModalForm = (
        <div className="table-details pad-0">
            <Form
                form={addForm}
                onValuesChange={handleValuesAddChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_TYPE_DOCUMENTS')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
    const handleAddCancel = () => {
        setAddModalFormData({});
        setAddModalState({ "isVisible": false })
        const fields = addForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        addForm.setFieldsValue(projects)
    }

    const [isCreateLoading, setisCreateLoading] = useState(false);
    
    const handleAddConfirm = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        let hasError = false
        let fields = []
        if (!addModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_TYPE_DOCUMENTS'))
        }

        if (!hasError) {
            const data = {
                "documentTypeName": addModalFormData.name,
                "isRequired": false
            }
            createDocumentType(data)
            setAddModalState({ "isVisible": false })
            setAddModalFormData({});
            const fields = addForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            addForm.setFieldsValue(projects)
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    //Document Type List Add
    useEffect(() => {
        if (createDocumentTypeResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': createDocumentTypeResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getDocumentTypeList()
            resetCreateDocumentType();

        } else if (createDocumentTypeResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': createDocumentTypeResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateDocumentType();
        }
    }, [createDocumentTypeResponse])
    useEffect(() => {
        if (updateDocumentTypeResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': updateDocumentTypeResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getDocumentTypeList()
            resetUpdateDocumentType();

        } else if (updateDocumentTypeResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateDocumentTypeResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateDocumentType();
        }
    }, [updateDocumentTypeResponse])
    const editDocumentType = (id, name) => {
        setEditModalState({ ...editModalState, 'isVisible': true, 'id': id })
        setEditModalFormData({ ...editModalFormData, 'name': name })
        const setObj = {
            "name": name
        }
        editForm.setFieldsValue(setObj)
    }
    const handleValuesEditChange = (changedObj, all) => {
        setEditModalFormData({ ...addModalFormData, ...changedObj });
    }
    const editModalForm = (
        <div className="table-details pad-0">
            <Form
                form={editForm}
                onValuesChange={handleValuesEditChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_TAG_NAME')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
    const handleEditConfirm = () => {
        let hasError = false
        let fields = []
        if (!editModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_TAG_NAME'))
        }
        if (!hasError) {
            const data = {
                "documentTypeName": editModalFormData.name,
                "isRequired": false
            }
            updateDocumentType(data, editModalState.id)
            setEditModalState({ "isVisible": false })
            setEditModalFormData({});
            const fields = editForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            editForm.setFieldsValue(projects)

        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleEditCancel = () => {
        setEditModalFormData({});
        setEditModalState({ "isVisible": false })
        const fields = editForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        editForm.setFieldsValue(projects)
    }
    useEffect(() => {
        if (deleteDocumentTypeResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': deleteDocumentTypeResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getDocumentTypeList()
            resetDeleteDocumentType();

        } else if (deleteDocumentTypeResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': deleteDocumentTypeResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetDeleteDocumentType();
        }
    }, [deleteDocumentTypeResponse])

    const deleteDocumentTypeModal = (id) => {
        setDeleteModalState({ ...deleteModalState, 'isVisible': true, 'id': id })
    }
    return (
        <div className="custom-table-card-container job-list-container">
            <DynamicRequestModal
                modalVisible={addModalState.isVisible}
                setStateData={setAddModalState}
                isForm={addModalForm}
                handleConfirm={handleAddConfirm}
                handleCancel={handleAddCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('CREATE_DOCUMENT_TYPE')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('SETTINGS_CREATE')}
            />
            <DynamicRequestModal
                modalVisible={editModalState.isVisible}
                setStateData={setEditModalState}
                isForm={editModalForm}
                handleConfirm={handleEditConfirm}
                handleCancel={handleEditCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('PARTNER_EDIT')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('PARTNER_SAVE')}
            />
            <DynamicRequestModal
                modalVisible={deleteModalState.isVisible}
                setStateData={setDeleteModalState}
                postAction={deleteDocumentType}
                confirmData={deleteModalState.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_DOCUMENT_TYPE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <div className="custom-table-card-add">
                <Button onClick={() => addDocumentType()} className="primary-btn m-w-180 h-32"><PlusOutlined /> {t('CREATE_NEW_DOCUMENT_TYPE')}</Button>
            </div>
            <div className="custom-table-card-class">
                {totalNumberOfRecords > 0 ?
                    <div className="custom-table-card-title-class job-list-column">
                        <Col span={24}>
                            <Row>
                                <Col span={8}>
                                    <h1>{t('PARTNER_TYPE_DOCUMENTS')}</h1>
                                </Col>
                                <Col span={8}>
                                    <h1>{t('CREATE_DATE')}</h1>
                                </Col>
                                <Col span={8} />
                            </Row>
                        </Col>
                    </div>
                    : ''}
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={null}
                    dataSource={documentTypeList} //documentTypeList
                    loading={documentTypeListLoading}
                    className={totalNumberOfRecords === 0 ? 'table-border-bottom-0' : 'custom-table-list'}
                    locale={{ emptyText: (<div className="loading-container">{noData(t)}</div>) }}
                    renderItem={data => (
                        <List.Item
                            key={data?.resourceId + 'fs'}
                            className={data?.publicDocument ? "job-list-item document-type-disable-card" : "job-list-item"}
                        >
                            <Card key={data.resourceId} className="custom-table-card job-list-card" >
                                <Col span={24}>
                                    <Row>
                                        <Col span={8}>
                                            <p>{data.documentTypeName}</p>
                                        </Col>
                                        <Col span={8}>
                                            <p>{data.createdDate ? moment(data.createdDate).format(dateFormatConvert) : null}</p>
                                        </Col>
                                        <Col span={6} />
                                        {!data?.publicDocument &&
                                            <>
                                                <Col span={1}>
                                                    <div onClick={() => editDocumentType(data.resourceId, data.documentTypeName)} className="custom-table-card-icon flex-end clickable">
                                                        <UilEditAlt />
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div onClick={() => deleteDocumentTypeModal(data.resourceId)} className="custom-table-card-icon flex-end clickable">
                                                        <UilTrash />
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        documentTypeList: state.documentType.documentTypeList.results,
        totalNumberOfRecords: state.documentType.documentTypeList.totalNumberOfRecords,
        documentTypeListLoading: state.documentType.documentTypeListLoading,
        createDocumentTypeResponse: state.documentType.createDocumentTypeResponse,
        updateDocumentTypeResponse: state.documentType.updateDocumentTypeResponse,
        deleteDocumentTypeResponse: state.documentType.deleteDocumentTypeResponse,
        permissions: state.profile.permissions,
        profile: state.profile,

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        createDocumentType: (data) => { dispatch(createDocumentType(data)) },
        resetCreateDocumentType: () => { dispatch(resetCreateDocumentType()) },
        getDocumentTypeList: () => { dispatch(getDocumentTypeList()) },
        updateDocumentType: (data, id) => { dispatch(updateDocumentType(data, id)) },
        resetUpdateDocumentType: () => { dispatch(resetUpdateDocumentType()) },
        deleteDocumentType: (id) => { dispatch(deleteDocumentType(id)) },
        resetDeleteDocumentType: () => { dispatch(resetDeleteDocumentType()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeListTab);