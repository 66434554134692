import { Col, Card, Row, Button, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import React, {useEffect} from "react";
import "../../styles/subscription.scss";
import { UilArrowCircleUp,UilUsersAlt,UilRepeat,UilCreditCard,UilFileAlt } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/tr';
import { dateFormatConvert } from '../../utils/config';

function SubscriptionPayment({openCreditCardModal,openTryPaymentAgainModal,openPaymentHistroyModal,subscriptionResponse, isPaymentFailed , permissionList}) {

    const { t } = useTranslation();

    const [permissions, setPermissions ] = React.useState(permissionList)
    useEffect(()=>{
        setPermissions(permissionList)
    },[JSON.stringify(permissionList)])
    

    return (
            <Row gutter={[24, 24]}>
                <Col xs={24} lg={12}>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Card>
                                <div className="subscription-container">
                                    <h6 className="subscription-card-title">{t("PACKAGE")}</h6>
                                    <div className="subscription-package-information">
                                        <div className="d-flex space-between">
                                            <h6 className="subscription-package-name">{subscriptionResponse?.currentPackage?.packageName}</h6>
                                            <div>
                                                <span className="subscription-package-name grey">
                                                    {subscriptionResponse?.currentPackage?.currencyId == 1 
                                                    ? subscriptionResponse?.currentPackage?.amount?.toLocaleString('tr-TR') + ' ₺'
                                                    : '$'+subscriptionResponse?.currentPackage?.amount?.toLocaleString('en-US').replace(',' , '')
                                                    }
                                                </span>
                                                <span className="price-ext">
                                                    /{subscriptionResponse?.currentPackage?.isYearly ? t("PACKAGE_PERIOD_YEAR") : t("PACKAGE_PERIOD_MONTH")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <UilUsersAlt size={20} className="primary-green-font-color mr-2" />
                                            <div className="subscription-card-title subtitle">{subscriptionResponse?.currentPackage?.employeeCount} {t("EMPLOYEE_COUNT_LBL")} </div>
                                        </div>
                                    </div>
                                    <Link className="subscription-button-link" to="/prices">
                                        <Button 
                                        className="white-btn subscription-button" icon={<UilArrowCircleUp />}>
                                            {t("SUBSCRIPTION_CHANGE_PACKAGE")}
                                        </Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                {subscriptionResponse?.cardInformation ? 
                                <div className="subscription-container">
                                    <h6 className="subscription-card-title">{t("CARD_INFO")} </h6>
                                    <div className="subscription-package-information">
                                        <div className="d-flex">
                                        <div className="subscription-credit-card-container">
                                            <svg className="mr-2" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fillOpacity="evenodd"> <circle cx="7" cy="12" r="7" fill="#EA001B"></circle> <circle cx="17" cy="12" r="7" fill="#FFA200" fillOpacity=".8"></circle> </g> </g></svg>
                                            <div>
                                                <div className="mr-2 subscription-card-title subtitle">•••• •••• •••• {subscriptionResponse?.cardInformation?.lastFourDigits}</div>
                                                <div className="mr-2 subscription-card-title sm-soft">{subscriptionResponse?.cardInformation?.cardAlias}</div>
                                                <div className="mr-2 subscription-card-title sm-soft"></div>
                                            </div>
                                        </div>

                                        {isPaymentFailed && 
                                            <Alert className="subscription-card-error-alert" message={t('PAYMENT_DECLINED')} type="error" showIcon />
                                        }
                                        
                                        </div>
                                      </div>
                                    
                                    <div className="d-flex">
                                        
                                        {isPaymentFailed && 
                                            <Button onClick={() => openTryPaymentAgainModal()} 
                                            className="white-btn subscription-button mr-2" icon={<UilRepeat />}>
                                                {t("TRY_PAYMENT_AGAIN")}
                                            </Button>
                                        }
                                        <Button onClick={() => openCreditCardModal()} 
                                        className="white-btn subscription-button" icon={<UilCreditCard />}>
                                            {t("UPDATE_CARD")}
                                        </Button>
                                    </div>
                                    
                                </div>
                                : 
                                <div className="subscription-container">
                                <h6 className="subscription-card-title">{t("CARD_INFO")} </h6>
                                <div className="subscription-package-information">
                                    <div className="secondary-dark-text">
                                        {t("NO_CREDIT_CARD_FOUND")}
                                    </div>
                                </div>
                                
                                <div className="d-flex">
                                    <Button onClick={() => openCreditCardModal()} 
                                    className="white-btn subscription-button" icon={<UilCreditCard />}>
                                        {t("ADD_CREDIT_CARD")}
                                    </Button>
                                </div>
                                
                            </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={24} lg={12}>
                    <Card>
                        <div className="subscription-container">
                            <h6 className="subscription-card-title">{t("MY_PAYMENT_INFORMATION")}</h6>
                            <div className="subscription-payment-information-container">
                                <div className="d-flex space-between">
                                    <div className="d-flex flex-column subscription-payment-gap">
                                        <div className="subscription-card-title md">{t("NEXT_PAYMENT_DATE")}</div>
                                        <div className="subscription-card-title sm">
                                            {moment(subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 1)[0]?.paymentDate).format(dateFormatConvert)}
                                        </div>
                                    </div>
                                    { !permissions.includes("default_subscriber") && (
                                    <div className="subscription-package-name grey">
                                        {subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 1)[0]?.currencyId == 1
                                            ? subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 1)[0]?.amount?.toLocaleString('tr-TR') + ' ₺'
                                            : '$'+subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 1)[0]?.amount?.toLocaleString('en-US').replace(',' , '')
                                        }
                                        </div>
                                    )}
                                </div>
                                { !permissions.includes("default_subscriber") && (
                                    <>
                                    <div className="subscription-hr"></div>
                                    <div className="d-flex space-between">
                                    <div className="d-flex flex-column subscription-payment-gap">
                                        <div className="subscription-card-title md">{t("PAYMENT_DUE_DATE")}</div>
                                        <div className="subscription-card-title sm">
                                            {moment(subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 2)[0]?.paymentDate).format(dateFormatConvert)}
                                        </div>
                                    </div>
                                    <div className="subscription-package-name grey">
                                        {subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 2)[0]?.currencyId == 1
                                            ? subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 2)[0]?.amount?.toLocaleString('tr-TR') + ' ₺'
                                            : '$'+subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 2)[0]?.amount?.toLocaleString('en-US').replace(',' , '')
                                        }
                                        </div>
                                </div>
                                    <div className="subscription-hr"></div>
                                    <div className="d-flex space-between">
                                    <div className="d-flex flex-column subscription-payment-gap">
                                        <div className="subscription-card-title md">{t("FIRST_SUBSCRIPTION_DATE")}</div>
                                        <div className="subscription-card-title sm">
                                            {moment(subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 3)[0]?.paymentDate).format(dateFormatConvert)}
                                        </div>
                                    </div>
                                    <div className="subscription-package-name grey">
                                        {subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 3)[0]?.currencyId == 1
                                            ? subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 3)[0]?.amount?.toLocaleString('tr-TR') + ' ₺'
                                            : '$'+subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 3)[0]?.amount?.toLocaleString('en-US').replace(',' , '')
                                        }
                                        </div>
                                        

                                </div>
                                    <div className="subscription-hr"></div>
                                    </>)}
                            </div>

                            { !permissions.includes("default_subscriber") && (
                                <Button onClick={() => openPaymentHistroyModal()} 
                                className="white-btn subscription-button mr-2" icon={<UilFileAlt />}>
                                    {t("SHOW_PAYMENT_HISTORY")}
                                </Button>
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
    )

}

const mapStateToProps = (state) => {
    return {
        user: state.auth?.user?.profile,
        permissionList: state.profile.permissions
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPayment);