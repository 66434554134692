import { Layout, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import '../../styles/securty.scss';
import BreadCrump from '../../features/breadcrump/breadcrump';
import SafeBtn from '../../features/helper/safe-btn';
import CallBtn from '../../features/helper/call-btn';
const { Content } = Layout;


function Privacy() {


    return (


        <Col span={24}>
            <Content className="page-container">
                <Row>
                    <Col span={6}>
                        <BreadCrump />
                    </Col>
                    <Col span={18}>
                        <SafeBtn />
                    </Col>
                </Row>
                <h1 className="page-title-1">Gizlilik</h1>
                <Row>
                    <Col span={21}>
                        <div className="helper-text">
                            <h1>Ofix.com kredi kartınızla yaptığınız alışverişlerinizi en güvenli şekilde tamamlamanız için en son teknolojileri kullanmaktadır.
                                Sitemizden yapacağınız alışverişlerde güvenlik kaygılarınızı tümüyle unutun! Üyelik veya sipariş aşamasında paylaştığınız kişisel bilgiler sitemizde
                                tümüyle koruma altındadır. Ofix.com ile paylaşacağınız hiçbir özel bilginin üçüncü şahıs veya kurumlarla paylaşılmayacağını garanti ediyoruz!
                                Sitemizde kredi kartı bilgileriniz yalnızca siparişlerinizin ödemesi sırasında kullanılır ve veri tabanında kesinlikle kayıt altına alınmaz. </h1>
                        </div>

                    </Col>
                    <Col span={3}>
                        {/* <CallBtn /> */}
                    </Col>
                </Row>

            </Content >
        </Col>




    )
}
export default Privacy;