import Oidc, { UserManager } from "oidc-client";
import { oidcConfig } from "../utils/config";


const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const langFromParam = params.get('lang');


if(langFromParam == "en" && localStorage.getItem("i18nLang") === null){
    localStorage.setItem("i18nLang", langFromParam)
}
if(langFromParam == "tr-TR" && localStorage.getItem("i18nLang") === null){
    localStorage.setItem("i18nLang", langFromParam)
}

const config = {
    userStore: new Oidc.WebStorageStateStore({ store: localStorage }),
    authority: oidcConfig.authority,
    client_id: oidcConfig.client_id,
    redirect_uri: oidcConfig.redirect_uri,
    silent_redirect_uri : oidcConfig.silent_redirect_uri,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    loadUserInfo : false,
    response_type: "code",
    scope: "openid profile nethr.api IdentityServerApi offline_access",
    post_logout_redirect_uri: oidcConfig.post_logout_redirect_uri,
    ui_locales: langFromParam ? langFromParam : localStorage.getItem("i18nLang") === "en" ? "en" : "tr-TR",
    extraQueryParams: {isMobil:false, ui_locales: langFromParam ? langFromParam : localStorage.getItem("i18nLang") === "en" ? "en" : "tr-TR"}
};
  
const userManager = new UserManager(config);

export default userManager;