import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';


const TagActionType = {
  TAGS_LIST_REQUEST: "TAGS_LIST_REQUEST",
  TAGS_LIST_SUCCESS: "TAGS_LIST_SUCCESS",
  TAGS_LIST_FAIL: "TAGS_LIST_FAIL",
  CREATE_TAG_NAME_SUCCESS: "CREATE_TAG_NAME_SUCCESS",
  CREATE_TAG_NAME_FAIL: "CREATE_TAG_NAME_FAIL",
  CREATE_TAG_NAME_INPUT_ERROR: "CREATE_TAG_NAME_INPUT_ERROR",
  CREATE_TAG_NAME_RESET: "CREATE_TAG_NAME_RESET",
  UPDATE_TAG_NAME_REQUEST: "UPDATE_TAG_NAME_REQUEST",
  UPDATE_TAG_NAME_SUCCESS: "UPDATE_TAG_NAME_SUCCESS",
  UPDATE_TAG_NAME_FAIL: "UPDATE_TAG_NAME_FAIL",
  UPDATE_TAG_NAME_INPUT_ERROR: "UPDATE_TAG_NAME_INPUT_ERROR",
  UPDATE_TAG_NAME_RESET: "UPDATE_TAG_NAME_RESET",
  DELETE_TAG_NAME_SUCCESS: "DELETE_TAG_NAME_SUCCESS",
  DELETE_TAG_NAME_FAIL: "DELETE_TAG_NAME_FAIL",
  DELETE_TAG_NAME_RESET: "DELETE_TAG_NAME_RESET",
  GET_CONNECTED_TAG_REQUEST:"GET_CONNECTED_TAG_REQUEST",
  GET_CONNECTED_TAG_SUCCESS:"GET_CONNECTED_TAG_SUCCESS",
  GET_CONNECTED_TAG_FAIL:"GET_CONNECTED_TAG_FAIL"
};

const getTagList = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: TagActionType.TAGS_LIST_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: 'post',
        url: apiUrl + '/tags/search',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TagActionType.TAGS_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: TagActionType.TAGS_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404 || error.response?.status === 500) {
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
              "results": []
            }
            dispatch({ type: TagActionType.TAGS_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TagActionType.TAGS_LIST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TagActionType.TAGS_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const createTagName = (createData) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createData);
      var config = {
        method: 'post',
        url: apiUrl + '/tags',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: TagActionType.CREATE_TAG_NAME_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TagActionType.CREATE_TAG_NAME_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TagActionType.CREATE_TAG_NAME_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TagActionType.CREATE_TAG_NAME_FAIL, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TagActionType.CREATE_TAG_NAME_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateTagName = () => {
  return async (dispatch) => {
    dispatch({ type: TagActionType.CREATE_TAG_NAME_RESET, payload: {} });
  };
}
const updateTagName = (updateData, id) => {
  return async (dispatch) => {
    dispatch({ type: TagActionType.UPDATE_TAG_NAME_REQUEST });
    try {
      const resourceId = id;
      const data = JSON.stringify(updateData);
      var config = {
        method: 'put',
        url: apiUrl + '/tags/' + resourceId,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TagActionType.UPDATE_TAG_NAME_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TagActionType.UPDATE_TAG_NAME_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TagActionType.UPDATE_TAG_NAME_INPUT_ERROR, payload: error.response.data.message });
          }else if(error.response?.status === 409) {
            dispatch({ type: TagActionType.UPDATE_TAG_NAME_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TagActionType.UPDATE_TAG_NAME_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TagActionType.UPDATE_TAG_NAME_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateTagName = () => {
  return async (dispatch) => {
    dispatch({ type: TagActionType.UPDATE_TAG_NAME_RESET, payload: {} });
  };
}
const deleteTagName = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'delete',
        url: apiUrl + '/tags/' + resourceId,
        headers: {
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: TagActionType.DELETE_TAG_NAME_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: TagActionType.DELETE_TAG_NAME_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: TagActionType.DELETE_TAG_NAME_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: TagActionType.DELETE_TAG_NAME_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: TagActionType.DELETE_TAG_NAME_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteTagName = () => {
  return async (dispatch) => {
    dispatch({ type: TagActionType.DELETE_TAG_NAME_RESET, payload: {} });
  };
}

const getPersonsTagConnect = (searchData,resourceId) => {
  return async (dispatch) => {
    dispatch({ type: TagActionType.GET_CONNECTED_TAG_REQUEST });

    try {
      const data = JSON.stringify(searchData);

        var config = {
          method: 'post',
          url: apiUrl+'/tags/'+resourceId+'/getPersons',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data

        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: TagActionType.GET_CONNECTED_TAG_SUCCESS, payload: response.data.data });
          }else{
  
              dispatch({ type: TagActionType.GET_CONNECTED_TAG_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 500){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
              "results": []
          }
            dispatch({ type: TagActionType.GET_CONNECTED_TAG_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TagActionType.GET_CONNECTED_TAG_FAIL, payload: error.message });
        });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TagActionType.GET_CONNECTED_TAG_FAIL, payload: error.message });
        }
      }
    };
  };
  const setTagConnectDraw = (connectTagDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_TAG_CONNECT_DRAWER',
            payload: connectTagDrawer,
        });
    } catch (e) {
        console.log(e);
    }
};
export {
  TagActionType,
  getTagList,
  createTagName,
  resetCreateTagName,
  updateTagName,
  resetUpdateTagName,
  deleteTagName,
  resetDeleteTagName,
  getPersonsTagConnect,
  setTagConnectDraw
}