import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import 'moment/locale/tr';
import { Spin} from 'antd';
import { connect } from 'react-redux';
import { tryPaymentAgain } from '../../redux/actions/subscription.action';

const TryPaymentAgain = ({subscriptionResponse,tryPaymentAgain, isTryPaymentAgainLoading, handleRejectTryPaymentAgainModal,closeTryPaymentOpenCardUpdateModal}) => {
        
    const { t } = useTranslation();

        if (isTryPaymentAgainLoading == null) {
            return (
                <>
                    <div className="subscription-modal-description mt-2">
                        {t('SUBSCRIPTION_PRICE_INFORMATION_TEXT',{'price': subscriptionResponse?.paymentInformationList?.filter(x => x.orderType == 1)[0]?.amount?.toLocaleString('tr-TR')})}
                    </div>
                    <Button onClick={() => tryPaymentAgain()} key="submit-1" className="ant-btn primary-btn w-100 h-40 mt-3">
                        {t('PAYMENT_CONFIRM_MODAL')}
                    </Button>
                </>
            );
        }
        if (isTryPaymentAgainLoading == 'success') {
            return (
                <>
                    <div className="subscription-modal-description mt-2">
                        {t('PAYMENT_CONFIRM_MODAL_SUCCESS')}
                    </div>
                    <Button onClick={() => handleRejectTryPaymentAgainModal()} key="submit-1" className="ant-btn primary-btn w-100 h-40 mt-3">
                        {t('PARTNER_CLOSE')}
                    </Button>
                </>
            );
        }
        if (isTryPaymentAgainLoading == 'failed') {
            return (
                <>
                    <div className="subscription-modal-description mt-2">
                        {t('PAYMENT_CONFIRM_MODAL_ERROR')}
                    </div>
                    <Button onClick={() => closeTryPaymentOpenCardUpdateModal()} key="submit-1" className="ant-btn primary-btn w-100 h-40 mt-3">
                        {t('UPDATE_CARD')}
                    </Button>
                </>
            );
        }
        if (isTryPaymentAgainLoading == 'loading') {
            return (
                <Spin className="my-15" size="medium" tip={`${t('LOADING')}`}></Spin>
            );
        }
}

const mapStateToProps = (state) => {
    return {
        isTryPaymentAgainLoading: state.subscription.isTryPaymentAgainLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        tryPaymentAgain: () => { dispatch(tryPaymentAgain()) },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(TryPaymentAgain);