import { SettingsAction } from "../actions/settings-page.action";
const initialState = {

holidays:[],
holidaysLoading:true,
employeesRoleLoading:true,
employeesRoleList:{
    totalNumberOfRecords:0,
    results:[]
}
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
        case SettingsAction.GET_HOLIDAY_REQUEST:
            return {
                ...state,
                holidaysLoading: true
            }
        case SettingsAction.GET_HOLIDAY_SUCCESS:
            return {
                ...state,
                holidays: action.payload,
                holidaysLoading: false
            }
        case SettingsAction.GET_HOLIDAY_FAIL:
            return {
                ...state,
                holidaysLoading: false
            }
        case SettingsAction.EMPLOYEE_ROLE_LIST_REQUESTS:
            return {
                ...state,
                employeesRoleLoading: true
            };
        case SettingsAction.EMPLOYEE_ROLE_LIST_SUCCESS:
            return {
                ...state,
                employeesRoleList: action.payload,
                employeesRoleLoading: false
            }
        case SettingsAction.EMPLOYEE_ROLE_LIST_FAIL:
            return {
                ...state,
                employeesRoleLoading: false,
            }
        default: return state
    }

    }