import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Dropdown, Button, Spin, List, Upload, Popover } from 'antd';
import { UilEllipsisV, UilExport, UilImport, UilTrashAlt, UilEye, UilTrash } from '@iconscout/react-unicons';
import { FileAltIcon } from '../../features/Icon/Icons';
import moment from 'moment';

import { setCreateOwnerShipDrawer, setOwnerShipDetailsDrawer } from '../../redux/actions/person-detail-tab-action';
import { ownerShipSearch, getPerson, deleteAssignmentFile, deleteAssignmentFileReset } from '../../redux/actions/person.actions';
import { getOwnerShipDetails, deleteOwnerShip, deleteOwnerShipResponseReset, exportPersonOwnerShip, exportPersonOwnerShipReset } from '../../redux/actions/ownership.actions';
import { setOwnerShipReturnDrawer } from '../../redux/actions/ownership-drawer.action';
import { dateFormatConvert, apiUrl } from '../../utils/config';
import { ownerShipListDropdown } from '../../features/global-dropdown-menu/index';
import { noData } from '../../utils/commonFormFunctions';

import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';
import Can from '../../authorization/index';
import Forbidden from '../forbidden/index';
import 'moment/locale/tr';

import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';

import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";

function OwnerShipList(props) {
    const { ownerShip, ownershipCreateResponse, ownerShipLoading, deleteOwnerShipResponse, page = "",
        totalNumberOfRecords, ownerShipInitialData, selectedPersonId, ownerShipSearch, pageNumber, setSelectedOwnerShipAssigmentDate,
        addOwnerShipDrawer, getOwnerShipDetails, setOwnerShipDetailsDrawer, openOwnerShipDetails, deleteOwnerShip,
        deleteOwnerShipResponseReset, permissions, disableStatus, setSelectedOwnerShipResourceId, selectedOwnerShipResourceId, returnOwnerShipResponse,
        personOwnerShipExportResponse, exportPersonOwnerShip, exportPersonOwnerShipReset, auth, deleteAssignmentFile, deleteAssignmentFileReset,
        deleteAssignmentFileResponse, person, getPerson, setOwnerShipReturnDrawer, setReturnComponentKey } = props;
    const { t } = useTranslation();
    const [searchData, setSearchData] = useState(ownerShipInitialData);
    const [DeleteConfirmModalData, setDeleteConfirmModalData] = useState({ "isVisible": false });
    const [DeleteInformationModalData, setDeleteInformationModalData] = useState({ "isVisible": false });
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })
    const access_token = auth.user?.access_token;
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const detailOwnerShipDrawer = (resourceId, assigmentDate) => {
        setOwnerShipDetailsDrawer(true)
        openOwnerShipDetails()
        getOwnerShipDetails(resourceId)
        setSelectedOwnerShipResourceId(resourceId);
        setSelectedOwnerShipAssigmentDate(assigmentDate);
    }
    const openReturnDrawer = (resourceId, assigmentDate) => {
        setOwnerShipReturnDrawer(true);
        setReturnComponentKey((i) => i + 1);
        setSelectedOwnerShipResourceId(resourceId);
        setSelectedOwnerShipAssigmentDate(assigmentDate);
    }
    useEffect(() => {
        if (returnOwnerShipResponse.status === 'SUCCESS') {
            setOwnerShipDetailsDrawer(false)
            getOwnerShipDetails(selectedOwnerShipResourceId)
            ownerShipSearch(selectedPersonId, searchData, page);
        }
    }, [returnOwnerShipResponse])

    useEffect(() => {
        if (searchData !== null) {
            ownerShipSearch(selectedPersonId, searchData, page);
        }
    }, [searchData])

    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.page, 'pageSize': data.pageSize }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const customTableChange = (page, pageSize) => {
        handleTableChange({ "page": page, "pageSize": pageSize })
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        current: pageNumber,
        pageSize: searchData.pagination.pageSize,
        locale: { items_per_page: "/ " + t('PAGE') },
        onChange: (a, b) => customTableChange(a, b),
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ''
    })
    useEffect(() => {
        if (ownershipCreateResponse.status === 'SUCCESS') {
            ownerShipSearch(selectedPersonId, searchData, page);
        }
    })
    useEffect(() => {
        if (deleteOwnerShipResponse.status === 'SUCCESS') {
            setDeleteConfirmModalData({ isVisible: false });
            deleteOwnerShipResponseReset();
            ownerShipSearch(selectedPersonId, searchData, page);
            DynamicToastMessage({
                key: 'ownership_Success',
                title: t('SUCCESS'),
                description: deleteOwnerShipResponse.message,
                statusType: 'success',
                position: 'bottom-right',
            });
        } else if (deleteOwnerShipResponse.status === 'FAIL') {
            setDeleteConfirmModalData({ isVisible: false });
            setDeleteInformationModalData({ isVisible: true });
            deleteOwnerShipResponseReset();
        }
    });
    useEffect(() => {
        if (personOwnerShipExportResponse.status === 'FAIL') {
            DynamicToastMessage({
                key: 'person_ownership_fail',
                title: t('ERROR'),
                description: personOwnerShipExportResponse.message,
                statusType: 'error',
                position: 'bottom-right',
            });
            exportPersonOwnerShipReset();
        }
    });
    useEffect(() => {
        if (deleteAssignmentFileResponse.status === 'SUCCESS') {
            getPerson(selectedPersonId);
            setDeleteModalData({ isVisible: false });
            deleteAssignmentFileReset();
            DynamicToastMessage({
                key: 'ownership_delete_file_success',
                title: t('SUCCESS'),
                description: deleteAssignmentFileResponse.message,
                statusType: 'success',
                position: 'bottom-right',
            });
        } else if (deleteAssignmentFileResponse.status === 'FAIL') {
            setDeleteConfirmModalData({ isVisible: false });
            DynamicToastMessage({
                key: 'ownership_delete_file_fail',
                title: t('ERROR'),
                description: deleteAssignmentFileResponse.message,
                statusType: 'error',
                position: 'bottom-right',
            });
            deleteAssignmentFileReset();
        }
    }, [deleteAssignmentFileResponse])


    const uploadProgress = {
        name: 'file',
        action: apiUrl + '/Person/UploadAssignmentFile',
        headers: {
            "Authorization": `Bearer ${access_token}`,
            "Accept-Language": localStorage.getItem('i18nLang')
        },
        data: {
            personResourceId: selectedPersonId
        },
        listType: 'picture',
        accept: ".xlsx,.pdf,.docx,.png,.jpg,.jpeg",
        maxCount: 1,
        showUploadList: { showRemoveIcon: true, removeIcon: <div className='error-message'><span>{t('ERROR')}</span><UilTrashAlt /></div> },
        iconRender: () => {
            return <FileAltIcon />
        },
        onChange(info) {
            if (info.file.status === 'done') {
                DynamicToastMessage({ 'key': 'detail_upload_document_success', 'title': t('SUCCESS'), 'description': info.file.response.message, 'statusType': 'success', 'position': 'bottom-right' })
                getPerson(selectedPersonId);
            } else if (info.file.status === 'error') {
                DynamicToastMessage({ 'key': 'detail_upload_document_fail', 'title': t('ERROR'), 'description': info.file.response.message, 'statusType': 'error', 'position': 'bottom-right' })
            }
        },
        progress: {
            strokeColor: {
                '0%': '#7CC8C8',
                '100%': '#7CC8C8',
            },
            strokeWidth: 8,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    const uploadFile = () => {
        setTimeout(() => {
            document.getElementById("upload-ownership-btn").click()
        }, 500);
    }
    const deleteDocumentRequestModal = (name, resourceId) => {
        return setDeleteModalData({ 'isVisible': true, 'name': name, 'id': resourceId });
    }
    const viewDocument = (link) => {
        return window.open(link, '_blank', 'noopener,noreferrer')
    }

    const handleExportPersonOwnerShip = (selectedPersonId) => { 
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        exportPersonOwnerShip(selectedPersonId);
     }


    // #region OnBoarding
    const [popoverVisible, setPopoverVisible] = useState(false);
    const hide = () => { setPopoverVisible(false); setCurrentTaskState(83);  };
    const handlePopoverVisibleChange = (visible) => {
        if(visible == false){ props.setCurrentTaskState(83); }
        if(props.currentTaskState !== null){
            setPopoverVisible(visible);
        } 
    };
    
    OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'owner-ship-list' );

    useEffect(() => { 
        if(props.currentTaskState == 82){ setPopoverVisible(true) }
    }, [props.currentTaskState]); 
   
    // #endregion

        
     
    return (
        ownerShipLoading ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <div className="debit-body">
                <DynamicRequestModal
                    modalVisible={DeleteConfirmModalData.isVisible}
                    setStateData={setDeleteConfirmModalData}
                    postAction={deleteOwnerShip}
                    confirmData={DeleteConfirmModalData.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('OWNERSHIP_DELETE_MODAL', { "inventoryName": DeleteConfirmModalData.inventoryName, "serialNumber": DeleteConfirmModalData.serialNumber })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <DynamicRequestModal
                    modalVisible={DeleteInformationModalData.isVisible}
                    setStateData={setDeleteInformationModalData}
                    postAction={''}
                    confirmData={''}
                    iconName='UilInfoCircle'
                    modalTitle={''}
                    description={t('OWNERSHIP_INFO_MODAL')}
                    singleButton={true}
                />
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteAssignmentFile}
                    confirmData={deleteModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_TIME_OFF_DOCUMENT_MODAL', { "name": deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <div className="w-100 ownership-document-container">
                    <div className="d-block w-100">
                        <Button disabled={disableStatus} className='flex-class svg-class white-btn m-w-180 h-32 w-auto' onClick={() => uploadFile()} icon={<UilImport />}>{person?.assignmentFile?.documentName ? t('UPDATE_DOCUMENT') : t('UPLOAD_DOCUMENT')}</Button>
                        <div className="document-upload-container py-3">
                            <div className="w-100">
                                <Upload {...uploadProgress} disabled={disableStatus}>
                                    <span id="upload-ownership-btn"></span>
                                </Upload >
                                {person?.assignmentFile?.documentName &&
                                    <div className='document-upload-card-container'>
                                        <Card className='document-upload-card'>
                                            <Row>
                                                <Col span={1}>
                                                    <FileAltIcon />
                                                </Col>
                                                <Col span={19}>
                                                    <div className="w-100 h-100 d-flex justify-center flex-wrap">
                                                        <div className="w-100 d-flex">
                                                            <span className='document-text'>{person?.assignmentFile.documentName}</span>
                                                        </div>
                                                        <div className="w-100 d-flex">
                                                            <span className='document-text'>{person?.assignmentFile.uploadDate && t('UPLOAD_DATE') + ' : ' + moment(person?.assignmentFile?.uploadDate).format(dateFormatConvert)}</span>
                                                        </div>
                                                    </div>

                                                </Col>
                                                <Col span={4}>
                                                    <Col span={24}>
                                                        <div className='document-action-buttons'>
                                                            <Button className="w-180-h-40 transparent-btn"><UilEye onClick={() => viewDocument(person?.assignmentFile?.documentUrl)} /></Button>
                                                            <Button className="w-180-h-40 transparent-btn"><UilTrash disabled={disableStatus} onClick={() => deleteDocumentRequestModal(person?.assignmentFile.documentName, selectedPersonId)} /></Button>
                                                        </div>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 space-between">
                    <Can I="person.asset.add">
                        <Popover
                            key={'ownership.popover'}
                            overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                            content={<PopoverContent key={'ownership.popover.content'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                            title={false}
                            placement="right"
                            trigger="click"
                            visible={popoverVisible && props.currentTaskState == 82 && props.currentTaskState !== null}
                            onVisibleChange={handlePopoverVisibleChange}
                        >
                            <Button disabled={disableStatus} onClick={() => addOwnerShipDrawer()} 
                                className={`primary-btn m-w-180 h-32 f-14 w-auto ${(popoverVisible && props.currentTaskState == 82 && props.currentTaskState !== null) ? 'onboarding-button-border' : ''}`}
                            >+ {t('PARTNER_NEW_OWNERSHIP')}</Button>
                        </Popover>        
                    </Can>
                    <Can I="person.asset.list">
                        <Button onClick={() => handleExportPersonOwnerShip(selectedPersonId)} icon={<UilExport />} className="flex-class svg-class white-btn m-w-180 h-32 w-auto">{t('SETTINGS_EXPORT')}</Button>
                    </Can>
                </div>
                {(ownerShip.length > 0 && ((permissions.includes("person.asset.list") && page !== 'profile') || page === 'profile')) &&
                    <Col span={24}>
                        <div className="debit-status pad-left-28">
                            <Row>
                                <Col span={8}>
                                    <h1 className="title">{t('PARTNER_DEVICE_NAME')}</h1>
                                </Col>
                                <Col span={8}>
                                    <h1 className="title">{t('PARTNER_SERIAL_NUMBER')}</h1>
                                </Col>
                                <Col span={8}>
                                    <h1 className="title">{t('ASSIGNMENT_AND_RETURN_DATE')}</h1>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                }
                {
                    ((permissions.includes("person.asset.list") && page !== 'profile') || page === 'profile') ? (
                        <Row>
                            <Col span={24}>
                                <div className="debit-info">
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        pagination={totalNumberOfRecords > 10 ? paginationConfig() : null}
                                        dataSource={ownerShip}
                                        className={ownerShip.length === 0 ? 'custom-table-list table-border-bottom-0' : 'custom-table-list'} locale={{ emptyText: (noData(t)) }}
                                        renderItem={data => (
                                            <List.Item key={data.resourceId + 'fs'}  >
                                                <Card className={permissions.includes('person.asset.read') ? "debit-info-card clickable" : "debit-info-card"} key={data.resourceId + 'sd'}>
                                                    <div className="debit-info-card-desc" >
                                                        <Row>
                                                            <Col onClick={() => permissions.includes('person.asset.read') ? detailOwnerShipDrawer(data.resourceId, data.assigmentDate) : null} span={8}>
                                                                <h1>{data.name}</h1>
                                                                {data.isActive === true ? <h1 className="green">{t('SETTINGS_ACTIVE')}</h1> : <h1 className="red">{t('SETTINGS_PASSIVE')}</h1>}
                                                            </Col>
                                                            <Col onClick={() => permissions.includes('person.asset.read') ? detailOwnerShipDrawer(data.resourceId, data.assigmentDate) : null} span={8}>
                                                                <h1 className="center">{data.serialNumber}</h1>
                                                            </Col>
                                                            <Col onClick={() => permissions.includes('person.asset.read') ? detailOwnerShipDrawer(data.resourceId, data.assigmentDate) : null} span={6}>
                                                                {data.returnDate === undefined || data.returnDate === null || data.returnDate === "" ?
                                                                    <h1 className="center">{data.assigmentDate ? moment(data.assigmentDate).format(dateFormatConvert) : ''}</h1>
                                                                    :
                                                                    <div>
                                                                        <h1>{data.assigmentDate ? moment(data.assigmentDate).format(dateFormatConvert) : ''}</h1>
                                                                        <h1>{moment(data.returnDate).format(dateFormatConvert)}</h1>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            {(!disableStatus && data.isActive) && (
                                                                <Col span={2}>
                                                                    <Can I="asset.read">
                                                                        <div className="more center">
                                                                            <Dropdown overlay={ownerShipListDropdown(t, openReturnDrawer, data.resourceId, data.assigmentDate)} trigger={['click']}>

                                                                                <UilEllipsisV />
                                                                            </Dropdown>
                                                                        </div>
                                                                    </Can>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                    ) :
                        <Forbidden size="sm"></Forbidden>
                }
            </div >
        )
    )
}
const mapStateToProps = (state) => {
    return {
        tabreducer: state.tabreducer,
        personDebitDetails: state.personDetailReducer.personDebitDetails,
        totalNumberOfRecords: state.person.ownerShip.totalNumberOfRecords,
        pageNumber: state.person.ownerShip.pageNumber,
        pageSize: state.person.ownerShip.pageSize,
        ownerShip: state.person.ownerShip.results,
        ownerShipLoading: state.person.ownerShipLoading,
        ownershipCreateResponse: state.ownership.ownershipCreateResponse,
        deleteOwnerShipResponse: state.ownership.deleteOwnerShipResponse,
        permissions: state.profile.permissions,
        returnOwnerShipResponse: state.ownership.returnOwnerShipResponse,
        personOwnerShipExportResponse: state.ownership.personOwnerShipExportResponse,
        auth: state.auth,
        deleteAssignmentFileResponse: state.person.deleteAssignmentFileResponse,
        person: state.person.person,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCreateOwnerShipDrawer: (props) => { dispatch(setCreateOwnerShipDrawer(props)) },
        ownerShipSearch: (selectedPersonId, searchData, page) => { dispatch(ownerShipSearch(selectedPersonId, searchData, page)) },
        setOwnerShipDetailsDrawer: (props) => { dispatch(setOwnerShipDetailsDrawer(props)) },
        getOwnerShipDetails: (props) => { dispatch(getOwnerShipDetails(props)) },
        deleteOwnerShip: (props) => { dispatch(deleteOwnerShip(props)) },
        deleteOwnerShipResponseReset: () => { dispatch(deleteOwnerShipResponseReset()) },
        exportPersonOwnerShip: (resourceId) => { dispatch(exportPersonOwnerShip(resourceId)) },
        exportPersonOwnerShipReset: () => { dispatch(exportPersonOwnerShipReset()) },
        getPerson: (id) => { dispatch(getPerson(id)) },
        deleteAssignmentFile: (resourceId) => { dispatch(deleteAssignmentFile(resourceId)) },
        deleteAssignmentFileReset: () => { dispatch(deleteAssignmentFileReset()) },
        setOwnerShipReturnDrawer: (props) => { dispatch(setOwnerShipReturnDrawer(props)) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OwnerShipList);