import { Breadcrumb } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

function BreadCrumpData() {
    const { t } = useTranslation();
    const location = useLocation();
    const [currentBreadItems, setCurrentBreadItems] = useState({});
    var links = [""];
    links = location.pathname.split('/');
    links = links.filter(element => element !== "" && element !== null && typeof element !== "undefined");
    var navListItem = [
        {
            'baseUrl': 'homepage',
            'baseName': `${t('HOMEPAGE')}`,
            'subItems': []
        },
        {
            'baseUrl': 'profile',
            'baseName': `${t('MY_INFORMATION')}`,
            'subItems': []
        },
        {
            'baseUrl': 'person',
            'baseName': `${t('PEOPLE')}`,
            'subItems': []
        },
        {
            'baseUrl': 'time-off',
            'baseName': `${t('SETTINGS_TIME_PROCEDURES')}`,
            'subItems': [
                {
                    'subUrl': 'me',
                    'subName': `${t('MY_TIME_OFF_REQUESTS')}`
                },
                {
                    'subUrl': 'person',
                    'subName': `${t('TIME_OFF_REQUEST_PERSON')}`
                },
                {
                    'subUrl': 'people',
                    'subName': `${t('TIME_OFF_REQUEST_PEOPLE_ON_LEAVE')}`
                },
                {
                    'subUrl': 'remainder-years',
                    'subName': `${t('REMAINING_DURATION')}`
                },
                {
                    'subUrl': 'all',
                    'subName': `${t('ALL_TIME_OFF_REQUEST')}`
                }
            ]
        },
        {
            'baseUrl': 'department',
            'baseName': `${t('DEPARTMENTS')}`,
            'subItems': []
        },
        {
            'baseUrl': 'organization',
            'baseName': `${t('ORGANIZATIONS')}`,
            'subItems': []
        },
        {
            'baseUrl': 'advance',
            'baseName': `${t('ADVANCE_PROCEDURES_HEADER')}`,
            'subItems': []
        },
        {
            'baseUrl': 'expense',
            'baseName': `${t('PARTNER_REQUEST_EXPENSE')}`,
            'subItems': []
        },
        {
            'baseUrl': 'travel-requests',
            'baseName': `${t('TRAVEL_TRANSACTIONS')}`,
            'subItems': []
        },
        {
            'baseUrl': 'ownership-info',
            'baseName': `${t('EMBEZZLEMENT')}`,
            'subItems': []
        },
        {
            'baseUrl': 'privacy',
            'baseName': `${t('PRIVACY')}`,
            'subItems': []
        },
        {
            'baseUrl': 'help',
            'baseName': `${t('HEADER_HELP')}`,
            'subItems': []
        },
        {
            'baseUrl': 'contact',
            'baseName': `${t('PARTNER_CONTACT')}`,
            'subItems': []
        },
        {
            'baseUrl': 'setting',
            'baseName': `${t('SETTINGS')}`,
            'subItems': []
        },
        {
            'baseUrl': 'users',
            'baseName': `${t('USERS')}`,
            'subItems': []
        },
        {
            'baseUrl': 'subscription',
            'baseName': `${t('SUBSCRIPTION')}`,
            'subItems': []
        },
        {
            'baseUrl': 'template',
            'baseName': `${t('EMAIL_SIGNATURE')}`,
            'subItems': []
        },
        {
            'baseUrl': 'inventory',
            'baseName': `${t('DEVICES')}`,
            'subItems': []
        },
        {
            'baseUrl': 'inventory-type',
            'baseName': `${t('TYPES_OF_DEVICE')}`,
            'subItems': []
        },
        {
            'baseUrl': 'service',
            'baseName': `${t('SERVICES')}`,
            'subItems': []
        },
        {
            'baseUrl': 'invoice',
            'baseName': `${t('INVOICES')}`,
            'subItems': []
        },
        {
            'baseUrl': 'bank',
            'baseName': `${t('BANKING')}`,
            'subItems': []
        },
        {
            'baseUrl': 'employee-summary',
            'baseName': `${t('EMPLOYEE_SUMMARY')}`,
            'subItems': []
        },
        {
            'baseUrl': 'time-off-all-summary',
            'baseName': `${t('ALL_PERMISSIONS_REPORT')}`,
            'subItems': []
        },
        {
            'baseUrl': 'time-off-years-summary',
            'baseName': `${t('REMAINING_DURATION')}`,
            'subItems': []
        },
        {
            'baseUrl': 'travel-host-request',
            'baseName': `${t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST')}`,
            'subItems': []
        },
        {
            'baseUrl': 'tasks',
            'baseName': `${t('TASKS')}`,
            'subItems': [
                {
                    'subUrl': 'description',
                    'subName': `${t('DEFINED_TASKS')}`,
                    'subItems': []
                },
                {
                    'subUrl': 'assigned',
                    'subName': `${t('ASSIGNED_TASKS')}`,
                    'subItems': []
                },
                {
                    'subUrl': 'me',
                    'subName': `${t('MY_TASKS')}`,
                    'subItems': []
                },
            ]
        },
        {
            'baseUrl': 'training',
            'baseName': `${t('TRAINING_OPERATIONS')}`,
            'subItems': [
                {
                    'subUrl': 'description',
                    'subName': `${t('TRAINING_DESCRIPTION')}`,
                    'subItems': []
                },
                {
                    'subUrl': 'me',
                    'subName': `${t('MY_TRAININGS')}`,
                    'subItems': [
                        {
                            'subUrl': 'details',
                            'subName': `${t('EDUCATION_DETAIL')}`
                        },
                    ]
                },
            ]
        },
        {
            'baseUrl': 'settings',
            'baseName': `${t('SETTINGS')}`,
            'subItems': []
        },
    ];
    function getMatchingNavItem(baseUrl, subUrl, subToSubUrl) {
        var resultItem = {};
        for (let i = 0; i < navListItem.length; i++) {
            let item = navListItem[i];
            if (item.baseUrl === baseUrl) {
                resultItem = {
                    ...resultItem,
                    'baseItem': {
                        'baseUrl': item.baseUrl,
                        'baseName': item.baseName
                    },
                }
                if (item.subItems.length > 0) {
                    for (let j = 0; j < item.subItems.length; j++) {
                        let subItem = item.subItems[j];
                        if (subItem.subUrl === subUrl) {
                            if (subItem?.subItems !== undefined && subItem?.subItems.length > 0 && subToSubUrl) {
                                resultItem = {
                                    ...resultItem,
                                    'subItem': {
                                        'subUrl': subItem.subUrl,
                                        'subName': subItem.subName
                                    },
                                }
                            } else {
                                return resultItem = {
                                    ...resultItem,
                                    'subItem': {
                                        'subUrl': subItem.subUrl,
                                        'subName': subItem.subName
                                    },
                                }
                            }
                        }
                        if (subItem.subUrl === subUrl) {
                            if (subItem?.subItems) {
                                for (let index = 0; index < subItem.subItems.length; index++) {
                                    let subToSub = subItem.subItems[index];
                                    if (subToSub.subUrl === subToSubUrl) {
                                        return resultItem = {
                                            ...resultItem,
                                            'subToSubItem': {
                                                'subUrl': subToSub.subUrl,
                                                'subName': subToSub.subName
                                            },
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    return resultItem
                }
            }
        }
        return null;
    }
    const getBreadCrumb = () => {
        return setCurrentBreadItems(getMatchingNavItem(links[0], links[1], links[2]));
    }
    useEffect(() => {
        getBreadCrumb();
    }, [location]);
    return (
        <div className="breadcrump">
            {currentBreadItems?.baseItem &&
                <Breadcrumb separator="/" >
                    <Breadcrumb.Item ><Link to={'/homepage'}>{navListItem[0].baseName}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item key={currentBreadItems.baseName} >
                        {currentBreadItems?.baseItem?.baseName}
                    </Breadcrumb.Item>
                    {currentBreadItems?.subItem &&
                        (links.length > 2 && links[2] === currentBreadItems.subToSubItem.subUrl) ?
                        <Breadcrumb.Item key={currentBreadItems.subItem?.subName} ><Link to={'/' + currentBreadItems.baseItem?.baseUrl + '/' + currentBreadItems.subItem?.subUrl}>{currentBreadItems.subItem?.subName}</Link></Breadcrumb.Item>
                        :
                        <Breadcrumb.Item key={currentBreadItems.subItem?.subName} >{currentBreadItems.subItem?.subName}</Breadcrumb.Item>
                    }
                    {currentBreadItems?.subToSubItem &&
                        <Breadcrumb.Item key={currentBreadItems.subToSubItem?.subName} >{currentBreadItems.subToSubItem?.subName}</Breadcrumb.Item>
                    }
                </Breadcrumb>
            }
        </div>
    )
}
export default BreadCrumpData;