import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Col, Row, Form, Spin } from 'antd';
import {
    getCountry, getProvinces, getDistricts, getNeighborhoods
} from '../../redux/actions/address.actions.js';
import { getDataset } from "../../redux/actions/dataset-action";

import DynamicSelectbox from "../dynamicSelectbox/index";
import PhoneInput from 'react-phone-input-2';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

import { createInitialList, compareObjects } from '../../utils/commonFormFunctions'
import { phoneInputConfig } from "../../utils/config.js";


function PersonContact(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [contactState, setContactState] = useState({ "address": {} });
    const { personContactDetails, personContactIsFetching, getUpdateObj, getCountry, getProvinces, getDataset, emergencyContactDegreeList,
        getDistricts, getNeighborhoods, countries, provinces, districts, neighborhoods, disableStatus } = props;
    const [countryList, setCountryList] = useState(createInitialList(personContactDetails.address?.country || {}));
    const [provinceList, setProvinceList] = useState(createInitialList(personContactDetails.address?.province || {}));
    const [distirctList, setDistirctList] = useState(createInitialList(personContactDetails.address?.district || {}));
    const [neighborhoodList, setNeighborhoodList] = useState(createInitialList(personContactDetails.address?.neighborhood || {}));
    const [emergencyDegreeList, setEmergencyDegreeList] = useState(createInitialList(personContactDetails.emergencyContactDegreeList || {}));

    useEffect(() => {
        setContactState(personContactDetails);
        form.setFieldsValue({
            "personalEmail": personContactDetails.personalEmail,
            "cellPhone": personContactDetails.cellPhone ?? (localStorage.getItem("i18nLang") === "tr-TR" ? '90' : '44'),
            "emergencyContactPersonDegreeOfProximity": personContactDetails.emergencyContactPersonDegreeOfProximity,
            "emergencyContactPersonName": personContactDetails.emergencyContactPersonName,
            "emergencyContactPersonPhone": personContactDetails.emergencyContactPersonPhone ?? (localStorage.getItem("i18nLang") === "tr-TR" ? '90' : '44'),
            "country": personContactDetails.address?.country || null,
            "province": personContactDetails.address?.province || null,
            "district": personContactDetails.address?.district || null,
            "neighborhood": personContactDetails.address?.neighborhood || null,
            "addressLine": personContactDetails.address?.addressLine || null,
        })
    }, [form, personContactDetails])

    useEffect(() => {
        setCountryList(countries);
    }, [countries]);
    useEffect(() => {
        setProvinceList(provinces);
    }, [provinces]);
    useEffect(() => {
        setDistirctList(districts);
    }, [districts]);
    useEffect(() => {
        setNeighborhoodList(neighborhoods);
    }, [neighborhoods]);
    useEffect(() => {
        getUpdateObj(contactState)
    }, [contactState]);
    useEffect(() => {
        setEmergencyDegreeList(emergencyContactDegreeList);
    }, [emergencyContactDegreeList]);

    const handleValuesChange = (changedObj, all) => {
        const changedKey = Object.keys(changedObj)[0];
        if (changedKey === 'country') {
            if (JSON.stringify(changedObj.country) !== JSON.stringify(personContactDetails.address && personContactDetails.address.country || {})) {
                // ülke değişti
                setProvinceList([])//ili sıfırla
                setDistirctList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                form.setFieldsValue(projects)
                const addressObj = { ...contactState.address, country: changedObj.country, province: "", district: { id: "", name: null }, neighborhood: { id: "", name: null } }
                setContactState({ ...contactState, address: addressObj })
            } else {
                // orjinal dataya geri dön
                setProvinceList([])//ili sıfırla
                setDistirctList([])
                setNeighborhoodList([])
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["province"] = personContactDetails.address.province;
                projects["district"] = personContactDetails.address.district;
                projects["neighborhood"] = personContactDetails.address.neighborhood;
                form.setFieldsValue(projects)
                const addressObj = { ...contactState.address, country: changedObj.country, province: personContactDetails.address.province, district: personContactDetails.address.district, neighborhood: personContactDetails.address.neighborhood }
                setContactState({ ...contactState, address: addressObj })
            }
        } else if (changedKey === 'province') {
            if (JSON.stringify(changedObj.province) !== JSON.stringify(personContactDetails.address && personContactDetails.address.province || {})) {
                // il değişti
                setDistirctList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["district"] = { id: null, name: null };//ilçeyi sıfırla
                projects["neighborhood"] = { id: null, name: null };//mahlleyi sıfırla
                form.setFieldsValue(projects)
                const addressObj = { ...contactState.address, province: changedObj.province, district: { id: "", name: null }, neighborhood: { id: "", name: null } }
                setContactState({ ...contactState, address: addressObj })
            } else {
                // orjinal dataya geri dön
                setDistirctList([])
                setNeighborhoodList([])
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["district"] = personContactDetails.address.district;
                projects["neighborhood"] = personContactDetails.address.neighborhood;
                form.setFieldsValue(projects)
                const addressObj = { ...contactState.address, province: changedObj.province, district: personContactDetails.address.district, neighborhood: personContactDetails.address.neighborhood }
                setContactState({ ...contactState, address: addressObj })
            }
        } else if (changedKey === 'district') {
            if (JSON.stringify(changedObj.district) !== JSON.stringify(personContactDetails.address && personContactDetails.address.district || {})) {
                // ilçe değişti
                setNeighborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["neighborhood"] = { id: null, name: null };//mahlleyi sıfırla
                form.setFieldsValue(projects)
                const addressObj = { ...contactState.address, district: changedObj.district, neighborhood: { id: "", name: null } }
                setContactState({ ...contactState, address: addressObj })
            } else {
                // orjinal dataya geri dön
                setNeighborhoodList([])
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["neighborhood"] = personContactDetails.address.neighborhood;
                form.setFieldsValue(projects)
                const addressObj = { ...contactState.address, district: changedObj.district, neighborhood: personContactDetails.address.neighborhood }
                setContactState({ ...contactState, address: addressObj })
            }
        } else if ('neighborhood' === Object.keys(changedObj)[0]) {
            const addressObj = { ...contactState.address, 'neighborhood': changedObj["neighborhood"] };
            setContactState({ ...contactState, address: addressObj });
        } else if (changedKey === 'addressLine') {
            const addressObj = { ...contactState.address, addressLine: changedObj.addressLine }
            setContactState({ ...contactState, address: addressObj })
        } else if (changedKey === 'emergencyContactPersonDegreeOfProximity') {
            setContactState({ ...contactState, "emergencyContactPersonDegreeOfProximity": changedObj['emergencyContactPersonDegreeOfProximity'] });
        } else {
            setContactState({ ...contactState, ...changedObj });
        }
    }
    return (
        personContactIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
                requiredMark={false}

            >
                <Row gutter={[32, 0]}>
                    <Col span="12">
                        <div className="form-left-body">
                            <Form.Item name="personalEmail" label={t('PARTNER_PERSONEL_MAIL')} required >
                                <MaskedInput disabled={disableStatus} className={!compareObjects(personContactDetails.personalEmail, contactState.personalEmail) ? 'is-changed ant-input' : 'ant-input'} mask={emailMask} />

                            </Form.Item>
                            <Form.Item name="cellPhone" label={t('PARTNER_CELL_PHONE')}>
                                <PhoneInput
                                    disabled={disableStatus}
                                    {...phoneInputConfig}
                                    containerClass={disableStatus ? 'flag-disable' : !compareObjects(personContactDetails.cellPhone, contactState.cellPhone) ? 'is-changed' : ''}
                                />
                            </Form.Item>
                            <Form.Item name="emergencyContactPersonName" label={t('EMERGENCY_CONTACT_NAME')} required >
                                <Input disabled={disableStatus} className={!compareObjects(personContactDetails.emergencyContactPersonName, contactState.emergencyContactPersonName) ? 'is-changed ant-input' : 'ant-input'} />
                            </Form.Item>
                            <Form.Item name="emergencyContactPersonDegreeOfProximity" className="custom-select" label={t('EMERGENCY_CONTACT_DEGREE')}>
                                <DynamicSelectbox
                                    disabled={disableStatus}
                                    optionList={emergencyDegreeList}
                                    placeholder={t('PARTNER_SELECT')}
                                    onFocus={() => getDataset(34)}
                                    className={!compareObjects(personContactDetails.emergencyContactPersonDegreeOfProximity, contactState.emergencyContactPersonDegreeOfProximity) ? 'is-changed' : ''}></DynamicSelectbox>
                            </Form.Item>
                            <Form.Item name="emergencyContactPersonPhone" label={t('EMERGENCY_CONTACT_PHONE')}>
                                <PhoneInput
                                    disabled={disableStatus}
                                    {...phoneInputConfig}
                                    containerClass={disableStatus ? 'flag-disable' : !compareObjects(personContactDetails.emergencyContactPersonPhone, contactState.emergencyContactPersonPhone) ? 'is-changed' : ''}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span="12">
                        <div className="form-right-body">
                            <Form.Item name="country" className="custom-select" label={t('ORGANIZATION_COUNTRY')}>
                                <DynamicSelectbox disabled={disableStatus} optionList={countryList} placeholder={t('COUNTRY_PLACEHOLDER')} onFocus={() => getCountry()} className={personContactDetails.address && !compareObjects(personContactDetails.address?.country, contactState.address?.country) ? 'is-changed' : ''}></DynamicSelectbox>
                            </Form.Item>
                            <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                <Input disabled={disableStatus} className={personContactDetails.address?.province !== contactState.address?.province ? 'is-changed' : ''} />
                            </Form.Item>
                            {/*
                            <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                <DynamicSelectbox optionList={provinceList} placeholder={t('PROVINCE_PLACEHOLDER')} disabled={disableStatus || !contactState.address?.country?.id} onFocus={() => getProvinces(contactState.address?.country?.id)} className={personContactDetails.address && !compareObjects(personContactDetails.address?.province, contactState.address?.province) ? 'is-changed' : ''}></DynamicSelectbox>
                                <Input disabled={disableStatus} className={personContactDetails.address && personContactDetails.address.addressLine !== contactState.address.addressLine ? 'is-changed' : ''} />
                            </Form.Item>
                            <Form.Item name="district" className="custom-select" label={t('ORGANIZATION_DISTRICT')}>
                                <DynamicSelectbox optionList={distirctList} placeholder={t('DISTRICT_PLACEHOLDER')} disabled={disableStatus || !contactState.address?.province?.id} onFocus={() => getDistricts(contactState.address?.province?.id)} className={personContactDetails.address && !compareObjects(personContactDetails.address?.district, contactState.address?.district) ? 'is-changed' : ''}></DynamicSelectbox>
                            </Form.Item>
                            <Form.Item name="neighborhood" className="custom-select" label={t('ORGANIZATION_REGION')}>
                                <DynamicSelectbox optionList={neighborhoodList} disabled={disableStatus || !contactState.address?.district?.id} onFocus={() => getNeighborhoods(contactState.address?.district?.id)} className={personContactDetails.address && !compareObjects(personContactDetails.address?.neighborhood, contactState.address?.neighborhood) ? 'is-changed' : ''} placeholder={t('REGION_PLACEHOLDER')}></DynamicSelectbox>
                            </Form.Item>
                            */}
                            <Form.Item name="addressLine" label={t('ORGANIZATION_ADDRESS')}>
                                <Input.TextArea disabled={disableStatus} className={personContactDetails.address && personContactDetails.address.addressLine !== contactState.address.addressLine ? 'is-changed' : ''} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form >
        )
    )
}
const mapStateToProps = (state) => {
    return {
        countries: state.addressReducer.countries,
        provinces: state.addressReducer.provinces,
        districts: state.addressReducer.districts,
        neighborhoods: state.addressReducer.neighborhoods,
        emergencyContactDegreeList: state.dataset.emergencyContactDegreeList

    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },
        getDistricts: (provinceId) => { dispatch(getDistricts(provinceId)) },
        getNeighborhoods: (districtId) => { dispatch(getNeighborhoods(districtId)) },
        getDataset: (id) => { dispatch(getDataset(id)) },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonContact);
