import React, { useState, useEffect } from "react";
import { Layout, Col, Tabs, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { getHoliday, getEmployeesRoleList } from "../../redux/actions/settings-page.action";
import { setApprovalProcessesCreateDrawer, setApprovalProcessesDetailDrawer, getAllProcess } from "../../redux/actions/approval-processes.actions";
import {
    setOrganizationWorkHoursCreateDrawer, setOrganizationWorkHoursDetailDrawer, getWorkHourDetailsByDefinitions,
    getWorkHourDetailsByDefinitionId
} from '../../redux/actions/organization.actions';
import { getOrganizationDetailsInfo } from '../../redux/actions/organizationDetail.actions';
import { getJobList } from "../../redux/actions/job.actions";
import { getTagList } from "../../redux/actions/tag.actions";
import { getPerson } from "../../redux/actions/person.actions";
import { getDocumentTypeList } from "../../redux/actions/document-type.action";
import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";
import { setAddTimeoffTypeDrawer, setDetailTimeoffTypeDrawer } from "../../redux/actions/timeoffTypes.action";
import { UilPlane, UilClockThree, UilUserLocation, UilLabelAlt, UilUsersAlt, UilFileCheckAlt, UilFileInfoAlt, UilCog } from '@iconscout/react-unicons'
import { ToastContainer } from 'react-toastify';

import DrawerTabs from '../person/personDrawerTabs';
import ApprovalProcessesList from "../../component/settingTabs/approvalProcesses/approvalProcessesList";
import HolidayTab from "../../component/settingTabs/holidayTab";
import WorkHoursTab from "../../component/settingTabs/workHoursTab";
import JobList from "../../component/settingTabs/jobListTab";
import TicketListTab from "../../component/settingTabs/ticketListTab";
import DocumentTypeListTab from "../../component/settingTabs/documentTypes";
import TimeOffTypes from "../../component/settingTabs/timeOffTypes";

import CreateNewWorkHours from "../../component/settingTabs/workHours/createNewWorkHours";
import CreateApprovalProcessesTabList from "../../component/settingTabs/approvalProcesses/add/createApprovalProcessesTabList";
import DetailApprovalProcessesTabList from "../../component/settingTabs/approvalProcesses/detail/detailApprovalProcessesTabList";
import DetailWorkHours from "../../component/settingTabs/workHours/detailWorkHours";
import EmployeeesRoleTab from '../../component/settingTabs/employeesRoleTab';
import AddTimeOffTypesTabs from "../../component/settingTabs/timeOffTypes/add/timeoffTypesTabs";
import DetailTimeOffTypesTabs from "../../component/settingTabs/timeOffTypes/detail/timeoffTypesTabs";

import OrganizationLanguageOptions from '../../component/OrganizationCulture/OrganizationLanguageOptions';
import Forbidden from '../../component/forbidden/index';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import { drawerWidth } from "../../utils/config";
import BreadCrumb from '../../features/breadcrump/breadcrump';

function Settings(props) {

    const { Content } = Layout;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [currentTab, setCurrentTab] = useState("approvalProcesses");
    // const [currentTab, setCurrentTab] = useState("holiday");
    const [selectApprovalProcess, setSelectApprovalProcess] = useState();
    const [selectedPersonId, setSelectedPersonId] = useState();
    const [selectWorkHours, setSelectWorkHours] = useState()
    const [isPersonDetailDrawerVisible, setIsPersonDetailDrawerVisible] = useState(false);
    const [workHoursDrawerId, setWorkHoursDrawerId] = useState({ 'add': 10, 'detail': 50 });
    const [approvalProcessesKey, setApprovalProcessesKey] = useState({ 'add': 10042, 'detail': 10242 });
    const [approvalIsOpenDrawer, setApprovalIsOpenDrawer] = useState(false);
    const [timeoffTypeDrawerKey, setTimeoffTypeDrawerKey] = useState({ 'add': 0, 'detail': 10000 });
    const [timeoffTypeIsOpenDrawer, setTimeoffTypeIsOpenDrawer] = useState(false);
    const { getHoliday, getJobList, getPerson, getTagList, permissions, getWorkHourDetailsByDefinitions, getWorkHourDetailsByDefinitionId,
        updateOrganizationWorkHoursResponse, setOrganizationWorkHoursCreateDrawer, setOrganizationWorkHoursDetailDrawer,
        organizationWorkHoursCreateDrawer, organizationWorkHoursDetailDrawer, getEmployeesRoleList, setApprovalProcessesCreateDrawer,
        setApprovalProcessesDetailDrawer, approvalProcessesCreateDrawer, approvalProcessesDetailDrawer, getAllProcess, profile,
        getDocumentTypeList, getOrganizationDetailsInfo, setAddTimeoffTypeDrawer, timeoffTypeCrateDrawer, setDetailTimeoffTypeDrawer, timeoffTypeDetailDrawer } = props;
    const createWorkingHoursDraw = () => {
        setOrganizationWorkHoursCreateDrawer(true)
        setWorkHoursDrawerId({ ...workHoursDrawerId, 'add': workHoursDrawerId.add + 1 })
    }
    const createApprovalProcessesDraw = () => {
        setApprovalProcessesCreateDrawer(true);
        setApprovalProcessesKey({ ...approvalProcessesKey, 'add': approvalProcessesKey.add + 1 })
    }
    const createTimeoffTypeDraw = () => {
        setAddTimeoffTypeDrawer(true);
        setTimeoffTypeDrawerKey({ ...timeoffTypeDrawerKey, 'add': timeoffTypeDrawerKey.add + 1 })
    }
    const detailApprovalProcessesDraw = () => {
        setApprovalProcessesDetailDrawer(true);
        setApprovalProcessesKey({ ...approvalProcessesKey, 'detail': approvalProcessesKey.detail + 1 })
    }
    const detailWorkingHoursDraw = (resourceId) => {
        setOrganizationWorkHoursDetailDrawer(true)
        setWorkHoursDrawerId({ ...workHoursDrawerId, 'detail': workHoursDrawerId.detail + 1 })
        getWorkHourDetailsByDefinitionId(resourceId);
        setSelectWorkHours(resourceId)
    }
    useEffect(() => {
        if (updateOrganizationWorkHoursResponse?.status === 'SUCCESS') {
            getWorkHourDetailsByDefinitions();
        }
    }, [updateOrganizationWorkHoursResponse])
    const jobData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "Name",
            "ascending": true
        }
    }
    const jobConnectSearchData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "fullName",
            "ascending": true
        }
    }
    const roleListSearchData = {
        "SearchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "GivenName",
            "ascending": true
        }
    }
    const documentTypeSearchData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 100,
            "orderBy": "Name",
            "ascending": true
        }
    }
    function callback(key) {
        setCurrentTab(key);
        if (key === 'approvalProcesses') {
            getAllProcess();
        }
        else if (key === 'holiday') {
            getHoliday(new Date().getFullYear());
        }
        else if (key === "jobs") {
            getJobList(jobData);
        }
        else if (key === "tags") {
            getTagList(jobData)
        }
        else if (key === 'WorkHours') {
            getWorkHourDetailsByDefinitions();
        }
        else if (key === 'roles') {
            getEmployeesRoleList(roleListSearchData);
        }
        else if (key === 'documentType') {
            // getDocumentTypeList(profile.resourceId)
            getDocumentTypeList();
        }
        else if (key === 'TimeOffTypes') {
            getOrganizationDetailsInfo(profile.organization?.resourceId);
        }
    }
    const handleSetIsPersonDetailDrawerVisible = (status, id) => {
        setIsPersonDetailDrawerVisible(status)
        if (status === true) {
            setSelectedPersonId(id)
            getPerson(id)
        }
    }
    useEffect(() => {
        if (permissions.includes("workhour.list")) {
            getAllProcess();
        } else {
            getHoliday(new Date().getFullYear());
        }
    }, [])
    useEffect(() => {
        if (permissions.includes("workhour.list")) {
            setCurrentTab("approvalProcesses");

        } else {
            setCurrentTab("holiday");
        }
    }, [permissions])

    useEffect(() => {
        if(props.currentTaskState == 40){
            setCurrentTab('approvalProcesses')
        }
        if(props.currentTaskState == 41){
            const WorkHours = document.getElementById('work-hours-tab');
            if (WorkHours) { WorkHours?.click();} 
        }
        if(props.currentTaskState == 51){
            const approval = document.getElementById('approval-process-tab');
            if (approval) { approval?.click();} 
        }

    }, [props.currentTaskState])
    
    useEffect(() => {
        if(props.currentTaskState == 41 && organizationWorkHoursDetailDrawer){
            props.setCurrentTaskState(42);
        }
        if(props.currentTaskState == 42 && !organizationWorkHoursDetailDrawer){
            props.setCurrentTaskState(43);
        }
    }, [organizationWorkHoursDetailDrawer])

    useEffect(() => {
        if(props.currentTaskState == 51 && approvalProcessesDetailDrawer){
            props.setCurrentTaskState(52);
        }
        if(props.currentTaskState == 52 && !approvalProcessesDetailDrawer){
            props.setCurrentTaskState(53);
        }
    }, [approvalProcessesDetailDrawer])

    
    return (
        <Layout>
            <Col span={24}>
                <Content className="page-container">
                    <Can I="setting">
                        <div className="tab-menu">
                            <BreadCrumb />
                            <h1 className="page-title">{t('SETTINGS')}</h1>
                            <Tabs
                                onChange={callback}
                                activeKey={currentTab.toString()}
                                defaultActiveKey={currentTab.toString()}
                                className="pt-3"
                            >
                                {permissions.includes("workhour.list") && (
                                    <TabPane key="approvalProcesses" tab={<span id="approval-process-tab">{t('APPROVAL_PROCESSES')}</span>} >
                                        <ApprovalProcessesList
                                            currentTab={currentTab}
                                            createApprovalProcessesDraw={createApprovalProcessesDraw}
                                            detailApprovalProcessesDraw={detailApprovalProcessesDraw}
                                            approvalIsOpenDrawer={approvalIsOpenDrawer}
                                            setApprovalIsOpenDrawer={setApprovalIsOpenDrawer}
                                            setSelectApprovalProcess={setSelectApprovalProcess}
                                        />
                                    </TabPane>
                                )}
                                {/* <TabPane key="SettingsGeneralSettings" tab={<span><UilSetting />{t('SETTINGS_GENERAL_SETTINGS')}</span>} >
                                    <Comingsoon />
                                </TabPane> */}
                                {permissions.includes("setting") && (
                                    <TabPane key="holiday" tab={<span>{t('SETTINGS_HOLIDAYS')}</span>} >
                                        <HolidayTab />
                                    </TabPane>
                                )}
                                {permissions.includes("workhour.list") && (
                                    <TabPane key="WorkHours" tab={<span id="work-hours-tab">{t('WORK_HOURS')}</span>} >
                                        <WorkHoursTab currentTab={currentTab} createWorkingHoursDraw={createWorkingHoursDraw} detailWorkingHoursDraw={detailWorkingHoursDraw} />
                                    </TabPane>
                                )}
                                {permissions.includes("jobtitle.list") && (
                                    <TabPane key="jobs" tab={<span>{t('PARTNER_JOBS')}</span>} >
                                        <JobList jobConnectSearchData={jobConnectSearchData} jobSearchData={jobData} handleSetIsPersonDetailDrawerVisible={handleSetIsPersonDetailDrawerVisible} />
                                    </TabPane>
                                )}
                                {permissions.includes("tag.list") && (
                                    <TabPane key="tags" tab={<span>{t('PARTNER_TAGS')}</span>} >
                                        <TicketListTab tagConnectSearchData={jobConnectSearchData} tagSearchData={jobData} handleSetIsPersonDetailDrawerVisible={handleSetIsPersonDetailDrawerVisible} />
                                    </TabPane>
                                )}
                                <TabPane key="roles" tab={<span>{t('SETTINGS_ROLES')}</span>} >
                                    <EmployeeesRoleTab roleListSearchData={roleListSearchData} />
                                </TabPane>
                                {permissions.includes("tag.list") && (
                                    <TabPane key="documentType" tab={<span>{t('DOCUMENT_TYPES')}</span>} >
                                        <DocumentTypeListTab documentTypeSearchData={documentTypeSearchData} />
                                    </TabPane>
                                )}
                                {permissions.includes("workhour.list") && (
                                    <TabPane key="TimeOffTypes" tab={<span>{t('TIMEOFF_TYPES')}</span>} >
                                        <TimeOffTypes
                                        createTimeoffTypeDraw={createTimeoffTypeDraw}
                                        setTimeoffTypeIsOpenDrawer={setTimeoffTypeIsOpenDrawer}

                                        />
                                    </TabPane>
                                )}
                                
                                <TabPane key="otherSettings" tab={<span>{t('OTHER_SETTINGS')}</span>} >
                                    <OrganizationLanguageOptions />
                                </TabPane>
                                
                            </Tabs>
                        </div>
                        <DrawerTabs
                            selectedPersonId={selectedPersonId}
                            isPersonDetailDrawerVisible={isPersonDetailDrawerVisible}
                            setIsPersonDetailDrawerVisible={(status, id) => handleSetIsPersonDetailDrawerVisible(status, id)}
                            deleteModalData={null}
                            setDeleteModalData={null}
                        />
                    </Can>
                    <Drawer
                        key={workHoursDrawerId.add}
                        title={false}
                        width={1040}
                        placement="right"
                        closable={false}
                        onClose={setOrganizationWorkHoursCreateDrawer}
                        visible={organizationWorkHoursCreateDrawer}
                        zIndex={1002}
                    >
                        <CreateNewWorkHours />
                    </Drawer>
                    <Drawer
                        key={workHoursDrawerId.detail}
                        title={false}
                        width={1040}
                        placement="right"
                        closable={false}
                        onClose={setOrganizationWorkHoursDetailDrawer}
                        visible={organizationWorkHoursDetailDrawer}
                        zIndex={1002}
                    >
                        <DetailWorkHours selectWorkHours={selectWorkHours} />
                    </Drawer>
                    <Drawer key={approvalProcessesKey.add} title={false} width={drawerWidth} placement="right" closable={false} visible={approvalProcessesCreateDrawer}>
                        <CreateApprovalProcessesTabList />
                    </Drawer>
                    <Drawer key={approvalProcessesKey.detail} title={false} width={drawerWidth} placement="right" closable={false} visible={approvalProcessesDetailDrawer}>
                        <DetailApprovalProcessesTabList
                            setApprovalIsOpenDrawer={setApprovalIsOpenDrawer}
                            selectApprovalProcess={selectApprovalProcess}
                        />
                    </Drawer>
                    <Drawer
                        key={'timeofftypes-'+timeoffTypeDrawerKey.add}
                        title={false}
                        width={760}
                        placement="right"
                        closable={false}
                        onClose={setAddTimeoffTypeDrawer}
                        visible={timeoffTypeCrateDrawer}
                        zIndex={1002}
                    >
                        <AddTimeOffTypesTabs />
                    </Drawer>
                    <Drawer
                        key={'edit-timeofftypes-'+timeoffTypeDrawerKey.add}
                        title={false}
                        width={760}
                        placement="right"
                        closable={false}
                        onClose={setDetailTimeoffTypeDrawer}
                        visible={timeoffTypeDetailDrawer}
                        zIndex={1002}
                    >
                        <DetailTimeOffTypesTabs />
                    </Drawer>
                    <Cannot I="setting">
                        <Forbidden />
                    </Cannot>
                </Content>
            </Col>
            <ToastContainer />
        </Layout >
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        permissions: state.profile.permissions,
        organizationWorkHoursCreateDrawer: state.organization.organizationWorkHoursCreateDrawer,
        organizationWorkHoursDetailDrawer: state.organization.organizationWorkHoursDetailDrawer,
        updateOrganizationWorkHoursResponse: state.organization.updateOrganizationWorkHoursResponse,
        approvalProcessesCreateDrawer: state.approvalProcesses.approvalProcessesCreateDrawer,
        approvalProcessesDetailDrawer: state.approvalProcesses.approvalProcessesDetailDrawer,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
        timeoffTypeCrateDrawer: state.timeoffTypes.timeoffTypeCrateDrawer,
        timeoffTypeDetailDrawer: state.timeoffTypes.timeoffTypeDetailDrawer
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getHoliday: (props) => { dispatch(getHoliday(props)) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) },
        getPerson: (id) => { dispatch(getPerson(id)) },
        getTagList: (searchData) => { dispatch(getTagList(searchData)) },
        getWorkHourDetailsByDefinitionId: (resourceId) => { dispatch(getWorkHourDetailsByDefinitionId(resourceId)) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) },
        setOrganizationWorkHoursCreateDrawer: (status) => { dispatch(setOrganizationWorkHoursCreateDrawer(status)) },
        setOrganizationWorkHoursDetailDrawer: (status) => { dispatch(setOrganizationWorkHoursDetailDrawer(status)) },
        getEmployeesRoleList: (searchData) => { dispatch(getEmployeesRoleList(searchData)) },
        setApprovalProcessesCreateDrawer: (status) => { dispatch(setApprovalProcessesCreateDrawer(status)) },
        setApprovalProcessesDetailDrawer: (status) => { dispatch(setApprovalProcessesDetailDrawer(status)) },
        getDocumentTypeList: () => { dispatch(getDocumentTypeList()) },
        getAllProcess: () => { dispatch(getAllProcess()) },
        getOrganizationDetailsInfo: (resourceId) => { dispatch(getOrganizationDetailsInfo(resourceId)) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
        setAddTimeoffTypeDrawer : (status) =>{ dispatch(setAddTimeoffTypeDrawer(status))},
        setDetailTimeoffTypeDrawer : (status) =>{ dispatch(setDetailTimeoffTypeDrawer(status))}
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
