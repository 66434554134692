import { React, useState } from 'react';
import { Layout, Form, Input, Button, Image, Card } from 'antd';
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import '../../styles/button.scss';
import '../../styles/all-login-page.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function ResetPassword() {
// Kullanılmıyor

    const [inputControl, setInputControl] = useState(false);
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    const Switch = () => {
        setInputControl(!inputControl);
    }
    return (
        <Layout>
            <div className="netHR-container">
                <div className="logo">
                    <Image src={process.env.REACT_APP_URL + 'images/ofixpro45.png'} preview={false} />
                </div>
                <Card className="netHR-card">
                    <Form
                        name="reset-password"
                        layout="vertical"
                        onFinish={onFinish}
                        requiredMark={false}

                    >

                        <h1 className="reset-form-title">
                            Şifre Sıfırla
                        </h1>
                        <p className="reset-form-description">Şifre yenileme bağlantısı gönderebilmemiz için lütfen E-posta adresinizi giriniz.</p>
                        {inputControl === false ?
                            <div>
                                <Form.Item
                                    label="E-posta Adresiniz"
                                    name="e-posta"
                                    rules={[{ required: true, message: 'Lütfen bir eposta adresi giriniz' }]}
                                >
                                    <Input placeholder="Ör mehmetcelik@alanadi.com" />

                                </Form.Item>
                                <Button className="transparent-btn switch f-14" onClick={Switch}>
                                    Telefon ile şifre yenilemek için tıklayınız
                                </Button>
                            </div>

                            :
                            <div>
                                <Form.Item
                                    label="Cep Telefonu Numaranız"
                                    name="phone-number"
                                    rules={[{ required: true, message: 'Lütfen cep telefonu numaranızı giriniz.' }]}
                                >
                                    <PhoneInput
                                        onlyCountries={['tr', 'gr']}
                                        country={'tr'}
                                        localization={{ tr: 'Türkiye', gr: 'Yunanistan' }}
                                        masks={{ tr: '(...) ...-..-..', gr: '(....) ...-..-..' }}
                                        inputProps={{
                                            name: 'phone',
                                            required: true
                                        }}

                                    />

                                </Form.Item>
                                <Button className="transparent-btn switch f-14" onClick={Switch}>
                                    E-posta ile şifre yenilemek için tıklayınız
                                </Button>
                            </div>
                        }



                        <Form.Item>
                            <div className="submit-btn">
                                <Button htmlType="submit" className="primary-btn w-390 h-40">
                                    Şifre Sıfırla
                                </Button>
                            </div>
                        </Form.Item>
                        <div className="register-btn">
                            <a href="/kayit-ol">Üye Değil misiniz? <span> </span>Kayit Olmak için tıklayın.</a>
                        </div>

                    </Form>

                </Card>
                <div className="helper">
                    <a href="/helper">Yardım</a>
                </div>
            </div>
        </Layout>
    )


}
export default ResetPassword;