import { ProfileActionTypes } from "../actions/profile.actions";

const initialState = {
    "givenName":"",
    "familyName":"",
    "permissions":[

    ],
    changePasswordDrawer: false,
    changePasswordResponse : {"status":"loading","message":""},

}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {
       
        case ProfileActionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case ProfileActionTypes.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions:action.payload
            }
        case ProfileActionTypes.GET_PERMISSIONS_VIA_LOGIN:
            return {
                ...state,
                permissions:[...action.payload]
            }
        case "SET_CHANGE_PASSWORD_DRAWER":
            return {
                ...state,
                changePasswordDrawer: action.payload,
            }
        case ProfileActionTypes.CHANGE_PASSWORD_RESET:
            return {
                ...state,
                changePasswordResponse : {"status":"loading","message":action.payload}
                
            }    
        case ProfileActionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordResponse: {
                   "status":"SUCCESS",
                   "message":action.payload
                }
            }
        case ProfileActionTypes.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                changePasswordResponse: {
                   "status":"FAIL",
                   "message":action.payload
                }
            }
            
        default: return state
    }

}