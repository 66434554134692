import { AuthActionType } from "../actions/auth.actions";

const initialState = {
  isChangingOrganization: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AuthActionType.CHANGING_ORGANIZATION_STARTED:
      return {
        ...state,
        isChangingOrganization: true,
      };
    case AuthActionType.CHANGING_ORGANIZATION_FINISHED:
      return {
        ...state,
        isChangingOrganization: false,
      };
    
    default:
      return state;
  }
}
