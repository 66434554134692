import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Input, DatePicker, Col, Row, ConfigProvider, AutoComplete, Spin } from 'antd';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';

import { getDepartments } from '../../../redux/actions/organization.actions';
import { getOrganizationDocumentType, getWorkHourDetailsByDefinitions } from '../../../redux/actions/organization.actions';
import { getJobList } from '../../../redux/actions/job.actions';

import DynamicSelectbox from "../../dynamicSelectbox/index";
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import { dateFormat, dateFormatList, localeLang, phoneInputConfig, dynamicDateInputFormatter } from '../../../utils/config';
import { focusDateInput, blurDateInput, timeOffMask, disabledDateWithToday, disabledDynamicDate } from '../../../utils/commonFormFunctions'

import '../../../styles/person.scss';

function AddUserBasicInformation(props) {
    const { t } = useTranslation();
    const { getDepartments, departments, profile, focusOnEmail, getOrganizationDocumentType, organizationDocumentTypeList,
        jobList, getJobList, workHourDetailsByDefinitions, getWorkHourDetailsByDefinitions, personWorkHours, personWorkHoursLoading } = props;
    const [departmentList, setDepartmentList] = useState([]);
    const organizationResourceId = profile.organization?.resourceId;
    const emailInput = React.useRef(null);
    const [createPersonState, setCreatePersonState] = useState({});
    const [form] = Form.useForm()
    const { dataset, setCreateObject, createPersonObject } = props;
    const [jobListState, setJobListState] = useState([]);
    const [workHourList, setWorkHourList] = useState([]);
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "hireDate": dateFormatList,
        "trialPeriodDate": dateFormatList
    })

    useEffect(() => {
        if (focusOnEmail) {
            emailInput.current.inputElement.focus();
        }
    }, [focusOnEmail]);
    useEffect(() => {
        var tempData = workHourDetailsByDefinitions.definitions.map((data) => {
            return {
                'id': data.definitionId,
                'displayValue': data.name
            }
        })
        setWorkHourList(tempData)
    }, [workHourDetailsByDefinitions])

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'DepartmentResourceId') {
            setCreatePersonState({ ...createPersonState, 'DepartmentResourceId': changedObj["DepartmentResourceId"].id });
        } else if (key === 'OrganizationWorkDefinitionIdx') {
            setCreatePersonState({ ...createPersonState, 'OrganizationWorkDefinitionIdx': changedObj["OrganizationWorkDefinitionIdx"].id });
        } else if (key === 'jobName') {
            setCreatePersonState({ ...createPersonState, 'JobName': changedObj["jobName"] });
        } else if (key === 'hireDate') {
            // setCreatePersonState({ ...createPersonState, 'hireDate': moment(changedObj.hireDate).format(dateFormat), 'startDate': moment(changedObj['hireDate']).format(dateFormat) });
            setCreatePersonState({ ...createPersonState, 'hireDate': moment(changedObj.hireDate).format(dateFormat), 'trialPeriodDate': moment(changedObj.hireDate).add(2, 'months').format(dateFormat), 'startDate': moment(changedObj['hireDate']).format(dateFormat) });
            const fields = form.getFieldsValue()
            const newFields = { ...fields };
            newFields["trialPeriodDate"] = moment(changedObj.hireDate).add(2, 'months');
            form.setFieldsValue(newFields);
        } else if (key === 'trialPeriodDate') {
            setCreatePersonState({ ...createPersonState, 'trialPeriodDate': moment(changedObj.trialPeriodDate).format(dateFormat) });
        }
        else {
            setCreatePersonState({ ...createPersonState, ...changedObj });
        }
    }

    useEffect(() => {
        setDepartmentList(departments);
    }, [departments]);

    useEffect(() => {
        setCreateObject(createPersonState)
    }, [createPersonState]);

    useEffect(() => {
        if (personWorkHours.id) {
            setWorkHourList({
                'id': parseInt(personWorkHours?.id),
                'displayValue': personWorkHours?.displayValue
            })
            setCreatePersonState({
                ...createPersonState, 'OrganizationWorkDefinitionIdx': parseInt(personWorkHours?.id),
            })
        }
    }, [personWorkHours])
    useEffect(() => {
        const fields = form.getFieldsValue()
        const projects = { ...fields };
        projects["officePhone"] = '';
        if (personWorkHours.id) {
            projects["OrganizationWorkDefinitionIdx"] = { 'id': parseInt(personWorkHours?.id), 'displayValue': personWorkHours.displayValue };
        }
        form.setFieldsValue(projects)
    }, [form, personWorkHours])

    useEffect(() => {
        if (createPersonObject?.documentRequest !== null) {
            setCreatePersonState({
                ...createPersonState,
                'documentRequest':
                    organizationDocumentTypeList.filter(item => item.isRequired).map((data) => {
                        return { 'documentResourceId': data.resourceId }
                    })
            })
        }
    }, [organizationDocumentTypeList]);
    useEffect(() => {
        const tempArr = [];
        jobList.map((data) => {
            return tempArr.push({ 'value': data.name })
        })
        setJobListState(tempArr)
    }, [jobList])

    return (
        personWorkHoursLoading ?
            <Spin className="tab-loading" /> :
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <div className="add-user-draw-required-field-text">* {t('PARTNER_REQUIRED_FIELD')}</div>
                    <Form.Item className="add-user-draw-required-input" name="givenName" label={t('PARTNER_GIVENNAME') + " *"} required>
                        <Input />
                    </Form.Item>
                    <Form.Item className="add-user-draw-required-input" name="familyName" label={t('PARTNER_FAMILYNAME') + " *"} required>
                        <Input />
                    </Form.Item>
                    <Form.Item name="DepartmentResourceId" className="custom-select" label={t('PARTNER_DEPARTMENT') + " *"}>
                        <DynamicSelectbox optionList={departmentList} placeholder="" onFocus={() => getDepartments(organizationResourceId)} ></DynamicSelectbox>
                    </Form.Item>
                    <Form.Item name="OrganizationWorkDefinitionIdx" className="custom-select" label={t('CHOOSE_WORKING_HOURS')}>
                        <DynamicSelectbox
                            placeholder={t('PARTNER_SELECT')}
                            optionList={workHourList}
                            disallowClear={true}
                            defaultValue={(workHourDetailsByDefinitions?.definitions?.find(x => x.isDefault == true))?.name}
                            onFocus={() => getWorkHourDetailsByDefinitions()}
                        />
                    </Form.Item>
                    <Form.Item name="hireDate" label={datePickerFormatType.hireDate === dynamicDateInputFormatter ? t('PARTNER_START_DATE_OF_COMPANY') + " * " + t('PLACEHOLDER_DATE') : t('PARTNER_START_DATE_OF_COMPANY') + " *"}>
                        <DatePicker
                            format={datePickerFormatType.hireDate}
                            locale={localeLang}
                            placeholder={t('PLACEHOLDER_DATE')}
                            disabledDate={disabledDateWithToday}
                            onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'hireDate', dynamicDateInputFormatter)}
                            onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'hireDate', dateFormatList)}
                        />
                    </Form.Item>
                    <Form.Item name="trialPeriodDate" label={datePickerFormatType.trialPeriodDate === dynamicDateInputFormatter ? t('PARTNER_TRIAL_PERIOD_DATE') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_TRIAL_PERIOD_DATE')}>
                        <DatePicker
                            format={datePickerFormatType.trialPeriodDate}
                            locale={localeLang}
                            disabledDate={(current) => disabledDynamicDate(current, createPersonState.hireDate)}
                            placeholder={t('PLACEHOLDER_DATE')}
                            onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'trialPeriodDate', dynamicDateInputFormatter)}
                            onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'trialPeriodDate', dateFormatList)}
                        />
                    </Form.Item>
                    <Form.Item name="transferorBalance" label={t('TRANSFEROR_BALANCE')}>
                        <MaskedInput className='ant-input' mask={timeOffMask} />
                    </Form.Item>
                    <Form.Item name="jobName" className="auto-complete-input" label={t('PARTNER_JOB')}>
                        <AutoComplete
                            options={jobListState}
                            onFocus={() => getJobList({
                                "searchTerm": "",
                                "pagination": {
                                    "pageNumber": 1,
                                    "pageSize": 100,
                                    "orderBy": "Name",
                                    "ascending": true
                                }
                            })}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Row>
                        <Col span={11}>
                            <Form.Item className="add-user-draw-phone-input" name="officePhone" label={t('PARTNER_OFFICE_PHONE')}>
                                <PhoneInput
                                    {...phoneInputConfig}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={1}>
                            <Form.Item className="add-user-draw-phone-ext-input" name="workPhoneExt" label={t('PARTNER_WORK_PHONE_EXT')}>
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="corporateEmail" label={t('PARTNER_CORPORATE_MAIL')}>
                        <MaskedInput ref={emailInput} mask={emailMask} className="ant-input" />
                    </Form.Item>
                    <Form.Item name="identityNumber" label={t('PARTNER_IDENTITY_NUMBER')}>
                        <Input maxLength={20} />
                    </Form.Item>
                    {/* <div className="add-user-draw-add-image">
                    <Form.Item name="photo">
                        <Upload maxCount={1} {...uploadBTN} fileList={upload.fileList} ><Image src="/images/pp-undefined.png" preview={false} /><span>Fotoğraf Ekle</span></Upload>
                    </Form.Item>
                </div> */}
                </Form>
            </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
        departments: state.organization.departments,
        profile: state.profile,
        organizationDocumentTypeList: state.organization.organizationDocumentTypeList,
        jobList: state.job.jobList.results,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions,
        personWorkHours: state.organization.personWorkHours,
        personWorkHoursLoading: state.organization.personWorkHoursLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDepartments: (props) => { dispatch(getDepartments(props)) },
        getOrganizationDocumentType: () => { dispatch(getOrganizationDocumentType()) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) }

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUserBasicInformation);