
import React, { useState, useEffect } from "react";
import { Layout, Col, Tabs, Button, Dropdown, Tag, Drawer, List, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'

import { getDepartmentDetailInfo, departmentListExportWithPdf, departmentControl, departmentMoreAction, departmentMoreActionResponseReset, updateDepartmentResponseReset } from '../../redux/actions/department.action';
import { departmentSearch, personsDepartmentConnect, getDepartmentOrgChart, getPersonWorkHours } from '../../redux/actions/organization.actions';
import { getPerson } from '../../redux/actions/person.actions';
import { getGridSetting, createGridSettings, settingsReset } from '../../redux/actions/setting.actions';
import { setDepartmentAddDraw, setDepartmentDetailsDraw, setDepartmentConnectDraw, setDepartmentControlDrawer } from '../../redux/actions/person-organization-drawer-action';
import { department_table_dropdown, organization_dropdown } from '../../features/global-dropdown-menu/index';

import { UnorderedListOutlined, ApartmentOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { UilFileExport, UilFileImport } from '@iconscout/react-unicons';
import { ToastContainer } from 'react-toastify';
import Media from "react-media";

import CreateDepartmentTabs from '../../component/departmentDrawerTabs/add/department-all-tabs';
import DepartmentDetailsDraw from '../../component/departmentDrawerTabs/details/department-details-draw';
import DynamicTable from '../../component/dynamicTable/index';
import DynamicRequestModal from '../../component/dynamicModal/requestModal'
import ExportModal from '../../component/dynamicModal/exportModal';
import DepartmentActionDrawers from "../../component/departmentDrawerTabs/department-action-drawers";
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import DrawerTabs from '../person/personDrawerTabs';
import BreadCrumb from '../../features/breadcrump/breadcrump';
import InventoryImportModal from '../../component/modals/inventory/import';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../../component/forbidden/index";

import { drawerWidth } from '../../utils/config';
import { useWindowDimensions } from '../../utils/config';
import { TreeView } from '../../component/treeView/index';

import '../../styles/organization-page.scss';
import '../../styles/department.scss';

import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";
import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';

function Department(props) {
    const { Content } = Layout;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { departmentImportResponse, setDepartmentAddDraw, departmentSearch, departmentList, totalNumberOfRecords, departmentOrgChart,
        getDepartmentOrgChart, getPerson, getGridSetting, createGridSettings, createGridSettingsResponse, gridSettings,
        setDepartmentDetailsDraw, personsDepartmentConnect, setDepartmentConnectDraw, departmentListExportWithPdf,
        getDepartmentDetailInfo, updateDepartmentResponseReset, settingsReset, profile, departmentIsSearchLoading,
        updateResponse, permissions, departmentControl, departmentControlData, setDepartmentControlDrawer, departmentControlDrawer,
        departmentMoreAction, departmentMoreActionResponseReset, departmentMoreActionResponse, organizationTabreducer, getPersonWorkHours } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [isPersonDetailDrawerVisible, setIsPersonDetailDrawerVisible] = useState(false);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [componentKey, setcomponentKey] = useState(0)
    const [controlModalData, setControlModalData] = useState({ 'isVisible': false })
    const { width } = useWindowDimensions();
    const organizationResourceId = profile.organization?.resourceId;
    const initialGetGridSettingData = {
        pageId: 3, //department
        gridId: 1, //department grid
    }
    const initialSearchData = {
        "searchTerm": "",
        "departments": [],
        "organizations": [],
        "parentDepartments": [],
        "departmentManagers": [],
        "showPassiveRecords": false,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "DepartmentName",
            "ascending": true
        }
    }
    const pdfInitialData = {
        "searchTerm": "",
        "departments": [],
        "organizations": [],
        "parentDepartments": [],
        "departmentManagers": [],
        "showPassiveRecords": false,
        "exportType": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "DepartmentName",
            "ascending": true
        }
    }
    const [searchData, setSearchData] = useState(initialSearchData);
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData);
    const [componentKeyDepartmentAdd, setComponentKeyDepartmentAdd] = useState(4500)
    const [treeViewData, setTreeViewData] = useState(null);

    const [importData, setImportData] = useState({
        'key': 10000,
        'isVisible': false
    });

    let addNodeChildFunc = null;

    const handleSetIsPersonDetailDrawerVisible = (status, id) => {
        setIsPersonDetailDrawerVisible(status)
        if (status === true) {
            getPerson(id);
            setSelectedPersonId(id);
        }
    }
    const handleSaveGridSettings = (d) => {
        let createObj = { "data": d, "pageId": getGridSettingsData.pageId, "gridId": getGridSettingsData.gridId }
        createGridSettings(createObj)
    }
    function callback(key) {
        if (key === '2') {
            setcomponentKey((i) => i + 1);
            getDepartmentOrgChart(organizationResourceId);
        }
    }
    const multipleRootFilter = (data, type) => {
        const root = data.filter((item) => { return item.parentNodeId === null })
        if (root.length > 1) {
            const virtualParent = {
                "isVirtualParent": true,
                "nodeId": "virtualParent",
                "parentNodeId": null,
                "personResourceId": "",
                "organizationLegalName": profile.organization?.legalName,
                "organizationManager": profile.organization?.manager?.displayValue,
                "profileImgUrl": null
            }
            const transformednulls = data.map((item) => {
                if (item.parentNodeId === null) {
                    return { ...item, "parentNodeId": "virtualParent" }
                } else {
                    return item
                }
            })
            const modifiedList = [virtualParent, ...transformednulls]
            return modifiedList
        } else {
            return data
        }
    }
    useEffect(() => {
        setTreeViewData(multipleRootFilter(departmentOrgChart));
    }, [departmentOrgChart]);

    useEffect(() => {
        getGridSetting(getGridSettingsData)
    }, [getGridSettingsData])

    useEffect(() => {
        if (createGridSettingsResponse.status === 'SUCCESS') {
            settingsReset();
        }
    }, [createGridSettingsResponse])

    useEffect(() => {
        if (departmentControlData?.isHaveChildDepartments === false && departmentControlData?.isHavePersons === false) {
            setControlModalData({ ...controlModalData, 'isVisible': true })
        } else if (departmentControlData?.isHaveChildDepartments === true || departmentControlData?.isHavePersons === true) {
            setDepartmentControlDrawer({
                status: true, //Drawer status
                id: controlModalData.id,
                name: controlModalData.name,
                modalType: controlModalData.modalType,
                componentKey: departmentControlDrawer?.componentKey !== undefined ? departmentControlDrawer?.componentKey + 1 : 30
            });
        }
    }, [departmentControlData])

    useEffect(() => {
        if (departmentMoreActionResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'department_more_action_success',
                'title': t('SUCCESS'),
                'description': departmentMoreActionResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            if (departmentList?.length > 1) {
                departmentSearch(searchData);
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            getDepartmentOrgChart(organizationResourceId);
            setDepartmentControlDrawer({
                status: false,
                id: null,
                name: null,
                modalType: null,
                componentKey: departmentControlDrawer?.componentKey
            })
            setDepartmentDetailsDraw(false)
            departmentMoreActionResponseReset();
        } else if (departmentMoreActionResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'department_more_action_error',
                'title': t('ERROR'),
                'description': departmentMoreActionResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            departmentMoreActionResponseReset();
        }
    }, [departmentMoreActionResponse])
    useEffect(() => {
        if (updateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success',
                'title': t('SUCCESS'),
                'description': updateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            updateDepartmentResponseReset()
            departmentSearch(searchData)
            getDepartmentOrgChart(organizationResourceId)
            getDepartmentDetailInfo(selectedPersonId);
            setcomponentKey((i) => i + 1);
        } else if (updateResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'departmeent_details_fail_notification',
                'title': t('ERROR'),
                'description': updateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateDepartmentResponseReset()

        } else if (updateResponse.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'departmeent_details_validation_notification',
                'title': t('ERROR'),
                'description': <List>{updateResponse.message.validationMessages.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateDepartmentResponseReset()
        }
    }, [updateResponse]);

    const showOrganizationDetailDrawer = (id) => {
        getDepartmentDetailInfo(id);
        setDepartmentDetailsDraw(true)
        setSelectedPersonId(id)
    }
    function onNodeClick(nodeId) {
        if (permissions.includes("department.read") && nodeId !== "virtualParent") {
            const resourceId = treeViewData.find((item) => { return item.nodeId == nodeId }).departmentResourceId;
            showOrganizationDetailDrawer(resourceId);
        }
    }
    const showConnectedPersonsDrawer = (id) => {
        const searchData = {
            "searchTerm": "",
            "pagination": {
                "pageNumber": 1,
                "pageSize": 1000,
                "orderBy": "fullName",
                "ascending": true
            }
        }
        personsDepartmentConnect({ "data": searchData, "resourceId": id });
        setDepartmentConnectDraw({ "status": true, "id": id });
    }
    const canDelete = (id, name) => {

        departmentControl(id)
        setControlModalData({ ...controlModalData, 'id': id, 'name': name, 'modalType': 'delete' })
    }
    const canPassive = (id, name) => {
        departmentControl(id)
        setControlModalData({ ...controlModalData, 'id': id, 'name': name, 'modalType': 'disable' })
    }
    const canActive = (id, name) => {

        setControlModalData({ ...controlModalData, 'id': id, 'name': name, 'modalType': 'active', 'isVisible': true })
    }
    
    const data = departmentList.map((row, index) => {
        return (
            {
                "key": index,
                "departmentName": { "text": row.departmentName, "id": row.resourceId, "isHead": row.isHeadDepartment, "isActive": row.isActive, },
                "isHeadDepartment": row.isHeadDepartment,
                "parentDepartment": row.parentDepartment,
                "departmentManager": row.departmentManager,
                "personsCount": { "text": row.personsCount, "id": row.resourceId }
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['departmentName', 'parentDepartment', 'departmentManager'] : [],
        sortableColumns: ['departmentName', 'isHeadDepartment', 'parentDepartment', 'departmentManager', 'personsCount'],
        visibilityOptionExcludes: ['departmentName', 'actionMenu'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "department"
    })
    const paginationConfig = () => ({
        current: searchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const handleTableChange = (data) => {

        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }

        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 }
        setSearchData({ ...searchData, "searchTerm": e, "pagination": pagingObj })
    }
    const handleTableFilter = (data) => {
        setSearchData({
            ...searchData, "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "fullName",
                "ascending": true
            },
            "departments": data.departmentName,
            "organizations": data.organizationName,
            "parentDepartments": data.parentDepartments,
            "departmentManagers": data.departmentManagers,
        });
    }
    const handleShowPassivesToogle = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 };
        setIsShowPassives(e);
        setSearchData({ ...searchData, "showPassiveRecords": e, "pagination": pagingObj });
    }
    const handleResetAllfilters = () => {
        setIsShowPassives(false)
        setSearchData(initialSearchData)
    }
    useEffect(() => {
        departmentSearch(searchData)
    }, [searchData]);

    function handleExportDocument(props) {
        pdfInitialData.exportType = props;
        departmentListExportWithPdf(pdfInitialData)
        setIsModalVisible(false);
    }

    // New Import Modal 
    
    useEffect(() => {
        if(departmentImportResponse.status === "SUCCESS"){
            updateDepartmentResponseReset()
            departmentSearch(searchData)
            getDepartmentOrgChart(organizationResourceId)
            setcomponentKey((i) => i + 1);
        }
    },[departmentImportResponse])

    const openImportModal = () => {
        setImportData({ ...importData, 'key': importData?.key + 1, 'isVisible': true });

    }
    const closeImportModal = () => {
        setImportData({ ...importData, 'isVisible': false });
    }


    // #region OnBoarding
    const [popoverVisible, setPopoverVisible] = useState(false);
    const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
    const handlePopoverVisibleChange = (visible) => {
        if(visible == false){ props.setCurrentTaskState(null); }
        if(props.currentTaskState !== null){
            setPopoverVisible(visible);
        } 
    };
    
    OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'pages/department/index' );
    
    useEffect(() => { 
        if(props.currentTaskState == 12){  // Department
            setPopoverVisible(true); 
        }
        if(props.currentTaskState == 13){  // Department
            setPopoverVisible(false); 
            addDepartment(true);
        }

    }, [props.currentTaskState]); 

                
    useEffect(() => {
        if(organizationTabreducer.departmentAddDrawer == false && props.currentTaskState == 13){
            props.setCurrentTaskState(null);
        }
    }, [organizationTabreducer.departmentAddDrawer])
    // #endregion

            

    const OperationsSlot = {
        left: <h1 className="page-title">{t('DEPARTMENTS')} <span className="count-info">({totalNumberOfRecords})</span> </h1>,
        right: <div className="tab-btn">
            <Can I="inventory.export">
                <Button onClick={openImportModal} className="white-btn flex-align-center svg-class f-14 mr-2 import-button" icon={<UilFileImport />}>{t('SETTINGS_IMPORT')}</Button>
            </Can>
            <Can I="department.add">
                <Popover
                    key={'department.add'}
                    overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                    content={<PopoverContent key={'department.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                    title={false}
                    placement="left"
                    trigger="click"
                    visible={popoverVisible && props.currentTaskState !== null}
                    onVisibleChange={handlePopoverVisibleChange}
                >
                    <Button onClick={() => addDepartment(popoverVisible)} className={`primary-btn w-230 h-32 f-14 ${popoverVisible ? 'onboarding-button-border' : ''}`} icon={<PlusOutlined />}>
                        {/* <Button onClick={() => addDepartment()} className="primary-btn px-15  h-32 f-14 margin-r-24" icon={<PlusOutlined />}> */}
                        {t('CREATE_NEW_DEPARTMENTS')}
                    </Button>
                </Popover>

                
            </Can>
            {/* <Can I="department.export">
                <Dropdown overlay={organization_dropdown(t, showModal)} trigger={['click']}>
                    <Button className="white-btn w-178 h-32 f-14 " icon={<MoreOutlined style={{ fontSize: '14px' }} />}>{t('PARTNER_PROCESS')}</Button>
                </Dropdown>
            </Can> */}
        </div>,
    };
    const addDepartment = (fromPopover) => {
        if(fromPopover){
            setPopoverVisible(false);
            props.setCurrentTaskState(13);
        }
        setDepartmentAddDraw(true);
        setComponentKeyDepartmentAdd((i) => i + 1)
        getPersonWorkHours();
    }
    
    const renderActionModal = () => {
        const controlRequestData = {
            "ResourceId": controlModalData?.id,
            "DeleteOptions": []
        };
        if (controlModalData?.modalType === 'delete') {
            return (
                <DynamicRequestModal
                    key={'department-delete'}
                    modalVisible={controlModalData?.isVisible}
                    setStateData={setControlModalData}
                    postAction={departmentMoreAction}
                    confirmData={{ 'id': controlModalData?.id, 'requestObj': controlRequestData, 'type': controlModalData?.modalType }}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DEPARTMENT_DELETE_MODAL_MESSAGE', { "departmentName": controlModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
            )
        } else if (controlModalData?.modalType === 'disable') {
            return (
                <DynamicRequestModal
                    key={'department-disable'}
                    modalVisible={controlModalData?.isVisible}
                    setStateData={setControlModalData}
                    postAction={departmentMoreAction}
                    confirmData={{ 'id': controlModalData?.id, 'requestObj': controlRequestData, 'type': controlModalData?.modalType }}
                    iconName='UilBan'
                    modalTitle={t('PARTNER_DO_PASSIVE')}
                    description={t('DEPARTMENT_PASSIVE_MODAL_MESSAGE', { "departmentName": controlModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('MAKE_IT_PASSIVE')}
                />
            )
        } else if (controlModalData?.modalType === 'active') {
            return (
                <DynamicRequestModal
                    key={'department-active'}
                    modalVisible={controlModalData?.isVisible}
                    setStateData={setControlModalData}
                    postAction={departmentMoreAction}
                    confirmData={{ 'id': controlModalData?.id, 'requestObj': controlRequestData, 'type': 'active' }}
                    iconName='UilBan'
                    modalTitle={t('DO_ACTIVE')}
                    description={t('DEPARTMENT_ACTIVE_MODAL_MESSAGE', { "departmentName": controlModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('MAKE_IT_ACTIVE')}
                />
            )
        }
    }

    return (
        <React.Fragment>
            <Can I="department.list">
                <Layout>
                    <ExportModal
                        onCancel={() => { setIsModalVisible(false) }}
                        status={isModalVisible}
                        handleExportDocument={handleExportDocument}
                    />
                    {renderActionModal()}
                    <Layout>
                        <Drawer title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={organizationTabreducer.departmentAddDrawer} > {/* EKLE DRAWI */}
                            <CreateDepartmentTabs key={componentKeyDepartmentAdd} returnShow={true} searchData={searchData} />
                        </Drawer >
                        <DrawerTabs
                            selectedPersonId={selectedPersonId}
                            isPersonDetailDrawerVisible={isPersonDetailDrawerVisible}
                            setIsPersonDetailDrawerVisible={(status, id) => handleSetIsPersonDetailDrawerVisible(status, id)}
                        />
                        <Drawer title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={organizationTabreducer.departmentDetailsDrawer} className="ant-drawer-without-shadow" >
                            <DepartmentDetailsDraw
                                searchData={searchData}
                                canPassive={canPassive}
                                canDelete={canDelete}
                                canActive={canActive}
                            />
                        </Drawer>
                        <Drawer
                            key={departmentControlDrawer?.componentKey}
                            className="delete-person-drawer"
                            title={false} width={drawerWidth}
                            closable={false}
                            onClose={false}
                            placement="right"
                            visible={departmentControlDrawer?.status}
                            zIndex={1002}
                        >
                            <DepartmentActionDrawers />
                        </Drawer>
                        <Col span={24}>
                            <Content className="page-container department-container">
                                <BreadCrumb />
                                <div className="tab-menu">
                                    <Tabs tabBarExtraContent={OperationsSlot} onChange={callback}>
                                        <TabPane tab={<span><UnorderedListOutlined />{t('PARTNER_LIST')}</span>} key="1">
                                            <Col span={24}>
                                                <div className="table-container">
                                                    <Media query="(min-width: 875px)" >
                                                        {matches => {
                                                            const columns = [
                                                                {
                                                                    title: `${t('DEPARTMENT_NAME')}`,
                                                                    dataIndex: 'departmentName',
                                                                    key: 'departmentName',
                                                                    sorter: true,
                                                                    render: obj =>
                                                                        <div className="imageNameActionColumn">
                                                                            <Can I="department.read">
                                                                                {
                                                                                    width >= 875 ?
                                                                                        <React.Fragment>
                                                                                            <a className="details-text mar-0" onClick={() => showOrganizationDetailDrawer(obj.id)}>{obj.text}</a>
                                                                                            <Dropdown
                                                                                                className="action-menu"
                                                                                                overlay={department_table_dropdown(t, canDelete, canPassive, canActive, obj.isActive, obj.id, obj.text, obj.isHead, permissions)}
                                                                                                trigger={['click']}
                                                                                            >
                                                                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                                                            </Dropdown>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                                                                }
                                                                            </Can>
                                                                            <Cannot I="department.read">
                                                                                <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                                                            </Cannot>
                                                                        </div>
                                                                },
                                                                {
                                                                    title: `${t('DEPARTMENT_MANAGER')}`,
                                                                    dataIndex: 'departmentManager',
                                                                    key: 'departmentManager',
                                                                    sorter: true,

                                                                },
                                                                {
                                                                    title: `${t('Lokasyon')}`,
                                                                    dataIndex: '',
                                                                    key: '',
                                                                    sorter: true,
                                                                },
                                                            ];
                                                            if (matches) {
                                                                columns.splice(1, 0,
                                                                    {
                                                                        title: `${t('HIERARCHY')}`,
                                                                        dataIndex: 'isHeadDepartment',
                                                                        key: 'isHeadDepartment',
                                                                        sorter: true,
                                                                        render: obj => { return obj ? <Tag className="m-w-110 text-center" color="cyan">{t('HEAD_DEPARTMENT')}</Tag> : <Tag className="m-w-110 text-center" color="default">{t('SUB_DEPARTMENT')}</Tag> }

                                                                    }
                                                                );
                                                                columns.splice(2, 0,
                                                                    {
                                                                        title: `${t('DEPARTMENT_PARENT_DEPARTMENT')}`,
                                                                        dataIndex: 'parentDepartment',
                                                                        key: 'parentDepartment',
                                                                        sorter: true,
                                                                    }
                                                                );
                                                                columns.push({
                                                                    title: `${t('PEOPLE')}`,
                                                                    dataIndex: 'personsCount',
                                                                    key: 'personsCount',
                                                                    sorter: true,
                                                                    width: '5%',
                                                                    render: personsCount => { return (personsCount.text > 0 ? <a onClick={() => showConnectedPersonsDrawer(personsCount.id)} className="details-text">{personsCount.text + ' ' + t('DEBIT_PERSON')}</a> : t('NONE')) },
                                                                });
                                                            }
                                                            return <DynamicTable
                                                                columns={columns} dataSource={data}
                                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                                                                onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                                                                searchPlaceholder={t('SEARCH_DEPARTMENT')}
                                                                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                                                                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                                                loadStatus={departmentIsSearchLoading}
                                                                checkboxStatus={false}
                                                            />
                                                        }}
                                                    </Media >
                                                </div>
                                            </Col>
                                        </TabPane>
                                        {permissions.includes("department.orgchart") && (
                                            <TabPane tab={<span><ApartmentOutlined />{t('PARTNER_TREEVIEW')}</span>} key="2">
                                                <TreeView
                                                    key={componentKey}
                                                    setClick={click => (addNodeChildFunc = click)}
                                                    onNodeClick={onNodeClick}
                                                    data={treeViewData}
                                                    page="department"
                                                />
                                            </TabPane>
                                        )}
                                    </Tabs>
                                </div>
                            </Content>
                        </Col>
                    </Layout>
                </Layout>
            </Can>
            <Cannot I="department.list">
                <Forbidden />
            </Cannot>
            <ToastContainer />
            <InventoryImportModal
                key={importData?.key}
                modalState={importData}
                cancelBtnFunction={closeImportModal}
                modalType={'department'}
            />
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        departmentList: state.organization.departmentSearchResult.results,
        departmentIsSearchLoading: state.organization.departmentIsSearchLoading,
        totalNumberOfRecords: state.organization.departmentSearchResult.totalNumberOfRecords,
        organizationTabreducer: state.organizationTabreducer,
        departmentOrgChart: state.organization.departmentOrgChart,
        gridSettings: state.setting.gridSettings,
        createGridSettingsResponse: state.setting.createGridSettingsResponse,
        profile: state.profile,
        updateResponse: state.departmentReducer.updateResponse,
        permissions: state.profile.permissions,
        departmentControlData: state.departmentReducer.departmentControlData,
        departmentControlDrawer: state.organizationTabreducer.departmentControlDrawer,
        departmentMoreActionResponse: state.departmentReducer.departmentMoreActionResponse,
        departmentImportResponse: state.departmentReducer.departmentImportResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        departmentSearch: (searchData) => { dispatch(departmentSearch(searchData)) },
        setDepartmentAddDraw: (props) => { dispatch(setDepartmentAddDraw(props)) },
        setDepartmentConnectDraw: (props) => { dispatch(setDepartmentConnectDraw(props)) },
        setDepartmentDetailsDraw: (props) => { dispatch(setDepartmentDetailsDraw(props)) },
        getDepartmentDetailInfo: (id) => { dispatch(getDepartmentDetailInfo(id)) },
        departmentListExportWithPdf: (props) => { dispatch(departmentListExportWithPdf(props)) },
        personsDepartmentConnect: (searchData) => { dispatch(personsDepartmentConnect(searchData)) },
        getDepartmentOrgChart: (id) => { dispatch(getDepartmentOrgChart(id)) },
        getPerson: (id) => { dispatch(getPerson(id)) },
        getGridSetting: (props) => { dispatch(getGridSetting(props)) },
        createGridSettings: (props) => { dispatch(createGridSettings(props)) },
        settingsReset: () => { dispatch(settingsReset()) },
        departmentControl: (resourceId) => { dispatch(departmentControl(resourceId)) },
        setDepartmentControlDrawer: (props) => { dispatch(setDepartmentControlDrawer(props)) },
        departmentMoreAction: (data) => { dispatch(departmentMoreAction(data)) },
        updateDepartmentResponseReset: (id) => { dispatch(updateDepartmentResponseReset) },
        departmentMoreActionResponseReset: () => { dispatch(departmentMoreActionResponseReset()) },
        getPersonWorkHours: () => { dispatch(getPersonWorkHours()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Department);
