import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    setTravelDetailsDrawer, updateTravelRequest, updateTravelRequestReset, getTravelDetail,
    getTravelDetailSteps, travelChangeStatus, resetTravelChangeStatus
} from '../../../redux/actions/travel.actions';

import { Row, Col, Form, Steps, ConfigProvider, Button, List, Spin, Input } from 'antd';
import { dateFormatConvert, dateFormatWithHours, dateFormat, dynamicDateInputFormatter } from "../../../utils/config";
import { UilMultiply } from '@iconscout/react-unicons';

import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicTable from '../../dynamicTable/index';
import DynamicRequestModal from '../../dynamicModal/requestModal';
import TravelEditTab from './travel_edit_tab';
import moment from 'moment'


import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';
import '../../../styles/table.scss';
import '../../../styles/travel-host.scss';
import '../../../styles/custom-step-drawer.scss';

function TravelDetailTabs(props) {
    const { Step } = Steps;

    const { setTravelDetailsDrawer, travelDetail, travelDetailLoading,
        updateTravelResponse, updateTravelRequest, updateTravelRequestReset,
        selectTravelRequest, getTravelDetail, getTravelDetailSteps, travelDetailStep,
        travelDetailStepLoading, travelChangeStatusResponse, travelChangeStatus, resetTravelChangeStatus } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [travelObj, setTravelObj] = useState({
    });

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState();

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false })
    const [rejectState, setRejectState] = useState({})
    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false })
    const [approveState, setApproveState] = useState({})
    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const handleApproveConfirm = () => {
        travelChangeStatus({ 'resourceId': selectTravelRequest, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        travelChangeStatus({ 'resourceId': selectTravelRequest, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const personColumn = [
        {
            title: `${t('PARTNER_NAME_SURNAME')}`,
            dataIndex: 'name',
            key: 'name',
            sorter: true,

        },
        {
            title: `${t('PARTNER_COMPANY_NAME')}`,
            dataIndex: 'companyName',
            key: 'companyName',
            sorter: true,
        },
    ]
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        page: "table",
        showVisibilityMenu: false
    });
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const paginationConfig = () => ({
        total: 300,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: ['none', 'none'],
    });

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }
    const closeDrawer = () => {
        setTravelDetailsDrawer(false);
        setCurrentTab(1);
        setTravelObj({});
    }
    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(travelObj, obj)
        setTravelObj(mergedObj)

    }

    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []

        if (travelObj?.travelRequestDetailsList && travelObj.travelRequestDetailsList.length > 0) {
            travelObj.travelRequestDetailsList.map((data, index) => {
                if ((!data.country || !data.country?.id)) {
                    hasError = true;
                    fields.push(t('ORGANIZATION_COUNTRY'))
                }
                if (!data.provinceFrom) {
                    hasError = true;
                    fields.push(t('FROM_WHERE'))
                }
                if (!data.provinceTo) {
                    hasError = true;
                    fields.push(t('FROM_TO'))
                }
                if (!data.modeOfTransportation || !data?.modeOfTransportation?.id) {
                    hasError = true;
                    fields.push(t('MODE_OF_TRANSPORTATION'))
                }
                if (!data.date) {
                    hasError = true;
                    fields.push(t('PARTNER_DATE'))
                }
                if (!data.hour) {
                    hasError = true;
                    fields.push(t('HOUR'))
                }
            })
        } else {
            // hasError = true
            // fields.push(t('ESTIMATED_BUDGET'))
        }
        if (travelObj?.hostDetailsList && travelObj.hostDetailsList.length > 0) {
            travelObj.hostDetailsList.map((data, index) => {
                if ((!data.country || !data.country?.id)) {
                    hasError = true;
                    fields.push(t('ORGANIZATION_COUNTRY'))
                }
                if (!data.province) {
                    hasError = true;
                    fields.push(t('PARTNER_PROVINCE'))
                }
                if (!data.startDate) {
                    hasError = true;
                    fields.push(t('PARTNER_ENTRY_DATE'))
                }
                if (!data.endDate) {
                    hasError = true;
                    fields.push(t('RELEASE_DATE'))
                }
            })
        } else {
            // hasError = true
            // fields.push(t('ESTIMATED_BUDGET'))
        }
        if (!hasError) {

            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    function edit() {
        setCurrentTab(2);
    }
    function cancel() {
        setCurrentTab(1);
    }
    function save() {
        var travelDescription = null

        if (travelObj?.country?.displayValue && travelObj?.province && travelObj?.departureDate) {
            travelDescription = moment(travelObj?.departureDatePicker).format(dynamicDateInputFormatter) + " " + travelObj?.country?.displayValue + "-" + travelObj?.province
        }
        if (travelObj.returnDate) {
            const returnDatePicker = moment(travelObj.returnDate).format(dateFormat);
            var returnDateWithHours = moment(returnDatePicker + ' ' + '00:00:00').format(dateFormatWithHours);
        }
        if (travelObj.departureDate) {
            const departureDatePicker = moment(travelObj.departureDate).format(dateFormat);
            var departureDateWithHours = moment(departureDatePicker + ' ' + '00:00:00').format(dateFormatWithHours);
        }
        const data = {
            'travelDescription': travelDescription,
            'departureDate': departureDateWithHours,
            'returnDate': returnDateWithHours,
            'CityName': travelObj?.province,
            'CountryIdx': travelObj?.country?.id,
            'CountryName': travelObj?.country?.displayValue,
            'estimatedAmount': travelObj?.estimatedAmounts,
            'estimatedTotal': travelObj?.estimatedTotal,
            'notes': travelObj?.notes ? travelObj.notes : null,
            'traveledPersons': travelObj?.traveledPersonsModels?.length > 0 ? travelObj?.traveledPersonsModels : []
        }
        checkRequiredFields(() => updateTravelRequest(data, selectTravelRequest))
    }
    useEffect(() => {
        if (updateTravelResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': updateTravelResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTravelDetail(selectTravelRequest);
            getTravelDetailSteps(selectTravelRequest);
            setCurrentTab(1);
            updateTravelRequestReset();

        }
        else if (updateTravelResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateTravelResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateTravelRequestReset();
        }
    }, [updateTravelResponse])
    useEffect(() => {
        if (travelChangeStatusResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': travelChangeStatusResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTravelDetail(selectTravelRequest);
            getTravelDetailSteps(selectTravelRequest);

            setRejectModalData({ 'isVisible': false });
            setRejectState({});
            rejectForm.resetFields();

            setApproveModalData({ 'isVisible': false });
            setApproveState({});
            approveForm.resetFields();

            resetTravelChangeStatus();

        }
        else if (travelChangeStatusResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': travelChangeStatusResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetTravelChangeStatus()
        }
    }, [travelChangeStatusResponse])

    function approve() {
        setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': selectTravelRequest })
    }
    function decline() {
        setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': selectTravelRequest })
    }
    return (
        (travelDetailLoading && travelDetailStepLoading) ?
            <Spin className="tab-loading " />
            :
            <>
                <DynamicRequestModal
                    modalVisible={rejectModalData?.isVisible}
                    setStateData={setRejectModalData}
                    handleConfirm={handleRejectConfirm}
                    handleCancel={handleRejectCancel}
                    confirmData={rejectModalData?.id}
                    isForm={rejectModalForm}
                    iconName='UilTimesCircle'
                    modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                    description={t('TRAVEL_REJECT_MESSAGE')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
                />
                <DynamicRequestModal
                    modalVisible={approveModalData?.isVisible}
                    setStateData={setApproveModalData}
                    handleConfirm={handleApproveConfirm}
                    handleCancel={handleApproveCancel}
                    confirmData={approveModalData?.id}
                    isForm={approveModalForm}
                    iconName='UilCheckCircle'
                    modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                    description={t('TRAVEL_APPROVE_MESSAGE')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
                />
                <div className="table-details ">
                    <div className="add-global-close-icon">
                        <UilMultiply onClick={() => closeDrawer()} />
                    </div>

                    <Row className="w-100">
                        <Col span={5}>
                            <Steps className="left-step time-off-step pad-top-40" direction="vertical" size="middle" current={travelDetailStep.filter(filterData => filterData.isSelected === true)[0]?.sortOrder - 1 || travelDetailStep.length}>
                                {travelDetailStep.map((data) => {
                                    return (
                                        <Step key={data.sortOrder} title={data.stepName}
                                            description={

                                                <ul className="sub-tab-hide-icon">
                                                    <li className={data.isSelected ? "sub-tab-active" : ''}>
                                                        {travelDetailStep.length > 0 ? data.name : ''} <br />
                                                        {travelDetailStep.length > 0 ? data?.date ? moment(data?.date).format('L') : '' : ''}
                                                    </li>
                                                </ul>
                                            } />
                                    )
                                })}
                            </Steps>
                        </Col>
                        <Col span={19}>
                            {currentTab === 1 ?
                                <>
                                    <div className="custom-step-drawer pad-0">
                                        <div className="custom-step-drawer-title">
                                            <h1>{travelDetail?.travelDescription}</h1>
                                        </div>
                                    </div>
                                    <div className="custom-step-drawer-header pad-right-12">

                                        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                                            <Form
                                                form={form}
                                                layout="vertical travel-scrollable travel-container"
                                                requiredMark={true}
                                            >
                                                <Row>
                                                    <Col span={11}>
                                                        <div className="travel-drawer-left">
                                                            <Form.Item name="departureDate" label={t('DEPARTURE_DATE')}>
                                                                <p className="black f-16">{moment(travelDetail?.departureDate).format(dateFormatConvert)}</p>
                                                            </Form.Item>
                                                            <Form.Item name="returnDate" label={t('DATE_OF_RETURN')}>
                                                                <p className="black f-16">{moment(travelDetail?.returnDate).format(dateFormatConvert)}</p>
                                                            </Form.Item>
                                                            <Form.Item name="country" className="custom-select" label={t('ORGANIZATION_COUNTRY')}>
                                                                <p className="black f-16">{travelDetail?.countryName}</p>
                                                            </Form.Item>
                                                            <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                                                <p className="black f-16"> {travelDetail?.cityName}</p>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                    <Col span={11}>
                                                        <div className="travel-drawer-right">
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Row>
                                                                        <Col span={24} >
                                                                            <h3 className="black f-16 fw-600 ">{t('ESTIMATED_BUDGET') + ' *'}</h3>
                                                                        </Col>

                                                                    </Row>
                                                                    <Col span={18}>
                                                                        {
                                                                            travelDetail?.estimatedAmounts.map((item, index) => {
                                                                                return (
                                                                                    <Row key={index} style={{ "display": "flex", "alignItems": "center", }} >
                                                                                        <Col span={12} >
                                                                                            <p className="black f-16">{item?.expenseTypeName}</p>
                                                                                        </Col>
                                                                                        <Col span={12} >
                                                                                            <p className="black f-16 right-text">{parseFloat(item.amount).toLocaleString('tr-TR', {
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2
                                                                                            })} TL </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )
                                                                            })
                                                                        }
                                                                        <Col span={24} >
                                                                            <div class="w-100 pad-top-10">
                                                                                <Row>
                                                                                    <Col span={12}>
                                                                                        <p className="black f-16 fw-600"> {t('PARTNER_TOTAL')} :</p>
                                                                                    </Col>
                                                                                    <Col span={12}>
                                                                                        <p className="black f-16 fw-600 right-text">  {travelDetail.estimatedTotal > 0 && parseFloat(travelDetail.estimatedTotal).toLocaleString('tr-TR', {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        })} TL
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>
                                                                    </Col>

                                                                </Col>

                                                            </Row>
                                                            <br />
                                                            <Form.Item name="notes" className="custom-select" label={t('NOTES')}>
                                                                <p className="f-16 black">{travelDetail.notes}</p>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="expense-drawer">
                                                    <div className="table-container table-hide-all-buttons blue-table">
                                                        {travelDetail.traveledPersonsModels.length > 0 &&
                                                            <DynamicTable columns={personColumn} dataSource={travelDetail.traveledPersonsModels}
                                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={() => null}
                                                                onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                                isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </Form >

                                        </ConfigProvider >
                                    </div>
                                </>

                                :
                                <TravelEditTab setCreateObject={setCreateObject} travelDetail={travelDetail} />}

                        </Col>
                    </Row>

                </div>
                {(currentTab === 1 && travelDetailStep[0]?.editableStatus === 3) &&
                    <div className="add-user-draw-footer">
                        <Button onClick={edit} className="w-120 h-40 transparent-btn pad-0">{t('PARTNER_EDIT')}</Button>
                        <div className="w-100 flex-end pad-right-12">
                            <Button onClick={() => decline()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_DECLINE')}</Button>
                            <div className="pad-left-12" />
                            <Button onClick={() => approve()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_APPROVE')}</Button>
                        </div>
                    </div>

                }
                {(currentTab === 1 && travelDetailStep[0]?.editableStatus === 2) &&
                    <div className="add-user-draw-footer">
                        <Button onClick={edit} className="w-120 h-40 transparent-btn pad-0">{t('PARTNER_EDIT')}</Button>
                        <div className="add-user-draw-footer-end">
                            <div className="pad-left-24" />
                            <Button onClick={closeDrawer} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        </div>

                    </div>
                }
                {(currentTab === 1 && travelDetailStep[0]?.editableStatus === 1) &&
                    <div className="add-user-draw-footer">
                        <div className="add-user-draw-footer-end">
                            <div className="pad-left-24" />
                            <Button onClick={closeDrawer} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        </div>

                    </div>
                }
                {currentTab === 2 &&
                    <div className="add-user-draw-footer">
                        <Button onClick={cancel} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                        <div className="add-user-draw-footer-end">
                            <div className="pad-left-24" />
                            <Button onClick={save} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </div>
                    </div>
                }
            </>
    )

}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        travelDetail: state.travel.travelDetail,
        travelDetailLoading: state.travel.travelDetailLoading,
        updateTravelResponse: state.travel.updateTravelResponse,
        travelDetailStep: state.travel.travelDetailStep,
        travelDetailStepLoading: state.travel.travelDetailStepLoading,
        travelChangeStatusResponse: state.travel.travelChangeStatusResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTravelDetailsDrawer: (status) => { dispatch(setTravelDetailsDrawer(status)) },
        updateTravelRequest: (data, id) => { dispatch(updateTravelRequest(data, id)) },
        updateTravelRequestReset: () => { dispatch(updateTravelRequestReset()) },
        getTravelDetail: (id) => { dispatch(getTravelDetail(id)) },
        getTravelDetailSteps: (id) => { dispatch(getTravelDetailSteps(id)) },
        travelChangeStatus: (data, type) => { dispatch(travelChangeStatus(data, type)) },
        resetTravelChangeStatus: () => { dispatch(resetTravelChangeStatus()) }

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TravelDetailTabs);
