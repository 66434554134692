import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getTaskAssignedList, getAssignedTaskInformation, resetDeleteAssignedTasks } from "../../../redux/actions/task.actions";

import { Button } from "antd";
import Media from "react-media";
import moment from "moment";
import "moment/locale/tr";

import DynamicTable from "../../dynamicTable/index";
import DynamicToastMessage from "../../dynamicToastMessage";

import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";
import Forbidden from "../../forbidden";

import { dateFormatConvert, useWindowDimensions } from "../../../utils/config";

function AssignedTasks(props) {
    const { t } = useTranslation();
    const { taskAssignedInitialSearchData, assignedTaskRequestState, setAssignedTaskRequestState, openCreateTaskDrawer,
        tasksAssignedList, tasksAssignedListLoading, getTaskAssignedList, totalNumberOfRecords, openAssignedTaskDetailDrawer,
        getAssignedTaskInformation, deleteAssignedTaskModalData, setDeleteAssignedTaskModalData, deleteAssignedTaskResponse,
        resetDeleteAssignedTasks } = props;
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const handleSaveGridSettings = (d) => { };

    const handleResetAllfilters = () => {
        setAssignedTaskRequestState(taskAssignedInitialSearchData);
    }
    const handleTableFilter = (data) => {
        setAssignedTaskRequestState({
            ...assignedTaskRequestState, "pagination": {
                ...assignedTaskRequestState.pagination,
                "pageNumber": 1,
                "pageSize": 10,
            }, personResourceId: data.personName, appointeeId: data.appointName, taskGroupState: data.stateType
        });
    };
    const openDeleteModal = (groupId, personResourceId, ownerResourceId, appointResourceId, ownerShipTypeId) => {
        setDeleteAssignedTaskModalData({ ...deleteAssignedTaskModalData, 'groupId': groupId, 'personResourceId': personResourceId, 'ownerResourceId': ownerResourceId, 'appointResourceId': appointResourceId, 'ownerShipTypeId': ownerShipTypeId, 'isVisible': true })
    }
    const showMyTasks = (groupId, personResourceId, ownerResourceId, appointResourceId, ownerShipTypeId) => {
        if (groupId !== undefined && personResourceId !== undefined && ownerShipTypeId !== undefined) {
            getAssignedTaskInformation(groupId, personResourceId, ownerResourceId, appointResourceId, ownerShipTypeId)
            openAssignedTaskDetailDrawer();
        }
    };
    useEffect(() => {
        getTaskAssignedList(assignedTaskRequestState);
    }, [assignedTaskRequestState]);
    const data = tasksAssignedList.map((row, index) => {
        return {
            key: index,
            personName: {
                groupId: row.groupId,
                ownerShipTypeId: row.ownerShipTypeId,
                ownerResourceId: row.ownerResourceId,
                appointResourceId: row.appointResourceId,
                personResourceId: row.personResourceId,
                text: row.personName
            },
            taskCount: row.completedTaskCount + "/" + row.taskTotalCount,
            assignedDateTime: row.assignedDateTime,
            appointName: row.appointName,
            ownerShipName: row.ownerShipName,
            lastProcessDatetime: row.lastProcessDatetime,
            stateType: { id: row.ownerShipTypeId, displayValue: row.stateMessage },
            edit: {
                groupId: row.groupId,
                ownerShipTypeId: row.ownerShipTypeId,
                ownerResourceId: row.ownerResourceId,
                appointResourceId: row.appointResourceId,
                personResourceId: row.personResourceId,
            },
            delete: row.groupId,
        };
    });
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ["personName", "stateType", "appointName"] : [],
        sortableColumns: ["personName", "stateType", "appointName", "ownerShipName"],
        visibilityOptionExcludes: ["personName", "taskCount", "assignedDateTime", "appointName", "ownerShipName", "lastProcessDatetime", "stateType", "edit",],
        gridSettings: null,
        page: "assignedTasks",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    });
    const paginationConfig = {
        current: assignedTaskRequestState?.pagination?.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t("PAGE") },
        position: totalNumberOfRecords < 10 ? ["none", "none"] : ["none", "bottomLeft"],
    };
    const renameFieldName = (field) => {
        if (field === "stateType") {
            return "taskGroupState";
        } else {
            return field;
        }
    };
    const leftActionMenu = () => {
        return (
            <div className="w-100 d-flex justify-space-between">
                <Button onClick={() => openCreateTaskDrawer()} className="flex-class svg-class primary-btn h-32 w-auto m-w-180" >{t('ASSIGN_NEW_TASK')}</Button>
            </div>
        );
    };
    const handleTableChange = (data) => {
        const paginationObj = { ...assignedTaskRequestState.pagination, pageNumber: data.paging.current, pageSize: data.paging.pageSize, orderName: renameFieldName(data.sorter.field ? data.sorter.field : assignedTaskRequestState.pagination.orderBy) };
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === "ascend" ? true : false;
        }
        setAssignedTaskRequestState({ ...assignedTaskRequestState, pagination: paginationObj });
    };
    useEffect(() => {
        if (deleteAssignedTaskResponse.status === "SUCCESS") {
            DynamicToastMessage({
                'key': 'delete_success_key',
                'title': t('SUCCESS'),
                'description': deleteAssignedTaskResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            resetDeleteAssignedTasks();
            if (tasksAssignedList?.length > 0) {
                getTaskAssignedList(assignedTaskRequestState);
            } else {
                setAssignedTaskRequestState({
                    ...assignedTaskRequestState,
                    pagination: {
                        pageNumber: assignedTaskRequestState?.pagination?.pageNumber > 0 ? assignedTaskRequestState?.pagination?.pageNumber - 1 : 0, pageSize: assignedTaskRequestState.pagination.pageSize, orderBy: assignedTaskRequestState.pagination.orderBy
                    }
                });
            }
        } else if (deleteAssignedTaskResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'delete_error_key',
                'title': t('ERROR'),
                'description': deleteAssignedTaskResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetDeleteAssignedTasks();
        }
    }, [deleteAssignedTaskResponse])
    const handleShowPassivesToogle = (e) => { };
    return (
        <>
            <Can I="task.assignedlist">
                <div className="table-container">
                    <Media query="(min-width: 1000px)">
                        {(matches) => {
                            const columns = [
                                {
                                    title: `${t("QUANTITY_OF_TASKS")}`,
                                    dataIndex: "taskCount",
                                    key: "taskCount",
                                    sorter: true,
                                },
                                {
                                    title: `${t("ASSIGNED_DATE")}`,
                                    dataIndex: "assignedDateTime",
                                    key: "assignedDateTime",
                                    sorter: true,
                                    render: date => {
                                        return (date ? moment(date).format(dateFormatConvert) : '')
                                    }
                                },
                                {
                                    title: `${t("APPOINTED_PERSON")}`,
                                    dataIndex: "ownerShipName",
                                    key: "ownerShipName",
                                    sorter: true,
                                },
                                {
                                    title: `${t("PERSON_WHO_APPOINTED")}`,
                                    dataIndex: "appointName",
                                    key: "appointName",
                                    sorter: true,
                                },
                                {
                                    title: `${t("LAST_TRANSACTION_DATE")}`,
                                    dataIndex: "lastProcessDatetime",
                                    key: "lastProcessDatetime",
                                    sorter: true,
                                    render: date => {
                                        return (date ? moment(date).format(dateFormatConvert) : '')
                                    }
                                },
                                {
                                    title: `${t("ORGANIZATION_STATU")}`,
                                    dataIndex: "stateType",
                                    key: "stateType",
                                    sorter: true,
                                    render: (state) => {
                                        if (state.id === "1") {
                                            return <span className="yellow  flex-align-center">{state.displayValue}</span>;
                                        } else if (state.id === "2") {
                                            return <span>{state.displayValue}</span>;
                                        } else if (state.id === "3") {
                                            return <span className="red">{state.displayValue}</span>;
                                        } else if (state.id === "4") {
                                            return <span className="">{state.displayValue}</span>;
                                        } else {
                                            return <span>{state?.displayValue}</span>;
                                        }
                                    },
                                },
                            ];
                            if (matches) {
                                columns.splice(0, 0, {
                                    title: `${t("PARTNER_NAME_SURNAME2")}`,
                                    dataIndex: "personName",
                                    key: "personName",
                                    sorter: false,
                                    render: obj => (
                                        <div className="imageNameActionColumn">
                                            <a onClick={() => showMyTasks(obj.groupId, obj.personResourceId, obj.ownerResourceId, obj.appointResourceId, obj.ownerShipTypeId)} className="details-text mar-0">
                                                {obj.text}
                                            </a>
                                        </div>
                                    ),
                                });
                                columns.splice(1, 0, {
                                    title: "",
                                    dataIndex: "edit",
                                    key: "edit",
                                    sorter: true,
                                    render: obj => <div className="d-flex">
                                        <Button onClick={() => showMyTasks(obj.groupId, obj.personResourceId, obj.ownerResourceId, obj.appointResourceId, obj.ownerShipTypeId)} className="transparent-btn pad-0 svg-class flex-align-center">{t('PARTNER_EDIT')}</Button>
                                        <Button onClick={() => openDeleteModal(obj.groupId, obj.personResourceId, obj.ownerResourceId, obj.appointResourceId, obj.ownerShipTypeId)} className="transparent-btn pad-0 svg-class flex-align-center ml-8 mr-4">{t('PARTNER_DELETE')}</Button>
                                    </div>
                                });
                            }
                            return (
                                <DynamicTable
                                    columns={columns}
                                    dataSource={data}
                                    tableConfig={tableConfig()}
                                    paginationConfig={paginationConfig}
                                    onFilter={(data) => handleTableFilter(data)}
                                    onTableChange={(e) => handleTableChange(e)}
                                    onResetAllFilters={handleResetAllfilters}
                                    searchPlaceholder=""
                                    onShowPassivesToggle={(e) => handleShowPassivesToogle(e)}
                                    isShowPassives={isShowPassives}
                                    onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                    searchBox={false}
                                    checkboxStatus={false}
                                    passiveButton={false}
                                    loadStatus={tasksAssignedListLoading}
                                />
                            );
                        }}
                    </Media>
                </div>
            </Can>
            <Cannot I="task.assignedlist">
                <Forbidden size="sm" />
            </Cannot>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        tasksAssignedList: state.taskReducer.tasksAssignedList.assignedTaskList,
        tasksAssignedListLoading: state.taskReducer.tasksAssignedListLoading,
        totalNumberOfRecords: state.taskReducer.tasksAssignedList.totalNumberOfRecords,
        deleteAssignedTaskResponse: state.taskReducer.deleteAssignedTaskResponse,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTaskAssignedList: (searchData) => { dispatch(getTaskAssignedList(searchData)); },
        getAssignedTaskInformation: (groupId, personResourceId, ownerResourceId, appointResourceId, ownerShipTypeId) => { dispatch(getAssignedTaskInformation(groupId, personResourceId, ownerResourceId, appointResourceId, ownerShipTypeId)) },
        resetDeleteAssignedTasks: () => { dispatch(resetDeleteAssignedTasks()) }

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignedTasks);