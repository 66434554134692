import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPersonEducationDetail, resetPersonEducationDetail, } from '../../../redux/actions/education.actions';

import { Layout, Row, Col, Tree, Tag, Spin } from 'antd';
import { ToastContainer } from 'react-toastify';
import DynamicToastMessage from '../../../component/dynamicToastMessage/index';
import BreadCrumb from '../../../features/breadcrump/breadcrump';
import CustomPlayer from '../../../component/customPlayer/index';

import '../../../styles/education.scss';

function TrainingDetails(props) {
    const { t } = useTranslation();
    const { Content } = Layout;
    const detailId = props.match.params.resourceId;
    const { getPersonEducationDetail, personEducationDetail, personEducationDetailLoading, resetPersonEducationDetail } = props;

    const [detailData, setDetailData] = useState();
    const [treeData, setTreeData] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);

    const onSelect = (selectedKeys, info) => {
        setSelectedNode(info.node);
        updateDetailData(info.node);
    };

    const updateDetailData = (node) => {
        const newDetailData = node.children ? {
            ...detailData,
            'videoUrl': node.children[0]?.videoUrl,
            'videoName': node.children[0]?.videoName,
            'videoStatus': node.children[0]?.status,
            'videoState': node.children[0]?.resourceId,
            'videoDescription': node.children[0]?.description,
            'videoDurationSecond': node.children[0]?.videoDurationSecond,
            'isYoutubeUrl': node.children[0]?.isYoutubeUrl
        } : {
            ...detailData,
            'videoUrl': node?.videoUrl,
            'videoName': node?.videoName,
            'videoStatus': node?.status,
            'videoState': node?.resourceId,
            'videoDescription': node?.description,
            'videoDurationSecond': node?.videoDurationSecond,
            'isYoutubeUrl': node?.isYoutubeUrl
        };
        setDetailData(newDetailData);
    };

    useEffect(() => {
        getPersonEducationDetail(detailId);
    }, [])

    useEffect(() => {
        if (personEducationDetail.status === 'SUCCESS') {
            const newDetailData = {
                'contentResourceId': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.contentResourceId,
                'videoName': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.videoName,
                'videoDescription': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.description,
                'videoUrl': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.videoPath,
                'videoStatus': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.videoState,
                'videoState': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.videoStateResourceId,
                'videoDurationSecond': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.lastSecond,
                'isYoutubeUrl': personEducationDetail?.sectionInformations[0]?.contentInformation[0]?.isYoutubeUrl,
                'educatorName': personEducationDetail?.educationName,
                'description': personEducationDetail?.description,
                'educationState': personEducationDetail?.educationState,
                'educatorName': personEducationDetail?.educatorName,
            };

            const list = personEducationDetail.sectionInformations.map((tree, index) => ({
                title: tree.sectionName,
                key: index,
                children: tree.contentInformation.map((subTree, i) => ({
                    title: subTree?.videoName,
                    key: `${index}-${i}`,
                    icon: '',
                    videoUrl: subTree?.videoPath,
                    videoName: subTree?.videoName,
                    videoDurationSecond: subTree?.lastSecond,
                    status: subTree?.videoState,
                    resourceId: subTree?.videoStateResourceId,
                    description: subTree?.description
                }))
            }));
            setTreeData(list);

            if (selectedNode) {
                const updatedNode = findNodeByKey(list, selectedNode.key);
                if (updatedNode) {
                    setSelectedNode(updatedNode);
                    updateDetailData(updatedNode);
                }
            } else {
                setDetailData(newDetailData);
                // Select the first node by default
                setSelectedNode(list[0]?.children[0]);
            }
        }
        else if (personEducationDetail.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': personEducationDetail.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetPersonEducationDetail();
        }
    }, [personEducationDetail])

    const findNodeByKey = (nodes, key) => {
        for (let node of nodes) {
            if (node.key === key) return node;
            if (node.children) {
                const found = findNodeByKey(node.children, key);
                if (found) return found;
            }
        }
        return null;
    };

    const videoCompleted = () => {
        getPersonEducationDetail(detailId);
    }

    return (
        personEducationDetailLoading ? <Spin className="w-100 d-flex justify-center align-center h-100" /> :
            <>
                <Layout>
                    <Content className="page-container education-me-details-container w-100 pb-4">
                        <BreadCrumb />
                        <div className="tab-page pb-4">
                            <h1 className="page-title">{t('EDUCATION_DETAIL')}</h1>
                        </div>
                        <Row gutter={[24, 0]}>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 19 }}
                                lg={{ span: 19 }}
                                xl={{ span: 20 }}
                                xxl={{ span: 21 }}
                            >
                                <CustomPlayer
                                    status={detailData?.videoStatus}
                                    pageId={detailId}
                                    videoId={detailData?.videoState}
                                    url={detailData?.videoUrl}
                                    videoDurationSecond={detailData?.videoDurationSecond}
                                    isYoutubeUrl={detailData?.isYoutubeUrl}
                                    personEducationDetailLoading={personEducationDetailLoading}
                                    videoCompleted={videoCompleted}
                                />
                            </Col>
                            <Col
                                xs={{ span: 0 }}
                                sm={{ span: 0 }}
                                md={{ span: 5 }}
                                lg={{ span: 5 }}
                                xl={{ span: 4 }}
                                xxl={{ span: 3 }}
                            >
                                <Tree
                                    showLine={{ 'showLeafIcon': '' }}
                                    showIcon={true}
                                    defaultExpandedKeys={['0-0-0', '0-1-0']}
                                    onSelect={onSelect}
                                    treeData={treeData}
                                    selectedKeys={[selectedNode?.key]}
                                />
                            </Col>
                        </Row>
                        <div className="education-me-details-description">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 19 }}
                                lg={{ span: 19 }}
                                xl={{ span: 20 }}
                                xxl={{ span: 21 }}
                            >
                                <div key={detailData?.videoName} className="d-flex space-between">
                                    <h2>{detailData?.videoName}</h2>
                                    <div>
                                        {
                                            detailData?.videoStatus == 2 &&
                                            <Tag className="green-tag mt-3">{t('COMPLETE')}</Tag>
                                        }
                                        {
                                            detailData?.videoStatus == 1 &&
                                            <Tag className="yellow-tag mt-3">{t('INCOMPLETE')}</Tag>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 0 }}
                                sm={{ span: 0 }}
                                md={{ span: 5 }}
                                lg={{ span: 5 }}
                                xl={{ span: 4 }}
                                xxl={{ span: 3 }}
                            />
                            <h4>{t('VIDEO_DESCRIPTION')}</h4>
                            <p>{detailData?.videoDescription}</p>
                            <h2>{t('EDUCATION_INFORMATION')}</h2>
                            <>
                                <h4>{t('INSTRUCTOR')}</h4>
                                <p>{detailData?.educatorName}</p>
                            </>
                            <>
                                <h4>{t('TRAINING_DESCRIPTION')}</h4>
                                <p>{detailData?.description}</p>
                            </>
                        </div>
                    </Content>
                </Layout>
                <ToastContainer />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        permissions: state.profile.permissions,
        personEducationDetail: state.education.personEducationDetail,
        personEducationDetailLoading: state.education.personEducationDetailLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPersonEducationDetail: (resourceId) => { dispatch(getPersonEducationDetail(resourceId)) },
        resetPersonEducationDetail: () => { dispatch(resetPersonEducationDetail()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainingDetails);