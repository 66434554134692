import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getMyAdvanceRequests, getAdvanceDetails, getAdvanceDetailStep, deleteAdvance, deleteAdvanceReset } from '../../redux/actions/advance.actions';

import { Button, Dropdown } from 'antd';
import { UilPlus } from '@iconscout/react-unicons';
import { MoreOutlined } from '@ant-design/icons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { tableDeletelistDropdown } from '../../features/global-dropdown-menu';
import { dateFormatConvert, useWindowDimensions, numberFormatTR } from '../../utils/config';

import Can from "../../authorization/index";

function AdvanceRequests(props) {
    const { t } = useTranslation();
    const { openCreateAdvanceDrawer, openAdvanceDetailsDrawer, getMyAdvanceRequests, myAdvanceRequestInitialSearch, createAdvanceRequests,
        myAdvanceRequestSearchState, setMyAdvanceRequestSearchState, myAdvanceListLoading, myAdvanceList, totalNumberOfRecords, profile,
        getAdvanceDetails, getAdvanceDetailStep, setSelectAdvance, deleteAdvance, deleteAdvanceResponse, deleteAdvanceReset } = props

    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false });
    const [exportState, setExportState] = useState({ ...myAdvanceRequestSearchState, exportType: 0 });
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (createAdvanceRequests.status === "SUCCESS") {
            getMyAdvanceRequests(profile.resourceId, myAdvanceRequestSearchState);
        }
    }, [createAdvanceRequests])
    useEffect(() => {
        if (deleteAdvanceResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': deleteAdvanceResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            deleteAdvanceReset();
            getMyAdvanceRequests(profile.resourceId, myAdvanceRequestSearchState);
        }
        else if (deleteAdvanceResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': deleteAdvanceResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            deleteAdvanceReset()
        }
    }, [deleteAdvanceResponse])
    const handleSaveGridSettings = () => { }

    const handleResetAllfilters = () => {
        setMyAdvanceRequestSearchState({ ...myAdvanceRequestInitialSearch });
        setExportState({ ...myAdvanceRequestInitialSearch, exportType: 0 });
    }
    const handleTableFilter = (data) => {
        setMyAdvanceRequestSearchState({ ...myAdvanceRequestSearchState, "stateTypes": data.stateType });
        setExportState({ ...exportState, "stateTypes": data.stateType })
    }
    const showAdvanceDetails = (id) => {
        if (id !== undefined) {
            getAdvanceDetails(id);
            getAdvanceDetailStep(id);
            openAdvanceDetailsDrawer();
            setSelectAdvance(id);
        }
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...myAdvanceRequestSearchState.pagination, "pageNumber": 1 }
        setMyAdvanceRequestSearchState({ ...myAdvanceRequestSearchState, "SearchTerm": e, "pagination": pagingObj });
        setExportState({ ...exportState, 'SearchTerm': e });
    }
    useEffect(() => {
        if (myAdvanceRequestSearchState !== myAdvanceRequestInitialSearch) {
            getMyAdvanceRequests(profile.resourceId, myAdvanceRequestSearchState);
        }
    }, [myAdvanceRequestSearchState]);
    const canDelete = (id, count) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'count': count, 'isVisible': true })
    }
    const data = myAdvanceList?.map((row) => {
        return (
            {
                "key": row.resourceId,
                "needDate": { "text": moment(row.needDate).format(dateFormatConvert), "id": row.resourceId },
                // "advanceType": row.advanceType?.displayValue,
                "AdvanceMasterTypeId": row.advanceMasterTypeId === 1 ? t('JOB_ADVANCE') : t('SALARY_ADVANCE'),
                "amountsList": row.amountsList,
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue },
            }
        );

    });
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType'] : [],
        sortableColumns: ['needDate', 'AdvanceMasterTypeId', 'stateType'],
        visibilityOptionExcludes: ['needDate', 'AdvanceMasterTypeId', 'amountsList', 'stateType'],
        gridSettings: null,
        page: "myAdvanceRequest",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    });
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.add">
                        <Button onClick={() => openCreateAdvanceDrawer()} className="flex-class svg-class primary-btn h-32 w-auto mr-4" icon={<UilPlus />}>{t('ADVANCE_CREATE_REQUEST')}</Button>
                    </Can>
                }
            </div>
        )
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    });
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'NewStateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...myAdvanceRequestSearchState.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : myAdvanceRequestSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setMyAdvanceRequestSearchState({ ...myAdvanceRequestSearchState, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = () => { }
    return (
        <div className="table-container">
            <DynamicRequestModal
                key={'delete-advance-dynamic-modal'}
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteAdvance}
                confirmData={deleteModalData?.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_ADVANCE_REQUEST')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <Media query="(min-width: 1000px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === '1') {
                                    return <div className='yellow'>
                                        {state.displayValue}
                                    </div>
                                }
                                else if (state.id === '2') {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === '3') {
                                    return <div className='red'>{state.displayValue}</div>
                                } else if (state.id === '4') {
                                    return <div className=''>{state.displayValue}</div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },

                        {
                            title: `${t('ADVANCE_TYPE')}`,
                            dataIndex: 'AdvanceMasterTypeId',
                            key: 'AdvanceMasterTypeId',
                            sorter: true,
                        },
                        {
                            title: `${t('PARTNER_AMOUNT2')}`,
                            dataIndex: 'amountsList',
                            key: 'amountsList',
                            sorter: true,
                            render: state => {
                                return Array.isArray(state) ? state.length > 0 ?
                                    state.map((data) => numberFormatTR.format(data.amount) + ' ' + data.currencyName).join(', ')
                                    :
                                    ' ' : ''
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
                                dataIndex: 'needDate',
                                key: 'needDate',
                                sorter: true,
                                render: (obj,state) =>
                                    <div className="imageNameActionColumn">
                                        <a onClick={() => showAdvanceDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                       {state.stateType.id == "1" && <Dropdown className="action-menu" overlay={tableDeletelistDropdown(t, canDelete, obj.id)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                            </Dropdown>} 
                                    </div>
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={myAdvanceListLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        myAdvanceListLoading: state.advance.myAdvanceListLoading,
        myAdvanceList: state.advance.myAdvanceList.results,
        totalNumberOfRecords: state.advance.myAdvanceList.totalNumberOfRecords,
        createAdvanceRequests: state.advance.createAdvanceRequests,
        deleteAdvanceResponse: state.advance.deleteAdvanceResponse,
        permissions: state.profile.permissions

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMyAdvanceRequests: (resourceId, myAdvanceRequestSearchState) => { dispatch(getMyAdvanceRequests(resourceId, myAdvanceRequestSearchState)) },
        getAdvanceDetails: (resourceId) => { dispatch(getAdvanceDetails(resourceId)) },
        getAdvanceDetailStep: (resourceId) => { dispatch(getAdvanceDetailStep(resourceId)) },
        deleteAdvance: (resourceId) => { dispatch(deleteAdvance(resourceId)) },
        deleteAdvanceReset: () => { dispatch(deleteAdvanceReset()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceRequests);