import React from 'react'
import { connect } from 'react-redux'
import PeopleOnLeaveTable from './peopleOnLeaveTable'

function TimeOffRequestPeopleOnLeave(props) {
    const { setPeopleOnLeaveSearchData, peopleOnLeaveSearchData, peopleOnLeaveInitialSearchData, setSelectedData } = props;
    return (
        <div className="table-container">
            <div className="blue-tabs" >
                <PeopleOnLeaveTable setSelectedData={setSelectedData} setPeopleOnLeaveSearchData={setPeopleOnLeaveSearchData} peopleOnLeaveInitialSearchData={peopleOnLeaveInitialSearchData} peopleOnLeaveSearchData={peopleOnLeaveSearchData} />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffRequestPeopleOnLeave);