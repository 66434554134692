import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";

const NotificationActionType = {
  UPDATE_TIME_OFF_REQUEST_SETTINGS_SUCCESS: "UPDATE_TIME_OFF_REQUEST_SETTINGS_SUCCESS",
  UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL: "UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL",
  UPDATE_TIME_OFF_REQUEST_SETTINGS_RESET: "UPDATE_TIME_OFF_REQUEST_SETTINGS_RESET",
};

const updateTimeOffRequest = (ruleId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/settings/BirthDayRule/" + ruleId,
        headers: {
          "Content-Type": "application/json",
        },
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_SUCCESS,
              payload: response.data.message,
            });
          } else {
            dispatch({
              type: NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL,
              payload: response.data.message,
            });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({
              type: NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL,
              payload: error.response.data.message,
            });
          } else {
            dispatch({ type: NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_FAIL, payload: error.message });
      }
    }
  };
};

const updateTimeOffRequestReset = () => {
  return async (dispatch) => {
    dispatch({ type: NotificationActionType.UPDATE_TIME_OFF_REQUEST_SETTINGS_RESET, payload: {} });
  };
};

export { NotificationActionType, updateTimeOffRequest, updateTimeOffRequestReset };
