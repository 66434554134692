import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    searchTimeOffRequestManager, getTimeOffRequest, getTimeOffRequestStep
    , timeOffDownload, deleteTimeOffRequest, deleteTimeOffRequestReset, timeOffCancel,
    resetTimeOffApproveResponseStatus, timeOffRequestUpdateReset
} from '../../../redux/actions/timeOff.actions';
import { setTimeOffRequestApproveDrawer } from '../../../redux/actions/time-off-drawer-action';

import { Button, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { UilCheckCircle, UilMinusCircle } from '@iconscout/react-unicons'
import Media from "react-media";
import moment from 'moment'

import { timeoff_dropdown } from '../../../features/global-dropdown-menu/index';
import { dateFormatConvert, useWindowDimensions } from '../../../utils/config';


import DynamicTable from '../../dynamicTable/index';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";

function TimeOffRequestPerson(props) {
    const { t } = useTranslation();
    const { gridSettings, totalNumberOfRecords, searchTimeOffRequestManager, searchTimeOffManagerResult, setSelectedData,
        deleteTimeOffRequestReset, deleteTimeOffRequestResponse, setTimeOffRequestApproveDrawer, getTimeOffRequest, getTimeOffRequestStep,
        timeOffDownload, isSearchTimeOffManagerLoading, permissions, timeOffApproveResponse, resetTimeOffApproveResponseStatus } = props
    const { cancelModalData, setCancelModalData, deleteModalData, setDeleteModalData, timeOffApproveUpdateStatus,
        selectedRowKeys, setselectedRowKeys, setselectedRows, handleTimeOffChangeStatus, timeOffRequestUpdateReset,
        searchData, setSearchData, initialRequestPersonSearchData, handleCloseModal } = props;
    const { width } = useWindowDimensions();
    const initialGetGridSettingData = {
        pageId: 5, //timeoff request person
        gridId: 2, //timeoff request person grid
    }
    const getGridSettingsData = initialGetGridSettingData;
    const [isShowPassives, setIsShowPassives] = useState(false);
    const [tableKey, setTableKey] = useState(6489);

    useEffect(() => {
        searchTimeOffRequestManager(searchData);
    }, [searchData])

    // useEffect(() => {
    //     setTableData([]);
    //     console.log(searchTimeOffManagerResult);
    //     //  let sortByRequestDate = searchTimeOffManagerResult.sort((a, b) => b.requestDate.split('/').reverse().join().localeCompare(a.requestDate.split('/').reverse().join()));
    //     setTableData(searchTimeOffManagerResult);
    // }, [searchTimeOffManagerResult])

    useEffect(() => {
        if (deleteTimeOffRequestResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            if (searchTimeOffManagerResult?.length > 1) {
                searchTimeOffRequestManager(searchData)
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            deleteTimeOffRequestReset();
        } else if (deleteTimeOffRequestResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_FAIL',
                'title': t('ERROR'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffRequestReset()
        }
    }, [deleteTimeOffRequestResponse])
    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'APPROVE_SUCCESS',
                'title': t('SUCCESS'),
                'description': timeOffApproveResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (timeOffApproveResponse.resourceId !== undefined) {
                getTimeOffRequest(timeOffApproveResponse.resourceId)
                getTimeOffRequestStep(timeOffApproveResponse.resourceId)
            }
            resetTimeOffApproveResponseStatus();
            searchTimeOffRequestManager(searchData);
            handleCloseModal();
            setselectedRowKeys([]);
            setselectedRows([]);
        } else if (timeOffApproveResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'timeOffApproveResponseStatus_fail',
                'title': t('ERROR'),
                'description': timeOffApproveResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            timeOffRequestUpdateReset()
        }
    }, [timeOffApproveResponse])

    useEffect(() => {
        if (timeOffApproveUpdateStatus.status === 'SUCCESS') {
            searchTimeOffRequestManager(searchData);
            setselectedRowKeys([]);
            setselectedRows([]);
            timeOffRequestUpdateReset();
        }
    }, [timeOffApproveUpdateStatus])

    const handleSaveGridSettings = () => { }

    const handleTableFilter = (data) => {
        setSearchData({
            ...searchData,
            "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "requestDate",
                "ascending": false
            },
            "persons": data.persons, "timeOffTypes": data.timeOffTypeName, 'stateTypes': data.stateType
        });
    }
    const showTimeOffRequestApproveDrawer = (id, requestDate, timeOfffTypeName, approveSeqNumber, fullName, postId) => {
        if (id !== undefined) {
            getTimeOffRequest(id)
            getTimeOffRequestStep(id)
            setSelectedData({ 'resourceId': id, 'requestDate': requestDate, 'timeOfffTypeName': timeOfffTypeName, 'approveSeqNumber': approveSeqNumber, 'fullName': fullName, 'postId': postId })
            setTimeOffRequestApproveDrawer(true);
        }
    }
    const timeOffDeletelBtn = (id, timeOffTypeName) => {
        setDeleteModalData({ ...deleteModalData, "isVisible": true, "id": id, 'timeOffTypeName': timeOffTypeName })
    }
    const timeOffCancelBtn = (id) => {
        setCancelModalData({ ...cancelModalData, "isVisible": true, "id": id })
    }
    const handleCheckedRows = (k, r) => {
        setselectedRowKeys(k)
        setselectedRows(r)
    }
    const data = searchTimeOffManagerResult.map((row) => {
        return (
            {
                "key": row.resourceId,
                "requestDate": { "text": moment(row.requestDate).format(dateFormatConvert), "id": row.resourceId, "requestDate": row.requestDate, "fullName": row.fullName, "timeOffTypeName": row.timeOffTypeName, "approveSeqNumber": row.approveSeqNumber, "postId": row.id, "stateTypeId": row.stateType?.id },
                "fullName": row.fullName,
                "timeOffTypeName": row.timeOffTypeName,
                "startDate": moment(row.startDate).format(dateFormatConvert),
                "endDate": moment(row.endDate).format(dateFormatConvert),
                "balance": row.unitSetIdx === 1 ? t('TIME_OFF_REQUEST_DAYS', { "day": row.balance }) : t('TIME_OFF_REQUEST_HOURS', { "hour": row.balance }),
                "remianderBalance": row.unitSetIdx === 1 ? t('TIME_OFF_REQUEST_DAYS', { "day": row.remianderBalance }) : t('TIME_OFF_REQUEST_HOURS', { "hour": row.remianderBalance }),
                "stateType": { "statusObj": { "id": row.id, "timeOffTypeName": row.timeOffTypeName, "approveSeqNumber": row.approveSeqNumber, "fullName": row.fullName, "requestDate": row.requestDate }, "id": row.stateType.id, "displayValue": row.stateType.displayValue, "showConfirmButton": row.showConfirmButton, }
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['fullName', 'timeOffTypeName', 'stateType'] : [],
        sortableColumns: ['requestDate', 'fullName', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'remianderBalance', 'stateType'],
        visibilityOptionExcludes: ['requestDate', 'fullName', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'remianderBalance', 'stateType'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "timeoffPerson",
        showVisibilityMenu: false,
        leftActionMenu: leftActionMenu(),
        checkboxConfig: (record) => ({
            disabled: record.stateType.id !== "1"
        })
    })
    const paginationConfig = () => ({
        current: searchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleResetAllfilters = () => {
        let key = tableKey;
        key = key + 1;
        setTableKey(key);
        setIsShowPassives(false);
        setSearchData(initialRequestPersonSearchData);
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : searchData.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const leftActionMenu = () => {
        if (data.length > 0 && selectedRowKeys.length > 0) {
            return (
                <div className="timeoff-table-left-action-buttons">
                    <Can I="timeoff.request.reject">
                        <Button onClick={() => handleTimeOffChangeStatus(null, 'BULK_REJECT')} className="flex-class svg-class white-btn  h-32  margin-r-12">{t('TIME_OFF_REQUEST_REJECT_ALL')}</Button>
                    </Can>
                    <Can I="timeoff.request.approve">
                        <Button onClick={() => handleTimeOffChangeStatus(null, 'BULK_APPROVE')} className="flex-class svg-class primary-btn  h-32">{t('TIME_OFF_REQUEST_CONFIRM_ALL')}</Button>
                    </Can>
                </div>
            )
        } else {
            return null
        }
    }
    const handleShowPassivesToogle = (e) => { }
    return (
        <div className="table-container">
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('TIME_OFF_REQUEST_NAME_SURNAME')}`,
                            dataIndex: 'fullName',
                            key: 'fullName',
                            sorter: true,
                        },
                        {
                            title: `${t('TIME_OFF_REQUEST_TIME_OFF_TYPE')}`,
                            dataIndex: 'timeOffTypeName',
                            key: 'timeOffTypeName',
                            sorter: true,
                        },
                        {
                            title: `${t('TIME_OFF_REQUEST_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === '1') {
                                    return <div className="flex-class w-100" >
                                        <span className='yellow  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                        {state.showConfirmButton &&
                                            <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                                <Button className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleTimeOffChangeStatus(state.statusObj, 'APPROVE', true)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                                <Button className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleTimeOffChangeStatus(state.statusObj, 'REJECT', true)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                            </div>
                                        }
                                    </div>
                                } else if (state.id === '2') {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === '3') {
                                    return <div className='red'>{state.displayValue}</div>
                                } else if (state.id === '4') {
                                    return <div className=''>{state.displayValue}</div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('TIME_OFF_REQUEST_START_DATE')}`,
                            dataIndex: 'startDate',
                            key: 'startDate',
                            sorter: true,
                        },
                        {
                            title: `${t('PARTNER_TIME')}`,
                            dataIndex: 'balance',
                            key: 'balance',
                            sorter: true,
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('TIME_OFF_REQUEST_REQUEST_TIME')}`,
                                dataIndex: 'requestDate',
                                key: 'requestDate',
                                sorter: true,
                                render: obj =>
                                    <div className="imageNameActionColumn">
                                        <Can I="timeoff.request.all.read">
                                            <a onClick={() => showTimeOffRequestApproveDrawer(obj.id, obj.requestDate, obj.timeOffTypeName, obj.approveSeqNumber, obj.fullName, obj.postId)} className="details-text mar-0" >{obj.text}</a>
                                        </Can>
                                        <Cannot I="timeoff.request.all.read">
                                            <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                        </Cannot>
                                        {permissions.some(item => ["timeoff.request.cancel", "timeoff.request.export", "timeoff.request.delete"].includes(item)) &&
                                            <Dropdown
                                                className="action-menu"
                                                overlay={timeoff_dropdown(t, timeOffCancelBtn, timeOffDownload, timeOffDeletelBtn, obj.id, obj.stateTypeId, obj.timeOffTypeName, permissions)}
                                                trigger={['click']}
                                            >
                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                            </Dropdown>
                                        }
                                    </div>
                            }
                        );
                        columns.splice(4, 0,
                            {
                                title: `${t('TIME_OFF_REQUEST_END_DATE')}`,
                                dataIndex: 'endDate',
                                key: 'endDate',
                                sorter: true,
                            }
                        );
                        columns.splice(6, 0,
                            {
                                title: `${t('PARTNER_REMAINING_TIME')}`,
                                dataIndex: 'remianderBalance',
                                key: 'remianderBalance',
                                sorter: true,
                            }
                        )
                    }
                    return <DynamicTable
                        key={tableKey}
                        columns={columns} dataSource={data}
                        loadStatus={isSearchTimeOffManagerLoading}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={false}
                        passiveButton={false}
                        checkboxStatus={true}
                        onRowSelected={(k, r) => handleCheckedRows(k, r)}
                        selectedRowKeys={selectedRowKeys}
                    //  initialFilterData={initialRequestPersonSearchData}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        totalNumberOfRecords: state.timeOff.searchTimeOffManagerResult.totalNumberOfRecords,
        isSearchTimeOffManagerLoading: state.timeOff.isSearchTimeOffManagerLoading,
        searchTimeOffManagerResult: state.timeOff.searchTimeOffManagerResult.results,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        permissions: state.profile.permissions,
        deleteTimeOffRequestResponse: state.timeOff.deleteTimeOffRequestResponse,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        searchTimeOffRequestManager: (searchManagerData) => { dispatch(searchTimeOffRequestManager(searchManagerData)) },
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        timeOffDownload: (props) => { dispatch(timeOffDownload(props)) },
        timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)) },
        deleteTimeOffRequest: (resourceId) => { dispatch(deleteTimeOffRequest(resourceId)) },
        deleteTimeOffRequestReset: () => { dispatch(deleteTimeOffRequestReset()) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        timeOffRequestUpdateReset: () => { dispatch(timeOffRequestUpdateReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffRequestPerson);