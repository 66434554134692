import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Form, Input, ConfigProvider } from 'antd';


import '../../../styles/person.scss';


function CreateOwnerShipAddressInfo(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm()
    const { setCreateObject, createOwnerShipObj } = props;
    const [createOwnerShip, setCreateOwnerShip] = useState({});

    const handleValuesChange = (changedObj, all) => {
        setCreateOwnerShip({ ...createOwnerShip, ...changedObj });
    }
    useEffect(() => {
        setCreateObject(createOwnerShip)
    }, [createOwnerShip]);


    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={true}
            >
                <Form.Item name="Status" className="custom-select" label={t('INVENTORY_STATUS')}>
                    <p className="black f-16">{createOwnerShipObj.statusName}</p>
                </Form.Item>
                {/* <Form.Item name="Accessories" className="custom-select" label={t('PARTNER_ACCESSORIES')}>
                    <DynamicSelectbox
                        isMultiple={true}
                    //   optionList={parameters}
                    //onFocus={() => get('')}
                    />
                </Form.Item> */}
                <Form.Item name="assigmentDescription" className="custom-select" label={t('PARTNER_RETURN_DESCRIPTION')}>
                    <Input.TextArea

                    />
                </Form.Item>
            </Form>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOwnerShipAddressInfo);