import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getMyTasksList, getTaskDescriptionDetail } from "../../../redux/actions/task.actions";

import { Button } from "antd";
import Media from "react-media";
import moment from "moment";
import "moment/locale/tr";

import DynamicTable from "../../dynamicTable/index";
import { dateFormatConvert, useWindowDimensions } from "../../../utils/config";

function MyTasks(props) {
    const { t } = useTranslation();
    const { getMyTaskInitialSearchData, myTaskSearchState, setMyTaskSearchState, myTasksListLoading,
        myTasksList, totalNumberOfRecords, getMyTasksList, openMyTaskDetailDrawer, getTaskDescriptionDetail } = props;
    const isShowPassives = false;
    const { width } = useWindowDimensions();

    const handleSaveGridSettings = (d) => { };

    const handleResetAllfilters = () => {
        setMyTaskSearchState(getMyTaskInitialSearchData);
    }
    const handleTableFilter = (data) => {
        setMyTaskSearchState({
            ...myTaskSearchState, "pagination": {
                ...myTaskSearchState.pagination,
                "pageNumber": 1,
                "pageSize": 10,
            },
            personResourceId: data.personName, taskGroupState: data.stateType
        });
    };
    const showMyTasks = (groupId, personResourceId, ownerShipTypeId) => {
        if (groupId !== undefined && personResourceId !== undefined && ownerShipTypeId !== undefined) {
            getTaskDescriptionDetail(groupId, personResourceId, ownerShipTypeId)
            openMyTaskDetailDrawer();
        }
    };
    useEffect(() => {
        getMyTasksList(myTaskSearchState);
    }, [myTaskSearchState]);
    const data = myTasksList.map((row, index) => {
        return {
            key: index,
            date: row.assignedDate,
            personName: { groupId: row.groupId, personResourceId: row.personResourceId, ownerShipType: row.ownerShipType, text: row.personName },
            taskCount: row.completedCount + "/" + row.taskTotalCount,
            stateType: { id: null, displayValue: row.stateMessage },
            edit: { groupId: row.groupId, personResourceId: row.personResourceId, ownerShipType: row.ownerShipType },
        };
    });
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ["personName", "stateType"] : [],
        sortableColumns: ["personName", "stateType"],
        visibilityOptionExcludes: ["personName", "taskCount", "date", "stateType", "edit"],
        gridSettings: null,
        page: "myTasks",
        leftActionMenu: null,
        showVisibilityMenu: false,
    });
    const paginationConfig = () => ({
        current: myTaskSearchState.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t("PAGE") },
        position: totalNumberOfRecords < 10 ? ["none", "none"] : ["none", "bottomLeft"],
    });

    const renameFieldName = (field) => {
        if (field === "stateType") {
            return "taskGroupState";
        } else {
            return field;
        }
    };
    const handleTableChange = (data) => {
        const paginationObj = { ...myTaskSearchState.pagination, pageNumber: data.paging.current, pageSize: data.paging.pageSize, orderName: renameFieldName(data.sorter.field ? data.sorter.field : myTaskSearchState.pagination.orderBy) };
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === "ascend" ? true : false;
        }
        setMyTaskSearchState({ ...myTaskSearchState, pagination: paginationObj });
    };
    const handleShowPassivesToogle = (e) => { };
    return (
        <div className="table-container">
            <Media query="(min-width: 1000px)">
                {(matches) => {
                    const columns = [
                        {
                            title: `${t("QUANTITY_OF_TASKS")}`,
                            dataIndex: "taskCount",
                            key: "taskCount",
                            sorter: true,
                        },
                        {
                            title: `${t("PARTNER_DATE")}`,
                            dataIndex: "date",
                            key: "date",
                            sorter: true,
                            render: date => {
                                return (date ? moment(date).format(dateFormatConvert) : '')
                            }
                        },
                        {
                            title: `${t("ORGANIZATION_STATU")}`,
                            dataIndex: "stateType",
                            key: "stateType",
                            sorter: true,
                            render: (state) => {
                                if (state.id === "1") {
                                    return <span className="yellow  flex-align-center">{state.displayValue}</span>;
                                } else if (state.id === "2") {
                                    return <span>{state.displayValue}</span>;
                                } else if (state.id === "3") {
                                    return <span className="red">{state.displayValue}</span>;
                                } else if (state.id === "4") {
                                    return <span className="">{state.displayValue}</span>;
                                } else {
                                    return <span>{state?.displayValue}</span>;
                                }
                            },
                        },

                    ];
                    if (matches) {
                        columns.splice(0, 0, {
                            title: `${t("PARTNER_NAME_SURNAME2")}`,
                            dataIndex: "personName",
                            key: "personName",
                            sorter: false,
                            render: (obj) => (
                                <div className="imageNameActionColumn">
                                    <a onClick={() => showMyTasks(obj.groupId, obj.personResourceId, obj.ownerShipType)} className="details-text mar-0">
                                        {obj.text}
                                    </a>

                                </div>
                            ),
                        });
                        columns.splice(1, 0, {
                            title: "",
                            dataIndex: "edit",
                            key: "edit",
                            sorter: true,
                            render: obj => <Button onClick={() => showMyTasks(obj.groupId, obj.personResourceId, obj.ownerShipType)} className="transparent-btn pad-0 svg-class flex-align-center"  >{t('PARTNER_EDIT')}</Button>
                        },);

                    }
                    return (
                        <>
                        <DynamicTable
                            columns={columns}
                            dataSource={data}
                            tableConfig={tableConfig()}
                            paginationConfig={paginationConfig()}
                            onFilter={(data) => handleTableFilter(data)}
                            onTableChange={(e) => handleTableChange(e)}
                            onResetAllFilters={handleResetAllfilters}
                            searchPlaceholder=""
                            onShowPassivesToggle={(e) => handleShowPassivesToogle(e)}
                            isShowPassives={isShowPassives}
                            onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                            searchBox={false}
                            checkboxStatus={false}
                            passiveButton={false}
                            loadStatus={myTasksListLoading}
                        />
                        </>
                        
                    );
                }}
            </Media>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        myTasksListLoading: state.taskReducer.myTasksListLoading,
        myTasksList: state.taskReducer.myTasksList.taskItems,
        totalNumberOfRecords: state.taskReducer.myTasksList.totalNumberOfRecords,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMyTasksList: (searchData) => { dispatch(getMyTasksList(searchData)); },
        getTaskDescriptionDetail: (groupId, personResourceId, ownerShipTypeId) => { dispatch(getTaskDescriptionDetail(groupId, personResourceId, ownerShipTypeId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyTasks);