import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { exportPersonOwnerShip, exportPersonOwnerShipReset } from '../../../redux/actions/ownership.actions';

import { Form, Col, Row, Spin, ConfigProvider, Collapse } from 'antd';
import {
    UilAngleUp, UilAngleDown, UilImport
} from '@iconscout/react-unicons'
import moment from 'moment';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir
import { dateFormatConvert } from '../../../utils/config';

function InventoryDetailsOwnerShip(props) {
    const { t } = useTranslation();
    const [inventoryOwnerShipState, setInventoryOwnerShipState] = useState({});
    const [form] = Form.useForm()
    const { inventoryDetailsInfo, inventoryDetailsOwnerShipList, inventoryDetailsOwnerShipLoading,
        personOwnerShipExportResponse, exportPersonOwnerShipReset, exportPersonOwnerShip } = props;
    const { Panel } = Collapse;


    useEffect(() => {
        setInventoryOwnerShipState(inventoryDetailsInfo);
    }, [form, inventoryDetailsInfo])

    const handleValuesChange = (changedObj, all) => {
        setInventoryOwnerShipState({ ...inventoryOwnerShipState, ...changedObj });
    }
    useEffect(() => {
        if (personOwnerShipExportResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'person_ownership_fail',
                'title': t('SUCCESS'),
                'description': personOwnerShipExportResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            exportPersonOwnerShipReset()
        }
    }, [personOwnerShipExportResponse])

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            {inventoryDetailsOwnerShipLoading ?
                <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                :
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout=""
                    requiredMark={false}
                >
                    <div className="inventory-slider-cart-container">
                        <div className="inventory-slider-cart-row">
                            {inventoryDetailsOwnerShipList.length > 0 ?
                                <Row>
                                    <Col span={8}>
                                        <h1>{t('TIME_OFF_REQUEST_NAME_SURNAME')}</h1>
                                    </Col>
                                    <Col span={8}>
                                        <h1>{t('INVENTORY_ASSIGNMENT_DATE')}</h1>
                                    </Col>
                                    <Col span={8}>
                                        <h1>{t('DEBIT_THROUGH')}</h1>
                                    </Col>

                                </Row>
                                : <h1 className="ownership-not-found-text">{t('NO_RECORD_OWNERSHIP_OF_INVENTORY')} </h1>}

                        </div>
                        {inventoryDetailsOwnerShipList.map((data, index) => {

                            return (
                                <Collapse
                                    expandIconPosition={'right'}
                                    collapsible="icon"
                                    accordion={false}
                                    expandIcon={({ isActive }) => isActive ? <UilAngleUp size="40" /> : <UilAngleDown size="40" />}
                                >
                                    <Panel header={(
                                        <div className="inventory-slider-cart-header" >
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={8}>
                                                        <p className="collapse-text">{data.assigmentPerson?.displayValue}</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p className="collapse-text">{data.assigmentDate ? moment(data.assigmentDate).format(dateFormatConvert) : ''}</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p className="collapse-text">{data.returnDate ? moment(data.returnDate).format(dateFormatConvert) : <div className="w-100 flex-center pr-9">-</div>}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>
                                    )} key={index + '_collapse'} >
                                        <div className="inventory-slider-cart-body ">
                                            {data?.assigmentDescription &&
                                                <div className="w-100">
                                                    <Form.Item label={t('PARTNER_RETURN_DESCRIPTION')}>
                                                        <p className="inventory-slider-cart-description">{data.assigmentDescription}</p>
                                                    </Form.Item>
                                                </div>
                                            }
                                            <Form.Item label={t('EMBEZZLEMENET_DOWNLOAD')} className="inventory-slider-cart-export">
                                                <span onClick={() => exportPersonOwnerShip(data.assigmentPerson?.id)}><UilImport /></span>
                                            </Form.Item>
                                        </div>
                                    </Panel>

                                </Collapse>
                            )
                        })}


                    </div>
                </Form >
            }

        </ConfigProvider >
    )
}



const mapStateToProps = (state) => {
    return {
        inventoryDetailsOwnerShipList: state.inventory.inventoryDetailsOwnerShipList,
        inventoryDetailsOwnerShipLoading: state.inventory.inventoryDetailsOwnerShipLoading,
        personOwnerShipExportResponse: state.ownership.personOwnerShipExportResponse
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        exportPersonOwnerShip: (resourceId) => { dispatch(exportPersonOwnerShip(resourceId)) },
        exportPersonOwnerShipReset: () => { dispatch(exportPersonOwnerShipReset()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailsOwnerShip);