import createNumberMask from "text-mask-addons/dist/createNumberMask";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TwitterIcon, LinkedlnIcon, InstagramIcon, FacebookIcon, WebIcon } from "../features/Icon/Icons";
import { Image } from "antd";

let currentDate = new Date();
const regex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const regexddmmyyyy = new RegExp(
  "^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$",
);
const regexmmddyyyy = new RegExp(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/);
const repaymentOptions = [
  {
    id: 1,
    displayValue: "Taksit",
  },
  {
    id: 2,
    displayValue: "Tek Seferde",
  },
];
const monthOwerRideListTR = [
  {
    text: "Ocak",
    value: "01",
  },
  {
    text: "Şubat",
    value: "02",
  },
  {
    text: "Mart",
    value: "03",
  },
  {
    text: "Nisan",
    value: "04",
  },
  {
    text: "Mayıs",
    value: "05",
  },
  {
    text: "Haziran",
    value: "06",
  },
  {
    text: "Temmuz",
    value: "07",
  },
  {
    text: "Ağustos",
    value: "08",
  },
  {
    text: "Eylül",
    value: "09",
  },
  {
    text: "Ekim",
    value: "10",
  },
  {
    text: "Kasım",
    value: "11",
  },
  {
    text: "Aralık",
    value: "12",
  },
];

const noData = (t) => {
  return (
    <div className="table-empty-container">
      <div className="table-empty-image">
        <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
      </div>
      <div className="table-empty-text">
        <h1> {t("NO_DATA_TEXT")}</h1>
      </div>
    </div>
  );
};
const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: "created",
    VALUE_UPDATED: "updated",
    VALUE_DELETED: "deleted",
    VALUE_UNCHANGED: "unchanged",
    map: function (obj1, obj2, exeptionKey) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw "Invalid argument. Function given, object expected.";
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        const result = this.compareValues(obj1, obj2);
        if (result === "updated" || result === "created") {
          return obj2;
        } else {
          return null;
        }
      }

      var diff = {};

      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        var value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }
        let valx;
        if ((obj1[key]?.length === 0 && value2?.length !== 0) || (exeptionKey && exeptionKey.includes(key))) {
          valx = value2;
        } else {
          valx = this.map(obj1[key], value2);
        }
        if (valx !== null && JSON.stringify(valx) !== "{}" && valx !== undefined) {
          diff[key] = valx;
        }
      }

      return diff;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && moment(moment(value1)).isSame(moment(value2))) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === "[object Function]";
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === "[object Array]";
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === "[object Date]";
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === "[object Object]";
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
})();
const MergeObjects = (obj1, obj2) => {
  for (var i in obj2) {
    try {
      if (obj2[i].constructor === Object) {
        obj1[i] = MergeObjects(obj1[i], obj2[i]);
      } else {
        obj1[i] = obj2[i];
      }
    } catch (e) {
      obj1[i] = obj2[i];
    }
  }
  return obj1;
};

var createInitialList = function (object) {
  return [{ id: object?.id, displayValue: object?.displayValue }];
};

const compareCurrencyObjects = function (object1, object2) {
  if (
    object1.expenseDate === object2.expenseDate &&
    object1.cashedAmount === object2.cashedAmount &&
    object1.cashedCurrency?.id === object2.cashedCurrency?.id &&
    object1.convertedCurrency?.id === object2.convertedCurrency?.id &&
    object1.convertedAmount === object2.convertedAmount &&
    object1.invoiceNo === object2.invoiceNo &&
    object1.receiptInfo === object2.receiptInfo &&
    object1.receipt === object2.receipt
  ) {
    return true;
  } else {
    return false;
  }
};
const compareObjects = function (object1, object2) {
  if (object1 === null && (object2 === "" || object2?.toString() === "")) {
    return true;
  } else if (object1 === null && (object2?.displayValue === "" || object2?.displayValue === null)) {
    return true;
  } else {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }
};
const compareDate = function (date1, date2) {
  const var1 = date1 && date1 !== "Invalid date" ? date1 : "";
  const var2 = date2 && date2 !== "Invalid date" ? date2 : "";
  if (var1 !== "" && var2 !== "") {
    return moment(var1).isSame(moment(var2), "day");
  } else {
    return var1 === var2;
  }
};

const NumberList = function (n, withoutZero) {
  const { t } = useTranslation();

  var childirenForList = [];
  for (var i = 0; i <= n; i++) {
    if (i === 0 && !withoutZero) {
      childirenForList.push({ id: "0", displayValue: t("NONE") });
    } else if (i > 0 && i <= n) {
      childirenForList.push({ id: i, displayValue: i });
    }
  }
  return childirenForList;
};

const phoneExtMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  integerLimit: 5,
});

const timeOffMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  integerLimit: 2,
});

const nationalIdentityNumberMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  integerLimit: 11,
});
const addExtraValue = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 1,
  decimalSymbol: ",",
  allowDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
});
const minusExtraValue = createNumberMask({
  prefix: "-",
  suffix: "",
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 1,
  decimalSymbol: ",",
  allowDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
});
const price = createNumberMask({
  prefix: "",
  suffix: "",
});
const priceDecimalWithDot = createNumberMask({
  prefix: "",
  suffix: "",
  thousandsSeparatorSymbol: ".",
});
const priceDecimalWithDolar = createNumberMask({
  prefix: "",
  suffix: "$",
  thousandsSeparatorSymbol: ".",
});
const priceDecimalWithEuro = createNumberMask({
  prefix: "",
  suffix: "€",
  thousandsSeparatorSymbol: ".",
});
const priceDecimalWithTurkishLira = createNumberMask({
  prefix: "",
  suffix: "₺",
  thousandsSeparatorSymbol: ".",
});
const numberMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
});
const numberMaskWithSeperator = createNumberMask({
  prefix: "",
  suffix: "",
  thousandsSeparatorSymbol: ".",
  includeThousandsSeparator: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  allowDecimal: true,
});

const signedNumberMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 1,
  decimalSymbol: ",",
  allowDecimal: true,
  allowNegative: true,
  allowLeadingZeroes: false,
});

const resetScrollPosition = function (selector) {
  var divs = document.querySelectorAll(selector);
  for (var p = 0; p < divs.length; p++) {
    divs[p].scrollTop = 0;
  }
};
function disabledDate(current) {
  //return current && current < moment().endOf('day');
  return current && current > moment().add(-1, "day");
}
function disabledDynamicDate(current, hireDate) {
  // Disable dates after the hireDate
  if (hireDate) {
    return current && current.isBefore(hireDate, "day");
  }

  return false;
}
function disabledDateWithToday(current) {
  return current && current > moment().add("day");
}
const formatPhoneNumber = (phone) => {
  if (phone) {
    return phone[0] !== "+" ? "" + phone : phone;
  } else {
    return phone;
  }
};
const checkTextIsEmpty = (data) => {
  if (data && data !== "") {
    return data;
  } else {
    return " - ";
  }
};

const checkMomentTextIsEmpty = (data, formater) => {
  if (data && data !== "") {
    return moment(data).format(formater);
  } else {
    return " - ";
  }
};
function getSocialIcon(type, link) {
  switch (type) {
    case "Twitter":
      return (
        <a className="social-item" href={link} target="blank">
          <TwitterIcon />
        </a>
      );
    case "Instagram":
      return (
        <a className="social-item" href={link} target="blank">
          <InstagramIcon />
        </a>
      );
    case "Facebook":
      return (
        <a className="social-item" href={link} target="blank">
          <FacebookIcon />
        </a>
      );
    case "Linkedin":
      return (
        <a className="social-item" href={link} target="blank">
          <LinkedlnIcon />
        </a>
      );
    case "personalPageUrl":
      return (
        <a className="social-item" href={link} target="blank">
          <WebIcon />
        </a>
      );
    default:
      return null;
  }
}
const inputMaskDateValidation = (
  name,
  formatShowStatus,
  formatHideState,
  errorState,
  setErrorState,
  orginalData,
  stateData,
  maskInputFormatter,
) => {
  formatHideState({ ...formatShowStatus, [name]: false });

  const today = moment(moment());
  const inputDate = moment(stateData, maskInputFormatter);
  const result = today.diff(inputDate, "days");
  const resultSecond = today.diff(inputDate, "second");
  if (stateData === "__/__/____") {
    setErrorState({ ...errorState, [name]: false });
  } else if (orginalData !== stateData) {
    if (localStorage.getItem("i18nLang") === "tr-TR" ? regexddmmyyyy.test(stateData) : regexmmddyyyy.test(stateData)) {
      if (result < 0 || resultSecond < 1) {
        setErrorState({ ...errorState, [name]: true });
      } else {
        setErrorState({ ...errorState, [name]: false });
      }
    } else {
      setErrorState({ ...errorState, [name]: true });
    }
  }
};
const inputMaskDateBirthValidation = (
  name,
  formatShowStatus,
  formatHideState,
  errorState,
  setErrorState,
  orginalData,
  stateData,
  maskInputFormatter,
) => {
  formatHideState({ ...formatShowStatus, [name]: false });
  const today = moment(moment());
  const inputDate = moment(stateData, maskInputFormatter);
  const result = today.diff(inputDate, "years");
  const resultSecond = today.diff(inputDate, "second");
  if (stateData === "__/__/____") {
    setErrorState({ ...errorState, [name]: false });
  } else if (orginalData !== stateData) {
    if (localStorage.getItem("i18nLang") === "tr-TR" ? regexddmmyyyy.test(stateData) : regexmmddyyyy.test(stateData)) {
      if (result < 16 || resultSecond < 1) {
        setErrorState({ ...errorState, [name]: true });
      } else {
        setErrorState({ ...errorState, [name]: false });
      }
    } else {
      setErrorState({ ...errorState, [name]: true });
    }
  }
};
const inputMaskDateCustomClass = (orginalData, stateData, errorStatus) => {
  if (errorStatus === false) {
    if (!compareObjects(orginalData, stateData)) {
      return "ant-input is-changed";
    } else {
      return "ant-input";
    }
  } else {
    return "ant-input is-changed is-error";
  }
};
const inputMaskShowFormatter = (name, state, setState) => {
  setState({ ...state, [name]: true });
};
const focusDateInput = (setState, state, formName, formatter) => {
  setState({ ...state, [formName]: formatter });
};
const blurDateInput = (setState, state, formName, formatter) => {
  setState({ ...state, [formName]: formatter });
};
function randomNumberInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function IsJsonString(str) {
  try {
    var json = JSON.parse(str);
    return typeof json === "object";
  } catch (e) {
    return false;
  }
}
function numberWithCommas(x) {
  if (x > 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
const turkishCharacterRegex = (keyword) =>
  keyword
    .replace(/[ıİiI]/g, "[ıİiI]")
    .replace(/[şŞsS]/g, "[şŞsS]")
    .replace(/[çÇcC]/g, "[çÇcC]")
    .replace(/[ğĞgG]/g, "[ğĞgG]")
    .replace(/[öÖoO]/g, "[öÖoO]")
    .replace(/[üÜuU]/g, "[üÜuU]");
const customFilter = (searchText, option) =>
  turkishCharacterRegex(option.title).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase());
const getDynamicTaskMessage = (t, count, totalCount) => {
  switch (true) {
    case count === 0:
      return t("TASK_MESSAGE_1");
    case count + 3 < totalCount && count !== 0:
      return t("TASK_MESSAGE_2", { count: count });
    case count + 2 < totalCount && count !== 0:
      return t("TASK_MESSAGE_2", { count: count });
    case count + 1 < totalCount && count !== 0:
      return t("TASK_MESSAGE_3");
    case count + 1 === totalCount && count !== 0:
      return t("TASK_MESSAGE_4");
    case count === totalCount && count !== 0:
      return t("TASK_MESSAGE_5");
    default:
      return t("TASK_MESSAGE_1");
  }
};
const getDepartmentsTreeViewCount = (dataArray) => {
  let count = 0;
  const countRecursively = (item) => {
    count++;
    if (item.subDepartments && item.subDepartments.length) {
      item.subDepartments.forEach((subItem) => countRecursively(subItem));
    }
  };
  dataArray.forEach((item) => countRecursively(item));
  return count;
};
const departmentExtractResourceIds = (items) => {
  let ids = [];
  items.forEach((item) => {
    ids.push(item.resourceId);
    if (item.subDepartments) {
      ids = ids.concat(departmentExtractResourceIds(item.subDepartments));
    }
  });
  return ids;
};
export {
  regex,
  currentDate,
  repaymentOptions,
  monthOwerRideListTR,
  noData,
  createInitialList,
  compareObjects,
  compareDate,
  NumberList,
  MergeObjects,
  deepDiffMapper,
  phoneExtMask,
  timeOffMask,
  nationalIdentityNumberMask,
  addExtraValue,
  minusExtraValue,
  price,
  priceDecimalWithDot,
  priceDecimalWithTurkishLira,
  priceDecimalWithDolar,
  priceDecimalWithEuro,
  numberMask,
  numberMaskWithSeperator,
  resetScrollPosition,
  disabledDate,
  disabledDateWithToday,
  disabledDynamicDate,
  formatPhoneNumber,
  checkTextIsEmpty,
  checkMomentTextIsEmpty,
  getSocialIcon,
  inputMaskDateValidation,
  inputMaskDateBirthValidation,
  inputMaskDateCustomClass,
  inputMaskShowFormatter,
  focusDateInput,
  blurDateInput,
  compareCurrencyObjects,
  randomNumberInRange,
  IsJsonString,
  numberWithCommas,
  signedNumberMask,
  customFilter,
  turkishCharacterRegex,
  getDynamicTaskMessage,
  getDepartmentsTreeViewCount,
  departmentExtractResourceIds,
};
