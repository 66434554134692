import { Modal, Image, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
const ExportModal = ({ onCancel = null, status = null, handleExportDocument = null }) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="export-modal"
            visible={status}
            footer={null}
            onCancel={() => { onCancel() }}
            closable={false}
            centered={true}
            maskClosable={true}
        >
            <Row>
                <Col span={24}>
                    <div onClick={() => handleExportDocument(0)} className="export-model-item">
                        <Row>
                            <Col span={6}>
                                <Image src={process.env.REACT_APP_URL + 'images/excel.png'} preview={false} />
                            </Col>
                            <Col span={18}>
                                <span>{t('EXPORT_AS_EXCEL')}</span>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={24}>
                    <div onClick={() => handleExportDocument(2)} className="export-model-item">
                        <Row>
                            <Col span={6}>
                                <Image src={process.env.REACT_APP_URL + 'images/pdf.png'} preview={false} />
                            </Col>
                            <Col span={18}>
                                <span>{t('EXPORT_AS_PDF')}</span>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={24}>
                    <div onClick={() => handleExportDocument(1)} className="export-model-item">
                        <Row>
                            <Col span={6}>
                                <Image src={process.env.REACT_APP_URL + 'images/csv.png'} preview={false} />
                            </Col>
                            <Col span={18}>
                                <span>{t('EXPORT_AS_CSV')}</span>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Modal>
    )

}
export default ExportModal