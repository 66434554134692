import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

import {
  Col, Row, Button, Tabs, Form, Input, Switch,
  Drawer, Spin, Upload, Layout
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UilMultiply } from '@iconscout/react-unicons';

import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import { randomNumberInRange } from '../../utils/commonFormFunctions';

const DynamicEducationModal = ({ allPersons, openAuto, keyboard = true, modalVisible = null, modalWidth = null, setStateData = null, isForm = null, handleCancel = null, maskClosable = true }) => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('1')
  const [educationTitle, setEducationTitle] = useState('')
  const [educationDesc, setEducationDesc] = useState('')
  const [educationTeacher, setEducationTeacher] = useState('')
  const [stepList, setStepList] = useState([])
  const [subVideos, setSubVideos] = useState([])
  const [activeEducation, setActiveEducation] = useState(true)
  const [customSuccess, setCustomSuccess] = useState(false)
  const [createdResourceId, setCreatedResourceId] = useState(false)
  const [loader, setLoader] = useState(false)
  const [personData, setPersonData] = useState([])
  const [tableData, setTableData] = useState([]);
  const [fileListImage, setFileListImage] = useState([]);

  const closeAllDrawer = () => {
    handleCancel();
    setCurrentTab('1');
    setEducationTitle('');
    setEducationDesc('');
    setEducationTeacher('');
    setStepList([]);
    setSubVideos([]);
    setActiveEducation(true);
    setPersonData([]);
    setTableData([]);
    setFileListImage([]);
    setCustomSuccess(false);
  }

  const closeAllDrawerAuto = (id) => {
    handleCancel();
    setCurrentTab('1');
    setEducationTitle('');
    setEducationDesc('');
    setEducationTeacher('');
    setStepList([]);
    setSubVideos([]);
    setActiveEducation(true);
    setPersonData([]);
    setTableData([]);
    setFileListImage([]);
    setCustomSuccess(false);
    openAuto(id);
  }
  const switchChange = (checked) => {
    setActiveEducation(checked)
  };
  const sendForm = () => {
    if(!educationDesc){
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('EDUCATION_MAIN_DESCRIPTION_ERROR'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false;
    }else{
      setLoader(true);
      const formData = new FormData();
      const sendData = {
        "isActive": activeEducation,
        "educationName": educationTitle,
        "description": educationDesc,
        "viewPhoto": fileListImage,
        "educatorName": educationTeacher,
        "contents": [],
        "subscribers": []
      }
      tableData.map((item, index) => {
        const data = {
          "key": index,
          "personResourceId": item.id,
          "startDate": item.startedDate,
          "endDate": item.endDate
        }
        sendData.subscribers.push(data)
      })
      stepList.map((item, index) => {
        const section = {
          sectionName: item.name,
          videos: []
        }
        subVideos.map((sub, i) => {
          if (sub.index == index) {
            section.videos.push({
              "videoName": sub.videoName,
              "file": sub.videoList[0].originFileObj,
              "picture": sub.imgList[0].originFileObj,
              "description": sub.videoDesc
            })
          }
        })
        sendData.contents.push(section)
      })
      formData.append('IsActive', sendData.isActive);
      formData.append('EducationName', sendData.educationName);
      formData.append('Description', sendData.description);
      if (fileListImage.length > 0) {
        formData.append('File', fileListImage[0].originFileObj);
      }
      formData.append('EducatorName', sendData.educatorName);
  
      var config = {
        method: 'post',
        url: apiUrl + '/education/form-create',
        headers: { 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' },
        data: formData
      };
      hrApi(config)
        .then(function (response) {
          setLoader(false)
          setCustomSuccess(true)
          setCreatedResourceId(response.data.data.resourceId)
        })
        .catch(function (error) {
          setLoader(false)
          DynamicToastMessage({
            'key': 'error_key',
            'title': t('ERROR'),
            'description': t('ERROR_WAS_ENCOUNTERED'),
            'statusType': 'error',
            'position': 'bottom-right'
          });
        });
    }
  }
 
  useEffect(() => {
    setPersonData([...personData, allPersons])
  }, [allPersons])

  // Egitim resim
  const beforeUploadImage = (file) => {
    const isVideo = file.type.startsWith('image/');
    const isSizeValid = file.size <= 5 * 1024 * 1024;
    if (!isVideo) {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('SHOULD_NOT_EXCEED_IMG'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
    }
    if (!isSizeValid) {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('IMAGE_FILE_MAX_5MB'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
    }

    if (fileListImage.length >= 1) {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ONLY_ONE_FILE_ERROR'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false;
    }

    return false;
  };

  const handleChangeImage = (info) => {
    const updatedFileList = info.fileList.filter((file) =>
      file.type.startsWith('image/')
    );
    setFileListImage(updatedFileList);
  };
  return (
    <Drawer
      key={() => randomNumberInRange(1, 5000000)}
      visible={modalVisible}
      className="delete-confirm-modal"
      onCancel={() => isForm ? handleCancel() : setStateData({ "isVisible": false })}
      maskClosable={maskClosable ? true : false}
      width={modalWidth ? modalWidth : 520}
      keyboard={keyboard}
      closable={false}
      centered
      footer={[]}>
      {
        !customSuccess &&
        <div>
          <div className="table-details">
            <div className="add-global-close-icon">
              <UilMultiply onClick={() => closeAllDrawer()} />
            </div>
            <div className="add-global-draw-title flex-start">
              <h1>{t('CREATE_NEW_EDUCATION')}</h1>
            </div>
          </div>
          <div className="content">
            <div className="table-details">
              <Tabs className=""
                activeKey={currentTab}
                style={{
                  width: '100%'
                }}
              >
                <TabPane tab={t('EDUCATION_INFORMATION')} key="1">
                  <>
                    <div className="w-100 pt-4">
                      <Form layout="vertical">
                        <div className="form-left-body">
                          <div className="mb-8 mt-4">
                            <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }} htmlFor=""><strong>{t('EDUCATION_MAIN_TITLE')} *</strong></label>
                            <Input style={{
                              height: '40px',
                              width: '100%'
                            }} onChange={(e) => setEducationTitle(e.target.value)} value={educationTitle} placeholder={t('ENTER_TRAINING_NAME')} />
                          </div>
                          <div className="d-flex mb-2 mt-2">
                            <span className="pr-2">{t('PARTNER_ACTIVE')} / {t('PARTNER_PASSIVE')}</span>
                            <Switch defaultChecked onChange={switchChange} />
                          </div>
                          <div className="mb-8 mt-4">
                            <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }} htmlFor=""><strong>{t('INSTRUCTOR')}</strong></label>
                            <Input style={{
                              height: '40px',
                              width: '100%'
                            }} placeholder={t('ENTER_INSTRUCTOR_NAME')} onChange={(e) => setEducationTeacher(e.target.value)} value={educationTeacher} />
                          </div>
                          <div className="mb-8 mt-4">
                            <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }} htmlFor=""><strong>{t('DOWNLOAD_EDUCATION_IMAGE')}</strong></label>
                            <span>
                              <Upload multiple={false} beforeUpload={beforeUploadImage} fileList={fileListImage} onChange={handleChangeImage}>
                                <Button icon={<UploadOutlined />}>{t('UPLOAD')}</Button>
                              </Upload>
                            </span>
                          </div>
                          <div className="mb-8 mt-4">
                            <label style={{ marginBottom: '5px', textAlign: 'left', display: 'block', fontSize: "16px", fontWeight: "600", color: "#595959" }}><strong>{t('TRAINING_DESCRIPTION')} *</strong></label>
                            <Input.TextArea style={{
                              height: '90px',
                              width: '100%'
                            }} onChange={(e) => setEducationDesc(e.target.value)} value={educationDesc} placeholder={t('PARTNER_TEXT_DESCRIPTION')} />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </>
                  <div style={{
                    marginLeft: '-24px'
                  }} className="add-user-draw-footer">
                    <Button key="back" className="ant-btn white-btn w-25 h-40" onClick={closeAllDrawer}>
                      {t('PARTNER_CLOSE')}
                    </Button>
                    <div className="add-user-draw-footer-end">
                      <div className="pad-left-24" />
                      {
                        loader &&
                        <>
                          <div>
                            <div style={{
                              marginTop: '10px'
                            }}>
                              <Spin />
                            </div>
                          </div>
                          <Button key="submit" className="ant-btn primary-btn h-40" disabled>
                            {t('SETTINGS_CREATE')}
                          </Button>
                        </>
                      }
                      {
                        !loader &&
                        <Button key="submit" className="ant-btn primary-btn h-40" onClick={() => sendForm()}>
                          {t('SETTINGS_CREATE')}
                        </Button>
                      }
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      }
      {
        customSuccess &&
        <Layout className={"successful-layout"}>
          <Row align="center">
            <Col className={"successful-wrapper middle"}>
              <div className='img-wrapper'>
                <img src={process.env.REACT_APP_URL + "images/successful.gif"} />
              </div>
              <div className={'successful-header-text pad-top-20'}>
                {t('HOMEPAGE_CONGATULATIONS')}.
              </div>
              <div className={'successful-header-helper-text'}>
                {t('CREATED_NEW_EDUCATION_SET')}
              </div>
              <Button onClick={() => closeAllDrawer()} className="w-200 h-40 f-14 font-w-700 white-btn mr-2">{t('RETURN_EDUCATION_LIST')}</Button>
              <Button onClick={() => closeAllDrawerAuto(createdResourceId)} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('EDIT_EDUCATION_DETAIL')}</Button>
            </Col>
          </Row>
        </Layout >
      }
    </Drawer>
  )
}
export default DynamicEducationModal   