import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';

import { Form, Input, Radio, ConfigProvider } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import { compareObjects } from '../../../utils/commonFormFunctions'

function InventoryDetailsPage(props) {
    const [dynamicData, setDynamicData] = useState({});
    const inputRef = useRef([]);

    const [form] = Form.useForm()
    const { inventoryDetailsInfo, setInventoryUpdateObj, dynamicAttributeData, dynamicAttributeList } = props;
    const isAccessoryType = (dynamicAttributeData[79]?.item?.attributeItemId === 79 || dynamicAttributeData[78]?.item?.attributeItemId === 78) ? true : false;

    const findOrginalItem = (item) => {
        const options = item.inventoryAttributeItemValues
        const attributeItemValueId = options.filter(i => i?.selected)[0]?.attributeItemValueId
        const value = options.filter(i => i?.selected)[0]?.value
        const valueObj = { 'attributeItemValueId': attributeItemValueId, 'value': value }
        return valueObj
    }
    const clearDynamicItem = (itemx) => {
        const tempObj = { ...itemx };
        delete tempObj['item']
        return tempObj;
    }
    useEffect(() => {
        setDynamicData(dynamicAttributeData);
    }, [form, dynamicAttributeData, dynamicAttributeList])

    const handleValuesChange = (event, item) => {
        let value = '', attributeItemValueId = null;
        if (item.fieldType === 4) {
            value = event.value;
            attributeItemValueId = event.attributeItemValueId;
        } else if (item.fieldType === 5) {
            const selectedOption = item.inventoryAttributeItemValues.filter(opt => opt.attributeItemValueId === event.target.value)[0];
            value = selectedOption ? selectedOption.value : '';
            attributeItemValueId = event.target.value;
        } else if (item.fieldType === 1) {
            value = event.target.value;
            setTimeout(() => {
                inputRef.current[item.attributeItemId].focus();
            }, 0);
        }

        if (dynamicData[item.attributeItemId]) {

            if (value === '') {
                const temp = { ...dynamicData };
                delete temp[item.attributeItemId]
                setDynamicData(temp)
            } else {

                setDynamicData({
                    ...dynamicData,
                    [item.attributeItemId]: value ? {
                        attributeItemValueId: attributeItemValueId,
                        value: value,
                        item: item,
                    } : null,
                });

            }
        } else {

            setDynamicData({
                ...dynamicData,
                [item.attributeItemId]: value ? {
                    attributeItemValueId: attributeItemValueId,
                    value: value,
                    item: item,
                } : null,
            });

        }

    }

    useEffect(() => {

        setInventoryUpdateObj(dynamicData)

    }, [dynamicData]);

    function GetInputByType(props) {
        const item = props.item;
        if (item.fieldType === 4) {
            return <DynamicSelectbox
                className={!compareObjects(findOrginalItem(item), clearDynamicItem(dynamicData[item?.attributeItemId])) ? 'is-changed' : ''}
                value={dynamicData[item?.attributeItemId]}
                optionList={item.inventoryAttributeItemValues}
                onChange={(e) => handleValuesChange(e, item)}
                fields={{ "id": "attributeItemValueId", "displayValue": "value" }}
                disabled={!inventoryDetailsInfo?.isActive}
            />
        }
        else if (item.fieldType === 1) {
            return <Input
                ref={el => inputRef.current[item?.attributeItemId] = el}
                value={dynamicData[item?.attributeItemId]?.value}
                onChange={(e) => handleValuesChange(e, item)}
                className={!compareObjects(item.defaultValue, dynamicData[item?.attributeItemId]?.value) ? 'is-changed' : ''}
                disabled={!inventoryDetailsInfo?.isActive}
            />
        }
        else if (item.fieldType === 5) {

            const options = item.inventoryAttributeItemValues
            const attributeItemValueId = options.filter(i => i.selected)[0]?.attributeItemValueId
            return <Radio.Group onChange={(e) => handleValuesChange(e, item)} value={dynamicData[item.attributeItemId]?.attributeItemValueId} defaultValue={attributeItemValueId}>
                {
                    item.inventoryAttributeItemValues.map(option => {
                        return <Radio
                            className={!compareObjects(findOrginalItem(item), clearDynamicItem(dynamicData[item.attributeItemId])) ? 'is-changed-radio' : ''}
                            value={option.attributeItemValueId}
                            disabled={!inventoryDetailsInfo?.isActive}
                        >{option.value}</Radio>
                    })
                }
            </Radio.Group>
        }
        else {
            return 'type not found';
        }
    }

    const mapFormItem = (item) => {
        if (isAccessoryType) {
            return (
                <Form.Item key={item.attributeId} className="custom-select" name={item.attributeId} label={item.name + ' *'}>
                    <GetInputByType item={item} />
                </Form.Item>
            );
        } else {
            return (
                <Form.Item key={item.attributeId} className="custom-select" name={item.attributeId} label={item.name}>
                    <GetInputByType item={item} />
                </Form.Item>
            );
        }
    };
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form layout="vertical pad-0">
                <div className="w-100 department-scrollable pad-right-16">
                    {
                        dynamicAttributeList && dynamicAttributeList.map(mapFormItem)
                    }
                </div>
            </Form>
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {

    };
}

const mapDispatchToProps = (dispatch) => {
    return {


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailsPage);