import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDataset } from "../../../redux/actions/dataset-action";
import { getCountry, getProvinces } from '../../../redux/actions/address.actions.js';

import { Row, Col, Form, ConfigProvider, Input, DatePicker, List, Button } from 'antd';

import moment from 'moment';

import { localeLang, dateFormatList, dynamicDateInputFormatter } from "../../../utils/config";
import { focusDateInput, blurDateInput, numberMaskWithSeperator } from "../../../utils/commonFormFunctions";

import MaskedInput from 'react-text-mask';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicTable from "../../dynamicTable";

import locale from "antd/es/date-picker/locale/en_US";

import '../../../styles/person.scss';


function TravelEditTab(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [travelState, setTravelState] = useState({
    });

    const [countryList, setCountryList] = useState([]);
    const [travelAmountList, setTravelAmountList] = useState([]);
    const [expenseGroupList, setExpenseGroupList] = useState([]);

    const [personAddList, setPersonAddList] = useState([]);
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "departureDate": dateFormatList,
        "returnDate": dateFormatList
    })

    const { setCreateObject, getDataset, getCountry, countries, expenseTypes,
        travelDetail } = props;


    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];

        setTravelState({ ...travelState, ...changedObj });
    }


    useEffect(() => {
        const countryMap = countries.map((data) => {
            return {
                'id': parseInt(data.id),
                'displayValue': data.displayValue
            }
        })
        setCountryList(countryMap);
    }, [countries]);

    useEffect(() => {
        getDataset(28);
    }, [])
    useEffect(() => {
        getCountry();
    }, [])
    useEffect(() => {
        setExpenseGroupList(
            expenseTypes.map((data) => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                }
            })
        );
    }, [expenseTypes])

    const focusExpenseTypes = () => {
        var selectData = travelAmountList.map((data) => { return parseInt(data.expenseGroup?.id) });
        const findSelectData = expenseGroupList.filter(filterData => selectData.includes(parseInt(filterData.id)));
        const findSelectDataID = findSelectData.map((data) => data.id);
        var newExpenseGroupData = expenseGroupList.map((data) => {
            return {
                'id': data.id,
                'displayValue': data.displayValue,
                'disabled': findSelectDataID.includes(data.id)
            }
        });
        setExpenseGroupList(newExpenseGroupData)
    }
    useEffect(() => {
        var total = 0;
        let travelObj = travelAmountList.map((data) => {
            total += parseFloat(data?.amount);
            return {
                'Amount': data?.amount,
                'expenseTypeIdx': data?.expenseGroup?.id && parseInt(data?.expenseGroup?.id)
            }
        })
        setTravelState({ ...travelState, estimatedAmounts: travelObj, estimatedTotal: parseFloat(total) })
        focusExpenseTypes();
    }, [travelAmountList])
    useEffect(() => {
        setTravelState({ ...travelState, traveledPersonsModels: personAddList })
    }, [personAddList])

    useEffect(() => {
        var stateData = {};
        let total = 0;
        var personModelList = [];
        var estimatedAmountList = [];


        if (travelDetail) {
            stateData = {
                'province': travelDetail?.cityName,
                'country': { 'id': travelDetail?.countryIdx, 'displayValue': travelDetail?.countryName },
                'estimatedTotal': travelDetail?.estimatedTotal,
                'notes': travelDetail?.notes,
                'returnDate': travelDetail?.returnDate,
                'travelDescription': travelDetail?.travelDescription,
            }
            if (travelDetail?.departureDate) {
                stateData["departureDate"] = moment(travelDetail.departureDate)
            }
            if (travelDetail?.returnDate) {
                stateData["returnDate"] = moment(travelDetail.returnDate)

            }
            if (travelDetail.estimatedAmounts.length > 0) {
                travelDetail.estimatedAmounts.map((data) => {
                    total += data.amount
                    return estimatedAmountList.push({
                        "expenseGroup": { 'id': data.expenseTypeIdx, 'displayValue': data.expenseTypeName },
                        "amount": data.amount,
                        'amountXwrite': parseFloat(data.amount).toLocaleString('tr-TR'),
                    });
                });
                setTravelAmountList(estimatedAmountList);
            }
            if (travelDetail.traveledPersonsModels.length > 0) {
                travelDetail.traveledPersonsModels.map((data) => {

                    return personModelList.push({
                        "name": data.name,
                        "companyName": data.companyName,
                    });
                });
                setPersonAddList(personModelList);
            }
            form.setFieldsValue(stateData);
            setTravelState({
                ...stateData,
                'departureDate': travelDetail?.departureDate,
                'returnDate': travelDetail?.returnDate,
                'estimatedTotal': parseFloat(total),
                'estimatedAmounts': estimatedAmountList,
                'traveledPersonsModels': personModelList
            });

        }
    }, [travelDetail])

    const handleExpenseGroupList = (e, index, key, inputType) => {
        const list = [...travelAmountList];
        switch (inputType) {
            case 'select':
                list[index][key] = { 'id': e.id, 'displayValue': e.displayValue };

                return setTravelAmountList(list);

            case 'price':
                const val = e.target.value;
                var replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                var parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                list[index][key + "Xwrite"] = val;
                return setTravelAmountList(list);
            default:
                return null;
        }
    }
    function handleAddNewExpense() {
        const newRowList = [...travelAmountList];
        let requiredFields = {
            'expenseGroup': false,
            'amount': false,
        }
        let hasError = false
        let fields = []

        newRowList.map((data) => {
            if (!data.expenseGroup) {
                requiredFields.expenseGroup = true;
            }
            if ((!data.amount || data.amount === "0")) {
                requiredFields.amount = true;
            }
        });
        if (requiredFields.expenseGroup) {
            hasError = true
            fields.push(t('ESTIMATED_BUDGET'));
        }
        if (requiredFields.amount) {
            hasError = true;
            fields.push(t('PARTNER_AMOUNT2'));
        }
        newRowList.push(
            {
                'expenseGroup': null,
                'amount': null
            }
        );
        if (!hasError) {
            setTravelAmountList(newRowList);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }

    }
    const handleRemoveClick = index => {
        const list = [...travelAmountList];
        list.splice(index, 1);
        setTravelAmountList(list);

    };

    const handleAddPersonList = (index) => {
        const list = [...personAddList];
        list.splice(index, 1);
        setPersonAddList(list);
    };
    const handleAddPersonRow = () => {

        var requiredFields = {
            "name": false,
            "companyName": false
        }
        let hasError = false
        let fields = []
        var list = [];

        list = [...personAddList];


        list.map((data) => {
            if (!data.name) {
                requiredFields.name = true;
            }
            if (!data.companyName) {
                requiredFields.companyName = true;
            }
        });
        if (requiredFields.name) {
            hasError = true
            fields.push(t('PARTNER_NAME_SURNAME'));
        }
        if (requiredFields.companyName) {
            hasError = true;
            fields.push(t('ORGANIZATION_COMPANY_NAME'));
        }

        if (!hasError) {

            list.push({
                "name": null,
                "companyName": null
            })
            requiredFields = {
                "name": false,
                "companyName": false
            }
            setPersonAddList(list);


        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };
    const handleAddPersonListInputChange = (e, index, key, inputType) => {
        const list = [...personAddList];
        switch (inputType) {
            case 'text':
                list[index][key] = e.target.value;
                return setPersonAddList(list);
            default:
                return null;
        }
    }
    const disabledEndDate = (current) => {
        return current < moment(travelDetail?.departureDatePicker)
    }
    useEffect(() => {
        setCreateObject(travelState)
    }, [travelState]);
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical travel-scrollable travel-container"
                requiredMark={true}
            >
                <Row>
                    <Col span={12}>
                        <div className="travel-drawer-left">
                            <Form.Item name="departureDate" label={t('DEPARTURE_DATE')}>
                                <DatePicker
                                    locale={localeLang}
                                    format={datePickerFormatType.departureDate}
                                    placeholder={t('PLACEHOLDER_DATE')}
                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'departureDate', dynamicDateInputFormatter)}
                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'departureDate', dateFormatList)}
                                />
                            </Form.Item>
                            <Form.Item name="returnDate" label={t('DATE_OF_RETURN')}>
                                <DatePicker
                                    locale={localeLang}
                                    format={datePickerFormatType.returnDate}
                                    placeholder={t('PLACEHOLDER_DATE')}
                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'returnDate', dynamicDateInputFormatter)}
                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'returnDate', dateFormatList)}
                                    disabledDate={disabledEndDate}
                                    disabled={!travelDetail?.departureDate}
                                />
                            </Form.Item>
                            <Form.Item name="country" className="custom-select" label={t('ORGANIZATION_COUNTRY')}>
                                <DynamicSelectbox
                                    optionList={countryList}
                                    onFocus={() => getCountry()}
                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                ></DynamicSelectbox>
                            </Form.Item>
                            <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                <Input disabled={!travelDetail?.countryIdx}  ></Input>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="travel-drawer-right">
                            <>
                                <Row>
                                    <Col className="pad-12" span={24}>
                                        <Row>
                                            <Col span={8} >
                                                <h3 className="black f-16 fw-600">{t('ESTIMATED_BUDGET') + ' *'}</h3>
                                            </Col>

                                        </Row>
                                        {
                                            travelAmountList.map((item, index) => {
                                                return (
                                                    <Row key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": '30px' }} >
                                                        <Col span={10} >
                                                            <div className="custom-select">
                                                                <DynamicSelectbox className="w-100" style={{ marginTop: '3px' }}
                                                                    value={item.expenseGroup}
                                                                    optionList={expenseGroupList}
                                                                    onFocus={() => focusExpenseTypes()}
                                                                    onChange={(e) => handleExpenseGroupList(e, index, 'expenseGroup', 'select')}
                                                                    placeholder={t('PARTNER_SELECT')}
                                                                />
                                                            </div>


                                                        </Col>
                                                        <Col span={10} offset={1}>
                                                            <MaskedInput
                                                                className="ant-input"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                mask={numberMaskWithSeperator}
                                                                value={item.amountXwrite}
                                                                onChange={(e) => handleExpenseGroupList(e, index, 'amount', 'price')}
                                                            />
                                                        </Col>
                                                        {index > 0 &&
                                                            <Col offset={1} span={2}>
                                                                <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                )
                                            })
                                        }
                                        <div className="w-100 ">
                                            <Row>
                                                <Col span={11}>
                                                    <a
                                                        className="transparent-btn f-16 pad-0"
                                                        onClick={handleAddNewExpense}
                                                        style={{
                                                            marginBottom: 16,
                                                        }}
                                                    >
                                                        {t('INVENTORY_ADD_TEXT')}
                                                    </a>
                                                </Col>
                                                <Col span={10}>
                                                    <div className="black f-16 fw-600"> {travelState?.estimatedTotal > 0 && parseFloat(travelState?.estimatedTotal).toLocaleString('tr-TR') + ' TL'}</div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>

                                </Row>
                            </>
                            <br />
                            <Form.Item name="notes" className="custom-select" label={t('NOTES')}>
                                <Input.TextArea />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className="pad-top-40 expense-drawer">
                    <div className="table-container  table-hide-all-buttons blue-table">
                        <div className="w-100 flex-end">
                            <Button
                                onClick={() => handleAddPersonRow()}
                                className="primary-btn"
                                style={{
                                    marginBottom: 5,
                                }}
                            >
                                {t('INVENTORY_ADD_TEXT')}
                            </Button>
                        </div>
                        <div className="person-detail-list">
                            <div className="row">
                                <label className="name m-r-10">  {t('PARTNER_NAME_SURNAME')} </label>
                                <label className="companyName m-r-10">  {t('PARTNER_COMPANY_NAME')} </label>
                            </div>
                            {
                                personAddList.map((item, index) => {

                                    return (
                                        <div className="editable" key={index} >
                                            <Input
                                                className="name m-r-10"
                                                value={item.name}
                                                onChange={(e) => handleAddPersonListInputChange(e, index, 'name', 'text')}
                                            />
                                            <Input className="companyName m-r-10"
                                                value={item.companyName}
                                                onChange={(e) => handleAddPersonListInputChange(e, index, 'companyName', 'text')}
                                            />

                                            <label className="delete" onClick={() => handleAddPersonList(index)}>
                                                {t('PARTNER_DELETE')}
                                            </label>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>
                </div>
            </Form >

        </ConfigProvider >
    )
}

const mapStateToProps = (state) => {
    return {
        countries: state.addressReducer.countries,
        provinces: state.addressReducer.provinces,
        expenseTypes: state.dataset.expenseTypes,
        personTravel: state.travel.personTravel,
        personTravelLoading: state.travel.personTravelLoading,
        profile: state.profile,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (type) => { dispatch(getDataset(type)) },
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelEditTab);