import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Form, Input, Col, Row, ConfigProvider } from 'antd';
import PhoneInput from 'react-phone-input-2';
import MaskedInput from 'react-text-mask'
import { phoneInputConfig } from '../../../utils/config';

import DynamicSelectbox from "../../dynamicSelectbox/index";
import { getDataset } from '../../../redux/actions/dataset-action';
import { getParentOrganizations } from '../../../redux/actions/organization.actions';
import { phoneExtMask } from '../../../utils/commonFormFunctions'

import '../../../styles/person.scss';


function AddOrganizationBasicInfo(props) {

    const { t } = useTranslation();
    const [organizationAddBasicInfoState, setOrganizationAddBasicInfoState] = useState();
    const [form] = Form.useForm()
    const { dataset, getDataset, getParentOrganizations, organization, setCreateObject, profile } = props;

    const [organizationTypeList, setOrganizationTypeList] = useState([]);
    const [sectorList, setSectorList] = useState([]);
    const [parentOrganizationList, setParentOrganizationList] = useState([]);
    const handleValuesChange = (neu, all) => {
        if (Object.keys(neu)[0] === 'OrganizationType') {
            setOrganizationAddBasicInfoState({ ...organizationAddBasicInfoState, OrganizationType: neu["OrganizationType"].id });
        }
        //  else if (Object.keys(neu)[0] === 'ParentOrganizationResourceId') {
        //     setOrganizationAddBasicInfoState({ ...organizationAddBasicInfoState, ParentOrganizationResourceId: neu["ParentOrganizationResourceId"].id })
        // }
        else if (Object.keys(neu)[0] === 'SectorId') {
            setOrganizationAddBasicInfoState({ ...organizationAddBasicInfoState, SectorId: neu["SectorId"].id })
        } else {
            setOrganizationAddBasicInfoState({ ...organizationAddBasicInfoState, ...neu });
        }
    }
    useEffect(() => {
        setCreateObject(organizationAddBasicInfoState)
    }, [organizationAddBasicInfoState]);
    useEffect(() => {
        setOrganizationTypeList(dataset.organizationTypes);
    }, [dataset.organizationTypes]);
    useEffect(() => {
        setSectorList(dataset.sectorTypes);
    }, [dataset.sectorTypes]);
    // useEffect(() => {
    //     setParentOrganizationList(organization.parentOrganizations);
    // }, [organization.parentOrganizations]);
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={true}
            >


                <Form.Item className="custom-select" name="OrganizationType" label={t('ORGANIZATION_ORGANIZATION_TYPE') + "*"} required>
                    <DynamicSelectbox
                        optionList={organizationTypeList}
                        onFocus={() => getDataset(19)}
                        placeholder={t('PARTNER_SELECT')}

                    />
                </Form.Item>
                <Form.Item className="add-user-draw-required-input" name="Name" label={t('ORGANIZATION_NAME') + "*"} required>
                    <Input />
                </Form.Item>
                <Form.Item name="LegalName" label={t('ORGANIZATION_LEGAL_NAME') + "*"} required>
                    <Input />
                </Form.Item>
                {/* <Form.Item name="ParentOrganizationResourceId" className="custom-select" label={t('ORGANIZATION_PARENT_ORGANIZATION') + "*"}>
                    <DynamicSelectbox
                        optionList={parentOrganizationList}
                        onFocus={() => getParentOrganizations(profile.organization?.resourceId)}
                        placeholder={t('PARTNER_SELECT')}

                    />
                </Form.Item> */}
                <Form.Item name="SectorId" className="custom-select" label={t('ORGANIZATION_SECTOR')}>
                    <DynamicSelectbox
                        optionList={sectorList}
                        onFocus={() => getDataset(21)}
                        placeholder={t('PARTNER_SELECT')}

                    />
                </Form.Item>
                <Row>
                    <Col span={11} >
                        <Form.Item className="add-user-draw-phone-input" name="Telephone" label={t('ORGANIZATION_PHONE')}>
                            <PhoneInput
                                {...phoneInputConfig}
                                placeholder={""}
                            />
                        </Form.Item>
                    </Col>
                    <Col offset={1} span={4}>
                        <Form.Item className="add-user-draw-phone-ext-input" name="PhoneExt" label={t('ORGANIZATION_PHONE_EXT')}>
                            <MaskedInput className="ant-input" mask={phoneExtMask} />

                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="PageUrl" label={t('ORGANIZATION_WEB')}>
                    <Input />
                </Form.Item>
                {/* <div className="add-user-draw-add-image">
                    <Upload maxCount={1} {...uploadImgConfig} ><Image src="/images/pp-undefined.png" preview={false} /><span>Organizasyon Logosu Ekle</span></Upload>
                </div> */}
            </Form>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
        organization: state.organization,
        profile: state.profile


    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (props) => { dispatch(getDataset(props)) },
        getParentOrganizations: (resourceId) => { dispatch(getParentOrganizations(resourceId)) },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganizationBasicInfo);