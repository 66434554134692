import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { UilArrowCircleRight,UilExclamationOctagon } from "@iconscout/react-unicons";

import Can from '../authorization/index';
import Cannot from '../authorization/cannot';

import "../styles/global.scss";
import "../styles/subscription-warning-bar.scss";

function SubscriptionPaymentWarningBar(props) {
    const { t } = useTranslation();
    const { accountExpireDate } = props;

    const [remainingDay, setRemainingDay] = useState()

    useEffect(() => {

        const trialExpireDate = new Date(accountExpireDate);
        const today = new Date();
        const diffDate = trialExpireDate - today;
        const remainingDay = Math.ceil(diffDate / 86400000);

        setRemainingDay(remainingDay)
    }, [accountExpireDate])


    return (
        <div className="subscription-payment-warning">
            <Can I="payment">
                <div className="d-flex align-items-center">
                    <UilExclamationOctagon />
                    <div className="subscription-payment-warning-text-block">
                        <div className="ml-3">
                            <span className="f-w-400">{t("SUBSCRIPTION_PAYMENT_ERROR_1")}</span> 
                            <span>{t('SUBSCRIPTION_PAYMENT_ERROR_2',{"day":remainingDay})} <br className="subsciption-d-none-mobile-block"/></span>
                            <span className="f-w-400">{t("SUBSCRIPTION_PAYMENT_ERROR_3")}</span> 
                        </div>
                      
                        <Link to="/subscription" className="go-warning-prices-btn ml-3"> {t('GO_TO_SUBSCRIPTION_PAGE')} <UilArrowCircleRight /></Link>
                    </div>
                </div>
                
            </Can>
            <Cannot I="payment">
                {t('TRIAL_EXPIRED_UNAUTHORIZED_WARNING',{"remainingDay":remainingDay})}
            </Cannot>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        accountExpireDate: state.auth?.user?.profile?.organizationEntryEndDate
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
    

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPaymentWarningBar);