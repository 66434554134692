import { OwnerShip } from "../actions/ownership.actions";

const initialState = {
    ownershipCreateResponse:{"status":"loading","message":""},
    ownershipDetailsLoading:false,
    ownershipDetails:{
        "inventoryType": {
            "id": null,
            "displayValue": null
        },
        "inventory": {
            "id": null,
            "displayValue": null
        },
        "assigmentPerson": {
            "id": null,
            "displayValue": null
        },
        "assigmentDate": null,
        "status": {
            "id": null,
            "displayValue": null
        },
        "assigmentDocumentId": null,
        "assigmentDescription": null,
        "returnDate": null,
        "returnDescription": null,
        "isMaintanenceNeeded": null,
        "accessoryInventories": [
            {
                "id": null,
                "displayValue": null
            }
        ]
    },
    deleteOwnerShipResponse:{"status":"loading","message":""},
    ownerShipSearchResult:{
        totalNumberOfRecords:0,
        results:[]
    },
    isOwnerShipSearchLoading:false,
    returnOwnerShipResponse:{"status":"loading","message":""},
    ownerShipExportResponse: {"status":"loading", "message":""},
    personOwnerShipExportResponse: {"status":"loading", "message":""},
    ownerShipUpdateResponse: { "status": "loading", "message": "" },
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case OwnerShip.CREATE_OWNERSHIP_SUCCESS:
            return {
                ...state,
                ownershipCreateResponse: {
                   "status":"SUCCESS",
                   "message":action.payload
                }

            }
        case OwnerShip.CREATE_OWNERSHIP_FAIL:
            return {
                ...state,
                ownershipCreateResponse: {
                   "status":"FAIL",
                   "message":action.payload
                }

            }
        case OwnerShip.CREATE_OWNERSHIP_INPUT_ERROR:
            return {
                ...state,
                ownershipCreateResponse: {
                   "status":"VALIDATION_ERROR",
                   "message":action.payload
                }

            }
        case OwnerShip.CREATE_OWNERSHIP_RESET:
            return{
                ...state,
                ownershipCreateResponse: {
                    "status":"loading",
                    "message":null
                 }
            }
        case OwnerShip.GET_OWNERSHIP_DETAILS_REQUEST:
            return {
                ...state,
                ownershipDetailsLoading: true
            };
        case OwnerShip.GET_OWNERSHIP_DETAILS_SUCCESS:
            return {
                ...state,
                ownershipDetails: action.payload,
                ownershipDetailsLoading: false
            }
        case OwnerShip.GET_OWNERSHIP_DETAILS_FAIL:
            return {
                ...state,
                ownershipDetailsLoading: false
            }
        case OwnerShip.GET_OWNERSHIP_DETAILS_RESET:
            return {
                ...state,
                ownershipDetails: {
                    "inventoryType": {
                        "id": null,
                        "displayValue": null
                    },
                    "inventory": {
                        "id": null,
                        "displayValue": null
                    },
                    "assigmentPerson": {
                        "id": null,
                        "displayValue": null
                    },
                    "assigmentDate": null,
                    "status": {
                        "id": null,
                        "displayValue": null
                    },
                    "assigmentDocumentId": null,
                    "assigmentDescription": null,
                    "returnDate": null,
                    "returnDescription": null,
                    "isMaintanenceNeeded": null,
                    "accessoryInventories": [
                        {
                            "id": null,
                            "displayValue": null
                        }
                    ]
                },
                ownershipDetailsLoading: true
            }
        case OwnerShip.DELETE_OWNERSHIP_SUCCESS:
            return {
                ...state,
                deleteOwnerShipResponse: {
                   "status":"SUCCESS",
                   "message":action.payload
                },
                
            }
        case OwnerShip.DELETE_OWNERSHIP_FAIL:
            return {
                ...state,
                deleteOwnerShipResponse: {
                   "status":"FAIL",
                   "message":action.payload
                }
            }
        case OwnerShip.DELETE_OWNERSHIP_RESET:
            return{
                ...state,
                deleteOwnerShipResponse: {
                    "status":"loading",
                    "message":null
                 }
            } 
        case OwnerShip.OWNERSHIP_SEARCH_REQUEST:
            return {
                ...state,
                isOwnerShipSearchLoading: true
            };
        case OwnerShip.OWNERSHIP_SEARCH_SUCCESS:
            return {
                ...state,
                ownerShipSearchResult: action.payload,
                isOwnerShipSearchLoading: false
            }
        case OwnerShip.OWNERSHIP_SEARCH_FAIL:
            return {
                ...state,
                isOwnerShipSearchLoading: false
            }
        case OwnerShip.OWNERSHIP_POST_RETURN_SUCCESS:
            return {
                ...state,
                returnOwnerShipResponse: {
                    "status":"SUCCESS",
                    "message":action.payload
                 },
            }
        case OwnerShip.OWNERSHIP_POST_RETURN_FAIL:
            return {
                ...state,
                returnOwnerShipResponse: {
                    "status":"FAIL",
                    "message":action.payload
                 }
            }
        case OwnerShip.DELETE_OWNERSHIP_RETURN_RESET:
            return{
                ...state,
                returnOwnerShipResponse: {
                    "status":"loading",
                    "message":null
                 }
            } 
            
        case OwnerShip.EXPORT_OWNERSHIP_FAIL:
            return {
                ...state,
                ownerShipExportResponse: {
                    "status":"FAIL",
                    "message":action.payload
                 }
            }
        case OwnerShip.EXPORT_OWNERSHIP_RESET:
            return{
                ...state,
                ownerShipExportResponse: {
                    "status":"loading",
                    "message":null
                 }
            }   
        case OwnerShip.EXPORT_PERSON_OWNERSHIP_FAIL:
            return {
                ...state,
                personOwnerShipExportResponse: {
                    "status":"FAIL",
                    "message":action.payload
                 }
            }
        case OwnerShip.EXPORT_PERSON_OWNERSHIP_RESET:
            return{
                ...state,
                personOwnerShipExportResponse: {
                    "status":"loading",
                    "message":null
                 }
            }
        case OwnerShip.UPDATE_OWNERSHIP_SUCCESS:
            return {
                ...state,
                ownerShipUpdateResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case OwnerShip.UPDATE_OWNERSHIP_FAIL:
            return {
                ...state,
                ownerShipUpdateResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case OwnerShip.UPDATE_OWNERSHIP_RESET:
            return {
                ...state,
                ownerShipUpdateResponse: {
                    "status": "loading",
                    "message": null
                }
            }       
        default: return state

            }
        }