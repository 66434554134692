import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Input, ConfigProvider, Spin } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";

import { getPersons, getWorkHourDetailsByDefinitions } from '../../../redux/actions/organization.actions';
import { getParentDepartmentList } from '../../../redux/actions/organization-select.action';
import '../../../styles/person.scss';


function CreateDepartmentBasicInfo(props) {
    const { t } = useTranslation();
    const [departmentAddBasicInfoState, setDepartmentAddBasicInfoState] = useState({ 'DepartmentManagerResourceId': '', 'Floor': '' });
    const [form] = Form.useForm()
    const { organizationSelectReducer, getParentDepartmentList, setCreateObject, getPersons, persons, profile, getWorkHourDetailsByDefinitions, workHourDetailsByDefinitions, personWorkHours, personWorkHoursLoading } = props;
    const OrganizationResourceId = profile.organization?.resourceId;
    const [parentDepartmentList, setParentDepartmentList] = useState([]);
    const [workHourList, setWorkHourList] = useState([]);
    const [DepartmentManagerResourceIdList, setDepartmentManagerResourceIdList] = useState([]);
    
    const handleValuesChange = (neu, all) => {
        if (Object.keys(neu)[0] === 'ParentDepartmentResourceId') {
            setDepartmentAddBasicInfoState({ ...departmentAddBasicInfoState, ParentDepartmentResourceId: neu["ParentDepartmentResourceId"].id })
        } else if (Object.keys(neu)[0] === 'DepartmentManagerResourceId') {
            setDepartmentAddBasicInfoState({ ...departmentAddBasicInfoState, DepartmentManagerResourceId: neu["DepartmentManagerResourceId"].id })
        } else if (Object.keys(neu)[0] === 'OrganizationWorkDefinitionIdx') {
            setDepartmentAddBasicInfoState({ ...departmentAddBasicInfoState, OrganizationWorkDefinitionIdx: neu["OrganizationWorkDefinitionIdx"].id })
        } else {
            setDepartmentAddBasicInfoState({ ...departmentAddBasicInfoState, ...neu });
        }
    }

    useEffect(() => {
        setCreateObject(departmentAddBasicInfoState)
    }, [departmentAddBasicInfoState]);

    useEffect(() => {
        setParentDepartmentList(organizationSelectReducer.parentDepartment);
    }, [organizationSelectReducer.parentDepartment]);

    useEffect(() => {
        setDepartmentManagerResourceIdList(persons);
    }, [persons]);

    useEffect(() => {
        if (personWorkHours.id !== null) {
            setDepartmentAddBasicInfoState({
                ...departmentAddBasicInfoState, 'OrganizationWorkDefinitionIdx': parseInt(personWorkHours.id),
            })
        }
    }, [personWorkHours])

    useEffect(() => {
        if (personWorkHours.id !== null) {
            form.setFieldsValue({
                OrganizationWorkDefinitionIdx: {
                    'id': parseInt(personWorkHours?.id),
                    'displayValue': personWorkHours?.displayValue
                }
            })
        }
    }, [form, personWorkHours])

    useEffect(() => {
        var tempData = workHourDetailsByDefinitions.definitions.map((data) => {
            return {
                'id': data.definitionId,
                'displayValue': data.name
            }
        })
        setWorkHourList(tempData)
    }, [workHourDetailsByDefinitions])

    useEffect(() => {
        setParentDepartmentList([]);
    },[])

    return (
        personWorkHoursLoading ?
            <Spin className="tab-loading" /> :
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <Form.Item className="custom-select" name="Name" label={t('DEPARTMENT_NAME') + " *"} required>
                        <Input />
                    </Form.Item>
                    <Form.Item name="ParentDepartmentResourceId" className="custom-select" label={t('DEPARTMENT_PARENT_DEPARTMENT')}>
                        <DynamicSelectbox
                            optionList={parentDepartmentList}
                            onFocus={() => getParentDepartmentList(OrganizationResourceId)}
                            disabled={!OrganizationResourceId}
                            placeholder={t('PARTNER_SELECT')}
                        />
                    </Form.Item>
                    <Form.Item name="DepartmentManagerResourceId" className="custom-select" label={t('DEPARTMENT_MANAGER')}>
                        <DynamicSelectbox
                            onFocus={() => getPersons(OrganizationResourceId)}
                            optionList={DepartmentManagerResourceIdList}
                            disabled={!OrganizationResourceId}
                            placeholder={t('PARTNER_SELECT')}
                        />
                    </Form.Item>
                    <Form.Item name="OrganizationWorkDefinitionIdx" className="custom-select" label={t('CHOOSE_WORKING_HOURS')}>
                        <DynamicSelectbox
                            placeholder={t('PARTNER_SELECT')}
                            optionList={workHourList}
                            disallowClear={true}
                            onFocus={() => getWorkHourDetailsByDefinitions()}
                        />
                    </Form.Item>
                </Form>
            </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        organizationSelectReducer: state.organizationSelectReducer,
        persons: state.organization.persons,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions,
        profile: state.profile,
        personWorkHours: state.organization.personWorkHours,
        personWorkHoursLoading: state.organization.personWorkHoursLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getParentDepartmentList: (resourceId) => { dispatch(getParentDepartmentList(resourceId)) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateDepartmentBasicInfo);