import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDataset } from "../../../redux/actions/dataset-action";
import { getCountry, getProvinces } from '../../../redux/actions/address.actions.js';

import { Checkbox, Form, DatePicker, TimePicker, ConfigProvider, Input, Button, List, Spin } from 'antd';

import moment from 'moment';

import { localeLang, hoursFormat, dateFormat } from "../../../utils/config";

import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';

import locale from "antd/es/date-picker/locale/en_US";

import '../../../styles/person.scss';


function TravelHostEditTab(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [travelHostState, setTravelHostState] = useState({
    });
    const [hostDetailsList, setHostDetailsList] = useState([]);
    const [travelRequestDetailsList, setTravelRequestDetailsList] = useState([]);

    const [countryList, setCountryList] = useState([]);
    const [personTravelList, setPersonTravelList] = useState([]);


    const { setCreateObject, getDataset, getCountry, countries, personTravel,
        travelHostDetails, personTravelLoading } = props;
    const transportationlist = [
        {
            "id": 1,
            "displayValue": "Otobüs"
        },
        {
            "id": 2,
            "displayValue": "Tren"
        },
        {
            "id": 3,
            "displayValue": "Uçak"
        }
    ];

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];

        setTravelHostState({ ...travelHostState, ...changedObj });
    }

    useEffect(() => {
        setCreateObject(travelHostState)
    }, [travelHostState]);

    useEffect(() => {
        const countryMap = countries.map((data) => {
            return {
                'id': parseInt(data.id),
                'displayValue': data.displayValue
            }
        })
        setCountryList(countryMap);
    }, [countries]);

    useEffect(() => {
        getDataset(28);
    }, [])
    useEffect(() => {
        getCountry();
    }, [])


    useEffect(() => {
        var arr = [];
        personTravel.length > 0 &&
            personTravel.map((data) =>
                arr.push({

                    'id': data.travelDefinitionId,
                    'displayValue': data.travelDescription
                })
            )
        setPersonTravelList(arr);
    }, [personTravel])



    const handleAddRequestDynamicInputChange = (e, index, key, inputType, table) => {
        var list = [];
        if (table === 'travel') {
            list = [...travelRequestDetailsList];
        } else {
            list = [...hostDetailsList]
        }
        switch (inputType) {
            case 'select':
                list[index][key] = e.id ? { 'id': e.id, 'displayValue': e.displayValue } : { 'id': null, 'displayValue': '' };
                if (key === 'country' && table === 'travel' && !e.id) {
                    list[index]['provinceFrom'] = '';
                    list[index]['provinceTo'] = '';
                } else if (key === 'country' && table === 'accommodation' && !e.id) {
                    list[index]['province'] = '';
                }
                if (table === 'travel') {
                    return setTravelRequestDetailsList(list);
                } else {
                    return setHostDetailsList(list);
                }
            case 'text':
                list[index][key] = e.target.value;
                if (table === 'travel') {
                    return setTravelRequestDetailsList(list);
                } else {
                    return setHostDetailsList(list);
                }
            case 'datePicker':
                list[index][key] = e;
                if (table === 'travel') {
                    return setTravelRequestDetailsList(list);
                } else {
                    return setHostDetailsList(list);
                }
            default:
                return null;
        }
    }


    useEffect(() => {
        setCreateObject({ ...travelHostState, travelRequestDetailsList: travelRequestDetailsList })
    }, [travelRequestDetailsList])

    useEffect(() => {
        setCreateObject({ ...travelHostState, hostDetailsList: hostDetailsList })
    }, [hostDetailsList])


    const dynamicDeleteColumn = (index, table) => {
        var list = [];
        if (table === 'travel') {
            list = [...travelRequestDetailsList];
        } else {
            list = [...hostDetailsList];

        }
        list.splice(index, 1);
        if (table === 'travel') {
            setTravelRequestDetailsList(list);
        } else {
            setHostDetailsList(list)
        }
    };
    const handleAddDynamicRow = (table) => {

        var requiredFields = {}

        if (table === 'travel') {
            requiredFields = {
                "country": false,
                "provinceFrom": false,
                "provinceTo": false,
                "modeOfTransportation": false,
                "date": false,
                "hour": false,
            }
        } else {
            requiredFields = {
                "country": false,
                "province": false,
                "startDate": false,
                "endDate": false,
            }
        }
        let hasError = false
        let fields = []
        var list = [];
        if (table === 'travel') {
            list = [...travelRequestDetailsList];
            list.map((data) => {
                if (!data.country) {
                    requiredFields.country = true;
                }
                if (!data.provinceFrom) {
                    requiredFields.provinceFrom = true;
                }
                if (!data.provinceTo) {
                    requiredFields.provinceTo = true;
                }
                if (!data.modeOfTransportation) {
                    requiredFields.modeOfTransportation = true;
                }
                if (!data.date) {
                    requiredFields.date = true;
                }
                if (!data.hour) {
                    requiredFields.hour = true;
                }
            });
            if (requiredFields.country) {
                hasError = true
                fields.push(t('ORGANIZATION_COUNTRY'));
            }
            if (requiredFields.provinceFrom) {
                hasError = true;
                fields.push(t('FROM_WHERE'));
            }
            if (requiredFields.provinceTo) {
                hasError = true;
                fields.push(t('FROM_TO'));
            }
            if (requiredFields.modeOfTransportation) {
                hasError = true;
                fields.push(t('MODE_OF_TRANSPORTATION'));
            }
            if (requiredFields.date) {
                hasError = true;
                fields.push(t('PARTNER_DATE'));
            }
            if (requiredFields.hour) {
                hasError = true;
                fields.push(t('HOUR'));
            }
        } else if (table === 'accommodation') {
            list = [...hostDetailsList];
            list.map((data) => {
                if (!data.country) {
                    requiredFields.country = true;
                }
                if (!data.province) {
                    requiredFields.province = true;
                }
                if (!data.startDate) {
                    requiredFields.startDate = true;
                }
                if (!data.endDate) {
                    requiredFields.endDate = true;
                }
            });
            if (requiredFields.country) {
                hasError = true
                fields.push(t('ORGANIZATION_COUNTRY'));
            }
            if (requiredFields.province) {
                hasError = true;
                fields.push(t('PARTNER_PROVINCE'));
            }
            if (requiredFields.startDate) {
                hasError = true;
                fields.push(t('PARTNER_ENTRY_DATE'));
            }
            if (requiredFields.endDate) {
                hasError = true;
                fields.push(t('RELEASE_DATE'));
            }


        }
        if (!hasError) {
            if (table === 'travel') {
                list.push({
                    "country": null,
                    "provinceFrom": null,
                    "provinceTo": null,
                    "modeOfTransportation": null,
                    "date": null,
                    "hour": null
                })
                requiredFields = {
                    "country": false,
                    "provinceFrom": false,
                    "provinceTo": false,
                    "modeOfTransportation": false,
                    "date": false,
                    "hour": false,
                }
                setTravelRequestDetailsList(list);
            } else {
                list.push({
                    "country": null,
                    "province": null,
                    "startDate": null,
                    "endDate": null,
                })
                requiredFields = {
                    "country": false,
                    "province": false,
                    "startDate": false,
                    "endDate": false,
                }
                setHostDetailsList(list)
            }

        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };
    useEffect(() => {
        var stateData = {};
        if (travelHostDetails) {
            stateData = {
                ...travelHostState,
                'travel': { 'id': travelHostDetails?.relatedTravelIdx, 'name': travelHostDetails?.relatedTravelName },
                'isTravelRequest': (travelHostDetails?.requestType === 0 || travelHostDetails?.requestType === 2) ? true : false,
                'travelDescription': travelHostDetails.travelDescription,
                'isAccomodation': (travelHostDetails?.requestType === 1 || travelHostDetails?.requestType === 2) ? true : false,
                'transportationDescription': travelHostDetails.hostDescription,
            }
            form.setFieldsValue(stateData);
            setTravelHostState(stateData);
            if (travelHostDetails.travelDetails.length > 0) {
                var tempArr = [];
                travelHostDetails.travelDetails.map((data) => {

                    return tempArr.push({
                        "country": { 'id': data.countryId, 'displayValue': data.countryName },
                        "provinceFrom": data.fromCity,
                        "provinceTo": data.destinationCity,
                        "modeOfTransportation": { 'id': data.transportType, 'displayValue': transportationlist.filter(filterData => filterData.id === data.transportType).displayValue },
                        "date": data.transportDate,
                        "hour": moment(data.transportDate).format(hoursFormat),
                        "description": data.description
                    });
                });
                setTravelRequestDetailsList(tempArr);
            }
            if (travelHostDetails.hostDetails.length > 0) {
                var tempArr = [];
                travelHostDetails.hostDetails.map((data) => {

                    return tempArr.push({
                        "country": { 'id': data.countryId, 'displayValue': data.countryName },
                        "province": data.city,
                        "startDate": data.entryDate,
                        "endDate": data.exitDate,
                        "description": data.description,
                    });
                });
                setHostDetailsList(tempArr);
            }

        }
    }, [travelHostDetails])

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical travel-scrollable travel-host-details-container w-100"
                requiredMark={true}
            >
                <Form.Item name="travel" className="custom-select" label={t('TRAVEL_NOTES')} >
                    {personTravelLoading ?
                        <Spin /> :
                        <DynamicSelectbox
                            optionList={personTravelList}
                            placeholder={t('PARTNER_SELECT')}
                        />
                    }

                </Form.Item>

                <Form.Item name="isTravelRequest" valuePropName="checked" className="mar-0">
                    <Checkbox >{t('TRANSPORTATION_REQUEST')}</Checkbox>
                </Form.Item>
                {travelHostState.isTravelRequest &&
                    <div className="expense-drawer">

                        <div className="table-container table-hide-all-buttons blue-table">
                            <div className="w-100 flex-end">
                                <Button
                                    onClick={() => handleAddDynamicRow('travel')}
                                    className="primary-btn"
                                    style={{
                                        marginBottom: 5,
                                    }}
                                >
                                    {t('INVENTORY_ADD_TEXT')}
                                </Button>
                            </div>
                            <div className="travel-request-add-list">
                                <div className="row">
                                    <label className="country m-r-10">  {t('ORGANIZATION_COUNTRY')} </label>
                                    <label className="province m-r-10">  {t('FROM_WHERE')} </label>
                                    <label className="province m-r-10">  {t('FROM_TO')} </label>
                                    <label className="modeOfTransportation m-r-10">  {t('MODE_OF_TRANSPORTATION')} </label>
                                    <label className="datePicker  m-r-10">  {t('PARTNER_DATE')} </label>
                                    <label className="hour  m-r-10">  {t('HOUR')} </label>
                                    <label className="description m-r-10">  {t('PARTNER_DESCRIPTION')} </label>

                                </div>
                                {
                                    travelRequestDetailsList.map((item, index) => {

                                        return (
                                            <div className="editable" key={index} >
                                                <DynamicSelectbox
                                                    value={item.country}
                                                    className="country m-r-10"
                                                    optionList={countryList}
                                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'country', 'select', 'travel')}
                                                ></DynamicSelectbox>
                                                <Input
                                                    className="province m-r-10"
                                                    value={item.provinceFrom}
                                                    disabled={!item.country?.id}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'provinceFrom', 'text', 'travel')}
                                                />
                                                <Input
                                                    className="province m-r-10"
                                                    value={item.provinceTo}
                                                    disabled={!item.country?.id}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'provinceTo', 'text', 'travel')}
                                                />
                                                <DynamicSelectbox
                                                    value={item.modeOfTransportation}
                                                    className="modeOfTransportation m-r-10"
                                                    optionList={transportationlist}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'modeOfTransportation', 'select', 'travel')}
                                                ></DynamicSelectbox>
                                                <DatePicker
                                                    value={item.date ? moment(item.date, dateFormat) : null}
                                                    className="datePicker m-r-10"
                                                    locale={localeLang}
                                                    onChange={(date, dateString) => handleAddRequestDynamicInputChange(dateString, index, 'date', 'datePicker', 'travel')}
                                                />
                                                <TimePicker
                                                    className="hour m-r-10"
                                                    locale={{
                                                        ...locale,
                                                        lang: {
                                                            ...locale.lang,
                                                            ok: t('PARTNER_SAVE'),
                                                        }
                                                    }}
                                                    minuteStep={15}
                                                    disabled={!item.date}
                                                    popupClassName=''
                                                    clearText={t('SETTINGS_DELETE')}
                                                    format={hoursFormat}
                                                    showNow={false}
                                                    inputReadOnly={true}
                                                    placeholder={t('SELECT_TIME')}
                                                    value={item.hour ? moment(item.hour, hoursFormat) : null}
                                                    onChange={(date, dateString) => handleAddRequestDynamicInputChange(dateString, index, 'hour', 'datePicker', 'travel')}
                                                />
                                                <Input
                                                    className="description m-r-10"
                                                    value={item.description}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'description', 'text', 'travel')}
                                                />


                                                <label className="delete" onClick={() => dynamicDeleteColumn(index, 'travel')}>
                                                    {t('PARTNER_DELETE')}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <br />
                        <Form.Item name="travelDescription" label={t('TRANSPORTATION_DESCRIPTION')}>
                            <Input.TextArea
                                placeholder={t('PARTNER_TEXT_DESCRIPTION')}
                            />
                        </Form.Item>
                    </div>
                }
                <Form.Item name="isAccomodation" valuePropName="checked" className="mar-0" >
                    <Checkbox >{t('ACCOMMODATION_REQUEST')}</Checkbox>
                </Form.Item>
                {travelHostState.isAccomodation &&
                    <div className="expense-drawer">
                        <div className="table-container table-hide-all-buttons blue-table">
                            <div className="w-100 flex-end">
                                <Button
                                    onClick={() => handleAddDynamicRow('accommodation')}
                                    className="primary-btn"
                                    style={{
                                        marginBottom: 5,
                                    }}
                                >
                                    {t('INVENTORY_ADD_TEXT')}
                                </Button>
                            </div>
                            <div className="host-request-details-list">
                                <div className="row">
                                    <label className="country m-r-10">  {t('ORGANIZATION_COUNTRY')} </label>
                                    <label className="province m-r-10">  {t('PARTNER_PROVINCE')} </label>
                                    <label className="datePicker  m-r-10">  {t('PARTNER_ENTRY_DATE')} </label>
                                    <label className="datePicker  m-r-10">  {t('RELEASE_DATE')} </label>
                                    <label className="description m-r-10">  {t('PARTNER_DESCRIPTION')} </label>
                                </div>
                                {
                                    hostDetailsList.map((item, index) => {

                                        return (
                                            <div className="editable" key={index} >
                                                <DynamicSelectbox
                                                    value={item.country}
                                                    className="country m-r-10"
                                                    optionList={countryList}
                                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'country', 'select', 'accommodation')}
                                                ></DynamicSelectbox>
                                                <Input
                                                    className="province m-r-10"
                                                    value={item.province}
                                                    disabled={!item.country?.id}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'province', 'text', 'accommodation')}
                                                />
                                                <DatePicker
                                                    value={item.startDate ? moment(item.startDate, dateFormat) : null}
                                                    className="datePicker m-r-10"
                                                    locale={localeLang}
                                                    onChange={(date, dateString) => handleAddRequestDynamicInputChange(dateString, index, 'startDate', 'datePicker', 'accommodation')}
                                                />
                                                <DatePicker
                                                    value={item.endDate ? moment(item.endDate, dateFormat) : null}
                                                    className="datePicker m-r-10"
                                                    locale={localeLang}
                                                    onChange={(date, dateString) => handleAddRequestDynamicInputChange(dateString, index, 'endDate', 'datePicker', 'accommodation')}
                                                />
                                                <Input
                                                    className="description m-r-10"
                                                    value={item.description}
                                                    onChange={(e) => handleAddRequestDynamicInputChange(e, index, 'description', 'text', 'accommodation')}
                                                />
                                                <label className="delete" onClick={() => dynamicDeleteColumn(index, 'accommodation')}>
                                                    {t('PARTNER_DELETE')}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <br />
                        <Form.Item name="transportationDescription" label={t('ACCOMMODATION_DESCRIPTION')}>
                            <Input.TextArea
                                placeholder={t('PARTNER_TEXT_DESCRIPTION')}
                            />
                        </Form.Item>
                    </div>
                }

                {/* {JSON.stringify(travelRequestDetailsList)} */}

            </Form >

        </ConfigProvider >
    )
}

const mapStateToProps = (state) => {
    return {
        countries: state.addressReducer.countries,
        provinces: state.addressReducer.provinces,
        expenseTypes: state.dataset.expenseTypes,
        personTravel: state.travel.personTravel,
        personTravelLoading: state.travel.personTravelLoading,
        profile: state.profile
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (type) => { dispatch(getDataset(type)) },
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelHostEditTab);