import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setAddTimeoffTypeDrawer, createTimeoffType, getOrganizationTimeoffTypeList, resetTimeoffTypeResponses } from '../../../../redux/actions/timeoffTypes.action'; // Drawer Statusler

import { Col, Row, Button, Steps, List, Tabs } from 'antd';
import { UilMultiply} from '@iconscout/react-unicons'


import AddTimeoffTypeBasicInfo from './timeoffTypesBasicInfo';
import AddTimeoffTypeDetailInfo from './timeoffTypesDetailInfo';
import AddTimeoffTypeAppliedPersons from './timeoffTypesAppliedPersons';
import AddTimeoffTypeSummary from './timeoffTypesSummary';
import Successful from "../../../successful";

import DynamicRequestModal from '../../../dynamicModal/requestModal';
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/left-step.scss';

function CreateTimeoffTypeTabList(props) {
    const { t } = useTranslation();

    const { setAddTimeoffTypeDrawer, createTimeoffType, createTimeoffTypeResponse, getOrganizationTimeoffTypeList, resetTimeoffTypeResponses } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const defaultData = {
        "name": null,
        "paymentSetIdx": null,
        "description": null,
        "isBalanceUnlimited": null,
        "maxBalance": null,
        "unitSetIdx": null,
        "accrualUnitSetIdx": null,
        "accrualDateSetIdx": null,
        "accrualDuration": null,
        "accrualDurationTypeSetIdx": null,
        "isTransferable": false,
        "isExceedable": false,
        "maxExceedBalance": null,
        "isExceedableInform": false,
        "isGenderType": false,
        "genderTypeSetIdx": null,
        "isRenewalableBalance": false,
        "isRequiredDocument": false,
        "isAppearContactInfo": false,
        "isRequiredContactInfo": null,
        "isRequiredDescription": false,
        "isActive":false,
        "personList": null,
        // "departmentList": [
        //   0
        // ],
        "tagList": null
    }
    const headerList = {
        "1":t('TIMEOFF_STEP_BASIC_INFO'),
        "2":t('TIMEOFF_STEP_DETAIL_INFO'),
        "3":t('TIMEOFF_STEP_APPLIED_PERSONS'),
        "4":t('TIMEOFF_STEP_PREVIEW') ,
        "5":t('TIMEOFF_STEP_DONE')
    }
    const [createTimeoffTypeObject, setCreateTimeoffTypeObject] = useState(defaultData);
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false });

    const { Step } = Steps;
    const { TabPane } = Tabs;
    
    function callback(key) {
        checkRequiredFields(() => setCurrentTab(parseInt(key)));
    }
    function prevButton() {
        checkRequiredFields(() => setCurrentTab(currentTab - 1));
    }
    function nextButton() {
        checkRequiredFields(() => setCurrentTab(currentTab + 1));
    }
    function closeDrawerControl() {
        if (currentTab !== 5 && (createTimeoffTypeObject?.name || createTimeoffTypeObject?.paymentSetIdx || createTimeoffTypeObject?.description )) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal()
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setAddTimeoffTypeDrawer(false);
        setCreateTimeoffTypeObject(defaultData);
    }

    const checkRequiredFields = (nextFunc) => {
        let hasError = false;
        let fields = [];
        if (!createTimeoffTypeObject.name || createTimeoffTypeObject.name?.trim() === "") {
            hasError = true;
            fields.push(t('TIMEOFF_NAME'));
        }
        if (!createTimeoffTypeObject.paymentSetIdx) {
            hasError = true;
            fields.push(t('TIMEOFF_TYPE'));
        }
        if (!createTimeoffTypeObject.isBalanceUnlimited?.toString()) {
            hasError = true;
            fields.push(t('TIMEOFF_DURATION'));
        }
        if (createTimeoffTypeObject.isBalanceUnlimited == 0) {
            if (!createTimeoffTypeObject.accrualUnitSetIdx) {
                hasError = true;
                fields.push(t('TIMEOFF_ACCRUAL_UNIT_SET'));
            }
            if (!createTimeoffTypeObject.maxBalance) {
                hasError = true;
                fields.push(t('TIMEOFF_LIMITED_LIMIT'));
            }
            if (!createTimeoffTypeObject.unitSetIdx) {
                hasError = true;
                fields.push(t('TIMEOFF_UNIT_SET'));
            }
            
        }
        if (!createTimeoffTypeObject.accrualDateSetIdx) {
            hasError = true;
            fields.push(t('TIMEOFF_STARTING_TIME'));
        }
        if (createTimeoffTypeObject.accrualDateSetIdx == 3) {
            if (!createTimeoffTypeObject.accrualDuration) {
                hasError = true;
                fields.push(t('TIMEOFF_ACCRUAL_DATE_SET'));
            }
            if (!createTimeoffTypeObject.accrualDurationTypeSetIdx) {
                hasError = true;
                fields.push(t('TIMEOFF_ACCRUAL_DATE_UNIT'));
            }
        }
        if (createTimeoffTypeObject.isExceedable == true) {
            if (!createTimeoffTypeObject.maxExceedBalance) {
                hasError = true;
                fields.push(t('TIMEOFF_TIMEOUT_MAX'));
            }
        }
        if (createTimeoffTypeObject.isGenderType == true) {
            if (createTimeoffTypeObject.genderTypeSetIdx == null) {
                hasError = true;
                fields.push(t('PARTNER_GENDER'));
            }
        }
        if (createTimeoffTypeObject.isAppearContactInfo == true) {
            if (createTimeoffTypeObject.isRequiredContactInfo == null) {
                hasError = true;
                fields.push(t('TIMEOFF_CONTACT_INFO_OPT_NOT_QUESTION'));
            }
        }
        if (!hasError) {
            nextFunc();
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        }
    }

    const [isCreateLoading, setisCreateLoading] = useState(false);

    const handleCreateTimeoffType = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        checkRequiredFields(() => createTimeoffType(createTimeoffTypeObject), true);
    }
    useEffect(()=>{
    },[createTimeoffTypeObject])

    useEffect(() => {
        if (createTimeoffTypeResponse.status === 'SUCCESS') {
            setCurrentTab(5);
            getOrganizationTimeoffTypeList();
            resetTimeoffTypeResponses();
        } else if (createTimeoffTypeResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'timeoff_type_create_fail_notification',
                'title': t('ERROR'),
                'description': createTimeoffTypeResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetTimeoffTypeResponses();
        } 

    }, [createTimeoffTypeResponse])

    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW_TIMEOFF_TYPE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="add-user-close-icon">
                <UilMultiply onClick={() => closeDrawerControl()} />
            </div>
            
            <div className="table-details">

                <div className="w-100">
                    <div className="add-user-draw-title pb-4">
                        <h1 className="mb-0">{t('CREATE_TIMEOFF_TYPE')}</h1>
                    </div>
                    <Row>
                        <Col span={24}>
                            
                            <Steps labelPlacement="vertical" className="timeoff-type-steps" size="small" current={currentTab - 1}>
                                <Step title={t('TIMEOFF_STEP_BASIC_INFO')} />
                                <Step title={t('TIMEOFF_STEP_DETAIL_INFO')} description="" />
                                <Step title={t('TIMEOFF_STEP_APPLIED_PERSONS')} description="" />
                                <Step title={t('TIMEOFF_STEP_PREVIEW')} description="" />
                                <Step title={t('TIMEOFF_STEP_DONE')} description="" />
                            </Steps>
                        </Col>
                        <Col span={24}>
                            {currentTab<5 && (<h2>{headerList[currentTab.toString()]}</h2>)}
                        </Col>
                        <Col span={24}>
                            
                            <Tabs className="custom-tab add-timeoff-types-draw-scrollable-tab"
                                onChange={callback}
                                activeKey={currentTab.toString()}
                                defaultActiveKey={currentTab.toString()}
                                renderTabBar={()=>{}}
                            >
                                <TabPane tab={`${t('TIMEOFF_STEP_BASIC_INFO')}`} key={1}>
                                    <AddTimeoffTypeBasicInfo setCreateTimeoffTypeObject={(o)=>{setCreateTimeoffTypeObject(o)}} createTimeoffTypeObject={createTimeoffTypeObject}/>
                                </TabPane>
                                <TabPane tab={t('TIMEOFF_STEP_DETAIL_INFO')} key={2}>
                                    <AddTimeoffTypeDetailInfo setCreateTimeoffTypeObject={(o)=>{setCreateTimeoffTypeObject(o)}} createTimeoffTypeObject={createTimeoffTypeObject}/>
                                </TabPane>
                                <TabPane tab={t('TIMEOFF_STEP_APPLIED_PERSONS')} key={3}>
                                    <AddTimeoffTypeAppliedPersons setCreateTimeoffTypeObject={(o)=>{setCreateTimeoffTypeObject(o)}} createTimeoffTypeObject={createTimeoffTypeObject}/>
                                </TabPane>
                                <TabPane tab={t('TIMEOFF_STEP_PREVIEW')} key={4}>
                                    <AddTimeoffTypeSummary setCreateTimeoffTypeObject={(o)=>{setCreateTimeoffTypeObject(o)}} createTimeoffTypeObject={createTimeoffTypeObject}/>
                                </TabPane>
                                <TabPane tab={t('TIMEOFF_STEP_DONE')} key={5}>
                                    <Successful message={t('TIMEOFF_COMPLETE_MESSAGE')} />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </div>
                
            <div className="add-timeoff-type-draw-footer bg-white space-between pr-4  timeoff-type-drawer-footer">
                <div>{currentTab < 5 ?
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                    </div>
                    <div className="d-flex">
                {currentTab > 1 && currentTab < 5 ?
                    <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                    :
                    ''
                }
                <div className="pad-left-24" />
                {currentTab < 5 ?
                    currentTab === 4 ?
                        <Button onClick={() => handleCreateTimeoffType()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('TIMEOFF_TYPE_CREATE_BTN')}</Button>
                        :
                        <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_NEXT')}</Button>
                    :
                    <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('TIMEOFF_RETURN_LIST')}</Button>
                }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        createTimeoffTypeResponse : state.timeoffTypes.createTimeoffTypeResponse,
        profile: state.profile
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAddTimeoffTypeDrawer: (status) => { dispatch(setAddTimeoffTypeDrawer(status)) },
        createTimeoffType: (data) => { dispatch(createTimeoffType(data)) },
        getOrganizationTimeoffTypeList: () => { dispatch(getOrganizationTimeoffTypeList()) },
        resetTimeoffTypeResponses: () => { dispatch(resetTimeoffTypeResponses()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTimeoffTypeTabList);