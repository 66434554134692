import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
const FileDownload = require("js-file-download");

const DepartmentDetailActionType = {
  GET_DEPARTMENT_DETAIL_INFO_REQUEST: "GET_DEPARTMENT_DETAIL_INFO_REQUEST",
  GET_DEPARTMENT_DETAIL_INFO_SUCCESS: "GET_DEPARTMENT_DETAIL_INFO_SUCCESS",
  GET_DEPARTMENT_DETAIL_INFO_FAIL: "GET_DEPARTMENT_DETAIL_INFO_FAIL",
  GET_DEPARTMENT_RESET: "GET_DEPARTMENT_RESET",
  UPDATE_DEPARTMENT_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
  UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
  UPDATE_DEPARTMENT_FAIL: "UPDATE_DEPARTMENT_FAIL",
  UPDATE_DEPARTMENT_INPUT_ERROR: "UPDATE_DEPARTMENT_INPUT_ERROR",
  DEPARTMENT_EXPORT_FAIL: "DEPARTMENT_EXPORT_FAIL",
  UPDATE_DEPARTMENT_RESPONSE_RESET: "UPDATE_DEPARTMENT_RESPONSE_RESET",
  DEPARTMENT_FILTER_REQUEST: "DEPARTMENT_FILTER_REQUEST",
  DEPARTMENT_FILTER_SUCCESS: "DEPARTMENT_FILTER_SUCCESS",
  DEPARTMENT_FILTER_FAIL: "DEPARTMENT_FILTER_FAIL",
  PARENT_DEPARTMENT_FILTER_REQUEST: "PARENT_DEPARTMENT_FILTER_REQUEST",
  PARENT_DEPARTMENT_FILTER_SUCCESS: "PARENT_DEPARTMENT_FILTER_SUCCESS",
  PARENT_DEPARTMENT_FILTER_FAIL: "PARENT_DEPARTMENT_FILTER_FAIL",
  GET_HEAD_DEPARTMENT_REQUEST: "GET_HEAD_DEPARTMENT_REQUEST",
  GET_HEAD_DEPARTMENT_SUCCESS: "GET_HEAD_DEPARTMENT_SUCCESS",
  GET_HEAD_DEPARTMENT_FAIL: "GET_HEAD_DEPARTMENT_FAIL",
  GET_DEPARTMENT_CONTROL_REQUEST: "GET_DEPARTMENT_CONTROL_REQUEST",
  GET_DEPARTMENT_CONTROL_SUCCESS: "GET_DEPARTMENT_CONTROL_SUCCESS",
  GET_DEPARTMENT_CONTROL_FAIL: "GET_DEPARTMENT_CONTROL_FAIL",
  RESET_DEPARTMENT_CONTROL: "RESET_DEPARTMENT_CONTROL",
  DEPARTMENT_MORE_ACTION_SUCCESS: "DEPARTMENT_MORE_ACTION_SUCCESS",
  DEPARTMENT_MORE_ACTION_FAIL: "DEPARTMENT_MORE_ACTION_FAIL",
  DEPARTMENT_MORE_ACTION_RESET: "DEPARTMENT_MORE_ACTION_RESET",
  GET_ALL_DEPARTMENT_TREE_VIEW_REQUEST: "GET_ALL_DEPARTMENT_TREE_VIEW_REQUEST",
  GET_ALL_DEPARTMENT_TREE_VIEW_SUCCESS: "GET_ALL_DEPARTMENT_TREE_VIEW_SUCCESS",
  GET_ALL_DEPARTMENT_TREE_VIEW_FAIL: "GET_ALL_DEPARTMENT_TREE_VIEW_FAIL",
  IMPORT_DEPARTMENT_SUCCESS: "IMPORT_DEPARTMENT_SUCCESS",
  IMPORT_DEPARTMENT_FAIL: "IMPORT_DEPARTMENT_FAIL",
  IMPORT_DEPARTMENT_RESET: "IMPORT_DEPARTMENT_RESET",
};
const getHeadDepartment = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.GET_HEAD_DEPARTMENT_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/departments/head",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            dispatch({ type: DepartmentDetailActionType.GET_HEAD_DEPARTMENT_SUCCESS, payload: response.data.data });
          } else {
            dispatch({
              type: DepartmentDetailActionType.GET_HEAD_DEPARTMENT_FAIL,
              payload: response.data.errorMessages,
            });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const headDepartment = { name: null };
            dispatch({ type: DepartmentDetailActionType.GET_HEAD_DEPARTMENT_SUCCESS, payload: headDepartment });
          } else if (error.response) {
            dispatch({
              type: DepartmentDetailActionType.GET_HEAD_DEPARTMENT_FAIL,
              payload: error,
            });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: DepartmentDetailActionType.GET_HEAD_DEPARTMENT_FAIL,
          payload: error,
        });
      }
    }
  };
};
const getDepartmentDetailInfo = (id) => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/departments/" + id,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            dispatch({
              type: DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_SUCCESS,
              payload: response.data.data,
            });
          } else {
            dispatch({
              type: DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_FAIL,
              payload: response.data.errorMessages,
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            dispatch({
              type: DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_FAIL,
              payload: error,
            });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: DepartmentDetailActionType.GET_DEPARTMENT_DETAIL_INFO_FAIL,
          payload: error,
        });
      }
    }
  };
};
const updateDepartment = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_REQUEST });
    try {
      const resourceId = updateData.resourceId;
      const data = JSON.stringify(updateData.data);
      var config = {
        method: "put",
        url: apiUrl + "/departments/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_INPUT_ERROR, payload: error.response.data });
          } else if (error.response?.status === 404) {
            dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_FAIL, payload: error.message });
      }
    }
  };
};
const departmentListExportWithPdf = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);
      var config = {
        method: "post",
        url: apiUrl + "/departments/export",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob", // Important
      };
      hrApi(config)
        .then(function (response, typeId) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };
            FileDownload(response.data, "department_" + newDate + "." + exportTypes[props.exportType]);
          } else {
            dispatch({ type: DepartmentDetailActionType.DEPARTMENT_EXPORT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: DepartmentDetailActionType.DEPARTMENT_EXPORT_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DepartmentDetailActionType.DEPARTMENT_EXPORT_FAIL, payload: error.message });
      }
    }
  };
};
const resetAction = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.GET_DEPARTMENT_RESET, payload: {} });
  };
};
const updateDepartmentResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.UPDATE_DEPARTMENT_RESPONSE_RESET, payload: {} });
  };
};
const getDepartmentFilter = (filterData) => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.DEPARTMENT_FILTER_REQUEST });
    try {
      const data = JSON.stringify(filterData);
      const column = filterData.column;
      var config = {
        method: "post",
        url: apiUrl + "/departments/filter",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            if (column === "parentDepartment") {
              dispatch({
                type: DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_SUCCESS,
                payload: response.data.data,
              });
            } else {
              dispatch({ type: DepartmentDetailActionType.DEPARTMENT_FILTER_SUCCESS, payload: response.data.data });
            }
          } else {
            if (column === "parentDepartment") {
              dispatch({
                type: DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_FAIL,
                payload: response.data.Message,
              });
            } else {
              dispatch({ type: DepartmentDetailActionType.DEPARTMENT_FILTER_FAIL, payload: response.data.Message });
            }
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            if (column === "parentDepartment") {
              dispatch({ type: DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_SUCCESS, payload: emptyData });
            } else {
              dispatch({ type: DepartmentDetailActionType.DEPARTMENT_FILTER_SUCCESS, payload: emptyData });
            }
          }
          if (column === "parentDepartment") {
            dispatch({ type: DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_FAIL, payload: error.message });
          } else {
            dispatch({ type: DepartmentDetailActionType.DEPARTMENT_FILTER_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        if (filterData.column === "parentDepartment") {
          dispatch({ type: DepartmentDetailActionType.PARENT_DEPARTMENT_FILTER_FAIL, payload: error.message });
        } else {
          dispatch({ type: DepartmentDetailActionType.DEPARTMENT_FILTER_FAIL, payload: error.message });
        }
      }
    }
  };
};
const departmentControl = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/departments/" + resourceId + "/deleteDepartmentControl",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_SUCCESS, payload: response.data.data });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            dispatch({
              type: DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_FAIL,
              payload: error.response.data.data,
            });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DepartmentDetailActionType.GET_DEPARTMENT_CONTROL_FAIL, payload: error.data.data });
      }
    }
  };
};
const resetDepartmentControl = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.RESET_DEPARTMENT_CONTROL, payload: {} });
  };
};
const departmentMoreAction = (data) => {
  // Delete or passive
  var resourceId = data?.id;
  var requestObj = data?.requestObj;
  var actionType = data?.type;
  var generateUrl = "";
  if (actionType === "disable") {
    generateUrl = apiUrl + "/departments/" + resourceId + "/disable";
  } else if (actionType === "active") {
    generateUrl = apiUrl + "/departments/" + resourceId + "/active";
  } else {
    generateUrl = apiUrl + "/departments/" + resourceId;
  }
  return async (dispatch) => {
    try {
      var config = {
        method: actionType === "active" ? "post" : "delete",
        url: generateUrl,
        headers: {},
        data: requestObj,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_SUCCESS,
              payload: response.data.message,
            });
          } else {
            dispatch({ type: DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_FAIL, payload: error.message });
      }
    }
  };
};
const departmentMoreActionResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.DEPARTMENT_MORE_ACTION_RESET, payload: {} });
  };
};
const getAllDepartmentsTreeView = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/departments/GetAllDepartmentsTreeView",
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_SUCCESS,
              payload: response.data.data,
            });
          } else {
            dispatch({
              type: DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_FAIL,
              payload: response.data.Message,
            });
          }
        })
        .catch(function (error) {
          dispatch({
            type: DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_FAIL,
            payload: error.response?.data,
          });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DepartmentDetailActionType.GET_ALL_DEPARTMENT_TREE_VIEW_FAIL, payload: error.message });
      }
    }
  };
};
const importDepartment = (data) => {
  const formData = new FormData();
  formData.append('formFile', data);
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/departments/import",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      hrApi(config)
        .then(function (response) {
          
          if (response.status === 200) {
            dispatch({ type: DepartmentDetailActionType.IMPORT_DEPARTMENT_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: DepartmentDetailActionType.IMPORT_DEPARTMENT_FAIL, payload: response.data });
          }
        })
        .catch(function (error) {
          //dispatch({ type: DepartmentDetailActionType.IMPORT_DEPARTMENT_FAIL, payload: error.message });
          dispatch({ type: DepartmentDetailActionType.IMPORT_DEPARTMENT_FAIL, payload: error.response.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: DepartmentDetailActionType.IMPORT_DEPARTMENT_FAIL, payload: error.message });
      }
    }
  };
};
const resetImportDepartment = () => {
  return async (dispatch) => {
    dispatch({ type: DepartmentDetailActionType.IMPORT_DEPARTMENT_RESET, payload: {} });
  };
};
export {
  DepartmentDetailActionType,
  getDepartmentDetailInfo,
  updateDepartment,
  departmentListExportWithPdf,
  resetAction,
  updateDepartmentResponseReset,
  getDepartmentFilter,
  getHeadDepartment,
  departmentControl,
  resetDepartmentControl,
  departmentMoreAction,
  departmentMoreActionResponseReset,
  getAllDepartmentsTreeView,
  importDepartment,
  resetImportDepartment
};
