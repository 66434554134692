import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {UilInfoCircle, UilAngleUp, UilAngleDown} from '@iconscout/react-unicons';

import { Form, Input, Col, Row, Checkbox, Spin, ConfigProvider, Card, Button, Divider, Typography, Tooltip, notification   } from 'antd';
import DynamicSelectbox from "../../component/dynamicSelectbox/index";

import { useLocation,useHistory  } from 'react-router-dom';



import { getTaxOffices, getDistricts, getProvinces, getCountry} from '../../redux/actions/address.actions';
import { getPaymentSummary, getOrganizationInvoiceAddress, getPaymentTransaction } from '../../redux/actions/checkout.actions';


function Payment(props) {

    const history = useHistory();

    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nLang') || 'en');

    const {
        getTaxOffices, getCountry, getProvinces, getDistricts, addressReducer, getPaymentSummary, paymentSummary, paymentSummaryLoading, getOrganizationInvoiceAddress,
        organizationInvoiceAddress, organizationInvoiceAddressLoading, getPaymentTransaction, paymentTransaction, organizationPackageId, permissionList
    } = props;

    const [permissions, setPermissions ] = React.useState(permissionList)

    useEffect(()=>{
        setPermissions(permissionList)
    },[JSON.stringify(permissionList)])


    const location = useLocation();

    const [paymentSummaryPayload,setPaymentSummaryPayload] = useState({"packageId": null, "discountCode": ""})

    const { t } = useTranslation();
    const [paymentPayload, setPaymentPayload] = useState({
        "packageId": "",
        "discountCode": "",
        "paymentTypeId": 1,
        "creditCard": {
          "cardHolderName": "",
          "cardNumber": "",
          "expireMonth": "",
          "expireYear": "",
          "cvc": "",
          "secure3D": true,
          "saveCard": true,
        },
        "invoiceAddress": {
          "addressLine": "",
          "companyName": "",
          "vknTckn": "",
          "email": "",
          "country": null,
          "province": null,
          "district": null,
          "taxOffice": null
        }
    })

    const [paymentSummaryState, setPaymentSummaryState] = useState(paymentSummary)

    const [form] = Form.useForm()

    const { Title, Text } = Typography;

    const [discountCodeVisible, setDiscountCodeVisible] = useState(false);

    const [isDiscountUsed, setIsDiscountUsed] = useState(false);
    const [CCVError, setCCVError] = useState('');
    const [isAmex , setIsAmex] = useState(false);
    const [discountCodeError , setDiscountCodeError] = useState('');
    
    const [checkStatus, setCheckStatus] = useState(false);
    const [saveCardInfo, setSaveCardInfo] = useState(false);
    const [taxIdList, setTaxIdList] = useState([
        { ...organizationInvoiceAddress.billingAddress?.taxOffice }
    ]);
    const [countryList, setCountryList] = useState([
        { ...organizationInvoiceAddress.billingAddress?.country || { "id": 224, "displayValue": "Türkiye" } }
    ]);
    const [districtList, setDistrictList] = useState([
        { ...organizationInvoiceAddress.billingAddress?.district }
    ]);
    const [provinceList, setProvinceList] = useState([
        { ...organizationInvoiceAddress.billingAddress?.province }
    ]);
    
    const toggleVisibility = () => {
        setDiscountCodeVisible(!discountCodeVisible);
    };

    const luhnCheck = (value) => {
        // accept only digits, dashes or spaces
        // if (/[^0-9-\s]+/.test(value)) return false;
        if (/[^0-9-\s]+/.test(value)) return t('ENTER_VALID_CARD_NUMBER');
      
        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0,
          nDigit = 0,
          bEven = false;
        value = value.replace(/\D/g, "");
      
        for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);
      
          if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
          }
      
          nCheck += nDigit;
          bEven = !bEven;
        }
      
        return nCheck % 10 == 0 ? null : t('ENTER_VALID_CARD_NUMBER');
    }

    const validateExpiry = (value) => {

        const [month, year] = value.split('/');
        const currentYear = new Date().getFullYear() % 100; // Yılın son iki basamağı
        const currentLongYear = new Date().getFullYear(); // Yılın son iki basamağı
        const currentMonth = new Date().getMonth() + 1; // Ay 0'dan başladığı için 1 ekliyoruz

        const inputYear = parseInt(year, 10);
        const inputLongYear = parseInt(year);
        const inputMonth = parseInt(month, 10);

        const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2}|[0-9]{4})$/;
        if (!regex.test(value)) {
            return t('ENTER_VALID_EXPIRATION_DATE')
        }else if ( inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth) || 
            (year?.length == 4 && (inputLongYear < currentLongYear  || (inputLongYear === currentLongYear && inputMonth < currentMonth)))
            ) {
            return t('EXPIRED_CARD_VALIDATION')
        }else{
            return null;
        }
    };

    const validateCCV = (value) => {
        const regex = /^[0-9]{3,4}$/;
        if (!regex.test(value)) { 
            return t('ENTER_VALID_SECURITY_CODE')
        }else{
            return null
        }
    };

    const allowOnlyNumericKeyPress = (e) => {
        // Sadece sayıları kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericPaste = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const cardNumberKeypressFilter = (e) => {
        // Sadece sayıları kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/[0-9\s]/.test(key)) {
          e.preventDefault();
        }
    };

    const cardNumberPasteFilter = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9\s]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleCardHolderKeyPress = (e) => {
        // Sadece harf, boşluk ve bazı özel karakterleri kabul et, diğer karakterleri engelle
        const key = e.key;
        if (!/^[A-Za-z\sÀ-ÖØ-öø-ÿÇçĞğİıÖöŞşÜü]*$/.test(key)) {
          e.preventDefault();
        }
    };

    const handleCardHolderPaste = (e) => {
        // Sadece harf, boşluk ve bazı özel karakterleri kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[A-Za-z\sÀ-ÖØ-öø-ÿ]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const handleExpireDateKeypress = (e) => {
        const key = e.key;
        if (!/[0-9\/]/.test(key)) {
          e.preventDefault();
        }
    }

    const handleExpireDatePaste = (e) => {
        // Yapıştırma işlemi sırasında sadece sayıları kabul et, diğer karakterleri engelle
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9\/]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    useEffect(() => {

        const addressObj = {
            "addressLine": organizationInvoiceAddress.billingAddress?.addressLine,
            "companyName": organizationInvoiceAddress.billingAddress?.companyName,
            "country": organizationInvoiceAddress.billingAddress?.country || { "id": 224, "displayValue": "Türkiye" },
            "district": organizationInvoiceAddress.billingAddress?.district || null,
            "email": organizationInvoiceAddress.billingAddress?.email,
            "province": organizationInvoiceAddress.billingAddress?.province || null,
            "taxOffice": organizationInvoiceAddress.billingAddress?.taxOffice || null,
            "vknTckn": organizationInvoiceAddress.billingAddress?.vknTckn,

        }
        form.setFieldsValue(addressObj)
        setPaymentPayload({...paymentPayload, invoiceAddress : addressObj })

    
    }, [form,organizationInvoiceAddress])

    useEffect(() => {

        const query = new URLSearchParams(location.search);

        const activePackage = query.get('packageId');

        if (activePackage) {
            if(organizationPackageId == activePackage && !permissions.includes("default_subscriber")){
                history.push('/prices')
            }
            setPaymentSummaryPayload({...paymentSummaryPayload, packageId : activePackage})
            setPaymentPayload({...paymentPayload, packageId : activePackage})
        }else{
            history.push('/prices')
        }

        const paymentError = query.get('error');
        if(paymentError){
            notification['error']({
                message: t("ERROR"),
                description: t(paymentError),
                placement:'topRight',
                duration: 15
            });
        }
        
    }, [location]);

    useEffect(() => {

        setPaymentSummaryState(paymentSummary)
        if(paymentSummary.discountCodeMessage){
            setDiscountCodeError(paymentSummary.discountCodeMessage)
            setIsDiscountUsed(false)
        }else{
            setDiscountCodeError('')
        }
        
        if(paymentSummary.discountPackagePrice == 0){
            
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields , cardHolderName: '' , cardNumber: '', expireDate : '' , CCV : ''};
            form.setFieldsValue(fieldsObj)

            const creditCard = {...paymentPayload.creditCard, cardHolderName: '' , cardNumber: '', expireDate : '' , cvc : ''  }

            setSaveCardInfo(false)

            if(isDiscountUsed){
                setPaymentPayload({...paymentPayload, paymentTypeId : 2, creditCard:creditCard })
            }else{
                setPaymentPayload({...paymentPayload, paymentTypeId : 0, creditCard:creditCard })
            }

        }else{
            setPaymentPayload({...paymentPayload, paymentTypeId : 1})
        }
        
    }, [paymentSummary]);

    useEffect(() => {

        paymentSummaryPayload.packageId && getPaymentSummary(paymentSummaryPayload)
        
    }, [paymentSummaryPayload]);


    useEffect(()=>{
        getOrganizationInvoiceAddress()
    },[])

    useEffect(()=>{
        if(paymentTransaction?.status === 400){
            notification['error']({
                message: t("ERROR"),
                description: paymentTransaction.data.map((msg)=> {return(<div>{msg}</div>)}),
                placement:'topRight',
                duration: 15
            });
        }
        if(paymentTransaction.htmlContent && paymentPayload.paymentTypeId == 1){
            paymentTransaction && history.push('/secure3DCallback')
        }else if(paymentTransaction.paymentNumber){
            history.push('/paymentCompleted/'+paymentTransaction.paymentNumber)
        }
    },[paymentTransaction])

    useEffect(() => {
        setTaxIdList(addressReducer.TaxId);
    }, [addressReducer.TaxId]);
    useEffect(() => {
        setCountryList(addressReducer.countries);
    }, [addressReducer.countries]);
    useEffect(() => {
        setProvinceList(addressReducer.provinces);
    }, [addressReducer.provinces]);
    useEffect(() => {
        setDistrictList(addressReducer.districts);
    }, [addressReducer.districts]);

    
    const toggleDiscountUse = () => {

        if(paymentPayload.discountCode !== ''){
            setIsDiscountUsed(!isDiscountUsed)
            // Logic to apply the discount code
            if(isDiscountUsed){
                getPaymentSummary({...paymentSummaryPayload, discountCode: '' })
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["discountCode"] = '';
                form.setFieldsValue(fieldsObj)
                setPaymentPayload({...paymentPayload, discountCode:''})
            }else{
                getPaymentSummary({...paymentSummaryPayload, discountCode: paymentPayload.discountCode })
            }
        }else{
            setDiscountCodeError(t('DISCOUNT_CODE_REQUIRED'))
        }
    };

    const [fieldsWithError, setFieldsWithError] = useState([]);

    useEffect(() => {
        if (fieldsWithError.length > 0) {
        const fieldElement = document.getElementById(fieldsWithError[0]);
        if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        }
    }, [fieldsWithError]);

    const onFinishFailed = (errorInfo) => {
        const fieldNames = errorInfo.errorFields.map(field => field.name[0]);
        setFieldsWithError(fieldNames);
    };

    const complatePayment = () =>{
        
        const paymentObject = JSON.parse(JSON.stringify(paymentPayload))
        if(!isDiscountUsed){
            paymentObject['discountCode']=''
        }

        const query = new URLSearchParams(location.search);

        const activePackage = query.get('packageId');

        if (activePackage) {
            paymentObject['packageId'] = parseInt(activePackage)
        }

        if(paymentPayload.paymentTypeId == 0 || paymentPayload.paymentTypeId == 2){
            paymentObject['creditCard']=null;
        }


        getPaymentTransaction(paymentObject)
    }

    const handleValuesChange = (changedObj, all) => {
        if ('country' === Object.keys(changedObj)[0]) {
            if (JSON.stringify(changedObj.country) !== null) {
                // ülke değişti
                setProvinceList([])//il listesini sıfırla
                setDistrictList([])//ilçe listesini sıfırla
                setTaxIdList([])//vergi dairesi listesini sıfırla
            
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                if(!changedObj['country'].id){
                    fieldsObj["country"] = null
                }
                fieldsObj["district"] = '';//ilçeyi sıfırla
                fieldsObj["province"] = '';//il sıfırla
                fieldsObj["taxOffice"] = '';//Vergi Dairesini sıfırla
                form.setFieldsValue(fieldsObj)
                
                const addressObj = {
                    ...paymentPayload.invoiceAddress, "country": changedObj['country'],
                    "taxOffice": "", "province": "", "district": ""
                }
                setPaymentPayload({ ...paymentPayload, "invoiceAddress": addressObj })

            } else {
                const addressObj = { ...paymentPayload.invoiceAddress, 'country': changedObj["country"] };
                setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
            }

        } else if ('province' === Object.keys(changedObj)[0]) {
            if (JSON.stringify(changedObj.province) !== null) {
                // il değişti
                setDistrictList([])//ilçe listesini sıfırla
                setTaxIdList([])//vergi dairesi listesini sıfırla
     
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                if(!changedObj['province']){
                    fieldsObj["province"] = null
                }
                fieldsObj["district"] = '';//ilçeyi sıfırla
                fieldsObj["taxOffice"] = '';//Vergi Dairesini sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...paymentPayload.invoiceAddress, "province": changedObj['province'], "district": "", "taxOffice": ""}
                setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj })

            } else {
                const addressObj = { ...paymentPayload.invoiceAddress, 'province': changedObj["province"] };
                setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
            }
        } else if ('district' === Object.keys(changedObj)[0]) {
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields };
            if(!changedObj['district'].id){
                fieldsObj["district"] = null
            }
            form.setFieldsValue(fieldsObj)
            const addressObj = { ...paymentPayload.invoiceAddress, 'district': changedObj["district"] };
            setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
        } else if ('addressLine' === Object.keys(changedObj)[0]) {
            const addressObj = { ...paymentPayload.invoiceAddress, 'addressLine': changedObj["addressLine"] };
            setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
        } else if ('companyName' === Object.keys(changedObj)[0]) {
            const addressObj = { ...paymentPayload.invoiceAddress, 'companyName': changedObj["companyName"] };
            setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
        } else if ('email' === Object.keys(changedObj)[0]) {
            const addressObj = { ...paymentPayload.invoiceAddress, 'email': changedObj["email"] };
            setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
        }else if ('taxOffice' === Object.keys(changedObj)[0]) {
            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields };
            if(!changedObj['taxOffice'].id){
                fieldsObj["taxOffice"] = null
            }
            form.setFieldsValue(fieldsObj)
            const addressObj = { ...paymentPayload.invoiceAddress, 'taxOffice': changedObj["taxOffice"] };
            setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
        } else if ('vknTckn' === Object.keys(changedObj)[0]) {
            const addressObj = { ...paymentPayload.invoiceAddress, 'vknTckn': changedObj["vknTckn"] };
            setPaymentPayload({ ...paymentPayload, invoiceAddress: addressObj });
        } else if ('cardHolderName' === Object.keys(changedObj)[0]) {
            const creditCard = { ...paymentPayload.creditCard, 'cardHolderName': changedObj["cardHolderName"] };
            setPaymentPayload({ ...paymentPayload, creditCard: creditCard });
        }  else if ('cardNumber' === Object.keys(changedObj)[0]){
            if(changedObj['cardNumber'] && !luhnCheck(changedObj['cardNumber'].replace(/\s+/g, ''))){
                setSaveCardInfo(true)
                const re = new RegExp("^(34|37)");
                const condition = changedObj['cardNumber'].match(re) != null;
                if (condition) {
                    setIsAmex(true)
                    //return value.length < 15 ? "Kart Numarası en az 15 hane olmalıdır" : true;
                } else {
                    setIsAmex(false)
                    //return value.length < 16 ? "Kart Numarası en az 16 hane olmalıdır" : true;
                }
            }else{
                setSaveCardInfo(false)

            }

            const fields = form.getFieldsValue()
            const fieldsObj = { ...fields, 'cardNumber' : changedObj['cardNumber'].replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim(), CCV:''};
            form.setFieldsValue(fieldsObj)

            const creditCard = {...paymentPayload.creditCard, cardNumber : changedObj['cardNumber'].replace(/\s+/g, '')}
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
            
            
        } else if('expireDate' === Object.keys(changedObj)[0]){
            
            let inputExpiry = changedObj['expireDate'];
            if(inputExpiry.length ==1 && parseInt(inputExpiry, 10) > 1){
                inputExpiry = '0'+inputExpiry
            }  
            const cleanedExpiry = inputExpiry.replace(/[^0-9]/g, ''); // Sadece rakamları al


            const fields = form.getFieldsValue()

            // Eğer tam olarak 6 karakter varsa (MMYYYY formatı), MM/YY formatına çevir
            if (cleanedExpiry.length === 6) {
            const formattedExpiry = `${cleanedExpiry.slice(0, 2)}/${cleanedExpiry.slice(2)}`;

            const fieldsObj = { ...fields, 'expireDate' : formattedExpiry };
            form.setFieldsValue(fieldsObj)
            } else if (cleanedExpiry.length === 4) {
            // Eğer tam olarak 4 karakter varsa (MMYY formatı), MM/YY formatına çevir
            const formattedExpiry = `${cleanedExpiry.slice(0, 2)}/${cleanedExpiry.slice(2)}`;
            const fieldsObj = { ...fields, 'expireDate' : formattedExpiry };
            form.setFieldsValue(fieldsObj)
            } else {
            // Diğer durumlar için girilen değeri kullan
            const fieldsObj = { ...fields, 'expireDate' : inputExpiry };
            form.setFieldsValue(fieldsObj)

            }

            const expireMonth = cleanedExpiry.slice(0, 2);
            const expireYear = cleanedExpiry.slice(2).length == 2 ? '20'+cleanedExpiry.slice(2) : cleanedExpiry.slice(2); 

            const creditCard = {...paymentPayload.creditCard, expireMonth : expireMonth , expireYear: expireYear }

            setPaymentPayload({...paymentPayload, creditCard : creditCard})

        } else if('CCV' === Object.keys(changedObj)[0]){

            const creditCard = {...paymentPayload.creditCard, cvc : changedObj['CCV']  }
            setPaymentPayload({...paymentPayload, creditCard : creditCard})
        } else if('discountCode' === Object.keys(changedObj)[0]){

            setPaymentPayload({...paymentPayload, discountCode : changedObj['discountCode']})
            setDiscountCodeError('')
        } else {
            setPaymentPayload({ ...paymentPayload, ...changedObj });

        }

    }

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={false}
                style={{paddingTop:'32px',paddingRight:'0px'}}
                onFinish={complatePayment}
                onFinishFailed={onFinishFailed}
            >
              <Row gutter={[16, 16]} justify="center">
                <Col span={23} sm={22} lg={18} xl={14} xxl={11}>
                  <Card className="fill-info-card">
                    <Text className="payment-card-title">{t("INVOICE_INFO")}</Text>
                    { !organizationInvoiceAddressLoading ? ( <Row gutter={[32, 0]}>
                        <Col span={24} md={12}>
                            <div className="form-left-body">

                                <Form.Item className="custom-select" name="country" label={t('ORGANIZATION_COUNTRY')} rules={[{ required: true, message: t('CHOOSE_FIELD_ERROR', {'label':t('ORGANIZATION_COUNTRY')})}]}>
                                    <DynamicSelectbox
                                        id="country"
                                        optionList={countryList}
                                        onFocus={() => getCountry()}
                                        placeholder={t('SELECTBOX_CHOOSE_PH')}
                                    />
                                </Form.Item>

                                <Form.Item className="custom-select" name="province" label={t('ORGANIZATION_PROVINCE')} rules={[{ required: true, message: t('ENTER_FIELD_ERROR', {'label':t('ORGANIZATION_PROVINCE')}) }]}>
                                    <Input disabled={!paymentPayload.invoiceAddress?.country?.id} />
                                </Form.Item>

                                {/* 
                                <Form.Item className="custom-select" name="district" label={t('ORGANIZATION_DISTRICT')} rules={[{ required: true, message: t('CHOOSE_FIELD_ERROR', {'label':t('ORGANIZATION_DISTRICT')}) }]}>
                                    <DynamicSelectbox
                                        id="district"
                                        optionList={districtList}
                                        onFocus={() => getDistricts(paymentPayload.invoiceAddress?.province?.id)}
                                        disabled={!paymentPayload.invoiceAddress?.province?.id}
                                        placeholder={t('SELECTBOX_CHOOSE_PH')}
                                    />
                                </Form.Item>
                                */ }

                                <Form.Item name="addressLine" label={t('ORGANIZATION_ADDRESS')} rules={[{ required: true, message: t('ENTER_FIELD_ERROR', {'label':t('ORGANIZATION_ADDRESS')}) }]}>
                                    <Input.TextArea id="addressLine" maxLength={500} rows={1}  placeholder={t('ORGANIZATION_ADDRESS_PH')} />
                                </Form.Item>
                                
                                

                            </div>

                        </Col>
                        <Col span={24} md={12}>
                            <div className="form-right-body">

                                <Form.Item name="companyName" label={t('ORGANIZATION_PERSON_NAME')}  rules={[{ required: true, message: t('ENTER_FIELD_ERROR', {'label':t('ORGANIZATION_PERSON_NAME')}) }]}>
                                    <Input id="companyName" maxLength={250} placeholder={t('ORGANIZATION_PERSON_NAME_PH')} />
                                </Form.Item>

                                <Form.Item name="email" label={t('INVOICE_EMAIL')} rules={[{ required: true, message: t('ENTER_FIELD_ERROR', {'label':t('INVOICE_EMAIL')}) },{ type: 'email', message: t('INVOICE_EMAIL_ERROR') }]} >
                                    <Input id="email" maxLength={250} placeholder={t('INVOICE_EMAIL_PH')} />
                                </Form.Item>

                                <Form.Item name="vknTckn" label={t('ORGANIZATION_VKNTCKN')} rules={[{ required: true, message: t('ENTER_FIELD_ERROR', {'label':t('ORGANIZATION_TAX_NUMBER')}) }]}>
                                    <Input id="vknTckn"  maxLength={50} placeholder={t('ORGANIZATION_VKNTCKN_PH')}/>
                                </Form.Item>
                                {/*
                                    <Form.Item name="taxOffice" className="custom-select" label={t('ORGANIZATION_TAX_OFFICE')} rules={[{ required: true, message: t('CHOOSE_FIELD_ERROR', {'label':t('ORGANIZATION_TAX_OFFICE')}) }]}>
                                        <DynamicSelectbox
                                            id="taxOffice"
                                            optionList={taxIdList}
                                            onFocus={() => getTaxOffices(paymentPayload.invoiceAddress?.province.id)}
                                            disabled={!paymentPayload.invoiceAddress?.province?.id}
                                            placeholder={t('SELECTBOX_CHOOSE_PH')}
                                        />
                                    </Form.Item>
                                */}
                            </div>
                        </Col>
                    </Row>):(
                        <div style={{height:'344px',display:'flex',alignItems:'center',justifyContent:"center"}}><Spin/></div>
                    )}
                    <Divider />
                    <Text className="payment-card-title">{t('CARD_INFO')}</Text>
                    { paymentPayload.paymentTypeId == 2 && (<Row>
                        <Col span={24}>
                            <div className="free-payment-info">
                                <UilInfoCircle /> {t('PAYMENT_BY_DISCOUNT_CODE')}
                            </div>
                        </Col>
                    </Row>)}
                    <Row gutter={[32, 0]}>
                      <Col  span={24} md={12}> 
                        <Form.Item name="cardHolderName" label={t('CARD_HOLDER_NAME')} rules={[{ required: paymentSummary.discountPackagePrice == 0? false :true, message: t('REQUIRED_FIELD') }]}>
                            <Input id="cardHolderName" placeholder={t('CARD_HOLDER_NAME_PH')} maxLength={100} onKeyPress={handleCardHolderKeyPress} onPaste={handleCardHolderPaste} disabled={paymentPayload.paymentTypeId!==1}  />
                        </Form.Item>
                        <Form.Item name="cardNumber" label={t('CARD_NUMBER')} rules={[{ required: paymentSummary.discountPackagePrice == 0? false :true, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && luhnCheck(value)) ? Promise.reject(new Error(luhnCheck(value))) : Promise.resolve()}]}>
                            <Input id="cardNumber" placeholder={t('CARD_NUMBER_PH')} maxLength={19} onKeyPress={cardNumberKeypressFilter} onPaste={cardNumberPasteFilter} disabled={paymentPayload.paymentTypeId!==1}/>
                        </Form.Item>
                      </Col>
                      <Col  span={24} md={12}> 
                        <Row gutter={[32, 0]}>
                            <Col span={12}>
                                <Form.Item name="expireDate" label={t('CARD_EXPIRE')} rules={[{ required: paymentSummary.discountPackagePrice == 0? false :true, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && validateExpiry(value)) ? Promise.reject(new Error(validateExpiry(value))) : Promise.resolve()}]}>
                                    <Input  id="expireDate" placeholder={t('CARD_EXPIRE_PH')} maxLength={7} onKeyPress={handleExpireDateKeypress} onPaste={handleExpireDatePaste} disabled={paymentPayload.paymentTypeId!==1}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="CCV" label={t('CARD_CCV')} rules={[{ required: paymentSummary.discountPackagePrice == 0? false :true, message: t('REQUIRED_FIELD') }, {validator: (_,value) => (value && validateCCV(value)) ? Promise.reject(new Error(validateCCV(value))) : Promise.resolve()}]}>
                                    <Input id="CCV" placeholder={t('CARD_CCV_PH')} maxLength={isAmex ? 4 : 3} onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} disabled={paymentPayload.paymentTypeId!==1 || !saveCardInfo }/>
                                </Form.Item>
                            </Col>
                        </Row>
                      </Col>
                    </Row>
                    { (saveCardInfo && paymentPayload.paymentTypeId==1 ) && (
                    <Row>
                        <Col className="save-card-info">
                            <Checkbox checked disabled>{t('CARD_SAVE_INFO_1')}</Checkbox>
                            <Tooltip title={t('CARD_SAVE_INFO_2')}>
                                <UilInfoCircle />
                            </Tooltip>
                        </Col>
                    </Row>
                    )}
                  </Card>
                </Col>
                <Col span={23} sm={22} lg={18} xl={8} xxl={7}>
                    {!paymentSummaryLoading ? (
                    <Card className="basket-summary-card">
                        <Text className="payment-card-title">{t('PAYMENT_SUMMARY')}</Text>
                        
                        {selectedLanguage == 'tr-TR'  && 
                            <div className="tl-price-payment">
                                    {paymentSummaryState?.packagePrice?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        <div className="price-row" >
                        <Text strong>{t('PACKAGE_PRICE')}</Text>
                        <Text className="price-value">
                            {selectedLanguage == 'tr-TR' 
                                ? paymentSummaryState?.packagePriceInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : paymentSummaryState?.packagePrice?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }
                        </Text>
                        </div>
                        {(selectedLanguage == 'tr-TR' && paymentSummaryState.discountPrice > 0)  && 
                            <div className="tl-price-payment">
                                    {paymentSummaryState?.discountPrice?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        {paymentSummaryState.discountPrice > 0 && (
                            <div className="price-row" >
                            <Text strong>{t('DISCOUNT')}</Text>
                            <Text className="price-value">
                                - 
                                {selectedLanguage == 'tr-TR' 
                                ? paymentSummaryState?.discountPriceInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : paymentSummaryState?.discountPrice?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }
                            </Text>
                            </div>
                        )}

                        <Divider/>

                        <div>
                            <div onClick={toggleVisibility} className="discount-title">
                                {t('DISCOUNT_CODE')}
                                {discountCodeVisible ? <UilAngleUp /> : <UilAngleDown />}
                            </div>
                            {discountCodeVisible && (
                                <Tooltip title={!isDiscountUsed && paymentSummary.discountPackagePrice == 0 ? t('DISCOUNT_CODE_WARNING') :''}>
                                
                                <div className="discount-input">
                                    <Form.Item name='discountCode' style={{ flex: 1 }} >
                                        <Input id="discountCode" placeholder={t('DISCOUNT_CODE')} disabled={isDiscountUsed || paymentSummary.discountPackagePrice == 0}  />
                                    </Form.Item>
                                    <Button className="white-btn" onClick={toggleDiscountUse} disabled={!isDiscountUsed && paymentSummary.discountPackagePrice == 0}>
                                        { isDiscountUsed ? t('REMOVE_CODE'):t('APPLY_CODE') }
                                    </Button>
                                </div>
                            </Tooltip>
                            )}
                            {
                            discountCodeError && (
                                <div className='discount-warning'>
                                    {discountCodeError}
                                </div>
                            )}
                            
                        </div>

                        <Divider />
                        {selectedLanguage == 'tr-TR'  && 
                            <div className="tl-price-payment">
                                    {paymentSummaryState?.discountPackagePrice?.toLocaleString('en-US').replace(',' , '') + ' USD'}
                            </div>
                        }
                        <div className="price-row" >
                            <Text strong>{t('TOTAL_DISCOUNTED_PRICE')}</Text>
                            <Text className="price-value">
                                {selectedLanguage == 'tr-TR' 
                                ? paymentSummaryState?.discountPackagePriceInCurrency?.toLocaleString('tr-TR') + ' TL'
                                : paymentSummaryState?.discountPackagePrice?.toLocaleString('en-US').replace(',' , '') + ' USD'
                            }
                            </Text>
                        </div>
                        

                        <Form.Item name="agreement" valuePropName="checked" rules={[{ validator: (_, value) => value  ? Promise.resolve() : Promise.reject(new Error(t('REQUIRED_CHECKBOX')))}]}>
                        <Checkbox id="agreement">
                            {selectedLanguage == 'tr-TR' 
                                        ?<>
                                            <a href='https://www.hrplan.net/kullanici-sozlesmesi/' target="_blank">{t('USER_AGREEMENT')}</a>{t('AGREEMENTS_EXT_1')},&nbsp;
                                            <a href='https://www.hrplan.net/aydinlatma-metni/' target="_blank">{t('USER_INFORM_TEXT')}</a>{t('AGREEMENTS_EXT_2')},&nbsp;
                                            <a href='https://www.hrplan.net/acik-riza-metni/' target="_blank">{t('USER_DATA_AGREEMENT')}</a> {t('AGREEMENTS_EXT_3')}
                                        </>
                                        :<>
                                            <a href='https://www.hrplan.net/en/user-agreement/' target="_blank">{t('USER_AGREEMENT')}</a>{t('AGREEMENTS_EXT_1')},&nbsp;
                                            <a href='https://www.hrplan.net/en/customer-information-text/' target="_blank">{t('USER_INFORM_TEXT')}</a>{t('AGREEMENTS_EXT_2')},&nbsp;
                                            <a href='https://www.hrplan.net/en/customer-overseas-data-transfer-explicit-consent-statement/' target="_blank">{t('USER_DATA_AGREEMENT')}</a> {t('AGREEMENTS_EXT_3')}
                                        </>
                            }
                        </Checkbox>
                        </Form.Item>
                        <Form.Item>
                        <Button htmlType="submit" block className={'primary-btn'}>
                            {t('COMPLETE_PAYMENT')}
                        </Button>
                        </Form.Item>
                        
                    </Card>
                  ):( <Card className="basket-summary-card" style={{height:'341px',display:'flex',alignItems:'center',justifyContent:"center"}}><Spin/></Card>)}
                </Col>
              </Row>
              
            </Form>
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        addressReducer: state.addressReducer,
        paymentSummary: state.checkout.paymentSummary,
        paymentSummaryLoading: state.checkout.paymentSummaryLoading,
        organizationInvoiceAddress : state.checkout.organizationInvoiceAddress,
        organizationInvoiceAddressLoading : state.checkout.organizationInvoiceAddressLoading,
        paymentTransaction : state.checkout.paymentTransaction,
        organizationPackageId: state.auth?.user?.profile?.organizationPackageId,
        permissionList: state.profile.permissions

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTaxOffices: (props) => { dispatch(getTaxOffices(props)) },
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (props) => { dispatch(getProvinces(props)) },
        getDistricts: (props) => { dispatch(getDistricts(props)) },
        getPaymentSummary: (props) => { dispatch(getPaymentSummary(props)) },
        getOrganizationInvoiceAddress : () => { dispatch(getOrganizationInvoiceAddress()) },
        getPaymentTransaction: (props) => { dispatch(getPaymentTransaction(props)) }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);