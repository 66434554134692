import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

const InventoryType = {
    GET_INVENTORY_TYPE_REQUEST:"GET_INVENTORY_TYPE_REQUEST",
    GET_INVENTORY_TYPE_SUCCESS:"GET_INVENTORY_TYPE_SUCCESS",
    GET_INVENTORY_TYPE_FAIL:"GET_INVENTORY_TYPE_FAIL",
    GET_INVENTORY_TYPE_LIST_REQUEST:"GET_INVENTORY_TYPE_LIST_REQUEST",
    GET_INVENTORY_TYPE_LIST_SUCCESS:"GET_INVENTORY_TYPE_LIST_SUCCESS",
    GET_INVENTORY_TYPE_LIST_FAIL:"GET_INVENTORY_TYPE_LIST_FAIL",
    UPDATE_INVENTORY_TYPE_STATUS_REQUEST: "UPDATE_INVENTORY_TYPE_STATUS_REQUEST",
    UPDATE_INVENTORY_TYPE_STATUS_SUCCESS: "UPDATE_INVENTORY_TYPE_STATUS_SUCCESS",
    UPDATE_INVENTORY_TYPE_STATUS_FAIL: "UPDATE_INVENTORY_TYPE_STATUS_FAIL",
    UPDATE_INVENTORY_TYPE_STATUS_RESET:"UPDATE_INVENTORY_TYPE_STATUS_RESET"
}

const getInventoryTypes = (searchData, filterService = false) => {
    return async (dispatch) => {
    dispatch({ type: InventoryType.GET_INVENTORY_TYPE_REQUEST });
    try {

        const data = JSON.stringify(searchData);

        var config = {
            method: filterService ? 'post' :'get',
            url: filterService ? apiUrl+'/inventoryTypes/filter' : apiUrl+'/inventoryTypes/inventoryTypeListName',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
        hrApi(config)
        .then(function (response) {
            if(response.status===200){

                dispatch({ type: InventoryType.GET_INVENTORY_TYPE_SUCCESS, payload: filterService ? response.data.data : {results: response.data.data}  });
            }else{

                dispatch({ type: InventoryType.GET_INVENTORY_TYPE_FAIL, payload: response.data.Message });
            }
        })
        .catch(function (error) {
            if(error.response?.status === 404){
            const emptyData = {
                "pageNumber": 1,
                "pageSize": 1,
                "totalNumberOfPages": 1,
                "totalNumberOfRecords": 0,
                "results": []
            }
            dispatch({ type: InventoryType.GET_INVENTORY_TYPE_SUCCESS, payload: emptyData });
            }
            dispatch({ type: InventoryType.GET_INVENTORY_TYPE_FAIL, payload: error.message });
        });

            
        } catch (error) {
            if (error.response) {
                dispatch({ type: InventoryType.GET_INVENTORY_TYPE_FAIL, payload: error.message });
            }
        }
    };
};

const getInventoryTypeList = () => {
    return async (dispatch) => {
    dispatch({ type: InventoryType.GET_INVENTORY_TYPE_LIST_REQUEST });
    try {


        var config = {
            method: 'get',
            url: apiUrl+'/inventoryTypes',
            headers: { 
            'Content-Type': 'application/json'
            },
        };
        
        hrApi(config)
        .then(function (response) {
            if(response.status===200){

                dispatch({ type: InventoryType.GET_INVENTORY_TYPE_LIST_SUCCESS, payload: response.data.data });
            }else{

                dispatch({ type: InventoryType.GET_INVENTORY_TYPE_LIST_FAIL, payload: response.data.message });
            }
        })
        .catch(function (error) {
            if(error.response?.status === 404){
            const emptyData = {}
            dispatch({ type: InventoryType.GET_INVENTORY_TYPE_LIST_SUCCESS, payload: emptyData });
            }
            dispatch({ type: InventoryType.GET_INVENTORY_TYPE_LIST_FAIL, payload: error.response.data.message });
        });

            
        } catch (error) {
            if (error.response) {
                dispatch({ type: InventoryType.GET_INVENTORY_TYPE_LIST_FAIL, payload: error.response.data.message });
            }
        }
    };
};

const updateInventoryTypeStatus = (status,resourceId) => {

    const statusObj = {"isActive": status}

    return async (dispatch) => {
      dispatch({ type: InventoryType.UPDATE_INVENTORY_TYPE_STATUS_REQUEST });
      try {
           var config = {
            method: 'put',
            url: apiUrl+'/inventoryTypes/'+resourceId,
            headers: { 
              'Content-Type': 'application/json'
            },
            data:statusObj
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: InventoryType.UPDATE_INVENTORY_TYPE_STATUS_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: InventoryType.UPDATE_INVENTORY_TYPE_STATUS_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: InventoryType.UPDATE_INVENTORY_TYPE_STATUS_FAIL, payload: error.response.data.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: InventoryType.UPDATE_INVENTORY_TYPE_STATUS_FAIL, payload: error.response.data.message });
        }
      }
    };
  };

const updateInventoryTypeStatusReset = () => {
    
    return async (dispatch) => {
      dispatch({ type: InventoryType.UPDATE_INVENTORY_TYPE_STATUS_RESET, payload:{} });
      };
  }

export {
    InventoryType,
    getInventoryTypes,
    getInventoryTypeList,
    updateInventoryTypeStatus,
    updateInventoryTypeStatusReset
}

