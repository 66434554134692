import { Row, Col } from 'antd';
import { LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function SafeBtn() {

    const { t } = useTranslation();

    return (

        <Col span={24}>
            <div className="flex-end ">
                <div className="safe-info">
                    <Row>
                        <Col span={10}>
                            <h1><LockOutlined />%100 Güvenli</h1>
                        </Col>
                        <Col span={1} >
                            <div className="safe-hr"></div>
                        </Col>
                        <Col offset={1} span={11}>
                            <h1><PhoneOutlined />0850 288  00 00 </h1>
                        </Col>
                    </Row>
                </div>

            </div>
        </Col>
    );

}

export default SafeBtn;