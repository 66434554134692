export const setTimeOffAddDrawer = (setTimeOffAddDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_TIME_OFF_ADD_DRAWER',
            payload: setTimeOffAddDrawer,
        });
    } catch (e) {
    }
};
export const setTimeOffTypeDetailsDrawer = (setTimeOffTypeDetailsDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_TIME_OFF_TYPE_DETAILS_DRAWER',
            payload: setTimeOffTypeDetailsDrawer,
        });
    } catch (e) {
    }
};
export const setTimeOffRequestApproveDrawer = (setTimeOffRequestApproveDrawer) => async dispatch => {
    try {
        dispatch({
            type: 'SET_TIME_OFF_REQUEST_APPROVE_DRAWER',
            payload: setTimeOffRequestApproveDrawer,
        });
    } catch (e) {
    }
};