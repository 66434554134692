import { Dashboard } from "../actions/dashboard.actions";

const initialState = {
    timeOffRequestConfirmedPersonLoading:false,
    timeOffRequestConfirmedPersons:  [
        {
            "personName":"",
            "name":"",
            "startDate":"",
            "endDate":"",
            "balance":null,
            "genderIdx":0,
            
        }
    ],
    timeOffRequestListPersonLoading:false,
    timeOffRequestListPersons:  [
        {
            "id": null,
            "startDate": "",
            "endDate": "",
            "stateTypeSetIdx": null,
            "stateTypeName": "",
            "timeOffTypeName": ""
            
        }
    ],
    congratulationsLoading:false,
    congratulationsList:[
        {      
        "personName": "",
        "dateInfo": "",
        "status": "",
        "genderIdx": 0,
        "statusId": 0
    }
    ],
    timeOffPaiChartData:[
        {
            "timeOffTypeName": "",
            "maxBalance": 0,
            "remainingValue": 0
        },
    ],
    timeOffPaiChartDataLoading:true,
    numberOfFemaleAndMaleListLoading:false,
    numberOfFemaleAndMaleList:[],
    numberOfMarriedAndSingleLoading:false,
    numberOfMarriedAndSingleList:[],
    employeeExperienceRatesLoading:false,
    employeeExperienceRatesList:[],
    employeesWorkingTimeInTheCompanyLoading:false,
    employeesWorkingTimeInTheCompanyList:[],
    numberOfChildrenEmployeesLoading:false,
    numberOfChildrenEmployeesList:[],
    distributionOfEmployeesByDepartmentsLoading:false,
    distributionOfEmployeesByDepartmentsList:[],
    pendingApprovalTimeOffRequestLoading:false,
    pendingApprovalTimeOffRequestData:null,
    requestsPendingMyApprovalData:[],
    requestsPendingMyApprovalLoading:false,
        
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
       
        case Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_REQUEST:
            return {
                ...state,
                timeOffRequestConfirmedPersonLoading: true
            };
        case Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_SUCCESS:
            return {
                ...state,
                timeOffRequestConfirmedPersons: action.payload,
                timeOffRequestConfirmedPersonLoading: false
            }
        case Dashboard.GET_TIME_OFF_CONFIRMED_PERSONS_FAIL:
            return {
                ...state,
                timeOffRequestConfirmedPersonLoading: false
            }
        case Dashboard.GET_TIME_OFF_LIST_BY_PERSON_REQUEST:
            return {
                ...state,
                timeOffRequestListPersonLoading: true
            };
        case Dashboard.GET_TIME_OFF_LIST_BY_PERSON_SUCCESS:
            return {
                ...state,
                timeOffRequestListPersons: action.payload,
                timeOffRequestListPersonLoading: false
            }
        case Dashboard.GET_TIME_OFF_LIST_BY_PERSON_FAIL:
            return {
                ...state,
                timeOffRequestListPersonLoading: false
            }
                           
        case Dashboard.GET_CANGRATULATIONS_REQUEST:
            return {
                ...state,
                congratulationsLoading: true
            };
        case Dashboard.GET_CANGRATULATIONS_SUCCESS:
            return {
                ...state,
                congratulationsList: action.payload,
                congratulationsLoading: false
            }
        case Dashboard.GET_CANGRATULATIONS_FAIL:
            return {
                ...state,
                congratulationsLoading: false
            }
        case Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_REQUEST:
            return {
                ...state,
                numberOfFemaleAndMaleListLoading: true
            };
        case Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_SUCCESS:
            return {
                ...state,
                numberOfFemaleAndMaleList: action.payload,
                numberOfFemaleAndMaleListLoading: false
            }
        case Dashboard.GET_NUMBER_OF_FEMALE_AND_MALE_FAIL:
            return {
                ...state,
                numberOfFemaleAndMaleListLoading: false
            }    
        case Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_REQUEST:
            return {
                ...state,
                numberOfMarriedAndSingleLoading: true
            };
        case Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_SUCCESS:
            return {
                ...state,
                numberOfMarriedAndSingleList: action.payload,
                numberOfMarriedAndSingleLoading: false
            }
        case Dashboard.GET_NUMBER_OF_MARRIED_AND_SINGLE_FAIL:
            return {
                ...state,
                numberOfMarriedAndSingleLoading: false
            }    
        case Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_REQUEST:
            return {
                ...state,
                employeeExperienceRatesLoading: true
            };
        case Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_SUCCESS:
            return {
                ...state,
                employeeExperienceRatesList: action.payload,
                employeeExperienceRatesLoading: false
            }
        case Dashboard.GET_EMPLOYEE_EXPERIENCE_RATES_FAIL:
            return {
                ...state,
                employeeExperienceRatesLoading: false
            }    
        case Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_REQUEST:
            return {
                ...state,
                employeesWorkingTimeInTheCompanyLoading: true
            };
        case Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_SUCCESS:
            return {
                ...state,
                employeesWorkingTimeInTheCompanyList:action.payload,
                employeesWorkingTimeInTheCompanyLoading:false
            }
        case Dashboard.GET_EMPLOYEES_WORKING_TIME_COMPANY_FAIL:
            return {
                ...state,
                employeesWorkingTimeInTheCompanyLoading: false
            }  
        case Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_REQUEST:
            return {
                ...state,
                numberOfChildrenEmployeesLoading: true
            };
        case Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_SUCCESS:
            return {
                ...state,
                numberOfChildrenEmployeesList:action.payload,
                numberOfChildrenEmployeesLoading:false
            }
        case Dashboard.GET_NUMBER_OF_CHILDREN_EMPLOYEES_FAIL:
            return {
                ...state,
                numberOfChildrenEmployeesLoading: false
            }          
        case Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_REQUEST:
               return {
                   ...state,
                   distributionOfEmployeesByDepartmentsLoading: true
               };
        case Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_SUCCESS:
               return {
                   ...state,
                   distributionOfEmployeesByDepartmentsList:action.payload,
                   distributionOfEmployeesByDepartmentsLoading:false
               }
        case Dashboard.GET_DISTRIBUTION_OF_EMPLOYEES_FAIL:
               return {
                   ...state,
                   distributionOfEmployeesByDepartmentsLoading: false
               }        
        case Dashboard.GET_PENDING_APPROVAL_TIME_OFF_REQUEST:
             return {
                 ...state,
                 pendingApprovalTimeOffRequestLoading: true
             };
        case Dashboard.GET_PENDING_APPROVAL_TIME_OFF_SUCCESS:
                return {
                    ...state,
                    pendingApprovalTimeOffRequestData:action.payload,
                    pendingApprovalTimeOffRequestLoading:false
                }
        case Dashboard.GET_PENDING_APPROVAL_TIME_OFF_FAIL:
                return {
                    ...state,
                    pendingApprovalTimeOffRequestLoading: false
                }          
        case Dashboard.GET_PENDING_MY_APPROVAL_REQUEST:
            return {
                ...state,
                requestsPendingMyApprovalLoading: true
            };
        case Dashboard.GET_PENDING_MY_APPROVAL_SUCCESS:
            return {
                ...state,
                requestsPendingMyApprovalData:action.payload,
                requestsPendingMyApprovalLoading:false
            }
        case Dashboard.GET_PENDING_MY_APPROVAL_FAIL:
            return {
                ...state,
                requestsPendingMyApprovalLoading: false
            }       
     
        case Dashboard.GET_DASHBOARD_TIME_OFF_SUCCESS:
            return {
                ...state,
                timeOffPaiChartData:action.payload,
                timeOffPaiChartDataLoading:false,
            }
        case Dashboard.GET_DASHBOARD_TIME_OFF_FAIL:
            return {
                ...state,
                timeOffPaiChartDataLoading: false
            }
        case Dashboard.GET_DASHBOARD_TIME_OFF_RESET:
            return {
                ...state,
                timeOffPaiChartData:[],
                timeOffPaiChartDataLoading:true,
            }        
        default: return state

            }
        }
