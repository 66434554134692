import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setOwnerShipDetailsDrawer } from '../../redux/actions/person-detail-tab-action'
import { getInventoryTypes } from '../../redux/actions/inventoryType.action';
import { getInventoryByInventoryType } from '../../redux/actions/inventory.actions';
import { setOwnerShipReturnDrawer } from '../../redux/actions/ownership-drawer.action';
import { getOwnerShipDetails, updateOwnerShip, ownerShipDetailsReset, resetUpdateOwnerShip } from '../../redux/actions/ownership.actions';
import { getDataset } from '../../redux/actions/dataset-action';
import { ownerShipSearch } from "../../redux/actions/person.actions";

import { Input, Col, Row, Form, Button, Checkbox, Spin, ConfigProvider, DatePicker, List } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { dateFormat, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../utils/config';
import { compareDate, focusDateInput, blurDateInput } from '../../utils/commonFormFunctions';

import DynamicToastMessage from "../dynamicToastMessage";
import moment from 'moment';
import 'moment/locale/tr';

function OwnerShipDetails(props) {
    const { t } = useTranslation();
    const { setOwnerShipDetailsDrawer, ownershipDetails, ownershipDetailsLoading, setOwnerShipReturnDrawer,
        setReturnComponentKey, disableStatus, updateOwnerShip, selectedOwnerShipResourceId, ownerShipDetailsReset,
        resetUpdateOwnerShip, ownerShipUpdateResponse, getOwnerShipDetails, ownerShipSearch, selectedPersonId, ownerShipInitialData } = props;
    const [form] = Form.useForm();
    const [ownerShipDetailsState, setOwnerShipDetailsState] = useState();
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "assigmentDate": dateFormatList,
    });
    const [updateCountState, setUpdateCountState] = useState(0);

    const destroyLastState = () => {
        form.resetFields();
        setOwnerShipDetailsState({});
        ownerShipDetailsReset();
        setOwnerShipDetailsDrawer(false);
        if (updateCountState > 0) {
            ownerShipSearch(selectedPersonId, ownerShipInitialData);
        }
    }
    const openReturnDrawer = () => {
        setOwnerShipReturnDrawer(true)
        setReturnComponentKey((i) => i + 1)
    }
    const handleValuesChange = (changedObj) => {
        const key = Object.keys(changedObj)[0];
        if (key === 'assigmentDate') {
            if (changedObj.assigmentDate) {
                setOwnerShipDetailsState({ ...ownerShipDetailsState, 'assigmentDate': moment(changedObj.assigmentDate).format(dateFormat) });
            } else {
                setOwnerShipDetailsState({ ...ownerShipDetailsState, 'assigmentDate': null });
            }
        } else {
            setOwnerShipDetailsState({ ...ownerShipDetailsState, ...changedObj });
        }
    }
    const handleUpdateOwnerShip = () => {
        const updateObj = {
            "assigmentDate": ownerShipDetailsState.assigmentDate && ownerShipDetailsState.assigmentDate !== 'Invalid date' ? moment(ownerShipDetailsState.assigmentDate).format(dateFormat) : null,
            "assigmentDescription": ownerShipDetailsState.assigmentDescription || null
        };
        let hasError = false;
        let fields = [];
        if ((!updateObj.assigmentDate || updateObj.assigmentDate === '')) {
            hasError = true
            fields.push(t('DEBIT_DATE'));
        }
        if ((!updateObj.assigmentDescription || updateObj.assigmentDescription === '')) {
            hasError = true
            fields.push(t('DEBIT_DESCRIPTION'));
        }
        if (!hasError) {
            updateOwnerShip(selectedOwnerShipResourceId, updateObj);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    useEffect(() => {
        const setObj = {
            "inventoryType": ownershipDetails.inventoryType?.displayValue || null,
            "inventory": ownershipDetails.inventory?.displayValue || null,
            "assigmentPerson": ownershipDetails.assigmentPerson?.displayValue || null,
            "assigmentDate": ownershipDetails.assigmentDate ? moment(ownershipDetails.assigmentDate) : null,
            "returnDate": ownershipDetails.returnDate ? moment(ownershipDetails.returnDate) : null,
            "inventoryStatus": ownershipDetails.inventoryStatus?.displayValue || null,
            "assigmentDocumentId": ownershipDetails.assigmentDocumentId || null,
            "assigmentDescription": ownershipDetails.assigmentDescription || null,
            "returnDescription": ownershipDetails.returnDescription || null,
            "returnPerson": ownershipDetails.returnPerson?.displayValue || null,
            "isMaintanenceNeeded": ownershipDetails.isMaintanenceNeeded || null,
            "accessoryInventories": ownershipDetails.accessoryInventories || null,
        }
        setOwnerShipDetailsState(ownershipDetails);
        if (ownershipDetails.inventoryType?.displayValue) {
            form.setFieldsValue(setObj);
        }
    }, [ownershipDetails])
    useEffect(() => {
        if (ownerShipUpdateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'ownership_update_success_notification',
                'title': t('SUCCESS'),
                'description': ownerShipUpdateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            setUpdateCountState(updateCountState + 1);
            getOwnerShipDetails(selectedOwnerShipResourceId);
            resetUpdateOwnerShip();
        } else if (ownerShipUpdateResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'ownership_update_fail_notification',
                'title': t('ERROR'),
                'description': ownerShipUpdateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetUpdateOwnerShip();
        }
    }, [ownerShipUpdateResponse])
    return (
        <div className="table-details">
            <Col span={24}>
                <Row>
                    <Col span={18}>
                        <div className="back-drawer">
                            <Button onClick={() => destroyLastState()} className="transparent-btn" icon={<UilArrowLeft />}>{t('PARTNER_RETURN_OWNERSHIP')}</Button>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="close-icon">
                            <CloseOutlined onClick={() => destroyLastState()} />
                        </div>
                    </Col>
                </Row>
                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    {ownershipDetailsLoading ?
                        <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                        :
                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark={false}
                            onValuesChange={handleValuesChange}
                        >
                            <div className="pad-25" />
                            <Row gutter={[32, 0]}>
                                <Col span={ownershipDetails.returnDate ? 12 : 24}>
                                    <div className="form-left-body">
                                        <Form.Item name="inventoryType" className="custom-select" label={t('DEBIT_INVENTORY_TYPE')} required >
                                            <Input
                                                disabled={true}
                                            />
                                        </Form.Item>
                                        <Form.Item name="inventory" className="custom-select" label={t('PARTNER_DEVICE_NAME')} required >
                                            <Input
                                                disabled={true}
                                            />
                                        </Form.Item>
                                        <Form.Item name="inventoryStatus" className="custom-select" label={t('INVENTORY_STATUS')} required >
                                            <Input
                                                disabled={true}
                                            ></Input>
                                        </Form.Item>
                                        <Form.Item name="assigmentDate" label={t('PARTNER_ASSIGNMENT_DATE') + " *"}>
                                            <DatePicker
                                                disabled={!disableStatus && !ownershipDetails.status?.id === "2" && !ownershipDetails.isActive}
                                                className={!compareDate(ownershipDetails?.assigmentDate, ownerShipDetailsState?.assigmentDate) ? 'is-changed' : ''}
                                                format={datePickerFormatType.assigmentDate}
                                                locale={localeLang}
                                                placeholder={t('PLACEHOLDER_DATE')}
                                                onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'assigmentDate', dynamicDateInputFormatter)}
                                                onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'assigmentDate', dateFormatList)}
                                            />
                                        </Form.Item>
                                        <Form.Item name="assigmentDescription" label={t('PARTNER_TEXT_DESCRIPTION')} required >
                                            <Input.TextArea
                                                disabled={!disableStatus && !ownershipDetails.status?.id === "2" && !ownershipDetails.isActive}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                {ownershipDetails.returnDate ?

                                    <Col span={12}>
                                        <div className="form-right-body">

                                            <Form.Item name="returnDate" label={t('PARTNER_RETURN_DATE')}>
                                                <Input
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item name="returnPerson" label={t('PARTNER_RETURN_USER')}>
                                                <Input
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item name="returnDescription" label={t('DEBIT_RETURN_DESCRIPTION')} required >
                                                <Input.TextArea
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item name="isMaintanenceNeeded" label="" valuePropName="checked">
                                                <Checkbox disabled={true} >{t('DEBIT_MAINTANENCE_NEEDED')}</Checkbox>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    : ''}
                            </Row>
                        </Form>
                    }
                </ConfigProvider>
            </Col >
            {!ownershipDetailsLoading && ownershipDetails.status?.id === "2" && ownershipDetails.isActive === true && disableStatus !== true ?
                <div className="fixed-btn">
                    <div className="page-end">
                        <div className="hr-end"></div>
                    </div>

                    <div className="form-submit-space-between">
                        <Button onClick={() => destroyLastState()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                        <div className="d-flex">
                            <Button onClick={() => openReturnDrawer()} className="white-btn w-180 h-40 f-14">{t('PARTNER_GET_A_REFUND')}</Button>
                            <div className='margin-r-12' />
                            <Button onClick={handleUpdateOwnerShip} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </div>
                    </div>
                </div>
                : ''}
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        inventoryType: state.inventoryType.inventoryTypes.results,
        ownershipDetails: state.ownership.ownershipDetails,
        ownershipDetailsLoading: state.ownership.ownershipDetailsLoading,
        inventorySelectData: state.inventory.inventorySelectData,
        assigmentStateTypes: state.dataset.assigmentStateTypes,
        ownerShipUpdateResponse: state.ownership.ownerShipUpdateResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setOwnerShipDetailsDrawer: (props) => { dispatch(setOwnerShipDetailsDrawer(props)) },
        getInventoryTypes: (searchData) => { dispatch(getInventoryTypes(searchData)) },
        getInventoryByInventoryType: (id) => { dispatch(getInventoryByInventoryType(id)) },
        getDataset: (props) => { dispatch(getDataset(props)) },
        setOwnerShipReturnDrawer: (props) => { dispatch(setOwnerShipReturnDrawer(props)) },
        getOwnerShipDetails: (resourceId) => { dispatch(getOwnerShipDetails(resourceId)) },
        updateOwnerShip: (resourceId, updateData) => { dispatch(updateOwnerShip(resourceId, updateData)) },
        ownerShipDetailsReset: () => { dispatch(ownerShipDetailsReset()) },
        resetUpdateOwnerShip: () => { dispatch(resetUpdateOwnerShip()) },
        ownerShipSearch: (personId, initialData) => { dispatch(ownerShipSearch(personId, initialData)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OwnerShipDetails);