import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getTravelHostApprovalList, getTravelHostDetails, travelHostChangeStatus, getTravelHostDetailStepList,
    travelHostApprovalsTableExport, resetTravelHostApprovalsTableExport
} from '../../redux/actions/travelHost.actions';
import { Button, Dropdown, Input, ConfigProvider, Form } from 'antd';
import { UilCheckCircle, UilMinusCircle, UilImport } from '@iconscout/react-unicons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage';
import ExportModal from '../dynamicModal/exportModal';
import Can from "../../authorization/index";

import { useWindowDimensions, dateFormatConvert } from '../../utils/config';
import { IsJsonString } from '../../utils/commonFormFunctions';

function ApprovalTravelHostRequestTable(props) {

    const { t } = useTranslation();
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const { approvalRequestsInitialSearch, approvalRequestsSearchState, setApprovalRequestsSearchState,
        openDetailsDrawer, getTravelHostDetails, setSelectItem, travelHostApprovalLoading,
        getTravelHostApprovalList, travelHostApprovalList, totalNumberOfRecords, travelHostChangeStatus,
        travelHostChangeStatusResponse, currentTab, getTravelHostDetailStepList, travelHostApprovalsTableExport,
        travelHostApprovalExportDataResponse, resetTravelHostApprovalsTableExport } = props;

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false })
    const [rejectState, setRejectState] = useState({})

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false })
    const [approveState, setApproveState] = useState({})

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportState, setExportState] = useState({ ...approvalRequestsSearchState, exportType: 0 })

    const handleApproveConfirm = () => {
        travelHostChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        travelHostChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        setApproveState({});
        approveForm.resetFields();
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false });
        setRejectState({});
        rejectForm.resetFields();
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const handleTravelHostChangeStatus = (status, resourceId) => {
        if (status === "APPROVE") {
            setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': resourceId })
        } else {
            setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': resourceId })
        }
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );

    useEffect(() => {
        if (approvalRequestsSearchState !== approvalRequestsInitialSearch) {
            getTravelHostApprovalList(approvalRequestsSearchState);
        }
    }, [approvalRequestsSearchState]);

    useEffect(() => {
        if (currentTab === "TravelHostRequests") {
            if (travelHostChangeStatusResponse.status === 'SUCCESS') {
                setRejectModalData({ 'isVisible': false });
                setRejectState({});
                rejectForm.resetFields();

                setApproveModalData({ 'isVisible': false });
                setApproveState({});
                approveForm.resetFields();
            }
        }
    }, [travelHostChangeStatusResponse])
    useEffect(() => {
        if (travelHostApprovalExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': IsJsonString(travelHostApprovalExportDataResponse.message) ? JSON.parse(travelHostApprovalExportDataResponse.message).message : t('ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTravelHostApprovalsTableExport();
        }
    }, [travelHostApprovalExportDataResponse])
    const handleSaveGridSettings = (d) => { }
    const handleResetAllfilters = () => {
        setExportState({ ...approvalRequestsInitialSearch, exportType: 0 });
        setApprovalRequestsSearchState({ ...approvalRequestsInitialSearch });
    }
    const handleTableFilter = (data) => {
        setApprovalRequestsSearchState({ ...approvalRequestsSearchState, "stateTypes": data.stateType });
        setExportState({ ...exportState, "stateTypes": data.stateType });
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...approvalRequestsSearchState.pagination, "pageNumber": 1 }
        setApprovalRequestsSearchState({ ...approvalRequestsSearchState, "SearchTerm": e, "pagination": pagingObj })
        setExportState({ ...exportState, "SearchTerm": e });
    }
    const showDetails = (id) => {
        if (id !== undefined) {
            setSelectItem({ 'resourceId': id })
            openDetailsDrawer();
            getTravelHostDetails(id);
            getTravelHostDetailStepList(id);
        }
    }
    const data = travelHostApprovalList.length > 0 ? travelHostApprovalList.map((row) => {
        return (
            {
                "key": row?.resourceId,
                "createdDate": { "text": moment(row.createdDate).format(dateFormatConvert), "id": row?.resourceId },
                "requestType": row.requestType,
                "description": row.description,
                "stateType": { "id": row.stateTypeId, "displayValue": row.stateTypeName, 'resourceId': row?.resourceId },
            }
        );
    }) : [];
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType'] : [],
        sortableColumns: ['createdPerson', 'createdDate', 'requestType', 'stateType'],
        visibilityOptionExcludes: ['createdPerson', 'createdDate', 'requestType', 'stateType'],
        page: "ApprovalTravelHostRequest",
        leftActionMenu: totalNumberOfRecords > 0 ? leftActionMenu() : null,
        showVisibilityMenu: false,
    });
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']

    });
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...approvalRequestsSearchState.pagination, 'isChanged': true, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : approvalRequestsSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setApprovalRequestsSearchState({ ...approvalRequestsSearchState, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }
    function handleExportDocument(props) {
        travelHostApprovalsTableExport({
            ...exportState,
            'exportType': props,
            'pagination': {
                "pageNumber": 1,
                "pageSize": 10000,
                "orderBy": "NeedDate",
                "ascending": exportState.pagination.ascending
            }
        });
        setIsModalVisible(false);
    }
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.export">
                        <Button onClick={() => setIsModalVisible(true)} className="flex-class svg-class white-btn h-32 w-auto m-w-180"><UilImport />  {t('SETTINGS_EXPORT')}</Button>
                    </Can>
                }
            </div>
        )
    }
    return (
        <>
            <ExportModal
                onCancel={() => { setIsModalVisible(false) }}
                status={isModalVisible}
                handleExportDocument={handleExportDocument}
            />
            <DynamicRequestModal
                modalVisible={rejectModalData?.isVisible}
                setStateData={setRejectModalData}
                handleConfirm={handleRejectConfirm}
                handleCancel={handleRejectCancel}
                confirmData={rejectModalData?.id}
                isForm={rejectModalForm}
                iconName='UilTimesCircle'
                modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                description={t('TRAVEL_HOST_REJECT_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
            />
            <DynamicRequestModal
                modalVisible={approveModalData?.isVisible}
                setStateData={setApproveModalData}
                handleConfirm={handleApproveConfirm}
                handleCancel={handleApproveCancel}
                confirmData={approveModalData?.id}
                isForm={approveModalForm}
                iconName='UilCheckCircle'
                modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                description={t('TRAVEL_HOST_APPROVE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
            />
            <div className="table-container">
                <Media query="(min-width: 875px)" >
                    {matches => {
                        const columns = [
                            {
                                title: `${t('ORGANIZATION_STATU')}`,
                                dataIndex: 'stateType',
                                key: 'stateType',
                                sorter: true,
                                render: state => {
                                    if (state.id === 1) {
                                        return <div className="flex-class w-100" >
                                            <span className='yellow  flex-align-center'>
                                                {state.displayValue}
                                            </span>
                                            <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                                <Button className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleTravelHostChangeStatus('APPROVE', state.resourceId)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                                <Button className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleTravelHostChangeStatus('REJECT', state.resourceId)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                            </div>
                                        </div>
                                    } else if (state.id === 2) {
                                        return <div>{state.displayValue}</div>
                                    } else if (state.id === 3) {
                                        return <div className="flex-class w-100" >
                                            <span className='red  flex-align-center'>
                                                {state.displayValue}
                                            </span>

                                        </div>
                                    } else if (state.id === 4) {
                                        return <div className="flex-class w-100" >
                                            <span className='red  flex-align-center'>
                                                {state.displayValue}
                                            </span>

                                        </div>
                                    } else {
                                        return <div>{state?.displayValue}</div>
                                    }
                                }
                            },
                            {
                                title: `${t('REQUEST_TYPE')}`,
                                dataIndex: 'requestType',
                                key: 'requestType',
                                sorter: true,
                                render: id => {
                                    switch (id) {
                                        case 0:
                                            return t('TRANSPORTATION')
                                        case 1:
                                            return t('ACCOMMODATION')
                                        case 2:
                                            return t('TRANSPORTATION_AND_ACCOMMODATION')
                                        default:
                                            return ''
                                    }
                                }
                            },
                            // {
                            //     title: `${t('PARTNER_DESCRIPTION')}`,
                            //     dataIndex: 'description',
                            //     key: 'description',
                            //     sorter: true,
                            //     render: text => {
                            //         if (text) {
                            //             if (text.length > 10) {
                            //                 return text.slice(0, 10) + '...';
                            //             } else {
                            //                 return text
                            //             }
                            //         }

                            //     }
                            // },
                        ];
                        if (matches) {
                            columns.splice(0, 0,
                                {
                                    title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
                                    dataIndex: 'createdDate',
                                    key: 'createdDate',
                                    sorter: true,
                                    render: obj => {
                                        return (
                                            <div className="imageNameActionColumn">
                                                <a onClick={() => showDetails(obj.id)} class="details-text mar-0" >{obj.text}</a>
                                                <Dropdown
                                                    className="action-menu"
                                                    overlay={null}
                                                    trigger={['click']}
                                                >
                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                                </Dropdown>
                                            </div>
                                        )
                                    }
                                }
                            );
                        }
                        return <DynamicTable
                            columns={columns} dataSource={data}
                            tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                            onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                            searchPlaceholder=""
                            onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                            onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                            searchBox={true}
                            checkboxStatus={false}
                            passiveButton={false}
                            loadStatus={travelHostApprovalLoading}
                        />
                    }}
                </Media>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        travelHostApprovalLoading: state.travelHostReducer.travelHostApprovalLoading,
        travelHostApprovalList: state.travelHostReducer.travelHostApprovalList.results,
        totalNumberOfRecords: state.travelHostReducer.travelHostApprovalList.totalNumberOfRecords,
        travelHostChangeStatusResponse: state.travelHostReducer.travelHostChangeStatusResponse,
        travelHostApprovalExportDataResponse: state.travelHostReducer.travelHostApprovalExportDataResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTravelHostApprovalList: (searchData) => { dispatch(getTravelHostApprovalList(searchData)) },
        getTravelHostDetails: (id) => { dispatch(getTravelHostDetails(id)) },
        getTravelHostDetailStepList: (id) => { dispatch(getTravelHostDetailStepList(id)) },
        travelHostChangeStatus: (data, type) => { dispatch(travelHostChangeStatus(data, type)) },
        travelHostApprovalsTableExport: (props) => { dispatch(travelHostApprovalsTableExport(props)) },
        resetTravelHostApprovalsTableExport: () => { dispatch(resetTravelHostApprovalsTableExport()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApprovalTravelHostRequestTable);
