import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRoles } from '../../../redux/actions/roles.actions';

import { Form, Row, Col, ConfigProvider, Checkbox, Alert, Button } from 'antd';

function Authorization(props) {

    const { setCreateObject, getRoles, roles, corporateEmail, goToCorporateEmail } = props;
    const { t } = useTranslation();
    const [authorizationState, setAuthorizationState] = useState({})
    const [roleCheckboxList, setRoleCheckboxList] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getRoles();
    }, [])

    useEffect(() => {
        const cbList = roles.map((item) => { return { "label": item.roleName, "value": item.resourceId, ...item } })
        setRoleCheckboxList(cbList)
    }, [roles])

    useEffect(() => {
        form.setFieldsValue({
            "Roles": []
        })
    }, [form])

    useEffect(() => {
        if (corporateEmail === '') {
            const tempform = form.getFieldsValue();
            tempform["Roles"] = [];
            tempform["canLogin"] = false
            form.setFieldsValue({ ...tempform });
            setAuthorizationState({ ...authorizationState, "canLogin": false, "Roles": [] });
        }
    }, [corporateEmail]);

    const handleValuesChange = (changedObj) => {

        if (Object.keys(changedObj)[0] === "Roles") {

            var activeRole = changedObj["Roles"];
            if (activeRole.at(-1) === "FJWGjMrBwDDCP3M") {
                activeRole = activeRole.filter((filterData) => (filterData === "FJWGjMrBwDDCP3M" || filterData === "p3PmYkNDeb8tvEs"));
            } else {
                activeRole = activeRole.filter((filterData) => filterData !== "FJWGjMrBwDDCP3M");
            }

            const newRoles = roles.map((obj) => {
                if (activeRole.includes(obj.resourceId)) {
                    return { ...obj, "hasAssign": true }
                } else {
                    return { ...obj, "hasAssign": false }
                }
            })
            const fields = form.getFieldsValue()
            const projects = { ...fields };
            projects["Roles"] = activeRole
            form.setFieldsValue(projects)
            setAuthorizationState({ ...authorizationState, "Roles": newRoles });

        } else {
            if (changedObj["canLogin"] === false) {
                const tempform = form.getFieldsValue();
                tempform["Roles"] = [];
                form.setFieldsValue({ ...tempform });
                setAuthorizationState({ ...authorizationState, "canLogin": changedObj["canLogin"], "Roles": [] });
            } else {
                const checkedRoles = roles.filter(item => item.isDefaultRole)
                const checkedList = checkedRoles ? checkedRoles.map(item => item.resourceId) : [];
                const tempform = form.getFieldsValue();
                tempform["Roles"] = checkedList;
                form.setFieldsValue({ ...tempform });
                const newRoles = roles.map((obj) => {
                    if (checkedList.includes(obj.resourceId)) {
                        return { ...obj, "hasAssign": true }
                    } else {
                        return { ...obj, "hasAssign": false }
                    }
                })
                setAuthorizationState({ ...authorizationState, "canLogin": changedObj["canLogin"], "Roles": newRoles });
            }
        }

    }

    useEffect(() => {
        setCreateObject(authorizationState)
    }, [authorizationState]);


    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            {!corporateEmail ? (
                <Alert
                    className="pad-bottom-10"
                    description={t('NEED_CORPORATE_EMAIL_FOR_AUTH')}
                    type="info"
                    action={
                        <Button className="ant-btn primary-btn h-32" onClick={goToCorporateEmail}>
                            {t("GO_TO_CORPORATE_EMAIL_FIELD")}
                        </Button>
                    }
                />

            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                >
                    <div className="border-none">
                        <Form.Item name="canLogin" valuePropName="checked">
                            <Checkbox>{t('CAN_LOGIN_LBL')}</Checkbox>
                        </Form.Item>
                        {authorizationState.canLogin && (
                            <Form.Item name="Roles" label={t('SETTINGS_ROLES')}>
                                <Checkbox.Group>
                                    {roleCheckboxList.map(item => {
                                        return (
                                            <Row className="w-100" key={item.resourceId}>
                                                <Col size={24}>
                                                    <Checkbox value={item.value} disabled={item.isDefaultRole}>{item.label}</Checkbox>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        )}

                    </div>

                </Form>)}
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        roles: state.roles.roles
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: () => { dispatch(getRoles()) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);