import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getRoles, search } from '../../redux/actions/person.actions';
import { getGridSetting, createGridSettings, settingsReset } from '../../redux/actions/setting.actions';
import {
    getPerson, getUserRoles, getConnectedPersons, exportPersons, deletePersonResponseReset,
    resetSalaryList, resetJobHistory, deletePersonControl, doActivePersonReset
} from '../../redux/actions/person.actions';
import { employeeTable, resetEmployeeReportDownload, employeeReportDownload } from '../../redux/actions/report.actions';
import { getPersonOrgChart, getPersonWorkHours, getDepartments, getWorkHourDetailsByDefinitions, getPersons } from '../../redux/actions/organization.actions';
import { setConnectedpersonsOpenDraw, setPersonAddOpenDraw, setPersonDeleteDrawer } from '../../redux/actions/person-detail-tab-action';
import { getDataset } from '../../redux/actions/dataset-action';
import { getJobList } from '../../redux/actions/job.actions';
import { getProvinces } from '../../redux/actions/address.actions.js';

import { Button, Image, Dropdown, Layout, Col, ConfigProvider, Form, DatePicker, Spin } from 'antd';
import { ToastContainer } from 'react-toastify';
import { MoreOutlined, ArrowRightOutlined } from '@ant-design/icons';

import Media from "react-media";
import moment from 'moment';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

import BreadCrumb from '../../features/breadcrump/breadcrump';
import DynamicTable from '../../component/dynamicTable/index';
import DrawerTabs from '../../pages/person/personDrawerTabs';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import { personListDropdown } from '../../features/global-dropdown-menu/index';
import { formatPhoneNumber, nationalIdentityNumberMask, NumberList, compareObjects } from '../../utils/commonFormFunctions';
import { useWindowDimensions, localeLang, dateFormat } from '../../utils/config';

import '../../styles/reports.scss';
import DynamicSelectbox from "../../component/dynamicSelectbox";

function EmployeeSummary(props) {
    const { t } = useTranslation();
    const { Content } = Layout;

    const { getPerson, getUserRoles, getRoles, search, getGridSetting, createGridSettings, deletePersonResponse, deletePersonResponseReset,
        createGridSettingsResponse, gridSettings, totalNumberOfRecords, setConnectedpersonsOpenDraw, resetSalaryList, resetJobHistory,
        getConnectedPersons, getPersonOrgChart, exportPersons, settingsReset, profile, permissions, dataset, getDataset, getDepartments,
        deletePersonControl, deletePersonControlResponse, setPersonDeleteDrawer, personDeleteDrawer, doActivePersonResponse, doActivePersonReset, departments,
        workHourDetailsByDefinitions, getWorkHourDetailsByDefinitions, getJobList, jobList, provinces, getProvinces, getPersons, persons, employeeTable, employeeTableResponse,
        employeeTableResponseLoading, reportPersonExportDataResponse, resetEmployeeReportDownload, employeeReportDownload } = props;
    const emptyData = {
        'stateType': [],
        'maritialStatus': [],
        'person': [],
        'militaryStatus': [],
        'departments': [],
        'workHourDefinition': [],
        'education': [],
        'childrentCount': [],
        'title': [],
        'gender': [],
        'addressCity': [],
        'managerPerson': { id: '', displayValue: '' },
        'bloodGroup': [],
        'careerYear': [],
        'workDate': null,
        'workDate': null,
        'corporateEmail': null,
        'tcNumber': null,
    }
    const initialSearchData = {
        ...emptyData, "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CorporateEmail",
            "ascending": true
        }
    }
    const initialGetGridSettingData = {
        pageId: 9, //persons report
        gridId: 1, //persons grid
    }
    const [searchData, setSearchData] = useState(initialSearchData);
    const [isPersonDetailDrawerVisible, setIsPersonDetailDrawerVisible] = useState(false);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false });
    const [isShowPassives, setIsShowPassives] = useState(false);
    const [personStatusTypeList, setPersonStatusTypeList] = useState([]);
    const focusOnEmail = false;
    const [maritalList, setMaritalList] = useState([]);
    const [militaryServiceTypesList, setMilitaryServiceTypesList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [workHourList, setWorkHourList] = useState([]);
    const [educationDegreeList, setEducationDegreeList] = useState([]);
    const childirenList = NumberList(10);
    const [jobListState, setJobListState] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [personListState, setPersonListState] = useState([]);
    const [bloodGroupList, setBloodGroupList] = useState([]);

    const [filterState, setFilterState] = useState(emptyData);
    const [isOpen, setIsOpen] = useState(false);

    const { width } = useWindowDimensions();
    const componentKey = 0;
    const [form] = Form.useForm();
    const emailInput = React.useRef(null);
    const { RangePicker } = DatePicker;
    const careerYearList = [
        {
            'id': '0-1',
            'displayValue': '0-1'
        },
        {
            'id': '1-3',
            'displayValue': '1-3'
        },
        {
            'id': '4-6',
            'displayValue': '4-6'
        },
        {
            'id': '7-9',
            'displayValue': '7-9'
        },
        {
            'id': '10-14',
            'displayValue': '10-14'
        },
        {
            'id': '15+',
            'displayValue': '15+'
        }
    ]
    // const [numberOfMarriedAndSingleState, setNumberOfMarriedAndSingleState] = useState({
    //     series: [],
    //     options: {
    //         chart: {
    //             type: 'donut',
    //         },
    //         responsive: [{
    //             breakpoint: 480,
    //             options: {
    //                 chart: {
    //                     width: 200,
    //                 },
    //                 legend: {
    //                     position: 'bottom'
    //                 }
    //             }
    //         }],
    //         labels: [],
    //     },
    // });
    // const colorArray = ['#39B2AC', '#FDBA31', '#FAA724', '#84bad6']
    // const paiChartLegendOptions = {
    //     show: true,
    //     showForSingleSeries: false,
    //     showForNullSeries: true,
    //     showForZeroSeries: true,
    //     position: 'bottom',
    //     horizontalAlign: 'center',
    //     floating: false,
    //     fontSize: '14px',
    //     fontFamily: "'Roboto', sans-serif ",
    //     fontWeight: 400,
    //     formatter: undefined,
    //     inverseOrder: false,
    //     width: undefined,
    //     height: undefined,
    //     tooltipHoverFormatter: undefined,
    //     customLegendItems: [],
    //     offsetX: 0,
    //     offsetY: 0,
    //     markers: {
    //         width: 12,
    //         height: 12,
    //         strokeWidth: 0,
    //         strokeColor: '#fff',
    //         fillColors: undefined,
    //         radius: 12,
    //         customHTML: undefined,
    //         onClick: undefined,
    //         offsetX: 0,
    //         offsetY: 0
    //     },
    //     itemMargin: {
    //         horizontal: 5,
    //         vertical: 0
    //     },
    //     onItemClick: {
    //         toggleDataSeries: true
    //     },
    //     onItemHover: {
    //         highlightDataSeries: true
    //     },
    // }
    // const responsivePaiChart = [{
    //     breakpoint: 480,
    //     options: {
    //         chart: {
    //             width: 200,
    //         },
    //         legend: {
    //             position: 'bottom'
    //         },
    //     },
    // }]
    // useEffect(() => {
    //     let seriesArray = [];
    //     let labelArray = [];
    //     numberOfMarriedAndSingleList.forEach(element => {
    //     seriesArray.push(5)
    //     labelArray.push('Erkek')
    //     // });
    //     setNumberOfMarriedAndSingleState(
    //         {
    //             series: seriesArray,
    //             options: {
    //                 chart: {
    //                     type: 'donut',
    //                 },
    //                 responsive: responsivePaiChart,
    //                 legend: paiChartLegendOptions,
    //                 labels: labelArray,
    //                 colors: colorArray,
    //             }
    //         }
    //     )
    // }, [])

    useEffect(() => {
        employeeTable(searchData)
    }, [searchData]);

    useEffect(() => {
        getGridSetting(initialGetGridSettingData)
    }, [])

    useEffect(() => {
        if (deletePersonControlResponse?.status === "openModal") {
            setDeleteModalData({ ...deleteModalData, 'isVisible': true })

        } else if (deletePersonControlResponse?.status === "openDrawer") {
            setPersonDeleteDrawer({
                status: true, //Drawer status
                deleteStatus: deleteModalData.deleteStatus,
                id: deleteModalData.id,
                name: deleteModalData.name,
                componentKey: personDeleteDrawer?.componentKey !== undefined ? personDeleteDrawer?.componentKey + 1 : 30
            })
        }
    }, [deletePersonControlResponse])

    const showPersonDetailDrawer = (id) => {
        setIsPersonDetailDrawerVisible(true);
        setSelectedPersonId(id);
        getPerson(id);
        getUserRoles(id);
        getRoles();
        resetSalaryList()
        resetJobHistory()
    }
    const handleSetIsPersonDetailDrawerVisible = (status, id) => {
        setIsPersonDetailDrawerVisible(status)
        if (status === true) {
            getPerson(id);
            getUserRoles(id);
            getRoles();
        }
    }
    const showConnectedPersonsDrawer = (id) => {
        const searchData = {
            "searchTerm": "",
            "pagination": {
                "pageNumber": 1,
                "pageSize": 1000,
                "orderBy": "personName",
                "ascending": true
            }
        }
        getConnectedPersons({ "data": searchData, "resourceId": id });
        setConnectedpersonsOpenDraw({ "status": true, "id": id });
    }
    const canDelete = (id, name) => {
        deletePersonControl(id)
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': true, 'modalType': 'delete' })
    }
    const canPassive = (id, name) => {
        deletePersonControl(id)
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': false, 'modalType': 'passive' })
    }
    const canDismall = (id, name) => {
        deletePersonControl(id)
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': null, 'modalType': 'dismall' })
    }
    const canActive = (id, name) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'deleteStatus': null, 'isVisible': true, 'modalType': 'active' })
    }
    const data = employeeTableResponse ? employeeTableResponse.map((row) => {
        return (
            {
                "key": row.personResourceId,
                "personName": { "text": row.personName, "id": row.personResourceId, "photo": row.photo, "status": row.statusId },
                "statusName": row.statusName,
                "jobTitle": row.jobTitle,
                "departmentName": row.departmentName,
                "corporateEmail": row.corporateEmail,
                "officePhone": formatPhoneNumber(row.officePhone),
                "hireDate": row.hireDate,
                "managerPerson": { "text": row.managerPerson ? row.managerPerson : "", "id": row.managerPersonRId ? row.managerPersonRId : null },
                "connectedPersonCount": { "text": row.connectedPersonCount, "id": row.personResourceId }
            }
        );
    }) : [];
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: ['personName', 'statusName', 'jobTitle', 'departmentName', 'corporateEmail', 'officePhone', 'hireDate', 'managerPerson', 'connectedPersonCount'],
        visibilityOptionExcludes: ['profileImgUrl', 'actionMenu'],
        gridSettings: gridSettings[initialGetGridSettingData.pageId][initialGetGridSettingData.gridId],
        page: "person"
    })
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: totalNumberOfRecords > 10 ? true : false,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'bottomLeft'] : ['none', 'bottomLeft'],
        showTotal: total => t('TOTAL_RECORDS_FOUND', {total : total})
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 }
        setSearchData({ ...searchData, "searchTerm": e, "pagination": pagingObj })
    }
    const handleTableFilter = (data) => { }
    const handleShowPassivesToogle = (e) => {
        setIsShowPassives(e)
        setSearchData({ ...searchData, "showPassiveRecords": e })
    }
    const handleResetAllfilters = () => {
        setIsShowPassives(false)
        setSearchData({
            ...emptyData, "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "CorporateEmail",
                "ascending": true
            }
        })
    }
    const handleSaveGridSettings = (d) => {
        let createObj = { "data": d, "pageId": initialGetGridSettingData.pageId, "gridId": initialGetGridSettingData.gridId }
        createGridSettings(createObj)
    }
    useEffect(() => {
        if (createGridSettingsResponse.status === 'SUCCESS') {
            employeeTable(searchData);
            settingsReset();
        }
    }, [createGridSettingsResponse])
    useEffect(() => {
        if (deletePersonResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'delete_person_success', 'title': t('SUCCESS'), 'description': deletePersonResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            deletePersonResponseReset();
            search(searchData);
            getPersonOrgChart(profile.organization.resourceId);
            setPersonDeleteDrawer({
                status: false,
                deleteStatus: null,
                id: null,
                name: null,
                componentKey: personDeleteDrawer?.componentKey,
            })
        } else if (deletePersonResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'delete_person_error', 'title': t('ERROR'), 'description': deletePersonResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            deletePersonResponseReset();
        }
    }, [deletePersonResponse])

    useEffect(() => {
        if (doActivePersonResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'active_person_success', 'title': t('SUCCESS'), 'description': doActivePersonResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            doActivePersonReset();
            search(searchData);
            getPersonOrgChart(profile.organization.resourceId);

        } else if (doActivePersonResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'delete_person_error', 'title': t('ERROR'), 'description': doActivePersonResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            deletePersonResponseReset();
        }
    }, [doActivePersonResponse])
    useEffect(() => {
        setPersonStatusTypeList(dataset.personStatusTypes);
    }, [dataset.personStatusTypes]);
    useEffect(() => {
        if (focusOnEmail) {
            emailInput.current.inputElement.focus();
        }
    }, [focusOnEmail]);
    useEffect(() => {
        setMaritalList(dataset.maritalStatusTypes);
    }, [dataset.maritalStatusTypes]);
    useEffect(() => {
        setMilitaryServiceTypesList(dataset.militaryServiceTypes);
    }, [dataset.militaryServiceTypes]);
    useEffect(() => {
        setDepartmentList(departments);
    }, [departments]);
    useEffect(() => {
        var tempData = workHourDetailsByDefinitions.definitions.map((data) => {
            return {
                'id': data.definitionId,
                'displayValue': data.name
            }
        })
        setWorkHourList(tempData)
    }, [workHourDetailsByDefinitions])
    useEffect(() => {
        setEducationDegreeList(dataset.educationDegries);
    }, [dataset.educationDegries]);
    useEffect(() => {
        const tempArr = [];
        jobList.map((data) => {
            return tempArr.push({ 'id': data.resourceId, 'displayValue': data.name })
        })
        setJobListState(tempArr)
    }, [jobList])
    useEffect(() => {
        setGenderList(dataset.genders);
    }, [dataset.genders]);
    useEffect(() => {
        setProvinceList(provinces);
    }, [provinces]);
    useEffect(() => {
        setPersonListState(persons)
    }, [persons]);
    useEffect(() => {
        setBloodGroupList(dataset.bloodGroups);
    }, [dataset.bloodGroups]);

    const handleValuesChange = (changedObj, all) => {
        setFilterState({ ...filterState, ...changedObj });
    }
    const resetFilter = () => {
        form.resetFields();
        setFilterState(emptyData)
        setSearchData({
            ...emptyData, "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "CorporateEmail",
                "ascending": true
            }
        });
    }
    const searchAction = () => {
        const stateTypeIdList = filterState?.stateType ? filterState.stateType.map((data => { return parseInt(data.id) })) : [];
        const maritialStatusIdList = filterState?.maritialStatus ? filterState?.maritialStatus.map((data => { return parseInt(data.id) })) : [];
        const personIdList = filterState?.person ? filterState?.person.map((data => { return data.id })) : [];
        const militaryStatusIdList = filterState?.militaryStatus ? filterState?.militaryStatus.map((data => { return parseInt(data.id) })) : [];
        const departmentsIdList = filterState?.departments ? filterState?.departments.map((data => { return data.id })) : [];
        const workHourDefinitionIdList = filterState?.workHourDefinition ? filterState?.workHourDefinition.map((data => { return parseInt(data.id) })) : [];
        const educationIdList = filterState?.education ? filterState?.education.map((data => { return parseInt(data.id) })) : [];
        const childrentCountList = filterState?.childrentCount ? filterState?.childrentCount.map((data => { return parseInt(data.id) })) : [];
        const titleIdList = filterState?.title ? filterState?.title.map((data => { return data.id })) : [];
        const genderIdList = filterState?.gender ? filterState?.gender.map((data => { return parseInt(data.id) })) : [];
        const addressCityIdList = filterState?.addressCity ? filterState?.addressCity.map((data => { return parseInt(data.id) })) : [];

        const bloodGroupIdList = filterState?.bloodGroup ? filterState?.bloodGroup.map((data => { return parseInt(data.id) })) : [];
        const careerYearIdList = filterState?.careerYear ? filterState?.careerYear.map((data => { return data.id })) : [];

        const workStartDate = Array.isArray(filterState?.workDate) ? filterState?.workDate.length == 2 ? moment(filterState?.workDate[0]).format(dateFormat) : null : null
        const workEndDate = Array.isArray(filterState?.workDate) ? filterState?.workDate.length == 2 ? moment(filterState?.workDate[1]).format(dateFormat) : null : null
        const birtDateStart = Array.isArray(filterState?.birthDate) ? filterState?.birthDate.length == 2 ? moment(filterState?.birthDate[0]).format(dateFormat) : null : null
        const birtDateEnd = Array.isArray(filterState?.birthDate) ? filterState?.birthDate.length == 2 ? moment(filterState?.birthDate[1]).format(dateFormat) : null : null

        const filterData = {
            'stateTypeIdList': stateTypeIdList,
            'corporateEmail': filterState?.corporateEmail ? filterState?.corporateEmail : null,
            'maritialStatusIdList': maritialStatusIdList,
            'personIdList': personIdList,
            'militaryStatusIdList': militaryStatusIdList,
            'departmentsIdList': departmentsIdList,
            'workHourDefinitionIdList': workHourDefinitionIdList,
            'educationIdList': educationIdList,
            'childrentCountList': childrentCountList,
            'titleIdList': titleIdList,
            'genderIdList': genderIdList,
            'addressCityIdList': addressCityIdList,
            'managerPersonIdList': filterState?.managerPerson?.id ? [filterState?.managerPerson.id] : [],
            'bloodGroupIdList': bloodGroupIdList,
            'tcNumber': filterState?.tcNumber ? filterState?.tcNumber : null,
            'careerYearList': careerYearIdList,
            'workStartDate': workStartDate,
            'workEndDate': workEndDate,
            'birtDateStart': birtDateStart,
            'birtDateEnd': birtDateEnd,
        }
        setSearchData({
            ...filterData, "pagination": initialSearchData.pagination
        });
    }
    const downloadFile = (type) => {
        employeeReportDownload({ ...searchData, 'exportType': type })
    }
    useEffect(() => {
        if (reportPersonExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': JSON.parse(reportPersonExportDataResponse.message).message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetEmployeeReportDownload();
        }
    }, [reportPersonExportDataResponse])

    const isChanged = () => {
        if (searchData.addressCity?.length > 0 || searchData.bloodGroup?.length > 0 || searchData.careerYear?.length > 0 || searchData.childrentCount?.length > 0 || (searchData.corporateEmail && searchData.corporateEmail !== "") ||
            searchData.departments?.length > 0 || searchData.education?.length > 0 || searchData.gender?.length > 0 || (searchData.managerPersonIdList !== "" && searchData.managerPersonIdList) || searchData.managerPerson?.id !== "" || searchData.maritialStatus?.length > 0 ||
            searchData.militaryStatus?.length > 0 || searchData.person?.length > 0 || searchData.stateType?.length > 0 || (searchData.tcNumber && searchData.tcNumber !== "") || searchData.title?.length > 0 || searchData.workDate || searchData.workHourDefinition?.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <React.Fragment>
                <Layout>
                    <Col span={24}>
                        <Content className="page-container">
                            <BreadCrumb />
                            <div className="tab-menu report-container">
                                <h1 className="page-title">{t('EMPLOYEE_SUMMARY')}</h1>
                                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                                    <div className="w-100 flex-end pad-12">
                                        <Button onClick={() => downloadFile(0)} className="w-120 white-btn margin-r-12">Excel</Button>
                                        <Button onClick={() => downloadFile(2)} className="w-120 white-btn">PDF</Button>
                                    </div>
                                    <div className="filter-container table-details">
                                        {employeeTableResponseLoading ?
                                            <Spin className="filter-loading" ></Spin> :
                                            <>
                                                <Form
                                                    form={form}
                                                    onValuesChange={handleValuesChange}
                                                    requiredMark={true}
                                                    layout="vertical"
                                                >
                                                    <Form.Item name="stateType" className="custom-select" label={t('EMPLOYEE_STATUS')} >
                                                        <DynamicSelectbox
                                                            isMultiple='true'
                                                            optionList={personStatusTypeList}
                                                            onFocus={() => getDataset(24)}
                                                            placeholder={t('EMPLOYEE_STATUS')}
                                                            orderBy={'none'}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="departments" className="custom-select" label={t('PARTNER_DEPARTMENT')}>
                                                        <DynamicSelectbox
                                                            isMultiple='true'
                                                            optionList={departmentList}
                                                            onFocus={() => getDepartments(profile.organization?.resourceId)}
                                                            placeholder={t('PARTNER_DEPARTMENT')}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="title" className="custom-select" label={t('PARTNER_JOB')}>
                                                        <DynamicSelectbox
                                                            isMultiple='true'
                                                            placeholder={t('PARTNER_JOB')}
                                                            optionList={jobListState}
                                                            onFocus={() => getJobList({
                                                                "searchTerm": "",
                                                                "pagination": {
                                                                    "pageNumber": 1,
                                                                    "pageSize": 100,
                                                                    "orderBy": "Name",
                                                                    "ascending": true
                                                                }
                                                            })}
                                                            orderBy={'asc'}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="person" className="custom-select" label={t('NAME_AND_SURNAME')}>
                                                        <DynamicSelectbox
                                                            isMultiple='true'
                                                            optionList={personListState}
                                                            onFocus={() => getPersons(profile.organization?.resourceId)}
                                                            placeholder={t('NAME_AND_SURNAME')}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label=" ">
                                                        <Button onClick={() => setIsOpen(!isOpen)} className="transparent-btn report-show-hide-button">
                                                            {isOpen ? t('HIDE_DETAIL') : t('DETAILED_SEARCH')}
                                                        </Button>
                                                    </Form.Item>
                                                    <div
                                                        className={isOpen ? "filter-details open-collapse" : "filter-details hide-collapse"}>
                                                        <Form.Item name="corporateEmail" label={t('REGISTER_MAIL_LABEL')}>
                                                            <MaskedInput
                                                                ref={emailInput}
                                                                placeholder={t('REGISTER_MAIL_LABEL')}
                                                                className="ant-input"
                                                                mask={emailMask}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="maritialStatus" className="custom-select" label={t('PARTNER_MARITAL_STATUS')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={maritalList}
                                                                onFocus={() => getDataset(11)}
                                                                placeholder={t('PARTNER_MARITAL_STATUS')}
                                                                orderBy={'none'}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="militaryStatus" className="custom-select" label={t('MILITARY_STATUS_TYPE')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={militaryServiceTypesList}
                                                                onFocus={() => getDataset(33)}
                                                                placeholder={t('MILITARY_STATUS_TYPE')}
                                                                orderBy={'none'}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item name="workHourDefinition" className="custom-select" label={t('CHOOSE_WORKING_HOURS')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                placeholder={t('CHOOSE_WORKING_HOURS')}
                                                                optionList={workHourList}
                                                                disallowClear={true}
                                                                onFocus={() => getWorkHourDetailsByDefinitions()}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="education" className="custom-select" label={t('PARTNER_EDUCATION_STATUS')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={educationDegreeList}
                                                                onFocus={() => getDataset(15)}
                                                                placeholder={t('PARTNER_EDUCATION_STATUS')}
                                                                orderBy={'asc'}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="childrentCount" className="custom-select" label={t('PARTNER_CHILDREN_COUNT')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={childirenList}
                                                                placeholder={t('PARTNER_CHILDREN_COUNT')}
                                                                orderBy={'asc'}
                                                            />
                                                        </Form.Item>


                                                        <Form.Item name="gender" className="custom-select" label={t('PARTNER_GENDER')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={genderList}
                                                                onFocus={() => getDataset(2)}
                                                                placeholder={t('PARTNER_GENDER')}
                                                                orderBy={'none'}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="addressCity" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={provinceList}
                                                                placeholder={t('ORGANIZATION_PROVINCE')}
                                                                onFocus={() => getProvinces(224)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="managerPerson" className="custom-select" label={t('PARTNER_MANAGER')}>
                                                            <DynamicSelectbox
                                                                optionList={personListState}
                                                                onFocus={() => getPersons(profile.organization?.resourceId)}
                                                                placeholder={t('PARTNER_MANAGER')}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="bloodGroup" className="custom-select" label={t('PARTNER_BLOOD_GROUP')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={bloodGroupList}
                                                                onFocus={() => getDataset(14)}
                                                                placeholder={t('PARTNER_BLOOD_GROUP')}
                                                                orderBy={'none'}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="tcNumber" label={t('PARTNER_NATIONAL_ID')}>
                                                            <MaskedInput
                                                                className="ant-input"
                                                                placeholder={t('PARTNER_NATIONAL_ID')}
                                                                mask={nationalIdentityNumberMask}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="careerYear" className="custom-select" label={t('CAREER_DURATION')}>
                                                            <DynamicSelectbox
                                                                isMultiple='true'
                                                                optionList={careerYearList}
                                                                // onFocus={() => getDataset(14)}
                                                                placeholder={t('CAREER_DURATION')}
                                                                orderBy={'none'}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="workDate" label={t('PARTNER_START_DATE_OF_JOB')}>
                                                            <RangePicker
                                                                placeholder={[t('PARTNER_START_DATE_OF_JOB'), t('PARTNER_START_DATE_OF_JOB')]}
                                                                locale={localeLang}
                                                                format={"LL"}
                                                                inputReadOnly={true}
                                                                separator={<ArrowRightOutlined />}

                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="birthDate" label={t('PARTNER_BIRTHDAY')}>
                                                            <RangePicker
                                                                placeholder={[t('PARTNER_BIRTHDAY'), t('PARTNER_BIRTHDAY')]}
                                                                locale={localeLang}
                                                                format={"LL"}
                                                                inputReadOnly={true}
                                                                separator={<ArrowRightOutlined />}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </Form>
                                                <div className="filter-button">
                                                    <Button onClick={searchAction} className="w-120 primary-btn margin-r-12">{t('SEARCH')}</Button>
                                                    {isChanged() &&
                                                        <Button onClick={resetFilter} className="w-120 white-btn margin-r-12">{t('PARTNER_CLEAR')}</Button>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </ConfigProvider>

                                {/* <div className="person-report-pai-chart-container">
                                    <div className="person-report-pai-chart-items">
                                        <h2>{t('PARTNER_DEPARTMENT')}</h2>
                                        <ApexChart options={numberOfMarriedAndSingleState.options} series={numberOfMarriedAndSingleState.series} type="donut" height={240} />
                                    </div>
                                    <div className="person-report-pai-chart-items">
                                        <h2>{t('AGE')}</h2>
                                        <ApexChart options={numberOfMarriedAndSingleState.options} series={numberOfMarriedAndSingleState.series} type="donut" height={240} />
                                    </div>
                                    <div className="person-report-pai-chart-items">
                                        <h2>{t('PARTNER_MARITAL_STATUS')}</h2>
                                        <ApexChart options={numberOfMarriedAndSingleState.options} series={numberOfMarriedAndSingleState.series} type="donut" height={240} />
                                    </div>
                                </div> */}
                                <div className="table-container pad-24">
                                    <Media query="(min-width: 875px)" >
                                        {matches => {
                                            const columns = [
                                                {
                                                    title: `${t('PARTNER_NAME_SURNAME')}`,
                                                    dataIndex: 'personName',
                                                    key: 'personName',
                                                    sorter: true,
                                                    render: obj =>
                                                        <div className="imageNameActionColumn">
                                                            <Image className="table-avatar" src={obj.photo || process.env.REACT_APP_URL + 'images/avatar_' + obj.gender?.id + '.png'} preview={false} />
                                                            <Can I="person.read">
                                                                {
                                                                    width >= 875 ?
                                                                        <React.Fragment>
                                                                            <a className="details-text" onClick={() => showPersonDetailDrawer(obj.id)}>{obj.text}</a>

                                                                            <Dropdown
                                                                                className="action-menu"
                                                                                overlay={personListDropdown(t, canDelete, canPassive, canDismall, canActive, obj.id, obj.text, permissions, obj.status)}
                                                                                trigger={['click']}
                                                                            >
                                                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                                            </Dropdown>
                                                                        </React.Fragment>
                                                                        :
                                                                        <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                                                }
                                                            </Can>
                                                            <Cannot I="person.read">
                                                                <span style={{ paddingLeft: '15px' }} >{obj.text}</span>

                                                            </Cannot>
                                                        </div>
                                                },
                                                {
                                                    title: `${t('PARTNER_STATUS')}`,
                                                    dataIndex: 'statusName',
                                                    key: 'statusName',
                                                    sorter: true,
                                                },
                                                {
                                                    title: `${t('PARTNER_JOB')}`,
                                                    dataIndex: 'jobTitle',
                                                    key: 'jobTitle',
                                                    sorter: true,
                                                },
                                                {
                                                    title: `${t('PARTNER_DEPARTMENT')}`,
                                                    dataIndex: 'departmentName',
                                                    key: 'departmentName',
                                                    sorter: true,
                                                },


                                                {
                                                    title: `${t('REPORTS_TO')}`,
                                                    dataIndex: 'managerPerson',
                                                    key: 'managerPerson',
                                                    sorter: true,
                                                    render: obj =>

                                                        <div>
                                                            <Can I="person.read">
                                                                {
                                                                    width >= 875 ?
                                                                        <React.Fragment>
                                                                            <a className="details-text" onClick={() => showPersonDetailDrawer(obj.id)}>{obj.text}</a>
                                                                        </React.Fragment>
                                                                        :
                                                                        <span style={{ paddingLeft: '15px' }} >{obj.text}</span>
                                                                }
                                                            </Can>
                                                            <Cannot I="person.read"><span style={{ paddingLeft: '15px' }} >{obj.text}</span></Cannot>
                                                        </div>

                                                },

                                            ];
                                            if (matches) {
                                                columns.splice(3, 0,
                                                    {
                                                        title: `${t('ORGANIZATION_E_MAIL')}`,
                                                        dataIndex: 'corporateEmail',
                                                        key: 'corporateEmail',
                                                        sorter: true,
                                                    }
                                                );
                                                columns.splice(4, 0,
                                                    {
                                                        title: `${t('PARTNER_OFFICE_PHONE')}`,
                                                        dataIndex: 'officePhone',
                                                        key: 'officePhone',
                                                        sorter: true,
                                                    },
                                                );
                                                columns.splice(5, 0,
                                                    {
                                                        title: `${t('PARTNER_START_DATE_OF_COMPANY')}`,
                                                        dataIndex: 'hireDate',
                                                        key: 'hireDate',
                                                        sorter: true,
                                                        render: obj => {
                                                            return (obj ? moment(obj).format('LL') : '')
                                                        }
                                                    }
                                                );
                                                columns.push({
                                                    title: `${t('PERSON_WHO_RECEIVED_REPORTS')}`,
                                                    dataIndex: 'connectedPersonCount',
                                                    key: 'connectedPersonCount',
                                                    sorter: true,
                                                    render: connectedPersonsCount => { return (connectedPersonsCount.text > 0 ? <a onClick={() => showConnectedPersonsDrawer(connectedPersonsCount.id)} className="details-text">{connectedPersonsCount.text + ' ' + t('DEBIT_PERSON')}</a> : t('NONE')) },
                                                });
                                            }
                                            return <DynamicTable columns={columns} dataSource={data}
                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                                                onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                                                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                                                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                                loadStatus={employeeTableResponseLoading}
                                                checkboxStatus={false}
                                                searchBox={false}
                                                passiveButton={false}
                                            />;
                                        }
                                        }
                                    </Media >


                                </div>
                            </div>
                        </Content>
                    </Col>

                </Layout >
                <ToastContainer />
            </React.Fragment >
            <DrawerTabs
                key={componentKey}
                searchData={searchData}
                selectedPersonId={selectedPersonId}
                isPersonDetailDrawerVisible={isPersonDetailDrawerVisible}
                setIsPersonDetailDrawerVisible={(status, id) => handleSetIsPersonDetailDrawerVisible(status, id)}
                deleteModalData={deleteModalData}
                setDeleteModalData={setDeleteModalData}
                canDelete={canDelete}
                canPassive={canPassive}
                canDismall={canDismall}
                canActive={canActive}
            />

        </React.Fragment >

    )
}

const mapStateToProps = (state) => {
    return {
        persons: state.organization.persons,
        personOrgChart: state.organization.personOrgChart,
        gridSettings: state.setting.gridSettings,
        createGridSettingsResponse: state.setting.createGridSettingsResponse,
        deletePersonResponse: state.person.deletePersonResponse,
        profile: state.profile,
        permissions: state.profile.permissions,
        deletePersonControlLoading: state.person.deletePersonControlLoading,
        deletePersonControlResponse: state.person.deletePersonControlResponse,
        personDeleteDrawer: state.tabreducer.personDeleteDrawer,
        doActivePersonResponse: state.person.doActivePersonResponse,
        dataset: state.dataset,
        departments: state.organization.departments,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions,
        jobList: state.job.jobList.results,
        provinces: state.addressReducer.provinces,
        employeeTableResponse: state.report.employeeTable.results,
        employeeTableResponseLoading: state.report.employeeTableResponseLoading,
        totalNumberOfRecords: state.report.employeeTable.totalNumberOfRecords,
        reportPersonExportDataResponse: state.report.reportPersonExportDataResponse


    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        search: (searchData) => { dispatch(search(searchData)) },
        getPerson: (id) => { dispatch(getPerson(id)) },
        getUserRoles: (id) => { dispatch(getUserRoles(id)) },
        getRoles: () => { dispatch(getRoles()) },
        setConnectedpersonsOpenDraw: (props) => { dispatch(setConnectedpersonsOpenDraw(props)) },
        getConnectedPersons: (id) => { dispatch(getConnectedPersons(id)) },
        getPersonOrgChart: (id) => { dispatch(getPersonOrgChart(id)) },
        exportPersons: (data) => { dispatch(exportPersons(data)) },
        setPersonAddOpenDraw: (props) => { dispatch(setPersonAddOpenDraw(props)) },
        getGridSetting: (props) => { dispatch(getGridSetting(props)) },
        createGridSettings: (props) => { dispatch(createGridSettings(props)) },
        settingsReset: () => { dispatch(settingsReset()) },
        deletePersonResponseReset: () => { dispatch(deletePersonResponseReset()) },
        resetSalaryList: () => { dispatch(resetSalaryList()) },
        resetJobHistory: () => { dispatch(resetJobHistory()) },
        deletePersonControl: (resourceId) => { dispatch(deletePersonControl(resourceId)) },
        setPersonDeleteDrawer: (drawerData) => { dispatch(setPersonDeleteDrawer(drawerData)) },
        doActivePersonReset: () => { dispatch(doActivePersonReset()) },
        getPersonWorkHours: () => { dispatch(getPersonWorkHours()) },
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getDepartments: (id) => { dispatch(getDepartments(id)) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        employeeTable: (data) => { dispatch(employeeTable(data)) },
        resetEmployeeReportDownload: () => { dispatch(resetEmployeeReportDownload()) },
        employeeReportDownload: (data) => { dispatch(employeeReportDownload(data)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSummary);