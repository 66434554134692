import { Layout, Col } from 'antd';
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import '../../styles/tab-menu.scss';
import '../../styles/header.scss';
import '../../styles/nav-menu.scss';
import BreadCrumb from '../../features/breadcrump/breadcrump';


const { Content } = Layout;

function Account() {


    return (

        <Layout>
            <Layout>
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrumb />
                        <h1>Hesabım</h1>

                    </Content >
                </Col>
            </Layout>
        </Layout >



    )

}
export default Account;
