import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getAdvanceManager, getAdvanceDetails, getAdvanceDetailStep, advanceChangeStatus, advanceApprovalsDownload, resetAdvanceApprovalsDownload
} from '../../redux/actions/advance.actions';

import { Button, Dropdown, Input, ConfigProvider, Form } from 'antd';
import { UilCheckCircle, UilMinusCircle, UilImport } from '@iconscout/react-unicons';
import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicToastMessage from "../dynamicToastMessage/index";
import DynamicRequestModal from '../dynamicModal/requestModal';
import ExportModal from '../dynamicModal/exportModal';
import { dateFormatConvert, useWindowDimensions, numberFormatTR } from '../../utils/config';
import { IsJsonString } from '../../utils/commonFormFunctions';

import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";

function ApproveAdvance(props) {
    const { t } = useTranslation();
    const { openAdvanceDetailsDrawer, getAdvanceManager, advanceManagerInitialSearch, currentTab, advanceApprovalExportDataResponse,
        advanceManagerSearchState, setAdvanceManagerSearchState, advanceManagerListLoading, advanceManagerList, totalNumberOfRecords,
        getAdvanceDetails, getAdvanceDetailStep, setSelectAdvance, advanceChangeStatus, advanceChangeStatusResponse, advanceApprovalsDownload,
        resetAdvanceApprovalsDownload } = props
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false })
    const [rejectState, setRejectState] = useState({})

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false })
    const [approveState, setApproveState] = useState({})

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();
    const [exportState, setExportState] = useState({ ...advanceManagerSearchState, exportType: 0 });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleApproveConfirm = () => {
        advanceChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        advanceChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    useEffect(() => {
        if (currentTab === "ApproveAdvance") {
            if (advanceChangeStatusResponse.status === 'SUCCESS') {
                setRejectModalData({ 'isVisible': false })
                setApproveModalData({ 'isVisible': false })
                const fields = approveForm.getFieldsValue()
                const projects = { ...fields };
                projects["description"] = "";
                approveForm.setFieldsValue(projects)
                const fields2 = rejectForm.getFieldsValue()
                const projects2 = { ...fields2 };
                projects["description"] = "";
                rejectForm.setFieldsValue(projects2)
            }
        }
    }, [advanceChangeStatusResponse]);
    useEffect(() => {
        if (advanceApprovalExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': IsJsonString(advanceApprovalExportDataResponse.message) ? JSON.parse(advanceApprovalExportDataResponse.message).message : t('ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetAdvanceApprovalsDownload();
        }
    }, [advanceApprovalExportDataResponse])
    const handleSaveGridSettings = () => { }

    const handleResetAllfilters = () => {
        setExportState({ ...advanceManagerInitialSearch, exportType: 0 });
        setAdvanceManagerSearchState({ ...advanceManagerInitialSearch });
    }
    const handleTableFilter = (data) => {
        setAdvanceManagerSearchState({ ...advanceManagerSearchState, "persons": data.persons, "stateTypes": data.stateType })
        setExportState({ ...exportState, "stateTypes": data.stateType })
    }
    const showAdvanceDetails = (id) => {
        if (id !== undefined) {
            getAdvanceDetails(id);
            getAdvanceDetailStep(id);
            openAdvanceDetailsDrawer();
            setSelectAdvance(id);
        }
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...advanceManagerSearchState.pagination, "pageNumber": 1 }
        setAdvanceManagerSearchState({ ...advanceManagerSearchState, "SearchTerm": e, "pagination": pagingObj });
        setExportState({ ...exportState, 'SearchTerm': e });
    }
    useEffect(() => {
        if (advanceManagerSearchState !== advanceManagerInitialSearch) {
            getAdvanceManager(advanceManagerSearchState);
        }
    }, [advanceManagerSearchState]);

    const data = advanceManagerList.map((row) => {
        return (
            {
                "key": row.resourceId,
                "needDate": { "text": moment(row.needDate).format(dateFormatConvert), "id": row.resourceId },
                "AdvanceMasterTypeId": row.advanceMasterTypeId === 1 ? t('JOB_ADVANCE') : t('SALARY_ADVANCE'),
                "fullName": row.givenName + " " + row.familyName,
                "amountsList": row.amountsList,
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue, "showConfirmButton": row.showConfirmButton, "resourceId": row.resourceId },
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['fullName', 'stateType'] : [],
        sortableColumns: ['needDate', 'AdvanceMasterTypeId', 'fullName', 'stateType'],
        visibilityOptionExcludes: ['needDate', 'AdvanceMasterTypeId', 'amountsList', 'fullName', 'stateType'],
        gridSettings: null,
        page: "approveAdvance",
        leftActionMenu: totalNumberOfRecords > 0 ? leftActionMenu() : null,
        showVisibilityMenu: false,
    })
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })

    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'NewStateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...advanceManagerSearchState.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : advanceManagerSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setAdvanceManagerSearchState({ ...advanceManagerSearchState, 'pagination': paginationObj })
    }
    const handleExportDocument = (props) => {
        advanceApprovalsDownload({
            ...exportState,
            'exportType': props,
            'pagination': {
                "pageNumber": 1,
                "pageSize": 10000,
                "orderBy": "NeedDate",
                "ascending": exportState.pagination.ascending
            }
        });
        setIsModalVisible(false);
    }
    const handleShowPassivesToogle = (e) => { }
    const handleTimeOffChangeStatus = (status, resourceId) => {
        if (status === "APPROVE") {
            setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': resourceId })
        } else {
            setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': resourceId })
        }
    }
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.export">
                        <Button onClick={() => setIsModalVisible(true)} className="flex-class svg-class white-btn h-32 w-auto m-w-180"><UilImport />  {t('SETTINGS_EXPORT')}</Button>
                    </Can>
                }
            </div>
        )
    }
    return (
        <div className="table-container">
            <ExportModal
                onCancel={() => { setIsModalVisible(false) }}
                status={isModalVisible}
                handleExportDocument={handleExportDocument}
            />
            <DynamicRequestModal
                modalVisible={rejectModalData?.isVisible}
                setStateData={setRejectModalData}
                handleConfirm={handleRejectConfirm}
                handleCancel={handleRejectCancel}
                confirmData={rejectModalData?.id}
                isForm={rejectModalForm}
                iconName='UilTimesCircle'
                modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                description={t('ADVANCE_REJECT_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
            />
            <DynamicRequestModal
                modalVisible={approveModalData?.isVisible}
                setStateData={setApproveModalData}
                handleConfirm={handleApproveConfirm}
                handleCancel={handleApproveCancel}
                confirmData={approveModalData?.id}
                isForm={approveModalForm}
                iconName='UilCheckCircle'
                modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                description={t('ADVANCE_APPROVE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
            />

            <Media query="(min-width: 1000px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === '1') {
                                    return <div className="flex-class w-100" >
                                        <span className='yellow  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                        {state.showConfirmButton &&
                                            <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                                <Button className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleTimeOffChangeStatus('APPROVE', state.resourceId)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                                <Button className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleTimeOffChangeStatus('REJECT', state.resourceId)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                            </div>
                                        }
                                    </div>
                                } else if (state.id === '2') {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === '3') {
                                    return <div className='red'>{state.displayValue}</div>
                                } else if (state.id === '4') {
                                    return <div className=''>{state.displayValue}</div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('EMPLOYEE')}`.charAt(0).toUpperCase() + `${t('EMPLOYEE')}`.slice(1).toLowerCase(),
                            dataIndex: 'fullName',
                            key: 'fullName',
                            sorter: false,
                        },
                        {
                            title: `${t('ADVANCE_TYPE')}`,
                            dataIndex: 'AdvanceMasterTypeId',
                            key: 'AdvanceMasterTypeId',
                            sorter: true,
                        },
                        {
                            title: `${t('PARTNER_AMOUNT2')}`,
                            dataIndex: 'amountsList',
                            key: 'amountsList',
                            sorter: true,
                            render: state => {
                                return Array.isArray(state) ? state.length > 0 ?
                                    state.map((data) => numberFormatTR.format(data.amount) + ' ' + data.currencyName).join(', ')
                                    :
                                    ' ' : ''
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
                                dataIndex: 'needDate',
                                key: 'needDate',
                                sorter: true,
                                render: obj =>
                                    <div className="imageNameActionColumn">
                                        <Can I="timeoff.request.read">
                                            <a onClick={() => showAdvanceDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                            <Dropdown className="action-menu" overlay={null} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                            </Dropdown>
                                        </Can>
                                        <Cannot I="timeoff.request.read">
                                            <span >{obj.text}</span>
                                        </Cannot>
                                    </div>
                            }
                        );

                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={advanceManagerListLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        advanceManagerListLoading: state.advance.advanceManagerListLoading,
        advanceManagerList: state.advance.advanceManagerList.results,
        totalNumberOfRecords: state.advance.advanceManagerList.totalNumberOfRecords,
        permissions: state.profile.permissions,
        advanceChangeStatusResponse: state.advance.advanceChangeStatusResponse,
        advanceApprovalExportDataResponse: state.advance.advanceApprovalExportDataResponse,
        profile: state.profile,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAdvanceManager: (searchData) => { dispatch(getAdvanceManager(searchData)) },
        getAdvanceDetails: (resourceId) => { dispatch(getAdvanceDetails(resourceId)) },
        getAdvanceDetailStep: (resourceId) => { dispatch(getAdvanceDetailStep(resourceId)) },
        advanceChangeStatus: (data, type) => { dispatch(advanceChangeStatus(data, type)) },
        advanceApprovalsDownload: (props) => { dispatch(advanceApprovalsDownload(props)) },
        resetAdvanceApprovalsDownload: (props) => { dispatch(resetAdvanceApprovalsDownload(props)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveAdvance);

