import { hrApi } from "../../utils/apiConfig";
import { apiUrl, authUrl } from '../../utils/config'; 
const FileDownload = require('js-file-download');

const OrganizationActionType = {
  GET_ORGANIZATION_REQUEST: "GET_ORGANIZATION_REQUEST",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAIL: "GET_ORGANIZATION_FAIL",
  GET_PERSON_ORG_CHART_REQUEST: "GET_PERSON_ORG_CHART_REQUEST",
  GET_PERSON_ORG_CHART_SUCCESS: "GET_PERSON_ORG_CHART_SUCCESS",
  GET_PERSON_ORG_CHART_FAIL: "GET_PERSON_ORG_CHART_FAIL",
  GET_PARENT_ORGANIZATION_SUCCESS: "GET_PARENT_ORGANIZATION_SUCCESS",
  GET_PARENT_ORGANIZATION_FAIL: "GET_PARENT_ORGANIZATION_FAIL",
  GET_DEPARTMENT_REQUEST:"GET_DEPARTMENT_REQUEST",
  GET_DEPARTMENT_SUCCESS: "GET_DEPARTMENT_SUCCESS",
  GET_DEPARTMENT_FAIL: "GET_DEPARTMENT_FAIL",
  GET_PERSONS_SUCCESS: "GET_PERSONS_SUCCESS",
  GET_PERSONS_FAIL: "GET_PERSONS_FAIL",
  CREATE_ORGANIZATION_SUCCESSS: "CREATE_ORGANIZATION_SUCCESSS",
  CREATE_ORGANIZATION_FAIL:  "CREATE_ORGANIZATION_FAIL",
  CREATE_ORGANIZATION_INPUT_ERROR:"CREATE_ORGANIZATION_INPUT_ERROR",
  CREATE_ORGANIZATION_RESET_STATUS:"CREATE_ORGANIZATION_RESET_STATUS",
  CREATE_DEPARTMENT_SUCCESS: "CREATE_DEPARTMENT_SUCCESS",
  CREATE_DEPARTMENT_FAIL: "CREATE_DEPARTMENT_FAIL",
  CREATE_DEPARTMENT_INPUT_ERROR:"CREATE_DEPARTMENT_INPUT_ERROR",
  CREATE_DEPARTMENT_RESET_STATUS:"CREATE_DEPARTMENT_RESET_STATUS",
  ORGANIZATION_SEARCH_REQUEST:"ORGANIZATION_SEARCH_REQUEST",
  ORGANIZATION_SEARCH_SUCCESS:"ORGANIZATION_SEARCH_SUCCESS",
  ORGANIZATION_SEARCH_FAIL:"ORGANIZATION_SEARCH_FAIL",
  ORGANIZATION_EXPORT_SUCCESS:"ORGANIZATION_EXPORT_SUCCESS",
  ORGANIZATION_EXPORT_FAIL:"ORGANIZATION_EXPORT_FAIL",
  DEPARTMENT_SEARCH_REQUEST:"DEPARTMENT_SEARCH_REQUEST",
  DEPARTMENT_SEARCH_SUCCESS:"DEPARTMENT_SEARCH_SUCCESS",
  DEPARTMENT_SEARCH_FAIL:"DEPARTMENT_SEARCH_FAIL",
  GET_CONNECTED_DEPARTMENT_REQUEST:"GET_CONNECTED_DEPARTMENT_REQUEST",
  GET_CONNECTED_DEPARTMENT_SUCCESS:"GET_CONNECTED_DEPARTMENT_SUCCESS",
  GET_CONNECTED_DEPARTMENT_FAIL:"GET_CONNECTED_DEPARTMENT_FAIL",
  GET_DEPARTMENT_ORG_CHART_REQUEST:"GET_DEPARTMENT_ORG_CHART_REQUEST",
  GET_DEPARTMENT_ORG_CHART_SUCCESS:"GET_DEPARTMENT_ORG_CHART_SUCCESS",
  GET_DEPARTMENT_ORG_CHART_FAIL:"GET_DEPARTMENT_ORG_CHART_FAIL",
  GET_ORGANIZATION_ORG_CHART_REQUEST:"GET_ORGANIZATION_ORG_CHART_REQUEST",
  GET_ORGANIZATION_ORG_CHART_SUCCESS:"GET_ORGANIZATION_ORG_CHART_SUCCESS",
  GET_ORGANIZATION_ORG_CHART_FAIL:"GET_ORGANIZATION_ORG_CHART_FAIL",
  ORGANIZATION_FILTER_REQUEST:"ORGANIZATION_FILTER_REQUEST",
  ORGANIZATION_FILTER_SUCCESS:"ORGANIZATION_FILTER_SUCCESS",
  ORGANIZATION_FILTER_FAIL:"ORGANIZATION_FILTER_FAIL",
  LEGAL_NAME_FILTER_REQUEST:"LEGAL_NAME_FILTER_REQUEST",
  LEGAL_NAME_FILTER_SUCCESS:"LEGAL_NAME_FILTER_SUCCESS",
  LEGAL_NAME_FILTER_FAIL:"LEGAL_NAME_FILTER_FAIL",
  DELETE_LOGO_SUCCESS : "DELETE_LOGO_SUCCESS",
  DELETE_LOGO_FAIL : "DELETE_LOGO_FAIL",
  DELETE_LOGO_RESET:"DELETE_LOGO_RESET",
  GET_ORGANIZATION_WORK_HOURS_REQUEST : "GET_ORGANIZATION_WORK_HOURS_REQUEST",
  GET_ORGANIZATION_WORK_HOURS_SUCCESS : "GET_ORGANIZATION_WORK_HOURS_SUCCESS",
  GET_ORGANIZATION_WORK_HOURS_FAIL : "GET_ORGANIZATION_WORK_HOURS_FAIL",
  UPDATE_ORGANIZATION_WORK_HOURS_SUCCESS : "UPDATE_ORGANIZATION_WORK_HOURS_SUCCESS",
  UPDATE_ORGANIZATION_WORK_HOURS_FAIL : "UPDATE_ORGANIZATION_WORK_HOURS_FAIL",
  UPDATE_ORGANIZATION_WORK_HOURS_RESET : "UPDATE_ORGANIZATION_WORK_HOURS_RESET",
  UPDATE_ORGANIZATION_WORK_INPUT_ERROR:"UPDATE_ORGANIZATION_WORK_INPUT_ERROR",
  GET_ORGANIZATION_DOCUMENT_TYPE_REQUEST : "GET_ORGANIZATION_DOCUMENT_TYPE_REQUEST",
  GET_ORGANIZATION_DOCUMENT_TYPE_SUCCESS : "GET_ORGANIZATION_DOCUMENT_TYPE_SUCCESS",
  GET_ORGANIZATION_DOCUMENT_TYPE_FAIL : "GET_ORGANIZATION_DOCUMENT_TYPE_FAIL",
  GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_REQUEST:"GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_REQUEST",
  GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_SUCCESS:"GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_SUCCESS",
  GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_FAIL:"GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_FAIL",
  SET_ORGANIZATION_WORKHOURS_CREATE_DRAWER:"SET_ORGANIZATION_WORKHOURS_CREATE_DRAWER",
  SET_ORGANIZATION_WORKHOURS_DETAIL_DRAWER:"SET_ORGANIZATION_WORKHOURS_DETAIL_DRAWER",
  GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_REQUEST:"GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_REQUEST",
  GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_SUCCESS:"GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_SUCCESS",
  GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_FAIL:"GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_FAIL",
  GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_REQUEST:"GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_REQUEST",
  GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_SUCCESS:"GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_SUCCESS",
  GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_FAIL:"GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_FAIL",
  GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_REQUEST:"GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_REQUEST",
  GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_SUCCESS:"GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_SUCCESS",
  GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_FAIL:"GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_FAIL",
  DELETE_ORGANIZATION_WORK_HOURS_SUCCESS:"DELETE_ORGANIZATION_WORK_HOURS_SUCCESS",
  DELETE_ORGANIZATION_WORK_HOURS_FAIL:"DELETE_ORGANIZATION_WORK_HOURS_FAIL",
  DELETE_ORGANIZATION_WORK_HOURS_RESET:"DELETE_ORGANIZATION_WORK_HOURS_RESET",
  MAKE_DEFAULT_ORGANIZATION_WORKHOUR_REQUEST:"MAKE_DEFAULT_ORGANIZATION_WORKHOUR_REQUEST",
  MAKE_DEFAULT_ORGANIZATION_WORKHOUR_SUCCESS:"MAKE_DEFAULT_ORGANIZATION_WORKHOUR_SUCCESS",
  MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL:"MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL",
  MAKE_DEFAULT_ORGANIZATION_WORKHOUR_RESET:"MAKE_DEFAULT_ORGANIZATION_WORKHOUR_RESET",
  UPDATE_WORKHOURS_PERSON_CONTROL_REQUEST:"UPDATE_WORKHOURS_PERSON_CONTROL_REQUEST",
  UPDATE_WORKHOURS_PERSON_CONTROL_OPEN_MODAL:"UPDATE_WORKHOURS_PERSON_CONTROL_OPEN_MODAL",
  GET_DELETE_PERSON_CONTROL_PASS:"GET_DELETE_PERSON_CONTROL_PASS",
  UPDATE_WORKHOURS_PERSON_CONTROL_RESET:"UPDATE_WORKHOURS_PERSON_CONTROL_RESET",
  GET_PERSON_WORK_HOURS_REQUEST:"GET_PERSON_WORK_HOURS_REQUEST",
  GET_PERSON_WORK_HOURS_SUCCESS:"GET_PERSON_WORK_HOURS_SUCCESS",
  GET_PERSON_WORK_HOURS_FAIL:"GET_PERSON_WORK_HOURS_FAIL",
  GET_PERSON_WORK_HOURS_RESET:"GET_PERSON_WORK_HOURS_RESET",
  GET_APPROVE_ORGANIZATION_REGISTER_MAIL_REQUEST: "GET_APPROVE_ORGANIZATION_REGISTER_MAIL_REQUEST",
  GET_APPROVE_ORGANIZATION_REGISTER_MAIL_SUCCESS: "GET_APPROVE_ORGANIZATION_REGISTER_MAIL_SUCCESS",
  GET_APPROVE_ORGANIZATION_REGISTER_MAIL_FAIL: "GET_APPROVE_ORGANIZATION_REGISTER_MAIL_FAIL" ,
  GET_ACCOUNT_CONTRACTS_REQUEST: "GET_ACCOUNT_CONTRACTS_REQUEST",
  GET_ACCOUNT_CONTRACTS_SUCCESS: "GET_ACCOUNT_CONTRACTS_SUCCESS",
  GET_ACCOUNT_CONTRACTS_FAIL: "GET_ACCOUNT_CONTRACTS_FAIL",
  GET_PERSON_ORGANIZATIONS_REQUEST: "GET_PERSON_ORGANIZATIONS_REQUEST",
  GET_PERSON_ORGANIZATIONS_SUCCESS: "GET_PERSON_ORGANIZATIONS_SUCCESS",
  GET_PERSON_ORGANIZATIONS_FAIL: "GET_PERSON_ORGANIZATIONS_FAIL",
  ORGANIZATION_UPDATE_PHONE_REQUEST_SUCCESS: "ORGANIZATION_UPDATE_PHONE_REQUEST_SUCCESS",
  ORGANIZATION_UPDATE_PHONE_REQUEST_FAIL: "ORGANIZATION_UPDATE_PHONE_REQUEST_FAIL",
  ORGANIZATION_UPDATE_PHONE_REQUEST_RESET: "ORGANIZATION_UPDATE_PHONE_REQUEST_RESET"
};

const getOrganizations = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_ORGANIZATION_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizations/'+resourceId,
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_FAIL, payload: error.message });
        });

       
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_FAIL, payload: error.message });
      }
    }
  };
};

const getPersonOrgChart = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_PERSON_ORG_CHART_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizations/'+resourceId+'/person-org-chart',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_PERSON_ORG_CHART_SUCCESS, payload: response.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_PERSON_ORG_CHART_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          
          dispatch({ type: OrganizationActionType.GET_PERSON_ORG_CHART_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_PERSON_ORG_CHART_FAIL, payload: error.message });
      }
    }
  };
};

const getDepartmentOrgChart = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_DEPARTMENT_ORG_CHART_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizations/'+resourceId+'/department-org-chart',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_DEPARTMENT_ORG_CHART_SUCCESS, payload: response.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_DEPARTMENT_ORG_CHART_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_DEPARTMENT_ORG_CHART_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_DEPARTMENT_ORG_CHART_FAIL, payload: error.message });
      }
    }
  };
};


const getOrganizationOrgChart = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_ORGANIZATION_ORG_CHART_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizations/'+resourceId+'/org-chart',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_ORG_CHART_SUCCESS, payload: response.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_ORG_CHART_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_ORG_CHART_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_ORG_CHART_FAIL, payload: error.message });
      }
    }
  };
};

const getParentOrganizations = (resourceId) => {
    return async (dispatch) => {
      try {
          var config = {
            method: 'get',
            url:    apiUrl+'/organizations/'+resourceId+'/parent',
            headers: { }
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: OrganizationActionType.GET_PARENT_ORGANIZATION_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: OrganizationActionType.GET_PARENT_ORGANIZATION_FAIL, payload: response.data.data.message });
            }
          })
          .catch(function (error) {
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: OrganizationActionType.GET_PARENT_ORGANIZATION_FAIL, payload: error.response.message });
        }
      }
    };
};

const getDepartments = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_DEPARTMENT_REQUEST });
    try {
        var config = {
          method: 'get',
          url:    apiUrl+'/organizations/'+resourceId+'/departments',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status === 200){
              dispatch({ type: OrganizationActionType.GET_DEPARTMENT_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_DEPARTMENT_FAIL, payload: response.data.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_DEPARTMENT_FAIL, payload: error.response.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_DEPARTMENT_FAIL, payload: error.response.message });
      }
    }
  };
};
const getPersons = (resourceId) => {
  return async (dispatch) => {
    try {
        var config = {
          method: 'get',
          url:    apiUrl+'/organizations/'+resourceId+'/persons',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_PERSONS_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_PERSONS_FAIL, payload: response.data.data.message });
          }
        })
        .catch(function (error) {});
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_PERSONS_FAIL, payload: error.response.message });
      }
    }
  };
};

const personsDepartmentConnect = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_CONNECTED_DEPARTMENT_REQUEST });
    try {
      const data = JSON.stringify(searchData.data);
      const resourceId = searchData.resourceId;
        var config = {
          method: 'post',
          url:    apiUrl+'/departments/'+resourceId+'/persons',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_CONNECTED_DEPARTMENT_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_CONNECTED_DEPARTMENT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = {
                "pageNumber": 1,
                "pageSize": 1,
                "totalNumberOfPages": 1,
                "totalNumberOfRecords": 0,
                "results": []
            }
            dispatch({ type: OrganizationActionType.GET_CONNECTED_DEPARTMENT_SUCCESS, payload: emptyData });
          }
          dispatch({ type: OrganizationActionType.GET_CONNECTED_DEPARTMENT_FAIL, payload: error.message });
        });
      } catch (error) {
        if (error.response) {
            dispatch({ type: OrganizationActionType.GET_CONNECTED_DEPARTMENT_FAIL, payload: error.message });
        }
      }
    };
  };

const createOrganization = (createOrganizationState) => {
  return async (dispatch) => {
    try {
        var data = JSON.stringify(createOrganizationState);
        var config = {
          method: 'post',
          url: apiUrl+'/organizations',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===201){
              dispatch({ type: OrganizationActionType.CREATE_ORGANIZATION_SUCCESSS, payload: response.data.Message });
          }else{
              dispatch({ type: OrganizationActionType.CREATE_ORGANIZATION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 422){
            dispatch({ type: OrganizationActionType.CREATE_ORGANIZATION_INPUT_ERROR, payload: error.response.data.validationMessages });
          }else{
            dispatch({ type: OrganizationActionType.CREATE_ORGANIZATION_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.CREATE_ORGANIZATION_FAIL, payload: error.message });
      }
    }
  };
};


const createDepartment  = (createOrganizationState) => {
  return async (dispatch) => {
    try {
        var data = JSON.stringify(createOrganizationState);
        var config = {
          method: 'post',
          url: apiUrl+'/departments',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===201){
              dispatch({ type: OrganizationActionType.CREATE_DEPARTMENT_SUCCESS, payload: response.data.Message });
              dispatch({ type: OrganizationActionType.GET_DEPARTMENT_SUCCESS, payload: [] });
          }else{
              dispatch({ type: OrganizationActionType.CREATE_DEPARTMENT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 422){
            dispatch({ type: OrganizationActionType.CREATE_DEPARTMENT_INPUT_ERROR, payload: error.response.data.validationMessages });
          }else{
            dispatch({ type: OrganizationActionType.CREATE_DEPARTMENT_FAIL, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.CREATE_DEPARTMENT_FAIL, payload: error.message });
      }
    }
  };
};

const organizationListExportWithPdf  = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
        var data = JSON.stringify(props);
        var config = {
          method: 'post',
          url: apiUrl+'/organizations/export',
          headers: { 
            'Content-Type': 'application/json; charset=utf8'
          },
          data : data,
          responseType: 'blob', // Important
        };
        hrApi(config)
        .then(function (response,typeId)  {
          if(response.status===200){
             const exportTypes = {
               0: "xlsx",
               1:"csv",
               2:"pdf"
             };
              FileDownload(response.data, 'organization_'+newDate+'.'+ exportTypes[props.exportType]);
          }else{
              dispatch({ type: OrganizationActionType.ORGANIZATION_EXPORT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.ORGANIZATION_EXPORT_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.ORGANIZATION_EXPORT_FAIL, payload: error.message });
      }
    }
  };
};

const organizationSearch = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.ORGANIZATION_SEARCH_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: 'post',
        url: apiUrl+'/organizations/search',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
            dispatch({ type: OrganizationActionType.ORGANIZATION_SEARCH_SUCCESS, payload: response.data.data });
        }else{

            dispatch({ type: OrganizationActionType.ORGANIZATION_SEARCH_FAIL, payload: response.data.Message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 404){
          const emptyData = {
            "pageNumber": 1,
            "pageSize": 1,
            "totalNumberOfPages": 1,
            "totalNumberOfRecords": 0,
             "results": []
          }
          dispatch({ type: OrganizationActionType.ORGANIZATION_SEARCH_SUCCESS, payload: emptyData });
        }
        dispatch({ type: OrganizationActionType.ORGANIZATION_SEARCH_FAIL, payload: error.message });
      });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.ORGANIZATION_SEARCH_FAIL, payload: error.message });
      }
    }
  };
};

const departmentSearch = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.DEPARTMENT_SEARCH_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: 'post',
        url: apiUrl+'/departments/search',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
            dispatch({ type: OrganizationActionType.DEPARTMENT_SEARCH_SUCCESS, payload: response.data.data });
        }else{
            dispatch({ type: OrganizationActionType.DEPARTMENT_SEARCH_FAIL, payload: response.data.Message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 404){
          const emptyData = {
            "pageNumber": 1,
            "pageSize": 1,
            "totalNumberOfPages": 1,
            "totalNumberOfRecords": 0,
             "results": []
          }
          dispatch({ type: OrganizationActionType.DEPARTMENT_SEARCH_SUCCESS, payload: emptyData });
        }
        dispatch({ type: OrganizationActionType.DEPARTMENT_SEARCH_FAIL, payload: error.message });
      });

       
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.DEPARTMENT_SEARCH_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateOrganizationResponse = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.CREATE_ORGANIZATION_RESET_STATUS, payload:{} });
    };
}

const resetCreateDepartmentResponse = () => {    
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.CREATE_DEPARTMENT_RESET_STATUS, payload:{} });
    };
}

const getOrganizationFilter = (filterData) => {
  return async (dispatch) => {
    const column = filterData.column;
    if(column==='organizationName'){
      dispatch({ type: OrganizationActionType.ORGANIZATION_FILTER_REQUEST });
    }else if(column==='legalName'){
      dispatch({ type: OrganizationActionType.LEGAL_NAME_FILTER_REQUEST });
    }
    try {
      const data = JSON.stringify(filterData);
      var config = {
        method: 'post',
        url: apiUrl+'/organizations/filter',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
          if(column==='organizationName'){
            dispatch({ type: OrganizationActionType.ORGANIZATION_FILTER_SUCCESS, payload: response.data.data });
          }else if(column==='legalName'){
            dispatch({ type: OrganizationActionType.LEGAL_NAME_FILTER_SUCCESS, payload: response.data.data });
          }
        }else{
          if(column==='organizationName'){
            dispatch({ type: OrganizationActionType.ORGANIZATION_FILTER_FAIL, payload: response.data.Message });
          }else if(column==='legalName'){
            dispatch({ type: OrganizationActionType.LEGAL_NAME_FILTER_FAIL, payload: response.data.Message });
          }
        }
      })
      .catch(function (error) {
        if(error.response?.status === 404){
          const emptyData = {
            "pageNumber": 1,
            "pageSize": 1,
            "totalNumberOfPages": 1,
            "totalNumberOfRecords": 0,
             "results": []
          }
          if(column==='organizationName'){
            dispatch({ type: OrganizationActionType.ORGANIZATION_FILTER_SUCCESS, payload: emptyData });
          }else if(column==='legalName'){
            dispatch({ type: OrganizationActionType.LEGAL_NAME_FILTER_SUCCESS, payload: emptyData });
          } 
        }
      });
    } catch (error) {
      if (error.response) {
        if(column==='organizationName'){
          dispatch({ type: OrganizationActionType.ORGANIZATION_FILTER_FAIL, payload: error.message });
        }else if(column==='legalName'){
          dispatch({ type: OrganizationActionType.LEGAL_NAME_FILTER_FAIL, payload: error.message });
        } 
      }
    }
  };
};

const deleteLogoOrganization = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'delete',
        url: apiUrl+'/organizations/'+resourceId+'/deleteOrganizationLogo',
        headers: { 
        },
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===201){
            dispatch({ type: OrganizationActionType.DELETE_LOGO_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: OrganizationActionType.DELETE_LOGO_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 422){
          dispatch({ type: OrganizationActionType.DELETE_LOGO_FAIL, payload: error.response.data.message});
        }else{
          dispatch({ type: OrganizationActionType.DELETE_LOGO_FAIL, payload: error.message });
        }
      });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.DELETE_LOGO_FAIL, payload: error.message });
      }
    }
  };
};

const deleteOrganizationLogoResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.DELETE_LOGO_RESET, payload:{} });
    };
}

const getOrganizationWorkHours = (organizationResourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_REQUEST });
    try {
      var config = {
          method: 'get',
          url: apiUrl+'/organizationWorkHours/'+organizationResourceId+'/list',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = []
            dispatch({ type: OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_SUCCESS, payload: emptyData });
          }else{
            dispatch({ type: OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_WORK_HOURS_FAIL, payload: error.message });
      }
    }
  };
};

const updateOrganizationWorkHours = (updateData) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(updateData);
      var config = {
        method: 'post',
        url: apiUrl+'/organizationWorkHours',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===201){
            dispatch({ type: OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 422){
          dispatch({ type: OrganizationActionType.UPDATE_ORGANIZATION_WORK_INPUT_ERROR, payload: error.response.data.validationMessages });
        }else{
          dispatch({ type: OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_FAIL, payload: error.message });
        }
      });
       
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateOrganizationWorkHours = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.UPDATE_ORGANIZATION_WORK_HOURS_RESET, payload:{} });
    };
}

const getOrganizationDocumentType = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizationDocumentType',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status === 201){
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_FAIL, payload: error.message });
      }
    }
  };
};

const getOrganizationDocumentTypeWithUserId = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizationDocumentType/'+resourceId,
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status === 201){
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_ORGANIZATION_DOCUMENT_TYPE_WITH_USER_ID_FAIL, payload: error.message });
      }
    }
  };
};

const getWorkHourDetailsByDefinitions = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizationWorkHourDefinitions/list',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONS_FAIL, payload: error.message });
      }
    }
  };
};
const getWorkHourDetailsByDefinitionId = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizationWorkHours/'+resourceId+'/list',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          
          dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DETAILS_BY_DEFINITIONID_FAIL, payload: error.message });
      }
    }
  };
};
const getWorkHourDepartmentInPersons = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/departments/GetAllDepartmentsInPersons',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_WORK_HOUR_DEPARTMENT_IN_PERSONS_FAIL, payload: error.message });
      }
    }
  };
};
const setOrganizationWorkHoursCreateDrawer = (status) => async dispatch => {
  try {
      dispatch({
          type: OrganizationActionType.SET_ORGANIZATION_WORKHOURS_CREATE_DRAWER,
          payload: status,
      });
  } catch (e) {
      console.log(e);
  }
};
const setOrganizationWorkHoursDetailDrawer = (status) => async dispatch => {
  try {
      dispatch({
          type: OrganizationActionType.SET_ORGANIZATION_WORKHOURS_DETAIL_DRAWER,
          payload: status,
      });
  } catch (e) {
      console.log(e);
  }
};

const deleteOrganizationWorkHours = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: 'delete',
        url: apiUrl+'/organizationWorkHours/'+resourceId,
        headers: { 
        },
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
            dispatch({ type: OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 422){
          dispatch({ type: OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_FAIL, payload: error.response.data.message});
        }else{
          dispatch({ type: OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_FAIL, payload: error.message });
        }
      });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_FAIL, payload: error.message });
      }
    }
  };
};

const deleteOrganizationWorkHoursReset = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.DELETE_ORGANIZATION_WORK_HOURS_RESET, payload:{} });
    };
}

const makeDefaultOrganizationWorkHours = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_REQUEST });
    try {
      var config = {
        method: 'put',
        url: apiUrl+'/organizationWorkHours/makeDefault/'+resourceId,
        headers: { 
          'Content-Type': 'application/json'
        },
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
            dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
        if(error.response?.status === 422){
          dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL, payload: error.response.data.message });
        }else{
          dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL, payload: error.message });
        }
      });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_FAIL, payload: error.message });
      }
    }
  };
};
const makeDefaultOrganizationWorkHourReset = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.MAKE_DEFAULT_ORGANIZATION_WORKHOUR_RESET, payload:{} });
    };
}
const workHoursControl = (data) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.UPDATE_WORKHOURS_PERSON_CONTROL_REQUEST });
    try {
        var config = {
          method: 'post',
          url: apiUrl+'/organizationWorkHours/saveOrganizationWorkHourControl',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status ===201){
              dispatch({ type: OrganizationActionType.UPDATE_WORKHOURS_PERSON_CONTROL_OPEN_MODAL, payload: response.data.data });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            dispatch({ type: OrganizationActionType.GET_DELETE_PERSON_CONTROL_PASS, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
         dispatch({ type: OrganizationActionType.GET_DELETE_PERSON_CONTROL_PASS, payload: error.data.data });
      }
    }
  };
};
const resetworkHoursControl = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.UPDATE_WORKHOURS_PERSON_CONTROL_RESET, payload:{} });
  };
}
const getPersonWorkHours = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_PERSON_WORK_HOURS_REQUEST });
    try {
        var config = {
          method: 'get',
          url: apiUrl+'/organizations/GetDefaultWorkDefinition',
          headers: { }
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: OrganizationActionType.GET_PERSON_WORK_HOURS_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: OrganizationActionType.GET_PERSON_WORK_HOURS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_PERSON_WORK_HOURS_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.GET_PERSON_WORK_HOURS_FAIL, payload: error.message });
      }
    }
  };
};
const resetPersonWorkHours = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_PERSON_WORK_HOURS_RESET, payload:{} });
  };
}
const getApproveOrganizationRegisterMail = (data) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_REQUEST });
    try {
      var config = {
        method: "post",
        url: authUrl + "/account/ConfirmOrganizationEmail",
        headers: {
          'Content-Type': 'application/json'
        },
        data:data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_SUCCESS, payload: response?.data?.message });
          } else {
            dispatch({ type: OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_FAIL, payload: response?.data?.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_FAIL, payload: error?.response?.data?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OrganizationActionType.GET_APPROVE_ORGANIZATION_REGISTER_MAIL_FAIL, payload: error?.message });
      }
    }
  };
};
function accountContracts() {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_ACCOUNT_CONTRACTS_REQUEST });
    try {
      var config = {
        method: "get",        
        url: apiUrl + "/contracts",
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OrganizationActionType.GET_ACCOUNT_CONTRACTS_SUCCESS, payload: response?.data?.contractItems });
          } else {
            dispatch({ type: OrganizationActionType.GET_ACCOUNT_CONTRACTS_FAIL, payload: response?.data?.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_ACCOUNT_CONTRACTS_FAIL, payload: error?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OrganizationActionType.GET_ACCOUNT_CONTRACTS_FAIL, payload: error?.message });
      }
    }
  };
};
const getPersonOrganizations = (requestData) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.GET_PERSON_ORGANIZATIONS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/user-organizations",
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OrganizationActionType.GET_PERSON_ORGANIZATIONS_SUCCESS, payload: response?.data?.personUsers });
          } else {
            dispatch({ type: OrganizationActionType.GET_PERSON_ORGANIZATIONS_FAIL, payload: response?.data?.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OrganizationActionType.GET_PERSON_ORGANIZATIONS_FAIL, payload: error?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OrganizationActionType.GET_PERSON_ORGANIZATIONS_FAIL, payload: error?.message });
      }
    }
  };
};
const organizationUpdatePhoneRequest = (requestData) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(requestData);
      var config = {
        method: 'put',
        url: apiUrl+'/organizations/update-phone-request',
        headers: { 
          'Content-Type': 'application/json'
        },
        data:data
      };
      hrApi(config)
      .then(function (response) {
        if(response.status===200){
            dispatch({ type: OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_SUCCESS, payload: response.data.message });
        }else{
            dispatch({ type: OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_FAIL, payload: response.data.message });
        }
      })
      .catch(function (error) {
          dispatch({ type: OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_FAIL, payload: error.message });
      });
    } catch (error) {
      if (error.response) {
          dispatch({ type: OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_FAIL, payload: error.message });
      }
    }
  };
};
const resetOrganizationUpdatePhoneRequest = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationActionType.ORGANIZATION_UPDATE_PHONE_REQUEST_RESET, payload:{} });
  };
}
export {
    OrganizationActionType,
    getOrganizations,
    getPersonOrgChart,
    getParentOrganizations,
    getDepartments,
    getPersons,
    createOrganization,
    createDepartment,
    organizationListExportWithPdf,
    organizationSearch,
    departmentSearch,
    personsDepartmentConnect,
    getDepartmentOrgChart,
    getOrganizationOrgChart,
    getOrganizationFilter,
    resetCreateOrganizationResponse,
    resetCreateDepartmentResponse,
    deleteLogoOrganization,
    deleteOrganizationLogoResponseReset,
    getOrganizationWorkHours,
    updateOrganizationWorkHours,
    resetUpdateOrganizationWorkHours,
    getOrganizationDocumentType,
    getOrganizationDocumentTypeWithUserId,
    getWorkHourDetailsByDefinitions,
    getWorkHourDetailsByDefinitionId,
    getWorkHourDepartmentInPersons,
    setOrganizationWorkHoursCreateDrawer,
    setOrganizationWorkHoursDetailDrawer,
    deleteOrganizationWorkHours,
    deleteOrganizationWorkHoursReset,
    makeDefaultOrganizationWorkHours,
    makeDefaultOrganizationWorkHourReset,
    workHoursControl,
    resetworkHoursControl,
    getPersonWorkHours,
    resetPersonWorkHours,
    getApproveOrganizationRegisterMail,
    accountContracts,
    getPersonOrganizations,
    organizationUpdatePhoneRequest,
    resetOrganizationUpdatePhoneRequest
};