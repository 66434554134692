import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    setApprovalProcessesDetailDrawer, updateApprovalProcesses, getProcessesDetail,
    resetUpdateApprovalProcesses, deleteApprovalProcesses, getAllProcess,
    resetDeleteApprovalProcesses, setCheckDefaultProcess
} from '../../../../redux/actions/approval-processes.actions';
import { getAllDepartmentsTreeView } from '../../../../redux/actions/department.action';

import { Col, Row, Button, Dropdown, Spin, List, Tabs } from 'antd';
import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { UilFileCheckAlt } from '@iconscout/react-unicons';

import { resetScrollPosition } from '../../../../utils/commonFormFunctions'
import { approvalProcessesDetailDropdown } from '../../../../features/global-dropdown-menu/index';
import DetailApprovalProcessesBasicForm from './detailApprovalProcessesBasicForm';
import DetailApprovalProcessesUnitsStepForm from './detailApprovalProcessesUnitsForm';
import DynamicRequestModal from '../../../dynamicModal/requestModal'
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/inventory.scss';

function DetailApprovalProcessesTabList(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { setApprovalProcessesDetailDrawer, approvalProcessesDetailInfo,
        updateApprovalProcessesResponse, deleteApprovalProcessResponse,
        approvalProcessesDetaiLoading, setApprovalIsOpenDrawer, profile,
        getAllDepartmentsTreeView, updateApprovalProcesses, resetUpdateApprovalProcesses,
        deleteApprovalProcesses, getAllProcess, resetDeleteApprovalProcesses,
        selectApprovalProcess, getProcessesDetail, checkDefaultProcessResponse, setCheckDefaultProcess } = props;
    const [deleteModalData, setDeleteModalData] = useState({ "isVisible": false });
    const [infoModalData, setInfoModalData] = useState({ 'isVisible': false, 'description': '' });
    const [currentTab, setCurrentTab] = useState("basic");
    const [basicTabState, setBasicTabState] = useState();
    const [unitTabState, setUnitTabState] = useState();
    const [errorIdListState, setErrorIdListState] = useState([]);
    const [approvalFormData, setApprovalFormData] = useState({
        "IsDefault": null,
        "ProcessName": null,
        "ProcessType": null,
    });

    function callback(key) {
        setCurrentTab(key);
        resetScrollPosition('.drawer-scrollable-tab-inventory > .ant-tabs-content-holder');
    }
    useEffect(() => {
        getAllDepartmentsTreeView();
    }, [])
    useEffect(() => {
        if (deleteApprovalProcessResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_APPROVAL_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteApprovalProcessResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            close();
            getAllProcess();
            resetDeleteApprovalProcesses();
        } else if (deleteApprovalProcessResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_APPROVAL_FAIL',
                'title': t('ERROR'),
                'description': deleteApprovalProcessResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetDeleteApprovalProcesses();
        }
    }, [deleteApprovalProcessResponse])
    useEffect(() => {
        if (updateApprovalProcessesResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'UPDATE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': updateApprovalProcessesResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            setCurrentTab("basic");
            getProcessesDetail(selectApprovalProcess);
            getAllProcess();
            setErrorIdListState([]);
            resetUpdateApprovalProcesses();
        } else if (updateApprovalProcessesResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'UPDATE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': updateApprovalProcessesResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateApprovalProcesses();
        } else if (updateApprovalProcessesResponse.status === 'VALIDATION_ERROR') {
            setErrorIdListState(updateApprovalProcessesResponse?.errorIds);
            DynamicToastMessage({
                'key': 'approval_processes_create_fail_notification',
                'title': t('ERROR'),
                'description': updateApprovalProcessesResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateApprovalProcesses();
        }
    }, [updateApprovalProcessesResponse]);

    useEffect(() => {
        setBasicTabState({
            'ProcessName': approvalProcessesDetailInfo?.processName,
            'ProcessType': approvalProcessesDetailInfo?.processType,
            'ProcessStep': approvalProcessesDetailInfo?.processStep,
            'IsDefault': approvalProcessesDetailInfo?.IsDefault
        });
        setUnitTabState({
            'ProcessAssign': approvalProcessesDetailInfo?.processAssign
        });
    }, [approvalProcessesDetailInfo])
    useEffect(() => {
        if (checkDefaultProcessResponse?.status === "SUCCESS" && checkDefaultProcessResponse?.message) {
            setInfoModalData({ ...infoModalData, 'description': checkDefaultProcessResponse.message, 'isVisible': true, 'isSuccess': checkDefaultProcessResponse?.data?.isSuccess })
        }
    }, [checkDefaultProcessResponse])
    function close() {
        setApprovalProcessesDetailDrawer(false);
        setBasicTabState({});
        setUnitTabState({});
        setApprovalIsOpenDrawer(false);
        setCheckDefaultProcess();
    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false;
        let fields = [];
        if (!basicTabState?.ProcessName || basicTabState?.ProcessName === "") {
            hasError = true
            fields.push(t('NAME_YOUR_PROCESS'))
        }
        if (!basicTabState?.ProcessType?.id || basicTabState?.ProcessType?.id === "") {
            hasError = true
            fields.push(t('PROCESSES_TYPE'))
        }
        if (basicTabState?.ProcessStep?.length === 0) {
            hasError = true
            fields.push(t('PROCESSES_APPROVE_PROCESSES'))
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [isUpdateLoading, setisUpdateLoading] = useState(false);

    const handleUpdateApprovalProcesses = () => {

        if(isUpdateLoading) return;
        setisUpdateLoading(true);
        setTimeout(() => {setisUpdateLoading(false);}, 1055) // double click prevent;

        const AssignmentProcessSeqNumber = basicTabState?.ProcessStep.map((data, index) => {
            return {
                ...data,
                SeqNumber: index + 1
            }
        });
        const AddedProcessStep = AssignmentProcessSeqNumber.filter((filterData) => filterData.status === 'add').map((data) => {
            return {
                "ProcessPersonType": data?.id,
                "AnotherUserResourceId": data?.secondApproval?.id,
                "SeqNumber": data?.SeqNumber
            }
        });
        var UpdatedProcessStep = AssignmentProcessSeqNumber.filter((filterData) => filterData.status === 'update').map((data) => {
            return {
                "ResourceId": data?.resourceId,
                "ProcessPersonType": data?.id,
                "AnotherUserResourceId": data?.secondApproval?.id,
                "SeqNumber": data?.SeqNumber
            }
        });
        if (basicTabState?.DeleteProcessStep) {
            // If Found Delete Data Remove To Update Obj 
            for (var i = UpdatedProcessStep.length - 1; i >= 0; i--) {
                for (var j = 0; j < basicTabState?.DeleteProcessStep.length; j++) {
                    if (UpdatedProcessStep[i] && (UpdatedProcessStep[i].ResourceId === basicTabState?.DeleteProcessStep[j].ResourceId)) {
                        UpdatedProcessStep.splice(i, 1);
                    }
                }
            }
        }
        const ProcessAssign = unitTabState?.ProcessAssignUpdate ? unitTabState?.ProcessAssignUpdate :
            unitTabState?.ProcessAssign.length > 0 ? unitTabState?.ProcessAssign.map((data) => {
                return {
                    'EntityTypeIdx': data?.entityType?.id,
                    'EntityResourceIdx': data?.entityType?.id === "2" ? data.departmentDto?.resourceId : data?.personDto?.resourceId
                }
            }) : null;
        const data = {
            "ProcessName": basicTabState?.ProcessName,
            "IsDefault": basicTabState?.IsDefault ? basicTabState?.IsDefault : false, // be want to request like this
            "IsActive": true,  // be want to request like this
            "ProcessType": basicTabState?.ProcessType?.id,
            "AddedProcessStep": AddedProcessStep,
            "UpdatedProcessStep": UpdatedProcessStep,
            "DeletedProcessStep": basicTabState?.DeleteProcessStep ? basicTabState?.DeleteProcessStep : [],
            "ProcessAssign": !basicTabState?.IsDefault ? ProcessAssign : []
        };
        if ((!basicTabState.IsDefault && unitTabState?.ProcessAssignUpdate) && unitTabState?.ProcessAssignUpdate.length === 0) {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': t('CHOOSE_PARTNER_APPLIED_DEPARTMENT_OR_APPLIED_PERSON'),
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        } else {
            checkRequiredFields(() => updateApprovalProcesses(data, approvalProcessesDetailInfo?.resourceId));
            window.sendinblue?.track(
                //brevo_event_update_approval_process
                'update_approval_process',
            )
        }
    }
    const canDelete = (id, name) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'isVisible': true });
    }
    const setUnCheckApprovalProcessBtn = (type) => {
        if (type === 'cancel') {
            if (!infoModalData?.isSuccess) {
                setInfoModalData({ 'isVisible': false });
            } else {
                setApprovalFormData({ ...approvalFormData, 'IsDefault': !basicTabState?.IsDefault })
                setBasicTabState({ ...basicTabState, 'IsDefault': !basicTabState?.IsDefault });
                setInfoModalData({ 'isVisible': false });;
            }
        } else {
            setInfoModalData({ 'isVisible': false });
        }
    }
    return (
        approvalProcessesDetaiLoading ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <div className="table-details organization-drawer">
                <DynamicRequestModal
                    modalVisible={deleteModalData.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteApprovalProcesses}
                    confirmData={approvalProcessesDetailInfo.resourceId}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_APPROVAL_PROCESSES_DESCRIPTION', { 'name': deleteModalData?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <DynamicRequestModal
                    modalVisible={infoModalData?.isVisible}
                    setStateData={setInfoModalData}
                    handleConfirm={() => setUnCheckApprovalProcessBtn('confirm')}
                    handleCancel={() => setUnCheckApprovalProcessBtn('cancel')}
                    iconName='UilInfoCircle'
                    modalTitle={t('INFO')}
                    isForm={true}
                    description={infoModalData?.description}
                    hideButton={!infoModalData?.isSuccess}
                />
                <div className="close-icon" onClick={() => close()}>
                    <CloseOutlined />
                </div>
                <Col span={24}>
                    <Row>
                        <Col span={17}>
                            <Row>
                                <Col span={6}>
                                    <div className="custom-icon-logo">
                                        <UilFileCheckAlt />
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div className="user-details">
                                        <h1>{t('PARTNER_PROCESSES_DETAILS')}</h1>
                                        <div className="info-alt">
                                            <h3>{approvalProcessesDetailInfo.processName} </h3>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col offset={2} span={5}>
                            <div className="changepage">
                                <Row gutter={[0, 15]}>
                                    <div className="procces pad-0">
                                        {!approvalProcessesDetailInfo?.isDefault &&
                                            <Dropdown
                                                overlay={approvalProcessesDetailDropdown(t, canDelete, approvalProcessesDetailInfo.resourceId, approvalProcessesDetailInfo.processName)}
                                                trigger={['click']}>
                                                <Button className="primary-btn w-180 h-32"> <SettingOutlined />{t('PARTNER_PROCESS')} </Button>
                                            </Dropdown>
                                        }
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className='flex-end'>
                    </div>
                </Col>
                <div className="hr-bottom"></div>
                <Tabs
                    className="custom-tab update-user-draw drawer-scrollable-tab"
                    onChange={callback}
                    activeKey={currentTab}
                    defaultActiveKey={currentTab}
                >
                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key={"basic"}>
                        <DetailApprovalProcessesBasicForm
                            approvalFormData={approvalFormData}
                            setApprovalFormData={setApprovalFormData}
                            setBasicTabState={setBasicTabState}
                            basicTabState={basicTabState}
                            approvalProcessesDetailInfo={approvalProcessesDetailInfo}
                        />
                    </TabPane>
                    <TabPane tab={t('PARTNER_UNITS_WHICH')} key={"units"}>
                        <DetailApprovalProcessesUnitsStepForm
                            basicTabState={basicTabState}
                            setUnitTabState={setUnitTabState}
                            unitTabState={unitTabState}
                            approvalProcessesDetailInfo={approvalProcessesDetailInfo}
                            errorIdListState={errorIdListState}
                        />
                    </TabPane>
                </Tabs>
                {checkDefaultProcessResponse?.data?.isSuccess === true &&
                    <div className="fixed-btn">
                        <div className="page-end">
                            <div className="hr-end"></div>
                        </div>
                        <div className="form-submit-space-between">
                            <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                            <div className="btn-padding" />
                            <Button onClick={() => handleUpdateApprovalProcesses()} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </div>
                    </div>
                }
            </div >
        )
    )
}
const mapStateToProps = (state) => {
    return {
        approvalProcessesDetailInfo: state.approvalProcesses.approvalProcessesDetailInfo,
        approvalProcessesDetaiLoading: state.approvalProcesses.approvalProcessesDetaiLoading,
        updateApprovalProcessesResponse: state.approvalProcesses.updateApprovalProcessesResponse,
        deleteApprovalProcessResponse: state.approvalProcesses.deleteApprovalProcessResponse,
        profile: state.profile,
        checkDefaultProcessResponse: state.approvalProcesses.checkDefaultProcessResponse,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setApprovalProcessesDetailDrawer: (props) => { dispatch(setApprovalProcessesDetailDrawer(props)) },
        getAllDepartmentsTreeView: () => { dispatch(getAllDepartmentsTreeView()) },
        updateApprovalProcesses: (data, resourceId) => { dispatch(updateApprovalProcesses(data, resourceId)) },
        resetUpdateApprovalProcesses: () => { dispatch(resetUpdateApprovalProcesses()) },
        deleteApprovalProcesses: (resourceId) => { dispatch(deleteApprovalProcesses(resourceId)) },
        getAllProcess: () => { dispatch(getAllProcess()) },
        resetDeleteApprovalProcesses: () => { dispatch(resetDeleteApprovalProcesses()) },
        getProcessesDetail: (resourceId) => { dispatch(getProcessesDetail(resourceId)) },
        setCheckDefaultProcess: () => { dispatch(setCheckDefaultProcess()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailApprovalProcessesTabList);