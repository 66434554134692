import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";

const EducationActionType = {
  EDUCATION_LIST_REQUEST: "EDUCATION_LIST_REQUEST",
  EDUCATION_LIST_SUCCESS: "EDUCATION_LIST_SUCCESS",
  EDUCATION_LIST_FAIL: "EDUCATION_LIST_FAIL",
  BULK_EDUCATION_LIST_EDIT_REQUEST: "BULK_EDUCATION_LIST_EDIT_REQUEST",
  BULK_EDUCATION_LIST_EDIT_SUCCESS: "BULK_EDUCATION_LIST_EDIT_SUCCESS",
  BULK_EDUCATION_LIST_EDIT_FAIL: "BULK_EDUCATION_LIST_EDIT_FAIL",
  BULK_EDUCATION_LIST_DELETE_REQUEST: "BULK_EDUCATION_LIST_DELETE_REQUEST",
  BULK_EDUCATION_LIST_DELETE_SUCCESS: "BULK_EDUCATION_LIST_DELETE_SUCCESS",
  BULK_EDUCATION_LIST_DELETE_FAIL: "BULK_EDUCATION_LIST_DELETE_FAIL",
  DELETE_EDUCATION_SUCCESS: "DELETE_EDUCATION_SUCCESS",
  DELETE_EDUCATION_FAIL: "DELETE_EDUCATION_FAIL",
  DELETE_EDUCATION_RESET: "DELETE_EDUCATION_RESET",
  GET_EDUCATION_INFORMATION_REQUEST: "GET_EDUCATION_INFORMATION_REQUEST",
  GET_EDUCATION_INFORMATION_SUCCESS: "GET_EDUCATION_INFORMATION_SUCCESS",
  GET_EDUCATION_INFORMATION_FAIL: "GET_EDUCATION_INFORMATION_FAIL",
  GET_EDUCATION_INFORMATION_RESET: "GET_EDUCATION_INFORMATION_RESET",
  GET_PERSON_EDUCATION_VIDEOS_REQUEST: "GET_PERSON_EDUCATION_VIDEOS_REQUEST",
  GET_PERSON_EDUCATION_VIDEOS_SUCCESS: "GET_PERSON_EDUCATION_VIDEOS_SUCCESS",
  GET_PERSON_EDUCATION_VIDEOS_FAIL: "GET_PERSON_EDUCATION_VIDEOS_FAIL",
  GET_PERSON_EDUCATION_VIDEOS_RESET: "GET_PERSON_EDUCATION_VIDEOS_RESET",
  EDUCATION_REPORT_REQUEST: "EDUCATION_REPORT_REQUEST",
  EDUCATION_REPORT_SUCCESS: "EDUCATION_REPORT_SUCCESS",
  EDUCATION_REPORT_FAIL: "EDUCATION_REPORT_FAIL",
  EDUCATION_REPORT_RESET: "EDUCATION_REPORT_RESET",
  GET_PERSON_EDUCATION_DETAIL_REQUEST: "GET_PERSON_EDUCATION_DETAIL_REQUEST",
  GET_PERSON_EDUCATION_DETAIL_SUCCESS: "GET_PERSON_EDUCATION_DETAIL_SUCCESS",
  GET_PERSON_EDUCATION_DETAIL_FAIL: "GET_PERSON_EDUCATION_DETAIL_FAIL",
  GET_PERSON_EDUCATION_DETAIL_RESET: "GET_PERSON_EDUCATION_DETAIL_RESET",
  PERSON_TAB_EDUCATION_LIST_REQUEST: "PERSON_TAB_EDUCATION_LIST_REQUEST",
  PERSON_TAB_EDUCATION_LIST_SUCCESS: "PERSON_TAB_EDUCATION_LIST_SUCCESS",
  PERSON_TAB_EDUCATION_LIST_FAIL: "PERSON_TAB_EDUCATION_LIST_FAIL",
  PERSON_TAB_EDUCATION_LIST_RESET: "PERSON_TAB_EDUCATION_LIST_RESET",
  EDUCATION_VIDEO_UPDATE_STATUS_SUCCESS: "EDUCATION_VIDEO_UPDATE_STATUS_SUCCESS",
  EDUCATION_VIDEO_UPDATE_STATUS_FAIL: "EDUCATION_VIDEO_UPDATE_STATUS_FAIL",
  EDUCATION_VIDEO_UPDATE_STATUS_RESET: "EDUCATION_VIDEO_UPDATE_STATUS_RESET",
  EDUCATION_CREATE_SECTION_SUCCESS: "EDUCATION_CREATE_SECTION_SUCCESS",
  EDUCATION_CREATE_SECTION_FAIL: "EDUCATION_CREATE_SECTION_FAIL",
  EDUCATION_CREATE_SECTION_RESET: "EDUCATION_CREATE_SECTION_RESET",
  EDUCATION_UPDATE_SECTION_SUCCESS: "EDUCATION_UPDATE_SECTION_SUCCESS",
  EDUCATION_UPDATE_SECTION_FAIL: "EDUCATION_UPDATE_SECTION_FAIL",
  EDUCATION_UPDATE_SECTION_RESET: "EDUCATION_UPDATE_SECTION_RESET",
  EDUCATION_DELETE_SECTION_SUCCESS: "EDUCATION_DELETE_SECTION_SUCCESS",
  EDUCATION_DELETE_SECTION_FAIL: "EDUCATION_DELETE_SECTION_FAIL",
  EDUCATION_DELETE_SECTION_RESET: "EDUCATION_DELETE_SECTION_RESET",
  EDUCATION_DELETE_VIDEO_SECTION_SUCCESS: "EDUCATION_DELETE_VIDEO_SECTION_SUCCESS",
  EDUCATION_DELETE_VIDEO_SECTION_FAIL: "EDUCATION_DELETE_VIDEO_SECTION_FAIL",
  EDUCATION_DELETE_VIDEO_SECTION_RESET: "EDUCATION_DELETE_VIDEO_SECTION_RESET",
  EDUCATION_ADD_SUBSCRIBER_SUCCESS: "EDUCATION_ADD_SUBSCRIBER_SUCCESS",
  EDUCATION_ADD_SUBSCRIBER_FAIL: "EDUCATION_ADD_SUBSCRIBER_FAIL",
  EDUCATION_ADD_SUBSCRIBER_RESET: "EDUCATION_ADD_SUBSCRIBER_RESET",
  EDUCATION_SUBSCRIBER_REMOVE_SUCCESS: "EDUCATION_SUBSCRIBER_REMOVE_SUCCESS",
  EDUCATION_SUBSCRIBER_REMOVE_FAIL: "EDUCATION_SUBSCRIBER_REMOVE_FAIL",
  EDUCATION_SUBSCRIBER_REMOVE_RESET: "EDUCATION_SUBSCRIBER_REMOVE_RESET",
  EDUCATION_VIDEO_SORT_UPDATE_SUCCESS: "EDUCATION_VIDEO_SORT_UPDATE_SUCCESS",
  EDUCATION_VIDEO_SORT_UPDATE_FAIL: "EDUCATION_VIDEO_SORT_UPDATE_FAIL",
  EDUCATION_VIDEO_SORT_UPDATE_RESET: "EDUCATION_VIDEO_SORT_UPDATE_RESET"

};
const getPersonEducationDetail = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_DETAIL_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/education/person-education-information/" + resourceId,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_DETAIL_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_DETAIL_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_DETAIL_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_DETAIL_FAIL, payload: error.message });
      }
    }
  };
};
const resetPersonEducationDetail = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_DETAIL_RESET, payload: {} });
  };
};

const getEducationInformation = (id) => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.GET_EDUCATION_INFORMATION_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/education/information/" + id,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.GET_EDUCATION_INFORMATION_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.GET_EDUCATION_INFORMATION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.GET_EDUCATION_INFORMATION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.GET_EDUCATION_INFORMATION_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationInformation = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.GET_EDUCATION_INFORMATION_RESET, payload: {} });
  };
};
const getEducationList = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_LIST_REQUEST });
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/list",
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_LIST_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const getPersonEducationVideos = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_VIDEOS_REQUEST });
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/person-education",
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_VIDEOS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_VIDEOS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_VIDEOS_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_VIDEOS_FAIL, payload: error.message });
      }
    }
  };
};
const resetPersonEducationVideos = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.GET_PERSON_EDUCATION_VIDEOS_RESET, payload: {} });
  };
};
const getEducationReport = (id) => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_REPORT_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/education/subscriber-report/" + id,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_REPORT_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_REPORT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_REPORT_FAIL, payload: error?.response?.data?.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_REPORT_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationReport = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_REPORT_RESET, payload: {} });
  };
};
const deleteEducation = (id) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/education/delete/" + id,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.DELETE_EDUCATION_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: EducationActionType.DELETE_EDUCATION_FAIL, payload: response.data });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.DELETE_EDUCATION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.DELETE_EDUCATION_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteEducation = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.DELETE_EDUCATION_RESET, payload: {} });
  };
};
const getPersonTabEducationList = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.PERSON_TAB_EDUCATION_LIST_REQUEST });
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/person-education-list?resourceId=" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.PERSON_TAB_EDUCATION_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.PERSON_TAB_EDUCATION_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.PERSON_TAB_EDUCATION_LIST_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.PERSON_TAB_EDUCATION_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const resetPersonTabEducationList = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.PERSON_TAB_EDUCATION_LIST_RESET, payload: {} });
  };
};
const educationVideoUpdateStatus = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/education/update-state/" + data.videoStateResourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationVideoUpdateStatus = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_VIDEO_UPDATE_STATUS_RESET, payload: {} });
  };
};
const educationCreateSection = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/create-section",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_CREATE_SECTION_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_CREATE_SECTION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_CREATE_SECTION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_CREATE_SECTION_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationCreateSection = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_CREATE_SECTION_RESET, payload: {} });
  };
};
const educationUpdateSection = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/update-section",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_UPDATE_SECTION_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_UPDATE_SECTION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_UPDATE_SECTION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_UPDATE_SECTION_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationUpdateSection = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_UPDATE_SECTION_RESET, payload: {} });
  };
};
const educationDeleteSection = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/education/sectiondelete/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_DELETE_SECTION_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_DELETE_SECTION_FAIL, payload: response.data });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_DELETE_SECTION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_DELETE_SECTION_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationDeleteSection = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_DELETE_SECTION_RESET, payload: {} });
  };
};
const educationDeleteVideoSection = (resourceId, index) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/education/sectiondeletevideo/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_SUCCESS,
              payload: { index: index, data: response.data },
            });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_FAIL, payload: response.data });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationDeleteVideoSection = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_DELETE_VIDEO_SECTION_RESET, payload: {} });
  };
};
const educationAddSubscriber = (requestData) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/add-subscriber",
        headers: {
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_ADD_SUBSCRIBER_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_ADD_SUBSCRIBER_FAIL, payload: response.data });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_ADD_SUBSCRIBER_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_ADD_SUBSCRIBER_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationAddSubscriber = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_ADD_SUBSCRIBER_RESET, payload: {} });
  };
};
const educationDeleteSubscriberRemove = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/education/subscriber-remove/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: EducationActionType.EDUCATION_SUBSCRIBER_REMOVE_SUCCESS,
              payload: { id: resourceId, data: response.data },
            });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_SUBSCRIBER_REMOVE_FAIL, payload: response.data });
          }
        })
        .catch(function (error) {
          dispatch({
            type: EducationActionType.EDUCATION_SUBSCRIBER_REMOVE_FAIL,
            payload: error.response?.data,
          });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_SUBSCRIBER_REMOVE_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationDeleteSubscriberRemove = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_SUBSCRIBER_REMOVE_RESET, payload: {} });
  };
};
const educationVideoSortUpdate = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/update-order",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) { 
          if (response.status === 200) {
            dispatch({ type: EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_FAIL, payload: error.message });
      }
    }
  };
};
const resetEducationVideoSortUpdate = () => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.EDUCATION_VIDEO_SORT_UPDATE_RESET, payload: {} });
  };
};

const bulkUpdateEducation = (data) => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_EDIT_REQUEST });
    try {
      var config = {
        method: "post",
        url: apiUrl + "/education/update-subscriberList",
        headers: {
          "Content-Type": "application/json",
        },
        data : data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_EDIT_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_EDIT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_EDIT_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_EDIT_FAIL, payload: error.message });
      }
    }
  };
};
const bulkDeleteEducation = (data) => {
  return async (dispatch) => {
    dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_DELETE_REQUEST });
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/education/delete-subscriberList",
        headers: {
          "Content-Type": "application/json",
        },
        data : data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_DELETE_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_DELETE_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_DELETE_FAIL, payload: error.response?.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: EducationActionType.BULK_EDUCATION_LIST_DELETE_FAIL, payload: error.message });
      }
    }
  };
};
export {
  EducationActionType,
  getPersonEducationDetail,
  resetPersonEducationDetail,
  getEducationInformation,
  getEducationList,
  getPersonEducationVideos,
  resetPersonEducationVideos,
  getEducationReport,
  resetEducationReport,
  deleteEducation,
  resetDeleteEducation,
  resetEducationInformation,
  getPersonTabEducationList,
  resetPersonTabEducationList,
  educationVideoUpdateStatus,
  resetEducationVideoUpdateStatus,
  educationCreateSection,
  resetEducationCreateSection,
  educationUpdateSection,
  resetEducationUpdateSection,
  educationDeleteSection,
  resetEducationDeleteSection,
  educationDeleteVideoSection,
  resetEducationDeleteVideoSection,
  educationAddSubscriber,
  resetEducationAddSubscriber,
  educationDeleteSubscriberRemove,
  resetEducationDeleteSubscriberRemove,
  educationVideoSortUpdate,
  resetEducationVideoSortUpdate,
  bulkUpdateEducation,
  bulkDeleteEducation
};
