var initialState = {
  openAssignmentDraw: false,
  assignmentHasChange: false,
  openAssignmentAddDraw: false,
  assignmentAddHasChange: false,
  salaryDetailsHasChange: false,
  salaryDetailsDraw:false,
  salaryAddHasChange:false,
  salaryAddDraw:false,
  advanceDetailsDraw:false,
  advanceDetailsHasChange:false,
  advanceAddDraw:false,
  advanceAddHasChange:false,
  openDebitDraw: false,
  createOwnerShipDrawer: false,
  ownerShipDetailsDrawer:false,
  debitDetailsDraw: {
    tabStatus:false,
    id:null
  },
  personAddOpenDraw:false,
  connectedPersonsOpenDraw:{
    status:false,
    id:null
  },
  personDeleteDrawer:{
    status:false,
    deleteStatus:null,
    id:null,
    name:null,
    componentKey:30
  }   
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {

    /*  ATAMALAR ISLEMLERI */
    case "SET_ASSIGMENT_EDIT_CHANGE_STATUS":
      const assignmentHasChangeState = {
        ...state,
        assignmentHasChange: action.payload,
      };

      return assignmentHasChangeState;

    case "SET_ASSIGMENT_EDIT_OPEN__DRAW":
      const openAssignmentDrawState = {
        ...state,
        openAssignmentDraw: action.payload,
      };
      return openAssignmentDrawState;


      case "SET_ASSIGMENT_ADD_CHANGE_STATUS":
        const assignmentAddHasChangeState = {
          ...state,
          assignmentAddHasChange: action.payload,
        };
  
        return assignmentAddHasChangeState;
  
    case "SET_ASSIGMENT_ADD_OPEN__DRAW":
      const openAssignmentAddDrawState = {
        ...state,
        openAssignmentAddDraw: action.payload,
      };
      return openAssignmentAddDrawState;
      

      
      /* MAAS BILGISI ISLEMLERI */

      case "SET_SALARY_EDIT_CHANGE_STATUS":
      const salaryDetailsHasChangeState = {
        ...state,
        salaryDetailsHasChange: action.payload,
      };
      return salaryDetailsHasChangeState;


      case "SET_SALERY_EDIT_OPEN_DRAW":
      const salaryDetailsDrawState = {
        ...state,
        salaryDetailsDraw: action.payload,
      };
      return salaryDetailsDrawState;



      case "SET_SALARY_ADD_CHANGE_STATUS":
      const salaryAddHasChangeState = {
        ...state,
        salaryAddHasChange: action.payload,
      };
      return salaryAddHasChangeState;


      case "SET_SALERY_ADD_OPEN_DRAW":
      const salaryAddDrawState = {
        ...state,
        salaryAddDraw: action.payload,
      };
      return salaryAddDrawState;


      /* AVANS BILGISI ISLEMLERI */

    case "SET_ADVANCE_EDIT_CHANGE_STATUS":
      const advanceDetailsHasChangeState = {
        ...state,
        advanceDetailsHasChange: action.payload,
      };
      return advanceDetailsHasChangeState;


      case "SET_ADVANCE_EDIT_OPEN_DRAW":
      const advanceDetailsDrawState = {
        ...state,
        advanceDetailsDraw: action.payload,
      };
      return advanceDetailsDrawState;


      case "SET_ADVANCE_ADD_CHANGE_STATUS":
        const advanceAddHasChangeState = {
          ...state,
          advanceAddHasChange: action.payload,
        };
        return advanceAddHasChangeState;
  
  
        case "SET_ADVANCE_ADD_OPEN_DRAW":
        const advanceAddDrawState = {
          ...state,
          advanceAddDraw: action.payload,
        };
        return advanceAddDrawState;
  
      /* ZIMMETLER ISLEMLERI */

      case "SET_DEBIT_DETAILS_DRAW":
        const debitDetailsDrawState = {
          ...state,
          debitDetailsDraw: action.payload,
        };
      return debitDetailsDrawState;

      case "SET_CREATE_OWNER_SHIP_DRAW":
        const createOwnerShipDrawerState = {
          ...state,
          createOwnerShipDrawer: action.payload,
        };
      return createOwnerShipDrawerState;

      case "SET_OWNER_SHIP_DETAILS_DRAW":
        const ownerShipDetailsDrawerState = {
          ...state,
          ownerShipDetailsDrawer: action.payload,
        };
      return ownerShipDetailsDrawerState;


      case "SET_PERSON_ADD_OPEN_STATUS":
        const personAddOpenDrawState = {
        ...state,
        personAddOpenDraw: action.payload,
      };
      return personAddOpenDrawState;
    

      case "SET_CONNECTED_PERSON_OPEN_STATUS":
        const connectedPersonsOpenDrawState = {
          ...state,
          connectedPersonsOpenDraw: action.payload,
      };
        return connectedPersonsOpenDrawState;

      case "SET_PERSON_DELETE_DRAWER":
        const personDeleteDrawerState = {
          ...state,
          personDeleteDrawer: action.payload,
        };
        return personDeleteDrawerState;
  

    default:return state;
  }
}
