{/*
    *timeoff.request.list //izinlerim litesi
    *timeoff.request.add //izin talep et
    *timeoff.approval.list // izin onayı listesi
    *timeoff.leave.list //izindeki çalışanlar listesi
    *timeoff.type.list //izin tipleri listesi
    *timeoff.type.canpassive //izin tipi aktif-pasif
    *timeoff.remaining.list // kalan izin listesi
    *timeoff.request.all.list //tüm izinler listesi
    *timeoff.extratime.add // izin ekleme butonu
    *timeoff.request.approve // izin onaylama
    *timeoff.request.reject //izin reddet
    *timeoff.request.cancel // izin iptal
    *timeoff.request.export //izin belgesi indir
    *timeoff.request.all.read
    *timeoff.request.read
    *timeoff.request.update
    *timeoff.request.delete
    *timeoff.remaining.delete
*/}

const tabDict = {
    "timeoff.request.list":"TimeOff",
    "timeoff.approval.list":"TimeOffRequestPerson",
    "timeoff.leave.list":"TimeOffPeople",
    "timeoff.type.list":"TimeOffTypes",
    "timeoff.remaining.list":"TimeOffRemainderYears",
    "timeoff.request.all.list":"TimeOffAll"
}

const timeoffListPermissions = [
    "timeoff.approval.list",
    "timeoff.leave.list",
    "timeoff.type.list",
    "timeoff.remaining.list",
    "timeoff.request.all.list"
];

export  {
    tabDict,
    timeoffListPermissions
}