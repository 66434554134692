import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Form, ConfigProvider } from 'antd';
import {
    getCountry, getProvinces, getDistricts, getNeighborhoods
} from '../../../redux/actions/address.actions.js';
import { getDataset } from "../../../redux/actions/dataset-action";


import DynamicSelectbox from "../../dynamicSelectbox/index";

import PhoneInput from 'react-phone-input-2';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

import { phoneInputConfig } from "../../../utils/config";


function AddPersonContact(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm()
    const [createPersonState, setCreatePersonState] = useState({ "Address": {} });
    const { setCreateObject, getDataset, getCountry, getProvinces, getDistricts, getNeighborhoods, countries, provinces, districts, neighborhoods, emergencyContactDegreeList, focusOnEmail } = props;

    const emailInput = React.useRef(null);
    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [distirctList, setDistirctList] = useState([]);
    const [neighborhoodList, setNeigborhoodList] = useState([]);
    const [emergencyDegreeList, setEmergencyDegreeList] = useState([]);



    useEffect(() => {
        setCountryList(countries);
    }, [countries]);
    useEffect(() => {
        setProvinceList(provinces);
    }, [provinces]);
    useEffect(() => {
        setDistirctList(districts);
    }, [districts]);
    useEffect(() => {
        setNeigborhoodList(neighborhoods);
    }, [neighborhoods]);
    useEffect(() => {
        setEmergencyDegreeList(emergencyContactDegreeList);
    }, [emergencyContactDegreeList]);

    useEffect(() => {
        setCreatePersonState({ "Address": { "CountryId": "224" } })
        form.setFieldsValue({
            "CountryId": { "id": "224", "displayValue": "Türkiye" },
            "cellPhone": "",
            "emergencyContactPersonPhone": ""
        })

    }, [form])


    const handleValuesChange = (changedObj, all) => {

        const changedKey = Object.keys(changedObj)[0];

        if (changedKey === 'CountryId') {
            if (JSON.stringify(changedObj.CountryId) !== null) {
                // ülke değişti
                setProvinceList([])//il listesini sıfırla
                setDistirctList([])//ilçe listesini sıfırla
                setNeigborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["ProvinceId"] = '';//ili sıfırla
                fieldsObj["DistrictId"] = '';//ilçeyi sıfırla
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...createPersonState.Address, "CountryId": changedObj['CountryId'].id, "ProvinceId": "", "DistrictId": "", "NeighborhoodId": "" }
                setCreatePersonState({ ...createPersonState, "Address": addressObj })

            }
            setProvinceList([])//il listesini sıfırla
            setDistirctList([])//ilçe listesini sıfırla
            setNeigborhoodList([])//mahalle listesini sıfırla

        } else if (changedKey === 'province') {
            if (JSON.stringify(changedObj.province) !== null) {
                // il değişti
                setDistirctList([])//ilçe listesini sıfırla
                setNeigborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["DistrictId"] = '';//ilçeyi sıfırla
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...createPersonState.Address, "Province": changedObj['province'] }
                setCreatePersonState({ ...createPersonState, "Address": addressObj })

            }
            setDistirctList([])//ilçe listesini sıfırla
            setNeigborhoodList([])//mahalle listesini sıfırla
        } else if (changedKey === 'ProvinceId') {
            if (JSON.stringify(changedObj.ProvinceId) !== null) {
                // il değişti
                setDistirctList([])//ilçe listesini sıfırla
                setNeigborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["DistrictId"] = '';//ilçeyi sıfırla
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...createPersonState.Address, "ProvinceId": changedObj['ProvinceId'].id, "DistrictId": "", "NeighborhoodId": "" }
                setCreatePersonState({ ...createPersonState, "Address": addressObj })

            }
            setDistirctList([])//ilçe listesini sıfırla
            setNeigborhoodList([])//mahalle listesini sıfırla
        } else if (changedKey === 'DistrictId') {
            if (JSON.stringify(changedObj.DistrictId) !== null) {
                // ilçe değişti1
                setNeigborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...createPersonState.Address, "DistrictId": changedObj['DistrictId'].id, "NeighborhoodId": "" }
                setCreatePersonState({ ...createPersonState, "Address": addressObj })

            }
            setNeigborhoodList([])//mahalle listesini sıfırla
        } else if (changedKey === 'NeighborhoodId') {
            const addressObj = { ...createPersonState.Address, "NeighborhoodId": changedObj['NeighborhoodId'].id }
            setCreatePersonState({ ...createPersonState, "Address": addressObj });
        } else if (changedKey === 'AddressLine') {
            const addressObj = { ...createPersonState.Address, ...changedObj }
            setCreatePersonState({ ...createPersonState, "Address": addressObj });
        } else if (changedKey === 'emergencyContactPersonDegreeOfProximity') {
            setCreatePersonState({ ...createPersonState, "emergencyContactPersonDegreeOfProximity": changedObj['emergencyContactPersonDegreeOfProximity'].id });
        } else {
            setCreatePersonState({ ...createPersonState, ...changedObj });
        }

    }
    useEffect(() => {
        setCreateObject(createPersonState)
    }, [createPersonState]);
    useEffect(() => {
        if (focusOnEmail) {
            emailInput.current.inputElement.focus();
        }
    }, [focusOnEmail]);

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
                requiredMark={false}

            >
                <Form.Item name="personalEmail" label={t('PARTNER_PERSONEL_MAIL')} required >
                    <MaskedInput ref={emailInput} mask={emailMask} className="ant-input" />
                </Form.Item>
                <Form.Item name="cellPhone" label={t('PARTNER_CELL_PHONE')}>
                    <PhoneInput
                        {...phoneInputConfig}
                    />
                </Form.Item>
                <Form.Item name="emergencyContactPersonName" label={t('EMERGENCY_CONTACT_NAME')} required >
                    <Input />
                </Form.Item>
                <Form.Item name="emergencyContactPersonDegreeOfProximity" className="custom-select" label={t('EMERGENCY_CONTACT_DEGREE')}>
                    <DynamicSelectbox optionList={emergencyDegreeList} placeholder={t('PARTNER_SELECT')} onFocus={() => getDataset(34)} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="emergencyContactPersonPhone" label={t('EMERGENCY_CONTACT_PHONE')}>
                    <PhoneInput
                        {...phoneInputConfig}
                    />
                </Form.Item>
                <div className="ant-col ant-form-item-label"><label>{t('PARTNER_CUSTOM_FIELDS')}</label></div>
                <Form.Item name="twitterLink" label={t('PARTNER_TWITTER_USERNAME')}  >
                    <Input />
                </Form.Item>
                <Form.Item name="instagramName" label={t('PARTNER_INSTAGRAM_USERNAME')}  >
                    <Input />
                </Form.Item>
                <Form.Item name="facebookName" label={t('PARTNER_FACEBOOK_USERNAME')}  >
                    <Input />
                </Form.Item>
                <Form.Item name="linkedinUrl" label={t('PARTNER_LINKEDIN_USERNAME')}  >
                    <Input />
                </Form.Item>
                <Form.Item name="personalPageUrl" label={t('PARTNER_PERSONEL_PAGE')}  >
                    <Input />
                </Form.Item>

                <Form.Item name="CountryId" className="custom-select" label={t('ORGANIZATION_COUNTRY')}>
                    <DynamicSelectbox optionList={countryList} placeholder={t('COUNTRY_PLACEHOLDER')} onFocus={() => getCountry()} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                    <Input />
                </Form.Item>
                {/*
                <Form.Item name="ProvinceId" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                    <DynamicSelectbox optionList={provinceList} placeholder={t('PROVINCE_PLACEHOLDER')} onFocus={() => getProvinces(createPersonState.Address.CountryId !== '' ? createPersonState.Address.CountryId : null)} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="DistrictId" className="custom-select" label={t('ORGANIZATION_DISTRICT')}>
                    <DynamicSelectbox optionList={distirctList} disabled={!createPersonState.Address.ProvinceId} placeholder={t('DISTRICT_PLACEHOLDER')} onFocus={() => getDistricts(createPersonState.Address.ProvinceId !== '' ? createPersonState.Address.ProvinceId : null)} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="NeighborhoodId" className="custom-select" label={t('ORGANIZATION_REGION')}>
                    <DynamicSelectbox
                        optionList={neighborhoodList} disabled={!createPersonState.Address.DistrictId} onFocus={() => getNeighborhoods(createPersonState.Address.DistrictId !== '' ? createPersonState.Address.DistrictId : null)} placeholder={t('REGION_PLACEHOLDER')}></DynamicSelectbox>
                </Form.Item>
                 */}
                <Form.Item name="AddressLine" label={t('ORGANIZATION_ADDRESS')}>
                    <Input.TextArea />
                </Form.Item>
            </Form >
        </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        countries: state.addressReducer.countries,
        provinces: state.addressReducer.provinces,
        districts: state.addressReducer.districts,
        neighborhoods: state.addressReducer.neighborhoods,
        emergencyContactDegreeList: state.dataset.emergencyContactDegreeList
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },
        getDistricts: (provinceId) => { dispatch(getDistricts(provinceId)) },
        getNeighborhoods: (districtId) => { dispatch(getNeighborhoods(districtId)) },
        getDataset: (id) => { dispatch(getDataset(id)) },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonContact);
