import { Layout, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import BreadCrump from '../../features/breadcrump/breadcrump';
import Comingsoon from '../../component/comingsoon/index';

function Users() {
    const { t } = useTranslation();
    const { Content } = Layout;
    return (
        <Layout>
            <Layout>
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrump />
                        <div className="tab-page">
                            <h1 className="page-title">{t('USERS')}</h1>
                            <div className="pad-15" />
                        </div>
                        <Comingsoon />
                    </Content >
                </Col>
            </Layout>
        </Layout >
    )
}
export default Users;