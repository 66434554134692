import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Input, ConfigProvider, Radio, Select } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import { getDataset } from '../../../../redux/actions/dataset-action';


import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/timeoffTypes.scss';

function AddTimeoffTypeBasicInfo(props) {
    const { t } = useTranslation();

    const [form] = Form.useForm()
    const { dataset, getDataset, setCreateTimeoffTypeObject, createTimeoffTypeObject } = props;

    const [paymentTypesList, setPaymentTypes] = useState([]);
    const [unitSetList, setUnitSetList] = useState([]);
    const [accrualUnitTypesList, setAccrualUnitTypesList] = useState([]);
    const [accrualDurationTypesList, setAccrualDurationTypesList] = useState([]);

    useEffect(() => {
        // setCreateTimeoffTypeObject({ "unitSetIdx": "1","accrualUnitSetIdx":"5", "accrualDurationTypeSetIdx":"1" })
        form.setFieldsValue({
            "unitSetIdx": { "id": null, "displayValue": null },
            "accrualUnitSetIdx": { "id": null, "displayValue": null },
            "accrualDurationTypeSetIdx": { "id": null, "displayValue": null },
        })

    }, [form])

    const allowOnlyNumericKeyPress = (e) => {
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericPaste = (e) => {
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };


    useEffect(() => {
        setPaymentTypes(dataset.paymentTypes)
    }, [dataset.paymentTypes])

    useEffect(() => {
        setUnitSetList(dataset.unitTypes);
    }, [dataset.unitTypes]);

    useEffect(() => {
        setAccrualUnitTypesList(dataset.accrualUnitTypes);
    }, [dataset.accrualUnitTypes]);

    useEffect(() => {
        setAccrualDurationTypesList(dataset.accrualDurationTypes);
    }, [dataset.accrualDurationTypes]);


    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'paymentSetIdx') {
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'paymentSetIdx': changedObj["paymentSetIdx"].id });
        }
        else if( key=== 'isBalanceUnlimited'){
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, isBalanceUnlimited: changedObj["isBalanceUnlimited"], 'accrualUnitSetIdx': null , 'maxBalance':null, 'unitSetIdx': null  });
            form.setFieldsValue({
                "accrualUnitSetIdx":{ "id": null, "displayValue": null },
                "unitSetIdx": { "id": null, "displayValue": null },
                "maxBalance": null
            })
        }
        else if (key === 'accrualUnitSetIdx') {
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'accrualUnitSetIdx': changedObj["accrualUnitSetIdx"].id , 'maxBalance':null, 'unitSetIdx': null  });
            form.setFieldsValue({
                "unitSetIdx": { "id": null, "displayValue": null },
                "maxBalance": null
            })
            if(changedObj["accrualUnitSetIdx"].id == 2){
                const filteredunitSetList = unitSetList.filter(u => u.id == 3 );
                setUnitSetList(filteredunitSetList)
            }else{
                setUnitSetList(dataset.unitTypes)
            }
        }
        else if (key === 'unitSetIdx') {
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'unitSetIdx': changedObj["unitSetIdx"].id, 'maxBalance':null });
            form.setFieldsValue({
                "maxBalance": null
            })
        }
        else if (key === 'accrualDurationTypeSetIdx') {
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'accrualDurationTypeSetIdx': changedObj["accrualDurationTypeSetIdx"].id });
        }
        else if (key === 'maxBalance') {
            let maxValueList = {
                5:365, //Yıl
                4:30, //Ay
                3:7, //Hafta
                2:24 //Gün
            }
            const maxValueMultiplier = {
                1:1, //günlük
                3:24 //saatlik
            }
            let maxValue = maxValueList[createTimeoffTypeObject.accrualUnitSetIdx] * ( createTimeoffTypeObject.accrualUnitSetIdx == 2 ? 1 : maxValueMultiplier[createTimeoffTypeObject.unitSetIdx] || 1) ;
            let minValue = 1;

            let filteredValue = changedObj["maxBalance"]
            if(changedObj["maxBalance"]>maxValue){
                filteredValue = maxValue
            }else if(changedObj["maxBalance"] < minValue &&  changedObj["maxBalance"] != ''){
                filteredValue = minValue
            }
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'maxBalance': filteredValue  });
            form.setFieldsValue({"maxBalance": filteredValue})
        }
        else if( key=='accrualDateSetIdx'){
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, 'accrualDateSetIdx': changedObj["accrualDateSetIdx"], 'accrualDuration':null, 'accrualDurationTypeSetIdx':null });
            form.setFieldsValue({
                "accrualDuration": null,
                "accrualDurationTypeSetIdx": { "id": null, "displayValue": null }
            })
        }
        else {
            setCreateTimeoffTypeObject({ ...createTimeoffTypeObject, ...changedObj });
        }
    }

    const { Option } = Select;

    const limitBefore = (
        <Form.Item className="add-user-draw-required-input" name="accrualUnitSetIdx" required>
            <DynamicSelectbox
                optionList={accrualUnitTypesList}
                onFocus={() => getDataset(6)} 
                placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                size="large"
            />
        </Form.Item>
    );

    const limitAfter = (
        <Form.Item className="add-user-draw-required-input" name="unitSetIdx" required>
            <DynamicSelectbox
                optionList={unitSetList}
                onFocus={() => getDataset(4)}
                placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                size="large"
                disabled={!createTimeoffTypeObject.accrualUnitSetIdx}
            />
        </Form.Item>
    );

    const accrualAfter = (
        <Form.Item className="add-user-draw-required-input" name="accrualDurationTypeSetIdx" required>
            <DynamicSelectbox
                optionList={accrualDurationTypesList}
                onFocus={() => getDataset(42)}
                placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                size="large"
            />
        </Form.Item>
    );

    return (
        
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <Form.Item className="max-width-50per" name="name" label={t('TIMEOFF_NAME') + " *"} required>
                        <Input placeholder={t('TIMEOFF_NAME_PLACEHOLDER')} />
                    </Form.Item>
                    <Form.Item className="max-width-50per" name="paymentSetIdx" label={t('TIMEOFF_TYPE') + " *"} required>
                        <DynamicSelectbox
                            optionList={paymentTypesList}
                            onFocus={() => getDataset(1)}
                            placeholder={t('TIMEOFF_TYPE_PLACEHOLDER')}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="description" className="max-width-50per custom-select" label={t('TIMEOFF_DESCRIPTION')}>
                        <Input.TextArea placeholder={t("TIMEOFF_DESCRIPTION_PLACEHOLDER")} />
                    </Form.Item>
                    <Form.Item className="margin-b-12 max-width-50per" name="isBalanceUnlimited" label={t('TIMEOFF_DURATION') + " *"} >
                        <Radio.Group options={
                            [
                                { label: `${t('TIMEOFF_LIMITLESS') }`, value: 1 },
                                { label: `${t('TIMEOFF_LIMITED')}`, value: 0 },
                            ]
                        } />
                    </Form.Item>
                    { createTimeoffTypeObject?.isBalanceUnlimited == 0 && (<>
                    <Row gutter={16} className="max-width-50per">
                        <Col span={12}>
                            <Form.Item className="add-user-draw-required-input" name="accrualUnitSetIdx" label={t('TIMEOFF_LIMITED_LIMIT') + " *"}>
                                <DynamicSelectbox
                                    optionList={accrualUnitTypesList}
                                    onFocus={() => getDataset(6)} 
                                    placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="add-user-draw-required-input no-label-input-in-row" name="unitSetIdx">
                                <DynamicSelectbox
                                    optionList={unitSetList}
                                    onFocus={() => getDataset(4)}
                                    placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                                    size="large"
                                    disabled={!createTimeoffTypeObject.accrualUnitSetIdx}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex align-items-center">
                            <span className="dotted-underline">{dataset.accrualUnitTypes?.filter( item => item.id == createTimeoffTypeObject?.accrualUnitSetIdx)[0]?.displayValue}</span>
                            <Form.Item className="margin-b-12 px-3 delete-draw-label custom-input-group max-width-50per " name="maxBalance" >
                                <Input size="large" disabled={!createTimeoffTypeObject.accrualUnitSetIdx || !createTimeoffTypeObject.unitSetIdx} onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} placeholder={t("TIMEOFF_LIMITED_PLACEHOLDER")} />
                            </Form.Item>
                            <span className="dotted-underline me-3">{dataset.unitTypes?.filter( item => item.id == createTimeoffTypeObject?.unitSetIdx)[0]?.displayValue}</span>
                            <span> {t('TIMEOFF_BALANCE_LIMIT_POSTFIX')}</span>
                        </Col>
                    </Row>
                    </>)}
                    <Form.Item className="margin-b-12 delete-draw-label custom-label-tooltip" name="accrualDateSetIdx" label={t('TIMEOFF_STARTING_TIME') + " *"} tooltip={{
                    title: t('TIMEOFF_ACRUAL_DESC'),
                    icon: <InfoCircleOutlined />,
                    placement:'top'
                    }} >
                        <Radio.Group options={
                            [
                                { label: `${t('TIMEOFF_STARTING_TIME_OPTION_1') }`, value: 1 },
                                { label: `${t('TIMEOFF_STARTING_TIME_OPTION_2')}`, value: 3 },
                            ]
                        } />
                    </Form.Item>
                    { createTimeoffTypeObject?.accrualDateSetIdx == 3 &&
                    <div className="d-flex align-items-center">
                        <span style={{minWidth:'fit-content',marginRight:'16px'}}>{t("TIMEOFF_START_TIME_FROM")} </span>
                        <Form.Item className="delete-draw-label custom-input-group mb-0" name="accrualDuration">
                            <Input style={{maxWidth: '245px'}} addonAfter={accrualAfter} onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} placeholder={t("TIMEOFF_LIMITED_PLACEHOLDER")} />
                        </Form.Item>
                        <span style={{minWidth:'fit-content',marginLeft:'16px'}}>{t("TIMEOFF_START_TIME_AFTER")}</span>
                    </div>
                    }
                </Form>
            </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        dataset: state.dataset
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTimeoffTypeBasicInfo);