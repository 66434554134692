import React from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkbox from "antd/lib/checkbox/Checkbox";

import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR';
import 'moment/locale/tr';

import { Form, Col, Row, Spin, ConfigProvider } from 'antd';

import { dateFormatConvert } from '../../utils/config';
import { checkTextIsEmpty, checkMomentTextIsEmpty } from '../../utils/commonFormFunctions'

import '../../styles/person.scss';


function ProfilPersonInformation(props) {

    const { t } = useTranslation();
    const { personInformation, personInformationIsFetching } = props;


    return (
        personInformationIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form layout="vertical pad-top-10" >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-1">
                                <Form.Item name="birthday" label={t('PARTNER_BIRTHDAY')} required>
                                    <p className="form-text">{checkMomentTextIsEmpty(personInformation.birthday, dateFormatConvert)}</p>
                                </Form.Item>
                                <Form.Item name="nationalIdentityNumber" label={t('PARTNER_NATIONAL_ID')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.nationalIdentityNumber)}</p>
                                </Form.Item>
                                <Form.Item name="maritalStatus" className="custom-select" label={t('PARTNER_MARITAL_STATUS')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.maritalStatus?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="careerStartDate" label={t('PARTNER_CAREER_START_DATE')}>
                                    <p className="form-text">{checkMomentTextIsEmpty(personInformation.careerStartDate, dateFormatConvert)}</p>
                                </Form.Item>

                            </div>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-2">

                                <Form.Item name="gender" className="custom-select" label={t('PARTNER_GENDER')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.gender?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="passportNumber" label={t('PARTNER_PASSAPORT_NUMBER')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.passportNumber)}</p>
                                </Form.Item>
                                <Form.Item name="childrenCount" className="custom-select" label={t('PARTNER_CHILDREN_COUNT')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.childrenCount)}</p>
                                </Form.Item>
                                <Form.Item name="languages" className="custom-select" label={t('PARTNER_LANGUAGE')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.languages?.displayValue)}</p>
                                </Form.Item>

                            </div>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-3">
                                {personInformation?.gender?.id === "1" &&
                                    <Form.Item name="militaryStatusType" className="custom-select" label={t('MILITARY_STATUS_TYPE')}>
                                        <p className="form-text">{checkTextIsEmpty(personInformation.militaryStatusType?.displayValue)}</p>
                                    </Form.Item>
                                }
                                <Form.Item name="educationDegree" className="custom-select" label={t('PARTNER_EDUCATION_STATUS')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.educationDegree?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="bloodGroup" className="custom-select" label={t('PARTNER_BLOOD_GROUP')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.bloodGroup?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="isStudent" >
                                    <Checkbox defaultChecked={personInformation.isStudent} disabled={true}>{t('PARTNER_STUDENT')}</Checkbox>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-4">
                                <Form.Item name="nationality" className="custom-select" label={t('PARTNER_NATIONALITY')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.nationality?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="drivingLicenceType" className="custom-select" label={t('DRIVING_LICENCE_TYPE')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.drivingLicenceType?.displayValue)}</p>
                                </Form.Item>

                                {personInformation?.drivingLicenceType &&
                                    <Form.Item name="drivingLicenceNumber" label={t('DRIVING_LICENCE_NUMBER')}>
                                        <p className="form-text">{checkTextIsEmpty(personInformation.drivingLicenceNumber)}</p>
                                    </Form.Item>
                                }
                                {personInformation.maritalStatus?.id === "2" && (
                                    <React.Fragment>
                                        <Form.Item name="maritalDate" label={t('MARITAL_DATE')}>
                                            <p className="form-text">{checkMomentTextIsEmpty(personInformation.maritalDate, dateFormatConvert)}</p>
                                        </Form.Item>
                                        <Form.Item name="coupleBirthDate" label={t('COUPLE_BIRTH_DATE')}>
                                            <p className="form-text">{checkMomentTextIsEmpty(personInformation.coupleBirthDate, dateFormatConvert)}</p>
                                        </Form.Item>
                                        <Form.Item name="coupleWorkStatus" className="custom-select" label={t('PARTNER_COUPLE_WORK')}>
                                            <p className="form-text">{checkTextIsEmpty(personInformation.coupleWorkStatus?.displayValue)}</p>
                                        </Form.Item>
                                    </React.Fragment>
                                )}
                                <Form.Item name="disabilityDegree" className="custom-select" label={t('PARTNER_DISABILITY_DEGREE')}>
                                    <p className="form-text">{checkTextIsEmpty(personInformation.disabilityDegree?.displayValue)}</p>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider >

        ))
}

const mapStateToProps = (state) => {
    return {

    };
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilPersonInformation);
