import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    setTravelHostCreateDrawer, getMyTravelHostList, setTravelHostDetailsDrawer,
    updateTravelHostReset, resetTravelHostChangeStatus, getTravelHostApprovalList,
    getAllTravelHostList
} from '../../redux/actions/travelHost.actions';
import { Layout, Col, Tabs, Drawer } from 'antd';
import { ToastContainer } from 'react-toastify';

import BreadCrumb from '../../features/breadcrump/breadcrump';
import MyTravelHostRequests from '../../component/travelHost/my_travel_host_request_table';
import ApprovalTravelHostRequestTable from '../../component/travelHost/approval_travel_host_request_table';
import AllTravelHostRequestTable from '../../component/travelHost/all_travel_host_request_table';
import CreateTravelHostAllTab from '../../component/travelHost/add/travel_host_all_tabs';
import TravelHostDetails from '../../component/travelHost/details/travel_host_details';
import DynamicToastMessage from '../../component/dynamicToastMessage';
import Forbidden from "../../component/forbidden";

function MyTravelHostRequest(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const [currentTab, setCurrentTab] = useState('MyTravelHostRequests');
    const [createKey, setCreateKey] = useState(1);
    const [detailsKey, setDetailKey] = useState(100);
    const { permissions, getMyTravelHostList, travelHostCreateDrawer, setTravelHostCreateDrawer,
        travelHostChangeStatusResponse, setTravelHostDetailsDrawer, travelHostDetailsDrawer,
        travelHostUpdateResponse, updateTravelHostReset, resetTravelHostChangeStatus, getTravelHostApprovalList,
        getAllTravelHostList } = props;
    const myRequestInitialSearch = {
        "SearchTerm": "",
        "stateTypes": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const approvalRequestsInitialSearch = {
        "SearchTerm": "",
        "stateTypes": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const allRequestInitialSearch = {
        "SearchTerm": "",
        "stateTypes": [],
        "ShowPassiveRecords": 0,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "CreatedDate",
            "ascending": false
        }
    };
    const [myRequestSearchState, setMyRequestSearchState] = useState(myRequestInitialSearch);
    const [approvalRequestsSearchState, setApprovalRequestsSearchState] = useState(approvalRequestsInitialSearch);
    const [allRequestSearchState, setAllRequestSearchState] = useState(allRequestInitialSearch);
    const [selectItem, setSelectItem] = useState(null);

    function callback(key) {
        setCurrentTab(key);
    }
    const openCreateRequest = () => {
        setTravelHostCreateDrawer(true)
        setCreateKey((i) => i + 1)
    }
    const openDetailsDrawer = () => {
        setTravelHostDetailsDrawer(true)
        setDetailKey((i) => i + 1)
    }
    const updateTable = () => {
        if (currentTab === "MyTravelHostRequests") {
            getMyTravelHostList(myRequestSearchState);
        } else if (currentTab === "TravelHostRequests") {
            getTravelHostApprovalList(approvalRequestsSearchState);
        } else if (currentTab === "AllTravelHostRequest") {
            getAllTravelHostList(allRequestSearchState);
        }
    }
    useEffect(() => {
        updateTable();
    }, [currentTab])

    useEffect(() => {
        if (!travelHostDetailsDrawer) {
            if (travelHostUpdateResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': travelHostUpdateResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                updateTravelHostReset()
            }
            else if (travelHostUpdateResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': travelHostUpdateResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                updateTravelHostReset()
            }
        } else {
            if (travelHostUpdateResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [travelHostUpdateResponse])

    useEffect(() => {
        if (!travelHostDetailsDrawer) {

            if (travelHostChangeStatusResponse.status === 'SUCCESS') {
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': travelHostChangeStatusResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                updateTable();
                resetTravelHostChangeStatus()
            }
            else if (travelHostChangeStatusResponse.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': travelHostChangeStatusResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                resetTravelHostChangeStatus()
            }
        } else {
            if (travelHostChangeStatusResponse.status === 'SUCCESS') {
                updateTable();
            }
        }
    }, [travelHostChangeStatusResponse])
    return (
        permissions.includes('travelhostrequest.list.all') ?
        <React.Fragment>
            <Layout>
                <Drawer key={createKey} title={false} width={1280} closable={false} onClose={false} placement="right" visible={travelHostCreateDrawer} >
                    <CreateTravelHostAllTab />
                </Drawer >
                <Drawer key={detailsKey} title={false} width={1000} closable={false} onClose={false} placement="right" visible={travelHostDetailsDrawer}>
                    <TravelHostDetails selectItem={selectItem} />
                </Drawer>
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrumb />
                        <div className="tab-menu ">
                            <h1 className="page-title">{t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST')}</h1>
                            <Tabs
                                className="pad-top-20 page-tabs"
                                onChange={callback}
                                defaultActiveKey="MyTravelHostRequests"
                                activeKey={currentTab}
                            >
                                <TabPane tab={<span>{t('MY_TRANSPORTATION_AND_ACCOMMODATION_REQUESTS')}</span>} key="MyTravelHostRequests">
                                    <MyTravelHostRequests
                                        openCreateRequest={openCreateRequest}
                                        openDetailsDrawer={openDetailsDrawer}
                                        myRequestInitialSearch={myRequestInitialSearch}
                                        myRequestSearchState={myRequestSearchState}
                                        setMyRequestSearchState={setMyRequestSearchState}
                                        setSelectItem={setSelectItem}
                                    />
                                </TabPane>
                                <TabPane tab={<span>{t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST_APPROVALS')}</span>} key="TravelHostRequests">
                                    <ApprovalTravelHostRequestTable
                                        currentTab={currentTab}
                                        openDetailsDrawer={openDetailsDrawer}
                                        approvalRequestsInitialSearch={approvalRequestsInitialSearch}
                                        approvalRequestsSearchState={approvalRequestsSearchState}
                                        setApprovalRequestsSearchState={setApprovalRequestsSearchState}
                                        setSelectItem={setSelectItem}
                                    />
                                </TabPane>
                                {permissions.includes("travelhostrequest.list.all") && (
                                    <TabPane tab={<span>{t('ALL_TRANSPORTATION_AND_ACCOMMODATION_REQUEST')}</span>} key="AllTravelHostRequest">
                                        <AllTravelHostRequestTable
                                            currentTab={currentTab}
                                            openDetailsDrawer={openDetailsDrawer}
                                            allRequestInitialSearch={allRequestInitialSearch}
                                            allRequestSearchState={allRequestSearchState}
                                            setAllRequestSearchState={setAllRequestSearchState}
                                            setSelectItem={setSelectItem}
                                        />
                                    </TabPane>
                                )}
                            </Tabs>
                        </div>
                    </Content>
                </Col>
            </Layout>
            <ToastContainer />
        </React.Fragment>
        : <Forbidden size="sm" />
    )
}
const mapStateToProps = (state) => {
    return {
        permissions: state.profile.permissions,
        travelHostCreateDrawer: state.travelHostReducer.travelHostCreateDrawer,
        travelHostDetailsDrawer: state.travelHostReducer.travelHostDetailsDrawer,
        travelHostUpdateResponse: state.travelHostReducer.travelHostUpdateResponse,
        travelHostChangeStatusResponse: state.travelHostReducer.travelHostChangeStatusResponse,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMyTravelHostList: (searchData) => { dispatch(getMyTravelHostList(searchData)) },
        setTravelHostCreateDrawer: (status) => { dispatch(setTravelHostCreateDrawer(status)) },
        setTravelHostDetailsDrawer: (status) => { dispatch(setTravelHostDetailsDrawer(status)) },
        updateTravelHostReset: () => { dispatch(updateTravelHostReset()) },
        resetTravelHostChangeStatus: () => { dispatch(resetTravelHostChangeStatus()) },
        getTravelHostApprovalList: (searchData) => { dispatch(getTravelHostApprovalList(searchData)) },
        getAllTravelHostList: (searchData) => { dispatch(getAllTravelHostList(searchData)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyTravelHostRequest);