import React from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Spin, ConfigProvider } from 'antd';


function ProfileSocialMedia(props) {

    const { t } = useTranslation();
    const { socialmedia, personSocialIsFetching } = props;




    return (
        personSocialIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form layout="vertical pad-top-10" >
                    <div className="form-left-body view-mode">
                        <Form.Item name="Twitter" label={t('PARTNER_TWITTER_USERNAME')} required >
                            {socialmedia.socialMediaAccounts.find((item) => item.type === "Twitter").link ?
                                <div className="flex-align-center"> <div className="form-text">{socialmedia.socialMediaAccounts.find((item) => item.type === "Twitter").link}</div> </div> : ' - '

                            }
                        </Form.Item>
                        <Form.Item name="Instagram" label={t('PARTNER_INSTAGRAM_USERNAME')} required >
                            {socialmedia.socialMediaAccounts.find((item) => item.type === "Instagram").link ?
                                <div className="flex-align-center"> <div className="form-text">{socialmedia.socialMediaAccounts.find((item) => item.type === "Instagram").link}</div> </div> : ' - '
                            }
                        </Form.Item>
                        <Form.Item name="Facebook" label={t('PARTNER_FACEBOOK_USERNAME')} required >
                            {socialmedia.socialMediaAccounts.find((item) => item.type === "Facebook").link ?
                                <div className="flex-align-center"> <div className="form-text">{socialmedia.socialMediaAccounts.find((item) => item.type === "Facebook").link}</div> </div> : ' - '

                            }
                        </Form.Item>
                        <Form.Item name="Linkedin" label={t('PARTNER_LINKEDIN_USERNAME')} required >
                            {socialmedia.socialMediaAccounts.find((item) => item.type === "Linkedin").link ?
                                <div className="flex-align-center"><div className="form-text">{socialmedia.socialMediaAccounts.find((item) => item.type === "Linkedin").link}</div> </div> : ' - '

                            }
                        </Form.Item>
                        <Form.Item name="personalPageUrl" label={t('PARTNER_PERSONEL_PAGE')} required >
                            {socialmedia.personalPageUrl ?
                                <div className="flex-align-center"><div className="form-text">{socialmedia.personalPageUrl}</div> </div> : ' - '
                            }
                        </Form.Item>
                    </div>
                </Form >
            </ConfigProvider >
        )
    )

}

const mapStateToProps = (state) => {
    return {
    };
}


const mapDispatchToProps = (dispatch) => {
    return {


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSocialMedia);
