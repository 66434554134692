import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, ConfigProvider, Input, Spin } from 'antd';
import DynamicSelectbox from "../../../dynamicSelectbox";
import { getPersons } from "../../../../redux/actions/organization.actions";
import { getAssignedTasksStepList } from "../../../../redux/actions/task.actions";
import '../../../../styles/person.scss';
import '../../../../styles/advances.scss';

function CreateTaskRequestsTab(props) {
    const { t } = useTranslation();
    const inputRef = useRef([]);
    const [createTaskRequest, setCreateTaskRequest] = useState({
        data: {}
    });
    const [taskDescriptionLabelState, setTaskDescriptionLabelState] = useState([]);
    const { createTaskObj, setCreateTaskObj, currentTab, getPersons, profile, persons, getAssignedTasksStepList,
        createAssignedForm, createAssignedFormLoading, assignedTasksListState } = props;

    const handleValuesChange = (event, item) => {
        if (item === "person") {
            getAssignedTasksStepList(event?.id);
            if (event?.id) {
                setCreateTaskRequest({ ...createTaskRequest, "person": event });
            } else {
                setCreateTaskRequest({ ...createTaskRequest, "person": null });
            }
        } else {
            const newData = { ...createTaskRequest.data };
            const resourceId = assignedTasksListState[currentTab - 1]?.resourceId;
            const targetItem = newData[resourceId].find(i => i.resourceId === item.resourceId);
            if (targetItem) {
                targetItem.value = event.target.value;
            }
            setCreateTaskRequest({ ...createTaskRequest, data: newData });
        }
    }
    const mapFormItem = (item, index) => {
        if (item.type === "input") {
            return (
                <Form.Item key={index} name={item.resourceId} label={item.attributeName}>
                    <Input
                        ref={el => inputRef.current[index] = el}
                        value={createTaskRequest.data[assignedTasksListState[currentTab - 1]?.resourceId][index]?.value}
                        onChange={(e) => handleValuesChange(e, item)}
                    />
                </Form.Item>
            );
        }
        //  else {
        //     return (
        //         <Form.Item key={index} name={item.resourceId} label={item.attributeName}>
        //             <Input.TextArea
        //                 ref={el => inputRef.current[index] = el}
        //                 value={createTaskRequest.data[assignedTasksListState[currentTab - 1]?.resourceId][index]?.value}
        //                 onChange={(e) => handleValuesChange(e, item)}
        //             />
        //         </Form.Item>
        //     );
        // }
    };

    useEffect(() => {
        setCreateTaskObj(createTaskRequest)
    }, [createTaskRequest]);
    useEffect(() => {
        if (!createAssignedFormLoading && currentTab > 0) {
            setTaskDescriptionLabelState({ ...taskDescriptionLabelState, [assignedTasksListState[currentTab - 1]?.resourceId]: createAssignedForm?.taskDescription });
            if (createAssignedForm?.attributes?.length > 0 && !Object.keys(createTaskRequest.data).includes(assignedTasksListState[currentTab - 1]?.resourceId)) {
                var formList = [];
                createAssignedForm.attributes.map(formData =>
                    formList.push({
                        ...formData,
                        'value': "",
                        "type": "input"
                    }));
                // formList.push({
                //     "resourceId": "description_" + assignedTasksListState[currentTab - 1]?.resourceId,
                //     "attributeName": t('ADVANCE_DESCRIPTION'),
                //     "value": "",
                //     "type": "textArea"
                // })
                const dataObj = { ...createTaskObj.data, [assignedTasksListState[currentTab - 1]?.resourceId]: formList };
                setCreateTaskRequest({ ...createTaskObj, data: dataObj });
            } else if (!Object.keys(createTaskRequest.data).includes(assignedTasksListState[currentTab - 1]?.resourceId)) {
                const dataObj = { ...createTaskObj.data, [assignedTasksListState[currentTab - 1]?.resourceId]: [] };
                setCreateTaskRequest({ ...createTaskObj, data: dataObj });
            } else {
                return false
            }
        }
    }, [createAssignedForm, createAssignedFormLoading])

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form layout={currentTab === 0 ? "vertical min pl-3" : "vertical drawer-scrollable assigned-task-drawer pl-3"} >
                {currentTab === 0 ?
                    <>
                        <p className="f-14 black pt-4 my-0">{createTaskRequest.person?.id ? t('TASK_ASSIGNED_NEXT_BUTTON') : t('TASK_SELECT_THE_PERSON')} </p>
                        <Form.Item className="custom-select " label={t('SELECT_EMPLOYEE')}>
                            <DynamicSelectbox
                                placeholder={t('PLEASE_SELECT_EMPLOYEE')}
                                onChange={(e) => handleValuesChange(e, "person")}
                                optionList={persons}
                                value={createTaskRequest?.person ? createTaskRequest?.person : null}
                                onFocus={() => getPersons(profile.organization?.resourceId)}
                            />
                        </Form.Item>
                    </>
                    :
                    createAssignedFormLoading ?
                        <Spin className="tab-loading" />
                        :
                        <>
                            {(taskDescriptionLabelState[assignedTasksListState[currentTab - 1]?.resourceId] === '')
                                && createTaskRequest.data[assignedTasksListState[currentTab - 1]?.resourceId].length === 0 &&
                                <p className="pt-4 f-16 black">{t('TASK_DETAIL_INFORMATION_NOT_FOUND')}</p>
                            }
                            {taskDescriptionLabelState[assignedTasksListState[currentTab - 1]?.resourceId] &&
                                <Form.Item className="pt-4 pb-1 mar-0" label={t('MISSION_DESCRIPTION')}>
                                    <p className="f-16 black" >{taskDescriptionLabelState[assignedTasksListState[currentTab - 1]?.resourceId]}</p>
                                </Form.Item>
                            }
                            {
                                createTaskRequest.data[assignedTasksListState[currentTab - 1]?.resourceId] &&
                                createTaskRequest.data[assignedTasksListState[currentTab - 1]?.resourceId].map(mapFormItem)
                            }
                        </>
                }
            </Form>
        </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        persons: state.organization.persons,
        createAssignedForm: state.taskReducer.createAssignedForm,
        createAssignedFormLoading: state.taskReducer.createAssignedFormLoading
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPersons: (id) => { dispatch(getPersons(id)) },
        getAssignedTasksStepList: (resourceId) => { dispatch(getAssignedTasksStepList(resourceId)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskRequestsTab);