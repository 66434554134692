import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { importInventory,resetImportInventory } from '../../../redux/actions/inventory.actions';
import { importPerson,resetImportPerson } from '../../../redux/actions/person.actions';
import { importDepartment,resetImportDepartment } from '../../../redux/actions/department.action';

import { Modal, Button, Form, Spin, Image, Steps, Upload, Typography, List } from 'antd';
import { UilFileImport, UilTimes, UilExport, UilCheckCircle, UilTimesCircle,UilClipboardNotes } from '@iconscout/react-unicons';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import '../../../styles/modals.scss';

const InventoryImportModal = ({ 
        /* Person İmport */     importPerson, resetImportPerson, personImportResponse,
        /* Department İmport */ importDepartment, resetImportDepartment, departmentImportResponse,
        /* Inventory İmport */ importInventory, inventoryImportResponse,resetImportInventory, 
                                modalType, cancelBtnFunction, modalState = {} }) => {
    const { t } = useTranslation();
    const { Step } = Steps;
    const { Dragger } = Upload;
    const [tabIndex, setTabIndex] = useState(1);
    const [formData, setFormData] = useState({'file': null });
    const [inventoryImportLoader, setInventoryImportLoader] = useState(false);
    const [listModalState, setListModalState] = useState({
        "key":4000,
        "isVisible":false
    })
    const [resultMessage, setResultMessage] = useState({
        'successCount':null,
        'errorCount': null,
        'errorList': []
    });
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nLang') || 'en');
    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: ".xlsx",
        beforeUploadAction: function () {
            return false;
        },
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        onChange(info) {
            if (info?.fileList[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                setFormData({ 'file': info.fileList });
            }
        },
        // onDrop(e) {
        //     console.log('Dropped files', e.dataTransfer.files);
        // },
    };

    const nextForm = () => {
        if(tabIndex === 2){
            if (!formData?.file) {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': t('INVENTORY_EXPORT_DRAGGER_UPLOAD_DESCRIPTION'),
                    'statusType': 'error',
                    'position': 'bottom-right'
                });
                return false
            } else {
                setInventoryImportLoader(true);
                
                if(modalType == 'inventory'){
                    importInventory(formData?.file[0]?.originFileObj);
                }
                
                if(modalType == 'person'){
                    importPerson(formData?.file[0]?.originFileObj);
                }
                
                if(modalType == 'department'){
                    importDepartment(formData?.file[0]?.originFileObj);
                }  
            }
        }else{
            setTabIndex(tabIndex + 1);
        }
    }
    const prevForm = () => {
        setTabIndex(tabIndex - 1);
    }
    const closeModal = () => {
        setFormData({ 'file': null });
        cancelBtnFunction();
    }
    const importAgain = () => {
        setFormData({ 'file': null });
        setTabIndex(2);
    }
    const openErrorModal = () => {
        setListModalState({...listModalState, 'isVisible': true, 'key': listModalState?.key +1});
    }
    const closeErrorListModal = () =>{
        setListModalState({...listModalState, 'isVisible': false});
    }
    useEffect(() =>{
            if(inventoryImportResponse.status === "SUCCESS"){
                setTabIndex(3);
                setInventoryImportLoader(false);
                setResultMessage({
                    "successCount": inventoryImportResponse?.data?.data2.length,
                    "errorCount" : inventoryImportResponse?.data?.data?.length,
                    "errorList": inventoryImportResponse.data?.data
                });
                resetImportInventory();
            }else if(inventoryImportResponse.status === "FAIL"){
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': inventoryImportResponse?.data?.message ? inventoryImportResponse?.data?.message : t('INVENTORY_EXPORT_DRAGGER_UPLOAD_DESCRIPTION'),
                    'statusType': 'error',
                    'position': 'bottom-right'
                });
                setInventoryImportLoader(false);
                resetImportInventory();
            }
    },[inventoryImportResponse])

    useEffect(() =>{
        if(personImportResponse.status === "SUCCESS"){
            setTabIndex(3);
            setInventoryImportLoader(false);
            setResultMessage({
                "successCount": personImportResponse?.data?.data?.successIds?.length,
                "errorCount" : personImportResponse?.data?.data?.errorIds?.length,
                "errorList": personImportResponse.data?.data
            });
            resetImportPerson();
        }else if(personImportResponse.status === "FAIL"){
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': personImportResponse?.data?.message ? personImportResponse?.data?.message : t('INVENTORY_EXPORT_DRAGGER_UPLOAD_DESCRIPTION'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            setInventoryImportLoader(false);
            resetImportPerson();
        }
    },[personImportResponse])

    useEffect(() =>{
        if(departmentImportResponse.status === "SUCCESS"){
            setTabIndex(3);
            setInventoryImportLoader(false);
            setResultMessage({
                "successCount": departmentImportResponse?.data?.data2.length,
                "errorCount" : departmentImportResponse?.data?.data?.length,
                "errorList": departmentImportResponse.data?.data
            });
            resetImportDepartment();
        }else if(departmentImportResponse.status === "FAIL"){
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': departmentImportResponse?.data?.message ? departmentImportResponse?.data?.message : t('INVENTORY_EXPORT_DRAGGER_UPLOAD_DESCRIPTION'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            setInventoryImportLoader(false);
            resetImportDepartment();
        }
    },[departmentImportResponse])
    
    return (
        <>
        <Modal
            key={modalState?.key}
            visible={modalState?.isVisible}
            className="form-modal"
            onCancel={() => closeModal()}
            maskClosable={false}
            width={520}
            closable={false}
            centered={true}
            footer={[]}>
            <div className="position-relative inventory-import-modal">
                {inventoryImportLoader ? <Spin className="w-100 h-100 p-absolute" size="medium" tip={`${t('LOADING')}`} /> : ''}
                <div className="form-modal-header import-modal-header">
                    <div className="form-modal-header-title import-modal-header">
                        {modalType === 'inventory' && t('IMPORT_INVENTORY')}
                        {modalType === 'person' && t('IMPORT_PERSON')}
                        {modalType === 'department' && t('IMPORT_DEPARTMENT')}
                    </div>
                    <div className="form-modal-header-exit import-modal-close"><Button onClick={closeModal} className="transparent-btn" icon={<UilTimes style={{color:'#595959'}} />} ></Button></div>
                </div>

                <div className="form-modal-form step-primary-tail ">
                    {tabIndex === 1 &&
                        <div className="d-flex justify-center text-center py-12">
                            {selectedLanguage === 'tr-TR' 
                            ? 
                                <a href={modalType === 'inventory' ? 'https://hrplan.blob.core.windows.net/hrplan/ornek-file4.xlsx' : 
                                modalType === 'person' ? 'https://hrplan.blob.core.windows.net/hrplan/ornek-personel.xlsx' :
                                modalType === 'department' ? 'https://hrplan.blob.core.windows.net/hrplan/ornek-departman.xlsx' : 
                                '#'} download>
                                <Image src={process.env.REACT_APP_URL + 'images/excel.png'} preview={false} />
                                <div className="import-color-grey f-16 pt-2 fw-600 import-download-section">
                                    <div> {t('SAMPLE_FILE')} </div>
                                    <Button className="ant-btn white-btn import-download-button mt-2">
                                        {t('DOWNLOAD_FILE')}
                                    </Button>
                                </div>
                                </a>
                            :
                                <a href={modalType === 'inventory' ? 'https://hrplan.blob.core.windows.net/hrplan/example-inventory.xlsx' : 
                                modalType === 'person' ? 'https://hrplan.blob.core.windows.net/hrplan/example-employee.xlsx' :
                                modalType === 'department' ? 'https://hrplan.blob.core.windows.net/hrplan/example-department.xlsx' : 
                                '#'} download>
                                <Image src={process.env.REACT_APP_URL + 'images/excel.png'} preview={false} />
                                <div className="import-color-grey f-16 pt-2 fw-600 import-download-section">
                                    <div> {t('SAMPLE_FILE')} </div>
                                    <Button className="ant-btn white-btn import-download-button mt-2">
                                        {t('DOWNLOAD_FILE')}
                                    </Button>
                                </div>
                                </a>
                            }

                        </div>
                    }
                    {tabIndex === 2 &&
                        <Form layout="vertical">
                            <Form.Item name="file" >
                                <Dragger {...props} className="my-2 import-modal-upload-section " fileList={formData?.file} >
                                    <p className="ant-upload-text">{t('INVENTORY_EXPORT_DRAGGER_UPLOAD_TITLE')}</p>
                                    <p className="ant-upload-hint">{t('INVENTORY_EXPORT_DRAGGER_UPLOAD_DESCRIPTION')}</p>
                                    <p className="ant-upload-drag-icon import-modal-drag-icon">
                                        <Button className="white-btn flex-align-center svg-class f-14 mr-2 import-modal-button" 
                                        icon={<UilExport style={{ width: 18, height: 18, marginRight:6 }} />}>{t('UPLOAD_FILE')}</Button>
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Form>
                    }
                    {tabIndex === 3 &&
                        //Success
                        <div className="d-flex justify-center pb-1">
                          <div className="successful-wrapper">
                            <div className='img-wrapper'>
                              <img src={process.env.REACT_APP_URL + "images/successful.gif"} />
                            </div>
                            <div className="successful-header-text pb-1">
                              {t('SUCCESSFUL')}
                            </div>
                            <div className="success-data-message">
                                <UilCheckCircle /><span>{t('INVENTORY_IMPORT_SUCCESS_COUNT', { count: resultMessage?.successCount })}</span>
                            </div>
                            {resultMessage?.errorCount > 0 &&
                                <div className="error-data-message">
                                    <UilTimesCircle /><span>{t('INVENTORY_IMPORT_ERROR_COUNT', { count: resultMessage?.errorCount })}</span><Button onClick={openErrorModal} className="transparent-btn pad-0 ml-2" icon={<UilClipboardNotes />}></Button>
                                </div>
                            }
                           </div> 
                        </div>
                    }
                    <Steps progressDot={true} className="left-step horizontal-step px-4 pt-4" direction="horizontal" size="middle" current={tabIndex - 1}>
                        <Step className="inventory-download-template" title={t('EXAMPLE_OF_EXCEL')} description="" />
                        <Step title={t('SETTINGS_IMPORT')} />
                        <Step title={t('RESULT')} description="" />
                    </Steps>
                    {tabIndex !== 3 ?
                        <div className="fixed-modal-buttons">
                            <Button disabled={inventoryImportLoader} className="ant-btn white-btn mr-3 w-25 h-20" onClick= {closeModal}>
                                {t('PARTNER_CLOSE')}
                            </Button>
                            <div>
                                {tabIndex > 1 &&
                                    <Button disabled={inventoryImportLoader} onClick={prevForm} className="ant-btn primary-btn h-20 mr-2">
                                        {t('INVENTORY_BACK')}
                                    </Button>
                                }
                                <Button disabled={tabIndex === 2 && !formData?.file} onClick={nextForm} className="ant-btn primary-btn h-20">
                                    {tabIndex > 1 ? t('UPLOAD') : t('INVENTORY_NEXT')}
                                </Button>
                            </div>
                        </div>
                     : 
                    <div className="fixed-modal-buttons">
                        <Button onClick={() => closeModal()} className="w-auto h-40 f-14 font-w-700 white-btn mr-2">{t('INVENTORY_RETURN_LIST')}</Button>
                        <Button onClick={() => importAgain()} className="w-auto h-40 f-14 font-w-700 primary-btn">{t('IMPORT_AGAIN')}</Button>
                     </div>
                     }
                </div>
            </div>
        </Modal>
        <Modal
          key={listModalState?.key}
          visible={listModalState?.isVisible}
          className="form-modal"
          onCancel={() => closeErrorListModal()}
          maskClosable={false}
          width={520}
          closable={true}
          centered={true}
          footer={[]}
          >
                <div className="position-relative overflow-y-scroll inventory-error-list-modal">
                <List
                header={<span className="f-16 black fw-600">{t('ERROR_LIST')}</span>}
                bordered
                dataSource={resultMessage?.errorList}
                renderItem={(item,index) => (
                    <List.Item key={index}>
                    <Typography.Text mark>{item?.inventoryName}</Typography.Text> - {item?.message}
                    </List.Item>
                )}
                />
                </div>
        </Modal>
        </>
        
    );
}
const mapStateToProps = (state) => {
    return {
        inventoryImportResponse: state.inventory.inventoryImportResponse,
        personImportResponse: state.person.personImportResponse,
        departmentImportResponse: state.departmentReducer.departmentImportResponse,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        importInventory: (data) => { dispatch(importInventory(data)) },
        resetImportInventory: () => { dispatch(resetImportInventory())},
        importPerson: (data) => { dispatch(importPerson(data)) },
        resetImportPerson: () => { dispatch(resetImportPerson())},
        importDepartment: (data) => { dispatch(importDepartment(data)) },
        resetImportDepartment: () => { dispatch(resetImportDepartment())}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InventoryImportModal);
