import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, ConfigProvider } from 'antd';

import {
    getCountry, getProvinces, getDistricts, getNeighborhoods
} from '../../../redux/actions/address.actions.js';

import DynamicSelectbox from "../../dynamicSelectbox/index";
import '../../../styles/person.scss';

function CreateDepartmentAddress(props) {
    const { t } = useTranslation();
    const [departmentAddAddressState, setDepartmentAddAddressState] = useState({ "Address": {} });
    const [form] = Form.useForm()
    const { setCreateObject, getCountry, getProvinces, getDistricts, getNeighborhoods, countries, provinces, districts, neighborhoods } = props;
    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [distirctList, setDistirctList] = useState([]);
    const [neighborhoodList, setNeighborhoodList] = useState([]);

    useEffect(() => {
        setCountryList(countries);
    }, [countries]);
    useEffect(() => {
        setProvinceList(provinces);
    }, [provinces]);
    useEffect(() => {
        setDistirctList(districts);
    }, [districts]);
    useEffect(() => {
        setNeighborhoodList(neighborhoods);
    }, [neighborhoods]);

    useEffect(() => {
        setDepartmentAddAddressState({ "Address": { "CountryId": "224" } })
        form.setFieldsValue({
            "CountryId": { "id": "224", "displayValue": "Türkiye" },
        });
    }, [form])

    const handleValuesChange = (changedObj, all) => {
        const changedKey = Object.keys(changedObj)[0];
        if (changedKey === 'CountryId') {
            if (JSON.stringify(changedObj.CountryId) !== null) {
                // ülke değişti
                setProvinceList([])//il listesini sıfırla
                setDistirctList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["ProvinceId"] = '';//ili sıfırla
                fieldsObj["DistrictId"] = '';//ilçeyi sıfırla
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj)
                const addressObj = { ...departmentAddAddressState.Address, "CountryId": changedObj['CountryId'].id, "ProvinceId": "", "DistrictId": "", "NeighborhoodId": "" }
                setDepartmentAddAddressState({ ...departmentAddAddressState, "Address": addressObj })
            }
            setProvinceList([])//il listesini sıfırla
            setDistirctList([])//ilçe listesini sıfırla
        } else if (changedKey === 'ProvinceId') {
            if (JSON.stringify(changedObj.ProvinceId) !== null) {
                // il değişti
                setDistirctList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["DistrictId"] = '';//ilçeyi sıfırla
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj);
                const addressObj = { ...departmentAddAddressState.Address, "ProvinceId": changedObj['ProvinceId'].id, "DistrictId": "", "NeighborhoodId": "" }
                setDepartmentAddAddressState({ ...departmentAddAddressState, "Address": addressObj });
            }
            setDistirctList([]); //ilçe listesini sıfırla
            setNeighborhoodList([]); //mahalle listesini sıfırla

        } else if (changedKey === 'province') {
            if (JSON.stringify(changedObj.province) !== null) {
                // il değişti
                setDistirctList([])//ilçe listesini sıfırla
                setNeighborhoodList([])//mahalle listesini sıfırla
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["DistrictId"] = '';//ilçeyi sıfırla
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj);
                const addressObj = { ...departmentAddAddressState.Address, "Province": changedObj['province'] }
                setDepartmentAddAddressState({ ...departmentAddAddressState, "Address": addressObj });
            }
            setDistirctList([]); //ilçe listesini sıfırla
            setNeighborhoodList([]); //mahalle listesini sıfırla

        } else if (changedKey === 'DistrictId') {
            if (JSON.stringify(changedObj.DistrictId) !== null) {
                // ilçe değişti1
                const fields = form.getFieldsValue();
                const fieldsObj = { ...fields };
                fieldsObj["NeighborhoodId"] = '';//mahlleyi sıfırla
                form.setFieldsValue(fieldsObj);
                const addressObj = { ...departmentAddAddressState.Address, "DistrictId": changedObj['DistrictId'].id, "NeighborhoodId": "" }
                setDepartmentAddAddressState({ ...departmentAddAddressState, "Address": addressObj });
            }
            setNeighborhoodList([]); //mahalle listesini sıfırla
        } else if (changedKey === 'NeighborhoodId') {
            if (JSON.stringify(changedObj.NeighborhoodId) !== null) {
                const addressObj = { ...departmentAddAddressState.Address, "NeighborhoodId": changedObj['NeighborhoodId'].id }
                setDepartmentAddAddressState({ ...departmentAddAddressState, "Address": addressObj })
            }
        } else if (changedKey === 'AddressLine') {
            const addressObj = { ...departmentAddAddressState.Address, ...changedObj }
            setDepartmentAddAddressState({ ...departmentAddAddressState, "Address": addressObj });
        } else {
            setDepartmentAddAddressState({ ...departmentAddAddressState, ...changedObj });
        }
    }
    useEffect(() => {
        setCreateObject(departmentAddAddressState)
    }, [departmentAddAddressState]);
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={true}
            >
                <Form.Item name="CountryId" className="custom-select" label={t('ORGANIZATION_COUNTRY')}>
                    <DynamicSelectbox optionList={countryList} placeholder={t('COUNTRY_PLACEHOLDER')} onFocus={() => getCountry()} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="province" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                    <Input />
                </Form.Item>
                {/* 
                <Form.Item name="ProvinceId" className="custom-select" label={t('ORGANIZATION_PROVINCE')}>
                    <DynamicSelectbox optionList={provinceList} placeholder={t('PROVINCE_PLACEHOLDER')} onFocus={() => getProvinces(departmentAddAddressState.Address.CountryId !== '' ? departmentAddAddressState.Address.CountryId : null)} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="DistrictId" className="custom-select" label={t('ORGANIZATION_DISTRICT')}>
                    <DynamicSelectbox optionList={distirctList} disabled={!departmentAddAddressState.Address.ProvinceId} placeholder={t('DISTRICT_PLACEHOLDER')} onFocus={() => getDistricts(departmentAddAddressState.Address.ProvinceId !== '' ? departmentAddAddressState.Address.ProvinceId : null)} ></DynamicSelectbox>
                </Form.Item>
                <Form.Item name="NeighborhoodId" className="custom-select" label={t('ORGANIZATION_REGION')}>
                    <DynamicSelectbox optionList={neighborhoodList} disabled={!departmentAddAddressState.Address.DistrictId} onFocus={() => getNeighborhoods(departmentAddAddressState.Address.DistrictId !== '' ? departmentAddAddressState.Address.DistrictId : null)} placeholder={t('REGION_PLACEHOLDER')}></DynamicSelectbox>
                </Form.Item>
                */}

                <Form.Item name="AddressLine" label={t('ORGANIZATION_ADDRESS')}>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        countries: state.addressReducer.countries,
        provinces: state.addressReducer.provinces,
        districts: state.addressReducer.districts,
        neighborhoods: state.addressReducer.neighborhoods,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (countryId) => { dispatch(getProvinces(countryId)) },
        getDistricts: (provinceId) => { dispatch(getDistricts(provinceId)) },
        getNeighborhoods: (districtId) => { dispatch(getNeighborhoods(districtId)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateDepartmentAddress);