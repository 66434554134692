import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { authUrl } from '../../utils/config'; 

function SignUp() {

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const lang = query.get('lang');

        if (lang === 'en') {
            window.location.replace(authUrl + '/account/RegisterOrganizationEn');
        } else {
            window.location.replace(authUrl + '/account/RegisterOrganization');
        }
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spin />
        </div>
    ); 
}

export default SignUp;
