import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getCurrencyWithId, createExpenseAction, resetCreateExpenseResponse,
    resetCurrencyWithIdResponse, getMultipleCurrency, resetGetMultipleCurrency
} from '../../../redux/actions/expense.action';
import { getDataset } from '../../../redux/actions/dataset-action';

import { Input, Button, Form, ConfigProvider, Row, Col, DatePicker, Upload, List, Checkbox, Spin, Tooltip, Popover } from 'antd';
import { UilFile, UilTrashAlt, UilPlusCircle } from '@iconscout/react-unicons';
import { UploadOutlined } from '@ant-design/icons';

import moment from 'moment';

import MaskedInput from 'react-text-mask';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicSelect from '../../dynamicSelectbox/index';

import { numberMaskWithSeperator } from '../../../utils/commonFormFunctions';
import { dateFormat, apiUrl, numberFormatTR, localeLang } from '../../../utils/config';

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/expense.scss';

function ExpenseEdit(props) {
    const { auth, getCurrencyWithId, currencyWithId, getDataset, currencyTypes, expenseTypes, expenseData,
        openAdvanceListState } = props;
    const { creditCardList, setCreditCardList, creditCardCurrencyList, setCreditCardCurrencyList, creditCardTotalAmount,
        setCreditCardTotalAmount, returnAdvanceList, setReturnAdvanceList, returnAdvanceAddList, setReturnAdvanceAddList,
        returnAdvanceTotal, setReturnAdvanceTotal, returnCurrencyList, setReturnCurrencyList,
        expenseList, setExpenseList, expenseTotal, setExpenseTotal, expenseCurrencyList,
        setExpenseCurrencyList, currencyConvertList, setCurrencyConvertList, currencyConvertTotal, setCurrencyConvertTotal,
        currencyConvertSelectList1, setCurrencyConvertSelectList1, currencyConvertSelectList2, setCurrencyConvertSelectList2, isManager,
        expenseState, setExpenseState, selectRowKey, setSelectRowKey, expenseGroupList, setExpenseGroupList, differenceStatus, defaultExpenseFields,
        defaultConvertCardFields, defaultCreditCardFields, getMultipleCurrency, resetGetMultipleCurrency, multipleCurrencyWithId } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [selectAdvanceDate, setSelectAdvanceDate] = useState(null);
    const [fileLoadState, setFileLoadState] = useState({ 'page': null, 'status': false });
    const access_token = auth.user?.access_token;

    useEffect(() => {
        getDataset(23);
        getDataset(28);
    }, []);

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        setExpenseState({ ...expenseState, ...changedObj });
    }
    // =============== CREDIT CART LIST  && EXPENSE CART LIST  && CURRENCY LIST START ====================== //
    useEffect(() => {
        var amount = 0;
        creditCardList.length > 0 &&
            creditCardList.map(data => {
                if (data.tlAmount) {
                    amount += parseFloat(data.tlAmount);
                }
            })
        setCreditCardTotalAmount(amount);


    }, [creditCardList])
    useEffect(() => {
        var amount = 0;
        expenseList.length > 0 &&
            expenseList.map(data => {
                if (data.tlAmount) {
                    amount += parseFloat(data.tlAmount);
                }
            })
        setExpenseTotal(amount);

    }, [expenseList])
    useEffect(() => {
        var amount = 0;
        currencyConvertList.length > 0 &&
            currencyConvertList.map(data => {
                if (data.tlAmount) {
                    amount += parseFloat(data.tlAmount);
                }
            })
        setCurrencyConvertTotal(amount);


    }, [currencyConvertList])
    const creditCardCurrencyControl = (currencyControlData) => {

    }
    const expenseCardCurrencyControl = (currencyControlData) => {

    }
    const handleDynamicListInputChange = (e, index, key, inputType, page) => {
        var list = [];
        var listx = {};
        if (page === "creditCard") {
            list = [...creditCardList];
        } else if (page === "expense") {
            list = [...expenseList]
        } else if (page === "currencyConvert") {
            list = [...currencyConvertList]
        } else if (page === "default") {
            listx = { ...expenseState };
        }
        switch (inputType) {
            case 'datePicker':
                list[index][key] = e;
                if (page === "creditCard") {
                    creditCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': list[index].currency?.id, 'expenseDate': e }, index)
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    expenseCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': list[index].currency?.id, 'expenseDate': e }, index)
                    return setExpenseList(list);
                }
                else if (page === "currencyConvert") {
                    if (e && e.trim()) {
                        if (list[index].cashedCurrency?.id && list[index].convertedCurrency?.id) {
                            currencyConvertAmountDataControl({
                                'cashedAmount': list[index].cashedAmount,
                                'cashedCurrency': list[index].cashedCurrency?.id,
                                'convertedAmount': list[index].convertedAmount,
                                'convertedCurrency': list[index].convertedCurrency?.id,
                                'expenseDate': e
                            }, index, 'multipleCurrency');
                        } else if (list[index].cashedCurrency?.id && !list[index].convertedCurrency?.id) {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': e
                                }
                                , index, 'cashedCurrency');
                        } else if (!list[index].cashedCurrency?.id && list[index].convertedCurrency?.id) {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': e
                                }, index, 'convertedCurrency');
                        }
                    }
                }
            case 'select':
                if (key === "currency") {
                    if (page === "creditCard") {
                        creditCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': e?.id, 'expenseDate': list[index].expenseDate }, index)
                    } else if (page === "expense") {
                        expenseCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': e?.id, 'expenseDate': list[index].expenseDate }, index)
                    }
                } else {
                    if (page === "currencyConvert") {
                        if (key === "cashedCurrency") {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': e?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': list[index].expenseDate
                                }
                                , index, 'cashedCurrency')
                        } else if (key === "convertedCurrency") {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': e?.id,
                                    'expenseDate': list[index].expenseDate
                                }
                                , index, 'convertedCurrency')
                        }
                    }
                }
                list[index][key] = e;
                if (page === "creditCard") {
                    creditCardCurrencyControl(list)
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    expenseCardCurrencyControl(list)
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    return setCurrencyConvertList(list);
                }
            case 'priceInput':
                const val = e.target.value;
                if (page === "default") {
                    var valX = val.replaceAll('.', '').replaceAll(',', '.');
                    listx.missingAmount = parseFloat(parseFloat(valX).toFixed(2));
                    listx.missingAmountWrite = val;
                    return setExpenseState(listx);
                } else {
                    const replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                    let parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                    parseVal = parseVal !== "NaN" ? parseVal : 0;
                    list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                    list[index][key + "Xwrite"] = val;
                    if (page === "creditCard") {
                        creditCardAmountDataControl({ 'spendingAmount': parseVal, 'currency': list[index].currency?.id, 'expenseDate': list[index].expenseDate }, index, 'amount')
                        return setCreditCardList(list);
                    } else if (page === "expense") {
                        expenseCardAmountDataControl({ 'spendingAmount': parseVal, 'currency': list[index].currency?.id, 'expenseDate': list[index].expenseDate }, index, 'amount')
                        return setExpenseList(list);
                    } else if (page === "currencyConvert") {
                        if (key === "cashedAmount") {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': parseVal,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': list[index].expenseDate
                                }
                                , index, 'cashedCurrency', 'amount')
                        } else if (key === "convertedAmount") {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': parseVal,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': list[index].expenseDate
                                }
                                , index, 'convertedCurrency', 'amount')
                        }
                        return setCurrencyConvertList(list);
                    }
                }
            case 'text':
                list[index][key] = e.target.value;
                if (page === "creditCard") {
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    return setCurrencyConvertList(list);
                }
            case 'img':
                list[index][key] = e;
                if (page === "creditCard") {
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    return setCurrencyConvertList(list);
                }
            default:
                return null;
        }
    };
    const creditCardAmountDataControl = (dataObj, index, type) => {
        if ((dataObj.spendingAmount !== "" && dataObj.spendingAmount !== "0") && (dataObj.currency !== "" && dataObj.currency) && dataObj.expenseDate) {
            if (type === "amount") {
                const list = [...creditCardList];
                const lastCurrency = list[index].exchangeRate;
                const total = lastCurrency * dataObj.spendingAmount;
                list[index].tlAmount = 0;
                list[index].tlAmount = isNaN(total) ? 0 : total;
                setCreditCardList(list)
            } else {
                const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.currency, date1, date2)
            }
            setSelectRowKey({ 'id': index, 'tableName': 'creditcardTable' })
        } else if (dataObj.spendingAmount === "0" || dataObj.spendingAmount === "") {
            const list = [...creditCardList];
            list[index].tlAmount = 0;
            setCreditCardList(list)
        }
    }
    const expenseCardAmountDataControl = (dataObj, index, type) => {
        if ((dataObj.spendingAmount !== "" && dataObj.spendingAmount !== "0") && (dataObj.currency !== "" && dataObj.currency)) {
            if (selectAdvanceDate && type !== "amount") {
                const date1 = moment(selectAdvanceDate).format('YYYYMM');
                const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.currency, date1, date2)
            } else if (dataObj.expenseDate && type !== "amount") {
                const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.currency, date1, date2)

            } else if (type === "amount") {
                const list = [...expenseList];
                const lastCurrency = list[index].exchangeRate;
                const total = lastCurrency * dataObj.spendingAmount;
                list[index].tlAmount = 0;
                list[index].tlAmount = isNaN(total) ? 0 : total;
                setExpenseList(list)
            }
            setSelectRowKey({ 'id': index, 'tableName': 'expense' })
        } else if (dataObj.spendingAmount === "0" || dataObj.spendingAmount === "") {
            const list = [...expenseList];
            list[index].tlAmount = 0;
            setExpenseList(list)
        }
    }
    const currencyConvertAmountDataControl = (dataObj, index, whichSelect, type) => {
        if (whichSelect === "multipleCurrency" && dataObj.cashedCurrency && dataObj.convertedCurrency) {
            const date1 = moment(dataObj.expenseDate).format('YYYYMM');
            const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
            getMultipleCurrency(dataObj.cashedCurrency, dataObj.convertedCurrency, date1, date2);
        } else {
            if ((dataObj.cashedAmount !== "" && dataObj.cashedAmount !== "0") && (dataObj.cashedCurrency !== "" && dataObj.cashedCurrency) && whichSelect === "cashedCurrency") {
                if (selectAdvanceDate && type !== "amount") {
                    const date1 = moment(selectAdvanceDate).format('YYYYMM');
                    const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.cashedCurrency, date1, date2)
                } else if (dataObj.expenseDate && type !== "amount" && dataObj.cashedCurrency) {
                    const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                    const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.cashedCurrency, date1, date2)
                }
                if (type === "amount") {
                    const list = [...currencyConvertList];
                    const cashedAmount = dataObj.cashedAmount * list[index].cashedExchange;
                    const convertAmount = dataObj.convertedAmount * list[index].convertExchange;
                    const total = parseFloat(cashedAmount - convertAmount).toFixed(2);
                    list[index].tlAmount = 0;
                    list[index].tlAmount = isNaN(total) ? 0 : total;
                    setCurrencyConvertList(list)
                }
                setSelectRowKey({ 'id': index, 'tableName': 'cashedCurrency' });
            } else if ((dataObj.convertedAmount !== "" && dataObj.convertedAmount !== "0") && (dataObj.convertedCurrency !== "" && dataObj.convertedCurrency) && whichSelect === "convertedCurrency") {
                if (selectAdvanceDate && type !== "amount") {
                    const date1 = moment(selectAdvanceDate).format('YYYYMM');
                    const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.convertedCurrency, date1, date2)
                } else if (dataObj.expenseDate && type !== "amount" && dataObj.convertedCurrency) {
                    const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                    const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.convertedCurrency, date1, date2)
                }
                if (type === "amount") {
                    const list = [...currencyConvertList];
                    const cashedAmount = (isNaN(dataObj.cashedAmount) ? 0 : dataObj.cashedAmount) * list[index].cashedExchange;
                    const convertAmount = (isNaN(dataObj.convertedAmount) ? 0 : dataObj.convertedAmount) * list[index].convertExchange;
                    const total = parseFloat(cashedAmount - convertAmount).toFixed(2);
                    list[index].tlAmount = 0;
                    list[index].tlAmount = isNaN(total) ? 0 : total;
                    setCurrencyConvertList(list)
                }
                setSelectRowKey({ 'id': index, 'tableName': 'convertedCurrency' })
            }
        }
    }
    const handleDynamicListRemoveItem = (index, page) => {
        if (page === "creditCard") {
            const list = [...creditCardList];
            list.splice(index, 1);
            setCreditCardList(list);
            creditCardCurrencyControl(list)
        } else if (page === "expense") {
            const list = [...expenseList];
            list.splice(index, 1);
            setExpenseList(list);
        } else if (page === "currencyConvert") {
            const list = [...currencyConvertList];
            list.splice(index, 1);
            setCurrencyConvertList(list);
        }
    };
    const handleDynamicAddRow = (page) => {
        var requiredFields = {};
        let hasError = false
        let fields = []
        var list = [];
        if (page === "creditCard") {
            list = [...creditCardList];
            requiredFields = {
                'expenseDate': false,
                'expenditureGroup': false,
                'spendingAmount': false,
                'currency': false,
                'tlAmount': false,
            }
        } else if (page === "expense") {
            list = [...expenseList];
            requiredFields = {
                "expenseType": false,
                'expenditureGroup': false,
                'expenseDate': false,
                'spendingAmount': false,
                'currency': false,
                'tlAmount': false,
            }
        } else if (page === "currencyConvert") {
            list = [...currencyConvertList];
            requiredFields = {
                "expenseType": false,
                'expenseDate': false,
                'cashedAmount': false,
                'cashedCurrency': false,
                'convertedAmount': false,
                'convertedCurrency': false,
                'tlAmount': false,
            }
        }
        list.map(data => {
            if (!data.expenseDate) {
                requiredFields.expenseDate = true;
            }
            if ((!data.expenditureGroup || data.expenditureGroup?.id === "") && (page === "expense" || page === "creditCard")) {
                requiredFields.expenditureGroup = true;
            }
            if ((!data.spendingAmount || data.spendingAmount === "0") && page !== "currencyConvert") {
                requiredFields.spendingAmount = true;
            }
            if ((!data.currency || !data.currency?.id === "") && page !== "currencyConvert") {
                requiredFields.currency = true;
            }
            if (!data.tlAmount || !data.tlAmount === "0") {
                requiredFields.tlAmount = true;
            }
        });
        if (requiredFields.expenseDate) {
            hasError = true
            fields.push(t('PARTNER_DATE'));
        }
        if (requiredFields.expenditureGroup) {
            hasError = true;
            fields.push(t('EXPENDITURE_GROUP'));
        }
        if (requiredFields.spendingAmount) {
            hasError = true;
            fields.push(t('PARTNER_AMOUNT2'));
        }
        if (requiredFields.currency) {
            hasError = true;
            fields.push(t('PARTNER_CURRENCY'));
        }
        if (requiredFields.tlAmount) {
            hasError = true;
            fields.push(t('TL_AMOUNT'));
        }
        if (!hasError || (page === "expense" && expenseList.length === 0)) {
            if (page === "creditCard") {
                creditCardCurrencyControl(list)
                list.push(defaultCreditCardFields);
                requiredFields = {
                    'expenseDate': false,
                    'expenditureGroup': false,
                    'spendingAmount': false,
                    'currency': false,
                    'tlAmount': false
                }
                setCreditCardList(list);
            } else if (page === "expense") {
                list.push(defaultExpenseFields);
                requiredFields = {
                    "expenseType": false,
                    'expenditureGroup': false,
                    'expenseDate': false,
                    'spendingAmount': false,
                    'currency': false,
                    'tlAmount': false,
                }
                setExpenseList(list);
            } else if (page === "currencyConvert") {
                list.push(defaultConvertCardFields);
                requiredFields = {
                    "expenseType": false,
                    'expenseDate': false,
                    'cashedAmount': false,
                    'cashedCurrency': false,
                    'convertedAmount': false,
                    'convertedCurrency': false,
                    'tlAmount': false,
                }
                setCurrencyConvertList(list);
            }
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };
    // ===============  CREDIT CART LIST  && EXPENSE CART LIST  && CURRENCY LIST END ====================== //


    // =============== RETURN ADVANCE START ====================== //
    useEffect(() => {
        var advanceCount = 0;
        var returnlist = returnAdvanceList.concat(returnAdvanceAddList);
        if (returnAdvanceList.length > 0) {
            returnAdvanceList.map(data => {
                var total = data.received * data.exchangeRate;
                total -= data.return * data.exchangeRate;
                advanceCount += total;
            })
        }
        if (returnAdvanceAddList.length > 0) {
            returnAdvanceAddList.map(data => {
                if (data.advanceInformation?.id && data.exchangeRate > 0) {
                    var returnCount = data.return * data.exchangeRate;
                    advanceCount -= returnCount;
                }
            })
        }
        if (returnAdvanceList.length > 0 || returnAdvanceAddList.length > 0) {
            setReturnAdvanceTotal(advanceCount)
        } else if (returnlist.length === 0) {
            advanceCount = 0;
            setReturnAdvanceTotal(advanceCount)
        }
    }, [returnAdvanceList, returnAdvanceAddList])

    const handleReturnAdvanceListInputChange = (e, index, key, inputType) => {
        const list = [...returnAdvanceList];
        switch (inputType) {
            case 'priceInput':
                const val = e.target.value;
                var replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                var parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                //.replaceAll(RegExp(/\x20?\d+(?:[.,]\d{0,2})? +[$€£₺]/), '');
                var exchangeRate = parseFloat(list[index].exchangeRate);
                var returnAmount = parseFloat(replaceVal);
                var received = parseFloat(list[index].received);

                if (parseFloat(replaceVal) > 0 && parseFloat(list[index].received) > 0) {
                    list[index].advanceDebt = 0;
                    var calculator = (received * exchangeRate) - (returnAmount * exchangeRate);
                    list[index].advanceDebt = calculator;

                } else {
                    var calculator = received * exchangeRate;
                    list[index].advanceDebt = 0;
                    list[index].advanceDebt = calculator;
                }
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;;
                list[index][key + "Xwrite"] = val;
                return setReturnAdvanceList(list);
            default:
                return null;
        }
    };
    const handleReturnAdvanceAddListInputChange = (e, index, key, inputType) => {
        const list = [...returnAdvanceAddList];
        switch (inputType) {
            case 'select':
                list[index][key] = e.id ? { 'id': parseInt(e.id), 'displayValue': e.displayValue } : { 'id': null, 'displayValue': '' };
                if (key === "advanceInformation") {
                    returnAdvanceAmountDataControl({ 'received': list[index].received, 'return': list[index].return, 'advanceInformation': e?.id }, index, 'add')
                    returnListCurrencyControl(list);
                }
                returnListCurrencyControl(list);
                return setReturnAdvanceAddList(list);
            case 'priceInput':
                const val = e.target.value;
                var replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                var parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                list[index][key + "Xwrite"] = val;
                if (key === "received") {
                    returnAdvanceAmountDataControl({ 'received': replaceVal, 'return': list[index].return, 'advanceInformation': list[index].currency?.id }, index, 'add')
                } else {
                    returnAdvanceAmountDataControl({ 'return': replaceVal, 'received': list[index].received, 'advanceInformation': list[index].currency?.id }, index, 'add')
                }
                return setReturnAdvanceAddList(list);
            case 'text':
                list[index][key] = e.target.value;
                return setReturnAdvanceAddList(list);
            default:
                return null;
        }
    };
    const returnAdvanceAmountDataControl = (dataObj, index, data) => {
        if (dataObj.advanceInformation !== "" && dataObj.advanceInformation) {
            setSelectRowKey({ 'id': index, 'tableName': 'returnAddList' })
            if (selectAdvanceDate) {
                const date1 = moment(selectAdvanceDate).format('YYYYMM');
                const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.advanceInformation, date1, date2)
            }
            var list = [];
            list = [...returnAdvanceAddList];
            list[index].return = 0;
            setReturnAdvanceAddList(list);
        }
    }
    const handleReturnAddListRemoveItem = (index, page) => {
        if (page === "returnList") {
            const list = [...returnAdvanceAddList];
            list.splice(index, 1);
            setReturnAdvanceAddList(list);
            returnListCurrencyControl(list)
        } else if (page === "returnCheckList") {
            const list = [...returnAdvanceList];
            list.splice(index, 1);
            setReturnAdvanceList(list);
            returnListCurrencyControl(list)
        }
    };
    function handleAddNewReturnList() {
        const newRowList = [...returnAdvanceAddList];
        newRowList.push(
            {
                'advanceInformation': { 'id': null, 'displayValue': null },
                'received': 0,
                'return': null,
                'exchangeRate': 0,
                'advanceDebt': 0,
            }
        );
        const returnMergeObj = returnAdvanceList.concat(returnAdvanceAddList);
        returnListCurrencyControl(returnMergeObj);
        setReturnAdvanceAddList(newRowList);
    }
    const returnListCurrencyControl = (currencyControlDatax) => {
        const currencyControlData = returnAdvanceList.concat(currencyControlDatax);

        var defaultSelectList = currencyTypes.map(data => {
            return {
                'id': parseInt(data.id),
                'displayValue': data.displayValue,
                'disabled': false
            }
        })
        if (returnCurrencyList) {
            var selectData = currencyControlData.map(data => { return data.advanceInformation?.id });
            const findSelectData = defaultSelectList.filter(filterData => selectData.includes(filterData.id));
            const findSelectDataID = findSelectData.map(data => data.id);
            var newCurrencyData = returnCurrencyList.map(data => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                    'disabled': findSelectDataID.includes(data.id)
                }
            });
            setReturnCurrencyList(newCurrencyData)
        }
    }
    // =============== RETURN ADVANCE END ====================== //
    useEffect(() => {
        setExpenseGroupList(
            expenseTypes.map(data => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                }
            })
        );
    }, [expenseTypes])
    useEffect(() => {
        if (multipleCurrencyWithId.status === "SUCCESS") {
            var list = [...currencyConvertList];
            if (multipleCurrencyWithId.cashed && multipleCurrencyWithId.converted) {
                list[selectRowKey.id].cashedTotal = null;
                list[selectRowKey.id].convertTotal = null;
                list[selectRowKey.id].cashedExchange = multipleCurrencyWithId.cashed;
                const cashedTotal = list[selectRowKey.id].cashedAmount ? list[selectRowKey.id].cashedAmount * multipleCurrencyWithId.cashed : 0
                list[selectRowKey.id].cashedTotal = parseFloat(cashedTotal).toFixed(2);

                list[selectRowKey.id].convertExchange = multipleCurrencyWithId.converted;
                const convertTotal = list[selectRowKey.id].convertedAmount ? list[selectRowKey.id].convertedAmount * multipleCurrencyWithId.converted : 0
                list[selectRowKey.id].convertTotal = parseFloat(convertTotal).toFixed(2);
                list[selectRowKey.id].tlAmount = parseFloat(cashedTotal - convertTotal).toFixed(2);
                setCurrencyConvertList(list);
                resetGetMultipleCurrency();
            }
        }
    }, [multipleCurrencyWithId])
    useEffect(() => {
        if (currencyWithId.status === "SUCCESS") {
            if (expenseData.expenseMasterTypeId === 2 && selectRowKey.tableName === "creditcardTable") {
                const list = [...creditCardList];
                list[selectRowKey.id].tlAmount = null;
                const tlAmount = list[selectRowKey.id].spendingAmount ? list[selectRowKey.id].spendingAmount * currencyWithId.data : 0
                list[selectRowKey.id].tlAmount = parseFloat(tlAmount).toFixed(2);
                list[selectRowKey.id].exchangeRate = currencyWithId.data;
                setCreditCardList(list)
            } else if (expenseData.expenseMasterTypeId === 1 && selectRowKey.tableName === "returnAddList") {
                const list = [...returnAdvanceAddList];
                list[selectRowKey.id].exchangeRate = currencyWithId.data;
                setReturnAdvanceAddList(list)
            } else if (expenseData.expenseMasterTypeId === 1 && selectRowKey.tableName === "expense") {
                const list = [...expenseList];
                list[selectRowKey.id].tlAmount = null;
                const tlAmount = list[selectRowKey.id].spendingAmount ? list[selectRowKey.id].spendingAmount * currencyWithId.data : 0
                list[selectRowKey.id].tlAmount = parseFloat(tlAmount).toFixed(2);
                list[selectRowKey.id].exchangeRate = currencyWithId.data;
                setExpenseList(list)
            } else if (expenseData.expenseMasterTypeId === 1 && selectRowKey.tableName === "cashedCurrency") {
                const list = [...currencyConvertList];
                list[selectRowKey.id].cashedTotal = null;
                list[selectRowKey.id].cashedExchange = currencyWithId.data;
                const cashedTotal = list[selectRowKey.id].cashedAmount ? list[selectRowKey.id].cashedAmount * currencyWithId.data : 0
                list[selectRowKey.id].cashedTotal = parseFloat(cashedTotal).toFixed(2);
                if (list[selectRowKey.id].convertTotal) {
                    list[selectRowKey.id].tlAmount = parseFloat(cashedTotal - list[selectRowKey.id].convertTotal).toFixed(2);
                }
                setCurrencyConvertList(list)
            } else if (expenseData.expenseMasterTypeId === 1 && selectRowKey.tableName === "convertedCurrency") {
                const list = [...currencyConvertList];
                list[selectRowKey.id].convertTotal = null;
                list[selectRowKey.id].convertExchange = currencyWithId.data;
                const convertTotal = list[selectRowKey.id].convertedAmount ? list[selectRowKey.id].convertedAmount * currencyWithId.data : 0
                list[selectRowKey.id].convertTotal = parseFloat(convertTotal).toFixed(2);
                if (list[selectRowKey.id].cashedTotal) {
                    list[selectRowKey.id].tlAmount = parseFloat(list[selectRowKey.id].cashedTotal - convertTotal).toFixed(2);
                }
                setCurrencyConvertList(list)
            }
        }
    }, [currencyWithId])

    useEffect(() => {
        if (creditCardList.length === 1 || creditCardList.length === 0) {
            setCreditCardCurrencyList(
                currencyTypes.map(data => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
        if (expenseList.length === 0 || expenseList.length === 1) {
            setExpenseCurrencyList(
                currencyTypes.map(data => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
        if (currencyConvertList.length === 0 || currencyConvertList.length === 1) {
            setCurrencyConvertSelectList1(
                currencyTypes.map(data => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
            setCurrencyConvertSelectList2(
                currencyTypes.map(data => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
        if (returnAdvanceList.length === 0 || returnAdvanceAddList.length === 0) {
            setReturnCurrencyList(
                currencyTypes.map(data => {
                    return {
                        'id': parseInt(data.id),
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
    }, [currencyTypes])

    const uploadImgAction = (values, index, page) => {
        if (values.file.status === 'uploading') {
            setFileLoadState({ 'page': page, 'status': true });
        }
        if (values?.file?.response) {
            if (values?.file?.response?.status === "SUCCESS") {
                handleDynamicListInputChange(values?.file?.response?.data, index, 'receipt', 'img', page);
                DynamicToastMessage({
                    'key': 'upload_image_response_successx',
                    'title': t('SUCCESS'),
                    'description': `Dosyanın yüklenmesi tamamlandı`,
                    'statusType': 'success',
                    'position': 'bottom-right'
                });
                setFileLoadState({ 'page': null, 'status': false });
            } else {
                DynamicToastMessage({
                    'key': 'upload_image_response_error',
                    'title': t('ERROR'),
                    'description': values?.file?.response?.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                });
                setFileLoadState({ 'page': null, 'status': false });
            }
        }
    }
    useEffect(() => {
        if (expenseData.expenseMasterTypeId === 1) {
            var stateData = {};
            if (expenseData) {
                var differentAmount = 0;
                if (differenceStatus && expenseData.differentAmount > 0) {
                    differentAmount = parseFloat(expenseData.differentAmount) + parseFloat(expenseData.missingAmount);
                }
                stateData = {
                    ...expenseState,
                    'expenseType': expenseData.expenseMasterTypeId,
                    'descriptionType1': expenseData.description,
                    'speacialAreaType1': expenseData.additionalField,
                    'closeMissingAmount': expenseData.missingAmount > 0 ? true : false,
                    'missingAmountWrite': differenceStatus ? parseFloat(differentAmount).toLocaleString('tr-TR') : parseFloat(expenseData.missingAmount).toLocaleString('tr-TR'),
                    'missingAmount': differenceStatus ? differentAmount : expenseData.missingAmount,
                }
            } else {
                stateData = {
                    ...expenseState,
                    'expenseType': expenseData.expenseMasterTypeId,
                    'descriptionType1': expenseData.description,
                    'speacialAreaType1': expenseData.additionalField,
                    'closeMissingAmount': expenseData.missingAmount > 0 ? true : false,
                    'missingAmountWrite': parseFloat(expenseData.missingAmount).toLocaleString('tr-TR'),
                    'missingAmount': expenseData.missingAmount,
                }
            }
            const currentData = openAdvanceListState?.length > 0 && openAdvanceListState.map(data => {
                return data.confirmedDate;
            })
            if (currentData.length > 0) {
                var confirmDate = currentData.reduce(function (r, a, i) {
                    return !i || a < r ? a : r;
                }, undefined);
                setSelectAdvanceDate(confirmDate)
            }
            setExpenseState(stateData);
            form.setFieldsValue(stateData);
            if (expenseData.returnAmountList.length > 0) {
                const returnData = expenseData.returnAmountList.map(data => {
                    return {
                        'advanceInformation': { 'id': data.currencyId, 'displayValue': data.currencyName },
                        'received': data.remainingAdvance,
                        'return': data.returnAmount,
                        'returnXwrite': parseFloat(data.returnAmount).toLocaleString('tr-TR'),
                        'exchangeRate': data.exchangeRate,
                        'advanceDebt': data.totalAdvance
                    }
                })
                setReturnAdvanceList(returnData);
            }
            const expenseArr = expenseData?.expenseDetails?.length > 0 ? expenseData?.expenseDetails.map(data => {
                return {
                    'expenditureGroup': { 'id': data.expenseType?.id, 'displayValue': data.expenseType?.displayValue },
                    'currency': { 'id': data.currency?.id, 'displayValue': data.currency?.displayValue },
                    'expenseDate': data.expenseDate,
                    'spendingAmount': data.expenseAmount,
                    'spendingAmountXwrite': parseFloat(data.expenseAmount).toLocaleString('tr-TR'),
                    'invoiceNo': data.invoiceNo,
                    'description': data.description,
                    'tlAmount': data.amountTL,
                    'exchangeRate': data.exchangeRate,
                    'receiptInfo': data.invoiceTitle,
                    'receipt': data.invoiceImage
                }
            }) : [defaultExpenseFields]
            setExpenseList(expenseArr);
            const exchangeArr = expenseData?.exchanceExpense.length > 0 ? expenseData.exchanceExpense.map(data => {
                return {
                    'expenseDate': data.exchangeDate,
                    'cashedAmount': data.exchangeAmount,
                    'cashedAmountXwrite': parseFloat(data.exchangeAmount).toLocaleString('tr-TR'),
                    'convertTotal': data.convertTotal,
                    'cashedTotal': data.cashedTotal,
                    'cashedExchange': data.cashedExchange,
                    'cashedCurrency': { 'id': data.exchangeCurrencyId.toString(), 'displayValue': data.exchangeCurrencyName },
                    'convertedAmount': data.convertAmount,
                    'convertExchange': data.convertExchange,
                    'convertedAmountXwrite': parseFloat(data.convertAmount).toLocaleString('tr-TR'),
                    'convertedCurrency': { 'id': data.convertCurrencyId.toString(), 'displayValue': data.convertCurrencyName },
                    'tlAmount': data.exchangeExpense,
                    'invoiceNo': data.invoiceNumber,
                    'receiptInfo': data.invoiceTitle,
                    'receipt': data.invoiceImage
                }
            }) :
                [
                    defaultConvertCardFields
                ]
            setCurrencyConvertList(exchangeArr);
        } else if (expenseData.expenseMasterTypeId === 2) {
            const stateData = {
                'expenseType': expenseData.expenseMasterTypeId,
                'description': expenseData.description,
                'speacialArea': expenseData.additionalField,
            }
            setExpenseState(stateData);
            form.setFieldsValue(stateData);
            if (expenseData.expenseDetails.length > 0) {
                const exchangeArr = expenseData?.expenseDetails.map(data => {
                    return {
                        'expenditureGroup': { 'id': data.expenseType?.id, 'displayValue': data.expenseType?.displayValue },
                        'currency': { 'id': data.currency?.id, 'displayValue': data.currency?.displayValue },
                        'expenseDate': data.expenseDate,
                        'spendingAmount': data.expenseAmount,
                        'spendingAmountXwrite': parseFloat(data.expenseAmount).toLocaleString('tr-TR'),
                        'invoiceNo': data.invoiceNo,
                        'description': data.description,
                        'tlAmount': data.amountTL,
                        'exchangeRate': data.exchangeRate,
                        'receiptInfo': data.invoiceTitle,
                        'receipt': data.invoiceImage
                    }
                })
                setCreditCardList(exchangeArr);
            }
        }
    }, [expenseData, differenceStatus])

    return (
        <>
            <div className="w-100">
                <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    <Form
                        form={form}
                        onValuesChange={handleValuesChange}
                        layout="vertical min-height-500 pad-0"
                        requiredMark={true}
                    >
                        <Form.Item className="pt-5 mb-3" name="expenseType" label={t('PARTNER_EXPENSE_TYPE') + " *"} >
                            {expenseData.expenseMasterTypeId === 1 ?
                                <label>{t('CASH_EXPENSE_ENTRY')}</label>
                                :
                                <label>{t('SPENDING_ENTRY_WITH_CREDIT_CARD')}</label>
                            }
                        </Form.Item>
                        {expenseData.expenseMasterTypeId === 1 &&
                            <>
                                <Row>
                                    <Col span={12}>
                                        {Array.isArray(openAdvanceListState) ?
                                            openAdvanceListState.length > 0 ?
                                                <>
                                                    <Form.Item name="closeAdvanceList" label={t('ADVANCE_TO_BE_CLOSED')}>
                                                        {openAdvanceListState.map((item, index) => {
                                                            var checkBoxStatus = null;
                                                            expenseData.closedAdvance.includes(item.resourceId) ?
                                                                checkBoxStatus = true
                                                                :
                                                                checkBoxStatus = false;
                                                            return (
                                                                <div className="w-100" key={index}>
                                                                    <Checkbox defaultChecked={checkBoxStatus} value={index} disabled={true}>{moment(item.advanceCreatedDate).format(dateFormat) + " " + item.personName + " "}  <span className="close-advance-checkbox-amount-data" />{item.amounts.length > 0 &&
                                                                        item.amounts.map(data => numberFormatTR.format(data.amount) + ' ' + data.currencyName).join(', ')
                                                                    }</Checkbox>
                                                                </div>
                                                            )
                                                        })}
                                                    </Form.Item>
                                                </>
                                                : <div className="mb-20"></div>
                                            : <Spin />
                                        }
                                        <Form.Item name="descriptionType1" label={t('ADVANCE_DESCRIPTION')}>
                                            <Input.TextArea />
                                        </Form.Item>
                                        <Form.Item name="speacialAreaType1" label={t('SPEACIAL_AREA')}>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={1} span={11}>
                                        {returnAdvanceList.length > 0 &&
                                            <>
                                                <div>
                                                    <span className="f-16 black fw-600">{t('ENTER_THE_ADVANCE_TO_BE_REFUNDED')}</span>
                                                </div>
                                                <div className="table-container table-hide-all-buttons blue-table">
                                                    <div className="expense-drawer-advance-return-card-table">
                                                        <div className="row">
                                                            <label className="advance-information m-r-10"> {t('ADVANCE_INFORMATION')}</label>
                                                            <label className="received m-r-10"> {t('RECEIVED')}</label>
                                                            <label className="return m-r-10"> {t('RETURN')}</label>
                                                            <label className="exchange m-r-10"> {t('EXCHANGE_RATE')}</label>
                                                            <label className="advanceDebt m-r-10"> {t('ADVANCE_DEBT')}</label>
                                                        </div>
                                                        {
                                                            returnAdvanceList.length > 0 && returnAdvanceList.map((item, index) => {
                                                                return (
                                                                    <div className="editable" key={index} >
                                                                        <DynamicSelect
                                                                            className="advance-information m-r-10"
                                                                            disabled={true}
                                                                            value={item.advanceInformation}
                                                                            onChange={(e) => handleReturnAdvanceListInputChange(e, index, 'advanceInformation', 'select')}
                                                                        />
                                                                        <MaskedInput
                                                                            className="ant-input received m-r-10"
                                                                            placeholder={t('PARTNER_AMOUNT2')}
                                                                            mask={numberMaskWithSeperator}
                                                                            disabled={true}
                                                                            value={item.received}
                                                                            onChange={(e) => handleReturnAdvanceListInputChange(e, index, 'received', 'priceInput')}
                                                                        />
                                                                        <MaskedInput
                                                                            className="ant-input return m-r-10"
                                                                            placeholder={t('PARTNER_AMOUNT2')}
                                                                            mask={numberMaskWithSeperator}
                                                                            value={item.returnXwrite}
                                                                            onChange={(e) => handleReturnAdvanceListInputChange(e, index, 'return', 'priceInput')}
                                                                        />
                                                                        <Input
                                                                            className="exchange m-r-10"
                                                                            disabled={true}
                                                                            value={item.exchangeRate}
                                                                        />
                                                                        <Input
                                                                            className="advanceDebt m-r-10"
                                                                            disabled={true}
                                                                            value={parseFloat(item.advanceDebt).toLocaleString('tr-TR') + " ₺"}
                                                                        />
                                                                        <label className="delete" onClick={() => handleReturnAddListRemoveItem(index, 'returnCheckList')}>
                                                                            {t('PARTNER_DELETE')}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                        {returnAdvanceAddList.length > 0 && returnAdvanceAddList.map((item, index) => {
                                                            return (
                                                                <div className="editable" key={index} >
                                                                    <DynamicSelect
                                                                        className="advance-information m-r-10"
                                                                        optionList={returnCurrencyList}
                                                                        value={item.advanceInformation}
                                                                        onChange={(e) => handleReturnAdvanceAddListInputChange(e, index, 'advanceInformation', 'select')}
                                                                    />
                                                                    <MaskedInput
                                                                        className="ant-input received m-r-10"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        mask={numberMaskWithSeperator}
                                                                        disabled={true}
                                                                        value={item.received}
                                                                        onChange={(e) => handleReturnAdvanceAddListInputChange(e, index, 'received', 'priceInput')}
                                                                    />
                                                                    <MaskedInput
                                                                        className="ant-input return m-r-10"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        mask={numberMaskWithSeperator}
                                                                        value={item.returnXwrite}
                                                                        onChange={(e) => handleReturnAdvanceAddListInputChange(e, index, 'return', 'priceInput')}
                                                                    />
                                                                    <Input
                                                                        className="exchange m-r-10"
                                                                        disabled={true}
                                                                        value={item.exchangeRate}
                                                                    />
                                                                    <Input
                                                                        className="advanceDebt m-r-10"
                                                                        disabled={true}
                                                                        value={parseFloat(item.advanceDebt).toLocaleString('tr-TR') + " ₺"}
                                                                    />
                                                                    <label className="delete" onClick={() => handleReturnAddListRemoveItem(index, 'returnList')}>
                                                                        {t('PARTNER_DELETE')}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    {returnAdvanceList.length > 0 ?
                                                        <div className="w-100 flex-end">
                                                            <a
                                                                className="advance-refunded-add-button"
                                                                onClick={handleAddNewReturnList}
                                                            >
                                                                {t('INVENTORY_ADD_TEXT')}
                                                            </a>
                                                        </div> :
                                                        <br />
                                                    }
                                                </div>
                                            </>
                                        }
                                        <div className="expense-total-container">
                                            <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('NET_ADVANCE_RECEIVED_TOTAL')} : </span>{parseFloat(returnAdvanceTotal).toLocaleString('tr-TR')} TL</div>
                                            <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseTotal).toLocaleString('tr-TR')}  TL</div>
                                            <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('CURRENCY_CONVERSION_COSTS')} : </span>{parseFloat(currencyConvertTotal).toLocaleString('tr-TR')} TL</div>
                                            {expenseState?.missingAmount > 0 && <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('CLOSED_ADVANCE_DIFFERENCE')} : </span>{parseFloat(expenseState?.missingAmount).toLocaleString('tr-TR')} TL</div>}
                                            <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('DIFFERENCE')} : </span>{expenseState?.missingAmount > 0 ? parseFloat(parseFloat(returnAdvanceTotal) - (expenseTotal + currencyConvertTotal + expenseState.missingAmount)).toLocaleString('tr-TR', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) : returnAdvanceList.length > 0 ? parseFloat(parseFloat(returnAdvanceTotal) - (expenseTotal + currencyConvertTotal)).toLocaleString('tr-TR', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) + ' TL' : 0 + ' TL'}  </div>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                {(isManager === 3 && returnAdvanceList.length > 0) &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Form.Item label={t('ADVANCE_AMOUNT_TO_BE_CLOSED')} >
                                            <MaskedInput
                                                className="ant-input  w-200"
                                                placeholder={t('PARTNER_AMOUNT2')}
                                                mask={numberMaskWithSeperator}
                                                value={expenseState.missingAmountWrite}
                                                onChange={(e) => handleDynamicListInputChange(e, 0, 'missingAmount', 'priceInput', 'default')}
                                            />
                                        </Form.Item>
                                    </div>
                                }
                                <Spin tip="Dosya Yükleniyor..." spinning={fileLoadState.page === 'expense' && fileLoadState.loading}>
                                    <div className="w-100">
                                        <span className="f-24 black fw-600">{t('EXPENSES')}</span>
                                    </div>
                                    <div className="table-container table-hide-all-buttons blue-table">
                                        <div className="expense-drawer-expense-card-table">
                                            <div className="row">
                                                <label className="expenseDate m-r-10">  {t('PARTNER_DATE')} </label>
                                                <label className="expenditureGroup m-r-10"> {t('EXPENDITURE_GROUP')}</label>
                                                <label className="description m-r-10"> {t('PARTNER_RETURN_DESCRIPTION')}</label>
                                                <label className="spendingAmount m-r-10"> {t('PARTNER_AMOUNT2')}</label>
                                                <label className="currency m-r-10"> {t('PARTNER_CURRENCY')}</label>
                                                <label className="amount m-r-10"> {t('TL_AMOUNT')}</label>
                                                <label className="invoiceNo m-r-10"> {t('PARTNER_INVIOCE_NO')}</label>
                                                <label className="receiptInfo m-r-10 "> {t('RECEIPT_TITLE_INFORMATION')}</label>
                                                <label className="receipt m-r-20"> {t('RECEIPT')}</label>
                                            </div>
                                            {
                                                expenseList.map((item, index) => {
                                                    return (
                                                        <div className="editable" key={index} >
                                                            <DatePicker
                                                                locale={localeLang}
                                                                className="expenseDate m-r-10"
                                                                value={item.expenseDate ? moment(item.expenseDate, dateFormat) : null}
                                                                onChange={(date, dateString) => handleDynamicListInputChange(dateString, index, 'expenseDate', 'datePicker', 'expense')}
                                                            />
                                                            <DynamicSelect className="expenditureGroup m-r-10"
                                                                value={item.expenditureGroup}
                                                                optionList={expenseGroupList}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'expenditureGroup', 'select', 'expense')}
                                                            />
                                                            <Input
                                                                className="description m-r-10"
                                                                value={item.description}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'description', 'text', 'expense')}
                                                            />
                                                            <MaskedInput
                                                                className="ant-input spendingAmount m-r-10"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                mask={numberMaskWithSeperator}
                                                                value={item.spendingAmountXwrite}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'spendingAmount', 'priceInput', 'expense')}
                                                            />
                                                            <DynamicSelect
                                                                className="currency m-r-10"
                                                                optionList={expenseCurrencyList}
                                                                value={item.currency}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'currency', 'select', 'expense')}
                                                            />
                                                            <Input
                                                                className="amount m-r-10"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                value={parseFloat(item.tlAmount).toLocaleString('tr-TR')}
                                                                disabled={true}
                                                            />
                                                            <Input
                                                                className="invoiceNo m-r-10"
                                                                value={item.invoiceNo}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'invoiceNo', 'text', 'expense')}
                                                            />
                                                            <Input className="receiptInfo m-r-10"
                                                                value={item.receiptInfo}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'receiptInfo', 'text', 'expense')}
                                                            />
                                                            <Popover content={'Dosya Yükle / Değiştir'}>
                                                                <Upload
                                                                    action={apiUrl + "/expenses/upload"}
                                                                    headers={
                                                                        {
                                                                            "Authorization": `Bearer ${access_token}`
                                                                        }
                                                                    }
                                                                    multiple={false}
                                                                    showUploadList={false}
                                                                    maxCount={1}
                                                                    onChange={(e) => uploadImgAction(e, index, 'expense')}
                                                                //accept={".png,.jpg,.jpeg"}
                                                                >
                                                                    <Button className={item.receipt ? (expenseList.length - 1 === index && index !== 0) ? "primary-btn receipt" : "primary-btn receipt-full" : (expenseList.length - 1 === index && index !== 0) ? "primary-btn receipt-without-file" : "primary-btn receipt-without-file-full"} icon={<UploadOutlined />}></Button>
                                                                </Upload>
                                                            </Popover>
                                                            {item.receipt &&
                                                                <Popover content={t('PARTNER_DOWNLOAD')}>
                                                                    <div className="upload-icon mx-1">
                                                                        <a href={item.receipt} target="_blank"> <UilFile /></a>
                                                                    </div>
                                                                </Popover>
                                                            }
                                                            <div className={(expenseList.length - 1 === index) ? "action-buttons mx-1" : "action-buttons"} >
                                                                {expenseList.length !== 1 &&
                                                                    <Popover content={t('PARTNER_DELETE')}>
                                                                        <Button
                                                                            className="mx-1 pa-0 transparent-btn" onClick={() => handleDynamicListRemoveItem(index, 'expense')}>
                                                                            <UilTrashAlt />
                                                                        </Button>
                                                                    </Popover>
                                                                }
                                                                {expenseList.length - 1 === index &&
                                                                    <Popover content={t('INVENTORY_ADD_TEXT')}>
                                                                        <Button className="pa-0 transparent-btn" onClick={() => handleDynamicAddRow('expense')}   >
                                                                            <UilPlusCircle />
                                                                        </Button>
                                                                    </Popover>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <br />
                                        <div className="w-100">
                                            <div>
                                                <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseTotal).toLocaleString('tr-TR')} TL</span>
                                            </div>
                                        </div>
                                    </div>
                                </Spin >
                                <div className="pad-top-40">
                                    <Spin tip="Dosya Yükleniyor..." spinning={fileLoadState.page === 'currencyConvert' && fileLoadState.loading}>
                                        <div className="w-100">
                                            <span className="f-24 black fw-600">{t('CURRENCY_CONVERSION_COSTS')}</span>
                                        </div>
                                        <div className="table-container table-hide-all-buttons blue-table">
                                            <div className="expense-drawer-currency-conversion-table">
                                                <div className="row">
                                                    <label className="expenseDate align-self-center m-r-10">  {t('PARTNER_DATE')} </label>
                                                    <label className="cashedAmount align-self-center m-r-10"> {t('CASHED_AMOUNT')}</label>
                                                    <label className="cashedCurrency align-self-center m-r-10"> {t('PARTNER_CURRENCY')}</label>
                                                    <label className="convertedAmount align-self-center  m-r-10"> <Tooltip title={t('CONVERTED_AMOUNT')}>{t('CONVERTED_AMOUNT_SHORT')}</Tooltip></label>
                                                    <label className="convertedCurrency align-self-center m-r-10"> {t('PARTNER_CURRENCY')}</label>
                                                    <label className="tlAmount align-self-center  m-r-10"><Tooltip title={t('CONVERSION_COSTS')}> {t('CONVERSION_COSTS_SHORT')}</Tooltip></label>
                                                    <label className="invoiceNo align-self-center m-r-10"> {t('PARTNER_INVIOCE_NO')}</label>
                                                    <label className="receiptInfo align-self-center m-r-10"> {t('RECEIPT_TITLE_INFORMATION')}</label>
                                                    <label className="receipt align-self-center "> {t('RECEIPT')}</label>
                                                </div>
                                                {
                                                    currencyConvertList.map((item, index) => {
                                                        return (
                                                            <div className="editable" key={index} >
                                                                <DatePicker
                                                                    locale={localeLang}
                                                                    className="expenseDate m-r-10"
                                                                    value={item.expenseDate ? moment(item.expenseDate, dateFormat) : null}
                                                                    onChange={(date, dateString) => handleDynamicListInputChange(dateString, index, 'expenseDate', 'datePicker', 'currencyConvert')}
                                                                />
                                                                <MaskedInput
                                                                    className="ant-input cashedAmount m-r-10"
                                                                    placeholder={t('PARTNER_AMOUNT2')}
                                                                    mask={numberMaskWithSeperator}
                                                                    value={item.cashedAmountXwrite}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'cashedAmount', 'priceInput', 'currencyConvert')}
                                                                />
                                                                <DynamicSelect
                                                                    className="cashedCurrency m-r-10"
                                                                    optionList={currencyConvertSelectList1}
                                                                    value={item.cashedCurrency}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'cashedCurrency', 'select', 'currencyConvert')}

                                                                />
                                                                <MaskedInput
                                                                    className="ant-input convertedAmount m-r-10"
                                                                    placeholder={t('PARTNER_AMOUNT2')}
                                                                    mask={numberMaskWithSeperator}
                                                                    value={item.convertedAmountXwrite}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'convertedAmount', 'priceInput', 'currencyConvert')}
                                                                />
                                                                <DynamicSelect
                                                                    className="convertedCurrency m-r-10"
                                                                    optionList={currencyConvertSelectList2}
                                                                    value={item.convertedCurrency}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'convertedCurrency', 'select', 'currencyConvert')}
                                                                />
                                                                <Input
                                                                    className="tlAmount m-r-10"
                                                                    placeholder={t('PARTNER_AMOUNT2')}
                                                                    value={parseFloat(item.tlAmount).toLocaleString('tr-TR')}
                                                                    disabled={true}
                                                                />
                                                                <Input
                                                                    className="invoiceNo m-r-10"
                                                                    value={item.invoiceNo}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'invoiceNo', 'text', 'currencyConvert')}
                                                                />
                                                                <Input className="receiptInfo m-r-10"
                                                                    value={item.receiptInfo}
                                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'receiptInfo', 'text', 'currencyConvert')}
                                                                />
                                                                <Popover content={'Dosya Yükle / Değiştir'}>
                                                                    <Upload
                                                                        action={apiUrl + "/expenses/upload"}
                                                                        headers={
                                                                            {
                                                                                "Authorization": `Bearer ${access_token}`
                                                                            }
                                                                        }
                                                                        multiple={false}
                                                                        showUploadList={false}
                                                                        maxCount={1}
                                                                        onChange={(e) => uploadImgAction(e, index, 'currencyConvert')}
                                                                    //accept={".png,.jpg,.jpeg"}
                                                                    >
                                                                        <Button className={item.receipt ? (currencyConvertList.length - 1 === index && index !== 0) ? "primary-btn receipt" : "primary-btn receipt-full" : (currencyConvertList.length - 1 === index && index !== 0) ? "primary-btn receipt-without-file" : "primary-btn receipt-without-file-full"} icon={<UploadOutlined />}></Button>
                                                                    </Upload>
                                                                </Popover>
                                                                {item.receipt &&
                                                                    <Popover content={t('PARTNER_DOWNLOAD')}>
                                                                        <div className="upload-icon mx-1">
                                                                            <a href={item.receipt} target="_blank"> <UilFile /></a>
                                                                        </div>
                                                                    </Popover>
                                                                }
                                                                <div className={(currencyConvertList.length - 1 === index) ? "action-buttons mx-1" : "action-buttons"} >
                                                                    {currencyConvertList.length !== 1 &&
                                                                        <Popover content={t('PARTNER_DELETE')}>
                                                                            <Button
                                                                                className="mx-1 pa-0 transparent-btn" onClick={() => handleDynamicListRemoveItem(index, 'currencyConvert')}>
                                                                                <UilTrashAlt />
                                                                            </Button>
                                                                        </Popover>
                                                                    }
                                                                    {currencyConvertList.length - 1 === index &&
                                                                        <Popover content={t('INVENTORY_ADD_TEXT')}>
                                                                            <Button className="pa-0 transparent-btn" onClick={() => handleDynamicAddRow('currencyConvert')}   >
                                                                                <UilPlusCircle />
                                                                            </Button>
                                                                        </Popover>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <br />
                                            <div className="w-100">
                                                <div>
                                                    <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(currencyConvertTotal).toLocaleString('tr-TR')} TL</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>
                                </div>
                            </>
                        }
                        {expenseData.expenseMasterTypeId === 2 &&
                            <>
                                <Row>
                                    <Col span={11}>
                                        <Form.Item name="description" label={t('ADVANCE_DESCRIPTION')}>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={2} span={11}>

                                        <Form.Item name="speacialArea" label={t('SPEACIAL_AREA')}>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className="pad-top-40" />
                                <Spin tip="Dosya Yükleniyor..." spinning={fileLoadState.page === 'creditCard' && fileLoadState.loading}>
                                    <div className="w-100">
                                        <span className="f-24 black fw-600">{t('EXPENSES')}</span>
                                    </div>
                                    <div className="table-container table-hide-all-buttons blue-table">
                                        <div className="expense-drawer-credits-card-table">
                                            <div className="row">
                                                <label className="expenseDate m-r-20">  {t('PARTNER_DATE')} </label>
                                                <label className="expenditureGroup m-r-20"> {t('EXPENDITURE_GROUP')}</label>
                                                <label className="spendingAmount m-r-20"> {t('PARTNER_AMOUNT2')}</label>
                                                <label className="currency m-r-20"> {t('PARTNER_CURRENCY')}</label>
                                                <label className="amount m-r-20"> {t('TL_AMOUNT')}</label>
                                                <label className="description m-r-20"> {t('PARTNER_RETURN_DESCRIPTION')}</label>
                                                <label className="invoiceNo m-r-20"> {t('PARTNER_INVIOCE_NO')}</label>
                                                <label className="receiptInfo m-r-20"> {t('RECEIPT_TITLE_INFORMATION')}</label>
                                                <label className="receipt m-r-20"> {t('RECEIPT')}</label>
                                            </div>
                                            {
                                                creditCardList.map((item, index) => {
                                                    return (
                                                        <div className="editable" key={index} >
                                                            <DatePicker
                                                                locale={localeLang}
                                                                className="expenseDate m-r-20"
                                                                value={item.expenseDate ? moment(item.expenseDate, dateFormat) : null}
                                                                onChange={(date, dateString) => handleDynamicListInputChange(dateString, index, 'expenseDate', 'datePicker', 'creditCard')}
                                                            />
                                                            <DynamicSelect className="expenditureGroup m-r-20"
                                                                value={item.expenditureGroup}
                                                                optionList={expenseGroupList}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'expenditureGroup', 'select', 'creditCard')}
                                                            />
                                                            <MaskedInput
                                                                className="ant-input spendingAmount m-r-20"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                mask={numberMaskWithSeperator}
                                                                value={item.spendingAmountXwrite}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'spendingAmount', 'priceInput', 'creditCard')}
                                                            />
                                                            <DynamicSelect
                                                                className="currency m-r-20"
                                                                optionList={creditCardCurrencyList}
                                                                value={item.currency}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'currency', 'select', 'creditCard')}

                                                            ></DynamicSelect>
                                                            <Input
                                                                className=" amount m-r-20"
                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                value={parseFloat(item.tlAmount).toLocaleString('tr-TR')}
                                                                disabled={true}
                                                            />
                                                            <Input
                                                                className="description m-r-20"
                                                                value={item.description}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'description', 'text', 'creditCard')}
                                                            />
                                                            <Input
                                                                className="invoiceNo m-r-20"
                                                                value={item.invoiceNo}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'invoiceNo', 'text', 'creditCard')}
                                                            />
                                                            <Input className="receiptInfo m-r-20"
                                                                value={item.receiptInfo}
                                                                onChange={(e) => handleDynamicListInputChange(e, index, 'receiptInfo', 'text', 'creditCard')}
                                                            />
                                                            <Popover content={'Dosya Yükle / Değiştir'}>
                                                                <Upload
                                                                    action={apiUrl + "/expenses/upload"}
                                                                    headers={
                                                                        {
                                                                            "Authorization": `Bearer ${access_token}`
                                                                        }
                                                                    }
                                                                    multiple={false}
                                                                    showUploadList={false}
                                                                    maxCount={1}
                                                                    onChange={(e) => uploadImgAction(e, index, 'creditCard')}
                                                                //accept={".png,.jpg,.jpeg"}
                                                                >
                                                                    <Button className={item.receipt ? (creditCardList.length - 1 === index && index !== 0) ? "primary-btn receipt" : "primary-btn receipt-full" : (creditCardList.length - 1 === index && index !== 0) ? "primary-btn receipt-without-file" : "primary-btn receipt-without-file-full"} icon={<UploadOutlined />}></Button>
                                                                </Upload>
                                                            </Popover>
                                                            {item.receipt &&
                                                                <Popover content={t('PARTNER_DOWNLOAD')}>
                                                                    <div className="upload-icon mx-1">
                                                                        <a href={item.receipt} target="_blank"> <UilFile /></a>
                                                                    </div>
                                                                </Popover>
                                                            }
                                                            <div className={(creditCardList.length - 1 === index) ? "action-buttons mx-1" : "action-buttons"} >
                                                                {creditCardList.length !== 1 &&
                                                                    <Popover content={t('PARTNER_DELETE')}>
                                                                        <Button
                                                                            className="mx-1 pa-0 transparent-btn" onClick={() => handleDynamicListRemoveItem(index, 'creditCard')}>
                                                                            <UilTrashAlt />
                                                                        </Button>
                                                                    </Popover>
                                                                }
                                                                {creditCardList.length - 1 === index &&
                                                                    <Popover content={t('INVENTORY_ADD_TEXT')}>
                                                                        <Button className="pa-0 transparent-btn" onClick={() => handleDynamicAddRow('creditCard')}   >
                                                                            <UilPlusCircle />
                                                                        </Button>
                                                                    </Popover>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <br />
                                        <div className="w-100">
                                            <div>
                                                <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(creditCardTotalAmount).toLocaleString('tr-TR')} TL</span>
                                            </div>

                                        </div>
                                    </div>
                                </Spin>
                            </>
                        }
                    </Form>
                </ConfigProvider>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        currencyTypes: state.dataset.currencyTypes,
        currencyWithId: state.expense.currencyWithId,
        profile: state.profile,
        expenseTypes: state.dataset.expenseTypes,
        multipleCurrencyWithId: state.expense.multipleCurrencyWithId,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getCurrencyWithId: (id, date1, date2) => { dispatch(getCurrencyWithId(id, date1, date2)) },
        getDataset: (type) => { dispatch(getDataset(type)) },
        createExpenseAction: (data) => { dispatch(createExpenseAction(data)) },
        resetCreateExpenseResponse: () => { dispatch(resetCreateExpenseResponse()) },
        resetCurrencyWithIdResponse: () => { dispatch(resetCurrencyWithIdResponse()) },
        getMultipleCurrency: (data1, data2, date1, date2) => { dispatch(getMultipleCurrency(data1, data2, date1, date2)) },
        resetGetMultipleCurrency: () => { dispatch(resetGetMultipleCurrency()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseEdit);