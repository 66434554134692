import React from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir
import PhoneInput from 'react-phone-input-2';
import { Form, Col, Row, Spin, ConfigProvider } from 'antd';
import '../../styles/person.scss';

import { dateFormatConvert, phoneInputConfig } from '../../utils/config';
import { checkTextIsEmpty, checkMomentTextIsEmpty } from '../../utils/commonFormFunctions';


function ProfileBasicInformation(props) {

    const { t } = useTranslation();
    const { personBasicInfoIsFetching, personBasicInfo } = props;


    var hireDate = moment(personBasicInfo.hireDate);
    var currentDate = moment();
    var diffDuration = moment.duration(currentDate.diff(hireDate));


    return (
        personBasicInfoIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>

                <Form layout="vertical pad-top-10">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-1">
                                <Form.Item name="givenName" label={t('PARTNER_GIVENNAME')} required>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.givenName)}</p>
                                </Form.Item>
                                <Form.Item name="familyName" label={t('PARTNER_FAMILYNAME')} required>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.familyName)}</p>
                                </Form.Item>
                                <Form.Item name="job" className="custom-select" label={t('PARTNER_JOB')}>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.job?.displayValue)}</p>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-2">
                                <Form.Item className="custom-select" name="manager" label={t('PARTNER_MANAGER')} required >
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.manager?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="department" className="custom-select" label={t('PARTNER_DEPARTMENT')}>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.department?.displayValue)}</p>
                                </Form.Item>
                                <Form.Item name="identityNumber" label={t('PARTNER_IDENTITY_NUMBER')}>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.identityNumber)}</p>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-3">
                                <Form.Item name="corporateEmail" label={t('PARTNER_CORPORATE_MAIL')}>
                                    <p className="form-text email">{checkTextIsEmpty(personBasicInfo.corporateEmail)}</p>
                                </Form.Item>
                                <Form.Item label={t('PARTNER_OFFICE_PHONE')}>
                                    {personBasicInfo.officePhone ? 
                                        <PhoneInput
                                            {...phoneInputConfig}
                                            value={personBasicInfo.officePhone}
                                            disableDropdown={true}
                                            disabled={true}
                                        />
                                        : ' - '}
                                </Form.Item>
                                <Form.Item name="workPhoneExt" label={t('PARTNER_WORK_PHONE_EXT')}>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.workPhoneExt)}</p>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            <div className="form-left-body view-mode profile-col-4">

                                <Form.Item name="hireDate" label={t('PARTNER_START_DATE_OF_COMPANY')}>
                                    <p className="form-text">{checkMomentTextIsEmpty(personBasicInfo.hireDate, dateFormatConvert)}</p>
                                </Form.Item>
                                {personBasicInfo.hireDate && isNaN(personBasicInfo.hireDate) &&
                                    <Form.Item name="uptime" label={t('UPTIME')}>
                                        <p className="form-text">
                                            {diffDuration.years() > 0 && diffDuration.years() + ' ' + t('PARTNER_YEARS') + ' '}
                                            {diffDuration.months() > 0 && diffDuration.months() + ' ' + t('PARTNER_MONTH') + ' '}
                                            {diffDuration.days() > 0 && diffDuration.days() + ' ' + t('PARTNER_DAY')}
                                        </p>
                                    </Form.Item>
                                }
                                <Form.Item name="status" className="custom-select" label={t('PARTNER_STATUS')}>
                                    <p className="form-text">{checkTextIsEmpty(personBasicInfo.status?.displayValue)}</p>
                                </Form.Item>
                                {personBasicInfo?.status?.id === '3' &&
                                    <React.Fragment>
                                        <Form.Item name="leaveDate" label={t('PARTNER_LEAVE_DATE')}>
                                            <p className="form-text">
                                                {checkMomentTextIsEmpty(personBasicInfo.leaveDate, dateFormatConvert)}
                                            </p>
                                        </Form.Item>
                                        <Form.Item name="leaveReason" className="custom-select" label={t('PARTNER_LEAVE_REASON')}>
                                            <p className="form-text">{checkMomentTextIsEmpty(personBasicInfo.leaveReason?.displayValue)}</p>
                                        </Form.Item>
                                    </React.Fragment>
                                }
                            </div>
                        </Col>
                    </Row>

                </Form >
            </ConfigProvider >

        )
    )
}



const mapStateToProps = (state) => {
    return {

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBasicInformation);