import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";

const JobActionType = {
  JOB_FILTER_REQUEST: "JOB_FILTER_REQUEST",
  JOB_FILTER_SUCCESS: "JOB_FILTER_SUCCESS",
  JOB_FILTER_FAIL: "JOB_FILTER_FAIL",
  JOB_LIST_REQUEST: "JOB_LIST_REQUEST",
  JOB_LIST_SUCCESS: "JOB_LIST_SUCCESS",
  JOB_LIST_FAIL: "JOB_LIST_FAIL",
  CREATE_JOB_NAME_SUCCESS: "CREATE_JOB_NAME_SUCCESS",
  CREATE_JOB_NAME_FAIL: "CREATE_JOB_NAME_FAIL",
  CREATE_JOB_NAME_INPUT_ERROR: "CREATE_JOB_NAME_INPUT_ERROR",
  CREATE_JOB_NAME_RESET: "CREATE_JOB_NAME_RESET",
  UPDATE_JOB_NAME_REQUEST: "UPDATE_JOB_NAME_REQUEST",
  UPDATE_JOB_NAME_SUCCESS: "UPDATE_JOB_NAME_SUCCESS",
  UPDATE_JOB_NAME_FAIL: "UPDATE_JOB_NAME_FAIL",
  UPDATE_JOB_NAME_INPUT_ERROR: "UPDATE_JOB_NAME_INPUT_ERROR",
  UPDATE_JOB_NAME_RESET: "UPDATE_JOB_NAME_RESET",
  DELETE_JOB_NAME_SUCCESS: "DELETE_JOB_NAME_SUCCESS",
  DELETE_JOB_NAME_FAIL: "DELETE_JOB_NAME_FAIL",
  DELETE_JOB_NAME_RESET: "DELETE_JOB_NAME_RESET",
  GET_CONNECTED_JOB_REQUEST: "GET_CONNECTED_JOB_REQUEST",
  GET_CONNECTED_JOB_SUCCESS: "GET_CONNECTED_JOB_SUCCESS",
  GET_CONNECTED_JOB_FAIL: "GET_CONNECTED_JOB_FAIL",
};

const getJobFilter = (filterData) => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.JOB_FILTER_REQUEST });
    try {
      const data = JSON.stringify(filterData);
      var config = {
        method: "post",
        url: apiUrl + "/jobTitles/filter",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: JobActionType.JOB_FILTER_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: JobActionType.JOB_FILTER_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: JobActionType.JOB_FILTER_SUCCESS, payload: emptyData });
          }
          dispatch({ type: JobActionType.JOB_FILTER_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: JobActionType.JOB_FILTER_FAIL, payload: error.message });
      }
    }
  };
};

const getJobList = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.JOB_LIST_REQUEST });
    try {
      const data = JSON.stringify(searchData);

      var config = {
        method: "post",
        url: apiUrl + "/jobTitle/search",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: JobActionType.JOB_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: JobActionType.JOB_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404 || error.response?.status === 500) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: JobActionType.JOB_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: JobActionType.JOB_LIST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: JobActionType.JOB_LIST_FAIL, payload: error.message });
      }
    }
  };
};

const createJobName = (createData) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createData);

      var config = {
        method: "post",
        url: apiUrl + "/jobTitles",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: JobActionType.CREATE_JOB_NAME_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: JobActionType.CREATE_JOB_NAME_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: JobActionType.CREATE_JOB_NAME_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: JobActionType.CREATE_JOB_NAME_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: JobActionType.CREATE_JOB_NAME_FAIL, payload: error.message });
      }
    }
  };
};

const resetCreateJobName = () => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.CREATE_JOB_NAME_RESET, payload: {} });
  };
};

const updateJobName = (updateData, id) => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.UPDATE_JOB_NAME_REQUEST });
    try {
      const resourceId = id;
      const data = JSON.stringify(updateData);

      var config = {
        method: "put",
        url: apiUrl + "/jobTitles/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: JobActionType.UPDATE_JOB_NAME_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: JobActionType.UPDATE_JOB_NAME_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: JobActionType.UPDATE_JOB_NAME_INPUT_ERROR, payload: error.response.data.message });
          } else if (error.response?.status === 409) {
            dispatch({ type: JobActionType.UPDATE_JOB_NAME_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: JobActionType.UPDATE_JOB_NAME_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: JobActionType.UPDATE_JOB_NAME_FAIL, payload: error.message });
      }
    }
  };
};

const resetUpdateJobName = () => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.UPDATE_JOB_NAME_RESET, payload: {} });
  };
};

const deleteJobName = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/jobTitles/" + resourceId,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: JobActionType.DELETE_JOB_NAME_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: JobActionType.DELETE_JOB_NAME_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: JobActionType.DELETE_JOB_NAME_FAIL, payload: error.response.data.message });
          } else if (error.response?.status === 409) {
            dispatch({ type: JobActionType.DELETE_JOB_NAME_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: JobActionType.DELETE_JOB_NAME_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: JobActionType.DELETE_JOB_NAME_FAIL, payload: error.message });
      }
    }
  };
};
const resetDeleteJobName = () => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.DELETE_JOB_NAME_RESET, payload: {} });
  };
};

const getPersonsJobsConnect = (searchData, resourceId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionType.GET_CONNECTED_JOB_REQUEST });

    try {
      const data = JSON.stringify(searchData);

      var config = {
        method: "post",
        url: apiUrl + "/jobTitle/" + resourceId + "/getPersons",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: JobActionType.GET_CONNECTED_JOB_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: JobActionType.GET_CONNECTED_JOB_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 500) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: JobActionType.GET_CONNECTED_JOB_SUCCESS, payload: emptyData });
          }
          dispatch({ type: JobActionType.GET_CONNECTED_JOB_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: JobActionType.GET_CONNECTED_JOB_FAIL, payload: error.message });
      }
    }
  };
};

const setJobConnectDraw = (jobConnectDrawer) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_JOB_CONNECT_DRAWER",
      payload: jobConnectDrawer,
    });
  } catch (e) {
    console.log(e);
  }
};

export {
  JobActionType,
  getJobFilter,
  getJobList,
  createJobName,
  resetCreateJobName,
  updateJobName,
  resetUpdateJobName,
  deleteJobName,
  resetDeleteJobName,
  getPersonsJobsConnect,
  setJobConnectDraw,
};
