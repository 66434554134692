import { SubscriptionActionType } from "../actions/subscription.action";

const initialState = {
    // Responses
    subscriptionResponse : null,
    saveCardResponse : null,
    deleteCardResponse : null,

    isSubscriptionLoading: false,

    isCardSaveLoading: false,
    isCardDeleteLoading : null,
    isTryPaymentAgainLoading : null,

    subscriptionFail: false,
    saveCardResponseFail : false,
    deleteCardResponseFail : false,
  };

  // eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
    case SubscriptionActionType.GET_SUBSCRIPTION_REQUEST:
    return {
        ...state,
        isSubscriptionLoading: true,
        subscriptionFail: false,
        isCardDeleteLoading: null,
        isCardSaveLoading: null,
        isTryPaymentAgainLoading: null,
    };
    case SubscriptionActionType.GET_SUBSCRIPTION_SUCCESS:
    return {
        ...state,
        subscriptionResponse: action.payload,
        subscriptionFail: false,
        isSubscriptionLoading: false,
    };
    case SubscriptionActionType.GET_SUBSCRIPTION_FAIL:
    return {
        ...state,
        subscriptionFail: true,
        isSubscriptionLoading: false,
    };
    //
    case SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_REQUEST:
    return {
        ...state,
        saveCardResponse: action.payload,
        isCardSaveLoading: 'loading',
        saveCardResponseFail: false,
    };
    case SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_SUCCESS:
    return {
        ...state,
        saveCardResponse: action.payload,
        isCardSaveLoading: 'success',
        saveCardResponseFail: false,
    };
    case SubscriptionActionType.SAVE_CARD_SUBSCRIPTION_FAIL:
    return {
        ...state,
        isCardSaveLoading: 'failed',
        saveCardResponseFail: true,
    };
    //
    case SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_REQUEST:
    return {
        ...state,
        deleteCardResponse: action.payload,
        isCardDeleteLoading: 'loading',
        subscriptionFail: false,
    };
    case SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_SUCCESS:
    return {
        ...state,
        isCardDeleteLoading: 'success',
        deleteCardResponseFail: false,
    };
    case SubscriptionActionType.DELETE_CARD_SUBSCRIPTION_FAIL:
    return {
        ...state,
        deleteCardResponse: action.payload,
        isCardDeleteLoading: 'failed',
        deleteCardResponseFail: true,
    };
    //
    case SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_REQUEST:
    return {
        ...state,
        deleteCardResponse: action.payload,
        isTryPaymentAgainLoading: 'loading',
        subscriptionFail: false,
    };
    case SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_SUCCESS:
    return {
        ...state,
        isTryPaymentAgainLoading: 'success',
        deleteCardResponseFail: false,
    };
    case SubscriptionActionType.TRY_PAYMENT_AGAIN_SUBSCRIPTION_FAIL:
    return {
        ...state,
        deleteCardResponse: action.payload,
        isTryPaymentAgainLoading: 'failed',
        deleteCardResponseFail: true,
    };
      default:
        return state;
    }
}
