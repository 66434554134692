import { Col, Row, Card } from 'antd';
import { TeamOutlined, FileDoneOutlined, } from '@ant-design/icons';

function getHelperCard() {
    return (
        <div className="helper-class pad-24">
            <Row justify="space-between">
                <Col span={6}>
                    <Card className="helper-card">
                        <TeamOutlined />
                        <h1>İnsan Kaynakları İşlemleri</h1>

                        <p>Yeni Kişi Nasıl Eklerim ?</p>
                        <p>Kişi Bilgilerini Nasıl Düzenlerim ?</p>
                        <p>Nasıl İzin Talebi Oluştururum ?</p>
                        {/* <div className="all-question">
                            <a href="/tum-sorular">Tüm Sorular</a>
                        </div> */}
                    </Card>
                </Col>
                <Col span={6}>
                    <div className="flex-center">

                        <Card className="helper-card">
                            <FileDoneOutlined />
                            <h1>Envanter ve Zimmet İşlemleri</h1>

                            <p>Yeni Envanter Nasıl Eklerim?</p>
                            <p>Kişilere Nasıl Zimmet Oluşturabilirim?</p>
                            <p>Nasıl Yeni Envanter Tipi Oluşturabilirim?</p>
                            {/* <div className="all-question">
                                <a href="/tum-sorular">Tüm Sorular</a>
                            </div> */}
                        </Card>
                    </div>
                </Col>
                <Col span={6}>
                    <div className="flex-end">
                        <Card className="helper-card">
                            <FileDoneOutlined />
                            <h1>Diğer İşlemler</h1>

                            <p>Dil Seçeneğini Nasıl Değiştirebilirim?</p>
                            <p>Karanlık Moda Nasıl Geçebilirim?</p>
                            {/* <div className="all-question">
                                <a href="/tum-sorular">Tüm Sorular</a>
                            </div> */}
                        </Card>
                    </div>
                </Col>
            </Row>

        </div>
    )
}
export default getHelperCard;