import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { organizationSearch, organizationListExportWithPdf } from "../../redux/actions/organization.actions";
import { getGridSetting, createGridSettings, settingsReset } from "../../redux/actions/setting.actions";
import { setOrganizationAddDrawer, setOrganizationDetailsDrawer } from "../../redux/actions/person-organization-drawer-action";
import { getOrganizationDetailsInfo } from "../../redux/actions/organizationDetail.actions";

import { Button, Dropdown, Layout, Col } from "antd";
import { Drawer } from "antd";
import { drawerWidth } from "../../utils/config";

import { ToastContainer } from "react-toastify";
import Media from "react-media";

import OrganizationDetailsDraw from "../../component/organizationDrawerTabs/details/organization-details-draw";
import AddOrganizationTabs from "../../component/organizationDrawerTabs/add/organization-add-all-tabs";
import DynamicTable from "../../component/dynamicTable/index";
import BreadCrumb from "../../features/breadcrump/breadcrump";
import ExportModal from "../../component/dynamicModal/exportModal";
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../../component/forbidden/index";
import { useWindowDimensions } from "../../utils/config";
import { formatPhoneNumber } from "../../utils/commonFormFunctions";

function Organization(props) {
  const { t } = useTranslation();
  const { Content } = Layout;
  const [isShowPassives, setIsShowPassives] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addOrganizationDrawerKey, setAddOrganizationDrawerKey] = useState(100);
  const [detailOrganizationDrawerKey, setDetailOrganizationDrawerKey] = useState(1000);
  const { organizationList, totalNumberOfRecords, organizationSearch, getOrganizationDetailsInfo, organizationDetailsInfoIsFetching, organizationDetailsInfo, setOrganizationDetailsDrawer, organizationListExportWithPdf, isOrganizationFilterLoading, getGridSetting, createGridSettings, createGridSettingsResponse, gridSettings, settingsReset, organizationTabreducer } = props;

  const initialGetGridSettingData = {
    pageId: 2, //organization
    gridId: 1, //organization grid
  };
  const initialSearchData = {
    searchTerm: "",
    organizations: [],
    organizationTypes: [],
    organizationSectors: [],
    provinces: [],
    districts: [],
    showPassiveRecords: false,
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      orderBy: "OrganizationName",
      ascending: true,
    },
  };
  const pdfInitialData = {
    searchTerm: "",
    organizations: [],
    organizationTypes: [],
    organizationSectors: [],
    provinces: [],
    districts: [],
    showPassiveRecords: false,
    exportType: 0,
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      orderBy: "OrganizationName",
      ascending: true,
    },
  };

  const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData);
  const [searchData, setSearchData] = useState(initialSearchData);
  const { width } = useWindowDimensions();

  function handleExportDocument(props) {
    pdfInitialData.exportType = props;
    organizationListExportWithPdf(pdfInitialData);
  }

  useEffect(() => {
    organizationSearch(searchData);
  }, [searchData]);

  useEffect(() => {
    getGridSetting(getGridSettingsData);
  }, [getGridSettingsData]);

  useEffect(() => {
    if (createGridSettingsResponse.status === "SUCCESS") {
      settingsReset();
    }
  }, [createGridSettingsResponse]);

  const showOrganizationDetailDrawer = (id) => {
    getOrganizationDetailsInfo(id);
    setOrganizationDetailsDrawer(true);
  };

  const data = organizationList.map((row) => {
    return {
      key: row.resourceId,
      organizationName: { text: row.organizationName, id: row.resourceId },
      organizationType: row.organizationType,
      legalName: row.legalName,
      sector: row.sector,
      telephone: formatPhoneNumber(row.telephone),
      province: row.province,
      district: row.district,
    };
  });

  const tableConfig = () => ({
    filterableColumns: width >= 875 ? ["organizationName", "organizationType", "legalName", "sector", "province", "district"] : [],
    sortableColumns: ["organizationName", "organizationType", "legalName", "sector", "telephone", "province", "district"],
    visibilityOptionExcludes: ["organizationName", "actionMenu"],
    gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
    page: "organization",
  });

  const paginationConfig = () => ({
    total: totalNumberOfRecords,
    showSizeChanger: true,
    locale: { items_per_page: "/" + t("PAGE") },
    position: totalNumberOfRecords < 10 ? ["none", "none"] : ["none", "bottomLeft"],
  });
  const handleTableChange = (data) => {
    const paginationObj = {
      ...searchData.pagination,
      pageNumber: data.paging.current,
      pageSize: data.paging.pageSize,
      orderBy: data.sorter.field ? data.sorter.field : searchData.pagination.orderBy,
    };
    if (data.sorter.order) {
      paginationObj["ascending"] = data.sorter.order === "ascend" ? true : false;
    }
    setSearchData({ ...searchData, pagination: paginationObj });
  };

  const handleTableSearch = (e) => {
    const pagingObj = { ...searchData.pagination, pageNumber: 1 };
    setSearchData({ ...searchData, searchTerm: e, pagination: pagingObj });
  };

  const handleTableFilter = (data) => {
    setSearchData({
      ...searchData,
      organizations: data.organizations,
      organizationTypes: data.organizationTypes,
      organizationSectors: data.organizationSectors,
      provinces: data.provinces,
      districts: data.districts,
    });
  };

  const handleShowPassivesToogle = (e) => {
    setIsShowPassives(e);
    setSearchData({ ...searchData, showPassiveRecords: e });
  };

  const handleResetAllfilters = () => {
    setIsShowPassives(false);
    setSearchData(initialSearchData);
  };

  const handleSaveGridSettings = (d) => {
    let createObj = { data: d, pageId: getGridSettingsData.pageId, gridId: getGridSettingsData.gridId };
    createGridSettings(createObj);
  };
  useEffect(() => {
    if (organizationTabreducer.organizationaddDrawer) {
      setAddOrganizationDrawerKey((i) => i + 1);
    }
    if (organizationTabreducer.organizationDetailsDrawer) {
      setDetailOrganizationDrawerKey((i) => i + 1);
    }
  }, [organizationTabreducer]);
  return (
    <>
      <Can I="organization.list">
        <Layout>
          <ExportModal
            onCancel={() => {
              setIsModalVisible(false);
            }}
            status={isModalVisible}
            handleExportDocument={handleExportDocument}
          />
          <Layout>
            <Col span={24}>
              <Content className="page-container organization-container">
                <BreadCrumb />
                <div className="tab-menu">
                  <div className="organization-space-between">
                    <h1 className="page-title">
                      {t("ORGANIZATIONS")} <span className="count-info">({totalNumberOfRecords})</span>{" "}
                    </h1>
                  </div>
                  <Col span={24}>
                    <div className="table-container">
                      <Media query="(min-width: 875px)">
                        {(matches) => {
                          const columns = [
                            {
                              title: `${t("ORGANIZATION_NAME")}`,
                              dataIndex: "organizationName",
                              key: "organizationName",
                              sorter: true,
                              render: (obj) => (
                                <div className="imageNameActionColumn">
                                  <Can I="organization.read">
                                    {width >= 875 ? (
                                      <React.Fragment>
                                        <a className="details-text" onClick={() => showOrganizationDetailDrawer(obj.id)}>
                                          {obj.text}
                                        </a>
                                        <Dropdown className="action-menu" overlay={null} trigger={["click"]}>
                                          <Button onClick={(e) => e.preventDefault()} className="transparent-btn"></Button>
                                        </Dropdown>
                                      </React.Fragment>
                                    ) : (
                                      <span style={{ paddingLeft: "15px" }}>{obj.text}</span>
                                    )}
                                  </Can>
                                  <Cannot I="organization.read">
                                    <span style={{ paddingLeft: "15px" }}>{obj.text}</span>
                                  </Cannot>
                                </div>
                              ),
                            },
                            {
                              title: `${t("ORGANIZATION_PHONE")}`,
                              dataIndex: "telephone",
                              key: "telephone",
                              sorter: true,
                            },
                          ];
                          if (matches) {
                            columns.splice(1, 0, {
                              title: `${t("TYPE")}`,
                              dataIndex: "organizationType",
                              key: "organizationType",
                              sorter: true,
                            });
                            columns.splice(2, 0, {
                              title: `${t("LEGAL_NAME")}`,
                              dataIndex: "legalName",
                              key: "legalName",
                              sorter: true,
                            });
                            columns.splice(3, 0, {
                              title: `${t("ORGANIZATION_SECTOR")}`,
                              dataIndex: "sector",
                              key: "sector",
                              sorter: true,
                            });
                            columns.splice(5, 0, {
                              title: `${t("ORGANIZATION_PROVINCE")}`,
                              dataIndex: "province",
                              key: "province",
                              sorter: true,
                            });
                            columns.splice(6, 0, {
                              title: `${t("ORGANIZATION_DISTRICT")}`,
                              dataIndex: "district",
                              key: "district",
                              sorter: true,
                            });
                          }
                          return <DynamicTable columns={columns} dataSource={data} tableConfig={tableConfig()} paginationConfig={paginationConfig()} onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters} searchPlaceholder={t("SEARCH_ORGANIZATION")} onFilter={(data) => handleTableFilter(data)} onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives} onSaveGridSettings={(d) => handleSaveGridSettings(d)} loadStatus={isOrganizationFilterLoading} checkboxStatus={false} />;
                        }}
                      </Media>
                    </div>
                  </Col>
                  <Drawer key={addOrganizationDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={organizationTabreducer.organizationDetailsDrawer} className="ant-drawer-without-shadow">
                    <OrganizationDetailsDraw searchData={searchData} organizationDetailsInfo={organizationDetailsInfo} organizationDetailsInfoIsFetching={organizationDetailsInfoIsFetching} />
                  </Drawer>
                  <Drawer key={detailOrganizationDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={organizationTabreducer.organizationaddDrawer}>
                    <AddOrganizationTabs searchData={searchData} />
                  </Drawer>
                </div>
              </Content>
            </Col>
          </Layout>
          <ToastContainer />
        </Layout>
      </Can>
      <Cannot I="organization.list">
        <Forbidden />
      </Cannot>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    organizationList: state.organization.searchResult.results,
    isOrganizationFilterLoading: state.organization.isOrganizationFilterLoading,
    totalNumberOfRecords: state.organization.searchResult.totalNumberOfRecords,
    organizationTabreducer: state.organizationTabreducer,
    organizationDetailsInfo: state.organizationDetailsReducer.organizationDetailsInfo,
    organizationDetailsInfoIsFetching: state.organizationDetailsReducer.organizationDetailsInfoIsFetching,
    gridSettings: state.setting.gridSettings,
    createGridSettingsResponse: state.setting.createGridSettingsResponse,
    organizationOrgChart: state.organization.organizationOrgChart,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    organizationSearch: (searchData) => {
      dispatch(organizationSearch(searchData));
    },
    getOrganizationDetailsInfo: (id) => {
      dispatch(getOrganizationDetailsInfo(id));
    },
    setOrganizationAddDrawer: (props) => {
      dispatch(setOrganizationAddDrawer(props));
    },
    setOrganizationDetailsDrawer: (props) => {
      dispatch(setOrganizationDetailsDrawer(props));
    },
    organizationListExportWithPdf: (props) => {
      dispatch(organizationListExportWithPdf(props));
    },
    getGridSetting: (props) => {
      dispatch(getGridSetting(props));
    },
    createGridSettings: (props) => {
      dispatch(createGridSettings(props));
    },
    settingsReset: () => {
      dispatch(settingsReset());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Organization);
