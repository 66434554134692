import { useEffect } from 'react';

const OnboardingTaskHook = (currentTaskState, setPopoverVisible, getOnboarding , onboardingActive) => {
    useEffect(() => {
        if(!onboardingActive) { return; }

        if (currentTaskState === 1 || currentTaskState === 1000) {
            getOnboarding(0);
            return currentTaskState === 1 && setPopoverVisible(true);
        }
        if (currentTaskState === 14 || currentTaskState === 1004) {
            getOnboarding(1);
            return currentTaskState === 1004 && setPopoverVisible(true);
        }
        if (currentTaskState === 23 || currentTaskState === 1005) {
            getOnboarding(2);
            return currentTaskState === 1005 && setPopoverVisible(true);
        }
        if (currentTaskState === 34) {
            getOnboarding(3);
        }
        if (currentTaskState === 43) {
            getOnboarding(4);
        }
        if (currentTaskState === 53) {
            getOnboarding(5);
        }
        if (currentTaskState === 63) {
            getOnboarding(6);
        }
        if (currentTaskState === 74 || currentTaskState === 1007) {
            getOnboarding(7);
            return currentTaskState === 1007 && setPopoverVisible(true);
        }
        if (currentTaskState === 84) {
            getOnboarding(8);
        }
        if (currentTaskState === 92) {
            getOnboarding(9);
        }
        if (currentTaskState === 103) {
            getOnboarding(10);
        }
        if (currentTaskState === 112) {
            getOnboarding(11);
        }
        if (currentTaskState === 114) {
            getOnboarding(11);
        }
        if (currentTaskState === 1003) {
            setPopoverVisible(true);
        }
    }, [currentTaskState]);
};

export default OnboardingTaskHook;
