import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
const FileDownload = require("js-file-download");

const Inventory = {
  GET_INVENTORY_BY_INVENTORY_TYPE_REQUEST: "GET_INVENTORY_BY_INVENTORY_TYPE_REQUEST",
  GET_INVENTORY_BY_INVENTORY_TYPE_SUCCESS: "GET_INVENTORY_BY_INVENTORY_TYPE_SUCCESS",
  GET_INVENTORY_BY_INVENTORY_TYPE_FAIL: "GET_INVENTORY_BY_INVENTORY_TYPE_FAIL",
  GET_INVENTORY_LIST_REQUEST: "GET_INVENTORY_LIST_REQUEST",
  GET_INVENTORY_LIST_SUCCESS: "GET_INVENTORY_LIST_SUCCESS",
  GET_INVENTORY_LIST_FAIL: "GET_INVENTORY_LIST_FAIL",
  GET_INVENTORY_REQUEST: "GET_INVENTORY_REQUEST",
  GET_INVENTORY_SUCCESS: "GET_INVENTORY_SUCCESS",
  GET_INVENTORY_FAIL: "GET_INVENTORY_FAIL",
  GET_INVENTORY_ATTRIBUTES_REQUEST: "GET_INVENTORY_ATTRIBUTES_REQUEST",
  GET_INVENTORY_ATTRIBUTES_SUCCESS: "GET_INVENTORY_ATTRIBUTES_SUCCESS",
  GET_INVENTORY_ATTRIBUTES_FAIL: "GET_INVENTORY_ATTRIBUTES_FAIL",
  GET_INVENTORY_BRAND_REQUEST: "GET_INVENTORY_BRAND_REQUEST",
  GET_INVENTORY_BRAND_SUCCESS: "GET_INVENTORY_BRAND_SUCCESS",
  GET_INVENTORY_BRAND_FAIL: "GET_INVENTORY_BRAND_FAIL",
  CREATE_INVENTORY_SUCCESS: "CREATE_INVENTORY_SUCCESS",
  CREATE_INVENTORY_FAIL: "CREATE_INVENTORY_FAIL",
  CREATE_INVENTORY_INPUT_ERROR: "CREATE_INVENTORY_INPUT_ERROR",
  CREATE_INVENTORY_RESET: "CREATE_INVENTORY_RESET",
  GET_INVENTORY_DETAILS_REQUEST: "GET_INVENTORY_DETAILS_REQUEST",
  GET_INVENTORY_DETAILS_SUCCESS: "GET_INVENTORY_DETAILS_SUCCESS",
  GET_INVENTORY_DETAILS_FAIL: "GET_INVENTORY_DETAILS_FAIL",
  GET_INVENTORY_OWNER_SHIP_DETAILS_REQUEST: "GET_INVENTORY_OWNER_SHIP_DETAILS_REQUEST",
  GET_INVENTORY_OWNER_SHIP_DETAILS_SUCCESS: "GET_INVENTORY_OWNER_SHIP_DETAILS_SUCCESS",
  GET_INVENTORY_OWNER_SHIP_DETAILS_FAIL: "GET_INVENTORY_OWNER_SHIP_DETAILS_FAIL",
  UPDATE_INVENTORY_REQUEST: "UPDATE_INVENTORY_REQUEST",
  UPDATE_INVENTORY_SUCCESS: "UPDATE_INVENTORY_SUCCESS",
  UPDATE_INVENTORY_INPUT_ERROR: "UPDATE_INVENTORY_INPUT_ERROR",
  UPDATE_INVENTORY_FAIL: "UPDATE_INVENTORY_FAIL",
  UPDATE_INVENTORY_RESET: "UPDATE_INVENTORY_RESET",
  INVENTORY_STATUS_SUCCESS: "INVENTORY_STATUS_SUCCESS",
  INVENTORY_STATUS_FAIL: "INVENTORY_STATUS_FAIL",
  INVENTORY_STATUS_RESET: "INVENTORY_STATUS_RESET",
  DELETE_INVENTORY_SUCCESS: "DELETE_INVENTORY_SUCCESS",
  DELETE_INVENTORY_FAIL: "DELETE_INVENTORY_FAIL",
  DELETE_INVENTORY_RESET: "DELETE_INVENTORY_RESET",
  INVENTORY_EXPORT_FAIL: "INVENTORY_EXPORT_FAIL",
  RESET_INVENTORY_BRAND: "RESET_INVENTORY_BRAND",
  CREATE_INVENTORY_BRAND_SUCCESS: "CREATE_INVENTORY_BRAND_SUCCESS",
  CREATE_INVENTORY_BRAND_FAIL: "CREATE_INVENTORY_BRAND_FAIL",
  CREATE_INVENTORY_BRAND_INPUT_ERROR: "CREATE_INVENTORY_BRAND_INPUT_ERROR",
  CREATE_INVENTORY_BRAND_RESET: "CREATE_INVENTORY_BRAND_RESET",
  IMPORT_INVENTORY_SUCCESS: "IMPORT_INVENTORY_SUCCESS",
  IMPORT_INVENTORY_FAIL: "IMPORT_INVENTORY_FAIL",
  IMPORT_INVENTORY_RESET: "IMPORT_INVENTORY_RESET",
};

const getInventoryList = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.GET_INVENTORY_LIST_REQUEST });
    try {
      const data = JSON.stringify(searchData);

      var config = {
        method: "post",
        url: apiUrl + "/inventories/search",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.GET_INVENTORY_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: Inventory.GET_INVENTORY_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: Inventory.GET_INVENTORY_LIST_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.GET_INVENTORY_LIST_FAIL, payload: error.message });
      }
    }
  };
};

const getInventoryByInventoryType = (id) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/inventories/types/" + id,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_FAIL, payload: response.data.errorMessages });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = [{ id: null, displayName: null }];
            dispatch({ type: Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_SUCCESS, payload: emptyData });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: Inventory.GET_INVENTORY_BY_INVENTORY_TYPE_FAIL,
          payload: error,
        });
      }
    }
  };
};

const getBrandList = (searchData, id) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.GET_INVENTORY_BRAND_REQUEST });
    try {
      const data = JSON.stringify(searchData);

      var config = {
        method: "post",
        url: apiUrl + "/brands/filter/" + id,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.GET_INVENTORY_BRAND_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_BRAND_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: Inventory.GET_INVENTORY_BRAND_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.GET_INVENTORY_BRAND_FAIL, payload: error.message });
      }
    }
  };
};

const resetBrandList = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.RESET_INVENTORY_BRAND, payload: {} });
  };
};

const getInventoryAttributes = (typeId) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.GET_INVENTORY_ATTRIBUTES_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/inventories/attributes/" + typeId,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.GET_INVENTORY_ATTRIBUTES_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_ATTRIBUTES_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: Inventory.GET_INVENTORY_ATTRIBUTES_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.GET_INVENTORY_ATTRIBUTES_FAIL, payload: error.message });
      }
    }
  };
};

const createInventory = (createInventoryData) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createInventoryData);

      var config = {
        method: "post",
        url: apiUrl + "/inventories",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: Inventory.CREATE_INVENTORY_SUCCESS, payload: response.data.Message });
          } else {
            dispatch({ type: Inventory.CREATE_INVENTORY_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: Inventory.CREATE_INVENTORY_INPUT_ERROR, payload: error.response.data.validationMessages });
          } else {
            dispatch({ type: Inventory.CREATE_INVENTORY_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.CREATE_INVENTORY_FAIL, payload: error.message });
      }
    }
  };
};

const resetCreateInventory = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.CREATE_INVENTORY_RESET, payload: {} });
  };
};

const getInventoryDetails = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.GET_INVENTORY_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/inventories/" + resourceId,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.GET_INVENTORY_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_DETAILS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = [];
            dispatch({ type: Inventory.GET_INVENTORY_DETAILS_SUCCESS, payload: emptyData });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_DETAILS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.GET_INVENTORY_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};
const getInventoryOwnerShipDetails = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/assigments/inventory/" + resourceId,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = [];
            dispatch({ type: Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_SUCCESS, payload: emptyData });
          } else {
            dispatch({ type: Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.GET_INVENTORY_OWNER_SHIP_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};

const updateInventory = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: Inventory.UPDATE_INVENTORY_REQUEST });
    try {
      const resourceId = updateData.resourceId;
      const data = JSON.stringify(updateData.data);

      var config = {
        method: "put",
        url: apiUrl + "/inventories/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.UPDATE_INVENTORY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: Inventory.UPDATE_INVENTORY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: Inventory.UPDATE_INVENTORY_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: Inventory.UPDATE_INVENTORY_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.UPDATE_INVENTORY_FAIL, payload: error.message });
      }
    }
  };
};

const resetUpdateInventory = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.UPDATE_INVENTORY_RESET, payload: {} });
  };
};

const updateStatusInventory = (status, resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/inventories/" + status + "/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.INVENTORY_STATUS_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: Inventory.INVENTORY_STATUS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: Inventory.INVENTORY_STATUS_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: Inventory.INVENTORY_STATUS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.INVENTORY_STATUS_FAIL, payload: error.message });
      }
    }
  };
};

const resetInventoryStatus = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.INVENTORY_STATUS_RESET, payload: {} });
  };
};

const deleteInventory = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/inventories/" + resourceId,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.DELETE_INVENTORY_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: Inventory.DELETE_INVENTORY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: Inventory.DELETE_INVENTORY_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: Inventory.DELETE_INVENTORY_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.DELETE_INVENTORY_FAIL, payload: error.message });
      }
    }
  };
};
const deleteInventoryReset = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.DELETE_INVENTORY_RESET, payload: {} });
  };
};

const inventoryListExport = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);

      var config = {
        method: "post",
        url: apiUrl + "/inventories/export",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob", // Important
      };

      hrApi(config)
        .then(function (response, typeId) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };

            FileDownload(response.data, "inventory_" + newDate + "." + exportTypes[props.exportType]);
          } else {
            dispatch({ type: Inventory.INVENTORY_EXPORT_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: Inventory.INVENTORY_EXPORT_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.INVENTORY_EXPORT_FAIL, payload: error.message });
      }
    }
  };
};
const setInventoryAddDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_ADD_INVENTORY_DRAWER",
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const setInventoryDetailsDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_INVENTORY_DETAILS_DRAWER",
      payload: status,
    });
  } catch (e) {
    console.log(e);
  }
};
const createInventoryBrand = (props) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);
      var config = {
        method: "post",
        url: apiUrl + "/inventoryTypes/CreateBrand",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.CREATE_INVENTORY_BRAND_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: Inventory.CREATE_INVENTORY_BRAND_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({
              type: Inventory.CREATE_INVENTORY_BRAND_INPUT_ERROR,
              payload: error.response.data.validationMessages,
            });
          } else {
            dispatch({ type: Inventory.CREATE_INVENTORY_BRAND_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.CREATE_INVENTORY_BRAND_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateInventoryBrand = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.CREATE_INVENTORY_BRAND_RESET, payload: {} });
  };
};
const importInventory = (data) => {
  const formData = new FormData();
  formData.append('formFile', data);
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/inventories/import",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: Inventory.IMPORT_INVENTORY_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: Inventory.IMPORT_INVENTORY_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: Inventory.IMPORT_INVENTORY_FAIL, payload: error.response.data });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: Inventory.IMPORT_INVENTORY_FAIL, payload: error.message });
      }
    }
  };
};
const resetImportInventory = () => {
  return async (dispatch) => {
    dispatch({ type: Inventory.IMPORT_INVENTORY_RESET, payload: {} });
  };
};
export {
  Inventory,
  getInventoryList,
  getInventoryByInventoryType,
  setInventoryAddDrawer,
  setInventoryDetailsDrawer,
  getBrandList,
  getInventoryAttributes,
  createInventory,
  resetCreateInventory,
  getInventoryDetails,
  getInventoryOwnerShipDetails,
  updateInventory,
  resetUpdateInventory,
  updateStatusInventory,
  resetInventoryStatus,
  deleteInventory,
  deleteInventoryReset,
  inventoryListExport,
  resetBrandList,
  createInventoryBrand,
  resetCreateInventoryBrand,
  importInventory,
  resetImportInventory,
};
