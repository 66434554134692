import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Button, Form, Input, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons'
import { noData } from '../../utils/commonFormFunctions';

import { getTagList, createTagName, resetCreateTagName, updateTagName, resetUpdateTagName, deleteTagName, resetDeleteTagName, getPersonsTagConnect, setTagConnectDraw } from '../../redux/actions/tag.actions';

import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';

function TicketListTab(props) {
    const { totalNumberOfRecords, tagSearchData, pageNumber, getTagList, updateTagNameResponse, setTagConnectDraw,
        updateTagName, resetUpdateTagName, tagList, tagListLoading, createTagName, tagCreateResponse, getPersonsTagConnect,
        resetCreateTagName, deleteTagName, resetDeleteTagName, deleteTagNameResponse, tagConnectSearchData, permissions
    } = props;
    const { t } = useTranslation();
    const [searchData, setSearchData] = useState(tagSearchData)


    const [addModalState, setAddModalState] = useState({ 'isVisible': false })
    const [addModalFormData, setAddModalFormData] = useState({})
    const [addForm] = Form.useForm();

    const [editModalState, setEditModalState] = useState({ 'isVisible': false })
    const [editModalFormData, setEditModalFormData] = useState({})
    const [editForm] = Form.useForm();

    const [deleteModalState, setDeleteModalState] = useState({ 'isVisible': false })

    //Table
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.page, 'pageSize': data.pageSize }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const customTableChange = (page, pageSize) => {
        handleTableChange({ "page": page, "pageSize": pageSize })
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        current: pageNumber,
        pageSize: searchData.pagination.pageSize,
        locale: { items_per_page: "/ " + t('PAGE') },
        onChange: (a, b) => customTableChange(a, b),
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ''
    })

    useEffect(() => {
        if (searchData !== null) {
            getTagList(searchData);
        }
    }, [searchData])

    //Table

    const addTag = () => {
        setAddModalState({ addModalState, 'isVisible': true })
    }
    const handleValuesAddChange = (changedObj, all) => {
        setAddModalFormData({ ...addModalFormData, ...changedObj });
    }
    const addModalForm = (
        <div className="table-details pad-0">
            <Form
                form={addForm}
                onValuesChange={handleValuesAddChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_TAG_NAME')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
    const handleAddCancel = () => {
        setAddModalFormData({});
        setAddModalState({ "isVisible": false })
        const fields = addForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        addForm.setFieldsValue(projects)
    }

    const [isCreateLoading, setisCreateLoading] = useState(false);

    const handleAddConfirm = () => {

        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;

        let hasError = false
        let fields = []
        if (!addModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_JOB_NAME'))
        }

        if (!hasError) {
            const data = {
                "name": addModalFormData.name,
            }
            createTagName(data)
            setAddModalState({ "isVisible": false })
            setAddModalFormData({});
            const fields = addForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            addForm.setFieldsValue(projects)

        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    //Job List Add

    useEffect(() => {
        if (tagCreateResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': tagCreateResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTagList(tagSearchData)
            resetCreateTagName();

        } else if (tagCreateResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': tagCreateResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateTagName();
        }
    }, [tagCreateResponse])

    //Job List Edit

    useEffect(() => {
        if (updateTagNameResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': updateTagNameResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTagList(tagSearchData)
            resetUpdateTagName();

        } else if (updateTagNameResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateTagNameResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateTagName();
        }
    }, [updateTagNameResponse])

    const editTicketList = (id, name) => {
        setEditModalState({ ...editModalState, 'isVisible': true, 'id': id })
        setEditModalFormData({ ...editModalFormData, 'name': name })
        const setObj = {
            "name": name
        }
        editForm.setFieldsValue(setObj)
    }
    const handleValuesEditChange = (changedObj, all) => {
        setEditModalFormData({ ...addModalFormData, ...changedObj });
    }
    const editModalForm = (
        <div className="table-details pad-0">
            <Form
                form={editForm}
                onValuesChange={handleValuesEditChange}
                layout="vertical"
            >
                <div className="form-left-body max-w-478">
                    <Form.Item name="name" label={t('PARTNER_TAG_NAME')}>
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
    const handleEditConfirm = () => {
        let hasError = false
        let fields = []
        if (!editModalFormData.name) {
            hasError = true
            fields.push(t('PARTNER_TAG_NAME'))
        }
        if (!hasError) {
            const data = {
                "name": editModalFormData.name,
            }
            updateTagName(data, editModalState.id)
            setEditModalState({ "isVisible": false })
            setEditModalFormData({});
            const fields = editForm.getFieldsValue()
            const projects = { ...fields };
            projects["name"] = "";
            editForm.setFieldsValue(projects)
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleEditCancel = () => {
        setEditModalFormData({});
        setEditModalState({ "isVisible": false })
        const fields = editForm.getFieldsValue()
        const projects = { ...fields };
        projects["name"] = "";
        editForm.setFieldsValue(projects)
    }
    //Job List Delete
    useEffect(() => {
        if (deleteTagNameResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': deleteTagNameResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTagList(searchData)
            resetDeleteTagName();

        } else if (deleteTagNameResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': deleteTagNameResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetDeleteTagName();
        }
    }, [deleteTagNameResponse])
    const deleteTicketModal = (id) => {
        setDeleteModalState({ ...deleteModalState, 'isVisible': true, 'id': id })
    }

    //Job list person
    const showConnectedTagDrawer = (id) => {
        getPersonsTagConnect(tagConnectSearchData, id);
        setTagConnectDraw({ "status": true, "id": id });
    }
    return (
        <div className="custom-table-card-container job-list-container">
            <DynamicRequestModal
                modalVisible={addModalState.isVisible}
                setStateData={setAddModalState}
                isForm={addModalForm}
                handleConfirm={handleAddConfirm}
                handleCancel={handleAddCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('ADD_NEW_TAG')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('SETTINGS_CREATE')}
            />
            <DynamicRequestModal
                modalVisible={editModalState.isVisible}
                setStateData={setEditModalState}
                isForm={editModalForm}
                handleConfirm={handleEditConfirm}
                handleCancel={handleEditCancel}
                postAction={''}
                confirmData={''}
                iconName=''
                modalTitle={t('PARTNER_EDIT')}
                description={''}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('PARTNER_SAVE')}
            />
            <DynamicRequestModal
                modalVisible={deleteModalState.isVisible}
                setStateData={setDeleteModalState}
                postAction={deleteTagName}
                confirmData={deleteModalState.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_LABEL_DESCRIPTION')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <div className="custom-table-card-add">
                <Button onClick={() => addTag()} className="primary-btn m-w-180 h-32"><PlusOutlined /> {t('ADD_NEW_TAG')}</Button>
            </div>
            <div className="custom-table-card-class">
                {totalNumberOfRecords > 0 ?
                    <div className="custom-table-card-title-class job-list-column">
                        <Col span={24}>
                            <Row>
                                <Col span={6}>
                                    <h1>{t('PARTNER_TAG_NAME')}</h1>
                                </Col>


                            </Row>
                        </Col>
                    </div>
                    : ''}
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={totalNumberOfRecords > 10 ? paginationConfig() : null}
                    dataSource={tagList}
                    loading={tagListLoading}
                    className={totalNumberOfRecords === 0 ? 'table-border-bottom-0' : 'custom-table-list'}
                    locale={{ emptyText: (<div className="loading-container">{noData(t)}</div>) }}
                    renderItem={data => (
                        <List.Item
                            key={data?.resourceId + 'fs'}
                            className="job-list-item"
                        >
                            <Card key={data.resourceId} className="custom-table-card job-list-card" >
                                <Col span={24}>
                                    <Row>
                                        <Col span={6}>
                                            <p>{data.name}</p>
                                        </Col>
                                        <Col span={10} />
                                        <Col span={6}>
                                            {data.personCount > 0 ? <a onClick={() => showConnectedTagDrawer(data.resourceId)} className="details-text">{data.personCount + ' ' + t('DEBIT_PERSON')}</a> : <p className="details-empty-text">{t('NONE')}</p>}
                                        </Col>
                                        <Col span={1}>
                                            <div onClick={() => editTicketList(data.resourceId, data.name)} className="custom-table-card-icon flex-end clickable">
                                                <UilEditAlt />
                                            </div>
                                        </Col>
                                        <Col span={1}>
                                            <div onClick={() => deleteTicketModal(data.resourceId)} className="custom-table-card-icon flex-end clickable">
                                                <UilTrash />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        tagList: state.tag.tagList.results,
        totalNumberOfRecords: state.tag.tagList.totalNumberOfRecords,
        pageNumber: state.tag.tagList.pageNumber,
        tagListLoading: state.tag.tagListLoading,
        tagCreateResponse: state.tag.tagCreateResponse,
        updateTagNameResponse: state.tag.updateTagNameResponse,
        deleteTagNameResponse: state.tag.deleteTagNameResponse,
        permissions: state.profile.permissions,

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        createTagName: (data) => { dispatch(createTagName(data)) },
        resetCreateTagName: () => { dispatch(resetCreateTagName()) },
        getTagList: (searchData) => { dispatch(getTagList(searchData)) },
        updateTagName: (data, id) => { dispatch(updateTagName(data, id)) },
        resetUpdateTagName: () => { dispatch(resetUpdateTagName()) },
        deleteTagName: (id) => { dispatch(deleteTagName(id)) },
        resetDeleteTagName: () => { dispatch(resetDeleteTagName()) },
        getPersonsTagConnect: (searchData, resourceId) => { dispatch(getPersonsTagConnect(searchData, resourceId)) },
        setTagConnectDraw: (props) => { dispatch(setTagConnectDraw(props)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketListTab);