import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, Col, Row, Button, Steps, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import CreateOwnerShipBasicInfo from './ownership-basic-info';
import CreateOwnerShipAddressInfo from './ownership-address-info';
import { setCreateOwnerShipDrawer } from '../../../redux/actions/person-detail-tab-action'; // Drawer Statusler
import { createOwnerShip, createOwnerShipResponseReset } from '../../../redux/actions/ownership.actions';
import { ownerShipDetailsDrawTable } from '../../../redux/actions/person.actions';
import { dateFormat } from '../../../utils/config';
import moment from 'moment';
import '../../../styles/table.scss';
import Successful from "../../successful";

import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';


function OwnerShipAddTabs(props) {

    const { setCreateOwnerShipDrawer, organizationId, createOwnerShip, ownerShipDetailsDrawTable,
        ownershipCreateResponse, isOwnerShipPage, selectedPersonId, createOwnerShipResponseReset, ownerShipData } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [createOwnerShipObj, setCreateOwnerShipObj] = useState({});
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })
    const { Step } = Steps;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    let currentDate = new Date();

    const ownerShipInitialData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "Name",
            "ascending": true
        },
        "inventoryTypes": [],
        "status": true,
    }

    function callback(key) {
        checkRequiredFields(() => setCurrentTab(parseInt(key)));
    }
    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function nextButton() {
        checkRequiredFields(() => setCurrentTab(currentTab + 1));
    }
    function closeDrawerControl() {
        const defaultDate = moment(currentDate).format(dateFormat)
        const changeDate = createOwnerShipObj.assigmentDate ? moment(createOwnerShipObj.assigmentDate).format(dateFormat) : createOwnerShipObj.assigmentDate
        if (currentTab !== 3 && ((createOwnerShipObj.inventory && createOwnerShipObj.inventory.id) || (createOwnerShipObj.inventoryType && createOwnerShipObj.inventoryType.id) || defaultDate !== changeDate)) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal()
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setCreateOwnerShipDrawer(false)
        setCreateOwnerShipObj({});
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateOwnerShipObj({});
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createOwnerShipObj, obj)
        setCreateOwnerShipObj(mergedObj)
        if (mergedObj?.assigmentPerson?.id) {
            ownerShipDetailsDrawTable(createOwnerShipObj?.assigmentPerson === undefined ? selectedPersonId : createOwnerShipObj.assigmentPerson?.id, ownerShipInitialData);
        }
    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []
        if (!createOwnerShipObj.inventoryType || createOwnerShipObj.inventoryType === "") {
            hasError = true
            fields.push(t('DEBIT_INVENTORY_TYPE'))
        }
        if (!createOwnerShipObj.inventory || createOwnerShipObj.inventory?.id === "" || createOwnerShipObj.inventory?.id === null) {
            hasError = true
            fields.push(t('DEBIT_INVENTORY'))
        }
        if (selectedPersonId) {
            if (!selectedPersonId || selectedPersonId === "") {
                hasError = true
                fields.push(t('DEBIT_TO_PERSON'))
            }
        } else {
            if (!createOwnerShipObj.assigmentPerson || createOwnerShipObj.assigmentPerson === "") {
                hasError = true
                fields.push(t('DEBIT_TO_PERSON'))
            }
        }



        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [isCreateOwnerShipLoading, setisCreateOwnerShipLoading] = useState(false);

    const handleCreateOwnerShip = () => {

        if(isCreateOwnerShipLoading) return;
        setisCreateOwnerShipLoading(true);
        setTimeout(() => {setisCreateOwnerShipLoading(false);}, 1055) // double click prevent;

        const data = {
            "inventoryId": createOwnerShipObj.inventory?.id || null,
            "assigmentPersonResourceId": createOwnerShipObj?.assigmentPerson === undefined ? selectedPersonId : createOwnerShipObj.assigmentPerson?.id,
            "assigmentDate": createOwnerShipObj?.assigmentDate && createOwnerShipObj?.assigmentDate !== 'Invalid date' ? moment(createOwnerShipObj?.assigmentDate).format(dateFormat) : null,
            //"assigmentDate": current.format(dateFormat),
            "status": 1,
            "assigmentDocumentId": null,
            "assigmentDescription": createOwnerShipObj.assigmentDescription,
            "accessoryInventoryIds": []
        }

        checkRequiredFields(() => createOwnerShip(data))
    }

    useEffect(() => {
        if (ownershipCreateResponse.status === 'SUCCESS') {
            setCurrentTab(currentTab + 1);
            createOwnerShipResponseReset()

        } else if (ownershipCreateResponse.status === 'FAIL') {
            setCurrentTab(2);
            createOwnerShipResponseReset()
            DynamicToastMessage({
                'key': 'ownership_Error',
                'title': t('ERROR'),
                'description': ownershipCreateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
        }

    }, [ownershipCreateResponse])


    useEffect(() => {
        ownerShipDetailsDrawTable(createOwnerShipObj?.assigmentPerson === undefined ? selectedPersonId : createOwnerShipObj.assigmentPerson?.id, ownerShipInitialData);
    }, [])
    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {currentTab !== 3 ?
                    <React.Fragment>
                        <Col offset={6} span={16}>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('DEBIT_CREATE_NEW')}</h1>
                            </div>
                        </Col>
                        <div className="w-100">
                            <Row>
                                <Col span={6}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('PARTNER_BASIC_INFORMATION')} description="" />
                                        <Step title={t('PARTNER_DETAILS_INFORMATION')} />
                                        <Step title={t('PARTNER_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={17}>

                                    <Tabs
                                        className="custom-tab add-user-draw"
                                        onChange={callback}
                                        activeKey={currentTab.toString()}
                                        defaultActiveKey={currentTab.toString()}
                                    >
                                        <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key={1}>
                                            <CreateOwnerShipBasicInfo filterService={props.filterService} currentDate={currentDate} ownerShipData={ownerShipData} isOwnerShipPage={isOwnerShipPage} organizationId={organizationId} setCreateObject={(o) => setCreateObject(o)} />
                                        </TabPane>
                                        <TabPane tab={t('PARTNER_DETAILS_INFORMATION')} key={2}>
                                            <CreateOwnerShipAddressInfo createOwnerShipObj={createOwnerShipObj} setCreateObject={(o) => setCreateObject(o)} />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                    :
                    <Successful message={t('DEBIT_SUCCESS')} />
                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 3 ?
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                {/* clear state */}
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 3 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 3 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('DEBIT_CREATE_NEW')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 3 ?
                        currentTab === 2 ?
                            <Button onClick={() => handleCreateOwnerShip()} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('DEBIT_CREATE_NEW')}</Button>
                            :
                            <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_NEXT')}</Button>
                        :

                        <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        //clear state
                    }
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        ownershipCreateResponse: state.ownership.ownershipCreateResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCreateOwnerShipDrawer: (props) => { dispatch(setCreateOwnerShipDrawer(props)) },
        createOwnerShip: (createData) => { dispatch(createOwnerShip(createData)) },
        ownerShipDetailsDrawTable: (selectedPersonId, searchData) => { dispatch(ownerShipDetailsDrawTable(selectedPersonId, searchData)) },
        createOwnerShipResponseReset: () => { dispatch(createOwnerShipResponseReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OwnerShipAddTabs);
