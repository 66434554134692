import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPersons } from "../../../../redux/actions/organization.actions";
import { getDataset } from "../../../../redux/actions/dataset-action";

import { Input, ConfigProvider, Row, Col, Button, List, Checkbox } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { UilDraggabledots, UilTrashAlt } from '@iconscout/react-unicons'

import DynamicToastMessage from "../../../dynamicToastMessage";
import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/person.scss';

function CreateApprovalProcessesBasicStepForm(props) {
    const { setCreateApprovalProcessesObject, processType, getDataset,
        createApprovalProcessesObject, getPersons,
        persons, profile, processPersonType } = props;
    const { t } = useTranslation();
    const [approvalFormData, setApprovalFormData] = useState({
        'ProcessName': null,
        'ProcessType': null
    })
    const [approvalListState, setApprovalListState] = useState([
        {
            'id': null,
            'displayValue': null,
            "secondApproval": { "id": null, "displayValue": "" }
        }
    ]);
    const [processTypeState, setProcessTypeState] = useState([]);
    const [processPersonTypeState, setProcessPersonTypeState] = useState([]);

    useEffect(() => {
        setCreateApprovalProcessesObject({ ...createApprovalProcessesObject, "approvalListState": approvalListState })
    }, [approvalListState]);
    useEffect(() => {
        setCreateApprovalProcessesObject({ ...createApprovalProcessesObject, "IsDefault": approvalFormData?.IsDefault, "ProcessName": approvalFormData?.ProcessName, "ProcessType": approvalFormData?.ProcessType?.id })
    }, [approvalFormData])
    useEffect(() => {
        getDataset(38);
        getPersons(profile.organization?.resourceId);
    }, []);
    useEffect(() => {
        var arr = [];
        processType.length > 0 &&
            processType.map(data =>
                arr.push({
                    'id': parseInt(data.id),
                    'displayValue': data.displayValue
                }))
        setProcessTypeState(arr);
    }, [processType])
    useEffect(() => {
        var arr = [];
        processPersonType.length > 0 &&
            processPersonType.map(data =>
                arr.push({
                    'id': parseInt(data.id),
                    'displayValue': data.displayValue
                }))
        setProcessPersonTypeState(arr);
    }, [processPersonType])

    const DragHandle = SortableHandle(({ style }) => (
        <span style={style} className="sortable-list-icon"><UilDraggabledots /></span>)
    );
    const SortableItem = SortableElement(({ value }) => {
        return <div className="sortable-list-item">
            <div className="processes-create-form-item">
                <label >{t('PROCESSES_APPROVE', { 'n': value.index + 1 })}  {approvalListState.length !== 1 && <span className="sortable-list-delete" onClick={() => deleteApprovalProcesses(value.index)}><UilTrashAlt /></span>}</label>
            </div>
            <Row>
                <Col span={1}>
                    <DragHandle />
                </Col>
                <Col span={23}>
                    <div className="processes-create-form-item" >
                        <div className="approval-processes-select" >
                            <Row>
                                <Col span={value.data.id === 6 ? 12 : 24}>
                                    <div className={value.data.id === 6 ? "pr-3" : ""}>
                                        <DynamicSelectbox
                                            key={value.index + 100}
                                            onChange={(e) => handleApprovalProcessesChange(e, value.index, 'approval')}
                                            optionList={processPersonTypeState}
                                            placeholder={t('PARTNER_SELECT')}
                                            orderBy={'none'}
                                            value={{ 'id': value.data.id, 'displayValue': value.data.displayValue }}
                                        />
                                    </div>
                                </Col>
                                {value.data.id === 6 &&
                                    <Col span={12}>
                                        <div className="pl-3">
                                            <DynamicSelectbox
                                                key={value.index + 100}
                                                onChange={(e) => handleApprovalProcessesChange(e, value.index, 'secondApproval')}
                                                optionList={persons}
                                                placeholder={t('PARTNER_SELECT')}
                                                orderBy={'none'}
                                                value={{ 'id': value.data?.secondApproval?.id, 'displayValue': value.data?.secondApproval?.displayValue }}
                                            />
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    });
    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul className="sortable-list-container">
                {items.length > 0 && items.map((value, index) => (
                    <SortableItem key={`item-${value.id}}`} index={index} value={{ 'data': value, 'index': index }} />
                ))}
            </ul>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setApprovalListState(items => arrayMoveImmutable(items, oldIndex, newIndex));
    };
    const addApprovalProcesses = () => {
        const list = [...approvalListState];
        let hasError = false;
        let fields = [];
        var requiredFields = {
            "processes": false,
            'processesOther': false
        };
        list.map(data => {
            if (!data.id && data.id !== 6) {
                requiredFields.processes = true;
            }
            if (data.id === 6 && !data.secondApproval.id) {
                requiredFields.processesOther = true;
            }
        });
        if (requiredFields.processes) {
            hasError = true;
            fields.push(t('PROCESSES_APPROVE_PROCESSES'));
        }
        if (requiredFields.processesOther) {
            hasError = true;
            fields.push(t('PERSON_CHOOSE_USER'));
        }
        if (!hasError) {
            requiredFields = {
                "processes": false,
                'processesOther': false
            };
            list.push(
                {
                    'id': null,
                    'displayValue': null,
                    "secondApproval": { "id": null, "displayValue": "" }
                }
            );
            setApprovalListState(list);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const deleteApprovalProcesses = (index) => {
        const list = [...approvalListState]
        list.splice(index, 1);
        setApprovalListState(list);
    }
    const handleApprovalProcessesChange = (e, index, type) => {
        const list = [...approvalListState];
        if (type === "approval") {
            list[index]["id"] = e.id;
            list[index]["displayValue"] = e.displayValue;
            if (list[index]["secondApproval"].id) {
                list[index]["secondApproval"] = { "id": null, "displayValue": "" }
            }
        } else {
            list[index]["secondApproval"] = { 'id': e.id, 'displayValue': e.displayValue };
        }
        setApprovalListState(list);
    }
    const processesChangeStaticInput = (type, e) => {
        if (type === 'checkbox') {
            setApprovalFormData({ ...approvalFormData, 'IsDefault': e.target.checked });
        } else if (type === 'input') {
            setApprovalFormData({ ...approvalFormData, 'ProcessName': e.target.value });
        } else {
            setApprovalFormData({ ...approvalFormData, 'ProcessType': e });
        }
    }
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <div className="ant-form processes-create-form">
                <div className="ant-item mb-2 my-0 add-user-department-checkbox-all">
                    <Checkbox onChange={(e) => processesChangeStaticInput('checkbox', e)}>{t('SETTINGS_DEFAULT_PROCESS')}</Checkbox>
                </div>
                <div className="processes-create-form-item">
                    <label className="label-border-under">{t('NAME_YOUR_PROCESS') + " *"} </label>
                    <Input value={approvalFormData.ProcessName} onChange={(e) => processesChangeStaticInput('input', e)} />
                </div>
                <div className="processes-create-form-item" >
                    <label className="label-border-under">{t('PROCESSES_TYPE') + " *"}</label>
                    <div className="approval-processes-select">
                        <DynamicSelectbox
                            value={approvalFormData.ProcessType}
                            onChange={(e) => processesChangeStaticInput('select', e)}
                            optionList={processTypeState}
                            onFocus={() => getDataset(37)}
                            placeholder={t('PARTNER_SELECT')}
                            orderBy={'none'}
                        />
                    </div>
                </div>
                <div className="processes-create-form-item pb-4" >
                    <label className="label-border-under">{t('PROCESSES_APPROVE_PROCESSES')}</label>
                </div>
                <SortableList
                    items={approvalListState}
                    onSortEnd={onSortEnd}
                    axis="y"
                    helperClass="sortable-list-tab"
                    lockAxis="y"
                    distance={10}
                />
                <Button className="transparent-btn pad-0 f-14" onClick={() => addApprovalProcesses()}>{t('PROCESSES_NEW_STEP')}</Button>
            </div>
        </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        processType: state.dataset.processType,
        processPersonType: state.dataset.processPersonType,
        persons: state.organization.persons,
        profile: state.profile
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (type) => { dispatch(getDataset(type)) },
        getPersons: (id) => { dispatch(getPersons(id)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateApprovalProcessesBasicStepForm);