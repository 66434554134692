import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, Col, Row, Button, Steps, Spin, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'

import { dateFormatWithHours, dateFormat } from '../../../../utils/config';
import { resetScrollPosition } from "../../../../utils/commonFormFunctions";
import { setTimeOffRequestApproveDrawer } from '../../../../redux/actions/time-off-drawer-action'
import {
    updateTimeOffRequest, timeOffApprove, timeOffReject, timeOffCancel, resetTimeOffRequestDetails, resetWorkHourByDate,
    resetTimeOffApproveResponseStatus, timeOffRequestUpdateReset, getTimeOffRequest, resetTimeOffRequestInfo, getTimeOffRequestStep
} from '../../../../redux/actions/timeOff.actions';
import TimeOffWaitingApprove from './timeOffWaitingApprove';
import DynamicToastMessage from '../../../dynamicToastMessage/index';
import Can from "../../../../authorization/index";

import moment from 'moment';
import 'moment/locale/tr';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/left-step.scss';
import '../../../../styles/custom-step-drawer.scss';


function TimeOffAproveTabs(props) {
    const [status, setStatus] = useState(false);
    const { Step } = Steps;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    //data
    const { timeOffRequestDetails, timeOffRequestStep, timeOffRequestStepEditableStatus, timeOffRequestStepCanUpdatableStatus,
        resetWorkHourByDate, permissions, timeOffRequestDetailsIsLoading, timeOffApproveUpdateStatus, selectedData, page,
        timeOffApproveResponse, setCancelModalData, cancelModalData } = props;
    //method
    const { updateTimeOffRequest, setTimeOffRequestApproveDrawer, handleTimeOffChangeStatus, resetTimeOffRequestDetails,
        timeOffRequestUpdateReset, getTimeOffRequest, resetTimeOffRequestInfo, getTimeOffRequestStep } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const [timeOffApproveUpdateObj, setTimeOffApproveUpdateObj] = useState({})
    const [timeOffApproveRef, setTimeOffApproveRef] = useState("{}")
    const [declineButtonRef, setDeclineButtonRef] = useState(false)
    const [waitingApproveState, setWaitingApproveState] = useState({});
    const [statusObjState, setStatusObjState] = useState({});

    useEffect(() => {
        setStatusObjState({
            "id": selectedData?.postId,
            "approveSeqNumber": selectedData?.approveSeqNumber,
            "fullName": timeOffRequestStep[0]?.name,
            "fromName": timeOffRequestStep.filter(filterData => filterData.isSelected === true)[0]?.name,
            "requestDate": selectedData?.requestDate,
            "timeOffTypeName": timeOffRequestDetails?.timeOffType?.displayValue,
            "personResourceId": timeOffRequestStep?.filter(filterData => filterData.isSelected === true)[0]?.authResourceId,
            "timeOffTypeId": timeOffRequestDetails?.timeOffType?.id,
            "timeOffInfoRemainderBalance": timeOffRequestDetails?.timeOffType?.id === 1 ? timeOffRequestDetails?.timeOffInfo?.remainderTimeOffBalance : null
        });
    }, [timeOffRequestStep, timeOffRequestDetails, selectedData])
    useEffect(() => {
        setCurrentTab(timeOffRequestStep.filter(filterData => filterData.isSelected === true)[0]?.sortOrder || timeOffRequestStep.length + 1)
    }, [timeOffRequestStep])

    useEffect(() => {
        setTimeOffApproveRef(JSON.stringify({ ...timeOffRequestDetails }))
    }, [timeOffRequestDetails])

    useEffect(() => {
        setTimeOffApproveUpdateObj(JSON.parse(timeOffApproveRef))
    }, [timeOffApproveRef])
    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            getTimeOffRequest(selectedData.resourceId);
            getTimeOffRequestStep(selectedData.resourceId);
        }
    }, [timeOffApproveResponse])
    useEffect(() => {
        if (timeOffApproveUpdateStatus.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'timeOffApproveUpdateStatus_success',
                'title': t('SUCCESS'),
                'description': timeOffApproveUpdateStatus.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            setStatus(false);
            getTimeOffRequest(selectedData.resourceId);
            getTimeOffRequestStep(selectedData.resourceId);
            timeOffRequestUpdateReset();
        } else if (timeOffApproveUpdateStatus.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'timeOffApproveUpdateStatus_fail',
                'title': t('ERROR'),
                'description': timeOffApproveUpdateStatus.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            timeOffRequestUpdateReset()

        } else if (timeOffApproveUpdateStatus.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'timeOffApproveUpdateStatus_validation_error',
                'title': t('ERROR'),
                'description': <List>{timeOffApproveUpdateStatus.message.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            timeOffRequestUpdateReset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeOffApproveUpdateStatus])

    const setTimeOffApproveUpdateObject = (object) => {
        setTimeOffApproveUpdateObj(object)
    }
    function callback(key) {
        setCurrentTab(parseInt(key));
    }
    function destroyLastState() {
        setCurrentTab(1);
        setTimeOffRequestApproveDrawer(false)
        resetTimeOffRequestDetails()
        setStatus(false)
        resetTimeOffRequestInfo();
        resetWorkHourByDate('startEnd');
        resetWorkHourByDate('start');
        resetWorkHourByDate('end');
    }
    const timeOffCancelBtn = () => {
        setCancelModalData({ ...cancelModalData, "isVisible": true, "id": selectedData.resourceId })
    }
    function cancel() {
        setDeclineButtonRef(true)
        setStatus(false);
        setTimeOffApproveUpdateObj({})
    }
    function edit() {
        setStatus(true)
        setDeclineButtonRef(false)
    }
    function saveChanges() {
        if (timeOffApproveUpdateObj.StartDate) {
            const StartDate = moment(timeOffApproveUpdateObj.StartDate).format(dateFormat);
            var startDateWithHours = moment(StartDate + ' ' + timeOffApproveUpdateObj.StartDateTime).format(dateFormatWithHours);
        }
        if (timeOffApproveUpdateObj.EndDate) {
            const EndDate = moment(timeOffApproveUpdateObj.EndDate).format(dateFormat);
            var endDateWithHours = moment(EndDate + ' ' + timeOffApproveUpdateObj.EndDateTime).format(dateFormatWithHours);
        }
        var contactPhones = timeOffApproveUpdateObj.contactPhones.length > 0 ? timeOffApproveUpdateObj.contactPhones.map((data) => {
            return data.displayValue;
        }) : [];
        const updateObj = {
            "timeOffTypeId": timeOffRequestDetails.timeOffType?.id,
            "personResourceId": timeOffRequestDetails.person?.resourceId,
            "StartDate": timeOffApproveUpdateObj.StartDate ? timeOffApproveUpdateObj.unitId === 3 ? startDateWithHours : moment(timeOffApproveUpdateObj.StartDate).format(dateFormat) : '',
            "StartDateChooseDayType": timeOffApproveUpdateObj.StartDateChooseDayType,
            "StartDayType": timeOffApproveUpdateObj.StartDayType,
            "endDate": timeOffApproveUpdateObj.EndDate ? timeOffApproveUpdateObj.unitId === 3 ? endDateWithHours : moment(timeOffApproveUpdateObj.EndDate).format(dateFormat) : '',
            "EndDateChooseDayType": timeOffApproveUpdateObj.EndDateChooseDayType,
            "EndDayType": timeOffApproveUpdateObj.EndDayType,
            "description": timeOffApproveUpdateObj.description,
            "deputizedPersonId": null,
            "groupEmailAddr": timeOffApproveUpdateObj.contactMail,
            "approvalDescription": null,
            "contactPhones": contactPhones,
            "Documents": timeOffApproveUpdateObj.Documents
        }
        let hasError = false
        let fields = []
        if (!timeOffApproveUpdateObj.StartDate || timeOffApproveUpdateObj.StartDate === "") {
            hasError = true
            fields.push(t('TIME_OFF_REQUEST_START_TIME'))
        }
        if (!timeOffApproveUpdateObj.EndDate || timeOffApproveUpdateObj.EndDate === "") {
            hasError = true
            fields.push(t('TIME_OFF_REQUEST_END_TIME'))
        }
        if (!hasError) {
            updateTimeOffRequest(updateObj, selectedData.resourceId)

            window.sendinblue?.track(
                //brevo_event_update_time_off_request
                'update_time_off_request',
            )

        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const GetButtonWithCurrentTabEdit = () => {
        var showMaxWidth = false;
        if (timeOffRequestStepEditableStatus === 1 && timeOffRequestStepCanUpdatableStatus || ((timeOffRequestStepEditableStatus === 1 || timeOffRequestStepEditableStatus === 3) && timeOffRequestDetails.stateType.id === 1)) {
            showMaxWidth = true;
        }
        if (!status && (timeOffRequestStepEditableStatus == 2 && timeOffRequestStep.length > 0 && timeOffRequestDetails.stateType.id === 1) || timeOffRequestStepCanUpdatableStatus) {
            // 2 == 1.izin onay - 1.izin onayi gecerse gozukmemeli

            if (currentTab > 2 && !timeOffRequestStepCanUpdatableStatus) {
                return (
                    <div className="w-100 flex-end">
                        <Can I="timeoff.request.cancel">
                            <Button onClick={() => timeOffCancelBtn()} style={{ position: 'relative', right: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('TIME_OFF_CANCEL_BTN')}</Button>
                        </Can>
                    </div>
                )
            } else {
                if (!status) {
                    return (
                        showMaxWidth ?

                            <div className="w-100 max-w-330 space-between" >

                                {timeOffRequestDetails.stateType.id !== 4 &&
                                    <Can I="timeoff.request.cancel">
                                        <Button onClick={() => timeOffCancelBtn()} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('TIME_OFF_CANCEL_BTN')}</Button>
                                    </Can>
                                }
                                <Can I="timeoff.request.update">
                                    <Button disabled={!waitingApproveState?.StartDate} onClick={() => edit()} className="w-120 h-40 transparent-btn transparent-import pad-0">{t('PARTNER_EDIT')}</Button>
                                </Can>
                            </div>
                            :
                            <div className={"w-100 space-between"} >
                                <Can I="timeoff.request.update">
                                    <Button disabled={!waitingApproveState?.StartDate} onClick={() => edit()} className="w-120 h-40 transparent-import transparent-btn pad-0">{t('PARTNER_EDIT')}</Button>
                                </Can>
                                {timeOffRequestDetails.stateType.id !== 4 &&
                                    <Can I="timeoff.request.cancel">
                                        <Button onClick={() => timeOffCancelBtn()} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('TIME_OFF_CANCEL_BTN')}</Button>
                                    </Can>
                                }
                            </div>
                    )
                } else {
                    return ''
                }
            }
        } else {
            return ('')
        }
    }
    const GetButtonWithCurrentTabSaveChanges = () => {
        if (!status) {
            if ((timeOffRequestStepEditableStatus === 3 || (timeOffRequestStepCanUpdatableStatus && timeOffRequestDetails?.stateType?.id === 1)) && currentTab < timeOffRequestStep.length + 1) {

                return (
                    <div className="w-100 flex-end pad-right-12">
                        <Button onClick={() => handleTimeOffChangeStatus(statusObjState, 'REJECT')} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_DECLINE')}</Button>
                        <div className="pad-left-12" />
                        <Button onClick={() => handleTimeOffChangeStatus(statusObjState, 'APPROVE')} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_APPROVE')}</Button>
                    </div>
                )
            } else if (timeOffRequestStepEditableStatus === 1 || timeOffRequestDetails.stateType.id === 3 || timeOffRequestDetails.stateType.id === 4) {
                return (
                    <div className="w-100 flex-end pad-right-12">
                        <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                    </div>
                )
            } else if (timeOffRequestStepEditableStatus === 2 && timeOffRequestDetails.stateType.id === 2) {
                return (
                    <div className="w-100 flex-end">
                        <Can I="timeoff.request.cancel">
                            <Button onClick={() => timeOffCancelBtn()} style={{ position: 'relative', right: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('TIME_OFF_CANCEL_BTN')}</Button>
                        </Can>
                        <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        <div className="pad-right-12" />
                    </div>
                )
            }
            else if (timeOffRequestStepEditableStatus === 1 && timeOffRequestDetails.stateType.id === 2) {
                return (
                    <div className="w-100 flex-end">
                        <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        <div className="pad-right-12" />
                    </div>
                )
            }
            else {
                return ('')
            }
            // 2 == 1.izin onay - 1.izin onayi gecerse gozukmemeli
        } else if (status && (timeOffRequestStepEditableStatus === 2 && currentTab < 3) || timeOffRequestStepCanUpdatableStatus) {
            return (
                <div className="w-100 space-between pad-right-24">
                    <Button onClick={() => cancel()} className=" w-120 h-40 f-14 font-w-700 transparent-btn">{t('INVENTORY_CANCEL')}</Button>
                    <Button onClick={() => saveChanges()} className=" w-180 h-40 f-14 font-w-700 primary-btn ">{t('PARTNER_SAVE_CHANGES')}</Button>
                </div>
            )
        } else {
            return ('')
        }
    }
    return (
        <div key={'detailStep' + timeOffRequestDetails ? timeOffRequestDetails?.resourceId : "12415"}>
            {timeOffRequestDetailsIsLoading ?
                <Spin className="flex-center pad-top-75 " />
                :
                <div>
                    <div className="custom-step-drawer hide-tabs">
                        <div className="w-100">
                            <Row>
                                <Col span={6}>
                                    {/* Step 0 dan saymaya baslamaktadir bundan dolayi -1 , secili olan data var ise sortOrder ile secili olan id gelir data bulamazsa -1 yapmadan lenghti aliriz buda tamamlandi stepine eşit olmaktadır */}
                                    <Steps className="left-step time-off-step pad-top-40" direction="vertical" size="middle" current={timeOffRequestStep.filter(filterData => filterData.isSelected === true)[0]?.sortOrder - 1 || timeOffRequestStep.length}>
                                        {timeOffRequestStep.map((data) => {
                                            return (
                                                <Step key={data.sortOrder} title={data.stepName}
                                                    description={

                                                        <ul className="sub-tab-hide-icon">
                                                            <li className={data.isSelected ? "sub-tab-active" : ''}>
                                                                {timeOffRequestStep.length > 0 ? data.name : ''} <br />
                                                                {timeOffRequestStep.length > 0 ? data?.date ? moment(data?.date).format('L') : '' : ''}
                                                            </li>
                                                        </ul>
                                                    } />
                                            )
                                        })}
                                    </Steps>
                                </Col>
                                <Col span={18}>
                                    <div className="custom-step-drawer-header pad-right-12">

                                        <div className="custom-step-drawer-title">
                                            <h1>{t('TIME_OFF_REQUEST_TITLE')}</h1>
                                        </div>
                                        <div className="custom-step-drawer-close-icon ">
                                            <UilMultiply onClick={() => destroyLastState()} />
                                        </div>
                                    </div>
                                    <Tabs className="custom-tab timeoff-draw-scrollable-tab table-details pad-right-12"
                                        onChange={callback}
                                        activeKey={currentTab.toString()}
                                        defaultActiveKey={currentTab.toString()}
                                    >
                                        {timeOffRequestStep.map((data) => {
                                            return (
                                                <TabPane key={data.sortOrder}>
                                                    <TimeOffWaitingApprove
                                                        waitingApproveState={waitingApproveState}
                                                        setWaitingApproveState={setWaitingApproveState}
                                                        status={status}
                                                        getUpdateObj={(o) => setTimeOffApproveUpdateObject(o)}
                                                        timeOffRequestDetails={timeOffRequestDetails}
                                                        declineButtonRef={declineButtonRef}
                                                        selectID={selectedData.resourceId}
                                                    />
                                                </TabPane>
                                            )
                                        })}
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="add-user-draw-footer">
                        {permissions.some(item => ["timeoff.request.cancel", "timeoff.request.update"].includes(item)) &&
                            <GetButtonWithCurrentTabEdit />
                        }
                        <GetButtonWithCurrentTabSaveChanges />
                    </div>
                </div >}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        timeOffRequestDetails: state.timeOff.timeOffRequestDetails,
        timeOffRequestStep: state.timeOff.timeOffRequestStep.timeOffApproveList,
        timeOffRequestStepEditableStatus: state.timeOff.timeOffRequestStep.editableStatus,
        timeOffRequestStepCanUpdatableStatus: state.timeOff.timeOffRequestStep.canUpdatable,
        timeOffRequestDetailsIsLoading: state.timeOff.timeOffRequestDetailsIsLoading,
        timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        timeOffCancelResponse: state.timeOff.timeOffCancelResponse,
        permissions: state.profile.permissions
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        updateTimeOffRequest: (updateObj, resourceId) => { dispatch(updateTimeOffRequest(updateObj, resourceId)) },
        timeOffApprove: (approveData) => { dispatch(timeOffApprove(approveData)) },
        timeOffReject: (approveData) => { dispatch(timeOffReject(approveData)) },
        timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        timeOffRequestUpdateReset: () => { dispatch(timeOffRequestUpdateReset()) },
        resetTimeOffRequestDetails: () => { dispatch(resetTimeOffRequestDetails()) },
        resetTimeOffRequestInfo: () => { dispatch(resetTimeOffRequestInfo()) },
        resetWorkHourByDate: (type) => { dispatch(resetWorkHourByDate(type)) },
        getTimeOffRequestStep: (data) => { dispatch(getTimeOffRequestStep(data)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffAproveTabs);