import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    changePassword,
    changePasswordReset
} from "../../redux/actions/profile.actions";
import { Form, ConfigProvider, Input, Button } from 'antd';
import '../../styles/changePassword.scss';

import PasswordChecklist from "react-password-checklist";
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

import { UilCheck, UilTimes } from '@iconscout/react-unicons'
import { ToastContainer } from 'react-toastify';

function ChangePassword(props) {

    const {
        changePassword,
        onClose,
        changePasswordResponse,
        changePasswordReset
    } = props;


    const { t } = useTranslation();
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const [passwordState, setPasswordState] = useState({
        "oldPassword": "",
        "newPassword": "",
        "newPasswordConfirm": ""
    })

    const minLength = 8;
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false)
    const [isNewPasswordConfirmValid, setIsNewPasswordConfirmValid] = useState(false)

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(passwordState)
    }, [form])

    useEffect(() => {
    }, [passwordState])

    function close() {
        onClose(false)
    }

    const handleChangePassword = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        changePassword(passwordState)
    }

    const handleValuesChange = (changedObj) => {
        setPasswordState({ ...passwordState, ...changedObj });
    }

    useEffect(() => {
        if (changePasswordResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success',
                'title': t('SUCCESS'),
                'description': changePasswordResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            setTimeout(() => {
                // changePasswordReset()
                close()
            }, 100);
        } else if (changePasswordResponse.status === 'FAIL') {

        }
    }, [changePasswordResponse]);


    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <div className="changePasswordWrapper">
                <div className="page-title">
                    {t('HEADER_CHANGE_PASSWORD')}
                </div>
                <ToastContainer />
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                >

                    <Form.Item name="oldPassword" label={t('CURRENT_PASSWORD')}>
                        <Input.Password onChange={() => changePasswordReset()} />
                    </Form.Item>
                    {changePasswordResponse.status === 'FAIL' && (
                        <ul className="password-check-list"><li><span className="text-danger">{changePasswordResponse.message}</span></li></ul>
                    )}
                    <Form.Item name="newPassword" label={t('NEW_PASSWORD')}>
                        <Input.Password />
                    </Form.Item>
                    {passwordState.newPassword.length > 0 && (
                        <PasswordChecklist
                            className="password-check-list"
                            rules={["minLength", "number", "capital", "lowercase"]}
                            minLength={minLength}
                            value={passwordState.newPassword}
                            valueAgain={passwordState.newPasswordConfirm}
                            onChange={(isValid) => { setIsNewPasswordValid(isValid) }}
                            iconComponents={{ ValidIcon: <UilCheck />, InvalidIcon: <UilTimes /> }}
                            messages={{
                                minLength: t('REGISTER_AT_LEAST_8_CHARACTERS', { "length": minLength }),
                                number: t('REGISTER_AT_LEAST_1_DIGIT'),
                                capital: t('REGISTER_AT_LEAST_1_CAPITAL'),
                                lowercase: t('REGISTER_AT_LEAST_1_LOWERCASE'),
                            }}
                        />
                    )}
                    <Form.Item name="newPasswordConfirm" label={t('RE_NEW_PASSWORD')}>
                        <Input.Password />
                    </Form.Item>
                    {passwordState.newPasswordConfirm.length > 0 && (

                        <PasswordChecklist
                            className="password-check-list"
                            rules={["match"]}
                            value={passwordState.newPassword}
                            valueAgain={passwordState.newPasswordConfirm}
                            onChange={(isValid) => { setIsNewPasswordConfirmValid(isValid) }}
                            iconComponents={{ ValidIcon: <UilCheck />, InvalidIcon: <UilTimes /> }}
                            messages={{
                                match: t('PASSWORD_DOESNT_MATCH'),
                            }}
                        />
                    )}

                </Form>

            </div>
            {(isNewPasswordConfirmValid && isNewPasswordValid && passwordState.oldPassword) && (
                <div className="password-change-footer">
                    <Button onClick={() => close()} className="transparent-btn">{t('PARTNER_CANCEL')}</Button>
                    <Button onClick={handleChangePassword} className="primary-btn">{t('CHANGE_PASSWORD_BTN')}</Button>
                </div>
            )}
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        changePasswordResponse: state.profile.changePasswordResponse
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (o) => { dispatch(changePassword(o)) },
        changePasswordReset: () => { dispatch(changePasswordReset()) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);