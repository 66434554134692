
import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

const SettingsAction = {
    GET_HOLIDAY_REQUEST: "GET_HOLIDAY_REQUEST",
    GET_HOLIDAY_SUCCESS: "GET_HOLIDAY_SUCCESS",
    GET_HOLIDAY_FAIL: "GET_HOLIDAY_FAIL",
    EMPLOYEE_ROLE_LIST_REQUESTS:"EMPLOYEE_ROLE_LIST_REQUESTS",
    EMPLOYEE_ROLE_LIST_SUCCESS:"EMPLOYEE_ROLE_LIST_SUCCESS",
    EMPLOYEE_ROLE_LIST_FAIL:"EMPLOYEE_ROLE_LIST_FAIL"
  };
const getHoliday = (years) => {
    return async (dispatch) => {
      dispatch({ type: SettingsAction.GET_HOLIDAY_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/holidays/search?year='+years,
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: SettingsAction.GET_HOLIDAY_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: SettingsAction.GET_HOLIDAY_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = []
              dispatch({ type: SettingsAction.GET_HOLIDAY_SUCCESS, payload: emptyData });
            }else{
              dispatch({ type: SettingsAction.GET_HOLIDAY_FAIL, payload: error.message });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: SettingsAction.GET_HOLIDAY_FAIL, payload: error.message });
        }
      }
    };
  };
  const getEmployeesRoleList = (searchData) => {
    return async (dispatch) => {
      const data = JSON.stringify(searchData);
      dispatch({ type: SettingsAction.EMPLOYEE_ROLE_LIST_REQUESTS });
      try {
        var config = {
          method: 'post',
          url: apiUrl+'/persons/getUserRoleDetail',
          headers: { 
            'Content-Type': 'application/json'
          },
          data:data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: SettingsAction.EMPLOYEE_ROLE_LIST_SUCCESS, payload: response.data.data });
          }else{
  
              dispatch({ type: SettingsAction.EMPLOYEE_ROLE_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
               "results": []
            }
            dispatch({ type: SettingsAction.EMPLOYEE_ROLE_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: SettingsAction.EMPLOYEE_ROLE_LIST_FAIL, payload: error.message });
        });
      } catch (error) {
        if (error.response) {
            dispatch({ type: SettingsAction.EMPLOYEE_ROLE_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  
 
export {
    SettingsAction,
    getHoliday,
    getEmployeesRoleList
};