import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, Col, Row, Button, Steps, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons'
import AddInventoryBasicInfo from './inventory-basic-info';
import AddInventoryDynamicDetailsInfo from './inventory-dynamic-detail-info';
import { MergeObjects } from '../../../utils/commonFormFunctions'
import { dateFormat } from "../../../utils/config";


import moment from 'moment';


import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import { setInventoryAddDrawer, createInventory, resetCreateInventory } from '../../../redux/actions/inventory.actions';
import Successful from "../../successful";

import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import { setCurrentTaskState, setUserTaskInfo } from "../../../redux/actions/onboarding.actions";

function AddInventoryTabs(props) {
    const { Step } = Steps;
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [currentTab, setCurrentTab] = useState(1);
    const [createInventoryObj, setCreateInventoryObj] = useState({
        //basic: null,
        // inventoryValueSets: null,
    });
    const { setInventoryAddDrawer, inventoryAttribute, createInventory, inventoryCreateResponse, resetCreateInventory } = props;
    const requiredArray = [7, 8, 9, 10, 11, 12];
    const accessoryRequiredArray = [7, 8];

    function callback(key) {
        checkRequiredFields(() => setCurrentTab(parseInt(key)), 'changeTab');
    }
    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function nextButton() {
        checkRequiredFields(() => setCurrentTab(parseInt(currentTab + 1)), 'changeTab');

    }
    function inventoryCloseControl() {
        if (currentTab !== 3 && (createInventoryObj?.inventoryType?.id || (createInventoryObj?.brand && createInventoryObj?.brand?.id) || createInventoryObj?.serialNumber || createInventoryObj?.name || createInventoryObj?.status || createInventoryObj?.model || createInventoryObj?.barcodeNumber || createInventoryObj?.description || createInventoryObj?.deviceExpirationDate)) {
            setConfirmModalData({ 'isVisible': true })
        } else {
            destroyLastState()
        }
    }
    function destroyLastState() {
        resetCreateInventory()
        setCurrentTab(1);
        setInventoryAddDrawer(false)
        setCreateInventoryObj({})
    }
    function createNewOne() {
        resetCreateInventory()
        setCreateInventoryObj({})
        setCurrentTab(1);

    }

    const setCreateObject = (type, obj) => {

        let mergedObj = {}
        if (type === "dynamic") {
            const dynamicData = Object.values(obj).map(data => ({
                AttributeId: inventoryAttribute.attributeId,
                AttributeItemId: data?.item?.attributeItemId,
                FieldType: data?.item?.fieldType,
                AttributeItemValueId: data?.attributeItemValueId,
                Value: data?.value,
                LanguageKey : data?.languageKey,
            }));
            mergedObj = MergeObjects(createInventoryObj, { "inventoryValueSets": dynamicData })
        } else {
            mergedObj = MergeObjects(createInventoryObj, obj)
        }
        setCreateInventoryObj(mergedObj)
    }

    const checkRequiredFields = (nextFunc, type) => {

        let hasError = false
        let fields = []

        if (!createInventoryObj.inventoryType || createInventoryObj.inventoryType === "" || createInventoryObj.inventoryType === null) {
            hasError = true
            fields.push(t('DEBIT_INVENTORY_TYPE'))
        }
        if (!requiredArray.includes(createInventoryObj.inventoryType?.id)) {
            if (createInventoryObj.inventoryType?.id !== 6) { //CAR
                if (!createInventoryObj.serialNumber || createInventoryObj.serialNumber === "" || createInventoryObj.serialNumber === null) {
                    hasError = true
                    fields.push(t('INVENTORY_SERIAL_NUMBER'))
                }
            }

            if (!createInventoryObj.brand || createInventoryObj.brand === "" || createInventoryObj.brand === null) {
                hasError = true
                fields.push(t('INVENTORY_BRAND'))
            }
        }
        if (!createInventoryObj.name || createInventoryObj.name === "" || createInventoryObj.name === null) {
            hasError = true
            fields.push(t('PARTNER_DEVICE_NAME'))
        }
        if (!createInventoryObj.status || createInventoryObj.status === "" || createInventoryObj.status === null) {
            hasError = true
            fields.push(t('INVENTORY_STATU'))
        }
        if (type === 'submit') {
            if (accessoryRequiredArray.includes(createInventoryObj.inventoryType?.id)) {
                if (createInventoryObj?.inventoryValueSets) {
                    const isRequiredAccessoryTypeMap = createInventoryObj?.inventoryValueSets.length > 0 && createInventoryObj?.inventoryValueSets.map((data) => {
                        if ((data.AttributeItemId === 78 || data.AttributeItemId === 79) && data.AttributeItemValueId > 0) {
                            return true
                        } else {
                            return false
                        }
                    })
                    const isRequiredAccessoryType = isRequiredAccessoryTypeMap[0];
                    if (!isRequiredAccessoryType) {
                        hasError = true;
                        fields.push(t('ACCESSORY_TYPE'))
                    }
                } else {
                    hasError = true;
                    fields.push(t('ACCESSORY_TYPE'))
                }
            }
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [isCreateInventoryLoading, setisCreateInventoryLoading] = useState(false);

    const handleCreateInventory = () => {

        if(isCreateInventoryLoading) return;
        setisCreateInventoryLoading(true);
        setTimeout(() => {setisCreateInventoryLoading(false);}, 1055) // double click prevent;

        const data = {
            'model': createInventoryObj?.model,
            'serialNumber': createInventoryObj?.serialNumber,
            'barcodeNumber': createInventoryObj?.barcodeNumber,
            'name': createInventoryObj?.name,
            'description': createInventoryObj?.description,
            //'inventoryTypeId': createInventoryObj?.inventoryType?.id,
            'statusId': createInventoryObj?.status,
            'amount': createInventoryObj?.amount,
            'currencyId': createInventoryObj?.currency?.id,
            'brandId': createInventoryObj?.brand?.id,
            'sku': "",
            'mpn': "",
            'nsn': "",
            'isAccessory': false,
            'inventoryValueSets': createInventoryObj?.inventoryValueSets,
            'deviceExpirationDate': createInventoryObj?.deviceExpirationDate && createInventoryObj?.deviceExpirationDate !== 'Invalid date' ? moment(createInventoryObj?.deviceExpirationDate).format(dateFormat) : null,
            "inventoryTypeId" : createInventoryObj?.languageKey,

        }
        checkRequiredFields(() => createInventory(data), 'submit')

    }
    useEffect(() => {
        if (inventoryCreateResponse.status === 'SUCCESS') {
            if(props.currentTaskState == 72){
                props.setCurrentTaskState(73);
            }
            if(props.currentTaskState !== 72 && props.currentTaskState !== 73 && !props.userTaskInfo.completedTasks.includes(7)){                    
                setTimeout(() => { props.setCurrentTaskState(1006); }, 200);
            }
            setCurrentTab(3);
        } else if (inventoryCreateResponse.status === 'FAIL') {
            setCurrentTab(currentTab);
            DynamicToastMessage({
                'key': 'inventory_add_failed_notification',
                'title': t('ERROR'),
                'description': inventoryCreateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
        } else if (inventoryCreateResponse.status === 'VALIDATION_ERROR') {
            setCurrentTab(currentTab);
            DynamicToastMessage({
                'key': 'inventory_add_validation_error_notification',
                'title': t('ERROR'),
                'description': <List>{inventoryCreateResponse.message.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })

        }

    }, [inventoryCreateResponse])

    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })


    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={destroyLastState}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => inventoryCloseControl()} />
                </div>
                {currentTab !== 3 ?
                    <React.Fragment>
                        <Col offset={6} span={16}>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('INVENTORY_DEVICE')}</h1>
                            </div>
                        </Col>

                        <div className="w-100">
                            <Row>
                                <Col span={6}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('PARTNER_BASIC_INFORMATION')} description="" />
                                        <Step title={t('PARTNER_DETAILS_INFORMATION')} />
                                        <Step title={t('PARTNER_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={17}>


                                    <Tabs className="custom-tab add-user-draw"
                                        onChange={callback}
                                        activeKey={currentTab.toString()}
                                        defaultActiveKey={currentTab.toString()}
                                    >
                                        <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key={1}>
                                            <AddInventoryBasicInfo
                                                requiredArray={requiredArray}
                                                setCreateObject={(o) => setCreateObject("basic", o)}
                                            />
                                        </TabPane>
                                        <TabPane tab={t('PARTNER_DETAILS_INFORMATION')} key={2}>
                                            <AddInventoryDynamicDetailsInfo
                                                inventoryAttribute={inventoryAttribute}
                                                setCreateObject={(o) => setCreateObject("dynamic", o)}
                                            />
                                        </TabPane>
                                    </Tabs>

                                </Col>
                            </Row>

                        </div>
                    </React.Fragment>
                    :
                    <Successful message={t('INVENTORY_CREATED')} />
                }
            </div>
            <div className="add-user-draw-footer ">
                {currentTab === 1 ?
                    <div>
                        <Button onClick={() => handleCreateInventory()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_ADD')}</Button>
                        <Button onClick={() => inventoryCloseControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>

                    </div> :
                    <Button onClick={() => inventoryCloseControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>

                }
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 3 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 3 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_DEVICE')}</Button>
                    }
                    <div className="pad-left-24" />
                    {currentTab === 3 &&
                        <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                    }
                    {currentTab < 3 && currentTab === 1 ?

                        <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_NEXT')}</Button>
                        :
                        ''
                    }

                    {
                        currentTab === 2 ?
                            <Button onClick={() => handleCreateInventory()} className="pad-0 w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_ADD')}</Button>
                            : ''
                    }

                    {/* <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 3 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 3 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_DEVICE')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 3 ?
                        currentTab === 2 ?
                            <Button onClick={() => handleCreateInventory()} className="w-100 h-40 f-14 font-w-700 primary-btn pad-0">{t('INVENTORY_ADD')}</Button>
                            :
                            <Button onClick={() => nextButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_NEXT')}</Button>
                        :

                        <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        //clear state
                    }
                </div> */}
                </div>

            </div>
        </div>
    )

}



const mapStateToProps = (state) => {
    return {
        inventoryAttribute: state.inventory.inventoryAttribute.inventoryAttributeResult,
        inventoryCreateResponse: state.inventory.inventoryCreateResponse,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setInventoryAddDrawer: (props) => { dispatch(setInventoryAddDrawer(props)) },
        createInventory: (createData) => { dispatch(createInventory(createData)) },
        resetCreateInventory: () => { dispatch(resetCreateInventory()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryTabs);
