import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { setChangePasswordDrawer, changePasswordReset, getProfile, getPermissions } from "../redux/actions/profile.actions";
import { changeOrganization, resetChangeOrganization } from "../redux/actions/auth.actions";
import { setCurrentTaskState, setUserTaskInfo, getOnboarding } from "../redux/actions/onboarding.actions";
import { getPersonOrganizations } from '../redux/actions/organization.actions';
import { signoutRedirect } from '../services/userService'

import { Layout, Image, Button, Drawer, Modal, Spin, Typography, Popover } from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { UilKeySkeletonAlt } from '@iconscout/react-unicons'
import moment from 'moment';

import ChangePassword from "../component/changePassword";
import OnboardingTaskHook from '../component/onBoarding/OnboardingTaskHook';
import OnboardingBackdropHook from '../component/onBoarding/OnboardingBackdropHook';
import OnboardingHeader from '../component/onBoarding/OnboardingHeader';
import OrganizationCultureModal from '../component/OrganizationCulture/OrganizationCultureModal';
import SubscriptionWarningBar from "../features/subscription-warning-bar";
import SubscriptionPaymentWarningBar from "../features/subscription-payment-warning-bar";
import LangSelector from '../component/langSelector/index';
import "../styles/search.scss";
import "../styles/global.scss";
import "../styles/onboarding.scss";

import { useWindowDimensions } from '../utils/config';
import { sendGtmEvent } from '../utils/gtmFunction';

const { Text } = Typography;

function HeaderComponent(props) {
    // #region Header
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const { user, profile, getProfile, setChangePasswordDrawer, changePasswordReset, changePasswordDrawerStatus, getPersonOrganizations,
        personOrganizationList, personOrganizationListLoading, changeOrganization, resetChangeOrganization, changeOrganizationStatus,
        getPermissions, permissions, userTaskInfo, currentTaskState, setCurrentTaskState, getOnboarding } = props;
    const { Header } = Layout;
    const [cKey, setCKey] = useState(0);
    const [isShowProfile, setIsShowProfile] = useState(false);
    const [isSwitchOrganizationModalVisible, setIsSwitchOrganizationModalVisible] = useState(false);

    useEffect(() => {
        const i18nLang = localStorage.getItem("i18nLang");
        moment.locale(i18nLang === "en" ? 'en-gb' : 'tr');
    }, []);

    const location = useLocation();
    const { pathname } = location;

    const [isBarVisible,setIsBarVisible] = useState(false);
    const [isBarPaymentFailVisible,setIsBarPaymentFailVisible] = useState(false);

    function signOut() {
        window.sendinblue?.track(
            //brevo_event_logout
            'logout'
        )
        signoutRedirect(user)
    }

    const [person, setPerson] = useState({
        "givenName": "",
        "familyName": "",
        "organization": "",
        "photo": process.env.REACT_APP_URL + "images/avatar.png"
    })

    useEffect(() => {
        getProfile();
        getPersonOrganizations();
    }, [])

    useEffect(() => {
        setPerson({
            "givenName": profile?.givenName,
            "familyName": profile?.familyName,
            "organization": profile?.organization?.name,
            "photo": profile?.avatarImgUrl ? profile?.avatarImgUrl : process.env.REACT_APP_URL + "images/avatar_" + profile?.gender?.id + ".png"
        })

        const trialExpireDate = new Date(user?.profile?.organizationEntryEndDate);
        const today = new Date();
        const diffDate = trialExpireDate - today;
        const remainingDay = Math.ceil(diffDate / 86400000);
    
        if(
            !['/prices','/payment','/paymentCompleted'].some(path => pathname?.includes(path)) &&
            user?.profile?.organizationPackageId == 0 && permissions?.includes('access_authorization')
        ){
            setIsBarVisible(true)
        }
        if(!['/prices','/payment','/paymentCompleted'].some(path => pathname?.includes(path)) &&
                parseInt(user?.profile?.organizationPackageId) > 0 && permissions?.includes('access_authorization') &&
                remainingDay>-1 && remainingDay<16 && user?.profile?.organizationPaymentStatus == 2){
                setIsBarPaymentFailVisible(true)
        }else{
            setIsBarPaymentFailVisible(false)
        }
        }, [profile,user,permissions])

    const showProfile = () => {
        setIsShowProfile(true);
    };
    const hideProfile = () => {
        setIsShowProfile(false);
    };
    const setChangePasswordDrawerStatus = (s) => {
        setCKey(cKey + 1)
        changePasswordReset()
        setChangePasswordDrawer(s)
    }


    const updatePersonOrganizationList = () => {
        setIsSwitchOrganizationModalVisible(true);
    }
    useEffect(() => {
        if (changeOrganizationStatus) {
            setIsSwitchOrganizationModalVisible(false);
            let storedData = localStorage.getItem(`oidc.user:${process?.env?.REACT_APP_AUTH_URL}:${process?.env?.REACT_APP_AUTH_CLIENT_ID}`);
            storedData = JSON.parse(storedData);
            localStorage.setItem(`oidc.user:${process?.env?.REACT_APP_AUTH_URL}:${process?.env?.REACT_APP_AUTH_CLIENT_ID}`, JSON.stringify(user));
            localStorage.setItem('changeOrganizationStatus', 'true');
            window.location.reload();
            resetChangeOrganization();
        }
    }, [changeOrganizationStatus, user])

    useEffect(() => {
        let changeOrganizationStatus = localStorage.getItem('changeOrganizationStatus');
        if (changeOrganizationStatus) {
            getPermissions();
            getProfile();
            localStorage.removeItem('changeOrganizationStatus');
        }
    }, [])
    // #endregion

    // #region OnBoarding
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [nextTaskID , setNextTaskID] = useState(1);
    const [nextTaskPackage, setNextTaskPackage] = useState(1);
    const hide = () => { setPopoverVisible(false); setCurrentTaskState(null);  };
    const handlePopoverVisibleChange = (visible) => {
        if(visible == false){ props.setCurrentTaskState(null); }
        setPopoverVisible(visible); 
    };
    
    OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'header');
   
    const customSetCurrentTaskState = (state, showTaskList) => {
        setCurrentTaskState(state);
        if(showTaskList){
            setPopoverVisible(true);
        }
    }
    OnboardingTaskHook(currentTaskState, setPopoverVisible, getOnboarding , 
        userTaskInfo.initialized && width >= 992 && !permissions?.includes('must_systemsettings') && permissions?.includes('access_authorization') && permissions.includes('onboarding') /* false = disabled */);

    useEffect(() => { getOnboarding(); },[]); 

    useEffect(() => {
        if(userTaskInfo.initialized && width >= 992 && !permissions?.includes('must_systemsettings') && permissions?.includes('access_authorization') && permissions.includes('onboarding')){

            // Daha önce hiç görev yapmamış kişi || Yeni kayıt olan kişi
            if(userTaskInfo.completedTasks.length == 0 && userTaskInfo.initialized && !userTaskInfo.completedTasks.includes(-1)){
                setCurrentTaskState(0);
                sendGtmEvent("first_login");
            }
            // Daha önce görev yapmış, onboarding taskını ilk defa gören kişi.
            if(userTaskInfo.initialized && !userTaskInfo.completedTasks.includes(-1) && userTaskInfo.completedTasks.length > 0) {
                    getOnboarding(-1)
                    setCurrentTaskState(999);
                    setPopoverVisible(true);
            }
            const taskList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
            const validGeneralTasks = taskList.filter(task => task > 0);
            const completedCount = userTaskInfo.completedTasks.filter(task => task > 0).length;
            const completionPercentage = (completedCount / validGeneralTasks.length) * 100;

            // nextTask
            const nextTask = taskList.find(task => !userTaskInfo.completedTasks.includes(task));
           
            const taskPackages = [
                [1, 2, 3],    // 1. Paket
                [4, 5, 6],    // 2. Paket
                [7, 8, 9],    // 3. Paket
                [10, 11]      // 4. Paket
              ];
            const nextTaskPackageIndex = taskPackages.findIndex(packages => packages.includes(nextTask));
              
             if (typeof nextTask === 'undefined') {
                // Tüm görevler tamamlandı
                setNextTaskID('done');
                setNextTaskPackage('done');
                sendGtmEvent("onboarding_complete");
            }else{
                setNextTaskID(nextTask);
                setNextTaskPackage(nextTaskPackageIndex+1)
            }

            setPercentage(completionPercentage.toFixed(0))
        }

    },[userTaskInfo, permissions]); 

    
    // #endregion

    return (
        <React.Fragment>
            
            <Header className={ (isBarVisible || isBarPaymentFailVisible) && "header-with-bar" } >
                { !isBarPaymentFailVisible && isBarVisible && 
                             <SubscriptionWarningBar /> 
                }
                { !isBarVisible && isBarPaymentFailVisible && <SubscriptionPaymentWarningBar />}
                <div className="d-flex justify-space-between  w-100">
                    <OnboardingHeader
                        currentTaskState={currentTaskState}
                        setCurrentTaskState={setCurrentTaskState}
                        nextTaskID={nextTaskID}
                        nextTaskPackage={nextTaskPackage}
                        userTaskInfo={userTaskInfo}
                        width={width}
                        permissions={permissions}
                        pathname={pathname}
                        percentage={percentage}
                        user={user}
                        popoverVisible={popoverVisible}
                        handlePopoverVisibleChange={handlePopoverVisibleChange}
                        customSetCurrentTaskState={customSetCurrentTaskState}
                        hide={hide}
                    />

                    <OrganizationCultureModal isVisible={ permissions?.includes('must_systemsettings') }  />

                    <div className="header-user-panel">
                        {<LangSelector className="mr-2" />}
                        <Button key="back" className="ant-btn white-btn h-40">
                            <a href="mailto:destek@hrplan.net">{t('CLICK_FOR_SUPPORT')}</a>
                        </Button>
                        {
                            personOrganizationList?.length > 1 &&
                            <Button
                                className="transparent-btn change-organization-button "
                                onClick={() => updatePersonOrganizationList()}
                                icon={<Image className="img-border" src={profile?.organization?.organizationLogo} preview={false} />}
                            >
                                {t('HEADER_CHANGE_ORGANIZATION')}
                            </Button>
                        }
                        <div className="user-avatar" onClick={showProfile}>
                            <div className="avatar-details">
                                <div className="name">{person.givenName + " " + person.familyName}</div>
                                <div className="company"><Text ellipsis>{person.organization}</Text></div>
                            </div>
                            <Image src={person.photo} preview={false} />
                        </div>
                        <Modal visible={isSwitchOrganizationModalVisible} footer={null} onCancel={() => { setIsSwitchOrganizationModalVisible(false) }}>
                            {personOrganizationListLoading ?
                                <Spin className="d-flex w-100 h-100 justify-center" />
                                : personOrganizationList.map((data, index) => {

                                    return (
                                        <div key={index} className="switch-organization-list-item">
                                            <Button
                                                disabled={data.organizationResourceId === profile?.organization?.resourceId}
                                                onClick={() => changeOrganization(data.organizationResourceId)}
                                                className="transparent-btn switch-organization-list-item-button"
                                                icon={<Image className="img-border" src={data.organizationLogo} preview={false}
                                                />}
                                            >
                                                {`${data.organizationName} ${data.organizationResourceId === profile?.organization?.resourceId ? '(' + t('Seçili') + ')' : ''}`}
                                            </Button>
                                        </div>
                                    );
                                })
                            }
                        </Modal>
                        <Drawer
                            title={false}
                            width={279}
                            placement="right"
                            closable={false}
                            onClose={hideProfile}
                            visible={isShowProfile}
                        >
                            <div className="user-menu">
                                <div className="close-icon">
                                    <CloseOutlined onClick={hideProfile} />
                                </div>
                                <div className="user-details">
                                    <div className="avatar">
                                        <Image src={person.photo} preview={false} />
                                    </div>
                                    <div className="user-desc">
                                        <h1>{person.givenName + " " + person.familyName}</h1>
                                        <a href={"mailto:" + profile?.corporateEmail}>{profile?.corporateEmail}</a>
                                        <h2>{person.organization}</h2>

                                        <div className="hr pad-21" />
                                        <div className="user-settings show_off">
                                            <Link to="/profile">
                                                <UserOutlined />  {t('HEADER_INFORMATION')}
                                            </Link>
                                            <Button type="link" className="change-password-btn" onClick={() => setChangePasswordDrawerStatus(true)} icon={<UilKeySkeletonAlt />}> {t('HEADER_CHANGE_PASSWORD')}</Button>
                                        </div>
                                        <div className="pad-27" />
                                        <Button
                                            className="white-btn w-178 h-32 f-14"
                                            onClick={() => signOut()}
                                        >
                                            {t('HEADER_LOG_OUT')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                        <Drawer
                            title={false}
                            width={400}
                            placement="right"
                            closable={true}
                            onClose={() => setChangePasswordDrawerStatus(false)}
                            visible={changePasswordDrawerStatus}
                            zIndex={99999}
                        >
                            <ChangePassword key={cKey} onClose={(s) => setChangePasswordDrawerStatus(s)} />
                        </Drawer>
                    </div>
                </div>
            </Header>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        user: state.auth.user,
        changePasswordDrawerStatus: state.profile.changePasswordDrawer,
        personOrganizationList: state.organization.personOrganizationList,
        personOrganizationListLoading: state.organization.personOrganizationListLoading,
        changeOrganizationStatus: state.auth.changeOrganizationStatus,
        personMe: state.person.personMe,
        permissions: state.profile.permissions,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => { dispatch(getProfile()) },
        getPermissions: () => { dispatch(getPermissions()) },
        setChangePasswordDrawer: (s) => { dispatch(setChangePasswordDrawer(s)) },
        changePasswordReset: () => { dispatch(changePasswordReset()) },
        getPersonOrganizations: () => { dispatch(getPersonOrganizations()) },
        changeOrganization: (personData) => { dispatch(changeOrganization(personData)) },
        resetChangeOrganization: () => { dispatch(resetChangeOrganization()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
        getOnboarding: (id) => {dispatch(getOnboarding(id))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);