import { React, useState } from 'react';
import { Layout, Form, Input, Button, Image, Card, ConfigProvider } from 'antd';
import axios from 'axios';
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import '../../styles/button.scss';
import '../../styles/all-login-page.scss';
import {
    KeyOutlined
} from '@ant-design/icons';
import PasswordControl from '../../features/password-check/control';

function CreatePassword(props) {
    const { token } = props.match.params;
    const isNumberRegx = /\d/;
    const specialCharacterRegx = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
    const uppercaseRegx = /[A-Z]/;
    const lowercaseRegx = /[a-z]/;
    const [password, setPassword] = useState("");
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [result, setResult] = useState('');
    const [returnData, setReturndata] = useState();

    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null,
        upperLowerCase: null,
    });
    const [errorClass, setErrorClass] = useState('');
    const onFinish = (values) => {
        if (values.password !== values.password2) {
            setResult('Şifreler aynı olmalı');
            setErrorClass('ant-form-item-has-error');
        } else if (values.password.length < 8) {
            setResult('En az 8 karakter olmalı');
            setErrorClass('ant-form-item-has-error');
        } else if (!isNumberRegx.test(values.password)) {
            setResult('En az 1 rakam olmalı');
            setErrorClass('ant-form-item-has-error');
        } else if (!specialCharacterRegx.test(values.password)) {
            setResult('En az 1 simge olmalı ( ! . , & )');
            setErrorClass('ant-form-item-has-error');
        } else if (!uppercaseRegx.test(values.password) || !lowercaseRegx.test(values.password)) {

            setResult('Lütfen büyük / küçük harf kullanınız');
            setErrorClass('ant-form-item-has-error');
        } else {
            setResult('');
            setErrorClass('');
            postToken(values.password);
        }
    };

    const postToken = (data) => {
        const responseData = {
            password: data,
            token: token
        }
        axios.post('https://f4bede4f-189e-4ed4-ba78-9b6b07cbfd70.mock.pstmn.io/person/resetPassword', responseData)
            .then(response => setReturndata(response.data));

    }

    const onChangePassword = password => {
        setPassword(password);

        setPasswordValidity({
            minChar: password.length >= 8 ? true : false,
            number: isNumberRegx.test(password) ? true : false,
            specialChar: specialCharacterRegx.test(password) ? true : false,
            upperLowerCase: uppercaseRegx.test(password) && lowercaseRegx.test(password) ? true : false,
        });
    };
    const validateMessages = {
        required: '${label} boş bırakılamaz!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    return (
        <Layout>

            <div className="netHR-container">
                <div className="logo">
                    <Image src={process.env.REACT_APP_URL + "images/ofixpro45.png"} preview={false} />
                </div>
                {returnData !== undefined ?
                    returnData.message
                    :
                    <div></div>
                }
                <Card className="netHR-card">
                    <ConfigProvider form={{ validateMessages }}>
                        <Form
                            name="create_password"
                            className={errorClass}
                            layout="vertical"
                            onFinish={onFinish}
                            requiredMark={false}

                        >

                            <h1 className="reset-form-title">
                                Yeni Şifre Belirle
                            </h1>
                            {result !== '' ?
                                <p className="red-alert">{result}</p> : <p className="reset-form-description">Hesabınız için yeni şifre belirleyiniz.</p>}



                            <Form.Item
                                label="Şifre"
                                name="password"
                                className="custom-form"
                                rules={[{ required: true }]}

                            >
                                <Input.Password
                                    prefix={<KeyOutlined style={{ color: "#bfbfbf" }} />}
                                    iconRender={(visible) => (visible ? 'Göster' : 'Gizle')}
                                    className="password-input"
                                    value={password}
                                    onFocus={() => setPasswordFocused(true)}
                                    onChange={e => onChangePassword(e.target.value)}
                                />


                            </Form.Item>
                            {passwordFocused && (
                                <PasswordControl
                                    validity={passwordValidity}
                                />
                            )}
                            <Form.Item
                                label="Şifre Tekrar"
                                name="password2"
                                rules={[{ required: true }]}
                            >
                                <Input.Password
                                    prefix={<KeyOutlined style={{ color: "#bfbfbf" }} />}
                                    iconRender={(visible) => (visible ? 'Göster' : 'Gizle')}
                                    className="password-input"


                                />


                            </Form.Item>
                            <Form.Item>
                                <div className="submit-btn">
                                    <Button htmlType="submit" className="primary-btn w-390 h-40">
                                        Şifreyi Onayla
                                    </Button>
                                </div>
                            </Form.Item>
                            <div className="register-btn">
                                <a href="/kayit-ol">Üye Değil misiniz? <span> </span>Kayit Olmak için tıklayın.</a>
                            </div>

                        </Form>
                    </ConfigProvider>
                </Card>
                <div className="helper">
                    <a href="/helper">Yardım</a>
                </div>
            </div>
        </Layout>
    )


}
export default CreatePassword;