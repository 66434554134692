import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin, Form, Input, DatePicker, Switch, Checkbox, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { hrApi } from '../../utils/apiConfig';
import { apiUrl } from '../../utils/config';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { localeLang } from "../../utils/config";

async function holidaysCreate(settings) {
    const config = {
        method: "post",
        url: `${apiUrl}/holidays/create`,
        data: settings,
    };
    const { data } = await hrApi(config);
    return data;
}

const OrganizationHolidayModal = ({ isVisible, onClose, newHolidayTrigger }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isActive, setIsActive] = useState(false);
    const [isStartDateHalfActive, setIsStartDateHalfActive] = useState(false);
    const [isEndDateHalfActive, setIsEndDateHalfActive] = useState(false);
    const [startDateTime, setStartDateTime] = useState("08:00");
    const [endDateTime, setEndDateTime] = useState("13:30");

    const mutation = useMutation(holidaysCreate, {
        onSuccess: (data) => {
            if(data?.status == "SUCCESS" && data?.message){
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': data.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                });

            }
            newHolidayTrigger();
            form.resetFields();
            onClose();
        },
        onError: (error) => {
            if(error?.response?.data?.status == "FAILED" && error?.response?.data?.message){
                DynamicToastMessage({
                    key: 'error_key',
                    title: t('ERROR'),
                    description: error?.response?.data?.message,
                    statusType: 'error',
                    position: 'bottom-right'
                });
            }else{
                DynamicToastMessage({
                    key: 'error_key',
                    title: t('ERROR'),
                    description: t('EDUCATION_ERROR_OCCURRED'),
                    statusType: 'error',
                    position: 'bottom-right'
                });
            }
        },
    });

    const handleSubmit = (values) => {
        const settings = {
            name: values.holidayName,
            startDate: values.startDate.toISOString().split('T')[0] + "T00:00:00.000Z",
            endDate: values.endDate.toISOString().split('T')[0] + "T00:00:00.000Z",
            isActive: isActive
        };

        if (isStartDateHalfActive) {
            settings.startDate = values.startDate.toISOString().split('T')[0] + "T" + startDateTime + ":00.000Z";
        }

        mutation.mutate(settings);
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const onStartDateHalfChange = (e) => {
        setIsStartDateHalfActive(e.target.checked);
        if (!e.target.checked) {
            setStartDateTime("08:00");
        }
    };




    useEffect(() => {
        if (isVisible) {
            form.resetFields();
            setIsActive(false);
        }
    }, [isVisible]);

    const validateDates = (_, value) => {
        const startDate = form.getFieldValue('startDate');
        if (startDate && value && value.isBefore(startDate, 'day')) {
            return Promise.reject(new Error(t('END_DATE_AFTER_START_DATE')));
        }
        return Promise.resolve();
    };

    return (
        <Modal 
            className="culture-select-modal" 
            visible={isVisible} 
            onCancel={onClose}
            footer={null} 
            closable={true}
            zIndex={1000}
        >   
            <div className="culture-select-modal-wrapper">
                <div className="culture-select-modal-header">
                    <h2>{t('CREATE_NEW_HOLIDAY')}</h2>
                </div>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="culture-select-modal-select">
                        <Form.Item
                            label={t('HOLIDAY_NAME')}
                            name="holidayName"
                            rules={[{ required: true, message: t('ENTER_HOLIDAY_NAME') }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className="w-100 mb-2"
                            label={t('SETTINGS_START_DATE')}
                            name="startDate"
                            rules={[{ required: true, message: t('ENTER_HOLIDAY_START_DATE') }]}
                        >
                            <DatePicker className="w-100" locale={localeLang} format="DD-MM-YYYY" placeholder={t('PLACEHOLDER_DATE')} />
                        </Form.Item>
                        <Checkbox className="mb-2" onChange={onStartDateHalfChange}>{t('HALF_DAY')}</Checkbox>
                        <Form.Item
                            className="w-100 mb-2"
                            label={t('SETTINGS_END_DATE')}
                            name="endDate"
                            rules={[
                                { required: true, message: t('ENTER_HOLIDAY_END_DATE') },
                                { validator: validateDates }
                            ]}
                        >
                            <DatePicker className="w-100" locale={localeLang} format="DD-MM-YYYY" placeholder={t('PLACEHOLDER_DATE')} />
                        </Form.Item>
                        <Form.Item
                            name="isActive"
                            valuePropName="checked"
                        >
                           <div className="d-flex">
                                {isActive 
                                    ? <div className="mr-auto">{t('NEW_HOLIDAY_ACTIVE')} </div>
                                    : <div className="mr-auto">{t('NEW_HOLIDAY_INACTIVE')} </div>
                                }
                                <Switch checked={isActive} onChange={setIsActive} />
                           </div>
                        </Form.Item>
                    </div>
                    <div className="d-flex">
                        <Button
                            className="white-btn h-40 f-14 w-100"
                            onClick={handleCancel}
                            disabled={mutation.isLoading}
                        >
                            {t('QUICK_ACTION_CANCEL')}
                        </Button>
                        <Button
                            className="primary-btn h-40 f-14 w-100 ml-2"
                            htmlType="submit"
                            disabled={mutation.isLoading}
                        >
                            {mutation.isLoading ? <Spin /> : t('ONBOARDING_CONTINUE')}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default OrganizationHolidayModal;
