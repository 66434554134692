import { ApprovalProcesses } from "../actions/approval-processes.actions";

var initialState = {
    approvalProcessesCreateDrawer:false,
    approvalProcessesDetailDrawer:false,
    createApprovalProcessesResponse:{"status":"loading", "message":"","errorIds":[]},
    approvalProcessesDetailInfo:{
      "resourceId":"",
      "approvalName":"",
      "approvalType":"",
      "approvalList":""
    },
    approvalProcessesDetaiLoading:true,
    approvalProcessList:[],
    approvalProcessListLoading:true,
    deleteApprovalProcessResponse: { "status": "loading", "message": "" },
    updateApprovalProcessesTypeResponse:{"status":"loading", "message":"","selectIndex":null},
    updateApprovalProcessesResponse:{"status":"loading", "message":"","errorIds":[]},
    deleteApprovalProcessResponse: { "status": "loading", "message": "" },
    checkDefaultProcessResponse: { "status": "loading", "message": "","data":{"isSuccess":true} },
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
        case ApprovalProcesses.SET_APPROVAL_PROCESSES_CREATE_DRAWER:
          const approvalProcessesCreateDrawerState = {
            ...state,
            approvalProcessesCreateDrawer: action.payload,
          };
          return approvalProcessesCreateDrawerState;
        case ApprovalProcesses.SET_APPROVAL_PROCESSES_DETAIL_DRAWER:
          const approvalProcessesDetailDrawerState = {
            ...state,
            approvalProcessesDetailDrawer: action.payload,
          };
          return approvalProcessesDetailDrawerState;
        case ApprovalProcesses.CREATE_APPROVAL_PROCESSES_SUCCESS:
          return {
              ...state,
              createApprovalProcessesResponse:{
                  "status":"SUCCESS",
                  "message":action.payload.message,
                  "errorIds":[]
              }
          }
        case ApprovalProcesses.CREATE_APPROVAL_PROCESSES_FAIL:
          return {
              ...state,
              createApprovalProcessesResponse:{
                  "status":"FAIL",
                  "message":action.payload,
                  "errorIds":[]
            }
          }
        case ApprovalProcesses.CREATE_APPROVAL_PROCESSES_VALIDATION_FAIL:
          return {
              ...state,
              createApprovalProcessesResponse:{
                  "status":"VALIDATION_ERROR",
                  "message":action.payload.message,
                  "errorIds":action.payload.errorIds
            }
          }
        case ApprovalProcesses.CREATE_APPROVAL_PROCESSES_RESET:
          return{
              ...state,
              createApprovalProcessesResponse:{"status":"loading","message":"","errorIds":[]}
          }
        case ApprovalProcesses.GET_PROCESSES_DETAIL_REQUEST:
          return {
              ...state,
              approvalProcessesDetaiLoading: true
          };
        case ApprovalProcesses.GET_PROCESSES_DETAIL_SUCCESS:
          return {
              ...state,
              approvalProcessesDetailInfo:action.payload,
              approvalProcessesDetaiLoading: false,
          }
        case ApprovalProcesses.GET_PROCESSES_DETAIL_FAIL:
          return {
              ...state,
              approvalProcessesDetaiLoading: false,
          }
        case ApprovalProcesses.GET_ALL_PROCESS_REQUEST:
          return {
              ...state,
              approvalProcessListLoading: true
          };
        case ApprovalProcesses.GET_ALL_PROCESS_SUCCESS:
            return {
                ...state,
                approvalProcessList: action.payload,
                approvalProcessListLoading: false
            }
        case ApprovalProcesses.GET_ALL_PROCESS_FAIL:
            return {
                ...state,
                approvalProcessListLoading: false
            }
        case ApprovalProcesses.DELETE_APPROVAL_PROCESSES_SUCCESS:
          return {
              ...state,
              deleteApprovalProcessResponse: {
                  "status": "SUCCESS",
                  "message": action.payload
              }
          }
        case ApprovalProcesses.DELETE_APPROVAL_PROCESSES_FAIL:
            return {
                ...state,
                deleteApprovalProcessResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case ApprovalProcesses.DELETE_APPROVAL_PROCESSES_RESET:
            return {
                ...state,
                deleteApprovalProcessResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_SUCCESS:
          return {
              ...state,
              updateApprovalProcessesTypeResponse: {
                  "status": "SUCCESS",
                  "message": action.payload.message,
                  "selectIndex":action.payload.index
              }
          }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_FAIL:
            return {
                ...state,
                updateApprovalProcessesTypeResponse: {
                    "status": "FAIL",
                    "message": action.payload.message,
                    "selectIndex":action.payload.index
                }
            }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_TYPE_RESET:
            return {
                ...state,
                updateApprovalProcessesTypeResponse: {
                    "status": "loading",
                    "message": null
                }
            }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_SUCCESS:
          return {
              ...state,
              updateApprovalProcessesResponse: {
                  "status": "SUCCESS",
                  "message": action.payload,
                  "errorIds":[]
              }
          }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_FAIL:
            return {
                ...state,
                updateApprovalProcessesResponse: {
                    "status": "FAIL",
                    "message": action.payload,
                    "errorIds":[]
                }
            }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_VALIDATION_FAIL:
            return {
                ...state,
                updateApprovalProcessesResponse:{
                    "status":"VALIDATION_ERROR",
                    "message":action.payload.message,
                    "errorIds":action.payload.errorIds
              }
            }
        case ApprovalProcesses.UPDATE_APPROVAL_PROCESSES_RESET:
            return {
                ...state,
                updateApprovalProcessesResponse: {
                    "status": "loading",
                    "message": null,
                    "errorIds":[]
                }
            }
        case ApprovalProcesses.GET_CHECK_DEFAULT_REQUEST:
            return {
                ...state,
                checkDefaultProcessResponse: {
                    "status": "loading",
                    "message": "",
                    "data":{"isSuccess":true}
                }
            }
          case ApprovalProcesses.GET_CHECK_DEFAULT_PROCESSES_SUCCESS:
            return {
                ...state,
                checkDefaultProcessResponse: {
                    "status": "SUCCESS",
                    "message": action.payload.message,
                    "data":action.payload.data

                }
            }
          case ApprovalProcesses.GET_CHECK_DEFAULT_PROCESSES_FAIL:
            return {
                ...state,
                checkDefaultProcessResponse: {
                    "status": "FAIL",
                    "message": action.payload,
                    "data":{"isSuccess":true} ,
                }
            }
            case ApprovalProcesses.SET_CHECK_DEFAULT_PROCESSES:
            return {
                ...state,
                checkDefaultProcessResponse: {
                    "status": "FAIL",
                    "message": "",
                    "data":{"isSuccess":true} ,
                }
            }
      default:return state;
    }
  }