import React from "react";
import { Redirect, withRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Layout } from "antd";
import routeList from "../../routeMap.jsx";

const LayoutContent = (props) => {
    const { Content } = Layout;
    const { location } = props;
    return (
        <Content>
            <Switch location={location}>
                <Redirect exact from="/" to="/homepage" />
                {routeList.map((route) => {
                    return (

                        <Route
                            component={route.component}
                            exact
                            key={route.path}
                            path={route.path}
                        />
                    );
                })}
            </Switch>
        </Content>
    );
};
export default connect((state) => state)(withRouter(LayoutContent));