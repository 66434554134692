import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import MaskedInput from 'react-text-mask'
import moment from 'moment';

import { Button, Spin, Col, Row, Card, Form, Dropdown } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';

import { setSalaryDetailsOpenDraw, setSalaryAddOpenDraw } from "../../redux/actions/person-detail-tab-action";
import { getSalary, getSalaryHistory, deleteSalary, deleteSalaryReset } from "../../redux/actions/person.actions";

import { dateFormatConvert, customTableScrolling, useWindowDimensions } from '../../utils/config';
import { compareObjects, numberWithCommas } from '../../utils/commonFormFunctions';
import { noData } from '../../utils/commonFormFunctions';
import { salaryListDropdown } from '../../features/global-dropdown-menu/index'
import DynamicRequestModal from '../../component/dynamicModal/requestModal'
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

import Can from "../../authorization/index";
import Forbidden from '../forbidden';

import '../../styles/custom-table-card.scss';

function SalaryInformation(props) {
    const { t } = useTranslation();
    const { height } = useWindowDimensions();
    const { person, salaryHistory, getSalary, getSalaryHistory, getUpdateObj, disableStatus,
        page = '', permissions, isSalaryHistoryLoading, deleteSalary, deleteSalaryResponse, deleteSalaryReset } = props;
    const currencyShortList = { "Türk Lirası": "₺", "Turkish Lira": "₺", "ABD Doları": "$", "Euro": "€" };
    const [form] = Form.useForm()
    const [personState, setPersonState] = useState({});
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

    const openDetailsDraw = () => {
        props.setSalaryDetailsOpenDraw(true);
    }
    const openAddDraw = () => {
        props.setSalaryAddOpenDraw(true);
    }
    const checkEditable = (resourceId) => {
        openDetailsDraw();
        getSalary({ "personResourceId": person.resourceId, "salaryResourceId": resourceId })
    }
    const handleValuesChange = (changedObj) => {
        setPersonState({ ...personState, ...changedObj });
    }
    useEffect(() => {
        setPersonState(person);
        form.setFieldsValue({
            "ibanNumber": person.ibanNumber
        })
        getSalaryHistory(person.resourceId, page)
    }, [form, person])

    useEffect(() => {
        getUpdateObj(personState)
    }, [personState]);
    useEffect(() => {
        if (deleteSalaryResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'deleteSalaryResponse_success',
                'title': t('SUCCESS'),
                'description': deleteSalaryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            deleteSalaryReset()
            getSalaryHistory(person.resourceId, page)
        } else if (deleteSalaryResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'deleteSalaryResponse_fail',
                'title': t('ERROR'),
                'description': deleteSalaryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteSalaryReset()
        }
    }, [deleteSalaryResponse]);
    const canDelete = (id, count) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'count': count, 'isVisible': true })
    }
    return (
        isSalaryHistoryLoading ? <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin> :
            <div className={!isSalaryHistoryLoading ? customTableScrolling(salaryHistory.length, height, "custom-table-card-container") : 'custom-table-card-container'} >
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteSalary}
                    confirmData={deleteModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_SALARY_MESSAGE', { "count": deleteModalData?.count })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    requiredMark={false}
                >
                    <Form.Item name="ibanNumber"
                        className="salary-class-form"
                        label={t('PARTNER_IBAN')}
                    >
                        <MaskedInput
                            disabled={disableStatus}
                            className={personState.ibanNumber && !compareObjects(person.ibanNumber, personState.ibanNumber) ? 'ant-input is-changed' : 'ant-input'}
                            mask={[/[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i, /[A-Z0-9]/i]}
                            guide={false}
                        />
                    </Form.Item>
                </Form>


                <div className="custom-table-card-add">
                    <Can I="person.salary.add">
                        <Button disabled={disableStatus} onClick={openAddDraw} className="white-btn m-w-180 h-32"><PlusOutlined /> {t('PARTNER_ADD_SALARY_INFORMATION')}</Button>
                    </Can>
                </div>
                {((permissions.includes("person.salary.list") && page !== 'profile') || page === 'profile') ?
                    salaryHistory.length > 0 ?
                        <div className="custom-table-card-class customize-salary-list">
                            <div className="custom-table-card-title-class">
                                <Col span={24}>
                                    <Row>
                                        <Col span={6}>
                                            <h1>{t('PARTNER_SALARY_INFORMATION2')}</h1>
                                        </Col>
                                        <Col span={6}>
                                            <h1>{t('PARTNER_SALARY_CURRENCY')}</h1>
                                        </Col>
                                        <Col span={6}>
                                            <h1>{t('CHANGE_REASON')}</h1>
                                        </Col>
                                        <Col span={5}>
                                            <h1>{t('PARTNER_DATE_OF_VALIDITY')}</h1>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                            {salaryHistory.map((item) => {
                                return (
                                    <Card key={item.resourceId} className={permissions.includes("person.salary.read") ? "custom-table-card clickable" : "custom-table-card"}  >
                                        <Col span={24}>
                                            <Row>
                                                <Col span={6} onClick={() => permissions.includes("person.salary.read") ? checkEditable(item.resourceId) : null}>
                                                    <p>{numberWithCommas(item.salary)} {currencyShortList[item.salaryCurrency]}</p>
                                                </Col>
                                                <Col span={6} onClick={() => permissions.includes("person.salary.read") ? checkEditable(item.resourceId) : null}>
                                                    <p>{item.salaryCurrency}</p>
                                                </Col>
                                                <Col span={6} onClick={() => permissions.includes("person.salary.read") ? checkEditable(item.resourceId) : null}>
                                                    <p>{item.salaryChangeReasonType}</p>
                                                </Col>
                                                <Col span={5} onClick={() => permissions.includes("person.salary.read") ? checkEditable(item.resourceId) : null}>
                                                    {item.startDate ?
                                                        <p>{moment(item.startDate).format(dateFormatConvert)} </p>
                                                        : <p></p>
                                                    }
                                                </Col>
                                                <Col span={1}>
                                                    {permissions.includes('person.salary.update') &&
                                                        <div className="custom-table-card-icon clickable">
                                                            <Dropdown
                                                                className="action-menu"
                                                                overlay={salaryListDropdown(t, canDelete, item.resourceId, numberWithCommas(item.salary) + currencyShortList[item.salaryCurrency], permissions)}
                                                                trigger={['click']}
                                                                disabled={disableStatus}
                                                            >
                                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                            </Dropdown>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Card>
                                )
                            })}
                        </div>
                        :
                        <div className="custom-table-card-class">
                            {noData(t)}
                        </div>
                    :
                    (<Forbidden size="sm" />)
                }
            </div >
    )
}
const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        isSalaryLoading: state.person.isSalaryLoading,
        permissions: state.profile.permissions,
        isSalaryHistoryLoading: state.person.isSalaryHistoryLoading,
        deleteSalaryResponse: state.person.deleteSalaryResponse
    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setSalaryDetailsOpenDraw: (props) => { dispatch(setSalaryDetailsOpenDraw(props)); },
        setSalaryAddOpenDraw: (props) => { dispatch(setSalaryAddOpenDraw(props)); },
        getSalary: (item) => { dispatch(getSalary(item)) },
        getSalaryHistory: (id, page) => { dispatch(getSalaryHistory(id, page)) },
        deleteSalary: (salaryResourceId) => { dispatch(deleteSalary(salaryResourceId)) },
        deleteSalaryReset: () => { dispatch(deleteSalaryReset()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalaryInformation);