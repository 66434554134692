import { Layout, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import '../../styles/successful.scss'

function Loading(props) {
    const { message = "", whiteBtn = null, primaryBtn = null, dynamicMessage = null } = props
    const { t } = useTranslation();
    return (
        <Layout className={"successful-layout"}>
            <Row align="center">
                <Col className={"successful-wrapper middle"}>
                    <div className='img-wrapper'>
                        <img src={process.env.REACT_APP_URL + "images/hourglass.png"} />
                    </div>
                    <div className={'successful-header-text pad-top-20'}>
                        {dynamicMessage ? dynamicMessage : t('SUCCESSFUL')}
                    </div>
                    <div className={'successful-header-helper-text'}>
                        {message}
                    </div>
                    {(whiteBtn || primaryBtn) &&
                        <div className='d-flex w-100 pt-2'>
                            {whiteBtn ?? ''}
                            {whiteBtn && primaryBtn ? <div className='pl-2' /> : ''}
                            {primaryBtn ?? ''}
                        </div>}
                </Col>
            </Row>
        </Layout >
    )
}
export default Loading;