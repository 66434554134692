import React from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "../../styles/custom-toast-message.scss";

//bottom-right
//top-right

const DynamicToastMessage = (props) => {

    const config = {
        'key': props?.key ? props?.key : 'toast-message-modal',
        'title': props?.title ? props?.title : '',
        'description': props?.description ? props?.description : '',
        'statusType': props?.statusType ? props?.statusType : null,
        'autoClose': props?.autoClose ? props?.autoClose : 5000,
        'pauseOnHover': props?.pauseOnHover ? props?.pauseOnHover : false,
        'position': props?.position ? props?.position : "top-right",
        'className': props?.className ? props?.className : "",
    }
    const WriteText = () => {
        return (
            <div className="custom-toast-message-container">
                <h1>{config.title}</h1>
                <span>{config.description}</span>
            </div>
        )
    }
    return (
        toast(<WriteText />, {
            toastId: config.key,
            position: config.position,
            type: config.statusType,
            autoClose: config.autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: config.pauseOnHover,
            draggable: true,
            progress: undefined,
            className: config.className
        })
    )

}
export default DynamicToastMessage;