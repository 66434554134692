import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout, Col, Card, Row, Spin, } from 'antd';

import {
    getNumberOfFemaleAndMaleEmployees, getNumberOfMarriedAndSingleEmployees,
    getEmployeeExperienceRates, getEmployeesWorkingTimeInCompany, getNumberOfChildrenEmployees,
    getDistributionOfEmployeesByDepartments
} from '../../redux/actions/dashboard.actions'
import ApexChart from 'react-apexcharts'
import moment from 'moment'
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir

import '../../styles/dashboard.scss';


const { Content } = Layout;


function Reports(props) {
    const { t } = useTranslation();
    const { getNumberOfFemaleAndMaleEmployees, getNumberOfMarriedAndSingleEmployees,
        getEmployeeExperienceRates, getEmployeesWorkingTimeInCompany, getNumberOfChildrenEmployees, getDistributionOfEmployeesByDepartments } = props;

    const { numberOfFemaleAndMaleList, numberOfFemaleAndMaleListLoading,
        numberOfMarriedAndSingleList, numberOfMarriedAndSingleLoading, employeeExperienceRatesLoading,
        employeeExperienceRatesList, employeesWorkingTimeInTheCompanyList, employeesWorkingTimeInTheCompanyLoading,
        numberOfChildrenEmployeesLoading, numberOfChildrenEmployeesList, distributionOfEmployeesByDepartmentsLoading,
        distributionOfEmployeesByDepartmentsList } = props;
    const colorArray = ['#39B2AC', '#FDBA31', '#FAA724', '#84bad6']
    const paiChartLegendOptions = {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: "'Roboto', sans-serif ",
        fontWeight: 400,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
        },
        itemMargin: {
            horizontal: 5,
            vertical: 0
        },
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: true
        },
    }
    const responsivePaiChart = [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200,
            },
            legend: {
                position: 'bottom'
            },
        },

    }]
    const [numberOfFemaleState, setNumberOfFemaleState] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            labels: [],

        },

    })
    const [numberOfMarriedAndSingleState, setNumberOfMarriedAndSingleState] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            labels: [],

        },
    })
    const [employeeExperienceRatesState, setEmployeeExperienceRatesState] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            labels: [],
        },
    })


    const [employeesWorkingTimeInTheCompanyState, setEmployeesWorkingTimeInTheCompanyState] = useState(
        {
            series: [{
                name: '',
                data: []
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {},
                }
            },
            dataLabels: {},

            xaxis: {},
            yaxis: {},
        }
    )

    const [numberOfChildrenEmployeesState, setNumberOfChildrenEmployeesState] = useState(
        {
            series: [{
                name: '',
                data: []
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {},
                }
            },
            dataLabels: {},

            xaxis: {},
            yaxis: {},
        }
    )
    const [distributionOfEmployeesByDepartmentsState, setDistributionOfEmployeesByDepartmentsState] = useState(
        {
            series: [{
                name: '',
                data: []
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {},
                }
            },
            dataLabels: {},

            xaxis: {},
            yaxis: {},
        }
    )

    useEffect(() => {
        getNumberOfFemaleAndMaleEmployees()
    }, []);
    useEffect(() => {
        getNumberOfMarriedAndSingleEmployees()
    }, [])
    useEffect(() => {
        getEmployeeExperienceRates()
    }, []);
    useEffect(() => {
        getEmployeesWorkingTimeInCompany()
    }, []);
    useEffect(() => {
        getNumberOfChildrenEmployees()
    }, []);
    useEffect(() => {
        getDistributionOfEmployeesByDepartments()
    }, []);
    //
    useEffect(() => {
        if (numberOfFemaleAndMaleList.length > 0) {
            let seriesArray = [];
            let labelArray = [];

            numberOfFemaleAndMaleList.forEach(element => {
                seriesArray.push(element.personCount)
                labelArray.push(element.name)
            });
            setNumberOfFemaleState(
                {
                    series: seriesArray,
                    options: {
                        chart: {
                            type: 'donut',
                        },
                        responsive: responsivePaiChart,
                        legend: paiChartLegendOptions,
                        labels: labelArray,
                        colors: colorArray

                    }
                }
            )
        }

    }, [numberOfFemaleAndMaleList])
    useEffect(() => {


        if (numberOfMarriedAndSingleList.length > 0) {
            let seriesArray = [];
            let labelArray = [];

            numberOfMarriedAndSingleList.forEach(element => {
                seriesArray.push(element.personCount)
                labelArray.push(element.name)
            });
            setNumberOfMarriedAndSingleState(
                {
                    series: seriesArray,
                    options: {
                        chart: {
                            type: 'donut',
                        },
                        responsive: responsivePaiChart,
                        legend: paiChartLegendOptions,
                        labels: labelArray,
                        colors: colorArray,
                    }
                }
            )
        }

    }, [numberOfMarriedAndSingleList])


    useEffect(() => {


        if (employeeExperienceRatesList.length > 0) {
            let seriesArray = [];
            let labelArray = [];

            employeeExperienceRatesList.forEach(element => {
                seriesArray.push(element.personCount)
                labelArray.push(element.careerYear + ' ' + t('PARTNER_YEARS'))
            });
            setEmployeeExperienceRatesState(
                {
                    series: seriesArray,
                    options: {
                        chart: {
                            type: 'donut',
                        },
                        responsive: responsivePaiChart,
                        legend: paiChartLegendOptions,
                        labels: labelArray,
                        colors: colorArray,
                    }
                }
            )
        }
    }, [employeeExperienceRatesList])
    //

    useEffect(() => {


        if (employeesWorkingTimeInTheCompanyList.length > 0) {
            let seriesArray = [];
            let labelArray = [];

            employeesWorkingTimeInTheCompanyList.forEach(element => {
                seriesArray.push(element.personCount)
                labelArray.push(element.workingYear)
            });
            setEmployeesWorkingTimeInTheCompanyState(
                {
                    series: [{
                        name: '',
                        data: seriesArray
                    }],
                    options: {
                        chart: {
                            height: 350,
                            type: 'bar',
                        },
                        colors: ['#FF9933'],
                        //
                        plotOptions: {
                            bar: {
                                borderRadius: 0,
                                dataLabels: {
                                    position: 'top', // top, center, bottom
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return val;
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: "'Roboto', sans-serif ",
                            }
                        },
                        tooltip: {
                            x: {
                                formatter: function (series) {
                                    return series + ' ' + t('PARTNER_YEARS');
                                }
                            },
                            y: {
                                formatter: function (value, opts) {
                                    const sum = opts.series[0].reduce((a, b) => a + b, 0);
                                    const percent = (value / sum) * 100;
                                    return percent.toFixed(0) + '%'
                                },
                            },
                        },

                        xaxis: {
                            categories: labelArray,
                            position: 'top',
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            crosshairs: {
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        colorFrom: '#D8E3F0',
                                        colorTo: '#BED1E6',
                                        stops: [0, 100],
                                        opacityFrom: 0.4,
                                        opacityTo: 0.5,
                                    }
                                }
                            },
                            labels: {
                                show: true,
                                formatter: function (val) {
                                    return val + ' ' + t('PARTNER_YEARS');
                                }
                            }

                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                    },
                }
            )
        }

    }, [employeesWorkingTimeInTheCompanyList])


    useEffect(() => {


        if (numberOfChildrenEmployeesList.length > 0) {
            let seriesArray = [];
            let labelArray = [];

            numberOfChildrenEmployeesList.forEach(element => {
                seriesArray.push(element.personCount)
                labelArray.push(element.childrenCount)
            });
            setNumberOfChildrenEmployeesState(
                {
                    series: [{
                        name: '',
                        data: seriesArray
                    }],
                    options: {
                        chart: {
                            height: 350,
                            type: 'bar',

                        },
                        colors: ['#4451A3'],
                        //FF9933
                        plotOptions: {
                            bar: {
                                borderRadius: 0,
                                dataLabels: {
                                    position: 'top', // top, center, bottom
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return val;
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: "'Roboto', sans-serif ",
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, opts) {
                                    const sum = opts.series[0].reduce((a, b) => a + b, 0);
                                    const percent = (value / sum) * 100;
                                    return percent.toFixed(0) + '%'
                                },
                            },
                        },
                        xaxis: {
                            categories: labelArray,
                            position: 'top',
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            crosshairs: {
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        colorFrom: '#39B2AC',
                                        colorTo: '#BED1E6',
                                        stops: [0, 100],
                                        opacityFrom: 0.4,
                                        opacityTo: 0.5,
                                    }
                                }
                            },
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                    },
                }
            )
        }

    }, [numberOfChildrenEmployeesList])
    useEffect(() => {


        if (distributionOfEmployeesByDepartmentsList.length > 0) {
            let seriesArray = [];
            let labelArray = [];

            distributionOfEmployeesByDepartmentsList.forEach(element => {
                seriesArray.push(element.personCount)
                labelArray.push(element.departmentName)
            });
            setDistributionOfEmployeesByDepartmentsState(
                {
                    series: [{
                        name: '',
                        data: seriesArray
                    }],
                    options: {
                        chart: {
                            height: 350,
                            type: 'bar',
                        },
                        colors: [function ({ value, seriesIndex, w }) {
                            if (value < 10) {
                                return '#4451A3'
                            } else if (value > 10 && value < 30) {
                                return '#BA3A17'
                            } else if (value > 30 && value < 50) {
                                return '#457A86'
                            } else if (value > 50 && value < 70) {
                                return '#FDBA31'
                            } else {
                                return '#A351A9' //A351A9
                            }
                        }],
                        plotOptions: {
                            bar: {
                                borderRadius: 0,
                                dataLabels: {
                                    position: 'top', // top, center, bottom
                                },

                            }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return val;
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: "'Roboto', sans-serif ",
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, opts) {
                                    const sum = opts.series[0].reduce((a, b) => a + b, 0);
                                    const percent = (value / sum) * 100;
                                    return percent.toFixed(0) + '%'
                                },
                            },
                        },
                        xaxis: {
                            categories: labelArray,
                            show: false,
                            labels: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            }
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                    },
                }
            )
        }

    }, [distributionOfEmployeesByDepartmentsList])
    return (

        <Layout>

            <Layout>
                <Content className="page-container pad-30">

                    <div className="tab-page">
                        <h1 className="page-title">{t('REPORTS')}</h1>
                        <div className="pad-15" />
                    </div>

                    <div className="dashboard-card-container">

                        <Row gutter={[24, 32]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} >

                                <Card className="dashboard-reports-pie-card" key={1}>
                                    <div className="dashboard-reports-pie-title">
                                        <h1>{t('GENDER_BY_NUMBERS')} </h1>
                                    </div>
                                    {numberOfFemaleAndMaleListLoading ?
                                        <Spin className="flex-center w-100 pad-top-75" />
                                        :
                                        <div id="chart">
                                            <ApexChart options={numberOfFemaleState.options} series={numberOfFemaleState.series} type="donut" height={240} />
                                        </div>
                                    }
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} >
                                <Card className="dashboard-reports-pie-card" key={2}>
                                    <div className="dashboard-reports-pie-title">
                                        <h1>{t('MARITAL_BY_NUMBERS')} </h1>
                                    </div>
                                    {numberOfMarriedAndSingleLoading ?
                                        <Spin className="flex-center w-100 pad-top-75" />
                                        :
                                        numberOfMarriedAndSingleList.length > 0 ?
                                            <div id="chart">
                                                <ApexChart options={numberOfMarriedAndSingleState.options} series={numberOfMarriedAndSingleState.series} type="donut" height={240} />
                                            </div>
                                            : ''
                                    }
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} >
                                <Card className="dashboard-reports-pie-card" key={3}>
                                    <div className="dashboard-reports-pie-title">
                                        <h1>{t('RATIO_BY_EMPLOYEE_EXPERIENCE')} </h1>
                                    </div>
                                    {employeeExperienceRatesLoading ?
                                        <Spin className="flex-center w-100 pad-top-75" />
                                        :

                                        employeeExperienceRatesList.length > 0 ?
                                            <div id="chart">
                                                <ApexChart options={employeeExperienceRatesState.options} series={employeeExperienceRatesState.series} type="donut" height={240} />
                                            </div>
                                            : ''
                                    }
                                </Card>
                            </Col>


                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} >
                                <Card className="dashboard-reports-pie-card" key={6}>
                                    <div className="dashboard-reports-pie-title">
                                        <h1>{t('CHILDREN_BY_EMPLOYEES')} </h1>
                                    </div>
                                    {numberOfChildrenEmployeesLoading ?
                                        <Spin className="flex-center w-100 pad-top-75" />
                                        :
                                        <div id="chart">
                                            <ApexChart options={numberOfChildrenEmployeesState.options} series={numberOfChildrenEmployeesState.series} type="bar" height={220} />
                                        </div>
                                    }
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }} >
                                <Card className="dashboard-reports-pie-card" key={5}>
                                    <div className="dashboard-reports-pie-title">
                                        <h1>{t('COMPANY_EXPERIENCE_BY_RATIO')}</h1>
                                    </div>
                                    {employeesWorkingTimeInTheCompanyLoading ?
                                        <Spin className="flex-center w-100 pad-top-75" />
                                        :
                                        <div id="chart">
                                            <ApexChart options={employeesWorkingTimeInTheCompanyState.options} series={employeesWorkingTimeInTheCompanyState.series} type="bar" height={220} />
                                        </div>
                                    }
                                </Card>
                            </Col>

                            <Col span={24} >
                                <Card className="dashboard-reports-pie-card" key={4}>
                                    <div className="dashboard-reports-pie-title">
                                        <h1>{t('EMPLOYEE_BY_DEPARTMENT')} </h1>
                                    </div>
                                    {distributionOfEmployeesByDepartmentsLoading ?
                                        <Spin className="flex-center w-100 pad-top-75" />
                                        :
                                        <div id="chart">
                                            <ApexChart options={distributionOfEmployeesByDepartmentsState.options} series={distributionOfEmployeesByDepartmentsState.series} type="bar" height={225} />
                                        </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </div>

                </Content>


            </Layout >
        </Layout >
    )

}
const mapStateToProps = (state) => {
    return {
        numberOfFemaleAndMaleList: state.dashboard.numberOfFemaleAndMaleList,
        numberOfFemaleAndMaleListLoading: state.dashboard.numberOfFemaleAndMaleListLoading,
        numberOfMarriedAndSingleList: state.dashboard.numberOfMarriedAndSingleList,
        numberOfMarriedAndSingleLoading: state.dashboard.numberOfMarriedAndSingleLoading,
        employeeExperienceRatesLoading: state.dashboard.employeeExperienceRatesLoading,
        employeeExperienceRatesList: state.dashboard.employeeExperienceRatesList,
        employeesWorkingTimeInTheCompanyLoading: state.dashboard.employeesWorkingTimeInTheCompanyLoading,
        employeesWorkingTimeInTheCompanyList: state.dashboard.employeesWorkingTimeInTheCompanyList,
        numberOfChildrenEmployeesLoading: state.dashboard.numberOfChildrenEmployeesLoading,
        numberOfChildrenEmployeesList: state.dashboard.numberOfChildrenEmployeesList,
        distributionOfEmployeesByDepartmentsLoading: state.dashboard.distributionOfEmployeesByDepartmentsLoading,
        distributionOfEmployeesByDepartmentsList: state.dashboard.distributionOfEmployeesByDepartmentsList
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNumberOfFemaleAndMaleEmployees: () => { dispatch(getNumberOfFemaleAndMaleEmployees()) },
        getNumberOfMarriedAndSingleEmployees: () => { dispatch(getNumberOfMarriedAndSingleEmployees()) },
        getEmployeeExperienceRates: () => { dispatch(getEmployeeExperienceRates()) },
        getEmployeesWorkingTimeInCompany: () => { dispatch(getEmployeesWorkingTimeInCompany()) },
        getNumberOfChildrenEmployees: () => { dispatch(getNumberOfChildrenEmployees()) },
        getDistributionOfEmployeesByDepartments: () => { dispatch(getDistributionOfEmployeesByDepartments()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);