import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTravelHostCreateDrawer, createTravelHost, createTravelHostReset } from '../../../redux/actions/travelHost.actions';
import { Col, Row, Button, Steps, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons';

import moment from 'moment'


import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import '../../../styles/table.scss';
import Successful from "../../successful";

import TravelHostTab from './travel_host_tab';
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import { dateFormat, dateFormatWithHours } from "../../../utils/config";

function CreateTravelHostAllTab(props) {

    const { setTravelHostCreateDrawer, createTravelHostResponse,
        createTravelHost, createTravelHostReset, page } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [createTravelObj, setCreateTravelObj] = useState({
    });
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })
    const { Step } = Steps;
    const { t } = useTranslation();
    const [isCreateLoading, setisCreateLoading] = useState(false);
    useEffect(() => {
        if (createTravelHostResponse.status === 'SUCCESS') {
            setCurrentTab(2);
            createTravelHostReset()
        }
        else if (createTravelHostResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': createTravelHostResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createTravelHostReset()
        }
    }, [createTravelHostResponse])


    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function closeDrawerControl() {
        if (currentTab !== 2 && (createTravelObj?.travel?.id || createTravelObj.isTravelRequest || createTravelObj?.travelRequestAddList?.length > 0 || createTravelObj?.travelDescription || createTravelObj?.isAccomodation || createTravelObj?.accommodationRequestAddList?.length > 0)) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal()
            setCurrentTab(1);
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setTravelHostCreateDrawer(false)
        setCreateTravelObj({
        });
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateTravelObj({
        });
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createTravelObj, obj)
        setCreateTravelObj(mergedObj)
    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []
        if (createTravelObj?.travelRequestAddList && createTravelObj.travelRequestAddList.length > 0) {
            createTravelObj.travelRequestAddList.map((data, index) => {
                if ((!data.country || !data.country?.id)) {
                    hasError = true;
                    fields.push(t('ORGANIZATION_COUNTRY'))
                }
                if (!data.provinceFrom) {
                    hasError = true;
                    fields.push(t('FROM_WHERE'))
                }
                if (!data.provinceTo) {
                    hasError = true;
                    fields.push(t('FROM_TO'))
                }
                if (!data.modeOfTransportation || !data?.modeOfTransportation?.id) {
                    hasError = true;
                    fields.push(t('MODE_OF_TRANSPORTATION'))
                }
                if (!data.date) {
                    hasError = true;
                    fields.push(t('PARTNER_DATE'))
                }
                if (!data.hour) {
                    hasError = true;
                    fields.push(t('HOUR'))
                }
            })
        } else {
            // hasError = true
            // fields.push(t('ESTIMATED_BUDGET'))
        }
        if (createTravelObj?.accommodationRequestAddList && createTravelObj.accommodationRequestAddList.length > 0) {
            createTravelObj.accommodationRequestAddList.map((data, index) => {
                if ((!data.country || !data.country?.id)) {
                    hasError = true;
                    fields.push(t('ORGANIZATION_COUNTRY'))
                }
                if (!data.province) {
                    hasError = true;
                    fields.push(t('PARTNER_PROVINCE'))
                }
                if (!data.startDate) {
                    hasError = true;
                    fields.push(t('PARTNER_ENTRY_DATE'))
                }
                if (!data.endDate) {
                    hasError = true;
                    fields.push(t('RELEASE_DATE'))
                }
            })
        } else {
            // hasError = true
            // fields.push(t('ESTIMATED_BUDGET'))
        }
        if (!hasError) {

            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleCreateTravelRequests = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        const TravelDetails = createTravelObj?.travelRequestAddList ? createTravelObj?.travelRequestAddList.map((data) => {
            var date = null;
            var dateWithHours = null;
            if (data?.date && data?.hour) {
                date = moment(data.date).format(dateFormat);
                dateWithHours = moment(date + ' ' + data?.hour).format(dateFormatWithHours);
            }
            return {
                "CountryId": data?.country?.id,
                "FromCity": data?.provinceFrom,
                "DestinationCity": data?.provinceTo,
                "TransportType": data?.modeOfTransportation?.id,
                "TransportDate": dateWithHours ? dateWithHours : null,
                "Description": data?.description
            }
        }) : null;

        const HostDetails = createTravelObj?.accommodationRequestAddList ? createTravelObj?.accommodationRequestAddList.map((data) => {
            return {
                "CountryId": data?.country?.id,
                "City": data?.province,
                "EntryDate": data?.startDate ? data?.startDate : null,
                "ExitDate": data?.endDate ? data?.endDate : null,
                "Description": data?.description
            }
        }) : null;

        var RequestType = null;
        var isAccomodation = createTravelObj?.isAccomodation ? createTravelObj?.isAccomodation : false;
        var isTravelRequest = createTravelObj?.isTravelRequest ? createTravelObj?.isTravelRequest : false;
        if (!isTravelRequest && !isAccomodation) {
            RequestType = null;
        }
        if (isTravelRequest && !isAccomodation) {
            RequestType = 0;
        }
        if (!isTravelRequest && isAccomodation) {
            RequestType = 1;
        }
        if (isTravelRequest && isAccomodation) {
            RequestType = 2;
        }
        const data = {
            "RequestType": RequestType,
            "RelatedTravelIdx": createTravelObj?.travel?.id,
            'TravelDetails': TravelDetails?.length > 0 ? TravelDetails : [],
            "TravelDescription": createTravelObj?.travelDescription,
            "HostDetails": HostDetails,
            "HostDescription": createTravelObj?.transportationDescription
        }
        checkRequiredFields(() => createTravelHost(data))
    }
    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {currentTab !== 2 ?
                    <React.Fragment>
                        <Col offset={4} span={16}>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST')}</h1>
                            </div>
                        </Col>
                        <div className="w-100 ">
                            <Row>
                                <Col span={4}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('HOMEPAGE_CREATE_REQUEST')} description="" />
                                        <Step title={t('PARTNER_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={20}>
                                    <TravelHostTab setCreateObject={setCreateObject} />
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                    :
                    <Successful message={t('TRAVEL_ACC_REQUEST_SUCCESS')} />
                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 2 ?
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                {/* clear state */}
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 2 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 2 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-200 h-40 f-14 font-w-700 white-btn">{t('CREATE_NEW_REQUEST')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 2 ?
                        currentTab === 1 &&
                        <Button onClick={() => handleCreateTravelRequests()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('HOMEPAGE_CREATE_REQUEST')}</Button>
                        :
                        <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{page ? t('RETURN_HOMEPAGE') : t('INVENTORY_RETURN_LIST')}</Button>
                        //clear state
                    }
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        createTravelHostResponse: state.travelHostReducer.createTravelHostResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTravelHostCreateDrawer: (status) => { dispatch(setTravelHostCreateDrawer(status)) },
        createTravelHost: (data) => { dispatch(createTravelHost(data)) },
        createTravelHostReset: () => { dispatch(createTravelHostReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTravelHostAllTab);
