import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDataset } from "../../../redux/actions/dataset-action";
import { getPersonTravel } from '../../../redux/actions/travel.actions';
import { Row, Col, Form, DatePicker, ConfigProvider, Checkbox, Input, Button, List, Radio } from 'antd';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import TR from 'antd/es/date-picker/locale/tr_TR';

import { dynamicDateInputFormatter, localeLang, dateFormatList, dateFormat } from "../../../utils/config";
import { priceDecimalWithDot, focusDateInput, blurDateInput, NumberList } from '../../../utils/commonFormFunctions'

import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicTable from "../../dynamicTable";

import '../../../styles/person.scss';
import '../../../styles/advances.scss';

function CreateAdvanceRequestsTab(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [createAdvanceRequest, setCreateAdvanceRequest] = useState({
        'RequestCreditCard': false,
        'AdvanceTypes': 1
    });
    const repaymentOptions = [
        {
            'id': 1,
            'displayValue': "Taksit"
        },
        {
            'id': 2,
            'displayValue': "Tek Seferde"
        }
    ];
    const [currencyList, setCurrencyList] = useState();
    const [advanceAmountList, setAdvanceAmountList] = useState([{
        "amount": null,
        "amountXWrite": null,
        "currency": null,
    }]);
    const [personTravelList, setPersonTravelList] = useState([]);
    const [datePickerFormatType, setDatePickerFormatType] = useState({})
    const [numberOfInstallmentState, setNumberOfInstallmentState] = useState([]);
    const { setCreateObject, getDataset, currencyTypes, getPersonTravel, personTravel, profile } = props;
    var numberOptions = NumberList(12, true);

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === 'AdvanceTypes') {
            const fields = form.getFieldsValue()
            const formFields = { ...fields };
            formFields["RepaymentType"] = { id: null, name: null };
            formFields["NumberOfInstallments"] = { id: null, name: null };
            formFields["FirstInstallmentPaymentMonth"] = null;
            formFields["RelatedTravelIdx"] = null;
            form.setFieldsValue(formFields);

            setCreateAdvanceRequest({
                ...createAdvanceRequest,
                'AdvanceTypes': changedObj.AdvanceTypes,
                'RepaymentType': null,
                'NumberOfInstallments': null,
                'FirstInstallmentPaymentMonth': null,
                'RelatedTravelIdx': null,
                'Amounts': []
            });
            setAdvanceAmountList([
                {
                    "amount": null,
                    "amountXWrite": null,
                    "currency": null,
                }
            ]);
        } else if (key === 'RepaymentType') {
            const fields = form.getFieldsValue()
            const formFields = { ...fields };
            formFields["NumberOfInstallments"] = { id: null, name: null };
            formFields["FirstInstallmentPaymentMonth"] = null;
            form.setFieldsValue(formFields);

            setCreateAdvanceRequest({
                ...createAdvanceRequest,
                'RepaymentType': changedObj.RepaymentType,
                'NumberOfInstallments': null,
                'FirstInstallmentPaymentMonth': null,
            });
            setNumberOfInstallmentState([]);
        }
        else if (key === 'AdvanceNeedDate') {
            if (changedObj.AdvanceNeedDate) {
                setCreateAdvanceRequest({ ...createAdvanceRequest, 'AdvanceNeedDate': moment(changedObj.AdvanceNeedDate).format(dateFormat) });
            } else {
                setCreateAdvanceRequest({ ...createAdvanceRequest, 'AdvanceNeedDate': null });
            }
        } else {
            setCreateAdvanceRequest({ ...createAdvanceRequest, ...changedObj });
        }
    }
    useEffect(() => {
        var arr = [];
        personTravel.length > 0 &&
            personTravel.map((data) =>
                arr.push({
                    'id': data.travelDefinitionId,
                    'displayValue': data.travelDescription
                })
            )
        setPersonTravelList(arr);
    }, [personTravel])
    useEffect(() => {
        setCreateObject(createAdvanceRequest)
        if (createAdvanceRequest?.RepaymentType?.id === 1 && createAdvanceRequest?.NumberOfInstallments?.id > 0 && createAdvanceRequest?.FirstInstallmentPaymentMonth) {
            var data = [];
            const total = advanceAmountList[0].amount;
            const currency = advanceAmountList[0].currency?.displayValue;

            for (var i = 1; i <= createAdvanceRequest?.NumberOfInstallments?.id; i++) {
                data.push({
                    'numberOfInstallments': i,
                    'amount': parseFloat(total / createAdvanceRequest?.NumberOfInstallments?.id).toLocaleString('tr-TR') + " " + currency
                });
            }
            setNumberOfInstallmentState(data);
        }
    }, [createAdvanceRequest]);
    useEffect(() => {
        setCurrencyList(currencyTypes)
    }, [currencyTypes])
    const handleRemoveClick = index => {
        const list = [...advanceAmountList];
        list.splice(index, 1);
        setAdvanceAmountList(list);
    };
    const handleAddNewRow = (page) => {
        var requiredFields = {};
        let hasError = false
        let fields = []
        var list = [...advanceAmountList];
        requiredFields = {
            'amount': false,
            'currency': false,
        }
        list.map((data) => {
            if (!data.amount) {
                requiredFields.amount = true;
            }
            if (!data.currency?.id) {
                requiredFields.currency = true;
            }
        });
        if (requiredFields.amount) {
            hasError = true
            fields.push(t('ADVANCE_AMOUNT'));
        }
        if (requiredFields.currency) {
            hasError = true;
            fields.push(t('PARTNER_CURRENCY'));
        }
        if (!hasError) {
            list.push({
                'amount': null,
                'amountXWrite': null,
                'currency': null,
            })
            requiredFields = {
                "amount": false,
                'currency': false,
            }
            setAdvanceAmountList(list);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };
    useEffect(() => {
        let advanceObj = advanceAmountList.map((data) => {
            return {
                'Amount': data?.amount,
                'CurrencyId': data?.currency?.id && parseInt(data?.currency?.id)
            }
        });
        setCreateAdvanceRequest({ ...createAdvanceRequest, Amounts: advanceObj })
    }, [advanceAmountList])
    useEffect(() => {
        form.setFieldsValue({
            'AdvanceTypes': 1
        });
    }, [])
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        page: "advanceRequest",
        showVisibilityMenu: false
    })
    const paginationConfig = (tableLength) => ({
        total: tableLength,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: tableLength > 10 ? ['bottomLeft', 'none'] : ['none', 'none'],
    })
    const handleTableChange = () => { }
    const numberOfInstallmentsColumn = [
        {
            title: `${t('NUMBER_OF_INSTALLMENTS')}`,
            dataIndex: 'numberOfInstallments',
            key: 'numberOfInstallments',
        },
        {
            title: `${t('PARTNER_AMOUNT2')}`,
            dataIndex: 'amount',
            key: 'amount',
        },

    ];
    const handleDynamicListInputChange = (e, index, key, inputType) => {
        var list = [...advanceAmountList];
        switch (inputType) {
            case 'select':
                list[index][key] = e;
                return setAdvanceAmountList(list);
            case 'priceInput':
                const val = e.target.value;
                const replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                const parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                list[index][key + "XWrite"] = val;
                return setAdvanceAmountList(list);
            default:
                return null;
        }
    };
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical advance-scrollable"
                requiredMark={true}
            >
                <Form.Item className="pad-top-10 margin-b-12 delete-draw-label" name="AdvanceTypes" label={t('CHOOSE_ADVANCE_TYPE') + " *"} >
                    <Radio.Group options={
                        [
                            { label: `${t('JOB_ADVANCE')}`, value: 1, },
                            { label: `${t('SALARY_ADVANCE')}`, value: 2 },
                        ]
                    } />
                </Form.Item>
                <Form.Item name="AdvanceNeedDate" className="custom-select pad-top-10" label={t('PARTNER_NEED_DATE') + ' *'}>
                    <DatePicker
                        locale={localeLang}
                        format={datePickerFormatType.advanceNeedDate}
                        placeholder={t('PLACEHOLDER_DATE')}
                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'advanceNeedDate', dynamicDateInputFormatter)}
                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'advanceNeedDate', dateFormatList)}
                    />
                </Form.Item>
                <>
                    <Row>
                        <Col className="pad-12" span={24}>
                            <Row>
                                <Col span={10} >
                                    <h3 className="black f-16 fw-600">{t('ADVANCE_AMOUNT') + ' *'}</h3>
                                </Col>
                                <Col span={13} offset={1}>
                                    <h3 className="black f-16 fw-600">{t('PARTNER_CURRENCY') + ' *'}</h3>
                                </Col>
                            </Row>
                            {
                                advanceAmountList.map((item, index) => {
                                    return (
                                        <Row key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": '15px' }} >
                                            <Col span={10} >
                                                <MaskedInput
                                                    className="ant-input"
                                                    placeholder={t('PARTNER_AMOUNT2')}
                                                    mask={priceDecimalWithDot}
                                                    value={item.amountXWrite}
                                                    onChange={(e) => handleDynamicListInputChange(e, index, 'amount', 'priceInput')}
                                                />
                                            </Col>
                                            <Col span={6} offset={1}>
                                                <div className="custom-select">
                                                    <DynamicSelectbox className="w-100" style={{ marginTop: '3px' }}
                                                        value={item.currency}
                                                        optionList={currencyList}
                                                        onFocus={() => getDataset(23)}
                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'currency', 'select')}
                                                        placeholder={t('PARTNER_SELECT')}
                                                    />
                                                </div>
                                            </Col>
                                            {index > 0 &&
                                                <Col offset={1} span={2}>
                                                    <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>
                                                </Col>
                                            }
                                        </Row>
                                    )
                                })
                            }
                            <div>
                                {createAdvanceRequest.AdvanceTypes === 1 &&
                                    <div className="link-text my-4" onClick={() => handleAddNewRow()}>{t('TIME_OFF_REQUEST_NEW_ADD')}</div>
                                }
                            </div>
                        </Col>

                    </Row>
                    {createAdvanceRequest.AdvanceTypes === 1 &&
                        <Form.Item name="RequestCreditCard" valuePropName="checked">
                            <Checkbox>{t('I_REQUEST_A_COMPANY_CREDIT_CARD')}</Checkbox>
                        </Form.Item>
                    }
                    {createAdvanceRequest.AdvanceTypes === 2 &&
                        <>
                            <Form.Item name="RepaymentType" className="custom-select" label={t('FORM_OF_REPAYMENT')}>
                                <DynamicSelectbox className="custom-select"
                                    optionList={repaymentOptions}
                                    orderBy={'asc'}
                                    placeholder={t('PARTNER_SELECT')}
                                />
                            </Form.Item>
                            {createAdvanceRequest.RepaymentType?.id === 1 &&
                                <Form.Item name="NumberOfInstallments" className="custom-select" label={t('NUMBER_OF_INSTALLMENTS')}>
                                    <DynamicSelectbox className="custom-select"
                                        optionList={numberOptions}
                                        orderBy={'asc'}
                                        placeholder={t('PARTNER_SELECT')}
                                    />
                                </Form.Item>
                            }
                            {createAdvanceRequest.RepaymentType?.id === 1 &&
                                <Form.Item name="FirstInstallmentPaymentMonth" className="custom-select" label={t('FIRST_INSTALLMENT_PAYMENT_MONTH')}>
                                    <DatePicker
                                        locale={TR}
                                        format={'MMMM'}
                                        picker="month"
                                        placeholder={t('SELECT_MONTH')}
                                    />
                                </Form.Item>
                            }
                            {numberOfInstallmentState.length > 0 &&
                                <Form.Item label={t('PAYMENT_PLAN')}>
                                    <div className="advance-table">
                                        <DynamicTable columns={numberOfInstallmentsColumn} dataSource={numberOfInstallmentState} center={true}
                                            tableConfig={tableConfig()} paginationConfig={paginationConfig(numberOfInstallmentState.length)} onFilter={() => null}
                                            onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                            isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true} />
                                    </div>
                                </Form.Item>
                            }
                        </>
                    }
                    {createAdvanceRequest.AdvanceTypes === 1 &&
                        <Form.Item name="RelatedTravelIdx" className="custom-select" label={t('TRAVEL_NOTES')}>
                            <DynamicSelectbox className="custom-select"
                                optionList={personTravelList}
                                onFocus={() => getPersonTravel(profile?.resourceId)}
                                placeholder={t('PARTNER_SELECT')}
                            />
                        </Form.Item>
                    }
                    <Form.Item name="Description" className="custom-select" label={t('ADVANCE_DESCRIPTION')}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="Notes" className="custom-select" label={t('NOTES')}>
                        <Input.TextArea />
                    </Form.Item>
                </>
            </Form>
        </ConfigProvider >
    )
}
const mapStateToProps = (state) => {
    return {
        currencyTypes: state.dataset.currencyTypes,
        personTravel: state.travel.personTravel,
        profile: state.profile
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (type) => { dispatch(getDataset(type)) },
        getPersonTravel: (resourceId) => { dispatch(getPersonTravel(resourceId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAdvanceRequestsTab);