import React, { useEffect } from 'react'
import { signinRedirect } from '../../services/userService'
import { Spin} from 'antd'
function ExternalLoginCallback() {

  useEffect(() => {
    signinRedirect();
  }, [])

  return (
    <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"100vh"}}>
      <Spin/>
    </div>
  )
}

export default ExternalLoginCallback