import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

export const ProfileActionTypes = {
    "GET_PROFILE_SUCCESS":"GET_PROFILE_SUCCESS",
    "GET_PERMISSIONS_SUCCESS":"GET_PERMISSIONS_SUCCESS",
    "GET_PERMISSIONS_VIA_LOGIN":"GET_PERMISSIONS_VIA_LOGIN",
    "CHANGE_PASSWORD_SUCCESS":"CHANGE_PASSWORD_SUCCESS",
    "CHANGE_PASSWORD_FAIL":"CHANGE_PASSWORD_FAIL",
    "CHANGE_PASSWORD_RESET":"CHANGE_PASSWORD_RESET"

}
export const getProfile = () => async dispatch => {

    try {
        var config = {
            method: 'get',
            url: apiUrl+'/me',
            headers: { }
        };
        hrApi(config)
        .then(function (response) {
            if(response.status===200){
                dispatch({
                    type: ProfileActionTypes.GET_PROFILE_SUCCESS,
                    payload: response.data.data,
                });
            }
        })
        .catch(function (error) {
            
        });

    } catch (e) {

    }
};

export const getPermissions = () => async dispatch => {

    try {
        var config = {
            method: 'get',
            url: apiUrl+'/users/permissions',
            headers: { }
        };
        hrApi(config)
        .then(function (response) {
            if(response.status===200){
                dispatch({
                    type: ProfileActionTypes.GET_PERMISSIONS_SUCCESS,
                    payload: response.data.data,
                });
            }
        })
        .catch(function (error) {
            if(error.response.status === 404){
                dispatch({ type: ProfileActionTypes.GET_PERMISSIONS_SUCCESS, payload: [] });
            }
        });

    } catch (e) {

    }
};


export function getPermissionsViaLogin(permissions) {
    return {
      type: ProfileActionTypes.GET_PERMISSIONS_VIA_LOGIN,
      payload: permissions
    }
}

export const setChangePasswordDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: 'SET_CHANGE_PASSWORD_DRAWER',
            payload: status,
        });
    } catch (e) {
    }
};

export const changePassword = (passObj) => async dispatch => {

    try {

        var data = JSON.stringify(passObj);

        var config = {
            method: 'post',
            url: apiUrl+'/change-password',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };
        hrApi(config)
        .then(function (response) {
            if(response.status===201){
                dispatch({
                    type: ProfileActionTypes.CHANGE_PASSWORD_SUCCESS,
                    payload: response.data.message,
                });
            }
        })
        .catch(function (error) {
            if(error.response.status === 422){
                dispatch({ type: ProfileActionTypes.CHANGE_PASSWORD_FAIL, payload: error.response.data.message });
            }
        });

    } catch (e) {

    }
};

export const changePasswordReset = () => async dispatch => {
    try {
        dispatch({
            type: ProfileActionTypes.CHANGE_PASSWORD_RESET,
            payload: '',
        });
    } catch (e) {
    }
};