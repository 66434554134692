import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAssignedTaskDetails, updateOrderAssignedTasksList, resetUpdateOrderAssignedTasksList } from "../../../redux/actions/task.actions";

import { Card, Button, Spin, Image } from 'antd';
import { dateFormatConvert } from '../../../utils/config';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { UilDraggabledots, UilPlus, UilEditAlt, UilTrash } from '@iconscout/react-unicons';

import DynamicToastMessage from "../../dynamicToastMessage"; import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";
import Forbidden from "../../forbidden";

import moment from 'moment'
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir
import '../../../styles/dragger-table-card.scss';

function DefinedTasks(props) {
    const { openCreateDefinedTaskDrawer, openDetailDefinedTaskDrawer, deleteDefinedTasksModalData,
        setDeleteDefinedTasksModalData, getAssignedTaskDetails, updateOrderAssignedTasksList, assignedTasksList,
        assignedTasksListLoading, updateOrderAssignedTasksResponse, resetUpdateOrderAssignedTasksList } = props;
    const { t } = useTranslation();
    const [definedTaskList, setDefinedTaskList] = useState([]);
    const openDetails = (resourceId) => {
        //action
        getAssignedTaskDetails(resourceId);
        openDetailDefinedTaskDrawer();
    }
    const openDeleteModal = (name, resourceId) => {
        setDeleteDefinedTasksModalData({ ...deleteDefinedTasksModalData, 'id': resourceId, 'name': name, 'isVisible': true })
    }
    const onSortEnd = ({ oldIndex, newIndex }) => {
        var requestArr = [];
        const resultData = arrayMoveImmutable(definedTaskList, oldIndex, newIndex);
        resultData.map((data, index) => requestArr.push({ taskResourceId: data.resourceId, order: index }));
        setDefinedTaskList(items => arrayMoveImmutable(items, oldIndex, newIndex));
        updateOrderAssignedTasksList({ "tasks": requestArr });
    };
    const DragHandle = SortableHandle(({ style }) => (
        <span style={style} className="sortable-list-icon"><UilDraggabledots /></span>)
    );
    const SortableItem = SortableElement(({ value }) => {
        return <div className="sortable-list-item">
            <Card key={value?.data?.order} className="dragger-table-card" >
                <div className="flex-1">
                    <div className="d-flex">
                        <DragHandle />
                        <a onClick={() => openDetails(value?.data?.resourceId)} className="details-text pl-2">{value?.data?.name}</a>
                    </div>
                </div>
                <div className="flex-1">
                    <span>{value?.data?.ownerInformation}</span>
                </div>
                <div className="flex-1">
                    <span>{value?.data?.dateCreated ? moment(value?.data?.dateCreated).format(dateFormatConvert) : null}</span>
                </div>
                <div className="flex-1 flex-end pr-6">
                    <div onClick={() => openDetails(value?.data?.resourceId)} className="custom-table-card-icon flex-end clickable"><UilEditAlt /></div>
                    <div onClick={() => openDeleteModal(value?.data?.name, value?.data?.resourceId)} className="custom-table-card-icon flex-end clickable pl-6"><UilTrash /></div>
                </div>
            </Card>
        </div>
    });
    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul className="sortable-list-container">
                {items.length > 0 && items.map((value, index) => (
                    <SortableItem key={`item-${value.resourceId}}`} index={index} value={{ 'data': value, 'index': index }} />
                ))}
            </ul>
        );
    });
    useEffect(() => {
        if (updateOrderAssignedTasksResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'order_success_key',
                'title': t('SUCCESS'),
                'description': updateOrderAssignedTasksResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            resetUpdateOrderAssignedTasksList();
        } else if (updateOrderAssignedTasksResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'order_fail_key',
                'title': t('ERROR'),
                'description': updateOrderAssignedTasksResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateOrderAssignedTasksList();
        }
    }, [updateOrderAssignedTasksResponse])
    useEffect(() => {
        if (assignedTasksList.length > 0) {
            setDefinedTaskList(assignedTasksList);
        }
    }, [assignedTasksList])
    return (
        assignedTasksListLoading ?
            <Spin className="flex-center pad-top-40" />
            :
            <>
                <Can I="task.assignedlist">
                    <div className="dragger-table-card-container">
                        <Button onClick={openCreateDefinedTaskDrawer} className="flex-class svg-class primary-btn h-32 w-auto" icon={<UilPlus />}>{t('CREATE_NEW_TASK')}</Button>
                        {
                            definedTaskList.length > 0 ? 
                            <div className="dragger-table-card-class">
                            <div className="dragger-table-card-row ">
                                <Card >
                                    <div className="flex-1">
                                        <span className="pl-8">{t('TASK_NAME')}</span>
                                    </div>
                                    <div className="flex-1">
                                        <span>{t('TASK_OWNER')}</span>
                                    </div>
                                    <div className="flex-1">
                                        <span>{t('TIME_OFF_REQUEST_DATE')}</span>
                                    </div>
                                    <div className="flex-1 flex-end pr-6" />
                                </Card>
                            </div>
                            <SortableList
                                items={definedTaskList}
                                onSortEnd={onSortEnd}
                                axis="y"
                                helperClass="sortable-list-tab sortable-list-detail-container"
                                lockAxis="y"
                                distance={10} 
                            />
                        </div>:
                        <div style={{
                            background: '#fff',
                            marginTop: '20px',
                            border: '2px solid #dfdfdf'
                        }} className="table-empty-container">
                            <div className="table-empty-image">
                            <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
                            </div>
                            <div className="table-empty-text">
                            <h1> {t('NO_DATA_TEXT')}</h1>
                            </div>

                        </div>
                        }
                        
                        
                    </div>
                </Can>
                <Cannot I="task.list">
                    <Forbidden size="sm" />
                </Cannot>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        assignedTasksList: state.taskReducer.assignedTasksList,
        assignedTasksListLoading: state.taskReducer.assignedTasksListLoading,
        updateOrderAssignedTasksResponse: state.taskReducer.updateOrderAssignedTasksResponse
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAssignedTaskDetails: (resourceId) => { dispatch(getAssignedTaskDetails(resourceId)) },
        updateOrderAssignedTasksList: (data) => { dispatch(updateOrderAssignedTasksList(data)) },
        resetUpdateOrderAssignedTasksList: () => { dispatch(resetUpdateOrderAssignedTasksList()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DefinedTasks);