import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { setTimeOffAddDrawer, setTimeOffRequestApproveDrawer } from '../../redux/actions/time-off-drawer-action';
import { getGridSetting, createGridSettings, settingsReset } from '../../redux/actions/setting.actions';
import {
    getTimeOffRequest, getTimeOffRequestStep,
    timeOffDownload, timeOffCancel, resetTimeOffApproveResponseStatus, timeOffApprove,
    timeOffReject, deleteTimeOffRequest, deleteTimeOffRequestReset, getTimeOffTypesList
} from '../../redux/actions/timeOff.actions';
import { getDepartments, getPersons } from '../../redux/actions/organization.actions';
import { getTimeOffStateType } from '../../redux/actions/dataset-action';
import { getTimeOffAllReportFilter, timeOffAllReportDownload, resetTimeOffAllReportDownload } from '../../redux/actions/report.actions';
import { Button, Dropdown, Layout, Form, Drawer, ConfigProvider, Spin, DatePicker } from 'antd';
import { UilCheckCircle, UilTimesCircle, UilQuestionCircle } from '@iconscout/react-unicons';
import { timeoff_dropdown } from '../../features/global-dropdown-menu/index';
import { MoreOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import { drawerWidth, dateFormatConvert, localeLang } from '../../utils/config';

import moment from 'moment';
import 'moment/locale/tr';

import BreadCrumb from '../../features/breadcrump/breadcrump';
import DynamicTable from '../../component/dynamicTable/index';
import DynamicSelectbox from "../../component/dynamicSelectbox";
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';
import DynamicTimeOffModal from '../../component/dynamicTimeOffModal';
import TimeOffAproveTabs from '../../component/timeOffTabs/timeOffRequestPerson/timeOffApproveDrawer/timeOffApproveTabs';

import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";



function TimeOffAllSummary(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [filterForm] = Form.useForm();
    const { totalNumberOfRecords, profile, permissions, getTimeOffRequest, timeOffDownload, timeOffApproveResponse, deleteTimeOffRequestReset, deleteTimeOffRequestResponse,
        resetTimeOffApproveResponseStatus, getTimeOffRequestStep, timeOffAllFilterLoading, timeOffAllFilter, setTimeOffRequestApproveDrawer, deleteTimeOffRequest, timeOffApprove, timeOffReject, timeOffCancel,
        timeOffDrawerReducer, getGridSetting, createGridSettings, settingsReset, createGridSettingsResponse, gridSettings, getDepartments, getPersons, persons, departments, timeOffTypesList, getTimeOffTypesList,
        timeOffStateTypeFilterResult, getTimeOffStateType, getTimeOffAllReportFilter, timeOffAllReportDownload, reportTimeOffAllDataResponse, resetTimeOffAllReportDownload } = props
    const { Content } = Layout;

    const emptyData = {
        "Departments": [],
        "Year": "",
        "Mount": "",
        "Persons": [],
        "TimeOffTypes": [],
        "stateTypes": [],
        "showPassiveRecords": true,
    }
    const initialSearchData = {
        ...emptyData, "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "StartDate",
            "ascending": true
        }
    }
    const initialGetGridSettingData = {
        pageId: 10, //timeOffAll report
        gridId: 1, //persons grid
    }
    const [isShowPassives, setIsShowPassives] = useState(false);
    const [searchData, setSearchData] = useState(initialSearchData);
    const [pageSize, setPageSize] = useState(10);
    const [deleteModalData, setDeleteModalData] = useState({ "isVisible": false });
    const [cancelModalData, setCancelModalData] = useState({ "isVisible": false });
    const [ApproveModalData, setApproveModalData] = useState({ "isVisible": false });
    const [selectedRows, setselectedRows] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData);
    const [filterState, setFilterState] = useState(emptyData);
    const [departmentList, setDepartmentList] = useState([]);
    const [personListState, setPersonListState] = useState([]);
    const [timeOffTypes, setTimeOffTypes] = useState([]);
    const [filterTimeOffStatusList, setFilterTimeOffStatusList] = useState([])
    const key = "timeOffDrawerTabs"


    useEffect(() => {
        getTimeOffAllReportFilter(searchData)
    }, [searchData]);
    useEffect(() => {
        setDepartmentList(departments);
    }, [departments]);
    useEffect(() => {
        setPersonListState(persons)
    }, [persons]);
    useEffect(() => {
        const temp = timeOffTypesList.filter((item) => { return item.isActive === true })?.map((item) => { return { "id": item.id, "displayValue": item.name } })
        setTimeOffTypes(temp)
    }, [timeOffTypesList])
    useEffect(() => {
        var tempData = timeOffStateTypeFilterResult.map((data) => {
            return {
                'id': data.resourceId,
                'displayValue': data.stateTypeName
            }
        })
        setFilterTimeOffStatusList(tempData)
    }, [timeOffStateTypeFilterResult])

    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'APPROVE_SUCCESS',
                'title': t('SUCCESS'),
                'description': timeOffApproveResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (timeOffApproveResponse.resourceId !== undefined) {
                getTimeOffAllReportFilter(searchData)
                handleCloseModal();
            }
            resetTimeOffApproveResponseStatus()

        } else if (timeOffApproveResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'APPROVE_FAIL',
                'title': t('ERROR'),
                'description': timeOffApproveResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            handleCloseModal();
            resetTimeOffApproveResponseStatus()
        }
    }, [timeOffApproveResponse])


    useEffect(() => {
        if (deleteTimeOffRequestResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTimeOffAllReportFilter(searchData)
            deleteTimeOffRequestReset()

        } else if (deleteTimeOffRequestResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_FAIL',
                'title': t('ERROR'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffRequestReset()
        }
    }, [deleteTimeOffRequestResponse])
    useEffect(() => {
        if (reportTimeOffAllDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': reportTimeOffAllDataResponse.message ? JSON.parse(reportTimeOffAllDataResponse.message).message : '',
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTimeOffAllReportDownload();
        }
    }, [reportTimeOffAllDataResponse])

    const handleSaveGridSettings = (d) => {
        let createObj = { "data": d, "pageId": getGridSettingsData.pageId, "gridId": getGridSettingsData.gridId }
        createGridSettings(createObj)
    }
    useEffect(() => {
        getGridSetting(getGridSettingsData)
    }, [getGridSettingsData])
    useEffect(() => {
        if (createGridSettingsResponse.status === 'SUCCESS') {
            getTimeOffAllReportFilter(searchData)
            settingsReset();

        }
    }, [createGridSettingsResponse])

    const handleResetAllfilters = () => {
    }
    const handleTableFilter = (data) => {

    }
    const showTimeOffRequestApproveDrawer = (id, timeOffRequestDate, approveSeqNumber, postId) => {
        setTimeOffRequestApproveDrawer(true);
        if (id !== undefined) {
            getTimeOffRequest(id)
            getTimeOffRequestStep(id)
            setSelectedData({ 'resourceId': id, 'requestDate': timeOffRequestDate, 'approveSeqNumber': approveSeqNumber, 'postId': postId })
        }
    }
    const timeOffCancelBtn = (id) => {
        setCancelModalData({ ...cancelModalData, "isVisible": true, "id": id })
    }
    const timeOffDeletelBtn = (id, timeOffTypeName) => {
        setDeleteModalData({ ...deleteModalData, "isVisible": true, "id": id, 'timeOffTypeName': timeOffTypeName })
    }
    const handleApproveFormValuesChange = (changedObj, all) => {
        setApproveModalData((prevState) => ({
            ...prevState,
            'description': changedObj['description']
        }))
    }
    const columns = [
        {
            title: `${t('TIME_OFF_NAME_SURNAME')}`,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
            render: obj =>
                <div className="imageNameActionColumn">
                    <Can I="timeoff.request.all.read">
                        <a onClick={() => showTimeOffRequestApproveDrawer(obj.id, obj.timeOffRequestDate, obj.approveSeqNumber, obj.postId)} className="details-text mar-0" >{obj.name}</a>
                    </Can>
                    <Cannot I="timeoff.request.all.read">
                        <span style={{ paddingLeft: '15px' }} >{obj.name}</span>
                    </Cannot>
                    {permissions.some(item => ["timeoff.request.cancel", "timeoff.request.export", "timeoff.request.delete"].includes(item)) &&
                        <Dropdown
                            className="action-menu"
                            overlay={timeoff_dropdown(t, timeOffCancelBtn, timeOffDownload, timeOffDeletelBtn, obj.id, obj.stateTypeId, obj.timeOffTypeName, permissions)}
                            trigger={['click']}
                        >
                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                        </Dropdown>
                    }
                </div>
        },
        {
            title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
            dataIndex: 'timeOffRequestDate',
            key: 'timeOffRequestDate',
            sorter: true,
            render: obj =>
                <div className="imageNameActionColumn">
                    <p className="mar-0" >{moment(obj).format('LL')}</p>

                </div>
        },
        {
            title: `${t('TIME_OFF_TYPE_TEXT')}`,
            dataIndex: 'timeOffTypeName',
            key: 'timeOffTypeName',
            sorter: true,
        },
        {
            title: `${t('PARTNER_DEPARTMENT')}`,
            dataIndex: 'departmentName',
            key: 'departmentName',
            sorter: true,
        },
        {
            title: `${t('ORGANIZATION_STATU')}`,
            dataIndex: 'stateType',
            key: 'stateType',
            sorter: true,
            render: state => {
                if (state.id === '1') {
                    return <div className='green'>{state.displayValue}</div>
                } else if (state.id === '2') {
                    return <div>{state.displayValue}</div>
                } else if (state.id === '3') {
                    return <div className='red'>{state.displayValue}</div>
                } else if (state.id === '4') {
                    return <div className='blue'>{state.displayValue}</div>
                } else {
                    return <div >{state?.displayValue}</div>
                }
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_START_DATE')}`,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            render: obj => {
                return (moment(obj).format('LL'))
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_END_DATE')}`,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
            render: obj => {
                return (moment(obj).format('LL'))
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_TIME')}`,
            dataIndex: 'balance',
            key: 'balance',
            sorter: true,
            render: obj => {
                if (obj.unitId === 1) {
                    return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj.balance })}`
                } else {
                    return `${t('TIME_OFF_REQUEST_HOURS', { "hour": obj.balance })}`
                }
            }
        },
        {
            title: `${t('TIME_OFF_REQUEST_REMAINING')}`,
            dataIndex: 'remainderBalance',
            key: 'remainderBalance',
            sorter: true,
            render: obj => {
                if (obj.unitId === 1) {
                    return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj.remainderBalance })}`
                } else {
                    return `${t('TIME_OFF_REQUEST_HOURS', { "hour": obj.remainderBalance })}`
                }
            }
        },

        {
            title: `${t('APPROVAL_PERSON_TIME_OFF')}`,
            dataIndex: "personToApprove",
            key: "personToApprove",
            sorter: true,
        }


    ];
    const data = timeOffAllFilter.map((row) => {
        return (
            {

                "key": row.resourceId,
                "fullName": { 'id': row.resourceId, 'name': row.fullName, 'stateTypeId': row.stateType?.id, 'timeOffRequestDate': row.timeOffRequestDate, 'approveSeqNumber': row.approveSeqNumber, 'postId': row.timeOffRequestId },
                "timeOffRequestDate": row.timeOffRequestDate,
                "timeOffTypeName": row.timeOffTypeName,
                "departmentName": row?.departmentName,
                "startDate": row.startDate,
                "endDate": row.endDate,
                "balance": { "balance": row.balance, "unitId": row.unitSetIdx },
                "remainderBalance": { "remainderBalance": row.remainderBalance, "unitId": row.unitSetIdx },
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue },
                "personToApprove": row.personToApprove
            }
        );

    })
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: ['fullName', 'timeOffTypeName', 'departmentName', 'timeOffRequestDate', 'startDate', 'endDate', 'balance', 'remainderBalance', 'stateType', 'personToApprove'],
        visibilityOptionExcludes: ['fullName', 'timeOffTypeName', 'departmentName', 'startDate', 'endDate', 'balance', 'remainderBalance', 'stateType', 'personToApprove'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "TimeOffAllSummary",
        showVisibilityMenu: true,

    })



    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: totalNumberOfRecords > 10 ? true : false,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'bottomLeft'] : ['none', 'bottomLeft'],
        showTotal: total => t('TOTAL_RECORDS_FOUND', {total : total})

    })

    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleCloseModal = () => {
        setApproveModalData({ ...ApproveModalData, "isVisible": false, "description": null })
        form.setFieldsValue({ 'description': null })
    }
    const handleTableChange = (data) => {

        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : searchData.pagination.orderBy) }

        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }

    const handleShowPassivesToogle = (e) => { }
    const handleConfirm = () => {

        if (ApproveModalData.confirmBtnFunc === 'SINGLE_APPROVE') {
            const approveObj = {
                "processStepList":
                    [
                        {
                            "timeOffRequestId": ApproveModalData.id,
                            "approveSeqNumber": ApproveModalData.approveSeqNumber,
                            "description": ApproveModalData.description
                        }
                    ]
            }
            timeOffApprove({ "approveData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'SINGLE_REJECT') {
            const approveObj = {
                "processStepList":
                    [
                        {
                            "timeOffRequestId": ApproveModalData.id,
                            "approveSeqNumber": ApproveModalData.approveSeqNumber,
                            "description": ApproveModalData.description
                        }
                    ]
            }
            timeOffReject({ "rejectData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'BULK_APPROVE') {
            const approveObj = {};
            const processStepList = [];

            selectedRows.forEach((item) => {
                processStepList.push({
                    "timeOffRequestId": item?.stateType?.statusObj?.id,
                    "approveSeqNumber": item?.stateType?.statusObj?.approveSeqNumber,
                    "description": ApproveModalData.description
                })
            })

            approveObj["processStepList"] = processStepList

            timeOffApprove({ "approveData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'BULK_REJECT') {
            const approveObj = {};
            const processStepList = [];

            selectedRows.forEach((item) => {
                processStepList.push({
                    "timeOffRequestId": item?.stateType?.statusObj?.id,
                    "approveSeqNumber": item?.stateType?.statusObj?.approveSeqNumber,
                    "description": ApproveModalData.description
                })
            })

            approveObj["processStepList"] = processStepList

            timeOffReject({ "rejectData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'SINGLE_CANCEL') {
            timeOffCancel({ "resourceId": selectedData.resourceId, "key": key },)
        }

    }
    const handleTimeOffChangeStatus = (statusObj, decision, isApprovePage) => {

        if (decision === 'APPROVE') {
            var modalDesc = '';
            if (statusObj.personResourceId === profile.resourceId || isApprovePage) {
                modalDesc = t('TIMEOFF_SINGLE_APPROVE_TEXT', { "person": statusObj.fullName, "requestDate": moment(statusObj.requestDate).format(dateFormatConvert), "timeOffTypeName": statusObj.timeOffTypeName })
            } else {
                modalDesc = t('TIMEOFF_SINGLE_APPROVE_TEXT_NEW', { "person": statusObj.fromName });
            }
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilCheckCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('TIME_OFF_REQUEST_CONFIRM'),
                "confirmBtnFunc": "SINGLE_APPROVE",
                "modalTitle": t('TIME_OFF_REQUEST_CONFIRM'),
                "modalDesc": modalDesc,
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj.id,
                "approveSeqNumber": statusObj.approveSeqNumber,
                "description": null,
                'descriptionShow': true


            })

        } else if (decision === 'REJECT') {
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilTimesCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('TIME_OFF_REQUEST_REJECT'),
                "confirmBtnFunc": "SINGLE_REJECT",
                "modalTitle": t('TIME_OFF_REQUEST_REJECT'),
                "modalDesc": t('TIMEOFF_SINGLE_REJECT_TEXT', { "person": statusObj.fullName, "requestDate": moment(statusObj.requestDate).format(dateFormatConvert), "timeOffTypeName": statusObj.timeOffTypeName }),
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj.id,
                "approveSeqNumber": statusObj.approveSeqNumber,
                "description": null,
                'descriptionShow': true

            })
        } else if (decision === 'CANCEL') {
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilQuestionCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_NO_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('PARTNER_YES_CANCEL'),
                "confirmBtnFunc": "SINGLE_CANCEL",
                "modalTitle": t('TIME_OFF_CANCEL_BTN'),
                "modalDesc": "",
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj,
                "approveSeqNumber": null,
                "description": null,
                'descriptionShow': false
            })
        } else if (decision === 'BULK_APPROVE') {
            if (selectedRowKeys.length > 0) {
                setApproveModalData({
                    ...ApproveModalData,
                    "onModalCancel": handleCloseModal,
                    "icon": <UilCheckCircle className="dynamic-modal-icon" size={50} />,
                    "cancelBtnLbl": t('PARTNER_CANCEL'),
                    "cancelBtnFunc": handleCloseModal,
                    "confirmBtnLbl": t('TIME_OFF_REQUEST_CONFIRM'),
                    "confirmBtnFunc": "BULK_APPROVE",
                    "modalTitle": t('TIME_OFF_REQUEST_CONFIRM'),
                    "modalDesc": t('TIMEOFF_BULK_APPROVE_TEXT', { "count": selectedRowKeys.length }),
                    "handleValuesChange": handleApproveFormValuesChange,
                    "isVisible": true,
                    "id": null,
                    "approveSeqNumber": null,
                    "description": null,
                    'descriptionShow': true

                })
            } else {
                DynamicToastMessage({
                    'key': 'BULK_APPROVE_NOT_SELECTED',
                    'title': t('WARNING'),
                    'description': t('NOT_SELECTED_ROW_WARNING'),
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }

        } else if (decision === 'BULK_REJECT') {
            if (selectedRowKeys.length > 0) {
                setApproveModalData({
                    ...ApproveModalData,
                    "onModalCancel": handleCloseModal,
                    "icon": <UilTimesCircle className="dynamic-modal-icon" size={50} />,
                    "cancelBtnLbl": t('PARTNER_CANCEL'),
                    "cancelBtnFunc": handleCloseModal,
                    "confirmBtnLbl": t('TIME_OFF_REQUEST_REJECT'),
                    "confirmBtnFunc": "BULK_REJECT",
                    "modalTitle": t('TIME_OFF_REQUEST_REJECT'),
                    "modalDesc": t('TIMEOFF_BULK_REJECT_TEXT', { "count": selectedRowKeys.length }),
                    "handleValuesChange": handleApproveFormValuesChange,
                    "isVisible": true,
                    "id": null,
                    "approveSeqNumber": null,
                    "description": null,
                    'descriptionShow': true

                })
            } else {
                DynamicToastMessage({
                    'key': 'BULK_APPROVE_NOT_SELECTED',
                    'title': t('WARNING'),
                    'description': t('NOT_SELECTED_ROW_WARNING'),
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }
        }
    }
    const downloadFile = (type) => {
        timeOffAllReportDownload({ ...searchData, 'exportType': type })
    }
    const handleValuesChange = (changedObj, all) => {
        setFilterState({ ...filterState, ...changedObj });
    }
    const searchAction = () => {
        const personIdList = filterState?.person ? filterState?.person.map((data => { return data.id })) : [];
        const timeOffTypesList = filterState?.timeOffTypes ? filterState?.timeOffTypes.map((data => { return parseInt(data.id) })) : [];
        const departmentsIdList = filterState?.departments ? filterState?.departments.map((data => { return data.id })) : [];
        const stateTypesList = filterState?.stateTypes ? filterState?.stateTypes.map((data => { return data.id })) : [];


        const filterData = {
            'Departments': departmentsIdList,
            'Year': filterState?.time ? moment(filterState?.time).format('YYYY') : null,
            'Mount': filterState?.time ? parseInt(filterState?.time.format('MM').replace(/^(?:00:)?0?/, '')) : null,
            'Persons': personIdList,
            'TimeOffTypes': timeOffTypesList,
            'stateTypes': stateTypesList
        }
        setSearchData({
            ...filterData, "pagination": initialSearchData.pagination,
        });
    }

    const resetFilter = () => {
        filterForm.resetFields();
        setFilterState(emptyData)
        setSearchData({
            ...emptyData, "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "StartDate",
                "ascending": true,
                "showPassiveRecords": true,

            }
        });
    }
    const isChanged = () => {
        if (searchData.TimeOffTypes?.length > 0 || searchData.Departments?.length > 0 || searchData.Persons?.length > 0 || searchData.stateTypes?.length > 0 || searchData.Year || searchData.Mount) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <React.Fragment>
            <Drawer title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={timeOffDrawerReducer.timeOffRequestApproveDrawer} >
                <TimeOffAproveTabs selectedData={selectedData} handleTimeOffChangeStatus={handleTimeOffChangeStatus} page="timeOffMy" />
            </Drawer >
            <DynamicTimeOffModal form={form} ApproveModalData={ApproveModalData} handleConfirm={() => handleConfirm()} />
            <DynamicRequestModal
                key={'TIME_OFF_CANCEL_BTN-'+'delete-modal'}
                modalVisible={cancelModalData?.isVisible}
                setStateData={setCancelModalData}
                postAction={timeOffCancel}
                confirmData={{ "resourceId": cancelModalData?.id, "key": key }}
                iconName='UilQuestionCircle'
                modalTitle={t('TIME_OFF_CANCEL_BTN')}
                description={t('')}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <DynamicRequestModal
                key={deleteModalData?.id+'delete-modal'}
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteTimeOffRequest}
                confirmData={deleteModalData?.id}
                iconName='UilTrash'
                modalTitle={t('SETTINGS_DELETE')}
                description={t('TIMEOFF_DELETE_MODAL_DESCRIPTION', { "timeOffType": deleteModalData?.timeOffTypeName })}
                cancelBtnTxt={t('PARTNER_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <ToastContainer />
            <React.Fragment>
                <Layout>
                    <Content className="page-container report-container">
                        <BreadCrumb />
                        <div className="tab-menu">
                            <h1 className="page-title">{t('ALL_PERMISSIONS_REPORT')}</h1>
                            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                                <div className="w-100 flex-end pad-12">
                                    <Button onClick={() => downloadFile(0)} className="w-120 white-btn margin-r-12">Excel</Button>
                                    <Button onClick={() => downloadFile(2)} className="w-120 white-btn">PDF</Button>
                                </div>
                                <div className="filter-container table-details">
                                    {timeOffAllFilterLoading ?
                                        <Spin className="filter-loading" ></Spin> :
                                        <>
                                            <Form
                                                form={filterForm}
                                                onValuesChange={handleValuesChange}
                                                requiredMark={true}
                                                layout="vertical"
                                            >
                                                <Form.Item name="timeOffTypes" className="custom-select" label={t('TIME_OFF_TYPE_TEXT')}>
                                                    <DynamicSelectbox
                                                        isMultiple='true'
                                                        optionList={timeOffTypes}
                                                        onFocus={() => getTimeOffTypesList()}
                                                        placeholder={t('TIME_OFF_TYPE_TEXT')}
                                                        orderBy={'none'}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="departments" className="custom-select" label={t('PARTNER_DEPARTMENT')}>
                                                    <DynamicSelectbox
                                                        isMultiple='true'
                                                        optionList={departmentList}
                                                        onFocus={() => getDepartments(profile.organization?.resourceId)}
                                                        placeholder={t('PARTNER_DEPARTMENT')}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="person" className="custom-select" label={t('EMPLOYEE_NAME')}>
                                                    <DynamicSelectbox
                                                        isMultiple='true'
                                                        optionList={personListState}
                                                        onFocus={() => getPersons(profile.organization?.resourceId)}
                                                        placeholder={t('EMPLOYEE_NAME')}
                                                    />
                                                </Form.Item>

                                                <Form.Item name="time" label={t('PARTNER_YEARS') + " - " + t('PARTNER_MONTH')}>
                                                    <DatePicker
                                                        picker="month"
                                                        placeholder={t('PARTNER_YEARS') + " - " + t('PARTNER_MONTH')}
                                                        inputReadOnly={true}
                                                        locale={localeLang}
                                                        format="YYYY-MMMM"
                                                    />
                                                </Form.Item>
                                                <Form.Item name="stateTypes" className="custom-select" label={t('ORGANIZATION_STATU')} >
                                                    <DynamicSelectbox
                                                        isMultiple='true'
                                                        optionList={filterTimeOffStatusList}
                                                        onFocus={() => getTimeOffStateType({
                                                            "searchTerm": "",
                                                            "pagination": {
                                                                "pageNumber": 1,
                                                                "pageSize": 999,
                                                                "orderBy": "StateTypeName",
                                                                "ascending": true
                                                            }
                                                        })}
                                                        placeholder={t('ORGANIZATION_STATU')}
                                                    />
                                                </Form.Item>
                                            </Form>
                                            <div className="filter-button">
                                                <Button onClick={searchAction} className="w-120 primary-btn margin-r-12">{t('SEARCH')}</Button>
                                                {isChanged() &&
                                                    <Button onClick={resetFilter} className="w-120 white-btn margin-r-12">{t('PARTNER_CLEAR')}</Button>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </ConfigProvider>
                            <div className="table-container">
                                <DynamicTable
                                    columns={columns} dataSource={data}
                                    tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                                    onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
                                    searchPlaceholder=""
                                    onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                                    onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                    searchBox={false}
                                    checkboxStatus={false}
                                    passiveButton={false}
                                    loadStatus={timeOffAllFilterLoading}

                                />
                            </div>
                        </div>
                    </Content>
                </Layout >
            </React.Fragment >
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        timeOffAllFilter: state.report.timeOffAllFilter.results,
        timeOffAllFilterLoading: state.report.timeOffAllFilterLoading,
        totalNumberOfRecords: state.report.timeOffAllFilter.totalNumberOfRecords,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        profile: state.profile,
        permissions: state.profile.permissions,
        deleteTimeOffRequestResponse: state.timeOff.deleteTimeOffRequestResponse,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        createGridSettingsResponse: state.setting.createGridSettingsResponse,
        persons: state.organization.persons,
        departments: state.organization.departments,
        timeOffTypesList: state.timeOff.timeOffTypes,
        timeOffStateTypeFilterResult: state.dataset.timeOffStateType.results,
        reportTimeOffAllDataResponse: state.report.reportTimeOffAllDataResponse

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        setTimeOffAddDrawer: (props) => { dispatch(setTimeOffAddDrawer(props)) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        timeOffApprove: (approveData) => { dispatch(timeOffApprove(approveData)) },
        timeOffReject: (approveData) => { dispatch(timeOffReject(approveData)) },
        timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)) },
        timeOffDownload: (props) => { dispatch(timeOffDownload(props)) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        deleteTimeOffRequest: (resourceId) => { dispatch(deleteTimeOffRequest(resourceId)) },
        deleteTimeOffRequestReset: () => { dispatch(deleteTimeOffRequestReset()) },
        getGridSetting: (props) => { dispatch(getGridSetting(props)) },
        createGridSettings: (props) => { dispatch(createGridSettings(props)) },
        settingsReset: () => { dispatch(settingsReset()) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        getDepartments: (id) => { dispatch(getDepartments(id)) },
        getTimeOffTypesList: () => { dispatch(getTimeOffTypesList()) },
        getTimeOffStateType: (searchData) => { dispatch(getTimeOffStateType(searchData)) },
        getTimeOffAllReportFilter: (searchData) => { dispatch(getTimeOffAllReportFilter(searchData)) },
        timeOffAllReportDownload: (data) => { dispatch(timeOffAllReportDownload(data)) },
        resetTimeOffAllReportDownload: () => { dispatch(resetTimeOffAllReportDownload()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeOffAllSummary);
