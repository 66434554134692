import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Checkbox, ConfigProvider, Spin } from 'antd';

import { getOrganizationDocumentType } from '../../../redux/actions/organization.actions';

function AddUserDocument(props) {
    const { t } = useTranslation();
    const { organizationDocumentTypeList, getOrganizationDocumentType, organizationDocumentTypeLoading, setCreateObject } = props;
    const [documentListState, setDocumentListState] = useState();
    const [checkboxState, setCheckboxState] = useState({ 'checkAll': false, 'indeterminate': false })
    const [form] = Form.useForm();

    useEffect(() => {
        getOrganizationDocumentType();
    }, [])

    useEffect(() => {
        const checkedDocument = organizationDocumentTypeList.length > 0 ? organizationDocumentTypeList.filter(item => item.isRequired) : []
        const checkedList = checkedDocument ? checkedDocument.map(item => item.resourceId) : [];
        form.setFieldsValue({
            "document": checkedList,
        })
    }, [form, organizationDocumentTypeList])

    useEffect(() => {
        setDocumentListState({
            'documentRequest':
                organizationDocumentTypeList.filter(item => item.isRequired).map((data) => {
                    return { 'documentResourceId': data.resourceId }
                })

        })
        if (organizationDocumentTypeList.filter(item => item.isRequired).length > 0) {
            setCheckboxState({ ...checkboxState, indeterminate: true })
        } else {
            setCheckboxState({ ...checkboxState, indeterminate: false })
        }
    }, [organizationDocumentTypeList]);


    const handleValuesChange = (changedObj) => {
        if (Object.keys(changedObj)[0] === "document") {

            const activeData = changedObj["document"];
            const newDocument = organizationDocumentTypeList.map((obj) => {
                if (activeData.includes(obj.resourceId)) {
                    return { ...obj, "isRequired": true }
                } else {
                    return { ...obj, "isRequired": false }
                }
            })
            const compareData = newDocument.filter(item => item.isRequired).map((data) => {
                return { 'documentResourceId': data.resourceId }
            })
            if (compareData.length !== 0 && compareData.length === organizationDocumentTypeList.length) {
                setCheckboxState({ ...checkboxState, indeterminate: false, checkAll: true })
            } else if (compareData.length === 0) {
                setCheckboxState({ ...checkboxState, indeterminate: false, checkAll: false })
            } else {
                setCheckboxState({ ...checkboxState, indeterminate: true, checkAll: false })
            }
            setDocumentListState({ 'documentRequest': compareData });
        }

    }
    useEffect(() => {
        setCreateObject(documentListState)
    }, [documentListState]);


    const onCheckAllChange = e => {
        const status = e.target.checked;
        const documentList = organizationDocumentTypeList ? organizationDocumentTypeList.map(item => ({ 'documentResourceId': item.resourceId })) : [];
        const checkedDocument = organizationDocumentTypeList.length > 0 ? organizationDocumentTypeList.filter(item => item.isRequired) : []
        const checkedList = checkedDocument ? checkedDocument.map(item => ({ 'documentResourceId': item.resourceId })) : [];
        var indeterminateStatus = null
        if (organizationDocumentTypeList.filter(item => item.isRequired).length > 0) {
            indeterminateStatus = checkedList.length > 0 && checkboxState.indeterminate === true
        } else {
            indeterminateStatus = checkedList.length > 0 && documentList.length === checkedList.length ? false : true
        }
        setDocumentListState(status ? { ...documentListState, 'documentRequest': documentList } : { ...documentListState, 'documentRequest': checkedList });
        setCheckboxState({ ...checkboxState, indeterminate: indeterminateStatus ? false : true, checkAll: status })
        form.setFieldsValue({
            "document": status ? documentList.map((data) => { return data.documentResourceId }) : checkedList.map((data) => { return data.documentResourceId })
        })


    };
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
            >
                <div className="border-none">
                    {organizationDocumentTypeLoading ?
                        <Spin />
                        :
                        <React.Fragment>
                            {organizationDocumentTypeList.length > 0 ?
                                <>
                                    <label className="ant-form-item-required flex-class pad-12">{t('PARTNER_DOCUMENTS_REQUIRED_FROM_PERSON')}</label>

                                    <Checkbox indeterminate={checkboxState.indeterminate} onChange={onCheckAllChange} checked={checkboxState.checkAll}>{t('SELECT_ALL')}</Checkbox>
                                </>
                                :
                                <label className="ant-form-item-required flex-class pad-12"> {t("REQUIRED_DOCUMENT_NOT_FOUND")} </label>
                            }
                            <Form.Item className="add-user-document-checkbox" name="document" label=''>
                                <Checkbox.Group>
                                    {organizationDocumentTypeList.map(item => {
                                        return (
                                            <Row className="w-100" key={item.resourceId}>
                                                <Col size={24}>
                                                    <Checkbox value={item.resourceId} disabled={item.isRequired}>{item.documentTypeName}</Checkbox>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </React.Fragment>
                    }
                </div>
            </Form>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        organizationDocumentTypeList: state.organization.organizationDocumentTypeList,
        organizationDocumentTypeLoading: state.organization.organizationDocumentTypeLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationDocumentType: () => { dispatch(getOrganizationDocumentType()) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserDocument);