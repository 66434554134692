import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getCreateAssignedForm, resetCreateAssignedForm, resetAssignedTasksStepList, taskUpdateStatus, resetTaskUpdateStatus,
    assignedTaskAndAdvanceAction, resetAssignedTaskAndAdvanceAction, setCreateTaskDrawer, getTaskAssignedList, getAssignedTasksStepList
} from "../../../../redux/actions/task.actions";

import { Col, Row, Button, List, Avatar, Spin, Input } from 'antd';
import { UilMultiply, UilAngleRightB, UilAngleLeftB, UilSortAmountDown, UilSortAmountUp } from '@iconscout/react-unicons';

import Successful from "../../../successful";
import Loading from "../../../loading";
import CreateTaskRequestsTab from './create_task_requests_tab';
import DynamicToastMessage from '../../../dynamicToastMessage/index';
import { WaitIcon, SuccessIcon } from "../../../../features/Icon/Icons";
import { MergeObjects, turkishCharacterRegex } from "../../../../utils/commonFormFunctions";

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/table.scss'
import '../../../../styles/task-drawer.scss';

function CreateTaskTabs(props) {
    const { t } = useTranslation();
    const { setCreateTaskDrawer, getStepListTasks, getStepListTasksLoading, personId, getCreateAssignedForm, resetCreateAssignedForm,
        assignedTaskAndAdvanceAction, resetAssignedTaskAndAdvanceAction, assignedTaskAndAdvanceActionResponse, getTaskAssignedList,
        assignedTaskRequestState, resetAssignedTasksStepList, taskUpdateStatus, resetTaskUpdateStatus, taskUpdateStatusResponse, getAssignedTasksStepList } = props;
    const [currentTab, setCurrentTab] = useState(0); // tab state
    const [pageIndex, setPageIndex] = useState(1); //check complete index
    const [formStatus, setFormStatus] = useState("wait");
    const [updateCount, setUpdateCount] = useState(0);
    const [personIdState, setPersonIdState] = useState(personId);
    const [taskCount, setTaskCount] = useState({
        assignedTaskCount: 0,
        totalTaskCount: 0
    });
    const [dynamicTitle, setDynamicTitle] = useState("")
    const [createTaskObj, setCreateTaskObj] = useState({
        personResourceId: "",
        data: {}
    });
    const [assignedTasksListState, setAssignedTasksListState] = useState([]);
    const [sortStatus, setSortStatus] = useState(true);

    // if personId have currentTab 1 
    // if dont start 0 and choose  after start 1 arr 
    useEffect(() => {
        if (getStepListTasks?.personTask?.length > 0) {
            if (personIdState && personIdState?.length > 3) {
                setCurrentTab(1);
            }
            var list = [];
            getStepListTasks?.personTask.map(data => {
                if (!data.isAssigned)
                    return list.push({
                        'resourceId': data.taskResourceId,
                        'name': data.taskName,
                        'isAssigned': data?.isAssigned,
                        'assignedTaskResourceId': "",
                        'show': true
                    });
            });
            setAssignedTasksListState(list);
            setTaskCount({
                assignedTaskCount: getStepListTasks?.assignedTaskCount,
                totalTaskCount: getStepListTasks?.totalTaskCount
            });
            setDynamicTitle(t('DEFAULT_ASSIGNMENT_TASK_MESSAGE'));
        };
    }, [getStepListTasks, personIdState])

    const searchResourceId = (targetId) => {
        if (!Object.keys(createTaskObj.data).includes(targetId)) {
            resetCreateAssignedForm();
            getCreateAssignedForm(targetId);
        } else {
            return false;
        }
    };
    useEffect(() => {
        if (!personIdState) {
            getAssignedTasksStepList('');
        }
    }, [personIdState])
    useEffect(() => {
        if (assignedTasksListState.length > 0) {
            if (createTaskObj.data.length === 0) {
                return getCreateAssignedForm(assignedTasksListState[currentTab - 1]?.resourceId);
            } else {
                searchResourceId(assignedTasksListState[currentTab - 1]?.resourceId);
            }
        }
    }, [currentTab])
    useEffect(() => {
        if (assignedTaskAndAdvanceActionResponse.data?.message === "SUCCESS") {
            var list = [...assignedTasksListState];
            list[currentTab - 1].isAssigned = true;
            list[currentTab - 1].assignedTaskResourceId = assignedTaskAndAdvanceActionResponse?.data?.data?.assignedTaskResourceId;
            setAssignedTasksListState(list);
            setDynamicTitle(assignedTaskAndAdvanceActionResponse?.data?.data?.displayMessage);
            setTaskCount({
                assignedTaskCount: assignedTaskAndAdvanceActionResponse?.data?.data?.assignedTaskCount,
                totalTaskCount: assignedTaskAndAdvanceActionResponse?.data?.data?.totalTaskCount
            });
            setUpdateCount(updateCount + 1);
            if (pageIndex === 2) {
                setFormStatus("done");
            }
            nextBtn();
            resetAssignedTaskAndAdvanceAction();
        } else if (assignedTaskAndAdvanceActionResponse.data?.message === "FAIL") {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': assignedTaskAndAdvanceActionResponse.data?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetAssignedTaskAndAdvanceAction();
        }
    }, [assignedTaskAndAdvanceActionResponse, pageIndex])
    useEffect(() => {
        if (taskUpdateStatusResponse.status === "SUCCESS") {
            var list = [...assignedTasksListState];
            list[currentTab - 1].isAssigned = false;
            list[currentTab - 1].assignedTaskResourceId = "";
            setAssignedTasksListState(list);
            setDynamicTitle(assignedTaskAndAdvanceActionResponse?.data?.data?.displayMessage);
            setTaskCount({
                assignedTaskCount: taskCount.assignedTaskCount - 1,
                totalTaskCount: taskCount.totalTaskCount
            });
            setUpdateCount(updateCount + 1);
            DynamicToastMessage({
                'key': 'task_update_status_response_success_key',
                'title': t('SUCCESS'),
                'description': taskUpdateStatusResponse.data?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            resetTaskUpdateStatus();
        } else if (taskUpdateStatusResponse.status === "FAIL") {
            DynamicToastMessage({
                'key': 'task_update_status_response_error_key',
                'title': t('ERROR'),
                'description': taskUpdateStatusResponse.data?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTaskUpdateStatus();
        }
    }, [taskUpdateStatusResponse])
    const resetForm = () => {
        setCreateTaskObj({
            personResourceId: "",
            data: {}
        });
        setPageIndex(1);
        setTaskCount({
            assignedTaskCount: 0,
            totalTaskCount: 0
        })
        setAssignedTasksListState([]);
        resetCreateAssignedForm();
        setFormStatus("wait");
        setCurrentTab(0);
        if (!personIdState && updateCount > 0) {
            getTaskAssignedList(assignedTaskRequestState);
        }
        if (personIdState) {
            setPersonIdState(null);
        }
        resetAssignedTasksStepList();
    }
    const closeDrawerControl = () => {
        setCreateTaskDrawer(false);
        resetForm();
    }
    const createNewTask = () => {
        resetForm();
        getAssignedTasksStepList('');
    }
    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createTaskObj, obj);
        setCreateTaskObj(mergedObj);
    }
    const nextBtn = () => {
        if (currentTab !== getStepListTasks.totalTaskCount) {
            if (createTaskObj?.person?.id || personId) {
                setCurrentTab((i) => i + 1);
            } else {
                DynamicToastMessage({
                    'key': 'required_warning',
                    'title': t('WARNING'),
                    'description': t('TASK_SELECT_THE_PERSON'),
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }
        }
    }
    const prevBtn = () => {
        if (currentTab > 1) {
            setCurrentTab((i) => i - 1);
        }
    }
    const tabOnClick = (index) => {
        if (currentTab > 0) {
            setCurrentTab(index)
        }
    }
    const closeTaskRequest = () => {
        setPageIndex(2);
        setFormStatus("done");
    }

    const [isAssignLoading, setisAssignLoading] = useState(false);

    const assignedTaskAndAdvance = () => {

        if(isAssignLoading) return;
        setisAssignLoading(true);
        setTimeout(() => {setisAssignLoading(false);}, 1055) // double click prevent;

        let updateObj = {}
        var attributeList = [];
        createTaskObj.data[assignedTasksListState[currentTab - 1]?.resourceId].map(data =>
            data.resourceId === "description_" + assignedTasksListState[currentTab - 1]?.resourceId ?
                updateObj = { ...updateObj, 'description': data.value } :
                attributeList.push({
                    "attributeResourceId": data.resourceId,
                    "attributeValue": data.value,
                    "attributeValueResourceId": ""
                })
        );
        updateObj = {
            ...updateObj,
            taskResourceId: assignedTasksListState[currentTab - 1]?.resourceId,
            personResourceId: personIdState ? personIdState : createTaskObj?.person?.id,
            groupId: getStepListTasks?.groupId,
            attributes: attributeList,
        }
        assignedTaskAndAdvanceAction(updateObj);
        attributeList = [];
    }
    const undoTask = () => {
        let updateObj = {}
        let description = createTaskObj.data[assignedTasksListState[currentTab - 1]?.resourceId].filter(filterData => filterData.resourceId === "description_" + assignedTasksListState[currentTab - 1]?.resourceId)[0]?.value;
        updateObj = {
            state: 8,
            resourceId: assignedTasksListState[currentTab - 1]?.assignedTaskResourceId,
            description: description
        };
        taskUpdateStatus(updateObj);
    }
    const handleSearch = (e) => {
        const searchText = e.target.value;
        const modifiedSearchText = turkishCharacterRegex(searchText);
        const updatedData = assignedTasksListState.map(item => {
            const modifiedItemName = turkishCharacterRegex(item.name);
            if (modifiedItemName.toLowerCase().includes(modifiedSearchText.toLowerCase())) {
                return { ...item, show: true };
            } else {
                return { ...item, show: false };
            }
        });
        setAssignedTasksListState(updatedData);
    };
    const sortAscending = () => {
        const sortList = [...assignedTasksListState].sort((a, b) => a.name.localeCompare(b.name));
        setAssignedTasksListState(sortList);

        const taskArr = Object.keys(createTaskObj.data);
        if (Array.isArray(taskArr) && taskArr.length > 0) {
            const filtered = taskArr.filter(filterData => {
                return filterData === sortList[0].resourceId;
            });
            if (filtered.length === 0) {
                return getCreateAssignedForm(sortList[0]?.resourceId);
            } else {
                searchResourceId(sortList[0]?.resourceId);
            }
        } else {
            console.log("obj is not an array or is empty.");
        }
    };
    const sortDescending = () => {
        const sortList = [...assignedTasksListState].sort((a, b) => b.name.localeCompare(a.name));
        setAssignedTasksListState(sortList);
        const taskArr = Object.keys(createTaskObj.data);
        if (Array.isArray(taskArr) && taskArr.length > 0) {
            const filtered = taskArr.filter(filterData => {
                return filterData === sortList[0].resourceId;
            });
            if (filtered.length === 0) {
                return getCreateAssignedForm(sortList[0]?.resourceId);
            } else {
                searchResourceId(sortList[0]?.resourceId);
            }
        } else {
            console.log("obj is not an array or is empty.");
        }
    };
    const handleSortClick = () => {
        if (sortStatus) {
            sortAscending();
        } else {
            sortDescending();
        }
        setSortStatus(!sortStatus);
        setCurrentTab(1);
    };
    return (
        <div className="task-drawer">
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {pageIndex === 1 ?
                    <>
                        <div className="add-global-draw-title flex-start">
                            <h1>{t('TASK_ASSIGNMENT_PROCEDURES')}</h1>
                        </div>
                        <h3 className="pt-4 fw-600 black f-18">{t('EMPLOYEE_INFORMATION')}</h3>
                        <div className="d-flex w-100 justify-space-between">
                            {getStepListTasks?.person?.fullName ?
                                <div className="d-flex">
                                    <Avatar size="large" src={<img src={getStepListTasks?.person?.avatar !== "" ? getStepListTasks?.person?.avatar : process.env.REACT_APP_URL + 'images/avatar_0.png'} alt="avatar" />} />
                                    <div className="d-block pl-2  mt-1">
                                        <p className="f-16 black fw-600 line-10">{getStepListTasks?.person?.fullName} </p>
                                        <p className="f=14 grey12 line-10">{getStepListTasks?.person?.departman} </p>
                                    </div>
                                </div>
                                :
                                <div className="pb-8" >{t('PLEASE_SELECT_EMPLOYEE')}</div>
                            }
                            <label className="d-block text-end f-18 fw-500 black w-200">{dynamicTitle}</label>
                        </div>
                        <div className="w-100 ">
                            <Row>
                                <Col span={8}>
                                    <div className="d-inline-flex pt-1">
                                        <h3 className="fw-600 black f-18">{t('TASKS')}
                                        </h3>
                                        {(assignedTasksListState.length > 0 && currentTab != 0) &&
                                            <>
                                                <span className="mt-1 pl-2">({taskCount?.assignedTaskCount}/{taskCount?.totalTaskCount})</span>
                                                <Button onClick={handleSortClick} className="transparent-btn">{sortStatus ? <UilSortAmountUp /> : <UilSortAmountDown />}</Button>
                                            </>
                                        }
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="d-inline-flex pl-3">
                                        {currentTab > 1 &&
                                            <Button onClick={prevBtn} icon={<UilAngleLeftB />} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn prev-btn ">{t('INVENTORY_BACK')} </Button>
                                        }
                                        {(getStepListTasks?.totalTaskCount > currentTab && currentTab > 1) &&
                                            <div className="buttons-border mx-4" />
                                        }
                                        {(getStepListTasks?.totalTaskCount > currentTab && currentTab !== 0 && (createTaskObj?.person?.id || personIdState)) &&
                                            <Button onClick={nextBtn} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn next-btn">{t('INVENTORY_NEXT')} <UilAngleRightB /></Button>
                                        }
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="flex-end">
                                        {currentTab !== 0 ?
                                            <>
                                                {assignedTasksListState[currentTab - 1].assignedTaskResourceId && assignedTasksListState[currentTab - 1].isAssigned ?
                                                    <Button onClick={undoTask} className="w-180 h-40 f-14 font-w-700 grey-btn">{t('UNDO_MISSION')}</Button>
                                                    :
                                                    <Button onClick={assignedTaskAndAdvance} className="w-200 h-40 f-14 font-w-700 primary-btn">{t('ASSIGN_TASK_AND_ADVANCE')}</Button>
                                                }
                                                <Button onClick={() => closeTaskRequest()} className="w-200 h-40 f-14 font-w-700 ml-3 grey-btn">{t('FINISH_ASSIGNMENT')}</Button>
                                            </>
                                            : <Button onClick={nextBtn} className="w-auto h-40 f-14 font-w-700 transparent-btn pad-0 task-btn next-btn mt-1">{t('INVENTORY_NEXT')} <UilAngleRightB /></Button>
                                        }

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>

                                    <>
                                        <div className="task-step-search">
                                            <Input onChange={handleSearch} placeholder={t('SEARCH_TASK_NAME')} />
                                        </div>
                                        <List
                                            className="task-step-list drawer-scrollable assigned-task-drawer"
                                            itemLayout="horizontal"
                                            loading={getStepListTasksLoading}
                                            dataSource={assignedTasksListState}
                                            renderItem={(data, index) => (
                                                data.show &&
                                                <List.Item onClick={() => tabOnClick(index + 1)} key={index} className={index + 1 === currentTab ? data.isAssigned ? "task-item-active" : "task-item-current" : ""}>
                                                    <List.Item.Meta
                                                        className={currentTab === 0 ? "cursor-disabled" : data.status ? "cursor-pointer" : "cursor-pointer"}
                                                        avatar={data.isAssigned ? <SuccessIcon /> : <WaitIcon />}
                                                        title={<label className={currentTab === 0 ? "cursor-disabled" : data.isAssigned ? "cursor-pointer active-text" : "cursor-pointer"}>{data.name}</label>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                </Col>
                                <Col span={16} >
                                    <CreateTaskRequestsTab
                                        assignedTasksListState={assignedTasksListState}
                                        setCreateObject={setCreateObject}
                                        currentTab={currentTab}
                                        createTaskObj={createTaskObj}
                                        setCreateTaskObj={setCreateTaskObj}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    formStatus === "wait" ?
                        <Spin className="tab-loading w-100 flex-center" />
                        :
                        taskCount?.assignedTaskCount > 0 ?
                            <Successful
                                whiteBtn={<Button onClick={() => closeDrawerControl()} className="w-200 h-40 f-14 font-w-700 white-btn ">{t('INVENTORY_RETURN_LIST')}</Button>}
                                primaryBtn={<Button onClick={() => createNewTask()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('ASSIGN_MORE_TASKS')}</Button>}
                                dynamicMessage={t('ASSIGNED_TASK_SUCCESS', { "count": taskCount?.assignedTaskCount })}
                            />
                            :
                            <Loading
                                whiteBtn={<Button onClick={() => closeDrawerControl()} className="w-200 h-40 f-14 font-w-700 white-btn ">{t('INVENTORY_RETURN_LIST')}</Button>}
                                primaryBtn={<Button onClick={() => createNewTask()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('RETURN_ASSIGNED')}</Button>}
                                dynamicMessage={t('NO_TASK_ASSIGNED')}
                                message={t('FORWARD_ASSIGNED_AGAIN')}
                            />
                }
            </div>
            {pageIndex === 1 &&
                <div className="add-user-draw-footer">
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    <div className="add-user-draw-footer-end">
                        <div className="pad-left-24" />
                        {/* {currentTab !== 0 &&
                            <Button onClick={() => closeTaskRequest()} className="w-180 h-40 f-14 font-w-700 transparent-btn ">{t('END_PROCESSES')}</Button>
                        } */}
                    </div>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        getStepListTasks: state.taskReducer.getStepListTasks,
        getStepListTasksLoading: state.taskReducer.getStepListTasksLoading,
        createAssignedForm: state.taskReducer.createAssignedForm,
        createAssignedFormLoading: state.taskReducer.createAssignedFormLoading,
        assignedTaskAndAdvanceActionResponse: state.taskReducer.assignedTaskAndAdvanceActionResponse,
        taskUpdateStatusResponse: state.taskReducer.taskUpdateStatusResponse,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCreateTaskDrawer: (status) => { dispatch(setCreateTaskDrawer(status)) },
        getTaskAssignedList: (searchData) => { dispatch(getTaskAssignedList(searchData)) },
        getCreateAssignedForm: (resourceId) => { dispatch(getCreateAssignedForm(resourceId)) },
        resetCreateAssignedForm: () => { dispatch(resetCreateAssignedForm()) },
        assignedTaskAndAdvanceAction: (data) => { dispatch(assignedTaskAndAdvanceAction(data)) },
        resetAssignedTaskAndAdvanceAction: () => { dispatch(resetAssignedTaskAndAdvanceAction()) },
        resetAssignedTasksStepList: () => { dispatch(resetAssignedTasksStepList()) },
        taskUpdateStatus: (data) => { dispatch(taskUpdateStatus(data)) },
        resetTaskUpdateStatus: () => { dispatch(resetTaskUpdateStatus()) },
        getAssignedTasksStepList: (resourceId) => { dispatch(getAssignedTasksStepList(resourceId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskTabs);