import { authApi, hrApi, setAuthHeader } from "../../utils/apiConfig";

import { apiUrl, oidcConfig } from "../../utils/config";

const AuthActionType = {
  STORE_USER: "STORE_USER",
  USER_SIGNED_OUT: "USER_SIGNED_OUT",
  USER_EXPIRED: "USER_EXPIRED",
  STORE_USER_ERROR: "STORE_USER_ERROR",
  LOADING_USER: "LOADING_USER",
  CHANGING_ORGANIZATION_STARTED: "CHANGING_ORGANIZATION_STARTED",
  CHANGING_ORGANIZATION_FINISHED: "CHANGING_ORGANIZATION_FINISHED",
  CHANGE_ORGANIZATION_SUCCESS: "CHANGE_ORGANIZATION_SUCCESS",
  CHANGE_ORGANIZATION_FAIL: "CHANGE_ORGANIZATION_FAIL",
  CHANGE_ORGANIZATION_RESET: "CHANGE_ORGANIZATION_RESET",
};
function storeUser(user) {
  setAuthHeader(user.access_token);
  return {
    type: AuthActionType.STORE_USER,
    payload: user,
  };
}
function loadingUser() {
  return {
    type: AuthActionType.LOADING_USER,
  };
}
function storeUserError() {
  return {
    type: AuthActionType.STORE_USER_ERROR,
  };
}
function userExpired() {
  return {
    type: AuthActionType.USER_EXPIRED,
  };
}
function userSignedOut() {
  return {
    type: AuthActionType.USER_SIGNED_OUT,
  };
}
function changeOrganization(organizationResourceId) {
  return async (dispatch) => {
    dispatch({ type: AuthActionType.CHANGING_ORGANIZATION_STARTED, payload: true });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/changeOrganization/"+organizationResourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            setAuthHeader(response?.data?.accessToken);
            authApi.get(oidcConfig.authority+'/connect/userinfo', {
              headers: { 'Authorization': `Bearer ${response?.data?.accessToken}` }
            }).then((userinfoResponse)=>{
              const user = {
                ...response?.data,
                refresh_token: response?.data?.refreshToken,
                access_token: response?.data?.accessToken,
                profile : userinfoResponse?.data,
                permissions: userinfoResponse?.data?.permissions
              }
              dispatch({ type: AuthActionType.CHANGE_ORGANIZATION_SUCCESS, payload: user });
            });
            
          } else {
            dispatch({ type: AuthActionType.CHANGE_ORGANIZATION_FAIL, payload: response?.data?.Message });
            dispatch({ type: AuthActionType.CHANGING_ORGANIZATION_FINISHED, payload: true });

          }
        })
        .catch(function (error) {
          dispatch({ type: AuthActionType.CHANGE_ORGANIZATION_FAIL, payload: error?.message });
          dispatch({ type: AuthActionType.CHANGING_ORGANIZATION_FINISHED, payload: true });

        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: AuthActionType.CHANGE_ORGANIZATION_FAIL, payload: error?.message });
        dispatch({ type: AuthActionType.CHANGING_ORGANIZATION_FINISHED, payload: true });

      }
    }
  };
}
const resetChangeOrganization = () => {
  return async (dispatch) => {
    dispatch({ type: AuthActionType.CHANGE_ORGANIZATION_RESET, payload: {} });
  };
};
export {
  AuthActionType,
  storeUser,
  loadingUser,
  storeUserError,
  userExpired,
  userSignedOut,
  changeOrganization,
  resetChangeOrganization,
};
