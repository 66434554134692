import { ReportAction } from "../actions/report.actions";

const initialState = {
    employeeTable:{
        result: [],
        totalCount: 0,
    },
    employeeTableResponseLoading:false,
    reportPersonExportDataResponse:{"status":"loading","message":""},
    timeOffAllFilter:{
        results: [],
        totalNumberOfRecords:0,
    },
    timeOffAllFilterLoading:false,
    reportTimeOffAllDataResponse:{"status":"loading","message":""},

    timeOffYearsFilter:{
        results: [],
        totalNumberOfRecords:0,
    },
    timeOffYearsFilterLoading:false,
    exportReportTimeOffYearsDataResponse:{"status":"loading","message":""},


}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {

        case ReportAction.EMPLOYEE_FILTER_REQUESTS:
            return {
                ...state,
                employeeTableResponseLoading: true
            };
        case ReportAction.EMPLOYEE_FILTER_SUCCESS:
            return {
                ...state,
                employeeTable: action.payload,
                employeeTableResponseLoading: false
            }
        case ReportAction.EMPLOYEE_FILTER_FAIL:
            return {
                ...state,
                employeeTable: action.payload,
                employeeTableResponseLoading: false
            }
        case ReportAction.REPORT_PERSON_EXPORT_FAIL:
            return {
                ...state,
                reportPersonExportDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
                
        }
        case ReportAction.REPORT_PERSON_EXPORT_RESET:
            return {
                ...state,
                reportPersonExportDataResponse:{"status":"loading","message":""},
                
        }
        case ReportAction.TIME_OFF_ALL_REPORT_FILTER_REQUESTS:
            return {
                ...state,
                timeOffAllFilterLoading: true
            };
        case ReportAction.TIME_OFF_ALL_REPORT_FILTER_SUCCESS:
            return {
                ...state,
                timeOffAllFilter: action.payload,
                timeOffAllFilterLoading: false
            }
        case ReportAction.TIME_OFF_ALL_REPORT_FILTER_FAIL:
            return {
                ...state,
                timeOffAllFilterLoading: false
            }
        case ReportAction.TIME_OFF_ALL_REPORT_EXPORT_FAIL:
            return {
                ...state,
                reportTimeOffAllDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
                
        }
        case ReportAction.TIME_OFF_ALL_REPORT_EXPORT_RESET:
            return {
                ...state,
                reportTimeOffAllDataResponse:{"status":"loading","message":""},
                
        }

        case ReportAction.TIME_OFF_YEARS_REPORT_FILTER_REQUESTS:
            return {
                ...state,
                timeOffYearsFilterLoading: true
            };
        case ReportAction.TIME_OFF_YEARS_REPORT_FILTER_SUCCESS:
            return {
                ...state,
                timeOffYearsFilter: action.payload,
                timeOffYearsFilterLoading: false
            }
        case ReportAction.TIME_OFF_YEARS_REPORT_FILTER_FAIL:
            return {
                ...state,
                timeOffYearsFilterLoading: false
            }

        case ReportAction.TIME_OFF_YEARS_REPORT_EXPORT_FAIL:
            return {
                ...state,
                exportReportTimeOffYearsDataResponse:{
                    "status":"FAIL",
                    "message":action.payload
                 },
                
        }
        case ReportAction.TIME_OFF_YEARS_REPORT_EXPORT_RESET:
            return {
                ...state,
                exportReportTimeOffYearsDataResponse:{"status":"loading","message":""},
                
        }
        default: return state
    }

}