import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


import { Layout, Button, Row, Col, Card, Tag, notification, Spin ,Image} from "antd";
import { UilUsersAlt, UilCheck } from "@iconscout/react-unicons";

import { getPaymentPackages } from '../../redux/actions/checkout.actions';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';

import '../../styles/checkout.scss'

function Prices(props) {
    const { getPaymentPackages, paymentPackages, paymentPackagesLoading, organizationPackageId, permissionList } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nLang') || 'en');

    const [selectedPeriod, setSelectedPeriod] = useState('monthlyPackages');
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [cardList, setCardList] = useState([]);
    const [discountRate, setDiscountRate] = useState('');
    const [currentPackage, setCurrentPackage] = useState(null);
    const [permissions, setPermissions ] = React.useState(permissionList)

    useEffect(()=>{
        setPermissions(permissionList)
    },[JSON.stringify(permissionList)])


    const validateBasket = () =>{
        if(selectedPrice==null){
            DynamicToastMessage({
            'key': 'warning_key',
            'title': t('ATTENTION'),
            'description': t('PLEASE_CHOOSE_PACKAGE'),
            'statusType': 'warning',
            'position': 'bottom-right'
            });
        }else if(selectedPrice==parseInt(currentPackage)){
            DynamicToastMessage({
            'key': 'warning_key',
            'title': t('ATTENTION'),
            'description': t('SAME_PACKAGE_WARNING'),
            'statusType': 'warning',
            'position': 'bottom-right'
            });
        }else{
            if(parseInt(currentPackage) > 0 && !permissions.includes("default_subscriber")){
                history.push('/newPackagePayment?packageId='+selectedPrice)
            }else{
                history.push('/payment?packageId='+selectedPrice)
            }
        }
    }

    useEffect(() =>{
        getPaymentPackages()
    },[])

    useEffect(()=>{
        
        if(parseInt(currentPackage) > 0){
            setSelectedPrice(currentPackage)
        }else{
            setSelectedPrice(null)
        }
    },[currentPackage])

    useEffect(()=>{
        if(parseInt(organizationPackageId) > 0 && !permissions.includes("default_subscriber")){
            setCurrentPackage(organizationPackageId)
            if(parseInt(organizationPackageId) > 4){
                setSelectedPeriod('yearlyPackages')
            }
        }else{
            setCurrentPackage(null)
        }
    },[organizationPackageId])

    useEffect(()=>{
        paymentPackages[selectedPeriod] && setCardList(paymentPackages[selectedPeriod])
        paymentPackages['yearlyPackages'] && paymentPackages['yearlyPackages'][3] && setDiscountRate(paymentPackages['yearlyPackages'][3].discountRate)
    },[paymentPackages,selectedPeriod])

    return (
        <>
        <Layout className="prices-wrapper">
            <div>
                {t('PRICES_MOTTO_1')} <br/> {t('PRICES_MOTTO_2')}
            </div>
            <Button.Group className="period-switch">
                <Button
                    type={selectedPeriod === 'monthlyPackages' ? 'primary' : 'default'}
                    onClick={() => setSelectedPeriod('monthlyPackages')}
                    style={{
                    flex: 1,
                    borderRadius: '20px',
                    margin: 0,
                    border: 'none',
                    background: selectedPeriod === 'monthlyPackages' ? 'white' : '#4CAF50',
                    color: selectedPeriod === 'monthlyPackages' ? '#4CAF50' : 'white',
                    }}
                >
                    {t('MONTHLY')}
                </Button>
                <Button
                    type={selectedPeriod === 'yearlyPackages' ? 'primary' : 'default'}
                    onClick={() => setSelectedPeriod('yearlyPackages')}
                    style={{
                    flex: 1,
                    borderRadius: '20px',
                    margin: 0,
                    border: 'none',
                    background: selectedPeriod === 'yearlyPackages' ? 'white' : '#4CAF50',
                    color: selectedPeriod === 'yearlyPackages' ? '#4CAF50' : 'white',
                    }}
                >
                    {t('YEARLY')} -%{discountRate}
                </Button>
            </Button.Group>
            <Row justify="center" gutter={[16,16]} className="price-card-wrapper">
                {cardList.length>0 ? cardList.map((card,index)=>{
                    return(
                        <Col xs={24} sm={24} md={12} lg={6} key={index}>
                            <div className="price-card-sub-wrapper">
                                {card.id==selectedPrice && <div className="selected-price-icon" ><UilCheck /></div>}
                                <Card
                                className={`price-card ${card.id==selectedPrice ? 'active' : ''} ${card.isFavorite ? 'is-favorite' : ''}`}
                                title={card.name}
                                extra={ 
                                    <Tag color="green" style={{ borderRadius: 5 }}>
                                    {card.description}
                                    </Tag>
                                }
                                onClick={()=>setSelectedPrice(card.id)}
                                key={card.id}
                                >
                                    <div className="worker-count">
                                        <UilUsersAlt /> {card.employeeCount} {t('EMPLOYEE_COUNT_LBL')}
                                    </div>
                                    <div className="old-price my-3">
                                        {selectedLanguage == 'en' 
                                            ? card.discountRate>0 && '$'+card.price?.toLocaleString("en-US").replace(',' , '')
                                            : card.discountRate>0 && card.priceInCurrency?.toLocaleString('tr-TR')+ ' ₺'
                                        }
                                    </div>
                                   
                                    <div className="tl-price my-3">
                                    {selectedLanguage == 'tr-TR' ? 
                                            '$'+card.price?.toLocaleString("en-US").replace(',' , '') : 'ㅤ'
                                    }
                                    </div>
                                    
                                    {card.discountRate == 100 ?
                                        (
                                            <div>
                                                <span className="price">{t('FREE')}</span>
                                                <span className="price-ext">/1 {t('PACKAGE_PERIOD_YEAR')}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="price">{selectedLanguage == 'en' ? '$'+card.discountPrice?.toLocaleString("en-US").replace(',' , '') : card.discountPriceInCurrency?.toLocaleString('tr-TR')+ ' ₺'} </span>
                                                <span className="price-ext">/{selectedPeriod === 'monthlyPackages' ? t('PACKAGE_PERIOD_MONTH') : t('PACKAGE_PERIOD_YEAR')}</span>
                                            </div>
                                        )
                                    }
                                </Card>
                            </div>
                        </Col>
                        
                    )
                }) : paymentPackagesLoading ? (
                    <Col span={24} style={{height:'242px',display:'flex',alignItems:'center'}} >
                        <div className="table-empty-image">
                            <Spin />
                        </div>
                        <div className="table-empty-text">
                            
                        </div>
                    </Col>
                ) : (
                    <div className="table-empty-container">
                        <div className="table-empty-image">
                            <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
                        </div>
                        <div className="table-empty-text">
                            <h1>{t('PACKAGE_NOT_FOUND')}</h1>
                        </div>
                    </div>
                )}
                
            </Row>
            <div>
                {t('PRICES_MOTTO_3')} <br/> {t('PRICES_MOTTO_4')}
            </div>
            <div>
                <Button className="primary-btn m-w-220" size="large" onClick={validateBasket}>{t('CONTINUE_PAYMENT')}</Button>
            </div>
            
        </Layout>
        <ToastContainer />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        paymentPackages: state.checkout.paymentPackages,
        paymentPackagesLoading: state.checkout.paymentPackagesLoading,
        organizationPackageId: state.auth?.user?.profile?.organizationPackageId,
        permissionList: state.profile.permissions
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentPackages: () => { dispatch(getPaymentPackages()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Prices);