import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import PaymentSteps from '../pages/checkout/paymentSteps'
import Content from "./Content";
import Header from "../features/header";
import Footer from "../features/footer";
import '../styles/header.scss';
import '../styles/nav-menu.scss';
import '../styles/global.scss';
import '../styles/footer.scss';
import '../styles/checkout.scss'
const CheckoutLayout = (props) => {
  return (
    <Layout className="checkout-content">
      <Header />
      <PaymentSteps/>
      <Content />
      <Footer/>
    </Layout>
  );
};
export default connect((state) => state)(CheckoutLayout);