import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getTravelHostDetails, setTravelHostDetailsDrawer, updateTravelHost, getTravelHostDetailStepList,
    updateTravelHostReset, resetTravelHostChangeStatus, travelHostChangeStatus
} from '../../../redux/actions/travelHost.actions';
import { getPersonTravel } from '../../../redux/actions/travel.actions';

import { Checkbox, Form, List, ConfigProvider, Input, Button, Spin } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons';

import moment from 'moment'


import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';

import '../../../styles/table.scss';

import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicTable from '../../dynamicTable/index';
import DynamicRequestModal from '../../dynamicModal/requestModal';

import { dateFormatConvert, hoursFormat, dateFormat, dateFormatWithHours } from "../../../utils/config";

import TravelHostEditTab from './travel_edit_tab';

import '../../../styles/travel-host.scss';


function TravelHostDetails(props) {

    const { setTravelHostDetailsDrawer, getTravelHostDetails, getTravelHostDetailStepList, updateTravelHost, updateTravelHostReset, profile, selectItem,
        travelHostDetails, travelHostDetailsLoading, getPersonTravel, travelHostUpdateResponse, travelHostChangeStatusResponse,
        resetTravelHostChangeStatus, travelHostChangeStatus, travelHostDetailStep, travelHostDetailStepLoading } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [travelHostObj, setTravelHostObj] = useState({
    });
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState();
    const approveStatus = true;
    const editableStatus = true;

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false });
    const [rejectState, setRejectState] = useState({});

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false });
    const [approveState, setApproveState] = useState({});

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const handleApproveConfirm = () => {
        travelHostChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        travelHostChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        setApproveState({});
        approveForm.resetFields();
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false });
        setRejectState({});
        rejectForm.resetFields();
    }

    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }

    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const travelColumns = [
        {
            title: `${t('ORGANIZATION_COUNTRY')}`,
            dataIndex: 'countryName',
            key: 'countryName',
            sorter: true,

        },
        {
            title: `${t('FROM_WHERE')}`,
            dataIndex: 'fromCity',
            key: 'fromCity',
            sorter: true,
        },
        {
            title: `${t('FROM_TO')}`,
            dataIndex: 'destinationCity',
            key: 'destinationCity',
            sorter: true,
        },
        {
            title: `${t('MODE_OF_TRANSPORTATION')}`,
            dataIndex: 'transportType',
            key: 'transportType',
            sorter: true,
            render: obj => {
                switch (obj) {
                    case 1:
                        return 'Otobüs';
                    case 2:
                        return 'Tren';
                    case 3:
                        return 'Uçak';
                    default:
                        return '';
                }
            }
        },
        {
            title: `${t('PARTNER_DATE')}`,
            dataIndex: 'transportDate',
            key: 'transportDate',
            sorter: true,
            render: obj => {
                return moment(obj).isValid() ? moment(obj).format(dateFormatConvert) : ''
            }
        },
        {
            title: `${t('HOUR')}`,
            dataIndex: 'hour',
            key: 'hour',
            sorter: true,
            render: obj => {
                return moment(obj).isValid() ? moment(obj).format(hoursFormat) : ''
            }
        },
        {
            title: `${t('PARTNER_DESCRIPTION')}`,
            dataIndex: 'description',
            key: 'description',
            sorter: true,

        }
    ];
    const travelData = travelHostDetails && travelHostDetails.travelDetails.map((row) => {
        return (
            {
                "countryName": row.countryName,
                "fromCity": row.fromCity,
                "destinationCity": row.destinationCity,
                "transportType": row.transportType,
                "transportDate": row.transportDate,
                "hour": row.transportDate,
                "description": row.description
            }
        )
    })

    const hostDetailsColumn = [
        {
            title: `${t('ORGANIZATION_COUNTRY')}`,
            dataIndex: 'countryName',
            key: 'countryName',
            sorter: true,

        },
        {
            title: `${t('PARTNER_PROVINCE')}`,
            dataIndex: 'city',
            key: 'city',
            sorter: true,
        },
        {
            title: `${t('PARTNER_ENTRY_DATE')}`,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            render: obj => {
                return moment(obj).isValid() ? moment(obj).format(dateFormatConvert) : ''
            }
        },
        {
            title: `${t('RELEASE_DATE')}`,
            dataIndex: 'exitDate',
            key: 'exitDate',
            sorter: true,
            render: obj => {
                return moment(obj).isValid() ? moment(obj).format(dateFormatConvert) : ''
            }
        },
        {
            title: `${t('PARTNER_DESCRIPTION')}`,
            dataIndex: 'description',
            key: 'description',
            sorter: true,
        }
    ];
    const hostdetailsData = travelHostDetails && travelHostDetails.hostDetails.map((row) => {
        return (
            {
                "countryName": row.countryName,
                "city": row.city,
                "startDate": row.startDate,
                "exitDate": row.exitDate,
                "description": row.description,

            }
        )
    })
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        page: "table",
        showVisibilityMenu: false
    });
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const paginationConfig = () => ({
        total: 300,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: ['none', 'none'],
    })
    useEffect(() => {
        getPersonTravel(profile?.resourceId);
    }, [])




    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }
    const closeDrawer = () => {
        setTravelHostDetailsDrawer(false);
        setCurrentTab(1);
        setTravelHostObj({});
    }
    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(travelHostObj, obj)
        setTravelHostObj(mergedObj)

    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []

        if (travelHostObj?.travelRequestDetailsList && travelHostObj.travelRequestDetailsList.length > 0) {
            travelHostObj.travelRequestDetailsList.map((data, index) => {
                if ((!data.country || !data.country?.id)) {
                    hasError = true;
                    fields.push(t('ORGANIZATION_COUNTRY'))
                }
                if (!data.provinceFrom) {
                    hasError = true;
                    fields.push(t('FROM_WHERE'))
                }
                if (!data.provinceTo) {
                    hasError = true;
                    fields.push(t('FROM_TO'))
                }
                if (!data.modeOfTransportation || !data?.modeOfTransportation?.id) {
                    hasError = true;
                    fields.push(t('MODE_OF_TRANSPORTATION'))
                }
                if (!data.date) {
                    hasError = true;
                    fields.push(t('PARTNER_DATE'))
                }
                if (!data.hour) {
                    hasError = true;
                    fields.push(t('HOUR'))
                }
            })
        } else {
            // hasError = true
            // fields.push(t('ESTIMATED_BUDGET'))
        }
        if (travelHostObj?.hostDetailsList && travelHostObj.hostDetailsList.length > 0) {
            travelHostObj.hostDetailsList.map((data, index) => {
                if ((!data.country || !data.country?.id)) {
                    hasError = true;
                    fields.push(t('ORGANIZATION_COUNTRY'))
                }
                if (!data.province) {
                    hasError = true;
                    fields.push(t('PARTNER_PROVINCE'))
                }
                if (!data.startDate) {
                    hasError = true;
                    fields.push(t('PARTNER_ENTRY_DATE'))
                }
                if (!data.endDate) {
                    hasError = true;
                    fields.push(t('RELEASE_DATE'))
                }
            })
        } else {
            // hasError = true
            // fields.push(t('ESTIMATED_BUDGET'))
        }
        if (!hasError) {

            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    function edit() {
        setCurrentTab(2);
    }
    function cancel() {
        setCurrentTab(1);
    }
    function save() {
        const TravelDetails = travelHostObj?.travelRequestDetailsList ? travelHostObj?.travelRequestDetailsList.map((data) => {
            var date = null;
            var dateWithHours = null;
            if (data?.date && data?.hour) {
                date = moment(data.date).format(dateFormat);
                dateWithHours = moment(date + ' ' + data?.hour).format(dateFormatWithHours);
            }
            return {
                "CountryId": data?.country?.id,
                "FromCity": data?.provinceFrom,
                "DestinationCity": data?.provinceTo,
                "TransportType": data?.modeOfTransportation?.id,
                "TransportDate": dateWithHours ? dateWithHours : null,
                "Description": data?.description
            }
        }) : null;

        const HostDetails = travelHostObj?.hostDetailsList ? travelHostObj?.hostDetailsList.map((data) => {

            return {
                "CountryId": data?.country?.id,
                "City": data?.province,
                "EntryDate": data?.startDate ? data?.startDate : null,
                "ExitDate": data?.endDate ? data?.endDate : null,
                "Description": data?.description
            }
        }) : null;

        var RequestType = null;
        var isAccomodation = travelHostObj?.isAccomodation ? travelHostObj?.isAccomodation : false;
        var isTravelRequest = travelHostObj?.isTravelRequest ? travelHostObj?.isTravelRequest : false;

        if (!isTravelRequest && !isAccomodation) {
            RequestType = null;
        }
        if (isTravelRequest && !isAccomodation) {
            RequestType = 0;
        }
        if (!isTravelRequest && isAccomodation) {
            RequestType = 1;
        }
        if (isTravelRequest && isAccomodation) {
            RequestType = 2;
        }

        const data = {
            "RequestType": RequestType,
            "RelatedTravelIdx": travelHostObj?.travel?.id,
            'TravelDetails': TravelDetails?.length > 0 ? TravelDetails : [],
            "TravelDescription": travelHostObj?.travelDescription,
            "HostDetails": HostDetails,
            "HostDescription": travelHostObj?.transportationDescription
        }
        checkRequiredFields(() => updateTravelHost(data, selectItem.resourceId))
    }
    useEffect(() => {
        if (travelHostUpdateResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': travelHostUpdateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTravelHostDetails(selectItem.resourceId);
            getTravelHostDetailStepList(selectItem.resourceId);
            setCurrentTab(1);
            updateTravelHostReset();

        }
        else if (travelHostUpdateResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': travelHostUpdateResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateTravelHostReset();
        }
    }, [travelHostUpdateResponse])
    const approve = () => {
        setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': selectItem?.resourceId })
    }
    const decline = () => {
        setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': selectItem?.resourceId })
    }
    useEffect(() => {
        if (travelHostChangeStatusResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': travelHostChangeStatusResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getTravelHostDetails(selectItem.resourceId);
            setRejectModalData({ 'isVisible': false });
            setApproveModalData({ 'isVisible': false });
            approveForm.resetFields();
            rejectForm.resetFields();
            resetTravelHostChangeStatus();

        }
        else if (travelHostChangeStatusResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': travelHostChangeStatusResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetTravelHostChangeStatus();
        }
    }, [travelHostChangeStatusResponse])

    return (
        (travelHostDetailsLoading && travelHostDetailStepLoading) ?
            <Spin className="tab-loading " />
            :
            <>
                <DynamicRequestModal
                    modalVisible={rejectModalData?.isVisible}
                    setStateData={setRejectModalData}
                    handleConfirm={handleRejectConfirm}
                    handleCancel={handleRejectCancel}
                    confirmData={rejectModalData?.id}
                    isForm={rejectModalForm}
                    iconName='UilTimesCircle'
                    modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                    description={t('TRAVEL_HOST_REJECT_MESSAGE')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
                />
                <DynamicRequestModal
                    modalVisible={approveModalData?.isVisible}
                    setStateData={setApproveModalData}
                    handleConfirm={handleApproveConfirm}
                    handleCancel={handleApproveCancel}
                    confirmData={approveModalData?.id}
                    isForm={approveModalForm}
                    iconName='UilCheckCircle'
                    modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                    description={t('TRAVEL_HOST_APPROVE_MESSAGE')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
                />
                <div className="table-details">
                    <div className="add-global-close-icon">
                        <UilMultiply onClick={() => closeDrawer()} />
                    </div>
                    <div className="add-global-draw-title flex-start">
                        <h1>{t('TRANSPORTATION_AND_ACCOMMODATION_REQUEST')}</h1>
                    </div>

                    {currentTab === 1 ?
                        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                            <Form
                                form={form}
                                layout="vertical travel-scrollable travel-host-container w-100"
                                requiredMark={true}
                            >
                                {travelHostDetails?.relatedTravelName &&
                                    <Form.Item label={t('TRAVEL_NOTES')} >
                                        <p className="black f-16">{travelHostDetails?.relatedTravelName}</p>
                                    </Form.Item>
                                }
                                <Form.Item className="mar-0">
                                    <Checkbox checked={travelHostDetails?.requestType === 0 || travelHostDetails?.requestType === 2} disabled={true} >{t('TRANSPORTATION_REQUEST')}</Checkbox>
                                </Form.Item>
                                {travelHostDetails?.travelDetails?.length > 0 &&
                                    <div className="expense-drawer">

                                        <div className="table-container table-hide-all-buttons blue-table ">
                                            <DynamicTable columns={travelColumns} dataSource={travelData}
                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={() => null}
                                                onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true}
                                            />
                                        </div>
                                        <br />
                                        <Form.Item label={t('TRANSPORTATION_DESCRIPTION')}>
                                            <Input.TextArea
                                                disabled={true}
                                                value={travelHostDetails?.travelDescription}
                                            />
                                        </Form.Item>
                                    </div>
                                }
                                <Form.Item className="mar-0" >
                                    <Checkbox checked={travelHostDetails?.requestType === 1 || travelHostDetails?.requestType === 2} disabled={true} >{t('ACCOMMODATION_REQUEST')}</Checkbox>
                                </Form.Item>
                                {travelHostDetails.hostDetails?.length > 0 &&
                                    <div className="expense-drawer">
                                        <div className="table-container table-hide-all-buttons blue-table">
                                            <DynamicTable columns={hostDetailsColumn} dataSource={hostdetailsData}
                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={() => null}
                                                onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true}
                                            />
                                        </div>
                                        <br />
                                        <Form.Item label={t('ACCOMMODATION_DESCRIPTION')}>
                                            <Input.TextArea
                                                disabled={true}
                                                value={travelHostDetails?.hostDescription}

                                            />
                                        </Form.Item>
                                    </div>
                                }
                            </Form >
                        </ConfigProvider >
                        :
                        <TravelHostEditTab setCreateObject={setCreateObject} travelHostDetails={travelHostDetails} />}
                </div>
                {(currentTab === 1) &&
                    <div className="add-user-draw-footer">
                        <div className="w-100  space-between" >
                            <div className="w-100 flex-class">
                                {travelHostDetailStep[0].editableStatus > 1 &&
                                    <Button onClick={edit} className="w-180 h-40 white-btn">{t('PARTNER_EDIT')}</Button>
                                }
                            </div>
                            <div className="flex-class flex-end pad-right-12">
                                {travelHostDetailStep[0].editableStatus === 3 ?
                                    <>
                                        <Button onClick={() => decline()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_DECLINE')}</Button>
                                        <div className="pad-right-12" />
                                        <Button onClick={() => approve()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_APPROVE')}</Button>
                                    </>
                                    :
                                    <Button onClick={closeDrawer} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>

                                }
                            </div>
                        </div>
                    </div>
                }
                {currentTab === 2 &&
                    <div className="add-user-draw-footer">
                        <Button onClick={cancel} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                        <div className="add-user-draw-footer-end">
                            <div className="pad-left-24" />
                            <Button onClick={save} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_SAVE_CHANGES')}</Button>
                        </div>
                    </div>
                }
            </>
    )

}

const mapStateToProps = (state) => {
    return {
        createTravelHostResponse: state.travelHostReducer.createTravelHostResponse,
        travelHostDetails: state.travelHostReducer.travelHostDetails,
        travelHostDetailsLoading: state.travelHostReducer.travelHostDetailsLoading,
        travelHostUpdateResponse: state.travelHostReducer.travelHostUpdateResponse,
        travelHostChangeStatusResponse: state.travelHostReducer.travelHostChangeStatusResponse,
        travelHostDetailStep: state.travelHostReducer.travelHostDetailStep,
        travelHostDetailStepLoading: state.travelHostReducer.travelHostDetailStepLoading,
        profile: state.profile,

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTravelHostDetailsDrawer: (status) => { dispatch(setTravelHostDetailsDrawer(status)) },
        getPersonTravel: (id) => { dispatch(getPersonTravel(id)) },
        getTravelHostDetails: (id) => { dispatch(getTravelHostDetails(id)) },
        getTravelHostDetailStepList: (id) => { dispatch(getTravelHostDetailStepList(id)) },
        updateTravelHost: (data, id) => { dispatch(updateTravelHost(data, id)) },
        updateTravelHostReset: () => { dispatch(updateTravelHostReset()) },
        resetTravelHostChangeStatus: () => { dispatch(resetTravelHostChangeStatus()) },
        travelHostChangeStatus: (data, type) => { dispatch(travelHostChangeStatus(data, type)) },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TravelHostDetails);
