import { Layout, Col, Tabs,Row } from 'antd';
import { useTranslation } from 'react-i18next';
import BreadCrump from '../../features/breadcrump/breadcrump';
import Comingsoon from '../../component/comingsoon/index';


const { Content } = Layout;


function Invoice() {
    const { t } = useTranslation();
    

    return (

        <Layout>
            <Layout>
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrump />
                        <div className="tab-page">
                            <h1 className="page-title">{t('INVOICES')}</h1>
                            <div className="pad-15" />
                        </div>

                        <Comingsoon/>

                    </Content >
                </Col>
            </Layout>
        </Layout >
    )

}
export default Invoice;
