import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
const OrganizationDetailActionType = {
  UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_FAIL: "UPDATE_ORGANIZATION_FAIL",
  GET_ORGANIZATION_DETAILS_INFO_REQUEST: "GET_ORGANIZATION_DETAILS_INFO_REQUEST",
  GET_ORGANIZATION_DETAILS_INFO_SUCCESS: "GET_ORGANIZATION_DETAILS_INFO_SUCCESS",
  GET_ORGANIZATION_DETAILS_INFO_FAIL: "GET_ORGANIZATION_DETAILS_INFO_FAIL",
  UPDATE_ORGANIZATION_INPUT_ERROR: "UPDATE_ORGANIZATION_INPUT_ERROR",
  UPDATE_ORGANIZATION_RESET_STATUS: "UPDATE_ORGANIZATION_RESET_STATUS",
  GET_ORGANIZATION_RESET: "GET_ORGANIZATION_RESET",
};

const updateOrganization = (updateData) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationDetailActionType.UPDATE_ORGANIZATION_REQUEST });
    try {
      const resourceId = updateData.resourceId;
      const data = JSON.stringify(updateData.data);
      var config = {
        method: "put",
        url: apiUrl + "/organizations/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: OrganizationDetailActionType.UPDATE_ORGANIZATION_SUCCESS,
              payload: response.data.message,
            });
          } else {
            dispatch({ type: OrganizationDetailActionType.UPDATE_ORGANIZATION_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({
              type: OrganizationDetailActionType.UPDATE_ORGANIZATION_INPUT_ERROR,
              payload: error.response.data.validationMessages,
            });
          } else {
            dispatch({ type: OrganizationDetailActionType.UPDATE_ORGANIZATION_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OrganizationDetailActionType.UPDATE_ORGANIZATION_FAIL, payload: error.message });
      }
    }
  };
};
const getOrganizationDetailsInfo = (id) => {
  return async (dispatch) => {
    dispatch({ type: OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/organizations/" + id,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            dispatch({
              type: OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_SUCCESS,
              payload: response.data.data,
            });
          } else {
            dispatch({
              type: OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_FAIL,
              payload: response.data.errorMessages,
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            dispatch({
              type: OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_FAIL,
              payload: error,
            });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: OrganizationDetailActionType.GET_ORGANIZATION_DETAILS_INFO_FAIL,
          payload: error,
        });
      }
    }
  };
};
const resetAction = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationDetailActionType.GET_ORGANIZATION_RESET, payload: {} });
  };
};
const resetUpdateOrganization = () => {
  return async (dispatch) => {
    dispatch({ type: OrganizationDetailActionType.UPDATE_ORGANIZATION_RESET_STATUS, payload: {} });
  };
};

export { OrganizationDetailActionType, updateOrganization, getOrganizationDetailsInfo, resetAction, resetUpdateOrganization };
