

import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';
const FileDownload = require('js-file-download');

const TravelHostActionTypes = {
  SET_TRAVEL_HOST_CREATE_DRAWER:"SET_TRAVEL_HOST_CREATE_DRAWER",
  SET_TRAVEL_HOST_DETAILS_DRAWER:"SET_TRAVEL_HOST_DETAILS_DRAWER",
  CREATE_TRAVEL_HOST_SUCCESS:"CREATE_TRAVEL_HOST_SUCCESS",
  CREATE_TRAVEL_HOST_FAIL:"CREATE_TRAVEL_HOST_FAIL",
  CREATE_TRAVEL_HOST_RESET:"CREATE_TRAVEL_HOST_RESET",
  MY_TRAVEL_HOST_LIST_REQUESTS:"MY_TRAVEL_HOST_LIST_REQUESTS",
  MY_TRAVEL_HOST_LIST_SUCCESS:"MY_TRAVEL_HOST_LIST_SUCCESS",
  MY_TRAVEL_HOST_LIST_FAIL:"MY_TRAVEL_HOST_LIST_FAIL",
  GET_TRAVEL_HOST_APPROVE_ON_MAIL_REQUEST:"GET_TRAVEL_HOST_APPROVE_ON_MAIL_REQUEST",
  GET_TRAVEL_HOST_APPROVE_ON_MAIL_SUCCESS:"GET_TRAVEL_HOST_APPROVE_ON_MAIL_SUCCESS",
  GET_TRAVEL_HOST_APPROVE_ON_MAIL_FAIL:"GET_TRAVEL_HOST_APPROVE_ON_MAIL_FAIL",
  GET_TRAVEL_HOST_DETAILS_REQUEST:"GET_TRAVEL_HOST_DETAILS_REQUEST",
  GET_TRAVEL_HOST_DETAILS_SUCCESS:"GET_TRAVEL_HOST_DETAILS_SUCCESS",
  GET_TRAVEL_HOST_DETAILS_FAIL:"GET_TRAVEL_HOST_DETAILS_FAIL",
  UPDATE_TRAVEL_HOST_SUCCESS:"UPDATE_TRAVEL_HOST_SUCCESS",
  UPDATE_TRAVEL_HOST_FAIL:"UPDATE_TRAVEL_HOST_FAIL",
  UPDATE_TRAVEL_HOST_RESET:"UPDATE_TRAVEL_HOST_RESET",
  TRAVEL_HOST_CHANGE_STATUS_SUCCESS:"TRAVEL_HOST_CHANGE_STATUS_SUCCESS",
  TRAVEL_HOST_CHANGE_STATUS_FAIL:"TRAVEL_HOST_CHANGE_STATUS_FAIL",
  TRAVEL_HOST_CHANGE_STATUS_RESET:"TRAVEL_HOST_CHANGE_STATUS_RESET",
  TRAVEL_HOST_APPROVAL_LIST_REQUESTS:"TRAVEL_HOST_APPROVAL_LIST_REQUESTS",
  TRAVEL_HOST_APPROVAL_LIST_SUCCESS:"TRAVEL_HOST_APPROVAL_LIST_SUCCESS",
  TRAVEL_HOST_APPROVAL_LIST_FAIL:"TRAVEL_HOST_APPROVAL_LIST_FAIL",
  ALL_TRAVEL_HOSTL_LIST_REQUESTS:"ALL_TRAVEL_HOSTL_LIST_REQUESTS",
  ALL_TRAVEL_HOSTL_LIST_SUCCESS:"ALL_TRAVEL_HOSTL_LIST_SUCCESS",
  ALL_TRAVEL_HOSTL_LIST_FAIL:"ALL_TRAVEL_HOSTL_LIST_FAIL",
  GET_TRAVEL_HOST_DETAIL_STEP_LIST_REQUEST:"GET_TRAVEL_HOST_DETAIL_STEP_LIST_REQUEST",
  GET_TRAVEL_HOST_DETAIL_STEP_LIST_SUCCESS:"GET_TRAVEL_HOST_DETAIL_STEP_LIST_SUCCESS",
  GET_TRAVEL_HOST_DETAIL_STEP_LIST_FAIL:"GET_TRAVEL_HOST_DETAIL_STEP_LIST_FAIL",
  TRAVEL_HOST_TABLE_EXPORT_SUCCESS:"TRAVEL_HOST_TABLE_EXPORT_SUCCESS",
  TRAVEL_HOST_TABLE_EXPORT_FAIL:"TRAVEL_HOST_TABLE_EXPORT_FAIL",
  TRAVEL_HOST_TABLE_EXPORT_RESET:"TRAVEL_HOST_TABLE_EXPORT_RESET",
  TRAVEL_HOST_APPROVALS_TABLE_EXPORT_SUCCESS:"TRAVEL_HOST_APPROVALS_TABLE_EXPORT_SUCCESS",
  TRAVEL_HOST_APPROVALS_TABLE_EXPORT_FAIL:"TRAVEL_HOST_APPROVALS_TABLE_EXPORT_FAIL",
  TRAVEL_HOST_APPROVALS_TABLE_EXPORT_RESET:"TRAVEL_HOST_APPROVALS_TABLE_EXPORT_RESET",
  };
  const setTravelHostCreateDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: TravelHostActionTypes.SET_TRAVEL_HOST_CREATE_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
  };
  
  const setTravelHostDetailsDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: TravelHostActionTypes.SET_TRAVEL_HOST_DETAILS_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
  };
  const createTravelHost = (createObj) => {
    return async (dispatch) => {
      try {
        var data = JSON.stringify(createObj)
        
      
          var config = {
            method: 'post',
            url: apiUrl+'/travelHostRequest',
            dataType: "json",
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
              dispatch({ type: TravelHostActionTypes.CREATE_TRAVEL_HOST_SUCCESS, payload: response.data });
            }else{
                dispatch({ type: TravelHostActionTypes.CREATE_TRAVEL_HOST_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            if(error.response.status === 422){
              dispatch({ type: TravelHostActionTypes.CREATE_TRAVEL_HOST_FAIL, payload: error.response.data.message });
  
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.CREATE_TRAVEL_HOST_FAIL, payload: error.message });
        }
      }
    };
  };
  const createTravelHostReset = () => {    
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.CREATE_TRAVEL_HOST_RESET, payload:{} });
      };
  }
  const getMyTravelHostList = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.MY_TRAVEL_HOST_LIST_REQUESTS });
      try {
        const data = JSON.stringify(searchData);
        var config = {
          method: 'post',
          url: apiUrl+'/travelHostRequest/search/me',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: TravelHostActionTypes.MY_TRAVEL_HOST_LIST_SUCCESS, payload: response.data.data });
          }else{
              dispatch({ type: TravelHostActionTypes.MY_TRAVEL_HOST_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
              "results": []
            }
            dispatch({ type: TravelHostActionTypes.MY_TRAVEL_HOST_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TravelHostActionTypes.MY_TRAVEL_HOST_LIST_FAIL, payload: error.message });
        });
      } catch (error) {
        if (error.response) {
          dispatch({ type: TravelHostActionTypes.MY_TRAVEL_HOST_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getTravelHostApprovalList = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_REQUESTS });
      try {
        const data = JSON.stringify(searchData);
  
        var config = {
          method: 'post',
          url: apiUrl+'/travelHostRequest/search/manager',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_SUCCESS, payload: response.data.data });
          }else{
  
              dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
               "results": []
            }
            dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_FAIL, payload: error.message });
        });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVAL_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getAllTravelHostList = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_REQUESTS });
      try {
        const data = JSON.stringify(searchData);
  
        var config = {
          method: 'post',
          url: apiUrl+'/travelHostRequest/search/all',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
  
              dispatch({ type: TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_SUCCESS, payload: response.data.data });
          }else{
  
              dispatch({ type: TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 404){
            const emptyData = {
              "pageNumber": 1,
              "pageSize": 1,
              "totalNumberOfPages": 1,
              "totalNumberOfRecords": 0,
               "results": []
            }
            dispatch({ type: TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_SUCCESS, payload: emptyData });
          }
          dispatch({ type: TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_FAIL, payload: error.message });
        });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.ALL_TRAVEL_HOSTL_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getTravelHostOnMail = (data) => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/approveTravelHostOnMail?data='+data,
            headers: {}
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_FAIL, payload: error.response.data.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_APPROVE_ON_MAIL_FAIL, payload: error.message });
        }
      }
    };
  };

  const getTravelHostDetails = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/travelHostRequest/'+resourceId,
            headers: { },

          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_FAIL, payload: [] });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_FAIL, payload: [] });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAILS_FAIL, payload: error.message });
        }
      }
    };
  };
  const getTravelHostDetailStepList = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_REQUEST });
  
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/travelHostRequest/approvalList/'+resourceId,
            headers: { 
              'Content-Type': 'application/json',
            },
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_FAIL, payload: error.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.GET_TRAVEL_HOST_DETAIL_STEP_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const updateTravelHost = (data,resourceId) => {
    return async (dispatch) => {
      try {
           var config = {
            method: 'put',
            url: apiUrl+'/travelHostRequest/'+resourceId,
            headers: { 
              'Content-Type': 'application/json'
            },
            data:data
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelHostActionTypes.UPDATE_TRAVEL_HOST_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: TravelHostActionTypes.UPDATE_TRAVEL_HOST_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelHostActionTypes.UPDATE_TRAVEL_HOST_FAIL, payload: error.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.UPDATE_TRAVEL_HOST_FAIL, payload: error.message });
        }
      }
    };
  };

  const updateTravelHostReset = () => {
    
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.UPDATE_TRAVEL_HOST_RESET, payload:{} });
      };
  }
  const travelHostChangeStatus = (data,type) => {

    const changeMethod = type === 'delete' ? 'delete' : 'post';

    return async (dispatch) => {
      try {
           var config = {

            method: changeMethod,
            url: apiUrl+'/travelHostRequest/'+data.resourceId+'/'+type,
            headers: { 
              'Content-Type': 'application/json',
            },
            data:changeMethod !== 'delete' ? data.description: null
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status === 200 ){
                dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_FAIL, payload: error.response.data.message });
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetTravelHostChangeStatus = () => {    
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_CHANGE_STATUS_RESET, payload:{} });
      };
  }
  const travelHostTableExport  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);
      try {
          var config = {
            method: 'post',
            url: apiUrl+'/travelhostrequest/export',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          hrApi(config)
          .then(function (response,typeId)  {
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
               FileDownload(response.data, 'travelHost'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetTravelHostTableExport = () => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_TABLE_EXPORT_RESET, payload:{} });
      };
  }
  const travelHostApprovalsTableExport  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);
      try {
          var config = {
            method: 'post',
            url: apiUrl+'/travelhostrequest/exportmanager',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          hrApi(config)
          .then(function (response,typeId)  {
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
               FileDownload(response.data, 'travelHost_approvals_'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetTravelHostApprovalsTableExport = () => {
    return async (dispatch) => {
      dispatch({ type: TravelHostActionTypes.TRAVEL_HOST_APPROVALS_TABLE_EXPORT_RESET, payload:{} });
      };
  }
export {
    TravelHostActionTypes,
    setTravelHostCreateDrawer,
    setTravelHostDetailsDrawer,
    createTravelHost,
    createTravelHostReset,
    getMyTravelHostList,
    getTravelHostApprovalList,
    getAllTravelHostList,
    getTravelHostOnMail,
    getTravelHostDetails,
    updateTravelHost,
    updateTravelHostReset,
    travelHostChangeStatus,
    resetTravelHostChangeStatus,
    getTravelHostDetailStepList,
    travelHostTableExport,
    resetTravelHostTableExport,
    travelHostApprovalsTableExport,
    resetTravelHostApprovalsTableExport
};