import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getAllTravelHostList, getTravelHostDetails, travelHostChangeStatus, getTravelHostDetailStepList,
    travelHostTableExport, resetTravelHostTableExport
} from '../../redux/actions/travelHost.actions';
import { Button, Dropdown, Input, ConfigProvider, Form } from 'antd';
import { UilCheckCircle, UilMinusCircle, UilImport } from '@iconscout/react-unicons';
import { MoreOutlined } from '@ant-design/icons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import ExportModal from '../dynamicModal/exportModal';
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage';

import { travelHostListDropdown } from '../../features/global-dropdown-menu/index';
import { useWindowDimensions, dateFormatConvert } from '../../utils/config';
import { IsJsonString } from '../../utils/commonFormFunctions';

import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";

function AllTravelHostRequestTable(props) {
    const { t } = useTranslation();
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const { allRequestInitialSearch, allRequestSearchState, setAllRequestSearchState, openDetailsDrawer,
        getTravelHostDetails, setSelectItem, allTravelHostLoading, getAllTravelHostList, allTravelHostList,
        totalNumberOfRecords, permissions, travelHostChangeStatus, travelHostChangeStatusResponse,
        currentTab, getTravelHostDetailStepList, travelHostTableExport, travelHostExportDataResponse,
        resetTravelHostTableExport } = props;

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false })
    const [rejectState, setRejectState] = useState({})

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false })
    const [approveState, setApproveState] = useState({});
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportState, setExportState] = useState({ ...allRequestSearchState, exportType: 0 });

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const handleApproveConfirm = () => {
        travelHostChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        travelHostChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const handleTravelHostChangeStatus = (status, resourceId) => {
        if (status === "APPROVE") {
            setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': resourceId })
        } else {
            setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': resourceId })
        }
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    useEffect(() => {
        if (allRequestSearchState !== allRequestInitialSearch) {
            getAllTravelHostList(allRequestSearchState);
        }
    }, [allRequestSearchState]);
    useEffect(() => {
        if (currentTab === "AllTravelHostRequest") {
            if (travelHostChangeStatusResponse.status === 'SUCCESS') {
                setRejectModalData({ 'isVisible': false });
                setRejectState({});
                rejectForm.resetFields();

                setApproveModalData({ 'isVisible': false });
                setApproveState({});
                approveForm.resetFields();
            }
        }
    }, [travelHostChangeStatusResponse])
    useEffect(() => {
        if (travelHostExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': IsJsonString(travelHostExportDataResponse.message) ? JSON.parse(travelHostExportDataResponse.message).message : t('ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTravelHostTableExport();
        }
    }, [travelHostExportDataResponse])
    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setExportState({ ...allRequestInitialSearch, exportType: 0 });
        setAllRequestSearchState({ ...allRequestInitialSearch });
    }
    const handleTableFilter = (data) => {
        setAllRequestSearchState({ ...allRequestSearchState, "stateTypes": data.stateType });
        setExportState({ ...exportState, "stateTypes": data.stateType });
    }
    const showDetails = (id) => {
        if (id !== undefined) {
            setSelectItem({ 'resourceId': id })
            openDetailsDrawer();
            getTravelHostDetails(id);
            getTravelHostDetailStepList(id);
        }
    }
    const data = allTravelHostList.length > 0 ? allTravelHostList.map((row) => {
        return (
            {
                "key": row?.resourceId,
                "createdDate": { "text": moment(row.createdDate).format(dateFormatConvert), "id": row?.resourceId },
                "requestType": row.requestType,
                "description": row.description,
                "stateType": { "id": row.stateTypeId, "displayValue": row.stateTypeName, 'resourceId': row?.resourceId },
            }
        );
    }) : [];
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType'] : [],
        sortableColumns: ['createdPerson', 'createdDate', 'requestType', 'stateType'],
        visibilityOptionExcludes: ['createdPerson', 'createdDate', 'requestType', 'stateType'],
        page: "AllTravelHostRequest",
        leftActionMenu: totalNumberOfRecords > 0 ? leftActionMenu() : null,
        showVisibilityMenu: false,
    })
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...allRequestSearchState.pagination, 'isChanged': true, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : allRequestSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setAllRequestSearchState({ ...allRequestSearchState, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...allRequestSearchState.pagination, "pageNumber": 1 }
        setAllRequestSearchState({ ...allRequestSearchState, "SearchTerm": e, "pagination": pagingObj });
        setExportState({ ...exportState, "SearchTerm": e });
    }
    const handleShowPassivesToogle = (e) => { }

    const canDelete = (id, count) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'count': count, 'isVisible': true })
    }
    function handleExportDocument(props) {
        travelHostTableExport({
            ...exportState,
            'exportType': props,
            'pagination': {
                "pageNumber": 1,
                "pageSize": 10000,
                "orderBy": "NeedDate",
                "ascending": exportState.pagination.ascending
            }
        });
        setIsModalVisible(false);
    }
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.export">
                        <Button onClick={() => setIsModalVisible(true)} className="flex-class svg-class white-btn h-32 w-auto m-w-180"><UilImport />  {t('SETTINGS_EXPORT')}</Button>
                    </Can>
                }
            </div>
        )
    }
    return (
        <>
            <ExportModal
                onCancel={() => { setIsModalVisible(false) }}
                status={isModalVisible}
                handleExportDocument={handleExportDocument}
            />
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={travelHostChangeStatus}
                confirmData={'delete'}
                isMultipleData={{ 'resourceId': deleteModalData.id, 'description': null }}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_TRAVEL_HOST_REQUEST')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <DynamicRequestModal
                modalVisible={rejectModalData?.isVisible}
                setStateData={setRejectModalData}
                handleConfirm={handleRejectConfirm}
                handleCancel={handleRejectCancel}
                confirmData={rejectModalData?.id}
                isForm={rejectModalForm}
                iconName='UilTimesCircle'
                modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                description={t('TRAVEL_HOST_REJECT_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
            />
            <DynamicRequestModal
                modalVisible={approveModalData?.isVisible}
                setStateData={setApproveModalData}
                handleConfirm={handleApproveConfirm}
                handleCancel={handleApproveCancel}
                confirmData={approveModalData?.id}
                isForm={approveModalForm}
                iconName='UilCheckCircle'
                modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                description={t('TRAVEL_HOST_APPROVE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
            />
            <div className="table-container">
                <Media query="(min-width: 875px)" >
                    {matches => {
                        const columns = [
                            {
                                title: `${t('ORGANIZATION_STATU')}`,
                                dataIndex: 'stateType',
                                key: 'stateType',
                                sorter: true,
                                render: state => {
                                    if (state.id === 1) {
                                        return <div className="flex-class w-100" >
                                            <span className='yellow  flex-align-center'>
                                                {state.displayValue}
                                            </span>
                                            <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                                <Button className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleTravelHostChangeStatus('APPROVE', state.resourceId)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                                <Button className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleTravelHostChangeStatus('REJECT', state.resourceId)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                            </div>
                                        </div>
                                    } else if (state.id === 2) {
                                        return <div>{state.displayValue}</div>
                                    } else if (state.id === 3) {
                                        return <div className="flex-class w-100" >
                                            <span className='red  flex-align-center'>
                                                {state.displayValue}
                                            </span>
                                        </div>
                                    } else if (state.id === 4) {
                                        return <div className="flex-class w-100" >
                                            <span className='red  flex-align-center'>
                                                {state.displayValue}
                                            </span>
                                        </div>
                                    } else {
                                        return <div>{state?.displayValue}</div>
                                    }
                                }
                            },
                            {
                                title: `${t('REQUEST_TYPE')}`,
                                dataIndex: 'requestType',
                                key: 'requestType',
                                sorter: true,
                                render: id => {
                                    switch (id) {
                                        case 0:
                                            return t('TRANSPORTATION')
                                        case 1:
                                            return t('ACCOMMODATION')
                                        case 2:
                                            return t('TRANSPORTATION_AND_ACCOMMODATION')
                                        default:
                                            return ''
                                    }
                                }
                            },
                            // {
                            //     title: `${t('PARTNER_DESCRIPTION')}`,
                            //     dataIndex: 'description',
                            //     key: 'description',
                            //     sorter: true,
                            //     render: text => {
                            //         if (text) {
                            //             if (text.length > 10) {
                            //                 return text.slice(0, 10) + '...';
                            //             } else {
                            //                 return text
                            //             }
                            //         }

                            //     }
                            // },
                        ];
                        if (matches) {
                            columns.splice(0, 0,
                                {
                                    title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
                                    dataIndex: 'createdDate',
                                    key: 'createdDate',
                                    sorter: true,
                                    render: obj => {
                                        return (
                                            <div className="imageNameActionColumn">
                                                <Can I="timeoff.request.read">
                                                    <a onClick={() => showDetails(obj.id)} class="details-text mar-0" >{obj.text}</a>
                                                    <Dropdown className="action-menu" overlay={travelHostListDropdown(t, canDelete, obj.id, permissions)} trigger={['click']}>
                                                        <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                    </Dropdown>
                                                </Can>
                                                <Cannot I="timeoff.request.read">
                                                    <a className="details-text mar-0" >{obj.text}</a>
                                                    <Dropdown className="action-menu" overlay={null} trigger={['click']}>
                                                        <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                                    </Dropdown>
                                                </Cannot>


                                            </div>
                                        )
                                    }
                                }
                            );
                        }
                        return <DynamicTable
                            columns={columns} dataSource={data}
                            tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                            onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                            searchPlaceholder=""
                            onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                            onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                            searchBox={true}
                            checkboxStatus={false}
                            passiveButton={false}
                            loadStatus={allTravelHostLoading}
                        />
                    }}
                </Media>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        allTravelHostLoading: state.travelHostReducer.allTravelHostLoading,
        allTravelHostList: state.travelHostReducer.allTravelHostList.results,
        totalNumberOfRecords: state.travelHostReducer.allTravelHostList.totalNumberOfRecords,
        travelHostChangeStatusResponse: state.travelHostReducer.travelHostChangeStatusResponse,
        permissions: state.profile.permissions,
        travelHostExportDataResponse: state.travelHostReducer.travelHostExportDataResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTravelHostList: (searchData) => { dispatch(getAllTravelHostList(searchData)) },
        getTravelHostDetails: (id) => { dispatch(getTravelHostDetails(id)) },
        getTravelHostDetailStepList: (id) => { dispatch(getTravelHostDetailStepList(id)) },
        travelHostChangeStatus: (data, type) => { dispatch(travelHostChangeStatus(data, type)) },
        travelHostTableExport: (props) => { dispatch(travelHostTableExport(props)) },
        resetTravelHostTableExport: () => { dispatch(resetTravelHostTableExport()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllTravelHostRequestTable);