import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask'
import InputMask from "react-input-mask";
import DynamicSelectbox from "../dynamicSelectbox/index";
import Checkbox from "antd/lib/checkbox/Checkbox";


import { getDataset } from '../../redux/actions/dataset-action';
import { getNationalities } from '../../redux/actions/address.actions';
import { getTagList } from '../../redux/actions/tag.actions';
import { createInitialList, NumberList, nationalIdentityNumberMask, compareObjects, compareDate, disabledDate, inputMaskDateBirthValidation, inputMaskDateValidation, inputMaskDateCustomClass, inputMaskShowFormatter, focusDateInput, blurDateInput } from '../../utils/commonFormFunctions'
import moment from 'moment';
import { UilExclamationTriangle } from '@iconscout/react-unicons'
import { Form, Input, DatePicker, Col, Row, Spin, ConfigProvider } from 'antd';
import '../../styles/person.scss';
import { dateFormat, dateFormatList, maskInputFormatterFormLabel, dateInputMask, localeLang, dynamicDateInputFormatter } from '../../utils/config';

function PersonInformation(props) {

    const [personDetailsState, setPersonDetailsState] = useState({});
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const { personInformation, personInformationIsFetching, getUpdateObj, getDataset, dataset, getNationalities, nationalities, disableStatus, getTagList, tagList } = props;

    // SELECT DATA

    const [genderList, setGenderList] = useState(createInitialList(personInformation.gender));
    const [nationalityList, setNationalityList] = useState(createInitialList(personInformation.nationality));
    const [maritalList, setMaritalList] = useState(createInitialList(personInformation.maritalStatus));
    const [educationDegreeList, setEducationDegreeList] = useState(createInitialList(personInformation.educationDegree));
    const [coupleWorkList, setCoupleWorkList] = useState(createInitialList(personInformation.coupleWorkStatus));
    const [bloodGroupList, setBloodGroupList] = useState(createInitialList(personInformation.bloodGroup));
    const [disabiltyDegreeList, setDisabiltyDegreeList] = useState(createInitialList(personInformation.disabilityDegree));
    const [langList, setLangList] = useState(createInitialList(personInformation.languages));
    const [tagListState, setTagListState] = useState(createInitialList(personInformation.tags))

    const [childirenList, setChildirenList] = useState(NumberList(10));

    const [drivingLicenceTypesList, setDrivingLicenceTypesList] = useState(createInitialList(personInformation.drivingLicenceType));
    const [militaryServiceTypesList, setMilitaryServiceTypesList] = useState(createInitialList(personInformation.militaryStatusType));
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "maritalDate": dateFormatList,
        "coupleBirthDate": dateFormatList,
    })
    const [customDateInputPlaceHolder, setCustomDateInputPlaceHolder] = useState({
        "birthday": false,
        "careerStartDate": false
    })
    const [customDateInputErrorStatus, setCustomDateInputErrorStatus] = useState({
        "birthday": false,
        "careerStartDate": false
    })

    useEffect(() => {

        setPersonDetailsState(props.personInformation);

        let setObj = {
            "gender": props.personInformation.gender,
            "nationalIdentityNumber": props.personInformation.nationalIdentityNumber,
            "nationality": props.personInformation.nationality,
            "passportNumber": props.personInformation.passportNumber,
            "maritalStatus": props.personInformation.maritalStatus,
            "childrenCount": { "id": props.personInformation.childrenCount !== 0 ? props.personInformation.childrenCount : t('NONE'), "displayValue": props.personInformation.childrenCount !== 0 ? props.personInformation.childrenCount : t('NONE') },
            "educationDegree": props.personInformation.educationDegree,
            "coupleWorkStatus": props.personInformation.coupleWorkStatus,
            "bloodGroup": props.personInformation.bloodGroup,
            "disabilityDegree": props.personInformation.disabilityDegree,
            "languages": props.personInformation.languages,
            "tags": props.personInformation.tags,
            "isStudent": props.personInformation.isStudent,
            "drivingLicenceType": props.personInformation.drivingLicenceType,
            "drivingLicenceNumber": props.personInformation.drivingLicenceNumber,
            "militaryStatusType": props.personInformation.militaryStatusType,

        }
        if (props.personInformation.birthday) {
            setObj["birthday"] = moment(props.personInformation.birthday).format(maskInputFormatterFormLabel)
        }
        if (props.personInformation.maritalDate) {
            setObj["maritalDate"] = moment(props.personInformation.maritalDate)
        }
        if (props.personInformation.coupleBirthDate) {
            setObj["coupleBirthDate"] = moment(props.personInformation.coupleBirthDate)
        }
        if (props.personInformation.careerStartDate) {
            setObj["careerStartDate"] = moment(props.personInformation.careerStartDate).format(maskInputFormatterFormLabel)
        }
        form.setFieldsValue(setObj)

    }, [form, props.personInformation])
    useEffect(() => {
        getUpdateObj(personDetailsState)
    }, [personDetailsState]);

    useEffect(() => {
        setGenderList(dataset.genders);
    }, [dataset.genders]);
    useEffect(() => {
        setNationalityList(nationalities);
    }, [nationalities]);
    useEffect(() => {
        setMaritalList(dataset.maritalStatusTypes);
    }, [dataset.maritalStatusTypes]);
    // useEffect(() => {
    //     NumberList();
    // }, [childirenList]);
    useEffect(() => {
        setEducationDegreeList(dataset.educationDegries);
    }, [dataset.educationDegries]);
    useEffect(() => {
        setCoupleWorkList(dataset.coupleWorkStatuses);
    }, [dataset.coupleWorkStatuses]);
    useEffect(() => {
        setBloodGroupList(dataset.bloodGroups);
    }, [dataset.bloodGroups]);
    useEffect(() => {
        setDisabiltyDegreeList(dataset.disabilityDegries);
    }, [dataset.disabilityDegries]);
    useEffect(() => {
        setLangList(dataset.languageTypes);
    }, [dataset.languageTypes]);
    useEffect(() => {
        const tempArr = tagList.map((data) => {
            return {
                'id': data.id,
                'displayValue': data.name
            }
        })
        setTagListState(tempArr);
    }, [tagList]);
    useEffect(() => {
        setMilitaryServiceTypesList(dataset.militaryServiceTypes);
    }, [dataset.militaryServiceTypes]);
    useEffect(() => {
        setDrivingLicenceTypesList(dataset.drivingLicenceTypes);
    }, [dataset.drivingLicenceTypes]);


    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'childrenCount') {
            setPersonDetailsState({ ...personDetailsState, "childrenCount": changedObj.childrenCount.id });
        } else if (key === 'maritalDate') {
            setPersonDetailsState({ ...personDetailsState, 'maritalDate': moment(changedObj.maritalDate).format(dateFormat) });
        } else if (key === 'coupleBirthDate') {
            setPersonDetailsState({ ...personDetailsState, 'coupleBirthDate': moment(changedObj.coupleBirthDate).format(dateFormat) });
        } else if (key === "militaryStatusType") {
            setPersonDetailsState({ ...personDetailsState, "militaryStatusType": changedObj["militaryStatusType"] });
        } else if (key === "drivingLicenceType") {
            setPersonDetailsState({ ...personDetailsState, "drivingLicenceType": changedObj["drivingLicenceType"] });
        } else if (key === 'coupleBirthDate') {
            setPersonDetailsState({ ...personDetailsState, "coupleBirthDate": moment(changedObj['coupleBirthDate']).format(dateFormat) });
        } else if (key === 'careerStartDate') {
            setPersonDetailsState({ ...personDetailsState, "careerStartDate": changedObj['careerStartDate'] });
        } else if (key === 'birthday') {
            setPersonDetailsState({ ...personDetailsState, "birthday": changedObj['birthday'] });
        } else {
            setPersonDetailsState({ ...personDetailsState, ...changedObj });
        }
    }

    return (
        personInformationIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                    requiredMark={false}
                >
                    <Row gutter={[32, 0]}>
                        <Col span={12}>
                            <div className="form-left-body">
                                <Form.Item name="birthday" label={customDateInputPlaceHolder.birthday ? t('PARTNER_BIRTHDAY') + " " + maskInputFormatterFormLabel : t('PARTNER_BIRTHDAY')} className={customDateInputErrorStatus.birthday && "table-details-error-form-class"} required >
                                    <InputMask
                                        disabled={disableStatus}
                                        onFocus={() => inputMaskShowFormatter('birthday', customDateInputPlaceHolder, setCustomDateInputPlaceHolder)}
                                        onBlur={() => inputMaskDateBirthValidation('birthday', customDateInputPlaceHolder, setCustomDateInputPlaceHolder, customDateInputErrorStatus, setCustomDateInputErrorStatus, personInformation.birthday, personDetailsState.birthday, maskInputFormatterFormLabel)}
                                        className={inputMaskDateCustomClass(personInformation.birthday, personDetailsState.birthday, customDateInputErrorStatus.birthday)}
                                        mask={dateInputMask} placeholder={t('PLACEHOLDER_DATE')} />
                                </Form.Item>
                                {customDateInputErrorStatus.birthday &&
                                    <div className="table-details-error-text">
                                        <UilExclamationTriangle /> <span>{t('INVALID_DATE')}</span>
                                    </div>
                                }
                                <Form.Item name="gender" className="custom-select" label={t('PARTNER_GENDER')}>
                                    <DynamicSelectbox
                                        optionList={genderList}
                                        onFocus={() => getDataset(2)}
                                        className={!compareObjects(personInformation.gender, personDetailsState.gender) ? 'is-changed' : ''}
                                        placeholder={t('PARTNER_SELECT')}
                                        disabled={disableStatus}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                {personDetailsState?.gender?.id === "1" &&
                                    <Form.Item name="militaryStatusType" className="custom-select" label={t('MILITARY_STATUS_TYPE')}>
                                        <DynamicSelectbox
                                            optionList={militaryServiceTypesList}
                                            onFocus={() => getDataset(33)}
                                            className={!compareObjects(personInformation.militaryStatusType, personDetailsState.militaryStatusType) ? 'is-changed' : ''}
                                            placeholder={t('PARTNER_SELECT')}
                                            disabled={disableStatus}
                                            orderBy={'none'}
                                        ></DynamicSelectbox>
                                    </Form.Item>
                                }
                                <Form.Item name="nationality" className="custom-select" label={t('PARTNER_NATIONALITY')}>
                                    <DynamicSelectbox
                                        optionList={nationalityList}
                                        onFocus={() => getNationalities()}
                                        className={!compareObjects(personInformation.nationality, personDetailsState.nationality) ? 'is-changed' : ''}
                                        placeholder={t('PARTNER_SELECT')}
                                        disabled={disableStatus}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                <Form.Item name="nationalIdentityNumber" label={t('PARTNER_NATIONAL_ID')}>
                                    <MaskedInput disabled={disableStatus} className={!compareObjects(personInformation.nationalIdentityNumber, personDetailsState.nationalIdentityNumber) ? 'is-changed ant-input' : 'ant-input'} mask={nationalIdentityNumberMask} />
                                </Form.Item>
                                <Form.Item name="passportNumber" label={t('PARTNER_PASSAPORT_NUMBER')}>
                                    <Input disabled={disableStatus} className={!compareObjects(personInformation.passportNumber, personDetailsState.passportNumber) ? 'is-changed' : ''} placeholder="" maxLength={11} />
                                </Form.Item>
                                <Form.Item name="educationDegree" className="custom-select" label={t('PARTNER_EDUCATION_STATUS')}>
                                    <DynamicSelectbox
                                        optionList={educationDegreeList}
                                        onFocus={() => getDataset(15)}
                                        className={!compareObjects(personInformation.educationDegree, personDetailsState.educationDegree) ? 'is-changed' : ''}
                                        placeholder={t('PARTNER_SELECT')}
                                        disabled={disableStatus}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                <Form.Item name="drivingLicenceType" className="custom-select" label={t('DRIVING_LICENCE_TYPE')}>
                                    <DynamicSelectbox
                                        optionList={drivingLicenceTypesList}
                                        onFocus={() => getDataset(32)}
                                        placeholder={t('PARTNER_SELECT')}
                                        className={!compareObjects(personInformation.drivingLicenceType, personDetailsState.drivingLicenceType) ? 'is-changed' : ''}
                                        disabled={disableStatus}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>

                                {personDetailsState?.drivingLicenceType &&
                                    <Form.Item name="drivingLicenceNumber" label={t('DRIVING_LICENCE_NUMBER')}>
                                        <Input disabled={disableStatus} className={!compareObjects(personInformation.drivingLicenceNumber, personDetailsState.drivingLicenceNumber) ? 'is-changed' : ''} placeholder="" maxLength={11} />
                                    </Form.Item>
                                }
                                <Form.Item name="tags" className="custom-select" label={t('PARTNER_TAGS')}>
                                    <DynamicSelectbox
                                        disabled={disableStatus}
                                        optionList={tagListState}
                                        isMultiple={true}
                                        onFocus={() => getTagList({
                                            "searchTerm": "",
                                            "pagination": {
                                                "pageNumber": 1,
                                                "pageSize": 10,
                                                "orderBy": "Name",
                                                "ascending": true
                                            }
                                        })}
                                        className={!compareObjects(personInformation.tags, personDetailsState.tags) ? 'is-changed' : ''}

                                    ></DynamicSelectbox>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-right-body">

                                <Form.Item name="maritalStatus" className="custom-select" label={t('PARTNER_MARITAL_STATUS')}>
                                    <DynamicSelectbox
                                        optionList={maritalList}
                                        onFocus={() => getDataset(11)}
                                        className={!compareObjects(personInformation.maritalStatus, personDetailsState.maritalStatus) ? 'is-changed' : ''}
                                        placeholder={t('PARTNER_SELECT')}
                                        disabled={disableStatus}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                {personDetailsState.maritalStatus?.id === "2" && (
                                    <React.Fragment>
                                        <Form.Item name="maritalDate" label={datePickerFormatType.maritalDate === dynamicDateInputFormatter ? t('MARITAL_DATE') + " " + t('PLACEHOLDER_DATE') : t('MARITAL_DATE')}>
                                            <DatePicker
                                                locale={localeLang}
                                                format={datePickerFormatType.maritalDate}
                                                disabledDate={disabledDate}
                                                showToday={false}
                                                className={!compareDate(personInformation.maritalDate, personDetailsState.maritalDate) ? 'is-changed' : ''}
                                                disabled={disableStatus}
                                                placeholder={t('PLACEHOLDER_DATE')}
                                                onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'maritalDate', dynamicDateInputFormatter)}
                                                onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'maritalDate', dateFormatList)}
                                            />
                                        </Form.Item>
                                        <Form.Item name="coupleBirthDate" label={datePickerFormatType.coupleBirthDate === dynamicDateInputFormatter ? t('COUPLE_BIRTH_DATE') + " " + t('PLACEHOLDER_DATE') : t('COUPLE_BIRTH_DATE')}>
                                            <DatePicker
                                                locale={localeLang}
                                                format={datePickerFormatType.coupleBirthDate}
                                                disabledDate={disabledDate}
                                                showToday={false}
                                                className={!compareDate(personInformation.coupleBirthDate, personDetailsState.coupleBirthDate) ? 'is-changed' : ''}
                                                disabled={disableStatus}
                                                placeholder={t('PLACEHOLDER_DATE')}
                                                onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'coupleBirthDate', dynamicDateInputFormatter)}
                                                onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'coupleBirthDate', dateFormatList)}
                                            />
                                        </Form.Item>
                                        <Form.Item name="coupleWorkStatus" className="custom-select" label={t('PARTNER_COUPLE_WORK')}>
                                            <DynamicSelectbox
                                                optionList={coupleWorkList}
                                                onFocus={() => getDataset(12)}
                                                className={!compareObjects(personInformation.coupleWorkStatus, personDetailsState.coupleWorkStatus) ? 'is-changed' : ''}
                                                placeholder={t('PARTNER_SELECT')}
                                                disabled={disableStatus}
                                                orderBy={'none'}
                                            ></DynamicSelectbox>
                                        </Form.Item>
                                    </React.Fragment>
                                )}
                                <Form.Item name="childrenCount" className="custom-select" label={t('PARTNER_CHILDREN_COUNT')}>
                                    <DynamicSelectbox
                                        optionList={childirenList}
                                        className={personInformation.childrenCount !== personDetailsState.childrenCount ? 'is-changed' : ''}
                                        placeholder={t('PARTNER_SELECT')}
                                        disabled={disableStatus}
                                        orderBy={'asc'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                <Form.Item name="bloodGroup" className="custom-select" label={t('PARTNER_BLOOD_GROUP')}>
                                    <DynamicSelectbox
                                        optionList={bloodGroupList}
                                        onFocus={() => getDataset(14)}
                                        className={!compareObjects(personInformation.bloodGroup, personDetailsState.bloodGroup) ? 'is-changed' : ''}
                                        placeholder={t('PARTNER_SELECT')}
                                        disabled={disableStatus}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                <Form.Item name="disabilityDegree" className="custom-select" label={t('PARTNER_DISABILITY_DEGREE')}>
                                    <DynamicSelectbox
                                        disabled={disableStatus}
                                        optionList={disabiltyDegreeList}
                                        onFocus={() => getDataset(13)}
                                        className={!compareObjects(personInformation.disabilityDegree, personDetailsState.disabilityDegree) ? 'is-changed' : ''}
                                        placeholder={disableStatus ? '' : t('PARTNER_SELECT')}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                <Form.Item name="careerStartDate" label={customDateInputPlaceHolder.careerStartDate ? t('PARTNER_CAREER_START_DATE') + " " + maskInputFormatterFormLabel : t('PARTNER_CAREER_START_DATE')} className={customDateInputErrorStatus.careerStartDate && "table-details-error-form-class"}  >
                                    <InputMask
                                        disabled={disableStatus}
                                        onFocus={() => inputMaskShowFormatter('careerStartDate', customDateInputPlaceHolder, setCustomDateInputPlaceHolder)}
                                        onBlur={() => inputMaskDateValidation('careerStartDate', customDateInputPlaceHolder, setCustomDateInputPlaceHolder, customDateInputErrorStatus, setCustomDateInputErrorStatus, personInformation.careerStartDate, personDetailsState.careerStartDate, maskInputFormatterFormLabel)}
                                        className={inputMaskDateCustomClass(personInformation.careerStartDate, personDetailsState.careerStartDate, customDateInputErrorStatus.careerStartDate)}
                                        mask={dateInputMask} placeholder={t('PLACEHOLDER_DATE')} />
                                </Form.Item>
                                {customDateInputErrorStatus.careerStartDate &&
                                    <div className="table-details-error-text">
                                        <UilExclamationTriangle /> <span>{t('INVALID_DATE')}</span>
                                    </div>
                                }
                                <Form.Item name="languages" className="custom-select" label={t('PARTNER_LANGUAGE')}>
                                    <DynamicSelectbox
                                        disabled={disableStatus}
                                        optionList={langList}
                                        isMultiple={true}
                                        onFocus={() => getDataset(22)}
                                        className={!compareObjects(personInformation.languages, personDetailsState.languages) ? 'is-changed' : ''}

                                    ></DynamicSelectbox>
                                </Form.Item>
                                <div className={personInformation.isStudent !== personDetailsState.isStudent ? 'is-checked' : ''}>
                                    <Form.Item name="isStudent" valuePropName="checked">
                                        <Checkbox disabled={disableStatus}>{t('PARTNER_STUDENT')}</Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider>

        ))
}

const mapStateToProps = (state) => {
    return {
        nationalities: state.addressReducer.nationalities,
        dataset: state.dataset,
        tagList: state.tag.tagList.results,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getNationalities: () => { dispatch(getNationalities()) },
        getTagList: (searchData) => { dispatch(getTagList(searchData)) }

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonInformation);
