import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Upload, Radio, Spin, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { randomNumberInRange } from '../../utils/commonFormFunctions';
import { UploadOutlined } from '@ant-design/icons';
import { UilEdit, UilTimes } from '@iconscout/react-unicons'
import DynamicToastMessage from '../dynamicToastMessage/index';
import '../../styles/modals.scss';

const DynamicEducationInlineModal = ({isEditModal, editModalData,  dataGet, index, keyboard = true, modalVisible = null, modalWidth = null, handleCancel = null, loader = false }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    'videoName': '',
    'selectPlatform': '',
    'youtubeUrl': '',
    'fileList': [],
    'description': ''
  });

  useEffect(() => {
    if (isEditModal && editModalData) {
      setFormData({
        videoName: editModalData.videoName || '',
        selectPlatform: '',
        youtubeUrl: editModalData.youtubeUrl || '',
        fileList: [],
        description: editModalData.description || ''
      });
    }
  }, [isEditModal, editModalData]);

  const videoUploadTypes = [
    {
      label: 'Youtube ',
      value: 'youtube',
    },
    {
      label: 'HRplan',
      value: 'hrplan',
    },
  ];

  const listPush = () => {
    if (formData?.videoName == '') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ENTER_VIDEO_NAME_REQUIRED'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false
    }
    if (formData?.description == '') {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('ENTER_VIDEO_DESCRIPTION'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false
    }
    if (formData?.fileList.length <= 0 && formData?.selectPlatform === "hrplan") {
      DynamicToastMessage({
        'key': 'error_key',
        'title': t('ERROR'),
        'description': t('UPLOAD_VIDEO_IS_MANDATORY'),
        'statusType': 'error',
        'position': 'bottom-right'
      });
      return false
    }

    const data = {
      videoList: formData?.fileList,
      videoName: formData?.videoName,
      videoDesc: formData?.description,
      youtubeUrl: formData?.youtubeUrl,
      selectPlatform: formData?.selectPlatform,
      index: index
    }
    dataGet(data)
  }
  const closeModal = () => {
    setFormData({
      'videoName': '',
      'selectPlatform': '',
      'youtubeUrl': '',
      'fileList': [],
      'description': ''
    });
    handleCancel();
  }
  const dynamicHandleValuesChange = (val, type, name) => {
    if (type === 'video') {
      const isVideo = val?.file?.type.startsWith('video/');
      const isSizeValid = val?.file?.size <= 1000 * 1024 * 1024;
      const dataList = val.fileList
      const updatedFileList = val.fileList.filter((file) =>
        file.type.startsWith('video/')
      );
      if (isVideo && isSizeValid) {
        setFormData({ ...formData, [name]: updatedFileList });
      } else {
        if (!isVideo) {
          DynamicToastMessage({
            'key': 'error_key',
            'title': t('ERROR'),
            'description': t('UPLOAD_VIDEO_FILES_ONLY'),
            'statusType': 'error',
            'position': 'bottom-right'
          });
          setFormData({ ...formData, [name]: [] });
          return false;
        }

        if (!isSizeValid) {
          DynamicToastMessage({
            'key': 'error_key',
            'title': t('ERROR'),
            'description': t('VIDEO_FILE_MAX_LIMIT'),
            'statusType': 'error',
            'position': 'bottom-right'
          });
          setFormData({ ...formData, [name]: [] });
          return false;
        }
        if (dataList.length <= 0) {
          DynamicToastMessage({
            'key': 'error_key',
            'title': t('ERROR'),
            'description': t('ONLY_ONE_FILE_ERROR'),
            'statusType': 'error',
            'position': 'bottom-right'
          });
          setFormData({ ...formData, [name]: [] });
          return false;
        }
      }
    } else if (type === 'img') {
      const isImg = val?.file?.type?.startsWith('image/');
      const isSizeValid = val?.file?.size <= 5 * 1024 * 1024;
      if (isImg && isSizeValid) {
        const updatedFileList = val.fileList.filter((file) =>
          file.type.startsWith('image/')
        );
        setFormData({ ...formData, [name]: updatedFileList });
      } else {
        if (!isImg) {
          DynamicToastMessage({
            'key': 'error_key',
            'title': t('ERROR'),
            'description': t('SHOULD_NOT_EXCEED_IMG'),
            'statusType': 'error',
            'position': 'bottom-right'
          });
        }
        if (!isSizeValid) {
          DynamicToastMessage({
            'key': 'error_key',
            'title': t('ERROR'),
            'description': t('IMAGE_FILE_MAX_5MB'),
            'statusType': 'error',
            'position': 'bottom-right'
          });
        }
      }
    } else {
      setFormData({ ...formData, [name]: val });
    }
  }

  const beforeUploadAction = () => {
    return false;
  }
  return (
    <Modal
      key={() => randomNumberInRange(1, 5000000)}
      visible={modalVisible}
      className="education-modal"
      onCancel={() => closeModal()}
      maskClosable={false}
      width={modalWidth ? modalWidth : 520}
      keyboard={keyboard}
      closable={false}
      centered
      footer={[]}>

      <div className="position-relative">
        {loader ? <Spin className="w-100 h-100 p-absolute" size="medium" tip={`${t('EDUCATION_LOADING')}`} /> : ''}
        <div className="education-modal-header">
          <div className="education-modal-header-title"><UilEdit />{isEditModal ? t('EDIT_VIDEO') : t('ADD_VIDEO')}</div>
          <div className="education-modal-header-exit"><Button onClick={closeModal} className="transparent-btn" icon={<UilTimes />} ></Button></div>
        </div>
        <div className="education-modal-form">
          <Form layout="vertical">
            <div className="mb-6 mt-2 ant-form-item">
              <label htmlFor="videoName" className="ant-form-item-label education-modal-form-title">{t('VIDEO_NAME') + ' *'}</label>
              <Input disabled={loader} name="videoName" id="videoName" onChange={(e) => dynamicHandleValuesChange(e.target.value, 'text', 'videoName')} value={formData?.videoName} placeholder={t('ENTER_VIDEO_NAME')} />
            </div>
            {!isEditModal && 
                <div className="mb-3 mt-2 ant-form-item">
                  <label htmlFor="selectPlatform" className="ant-form-item-label education-modal-form-title">{t('PLATFORM') + ' *'}</label>
                  <Radio.Group disabled={loader} name="selectPlatform" options={videoUploadTypes} onChange={(e) => { dynamicHandleValuesChange(e.target.value, 'radio', 'selectPlatform') }} value={formData?.selectPlatform} />
                </div>
            }

            {(!isEditModal && formData?.selectPlatform === 'youtube') &&
              <div className="mb-6 mt-2 ant-form-item">
                <label htmlFor="youtubeUrl" className="ant-form-item-label education-modal-form-title">{t('ENTER_YOUTUBE_VIDEO_URL') + ' *'}</label>
                <Input disabled={loader} placeholder={t('ENTER_YOUTUBE_VIDEO_URL')} onChange={(e) => { dynamicHandleValuesChange(e.target.value, 'text', 'youtubeUrl') }} value={formData?.youtubeUrl} />
              </div>
            }
            {(!isEditModal && formData?.selectPlatform === 'hrplan') &&
              <div className="mb-6 mt-2 ant-form-item">
                <label htmlFor="youtubeUrl" className="ant-form-item-label education-modal-form-title">{t('VIDEO_UPLOAD') + ' *'}</label>
                <Upload disabled={loader} className="d-flex w-100" beforeUpload={beforeUploadAction} multiple={false} fileList={formData?.videoList} maxCount={1} onChange={(e) => dynamicHandleValuesChange(e, 'video', 'fileList')}>
                  <Button disabled={loader} className="w-100 white-btn" icon={<UploadOutlined />}>{t('VIDEO_UPLOAD')}</Button>
                </Upload>
              </div>
            }

            <div className="mb-6 ant-form-item">
              <label htmlFor="description" className="ant-form-item-label education-modal-form-title">{t('PARTNER_DESCRIPTION') + ' *'}</label>
              <Input.TextArea disabled={loader} name="description" id="description" onChange={(e) => { dynamicHandleValuesChange(e.target.value, 'text', 'description') }} value={formData?.description} placeholder={t('PARTNER_TEXT_DESCRIPTION')} />
            </div>
          </Form>
          <div className="d-flex justify-space-between">
            <Button disabled={loader} className="ant-btn white-btn mr-3 w-25 h-20" onClick={closeModal}>
              {t('PARTNER_CLOSE')}
            </Button>
            <Button disabled={loader} onClick={listPush} className="ant-btn primary-btn h-20">
              {isEditModal ? t('PARTNER_EDIT') : t('PARTNER_SAVE')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DynamicEducationInlineModal   