import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { setTimeOffRequestApproveDrawer } from '../../../redux/actions/time-off-drawer-action';
import {
    getTimeOffRequestMe, getTimeOffRequestStepMe, timeOffRequestSearchMe, timeOffDownload, getTimeOffRequest, getTimeOffRequestStep,
    timeOffCancel, resetTimeOffApproveResponseStatus, deleteTimeOffRequest, deleteTimeOffRequestReset, timeOffRequestUpdateReset
} from '../../../redux/actions/timeOff.actions'
import { getTimeOffDashboardPaiChartData } from '../../../redux/actions/dashboard.actions';

import { Button, Dropdown, Card, Row } from 'antd';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Media from 'react-media';
import { MoreOutlined } from '@ant-design/icons';
import { UilPlus } from '@iconscout/react-unicons';

import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../../dynamicTable/index';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import { timeoff_dropdown } from '../../../features/global-dropdown-menu/index';
import { dateFormatConvert, useWindowDimensions } from '../../../utils/config';

import Can from "../../../authorization/index";
import Cannot from "../../../authorization/cannot";

function TimeOffRequestMy(props) {
    const { t } = useTranslation();
    const { getTimeOffRequestMe, getTimeOffDashboardPaiChartData, paiChartData, getTimeOffRequestStepMe, totalNumberOfRecords, timeOffApproveResponse, profile, permissions, getTimeOffRequest, getTimeOffRequestStep,
        timeOffRequestSearchMe, setTimeOffRequestApproveDrawer, deleteTimeOffRequestReset, createTimeOffRequestResponse } = props
    const { gridSettings, timeOffRequestList, timeOffRequestSearchMeIsLoading, timeOffDownload, resetTimeOffApproveResponseStatus, deleteTimeOffRequestResponse } = props
    const { setSelectedData, deleteModalData, setDeleteModalData, timeOffApproveUpdateStatus, timeOffRequestUpdateReset,
        cancelModalData, setCancelModalData, handleCloseModal, initialSearchData, openTimeOffAddDrawer } = props;
    const initialGetGridSettingData = {
        pageId: 5, //timeoff request my
        gridId: 1, //timeoff grid
    }
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData)
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [searchData, setSearchData] = useState(initialSearchData)
    const { width } = useWindowDimensions();
    const [tableKey, setTableKey] = useState(2596);

    useEffect(() => {
        timeOffRequestSearchMe(searchData)
    }, [searchData]);

    const handleSaveGridSettings = (d) => { }
    const timeOffCancelBtn = (id) => {
        setCancelModalData({ ...cancelModalData, "isVisible": true, "id": id })
    }
    const timeOffDeletelBtn = (id, timeOffTypeName) => {
        setDeleteModalData({ ...deleteModalData, "isVisible": true, "id": id, 'timeOffTypeName': timeOffTypeName })
    }
    const handleResetAllfilters = () => {
        let key = tableKey;
        key = key + 1;
        setTableKey(key);
        setIsShowPassives(false)
        setSearchData(initialSearchData)
        timeOffRequestSearchMe(initialSearchData)
    }
    const handleTableFilter = (data) => {
        setSearchData({
            ...searchData, "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "timeOffRequestDate",
                "ascending": false
            },
            "stateTypes": data.stateType, "timeOffTypes": data.timeOffTypeName
        })
    }
    const showTimeOffRequestApproveDrawer = (id) => {
        setTimeOffRequestApproveDrawer(true);
        if (id !== undefined) {
            getTimeOffRequestMe(id)
            getTimeOffRequestStepMe(id)
            setSelectedData({ 'resourceId': id })
        }
    }
    useEffect(() => {
        if (createTimeOffRequestResponse.status === 'SUCCESS') {
            timeOffRequestSearchMe(searchData)
            getTimeOffDashboardPaiChartData(profile.resourceId, 'FH25DW56DYU5')
        }
    }, [createTimeOffRequestResponse])
    useEffect(() => {
        if (timeOffApproveUpdateStatus.status === 'SUCCESS') {
            getTimeOffDashboardPaiChartData(profile.resourceId, 'FH25DW56DYU5')
            timeOffRequestSearchMe(searchData)
            timeOffRequestUpdateReset();
        }
    }, [timeOffApproveUpdateStatus])
    useEffect(() => {
        if (deleteTimeOffRequestResponse?.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'DELETE_SUCCESS',
                'title': t('SUCCESS'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (timeOffRequestList?.length > 1) {
                timeOffRequestSearchMe(searchData);
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            deleteTimeOffRequestReset()
        } else if (deleteTimeOffRequestResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_FAIL',
                'title': t('ERROR'),
                'description': deleteTimeOffRequestResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffRequestReset()
        }
    }, [deleteTimeOffRequestResponse])

    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'APPROVE_SUCCESS',
                'title': t('SUCCESS'),
                'description': timeOffApproveResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if (timeOffApproveResponse.resourceId !== undefined) {
                timeOffRequestSearchMe(searchData);
                getTimeOffRequest(timeOffApproveResponse.resourceId)
                getTimeOffRequestStep(timeOffApproveResponse.resourceId)
            }
            resetTimeOffApproveResponseStatus();
            timeOffRequestSearchMe(searchData);
            handleCloseModal();

        } else if (timeOffApproveResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DELETE_FAIL',
                'title': t('ERROR'),
                'description': timeOffApproveResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            deleteTimeOffRequestReset()
        }
    }, [timeOffApproveResponse])

    const data = timeOffRequestList.map((row) => {
        return (
            {
                "key": row.resourceId,
                "timeOffRequestDate": { "text": row.timeOffRequestDate, "id": row.resourceId, "stateTypeId": row.stateType?.id, "timeOffTypeName": row.timeOffTypeName },
                "timeOffTypeName": row.timeOffTypeName,
                "startDate": row.startDate,
                "endDate": row.endDate,
                "balance": { "balance": row.balance, "unitId": row.unitSetIdx },
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue },
                "personToApprove": row.personToApprove
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['timeOffTypeName', 'stateType'] : [],
        sortableColumns: ['timeOffRequestDate', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'personToApprove', 'stateType'],
        visibilityOptionExcludes: ['timeOffRequestDate', 'timeOffTypeName', 'startDate', 'endDate', 'balance', 'stateType'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: "timeOffRequestMy",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    })
    const leftActionMenu = () => {
        return (
            width >= 875 &&
            <div className="timeoff-person-table-action-buttons time-off-request-my-card-add-time-off">
                <Can I="timeoff.request.add">
                    <Button onClick={() => openTimeOffAddDrawer()} className="flex-class svg-class primary-btn h-32 px-3 w-auto" icon={<UilPlus />}>{t('HEADER_CREATE_TIME_REQUEST')}</Button>
                </Can>
            </div>
        )
    }
    const paginationConfig = () => ({
        current: searchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : searchData.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }
    return (
        <div className="table-container">
            {paiChartData.map((data, index) => {
                return (
                    <Card key={index} className="timeoff-container-rights-card time-off-request-my-card">
                        <h1>{data.timeOffTypeName}</h1>
                        <Row>
                            <div className="trc-row">
                                <div className="chart-column">
                                    <CircularProgressbarWithChildren
                                        value={(data.remainingValue * 100) / data.maxBalance}
                                        styles={buildStyles({
                                            pathColor: "#0ACF83",
                                            trailColor: "#DDDDDD",
                                        })}
                                        counterClockwise
                                    >
                                        <h2>{t('PARTNER_REMAIN')}</h2>
                                        <h3>{t("TIME_OFF_REQUEST_DAYS", { "day": data.remainingValue })} </h3>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className="info-column-primary">
                                    {/* <div className="trc-column-row">
                                        <div className="trc-label">{t('TOTAL_DURATION_OF_LEAVE')}</div>
                                        <div>{t("TIME_OFF_REQUEST_DAYS", { "day": data.maxBalance })}</div>
                                    </div>
                                    <div className="trc-column-row">
                                        <div className="trc-label">{t('TIME_OFF_REQUEST_TIME_LEAVE_USED')}</div>
                                        <div>{t("TIME_OFF_REQUEST_DAYS", { "day": data.usedBalance })}</div>
                                    </div> */}
                                    <div className="trc-column-row">
                                        <div className="trc-label">{t("TIME_OFF_REQUEST_REMAINING_TIME")}</div>
                                        <div>{t("TIME_OFF_REQUEST_DAYS", { "day": data.remainingValue })}</div>
                                    </div>
                                    <div className="trc-column-row">
                                        <div className="trc-label">{t("DURATION_OF_ANNUAL_LEAVE")}</div>
                                        <div>{t("TIME_OFF_REQUEST_DAYS", { "day": data.accrualBalanceYearly })}</div>
                                    </div>
                                    <div className="trc-column-row">
                                        <div className="trc-label">{t("NEXT_TIME_OFF_ACCRUAL_DURATION")}</div>
                                        <div>{data.newYearlyTimeOffRequestDate && moment(data.newYearlyTimeOffRequestDate).format(dateFormatConvert)}</div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Card>
                )
            })}
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('TIME_OFF_TYPE_TEXT')}`,
                            dataIndex: 'timeOffTypeName',
                            key: 'timeOffTypeName',
                            sorter: true,
                        },
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === '1') {
                                    return <div className='green'>{state.displayValue}</div>
                                } else if (state.id === '2') {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === '3') {
                                    return <div className='red'>{state.displayValue}</div>
                                } else if (state.id === '4') {
                                    return <div className='blue'>{state.displayValue}</div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('SETTINGS_START_DATE')}`,
                            dataIndex: 'startDate',
                            key: 'startDate',
                            sorter: true,
                            render: obj => {
                                return (moment(obj).format('LL'))
                            }
                        },

                        {
                            title: `${t('PARTNER_TIME')}`,
                            dataIndex: 'balance',
                            key: 'balance',
                            sorter: true,
                            render: obj => {
                                if (obj.unitId === 1) {
                                    return `${t('TIME_OFF_REQUEST_DAYS', { "day": obj.balance })}`
                                } else {
                                    return `${t('TIME_OFF_REQUEST_HOURS', { "hour": obj.balance })}`
                                }
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
                                dataIndex: 'timeOffRequestDate',
                                key: 'timeOffRequestDate',
                                sorter: true,
                                render: obj =>
                                    <div className="imageNameActionColumn">
                                        <Can I="timeoff.request.read">
                                            <a onClick={() => showTimeOffRequestApproveDrawer(obj.id)} className="details-text mar-0" >{moment(obj.text).format('LL')}</a>
                                            {permissions.some(item => ["timeoff.request.cancel", "timeoff.request.export", "timeoff.request.delete"].includes(item)) &&
                                                <Dropdown
                                                    className="action-menu"
                                                    overlay={timeoff_dropdown(t, timeOffCancelBtn, timeOffDownload, timeOffDeletelBtn, obj.id, obj.stateTypeId, obj.timeOffTypeName, permissions)}
                                                    trigger={['click']}
                                                >
                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                </Dropdown>}
                                        </Can>
                                        <Cannot I="timeoff.request.read">
                                            <span >{moment(obj.text).format('LL')}</span>
                                        </Cannot>
                                    </div>
                            }
                        );
                        columns.splice(3, 0,
                            {
                                title: `${t('SETTINGS_END_DATE')}`,
                                dataIndex: 'endDate',
                                key: 'endDate',
                                sorter: true,
                                render: obj => {
                                    return (moment(obj).format('LL'))
                                }
                            })
                        columns.push({
                            title: `${t('APPROVAL_PERSON')}`,
                            dataIndex: "personToApprove",
                            key: "personToApprove",
                            sorter: true,
                        })
                    }

                    return <DynamicTable
                        key={tableKey}
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={null} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={false}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={timeOffRequestSearchMeIsLoading}
                    //   initialFilterData={initialSearchData}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        timeOffRequestList: state.timeOff.timeOffRequestSearchMeResult.results,
        timeOffRequestSearchMeIsLoading: state.timeOff.timeOffRequestSearchMeIsLoading,
        timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus,
        totalNumberOfRecords: state.timeOff.timeOffRequestSearchMeResult.totalNumberOfRecords,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        profile: state.profile,
        permissions: state.profile.permissions,
        paiChartData: state.dashboard.timeOffPaiChartData,
        createTimeOffRequestResponse: state.timeOff.createTimeOffRequestResponse,
        deleteTimeOffRequestResponse: state.timeOff.deleteTimeOffRequestResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        timeOffRequestSearchMe: (searchData) => { dispatch(timeOffRequestSearchMe(searchData)) },
        getTimeOffRequestMe: (resourceId) => { dispatch(getTimeOffRequestMe(resourceId)) },
        getTimeOffRequestStepMe: (resourceId) => { dispatch(getTimeOffRequestStepMe(resourceId)) },
        setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)) },
        timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)) },
        timeOffDownload: (props) => { dispatch(timeOffDownload(props)) },
        resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()) },
        deleteTimeOffRequest: (resourceId) => { dispatch(deleteTimeOffRequest(resourceId)) },
        deleteTimeOffRequestReset: () => { dispatch(deleteTimeOffRequestReset()) },
        getTimeOffDashboardPaiChartData: (personResourceId, timeOffResourceId) => { dispatch(getTimeOffDashboardPaiChartData(personResourceId, timeOffResourceId, false)) },
        timeOffRequestUpdateReset: () => { dispatch(timeOffRequestUpdateReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffRequestMy);