

import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';
const FileDownload = require('js-file-download');

const TravelActionType = {
    SET_TRAVEL_REQUEST_CREATE_DRAWER:"SET_TRAVEL_REQUEST_CREATE_DRAWER",
    SET_TRAVEL_REQUEST_DETAILS_DRAWER:"SET_TRAVEL_REQUEST_DETAILS_DRAWER",
    CREATE_TRAVEL_REQUEST: "CREATE_TRAVEL_REQUEST",
    CREATE_TRAVEL_SUCCESS: "CREATE_TRAVEL_SUCCESS",
    CREATE_TRAVEL_FAIL: "CREATE_TRAVEL_FAIL",
    CREATE_TRAVEL_RESET:"CREATE_TRAVEL_RESET",
    MY_TRAVEL_LIST_REQUESTS: "MY_TRAVEL_LIST_REQUESTS",
    MY_TRAVEL_LIST_SUCCESS: "MY_TRAVEL_LIST_SUCCESS",
    MY_TRAVEL_LIST_FAIL: "MY_TRAVEL_LIST_FAIL",
    TRAVEL_APPROVAL_LIST_REQUESTS:"TRAVEL_APPROVAL_LIST_REQUESTS",
    TRAVEL_APPROVAL_LIST_SUCCESS:"TRAVEL_APPROVAL_LIST_SUCCESS",
    TRAVEL_APPROVAL_LIST_FAIL:"TRAVEL_APPROVAL_LIST_FAIL",
    ALL_TRAVEL_REQUESTS_REQUESTS:"ALL_TRAVEL_REQUESTS_REQUESTS",
    ALL_TRAVEL_REQUESTS_SUCCESS:"ALL_TRAVEL_REQUESTS_SUCCESS",
    ALL_TRAVEL_REQUESTS_FAIL:"ALL_TRAVEL_REQUESTS_FAIL",
    PERSON_TRAVEL_LIST_REQUEST:"PERSON_TRAVEL_LIST_REQUEST",
    PERSON_TRAVEL_LIST_SUCCESS:"PERSON_TRAVEL_LIST_SUCCESS",
    PERSON_TRAVEL_LIST_FAIL:"PERSON_TRAVEL_LIST_FAIL",
    TRAVEL_CHANGE_STATUS_SUCCESS:"TRAVEL_CHANGE_STATUS_SUCCESS",
    TRAVEL_CHANGE_STATUS_FAIL:"TRAVEL_CHANGE_STATUS_FAIL",
    TRAVEL_CHANGE_STATUS_RESET:"TRAVEL_CHANGE_STATUS_RESET",
    GET_TRAVEL_APPROVE_ON_MAIL_REQUEST:"GET_TRAVEL_APPROVE_ON_MAIL_REQUEST",
    GET_TRAVEL_APPROVE_ON_MAIL_SUCCESS:"GET_TRAVEL_APPROVE_ON_MAIL_SUCCESS",
    GET_TRAVEL_APPROVE_ON_MAIL_FAIL:"GET_TRAVEL_APPROVE_ON_MAIL_FAIL",
    GET_TRAVEL_DETAIL_REQUEST:"GET_TRAVEL_DETAIL_REQUEST",
    GET_TRAVEL_DETAIL_SUCCESS:"GET_TRAVEL_DETAIL_SUCCESS",
    GET_TRAVEL_DETAIL_FAIL:"GET_TRAVEL_DETAIL_FAIL",
    UPDATE_TRAVEL_DETAIL_SUCCESS:"UPDATE_TRAVEL_DETAIL_SUCCESS",
    UPDATE_TRAVEL_DETAIL_FAIL:"UPDATE_TRAVEL_DETAIL_FAIL",
    UPDATE_TRAVEL_DETAIL_RESET:"UPDATE_TRAVEL_DETAIL_RESET",
    GET_TRAVEL_DETAIL_STEPS_REQUEST:"GET_TRAVEL_DETAIL_STEPS_REQUEST",
    GET_TRAVEL_DETAIL_STEPS_SUCCESS:"GET_TRAVEL_DETAIL_STEPS_SUCCESS",
    GET_TRAVEL_DETAIL_STEPS_FAIL:"GET_TRAVEL_DETAIL_STEPS_FAIL",
    DELETE_TRAVEL_REQUEST_SUCCESS:"DELETE_TRAVEL_REQUEST_SUCCESS",
    DELETE_TRAVEL_REQUEST_FAIL:"DELETE_TRAVEL_REQUEST_FAIL",
    DELETE_TRAVEL_REQUEST_RESET:"DELETE_TRAVEL_REQUEST_RESET",
    TRAVEL_TABLE_EXPORT_SUCCESS:"TRAVEL_TABLE_EXPORT_SUCCESS",
    TRAVEL_TABLE_EXPORT_FAIL:"TRAVEL_TABLE_EXPORT_FAIL",
    TRAVEL_TABLE_EXPORT_RESET:"TRAVEL_TABLE_EXPORT_RESET",
    TRAVEL_APPROVALS_TABLE_EXPORT_SUCCESS:"TRAVEL_APPROVALS_TABLE_EXPORT_SUCCESS",
    TRAVEL_APPROVALS_TABLE_EXPORT_FAIL:"TRAVEL_APPROVALS_TABLE_EXPORT_FAIL",
    TRAVEL_APPROVALS_TABLE_EXPORT_RESET:"TRAVEL_APPROVALS_TABLE_EXPORT_RESET"
  };

  const setTravelRequestCreateDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: TravelActionType.SET_TRAVEL_REQUEST_CREATE_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
  };
  const setTravelDetailsDrawer = (status) => async dispatch => {
    try {
        dispatch({
            type: TravelActionType.SET_TRAVEL_REQUEST_DETAILS_DRAWER,
            payload: status,
        });
    } catch (e) {
    }
  };
  const getMyTravelRequests = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.MY_TRAVEL_LIST_REQUESTS });
      const data = JSON.stringify(searchData);
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/travelDefinitions/search/me',
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.MY_TRAVEL_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelActionType.MY_TRAVEL_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                totalNumberOfRecords:0,
                results:[]
            }
              dispatch({ type: TravelActionType.MY_TRAVEL_LIST_SUCCESS, payload: emptyData });
            }
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.MY_TRAVEL_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getTravelApprovalRequests = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.TRAVEL_APPROVAL_LIST_REQUESTS });
      const data = JSON.stringify(searchData);
  
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/travelDefinitions/search/manager',
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
  
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.TRAVEL_APPROVAL_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelActionType.TRAVEL_APPROVAL_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                totalNumberOfRecords:0,
                results:[]
            }
              dispatch({ type: TravelActionType.TRAVEL_APPROVAL_LIST_SUCCESS, payload: emptyData });
            }
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.TRAVEL_APPROVAL_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const getAllTravelRequests = (searchData) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.ALL_TRAVEL_REQUESTS_REQUESTS });
      const data = JSON.stringify(searchData);
  
      try {
           var config = {
            method: 'post',
            url: apiUrl+'/travelDefinitions/search/all',
            headers: { 
              'Content-Type': 'application/json',
            },
            data:data
  
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.ALL_TRAVEL_REQUESTS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelActionType.ALL_TRAVEL_REQUESTS_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
            if(error.response?.status === 404){
              const emptyData = {
                totalNumberOfRecords:0,
                results:[]
            }
              dispatch({ type: TravelActionType.ALL_TRAVEL_REQUESTS_SUCCESS, payload: emptyData });
            }
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.ALL_TRAVEL_REQUESTS_FAIL, payload: error.message });
        }
      }
    };
  };
  const createTravel = (createObj) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.CREATE_TRAVEL_REQUEST });
      try {
          var data = JSON.stringify(createObj);
          var config = {
            method: 'post',
            url: apiUrl+'/travelDefinitions',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
              dispatch({ type: TravelActionType.CREATE_TRAVEL_SUCCESS, payload: response.data });
            }else{
                dispatch({ type: TravelActionType.CREATE_TRAVEL_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            if(error.response.status === 422){
              dispatch({ type: TravelActionType.CREATE_TRAVEL_FAIL, payload: error.response.data.message });
            }
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.CREATE_TRAVEL_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetCreateTravelResponse = () => {    
    return async (dispatch) => {
      dispatch({ type: TravelActionType.CREATE_TRAVEL_RESET, payload:{} });
      };
  }
  const getPersonTravel = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.PERSON_TRAVEL_LIST_REQUEST });
  
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/travelDefinitions/getPersonTravel/'+ resourceId,
            headers: { 
              'Content-Type': 'application/json',
            },
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.PERSON_TRAVEL_LIST_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelActionType.PERSON_TRAVEL_LIST_FAIL, payload: response.data.Message });
            }
          })
          .catch(function (error) {
           
              dispatch({ type: TravelActionType.PERSON_TRAVEL_LIST_FAIL, payload: error.response?.data });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.PERSON_TRAVEL_LIST_FAIL, payload: error.message });
        }
      }
    };
  };
  const travelChangeStatus = (data,type) => {
    const changeMethod = type === 'delete' ? 'delete' : 'post';
    return async (dispatch) => {
      try {
           var config = {
            method: changeMethod,
            url: apiUrl+'/traveldefinition/'+data.resourceId+'/'+type,
            headers: { 
              'Content-Type': 'application/json',
            },
            data:changeMethod !== 'delete' ? data.description: null
          };    
          hrApi(config)
          .then(function (response) {
            if(response.status === 200 ){
                dispatch({ type: TravelActionType.TRAVEL_CHANGE_STATUS_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: TravelActionType.TRAVEL_CHANGE_STATUS_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelActionType.TRAVEL_CHANGE_STATUS_FAIL, payload: error.response.data.message });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.TRAVEL_CHANGE_STATUS_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetTravelChangeStatus = () => {    
    return async (dispatch) => {
      dispatch({ type: TravelActionType.TRAVEL_CHANGE_STATUS_RESET, payload:{} });
      };
  }
  const getApproveTravelOnMail = (data) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/travelDefinition/approveOnMail?data='+data,
            headers: { }
          };
          
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_FAIL, payload: error.response.data.message });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.GET_TRAVEL_APPROVE_ON_MAIL_FAIL, payload: error.message });
        }
      }
    };
  };
  const getTravelDetail = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/travelDefinitions/getTravelDefiniton/'+resourceId,
            headers: { },
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_FAIL, payload: [] });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_FAIL, payload: [] });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_FAIL, payload: error.message });
        }
      }
    };
  };
  const getTravelDetailSteps = (resourceId) => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_STEPS_REQUEST });
      try {
           var config = {
            method: 'get',
            url: apiUrl+'/travelDefinitions/approvalList/'+resourceId,
            headers: { },

          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_STEPS_SUCCESS, payload: response.data.data });
            }else{
                dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_STEPS_FAIL, payload: [] });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_STEPS_FAIL, payload: [] });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.GET_TRAVEL_DETAIL_STEPS_FAIL, payload: error.message });
        }
      }
    };
  };
  const updateTravelRequest = (data,resourceId) => {
    return async (dispatch) => {
      try {
           var config = {
            method: 'put',
            url: apiUrl+'/travelDefinitions/'+resourceId,
            headers: { 
              'Content-Type': 'application/json'
            },
            data:data
          };
          hrApi(config)
          .then(function (response) {
            if(response.status===200){
                dispatch({ type: TravelActionType.UPDATE_TRAVEL_DETAIL_SUCCESS, payload: response.data.message });
            }else{
                dispatch({ type: TravelActionType.UPDATE_TRAVEL_DETAIL_FAIL, payload: response.data.message });
            }
          })
          .catch(function (error) {
            dispatch({ type: TravelActionType.UPDATE_TRAVEL_DETAIL_FAIL, payload: error.message });
          });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.UPDATE_TRAVEL_DETAIL_FAIL, payload: error.message });
        }
      }
    };
  };
  const updateTravelRequestReset = () => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.UPDATE_TRAVEL_DETAIL_RESET, payload:{} });
      };
  }
  const deleteTravelAction = (resourceId) => {
    return async (dispatch) => {
      try {
        var config = {
          method: 'delete',
          url: apiUrl+'/travelDefinitions/'+resourceId+'/delete',
          headers: { 
          },
        };
        hrApi(config)
        .then(function (response) {
          if(response.status===200){
              dispatch({ type: TravelActionType.DELETE_TRAVEL_REQUEST_SUCCESS, payload: response.data.message });
          }else{
              dispatch({ type: TravelActionType.DELETE_TRAVEL_REQUEST_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if(error.response?.status === 422){
            dispatch({ type: TravelActionType.DELETE_TRAVEL_REQUEST_FAIL, payload: error.response.data.message});
          }else{
            dispatch({ type: TravelActionType.DELETE_TRAVEL_REQUEST_FAIL, payload: error.message });
          }
        });
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.DELETE_TRAVEL_REQUEST_FAIL, payload: error.message });
        }
      }
    };
  };
  const deleteTravelActionReset = () => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.DELETE_TRAVEL_REQUEST_RESET, payload:{} });
      };
  }
  const travelTableExport  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);
      try {
          var config = {
            method: 'post',
            url: apiUrl+'/traveldefinition/export',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          hrApi(config)
          .then(function (response,typeId)  {
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
               FileDownload(response.data, 'traveldefinition_'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: TravelActionType.TRAVEL_TABLE_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: TravelActionType.TRAVEL_TABLE_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.TRAVEL_TABLE_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetTravelTableExport = () => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.TRAVEL_TABLE_EXPORT_RESET, payload:{} });
      };
  }
  const travelApprovalsTableExport  = (props) => {
    let newDate = Date.now();
    return async (dispatch) => {
      var data = JSON.stringify(props);
      try {
          var config = {
            method: 'post',
            url: apiUrl+'/traveldefinition/exportmanager',
            headers: { 
              'Content-Type': 'application/json; charset=utf8'
            },
            data : data,
            responseType: 'blob', // Important
          };
          hrApi(config)
          .then(function (response)  {
            if(response.status===200){
              const exportTypes = {
                0: "xlsx",
                1:"csv",
                2:"pdf"
              };
               FileDownload(response.data, 'traveldefinition_approvals_'+newDate+'.'+ exportTypes[props.exportType]);
            }else{
              var reader = new FileReader();
              reader.onload = function() {
                var renderData = reader.result;
                dispatch({ type: TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_FAIL, payload: renderData });
              }
              reader.readAsText(response.data);
            }
          })
          .catch(function (error) {
            var reader = new FileReader();
            reader.onload = function() {
              var renderData = reader.result;
              dispatch({ type: TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_FAIL, payload: renderData });
            }
            reader.readAsText(error.response.data);
          });
  
         
      } catch (error) {
        if (error.response) {
            dispatch({ type: TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_FAIL, payload: error.message });
        }
      }
    };
  };
  const resetTravelApprovalsTableExport = () => {
    return async (dispatch) => {
      dispatch({ type: TravelActionType.TRAVEL_APPROVALS_TABLE_EXPORT_RESET, payload:{} });
      };
  }
export {
    TravelActionType,
    setTravelRequestCreateDrawer,
    setTravelDetailsDrawer,
    createTravel,
    resetCreateTravelResponse,
    getMyTravelRequests,
    getTravelApprovalRequests,
    getAllTravelRequests,
    getPersonTravel,
    travelChangeStatus, 
    resetTravelChangeStatus,
    getApproveTravelOnMail,
    getTravelDetail,
    updateTravelRequest,
    updateTravelRequestReset,
    getTravelDetailSteps,
    deleteTravelAction,
    deleteTravelActionReset,
    travelTableExport,
    resetTravelTableExport,
    travelApprovalsTableExport,
    resetTravelApprovalsTableExport
};