import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { getMyTravelRequests, getTravelDetail, getTravelDetailSteps, deleteTravelAction } from '../../redux/actions/travel.actions';

import { Button, Dropdown } from 'antd';
import { UilPlus } from '@iconscout/react-unicons';
import { MoreOutlined } from '@ant-design/icons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import { dateFormatConvert, useWindowDimensions, numberFormatTR } from '../../utils/config';
import { travelListDropdown } from '../../features/global-dropdown-menu/index';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import Can from "../../authorization/index";

function TravelRequests(props) {

    const { t } = useTranslation();
    const { openCreateTravelRequestDrawer, getMyTravelRequests, myTravelRequestInitialSearch, createTravelRequests,
        openTravelDetailsDrawer, setSelectTravelRequest, deleteTravelAction, myTravelRequestSearchState,
        setMyTravelRequestSearchState, myTravelRequestListLoading, myTravelRequestList, totalNumberOfRecords,
        getTravelDetail, getTravelDetailSteps
    } = props
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

    useEffect(() => {
        if (createTravelRequests.status === "SUCCESS") {
            getMyTravelRequests(myTravelRequestSearchState);
        }
    }, [createTravelRequests])

    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setMyTravelRequestSearchState({ ...myTravelRequestInitialSearch });
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...myTravelRequestSearchState.pagination, "pageNumber": 1 }
        setMyTravelRequestSearchState({ ...myTravelRequestSearchState, "SearchTerm": e, "pagination": pagingObj })
    }
    const handleTableFilter = (data) => {
        setMyTravelRequestSearchState({ ...myTravelRequestSearchState, "stateTypes": data.stateType })
    }
    const showTravelDetails = (id) => {
        if (id !== undefined) {
            openTravelDetailsDrawer();
            setSelectTravelRequest(id);
            getTravelDetail(id);
            getTravelDetailSteps(id);
        }
    }
    useEffect(() => {
        if (myTravelRequestSearchState !== myTravelRequestInitialSearch) {
            getMyTravelRequests(myTravelRequestSearchState);
        }
    }, [myTravelRequestSearchState]);

    const data = myTravelRequestList?.map((row) => {
        return (
            {
                "key": row.resourceId,
                "travelDescription": { "text": row.travelDescription, "id": row.resourceId },
                "departureDate": moment(row.departureDate).format(dateFormatConvert),
                "returnDate": moment(row.returnDate).format(dateFormatConvert),
                "advanceType": t('JOB_ADVANCE'),
                "countryName": row.countryName,
                "cityName": row.cityName,
                "stateType": { "id": row.stateTypeId, "displayValue": row.stateTypeName },
                "estimatedTotal": row.estimatedTotal,
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType'] : [],
        sortableColumns: ['travelDescription', 'departureDate', 'returnDate', 'countryName', 'cityName', 'stateType', 'estimatedTotal'],
        visibilityOptionExcludes: ['travelDescription', 'departureDate', 'returnDate', 'countryName', 'cityName', 'stateType', 'estimatedTotal'],
        gridSettings: null,
        page: "TravelRequests",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    });
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.add">
                        <Button onClick={() => openCreateTravelRequestDrawer()} className="flex-class svg-class primary-btn h-32 w-auto" icon={<UilPlus />}>{t('CREATE_NEW_TRAVEL_REQUEST')}</Button>
                        <div className='pad-right-16' />
                    </Can>
                }
            </div>
        )
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    });
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...myTravelRequestSearchState.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : myTravelRequestSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setMyTravelRequestSearchState({ ...myTravelRequestSearchState, 'pagination': paginationObj })
    }
    const canDelete = (id, count) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'count': count, 'isVisible': true })
    }
    const handleShowPassivesToogle = (e) => { }
    return (
        <div className="table-container">
            <DynamicRequestModal
                key={'delete-travel-action'}
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteTravelAction}
                confirmData={deleteModalData?.id}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_TRAVEL_REQUEST')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <Media query="(min-width: 1000px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('DEPARTURE_DATE')}`,
                            dataIndex: 'departureDate',
                            key: 'departureDate',
                            sorter: true,
                        },
                        {
                            title: `${t('DATE_OF_RETURN')}`,
                            dataIndex: 'returnDate',
                            key: 'returnDate',
                            sorter: true,
                        },
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === 1) {
                                    return <div className='yellow'>
                                        {state.displayValue}
                                    </div>
                                }
                                else if (state.id === 2) {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === 3) {
                                    return <div className='red'>{state.displayValue}</div>
                                } else if (state.id === 4) {
                                    return <div className=''>{state.displayValue}</div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('ORGANIZATION_COUNTRY')}`,
                            dataIndex: 'countryName',
                            key: 'countryName',
                            sorter: true,
                        },
                        {
                            title: `${t('ORGANIZATION_PROVINCE')}`,
                            dataIndex: 'cityName',
                            key: 'cityName',
                            sorter: true,
                        },
                        {
                            title: `${t('ESTIMATED_BUDGET')}`,
                            dataIndex: 'estimatedTotal',
                            key: 'estimatedTotal',
                            sorter: true,
                            render: data => {
                                return numberFormatTR.format(data) + ' ' + 'TL'
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('REASON_FOR_TRAVEL')}`,
                                dataIndex: 'travelDescription',
                                key: 'travelDescription',
                                sorter: true,
                                render: (obj,state) =>
                                    <div className="imageNameActionColumn">
                                        <a onClick={() => showTravelDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                        {state.stateType.id == "1" && <Dropdown className="action-menu" overlay={travelListDropdown(t, canDelete, obj.id)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                        </Dropdown>}
                                    </div>
                                    
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={myTravelRequestListLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        myTravelRequestListLoading: state.travel.myTravelRequestListLoading,
        myTravelRequestList: state.travel.myTravelRequestList.results,
        totalNumberOfRecords: state.travel.myTravelRequestList.totalNumberOfRecords,
        createTravelRequests: state.travel.createTravelRequests,
        permissions: state.profile.permissions
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMyTravelRequests: (myTravelRequestSearchState) => { dispatch(getMyTravelRequests(myTravelRequestSearchState)) },
        getTravelDetail: (id) => { dispatch(getTravelDetail(id)) },
        getTravelDetailSteps: (id) => { dispatch(getTravelDetailSteps(id)) },
        deleteTravelAction: (id) => { dispatch(deleteTravelAction(id)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TravelRequests);
