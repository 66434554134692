import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setAssignmentDetailOpenDraw, setAssignmentDetailAddOpenDraw, setAssignmentDetailAddTabStatus } from '../../redux/actions/person-detail-tab-action';
import { getJobRecord, getSalaryHistory, getJobHistory, deleteJobHistory } from '../../redux/actions/person.actions';

import { Button, Col, Row, Card, Spin, Dropdown } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';

import moment from 'moment';

import { dateFormatConvert, customTableScrolling, useWindowDimensions } from '../../utils/config';
import { tableDeletelistDropdown } from '../../features/global-dropdown-menu';
import { noData } from '../../utils/commonFormFunctions';
import DynamicRequestModal from '../dynamicModal/requestModal';
import Forbidden from '../forbidden';
import Can from '../../authorization/index';

import '../../styles/table-draw.scss';
import '../../styles/custom-table-card.scss';


function AssignmentList(props) {
    const { t } = useTranslation();
    const { height } = useWindowDimensions();
    const { setAssignmentDetailOpenDraw, getSalaryHistory, getJobHistory, setAssignmentDetailAddOpenDraw, disableStatus,
        page = "", jobHistory, isJobHistoryLoading, salaryHistory, person, getJobRecord, permissions, deleteJobHistory } = props;
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })
    const openDraw = (resourceId) => {
        setAssignmentDetailOpenDraw(true)
        getJobRecord({ "resourceId": person.resourceId, "jobRecordResourceId": resourceId })
    }
    function AddDraw() {
        setAssignmentDetailAddOpenDraw(true)
    }
    useEffect(() => {
        getJobHistory(person.resourceId, page)
        getSalaryHistory(person.resourceId)
    }, [person])
    const canDelete = (id, count) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'count': count, 'isVisible': true })
    }
    return (
        isJobHistoryLoading ? <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin> :
            <>
                <DynamicRequestModal
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteJobHistory}
                    confirmData={deleteModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('DELETE_ASSIGNMENT_REQUEST')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <div className={!isJobHistoryLoading ? customTableScrolling(jobHistory.length, height, "custom-table-card-container") : 'custom-table-card-container'} >
                    <div className="w-100 flex-end">
                        <Can I="person.assignment.add">
                            {salaryHistory ?
                                <Button disabled={disableStatus} onClick={() => AddDraw()} className="white-btn m-w-180 h-32" ><PlusOutlined /> {t('PARTNER_ADD_ASSIGNMENTS')}</Button>
                                :
                                <Button className="white-btn m-w-180 h-32" disabled ><PlusOutlined /> {t('PARTNER_ADD_ASSIGNMENTS')}</Button>
                            }
                        </Can>
                    </div>
                    {
                        ((permissions.includes("person.assignment.list") && page !== 'profile') || page === 'profile') ?
                            jobHistory.length > 0 ?
                                <div className="custom-table-card-class customize-salary-list">
                                    <div className="custom-table-card-title-class">
                                        <Col span={24}>
                                            <Row>
                                                <Col span={7}>
                                                    <h1>{t('PARTNER_APPOINTMENT_DATE')}</h1>
                                                </Col>
                                                <Col span={7} >
                                                    <h1>{t('PARTNER_JOB2')}</h1>
                                                </Col>
                                                <Col span={7} >
                                                    <h1>{t('PARTNER_DEPARTMENT')}</h1>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>

                                    {jobHistory.map((item) => {
                                        return (
                                            <Card key={item.resourceId} className={permissions.includes("person.assignment.read") ? "custom-table-card clickable" : "custom-table-card"}  >
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={7} onClick={() => permissions.includes("person.assignment.read") ? openDraw(item.resourceId) : null}>
                                                            <p>{item.startDate ? moment(item.startDate).format(dateFormatConvert) : ""}</p>
                                                        </Col>
                                                        <Col span={7} onClick={() => permissions.includes("person.assignment.read") ? openDraw(item.resourceId) : null}>
                                                            <p>{item.jobTitle}</p>
                                                        </Col>
                                                        <Col span={7} onClick={() => permissions.includes("person.assignment.read") ? openDraw(item.resourceId) : null}>
                                                            <p>{item.department}</p>
                                                        </Col>
                                                        <Col span={3}>
                                                            <div className="custom-table-card-icon clickable w-100 flex-end">
                                                                <Dropdown
                                                                    className="action-menu"
                                                                    overlay={tableDeletelistDropdown(t, canDelete, item.resourceId)}
                                                                    trigger={['click']}
                                                                    disabled={disableStatus}
                                                                >
                                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                                </Dropdown>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Card>
                                        )
                                    })}

                                </div>
                                :
                                <div className="custom-table-card-class">
                                    {noData(t)}
                                </div>
                            :
                            (<Forbidden size="sm" />)
                    }
                </div>
            </>
    )
}

const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        isJobHistoryLoading: state.person.isJobHistoryLoading,
        permissions: state.profile.permissions
    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setAssignmentDetailOpenDraw: (props) => { dispatch(setAssignmentDetailOpenDraw(props)); },
        setAssignmentDetailAddTabStatus: (props) => { dispatch(setAssignmentDetailAddTabStatus(props)); },
        setAssignmentDetailAddOpenDraw: (props) => { dispatch(setAssignmentDetailAddOpenDraw(props)); },
        getJobRecord: (id) => { dispatch(getJobRecord(id)) },
        getJobHistory: (id, page) => { dispatch(getJobHistory(id, page)) },
        getSalaryHistory: (id) => { dispatch(getSalaryHistory(id)) },
        deleteJobHistory: (id) => { dispatch(deleteJobHistory(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentList)