import React from 'react'
import { useTranslation } from 'react-i18next';
import { UilFileAlt  } from "@iconscout/react-unicons";
import { ToastContainer } from 'react-toastify';


import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicToastMessage from '../dynamicToastMessage/index'
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../forbidden";

function InvoiceList(props) {
    const { t } = useTranslation();
    const { invoiceList } = props

    const handleDownloadFile = async (url,isInvoice) => {

        try {
            const response = await fetch(url);
            if (!response.ok) {
              DynamicToastMessage({
                'key': 'error_key',
                'description': isInvoice ? t('INVOICE_NOT_READY') : t('DOC_NOT_FOUND'),
                'statusType': 'error',
                'position': 'bottom-right'
              });
            } else {
              const blob = await response.blob();
              const downloadUrl = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              const fileName = url.split('/').pop() || 'document.pdf'
              link.href = downloadUrl;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            }
        } catch (error) {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': t('DOC_ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
        }

    }

    const columns = [
        {
            title: `${t('INVOICE_DATE')}`,
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            sorter: true,
            render: obj => {
                return (moment(obj).format('LL'))
            }
        },
        {
            title: `${t('INVOICE_PACKAGE')}`,
            dataIndex: 'packageName',
            key: 'packageName',
            sorter: true,
        },
        {
            title: `${t('INVOICE_NUMBER')}`,
            dataIndex: 'paymentNumber',
            key: 'paymentNumber',
            sorter: true,
        },
        {
            title: `${t('RECEIPT_DOC')}`,
            dataIndex: 'receiptUrl',
            key: 'receiptUrl',
            sorter: true,
            render: obj => {
                return obj ? (
                    <span className="download-pdf" onClick={()=>handleDownloadFile(obj)}><UilFileAlt  />{ t("RECEIPT_DOWNLOAD") }</span>
                ) : null
            }
        },
        {
            title: `${t('INVOICE_DOC')}`,
            dataIndex: 'invoiceUrl',
            key: 'invoiceUrl',
            sorter: true,
            render: obj => {
                return obj ? (
                    <span className="download-pdf" onClick={()=>handleDownloadFile(obj,true)}><UilFileAlt  />{ t("INVOICE_DOWNLOAD") }</span>
                ) : null
            }
        },
        {
            title: `${t('INVOICE_AMOUNT')}`,
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            align: 'right',
            render: (obj,data) => {
                return (data?.currencyId == 1 ? obj.toLocaleString('tr-TR')+' ₺' : '$'+obj.toLocaleString('en-US').replace(',' , '') )
            }
        }
    ];

    const data = invoiceList?.map((row) => {
        return (
            {
                "key": row.paymentNumber,
                "paymentDate": row.paymentDate,
                "amount": row.amount,
                "orderType": row.orderType,
                "packageName": row.packageName,
                "paymentNumber": row.paymentNumber,
                "invoiceUrl": row.invoiceUrl,
                "receiptUrl": row.receiptUrl,
                "currencyId": row.currencyId,
            }
        );
    })

    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        visibilityOptionExcludes: ['paymentDate', 'amount', 'orderType', 'packageName', 'paymentNumber', 'invoiceUrl', 'receiptUrl'],
        gridSettings: [],
        page: "allTimeOffRequest",
        showVisibilityMenu: false,
        emptyTableText: t('NO_PREVIOUS_INVOICE')
    })

    const paginationConfig = () => (false)

    return (
        <>
            <div className="table-container table-without-menu">
                <Can I="payment">
                    <DynamicTable
                        key="invoice-list"
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} 
                        onSearch={null} 
                        searchPlaceholder=""
                        searchBox={false}
                        checkboxStatus={false}
                        passiveButton={false}
                    />
                </Can>
                <Cannot I="payment">
                    <Forbidden size="sm" />
                </Cannot>
                
            </div>
            <ToastContainer />
        </>
        
    )
}

export default InvoiceList;