import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, ConfigProvider, Input, Spin } from 'antd';
import { getAssignedTasksStepList } from "../../../../redux/actions/task.actions";
import '../../../../styles/person.scss';
import '../../../../styles/advances.scss';

function DetailAssignedTaskRequestsTab(props) {
    const { t } = useTranslation();
    const inputRef = useRef([]);
    const [detailAssignedTaskRequest, setDetailAssignedTaskRequest] = useState({ data: {} });
    const [taskDescriptionLabelState, setTaskDescriptionLabelState] = useState([]);
    const { setDetailObject, currentTab,
        assignedTaskInformation, assignedTaskInformationLoading, assignedTasksListState } = props;
    const [form] = Form.useForm();

    const handleValuesChange = (event, item) => {
        const newData = { ...detailAssignedTaskRequest.data };
        const resourceId = assignedTasksListState[currentTab]?.resourceId;
        const targetItem = newData[resourceId].find(i => i.resourceId === item.resourceId);
        if (targetItem) {
            targetItem.value = event.target.value;
        }
        setDetailAssignedTaskRequest({ ...detailAssignedTaskRequest, data: newData });
    }
    const mapFormItem = (item, index) => {
        if (item.type === "input") {
            return (
                <Form.Item key={index} name={item.resourceId} label={item.attributeName}>
                    <Input
                        disabled={assignedTasksListState[currentTab]?.isCompleted}
                        ref={el => inputRef.current[index] = el}
                        value={detailAssignedTaskRequest.data[assignedTasksListState[currentTab]?.resourceId][index]?.value}
                        onChange={(e) => handleValuesChange(e, item)}
                    />
                </Form.Item>
            );
        } else if (item.type === "textArea") {
            return (
                <Form.Item key={index} name={item.resourceId} label={item.attributeName}>
                    <Input.TextArea
                        disabled={assignedTasksListState[currentTab]?.isCompleted}
                        ref={el => inputRef.current[index] = el}
                        value={item.value}
                        onChange={(e) => handleValuesChange(e, item)}
                    />
                </Form.Item>
            );
        } else {
            return (<Form.Item key={index} className="pt-4 pb-1 mar-0" name={item.resourceId} label={item.attributeName}>
                <p className="f-16 black" >{detailAssignedTaskRequest.data[assignedTasksListState[currentTab]?.resourceId][index]?.value}</p>
            </Form.Item>);
        }
    };

    useEffect(() => {
        setDetailObject(detailAssignedTaskRequest);
    }, [detailAssignedTaskRequest]);
    useEffect(() => {
        if (!assignedTaskInformationLoading && assignedTasksListState.length > 0) {
            setTaskDescriptionLabelState({ ...taskDescriptionLabelState, [assignedTasksListState[currentTab]?.resourceId]: assignedTaskInformation?.taskDescription });
            if (!Object.keys(detailAssignedTaskRequest.data).includes(assignedTasksListState[currentTab]?.resourceId)) {
                var formList = [];
                let initialValues = {};
                formList.push(
                    // {
                    //     "resourceId": "description_" + assignedTasksListState[currentTab]?.resourceId,
                    //     "attributeName": t('ADVANCE_DESCRIPTION'),
                    //     "value": "",
                    //     "type": "textArea"
                    // },
                    {
                        "resourceId": "missionDescription_" + assignedTasksListState[currentTab]?.resourceId,
                        "attributeName": t('MISSION_DESCRIPTION'),
                        "value": assignedTaskInformation?.personTasks[currentTab]?.taskDescription,
                        "type": "label"
                    }
                );
                if (typeof assignedTaskInformation?.personTasks[currentTab]?.assignedInformation?.attributes !== "undefined") {
                    if (assignedTaskInformation?.personTasks[currentTab]?.assignedInformation?.attributes?.length > 0) {
                        assignedTaskInformation?.personTasks[currentTab]?.assignedInformation?.attributes.map(formData => {
                            formList.push({
                                "resourceId": formData.attributeValueResourceId,
                                "attributeName": formData.taskAttributeName,
                                "value": formData.taskAttributeValue,
                                "type": "input"
                            })
                            initialValues[formData.attributeValueResourceId] = formData.taskAttributeValue;
                        });
                    }
                }
                //  initialValues["description_" + assignedTasksListState[currentTab]?.resourceId] = assignedTaskInformation?.personTasks[currentTab]?.assignedInformation?.description;
                initialValues["missionDescription_" + assignedTasksListState[currentTab]?.resourceId] = assignedTaskInformation?.personTasks[currentTab]?.taskDescription;
                const dataObj = { ...detailAssignedTaskRequest.data, [assignedTasksListState[currentTab]?.resourceId]: formList };
                setDetailAssignedTaskRequest({ ...detailAssignedTaskRequest, data: dataObj });
                form.setFieldsValue(initialValues);
            } else if (!Object.keys(detailAssignedTaskRequest.data).includes(assignedTasksListState[currentTab]?.resourceId)) {
                const dataObj = { ...detailAssignedTaskRequest.data, [assignedTasksListState[currentTab]?.resourceId]: [] };
                setDetailAssignedTaskRequest({ ...detailAssignedTaskRequest, data: dataObj });
            } else {
                return false
            }
        }
    }, [assignedTaskInformation, assignedTaskInformationLoading, assignedTasksListState, currentTab])

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                layout="vertical scrollable assigned-task-drawer pl-3 pt-2"
                form={form}
            >
                {
                    assignedTaskInformationLoading ?
                        <Spin className="tab-loading" />
                        :
                        <>
                            {detailAssignedTaskRequest.data[assignedTasksListState[currentTab]?.resourceId]?.length === 0 &&
                                <p className="pt-4 f-16 black">{t('TASK_DETAIL_INFORMATION_NOT_FOUND')}</p>
                            }
                            {
                                detailAssignedTaskRequest.data[assignedTasksListState[currentTab]?.resourceId] &&
                                detailAssignedTaskRequest.data[assignedTasksListState[currentTab]?.resourceId].map(mapFormItem)
                            }
                        </>
                }
            </Form>
        </ConfigProvider >
    )
}
const mapStateToProps = (state) => {
    return {
        assignedTaskInformation: state.taskReducer.assignedTaskInformation,
        assignedTaskInformationLoading: state.taskReducer.assignedTaskInformationLoading,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAssignedTasksStepList: (resourceId) => { dispatch(getAssignedTasksStepList(resourceId)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailAssignedTaskRequestsTab);