import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
const FileDownload = require("js-file-download");

const ExpenseActionType = {
  SET_EXPENSE_CREATE_DRAWER: "SET_EXPENSE_CREATE_DRAWER",
  SET_EXPENSE_DETAILS_DRAWER: "SET_EXPENSE_DETAILS_DRAWER",
  GET_CURRENCY_WITH_ID_REQUESTS: "GET_CURRENCY_WITH_ID_REQUESTS",
  GET_CURRENCY_WITH_ID_SUCCESS: "GET_CURRENCY_WITH_ID_SUCCESS",
  GET_CURRENCY_WITH_ID_FAIL: "GET_CURRENCY_WITH_ID_FAIL",
  CREATE_EXPENSE_REQUEST: "CREATE_EXPENSE_REQUEST",
  CREATE_EXPENSE_SUCCESS: "CREATE_EXPENSE_SUCCESS",
  CREATE_EXPENSE_FAIL: "CREATE_EXPENSE_FAIL",
  CREATE_EXPENSE_RESET: "CREATE_EXPENSE_RESET",
  RESET_CURRENCY_WITH_ID: "RESET_CURRENCY_WITH_ID",
  GET_OPEN_ADVANCE_REQUEST: "GET_OPEN_ADVANCE_REQUEST",
  GET_OPEN_ADVANCE_SUCCESS: "GET_OPEN_ADVANCE_SUCCESS",
  GET_OPEN_ADVANCE_FAIL: "GET_OPEN_ADVANCE_FAIL",
  MY_EXPENSE_LIST_REQUESTS: "MY_EXPENSE_LIST_REQUESTS",
  MY_EXPENSE_LIST_SUCCESS: "MY_EXPENSE_LIST_SUCCESS",
  MY_EXPENSE_LIST_FAIL: "MY_EXPENSE_LIST_FAIL",
  EXPENSE_MANAGER_LIST_REQUESTS: "EXPENSE_MANAGER_LIST_REQUESTS",
  EXPENSE_MANAGER_LIST_SUCCESS: "EXPENSE_MANAGER_LIST_SUCCESS",
  EXPENSE_MANAGER_LIST_FAIL: "EXPENSE_MANAGER_LIST_FAIL",
  ALL_EXPENSE_LIST_REQUEST: "ALL_EXPENSE_LIST_REQUEST",
  ALL_EXPENSE_LIST_SUCCESS: "ALL_EXPENSE_LIST_SUCCESS",
  ALL_EXPENSE_LIST_FAIL: "ALL_EXPENSE_LIST_FAIL",
  CREATE_EXPENSE_WITH_CASH_REQUEST: "CREATE_EXPENSE_WITH_CASH_REQUEST",
  CREATE_EXPENSE_WITH_CASH_SUCCESS: "CREATE_EXPENSE_WITH_CASH_SUCCESS",
  CREATE_EXPENSE_WITH_CASH_FAIL: "CREATE_EXPENSE_WITH_CASH_FAIL",
  CREATE_EXPENSE_WITH_CASH_RESET: "CREATE_EXPENSE_WITH_CASH_RESET",
  GET_EXPENSE_DETAILS_REQUEST: "GET_EXPENSE_DETAILS_REQUEST",
  GET_EXPENSE_DETAILS_SUCCESS: "GET_EXPENSE_DETAILS_SUCCESS",
  GET_EXPENSE_DETAILS_FAIL: "GET_EXPENSE_DETAILS_FAIL",
  EXPENSE_CHANGE_STATUS_SUCCESS: "EXPENSE_CHANGE_STATUS_SUCCESS",
  EXPENSE_CHANGE_STATUS_FAIL: "EXPENSE_CHANGE_STATUS_FAIL",
  EXPENSE_CHANGE_STATUS_RESET: "EXPENSE_CHANGE_STATUS_RESET",
  GET_EXPENSE_DETAILS_STEP_REQUEST: "GET_EXPENSE_DETAILS_STEP_REQUEST",
  GET_EXPENSE_DETAILS_STEP_SUCCESS: "GET_EXPENSE_DETAILS_STEP_SUCCESS",
  GET_EXPENSE_DETAILS_STEP_FAIL: "GET_EXPENSE_DETAILS_STEP_FAIL",
  UPDATE_EXPENSE_SUCCESS: "UPDATE_EXPENSE_SUCCESS",
  UPDATE_EXPENSE_FAIL: "UPDATE_EXPENSE_FAIL",
  UPDATE_EXPENSE_RESET: "UPDATE_EXPENSE_RESET",
  GET_EXPENSE_APPROVE_ON_MAIL_REQUEST: "GET_EXPENSE_APPROVE_ON_MAIL_REQUEST",
  GET_EXPENSE_APPROVE_ON_MAIL_SUCCESS: "GET_EXPENSE_APPROVE_ON_MAIL_SUCCESS",
  GET_EXPENSE_APPROVE_ON_MAIL_FAIL: "GET_EXPENSE_APPROVE_ON_MAIL_FAIL",
  EXPENSE_EXPORT_ALL_FILES_FAIL: "EXPENSE_EXPORT_ALL_FILES_FAIL",
  EXPENSE_EXPORT_ALL_FILES_RESET: "EXPENSE_EXPORT_ALL_FILES_RESET",
  UPDATE_PROCESSED_EXPENSE_SUCCESS: "UPDATE_PROCESSED_EXPENSE_SUCCESS",
  UPDATE_PROCESSED_EXPENSE_FAIL: "UPDATE_PROCESSED_EXPENSE_FAIL",
  UPDATE_PROCESSED_EXPENSE_RESET: "UPDATE_PROCESSED_EXPENSE_RESET",
  EXPENSE_EXPORT_DETAIL_FILES_FAIL: "EXPENSE_EXPORT_DETAIL_FILES_FAIL",
  EXPENSE_EXPORT_DETAIL_FILES_RESET: "EXPENSE_EXPORT_DETAIL_FILES_RESET",
  EXPENSE_EDIT_REQUEST_ACTION_SUCCESS: "EXPENSE_EDIT_REQUEST_ACTION_SUCCESS",
  EXPENSE_EDIT_REQUEST_ACTION_FAIL: "EXPENSE_EDIT_REQUEST_ACTION_FAIL",
  EXPENSE_EDIT_REQUEST_ACTION_RESET: "EXPENSE_EDIT_REQUEST_ACTION_RESET",
  EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_FAIL: "EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_FAIL",
  EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_RESET: "EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_RESET",
  RESET_EXPENSE_DETAILS_STATE: "RESET_EXPENSE_DETAILS_STATE",
  EXPENSE_EXPORT_SUCCESS: "EXPENSE_EXPORT_SUCCESS",
  EXPENSE_EXPORT_FAIL: "EXPENSE_EXPORT_FAIL",
  EXPENSE_EXPORT_RESET: "EXPENSE_EXPORT_RESET",
  EXPENSE_APPROVALS_EXPORT_SUCCESS: "EXPENSE_APPROVALS_EXPORT_SUCCESS",
  EXPENSE_APPROVALS_EXPORT_FAIL: "EXPENSE_APPROVALS_EXPORT_FAIL",
  EXPENSE_APPROVALS_EXPORT_RESET: "EXPENSE_APPROVALS_EXPORT_RESET",
  GET_MUTLIPLE_CURRENCY_WITH_ID_REQUESTS: "GET_MUTLIPLE_CURRENCY_WITH_ID_REQUESTS",
  GET_MUTLIPLE_CURRENCY_WITH_ID_SUCCESS: "GET_MUTLIPLE_CURRENCY_WITH_ID_SUCCESS",
  GET_MUTLIPLE_CURRENCY_WITH_ID_FAIL: "GET_MUTLIPLE_CURRENCY_WITH_ID_FAIL",
  RESET_GET_MUTLIPLE_CURRENCY_WITH_ID: "RESET_GET_MUTLIPLE_CURRENCY_WITH_ID",
};

const getCurrencyWithId = (currencyId, date1, date2) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.GET_CURRENCY_WITH_ID_REQUESTS });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/expenses/getCurrency/" + currencyId + "/" + date1 + "/" + date2,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.GET_CURRENCY_WITH_ID_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.GET_CURRENCY_WITH_ID_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.GET_CURRENCY_WITH_ID_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.GET_CURRENCY_WITH_ID_FAIL, payload: error.message });
      }
    }
  };
};
const resetCurrencyWithIdResponse = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.RESET_CURRENCY_WITH_ID, payload: {} });
  };
};
const getMultipleCurrency = (data1, data2, date1, date2) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.GET_MUTLIPLE_CURRENCY_WITH_ID_REQUESTS });
    try {
      var config1 = {
        method: "get",
        url: `${apiUrl}/expenses/getCurrency/${data1}/${date1}/${date2}`,
        headers: {},
      };

      var config2 = {
        method: "get",
        url: `${apiUrl}/expenses/getCurrency/${data2}/${date1}/${date2}`,
        headers: {},
      };
      Promise.all([hrApi(config1), hrApi(config2)])
        .then(function (responses) {
          // Check if both requests are successful
          if (responses[0].status === 200 && responses[1].status === 200) {
            dispatch({
              type: ExpenseActionType.GET_MUTLIPLE_CURRENCY_WITH_ID_SUCCESS,
              payload: [responses[0].data.data, responses[1].data.data],
            });
          } else {
            dispatch({
              type: ExpenseActionType.GET_MUTLIPLE_CURRENCY_WITH_ID_FAIL,
              payload: "Failed to fetch both currencies",
            });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.GET_MUTLIPLE_CURRENCY_WITH_ID_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.GET_MUTLIPLE_CURRENCY_WITH_ID_FAIL, payload: error.message });
      }
    }
  };
};
const resetGetMultipleCurrency = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.RESET_GET_MUTLIPLE_CURRENCY_WITH_ID, payload: {} });
  };
};
const getOpenAdvance = (resourceId, expenseResourceId) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.GET_OPEN_ADVANCE_REQUEST });
    try {
      var config = {
        method: "post",
        url: expenseResourceId
          ? apiUrl + "/expenses/getOpenAdvances/" + resourceId + "/" + expenseResourceId
          : apiUrl + "/expenses/getOpenAdvances/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.GET_OPEN_ADVANCE_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.GET_OPEN_ADVANCE_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.GET_OPEN_ADVANCE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.GET_OPEN_ADVANCE_FAIL, payload: error.message });
      }
    }
  };
};

const createExpenseAction = (createObj) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.CREATE_EXPENSE_REQUEST });

    try {
      var data = JSON.stringify(createObj);

      var config = {
        method: "post",
        url: apiUrl + "/expenses",
        dataType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.CREATE_EXPENSE_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: ExpenseActionType.CREATE_EXPENSE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: ExpenseActionType.CREATE_EXPENSE_FAIL, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.CREATE_EXPENSE_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateExpenseResponse = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.CREATE_EXPENSE_RESET, payload: {} });
  };
};

const createExpenseWithCashAction = (createObj) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.CREATE_EXPENSE_WITH_CASH_REQUEST });

    try {
      var data = JSON.stringify(createObj);

      var config = {
        method: "post",
        url: apiUrl + "/expenses/withCash",
        dataType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.CREATE_EXPENSE_WITH_CASH_SUCCESS, payload: response.data });
          } else {
            dispatch({ type: ExpenseActionType.CREATE_EXPENSE_WITH_CASH_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            dispatch({ type: ExpenseActionType.CREATE_EXPENSE_WITH_CASH_FAIL, payload: error.response.data.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.CREATE_EXPENSE_WITH_CASH_FAIL, payload: error.message });
      }
    }
  };
};
const resetCreateExpenseWithCashResponse = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.CREATE_EXPENSE_WITH_CASH_RESET, payload: {} });
  };
};

const getExpenseDetails = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/expenses/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_FAIL, payload: [] });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_FAIL, payload: [] });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};
const getMyExpenseRequests = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.MY_EXPENSE_LIST_REQUESTS });
    const data = JSON.stringify(searchData);

    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/search/me",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.MY_EXPENSE_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.MY_EXPENSE_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404 || error.response?.status === 500) {
            const emptyData = {
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: ExpenseActionType.MY_EXPENSE_LIST_SUCCESS, payload: emptyData });
          }
        });
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 404) {
          const emptyData = {
            totalNumberOfRecords: 0,
            results: [],
          };
          dispatch({ type: ExpenseActionType.MY_EXPENSE_LIST_SUCCESS, payload: emptyData });
        } else {
          dispatch({ type: ExpenseActionType.MY_EXPENSE_LIST_FAIL, payload: error.message });
        }
      }
    }
  };
};

const getExpenseManager = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_MANAGER_LIST_REQUESTS });
    const data = JSON.stringify(searchData);

    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/search/manager",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.EXPENSE_MANAGER_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.EXPENSE_MANAGER_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: ExpenseActionType.EXPENSE_MANAGER_LIST_SUCCESS, payload: emptyData });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_MANAGER_LIST_FAIL, payload: error.message });
      }
    }
  };
};
const getAllExpenseRequests = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.ALL_EXPENSE_LIST_REQUEST });
    const data = JSON.stringify(searchData);

    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/search",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.ALL_EXPENSE_LIST_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.ALL_EXPENSE_LIST_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: ExpenseActionType.ALL_EXPENSE_LIST_SUCCESS, payload: emptyData });
          }
        });
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 404) {
          const emptyData = {
            totalNumberOfRecords: 0,
            results: [],
          };
          dispatch({ type: ExpenseActionType.ALL_EXPENSE_LIST_SUCCESS, payload: emptyData });
        } else {
          dispatch({ type: ExpenseActionType.ALL_EXPENSE_LIST_FAIL, payload: error.message });
        }
      }
    }
  };
};
const expenseChangeStatus = (data, type) => {
  const changeMethod = type === "delete" ? "delete" : "post";

  return async (dispatch) => {
    try {
      var config = {
        method: changeMethod,
        url: apiUrl + "/expenses/" + data.resourceId + "/" + type,
        headers: {
          "Content-Type": "application/json",
        },
        data: changeMethod !== "delete" ? data.description : null,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.EXPENSE_CHANGE_STATUS_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ExpenseActionType.EXPENSE_CHANGE_STATUS_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.EXPENSE_CHANGE_STATUS_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_CHANGE_STATUS_FAIL, payload: error.message });
      }
    }
  };
};
const getExpenseDetailsStep = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_STEP_REQUEST });

    try {
      var config = {
        method: "get",
        url: apiUrl + "/expenses/approvalList/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_STEP_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_STEP_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_STEP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.GET_EXPENSE_DETAILS_STEP_FAIL, payload: error.message });
      }
    }
  };
};
const resetExpenseChangeStatus = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_CHANGE_STATUS_RESET, payload: {} });
  };
};

const updateExpenseWithCashAction = (data, resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/expenses/withCash/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_FAIL, payload: error.message });
      }
    }
  };
};
const updateExpenseWithCreditCardAction = (data, resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/expenses/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_FAIL, payload: error.message });
      }
    }
  };
};
const updateExpenseWithCashActionReset = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.UPDATE_EXPENSE_RESET, payload: {} });
  };
};
const setExpenseCreateDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: ExpenseActionType.SET_EXPENSE_CREATE_DRAWER,
      payload: status,
    });
  } catch (e) {}
};
const setExpenseDetailsDrawer = (status) => async (dispatch) => {
  try {
    dispatch({
      type: ExpenseActionType.SET_EXPENSE_DETAILS_DRAWER,
      payload: status,
    });
  } catch (e) {}
};
const getApproveExpenseOnMail = (data) => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/expense/approveOnMail?data=" + data,
        headers: {},
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.GET_EXPENSE_APPROVE_ON_MAIL_FAIL, payload: error.message });
      }
    }
  };
};
const expenseExportTable = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    var data = JSON.stringify(props);
    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/export",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob", // Important
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };
            FileDownload(response.data, "expenses_" + newDate + "." + exportTypes[props.exportType]);
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              var renderData = reader.result;
              dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_FAIL, payload: renderData });
            };
            reader.readAsText(response.data);
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_FAIL, payload: error.message });
      }
    }
  };
};
const resetExpenseExportTable = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_RESET, payload: {} });
  };
};
const expenseApprovalsExportTable = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    var data = JSON.stringify(props);
    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/exportmanager",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob", // Important
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };

            FileDownload(response.data, "expenses_approvals_" + newDate + "." + exportTypes[props.exportType]);
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              var renderData = reader.result;
              dispatch({ type: ExpenseActionType.EXPENSE_APPROVALS_EXPORT_FAIL, payload: renderData });
            };
            reader.readAsText(response.data);
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: ExpenseActionType.EXPENSE_APPROVALS_EXPORT_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_APPROVALS_EXPORT_FAIL, payload: error.message });
      }
    }
  };
};
const resetExpenseApprovalsExportTable = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_APPROVALS_EXPORT_RESET, payload: {} });
  };
};
const expenseExportAllFiles = (resourceId) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/expense/ExportAllFiles/" + resourceId,
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        responseType: "blob", // Important
      };
      hrApi(config)
        .then(function (response, typeId) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "zip",
            };
            FileDownload(response.data, "expense_" + newDate + "." + exportTypes[2]);
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              var renderData = reader.result;
              dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_ALL_FILES_FAIL, payload: renderData });
            };
            reader.readAsText(response.data);
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_ALL_FILES_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_ALL_FILES_FAIL, payload: error.message });
      }
    }
  };
};
const resetExpenseExportAllFiles = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_ALL_FILES_RESET, payload: {} });
  };
};
const updateExpenseProcessed = (resourceId, data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "put",
        url: apiUrl + "/expenses/processed/" + resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.UPDATE_PROCESSED_EXPENSE_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ExpenseActionType.UPDATE_PROCESSED_EXPENSE_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.UPDATE_PROCESSED_EXPENSE_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.UPDATE_PROCESSED_EXPENSE_FAIL, payload: error.message });
      }
    }
  };
};
const updateExpenseProcessedReset = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.UPDATE_PROCESSED_EXPENSE_RESET, payload: {} });
  };
};
const expenseExportDetailPage = (resourceId) => {
  let newDate = Date.now();
  const data = {
    ExpenseResourceId: resourceId,
    ExportType: 3,
  };
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/document/exportCash",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob", // Important
      };

      hrApi(config)
        .then(function (response, typeId) {
          if (response.status === 200) {
            FileDownload(response.data, "expenseDetail_" + newDate + ".pdf");
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              var renderData = reader.result;
              dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_DETAIL_FILES_FAIL, payload: renderData });
            };
            reader.readAsText(response.data);
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_DETAIL_FILES_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_DETAIL_FILES_FAIL, payload: error.message });
      }
    }
  };
};
const resetExpenseExportDetailPage = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_DETAIL_FILES_RESET, payload: {} });
  };
};
const submitEditRequestAction = (data) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/expenses/editRequest/" + data.resourceId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data.description,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_FAIL, payload: error.message });
      }
    }
  };
};
const submitEditRequestResetAction = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_EDIT_REQUEST_ACTION_RESET, payload: {} });
  };
};
const expenseCurrencyExportAllFiles = (resourceId) => {
  let newDate = Date.now();
  const data = {
    ExportType: 3,
  };
  return async (dispatch) => {
    try {
      var config = {
        method: "post",
        url: apiUrl + "/expense/ExportAllExchangeFiles/" + resourceId,
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob", // Important
      };

      hrApi(config)
        .then(function (response, typeId) {
          if (response.status === 200) {
            FileDownload(response.data, "expenseCurrencyDetail_" + newDate + ".pdf");
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              var renderData = reader.result;
              dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_FAIL, payload: renderData });
            };
            reader.readAsText(response.data);
          }
        })
        .catch(function (error) {
          var reader = new FileReader();
          reader.onload = function () {
            var renderData = reader.result;
            dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_FAIL, payload: renderData });
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_FAIL, payload: error.message });
      }
    }
  };
};
const resetExpenseCurrencyExportAllFiles = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.EXPENSE_EXPORT_CURRENCY_DETAIL_FILES_RESET, payload: {} });
  };
};
const resetExpenseDetailsState = () => {
  return async (dispatch) => {
    dispatch({ type: ExpenseActionType.RESET_EXPENSE_DETAILS_STATE, payload: {} });
  };
};
export {
  ExpenseActionType,
  setExpenseCreateDrawer,
  setExpenseDetailsDrawer,
  getCurrencyWithId,
  getOpenAdvance,
  resetCurrencyWithIdResponse,
  createExpenseAction,
  resetCreateExpenseResponse,
  getExpenseDetails,
  getExpenseDetailsStep,
  createExpenseWithCashAction,
  resetCreateExpenseWithCashResponse,
  getMyExpenseRequests,
  getExpenseManager,
  getAllExpenseRequests,
  expenseChangeStatus,
  resetExpenseChangeStatus,
  updateExpenseWithCashAction,
  updateExpenseWithCreditCardAction,
  updateExpenseWithCashActionReset,
  getApproveExpenseOnMail,
  expenseExportAllFiles,
  resetExpenseExportAllFiles,
  updateExpenseProcessed,
  updateExpenseProcessedReset,
  expenseExportDetailPage,
  resetExpenseExportDetailPage,
  submitEditRequestAction,
  submitEditRequestResetAction,
  expenseCurrencyExportAllFiles,
  resetExpenseCurrencyExportAllFiles,
  resetExpenseDetailsState,
  expenseExportTable,
  resetExpenseExportTable,
  expenseApprovalsExportTable,
  resetExpenseApprovalsExportTable,
  getMultipleCurrency,
  resetGetMultipleCurrency,
};
