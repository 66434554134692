import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getExpenseManager, getExpenseDetails, expenseChangeStatus, resetExpenseChangeStatus, getExpenseDetailsStep,
    expenseApprovalsExportTable, resetExpenseApprovalsExportTable
} from '../../redux/actions/expense.action';
import { Button, Dropdown, Form, ConfigProvider, Input } from 'antd';
import { UilCheckCircle, UilMinusCircle, UilImport } from '@iconscout/react-unicons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';
import ExportModal from '../dynamicModal/exportModal';
import { useWindowDimensions, dateFormatConvert } from '../../utils/config';
import { IsJsonString } from '../../utils/commonFormFunctions';

import Can from "../../authorization/index";

function ApproveExpense(props) {
    const { t } = useTranslation();
    const isShowPassives = false;
    const { width } = useWindowDimensions();

    const { expenseManagerInitialSearch, expenseManagerSearchState, setExpenseManagerSearchState, openExpenseDetailsDrawer, expenseChangeStatus, resetExpenseChangeStatus,
        expenseChangeStatusResponse, setSelectExpense, getExpenseManager, expenseManagerListLoading, expenseManagerList, totalNumberOfRecords, getExpenseDetails, currentTab,
        getExpenseDetailsStep, expenseApprovalsExportTable, resetExpenseApprovalsExportTable, expenseApprovalTableExportDataResponse } = props;

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false });
    const [rejectState, setRejectState] = useState({});

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false });
    const [approveState, setApproveState] = useState({});

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportState, setExportState] = useState({ ...expenseManagerSearchState, exportType: 0 })

    const handleApproveConfirm = () => {

        expenseChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');

    }
    const handleRejectConfirm = () => {

        expenseChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
        
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const handleApproveChangeStatus = (status, resourceId) => {
        if (status === "APPROVE") {
            setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': resourceId })
        } else {
            setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': resourceId })
        }
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    useEffect(() => {
        if (JSON.stringify(expenseManagerSearchState) !== JSON.stringify(expenseManagerInitialSearch)) {
            getExpenseManager(expenseManagerSearchState);
        }
    }, [expenseManagerSearchState]);

    useEffect(() => {
            if (expenseChangeStatusResponse.status === 'SUCCESS') {

                const button = document.getElementById('close-expense-details-'+ (approveModalData.id ? approveModalData.id : rejectModalData?.id));
                if (button) { button?.click();} 

                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': expenseChangeStatusResponse.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                })
                getExpenseManager({ ...expenseManagerSearchState, ...exportState });
            }
            else if (expenseChangeStatusResponse.status === 'FAIL') {

                const button = document.getElementById('close-expense-details-'+ (approveModalData.id ? approveModalData.id : rejectModalData?.id));

                if (button) { button?.click();} 

                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': expenseChangeStatusResponse.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                getExpenseManager({ ...expenseManagerSearchState, ...exportState });
            }
            else {
            if (expenseChangeStatusResponse.status === 'SUCCESS') {
                getExpenseManager({ ...expenseManagerSearchState, ...exportState });
            }
        }

    }, [expenseChangeStatusResponse])
    
    useEffect(() => {
        if (currentTab === "ExpenseRequests") {
            if (expenseChangeStatusResponse.status === 'SUCCESS') {
                setRejectModalData({ 'isVisible': false });
                setRejectState({});
                rejectForm.resetFields();

                setApproveModalData({ 'isVisible': false });
                setApproveState({});
                approveForm.resetFields();
                resetExpenseChangeStatus();
            }
        }
    }, [expenseChangeStatusResponse])

    useEffect(() => { 
        const button = document.getElementById("remove-expense-filter-button");
        if (button) {
            button.click();
        }
     }, [currentTab])
    
    useEffect(() => {
        if (expenseApprovalTableExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': IsJsonString(expenseApprovalTableExportDataResponse.message) ? JSON.parse(expenseApprovalTableExportDataResponse.message).message : t('ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetExpenseApprovalsExportTable();
        }
    }, [expenseApprovalTableExportDataResponse])
    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setExportState({ ...expenseManagerInitialSearch, exportType: 0 });
        setExpenseManagerSearchState({ ...expenseManagerInitialSearch , key:++expenseManagerInitialSearch.key});
    }
    const handleTableFilter = (data) => {
        setExpenseManagerSearchState({ ...expenseManagerSearchState, "stateTypes": data.stateType, "persons": data.personsExpense , key:++expenseManagerInitialSearch.key});
        setExportState({ ...exportState, "stateTypes": data.stateType, "persons": data.personsExpense });
    }
    const showExpenseDetails = (id) => {
        if (id !== undefined) {
            setSelectExpense({ 'resourceId': id })
            openExpenseDetailsDrawer();
            getExpenseDetails(id);
            getExpenseDetailsStep(id);
        }
    }
    const data = expenseManagerList.map((row) => {
        return (
            {
                "key": row.expenseResourceId,
                "createdDate": { "text": moment(row.createdDate).format(dateFormatConvert), "id": row.expenseResourceId },
                "givenName": row.expenseCreatedPersonName,
                "detailCount": row.detailCount,
                "expenseMasterTypeName": row.expenseMasterTypeName,
                "expenseExchangeList": row.expenseExchangeList,
                "totalExpenseTL": row.totalExpenseTL + ' TL',
                "differentAmount": row.differentAmount,
                "stateType": { "id": row.stateType?.id, "displayValue": row.stateType?.displayValue, "showConfirmButton": row.showConfirmButton, 'isProcessed': row.isProcessed, "resourceId": row.expenseResourceId },
            }
        );
    });
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType', 'givenName'] : [],
        sortableColumns: ['stateType', 'givenName', 'detailCount', 'expenseMasterTypeName', 'createdDate'],
        visibilityOptionExcludes: ['stateType', 'givenName', 'detailCount', 'expenseMasterTypeName', 'expenseExchangeList', 'totalExpenseTL', 'differentAmount', 'createdDate'],
        page: "ApproveExpense",
        // leftActionMenu: totalNumberOfRecords > 0 ? leftActionMenu() : null,
        leftActionMenu: null,
        showVisibilityMenu: false,
    });
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.export">
                        <Button onClick={() => setIsModalVisible(true)} className="flex-class svg-class white-btn h-32 w-auto m-w-180"><UilImport />  {t('SETTINGS_EXPORT')}</Button>
                    </Can>
                }
            </div>
        );
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']

    });
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeName'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...expenseManagerSearchState.pagination, 'isChanged': true, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : expenseManagerSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setExpenseManagerSearchState({ ...expenseManagerSearchState, 'pagination': paginationObj, key:++expenseManagerInitialSearch.key })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...expenseManagerSearchState.pagination, "pageNumber": 1 }
        setExpenseManagerSearchState({ ...expenseManagerSearchState, "SearchTerm": e, "pagination": pagingObj, key:++expenseManagerInitialSearch.key })
        setExportState({ ...exportState, "SearchTerm": e });
    }
    const handleShowPassivesToogle = (e) => { }
    function handleExportDocument(props) {
        expenseApprovalsExportTable({
            ...exportState,
            'exportType': props,
            'pagination': {
                "pageNumber": 1,
                "pageSize": 10000,
                "orderBy": "NeedDate",
                "ascending": exportState.pagination.ascending
            }
        });
        setIsModalVisible(false);
    }
    return (
        <div className="table-container">
            <ExportModal
                onCancel={() => { setIsModalVisible(false) }}
                status={isModalVisible}
                handleExportDocument={handleExportDocument}
            />
            <DynamicRequestModal
                modalVisible={rejectModalData?.isVisible}
                setStateData={setRejectModalData}
                handleConfirm={handleRejectConfirm}
                handleCancel={handleRejectCancel}
                confirmData={rejectModalData?.id}
                isForm={rejectModalForm}
                iconName='UilTimesCircle'
                modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                description={t('EXPENSE_REJECT_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
            />
            <DynamicRequestModal
                modalVisible={approveModalData?.isVisible}
                setStateData={setApproveModalData}
                handleConfirm={handleApproveConfirm}
                handleCancel={handleApproveCancel}
                confirmData={approveModalData?.id}
                isForm={approveModalForm}
                iconName='UilCheckCircle'
                modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                description={t('EXPENSE_APPROVE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
            />
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === '1') {
                                    return <div className="flex-class w-100" >
                                        <span className='yellow  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                        {state.showConfirmButton &&
                                            <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                                <Button id={'approve-'+state.resourceId} className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleApproveChangeStatus('APPROVE', state.resourceId)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                                <Button id={'reject-'+state.resourceId} className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleApproveChangeStatus('REJECT', state.resourceId)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                            </div>
                                        }
                                    </div>
                                } else if (state.id === '2') {
                                    if (state.isProcessed) {
                                        return <span>{t('PROCESSED')}</span>
                                    } else {
                                        return <span>{state.displayValue}</span>
                                    }
                                } else {
                                    return <span className='black  flex-align-center'>
                                        {state.displayValue}
                                    </span>
                                }
                            }
                        },
                        {
                            title: `${t('EMPLOYEE')}`.charAt(0).toUpperCase() + `${t('EMPLOYEE')}`.slice(1).toLowerCase(),
                            dataIndex: 'givenName',
                            key: 'givenName',
                            sorter: false,
                        },
                        {
                            title: `${t('PARTNER_EXPENSE_TYPE')}`,
                            dataIndex: 'expenseMasterTypeName',
                            key: 'expenseMasterTypeName',
                            sorter: true,
                        },
                        {
                            title: `${t('NUMBER_OF_EXPENDITURE_ITEMS')}`,
                            dataIndex: 'detailCount',
                            key: 'detailCount',
                            sorter: true,
                        },
                        {
                            title: `${t('CURRENCY_AMOUNT')}`,
                            dataIndex: 'expenseExchangeList',
                            key: 'expenseExchangeList',
                            sorter: true,
                        },
                        {
                            title: `${t('TL_AMOUNT')}`,
                            dataIndex: 'totalExpenseTL',
                            key: 'totalExpenseTL',
                            sorter: true,
                            render: total => {
                                return parseFloat(total).toLocaleString('tr-TR') + ' TL'
                            }
                        },
                        {
                            title: `${t('OPEN_ADVANCE_DIFFERENCE')}`,
                            dataIndex: 'differentAmount',
                            key: 'differentAmount',
                            sorter: true,
                            render: total => {
                                return parseFloat(total).toLocaleString('tr-TR') + ' TL'
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('EXPENSE_ENTRY_DATE')}`,
                                dataIndex: 'createdDate',
                                key: 'createdDate',
                                sorter: true,
                                render: obj =>
                                    <div className="imageNameActionColumn">
                                        <a onClick={() => showExpenseDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                        <Dropdown
                                            className="action-menu"
                                            overlay={null}
                                            trigger={['click']}
                                        >
                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                        </Dropdown>

                                    </div>
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={expenseManagerListLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        expenseManagerListLoading: state.expense.expenseManagerListLoading,
        expenseManagerList: state.expense.expenseManagerList.results,
        totalNumberOfRecords: state.expense.expenseManagerList.totalNumberOfRecords,
        expenseChangeStatusResponse: state.expense.expenseChangeStatusResponse,
        expenseTableExportData: state.expense.expenseTableExportData,
        expenseApprovalTableExportDataResponse: state.expense.expenseApprovalTableExportDataResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getExpenseManager: (searchData) => { dispatch(getExpenseManager(searchData)) },
        getExpenseDetails: (resourceId) => { dispatch(getExpenseDetails(resourceId)) },
        getExpenseDetailsStep: (resourceId) => { dispatch(getExpenseDetailsStep(resourceId)) },
        expenseChangeStatus: (data, type) => { dispatch(expenseChangeStatus(data, type)) },
        resetExpenseChangeStatus: () => { dispatch(resetExpenseChangeStatus()) },
        expenseApprovalsExportTable: (props) => { dispatch(expenseApprovalsExportTable(props)) },
        resetExpenseApprovalsExportTable: () => { dispatch(resetExpenseApprovalsExportTable()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveExpense);