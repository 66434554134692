import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker, Button, Spin, Radio, ConfigProvider, List } from 'antd';
// ICONS
import {
    CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import { getPersons } from '../../../redux/actions/organization.actions';
import { setPersonDeleteDrawer } from '../../../redux/actions/person-detail-tab-action';
import { deletePerson, dismissalPerson } from '../../../redux/actions/person.actions';
import { getDataset } from '../../../redux/actions/dataset-action';
import { dateFormat, dateFormatList } from '../../../utils/config';
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir
import DynamicToastMessage from '../../dynamicToastMessage/index';
import Can from "../../../authorization/index";

import '../../../styles/person.scss';

function PersonActionDrawer(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm()

    const { setPersonDeleteDrawer, personDeleteDrawer, deletePerson, getDataset, dismissalTypes, dismissalPerson,
        deletePersonControlData, deletePersonControlLoading, getPersons, persons, profile } = props;

    const [deletePersonState, setDeletePersonState] = useState({
        "processActionList": {},
        "departmentActionList": {}
    })
    const [personList, setPersonList] = useState([])
    const [leaveResonType, setLeaveResonType] = useState([])
    const [drawerTxtFromStatus, setDrawerTxtFromStatus] = useState({
        "drawerTitle": "",
        "processDescription": "",
        "departmentDescription": "",
        "buttonTxt": ""
    });
    const OrganizationResourceId = profile.organization?.resourceId;



    useEffect(() => {
        setPersonList(persons.filter(filterData => !filterData.id.includes(personDeleteDrawer.id)))
    }, [persons]);
    useEffect(() => {
        setLeaveResonType(dismissalTypes)
    }, [dismissalTypes]);
    useEffect(() => {
        if (personDeleteDrawer?.deleteStatus === true) {
            setDrawerTxtFromStatus({
                ...drawerTxtFromStatus,
                "drawerTitle": t('DELETE_EMPLOYEE_NAME', { "name": personDeleteDrawer.name }),
                "processDescription": t('EMPLOYEE_WHO_WILL_BE_DELETED'),
                "departmentDescription": t('PERSON_DEPARTMENT_PROCESS_DELETE'),
                "buttonTxt": t('DELETE_EMPLOYEE')
            })
        } else if (personDeleteDrawer?.deleteStatus === false) {
            setDrawerTxtFromStatus({
                ...drawerTxtFromStatus,
                "drawerTitle": t('PASSIVE_EMPLOYEE_NAME', { "name": personDeleteDrawer.name }),
                "processDescription": t('EMPLOYEE_WHO_WILL_BE_PASSIVE'),
                "departmentDescription": t('PERSON_DEPARTMENT_PROCESS_PASSIVE'),
                "buttonTxt": t('PASSIVE_EMPLOYEE')
            })
        } else if (personDeleteDrawer?.deleteStatus === null) {
            setDrawerTxtFromStatus({
                ...drawerTxtFromStatus,
                "drawerTitle": t('DISMISSAL_EMPLOYEE_NAME', { "name": personDeleteDrawer.name }),
                "processDescription": t('EMPLOYEE_WHO_WILL_BE_DISMISSAL'),
                "departmentDescription": t('PERSON_DEPARTMENT_PROCESS_DISMISSAL'),
                "buttonTxt": t('DISMISS_PERSON')
            })
        }
    }, [personDeleteDrawer])



    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []

        if (personDeleteDrawer?.deleteStatus === null) {
            if (!deletePersonState?.dismissalType) {
                hasError = true
                fields.push(t('PARTNER_LEAVE_REASON'))
            }

            else if (!deletePersonState?.dismissalDate || deletePersonState.dismissalDate === 'Invalid date') {
                hasError = true
                fields.push(t('PARTNER_LEAVE_DATE'))
            }

        }
        if (deletePersonControlData?.processValidations?.length > 0) {

            if (deletePersonControlData?.processValidations.length > Object.keys(deletePersonState?.processActionList).length) {
                hasError = true
                fields.push(t('PROCESSES_WHICH_EMPLOYEE'))
            }
            Object.entries(deletePersonState?.processActionList).map((value) => {
                const processElement = JSON.parse(value[1][["process_" + value[0]]]);
                const processDestinationUserElement = value[1][["processDestinationUser_" + value[0]]];
                if (processElement.id === "2" && (processDestinationUserElement?.id === null || processDestinationUserElement?.id === undefined || processDestinationUserElement?.id === "")) {
                    hasError = true
                    fields.push(t('SELECT_ANOTHER_PERSON_PROCESS'))
                }
            })
        }
        if (deletePersonControlData?.departmentValidations?.length > 0) {

            if (deletePersonControlData?.departmentValidations.length > Object.keys(deletePersonState?.departmentActionList).length) {
                hasError = true
                fields.push(t('PERSON_DEPARTMENT_MANAGER'))
            }

            Object.entries(deletePersonState?.departmentActionList).map((value) => {
                const departmentElement = JSON.parse(value[1][["department_" + value[0]]]);
                const departmentDestinationUserElement = value[1][["departmentDestinationUser_" + value[0]]];

                if (departmentElement.id === "2" && departmentDestinationUserElement?.id === null || departmentDestinationUserElement?.id === undefined || departmentDestinationUserElement?.id === "") {
                    hasError = true
                    fields.push(t('PERSON_DESINATE_ANOTHER_MANAGER'))
                } else if (!departmentElement.id) {
                    hasError = true
                    fields.push(t('PERSON_DEPARTMENT_MANAGER'))
                }
            })


        }

        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [isDeleteLoading, setisDeleteLoading] = useState(false);

    const handleUpdateDeletePerson = () => {

        if(isDeleteLoading) return;
        setisDeleteLoading(true);
        setTimeout(() => {setisDeleteLoading(false);}, 1055) // double click prevent;

        let personDeleteProcessActions = [];
        let departmentActions = [];

        Object.entries(deletePersonState?.processActionList).map((value) => {
            const processElement = JSON.parse(value[1][["process_" + value[0]]]);
            const processDestinationUserElement = value[1][["processDestinationUser_" + value[0]]];

            personDeleteProcessActions.push({
                "processResourceId": processElement.processResourceId,
                "destinationUserResourceId": processDestinationUserElement?.id,
                "exitTheProcess": processDestinationUserElement?.id ? false : true
            })
        })

        //DEPARTMENT DATA
        Object.entries(deletePersonState?.departmentActionList).map((value) => {
            const departmentElement = JSON.parse(value[1][["department_" + value[0]]]);
            const departmentDestinationUserElement = value[1][["departmentDestinationUser_" + value[0]]];
            departmentActions.push({
                "departmentResourceId": departmentElement.departmentResourceId,
                "destinationUserResourceId": departmentDestinationUserElement?.id,
                "assignToMasterDepartmentManager": departmentDestinationUserElement?.id ? false : true
            })
        })
        if (personDeleteDrawer?.deleteStatus === null) {
            const requestObj = {
                "dismissalDate": deletePersonState.dismissalDate && deletePersonState.dismissalDate !== 'Invalid date' ? moment(deletePersonState.dismissalDate).format(dateFormat) : null,
                "dismissalTypeId": deletePersonState?.dismissalType?.id || null,
                "ProcessActions": personDeleteProcessActions,
                "DepartmentActions": departmentActions
            }
            checkRequiredFields(() => dismissalPerson(requestObj, personDeleteDrawer.id))
        } else {
            const requestObj = {
                "IsDeleted": personDeleteDrawer?.deleteStatus,
                "ProcessActions": personDeleteProcessActions,
                "DepartmentActions": departmentActions
            }

            checkRequiredFields(() => deletePerson(requestObj, personDeleteDrawer.id))

        }
    }




    function close() {
        setPersonDeleteDrawer({
            ...personDeleteDrawer,
            status: false,
        })
    }



    const handleValuesChange = (changedObj, all) => {

        const changedValue = Object.keys(changedObj)[0] //proccess_0
        const processType = changedValue.split('_')[0]; // process
        const processKey = changedValue.split('_')[1]; // 0

        if (processType === 'process') {
            if (JSON.parse(changedObj[changedValue]).id === '1') {


                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["processDestinationUser_" + processKey] = "";
                form.setFieldsValue(fieldsObj)

                let tempItem = { ...deletePersonState.processActionList[processKey], [changedValue]: changedObj[changedValue], ["processDestinationUser_" + processKey]: { 'id': null, 'displayValue': null } };
                let tempProcessObj = { ...deletePersonState.processActionList, [processKey]: tempItem }
                setDeletePersonState({ ...deletePersonState, processActionList: tempProcessObj });

            } else {
                let tempItem = { ...deletePersonState.processActionList[processKey], [changedValue]: changedObj[changedValue] };
                let tempProcessObj = { ...deletePersonState.processActionList, [processKey]: tempItem }
                setDeletePersonState({ ...deletePersonState, processActionList: tempProcessObj });
            }

        } else if (processType === 'processDestinationUser') {
            let tempItem = { ...deletePersonState.processActionList[processKey], [changedValue]: changedObj[changedValue] };
            let tempProcessObj = { ...deletePersonState.processActionList, [processKey]: tempItem }
            setDeletePersonState({ ...deletePersonState, processActionList: tempProcessObj });
        }
        else if (processType === 'department') {
            if (JSON.parse(changedObj[changedValue]).id === '1') {


                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["departmentDestinationUser_" + processKey] = "";
                form.setFieldsValue(fieldsObj)

                let tempItem = { ...deletePersonState.departmentActionList[processKey], [changedValue]: changedObj[changedValue], ["departmentDestinationUser_" + processKey]: { 'id': null, 'displayValue': null } };
                let tempProcessObj = { ...deletePersonState.departmentActionList, [processKey]: tempItem }
                setDeletePersonState({ ...deletePersonState, departmentActionList: tempProcessObj });

            } else {
                let tempItem = { ...deletePersonState.departmentActionList[processKey], [changedValue]: changedObj[changedValue] };
                let tempProcessObj = { ...deletePersonState.departmentActionList, [processKey]: tempItem }
                setDeletePersonState({ ...deletePersonState, departmentActionList: tempProcessObj });
            }

        } else if (processType === 'departmentDestinationUser') {
            let tempItem = { ...deletePersonState.departmentActionList[processKey], [changedValue]: changedObj[changedValue] };
            let tempProcessObj = { ...deletePersonState.departmentActionList, [processKey]: tempItem }
            setDeletePersonState({ ...deletePersonState, departmentActionList: tempProcessObj });
        } else if (changedValue == 'dismissalDate') {
            setDeletePersonState({ ...deletePersonState, 'dismissalDate': moment(changedObj.dismissalDate).format(dateFormat) });
        } else {
            setDeletePersonState({ ...deletePersonState, ...changedObj });

        }


        // console.log('keySplit', keySplit)
        // if (key.split('_')[0] === 'process') {
        //     const tempObj = { ...deletePersonState.processActionList, [key]: changedObj[key] }
        //     setDeletePersonState({ ...deletePersonState, processActionList: tempObj });
        // } else if (key.split('_')[1] === 'processDestinationUser') {
        //     const tempObj = { ...deletePersonState.processActionList, [key]: changedObj[key] }
        //     setDeletePersonState({ ...deletePersonState, processActionList: tempObj });
        // }
        // // console.log(key, 'changedObj')
        // var list = {
        //     '0': { 'process_0': "{ 'id': '', 'value': '' }", 'processDestiantion_0': { "id": 0, "displayValue": 'ttt' } }

        // }


    }


    return (

        deletePersonControlLoading ? <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin> :
            <div className="table-details delete-person-container" >

                <div className="back-drawer pad-top-10 pad-21">
                    <h1 className="back-drawer-title">
                        {drawerTxtFromStatus?.drawerTitle}
                    </h1>
                </div>
                <div className="close-icon" onClick={() => close()}>
                    <CloseOutlined />
                </div>
                <div className="w-100 delete-person-form-container">
                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            onValuesChange={handleValuesChange}
                            layout="vertical"
                            requiredMark={true}
                        >
                            <div className="form-left-body">
                                {deletePersonControlData?.assignmentValidations?.length > 0 ?

                                    <div className="delete-drawer-information-class">
                                        <div className="delete-drawer-information-title">
                                            <h1>{t('EMBEZZLEMENT_INFORMATION')}</h1>
                                        </div>

                                        <div className="delete-drawer-information-item">
                                            <label className='delete-drawer-information-description'>{t('PERSON_EMBEZZLEMENT_MATERIALS')}</label>

                                            {deletePersonControlData?.assignmentValidations?.map((data) => {
                                                return (
                                                    <label>{data?.inventoryType} - {data?.inventoryName} </label>
                                                )
                                            })
                                            }

                                        </div>


                                    </div>
                                    :
                                    ''}
                                {personDeleteDrawer?.deleteStatus === null ?

                                    <div className='delete-drawer-information-class'>
                                        <div className="delete-drawer-information-title">
                                            <h1>{t('PERSON_LEAVE_REASON') + " *"}</h1>
                                        </div>

                                        <div className="delete-drawer-information-item">
                                            <label className='delete-drawer-information-description'>{t('PERSON_LEAVE_REASON_TEXT')}</label>
                                        </div>
                                        <div className='delete-drawer-dismissall-class' >
                                            <Form.Item name="dismissalDate" label={t('PARTNER_LEAVE_DATE')}>
                                                <DatePicker
                                                    locale={TR}
                                                    format={dateFormatList}
                                                    placeholder={t('PARTNER_SELECT_DATE')}
                                                    inputReadOnly={true}

                                                />
                                            </Form.Item>
                                            <Form.Item className="delete-draw-label" name="dismissalType" label={t('PARTNER_LEAVE_REASON')}  >
                                                <DynamicSelectbox
                                                    optionList={leaveResonType}
                                                    onFocus={() => getDataset(36)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                />
                                            </Form.Item>
                                        </div>

                                    </div>
                                    :
                                    ''}

                                {deletePersonControlData?.processValidations?.length > 0 ?
                                    <div className="delete-drawer-information-class">
                                        <div className="delete-drawer-information-title">
                                            <h1>{t('PROCESSES_WHICH_EMPLOYEE') + " *"}</h1>
                                        </div>

                                        <div className="delete-drawer-information-item">
                                            <label className='delete-drawer-information-description'>
                                                {drawerTxtFromStatus.processDescription}
                                            </label>
                                        </div>
                                        <div className="delete-drawer-information-item pad-top-10">
                                            {deletePersonControlData?.processValidations?.map((data, index) => {
                                                const processWriteLabel =
                                                    <ul>

                                                        <li> {data.processName}</li>
                                                    </ul>
                                                return (
                                                    <React.Fragment >
                                                        <Form.Item className="margin-b-12 delete-draw-label" name={"process_" + index} label={processWriteLabel}  >
                                                            <Radio.Group options={
                                                                [
                                                                    { label: `${t('REMOVE_FROM_PROCESS')}`, value: JSON.stringify({ "id": "1", "processResourceId": data.processResourceId }), disabled: !data.exitTheProcess },
                                                                    { label: `${t('SELECT_ANOTHER_PERSON_PROCESS')}`, value: JSON.stringify({ "id": "2", "processResourceId": data.processResourceId }), disabled: false },
                                                                ]
                                                            } />
                                                        </Form.Item>
                                                        {deletePersonState?.processActionList[index]?.['process_' + index] && JSON.parse(deletePersonState?.processActionList[index]?.['process_' + index])?.id === "2" ?
                                                            <Form.Item className='custom-select pad-12' name={"processDestinationUser_" + index} required >
                                                                <DynamicSelectbox
                                                                    optionList={personList}
                                                                    onFocus={() => getPersons(OrganizationResourceId)}
                                                                    placeholder={t('PARTNER_SELECT')}
                                                                />
                                                            </Form.Item>
                                                            : ''}
                                                    </React.Fragment>
                                                )
                                            })
                                            }

                                        </div>




                                    </div>
                                    : ''}

                                {deletePersonControlData?.departmentValidations?.length > 0 ?
                                    <div className="delete-drawer-information-class">
                                        <div className="delete-drawer-information-title">
                                            <h1>{t('PERSON_DEPARTMENT_MANAGER') + " *"}</h1>
                                        </div>

                                        <div className="delete-drawer-information-item">
                                            <label className='delete-drawer-information-description'>
                                                {drawerTxtFromStatus.departmentDescription}
                                            </label>
                                        </div>
                                        <div className="delete-drawer-information-item pad-top-10">
                                            {deletePersonControlData?.departmentValidations?.map((data, index) => {
                                                const writeLabel =
                                                    <ul>

                                                        <li> {data.departmentName}</li>
                                                    </ul>
                                                return (
                                                    <React.Fragment >
                                                        <Form.Item className="margin-b-12 delete-draw-label" name={"department_" + index} label={writeLabel}  >
                                                            <Radio.Group options={
                                                                [
                                                                    { label: `${t('PERSON_TOP_MANAGER')}`, value: JSON.stringify({ "id": "1", "departmentResourceId": data.departmentResourceId }) },
                                                                    { label: `${t('PERSON_DESINATE_ANOTHER_MANAGER')}`, value: JSON.stringify({ "id": "2", "departmentResourceId": data.departmentResourceId }) },
                                                                ]
                                                            } />
                                                        </Form.Item>
                                                        {deletePersonState?.departmentActionList[index]?.['department_' + index] && JSON.parse(deletePersonState?.departmentActionList[index]?.['department_' + index])?.id === "2" ?
                                                            <Form.Item className='custom-select pad-12' name={"departmentDestinationUser_" + index} required >
                                                                <DynamicSelectbox
                                                                    optionList={personList}
                                                                    onFocus={() => getPersons(OrganizationResourceId)}
                                                                    placeholder={t('PARTNER_SELECT')}
                                                                />
                                                            </Form.Item>
                                                            : ''}
                                                    </React.Fragment>
                                                )
                                            })
                                            }

                                        </div>




                                    </div>
                                    : ''}

                            </div>
                        </Form>
                    </ConfigProvider>
                </div>

                <div className="fixed-btn">
                    <div className="page-end">
                        <div className="hr-end"></div>
                    </div>

                    <div className="form-submit-space-between">
                        <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                        <div className="btn-padding" />
                        <Button onClick={handleUpdateDeletePerson} className="primary-btn w-180 h-40 f-14">{drawerTxtFromStatus?.buttonTxt}</Button>
                    </div>
                </div>
            </div >

        // )
    )
}

const mapStateToProps = (state) => {
    return {

        deletePersonControlData: state.person.deletePersonControlData,
        deletePersonControlLoading: state.person.deletePersonControlLoading,
        personDeleteDrawer: state.tabreducer.personDeleteDrawer,
        profile: state.profile,
        persons: state.organization.persons,
        dismissalTypes: state.dataset.dismissalTypes


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPersonDeleteDrawer: (drawerData) => { dispatch(setPersonDeleteDrawer(drawerData)) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        getDataset: (props) => { dispatch(getDataset(props)) },
        deletePerson: (data, id) => { dispatch(deletePerson(data, id)) },
        dismissalPerson: (data, id) => { dispatch(dismissalPerson(data, id)) }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonActionDrawer);