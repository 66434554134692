import { AuthActionType } from "../actions/auth.actions";

const initialState = {
  user: {},
  isLoadingUser: false,
  userErrorMessage: "",
  changeOrganizationStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AuthActionType.STORE_USER:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
      };
    case AuthActionType.LOADING_USER:
      return {
        ...state,
        isLoadingUser: true,
      };
    case AuthActionType.USER_EXPIRED:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
      };
    case AuthActionType.STORE_USER_ERROR:
    case AuthActionType.USER_SIGNED_OUT:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
      };
    case AuthActionType.CHANGE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        user: {...action.payload},
        changeOrganizationStatus: true,
      };
    case AuthActionType.CHANGE_ORGANIZATION_FAIL:
      return {
        ...state,
        user: {},
        userErrorMessage: action.payload,
        changeOrganizationStatus: false,
      };
    case AuthActionType.CHANGE_ORGANIZATION_RESET:
      return {
        ...state,
        changeOrganizationStatus: false,
      };
    default:
      return state;
  }
}
