import { JobActionType } from "../actions/job.actions";

const initialState = {
  jobTitlesFilterResult:{
        results:[]
    },
    isJobTitlesFilterLoading:false,
    jobList:{
        results:[]
    },
    jobListLoading:true,
    jobCreateResponse:{"status":"loading","message":""},
    updateJobNameResponse:{"status":"loading","message":""},
    deleteJobNameResponse:{"status":"loading","message":""},
    connectedJobList:[],
    connectedJobLoading:false,
    jobConnectDrawer:{
        status:false,
        id:null
      },

}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {

    switch (action.type) {

        case JobActionType.JOB_FILTER_REQUEST:
            return {
                ...state,
                isJobTitlesFilterLoading: true
            };
        case JobActionType.JOB_FILTER_SUCCESS:
            return {
                ...state,
                jobTitlesFilterResult: action.payload,
                isJobTitlesFilterLoading: false
            }
        case JobActionType.JOB_FILTER_FAIL:
            return {
                ...state,
                isJobTitlesFilterLoading: false
            }
        case JobActionType.JOB_LIST_REQUEST:
            return {
                ...state,
                jobListLoading: true
            };
        case JobActionType.JOB_LIST_SUCCESS:
            return {
                ...state,
                jobList: action.payload,
                jobListLoading: false
            }
        case JobActionType.JOB_LIST_FAIL:
            return {
                ...state,
                jobListLoading: false
            }    
        case JobActionType.CREATE_JOB_NAME_SUCCESS:
            return {
                ...state,
                jobCreateResponse: {
                   "status":"SUCCESS",
                   "message":action.payload
                }
            }
        case JobActionType.CREATE_JOB_NAME_FAIL:
            return {
                ...state,
                jobCreateResponse: {
                   "status":"FAIL",
                   "message":action.payload
                }
            }
        case JobActionType.CREATE_JOB_NAME_INPUT_ERROR:
            return {
                ...state,
                jobCreateResponse: {
                   "status":"VALIDATION_ERROR",
                   "message":action.payload
                }
            }
        case JobActionType.CREATE_JOB_NAME_RESET:
            return{
                ...state,
                jobCreateResponse: {
                    "status":"loading",
                    "message":null
                 }
            }
        case JobActionType.UPDATE_JOB_NAME_SUCCESS:
            return {
                ...state,
                updateJobNameResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case JobActionType.UPDATE_JOB_NAME_FAIL:
            return {
                ...state,
                updateJobNameResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case JobActionType.UPDATE_JOB_NAME_INPUT_ERROR:
            return {
            ...state,
            updateJobNameResponse: {
                "status": "VALIDATION_ERROR",
                "message": action.payload
            }
        }
        case JobActionType.UPDATE_JOB_NAME_RESET:
            return{
                ...state,
                updateJobNameResponse: {
                    "status":"loading",
                    "message":null
                 }
            }
        case JobActionType.DELETE_JOB_NAME_SUCCESS:
            return {
                ...state,
                deleteJobNameResponse: {
                    "status": "SUCCESS",
                    "message": action.payload
                }
            }
        case JobActionType.DELETE_JOB_NAME_FAIL:
            return {
                ...state,
                deleteJobNameResponse: {
                    "status": "FAIL",
                    "message": action.payload
                }
            }
        case JobActionType.DELETE_JOB_NAME_RESET:
          return{
              ...state,
              deleteJobNameResponse: {
                  "status":"loading",
                  "message":null
               }
          }
        case JobActionType.GET_CONNECTED_JOB_REQUEST:
            return {
                ...state,
                connectedJobLoading: true
            };
        case JobActionType.GET_CONNECTED_JOB_SUCCESS:
        return {
                ...state,
                connectedJobList: action.payload,
                connectedJobLoading:false
            }
        case JobActionType.GET_CONNECTED_JOB_FAIL:
            return {
                ...state,
                connectedJobLoading: false
            }         
        case "SET_JOB_CONNECT_DRAWER":
            const jobConnectDrawerState = {
              ...state,
              jobConnectDrawer: action.payload,
            };
            return jobConnectDrawerState;
                  
        default: return state
    }

}