import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config';

export const Roles = {
    "GET_ROLES_SUCCESS":"GET_ROLES_SUCCESS"
}

export const getRoles = () => async dispatch => {

    try {
        var config = {
            method: 'get',
            url: apiUrl+'/roles',
            headers: { }
        };
        hrApi(config)
        .then(function (response) {
            if(response.status===200){
                dispatch({
                    type: Roles.GET_ROLES_SUCCESS,
                    payload: response.data.data,
                });
            }
        })
        .catch(function (error) {
            
        });

    } catch (e) {

    }
};