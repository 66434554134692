import { Layout, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import '../../styles/forbidden.scss'

function Forbidden(props) {
    const { size } = props
    const { t } = useTranslation();
    const getClassNames = (classList) => {
        let sizeClass = size === 'sm' ? size : ''
        return classList + ' ' + sizeClass
    }
    return (
        <Layout>
            <Row align="center">
                <Col className={getClassNames("forbidden-wrapper middle")}>
                    <div>
                        <img src={process.env.REACT_APP_URL + "images/forbidden1.gif"} className={getClassNames()} />
                    </div>
                    <div className={getClassNames('forbidden-header-text pad-top-20')}>
                        {t('FORBIDDEN_HEADER_TEXT')}
                    </div>
                    <div className={getClassNames('forbidden-header-helper-text')}>
                        {t('FORBIDDEN_HEADER_HELPER_TEXT')}
                    </div>
                </Col>
            </Row>
        </Layout >
    )
}
export default Forbidden;
