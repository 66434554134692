import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from '../../utils/config'; 
const PersonDetailActionType = {
  GET_PERSON_BASIC_INFO_REQUEST: "GET_PERSON_BASIC_INFO_REQUEST",
  GET_PERSON_BASIC_INFO_SUCCESS: "GET_PERSON_BASIC_INFO_SUCCESS",
  GET_PERSON_BASIC_INFO_FAIL: "GET_PERSON_BASIC_INFO_FAIL",
  GET_PERSON_JOB_HISTORY_REQUEST:"GET_PERSON_JOB_HISTORY_REQUEST",
  GET_PERSON_JOB_HISTORY_SUCCESS: "GET_PERSON_JOB_HISTORY_SUCCESS",
  GET_PERSON_JOB_HISTORY_FAIL: "GET_PERSON_JOB_HISTORY_FAIL",
  GET_PERSON_PERSON_DETAILS_REQUEST: "GET_PERSON_PERSON_DETAILS_REQUEST",
  GET_PERSON_PERSON_DETAILS_SUCCESS: "GET_PERSON_PERSON_DETAILS_SUCCESS",
  GET_PERSON_PERSON_DETAILS_FAIL: "GET_PERSON_PERSON_DETAILS_FAIL",
  GET_PERSON_DEBIT_LIST_SUCCESS: "GET_PERSON_DEBIT_LIST_SUCCESS",
  GET_PERSON_DEBIT_LIST_FAIL: "GET_PERSON_DEBIT_LIST_FAIL",
  GET_PERSON_DEBIT_LIST_REQUEST: "GET_PERSON_DEBIT_LIST_REQUEST",
  GET_PERSON_DEBIT_DETAILS_REQUEST: "GET_PERSON_DEBIT_DETAILS_REQUEST",
  GET_PERSON_DEBIT_DETAILS_SUCCESS:"GET_PERSON_DEBIT_DETAILS_SUCCESS",
  GET_PERSON_DEBIT_DETAILS_FAIL: "GET_PERSON_DEBIT_DETAILS_FAIL",
  GET_PERSON_CONTACT_REQUEST: "GET_PERSON_CONTACT_REQUEST",
  GET_PERSON_CONTACT_SUCCESS: "GET_PERSON_CONTACT_SUCCESS",
  GET_PERSON_CONTACT_FAIL:"GET_PERSON_CONTACT_FAIL",
  GET_PERSON_SOCIAL_MEDIA_REQUEST:"GET_PERSON_SOCIAL_MEDIA_REQUEST",
  GET_PERSON_SOCIAL_MEDIA_SUCCESS:"GET_PERSON_SOCIAL_MEDIA_SUCCESS",
  GET_PERSON_SOCIAL_MEDIA_FAIL:"GET_PERSON_SOCIAL_MEDIA_FAIL",
  GET_PERSON_RESET:"GET_PERSON_RESET"
};

const getPersonBasicInfo = (id) => {
    return async (dispatch) => {
      dispatch({ type: PersonDetailActionType.GET_PERSON_BASIC_INFO_REQUEST });
        try {
            var config = {
            method: 'get',
           // url: apiUrl+'organizations/nAn5xkvZUWmDPWF',
            headers: { },
            };
    
            hrApi(config)
            .then(function (response) {
                if(response.status===200 || response.status === 201){
                    dispatch({ type: PersonDetailActionType.GET_PERSON_BASIC_INFO_SUCCESS, payload: response.data });
                }else{
                    dispatch({type: PersonDetailActionType.GET_PERSON_BASIC_INFO_FAIL, payload : response.data});
                }
            })
            .catch(function (error) {
                if (error.response) {
                    dispatch({
                      type: PersonDetailActionType.GET_PERSON_BASIC_INFO_FAIL,
                      payload: error
                    });
                }
            });
    
           
        } catch (error) {
          if (error.response) {
            dispatch({
              type: PersonDetailActionType.GET_PERSON_BASIC_INFO_FAIL,
              payload: error
            });
          }
        }
      };
}


const getPersonJobHistory = (id) => {
  return async (dispatch) => {
    dispatch({ type: PersonDetailActionType.GET_PERSON_JOB_HISTORY_REQUEST });

      try {
          var config = {
          method: 'get',
          url: apiUrl+'/jobhistory/jobhistory/'+id,
          headers: { },
          // data : data
          };
  
          hrApi(config)
          .then(function (response) {
              if(response.status===200){
                  dispatch({ type: PersonDetailActionType.GET_PERSON_JOB_HISTORY_SUCCESS, payload: response.data });
              }else{
                  dispatch({type: PersonDetailActionType.GET_PERSON_JOB_HISTORY_FAIL, payload : response.data});
              }
          })
          .catch(function (error) {
              if (error.response) {
                  dispatch({
                    type: PersonDetailActionType.GET_PERSON_JOB_HISTORY_FAIL,
                    payload: error
                  });
              }
          });
  
         
      } catch (error) {
        if (error.response) {
          dispatch({
            type: PersonDetailActionType.GET_PERSON_JOB_HISTORY_FAIL,
            payload: error
          });
        }
      }
    };
}
const getPersonPersonDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: PersonDetailActionType.GET_PERSON_PERSON_DETAILS_REQUEST });

      try {
          var config = {
          method: 'get',
          url: apiUrl+'/person/personalinformation/'+id,
          headers: { },
          // data : data
          };
  
          hrApi(config)
          .then(function (response) {
           
              if(response.status===200){
                  dispatch({ type: PersonDetailActionType.GET_PERSON_PERSON_DETAILS_SUCCESS, payload: response.data });
              }else{
                  dispatch({type: PersonDetailActionType.GET_PERSON_PERSON_DETAILS_FAIL, payload : response.data});
              }
          })
          .catch(function (error) {
              if (error.response) {
                  dispatch({
                    type: PersonDetailActionType.GET_PERSON_PERSON_DETAILS_FAIL,
                    payload: error
                  });
              }
          });
  
         
      } catch (error) {
        if (error.response) {
          dispatch({
            type: PersonDetailActionType.GET_PERSON_JOB_HISTORY_FAIL,
            payload: error
          });
        }
      }
    };
}

  const getPersonDebitDetails = (id) => {
    return async (dispatch) => {
    dispatch({ type: PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_REQUEST });

        try {
    
            var config = {
            method: 'get',
            url: apiUrl+'/inventory/assignment/' + id,
            headers: { },
            // data : data
            };
    
            hrApi(config)
            .then(function (response) {
                if(response.status===200){
                    dispatch({ type: PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_SUCCESS, payload: response.data });
                }else{
                    dispatch({type: PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_FAIL, payload : response.data});
                }
            })
            .catch(function (error) {
                if (error.response) {
                    dispatch({
                      type: PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_FAIL,
                      payload: error
                    });
                }
            });
    
           
        } catch (error) {
          if (error.response) {
            dispatch({
              type: PersonDetailActionType.GET_PERSON_DEBIT_DETAILS_FAIL,
              payload: error
            });
          }
        }
      };
    }
    
const getPersonContactDetails = (id) => {
    
  return async (dispatch) => {
    dispatch({ type: PersonDetailActionType.GET_PERSON_CONTACT_REQUEST });
      try {
  
          var config = {
          method: 'get',
          url: apiUrl+'/person/contact/'+id,
          headers: { },
          // data : data
          };
  
          hrApi(config)
          .then(function (response) {
              if(response.status===200){
                  dispatch({ type: PersonDetailActionType.GET_PERSON_CONTACT_SUCCESS, payload: response.data });
              }else{
                  dispatch({type: PersonDetailActionType.GET_PERSON_CONTACT_FAIL, payload : response.data});
              }
          })
          .catch(function (error) {
              if (error.response) {
                  dispatch({
                    type: PersonDetailActionType.GET_PERSON_CONTACT_FAIL,
                    payload: error
                  });
              }
          });
  
         
      } catch (error) {
        if (error.response) {
          dispatch({
            type: PersonDetailActionType.GET_PERSON_CONTACT_FAIL,
            payload: error
          });
        }
      }
    };
}

const getPersonSocialMedia = (id) => {
    
  return async (dispatch) => {
    dispatch({ type: PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_REQUEST });
      try {
  
          var config = {
          method: 'get',
          url: apiUrl+'/person/socialmedia/'+id,
          headers: { },
          // data : data
          };
  
          hrApi(config)
          .then(function (response) {
              if(response.status===200){
                  dispatch({ type: PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_SUCCESS, payload: response.data });
              }else{
                  dispatch({type: PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_FAIL, payload : response.data});
              }
          })
          .catch(function (error) {
              if (error.response) {
                  dispatch({
                    type: PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_FAIL,
                    payload: error
                  });
              }
          });
  
         
      } catch (error) {
        if (error.response) {
          dispatch({
            type: PersonDetailActionType.GET_PERSON_SOCIAL_MEDIA_FAIL,
            payload: error
          });
        }
      }
    };
}

const resetAction = () => {
    
  return async (dispatch) => {
    dispatch({ type: PersonDetailActionType.GET_PERSON_RESET, payload:{} });
    };
}

export {
  PersonDetailActionType,
  getPersonBasicInfo,
  getPersonJobHistory,
  getPersonPersonDetails,
  getPersonDebitDetails,
  getPersonContactDetails,
  getPersonSocialMedia,
  resetAction

};