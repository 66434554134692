import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Form, Input, Col, Row, ConfigProvider } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import { getDistricts, getProvinces, getCountry, getNeighborhoods } from '../../../redux/actions/address.actions';
import { compareObjects } from '../../../utils/commonFormFunctions'


function OrganizationAdressInfo(props) {

    const { t } = useTranslation();
    const [organizationAdressInfoState, setOrganizationAdressInfoState] = useState({
    });
    const [form] = Form.useForm()

    const { organizationDetailsInfo, setOrganizationUpdateObj, getCountry, getProvinces, getDistricts, getNeighborhoods, addressReducer } = props;


    const [countryList, setCountryList] = useState([
        { ...organizationDetailsInfo.address?.country }
    ]);
    const [districtList, setDistrictList] = useState([
        { ...organizationDetailsInfo.address?.district }
    ]);
    const [provinceList, setProvinceList] = useState([
        { ...organizationDetailsInfo.address?.province }
    ]);
    const [neighborhoodList, setNeighborhoodList] = useState([
        { ...organizationDetailsInfo.address?.neighborhood }
    ]);

    useEffect(() => {

        setOrganizationAdressInfoState(organizationDetailsInfo);
        form.setFieldsValue({
            "country": organizationDetailsInfo.address?.country,
            "province": organizationDetailsInfo.address?.province,
            "district": organizationDetailsInfo.address?.district,
            "neighborhood": organizationDetailsInfo.address?.neighborhood,
            "addressLine": organizationDetailsInfo.address?.addressLine,
            "postalCode": organizationDetailsInfo.address?.postalCode,
        })



    }, [form, organizationDetailsInfo])




    useEffect(() => {
        setOrganizationUpdateObj(organizationAdressInfoState)
    }, [organizationAdressInfoState]);



    useEffect(() => {
        setCountryList(addressReducer.countries);
    }, [addressReducer.countries]);
    useEffect(() => {
        setProvinceList(addressReducer.provinces);
    }, [addressReducer.provinces]);
    useEffect(() => {
        setDistrictList(addressReducer.districts);
    }, [addressReducer.districts]);
    useEffect(() => {
        setNeighborhoodList(addressReducer.neighborhoods);
    }, [addressReducer.neighborhoods]);


    const handleValuesChange = (changedObj, all) => {

        if ('country' === Object.keys(changedObj)[0]) {
            if (JSON.stringify(changedObj.country) !== null) {
                // ülke değişti
                setProvinceList([])//il listesini sıfırla
                setDistrictList([])//ilçe listesini sıfırla
                setNeighborhoodList([])
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["district"] = "";//ilçeyi sıfırla
                fieldsObj["province"] = "";//il sıfırla
                fieldsObj["neighborhood"] = "";
                form.setFieldsValue(fieldsObj)
                const addressObj = {
                    ...organizationAdressInfoState.address, "country": changedObj['country'],
                    "province": { id: null, displayValue: null }, "district": { id: null, displayValue: null }, "neighborhood": { id: null, displayValue: null }
                }
                setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj })

            } else {
                const addressObj = { ...organizationAdressInfoState.address, 'country': changedObj["country"] };
                setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });
            }

        } else if ('province' === Object.keys(changedObj)[0]) {
            if (JSON.stringify(changedObj.province) !== null) {
                
                const addressObj = { ...organizationAdressInfoState.address, "province": changedObj['province']}
                setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj })

            } else {
                const addressObj = { ...organizationAdressInfoState.address, 'province': changedObj["province"] };
                setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });
            }
        } else if ('district' === Object.keys(changedObj)[0]) {
            if (JSON.stringify(changedObj.district) !== null) {

                setNeighborhoodList([])
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["neighborhood"] = "";//mahalle sıfırla
                form.setFieldsValue(fieldsObj)

                const addressObj = { ...organizationAdressInfoState.address, 'district': changedObj["district"], "neighborhood": { id: null, displayValue: null } };
                setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });
            } else {
                const addressObj = { ...organizationAdressInfoState.address, 'district': changedObj["district"] };
                setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });

            }
        } else if ('neighborhood' === Object.keys(changedObj)[0]) {

            const addressObj = { ...organizationAdressInfoState.address, 'neighborhood': changedObj["neighborhood"] };
            setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });
        }
        else if ('addressLine' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationAdressInfoState.address, 'addressLine': changedObj["addressLine"] };
            setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });
        }
        else if ('postalCode' === Object.keys(changedObj)[0]) {
            const addressObj = { ...organizationAdressInfoState.address, 'postalCode': changedObj["postalCode"] };
            setOrganizationAdressInfoState({ ...organizationAdressInfoState, address: addressObj });
        } else {
            setOrganizationAdressInfoState({ ...organizationAdressInfoState, ...changedObj });
        }
    }



    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={false}
            >
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <div className="form-left-body">
                            <Form.Item className="custom-select" name="country" label={t('ORGANIZATION_COUNTRY')} required>
                                <DynamicSelectbox
                                    optionList={countryList}
                                    onFocus={() => getCountry()}
                                    className={!compareObjects(organizationDetailsInfo.address?.country, organizationDetailsInfo.address?.country) ? 'is-changed' : ''}
                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                />
                            </Form.Item>
                            <Form.Item name="addressLine" label={t('ORGANIZATION_ADDRESS')}>
                                <Input.TextArea className={organizationDetailsInfo.address?.addressLine !== organizationAdressInfoState.address?.addressLine ? 'is-changed' : ''} />
                            </Form.Item>
                            { /*
                            <Form.Item className="custom-select" name="province" label={t('ORGANIZATION_PROVINCE')} required>
                                <DynamicSelectbox
                                    optionList={provinceList}
                                    onFocus={() => getProvinces(organizationAdressInfoState.address?.country.id)}
                                    className={!compareObjects(organizationDetailsInfo.address?.province, organizationAdressInfoState.address?.province) ? 'is-changed' : ''}
                                    disabled={!organizationAdressInfoState.address?.country?.id}
                                    placeholder={t('PROVINCE_PLACEHOLDER')}
                                />
                            </Form.Item>
                              */
                             }


                            { /*
                                <Form.Item className="custom-select" name="district" label={t('ORGANIZATION_DISTRICT')} required>
                                <DynamicSelectbox optionList={districtList}
                                    onFocus={() => getDistricts(organizationAdressInfoState.address.province.id)}
                                    className={!compareObjects(organizationAdressInfoState.address?.district, organizationAdressInfoState.address?.district) ? 'is-changed' : ''}
                                    disabled={!organizationAdressInfoState.address?.province?.id}
                                    placeholder={t('DISTRICT_PLACEHOLDER')} />
                                </Form.Item>
                                */
                            }
                            { /*
                            <Form.Item className="custom-select" name="neighborhood" label={t('ORGANIZATION_REGION')} required>
                                <DynamicSelectbox optionList={neighborhoodList}
                                    onFocus={() => getNeighborhoods(organizationAdressInfoState.address?.district.id)}
                                    className={!compareObjects(organizationAdressInfoState.address?.neighborhood, organizationAdressInfoState.address?.neighborhood) ? 'is-changed' : ''}
                                    disabled={!organizationAdressInfoState.address?.district?.id}
                                    placeholder={t('REGION_PLACEHOLDER')} />
                            </Form.Item>
                        */ }
                        </div>
                        <div className="pad-100" />

                    </Col>
                    <Col span={12}>
                        <div className="form-right-body">
                        <Form.Item name="province" label={t('ORGANIZATION_PROVINCE')}>
                                <Input className={organizationDetailsInfo.address?.province !== organizationAdressInfoState.address?.province ? 'is-changed' : ''} />
                            </Form.Item>
                            <Form.Item name="postalCode" label={t('ORGANIZATION_POST_CODE')}>
                                <Input className={organizationDetailsInfo.address?.postalCode !== organizationAdressInfoState.address?.postalCode ? 'is-changed' : ''} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        addressReducer: state.addressReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCountry: () => { dispatch(getCountry()) },
        getProvinces: (props) => { dispatch(getProvinces(props)) },
        getDistricts: (props) => { dispatch(getDistricts(props)) },
        getNeighborhoods: (props) => { dispatch(getNeighborhoods(props)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAdressInfo);