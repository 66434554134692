import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { getDataset } from '../../redux/actions/dataset-action';
import { Form, Input, DatePicker, Col, Row, Spin, AutoComplete, ConfigProvider, Button, Popconfirm, Popover } from 'antd';
import { UilInfoCircle } from '@iconscout/react-unicons';
import DynamicSelectbox from "../dynamicSelectbox/index";
import '../../styles/person.scss';
import { getPersons, getDepartments, getWorkHourDetailsByDefinitions } from '../../redux/actions/organization.actions'
import { getDepartmentDetailInfo, resetAction } from '../../redux/actions/department.action';
import { getJobList } from '../../redux/actions/job.actions';
import { createInitialList, compareObjects, phoneExtMask, timeOffMask, compareDate, focusDateInput, blurDateInput, disabledDynamicDate } from '../../utils/commonFormFunctions'
import { dateFormat, dateFormatList, localeLang, phoneInputConfig, dynamicDateInputFormatter } from '../../utils/config';

import PopoverContent from '../../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../../component/onBoarding/OnboardingBackdropHook';

import { setCurrentTaskState, setUserTaskInfo } from "../../redux/actions/onboarding.actions";

function BasicInformationTab(props) {
    const history = useHistory();
    const { t } = useTranslation();
    //  const [basicInfoState, setBasicInfoState] = useState({ "status": {}, "job": {}, "trialPeriodDate": "" });
    const [basicInfoState, setBasicInfoState] = useState({ "status": {}, "job": {} });
    const [leaveResonType, setLeaveResonType] = useState([])
    const [form] = Form.useForm()
    const { personBasicInfoIsFetching, getDepartments, personBasicInfo, getDataset, focusOnEmail, dismissalTypes, getJobList, jobList,
        departments, dataset, getUpdateObj, disableStatus, getDepartmentDetailInfo, departmentDetailInfo,
        workHourDetailsByDefinitions, getWorkHourDetailsByDefinitions, resetAction, statusModalData, setStatusModalData } = props;
    const [personStatusTypeList, setPersonStatusTypeList] = useState(createInitialList(personBasicInfo.status));
    const [departmentList, setDepartmentList] = useState(createInitialList(personBasicInfo.department));
    const [jobListState, setJobListState] = useState([{ 'value': personBasicInfo?.job?.displayValue }]);
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "hireDate": dateFormatList,
        "trialPeriodDate": dateFormatList,
        "leaveDate": dateFormatList
    })
    const [workHourList, setWorkHourList] = useState([
        {
            'id': parseInt(personBasicInfo?.organizationWorkDefinition?.id),
            'displayValue': personBasicInfo?.organizationWorkDefinition?.displayValue
        }
    ])
    const emailInput = React.useRef(null);

    useEffect(() => {
        if (departmentDetailInfo?.departmentManager?.id) {
            let obj = {
                'id': departmentDetailInfo?.departmentManager?.id,
                'displayValue': departmentDetailInfo?.departmentManager?.displayValue
            }
            const fields = form.getFieldsValue()
            const newFields = { ...fields };
            newFields["manager"] = obj?.displayValue;
            form.setFieldsValue(newFields)
            setBasicInfoState({ ...basicInfoState, "manager": obj })
            resetAction()
        }
    }, [departmentDetailInfo])
    useEffect(() => {
        setPersonStatusTypeList(dataset.personStatusTypes);
    }, [dataset.personStatusTypes]);
    useEffect(() => {
        setBasicInfoState({ ...personBasicInfo, 'organizationWorkDefinition': { 'id': parseInt(personBasicInfo?.organizationWorkDefinition?.id), 'displayValue': personBasicInfo?.organizationWorkDefinition?.displayValue } });
        const setObj = {
            "givenName": personBasicInfo.givenName,
            "familyName": personBasicInfo.familyName,
            "job": personBasicInfo?.job?.displayValue,
            "manager": personBasicInfo.manager?.displayValue,
            "department": personBasicInfo.department,
            "officePhone": personBasicInfo.officePhone ?? (localStorage.getItem("i18nLang") === "tr-TR" ? '90' : '44'),
            "workPhoneExt": personBasicInfo.workPhoneExt,
            "corporateEmail": personBasicInfo.corporateEmail,
            "identityNumber": personBasicInfo.identityNumber,
            "transferorBalance": personBasicInfo.transferorBalance,
            "status": personBasicInfo.status,
            "leaveReason": personBasicInfo.leaveReason,
            "organizationWorkDefinition": personBasicInfo?.organizationWorkDefinition ? { "id": parseInt(personBasicInfo?.organizationWorkDefinition?.id), "displayValue": personBasicInfo?.organizationWorkDefinition?.displayValue } : null
        }
        if (personBasicInfo.leaveDate) {
            setObj["leaveDate"] = moment(personBasicInfo.leaveDate)
        }
        if (personBasicInfo.hireDate) {
            setObj['hireDate'] = moment(personBasicInfo.hireDate)
        }
        if (personBasicInfo.trialPeriodDate) {
            setObj['trialPeriodDate'] = moment(personBasicInfo.trialPeriodDate)
        }
        form.setFieldsValue(setObj)
    }, [form, personBasicInfo])

    useEffect(() => {
        getUpdateObj(basicInfoState)
    }, [basicInfoState]);
    useEffect(() => {
        setLeaveResonType(dismissalTypes)
    }, [dismissalTypes]);
    useEffect(() => {
        setDepartmentList(departments);
    }, [departments]);
    useEffect(() => {
        var tempData = workHourDetailsByDefinitions.definitions.map((data) => {
            return {
                'id': data.definitionId,
                'displayValue': data.name
            }
        });
        setWorkHourList(tempData)
    }, [workHourDetailsByDefinitions])
    useEffect(() => {
        const tempArr = [];
        jobList.map((data) => {
            return tempArr.push({ 'value': data.name })
        })
        setJobListState(tempArr)
    }, [jobList])

    useEffect(() => {
        if (focusOnEmail) {
            emailInput.current.inputElement.focus();
        }
    }, [focusOnEmail]);
    useEffect(() => {
        if (statusModalData.isAccept !== null) {
            const fields = form.getFieldsValue()
            const newFields = { ...fields };
            if (statusModalData.isAccept) {
                newFields["status"] = { id: '1', displayValue: t('PARTNER_ACTIVE') };
                setBasicInfoState({ ...basicInfoState, 'status': { id: '1', displayValue: t('PARTNER_ACTIVE') } })
            } else {
                newFields["status"] = { id: personBasicInfo.status?.id, displayValue: personBasicInfo.status?.displayValue };
                setBasicInfoState({ ...basicInfoState, 'status': { id: personBasicInfo.status?.id, displayValue: personBasicInfo.status?.displayValue } })
            }
            form.setFieldsValue(newFields);
        }
    }, [statusModalData])
    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === "job") {
            if (changedObj.job === "") {
                setBasicInfoState({ ...basicInfoState, 'job': "" });
            } else {
                const jobObj = { ...basicInfoState.job, 'displayValue': changedObj.job }
                setBasicInfoState({ ...basicInfoState, 'job': jobObj });
            }
        } else if (key === "organizationWorkDefinition") {
            setBasicInfoState({ ...basicInfoState, 'organizationWorkDefinition': changedObj.organizationWorkDefinition });
        }
        else if (key === 'department') {
            getDepartmentDetailInfo(changedObj.department?.id);
            setBasicInfoState({ ...basicInfoState, ...changedObj });
        } else if (key === 'hireDate') {
            // setBasicInfoState({ ...basicInfoState, 'hireDate': moment(changedObj.hireDate).format(dateFormat) });
            setBasicInfoState({ ...basicInfoState, 'hireDate': moment(changedObj.hireDate).format(dateFormat), "trialPeriodDate": moment(changedObj.hireDate).add(2, 'months').format(dateFormat) });
            const fields = form.getFieldsValue()
            const newFields = { ...fields };
            newFields["trialPeriodDate"] = moment(changedObj.hireDate).add(2, 'months');
            form.setFieldsValue(newFields);
        }
        else if (key === 'trialPeriodDate') {
            setBasicInfoState({ ...basicInfoState, 'trialPeriodDate': moment(changedObj.trialPeriodDate).format(dateFormat) });
        }
        else if (key === 'leaveDate') {
            setBasicInfoState({ ...basicInfoState, 'leaveDate': moment(changedObj.leaveDate).format(dateFormat) });
        } else if (key === 'status') {
            if (changedObj?.status?.id === '1') {
                setStatusModalData({ ...statusModalData, 'isVisible': "true" });
            }
        } else {
            setBasicInfoState({ ...basicInfoState, ...changedObj });
        }
    }
    
    var hireDate = moment(personBasicInfo.hireDate);

    // #region OnBoarding
    const [popoverVisible, setPopoverVisible] = useState(false);
    const hide = () => { setPopoverVisible(false); setCurrentTaskState(63);  };
    const handlePopoverVisibleChange = (visible) => {
        if(visible == false){ props.setCurrentTaskState(63); }
        if(props.currentTaskState !== null){
            setPopoverVisible(visible);
        } 
    };
    
    OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'basic-information-tab' );
    
    useEffect(() => { 
        
        if(props.currentTaskState == 62){
            
            const target = document.querySelector('.form-work-label');
            if (target) {
              target.scrollIntoView({ behavior: 'auto' });
              setPopoverVisible(true)
            }
        }
    }, [props.currentTaskState]); 
   
    // #endregion

          
    var currentDate = moment();
    var diffDuration = moment.duration(currentDate.diff(hireDate));
    return (
        personBasicInfoIsFetching ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Row gutter={[32, 0]}>
                        <Col span={12}>
                            <div className="form-left-body ">
                                <Form.Item name="givenName" label={t('PARTNER_GIVENNAME') + " *"} required>
                                    <Input disabled={disableStatus} className={personBasicInfo.givenName !== basicInfoState.givenName ? 'is-changed' : ''}></Input>
                                </Form.Item>
                                <Form.Item name="familyName" label={t('PARTNER_FAMILYNAME') + " *"} required>
                                    <Input disabled={disableStatus} className={personBasicInfo.familyName !== basicInfoState.familyName ? 'is-changed' : ''}></Input>
                                </Form.Item>
                                <Form.Item name="department" className="custom-select" label={t('PARTNER_DEPARTMENT') + " *"}>
                                    <DynamicSelectbox
                                        disabled={disableStatus}
                                        optionList={departmentList}
                                        onFocus={() => getDepartments(personBasicInfo.organization?.id)}
                                        className={!compareObjects(personBasicInfo.department, basicInfoState.department) ? 'is-changed' : ''}
                                        placeholder={disableStatus ? '' : t('PARTNER_SELECT')}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                <div className="d-flex">
                                    <div className="ant-form-item-label">
                                        <label className="">{t('PARTNER_MANAGER') + " *"}</label>
                                    </div>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={<div><span className="fw-600">{t('DEPARTMENT_POPOVER_TITLE')}</span> <br />  {t('DEPARTMENT_POPOVER_MESSAGE')}</div>}
                                        okText={t('DEPARTMENT_POPOVER_CONFIRM_TEXT')}
                                        onConfirm={() => history.push('/department')}
                                        cancelButtonProps={
                                            {
                                                'style': {
                                                    'display': 'none'
                                                }
                                            }
                                        }
                                    >
                                        <Button className="pad-0 h-32 w-auto transparent-btn" icon={<UilInfoCircle className="svg-primary-icon" />} />
                                    </Popconfirm>
                                </div>
                                <Form.Item className="custom-select" name="manager" label=''>
                                    <Input
                                        disabled={true}
                                        placeholder={''}
                                    />
                                </Form.Item>
                                <Form.Item name="job" className="auto-complete-input" label={t('PARTNER_JOB')}>
                                    <AutoComplete
                                        disabled={disableStatus}
                                        className={!compareObjects(personBasicInfo.job, basicInfoState.job) ? 'is-changed' : ''}
                                        options={jobListState}
                                        onFocus={() => getJobList({
                                            "searchTerm": "",
                                            "pagination": {
                                                "pageNumber": 1,
                                                "pageSize": 100,
                                                "orderBy": "Name",
                                                "ascending": true
                                            }
                                        })}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                </Form.Item>
                                <Form.Item name="organizationWorkDefinition" className="custom-select" label={t('CHOOSE_WORKING_HOURS')}>
                                    <DynamicSelectbox
                                        disabled={disableStatus}
                                        placeholder={t('PARTNER_SELECT')}
                                        optionList={workHourList}
                                        disallowClear={true}
                                        onFocus={() => getWorkHourDetailsByDefinitions()}
                                        className={!compareObjects(parseInt(personBasicInfo?.organizationWorkDefinition?.id), basicInfoState?.organizationWorkDefinition?.id) ? 'is-changed' : ''}
                                    />
                                </Form.Item>
                                {!basicInfoState?.organizationWorkDefinition?.id &&
                                    <p className="black f-14">{t('EMPTY_PERSON_WORK_HOURS', { "name": personBasicInfo?.givenName + ' ' + personBasicInfo?.familyName })}</p>
                                }
                                <Form.Item name="identityNumber" label={t('PARTNER_IDENTITY_NUMBER')}>
                                    <Input maxLength={20} disabled={disableStatus} className={personBasicInfo.identityNumber !== basicInfoState.identityNumber ? 'is-changed' : ''} />
                                </Form.Item>
                            </div>
                            <div className="pad-100" />
                        </Col>
                        <Col span={12}>
                            <div className="form-right-body">
                                <Form.Item name="hireDate" label={datePickerFormatType.hireDate === dynamicDateInputFormatter ? t('PARTNER_START_DATE_OF_COMPANY') + " * " + t('PLACEHOLDER_DATE') : t('PARTNER_START_DATE_OF_COMPANY') + " *"}>
                                    <DatePicker
                                        disabled={disableStatus}
                                        className={!compareDate(personBasicInfo.hireDate, basicInfoState.hireDate) ? 'is-changed' : ''}
                                        format={datePickerFormatType.hireDate}
                                        locale={localeLang}
                                        placeholder={disableStatus ? '' : t('PLACEHOLDER_DATE')}
                                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'hireDate', dynamicDateInputFormatter)}
                                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'hireDate', dateFormatList)}
                                    />
                                </Form.Item>
                                <Form.Item name="trialPeriodDate" label={datePickerFormatType.trialPeriodDate === dynamicDateInputFormatter ? t('PARTNER_TRIAL_PERIOD_DATE') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_TRIAL_PERIOD_DATE')}>
                                    <DatePicker
                                        disabled={disableStatus}
                                        className={!compareDate(personBasicInfo.trialPeriodDate, basicInfoState.trialPeriodDate) ? 'is-changed' : ''}
                                        format={datePickerFormatType.trialPeriodDate}
                                        locale={localeLang}
                                        disabledDate={(current) => disabledDynamicDate(current, basicInfoState.hireDate)}
                                        placeholder={disableStatus ? '' : t('PLACEHOLDER_DATE')}
                                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'trialPeriodDate', dynamicDateInputFormatter)}
                                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'trialPeriodDate', dateFormatList)}
                                    />
                                </Form.Item>
                                {personBasicInfo.hireDate && isNaN(personBasicInfo.hireDate) &&
                                    <Form.Item label={t('UPTIME')}>
                                        <div className="form-work-label">
                                            <p>
                                                {diffDuration.years() > 0 && diffDuration.years() + ' ' + t('PARTNER_YEARS') + ' '}
                                                {diffDuration.months() > 0 && diffDuration.months() + ' ' + t('PARTNER_MONTH') + ' '}
                                                {diffDuration.days() > 0 && diffDuration.days() + ' ' + t('PARTNER_DAY')}
                                            </p>
                                        </div>

                                    </Form.Item>
                                }

                                <Popover
                                    key={'basic-information-transferorBalance'}
                                    overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                                    content={<PopoverContent key={'person.add'} type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)} />}
                                    title={false}
                                    placement="left"
                                    trigger="click"
                                    visible={popoverVisible && props.currentTaskState !== 31 && props.currentTaskState !== 61 && props.currentTaskState !== 63 && props.currentTaskState !== null}
                                    onVisibleChange={handlePopoverVisibleChange}
                                >

                                    <Form.Item className={(props.currentTaskState && popoverVisible && props.currentTaskState !== 31 && props.currentTaskState !== 61 && props.currentTaskState !== 63 && props.currentTaskState !== null) ? 'onboarding-button-border px-2 py-1' : 'px-2 py-1'} name="transferorBalance" label={t('TRANSFEROR_BALANCE')}>
                                        <MaskedInput className={personBasicInfo.transferorBalance !== basicInfoState.transferorBalance ? 'is-changed ant-input' : 'ant-input'} mask={timeOffMask} />
                                    </Form.Item>
                                
                                </Popover>

                                <Row gutter={[10, 0]}>
                                    <Col span={16}>
                                        <Form.Item name="officePhone" label={t('PARTNER_OFFICE_PHONE')}>
                                            <PhoneInput 
                                                {...phoneInputConfig}
                                                containerClass={
                                                    disableStatus ? 'flag-disable' :
                                                        !compareObjects(personBasicInfo.officePhone, basicInfoState.officePhone) ? 'is-changed' : ''
                                                }
                                                disabled={disableStatus}
                                                disableDropdown={disableStatus}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="workPhoneExt" label={t('PARTNER_WORK_PHONE_EXT')}>
                                            <MaskedInput disabled={disableStatus} className={personBasicInfo.workPhoneExt !== basicInfoState.workPhoneExt ? 'is-changed ant-input' : 'ant-input'} mask={phoneExtMask} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item name="corporateEmail" label={t('PARTNER_CORPORATE_MAIL')}>
                                    <MaskedInput disabled={disableStatus} ref={emailInput} className={personBasicInfo.corporateEmail !== basicInfoState.corporateEmail ? 'is-changed ant-input' : 'ant-input'} mask={emailMask} />
                                </Form.Item>
                                <Form.Item name="status" className="custom-select" label={t('PARTNER_STATUS')}>
                                    <DynamicSelectbox
                                        disabled={personBasicInfo.status.id === '3' ? false : true}
                                        optionList={personStatusTypeList}
                                        onFocus={() => getDataset(24)}
                                        className={!compareObjects(personBasicInfo.status, basicInfoState.status) ? 'is-changed' : ''}
                                        placeholder={basicInfoState.status.id === '3' ? false : disableStatus ? '' : t('PARTNER_SELECT')}
                                        disallowClear={true}
                                        orderBy={'none'}
                                    ></DynamicSelectbox>
                                </Form.Item>
                                {basicInfoState.status.id === '3' &&
                                    <div>
                                        <Form.Item name="leaveDate" label={datePickerFormatType.leaveDate === dynamicDateInputFormatter ? t('PARTNER_LEAVE_DATE') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_LEAVE_DATE')}>
                                            <DatePicker
                                                disabled={disableStatus}
                                                locale={localeLang}
                                                className={basicInfoState.leaveDate && !compareDate(personBasicInfo.leaveDate, basicInfoState.leaveDate) ? 'is-changed' : ''}
                                                placeholder={disableStatus ? '' : t('PLACEHOLDER_DATE')}
                                                format={datePickerFormatType.leaveDate}
                                                onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'leaveDate', dynamicDateInputFormatter)}
                                                onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'leaveDate', dateFormatList)}
                                            />
                                        </Form.Item>
                                        <Form.Item name="leaveReason" className="custom-select" label={t('PARTNER_LEAVE_REASON')}>
                                            <DynamicSelectbox
                                                disabled={disableStatus}
                                                optionList={leaveResonType}
                                                onFocus={() => getDataset(36)}
                                                placeholder={t('PARTNER_SELECT')}
                                            />
                                        </Form.Item>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form >
            </ConfigProvider >
        )
    )
}
const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
        persons: state.organization.persons,
        profile: state.profile,
        departments: state.organization.departments,
        departmentDetailInfo: state.departmentReducer.departmentDetailInfo,
        dismissalTypes: state.dataset.dismissalTypes,
        jobList: state.job.jobList.results,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPersons: (id) => { dispatch(getPersons(id)) },
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getDepartments: (id) => { dispatch(getDepartments(id)) },
        getDepartmentDetailInfo: (id) => { dispatch(getDepartmentDetailInfo(id)) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) },
        getWorkHourDetailsByDefinitions: () => { dispatch(getWorkHourDetailsByDefinitions()) },
        resetAction: () => { dispatch(resetAction()) },
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicInformationTab);