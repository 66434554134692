import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getWorkHourDepartmentInPersons, updateOrganizationWorkHours, resetUpdateOrganizationWorkHours, setOrganizationWorkHoursCreateDrawer, workHoursControl,
    resetworkHoursControl
} from '../../../redux/actions/organization.actions';

import { Input, Button, List, Form, ConfigProvider, Switch, Checkbox, TimePicker, TreeSelect, Row, Col } from 'antd';
import { UilMultiply, UilArrowRight } from '@iconscout/react-unicons'
import { DownOutlined } from '@ant-design/icons';

import moment from 'moment';
import locale from "antd/es/date-picker/locale/en_US";


import { dateTimeFormat, dateTimeFormatWithSecond } from '../../../utils/config';
import { compareObjects } from "../../../utils/commonFormFunctions";

import Successful from "../../successful";
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';
import '../../../styles/setting.scss';

function CreateNewWorkHours(props) {
    const { updateOrganizationWorkHoursResponse, updateOrganizationWorkHours, resetUpdateOrganizationWorkHours, profile, setOrganizationWorkHoursCreateDrawer,
        workHourDepartmentInPerson, workHourDepartmentInPersonLoading, getWorkHourDepartmentInPersons, workHourDetailsByDefinitions, workHoursControlData,
        workHoursControlLoading, workHoursModal, workHoursControl, resetworkHoursControl } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [personTreeData, setPersonTreeData] = useState([])
    const [informationModal, setInformationModal] = useState({ 'isVisible': false })
    const [warningModal, setWarningModal] = useState({ 'isVisible': false })
    const [personInfoModal, setPersonInfoModal] = useState({ 'isVisible': false })
    const [isCreateNewWorkHourModalOpen, setIsCreateNewWorkHourModalOpen] = useState(false)
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const { SHOW_CHILD } = TreeSelect;
    var setObj = {};

    const turkishCharacterRegex = keyword => keyword
        .replace(/[ıİiI]/g, '[ıİiI]')
        .replace(/[şŞsS]/g, '[şŞsS]')
        .replace(/[çÇcC]/g, '[çÇcC]')
        .replace(/[ğĞgG]/g, '[ğĞgG]')
        .replace(/[öÖoO]/g, '[öÖoO]')
        .replace(/[üÜuU]/g, '[üÜuU]');
    const customFilter = (searchText, option) => ((turkishCharacterRegex(option.title)).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase()));
    const defaultData = {
        Name: "",
        AllWeekRequestIncludeSaturday: false,
        FridayRequestIncludeSaturday: false,
        OnlyFridayRequestIncludeSaturday: false,
        IsDefault: workHourDetailsByDefinitions?.definitions?.length > 0 ? false : true,
        persons: [],
        organizationWorkHours: [
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 1,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": true
            },
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 2,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": true
            },
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 3,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": true
            },
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 4,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": true
            },
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 5,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": true
            },
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 6,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": false
            },
            {
                "resourceId": "",
                "organizationId": 1,
                "workDay": 7,
                "startTime": "08:00:00",
                "endTime": "18:00:00",
                "lunchBreakStartTime": "12:30:00",
                "lunchBreakEndTime": "13:30:00",
                "isActive": false
            }
        ]
    };

    const [workHoursState, setWorkHoursState] = useState(defaultData)
    function getDefaultData() {
        defaultData?.organizationWorkHours?.map((data, index) => {
            setObj = {
                ...setObj,
                ["isActive_" + index]: data?.isActive,
            };
            if (data.startTime) {
                setObj["startTime_" + index] = moment(data?.startTime, dateTimeFormat)
            } else {
                setObj["startTime_" + index] = null
            }
            if (data.endTime) {
                setObj["endTime_" + index] = moment(data.endTime, dateTimeFormat)
            } else {
                setObj["endTime_" + index] = null
            }
            if (data.lunchBreakStartTime) {
                setObj["lunchBreakStartTime_" + index] = moment(data.lunchBreakStartTime, dateTimeFormat)
            } else {
                setObj["lunchBreakStartTime_" + index] = null
            }
            if (data.lunchBreakEndTime) {
                setObj["lunchBreakEndTime_" + index] = moment(data.lunchBreakEndTime, dateTimeFormat)
            } else {
                setObj["lunchBreakEndTime_" + index] = null
            }
        })
        setObj["IsDefault"] = defaultData?.IsDefault;
        setObj["Name"] = defaultData?.Name;
        setObj["persons"] = defaultData?.persons;
        setObj["AllWeekRequestIncludeSaturday"] = defaultData?.AllWeekRequestIncludeSaturday;
        setObj["FridayRequestIncludeSaturday"] = defaultData?.FridayRequestIncludeSaturday;
        setObj["OnlyFridayRequestIncludeSaturday"] = defaultData?.OnlyFridayRequestIncludeSaturday;
        form.setFieldsValue(setObj)
    }
    const renderDayName = (id) => {
        switch (id) {
            case 1:
                return t('MON');
            case 2:
                return t('TUE');
            case 3:
                return t('WED');
            case 4:
                return t('THU');
            case 5:
                return t('FRI');
            case 6:
                return t('SAT');
            case 7:
                return t('SUN');
            default:
                return null;
        }
    }
    useEffect(() => {
        getWorkHourDepartmentInPersons();
    }, [])
    
    useEffect(() => {
        setIsCreateNewWorkHourModalOpen(true);
    }, [workHoursModal])

    useEffect(() => {
        if (!workHoursControlLoading) {
            if (workHoursControlData.length > 0) {
                setPersonInfoModal({ 'isVisible': true })
            } else {
                if (workHoursModal !== "close") {
                    handleCreateOrganizationWorkHour()
                }
            }
        }
    }, [workHoursControlData])
    useEffect(() => {
        if (updateOrganizationWorkHoursResponse?.status === 'SUCCESS') {
            setCurrentTab(2);
            resetUpdateOrganizationWorkHours();
            resetworkHoursControl()
        }
        else if (updateOrganizationWorkHoursResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateOrganizationWorkHoursResponse?.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateOrganizationWorkHours();
            resetworkHoursControl()
        }
        else if (updateOrganizationWorkHoursResponse?.status === 'INPUT_ERROR') {
            DynamicToastMessage({
                'key': 'input_error_key',
                'title': t('ERROR'),
                'description': <List key={'input-error-key'}>{updateOrganizationWorkHoursResponse?.message?.map((item,i) => { return <List.Item key={i+'error'}>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateOrganizationWorkHours();
            resetworkHoursControl()
        }
    }, [updateOrganizationWorkHoursResponse])
    useEffect(() => {
        if (!workHourDepartmentInPersonLoading) {
            var list = [];
            workHourDepartmentInPerson.map((data) => {
                return list.push({
                    'title': data.departmentName,
                    'value': data.departmentId,
                    'key': data.departmentId,
                    'children': data.persons.map((datax) => {
                        return {
                            'title': datax.givenName + ' ' + datax.familyName,
                            'value': datax.resourceId,
                            'key': datax.resourceId,
                        }
                    })
                })
            })
            setPersonTreeData(list)
        }
    }, [workHourDepartmentInPerson])
    useEffect(() => {
        getDefaultData()
    }, [])

    const handleValuesChange = (changedObj, all) => {
        const changedValue = Object.keys(changedObj)[0] //isActive_0
        const type = changedValue.split('_')[0]; // isActive
        const key = changedValue.split('_')[1]; // 0
        if (changedValue === "Name") {
            setWorkHoursState({ ...workHoursState, ...changedObj });
        } else if (changedValue === "persons") {
            setWorkHoursState({ ...workHoursState, ...changedObj });
        } else if (changedValue === "IsDefault") {
            const fields = form.getFieldsValue()
            const projects = { ...fields };
            projects["persons"] = [];
            form.setFieldsValue(projects)
            setWorkHoursState({ ...workHoursState, "IsDefault": changedObj.IsDefault, "persons": [] })
        } else if (type === 'isActive') {
            const tempObj = { ...workHoursState?.organizationWorkHours[key], [type]: changedObj[changedValue] }
            let tempArr = [...workHoursState?.organizationWorkHours];
            tempArr[key] = tempObj;
            setWorkHoursState({ ...workHoursState, organizationWorkHours: tempArr });
        } else if (type === 'lunchBreakStartTime') {
            const StartTime = moment(workHoursState?.organizationWorkHours[key].startTime, dateTimeFormatWithSecond);
            const EndTime = moment(workHoursState?.organizationWorkHours[key].endTime, dateTimeFormatWithSecond);
            const LunchBreakStartTime = moment(changedObj[changedValue]);
            var startTimeResult = LunchBreakStartTime.diff(StartTime, 'hours');
            var endTimeResult = EndTime.diff(LunchBreakStartTime, 'hours');
            if (startTimeResult >= 0 && endTimeResult > 0 || changedObj[changedValue] === null) {
                const tempObj = { ...workHoursState?.organizationWorkHours[key], [type]: moment(changedObj[changedValue]).format(dateTimeFormatWithSecond) }
                let tempArr = [...workHoursState?.organizationWorkHours];
                tempArr[key] = tempObj;
                setWorkHoursState({ ...workHoursState, organizationWorkHours: tempArr });
            } else {
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["lunchBreakStartTime_" + key] = workHoursState?.organizationWorkHours[key].lunchBreakStartTime && workHoursState?.organizationWorkHours[key].lunchBreakStartTime !== "Invalid date" ? moment(workHoursState?.organizationWorkHours[key].lunchBreakStartTime, dateTimeFormat) : null
                form.setFieldsValue(projects)

                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': t('WORK_HOURS_CHOOSE_BETWEEN_MESSAGE'),
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
            }
        } else if (type === 'lunchBreakEndTime') {
            const StartTime = moment(workHoursState?.organizationWorkHours[key].startTime, dateTimeFormatWithSecond);
            const EndTime = moment(workHoursState?.organizationWorkHours[key].endTime, dateTimeFormatWithSecond);
            const LunchBreakEndTime = moment(changedObj[changedValue]);
            var startTimeResult = LunchBreakEndTime.diff(StartTime, 'hours');
            var endTimeResult = EndTime.diff(LunchBreakEndTime, 'hours');
            if (startTimeResult > 0 && endTimeResult >= 0 || changedObj[changedValue] === null) {
                const tempObj = { ...workHoursState?.organizationWorkHours[key], [type]: moment(changedObj[changedValue]).format(dateTimeFormatWithSecond) }
                let tempArr = [...workHoursState?.organizationWorkHours];
                tempArr[key] = tempObj;
                setWorkHoursState({ ...workHoursState, organizationWorkHours: tempArr });
            } else {
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["lunchBreakEndTime_" + key] = workHoursState?.organizationWorkHours[key].lunchBreakEndTime && workHoursState?.organizationWorkHours[key].lunchBreakEndTime !== "Invalid date" ? moment(workHoursState?.organizationWorkHours[key].lunchBreakEndTime, dateTimeFormat) : null
                form.setFieldsValue(projects)

                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': t('WORK_HOURS_CHOOSE_BETWEEN_MESSAGE'),
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
            }
        } else if (type === 'AllWeekRequestIncludeSaturday' || type === 'FridayRequestIncludeSaturday' || type === 'OnlyFridayRequestIncludeSaturday') {
            setWorkHoursState({ ...workHoursState, ...changedObj });
        } else {
            const tempObj = { ...workHoursState?.organizationWorkHours[key], [type]: moment(changedObj[changedValue]).format(dateTimeFormatWithSecond) }
            let tempArr = [...workHoursState?.organizationWorkHours];
            tempArr[key] = tempObj;
            setWorkHoursState({ ...workHoursState, organizationWorkHours: tempArr });
        }
    }
    function closeControl() {
        setIsCreateNewWorkHourModalOpen(false);
        // if (currentTab !== 2 && (workHoursState?.Name !== "" || compareObjects(defaultData?.organizationWorkHours, workHoursState?.organizationWorkHours) === false || workHoursState?.AllWeekRequestIncludeSaturday || workHoursState?.FridayRequestIncludeSaturday || workHoursState?.IsDefault || (workHoursState?.IsDefault === false && workHoursState?.persons.length > 0))) {
        if (currentTab !== 2 && compareObjects(defaultData, workHoursState) === false) {
            setConfirmModalData({ 'isVisible': true })
        } else {
            destroyLastState()
        }
    }
    function destroyLastState() {
        setCurrentTab(1);
        setOrganizationWorkHoursCreateDrawer(false)
        setWorkHoursState(defaultData);
        resetworkHoursControl()
    }
    function createNewOne() {
        setCurrentTab(1);
        setWorkHoursState(defaultData);
        getDefaultData()
        resetworkHoursControl()
    }
    const applyToAll = () => {
        let newData = [];
        const fields = form.getFieldsValue()
        const newForm = { ...fields };

        for (var i = 0; i < 7; i++) {
            if (workHoursState?.organizationWorkHours[i]?.isActive) {
                newData.push({
                    "resourceId": workHoursState?.organizationWorkHours[i]?.resourceId,
                    "organizationId": workHoursState?.organizationWorkHours[i]?.organizationId,
                    "workDay": workHoursState?.organizationWorkHours[i]?.workDay,
                    "startTime": workHoursState?.organizationWorkHours[0]?.startTime,
                    "endTime": workHoursState?.organizationWorkHours[0]?.endTime,
                    "lunchBreakStartTime": workHoursState?.organizationWorkHours[0]?.lunchBreakStartTime,
                    "lunchBreakEndTime": workHoursState?.organizationWorkHours[0]?.lunchBreakEndTime,
                    "isActive": workHoursState?.organizationWorkHours[i]?.isActive,
                });
                newForm["isActive_" + i] = workHoursState?.organizationWorkHours[i]?.isActive
                if (workHoursState?.organizationWorkHours[0].startTime) {
                    newForm["startTime_" + i] = moment(workHoursState?.organizationWorkHours[0].startTime, dateTimeFormat)
                } else {
                    newForm["startTime_" + i] = null
                }
                if (workHoursState?.organizationWorkHours[0].endTime) {
                    newForm["endTime_" + i] = moment(workHoursState?.organizationWorkHours[0].endTime, dateTimeFormat)
                } else {
                    newForm["endTime_" + i] = null
                }
                if (workHoursState?.organizationWorkHours[0].lunchBreakStartTime) {
                    newForm["lunchBreakStartTime_" + i] = moment(workHoursState?.organizationWorkHours[0].lunchBreakStartTime, dateTimeFormat)
                } else {
                    newForm["lunchBreakStartTime_" + i] = null
                }

                if (workHoursState?.organizationWorkHours[0].lunchBreakEndTime) {
                    newForm["lunchBreakEndTime_" + i] = moment(workHoursState?.organizationWorkHours[0].lunchBreakEndTime, dateTimeFormat)
                } else {
                    newForm["lunchBreakEndTime_" + i] = null
                }
            } else {
                newData.push({
                    "resourceId": workHoursState?.organizationWorkHours[i]?.resourceId,
                    "organizationId": workHoursState?.organizationWorkHours[i]?.organizationId,
                    "workDay": workHoursState?.organizationWorkHours[i]?.workDay,
                    "startTime": null,
                    "endTime": null,
                    "lunchBreakStartTime": null,
                    "lunchBreakEndTime": null,
                    "isActive": workHoursState?.organizationWorkHours[i]?.isActive,
                })
            }
        }
        form.setFieldsValue(newForm)
        setWorkHoursState(newData)
    }
    const showApplyToAll = (index, startTime, endTime) => {
        if (
            index === 0 && startTime && endTime &&
            startTime.toString() !== '00:00:00' && startTime.toString() !== 'Invalid date' &&
            startTime !== null && startTime !== 'Invalid date' &&
            endTime.toString() !== '00:00:00' && endTime.toString() !== 'Invalid date' &&
            endTime !== null && endTime !== 'Invalid date'
        ) {
            return (<Button onClick={() => applyToAll()} className='transparent-btn f-16'>{t('APPLY_TO_ALL')}</Button>)
        }
    }
    const checkRequiredFields = (nextFunc, data) => {
        if(isCreateNewWorkHourModalOpen === false) return;
        let hasError = false;
        let hasError2 = false;
        let fields = []
        if (!workHoursState.Name || workHoursState.Name === "") {
            hasError = true
            fields.push(t('WORKING_HOUR_NAME'))
        }
        /*
        if (workHoursState.IsDefault === false && workHoursState.persons.length === 0) {
            hasError = true
            fields.push(t('SETTINGS_SELECT_USER'))
        }
        */
        Object.entries(data.organizationWorkHours).map((value) => {
            const StartTime = moment(value[1].StartTime, dateTimeFormatWithSecond);
            const endTime = moment(value[1].EndTime, dateTimeFormatWithSecond);
            const LunchBreakStartTime = moment(value[1].LunchBreakStartTime, dateTimeFormatWithSecond);
            const LunchBreakEndTime = moment(value[1].LunchBreakEndTime, dateTimeFormatWithSecond);

            var startTimeResult = endTime.diff(StartTime, 'hours');
            var lunchBreakResult = LunchBreakEndTime.diff(LunchBreakStartTime, 'hours');

            if (value[1].IsActive === true && startTimeResult > 8 && (lunchBreakResult <= 0 || isNaN(lunchBreakResult))) {
                hasError2 = true
            }
        })
        
        if (!hasError && !hasError2) {
            setWarningModal({ ...informationModal, 'isVisible': true, 'data': data, 'action': nextFunc() })
        } else {
            if (fields.length !== 0) {
                DynamicToastMessage({
                    'key': 'add_departments_warning_text',
                    'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                    'description': <List key="toaster-warn">{fields.map((item,i) => { return <List.Item key={i+'warning'}>{item}</List.Item> })}</List>,
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }

        }
        if (hasError2 && !hasError) {
            setWarningModal({ ...informationModal, 'isVisible': true, 'data': data, 'action': openInfoModal })
        }
    }
    const openInfoModal = (data) => {
        setInformationModal({ ...informationModal, 'isVisible': true, 'data': data })
    }

    const handleCreateOrganizationWorkHour = () => {
        let organizationWorkHoursArray = [];
        organizationWorkHoursArray = workHoursState?.organizationWorkHours.map((value) => {
            if (value.isActive === true) {
                return {
                    "IsActive": value.isActive,
                    "StartTime": value.startTime === 'Invalid date' || value.startTime === null ? null : value.startTime,
                    "EndTime": value.endTime === 'Invalid date' || value.endTime === null ? null : value.endTime,
                    "LunchBreakStartTime": value.lunchBreakStartTime === 'Invalid date' || value.lunchBreakStartTime === null ? null : value.lunchBreakStartTime,
                    "LunchBreakEndTime": value.lunchBreakEndTime === 'Invalid date' || value.lunchBreakEndTime === null ? null : value.lunchBreakEndTime,
                    "WorkDay": value.workDay
                }
            } else {
                return {
                    "IsActive": value.isActive,
                    "StartTime": null,
                    "EndTime": null,
                    "LunchBreakStartTime": null,
                    "LunchBreakEndTime": null,
                    "WorkDay": value.workDay
                }
            }
        })

        const data = {
            "Name": workHoursState.Name,
            "IsDefault": workHoursState.IsDefault,
            "OrganizationResourceId": profile.organization.resourceId,
            "AllWeekRequestIncludeSaturday": workHoursState.AllWeekRequestIncludeSaturday,
            "FridayRequestIncludeSaturday": workHoursState.FridayRequestIncludeSaturday,
            "OnlyFridayRequestIncludeSaturday": workHoursState.OnlyFridayRequestIncludeSaturday,
            "organizationWorkHours": organizationWorkHoursArray,
            "UsersResourceId": workHoursState.persons
        }

        checkRequiredFields(() => updateOrganizationWorkHours, data)

        window.sendinblue?.track(
            //brevo_event_create_organization_work_hours
            'create_organization_work_hours',
        )
    }

    const [isworkHoursControlLoading, setisworkHoursControlLoading] = useState(false);

    function checkPerson() {

        if(isworkHoursControlLoading) return;
        setisworkHoursControlLoading(true);
        setTimeout(() => {setisworkHoursControlLoading(false);}, 1055) // double click prevent;

        resetworkHoursControl()
        workHoursControl({
            'OrganizationWorkHourDefinitionId': 0,
            'Name': workHoursState.Name,
            'UsersResourceId': workHoursState.persons
        })
    }
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })
    const personInfoModalContainer = (
        <div>
            <div className="black f-16 mb-20 left-text ">{t('WORKHOURS_PERSON_MODAL_DESC')}</div>
            <div>
                {workHoursControlData.map((data,i) => {
                    if (data.personCount > 0) {
                        return (
                            <Row key={i+'row'} justify="center">
                                <Col offset={2} span={13}>
                                    <div className="left-text f-14 pad-12 black">{data.personName}+{data.personCount + ' ' + t('EMPLOYEE')},{data.oldDefinitionName}</div>
                                </Col>
                                <Col span={2}>
                                    <div><UilArrowRight /></div>
                                </Col>
                                <Col span={7}>
                                    <div className="left-text f-14 black">{data.newDefinitionName}</div>
                                </Col>

                            </Row>);
                    } else {

                    }
                })}
            </div>
        </div>
    )
    const handleCancel = () => {
        setPersonInfoModal({ 'isVisible': false })
        resetworkHoursControl()
    }
    const handleConfirm = () => {
        setPersonInfoModal({ 'isVisible': false })
        handleCreateOrganizationWorkHour();
        resetworkHoursControl()
    }
    return (
        <div className="work-hours-drawer">
            
            {confirmModalData?.isVisible && (
                <DynamicRequestModal
                    key={'create-work-hours-confirm-modal'}
                    modalVisible={confirmModalData?.isVisible}
                    setStateData={setConfirmModalData}
                    postAction={destroyLastState}
                    confirmData={null}
                    iconName='UilQuestionCircle'
                    modalTitle={null}
                    description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_CANCEL')}
                />
            )}
            {warningModal?.isVisible && (
                <DynamicRequestModal
                    key={'create-work-hours-warning-modal'}
                    modalVisible={warningModal?.isVisible}
                    setStateData={setWarningModal}
                    postAction={warningModal.action}
                    confirmData={warningModal?.data}
                    iconName='UilQuestionCircle'
                    modalTitle={t('WARNING')}
                    description={t('WORKING_HOURS_AFFECTED_MESSAGE')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_SAVE')}
                />
            )}
            {informationModal?.isVisible && (
                <DynamicRequestModal
                    key={'create-work-hours-information-modal'}
                    modalVisible={informationModal?.isVisible}
                    setStateData={setInformationModal}
                    postAction={updateOrganizationWorkHours}
                    confirmData={informationModal?.data}
                    iconName='UilQuestionCircle'
                    modalTitle={t('WARNING')}
                    description={t('WORK_HOURS_WARNING_MESSAGE')}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('YES')}
                />
            )}
            {personInfoModal?.isVisible && (
                <DynamicRequestModal
                    key={'create-work-hours-person-info-modal'}
                    modalVisible={personInfoModal?.isVisible}
                    setStateData={setPersonInfoModal}
                    handleConfirm={handleConfirm}
                    handleCancel={handleCancel}
                    confirmData={null}
                    isForm={personInfoModalContainer}
                    modalWidth={600}
                    iconName=''
                    modalTitle={null}
                    description={null}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('YES')}
                />
            )}
            <div className="add-global-close-icon">
                <UilMultiply onClick={() => closeControl()} />
            </div>
            {currentTab !== 2 ?
                <React.Fragment>
                    <div className="add-global-draw-title flex-start">
                        <h1>{t('CREATE_NEW_WORKING_HOUR')}</h1>
                    </div>
                    <div className="setting-work-hours-container">
                        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                            <Form
                                form={form}
                                onValuesChange={handleValuesChange}
                                layout="vertical"
                                requiredMark={true}
                            >
                                <Form.Item className="w-100" name="Name" label={t('WORKING_HOUR_NAME') + " *"} required>
                                    <Input />
                                </Form.Item>
                                <div>
                                    <div className='setting-work-hours-title'>
                                        <h1 className='setting-work-hours'>{t('WORK_HOURS')}</h1>
                                    </div>
                                    <div className='column-left'>
                                        <div className='setting-work-hours-item setting-work-hours-title pad-0 '>
                                            <h1 className='setting-work-hours-day'>{t('PARTNER_DAY')}</h1>
                                            <h1 className='setting-work-hours-start'>{t('START')}</h1>
                                            <h1>{t('END')}</h1>
                                        </div>

                                        {
                                            workHoursState?.organizationWorkHours?.map((data, index) => {
                                                return (
                                                    <div key={index} className='setting-work-hours-item'>
                                                        <Form.Item name={"isActive_" + index} className="pad-0 mar-0" valuePropName="checked">
                                                            <Switch
                                                                checkedChildren={renderDayName(data.workDay)}
                                                                unCheckedChildren={renderDayName(data.workDay)}
                                                            />
                                                        </Form.Item>
                                                        {workHoursState?.organizationWorkHours[index]?.isActive ?
                                                            <React.Fragment>

                                                                <Form.Item name={"startTime_" + index} className="pad-0 mar-0">
                                                                    <TimePicker
                                                                        popupClassName='setting-work-hours-dropdown'
                                                                        locale={{
                                                                            ...locale,
                                                                            lang: {
                                                                                ...locale.lang,
                                                                                ok: t('PARTNER_SAVE'),
                                                                            }
                                                                        }}
                                                                        format={dateTimeFormat}
                                                                        inputReadOnly={true}
                                                                        showNow={false}
                                                                        placeholder=""
                                                                        suffixIcon={<DownOutlined />}
                                                                    />
                                                                </Form.Item>
                                                                <div className='setting-work-hours-right-icon'>
                                                                    <UilArrowRight />
                                                                </div>
                                                                <Form.Item name={"endTime_" + index} className="pad-0 mar-0">
                                                                    <TimePicker
                                                                        popupClassName='setting-work-hours-dropdown'
                                                                        locale={{
                                                                            ...locale,
                                                                            lang: {
                                                                                ...locale.lang,
                                                                                ok: t('PARTNER_SAVE'),
                                                                            }
                                                                        }}
                                                                        format={dateTimeFormat}
                                                                        inputReadOnly={true}
                                                                        showNow={false}
                                                                        suffixIcon={<DownOutlined />}
                                                                        placeholder=""
                                                                    />
                                                                </Form.Item>
                                                            </React.Fragment>
                                                            : <div style={{ height: '40px', paddingBottom: '20px' }} />}
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div className="column-right" >
                                    <div className='setting-work-hours-title'>
                                        <h1 >{t('LUNCH_BREAK_HOURS')}</h1>
                                    </div>
                                    <div className='setting-work-hours-item setting-work-hours-title pad-0'>
                                        <h1 className='setting-work-hours-start'>{t('START')}</h1>
                                        <h1>{t('END')}</h1>
                                    </div>
                                    {workHoursState?.organizationWorkHours?.map((data, index) => {
                                        return (
                                            <div key={index+'setting-work-hours-item'} className='setting-work-hours-item'>
                                                {workHoursState?.organizationWorkHours[index]?.isActive ?
                                                    <React.Fragment>
                                                        <Form.Item name={"lunchBreakStartTime_" + index} className="pad-0 mar-0">
                                                            <TimePicker
                                                                popupClassName='setting-work-hours-dropdown'
                                                                locale={{
                                                                    ...locale,
                                                                    lang: {
                                                                        ...locale.lang,
                                                                        ok: t('PARTNER_SAVE'),
                                                                    }
                                                                }}
                                                                format={dateTimeFormat}
                                                                inputReadOnly={true}
                                                                showNow={false}
                                                                suffixIcon={<DownOutlined />}
                                                                placeholder=""
                                                            />
                                                        </Form.Item>

                                                        <div className='setting-work-hours-right-icon'>
                                                            <UilArrowRight />
                                                        </div>
                                                        <Form.Item name={"lunchBreakEndTime_" + index} className="pad-0 mar-0">
                                                            <TimePicker
                                                                popupClassName='setting-work-hours-dropdown'
                                                                locale={{
                                                                    ...locale,
                                                                    lang: {
                                                                        ...locale.lang,
                                                                        ok: t('PARTNER_SAVE'),
                                                                    }
                                                                }}
                                                                format={dateTimeFormat}
                                                                inputReadOnly={true}
                                                                showNow={false}
                                                                suffixIcon={<DownOutlined />}
                                                                placeholder=""
                                                            />
                                                        </Form.Item>


                                                        {showApplyToAll(index, workHoursState[0]?.startTime, workHoursState[0]?.endTime)}

                                                    </React.Fragment>
                                                    : <div style={{ height: '40px', paddingBottom: '20px' }} />}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="work-hours-checkbox">
                                    <Form.Item className="margin-b-0" name="OnlyFridayRequestIncludeSaturday" valuePropName="checked">
                                        <Checkbox>{t('COUNT_THE_SATURDAY_FOR_THE_HOLIDAYS_TAKEN_ONLY_ON_FRIDAY')}</Checkbox>
                                    </Form.Item>
                                    <Form.Item className="margin-b-0" name="FridayRequestIncludeSaturday" valuePropName="checked">
                                        <Checkbox>{t('COUNT_SATURDAY_IN_WEEKLY_LEAVE')}</Checkbox>
                                    </Form.Item>
                                    <Form.Item name="AllWeekRequestIncludeSaturday" valuePropName="checked">
                                        <Checkbox>{t('COUNT_SATURDAY_FOR_WEEKLY_TIME_OFF')}</Checkbox>
                                    </Form.Item>

                                    <Form.Item className="margin-b-0" name="IsDefault" valuePropName="checked">
                                        <Checkbox >{t('DEFAULT_TIME')}</Checkbox>
                                    </Form.Item>
                                </div>
                                <Form.Item className="w-100 custom-select" name="persons" label={t('SETTINGS_SELECT_USER')} >
                                    <TreeSelect
                                        disabled={workHoursState?.IsDefault}
                                        treeData={personTreeData}
                                        treeCheckable={true}
                                        showCheckedStrategy={SHOW_CHILD}
                                        placeholder={t('PARTNER_SELECT')}
                                        filterTreeNode={(search, item) => customFilter(search, item)}
                                    />
                                </Form.Item>
                            </Form>
                        </ConfigProvider>
                    </div>
                </React.Fragment>
                :
                <Successful message={t('WORK_HOURS_COMPLETE_MESSAGE')} />
            }
            <div className="add-user-draw-footer">
                {currentTab < 2 ?
                    <Button onClick={() => closeControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                <div className="add-user-draw-footer-end">
                    {currentTab === 1 &&
                        <Button onClick={checkPerson} className="pad-0 w-180 h-40 f-14 font-w-700 primary-btn">{t('CREATE_NEW_WORKING_HOUR')}</Button>
                    }
                    <div className="pad-left-24" />
                    {currentTab === 2 &&
                        <React.Fragment>
                            <Button onClick={() => createNewOne()} className="w-180 h-40 f-14 font-w-700 primary-btn margin-r-12">{t('CREATE_NEW_WORKING_HOUR')}</Button>
                            <Button onClick={() => destroyLastState()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        updateOrganizationWorkHoursResponse: state.organization.updateOrganizationWorkHoursResponse,
        workHourDepartmentInPerson: state.organization.workHourDepartmentInPerson,
        workHourDepartmentInPersonLoading: state.organization.workHourDepartmentInPersonLoading,
        profile: state.profile,
        workHourDetailsByDefinitions: state.organization.workHourDetailsByDefinitions,
        workHoursControlData: state.organization.workHoursControlData,
        workHoursControlLoading: state.organization.workHoursControlLoading,
        workHoursModal: state.organization.workHoursModal,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getWorkHourDepartmentInPersons: () => { dispatch(getWorkHourDepartmentInPersons()) },
        updateOrganizationWorkHours: (updateData) => { dispatch(updateOrganizationWorkHours(updateData)) },
        resetUpdateOrganizationWorkHours: () => { dispatch(resetUpdateOrganizationWorkHours()) },
        setOrganizationWorkHoursCreateDrawer: (status) => { dispatch(setOrganizationWorkHoursCreateDrawer(status)) },
        workHoursControl: (data) => { dispatch(workHoursControl(data)) },
        resetworkHoursControl: () => { dispatch(resetworkHoursControl()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewWorkHours);
