import { Layout, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';


import BreadCrump from '../../features/breadcrump/breadcrump';
import SafeBtn from '../../features/helper/safe-btn';

import 'antd/dist/antd.css';
import '../../styles/contact.scss'

const { Content } = Layout;


function Contact() {

    const { t } = useTranslation();

    return (


        <Layout>
            <Col span={24}>
                <Content className="page-container">
                    <Row>
                        <Col span={6}>
                            <BreadCrump />
                        </Col>
                        <Col span={18}>
                            <SafeBtn />
                        </Col>
                    </Row>
                    <h1 className="page-title-1">{t('PARTNER_CONTACT')}</h1>
                    <Row>


                        <Col span={13}>

                            <div className="pad-top-20 contact-page">
                                <div className="contact-text">
                                    <h1>{t('PARTNER_JOB')} : </h1>
                                    <p>Ofix Ofis Malzemeleri A.Ş. </p>
                                </div>

                                <div className="contact-text">
                                    <h1>{t('PARTNER_TRADE_REGISTER_NO')} : </h1>
                                    <p>770869</p>
                                </div>

                                <div className="contact-text">
                                    <h1>{t('PARTNER_ADDRESS')} : </h1>
                                    <p>İkitelli Organize Sanayi Bölgesi Mahallesi Atatürk Bulvarı
                                        No: 42/F Başak Park AVM Kat: 2
                                        Başakşehir / İstanbul</p>
                                </div>

                                <div className="contact-text">
                                    <h1>{t('ORGANIZATION_PHONE')} : </h1>
                                    <p>0850 288 63 49 (Çağrı Merkezi)</p>
                                </div>

                                <div className="contact-text">
                                    <h1>{t('ORGANIZATION_MAIL')} : </h1>
                                    <p>info@ofix.com</p>
                                </div>

                                <div className="contact-text">
                                    <h1>Fax : </h1>
                                    <p>0212 258 41 08</p>
                                </div></div>
                        </Col>
                        <Col span={1}>
                            <div className="pad-top-40" />
                            <div className="contact-hr-class">
                                <div className="contact-hr"></div>
                            </div>
                        </Col>
                        <Col span={10}>
                            <div className="pad-top-20 flex-end contact-page">

                                <div className="contact-text">
                                    <h1>Kroki : </h1>

                                    <iframe title="Contact Page" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17018.719670411294!2d28.816049077668445!3d41.05777736709871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa582c852a137%3A0x7b0a7888787497c9!2sOfix.com!5e0!3m2!1str!2str!4v1625744968653!5m2!1str!2str" width={612} height={199} style={{ border: 0 }} allowfullscreen={false} loading="lazy" />
                                </div>
                            </div>
                        </Col>

                    </Row>



                </Content >
            </Col>
        </Layout>




    )

}
export default Contact;
