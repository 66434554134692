import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Button, Switch, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { getHoliday } from "../../redux/actions/settings-page.action";
import { PlusOutlined } from '@ant-design/icons';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { hrApi } from '../../utils/apiConfig';
import { apiUrl } from '../../utils/config';

import '../../styles/setting.scss';
import OrganizationHolidayModal from '../OrganizationCulture/OrganizationHolidayModal';

async function changeStatus(body) {
    const config = {
        method: "post",
        url: `${apiUrl}/holidays/changeStatus`,
        data: body,
    };
    const { data } = await hrApi(config);
    return data;
}

function HolidayTab(props) {
    const { t } = useTranslation();
    const { holidaysData, holidaysLoading, getHoliday } = props;

    const [isAddHolidayModalOpen, setisAddHolidayModalOpen] = useState(false);
    const [statusMap, setStatusMap] = useState(
        holidaysData.reduce((acc, holiday) => {
            acc[holiday.id] = holiday.isActive;
            return acc;
        }, {})
    );

    const mutation = useMutation(changeStatus, {
        onSuccess: (data) => {
            if(data?.status == "SUCCESS" && data?.message){
                DynamicToastMessage({
                    'key': 'success_key',
                    'title': t('SUCCESS'),
                    'description': data.message,
                    'statusType': 'success',
                    'position': 'bottom-right'
                });

            }
        },
        onError: (error) => {
            if(error?.response?.data?.status == "FAILED" && error?.response?.data?.message){
                DynamicToastMessage({
                    key: 'error_key',
                    title: t('ERROR'),
                    description: error?.response?.data?.message,
                    statusType: 'error',
                    position: 'bottom-right'
                });
            }else{
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': t('EDUCATION_ERROR_OCCURRED'),
                    'statusType': 'error',
                    'position': 'bottom-right'
                });
            }

        },
    });

    const statusChanged = (data, status) => {
        setStatusMap(prev => ({ ...prev, [data.id]: status }));
        mutation.mutate(
            { id: data.id, status: status },
            {
                onError: () => {
                    setStatusMap(prev => ({ ...prev, [data.id]: !status }));
                },
            }
        );
    }

    useEffect(() => {
        setStatusMap(
            holidaysData.reduce((acc, holiday) => {
                acc[holiday.id] = holiday.isActive;
                return acc;
            }, {}))
    }, [holidaysData])

    const newHolidayTrigger = () => {
        getHoliday(new Date().getFullYear());
    }
    return (
        <div className="setting-holiday-tab">
            <OrganizationHolidayModal newHolidayTrigger={newHolidayTrigger} isVisible={isAddHolidayModalOpen} onClose={() => { setisAddHolidayModalOpen(false) }} />
            <div className="w-100 flex-start">
                <Button onClick={() => { setisAddHolidayModalOpen(true) }} className="primary-btn m-w-180 h-32">
                    <PlusOutlined /> {t('CREATE_NEW_HOLIDAY')}
                </Button>
            </div>

            {holidaysData.length > 0 && !holidaysLoading ?
                <Row>
                    <div className="holiday-card-table column-padding-new-holiday mt-4">
                        <Col span={6}>
                            <h1>{t('SETTINGS_NAMED')}</h1>
                        </Col>
                        <Col span={5}>
                            <h1>{t('SETTINGS_START_DATE')}</h1>
                        </Col>
                        <Col span={5}>
                            <h1>{t('SETTINGS_END_DATE')}</h1>
                        </Col>
                        <Col span={5}>
                            <h1>{t('SETTINGS_TIME')}</h1>
                        </Col>
                        <Col span={3} className="holiday-text-center">
                            <h1>{t('TIMEOFF_TYPE_STATUS')}</h1>
                        </Col>
                    </div>
                </Row> : ''}

            {!holidaysLoading ?
                <div className="holiday-card-table">
                    {holidaysData.map((data, index) => {
                        const isLastItem = index === holidaysData.length - 1;
                        return (
                            <Card className={isLastItem ? "card-body" : "card-body-new-holiday"} key={index}>
                                <Row>
                                    <Col span={6}>
                                        <h1>{data.name}</h1>
                                    </Col>
                                    <Col span={5}>
                                        <h1 className="date">{data.startDate}</h1>
                                    </Col>
                                    <Col span={5}>
                                        <h1 className="date">{data.endDate}</h1>
                                    </Col>
                                    <Col span={5}>
                                        <h1 className="date">{data.day}</h1>
                                    </Col>
                                    <Col span={3} className="holiday-text-center">
                                        <Switch
                                            onChange={(status) => statusChanged(data, status)}
                                            checked={statusMap[data.id]}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        );
                    })}
                </div> : <Spin className="flex-center  pad-top-40" />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        holidaysData: state.settingsPage.holidays,
        holidaysLoading: state.settingsPage.holidaysLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHoliday: (props) => { dispatch(getHoliday(props)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayTab);
