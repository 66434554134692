import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { UilArrowCircleRight } from "@iconscout/react-unicons";
import { Popover } from "antd";
import Can from '../authorization/index';
import Cannot from '../authorization/cannot';

import { setCurrentTaskState, setUserTaskInfo } from "../redux/actions/onboarding.actions";
import PopoverContent from '../component/onBoarding/PopoverContent';
import OnboardingBackdropHook from '../component/onBoarding/OnboardingBackdropHook';

import "../styles/global.scss";
import "../styles/subscription-warning-bar.scss";

function SubscriptionWarningBar(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const { accountExpireDate } = props;

    const [remainingDay, setRemainingDay] = useState()

    useEffect(() => {

        const trialExpireDate = new Date(accountExpireDate);
        const today = new Date();
        const diffDate = trialExpireDate - today;
        const remainingDay = Math.ceil(diffDate / 86400000);

        setRemainingDay(remainingDay)
    }, [accountExpireDate])


        // #region OnBoarding
        const [popoverVisible, setPopoverVisible] = useState(false);
        const hide = () => { setPopoverVisible(false); 
            if(props.currentTaskState !== 111){ 
                setTimeout(() => { props.setCurrentTaskState(null);}, 100);
            }

        };
        const handlePopoverVisibleChange = (visible) => {
            if(visible == false && props.currentTaskState !== 111){  
                setPopoverVisible(visible);
                setTimeout(() => { props.setCurrentTaskState(null);}, 100);
             }
            setPopoverVisible(visible); 
        };
        
        OnboardingBackdropHook(popoverVisible, setPopoverVisible, 'subscription-warning-bar' );
    
        useEffect(() => { 
            if(props.currentTaskState == 110){
                setPopoverVisible(true)
            }
            if(props.currentTaskState == 111){
                history.push("/prices");
                setTimeout(() => { props.setCurrentTaskState(112);}, 100);
            }
        },[props.currentTaskState]); 
        
        // #endregion

        
    return (
        <div className="subscription-warning">
            <Can I="payment">{t('TRIAL_EXPIRED_AUTHORIZED_WARNING',{"remainingDay":remainingDay})}
            {
                popoverVisible && props.currentTaskState == 110 && props.currentTaskState !== null ? 
                <Popover
                        key={'SubscriptionWarningBar'}
                        overlayClassName={props.currentTaskState == null ? "onboard-popover-wrapper" : "onboard-nav-popover-wrapper"}
                        content={<PopoverContent type={props.currentTaskState} onClose={hide} OnchangeTaskState={(state) => props.setCurrentTaskState(state)}/>}
                        title={false}
                        trigger="click"
                        visible={popoverVisible && props.currentTaskState == 110 && props.currentTaskState !== null}
                        onVisibleChange={handlePopoverVisibleChange}
                        placement="bottom"
                    >
                        <Link to="/prices" 
                            className={props.currentTaskState == 110 && props.currentTaskState !== null ? "go-prices-btn onboarding-button-border" : 'go-prices-btn'}> 
                            {t('VIEW_PACKAGES_BTN')} <UilArrowCircleRight /></Link>
                    </Popover> :
                    <Link to="/prices" 
                    className={props.currentTaskState == 110 && props.currentTaskState !== null ? "go-prices-btn onboarding-button-border" : 'go-prices-btn'}> 
                    {t('VIEW_PACKAGES_BTN')} <UilArrowCircleRight /></Link>
            }
                    
            </Can>
            <Cannot I="payment">
                {t('TRIAL_EXPIRED_UNAUTHORIZED_WARNING',{"remainingDay":remainingDay})}
            </Cannot>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        accountExpireDate: state.auth?.user?.profile?.organizationEntryEndDate,
        userTaskInfo: state.onboarding.userTaskInfo,
        currentTaskState: state.onboarding.currentTaskState,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setUserTaskInfo : (object) => {dispatch(setUserTaskInfo(object))},
        setCurrentTaskState : (taskState) => {dispatch(setCurrentTaskState(taskState))},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionWarningBar);