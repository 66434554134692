import React, { useState, useEffect } from 'react';
import {
    Button, Col, Row, ConfigProvider,
    Form, DatePicker, List
} from 'antd';
import MaskedInput from 'react-text-mask'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

//STATE
import { setSalaryAddOpenDraw, setSalaryDetailAddTabStatus } from '../../redux/actions/person-detail-tab-action'; // edit-draw -- save-draw
import { getDataset } from '../../redux/actions/dataset-action'; //para birimi listeler
import { createSalaryHistory, getSalaryHistory, createSalaryHistoryResponseReset } from '../../redux/actions/person.actions'
//SELECT
import DynamicSelectbox from '../../component/dynamicSelectbox/index';


import { UilArrowLeft } from '@iconscout/react-unicons';
import { CloseOutlined } from '@ant-design/icons';

import { price, focusDateInput, blurDateInput } from '../../component/../utils/commonFormFunctions';
import { dateFormat, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../utils/config';

import moment from 'moment';

import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index'

function AddSalaryInformation(props) {

    const { t } = useTranslation();
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const [form] = Form.useForm();

    const { getDataset, currencyTypes, createSalaryHistory, person, salaryReasonTypes, createSalaryHistoryResponse, getSalaryHistory, setSalaryAddOpenDraw, createSalaryHistoryResponseReset } = props;
    const [currenyList, setCurrencyList] = useState([{ "id": "1", "displayValue": "Türk Lirası" }])
    const [salaryState, setSalaryState] = useState(null)
    const [salaryChangeReasonTypeList, setSalaryChangeReasonTypeList] = useState([])
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "StartDate": dateFormatList,
    })

    const closeSalary = () => {
        props.setSalaryAddOpenDraw(false);
        props.setSalaryDetailAddTabStatus(false);
        form.setFieldsValue({
            "Salary": null,
            "SalaryCurrencyId": null,
            "AdditionalPaymentAmount": null,
            "AdditionalPaymentAmountCurrencyId": null,
            "SalaryChangeReasonTypeId": null,
            "StartDate": null
        })
        setSalaryState(null)

    }
    function onChange() {
        props.setSalaryDetailAddTabStatus(true);
    }


    const handleValuesChange = (changedObj, all) => {

        const key = Object.keys(changedObj)[0]
        if (key === 'SalaryCurrencyId') {
            setSalaryState({ ...salaryState, "SalaryCurrencyId": changedObj["SalaryCurrencyId"]["id"] });
        } else if (key === 'AdditionalPaymentAmountCurrencyId') {
            setSalaryState({ ...salaryState, "AdditionalPaymentAmountCurrencyId": changedObj["AdditionalPaymentAmountCurrencyId"]["id"] });
        } else if (key === 'SalaryChangeReasonTypeId') {
            setSalaryState({ ...salaryState, "SalaryChangeReasonTypeId": changedObj["SalaryChangeReasonTypeId"]["id"] });
        } else if (key === 'StartDate') {
            setSalaryState({ ...salaryState, "StartDate": moment(changedObj.StartDate).format(dateFormat) });
        } else {
            setSalaryState({ ...salaryState, ...changedObj });
        }

    }
    const handleCreateSalaryHistory = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        const createObj = {
            "Salary": salaryState?.Salary,
            "SalaryCurrencyId": salaryState?.SalaryCurrencyId,
            "AdditionalPaymentAmount": salaryState?.AdditionalPaymentAmount,
            "AdditionalPaymentAmountCurrencyId": salaryState?.AdditionalPaymentAmountCurrencyId,
            "SalaryChangeReasonTypeId": salaryState?.SalaryChangeReasonTypeId,
            "StartDate": salaryState?.StartDate && salaryState?.StartDate !== 'Invalid date' ? moment(salaryState?.StartDate).format(dateFormat) : null,
        }

        let hasError = false
        let fields = []

        if (!createObj?.Salary || createObj?.Salary === null) {
            hasError = true
            fields.push('( ' + t('PARTNER_SALARY_TOTAL') + ' )' + ' bir değer giriniz')
        }
        if (!createObj?.SalaryCurrencyId || createObj?.SalaryCurrencyId === null) {
            hasError = true
            fields.push('( ' + t('PARTNER_SALARY_CURRENCY') + ' )' + ' seçiniz')
        }
        if (!createObj?.StartDate || createObj?.StartDate === null) {
            hasError = true
            fields.push('( ' + t('PARTNER_DATE_OF_VALIDITY') + ' )' + ' seçiniz')
        }
        if (createObj?.AdditionalPaymentAmountCurrencyId && !createObj?.AdditionalPaymentAmount) {
            hasError = true
            fields.push('( ' + t('PARTNER_ADDITIONAL_PAYMENT') + ' )' + '  bir değer giriniz')
        }

        if (createObj?.AdditionalPaymentAmount && !createObj?.AdditionalPaymentAmountCurrencyId) {
            hasError = true
            fields.push('( ' + t('PARTNER_ADDITIONAL_CURRENCY') + ' )' + '  seçiniz')
        }

        if (!hasError) {
            const createData = { "data": createObj, "resourceId": person.resourceId }
            createSalaryHistory(createData)
        } else {
            DynamicToastMessage({
                'key': 'salary_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    useEffect(() => {
        setCurrencyList(currencyTypes)
    }, [currencyTypes])

    useEffect(() => {
        setSalaryChangeReasonTypeList(salaryReasonTypes)
    }, [salaryReasonTypes])

    useEffect(() => {



        if (createSalaryHistoryResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'salary_add_success',
                'title': t('SUCCESS'),
                'description': createSalaryHistoryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getSalaryHistory(person.resourceId);
            createSalaryHistoryResponseReset()
            props.setSalaryAddOpenDraw(false);
            props.setSalaryDetailAddTabStatus(false);
            form.setFieldsValue({
                "Salary": null,
                "SalaryCurrencyId": null,
                "AdditionalPaymentAmount": null,
                "AdditionalPaymentAmountCurrencyId": null,
                "SalaryChangeReasonTypeId": null,
                "StartDate": null
            })
            setSalaryState(null)
        } else if (createSalaryHistoryResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'assignment_detail_fail',
                'title': t('ERROR'),
                'description': createSalaryHistoryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createSalaryHistoryResponseReset()
        }


    }, [createSalaryHistoryResponse])
    useEffect(() => {

    }, [form])
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })

    function closeDrawer() {
        if (salaryState?.Salary || salaryState?.SalaryChangeReasonTypeId || salaryState?.AdditionalPaymentAmount || salaryState?.SalaryCurrencyId || (salaryState?.StartDate && salaryState?.StartDate !== 'Invalid date') || salaryState?.AdditionalPaymentAmountCurrencyId) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeSalary()
        }
    }
    return (
        <React.Fragment>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeSalary}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details detail-scrollable h-70vh">
                <Col span={24}>
                    <Row>
                        <Col span={18}>
                            <div className="back-drawer">
                                <Button className="transparent-btn" onClick={() => closeDrawer()} icon={<UilArrowLeft />}>{t('PARTNER_RETURN_SALARY_INFORMATION')}</Button>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="close-icon">
                                <CloseOutlined onClick={() => closeDrawer()} />
                            </div>
                        </Col>

                    </Row>

                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={handleValuesChange}
                            requiredMark={false}
                            className="editDraw"
                            initialValue={{ "CurrencyId": { "id": "1", "displayValue": "Türk Lirası" } }}
                        >
                            <Col span={24}>
                                <div className="pad-25" />
                                <Row gutter={[32, 0]}>
                                    <Col span={12}>
                                        <div className="form-left-body">

                                            <Form.Item name="Salary" label={t('PARTNER_SALARY_TOTAL') + ' *'} required >
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={price}
                                                />
                                            </Form.Item>
                                            <Form.Item name="SalaryChangeReasonTypeId" className="custom-select" label={t('CHANGE_REASON')}>
                                                <DynamicSelectbox
                                                    optionList={salaryChangeReasonTypeList}
                                                    onFocus={() => getDataset(27)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                />
                                            </Form.Item>
                                            <Form.Item name="AdditionalPaymentAmount" label={salaryState?.AdditionalPaymentAmountCurrencyId ? t('PARTNER_ADDITIONAL_PAYMENT') + ' *' : t('PARTNER_ADDITIONAL_PAYMENT')} required >
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={price}
                                                />
                                            </Form.Item>

                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="form-right-drawer-body">
                                            <Form.Item name="SalaryCurrencyId" className="custom-select" label={t('PARTNER_SALARY_CURRENCY') + ' *'} required >
                                                <DynamicSelectbox
                                                    onChange={onChange}
                                                    optionList={currenyList}
                                                    onFocus={() => getDataset(23)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            <Form.Item name="StartDate" label={datePickerFormatType.StartDate === dynamicDateInputFormatter ? t('PARTNER_DATE_OF_VALIDITY') + " * " + t('PLACEHOLDER_DATE') : t('PARTNER_DATE_OF_VALIDITY') + " *"} required >
                                                <DatePicker
                                                    onChange={onChange}
                                                    locale={localeLang}
                                                    format={datePickerFormatType.StartDate}
                                                    placeholder={t('PLACEHOLDER_DATE')}
                                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'StartDate', dynamicDateInputFormatter)}
                                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'StartDate', dateFormatList)}

                                                />
                                            </Form.Item>
                                            <Form.Item name="AdditionalPaymentAmountCurrencyId" className="custom-select" label={salaryState?.AdditionalPaymentAmount ? t('PARTNER_ADDITIONAL_CURRENCY') + ' *' : t('PARTNER_ADDITIONAL_CURRENCY')} required >
                                                <DynamicSelectbox
                                                    onChange={onChange}
                                                    optionList={currenyList}
                                                    onFocus={() => getDataset(23)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>

                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                            {
                                <div className="fixed-btn">
                                    <div className="page-end">
                                        <div className="hr-end"></div>
                                    </div>

                                    <div className="form-submit">
                                        <Button onClick={() => closeDrawer()} className="white-btn w-180 h-40 f-14">{t('INVENTORY_CANCEL')}</Button>

                                        <div className="btn-padding" />
                                        <Button onClick={handleCreateSalaryHistory} className="primary-btn w-180 h-40 f-14">{t('QUICK_ACTION_SAVE')}</Button>
                                        <div className="btn-padding-end" />

                                    </div>




                                </div>
                            }

                        </Form >
                    </ConfigProvider>
                </Col>
            </div >
        </React.Fragment>


    )
}
const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        currencyTypes: state.dataset.currencyTypes,
        salaryReasonTypes: state.dataset.salaryReasonTypes,
        createSalaryHistoryResponse: state.person.createSalaryHistoryResponse

    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setSalaryAddOpenDraw: (props) => {
            dispatch(setSalaryAddOpenDraw(props));
        },
        setSalaryDetailAddTabStatus: (props) => {
            dispatch(setSalaryDetailAddTabStatus(props));
        },
        getDataset: (id) => {
            dispatch(getDataset(id));
        },
        createSalaryHistory: (id) => {
            dispatch(createSalaryHistory(id));
        },
        getSalaryHistory: (id) => {
            dispatch(getSalaryHistory(id));
        },
        createSalaryHistoryResponseReset: () => { dispatch(createSalaryHistoryResponseReset()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSalaryInformation);


