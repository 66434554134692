import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { getMyTravelHostList, getTravelHostDetails, travelHostChangeStatus, getTravelHostDetailStepList } from '../../redux/actions/travelHost.actions';
import { Button, Dropdown } from 'antd';
import { UilPlus } from '@iconscout/react-unicons';
import { MoreOutlined } from '@ant-design/icons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicRequestModal from '../dynamicModal/requestModal';
import { useWindowDimensions, dateFormatConvert } from '../../utils/config';
import { myTravelHostDropdown } from '../../features/global-dropdown-menu/index';

function MyTravelHostRequests(props) {
    const { t } = useTranslation();
    const isShowPassives = false;
    const { width } = useWindowDimensions();

    const { openCreateRequest, myRequestInitialSearch, myRequestSearchState, setMyRequestSearchState, openDetailsDrawer, getTravelHostDetails,
        setSelectItem, myTravelHostLoading, getMyTravelHostList, myTravelHostList, totalNumberOfRecords, createTravelHostResponse, travelHostChangeStatus,
        getTravelHostDetailStepList } = props;
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

    useEffect(() => {
        if (myRequestSearchState !== myRequestInitialSearch) {
            getMyTravelHostList(myRequestSearchState);
        }
    }, [myRequestSearchState]);

    useEffect(() => {
        if (createTravelHostResponse.status === "SUCCESS") {
            getMyTravelHostList(myRequestSearchState);
        }
    }, [createTravelHostResponse])

    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setMyRequestSearchState({ ...myRequestInitialSearch });
    }
    const handleTableFilter = (data) => {
        setMyRequestSearchState({ ...myRequestSearchState, "stateTypes": data.stateType })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...myRequestSearchState.pagination, "pageNumber": 1 }
        setMyRequestSearchState({ ...myRequestSearchState, "SearchTerm": e, "pagination": pagingObj })
    }
    const showDetails = (id) => {
        if (id !== undefined) {
            setSelectItem({ 'resourceId': id })
            openDetailsDrawer();
            getTravelHostDetails(id);
            getTravelHostDetailStepList(id);
        }
    }
    const data = myTravelHostList.length > 0 ? myTravelHostList.map((row) => {
        return (
            {
                "key": row?.resourceId,
                "createdDate": { "text": moment(row.createdDate).format(dateFormatConvert), "id": row?.resourceId },
                "requestType": row.requestType,
                "description": row.description,
                "stateType": { "id": row.stateTypeId, "displayValue": row.stateTypeName },
            }
        );
    }) : [];
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType'] : [],
        sortableColumns: ['createdPerson', 'createdDate', 'requestType', 'stateType'],
        visibilityOptionExcludes: ['createdPerson', 'createdDate', 'requestType', 'stateType'],
        page: "MyTravelHostRequest",
        leftActionMenu: leftActionMenu(),
        showVisibilityMenu: false,
    });
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <>
                        <Button onClick={() => openCreateRequest(true)} className="flex-class svg-class primary-btn h-32 w-230" icon={<UilPlus />}>{t('HOMEPAGE_CREATE_REQUEST')}</Button>
                    </>
                }
            </div>
        )
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    });
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeId'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...myRequestSearchState.pagination, 'isChanged': true, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : myRequestSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setMyRequestSearchState({ ...myRequestSearchState, 'pagination': paginationObj })
    }
    const handleShowPassivesToogle = (e) => { }

    const canDelete = (id, count) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'count': count, 'isVisible': true })
    }
    return (
        <div className="table-container">
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={travelHostChangeStatus}
                confirmData={'delete'}
                isMultipleData={{ 'resourceId': deleteModalData.id, 'description': null }}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_TRAVEL_HOST_REQUEST')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === 1) {
                                    return <div className='yellow'>{state.displayValue}</div>
                                } else if (state.id === 2) {
                                    return <div>{state.displayValue}</div>
                                } else if (state.id === 3) {
                                    return <div className="flex-class w-100" >
                                        <span className='red  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                    </div>
                                } else if (state.id === 4) {
                                    return <div className="flex-class w-100" >
                                        <span className='red  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                    </div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('REQUEST_TYPE')}`,
                            dataIndex: 'requestType',
                            key: 'requestType',
                            sorter: true,
                            render: id => {
                                switch (id) {
                                    case 0:
                                        return t('TRANSPORTATION')
                                    case 1:
                                        return t('ACCOMMODATION')
                                    case 2:
                                        return t('TRANSPORTATION_AND_ACCOMMODATION')
                                    default:
                                        return ''
                                }
                            }
                        },
                        // {
                        //     title: `${t('PARTNER_DESCRIPTION')}`,
                        //     dataIndex: 'description',
                        //     key: 'description',
                        //     sorter: true,
                        //     render: text => {
                        //         if (text) {
                        //             if (text.length > 10) {
                        //                 return text.slice(0, 10) + '...';
                        //             } else {
                        //                 return text
                        //             }
                        //         }

                        //     }
                        // },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('TIME_OFF_REQUEST_DATE_OF_REQUEST')}`,
                                dataIndex: 'createdDate',
                                key: 'createdDate',
                                sorter: true,
                                render: (obj, state) => {
                                    return (
                                        <div className="imageNameActionColumn">
                                            <a onClick={() => showDetails(obj.id)} class="details-text mar-0" >{obj.text}</a>
                                            {state.stateType.id == "1" && <Dropdown className="action-menu" overlay={myTravelHostDropdown(t, canDelete, obj.id)} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                            </Dropdown>}
                                        </div>
                                    )
                                }
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={myTravelHostLoading}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        myTravelHostLoading: state.travelHostReducer.myTravelHostLoading,
        myTravelHostList: state.travelHostReducer.myTravelHostList.results,
        totalNumberOfRecords: state.travelHostReducer.myTravelHostList.totalNumberOfRecords,
        createTravelHostResponse: state.travelHostReducer.createTravelHostResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMyTravelHostList: (searchData) => { dispatch(getMyTravelHostList(searchData)) },
        getTravelHostDetails: (id) => { dispatch(getTravelHostDetails(id)) },
        getTravelHostDetailStepList: (id) => { dispatch(getTravelHostDetailStepList(id)) },
        travelHostChangeStatus: (data, type) => { dispatch(travelHostChangeStatus(data, type)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyTravelHostRequests);
