import { hrApi } from "../../utils/apiConfig";
import { apiUrl } from "../../utils/config";
const FileDownload = require("js-file-download");

const OwnerShip = {
  CREATE_OWNERSHIP_SUCCESS: "CREATE_OWNERSHIP_SUCCESS",
  CREATE_OWNERSHIP_FAIL: "CREATE_OWNERSHIP_FAIL",
  CREATE_OWNERSHIP_INPUT_ERROR: "CREATE_OWNERSHIP_INPUT_ERROR",
  CREATE_OWNERSHIP_RESET: "CREATE_OWNERSHIP_RESET",
  GET_OWNERSHIP_DETAILS_REQUEST: "GET_OWNERSHIP_DETAILS_REQUEST",
  GET_OWNERSHIP_DETAILS_SUCCESS: "GET_OWNERSHIP_DETAILS_SUCCESS",
  GET_OWNERSHIP_DETAILS_FAIL: "GET_OWNERSHIP_DETAILS_FAIL",
  GET_OWNERSHIP_DETAILS_RESET: "GET_OWNERSHIP_DETAILS_RESET",
  DELETE_OWNERSHIP_SUCCESS: "DELETE_OWNERSHIP_SUCCESS",
  DELETE_OWNERSHIP_FAIL: "DELETE_OWNERSHIP_FAIL",
  DELETE_OWNERSHIP_RESET: "DELETE_OWNERSHIP_RESET",
  OWNERSHIP_SEARCH_REQUEST: "OWNERSHIP_SEARCH_REQUEST",
  OWNERSHIP_SEARCH_SUCCESS: "OWNERSHIP_SEARCH_SUCCESS",
  OWNERSHIP_SEARCH_FAIL: "OWNERSHIP_SEARCH_FAIL",
  OWNERSHIP_POST_RETURN_REQUEST: "OWNERSHIP_POST_RETURN_REQUEST",
  OWNERSHIP_POST_RETURN_SUCCESS: "OWNERSHIP_POST_RETURN_SUCCESS",
  OWNERSHIP_POST_RETURN_FAIL: "OWNERSHIP_POST_RETURN_FAIL",
  DELETE_OWNERSHIP_RETURN_RESET: "DELETE_OWNERSHIP_RETURN_RESET",
  EXPORT_OWNERSHIP_FAIL: "EXPORT_OWNERSHIP_FAIL",
  EXPORT_OWNERSHIP_RESET: "EXPORT_OWNERSHIP_RESET",
  EXPORT_PERSON_OWNERSHIP_FAIL: "EXPORT_PERSON_OWNERSHIP_FAIL",
  EXPORT_PERSON_OWNERSHIP_RESET: "EXPORT_PERSON_OWNERSHIP_RESET",
  UPDATE_OWNERSHIP_SUCCESS:"UPDATE_OWNERSHIP_SUCCESS",
  UPDATE_OWNERSHIP_FAIL:"UPDATE_OWNERSHIP_FAIL",
  UPDATE_OWNERSHIP_RESET:"UPDATE_OWNERSHIP_RESET"
};
const createOwnerShip = (createOwnerShipState) => {
  return async (dispatch) => {
    try {
      var data = JSON.stringify(createOwnerShipState);
      var config = {
        method: "post",
        url: apiUrl + "/assigments",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: OwnerShip.CREATE_OWNERSHIP_SUCCESS, payload: response.data.Message });
          } else {
            dispatch({ type: OwnerShip.CREATE_OWNERSHIP_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: OwnerShip.CREATE_OWNERSHIP_INPUT_ERROR, payload: error.response.data.validationMessages });
          } else {
            dispatch({ type: OwnerShip.CREATE_OWNERSHIP_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.CREATE_OWNERSHIP_FAIL, payload: error.message });
      }
    }
  };
};
const createOwnerShipResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.CREATE_OWNERSHIP_RESET, payload: {} });
  };
};
const getOwnerShipDetails = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_REQUEST });
    try {
      var config = {
        method: "get",
        url: apiUrl + "/assigments/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = [];
            dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_SUCCESS, payload: emptyData });
          } else {
            dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_FAIL, payload: error.message });
      }
    }
  };
};
const ownerShipDetailsReset = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.GET_OWNERSHIP_DETAILS_RESET, payload: {} });
  };
};
const deleteOwnerShip = (resourceId) => {
  return async (dispatch) => {
    try {
      var config = {
        method: "delete",
        url: apiUrl + "/assigments/" + resourceId,
        headers: {},
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OwnerShip.DELETE_OWNERSHIP_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: OwnerShip.DELETE_OWNERSHIP_FAIL, payload: response.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OwnerShip.DELETE_OWNERSHIP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.DELETE_OWNERSHIP_FAIL, payload: error.message });
      }
    }
  };
};
const deleteOwnerShipResponseReset = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.DELETE_OWNERSHIP_RESET, payload: {} });
  };
};
const ownerShipPageListSearch = (searchData) => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.OWNERSHIP_SEARCH_REQUEST });
    try {
      const data = JSON.stringify(searchData);
      var config = {
        method: "post",
        url: apiUrl + "/assigments/search",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OwnerShip.OWNERSHIP_SEARCH_SUCCESS, payload: response.data.data });
          } else {
            dispatch({ type: OwnerShip.OWNERSHIP_SEARCH_FAIL, payload: response.data.Message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 404) {
            const emptyData = {
              pageNumber: 1,
              pageSize: 1,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            };
            dispatch({ type: OwnerShip.OWNERSHIP_SEARCH_SUCCESS, payload: emptyData });
          }
          dispatch({ type: OwnerShip.OWNERSHIP_SEARCH_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.OWNERSHIP_SEARCH_FAIL, payload: error.message });
      }
    }
  };
};
const ownerShipPostReturn = (postData) => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.OWNERSHIP_POST_RETURN_REQUEST });
    try {
      const data = JSON.stringify(postData);
      var config = {
        method: "post",
        url: apiUrl + "/assigments/bulk/return",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 201) {
            dispatch({ type: OwnerShip.OWNERSHIP_POST_RETURN_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: OwnerShip.OWNERSHIP_POST_RETURN_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OwnerShip.OWNERSHIP_POST_RETURN_FAIL, payload: error.response.data.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.OWNERSHIP_POST_RETURN_FAIL, payload: error.response.data.message });
      }
    }
  };
};
const ownerShipPostReturnReset = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.DELETE_OWNERSHIP_RETURN_RESET, payload: {} });
  };
};
const exportOwnerShip = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);
      var config = {
        method: "post",
        url: apiUrl + "/assigments/export",
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob",
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            const exportTypes = {
              0: "xlsx",
              1: "csv",
              2: "pdf",
            };
            FileDownload(response.data, "ownership_" + newDate + "." + exportTypes[props.exportType]);
          } else {
            dispatch({ type: OwnerShip.EXPORT_OWNERSHIP_FAIL, payload: response.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OwnerShip.EXPORT_OWNERSHIP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.EXPORT_OWNERSHIP_FAIL, payload: error.message });
      }
    }
  };
};
const exportOwnerShipReset = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.EXPORT_OWNERSHIP_RESET, payload: {} });
  };
};
const exportPersonOwnerShip = (props) => {
  let newDate = Date.now();
  return async (dispatch) => {
    try {
      var data = JSON.stringify(props);
      var config = {
        method: "post",
        url: apiUrl + "/assigments/document/exportPerson/" + props,
        headers: {
          "Content-Type": "application/json; charset=utf8",
        },
        data: data,
        responseType: "blob",
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            FileDownload(response.data, "ownership_" + newDate + ".pdf");
          } else {
            dispatch({ type: OwnerShip.EXPORT_PERSON_OWNERSHIP_FAIL, payload: response.message });
          }
        })
        .catch(function (error) {
          dispatch({ type: OwnerShip.EXPORT_PERSON_OWNERSHIP_FAIL, payload: error.message });
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.EXPORT_PERSON_OWNERSHIP_FAIL, payload: error.message });
      }
    }
  };
};
const exportPersonOwnerShipReset = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.EXPORT_PERSON_OWNERSHIP_RESET, payload: {} });
  };
};
const updateOwnerShip = (resourceId, updateData) => {
  return async (dispatch) => {
    try {
      const data = JSON.stringify(updateData);
      var config = {
        method: 'put',
        url: apiUrl + '/assigments/update/' + resourceId,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      hrApi(config)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_SUCCESS, payload: response.data.message });
          } else {
            dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_FAIL, payload: response.data.message });
          }
        })
        .catch(function (error) {
          if (error.response?.status === 422) {
            dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_FAIL, payload: error.response.data.message });
          }else if(error.response?.status === 409) {
            dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_FAIL, payload: error.response.data.message });
          } else {
            dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_FAIL, payload: error.message });
          }
        });
    } catch (error) {
      if (error.response) {
        dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_FAIL, payload: error.message });
      }
    }
  };
};
const resetUpdateOwnerShip = () => {
  return async (dispatch) => {
    dispatch({ type: OwnerShip.UPDATE_OWNERSHIP_RESET, payload: {} });
  };
}
export {
  OwnerShip,
  createOwnerShip,
  getOwnerShipDetails,
  createOwnerShipResponseReset,
  deleteOwnerShip,
  deleteOwnerShipResponseReset,
  ownerShipPageListSearch,
  ownerShipPostReturn,
  ownerShipDetailsReset,
  ownerShipPostReturnReset,
  exportOwnerShip,
  exportOwnerShipReset,
  exportPersonOwnerShip,
  exportPersonOwnerShipReset,
  updateOwnerShip,
  resetUpdateOwnerShip
};
