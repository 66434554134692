import React, { useState, useEffect } from "react";
import {
    Button, Col, Row, Checkbox,
    Form, DatePicker, List,
    Select, Input,
    ConfigProvider, AutoComplete
} from 'antd';

import MaskedInput from 'react-text-mask'

import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setAssignmentDetailAddOpenDraw, setAssignmentDetailAddTabStatus } from '../../redux/actions/person-detail-tab-action';
import { getDepartments } from '../../redux/actions/organization.actions'
import { createJobRecord, getJobHistory, createJobRecordResponseReset } from '../../redux/actions/person.actions'
import { getJobList } from '../../redux/actions/job.actions';
import { getDataset } from '../../redux/actions/dataset-action';

import moment from 'moment';
import { createInitialList, focusDateInput, blurDateInput, price } from '../../utils/commonFormFunctions'
import { dateFormat, dateFormatList, localeLang, dynamicDateInputFormatter } from '../../utils/config';

import { UilArrowLeft } from '@iconscout/react-unicons';
import { CloseOutlined } from '@ant-design/icons';

import DynamicSelectbox from "../dynamicSelectbox/index";
import DynamicRequestModal from '../dynamicModal/requestModal';
import DynamicToastMessage from '../dynamicToastMessage/index';



function AssignmentDetailsAdd(props) {

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { getDepartments, getDataset, getJobHistory, person, salaryReasonTypes, createJobRecordResponseReset, profile, remoteWorkTypes, salaryHistory } = props;
    const { departments, workTypes, workStates, workStatuses, currencyTypes, createJobRecord, createJobRecordResponse, jobList, getJobList } = props;
    const [jobListState, setJobListState] = useState([]);

    const [createJobRecordState, setCreateJobRecordState] = useState({
        "IsMaintainCurrentSalary": true
    });



    const [workTypeList, setWorkTypeList] = useState([]);
    const [workStateList, setWorkStateList] = useState([]);
    const [workStatusList, setWorkStatusList] = useState([]);
    const [departmentList, setDepartmentList] = useState([{}]);
    const [currencyList, setCurrencyList] = useState([])
    const [remoteWorkList, setRemoteWorkList] = useState(createInitialList());
    const [salaryChangeReasonTypeList, setSalaryChangeReasonTypeList] = useState([])
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "StartDate": dateFormatList,
        "ContractEndDate": dateFormatList,
        "RemoteWorkFinishDate": dateFormatList
    })


    useEffect(() => {
        form.setFieldsValue({
            "IsMaintainCurrentSalary": true
        })
    }, [form])
    useEffect(() => {
        setWorkTypeList(workTypes);
    }, [workTypes]);
    useEffect(() => {
        const tempArr = [];
        jobList.map((data) => {
            return tempArr.push({ 'value': data.name })
        })
        setJobListState(tempArr)
    }, [jobList])
    useEffect(() => {
        setWorkStateList(workStates);
    }, [workStates]);

    useEffect(() => {
        setWorkStatusList(workStatuses);
    }, [workStatuses]);


    useEffect(() => {
        setDepartmentList(departments);
    }, [departments]);

    useEffect(() => {
        setCurrencyList(currencyTypes)
    }, [currencyTypes])

    useEffect(() => {
        setRemoteWorkList(remoteWorkTypes);
    }, [remoteWorkTypes]);

    useEffect(() => {
        setSalaryChangeReasonTypeList(salaryReasonTypes)
    }, [salaryReasonTypes])

    useEffect(() => {
        if (createJobRecordResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'assignment_detail_add_success',
                'title': t('SUCCESS'),
                'description': createJobRecordResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getJobHistory(person.resourceId);
            form.setFieldsValue({
                "OrganizationResourceId": null,
                "DepartmentResourceId": null,
                "JobName": null,
                "StartDate": null,
                //"AppointmentDate": null,
                "WorkStatusId": null,
                "WorkTypeId": null,
                "WorkStateId": null,
                "ContractEndDate": null,
                "OutsourcedOrganizationName": null,
                "Salary": null,
                "SalaryCurrencyId": null,
                "AdditionalPaymentAmount": null,
                "AdditionalPaymentAmountCurrencyId": null,
                "SalaryChangeReasonTypeId": null,
                "RemoteWorkType": null,
                "RemoteWorkFinishDate": null,
                "IsMaintainCurrentSalary": true,
            })
            setCreateJobRecordState({
                "IsMaintainCurrentSalary": true
            })
            props.setAssignmentDetailAddOpenDraw(false);
            createJobRecordResponseReset()

        } else if (createJobRecordResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'assignment_detail_add_fail',
                'title': t('ERROR'),
                'description': createJobRecordResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            createJobRecordResponseReset()
        }
    }, [createJobRecordResponse])
    function closeAssignmentControl() {
        if ((createJobRecordState?.DepartmentResourceId && createJobRecordState.DepartmentResourceId.id) || createJobRecordState?.JobName || createJobRecordState?.StartDate || (createJobRecordState?.WorkTypeId && createJobRecordState.WorkTypeId.id) || (createJobRecordState?.WorkStateId && createJobRecordState.WorkStateId.id) || (createJobRecordState?.WorkStatusId && createJobRecordState.WorkStatusId.id) || (createJobRecordState?.RemoteWorkType && createJobRecordState.RemoteWorkType.id)) {
            setConfirmModalData({ 'isVisible': true })
        } else {
            closeAssignment()

        }
    }
    const closeAssignment = () => {
        form.setFieldsValue({
            "OrganizationResourceId": null,
            "DepartmentResourceId": null,
            "JobName": null,
            "StartDate": null,
            //"AppointmentDate": null,
            "WorkStatusId": null,
            "WorkTypeId": null,
            "WorkStateId": null,
            "ContractEndDate": null,
            "OutsourcedOrganizationName": null,
            "Salary": null,
            "SalaryCurrencyId": null,
            "AdditionalPaymentAmount": null,
            "AdditionalPaymentAmountCurrencyId": null,
            "SalaryChangeReasonTypeId": null,
            "RemoteWorkType": null,
            "RemoteWorkFinishDate": null,
            "IsMaintainCurrentSalary": true
        })
        setCreateJobRecordState({
            "IsMaintainCurrentSalary": true
        })
        props.setAssignmentDetailAddOpenDraw(false);
    }
    function onChange() {
        //Onchange tetiklendiğinde kaydet veya iptal butonunu göstermeyi sağlıyor.
        props.setAssignmentDetailAddTabStatus(true);

    }

    const handleCreateJobRecord = () => {


        const createObj = {
            "OrganizationResourceId": person.organization.id,
            "DepartmentResourceId": createJobRecordState.DepartmentResourceId?.id,
            "JobName": createJobRecordState.JobName,
            "StartDate": createJobRecordState.StartDate && createJobRecordState.StartDate !== 'Invalid date' ? moment(createJobRecordState.StartDate).format(dateFormat) : null,
            //  "AppointmentDate": createJobRecordState.AppointmentDate && createJobRecordState.AppointmentDate !== 'Invalid date' ? moment(createJobRecordState.AppointmentDate).format(dateFormat) : null,
            "WorkStatusId": createJobRecordState.WorkStatusId?.id,
            "WorkTypeId": createJobRecordState.WorkTypeId?.id,
            "WorkStateId": createJobRecordState.WorkStateId?.id,
            "ContractEndDate": createJobRecordState.ContractEndDate && createJobRecordState.ContractEndDate !== 'Invalid date' ? moment(createJobRecordState.ContractEndDate).format(dateFormat) : null,
            "OutsourcedOrganizationName": createJobRecordState.OutsourcedOrganizationName ? createJobRecordState.OutsourcedOrganizationName : null,
            "Salary": createJobRecordState.Salary ? createJobRecordState.Salary : 0,
            "SalaryCurrencyId": createJobRecordState.SalaryCurrencyId?.id !== undefined ? createJobRecordState.SalaryCurrencyId.id : 0,
            "AdditionalPaymentAmount": createJobRecordState.AdditionalPaymentAmount ? createJobRecordState.AdditionalPaymentAmount : 0,
            "AdditionalPaymentAmountCurrencyId": createJobRecordState.AdditionalPaymentAmountCurrencyId?.id !== undefined ? createJobRecordState.AdditionalPaymentAmountCurrencyId.id : 0,
            "SalaryChangeReasonTypeId": createJobRecordState.SalaryChangeReasonTypeId?.id,
            "RemoteWorkType": createJobRecordState.RemoteWorkType?.id,
            "RemoteWorkFinishDate": createJobRecordState.RemoteWorkFinishDate && createJobRecordState.RemoteWorkFinishDate !== 'Invalid date' ? moment(createJobRecordState.RemoteWorkFinishDate).format(dateFormat) : null,
            "IsMaintainCurrentSalary": createJobRecordState.IsMaintainCurrentSalary
        }
        let hasError = false
        let fields = []
        if (!createObj.OrganizationResourceId || createObj.OrganizationResourceId === '') {
            hasError = true
            fields.push('( ' + t('ORGANIZATIONS2') + ' )' + ' seçiniz')
        }
        if (!createObj.DepartmentResourceId || createObj.DepartmentResourceId === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_DEPARTMENT') + ' )' + ' seçiniz')
        }
        if (!createObj.JobName || createObj.JobName === '') {
            hasError = true
            fields.push('( ' + t('PARTNER_JOB2') + ' )' + ' boş bırakılamaz')
        }
        if (!createJobRecordState.IsMaintainCurrentSalary) {
            if (!createObj.Salary || createObj.Salary === '') {
                hasError = true
                fields.push('( ' + t('PARTNER_START_SALARY') + ' )' + ' boş bırakılamaz')
            }
            if (!createObj.SalaryCurrencyId || createObj.SalaryCurrencyId === '') {
                hasError = true
                fields.push('( ' + t('PARTNER_SALARY_CURRENCY') + ' )' + ' boş bırakılamaz')
            }

        }
        // if (!createObj.StartDate || createObj.StartDate === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_START_NEW_JOB_DATE2') + ' )' + ' seçiniz')
        // }
        // if (!createObj.AppointmentDate || createObj.AppointmentDate === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_APPOINTMENT_DATE') + ' )' + ' seçiniz')
        // }
        // if (!createObj.Salary || createObj.Salary === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_START_SALARY') + ' )' + ' boş bırakılamaz')
        // }
        // if (!createObj.CurrencyId || createObj.CurrencyId === '') {
        //     hasError = true
        //     fields.push('( ' + t('PARTNER_CURRENCY') + ' )' + ' seçiniz')
        // }
        if (!hasError) {
            const createData = { "data": createObj, "resourceId": person.resourceId }
            createJobRecord(createData)

        } else {
            DynamicToastMessage({
                'key': 'assignment_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }


    const handleValuesChange = (changedObj, all) => {



        const key = Object.keys(changedObj)[0]
        if (key === 'RemoteWorkFinishDate') {
            setCreateJobRecordState({ ...createJobRecordState, 'RemoteWorkFinishDate': moment(changedObj.RemoteWorkFinishDate).format(dateFormat) });
        }
        //  else if (key === 'AppointmentDate') {
        //     setCreateJobRecordState({ ...createJobRecordState, 'AppointmentDate': moment(changedObj.AppointmentDate).format(dateFormat) });
        // }
        else if (key === 'StartDate') {
            setCreateJobRecordState({ ...createJobRecordState, 'StartDate': moment(changedObj.StartDate).format(dateFormat) });
        } else if (key === 'ContractEndDate') {
            setCreateJobRecordState({ ...createJobRecordState, 'ContractEndDate': moment(changedObj.ContractEndDate).format(dateFormat) });
        } else if (key === 'RemoteWorkType') {
            if (changedObj.RemoteWorkType?.id === '1') {
                setCreateJobRecordState({ ...createJobRecordState, 'RemoteWorkType': changedObj.RemoteWorkType });
            } else {
                setCreateJobRecordState({ ...createJobRecordState, 'RemoteWorkType': changedObj.RemoteWorkType, 'RemoteWorkFinishDate': null });
                if (createJobRecordState?.RemoteWorkFinishDate !== null || createJobRecordState?.RemoteWorkFinishDate !== undefined || createJobRecordState?.RemoteWorkFinishDate !== "") {
                    const fields = form.getFieldsValue()
                    const fieldsObj = { ...fields };
                    fieldsObj["RemoteWorkFinishDate"] = '';
                    form.setFieldsValue(fieldsObj)
                }
                // form.setFieldsValue({ ...createJobRecordState, 'RemoteWorkFinishDate': null, 'RemoteWorkType': { 'id': changedObj.RemoteWorkType?.id, 'displayValue': changedObj.RemoteWorkType?.displayValue } })
            }
        } else if (key === 'WorkStatusId') {

            if (changedObj.WorkStatusId?.id === '2') {
                setCreateJobRecordState({ ...createJobRecordState, 'WorkStatusId': changedObj.WorkStatusId, "OutsourcedOrganizationName": '' });
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["OutsourcedOrganizationName"] = '';
                form.setFieldsValue(fieldsObj)
            } else if (changedObj.WorkStatusId?.id === '3') {
                setCreateJobRecordState({ ...createJobRecordState, 'WorkStatusId': changedObj.WorkStatusId, "ContractEndDate": null });
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["ContractEndDate"] = '';
                form.setFieldsValue(fieldsObj)
            } else {
                const fields = form.getFieldsValue()
                const fieldsObj = { ...fields };
                fieldsObj["ContractEndDate"] = "";
                fieldsObj["OutsourcedOrganizationName"] = '';
                form.setFieldsValue(fieldsObj)

                setCreateJobRecordState({ ...createJobRecordState, 'WorkStatusId': changedObj.WorkStatusId, "ContractEndDate": null, "OutsourcedOrganizationName": "" });
            }
        } else {
            setCreateJobRecordState({ ...createJobRecordState, ...changedObj });

        }

    }


    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false })


    return (

        <React.Fragment>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAssignment}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details detail-scrollable h-70vh">
                <Col span={24}>
                    <Row>
                        <Col span={18}>
                            <div className="back-drawer">
                                <Button className="transparent-btn" onClick={() => closeAssignmentControl()} icon={<UilArrowLeft />}>{t('PARTNER_RETURN_ASSIGNMENTS')} </Button>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="close-icon">
                                <CloseOutlined onClick={() => closeAssignmentControl()} />
                            </div>
                        </Col>

                    </Row>

                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                        <Form
                            form={form}
                            layout="vertical pad-21"
                            onValuesChange={handleValuesChange}
                            requiredMark={false}
                        >

                            <Col span={24}>


                                <div className="pad-25" />
                                <Row gutter={[32, 0]}>
                                    <Col span={12}>
                                        <div className="form-left-body">

                                            <Form.Item className="custom-select" name="DepartmentResourceId" label={t('PARTNER_DEPARTMENT') + ' *'} required >
                                                <DynamicSelectbox
                                                    optionList={departmentList}
                                                    disabled={!profile.organization?.resourceId}
                                                    onFocus={() => getDepartments(profile.organization?.resourceId)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            <Form.Item name="JobName" className="auto-complete-input" label={t('PARTNER_JOB2') + ' *'} required >
                                                <AutoComplete
                                                    options={jobListState}
                                                    onFocus={() => getJobList({
                                                        "searchTerm": "",
                                                        "pagination": {
                                                            "pageNumber": 1,
                                                            "pageSize": 100,
                                                            "orderBy": "Name",
                                                            "ascending": true
                                                        }
                                                    })}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Form.Item>
                                            {/* <Form.Item name="AppointmentDate" label={t('PARTNER_APPOINTMENT_DATE')}>
                                            <DatePicker
                                                locale={TR}
                                                format={dateFormatList}
                                                placeholder={t('PARTNER_SELECT_DATE')}
                                                inputReadOnly={true}

                                            />
                                        </Form.Item> */}
                                            <Form.Item name="StartDate" label={datePickerFormatType.StartDate === dynamicDateInputFormatter ? t('PARTNER_APPOINTMENT_DATE') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_APPOINTMENT_DATE')}>
                                                <DatePicker
                                                    locale={localeLang}
                                                    format={datePickerFormatType.StartDate}
                                                    placeholder={t('PLACEHOLDER_DATE')}
                                                    onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'StartDate', dynamicDateInputFormatter)}
                                                    onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'StartDate', dateFormatList)}
                                                />
                                            </Form.Item>
                                            <Form.Item name="WorkTypeId" className="custom-select" label={t('PARTNER_WORK_TYPE')}>
                                                <DynamicSelectbox
                                                    optionList={workTypeList}
                                                    onFocus={() => getDataset(17)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            <Form.Item name="WorkStateId" className="custom-select" label={t('PARTNER_WORK_STATE')}>
                                                <DynamicSelectbox
                                                    optionList={workStateList}
                                                    onFocus={() => getDataset(18)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>

                                        </div>

                                    </Col>

                                    <Col span={12}>
                                        <div className="form-right-drawer-body">


                                            <Form.Item name="WorkStatusId" className="custom-select" label={t('PARTNER_WORK_STATUS')}>
                                                <DynamicSelectbox
                                                    optionList={workStatusList}
                                                    onFocus={() => getDataset(16)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>

                                            {createJobRecordState.WorkStatusId?.id === '2' ?
                                                <Form.Item name="ContractEndDate" className="custom-select" label={datePickerFormatType.ContractEndDate === dynamicDateInputFormatter ? t('CONTRACT_END_DATE') + " " + t('PLACEHOLDER_DATE') : t('CONTRACT_END_DATE')}>
                                                    <DatePicker
                                                        locale={localeLang}
                                                        format={datePickerFormatType.ContractEndDate}
                                                        placeholder={t('PLACEHOLDER_DATE')}
                                                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'ContractEndDate', dynamicDateInputFormatter)}
                                                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'ContractEndDate', dateFormatList)}
                                                    />
                                                </Form.Item>
                                                : ''}

                                            {createJobRecordState.WorkStatusId?.id === '3' ?
                                                <Form.Item name="OutsourcedOrganizationName" label={t('PARTNER_OUTSOURCED')}>
                                                    <Input />
                                                </Form.Item> : ''}

                                            <Form.Item name="IsMaintainCurrentSalary" valuePropName="checked">
                                                <Checkbox >{t('PARTNER_PROTECT_SALARY_INFORMATION')}</Checkbox>
                                            </Form.Item>
                                            {salaryHistory.length === 0 ?
                                                <div className="salary-count-message"><span>{t('PARTNER_NO_SALARY_RECORD')}</span></div>
                                                : ''}


                                            {!createJobRecordState.IsMaintainCurrentSalary ?
                                                <Row gutter={[22, 0]}>
                                                    <Col span={15}>
                                                        <Form.Item className="custom-input-number" name="Salary" label={t('PARTNER_START_SALARY') + ' *'} required >
                                                            <MaskedInput
                                                                className="ant-input right-text"
                                                                mask={price}
                                                            />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={9}>
                                                        <Form.Item className="custom-select" name="SalaryCurrencyId" label="&nbsp;" required >
                                                            <DynamicSelectbox
                                                                onChange={onChange}
                                                                optionList={currencyList}
                                                                onFocus={() => getDataset(23)}
                                                                placeholder={t('PARTNER_SELECT')}
                                                                orderBy={'none'}
                                                            ></DynamicSelectbox>
                                                        </Form.Item>
                                                    </Col>
                                                </Row> : ''}

                                            {!createJobRecordState.IsMaintainCurrentSalary ?
                                                <Row gutter={[22, 0]}>
                                                    <Col span={15}>
                                                        <Form.Item className="custom-input-number" name="AdditionalPaymentAmount" label={t('PARTNER_ADDITIONAL_PAYMENT')} required >
                                                            <MaskedInput
                                                                className="ant-input right-text"
                                                                mask={price}
                                                            />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={9}>
                                                        <Form.Item className="custom-select" name="AdditionalPaymentAmountCurrencyId" label="&nbsp;" required >
                                                            <DynamicSelectbox
                                                                onChange={onChange}
                                                                optionList={currencyList}
                                                                onFocus={() => getDataset(23)}
                                                                placeholder={t('PARTNER_SELECT')}
                                                                orderBy={'none'}
                                                            ></DynamicSelectbox>
                                                        </Form.Item>
                                                    </Col>
                                                </Row> : ''}

                                            {!createJobRecordState.IsMaintainCurrentSalary ?
                                                <Form.Item name="SalaryChangeReasonTypeId" className="custom-select" label={t('CHANGE_REASON')}>
                                                    <DynamicSelectbox
                                                        optionList={salaryChangeReasonTypeList}
                                                        onFocus={() => getDataset(27)}
                                                        placeholder={t('PARTNER_SELECT')}
                                                        orderBy={'none'}
                                                    />
                                                </Form.Item> : ''}

                                            <Form.Item name="RemoteWorkType" className="custom-select" label={t('PARTNER_REMOTE_WORK_TYPE')}>
                                                <DynamicSelectbox
                                                    optionList={remoteWorkList}
                                                    onFocus={() => getDataset(10)}
                                                    placeholder={t('PARTNER_SELECT')}
                                                    orderBy={'none'}
                                                ></DynamicSelectbox>
                                            </Form.Item>
                                            {createJobRecordState.RemoteWorkType?.id === "1" ?
                                                <Form.Item name="RemoteWorkFinishDate" label={datePickerFormatType.RemoteWorkFinishDate === dynamicDateInputFormatter ? t('PARTNER_REMOTE_WORK_END') + " " + t('PLACEHOLDER_DATE') : t('PARTNER_REMOTE_WORK_END')}>
                                                    <DatePicker
                                                        locale={localeLang}
                                                        format={datePickerFormatType.RemoteWorkFinishDate}
                                                        placeholder={t('PLACEHOLDER_DATE')}
                                                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'RemoteWorkFinishDate', dynamicDateInputFormatter)}
                                                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'RemoteWorkFinishDate', dateFormatList)}
                                                    />
                                                </Form.Item>
                                                : ""}

                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            {/* {
                        props.tabreducer.assignmentHasChange === true ? ( */}
                            <div className="fixed-btn">
                                <div className="page-end">
                                    <div className="hr-end"></div>
                                </div>

                                <div className="form-submit">
                                    <Button onClick={() => closeAssignmentControl()} className="white-btn w-180 h-40 f-14">{t('INVENTORY_CANCEL')}</Button>

                                    <div className="btn-padding" />
                                    <Button onClick={handleCreateJobRecord} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                                    <div className="btn-padding-end" />

                                </div>




                            </div>
                            {/* ) : ""
                    } */}

                        </Form >
                    </ConfigProvider>
                </Col>
            </div >
        </React.Fragment>



    )
}
const mapStateToProps = function (state) {
    return {
        tabreducer: state.tabreducer,
        workTypes: state.dataset.workTypes,
        workStates: state.dataset.workStates,
        workStatuses: state.dataset.workStatuses,
        departments: state.organization.departments,
        currencyTypes: state.dataset.currencyTypes,
        createJobRecordResponse: state.person.createJobRecordResponse,
        profile: state.profile,
        remoteWorkTypes: state.dataset.remoteWorkTypes,
        salaryReasonTypes: state.dataset.salaryReasonTypes,
        jobList: state.job.jobList.results

    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setAssignmentDetailAddTabStatus: (props) => {
            dispatch(setAssignmentDetailAddTabStatus(props));
        },
        setAssignmentDetailAddOpenDraw: (props) => {
            dispatch(setAssignmentDetailAddOpenDraw(props));
        },
        getJobHistory: (personId) => { dispatch(getJobHistory(personId)) },
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) },
        getDepartments: (props) => { dispatch(getDepartments(props)) },
        createJobRecord: (props) => { dispatch(createJobRecord(props)) },
        createJobRecordResponseReset: () => { dispatch(createJobRecordResponseReset()) },
        getJobList: (searchData) => { dispatch(getJobList(searchData)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetailsAdd);


