import React, { useState } from 'react';

import { Col, Row, Modal, Button } from 'antd';
import {
    UilBan, UilTrash, UilQuestionCircle, UilUserMinus, UilInfoCircle, UilFilePlusAlt, UilTimesCircle, UilCheckCircle, UilRedo, UilCreditCard
} from '@iconscout/react-unicons'
import { useTranslation } from 'react-i18next';
import { randomNumberInRange } from '../../utils/commonFormFunctions';

//confirmData == id
//isMultipleData post işleminde 2.bir data alıyorsa kullanilıyor
const DynamicRequestModal = ({ iconColor = null , confirmData = null, isMultipleData = null, modalVisible = null, modalWidth = null, setStateData = null, postAction = null, iconName = null, modalTitle = null, description = null, cancelBtnTxt = null, successBtnTxt = null, isForm = null, handleConfirm = null, handleCancel = null, singleButton = null, hideButton = null, maskClosable = true }) => {
    const { t } = useTranslation();
    const getIcon = (iconColor) => {
        switch (iconName) {
            case 'UilBan':
                return <UilBan className="dynamic-modal-icon" style={{ width: 48, height: 48, color: iconColor }} />
            case 'UilTrash':
                return <UilTrash className="dynamic-modal-icon" style={{ width: 48, height: 48, color: iconColor }} />
            case 'UilQuestionCircle':
                return <UilQuestionCircle className="dynamic-modal-icon" style={{ width: 48, height: 48, color: iconColor }} />
            case 'UilUserMinus':
                return <UilUserMinus className="dynamic-modal-icon" style={{ width: 48, height: 48, color: iconColor }} />
            case 'UilInfoCircle':
                return <UilInfoCircle className="dynamic-modal-icon" style={{ width: 54, height: 54, color: iconColor }} />
            case 'UilFilePlusAlt':
                return <UilFilePlusAlt className="dynamic-modal-icon" style={{ width: 54, height: 54, color: iconColor }} />
            case 'UilTimesCircle':
                return <UilTimesCircle className="dynamic-modal-icon" style={{ width: 54, height: 54, color: iconColor }} />
            case 'UilCheckCircle':
                return <UilCheckCircle className="dynamic-modal-icon" style={{ width: 54, height: 54, color: iconColor }} />
            case 'UilRedo':
                return <UilRedo className="dynamic-modal-icon" style={{ width: 54, height: 54, color: iconColor }} />
            case 'UilCreditCard':
                return <UilCreditCard className="dynamic-modal-icon" style={{ width: 54, height: 54, color: iconColor }} />

            default:
                return null;
        }
    }

    const [isPostLoading, setisPostLoading] = useState(false);
    function post() {
        if(isPostLoading) return;
        setisPostLoading(true);
        setTimeout(() => {setisPostLoading(false);}, 1055) // double click prevent;

        if (isMultipleData !== null) {
            postAction(isMultipleData, confirmData)
        } else {
            postAction(confirmData)
        }
        setStateData({ "isVisible": false })
    }
    return (
        <Modal
            key={() => randomNumberInRange(1, 5000000)}
            visible={modalVisible}
            className="delete-confirm-modal"
            onCancel={() => isForm ? handleCancel() : setStateData({ "isVisible": false })}
            maskClosable={maskClosable ? true : false}
            width={modalWidth ? modalWidth : 520}
            footer={[
                <Row key={() => randomNumberInRange(1, 5000000)} gutter={24}>
                    {!hideButton && singleButton ?
                        <div className="dynamic-modal-button">
                            <Button key="submit-0" className="ant-btn primary-btn m-w-180 h-40" onClick={isForm ? () => handleConfirm() : () => setStateData({ "isVisible": false })}>
                                {successBtnTxt ? successBtnTxt : t('PARTNER_CLOSE')}
                            </Button>
                        </div>
                        :
                        !hideButton &&
                        <>
                            <Col span="12">
                                {isForm ?
                                    <Button key="back-1" className="ant-btn white-btn w-100 h-40" onClick={() => handleCancel()}>
                                        {cancelBtnTxt ? cancelBtnTxt : t('PARTNER_CANCEL')}
                                    </Button>
                                    :
                                    <Button key="back-2" className="ant-btn white-btn w-100 h-40" onClick={() => setStateData({ "isVisible": false })}>
                                        {cancelBtnTxt ? cancelBtnTxt : t('PARTNER_CANCEL')}
                                    </Button>
                                }
                            </Col>

                            <Col span="12">
                                {isForm ?
                                    <Button key="submit-1" className="ant-btn primary-btn w-100 h-40" onClick={() => handleConfirm()}>
                                        {successBtnTxt ? successBtnTxt : t('YES')}
                                    </Button>
                                    :
                                    <Button key="submit-2" className="ant-btn primary-btn w-100 h-40" onClick={() => post()}>
                                        {successBtnTxt ? successBtnTxt : t('YES')}
                                    </Button>}

                            </Col>
                        </>
                    }
                </Row>,
            ]}>
            <div >
                <div>{getIcon(iconColor)}</div>
                <div className="title">{modalTitle}</div>
                <div className="black f-16 mb-20 ">{description}</div>
                {isForm ? isForm : ''}
            </div>
        </Modal>
    )
}

export default DynamicRequestModal 