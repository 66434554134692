import { PersonActionType } from "../actions/person.actions";

const initialState = {
  createPersonResponse: { status: "loading", message: "", resourceId: "" },
  createSalaryHistoryResponse: { status: "loading", message: "" },
  createJobRecordResponse: { status: "loading", message: "" },
  updateResponse: { status: "loading", message: "" },
  deletePersonResponse: { status: "loading", message: "" },
  person: {
    resourceId: "",
    givenName: "",
    familyName: "",
    hireDate: null,
    officePhone: "",
    workPhoneExt: "",
    corporateEmail: "",
    identityNumber: "",
    remoteWorkType: {
      id: null,
      displayValue: "",
    },
    remoteWorkFinishDate: null,
    birthday: null,
    gender: {
      id: null,
      displayValue: "",
    },
    nationality: {
      id: null,
      displayValue: "",
    },
    maritalStatus: {
      id: null,
      displayValue: "",
    },
    educationDegree: {
      id: null,
      displayValue: "",
    },
    childrenCount: null,
    coupleWorkStatus: {
      id: null,
      displayValue: "",
    },
    bloodGroup: {
      id: null,
      displayValue: "",
    },
    disabilityDegree: {
      id: null,
      displayValue: "",
    },
    languages: [],
    isStudent: false,
    ibanNumber: "",
    nationalIdentityNumber: "",
    passportNumber: "",
    personalEmail: "",
    cellPhone: "",
    personalPageUrl: "",
    leaveReason: null,
    leaveDate: null,
    socialMediaAccounts: [],
    jobHistory: {
      organization: {
        id: null,
        displayValue: "",
      },
      department: {
        id: null,
        displayValue: "",
      },
      jobTitle: {
        id: null,
        displayValue: "",
      },
      manager: {
        id: null,
        displayValue: "",
      },
      workType: {
        id: null,
        displayValue: "",
      },
      workState: {
        id: null,
        displayValue: "",
      },
      workStatus: {
        id: null,
        displayValue: "",
      },
      outsourcedOrganizationName: "",
      startDate: null,
      appointmentDate: null,
    },
    address: {
      country: {
        id: "1",
        displayValue: "Türkiye",
      },
      province: {
        id: null,
        displayValue: "",
      },
      district: {
        id: null,
        displayValue: "",
      },
      addressLine: "",
    },
    salary: {
      salary: "",
      additionalPaymentAmount: "",
      currency: {
        id: null,
        displayValue: "",
      },
      startDate: null,
    },
    status: {
      id: null,
      displayValue: "",
    },
    roles: [],
  },
  personMe: {
    resourceId: "",
    givenName: "",
    familyName: "",
    firstLogin: null,
    hireDate: null,
    officePhone: "",
    workPhoneExt: "",
    corporateEmail: "",
    identityNumber: "",
    remoteWorkType: {
      id: null,
      displayValue: "",
    },
    remoteWorkFinishDate: null,
    birthday: null,
    gender: {
      id: null,
      displayValue: "",
    },
    nationality: {
      id: null,
      displayValue: "",
    },
    maritalStatus: {
      id: null,
      displayValue: "",
    },
    educationDegree: {
      id: null,
      displayValue: "",
    },
    childrenCount: null,
    coupleWorkStatus: {
      id: null,
      displayValue: "",
    },
    bloodGroup: {
      id: null,
      displayValue: "",
    },
    disabilityDegree: {
      id: null,
      displayValue: "",
    },
    languages: [],
    isStudent: false,
    ibanNumber: "",
    nationalIdentityNumber: "",
    passportNumber: "",
    personalEmail: "",
    cellPhone: "",
    personalPageUrl: "",
    leaveReason: null,
    leaveDate: null,
    socialMediaAccounts: [],
    jobHistory: {
      organization: {
        id: null,
        displayValue: "",
      },
      department: {
        id: null,
        displayValue: "",
      },
      jobTitle: {
        id: null,
        displayValue: "",
      },
      manager: {
        id: null,
        displayValue: "",
      },
      workType: {
        id: null,
        displayValue: "",
      },
      workState: {
        id: null,
        displayValue: "",
      },
      workStatus: {
        id: null,
        displayValue: "",
      },
      outsourcedOrganizationName: "",
      startDate: null,
      appointmentDate: null,
    },
    address: {
      country: {
        id: "1",
        displayValue: "Türkiye",
      },
      province: {
        id: null,
        displayValue: "",
      },
      district: {
        id: null,
        displayValue: "",
      },
      addressLine: "",
    },
    salary: {
      salary: "",
      additionalPaymentAmount: "",
      currency: {
        id: null,
        displayValue: "",
      },
      startDate: null,
    },
    status: {
      id: null,
      displayValue: "",
    },
    roles: [],
  },
  userRolesLoading: false,
  salaryHistory: [],
  jobHistory: [],
  jobRecord: { jobTitle: {}, organization: {} },
  salary: {},
  isPersonLoading: false,
  isPersonMeLoading: false,
  isSalaryHistoryLoading: false,
  isSalaryLoading: false,
  isJobHistoryLoading: false,
  isJobRecordLoading: false,
  isSearchLoading: false,
  selectedSalaryHistory: {},
  updateSalaryResponse: { status: "loading", message: "" },
  updateJobRecordResponse: { status: "loading", message: "" },
  searchResult: {
    totalNumberOfRecords: 0,
    results: [],
  },
  connectedPersons: {
    totalNumberOfRecords: 0,
    results: [],
  },
  isConnectedPersonLoading: false,
  personFilterResult: {
    results: [],
  },
  isPersonFilterLoading: false,
  corporateEmailFilterResult: {
    results: [],
  },
  profileInfo: {
    givenName: "",
    familyName: "",
  },
  isCorporateEmailFilterLoading: false,
  ownerShip: {
    totalNumberOfRecords: 0,
    results: [],
  },
  ownerShipLoading: false,
  ownerShipDetailsDrawTable: {
    totalNumberOfRecords: 0,
    results: [],
  },
  ownerShipDetailsDrawTableLoading: false,
  deleteAvatarResponse: { status: "loading", message: "" },
  deletePersonControlLoading: false,
  deletePersonControlData: [],
  deletePersonControlResponse: { status: "loading" },
  fileUploadList: [],
  fileUploadListLoading: false,
  deleteDocumentResponse: { status: "loading", message: "" },
  deleteDocumentRequestResponse: { status: "loading", message: "" },
  createDocumentResponse: { status: "loading", message: "" },
  doActivePersonResponse: { status: "loading", message: "" },
  resendConfirmEmailResponse: { status: "loading", message: "" },
  deleteSalaryResponse: { status: "loading", message: "" },
  deleteAssignmentResponse: { status: "loading", message: "" },
  deleteFileResponse: { status: "loading", message: "" },
  deleteFileWithTypeResponse: { status: "loading", message: "" },
  deleteAssignmentFileResponse: { status: "loading", message: "" },
  updateDocumentKVKKAproveResponse: { status: "loading", message: "" },
  personOrganizationList: [],
  personOrganizationListLoading: true,
  personImportResponse: { status: "loading", data: "", errorIds: [] },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case PersonActionType.CREATE_PERSON_SUCCESS:
      return {
        ...state,
        createPersonResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          resourceId: action.payload.data.resourceId,
        },
      };
    case PersonActionType.CREATE_PERSON_FAIL:
      return {
        ...state,
        createPersonResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.CREATE_PERSON_INPUT_ERROR:
      return {
        ...state,
        createPersonResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        updateResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_PERSON_FAIL:
      return {
        ...state,
        updateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_PERSON_INPUT_ERROR:
      return {
        ...state,
        updateResponse: {
          status: "VALIDATION_ERROR",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_PERSON_SUCCESS:
      return {
        ...state,
        deletePersonResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_PERSON_FAIL:
      return {
        ...state,
        deletePersonResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_PERSON_RESPONSE_RESET:
      return {
        ...state,
        updateResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_PERSON_RESPONSE_RESET:
      return {
        ...state,
        deletePersonResponse: { status: "loading", message: "" },
      };
    case PersonActionType.CREATE_PERSON_RESPONSE_RESET:
      return {
        ...state,
        createPersonResponse: { status: "loading", message: "" },
      };
    case PersonActionType.GET_PERSON_REQUEST:
      return {
        ...state,
        isPersonLoading: true,
      };
    case PersonActionType.GET_PERSON_SUCCESS:
      return {
        ...state,
        person: { ...state.person, ...action.payload },
        isPersonLoading: false,
      };
    case PersonActionType.GET_PERSON_FAIL:
      return {
        ...state,
        //person: action.payload,
        isPersonLoading: false,
      };
    case PersonActionType.GET_PERSON_ME_REQUEST:
      return {
        ...state,
        isPersonMeLoading: true,
      };
    case PersonActionType.GET_PERSON_ME_SUCCESS:
      return {
        ...state,
        personMe: { ...state.personMe, ...action.payload },
        isPersonMeLoading: false,
      };
    case PersonActionType.GET_PERSON_ME_FAIL:
      return {
        ...state,
        isPersonMeLoading: false,
      };
    case PersonActionType.GET_SALARY_HISTORY_REQUEST:
      return {
        ...state,
        isSalaryHistoryLoading: true,
      };
    case PersonActionType.GET_USER_ROLES_REQUEST:
      return {
        ...state,
        userRolesLoading: true,
      };
    case PersonActionType.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        person: { ...state.person, roles: action.payload },
        userRolesLoading: false,
      };
    case PersonActionType.GET_SALARY_HISTORY_SUCCESS:
      return {
        ...state,
        salaryHistory: action.payload,
        isSalaryHistoryLoading: false,
      };
    case PersonActionType.GET_SALARY_HISTORY_FAIL:
      return {
        ...state,
        isSalaryHistoryLoading: false,
      };
    case PersonActionType.GET_SALARY_LIST_RESET:
      return {
        ...state,
        salaryHistory: [],
        isSalaryHistoryLoading: true,
      };
    case PersonActionType.CREATE_SALARY_HISTORY_RESPONSE_RESET:
      return {
        ...state,
        createSalaryHistoryResponse: { status: "loading", message: "" },
      };
    case PersonActionType.CREATE_SALARY_HISTORY_SUCCESS:
      return {
        ...state,
        createSalaryHistoryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.CREATE_SALARY_HISTORY_FAIL:
      return {
        ...state,
        createSalaryHistoryResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.GET_SALARY_REQUEST:
      return {
        ...state,
        isSalaryLoading: true,
      };
    case PersonActionType.GET_SALARY_SUCCESS:
      return {
        ...state,
        salary: action.payload,
        isSalaryLoading: false,
      };
    case PersonActionType.GET_SALARY_FAIL:
      return {
        ...state,
        isSalaryLoading: false,
      };
    case PersonActionType.UPDATE_SALARY_HISTORY_RESPONSE_RESET:
      return {
        ...state,
        updateSalaryResponse: { status: "loading", message: "" },
      };
    case PersonActionType.UPDATE_SALARY_SUCCESS:
      return {
        ...state,
        updateSalaryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_SALARY_FAIL:
      return {
        ...state,
        updateResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.GET_JOB_HISTORY_REQUEST:
      return {
        ...state,
        isJobHistoryLoading: true,
      };
    case PersonActionType.GET_JOB_HISTORY_SUCCESS:
      return {
        ...state,
        jobHistory: action.payload,
        isJobHistoryLoading: false,
      };
    case PersonActionType.GET_JOB_HISTORY_FAIL:
      return {
        ...state,
        isJobHistoryLoading: false,
      };
    case PersonActionType.GET_JOB_HISTORY_RESET:
      return {
        ...state,
        jobHistory: [],
        isJobHistoryLoading: true,
      };
    case PersonActionType.CREATE_JOB_RECORD_RESPONSE_RESET:
      return {
        ...state,
        createJobRecordResponse: { status: "loading", message: "" },
      };
    case PersonActionType.CREATE_JOB_RECORD_SUCCESS:
      return {
        ...state,
        createJobRecordResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.CREATE_JOB_RECORD_FAIL:
      return {
        ...state,
        createJobRecordResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.GET_JOB_RECORD_REQUEST:
      return {
        ...state,
        isJobRecordLoading: true,
      };
    case PersonActionType.GET_JOB_RECORD_SUCCESS:
      return {
        ...state,
        jobRecord: action.payload,
        isJobRecordLoading: false,
      };
    case PersonActionType.GET_JOB_RECORD_FAIL:
      return {
        ...state,
        isJobRecordLoading: false,
      };
    case PersonActionType.UPDATE_JOB_RECORD_SUCCESS:
      return {
        ...state,
        updateJobRecordResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_JOB_RECORD_RESPONSE_RESET:
      return {
        ...state,
        updateJobRecordResponse: { status: "loading", message: "" },
      };
    case PersonActionType.UPDATE_JOB_RECORD_FAIL:
      return {
        ...state,
        updateJobRecordResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.SEARCH_REQUEST:
      return {
        ...state,
        isSearchLoading: true,
      };
    case PersonActionType.SEARCH_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        isSearchLoading: false,
      };
    case PersonActionType.SEARCH_FAIL:
      return {
        ...state,
        isSearchLoading: false,
      };
    case PersonActionType.GET_CONNECTED_PERSONS_REQUEST:
      return {
        ...state,
        isConnectedPersonLoading: true,
      };
    case PersonActionType.GET_CONNECTED_PERSONS_SUCCESS:
      return {
        ...state,
        connectedPersons: action.payload,
        isConnectedPersonLoading: false,
      };
    case PersonActionType.GET_CONNECTED_PERSONS_FAIL:
      return {
        ...state,
        isConnectedPersonLoading: false,
      };
    case PersonActionType.PERSON_FILTER_REQUEST:
      return {
        ...state,
        isPersonFilterLoading: true,
      };
    case PersonActionType.PERSON_FILTER_SUCCESS:
      return {
        ...state,
        personFilterResult: action.payload,
        isPersonFilterLoading: false,
      };
    case PersonActionType.PERSON_FILTER_FAIL:
      return {
        ...state,
        isPersonFilterLoading: false,
      };
    case PersonActionType.CORPORATE_EMAIL_FILTER_REQUEST:
      return {
        ...state,
        isCorporateEmailFilterLoading: true,
      };
    case PersonActionType.CORPORATE_EMAIL_FILTER_SUCCESS:
      return {
        ...state,
        corporateEmailFilterResult: action.payload,
        isCorporateEmailFilterLoading: false,
      };
    case PersonActionType.CORPORATE_EMAIL_FILTER_FAIL:
      return {
        ...state,
        isCorporateEmailFilterLoading: false,
      };
    case PersonActionType.OWNER_SHIP_REQUEST:
      return {
        ...state,
        ownerShipLoading: true,
      };
    case PersonActionType.OWNER_SHIP_SUCCESS:
      return {
        ...state,
        ownerShip: action.payload,
        ownerShipLoading: false,
      };
    case PersonActionType.OWNER_SHIP_FAIL:
      return {
        ...state,
        ownerShipLoading: false,
      };
    case PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_REQUEST:
      return {
        ...state,
        ownerShipDetailsDrawTableLoading: true,
      };
    case PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_SUCCESS:
      return {
        ...state,
        ownerShipDetailsDrawTable: action.payload,
        ownerShipDetailsDrawTableLoading: false,
      };
    case PersonActionType.OWNER_SHIP_DETAILS_DRAW_TABLE_FAIL:
      return {
        ...state,
        ownerShipDetailsDrawTableLoading: false,
      };
    case PersonActionType.DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        deleteAvatarResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_AVATAR_FAIL:
      return {
        ...state,
        deleteAvatarResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_AVATAR_RESET:
      return {
        ...state,
        deleteAvatarResponse: {
          status: "loading",
          message: null,
        },
      };
    case PersonActionType.GET_DELETE_PERSON_CONTROL_REQUEST:
      return {
        ...state,
        deletePersonControlLoading: true,
      };
    case PersonActionType.GET_DELETE_PERSON_CONTROL_OPEN_DRAWER:
      return {
        ...state,
        deletePersonControlResponse: { status: "openDrawer" },
        deletePersonControlData: action.payload,
        deletePersonControlLoading: false,
      };
    case PersonActionType.GET_DELETE_PERSON_CONTROL_OPEN_MODAL:
      return {
        ...state,
        deletePersonControlResponse: { status: "openModal" },
        deletePersonControlData: action.payload,
        deletePersonControlLoading: false,
      };
    case PersonActionType.RESET_GET_DELETE_PERSON_CONTROL:
      return {
        ...state,
        deletePersonControlResponse: { status: "loading" },
        deletePersonControlData: [],
        deletePersonControlLoading: false,
      };
    case PersonActionType.GET_FILE_UPLOAD_LIST_REQUEST:
      return {
        ...state,
        fileUploadListLoading: true,
      };
    case PersonActionType.GET_FILE_UPLOAD_LIST_SUCCESS:
      return {
        ...state,
        fileUploadList: action.payload,
        fileUploadListLoading: false,
      };
    case PersonActionType.GET_FILE_UPLOAD_LIST_FAIL:
      return {
        ...state,
        fileUploadListLoading: false,
      };
    case PersonActionType.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocumentResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        deleteDocumentResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_DOCUMENT_RESET:
      return {
        ...state,
        deleteDocumentResponse: { status: "loading", message: "" },
      };
    case PersonActionType.CREATE_DOCUMENT_REQUESTS_SUCCESS:
      return {
        ...state,
        createDocumentResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.CREATE_DOCUMENT_REQUESTS_FAIL:
      return {
        ...state,
        createDocumentResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.CREATE_DOCUMENT_REQUESTS_RESET:
      return {
        ...state,
        createDocumentResponse: { status: "loading", message: "" },
      };
    case PersonActionType.PERSON_ACTIVE_SUCCESS:
      return {
        ...state,
        doActivePersonResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.PERSON_ACTIVE_FAIL:
      return {
        ...state,
        doActivePersonResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.PERSON_ACTIVE_RESET:
      return {
        ...state,
        doActivePersonResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_DOCUMENT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteDocumentRequestResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_DOCUMENT_REQUEST_FAIL:
      return {
        ...state,
        deleteDocumentRequestResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_DOCUMENT_REQUEST_RESET:
      return {
        ...state,
        deleteDocumentRequestResponse: { status: "loading", message: "" },
      };
    case PersonActionType.RESEND_CONFIRM_MAIL_SUCCESS:
      return {
        ...state,
        resendConfirmEmailResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.RESEND_CONFIRM_MAIL_FAIL:
      return {
        ...state,
        resendConfirmEmailResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.RESEND_CONFIRM_MAIL_RESET:
      return {
        ...state,
        resendConfirmEmailResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_SALARY_SUCCESS:
      return {
        ...state,
        deleteSalaryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_SALARY_FAIL:
      return {
        ...state,
        deleteSalaryResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_SALARY_RESET:
      return {
        ...state,
        deleteSalaryResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        deleteAssignmentResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_ASSIGNMENT_FAIL:
      return {
        ...state,
        deleteAssignmentResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_ASSIGNMENT_RESET:
      return {
        ...state,
        deleteAssignmentResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_FILE_SUCCESS:
      return {
        ...state,
        deleteFileResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          index: action.payload.index,
        },
      };
    case PersonActionType.DELETE_FILE_FAIL:
      return {
        ...state,
        deleteFileResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_FILE_RESET:
      return {
        ...state,
        deleteFileResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_FILE_WITH_TYPE_SUCCESS:
      return {
        ...state,
        deleteFileWithTypeResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          index: action.payload.index,
        },
      };
    case PersonActionType.DELETE_FILE_WITH_TYPE_FAIL:
      return {
        ...state,
        deleteFileWithTypeResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_FILE_WITH_TYPE_RESET:
      return {
        ...state,
        deleteFileWithTypeResponse: { status: "loading", message: "" },
      };
    case PersonActionType.DELETE_ASSIGNMENT_FILE_SUCCESS:
      return {
        ...state,
        deleteAssignmentFileResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_ASSIGNMENT_FILE_FAIL:
      return {
        ...state,
        deleteAssignmentFileResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.DELETE_ASSIGNMENT_FILE_RESET:
      return {
        ...state,
        deleteAssignmentFileResponse: {
          status: "loading",
          message: null,
        },
      };
    case PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_SUCCESS:
      return {
        ...state,
        updateDocumentKVKKAproveResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_FAIL:
      return {
        ...state,
        updateDocumentKVKKAproveResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case PersonActionType.UPDATE_DOCUMENT_KVKK_APROVE_RESET:
      return {
        ...state,
        updateDocumentKVKKAproveResponse: {
          status: "loading",
          message: null,
        },
      };
    case PersonActionType.GET_PERSON_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        personOrganizationListLoading: true,
      };
    case PersonActionType.GET_PERSON_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        personOrganizationList: action.payload,
        personOrganizationListLoading: false,
      };
    case PersonActionType.GET_PERSON_ORGANIZATIONS_FAIL:
      return {
        ...state,
        personOrganizationListLoading: false,
      };
    case PersonActionType.IMPORT_PERSON_SUCCESS:
      return {
        ...state,
        personImportResponse: {
          status: "SUCCESS",
          data: action.payload,
        },
      };
    case PersonActionType.IMPORT_PERSON_FAIL:
      return {
        ...state,
        personImportResponse: {
          status: "FAIL",
          data: action.payload,
        },
      };
    case PersonActionType.IMPORT_PERSON_RESET:
      return {
        ...state,
        personImportResponse: {
          status: "loading",
          data: null,
        },
      };
    default:
      return state;
  }
}
