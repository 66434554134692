import { TimeOff } from "../actions/timeOff.actions";
const initialState = {
  isSearchTimeOffManagerLoading: false,
  isSearchPeopleOnLeaveLoading: false,
  searchTimeOffManagerResult: {
    totalNumberOfRecords: 0,
    results: [],
  },
  searchPeopleOnLeaveResult: {
    totalNumberOfRecords: 0,
    results: [],
  },
  timeOffApproveResponse: { status: "loading", message: "", resourceId: "", key: null },
  timeOffTypes: [],
  timeOffTypesLoading: true,
  timeOffTypeUpdateStatus: { status: "loading", message: "" },
  timeOffTypeUpdateStatusLoading: true,
  timeOffInfoByType: {},
  timeOffInfoByTypeWarning: {},
  timeOffInfoByTypeDate: {},
  timeOffInfoByTypeDateWarning: {},
  createTimeOffRequestResponse: { status: "loading", message: "", data: "", validationMessages:[] },
  timeOffRequestSearchResult: {
    totalNumberOfRecords: 0,
    results: [],
  },
  timeOffRequestSearchMeResult: {
    totalNumberOfRecords: 0,
    results: [],
  },
  timeOffRequestSearchIsLoading: true,
  timeOffRequestSearchMeIsLoading: true,
  timeOffRequestDetails: {
    person: {
      id: null,
      resourceId: "",
      fullName: "",
    },
    timeOffType: {
      id: 1,
      resourceId: "",
      displayValue: "",
    },
    startDate: "",
    endDate: "",
    requestDate: "",
    balance: 0,
    balanceWeekend: 0,
    startWorkDate: "",
    groupEmailAddr: "",
    description: "",
    approvalDescriptions: [
      {
        approvalDate: "",
        personName: "",
        description: "",
      },
    ],
    timeOffInfo: {
      timeOffBalance: 0,
      usedTimeOffBalance: 0,
      remainderTimeOffBalance: 0,
    },
    stateType: {
      id: "",
      displayValue: "",
    },
    contactPhones: [
      {
        id: "",
        displayValue: "",
      },
    ],
  },
  timeOffRequestDetailsIsLoading: true,
  timeOffRequestStep: {
    editableStatus: 0,
    timeOffApproveList: [
      {
        name: "",
        date: "",
        sortOrder: 0,
        isSelected: false,
        description: null,
      },
    ],
  },
  timeOffRequestStepisIsLoading: true,
  timeOffApproveUpdateStatus: { status: "loading", message: "" },
  timeOffPaiChartData: [
    {
      timeOffTypeName: "",
      maxBalance: 0,
      remainingValue: 0,
    },
  ],
  timeOffPaiChartDataLoading: true,
  timeOffApproveUpdateStatusLoading: true,
  filterTimeOffTypeData: {
    totalNumberOfRecords: 0,
    results: [],
  },
  timeOffExportData: {},
  remainingTimeOffRequestLoading: false,
  remainingTimeOffRequestList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  deleteExtraValueResponse: { status: "loading", message: "" },
  createExtraValueResponse: { status: "loading", message: "" },
  deleteTimeOffRequestResponse: { status: "loading", message: "" },
  allTimeOffLoading: false,
  allTimeOffList: {
    totalNumberOfRecords: 0,
    results: [],
  },
  remainderYearsExportResponse: { status: "loading", message: "" },
  workHourStartByDate: {
    status: "LOADING",
    message: "",
    data: "",
  },
  workHourStartByDateLoading: true,
  workHourEndByDate: {
    status: "LOADING",
    message: "",
    data: "",
  },
  workHourEndByDateLoading: true,
  workHourStartEndByDate: {
    status: "LOADING",
    message: "",
    data: "",
  },
  workHourStartEndByDateLoading: true,
  timeOffRequestInfo: {
    details: [],
    weekendDetail: [],
    totalInfo: "",
    startDate: "",
  },
  timeOffRequestInfoLoading: true,
  timeOffApproveOnMailResponse: { status: "loading", message: "" },
  timeOffApproveOnMailResponseLoading: true,
  timeOffExportDataResponse: { status: "loading", message: "" },
  agreementFormDataResponse: { status: "loading", message: "" },
  extraValueDetailLoading: true,
  extraValueDetail: {
    histories: [],
  },
  deleteTimeOffAdjustsHistoryResponse: { status: "loading", message: "" },
  updateTimeOffAdjustsHistoryResponse: { status: "loading", message: "" },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_REQUEST:
      return {
        ...state,
        timeOffRequestSearchIsLoading: true,
      };
    case TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        timeOffRequestSearchResult: action.payload,
        timeOffRequestSearchIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_FAIL:
      return {
        ...state,
        timeOffRequestSearchIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_REQUEST:
      return {
        ...state,
        timeOffRequestSearchMeIsLoading: true,
      };
    case TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_SUCCESS:
      return {
        ...state,
        timeOffRequestSearchMeResult: action.payload,
        timeOffRequestSearchMeIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_LIST_SEARCH_ME_FAIL:
      return {
        ...state,
        timeOffRequestSearchMeIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_DETAILS_REQUEST:
      return {
        ...state,
        timeOffRequestDetailsisLoading: true,
      };
    case TimeOff.TIME_OFF_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        timeOffRequestDetails: action.payload,
        timeOffRequestDetailsIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_DETAILS_FAIL:
      return {
        ...state,
        timeOffRequestDetailsIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_REQUEST:
      return {
        ...state,
        timeOffRequestStepisIsLoading: true,
      };
    case TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_SUCCESS:
      return {
        ...state,
        timeOffRequestStep: action.payload,
        timeOffRequestStepisIsLoading: false,
      };
    case TimeOff.TIME_OFF_REQUEST_DETAILS_STEP_FAIL:
      return {
        ...state,
        timeOffRequestStepisIsLoading: false,
      };
    case TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_REQUEST:
      return {
        ...state,
        searchTimeOffManagerResult: {
          totalNumberOfRecords: 0,
          results: [],
        },
        isSearchTimeOffManagerLoading: true,
      };
    case TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_SUCCESS:
      return {
        ...state,
        searchTimeOffManagerResult: action.payload,
        isSearchTimeOffManagerLoading: false,
      };
    case TimeOff.SEARCH_TIMEOFF_REQUEST_MANAGER_FAIL:
      return {
        ...state,
        searchTimeOffManagerResult: {
          totalNumberOfRecords: 0,
          results: [],
        },
        isSearchTimeOffManagerLoading: false,
      };
    case TimeOff.SEARCH_TIMEOFF_REQUEST_REQUEST:
      return {
        ...state,
        isSearchPeopleOnLeaveLoading: true,
      };
    case TimeOff.SEARCH_TIMEOFF_REQUEST_SUCCESS:
      return {
        ...state,
        searchPeopleOnLeaveResult: action.payload,
        isSearchPeopleOnLeaveLoading: false,
      };
    case TimeOff.TIMEOFF_APPROVE_SUCCESS:
      return {
        ...state,
        timeOffApproveResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          resourceId: action.payload.resourceId,
          key: action.payload.key,
        },
      };
    case TimeOff.TIMEOFF_APPROVE_FAIL:
      return {
        ...state,
        timeOffApproveResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.TIMEOFF_CANCEL_SUCCESS:
      return {
        ...state,
        timeOffApproveResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          resourceId: action.payload.resourceId,
          key: action.payload.key,
        },
      };
    case TimeOff.TIMEOFF_CANCEL_FAIL:
      return {
        ...state,
        timeOffApproveResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.TIMEOFF_REJECT_SUCCESS:
      return {
        ...state,
        timeOffApproveResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          resourceId: action.payload.resourceId,
          key: action.payload.key,
        },
      };
    case TimeOff.GET_TIME_OFF_TYPES_REQUEST:
      return {
        ...state,
        timeOffTypesLoading: true,
      };
    case TimeOff.GET_TIME_OFF_TYPES_SUCCESS:
      return {
        ...state,
        timeOffTypes: action.payload,
        timeOffTypesLoading: false,
      };
    case TimeOff.GET_TIME_OFF_TYPES_FAIL:
      return {
        ...state,
        timeOffTypesLoading: false,
      };
    case TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_REQUEST:
      return {
        ...state,
        timeOffTypeUpdateStatusLoading: true,
      };
    case TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_SUCCESS:
      return {
        ...state,
        timeOffTypeUpdateStatus: {
          status: "SUCCESS",
          message: action.payload,
        },
        timeOffTypeUpdateStatusLoading: false,
      };
    case TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_FAIL:
      return {
        ...state,
        timeOffTypeUpdateStatus: {
          status: "FAIL",
          message: action.payload,
        },
        timeOffTypeUpdateStatusLoading: false,
      };
    case TimeOff.GET_TIMEOFF_INFO_BY_TYPE_SUCCESS:
      return {
        ...state,
        timeOffInfoByType: action.payload,
        timeOffInfoByTypeDate: {},
      };
    case TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_SUCCESS:
      return {
        ...state,
        timeOffInfoByTypeDate: action.payload,
      };
    case TimeOff.GET_TIMEOFF_INFO_BY_TYPE_WARNING:
      return {
        ...state,
        timeOffInfoByTypeWarning: action.payload,
      };
    case TimeOff.GET_TIMEOFF_INFO_BY_TYPE_DATE_WARNING:
      return {
        ...state,
        timeOffInfoByTypeDateWarning: action.payload,
        timeOffInfoByTypeDate: {},
      };
    case TimeOff.CREATE_TIMEOFF_REQUEST_SUCCESS:
      return {
        ...state,
        createTimeOffRequestResponse: {
          status: "SUCCESS",
          message: action.payload.message,
          data: action.payload.data.resourceId,
        },
      };
    case TimeOff.CREATE_TIMEOFF_REQUEST_FAIL:
      return {
        ...state,
        createTimeOffRequestResponse: {
          status: "FAIL",
          message: action.payload?.message,
          validationMessages : action.payload?.validationMessages
        },
      };
    case TimeOff.CREATE_TIMEOFF_REQUEST_RESET_STATUS:
      return {
        ...state,
        createTimeOffRequestResponse: { status: "loading", message: "" },
      };
    case TimeOff.RESET_TIMEOFF_APPROVE_NOTIFOCATIONS:
      return {
        ...state,
        timeOffApproveResponse: { status: "loading", message: "", resourceId: "", key: null },
      };
    case TimeOff.RESET_TIMEOFF_INFO_NOTIFICATIONS:
      return {
        ...state,
        timeOffInfoByTypeWarning: {},
        timeOffInfoByTypeDateWarning: {},
      };
    case TimeOff.UPDATE_TIMEOFF_APPROVE_SUCCESS:
      return {
        ...state,
        timeOffApproveUpdateStatus: {
          status: "SUCCESS",
          message: action.payload,
        },
        timeOffApproveUpdateStatusLoading: false,
      };
    case TimeOff.UPDATE_TIMEOFF_APPROVE_FAIL:
      return {
        ...state,
        timeOffApproveUpdateStatus: {
          status: "FAIL",
          message: action.payload,
        },
        timeOffApproveUpdateStatusLoading: false,
      };
    case TimeOff.UPDATE_TIMEOFF_APPROVE_RESET: {
      return {
        ...state,
        timeOffApproveUpdateStatus: { status: "loading", message: "" },
      };
    }
    case TimeOff.GET_TIMEOFF_PAI_CHART_DATA_REQUEST:
      return {
        ...state,
        timeOffPaiChartDataLoading: true,
      };
    case TimeOff.GET_TIMEOFF_PAI_CHART_DATA_SUCCESS:
      return {
        ...state,
        timeOffPaiChartData: action.payload,
        timeOffPaiChartDataLoading: false,
      };
    case TimeOff.GET_TIMEOFF_PAI_CHART_DATA_FAIL:
      return {
        ...state,
        timeOffPaiChartData: action.payload,
        timeOffPaiChartDataLoading: false,
      };
    case TimeOff.UPDATE_TIME_OFF_TYPE_STATUS_RESET:
      return {
        ...state,
        timeOffApproveUpdateStatus: { status: "loading", message: "" },
        timeOffTypeUpdateStatus: { status: "loading", message: "" },
      };
    case TimeOff.TIMEOFF_EXPORT_SUCCESS:
      return {
        ...state,
        timeOffExportData: action.payload,
      };
    case TimeOff.TIMEOFF_EXPORT_FAIL:
      return {
        ...state,
        timeOffExportData: {},
        timeOffExportDataResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.RESET_TIMEOFF_DOWNLOAD:
      return {
        ...state,
        timeOffExportData: {},
        timeOffExportDataResponse: { status: "loading", message: "" },
      };
    case "GET_FILTER_TIME_OFF_TYPE_SUCCESS":
      return {
        ...state,
        filterTimeOffTypeData: action.payload,
      };
    case TimeOff.RESET_TIMEOFF_REQUEST_DETAILS:
      return {
        ...state,
        timeOffRequestDetails: {
          person: {
            id: null,
            resourceId: "",
            fullName: "",
          },
          timeOffType: {
            id: 1,
            resourceId: "",
            displayValue: "",
          },
          startDate: "",
          endDate: "",
          requestDate: "",
          balance: 0,
          balanceWeekend: 0,
          startWorkDate: "",
          groupEmailAddr: "",
          description: "",
          approvalDescriptions: [
            {
              approvalDate: "",
              personName: "",
              description: "",
            },
          ],
          timeOffInfo: {
            timeOffBalance: 0,
            usedTimeOffBalance: 0,
            remainderTimeOffBalance: 0,
          },
          stateType: {
            id: "",
            displayValue: "",
          },
          contactPhones: [
            {
              id: "",
              displayValue: "",
            },
          ],
        },
        timeOffRequestDetailsIsLoading: true,
      };
    case TimeOff.REMAINDER_TIMEOFF_REQUEST_REQUEST:
      return {
        ...state,
        remainingTimeOffRequestLoading: true,
      };
    case TimeOff.REMAINDER_TIMEOFF_REQUEST_SUCCESS:
      return {
        ...state,
        remainingTimeOffRequestList: action.payload,
        remainingTimeOffRequestLoading: false,
      };
    case TimeOff.REMAINDER_TIMEOFF_REQUEST_FAIL:
      return {
        ...state,
        remainingTimeOffRequestLoading: true,
      };
    case TimeOff.DELETE_EXTRA_VALUE_SUCCESS:
      return {
        ...state,
        deleteExtraValueResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TimeOff.DELETE_EXTRA_VALUE_FAIL:
      return {
        ...state,
        deleteExtraValueResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.DELETE_EXTRA_VALUE_RESET:
      return {
        ...state,
        deleteExtraValueResponse: {
          status: "loading",
          message: null,
        },
      };
    case TimeOff.CREATE_EXTRA_VALUE_SUCCESS:
      return {
        ...state,
        createExtraValueResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TimeOff.CREATE_EXTRA_VALUE_FAIL:
      return {
        ...state,
        createExtraValueResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.CREATE_EXTRA_VALUE_RESET:
      return {
        ...state,
        createExtraValueResponse: { status: "loading", message: "" },
      };
    case TimeOff.GET_ALL_TIME_OFF_REQUEST:
      return {
        ...state,
        allTimeOffList: {
          totalNumberOfRecords: 0,
          results: [],
        },
        allTimeOffLoading: true,
      };
    case TimeOff.GET_ALL_TIME_OFF_SUCCESS:
      return {
        ...state,
        allTimeOffList: action.payload,
        allTimeOffLoading: false,
      };
    case TimeOff.GET_ALL_TIME_OFF_FAIL:
      return {
        ...state,
        allTimeOffList: {
          totalNumberOfRecords: 0,
          results: [],
        },
        allTimeOffLoading: false,
      };
    case TimeOff.DELETE_TIME_OFF_REQUEST_SUCCESS:
      return {
        ...state,
        deleteTimeOffRequestResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TimeOff.DELETE_TIME_OFF_REQUEST_FAIL:
      return {
        ...state,
        deleteTimeOffRequestResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.DELETE_TIME_OFF_REQUEST_RESET:
      return {
        ...state,
        deleteTimeOffRequestResponse: {
          status: "loading",
          message: null,
        },
      };
    case TimeOff.EXPORT_REMAINDER_YEARS_FAIL:
      return {
        ...state,
        remainderYearsExportResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.EXPORT_REMAINDER_YEARS_RESET:
      return {
        ...state,
        remainderYearsExportResponse: {
          status: "loading",
          message: null,
        },
      };
    case TimeOff.GET_WORK_HOUR_START_BY_DATE_REQUEST:
      return {
        ...state,
        workHourStartByDate: {
          status: "LOADING",
          message: "",
          data: "",
        },
        workHourStartByDateLoading: true,
      };
    case TimeOff.GET_WORK_HOUR_START_BY_DATE_SUCCESS:
      return {
        ...state,
        workHourStartByDate: {
          status: "SUCCESS",
          message: "",
          data: action.payload,
        },
        workHourStartByDateLoading: false,
      };
    case TimeOff.GET_WORK_HOUR_START_BY_DATE_FAIL:
      return {
        ...state,
        workHourStartByDate: {
          status: "FAIL",
          message: action.payload,
          data: "",
        },
        workHourStartByDateLoading: false,
      };
    case TimeOff.GET_WORK_HOUR_END_BY_DATE_REQUEST:
      return {
        ...state,
        workHourEndByDate: {
          status: "LOADING",
          message: "",
          data: "",
        },
        workHourEndByDateLoading: true,
      };
    case TimeOff.GET_WORK_HOUR_END_BY_DATE_SUCCESS:
      return {
        ...state,
        workHourEndByDate: {
          status: "SUCCESS",
          message: "",
          data: action.payload,
        },
        workHourEndByDateLoading: false,
      };
    case TimeOff.GET_WORK_HOUR_END_BY_DATE_FAIL:
      return {
        ...state,
        workHourEndByDate: {
          status: "FAIL",
          message: action.payload,
          data: "",
        },
        workHourEndByDateLoading: false,
      };
    case TimeOff.GET_WORK_HOUR_START_END_BY_DATE_REQUEST:
      return {
        ...state,
        workHourStartEndByDate: {
          status: "LOADING",
          message: "",
          data: "",
        },
        workHourStartEndByDateLoading: true,
      };
    case TimeOff.GET_WORK_HOUR_START_END_BY_DATE_SUCCESS:
      return {
        ...state,
        workHourStartEndByDate: {
          status: "SUCCESS",
          message: "",
          data: action.payload,
        },
        workHourStartEndByDateLoading: false,
      };
    case TimeOff.GET_WORK_HOUR_START_END_BY_DATE_FAIL:
      return {
        ...state,
        workHourStartEndByDate: {
          status: "FAIL",
          message: action.payload,
          data: "",
        },
        workHourStartEndByDateLoading: false,
      };
    case TimeOff.GET_TIME_OFF_INFO_REQUEST:
      return {
        ...state,
        timeOffRequestInfo: {
          details: [],
          weekendDetail: [],
          totalInfo: "",
          startDate: "",
        },
        timeOffRequestInfoLoading: true,
      };
    case TimeOff.GET_TIME_OFF_INFO_SUCCESS:
      return {
        ...state,
        timeOffRequestInfo: action.payload,
        timeOffRequestInfoLoading: false,
      };
    case TimeOff.GET_TIME_OFF_INFO_FAIL:
      return {
        ...state,
        timeOffRequestInfo: {
          details: [],
          weekendDetail: [],
          totalInfo: "",
          startDate: "",
        },
        timeOffRequestInfoLoading: false,
      };
    case TimeOff.GET_TIME_OFF_INFO_RESET:
      return {
        ...state,
        timeOffRequestInfo: {
          details: [],
          weekendDetail: [],
          totalInfo: "",
          startDate: "",
        },
        timeOffRequestInfoLoading: true,
      };
    case TimeOff.START_WORK_HOUR_START_BY_DATE_RESET:
      return {
        ...state,
        workHourStartByDate: {
          status: "LOADING",
          message: "",
          data: "",
        },
        workHourStartByDateLoading: true,
      };
    case TimeOff.END_WORK_HOUR_START_BY_DATE_RESET:
      return {
        ...state,
        workHourEndByDate: {
          status: "LOADING",
          message: "",
          data: "",
        },
        workHourEndByDateLoading: true,
      };
    case TimeOff.START_END_WORK_HOUR_START_BY_DATE_RESET:
      return {
        ...state,
        workHourStartEndByDate: {
          status: "LOADING",
          message: "",
          data: "",
        },
        workHourStartEndByDateLoading: true,
      };
    case TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_REQUEST:
      return {
        ...state,
        timeOffApproveOnMailResponse: {
          status: "LOADING",
          message: "",
        },
        timeOffApproveOnMailResponseLoading: true,
      };
    case TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_SUCCESS:
      return {
        ...state,
        timeOffApproveOnMailResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
        timeOffApproveOnMailResponseLoading: false,
      };
    case TimeOff.GET_TIMEOFF_APPROVE_ON_MAIL_FAIL:
      return {
        ...state,
        timeOffApproveOnMailResponse: {
          status: "FAIL",
          message: action.payload,
        },
        timeOffApproveOnMailResponseLoading: false,
      };
    case TimeOff.AGREEMENT_FORM_SUCCESS:
      return {
        ...state,
        agreementFormDataResponse: action.payload,
      };
    case TimeOff.AGREEMENT_FORM_FAIL:
      return {
        ...state,
        agreementFormDataResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.AGREEMENT_FORM_RESET:
      return {
        ...state,
        agreementFormDataResponse: { status: "loading", message: "" },
      };
    case TimeOff.GET_EXTRA_VALUE_REQUEST:
      return {
        ...state,
        extraValueDetailLoading: true,
      };
    case TimeOff.GET_EXTRA_VALUE_SUCCESS:
      return {
        ...state,
        extraValueDetail: action.payload,
        extraValueDetailLoading: false,
      };
    case TimeOff.GET_EXTRA_VALUE_FAIL:
      return {
        ...state,
        extraValueDetail: {
          histories: [],
        },
        extraValueDetailLoading: false,
      };
    case TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_SUCCESS:
      return {
        ...state,
        deleteTimeOffAdjustsHistoryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_FAIL:
      return {
        ...state,
        deleteTimeOffAdjustsHistoryResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.DELETE_TIME_OFF_ADJUST_HISTORY_RESET:
      return {
        ...state,
        deleteTimeOffAdjustsHistoryResponse: { status: "loading", message: "" },
      };
    case TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_SUCCESS:
      return {
        ...state,
        updateTimeOffAdjustsHistoryResponse: {
          status: "SUCCESS",
          message: action.payload,
        },
      };
    case TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_FAIL:
      return {
        ...state,
        updateTimeOffAdjustsHistoryResponse: {
          status: "FAIL",
          message: action.payload,
        },
      };
    case TimeOff.UPDATE_TIME_OFF_ADJUST_HISTORY_RESET:
      return {
        ...state,
        updateTimeOffAdjustsHistoryResponse: { status: "loading", message: "" },
      };
    default:
      return state;
  }
}
