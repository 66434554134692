import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resendConfirmMail, resendConfirmMailReset } from '../../redux/actions/person.actions';

import { Form, Row, Col, ConfigProvider, Checkbox, Spin, Alert, Button } from 'antd';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';


function Authorization(props) {

    const { getUpdateObj, personInformation, roles, userRolesLoading, corporateEmail, goToCorporateEmail,
        resendConfirmEmailResponse, resendConfirmMail, resendConfirmMailReset, disableStatus } = props;
    const { t } = useTranslation();
    const [authorizationState, setAuthorizationState] = useState({ "roles": [] })
    const [roleCheckboxList, setRoleCheckboxList] = useState([])
    const [form] = Form.useForm()
    const personRoles = personInformation.roles?.roles?.length > 0 ? personInformation.roles?.roles : [];

    useEffect(() => {
        setAuthorizationState({ ...personInformation })
        const checkedRoles = personRoles.length === 0 ? roles.filter(item => item.isDefaultRole) : personRoles.filter(item => item.hasAssign || item.isDefaultRole)
        const checkedList = checkedRoles ? checkedRoles.map(item => item.resourceId) : [];
        form.setFieldsValue({
            "userRoles": checkedList,
            "canLogin": personInformation.canLogin
        })
    }, [form, personInformation])

    useEffect(() => {
        setRoleCheckboxList(roles.map(item => { return { "label": item.roleName, "value": item.resourceId, ...item } }))
    }, [roles]);

    useEffect(() => {
        getUpdateObj(authorizationState)
    }, [authorizationState]);

    useEffect(() => {
        if (corporateEmail === '') {
            const tempform = form.getFieldsValue();
            tempform["userRoles"] = [];
            tempform["canLogin"] = false
            form.setFieldsValue({ ...tempform });
        }
    }, [corporateEmail]);
    useEffect(() => {
        if (resendConfirmEmailResponse.status === "SUCCESS") {
            DynamicToastMessage({
                'key': 'person_resend_mail_success_notification',
                'title': t('SUCCESS'),
                'description': resendConfirmEmailResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            resendConfirmMailReset();
        } else if (resendConfirmEmailResponse.status === "FAIL") {
            DynamicToastMessage({
                'key': 'person_resend_mail_error_notification',
                'title': t('ERROR'),
                'description': resendConfirmEmailResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resendConfirmMailReset();
        }
    }, [resendConfirmEmailResponse])

    const handleValuesChange = (changedObj) => {
        if (Object.keys(changedObj)[0] === "userRoles") {

            var activeRole = changedObj["userRoles"];
            if (activeRole.at(-1) === "FJWGjMrBwDDCP3M") {
                activeRole = activeRole.filter((filterData) => (filterData === "FJWGjMrBwDDCP3M" || filterData === "p3PmYkNDeb8tvEs"));
            } else {
                activeRole = activeRole.filter((filterData) => filterData !== "FJWGjMrBwDDCP3M");
            }
            const newRoles = roles.map((obj) => {
                if (activeRole.includes(obj.resourceId) || obj.isDefaultRole) {
                    return { ...obj, "hasAssign": true }
                } else {
                    return { ...obj, "hasAssign": false }
                }
            })
            const fields = form.getFieldsValue();
            const projects = { ...fields };
            projects["userRoles"] = activeRole;
            form.setFieldsValue(projects);
            const rolesData = { ...authorizationState.roles, "roles": newRoles }
            setAuthorizationState({ ...authorizationState, "roles": rolesData });

        } else if (Object.keys(changedObj)[0] === "canLogin") {
            setAuthorizationState({ ...authorizationState, "canLogin": changedObj["canLogin"] });
        } else {
            const checkedRoles = authorizationState.roles.roles.length === 0 ? roles.filter(item => item.isDefaultRole) : authorizationState.roles.roles.filter(item => item.hasAssign || item.isDefaultRole)
            const checkedList = checkedRoles ? checkedRoles.map(item => item.resourceId) : [];
            const newRoles = roles.map((obj) => {
                if (checkedList.includes(obj.resourceId) || obj.isDefaultRole) {
                    return { ...obj, "hasAssign": true }
                } else {
                    return { ...obj, "hasAssign": false }
                }
            })
            const rolesData = { ...authorizationState.roles, "roles": newRoles, };
            setAuthorizationState({ ...authorizationState, "roles": rolesData });
        }
    }
    const compareRoles = (item, originalState, updatedState) => {
        let first = originalState;
        let second = updatedState;
        if (originalState && originalState.length === 0) {
            first = roles.map(item => { return { ...item, "hasAssign": item.isDefaultRole ? true : false } })
        }
        if (updatedState && updatedState.length === 0) {
            second = roles.map(item => { return { ...item, "hasAssign": item.isDefaultRole ? true : false } })
        }
        return (
            !item.isDefaultRole &&
            Number(first?.filter(r => r.resourceId === item.value)[0]?.hasAssign) !==
            Number(second?.filter(r => r.resourceId === item.value)[0]?.hasAssign)
        )
    }
    const mailVerify = () => {
        resendConfirmMail({ 'Email': personInformation.corporateEmail })
    }

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            {userRolesLoading ? <Spin></Spin> : (
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                >
                    {!corporateEmail ? (
                        <Alert
                            className="pad-bottom-10"
                            description={t('NEED_CORPORATE_EMAIL_FOR_AUTH')}
                            type="info"
                            action={
                                <Button className="ant-btn primary-btn h-32" onClick={goToCorporateEmail}>
                                    {t("GO_TO_CORPORATE_EMAIL_FIELD")}
                                </Button>
                            }
                        />
                    ) : (
                        <div className="border-none">
                            <div className={personInformation.canLogin !== authorizationState?.canLogin ? 'is-checked' : ''}>
                                <Form.Item name="canLogin" valuePropName="checked" className="mar-0">
                                    <Checkbox disabled={disableStatus}>{t('CAN_LOGIN_LBL')}</Checkbox>
                                </Form.Item>
                            </div>

                            {(personInformation.canLogin && !authorizationState?.roles?.emailVerified && !disableStatus) && <div className="red toggle-text f-14">{t('EMAIL_ADDRESS_NOT_VERIFY')}
                                {localStorage.getItem('i18nLang') === 'tr-TR' ?
                                    <React.Fragment>{t('SEND_VERIFICATION_MAIL_TEXT')}<a className="verify-link" onClick={() => mailVerify()}> {t('CLICK')}</a></React.Fragment> :
                                    <React.Fragment><a className="verify-link" onClick={() => mailVerify()}>{t('CLICK')} </a>{t('SEND_VERIFICATION_MAIL_TEXT')}</React.Fragment>
                                }
                            </div>
                            }
                            <br />
                            {authorizationState.canLogin && (
                                authorizationState?.roles?.emailVerified &&
                                <Form.Item name="userRoles" label={t('SETTINGS_ROLES')} >
                                    <Checkbox.Group>
                                        {roleCheckboxList.map(item => {
                                            return (
                                                <Row key={item.resourceId} className="w-100">
                                                    <Col size={24}>
                                                        <div className={compareRoles(item, personRoles, authorizationState.roles?.roles) ? 'is-checked' : ''}>
                                                            <Checkbox value={item.value} disabled={item.isDefaultRole || disableStatus} className="">{item.label}</Checkbox>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            )}

                        </div>
                    )}
                </Form>
            )}
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        roles: state.roles.roles,
        userRolesLoading: state.person.userRolesLoading,
        resendConfirmEmailResponse: state.person.resendConfirmEmailResponse,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        resendConfirmMail: (mail) => { dispatch(resendConfirmMail(mail)) },
        resendConfirmMailReset: () => { dispatch(resendConfirmMailReset()) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);