import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Form, DatePicker, ConfigProvider } from 'antd';
import { getInventoryByInventoryType } from '../../../redux/actions/inventory.actions';
import { getInventoryTypes } from '../../../redux/actions/inventoryType.action';
import { getPersons } from '../../../redux/actions/organization.actions';
import { dateFormatConvert, dynamicDateInputFormatter, localeLang, dateFormatList, dateFormat } from "../../../utils/config";
import { focusDateInput, blurDateInput } from '../../../utils/commonFormFunctions'

import moment from 'moment';


import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicTable from "../../dynamicTable/index";

import '../../../styles/person.scss';


function CreateOwnerShipBasicInfo(props) {

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [createOwnerShip, setCreateOwnerShip] = useState({});
    const [inventoryTypeList, setInventoryTypeList] = useState([])
    const [inventoryList, setInventoryList] = useState([])
    const [personList, setPersonList] = useState([])
    const [searchData, setSearchData] = useState()
    const [showTable, setShowTable] = useState(false)
    const [datePickerFormatType, setDatePickerFormatType] = useState({
        "assigmentDate": dateFormatList,
    })


    const { setCreateObject, getInventoryTypes, getPersons, inventoryType, profile, ownerShipDetailsDrawTable, ownerShipDetailsDrawTableLoading,
        isOwnerShipPage, persons, inventorySelectData, getInventoryByInventoryType, ownerShipData, currentDate } = props;

    const OrganizationResourceId = profile.organization?.resourceId;

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0];
        if (key === 'inventoryType') {
            if (JSON.stringify(changedObj.inventoryType) !== JSON.stringify(createOwnerShip.inventoryType || {})) {
                setInventoryList([])
                const fields = form.getFieldsValue()
                const projects = { ...fields };
                projects["inventory"] = { id: null, displayValue: null };//
                form.setFieldsValue(projects)
                setCreateOwnerShip({ ...createOwnerShip, inventoryType: changedObj.inventoryType, inventory: { id: "", displayValue: null } })

            }
        } else {
            setCreateOwnerShip({ ...createOwnerShip, ...changedObj });
        }

    }

    useEffect(() => {
        setCreateObject(createOwnerShip)
    }, [createOwnerShip]);


    useEffect(() => {
        if(props.filterService){
            getInventoryTypes({
                "searchTerm": "",
                "pagination": {
                    "pageNumber": 1,
                    "pageSize": 20,
                    "orderBy": "DisplayValue",
                    "ascending": true
                }
            }, true);
        }
        setCreateObject({ ...createOwnerShip, 'assigmentDate': moment(currentDate).format(dateFormat) })
    }, []);

    useEffect(() => {
        setInventoryTypeList(inventoryType)
    }, [inventoryType])
    useEffect(() => {
        const data = inventorySelectData && inventorySelectData.map((data) => {
            if (data?.inventoryName !== undefined) {
                return {
                    'id': data?.id,
                    'displayValue': data?.inventoryName + ' - ' + data?.serialNumber
                }
            } else {
                return {}
            }

        })
        setInventoryList(data)
    }, [inventorySelectData])
    useEffect(() => {
        if (inventorySelectData && ownerShipData?.inventoryType === undefined) {

            const filterData = inventorySelectData.filter(i => i.id === createOwnerShip.inventory?.id)
            const status = filterData[0] ? filterData[0].status?.displayValue : null
            setCreateOwnerShip({ ...createOwnerShip, 'statusName': status })
        }

    }, [createOwnerShip.inventory])
    useEffect(() => {
        setPersonList(persons);
    }, [persons]);
    useEffect(() => {
        if (ownerShipDetailsDrawTable.length === 0) {
            setShowTable(false)
        }
    }, [ownerShipDetailsDrawTable])
    useEffect(() => {
        if (ownerShipData?.inventoryType !== undefined) {

            setInventoryTypeList(ownerShipData?.inventoryType)
            setInventoryList(ownerShipData?.inventory)
            const Obj = { ...createOwnerShip, 'inventory': ownerShipData.inventory, 'inventoryType': ownerShipData.inventoryType, 'statusName': ownerShipData.statusName }
            form.setFieldsValue(Obj)
            setCreateOwnerShip(Obj);

        }

    }, [ownerShipData])
    const disabledInput = () => {
        if (ownerShipData?.inventoryType) {
            return true
        } else {
            return !createOwnerShip.inventoryType?.id
        }
    }
    const showInventoryTable = () => {
        if (ownerShipDetailsDrawTable.length > 0) {
            setShowTable(true)
        }
    }
    const paginationConfig = () => ({
        total: 3,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: 3 < 10 ? ['none', 'none'] : ['none', 'bottomLeft'],
    })
    const handleTableChange = (data) => {

        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        visibilityOptionExcludes: ['inventoryName', 'serialNumber', 'assigmentDate'],
        page: "timeOff",
        showVisibilityMenu: false
    })
    const columns = [

        {
            title: `${t('PARTNER_DEVICE_NAME')}`,
            dataIndex: 'inventoryName',
            key: 'inventoryName',
            sorter: true,

        },
        {
            title: `${t('INVENTORY_SERIAL_NUMBER')}`,
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            sorter: true,
        },
        {
            title: `${t('INVENTORY_ASSIGNMENT_DATE')}`,
            dataIndex: 'assigmentDate',
            key: 'assigmentDate',
            sorter: true,
        }

    ];

    const tableData = ownerShipDetailsDrawTable.map((row) => {
        return (
            {
                "key": row.resourceId,
                "inventoryName": row.name,
                "serialNumber": row.serialNumber,
                "assigmentDate": row.assigmentDate ? moment(row.assigmentDate).format(dateFormatConvert) : ''
            }
        );

    })

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout={isOwnerShipPage ? "vertical" : "vertical min-height-500"}
                requiredMark={true}
            >
                <Form.Item name="inventoryType" className="custom-select" label={t('DEBIT_INVENTORY_TYPE') + ' *'}>
                    <DynamicSelectbox
                        optionList={inventoryTypeList}
                        onFocus={() => getInventoryTypes({
                            "searchTerm": "",
                            "pagination": {
                                "pageNumber": 1,
                                "pageSize": 20,
                                "orderBy": "DisplayValue",
                                "ascending": true
                            }
                        }, true)}
                        placeholder={t('PARTNER_SELECT')}
                        disabled={ownerShipData?.inventoryType !== undefined}

                    />
                </Form.Item>
                <Form.Item className="custom-select" name="inventory" label={t('PARTNER_DEVICE_NAME') + ' *'}>
                    <DynamicSelectbox
                        disabled={disabledInput()}
                        optionList={inventoryList}
                        onFocus={() => getInventoryByInventoryType(createOwnerShip?.inventoryType?.id)}
                        placeholder={t('PARTNER_SELECT')}
                    />

                </Form.Item>
                {isOwnerShipPage ?

                    <Form.Item name="assigmentPerson" className="custom-select" label={t('DEBIT_TO_PERSON') + ' *'}>
                        <DynamicSelectbox
                            optionList={personList}
                            onFocus={() => getPersons(OrganizationResourceId)}
                            placeholder={t('PARTNER_SELECT')}

                        />
                    </Form.Item>
                    : ''}
                <Form.Item name="assigmentDate" className="custom-select" label={datePickerFormatType.assigmentDate === dynamicDateInputFormatter ? t('DEBIT_DATE') + " * " + t('PLACEHOLDER_DATE') : t('DEBIT_DATE') + " *"}>

                    <DatePicker
                        locale={localeLang}
                        format={datePickerFormatType.assigmentDate}
                        placeholder={t('PLACEHOLDER_DATE')}
                        defaultValue={moment(currentDate, 'DD-MM-YYYY')}
                        onFocus={() => focusDateInput(setDatePickerFormatType, datePickerFormatType, 'assigmentDate', dynamicDateInputFormatter)}
                        onBlur={() => blurDateInput(setDatePickerFormatType, datePickerFormatType, 'assigmentDate', dateFormatList)}
                    />
                </Form.Item>
                {ownerShipDetailsDrawTable.length > 0 ?
                    <p onClick={() => showInventoryTable()} className="show-other-inventory">{t('CLICK_TO_SEE_OTHER_EMBEZZLEMENTS')}</p>
                    : ''}
                {showTable ?
                    <div className="table-container table-hide-all-buttons blue-table">
                        <DynamicTable columns={columns} dataSource={tableData}
                            tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={() => null}
                            onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                            isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={ownerShipDetailsDrawTableLoading} />
                    </div>
                    : ''}
            </Form>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        persons: state.organization.persons,
        inventoryType: state.inventoryType.inventoryTypes.results,
        inventorySelectData: state.inventory.inventorySelectData,
        profile: state.profile,
        ownerShipDetailsDrawTable: state.person.ownerShipDetailsDrawTable.results,
        ownerShipDetailsDrawTableLoading: state.person.ownerShipDetailsDrawTableLoading

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInventoryTypes: (searchData, filterService) => { dispatch(getInventoryTypes(searchData, filterService)) },
        getPersons: (id) => { dispatch(getPersons(id)) },
        getInventoryByInventoryType: (id) => { dispatch(getInventoryByInventoryType(id)) }


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOwnerShipBasicInfo);