import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux'
import { Row, Col, Table, Input, Button, Space, Checkbox, Image, Dropdown, Form } from 'antd';
import { SearchOutlined, FilterOutlined, StopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../../styles/table.scss';
import { getJobFilter } from "../../redux/actions/job.actions";
import { getPersonFilter } from "../../redux/actions/person.actions";
import { getDepartmentFilter } from "../../redux/actions/department.action";
import { getOrganizationFilter } from "../../redux/actions/organization.actions";
import { getDataset, getTimeOffStateType } from "../../redux/actions/dataset-action";
import { getProvinceFilter, getDistrictFilter } from "../../redux/actions/address.actions";
import { getFilterTimeOffType } from "../../redux/actions/timeOff.actions";
import { getInventoryTypes } from "../../redux/actions/inventoryType.action";
import { taskStatesFilter } from "../../redux/actions/task.actions";
import DynamicSelectbox from "../../component/dynamicSelectbox/index";

function DynamicTable(props) {
  const { t } = useTranslation();
  const { columns, dataSource, tableConfig, paginationConfig, onTableChange, searchPlaceholder, onSearch, isShowPassives, onShowPassivesToggle, onResetAllFilters, onSaveGridSettings } = props;
  const { getPersonFilter, organizationTypes, getDataset, sectorTypes, personFilterResult, getDepartmentFilter, getOrganizationFilter, departmentFilterResult,
    parentDepartmentFilterResult, organizationFilterResult, getJobFilter, jobTitlesFilterResult, onFilter, getProvinceFilter, getDistrictFilter, districtFilterResult, provinceFilterResult,
    legalNameFilterResult, loadStatus, searchBox, passiveButton, checkboxStatus, onRowSelected, selectedRowKeys, timeOffStateTypeFilterResult, getTimeOffStateType, getFilterTimeOffType, filterTimeOffTypeData,
    getInventoryTypes, inventoryTypes, inventoryFullStateTypes, hideScroll, center, onChangeSelect, listView, taskStatesFilter, filterAssignedTaskStatusResult,
    // initialFilterData
  } = props;

  const getFilterListTimeout = useRef(null);
  const getSearchInputTimeout = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterByColumnObj, setFilterByColumnObj] = useState({});
  const [searchTextObj, setSearchTextObj] = useState({});
  const defaultGridConfig = {
    search: {
      xs: 24,
      sm: 24,
      md: 14,
      lg: 14,
      xl: 18,
      xxl: 18
    },
    button: {
      xs: 0,
      sm: 0,
      md: 10,
      lg: 10,
      xl: 6,
      xxl: 6
    }
  }
  const initialPersonSearchData = {
    "searchTerm": "",
    "persons": [],
    "jobTitles": [],
    "departments": [],
    "showPassiveRecords": false,
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "fullName",
      "ascending": true
    },
    "status": 0
  }
  const initialDepartmentSearchData = {
    "searchTerm": "",
    "departments": [],
    "organizations": [],
    "parentDepartments": [],
    "departmentManagers": [],
    "showPassiveRecords": true,
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "DepartmentName",
      "ascending": true
    },
    "status": 0
  }
  const initialGetJobFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "JobTitle",
      "ascending": true
    }
  };
  const initialGetPersonFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "fullName",
      "ascending": true
    }
  };
  const initialGetDepartmentFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "DepartmentName",
      "ascending": true
    }
  }
  const initialGetOrganizationFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "OrganizationName",
      "ascending": true
    }
  }
  const initialGetProvinceFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "Name",
      "ascending": true
    }
  }
  const initialGetDistrictFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "Name",
      "ascending": true
    }
  }
  const initialGetStateTypeFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "StateTypeName",
      "ascending": true
    }
  }
  const initialGetTimeOffTypeFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 999,
      "orderBy": "TimeOffTypeName",
      "ascending": true
    }
  }
  const initialGetInventoryTypeFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 20,
      "orderBy": "DisplayValue",
      "ascending": true
    }
  }
  const initialGetInventoryStatusFilterData = {
    "searchTerm": "",
    "pagination": {
      "pageNumber": 1,
      "pageSize": 20,
      "orderBy": "DisplayValue",
      "ascending": true
    }
  }

  var initialData = {};
  if (tableConfig.page === 'person') {
    initialData = initialPersonSearchData
  } else if (tableConfig.page === 'department') {
    initialData = initialDepartmentSearchData
  }
  const [filterData, setFilterData] = useState(initialData)
  const initialRender = useRef(true)
  const [visibleColumnList, setVisibleColumnList] = useState(columns.map(i => i.dataIndex));
  const [visibilityOptions, setVisibilityOptions] = useState(tableConfig.gridSettings)
  const [getJobFilterData, setGetJobFilterData] = useState(initialGetJobFilterData);
  const [getPersonFilterData, setGetPersonFilterData] = useState({ ...initialGetPersonFilterData, "column": "person" });
  const [getDepartmentFilterData, setGetDepartmentFilterData] = useState({ ...initialGetDepartmentFilterData, "column": "department" })
  const [getParentDepartmentFilterData, setGetParentDepartmentFilterData] = useState({ ...initialGetDepartmentFilterData, "column": "parentDepartment" })
  const [getOrganizationFilterData, setGetOrganizationFilterData] = useState({ ...initialGetOrganizationFilterData, "column": "organizationName" })
  const [getLegalNameFilterData, setGetLegalNameFilterData] = useState({ ...initialGetOrganizationFilterData, "column": "legalName" });
  const [getProvinceFilterData, setProvinceFilterData] = useState(initialGetProvinceFilterData)
  const [getDistrictFilterData, setDistrictFilterData] = useState(initialGetDistrictFilterData)
  const [getStateTypeFilterData, setStateTypeFilterData] = useState(initialGetStateTypeFilterData)
  const [getTimeOffTypeFilterData, setTimeOffTypeFilterData] = useState(initialGetTimeOffTypeFilterData)
  const [getInventoryTypeFilterData, setInventoryTypeFilterData] = useState(initialGetInventoryTypeFilterData)
  const [getInventoryStatusFilterData, setInventoryStatusFilterData] = useState(initialGetInventoryStatusFilterData)

  const handleFilterByColumn = () => {
    const persons = filterByColumnObj['fullName'] || [];
    const personsExpense = filterByColumnObj['givenName'] || [];
    const jobTitles = filterByColumnObj['jobTitle'] || [];
    const departments = filterByColumnObj['department'] || [];
    const departmentName = filterByColumnObj['departmentName'] || [];
    const organizationName = filterByColumnObj['organizationName'] || [];
    const parentDepartments = filterByColumnObj['parentDepartment'] || [];
    const departmentManagers = filterByColumnObj['departmentManager'] || [];
    const organizations = filterByColumnObj['organizationName'] || [];
    const legalName = filterByColumnObj['legalName'] || [];
    const concatOrganization = [...organizations, ...legalName];
    const organizationTypes = filterByColumnObj['organizationType'] || [];
    const organizationSectors = filterByColumnObj['sector'] || [];
    const provinces = filterByColumnObj['province'] || [];
    const districts = filterByColumnObj['district'] || [];
    const stateType = filterByColumnObj['stateType'] || [];
    const timeOffTypeName = filterByColumnObj['timeOffTypeName'] || [];
    const advanceTypes = filterByColumnObj['advanceTypes'] || [];
    const inventoryTypeName = filterByColumnObj['inventoryType'] || [];
    const InventoryTypeName = filterByColumnObj['InventoryTypeName'] || [];
    const personName = filterByColumnObj['personName'] || [];
    const state = filterByColumnObj['state'] || [];
    const reportsTo = filterByColumnObj['reportsTo'] || [];
    const appointName = filterByColumnObj['appointName'] || [];
    setFilterData({
      ...filterData, "persons": persons, "personsExpense": personsExpense, "jobTitles": jobTitles, "departments": departments, "departmentName": departmentName, "organizationName": organizationName, "organizations": concatOrganization, "parentDepartments": parentDepartments, "departmentManagers": departmentManagers, "organizationTypes": organizationTypes, "organizationSectors": organizationSectors, "provinces": provinces,
      "districts": districts, "stateType": stateType, "timeOffTypeName": timeOffTypeName, "advanceTypes": advanceTypes, "inventoryType": inventoryTypeName, "InventoryTypeName": InventoryTypeName, "personName": personName, "state": state, "reportsTo": reportsTo, "appointName": appointName
    })
  }
  const handleFilterByColumnChange = (e, label, dataIndex) => {
    if (e.target.checked) {
      if (filterByColumnObj[dataIndex]) {
        let arr = [...filterByColumnObj[dataIndex], label]
        setFilterByColumnObj({ ...filterByColumnObj, [dataIndex]: arr })
      } else {
        let arr = [label]
        setFilterByColumnObj({ ...filterByColumnObj, [dataIndex]: arr })
      }
    } else {
      let temp = [...filterByColumnObj[dataIndex]].filter(item => item !== label)
      if (temp.length === 0) {
        let tempobj = { ...filterByColumnObj }
        delete tempobj[dataIndex]
        setFilterByColumnObj(tempobj)
      } else {
        setFilterByColumnObj({ ...filterByColumnObj, [dataIndex]: temp })
      }
    }
  }
  const handleFilterSearchInputChange = (e, dataIndex) => {
    // setSearchText(e.target.value);
    setSearchedColumn(dataIndex);
    const key = e.target.value;
    // let arr = [...searchTextObj[dataIndex],key]
    setSearchTextObj({ ...searchTextObj, [dataIndex]: key })
  }
  const handleResetFilterByColumn = (dataIndex) => {
    setSearchedColumn("");
    setGetJobFilterData(initialGetJobFilterData);
    setGetPersonFilterData({ ...initialGetPersonFilterData, "column": "person" });
    setGetDepartmentFilterData({ ...initialGetDepartmentFilterData, "column": "department" });
    setGetParentDepartmentFilterData({ ...initialGetDepartmentFilterData, "column": "parentDepartments" })
    setGetOrganizationFilterData({ ...initialGetOrganizationFilterData, "column": "organizationName" });
    setGetLegalNameFilterData({ ...initialGetOrganizationFilterData, "column": "legalName" });
    setProvinceFilterData(initialGetProvinceFilterData);
    setDistrictFilterData(initialGetDistrictFilterData);
    setStateTypeFilterData(initialGetStateTypeFilterData);
    setTimeOffTypeFilterData(initialGetTimeOffTypeFilterData);
    setInventoryTypeFilterData(initialGetInventoryTypeFilterData);
    setInventoryStatusFilterData(initialGetInventoryStatusFilterData);
    if (dataIndex) {
      let tempFilterObj = { ...filterByColumnObj }
      delete tempFilterObj[dataIndex]
      setFilterByColumnObj(tempFilterObj)
      let tempSearchObj = { ...searchTextObj }
      delete tempSearchObj[dataIndex]
      setSearchTextObj(tempSearchObj);
    } else {
      setFilterByColumnObj({})
      setSearchTextObj({});
    }
  };
  const getFilterList = (dataIndex) => {
    if (dataIndex === 'jobTitle') {
      return jobTitlesFilterResult
    } else if (dataIndex === 'fullName') {
      return personFilterResult
    } else if (dataIndex === 'givenName') {
      return personFilterResult.map((item) => { return { "givenName": item.fullName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'departmentManager') {
      return personFilterResult.map((item) => { return { "departmentManager": item.fullName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'department') {
      return departmentFilterResult.map((item) => { return { "department": item.departmentName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'departmentName') {
      return departmentFilterResult
    } else if (dataIndex === 'organizationName') {
      return organizationFilterResult
    } else if (dataIndex === 'legalName') {
      return legalNameFilterResult.filter((item) => { return item.legalName !== null });
    } else if (dataIndex === 'organizationType') {
      return organizationTypes.map((item) => { return { "organizationType": item.displayValue, "resourceId": item.id } })
    } else if (dataIndex === 'sector') {
      return sectorTypes.map((item) => { return { "sector": item.displayValue, "resourceId": item.id } })
    } else if (dataIndex === 'parentDepartment') {
      return parentDepartmentFilterResult.map((item) => { return { "parentDepartment": item.departmentName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'province') {
      return provinceFilterResult.map((item) => { return { ...item, "province": item.name } })
    } else if (dataIndex === 'district') {
      return districtFilterResult.map((item) => { return { ...item, "district": item.name } })
    } else if (dataIndex === 'stateType') {
      if (['myAdvanceRequest', 'allAdvanceRequest', 'approveAdvance'].includes(tableConfig.page)) {
        const staticData = [
          {
            'stateTypeName': t('OPEN'),
            'resourceId': 'F518EDSD',
          },
          {
            'stateTypeName':  t('CLOSED'),
            'resourceId': 'F5218EDSD',
          },
        ];
        var advanceTypesFilterResult = [...timeOffStateTypeFilterResult];
        advanceTypesFilterResult = advanceTypesFilterResult.concat(staticData);
        return advanceTypesFilterResult.map((item) => { return { "stateType": item.stateTypeName, "resourceId": item.resourceId } })
      }
      else if (['MyExpenses', 'ApproveExpense', 'ExpenseAll'].includes(tableConfig.page)) {
        const staticData = [
          {
            'stateTypeName': t('PROCESSED'),
            'resourceId': 'F518EDSD',
          }
        ];
        var expenseTypesFilterResult = [...timeOffStateTypeFilterResult];
        expenseTypesFilterResult = staticData.concat(expenseTypesFilterResult);
        return expenseTypesFilterResult.map((item) => { return { "stateType": item.stateTypeName, "resourceId": item.resourceId } })
      } else if (['assignedTasks', 'myTasks'].includes(tableConfig.page)) {
        return filterAssignedTaskStatusResult?.map((item) => { return { "stateType": item.name, "resourceId": item.id } })
      } else {
        return timeOffStateTypeFilterResult.map((item) => { return { "stateType": item.stateTypeName, "resourceId": item.resourceId } })
      }
    } else if (dataIndex === 'timeOffTypeName') {
      return filterTimeOffTypeData.map((item) => { return { "timeOffTypeName": item.timeOffTypeName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'inventoryType') {
      return inventoryTypes?.map((item) => { return { "inventoryType": item.displayValue, "resourceId": item.resourceId } })
    } else if (dataIndex === 'InventoryTypeName') {
      return inventoryTypes?.map((item) => { return { "InventoryTypeName": item.displayValue, "resourceId": item.resourceId } })
    }
    else if (dataIndex === 'personName') {
      return personFilterResult.map((item) => { return { "personName": item.fullName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'state') {
      return inventoryFullStateTypes?.map((item) => { return { "state": item.displayValue, "resourceId": item.id } })
    } else if (dataIndex === 'reportsTo') {
      return personFilterResult.map((item) => { return { "reportsTo": item.fullName, "resourceId": item.resourceId } })
    } else if (dataIndex === 'appointName') {
      return personFilterResult.map((item) => { return { "appointName": item.fullName, "resourceId": item.resourceId } })
    } else {
      return []
    }
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="dynamic-table-filter-container" style={{ padding: 8 }}>
        {dataIndex !== 'organizationType' && dataIndex !== 'sector' && dataIndex !== 'state' && dataIndex !== 'stateType' ?
          <Input
            placeholder={t('PARTNER_SELECT')}
            value={searchTextObj[dataIndex]}
            onChange={(e) => handleFilterSearchInputChange(e, dataIndex)}
            allowClear
          /> : ''}
        <div style={{ paddingBottom: 10 }} className="filter-options-div dynamic-table-filter-container">
          {
            getFilterList(dataIndex).length > 0 ? getFilterList(dataIndex)?.map((row) => {
              return (
                <Row key={row.resourceId}>
                  <Col span={24}>
                    <Checkbox checked={filterByColumnObj[dataIndex] && filterByColumnObj[dataIndex].includes(row.resourceId)} onChange={(e) => handleFilterByColumnChange(e, row.resourceId, dataIndex)}>{row[dataIndex]}</Checkbox>
                  </Col>
                </Row>
              )
            }) : ''
          }
        </div>
        <Space className="filter-button-class dynamic-table-filter-container">
          <Button
            className="white-btn h-32 f-14"
            onClick={() => handleResetFilterByColumn(dataIndex)}>
            {t('PARTNER_CLEAR')}
          </Button>
          <Button
            className="primary-btn h-32 f-14"
            onClick={() => handleFilterByColumn()}
          >
            {t('PARTNER_APPLY')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    }
  });
  useEffect(() => {
    if (getFilterListTimeout.current !== null) {
      clearTimeout(getFilterListTimeout.current);
    }
    getFilterListTimeout.current = setTimeout(() => {
      getFilterListTimeout.current = null;
      if (searchTextObj[searchedColumn]?.length > 1 || searchTextObj[searchedColumn]?.length === 0) {
        if (searchedColumn === 'jobTitle') {
          setGetJobFilterData({ ...getJobFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'fullName' || searchedColumn === 'givenName') {
          setGetPersonFilterData({ ...getPersonFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "person" })
        } else if (searchedColumn == 'departmentManager') {
          setGetPersonFilterData({ ...getPersonFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (['department', 'departmentName'].includes(searchedColumn)) {
          var test = { ...getDepartmentFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "department" }
          setGetDepartmentFilterData(test)
        } else if (searchedColumn === 'parentDepartment') {
          setGetParentDepartmentFilterData({ ...getParentDepartmentFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "parentDepartment" })
        } else if (searchedColumn === 'organizationName') {
          setGetOrganizationFilterData({ ...getOrganizationFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "organizationName" })
        } else if (searchedColumn === 'legalName') {
          setGetLegalNameFilterData({ ...getLegalNameFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "legalName" })
        } else if (searchedColumn === 'province') {
          setProvinceFilterData({ ...getProvinceFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'district') {
          setDistrictFilterData({ ...getDistrictFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'stateType') {
          setStateTypeFilterData({ ...getStateTypeFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'timeOffTypeName' || searchedColumn === 'advanceTypes') {
          setTimeOffTypeFilterData({ ...getTimeOffTypeFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'inventoryType') {
          setInventoryTypeFilterData({ ...getInventoryTypeFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'InventoryTypeName') {
          setInventoryTypeFilterData({ ...getInventoryTypeFilterData, "searchTerm": searchTextObj[searchedColumn] })
        } else if (searchedColumn === 'personName') {
          setGetPersonFilterData({ ...getPersonFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "person" })
        } else if (searchedColumn === 'reportsTo') {
          setGetPersonFilterData({ ...getPersonFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "person" })
        } else if (searchedColumn === 'appointName') {
          setGetPersonFilterData({ ...getPersonFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "person" })
        } else if (searchedColumn === 'state') {
          setInventoryStatusFilterData({ ...getInventoryStatusFilterData, "searchTerm": searchTextObj[searchedColumn], "column": "state" })
        }
      }
    }, 1000);
  }, [searchTextObj, searchedColumn]);

  useEffect(() => {
    if (tableConfig.page === 'person') {
      getJobFilter(getJobFilterData)
    }
  }, [getJobFilterData])

  useEffect(() => {
    if (tableConfig.page === 'organization') {
      getDataset([19, 21])
    }
  }, [])

  useEffect(() => {
    if (["department", "person", "ownership-list", "inventory", "allTimeOffRequest", "timeoffPerson",
      "timeOffRemainderYears", "employeeTimeOff", "allAdvanceRequest", "approveAdvance", "ApproveExpense", "ExpenseAll", "assignedTasks", "myTasks",
      "ApprovalTravelRequests", "AllTravelRequests"].includes(tableConfig.page)) {
      getPersonFilter(getPersonFilterData)
    }
  }, [getPersonFilterData])

  useEffect(() => {
    if (tableConfig.page === 'organization') {
      getProvinceFilter(getProvinceFilterData)
    }
  }, [getProvinceFilterData])
  useEffect(() => {
    if (tableConfig.page === 'organization') {
      getDistrictFilter(getDistrictFilterData)
    }
  }, [getDistrictFilterData])
  useEffect(() => {
    if (["department", "person", "timeOffRemainderYears"].includes(tableConfig.page)) {
      getDepartmentFilter(getDepartmentFilterData)
    }
  }, [getDepartmentFilterData])
  useEffect(() => {
    if (tableConfig.page === 'department') {
      getDepartmentFilter(getParentDepartmentFilterData)
    }
  }, [getParentDepartmentFilterData])

  useEffect(() => {
    if (["organization"].includes(tableConfig.page)) {
      getOrganizationFilter(getOrganizationFilterData)
    }
  }, [getOrganizationFilterData])
  useEffect(() => {
    if (tableConfig.page === 'organization') {
      getOrganizationFilter(getLegalNameFilterData)
    }
  }, [getLegalNameFilterData])
  useEffect(() => {
    if (["timeOffRequestMy", "allTimeOffRequest", "timeoffPerson", "employeeTimeOff",
      "allAdvanceRequest", "approveAdvance", "myAdvanceRequest", "MyExpenses", "ApproveExpense", "ExpenseAll",
      "TravelRequests", "ApprovalTravelRequests", "AllTravelRequests", "MyTravelHostRequest", "ApprovalTravelHostRequest",
      "AllTravelHostRequest"].includes(tableConfig.page)) {
      getTimeOffStateType(getStateTypeFilterData)
    }
  }, [getStateTypeFilterData])
  useEffect(() => {
    if (["timeOffRequestMy", "allTimeOffRequest", "timeoffPerson", "employeeTimeOff"].includes(tableConfig.page)) {
      getFilterTimeOffType(getTimeOffTypeFilterData)
    }
  }, [getTimeOffTypeFilterData])
  useEffect(() => {
    if (["ownership-list", "inventory"].includes(tableConfig.page)) {
      getInventoryTypes(getInventoryTypeFilterData)
    }
  }, [getInventoryTypeFilterData])
  useEffect(() => {
    if (tableConfig.page === 'inventory') {
      getDataset(26)
    }
  }, [getInventoryStatusFilterData])
  useEffect(() => {
    if (["assignedTasks", "myTasks"].includes(tableConfig.page)) {
      taskStatesFilter();
    }
  }, [])

  const getColumnSortingProps = dataIndex => ({
    sorter: true
  });

  let getColumns = () => columns.map((row) => {
    return {
      "title": row.title,
      "dataIndex": row.dataIndex,
      "key": row.key || row.dataIndex,
      "render": row.render,
      "className": row.className,
      "defaultSortOrder": "ascend",
      "sortDirections": ['ascend', 'descend', 'ascend'],
      "showSorterTooltip": false,
      ...(tableConfig.filterableColumns.includes(row.dataIndex) ? getColumnSearchProps(row.dataIndex) : false),
      ...(tableConfig.sortableColumns.includes(row.dataIndex) ? getColumnSortingProps(row.dataIndex) : false),
      "align": row.align
    }
  }).filter(row => { return visibleColumnList.includes(row.dataIndex) });

  const handleColumnVisibility = item => {
    const items = JSON.parse(JSON.stringify([...visibilityOptions]));
    const index = items.findIndex(i => i.columnName === item.target.id);
    items[index].isVisible = item.target.checked;
    setVisibilityOptions(items)
  };
  const getColumnTitle = (column) => {
    return columns.find((col) => col.dataIndex === column)?.title
  }

  useEffect(() => {
    if (tableConfig.showVisibilityMenu !== false) {
      const visibles = visibilityOptions.filter((i) => { return i.isVisible === true }).map((i) => { return i.columnName })
      setVisibleColumnList(visibles)
    }
  }, [visibilityOptions])

  useEffect(() => {
    setVisibilityOptions(tableConfig.gridSettings)
  }, [tableConfig.gridSettings])

  const isVisibilityOptionDisabled = item => {
    return visibleColumnList.length === 1 && visibleColumnList.includes(item)
  }

  const handleCloseVisibilityMenu = (e) => {
    setVisibilityMenuStatus(false)
  }
  const handleSaveGridSettings = () => {
    onSaveGridSettings(visibilityOptions)
    setVisibilityMenuStatus(false)
  }
  const columnVisibilityMenu = (

    <div className="ant-table-filter-dropdown visibility-column-filter-dropdown" style={{ padding: 8 }}>
      <div style={{ paddingBottom: 10 }}>
        {
          tableConfig.showVisibilityMenu !== false ?
            visibilityOptions.map((row) => {
              return (
                <Row key={row.columnName}>
                  <Col span={24}>
                    <Checkbox id={row.columnName} disabled={isVisibilityOptionDisabled(row.columnName)} onChange={handleColumnVisibility} checked={row.isVisible || false}>{getColumnTitle(row.columnName)}</Checkbox>
                  </Col>
                </Row>)
            })
            : ''
        }
      </div>
      <Space className="filter-button-class">
        <Button className="white-btn h-32 f-14" onClick={handleCloseVisibilityMenu} >
          {t('PARTNER_CANCEL')}
        </Button>
        <Button
          onClick={handleSaveGridSettings}
          className="primary-btn h-32 f-14"
        >
          {t('PARTNER_SAVE')}
        </Button>
      </Space>
    </div>
  );

  const [visibilityMenuStatus, setVisibilityMenuStatus] = useState(false)

  const handleVisibilityMenuVisibleChange = (flag) => {
    setVisibilityMenuStatus(flag)
  }


  const handleTableChange = (paging, filter, sorter) => {
    onTableChange({ "paging": paging, "filter": filter, "sorter": sorter })

    // SORT ORDER CONTROL 
    // if (initialFilterData) {
    //   let obj = {
    //     'ascending': sorter?.order ? sorter.order === 'ascend' ? true : false : initialFilterData?.pagination?.ascending,
    //     'orderBy': sorter?.columnKey ? sorter?.columnKey : initialFilterData?.pagination?.orderBy
    //   }
    //   setUpdatePaginationObj(obj);
    // }

  }
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onRowSelected || null,
    getCheckboxProps: tableConfig.checkboxConfig || null,
  };
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value
    setSearchText(searchKey)
    if (getSearchInputTimeout.current !== null) {
      clearTimeout(getSearchInputTimeout.current);
    }
    getSearchInputTimeout.current = setTimeout(() => {
      getSearchInputTimeout.current = null;
      if (searchKey.length > 1 || searchKey.length === 0) {
        onSearch(searchKey)
      }
    }, 800);
  }

  useEffect(() => {
    if (!initialRender.current) {
      onFilter(filterData)
    } else {
      initialRender.current = false
    }
  }, [filterData])

  const handleIsShowPassives = () => {
    onShowPassivesToggle(!isShowPassives)

  }
  const defaultPaginationObj = () => {

    // SORT ORDER CONTROL
    // if (initialFilterData) {
    //   let obj = {
    //     'ascending': initialFilterData?.pagination?.ascending,
    //     'orderBy': initialFilterData?.pagination?.orderBy
    //   }
    //   return setUpdatePaginationObj(obj);
    // }
  }
  const handleResetAllFilters = () => {
    handleResetFilterByColumn();
    onResetAllFilters();
    setSearchText('');
    defaultPaginationObj();
  }
  useEffect(() => {
    defaultPaginationObj();
  }, [])
  const noData = () => {
    return (
      <div className="table-empty-container">
        <div className="table-empty-image">
          <Image src={process.env.REACT_APP_URL + "images/empty-text.png"} preview={false} />
        </div>
        <div className="table-empty-text">
          <h1> {tableConfig.emptyTableText ? tableConfig.emptyTableText : t('NO_DATA_TEXT')}</h1>
        </div>

      </div>
    )
  }

  const filterListSelect = (value) => {
    onChangeSelect(value.id)
  }

  return (
    <>
      <Row>
        {(tableConfig.page !== "timeOffRequestMy" && tableConfig.leftActionMenu) || null}
        <div className="table-filter">
          {searchBox !== false ?
            <Col xs={{ span: defaultGridConfig.search.xs }} sm={{ span: defaultGridConfig.search.sm }} md={{ span: defaultGridConfig.search.md }} lg={{ span: defaultGridConfig.search.lg }} xl={{ span: defaultGridConfig.search.xl }} xxl={{ span: defaultGridConfig.search.xxl }}  >
              <div className="table-search">
                <Input placeholder={searchPlaceholder} allowClear value={searchText} onChange={(e) => handleSearchInputChange(e)} prefix={<SearchOutlined />} />
              </div>
            </Col>
            :
            tableConfig.page === "timeOffRequestMy" ?

              <Col xs={{ span: defaultGridConfig.search.xs }} sm={{ span: defaultGridConfig.search.sm }} md={{ span: defaultGridConfig.search.md }} lg={{ span: defaultGridConfig.search.lg }} xl={{ span: defaultGridConfig.search.xl }} xxl={{ span: defaultGridConfig.search.xxl }} >
                {tableConfig.leftActionMenu}
              </Col>
              :
              <Col xs={{ span: defaultGridConfig.search.xs }} sm={{ span: defaultGridConfig.search.sm }} md={{ span: defaultGridConfig.search.md }} lg={{ span: defaultGridConfig.search.lg }} xl={{ span: defaultGridConfig.search.xl }} xxl={{ span: defaultGridConfig.search.xxl }} />
          }
          <Col xs={{ span: defaultGridConfig.button.xs }} sm={{ span: defaultGridConfig.button.sm }} md={{ span: defaultGridConfig.button.md }} lg={{ span: defaultGridConfig.button.lg }} xl={{ span: defaultGridConfig.button.xl }} xxl={{ span: defaultGridConfig.button.xxl }} >
            <div className="flex-end" >
              {tableConfig.page === 'ownership-list' ?
                <Button className="transparent-btn margin-r-24" onClick={() => handleIsShowPassives()} icon={<StopOutlined />}>{isShowPassives === false ? t('ASSIGNMENT_SHOW_RETURNED') : t('ASSIGNMENT_HIDE_RETURNED')}</Button>
                : ''}
              {passiveButton !== false && tableConfig.page != 'person' ?
                <Button className="transparent-btn margin-r-24" onClick={() => handleIsShowPassives()} icon={<StopOutlined />}>{isShowPassives === false ? t('PARTNER_SHOW_PASSIVIES') : t('PARTNER_HIDE_PASSIVIES')}</Button>
                : ''}
              {passiveButton !== false && tableConfig.page == 'person' && listView ?
                  <div name="case" className="custom-select select-width person-selectbox" >
                    <DynamicSelectbox
                      optionList={[
                        {
                          id: 4,
                          displayValue: t('ALL')
                        },
                        {
                          id: 1,
                          displayValue: t('PARTNER_ACTIVE')
                        },
                        {
                          id: 2,
                          displayValue: t('PARTNER_PASSIVE')
                        },
                        {
                          id: 3,
                          displayValue: t('EMPLOYMENT_TERMINATED')
                        }
                      ]}
                      placeholder={t('PARTNER_SELECT')}
                      onChange={filterListSelect}
                      defaultValue={t('PARTNER_ACTIVE')}
                    />
                  </div>

                : ''}
              {/*
            // SORT ORDER CONTROL 
              {initialFilterData ?
                (Object.keys(filterByColumnObj).length > 0 || initialFilterData?.pagination?.ascending !== updatePaginationObj?.ascending || initialFilterData?.pagination?.orderBy !== updatePaginationObj?.orderBy)
                  ?
                  <Button className="transparent-btn margin-r-24" onClick={() => handleResetAllFilters()} icon={<FilterOutlined />}>{t('PARTNER_REMOVE_FILTER')}</Button>
                  :
                  ''
                :
                (searchText !== '' || JSON.stringify(filterByColumnObj) !== '{}') ?
                  <Button className="transparent-btn margin-r-24" onClick={() => handleResetAllFilters()} icon={<FilterOutlined />}>{t('PARTNER_REMOVE_FILTER')}</Button>
                  :
                  ''
              } */}
              {(Object.keys(filterByColumnObj).length > 0) ?
                <Button id="remove-expense-filter-button" className="transparent-btn margin-r-24" onClick={() => handleResetAllFilters()} icon={<FilterOutlined />}>{t('PARTNER_REMOVE_FILTER')}</Button>
                :
                ''
              }

              {tableConfig.showVisibilityMenu !== false ?
                <Dropdown trigger={['click']} overlay={columnVisibilityMenu} onVisibleChange={handleVisibilityMenuVisibleChange} visible={visibilityMenuStatus}>
                  <Button className="transparent-btn" icon={<PlusCircleOutlined />}>{t('PARTNER_HIDE_SHOW_COLUMNS')}</Button>
                </Dropdown> : ''
              }
            </div>
          </Col>
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <div className="table-container">
            {checkboxStatus === false ?
              <Table className={dataSource.length === 0 ? 'table-border-bottom-0' : ''} locale={{ emptyText: (noData()) }} loading={loadStatus} bordered={true} columns={dataSource.length > 0 ? getColumns() : null} dataSource={dataSource} onChange={(a, b, c) => handleTableChange(a, b, c)} pagination={paginationConfig} scroll={hideScroll === true ? false : { x: 1500 }} align={center ? 'center' : 'center'} />
              :
              <Table className={dataSource.length === 0 ? 'table-border-bottom-0' : ''} locale={{ emptyText: (noData()) }} loading={loadStatus} bordered={true} rowSelection={{ ...rowSelection }} columns={dataSource.length > 0 ? getColumns() : null} dataSource={dataSource} onChange={(a, b, c) => handleTableChange(a, b, c)} pagination={paginationConfig} scroll={hideScroll === true ? false : { x: 1500 }} align={center ? 'center' : 'center'} />
            }
          </div>
        </Col>
      </Row>
    </>
  )
}
const mapStateToProps = function (state) {
  return {
    personFilterResult: state.person.personFilterResult.results,
    departmentFilterResult: state.departmentReducer.departmentFilterResult.results,
    parentDepartmentFilterResult: state.departmentReducer.parentDepartmentFilterResult.results,
    organizationFilterResult: state.organization.organizationFilterResult.results,
    legalNameFilterResult: state.organization.legalNameFilterResult.results,
    jobTitlesFilterResult: state.job.jobTitlesFilterResult.results,
    organizationTypes: state.dataset.organizationTypes,
    sectorTypes: state.dataset.sectorTypes,
    provinceFilterResult: state.addressReducer.provinceFilterResult.results,
    districtFilterResult: state.addressReducer.districtFilterResult.results,
    timeOffStateTypeFilterResult: state.dataset.timeOffStateType.results,
    filterTimeOffTypeData: state.timeOff.filterTimeOffTypeData.results,
    inventoryTypes: state.inventoryType.inventoryTypes.results,
    inventoryFullStateTypes: state.dataset.inventoryFullStateTypes,
    filterAssignedTaskStatusResult: state.taskReducer.filterAssignedTaskStatusResult.items
  }
}
const mapDispatchToProps = function (dispatch) {
  return {
    getPersonFilter: (props) => { dispatch(getPersonFilter(props)) },
    getDepartmentFilter: (props) => { dispatch(getDepartmentFilter(props)) },
    getOrganizationFilter: (props) => { dispatch(getOrganizationFilter(props)) },
    getJobFilter: (props) => { dispatch(getJobFilter(props)) },
    getDataset: (props) => { dispatch(getDataset(props)) },
    getProvinceFilter: (props) => { dispatch(getProvinceFilter(props)) },
    getDistrictFilter: (props) => { dispatch(getDistrictFilter(props)) },
    getTimeOffStateType: (searchData) => { dispatch(getTimeOffStateType(searchData)) },
    getFilterTimeOffType: (searchData) => { dispatch(getFilterTimeOffType(searchData)) },
    getInventoryTypes: (searchData) => { dispatch(getInventoryTypes(searchData)) },
    taskStatesFilter: () => { dispatch(taskStatesFilter()) },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DynamicTable)