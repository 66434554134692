import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    BrowserRouter as Router,
    useLocation,
    useHistory
} from "react-router-dom";

import {
    getTimeOffTypesList, timeOffApprove, timeOffReject, deleteTimeOffRequest, timeOffCancel,
    getTimeOffRequest, getTimeOffRequestStep, resetTimeOffDownload, resetDownloadAgreementForm
} from '../../redux/actions/timeOff.actions'
import { setTimeOffAddDrawer } from '../../redux/actions/time-off-drawer-action';
import { resetTimeOffDashboardPaiChartData, getTimeOffDashboardPaiChartData } from '../../redux/actions/dashboard.actions'

import { Drawer, Tabs, Layout, Col, Form, ConfigProvider, Input } from 'antd';
import { ToastContainer } from 'react-toastify';
import { drawerWidth } from '../../utils/config';
import { dateFormatConvert } from "../../utils/config"

import BreadCrumb from '../../features/breadcrump/breadcrump';
import moment from 'moment'
import 'moment/locale/tr';

import TimeOffRequestMy from '../../component/timeOffTabs/timeOffRequestMy'
import TimeOffRequestPerson from '../../component/timeOffTabs/timeOffRequestPerson'
import TimeOffRequestPeopleOnLeave from '../../component/timeOffTabs/timeOffRequestPeopleOnLeave'
import AllTimeOffRequest from '../../component/timeOffTabs/timeOffAll';
import TimeOffRemainderYears from '../../component/timeOffTabs/timeOffRemainderYears'
import TimeOffAproveTabs from '../../component/timeOffTabs/timeOffRequestPerson/timeOffApproveDrawer/timeOffApproveTabs';
import NewTimeOffRequestTabs from '../../component/timeOffTabs/add/time-off-all-tabs';

import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import DynamicTimeOffModal from '../../component/dynamicTimeOffModal';
import DynamicRequestModal from '../../component/dynamicModal/requestModal';

import { UilCheckCircle, UilTimesCircle, UilQuestionCircle } from '@iconscout/react-unicons';

import '../../styles/time-off.scss';

function TimeOff(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [cancelForm] = Form.useForm();
    const location = useLocation();
    const { getTimeOffTypesList, permissions, getTimeOffDashboardPaiChartData,
        timeOffDrawerReducer, timeOffApprove, timeOffReject, deleteTimeOffRequest, timeOffCancel, profile, resetTimeOffDashboardPaiChartData,
        timeOffExportDataResponse, resetTimeOffDownload, setTimeOffAddDrawer, agreementFormDataResponse, resetDownloadAgreementForm,
        timeOffApproveResponse } = props;
    const initialTimeOffRequestMy = {
        "searchTerm": "",
        "timeOffTypes": [],
        "personResourceId": profile?.resourceId,
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "timeOffRequestDate",
            "ascending": false
        }
    }
    const allTimeOffSearchData = {
        "searchTerm": "",
        "timeOffTypes": [],
        "stateTypes": [],
        "persons": [],
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "timeOffRequestDate",
            "ascending": false
        }
    }
    const initialRequestPersonSearchData = {
        "searchTerm": "",
        "timeOffTypes": [],
        "persons": [],
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "requestDate",
            "ascending": false
        }
    }
    const peopleOnLeaveInitialSearchData = {
        "searchTerm": "",
        "persons": [],
        "timeOffTypes": [],
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "StartDate",
            "ascending": true
        }
    }
    const remainderYearsInitialSearchData = {
        "searchTerm": "",
        "persons": [],
        "departments": [],
        "showPassiveRecords": true,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "fullName",
            "ascending": true
        }
    }
    const [peopleOnLeaveSearchData, setPeopleOnLeaveSearchData] = useState(peopleOnLeaveInitialSearchData)
    const [currentTab, setCurrentTab] = useState(location.pathname.split('/time-off/')[1]);
    const [ApproveModalData, setApproveModalData] = useState({ "isVisible": false })
    const [selectedRowKeys, setselectedRowKeys] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [deleteModalData, setDeleteModalData] = useState({ "isVisible": false })
    const [cancelModalData, setCancelModalData] = useState({ "isVisible": false, 'description': '' })
    const [searchData, setSearchData] = useState(initialRequestPersonSearchData)
    const [selectedRows, setselectedRows] = useState([]);
    const [componentKey, setcomponentKey] = useState(0)
    const [componentKeyTimeoffAdd, setComponentKeyTimeoffAdd] = useState(3500)
    const key = "timeOffDrawerTabs"
    function callback(key) {
        setCurrentTab(key);
    }
    useEffect(() => {
        if (timeOffExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': JSON.parse(timeOffExportDataResponse.message).message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetTimeOffDownload();
        }
    }, [timeOffExportDataResponse])
    useEffect(() => {
        if (agreementFormDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': JSON.parse(agreementFormDataResponse.message).message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetDownloadAgreementForm();
        }
    }, [agreementFormDataResponse])
    useEffect(() => {
        setCurrentTab(location.pathname.split('/time-off/')[1]);
    }, [location]);
    useEffect(() => {
        if (currentTab === 'me') {
            getTimeOffDashboardPaiChartData(profile.resourceId, 'FH25DW56DYU5')
        } else if (currentTab === 'TimeOffTypes') {
            getTimeOffTypesList()
        } else if (currentTab === 'remainder-years') {
            resetTimeOffDashboardPaiChartData()
        }
    }, [currentTab])
    useEffect(() => {
        if (timeOffDrawerReducer.timeOffRequestAddDrawer) {
            setComponentKeyTimeoffAdd((i) => i + 1)
        }
    }, [timeOffDrawerReducer])
    useEffect(() => {
        if (timeOffApproveResponse.status === 'SUCCESS') {
            cancelForm.resetFields();
        }
    }, [timeOffApproveResponse])
    const handleTimeOffChangeStatus = (statusObj, decision, isApprovePage) => {

        if (decision === 'APPROVE') {
            var modalDesc = '';
            if (statusObj.personResourceId === profile.resourceId || isApprovePage) {
                modalDesc = t('TIMEOFF_SINGLE_APPROVE_TEXT', { "person": statusObj.fullName, "requestDate": moment(statusObj.requestDate).format(dateFormatConvert), "timeOffTypeName": statusObj.timeOffTypeName })
            } else {
                modalDesc = t('TIMEOFF_SINGLE_APPROVE_TEXT_NEW', { "person": statusObj.fromName });
            }
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilCheckCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('TIME_OFF_REQUEST_CONFIRM'),
                "confirmBtnFunc": "SINGLE_APPROVE",
                "modalTitle": t('TIME_OFF_REQUEST_CONFIRM'),
                "modalDesc": modalDesc,
                "balanceDesc": statusObj?.timeOffTypeId === 1 ? t('TIMEOFF_SINGLE_APPROVE_BALANCE', { "balance": statusObj?.timeOffInfoRemainderBalance }) : null,
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj.id,
                "approveSeqNumber": statusObj.approveSeqNumber,
                "description": null,
                'descriptionShow': true
            })
        } else if (decision === 'REJECT') {
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilTimesCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('TIME_OFF_REQUEST_REJECT'),
                "confirmBtnFunc": "SINGLE_REJECT",
                "modalTitle": t('TIME_OFF_REQUEST_REJECT'),
                "modalDesc": t('TIMEOFF_SINGLE_REJECT_TEXT', { "person": statusObj.fullName, "requestDate": moment(statusObj.requestDate).format(dateFormatConvert), "timeOffTypeName": statusObj.timeOffTypeName }),
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj.id,
                "approveSeqNumber": statusObj.approveSeqNumber,
                "description": null,
                'descriptionShow': true
            })
        } else if (decision === 'CANCEL') {
            setApproveModalData({
                ...ApproveModalData,
                "onModalCancel": handleCloseModal,
                "icon": <UilQuestionCircle className="dynamic-modal-icon" size={50} />,
                "cancelBtnLbl": t('PARTNER_NO_CANCEL'),
                "cancelBtnFunc": handleCloseModal,
                "confirmBtnLbl": t('PARTNER_YES_CANCEL'),
                "confirmBtnFunc": "SINGLE_CANCEL",
                "modalTitle": t('TIME_OFF_CANCEL_BTN'),
                "modalDesc": "",
                "handleValuesChange": handleApproveFormValuesChange,
                "isVisible": true,
                "id": statusObj,
                "approveSeqNumber": null,
                "description": null,
                'descriptionShow': false
            })
        } else if (decision === 'BULK_APPROVE') {
            if (selectedRowKeys.length > 0) {
                setApproveModalData({
                    ...ApproveModalData,
                    "onModalCancel": handleCloseModal,
                    "icon": <UilCheckCircle className="dynamic-modal-icon" size={50} />,
                    "cancelBtnLbl": t('PARTNER_CANCEL'),
                    "cancelBtnFunc": handleCloseModal,
                    "confirmBtnLbl": t('TIME_OFF_REQUEST_CONFIRM'),
                    "confirmBtnFunc": "BULK_APPROVE",
                    "modalTitle": t('TIME_OFF_REQUEST_CONFIRM'),
                    "modalDesc": t('TIMEOFF_BULK_APPROVE_TEXT', { "count": selectedRowKeys.length }),
                    "handleValuesChange": handleApproveFormValuesChange,
                    "isVisible": true,
                    "id": null,
                    "approveSeqNumber": null,
                    "description": null,
                    'descriptionShow': true
                })
            } else {
                DynamicToastMessage({
                    'key': 'BULK_APPROVE_NOT_SELECTED',
                    'title': t('WARNING'),
                    'description': t('NOT_SELECTED_ROW_WARNING'),
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }
        } else if (decision === 'BULK_REJECT') {
            if (selectedRowKeys.length > 0) {
                setApproveModalData({
                    ...ApproveModalData,
                    "onModalCancel": handleCloseModal,
                    "icon": <UilTimesCircle className="dynamic-modal-icon" size={50} />,
                    "cancelBtnLbl": t('PARTNER_CANCEL'),
                    "cancelBtnFunc": handleCloseModal,
                    "confirmBtnLbl": t('TIME_OFF_REQUEST_REJECT'),
                    "confirmBtnFunc": "BULK_REJECT",
                    "modalTitle": t('TIME_OFF_REQUEST_REJECT'),
                    "modalDesc": t('TIMEOFF_BULK_REJECT_TEXT', { "count": selectedRowKeys.length }),
                    "handleValuesChange": handleApproveFormValuesChange,
                    "isVisible": true,
                    "id": null,
                    "approveSeqNumber": null,
                    "description": null,
                    'descriptionShow': true
                })
            } else {
                DynamicToastMessage({
                    'key': 'BULK_APPROVE_NOT_SELECTED',
                    'title': t('WARNING'),
                    'description': t('NOT_SELECTED_ROW_WARNING'),
                    'statusType': 'warning',
                    'position': 'bottom-right'
                })
            }
        }
    }
    const handleCloseModal = () => {
        setApproveModalData({ ...ApproveModalData, "isVisible": false, "description": null })
        form.setFieldsValue({ 'description': null })
        setCancelModalData({ 'isVisible': false, 'description': '' });
    }
    const handleApproveFormValuesChange = (changedObj, all) => {

        setApproveModalData((prevState) => ({
            ...prevState,
            'description': changedObj['description']
        }))
    }

    const [isConfirmLoading, setisConfirmLoading] = useState(false);

    const handleConfirm = () => {

        if(isConfirmLoading) return;
        setisConfirmLoading(true);
        setTimeout(() => {setisConfirmLoading(false);}, 1055) // double click prevent;

        if (ApproveModalData.confirmBtnFunc === 'SINGLE_APPROVE') {
            const approveObj = {
                "processStepList":
                    [
                        {
                            "timeOffRequestId": ApproveModalData.id,
                            "approveSeqNumber": ApproveModalData.approveSeqNumber,
                            "description": ApproveModalData.description
                        }
                    ]
            }
            timeOffApprove({ "approveData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'SINGLE_REJECT') {
            const approveObj = {
                "processStepList":
                    [
                        {
                            "timeOffRequestId": ApproveModalData.id,
                            "approveSeqNumber": ApproveModalData.approveSeqNumber,
                            "description": ApproveModalData.description
                        }
                    ]
            }
            timeOffReject({ "rejectData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'BULK_APPROVE') {
            const approveObj = {};
            const processStepList = [];
            selectedRows.forEach((item) => {
                processStepList.push({
                    "timeOffRequestId": item?.stateType?.statusObj?.id,
                    "approveSeqNumber": item?.stateType?.statusObj?.approveSeqNumber,
                    "description": ApproveModalData.description
                })
            })
            approveObj["processStepList"] = processStepList
            timeOffApprove({ "approveData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'BULK_REJECT') {
            const approveObj = {};
            const processStepList = [];
            selectedRows.forEach((item) => {
                processStepList.push({
                    "timeOffRequestId": item?.stateType?.statusObj?.id,
                    "approveSeqNumber": item?.stateType?.statusObj?.approveSeqNumber,
                    "description": ApproveModalData.description
                })
            })
            approveObj["processStepList"] = processStepList
            timeOffReject({ "rejectData": approveObj, "key": key })
        } else if (ApproveModalData.confirmBtnFunc === 'SINGLE_CANCEL') {
            timeOffCancel({ "resourceId": selectedData.resourceId, "key": key },)
        }
    }
    const openTimeOffAddDrawer = () => {
        setTimeOffAddDrawer(true)
        setcomponentKey((i) => i + 1)
    }

    const [isCancelLoading, setisCancelLoading] = useState(false);

    const handleCancelConfirm = () => {
        if(isCancelLoading) return;
        setisCancelLoading(true);
        setTimeout(() => {setisCancelLoading(false);}, 1055) // double click prevent;
        timeOffCancel({ "resourceId": cancelModalData?.id, "key": key, "description": cancelModalData.description });
    }
    const handleCancelExit = () => {
        setCancelModalData({ 'isVisible': false, 'description': '' })
        const fields = cancelForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        cancelForm.setFieldsValue(projects)
    }
    const handleCancelFormValuesChange = (changedObj) => {
        setCancelModalData({ ...cancelModalData, 'description': changedObj.description });
    }
    const cancelFormModal = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={cancelForm}
                    onValuesChange={handleCancelFormValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    
    const handledeleteTimeOffRequest = (data) => { 
        // double click prevented in requestModal.jsx 
        deleteTimeOffRequest(data);
     }
    return (
        <React.StrictMode>
            <Layout>
                <Col span={24}>
                    <Content className="page-container">
                        <BreadCrumb />
                        <div className="tab-menu ">
                            <h1 className="page-title">{t('SETTINGS_TIME_PROCEDURES')}</h1>
                            <Tabs
                                className="pad-top-20 page-tabs"
                                onChange={key => {
                                    callback(key);
                                    history.push(`/time-off/${key}`);
                                }}
                                defaultActiveKey={location.pathname.split('/time-off/')[1]}
                                activeKey={currentTab}
                            >
                                <TabPane tab={<span>{t('TIME_OFF_REQUEST_MY')}</span>} key="me">
                                    <TimeOffRequestMy setSelectedData={setSelectedData} handleCloseModal={handleCloseModal}
                                        deleteModalData={deleteModalData} setDeleteModalData={setDeleteModalData}
                                        cancelModalData={cancelModalData} setCancelModalData={setCancelModalData}
                                        initialSearchData={initialTimeOffRequestMy}
                                        openTimeOffAddDrawer={openTimeOffAddDrawer}
                                    />
                                </TabPane>
                                {/* <TabPane tab={<span>{t('TIME_OFF_REQUEST_PERMISSION')}</span>} key="TimeOffPermission">
                                    <TimeOffRequetPermission />
                                </TabPane> */}
                                <TabPane tab={<span>{t('TIME_OFF_REQUEST_PERSON')}</span>} key="person">
                                    <TimeOffRequestPerson
                                        setSelectedData={setSelectedData} selectedData={selectedData}
                                        cancelModalData={cancelModalData} setCancelModalData={setCancelModalData}
                                        deleteModalData={deleteModalData} setDeleteModalData={setDeleteModalData}
                                        selectedRowKeys={selectedRowKeys} setselectedRowKeys={setselectedRowKeys}
                                        selectedRows={selectedRows} setselectedRows={setselectedRows}
                                        handleTimeOffChangeStatus={handleTimeOffChangeStatus} initialRequestPersonSearchData={initialRequestPersonSearchData}
                                        searchData={searchData} setSearchData={setSearchData} handleCloseModal={handleCloseModal} />
                                </TabPane>
                                {permissions.includes("timeoff.leave.list") && (
                                    <TabPane tab={<span>{t('TIME_OFF_REQUEST_PEOPLE_ON_LEAVE')}</span>} key="people">
                                        <TimeOffRequestPeopleOnLeave setSelectedData={setSelectedData}
                                            peopleOnLeaveSearchData={peopleOnLeaveSearchData}
                                            setPeopleOnLeaveSearchData={setPeopleOnLeaveSearchData}
                                            peopleOnLeaveInitialSearchData={peopleOnLeaveInitialSearchData} />
                                    </TabPane>
                                )}
                                {/* 
                                    <TabPane tab={<span>{t('TIME_OFF_REQUEST_PROCESS')}</span>} key="TimeOffProcess">
                                        <Comingsoon />
                                    </TabPane>
                                 */}
                                {/* {permissions.includes("timeoff.type.list") && (
                                    <TabPane tab={<span>{t('TIME_OFF_REQUEST_TYPES')}</span>} key="TimeOffTypes">
                                        <TimeOffTypes />
                                    </TabPane>
                                )} */}
                                {permissions.includes("timeoff.remaining.list") && (
                                    <TabPane tab={<span>{t('REMAINING_DURATION')}</span>} key="remainder-years">
                                        <TimeOffRemainderYears remainderYearsInitialSearchData={remainderYearsInitialSearchData} />
                                    </TabPane>
                                )}
                                {permissions.includes("timeoff.request.all.list") && (
                                    <TabPane tab={<span>{t('ALL_TIME_OFF_REQUEST')}</span>} key="all">
                                        <AllTimeOffRequest cancelModalData={cancelModalData} setCancelModalData={setCancelModalData}
                                            deleteModalData={deleteModalData} setDeleteModalData={setDeleteModalData}
                                            allTimeOffSearchData={allTimeOffSearchData} handleCloseModal={handleCloseModal} setSelectedData={setSelectedData} />
                                    </TabPane>
                                )}
                            </Tabs>

                        </div>
                    </Content>
                </Col>
                <Drawer key={componentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={timeOffDrawerReducer.timeOffRequestAddDrawer} >
                    <NewTimeOffRequestTabs key={componentKeyTimeoffAdd} searchData={searchData} typeData={null} returnShow={true} />
                </Drawer >
                <Drawer key={"detail" + componentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={timeOffDrawerReducer.timeOffRequestApproveDrawer} >
                    <TimeOffAproveTabs cancelModalData={cancelModalData} setCancelModalData={setCancelModalData} selectedData={selectedData} handleTimeOffChangeStatus={handleTimeOffChangeStatus} page="timeOffMy" />
                </Drawer >
                <DynamicTimeOffModal form={form} ApproveModalData={ApproveModalData} handleConfirm={() => handleConfirm()} />
                <DynamicRequestModal
                    key={deleteModalData?.id+'-cancel-confirm'}
                    modalVisible={cancelModalData?.isVisible}
                    setStateData={setCancelModalData}
                    handleConfirm={handleCancelConfirm}
                    handleCancel={handleCancelExit}
                    isForm={cancelFormModal}
                    iconName='UilTimesCircle'
                    modalTitle={t('TIME_OFF_CANCEL_BTN')}
                    description={t('')}
                    cancelBtnTxt={t('PARTNER_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_CANCEL')}
                />
                <DynamicRequestModal
                    key={deleteModalData?.id}
                    modalVisible={deleteModalData?.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={handledeleteTimeOffRequest}
                    confirmData={deleteModalData?.id}
                    iconName='UilTrash'
                    modalTitle={t('SETTINGS_DELETE')}
                    description={t('TIMEOFF_DELETE_MODAL_DESCRIPTION', { "timeOffType": deleteModalData?.timeOffTypeName })}
                    cancelBtnTxt={t('PARTNER_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
            </Layout>
            <ToastContainer />
        </React.StrictMode>
    )
}
const mapStateToProps = (state) => {
    return {
        permissions: state.profile.permissions,
        timeOffDrawerReducer: state.timeOffDrawerReducer,
        profile: state.profile,
        timeOffExportDataResponse: state.timeOff.timeOffExportDataResponse,
        agreementFormDataResponse: state.timeOff.agreementFormDataResponse,
        timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffTypesList: () => { dispatch(getTimeOffTypesList()) },
        getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)) },
        getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)) },
        timeOffApprove: (approveData) => { dispatch(timeOffApprove(approveData)) },
        timeOffReject: (approveData) => { dispatch(timeOffReject(approveData)) },
        timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)) },
        deleteTimeOffRequest: (resourceId) => { dispatch(deleteTimeOffRequest(resourceId)) },
        getTimeOffDashboardPaiChartData: (resourceId, type) => { dispatch(getTimeOffDashboardPaiChartData(resourceId, type)) },
        resetTimeOffDashboardPaiChartData: () => { dispatch(resetTimeOffDashboardPaiChartData()) },
        resetTimeOffDownload: () => { dispatch(resetTimeOffDownload()) },
        setTimeOffAddDrawer: (props) => { dispatch(setTimeOffAddDrawer(props)) },
        resetDownloadAgreementForm: () => { dispatch(resetDownloadAgreementForm()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TimeOff);