import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTravelHostOnMail } from '../../redux/actions/travelHost.actions';

import { UilExclamationTriangle } from '@iconscout/react-unicons'
import { Spin } from 'antd';
import '../../styles/approveOnMail.scss';
import '../../styles/global.scss';

function TravelHostOnMail(props) {
    const { data } = props.match.params;
    const { getTravelHostOnMail, travelHostResponse, travelHostResponseLoading } = props;
    const { t } = useTranslation();

    useEffect(() => {
        getTravelHostOnMail(data);
    }, [data])

    return (
        travelHostResponseLoading ?
            <Spin className="tab-loading pad-top-75" size="large" />
            :
            <div className='approve-on-mail-container'>
                <div className='approve-on-mail-card-container'>
                    <div className="approve-on-mail-card">
                        <div className='approve-on-mail-icon-container'>
                            {travelHostResponse.status === "SUCCESS" ?
                                <img src={process.env.REACT_APP_URL + "images/successful.gif"} />
                                :
                                <UilExclamationTriangle />
                            }
                        </div>
                        <h1 className={travelHostResponse.status == "SUCCESS" ? 'success' : 'warning'}>
                            {travelHostResponse.status === "SUCCESS" ? t('SUCCESSFUL') : t('WARNING')}
                        </h1>
                        <p>{travelHostResponse.message}</p>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        travelHostResponse: state.travelHostReducer.travelHostResponse,
        travelHostResponseLoading: state.travelHostReducer.travelHostResponseLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTravelHostOnMail: (data) => { dispatch(getTravelHostOnMail(data)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelHostOnMail);