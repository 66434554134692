import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    ownerShipPageListSearch, getOwnerShipDetails, exportOwnerShip, exportOwnerShipReset,
    deleteOwnerShip, deleteOwnerShipResponseReset, exportPersonOwnerShip, exportPersonOwnerShipReset
} from '../../redux/actions/ownership.actions'
import { getGridSetting, createGridSettings, settingsReset } from '../../redux/actions/setting.actions';
import { setOwnerShipAddDrawer, setOwnerShipDetailsDrawer, setOwnerShipReturnDrawer } from '../../redux/actions/ownership-drawer.action';

import { Button, Drawer, Dropdown, Layout, Col } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import Media from "react-media";
import moment from 'moment';

import OwnerShipDetailsDrawer from '../../component/ownerShipDrawerTabs/details/ownership-details-draw';
import OwnerShipAddTabs from '../../component/ownerShipDrawerTabs/add/ownership-all-tabs';
import OwnerShipReturn from '../../component/ownerShipDrawerTabs/returnDrawer';
import DynamicTable from '../../component/dynamicTable/index'
import DynamicRequestModal from '../../component/dynamicModal/requestModal'
import ExportModal from '../../component/dynamicModal/exportModal';
import BreadCrumb from '../../features/breadcrump/breadcrump';
import DynamicToastMessage from '../../component/dynamicToastMessage/index';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import Forbidden from "../../component/forbidden/index";
import { dateFormatConvert, useWindowDimensions } from "../../utils/config";
import { drawerWidth } from '../../utils/config';
import { ownerShipPageListDropdown } from '../../features/global-dropdown-menu/index';

function OwnerShip(props) {
    const { t } = useTranslation();
    const { Content } = Layout;
    const { totalNumberOfRecords, createGridSettings, gridSettings, createGridSettingsResponse, getGridSetting, ownershipTabReducer,
        ownerShipPageListSearch, ownerShipSearchResult, isOwnerShipSearchLoading, getOwnerShipDetails, setOwnerShipDetailsDrawer,
        returnOwnerShipResponse, permissions, deleteOwnerShip, deleteOwnerShipResponse, deleteOwnerShipResponseReset, exportOwnerShip,
        exportOwnerShipReset, ownerShipExportResponse, exportPersonOwnerShip, exportPersonOwnerShipReset, personOwnerShipExportResponse,
        setOwnerShipReturnDrawer } = props;

    const initialGetGridSettingData = {
        pageId: 4, //zimmetler
        gridId: 1, //zimmetler grid
    }
    const initialSearchData = {
        "searchTerm": "",
        "SerialNumbers": [],
        "inventoryTypes": [],
        "persons": [],
        "showPassiveRecords": false,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "SerialNumber",
            "ascending": true
        }
    }
    const pdfInitialData = {
        "searchTerm": "",
        "SerialNumbers": [],
        "inventoryTypes": [],
        "persons": [],
        "showPassiveRecords": false,
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "SerialNumber",
            "ascending": true
        },
        "exportType": 0
    }
    const [isShowPassives, setIsShowPassives] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOwnerShip, setSelectedOwnerShip] = useState();
    const [detailsComponentKey, setDetailsComponentKey] = useState(1)
    const [returnComponentKey, setReturnComponentKey] = useState(12)
    const [selectedOwnerShipAssigmentDate, setSelectedOwnerShipAssigmentDate] = useState(null);
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })
    const [deleteInformationModalData, setDeleteInformationModalData] = useState({ 'isVisible': false })
    const [searchData, setSearchData] = useState(initialSearchData)
    const [getGridSettingsData, setGetGridSettingsData] = useState(initialGetGridSettingData)
    const { width } = useWindowDimensions();

    const handleExportDocument = (props) => {
        pdfInitialData.exportType = props;
        exportOwnerShip(pdfInitialData)
        setIsModalVisible(false);
    }
    const showOwnerShipDetails = (id, assigmentDate) => {
        getOwnerShipDetails(id);
        setOwnerShipDetailsDrawer(true);
        setSelectedOwnerShip(id);
        setSelectedOwnerShipAssigmentDate(assigmentDate);
        setDetailsComponentKey((i) => i + 1);
    }
    const openReturnDrawer = (id, assigmentDate) => {
        setOwnerShipReturnDrawer(true);
        setReturnComponentKey((i) => i + 1);
        setSelectedOwnerShip(id);
        setSelectedOwnerShipAssigmentDate(assigmentDate);
    }
    useEffect(() => {
        getGridSetting(getGridSettingsData)
    }, [getGridSettingsData])

    useEffect(() => {
        ownerShipPageListSearch(searchData);
    }, [searchData])
    useEffect(() => {
        if (returnOwnerShipResponse.status === 'SUCCESS') {
            if (ownerShipSearchResult?.length > 1) {
                ownerShipPageListSearch(searchData);
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            setOwnerShipDetailsDrawer(false);
        }
    }, [returnOwnerShipResponse])
    useEffect(() => {
        if (personOwnerShipExportResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'person_ownership_fail',
                'title': t('SUCCESS'),
                'description': personOwnerShipExportResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            exportPersonOwnerShipReset()
        }
    }, [personOwnerShipExportResponse])

    const data = ownerShipSearchResult.map((row, index) => {
        return (
            {
                "key": index,
                "reportsTo": row.reportsTo,
                "serialNumber": row.serialNumber,
                "inventoryType": { "id": row.resourceId, "assigmentDate": row.assigmentDate, "serialNumber": row.serialNumber, "inventoryType": row.inventoryType, "personResourceId": row.personResourceId },
                "personName": { "id": row.resourceId, "assigmentDate": row.assigmentDate, "personName": row.personName },
                "assigmentDate": row.assigmentDate ? moment(row.assigmentDate).format(dateFormatConvert) : '-',
                "returnDate": row.returnDate ? moment(row.returnDate).format(dateFormatConvert) : '-',
                "assigmentDescription": row.assigmentDescription,
            }
        );
    })
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['inventoryType', 'personName'] : [],
        sortableColumns: ['serialNumber', 'inventoryType', 'personName', 'assigmentDate', 'returnDate'],
        visibilityOptionExcludes: ['serialNumber', 'inventoryType', 'personName', 'assigmentDate', 'returnDate', 'assigmentDescription'],
        gridSettings: gridSettings[getGridSettingsData.pageId][getGridSettingsData.gridId],
        page: 'ownership-list'
    })
    const paginationConfig = () => ({
        current: searchData.pagination.pageNumber,
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 }
        setSearchData({ ...searchData, "searchTerm": e, "pagination": pagingObj })
    }
    const handleTableFilter = (data) => {
        setSearchData({
            ...searchData,
            "pagination": {
                "pageNumber": 1,
                "pageSize": 10,
                "orderBy": "SerialNumber",
                "ascending": true
            },
            "persons": data.personName, "inventoryTypes": data.inventoryType
        })
    }
    const handleShowPassivesToogle = (e) => {
        const pagingObj = { ...searchData.pagination, "pageNumber": 1 }
        setIsShowPassives(e);
        setSearchData({ ...searchData, "showPassiveRecords": e, "pagination": pagingObj })
    }
    const handleResetAllfilters = () => {
        setIsShowPassives(false)
        setSearchData(initialSearchData)
    }
    const handleSaveGridSettings = (d) => {
        let createObj = { "data": d, "pageId": getGridSettingsData.pageId, "gridId": getGridSettingsData.gridId }
        createGridSettings(createObj)
    }
    useEffect(() => {
        if (createGridSettingsResponse.status === 'SUCCESS') {
            ownerShipPageListSearch(searchData);
            settingsReset();
        }
    }, [createGridSettingsResponse])

    useEffect(() => {
        if (ownerShipExportResponse?.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'OWNER_SHIP_EXPORT_FAIL',
                'title': t('ERROR'),
                'description': ownerShipExportResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            exportOwnerShipReset()
        }
    }, [ownerShipExportResponse])

    useEffect(() => {
        if (deleteOwnerShipResponse.status === 'SUCCESS') {
            setDeleteModalData({ "isVisible": false });
            if (ownerShipSearchResult?.length > 1) {
                ownerShipPageListSearch(searchData);
            } else {
                setSearchData({
                    ...searchData,
                    pagination: {
                        pageNumber: searchData.pagination.pageNumber > 0 ? searchData.pagination.pageNumber - 1 : 0, pageSize: searchData.pagination.pageSize, orderBy: searchData.pagination.orderBy
                    }
                });
            }
            deleteOwnerShipResponseReset()
            DynamicToastMessage({
                'key': 'ownership_Success',
                'title': t('SUCCESS'),
                'description': deleteOwnerShipResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
        } else if (deleteOwnerShipResponse.status === 'FAIL') {
            setDeleteModalData({ "isVisible": false });
            setDeleteInformationModalData({ "isVisible": true });
            deleteOwnerShipResponseReset()
        }
    }, [deleteOwnerShipResponse])
    // const showModal = () => {
    //     setIsModalVisible(true);
    // };
    return (
        <>
            <Can I="asset.list">
                <Layout>
                    <ExportModal
                        onCancel={() => { setIsModalVisible(false) }}
                        status={isModalVisible}
                        handleExportDocument={handleExportDocument}
                    />
                    <DynamicRequestModal
                        modalVisible={deleteModalData?.isVisible}
                        setStateData={setDeleteModalData}
                        postAction={deleteOwnerShip}
                        confirmData={deleteModalData?.id}
                        iconName='UilTrash'
                        modalTitle={t('PARTNER_DELETE')}
                        description={t('OWNERSHIP_DELETE_MODAL', { "inventoryName": deleteModalData?.inventoryType, "serialNumber": deleteModalData?.serialNumber })}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('PARTNER_YES_DELETE')}
                    />
                    <DynamicRequestModal
                        modalVisible={deleteInformationModalData.isVisible}
                        setStateData={setDeleteInformationModalData}
                        postAction={''}
                        confirmData={''}
                        iconName='UilInfoCircle'
                        modalTitle={''}
                        description={t('OWNERSHIP_INFO_MODAL')}
                        singleButton={true}
                    />
                    <Layout>
                        <Col span={24}>
                            <Content className="page-container">
                                <BreadCrumb />
                                <div className='space-between'>
                                    <div className="tab-menu">
                                        <h1 className="page-title">{t('EMBEZZLEMENT')}({totalNumberOfRecords})</h1>
                                    </div>
                                    <div className="tab-btn ">
                                        {/* <Dropdown overlay={inventoryPageDropdown(t, showModal)} trigger={['click']}>
                                            <Button className="white-btn w-178 h-32 f-14 " icon={<MoreOutlined style={{ fontSize: '14px' }} />}>{t('PARTNER_PROCESS')}</Button>
                                        </Dropdown> */}
                                    </div>
                                </div>
                                <div className="table-container">
                                    <Media query="(min-width: 875px)" >
                                        {matches => {
                                            const columns = [
                                                {
                                                    title: `${t('DEBIT_INVENTORY_TYPE')}`,
                                                    dataIndex: 'inventoryType',
                                                    key: 'inventoryType',
                                                    sorter: true,
                                                    render: obj =>
                                                        <div className="imageNameActionColumn">
                                                            <Can I="asset.read">
                                                                {width >= 875 ?
                                                                    <React.Fragment>
                                                                        <a onClick={() => showOwnerShipDetails(obj.id, obj.assigmentDate)} className="details-text" >{obj.inventoryType}</a>
                                                                        <Dropdown
                                                                            className="action-menu"
                                                                            overlay={ownerShipPageListDropdown(t, openReturnDrawer, exportPersonOwnerShip, obj.personResourceId, obj.id, permissions, obj.assigmentDate)}
                                                                            trigger={['click']}
                                                                        >
                                                                            <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                                        </Dropdown>
                                                                    </React.Fragment>
                                                                    :
                                                                    <span style={{ paddingLeft: '15px' }} >{obj.inventoryType}</span>
                                                                }
                                                            </Can>
                                                            <Cannot I="asset.read">
                                                                <span style={{ paddingLeft: '15px' }} >{obj.inventoryType}</span>
                                                            </Cannot>
                                                        </div>
                                                },
                                                {
                                                    title: `${t('DEBIT_PERSON')}`,
                                                    dataIndex: 'personName',
                                                    key: 'personName',
                                                    sorter: true,
                                                    render: obj =>
                                                        obj.personName
                                                },
                                                {
                                                    title: `${t('INVENTORY_SERIAL_NUMBER')}`,
                                                    dataIndex: 'serialNumber',
                                                    key: 'serialNumber',
                                                    sorter: true,
                                                },
                                            ];
                                            if (matches) {
                                                columns.push(
                                                    {
                                                        title: `${t('DEBIT_DATE')}`,
                                                        dataIndex: 'assigmentDate',
                                                        key: 'assigmentDate',
                                                        sorter: true,
                                                    }
                                                );
                                                columns.push(
                                                    {
                                                        title: `${t('DEBIT_THROUGH')}`,
                                                        dataIndex: 'returnDate',
                                                        key: 'returnDate',
                                                        sorter: true,
                                                    }
                                                );
                                                columns.push(
                                                    {
                                                        title: `${t('DEBIT_DESCRIPTION')}`,
                                                        dataIndex: 'assigmentDescription',
                                                        key: 'assigmentDescription',
                                                        sorter: true,
                                                    }
                                                );
                                            }
                                            return <DynamicTable
                                                columns={columns} dataSource={data}
                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                                                onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                                                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives} searchPlaceholder={t('EMBEZZLEMENT_SEARCH')}
                                                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                                passiveButton={false}
                                                loadStatus={isOwnerShipSearchLoading}
                                                checkboxStatus={false}
                                            />
                                        }}
                                    </Media>
                                </div>
                                <Drawer title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={ownershipTabReducer.ownerShipAddDrawer} > {/* Ekle DRAWI */}
                                    <OwnerShipAddTabs />
                                </Drawer >
                                <Drawer key={returnComponentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={ownershipTabReducer.ownerShipReturnDrawer} > {/* Iade DRAWI */}
                                    <OwnerShipReturn selectedOwnerShip={[selectedOwnerShip]} selectedOwnerShipAssigmentDate={selectedOwnerShipAssigmentDate} />
                                </Drawer >
                                <Drawer key={detailsComponentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={ownershipTabReducer.ownerShipDetailsDrawer} > {/* Detay DRAWI */}
                                    <OwnerShipDetailsDrawer searchData={searchData} selectedOwnerShip={selectedOwnerShip} setReturnComponentKey={setReturnComponentKey} />
                                </Drawer >
                            </Content>
                        </Col>
                    </Layout>
                </Layout>
                <ToastContainer />
            </Can>
            <Cannot I="asset.list">
                <Forbidden />
            </Cannot>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        gridSettings: state.setting.gridSettings,
        ownerShipSearchResult: state.ownership.ownerShipSearchResult.results,
        isOwnerShipSearchLoading: state.ownership.isOwnerShipSearchLoading,
        totalNumberOfRecords: state.ownership.ownerShipSearchResult.totalNumberOfRecords,
        createGridSettingsResponse: state.setting.createGridSettingsResponse,
        ownershipTabReducer: state.ownershipTabReducer,
        returnOwnerShipResponse: state.ownership.returnOwnerShipResponse,
        deleteOwnerShipResponse: state.ownership.deleteOwnerShipResponse,
        permissions: state.profile.permissions,
        ownerShipExportResponse: state.ownership.ownerShipExportResponse,
        personOwnerShipExportResponse: state.ownership.personOwnerShipExportResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setOwnerShipAddDrawer: (props) => { dispatch(setOwnerShipAddDrawer(props)) },
        setOwnerShipDetailsDrawer: (props) => { dispatch(setOwnerShipDetailsDrawer(props)) },
        getGridSetting: (props) => { dispatch(getGridSetting(props)) },
        ownerShipPageListSearch: (searchData) => { dispatch(ownerShipPageListSearch(searchData)) },
        createGridSettings: (props) => { dispatch(createGridSettings(props)) },
        getOwnerShipDetails: (props) => { dispatch(getOwnerShipDetails(props)) },
        deleteOwnerShip: (resourceId) => { dispatch(deleteOwnerShip(resourceId)) },
        deleteOwnerShipResponseReset: () => { dispatch(deleteOwnerShipResponseReset()) },
        exportOwnerShip: (props) => { dispatch(exportOwnerShip(props)) },
        exportOwnerShipReset: () => { dispatch(exportOwnerShipReset()) },
        exportPersonOwnerShip: (resourceId) => { dispatch(exportPersonOwnerShip(resourceId)) },
        exportPersonOwnerShipReset: () => { dispatch(exportPersonOwnerShipReset()) },
        setOwnerShipReturnDrawer: (props) => { dispatch(setOwnerShipReturnDrawer(props)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OwnerShip);