import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Form, Input, Col, Row, Spin, ConfigProvider, DatePicker } from 'antd';
import DynamicSelectbox from "../../dynamicSelectbox/index";
import { compareObjects, createInitialList } from '../../../utils/commonFormFunctions'
import { getDataset } from "../../../redux/actions/dataset-action";
import { getInventoryTypes } from "../../../redux/actions/inventoryType.action";
import { getBrandList, getInventoryAttributes, resetBrandList } from "../../../redux/actions/inventory.actions";

import { dateFormatList } from '../../../utils/config';
import { priceDecimalWithDot } from '../../../utils/commonFormFunctions';
import moment from 'moment';
import MaskedInput from "react-text-mask";
import TR from 'antd/es/date-picker/locale/tr_TR'; //Timepicker için türkçe tarih karşılığı getirir
import 'moment/locale/tr'; //Timepicker için türkçe dil dosyasını  getirir


function InventoryBasicInfo(props) {

    const { t } = useTranslation();
    const [inventoryBasicInfoState, setInventoryBasicInfoState] = useState({});
    const [inventoryTypeList, setInventoryTypeList] = useState([]);

    const [form] = Form.useForm()
    const { inventoryDetailsInfo, setInventoryUpdateObj, inventoryFullStateTypes, getInventoryAttributes, requiredArray,
        getInventoryTypes, inventoryTypes, inventoryBrand, getBrandList, getDataset, typeChange, resetBrandList, currencyTypes } = props;
    const [brandList, setBrandList] = useState(createInitialList(inventoryDetailsInfo?.brand));
    const [currencyList, setCurrencyList] = useState();
    const [requiredInput, setRequiredInput] = useState({
        'brand': false,
        'serialNumber': false

    })
    useEffect(() => {
        const inventoryId = inventoryDetailsInfo?.inventoryType?.setInventoryType?.id;
        setInventoryBasicInfoState(inventoryDetailsInfo);
        resetBrandList()
        const setObj = {
            "setInventoryType": inventoryDetailsInfo?.inventoryType?.setInventoryType,
            "brand": inventoryDetailsInfo?.brand,
            "model": inventoryDetailsInfo.model,
            "amount": inventoryDetailsInfo?.amount,
            "currency": inventoryDetailsInfo?.currency,
            "serialNumber": inventoryDetailsInfo.serialNumber,
            "barcodeNumber": inventoryDetailsInfo.barcodeNumber,
            "name": inventoryDetailsInfo.name,
            "assigmentStatus": inventoryDetailsInfo.assigmentStatus?.displayValue,
            "status": inventoryDetailsInfo.status,
            "description": inventoryDetailsInfo.description,
        }
        if (inventoryDetailsInfo.deviceExpirationDate) {
            setObj["deviceExpirationDate"] = moment(inventoryDetailsInfo.deviceExpirationDate)
        }
        if (!requiredArray.includes(inventoryId)) {
            if (inventoryId === 6) { //CAR
                setRequiredInput({ ...requiredInput, 'brand': true, 'serialNumber': false })
            } else {
                setRequiredInput({ ...requiredInput, 'brand': true, 'serialNumber': true })
            }
        }
        form.setFieldsValue(setObj)
    }, [form, inventoryDetailsInfo])
    const [inventoryStateType, setInventoryStateType] = useState(createInitialList(inventoryDetailsInfo.status))


    useEffect(() => {
     
        const transformedList = inventoryTypes.map(item => ({
            ...item,
            attr: item.id,
            id: item.languageKey
        }));
        
      
        setInventoryTypeList(transformedList);
    }, [inventoryTypes]);
    useEffect(() => {
        setBrandList(inventoryBrand)
    }, [inventoryBrand])
    useEffect(() => {
        setInventoryStateType(inventoryFullStateTypes)
    }, [inventoryFullStateTypes])
    useEffect(() => {
        var list = currencyTypes?.length > 0 ?
            currencyTypes.filter((filterData) => filterData.id === '1' || filterData.id === '2' || filterData.id === '3').map((data) => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue
                }
            }) : [];
        setCurrencyList(list)
    }, [currencyTypes])

const handleValuesChange = (changedObj, all) => {
    const key = Object.keys(changedObj)[0];
    if (key === 'setInventoryType') {
        const changedItemLanguageKey = inventoryTypes.find(x => x.id === changedObj['setInventoryType']?.id) || changedObj['setInventoryType']?.id;
        const fields = form.getFieldsValue();
        const fieldsObj = { ...fields };
        fieldsObj["brand"] = "";
        form.setFieldsValue(fieldsObj);
        setBrandList();
        typeChange(changedObj['setInventoryType']?.id);
        getInventoryAttributes(changedItemLanguageKey.languageKey);
        const tempObj = { ...inventoryBasicInfoState.inventoryType, setInventoryType: changedObj['setInventoryType'] };
        setInventoryBasicInfoState({ ...inventoryBasicInfoState, inventoryType: tempObj, "brand": { id: "", displayValue: "" } });

        if (!requiredArray.includes(changedObj?.setInventoryType?.id)) {
            if (changedObj?.setInventoryType?.id === 6) { //CAR
                setRequiredInput({ ...requiredInput, 'brand': true, 'serialNumber': false });
            } else {
                setRequiredInput({ ...requiredInput, 'brand': true, 'serialNumber': true });
            }
        } else {
            setRequiredInput({ ...requiredInput, 'brand': false, 'serialNumber': false });
        }
    } else {
            setInventoryBasicInfoState({ ...inventoryBasicInfoState, ...changedObj });
    }
};



    useEffect(() => {
            setInventoryUpdateObj(inventoryBasicInfoState);
    }, [inventoryBasicInfoState]);

    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                layout="vertical"
                requiredMark={false}
            >
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <div className="form-left-body">
                            <Form.Item name="setInventoryType" label={t('INVENTORY_TYPE') + (' *')} className="custom-select" required>
                                <DynamicSelectbox
                                    optionList={inventoryTypeList}
                                    onFocus={() => getInventoryTypes({
                                        "searchTerm": "",
                                        "pagination": {
                                            "pageNumber": 1,
                                            "pageSize": 999,
                                            "orderBy": "DisplayValue",
                                            "ascending": true
                                        }
                                    })}
                                    disallowClear={true}
                                    className={!compareObjects(inventoryDetailsInfo.inventoryType, inventoryBasicInfoState.inventoryType) ? 'is-changed' : ''}
                                    placeholder={t('PARTNER_SELECT')}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                            <Form.Item name="brand" label={requiredInput.brand ? t('INVENTORY_BRAND') + (' *') : t('INVENTORY_BRAND')} className="custom-select" required>
                                <DynamicSelectbox
                                    optionList={brandList}
                                    defaultSpin={false}
                                    disallowClear={true}
                                    onFocus={() => getBrandList(
                                        {
                                            "searchTerm": "",
                                            "pagination": {
                                                "pageNumber": 1,
                                                "pageSize": 20,
                                                "orderBy": "DisplayValue",
                                                "ascending": true
                                            }
                                        }, inventoryTypeList.find(x => x.id === inventoryBasicInfoState.inventoryType?.setInventoryType?.id).languageKey
                                    )}
                                    className={!compareObjects(inventoryDetailsInfo.brand, inventoryBasicInfoState.brand) ? 'is-changed' : ''}
                                    placeholder={t('PARTNER_SELECT')}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                            <Form.Item name="serialNumber" className="custom-select" label={requiredInput.serialNumber ? t('INVENTORY_SERIAL_NUMBER') + ' *' : t('INVENTORY_SERIAL_NUMBER')}>
                                <Input
                                    className={!compareObjects(inventoryDetailsInfo.serialNumber, inventoryBasicInfoState.serialNumber) ? 'is-changed' : ''}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                            <Form.Item name="model" label={t('INVENTORY_MODEL')}>
                                <Input
                                    className={!compareObjects(inventoryDetailsInfo.model, inventoryBasicInfoState.model) ? 'is-changed' : ''}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                            <Form.Item name="barcodeNumber" className="custom-select" label={t('PARTNER_BARCODE_NUMBER')}>
                                <Input
                                    className={!compareObjects(inventoryDetailsInfo.barcodeNumber, inventoryBasicInfoState.barcodeNumber) ? 'is-changed' : ''}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                        </div>
                        <div className="pad-100" />

                    </Col>
                    <Col span={12}>
                        <div className="form-right-body">
                            <Form.Item name="name" className="custom-select" label={t('PARTNER_DEVICE_NAME') + ' *'}>
                                <Input
                                    className={!compareObjects(inventoryDetailsInfo.name, inventoryBasicInfoState.name) ? 'is-changed' : ''}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                            <Form.Item name="status" label={t('INVENTORY_STATUS') + (' *')} className="custom-select" required>
                                <DynamicSelectbox
                                    optionList={inventoryStateType}
                                    onFocus={() => getDataset(26)}
                                    className={!compareObjects(inventoryDetailsInfo.status, inventoryBasicInfoState.status) ? 'is-changed' : ''}
                                    placeholder={t('PARTNER_SELECT')}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                    orderBy={'none'}
                                    disallowClear={true}
                                />
                            </Form.Item>
                            <Form.Item name="assigmentStatus" label={t('OWNERSHIP_STATUS')} className="custom-select" required>
                                <Input
                                    disabled={true}
                                />
                            </Form.Item>
                            <Row gutter={[24, 0]}>
                                <Col span={14}>
                                    <Form.Item name="amount" label={t('PARTNER_AMOUNT2')}>
                                        <MaskedInput
                                            className="ant-input"
                                            placeholder={t('PARTNER_AMOUNT2')}
                                            mask={priceDecimalWithDot}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item name="currency" className="custom-select" label={t('PARTNER_CURRENCY')}>
                                        <DynamicSelectbox style={{ marginTop: '3px' }}
                                            optionList={currencyList}
                                            onFocus={() => getDataset(23)}
                                            placeholder={t('PARTNER_SELECT')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="deviceExpirationDate" label={t('INVENTORY_DEVICE_EXPIRATION_DATE')}>
                                <DatePicker
                                    className={!compareObjects(inventoryDetailsInfo.deviceExpirationDate, inventoryBasicInfoState.deviceExpirationDate) ? 'is-changed' : ''}
                                    locale={TR}
                                    format={dateFormatList}
                                    placeholder={t('PARTNER_SELECT_DATE')}
                                    inputReadOnly={true}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                            <Form.Item name="description" className="custom-select" label={t('PARTNER_RETURN_DESCRIPTION')}>
                                <Input.TextArea
                                    className={!compareObjects(inventoryDetailsInfo.description, inventoryBasicInfoState.description) ? 'is-changed' : ''}
                                    disabled={!inventoryDetailsInfo?.isActive}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </ConfigProvider>
    )
}



const mapStateToProps = (state) => {
    return {
        inventoryTypes: state.inventoryType.inventoryTypes.results,
        inventoryBrand: state.inventory.inventoryBrand?.results,
        inventoryFullStateTypes: state.dataset.inventoryFullStateTypes,
        currencyTypes: state.dataset.currencyTypes,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInventoryTypes: (props) => { dispatch(getInventoryTypes(props)) },
        getBrandList: (searchData, id) => { dispatch(getBrandList(searchData, id)) },
        getDataset: (props) => { dispatch(getDataset(props)) },
        getInventoryAttributes: (props) => { dispatch(getInventoryAttributes(props)) },
        resetBrandList: () => { dispatch(resetBrandList()) }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryBasicInfo);