import React, { useEffect } from 'react'
import { removeUserFromStorage, signoutRedirectCallback } from '../../services/userService'
import { useHistory } from 'react-router-dom'
import {useSelector, useDispatch} from "react-redux";
import { Spin} from 'antd'

function LogoutCallback() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      await removeUserFromStorage(dispatch,history)
    }
    signoutAsync()
  }, [history])

  return (
    <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"100vh"}}>
      <Spin/>
    </div>
  )
}

export default LogoutCallback
