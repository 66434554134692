import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getApproveAdvanceOnMail } from '../../redux/actions/advance.actions';

import { UilExclamationTriangle } from '@iconscout/react-unicons'
import { Spin } from 'antd';
import '../../styles/approveOnMail.scss';
import '../../styles/global.scss';

function ApproveAdvanceOnMail(props) {
    const { data } = props.match.params;
    const { getApproveAdvanceOnMail, advanceApproveOnMailResponse, advanceApproveOnMailResponseLoading } = props;
    const { t } = useTranslation();
    useEffect(() => {
        getApproveAdvanceOnMail(data);
    }, [data])
    return (
        advanceApproveOnMailResponseLoading ?
            <Spin className="tab-loading pad-top-75" size="large" />
            :
            <div className="approve-on-mail-container">
                <div className="approve-on-mail-card-container">
                    <div className="approve-on-mail-card">
                        <div className='approve-on-mail-icon-container'>
                            {advanceApproveOnMailResponse.status == "SUCCESS" ?
                                <img src={process.env.REACT_APP_URL + 'images/successful.gif'} />
                                :
                                <UilExclamationTriangle />
                            }
                        </div>
                        <h1 className={advanceApproveOnMailResponse.status == "SUCCESS" ? 'success' : 'warning'}>
                            {advanceApproveOnMailResponse.status == "SUCCESS" ? t('SUCCESSFUL') : t('WARNING')}
                        </h1>
                        <p>{advanceApproveOnMailResponse.message}</p>
                    </div>
                </div>
            </div>

    )
}

const mapStateToProps = (state) => {
    return {
        advanceApproveOnMailResponse: state.advance.advanceApproveOnMailResponse,
        advanceApproveOnMailResponseLoading: state.advance.advanceApproveOnMailResponseLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApproveAdvanceOnMail: (data) => { dispatch(getApproveAdvanceOnMail(data)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveAdvanceOnMail);