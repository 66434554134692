import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tabs, Button, Dropdown, Spin, List } from 'antd';
import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { UilPanoramaHAlt, UilWindow, UilBookAlt, UilBed, UilFlask, UilMouse, UilUserSquare, 
    UilCreditCard, UilScrew, UilHdd, UilBuilding, UilFilesLandscapesAlt, UilBriefcase,UilFolderQuestion, 
    UilDesktop, UilDesktopCloudAlt, UilLaptop, UilDesktopAlt, UilCar, UilMobileAndroid  } from '@iconscout/react-unicons'
import moment from 'moment';

import { datasetReset } from '../../../redux/actions/dataset-action';
import { setInventoryDetailsDrawer, updateInventory, resetUpdateInventory, updateStatusInventory, deleteInventory, getInventoryDetails } from '../../../redux/actions/inventory.actions';
import { setOwnerShipReturnDrawer } from '../../../redux/actions/ownership-drawer.action';

import { deepDiffMapper, MergeObjects, resetScrollPosition } from '../../../utils/commonFormFunctions'
import { inventoryDetailsList, inventoryDetailsPassiveList } from '../../../features/global-dropdown-menu/index'
import { dateFormat } from "../../../utils/config";

import InventoryBasicInfo from './inventory-details-basic-info'
import InventoryDetailsPage from './inventory-details-details-page';
import InventoryDetailsOwnerShip from './inventory-details-ownership'
import DynamicRequestModal from '../../dynamicModal/requestModal'
import DynamicToastMessage from '../../dynamicToastMessage/index';
import Can from "../../../authorization/index";

import '../../../styles/inventory.scss';

function InventoryDetailTabs(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { setInventoryDetailsDrawer, inventoryDetailsInfo, updateInventory, updateStatusInventory, deleteInventory,
        getInventoryDetails, permissions, inventoryDetailsLoading, datasetReset, updateInventoryResponse, resetUpdateInventory, selectedId,
        setOwnerShipReturnDrawer, inventoryAttribute, inventoryStatusResponse } = props;
    const [currentTab, setCurrentTab] = useState("inventoryBasicInfo");
    const [inventoryRef, setInventoryRef] = useState("{}")
    const [doPassiveModalData, setDoPassiveModalData] = useState({ "isVisible": false })
    const [doActiveModalData, setDoActiveModalData] = useState({ "isVisible": false })
    const [deleteModalData, setDeleteModalData] = useState({ "isVisible": false })
    const [updateInventoryObj, setUpdateInventoryObj] = useState({})
    const [dynamicAttributeData, setDynamicAttributeData] = useState([])
    const [dynamicAttributeList, setDynamicAttributeList] = useState([])
    const [getAttributeId, setAttributeId] = useState(null)
    const [typeChangeID, setTypeChangeID] = useState(null);
    const requiredArray = [7, 8, 9, 10, 11, 12];

    const createDetailObject = (dataObj, isOrginal, attributeID) => {
        const dataObject = {};
        const detailDataObject = {};
        let mergeObj = [];

        dataObj?.attributeItems?.forEach(item => {
            let options, attributeItemValueId, value = null
            if (item.fieldType === 4 || item.fieldType === 5) {

                options = item.inventoryAttributeItemValues
                attributeItemValueId = options.filter(i => i?.selected)[0]?.attributeItemValueId
                value = options.filter(i => i?.selected)[0]?.value
                dataObject[item.attributeItemId] = {
                    AttributeId: attributeID,
                    AttributeItemId: item.attributeItemId,
                    FieldType: item?.fieldType,
                    AttributeItemValueId: attributeItemValueId,
                    Value: value
                }
                detailDataObject[item.attributeItemId] = {
                    item: item,
                    attributeItemValueId: isOrginal ? attributeItemValueId : null,
                    value: isOrginal ? value : null
                }
            } else if (item.fieldType === 1) {
                value = item.defaultValue
                dataObject[item.attributeItemId] = {
                    AttributeId: attributeID,
                    AttributeItemId: item.attributeItemId,
                    FieldType: item?.fieldType,
                    Value: value
                }
                detailDataObject[item.attributeItemId] = {
                    item: item,
                    value: isOrginal ? value : null
                }
            }
            mergeObj = Object.values(dataObject).map(data => ({
                AttributeId: attributeID,
                AttributeItemId: data?.AttributeItemId,
                FieldType: data?.FieldType,
                AttributeItemValueId: data?.AttributeItemValueId,
                Value: data?.Value
            }));
        });
        return {
            dataObject: dataObject,
            detailDataObject: detailDataObject,
            mergeObj: mergeObj,
        }
    }
    const setInventoryUpdateObj = (type, obj) => {
        let mergedObj = {}
        let dataObject = {}
        const diffObj = deepDiffMapper.map(JSON.parse(inventoryRef), obj);
        if (type === "dynamic") {
            const dynamicData = Object.values(obj).map(data => ({
                AttributeId: getAttributeId,
                AttributeItemId: data?.item?.attributeItemId,
                FieldType: data?.item?.fieldType,
                AttributeItemValueId: data?.attributeItemValueId,
                Value: data?.value
            }));

            dataObject = { "inventoryValueSets": dynamicData };
            mergedObj = MergeObjects(updateInventoryObj, dataObject)

        } else {
            if (diffObj !== null && Object.keys(diffObj).length === 0) {
                dataObject = obj
            } else {
                dataObject = diffObj
            }
            mergedObj = MergeObjects(updateInventoryObj, dataObject)
        }
        setUpdateInventoryObj(mergedObj)
    }
    useEffect(() => {
        setUpdateInventoryObj(JSON.parse(inventoryRef))
    }, [inventoryRef])
    useEffect(() => {
    }, [updateInventoryObj])
    const typeChange = (id) => {
        setTypeChangeID(id)
    }
    useEffect(() => {

        const result = createDetailObject(inventoryDetailsInfo?.inventoryAttributeResult, true, inventoryDetailsInfo?.inventoryAttributeResult?.attributeId)
        setDynamicAttributeData(result?.detailDataObject)
        setInventoryRef(JSON.stringify({ ...inventoryDetailsInfo, inventoryValueSets: result?.mergeObj }))
        setDynamicAttributeList(inventoryDetailsInfo?.inventoryAttributeResult?.attributeItems)
        setAttributeId(inventoryDetailsInfo?.inventoryAttributeResult?.attributeId)
    }, [inventoryDetailsInfo])

    useEffect(() => {

        setDynamicAttributeList(inventoryAttribute?.attributeItems)
        setAttributeId(inventoryAttribute?.attributeId)
        if (inventoryAttribute !== undefined) {

            if (inventoryDetailsInfo?.inventoryType?.setInventoryType?.id !== typeChangeID) {

                const result = createDetailObject(inventoryAttribute, false, inventoryAttribute?.attributeId)
                setDynamicAttributeData(result?.detailDataObject)
                setDynamicAttributeList(inventoryAttribute?.attributeItems)
                setUpdateInventoryObj({ ...updateInventoryObj, inventoryValueSets: result?.mergeObj })

            } else {
                const result = createDetailObject(inventoryDetailsInfo?.inventoryAttributeResult, true, inventoryAttribute?.attributeId)
                setDynamicAttributeData(result.detailDataObject)
                setDynamicAttributeList(inventoryDetailsInfo?.inventoryAttributeResult?.attributeItems)
                setUpdateInventoryObj({ ...updateInventoryObj, inventoryValueSets: result?.mergeObj })
            }
        }
    }, [inventoryAttribute])
    const getIcon = (id) => {
        if (id === 1) {
            return <UilLaptop />
        } else if (id === 2) {
            return <UilDesktop />
        } else if (id === 3) {
            return <UilMobileAndroid />
        } else if (id === 4) {
            return <UilDesktopAlt />
        } else if (id === 5) {
            return <UilDesktopAlt />
        } else if (id === 6) {
            return <UilCar />
        } else if (id === 7) {
            return <UilDesktopAlt />
        } else if (id === 18) {
            return <UilDesktopCloudAlt />
        } else if (id === 15) {
            return <UilBriefcase />
        } else if (id === 19) {
            return <UilFilesLandscapesAlt />
        } else if (id === 20) {
            return <UilBuilding />
        } else if (id === 13) {
            return <UilHdd />
        } else if (id === 11) {
            return <UilScrew />
        } else if (id === 9) {
            return <UilCreditCard />
        } else if (id === 12) {
            return <UilUserSquare />
        } else if (id === 14) {
            return <UilMouse />
        } else if (id === 21) {
            return <UilFlask />
        } else if (id === 22) {
            return <UilBed />
        } else if (id === 16) {
            return <UilDesktop />
        } else if (id === 23) {
            return <UilBookAlt />
        } else if (id === 24) {
            return <UilCar />
        } else if (id === 8) {
            return <UilMobileAndroid />
        } else if (id === 25) {
            return <UilWindow />
        } else if (id === 17) {
            return <UilPanoramaHAlt />
        } else {
            return <UilFolderQuestion />
        }
    }

    useEffect(() => {
        if (updateInventoryResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'UPDATE_INVENTORY_SUCCESS',
                'title': t('SUCCESS'),
                'description': updateInventoryResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getInventoryDetails(selectedId);
            resetUpdateInventory()

        } else if (updateInventoryResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'UPDATE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': updateInventoryResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateInventory()

        } else if (updateInventoryResponse.status === 'VALIDATION_ERROR') {
            DynamicToastMessage({
                'key': 'UPDATE_INVENTORY_FAIL2',
                'title': t('ERROR'),
                'description': <List>{updateInventoryResponse.message.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetUpdateInventory()
        }
    }, [updateInventoryResponse]);

    useEffect(() => {
        if (inventoryStatusResponse?.status === 'SUCCESS') {

            getInventoryDetails(selectedId)
        }
    }, [inventoryStatusResponse])


    function callback(key) {
        setCurrentTab(key);
        resetScrollPosition('.drawer-scrollable-tab-inventory > .ant-tabs-content-holder');

    }
    function close() {
        setInventoryDetailsDrawer(false);
        setCurrentTab("inventoryBasicInfo");
        setUpdateInventoryObj({});
        datasetReset()
    }
    const canDelete = (id) => {
        if (id === "2") {
            DynamicToastMessage({
                'key': 'DELETE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': t('RESOURCE_CAN_NOT_BE_DELETED'),
                'statusType': 'error',
                'position': 'bottom-right'
            })
        } else {
            setDeleteModalData({ ...deleteModalData, "isVisible": true })
        }
    }
    const canPassive = (id) => {
        if (id === "2") {
            DynamicToastMessage({
                'key': 'PASSIVE_INVENTORY_FAIL',
                'title': t('ERROR'),
                'description': t('RESOURCE_CAN_NOT_BE_PASSIVE'),
                'statusType': 'error',
                'position': 'bottom-right'
            })
        } else {
            setDoPassiveModalData({ ...doPassiveModalData, "isVisible": true })
        }
    }

    const checkRequiredFields = (nextFunc) => {
        const isRequiredAccessoryType = (updateInventoryObj.inventoryType?.setInventoryType?.id !== 9 && updateInventoryObj.inventoryType?.setInventoryType?.id !== 11 && updateInventoryObj.inventoryType?.setInventoryType?.id !== 12 && updateInventoryObj.inventoryType?.setInventoryType?.id !== 15) && !updateInventoryObj.inventoryValueSets[0] || (updateInventoryObj?.inventoryValueSets[0]?.AttributeItemId === 78 || updateInventoryObj?.inventoryValueSets[0]?.AttributeItemId === 79) && !updateInventoryObj?.inventoryValueSets[0]?.AttributeItemValueId;
        let hasError = false
        let fields = []
        if (!updateInventoryObj.inventoryType || updateInventoryObj.inventoryType === "") {
            hasError = true
            fields.push(t('DEBIT_INVENTORY_TYPE'))
        }
        if (!requiredArray.includes(updateInventoryObj.inventoryType?.setInventoryType?.id)) {
            if (updateInventoryObj.inventoryType?.setInventoryType?.id !== 6) { //CAR
                if (!updateInventoryObj.serialNumber || updateInventoryObj.serialNumber === "") {
                    hasError = true
                    fields.push(t('INVENTORY_SERIAL_NUMBER'))
                }
            }
            if (!updateInventoryObj.brand || updateInventoryObj.brand?.id === "") {
                hasError = true
                fields.push(t('INVENTORY_BRAND'))
            }
        }
        /*
        if (isRequiredAccessoryType) {
            hasError = true;
            fields.push(t('ACCESSORY_TYPE'))
        }
        */
        if (!updateInventoryObj.name || updateInventoryObj.name === "") {
            hasError = true
            fields.push(t('PARTNER_DEVICE_NAME'))
        }
        if (!updateInventoryObj.status || updateInventoryObj.status === "") {
            hasError = true
            fields.push(t('INVENTORY_STATU'))
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }

    const [isUpdateInventoryLoading, setisUpdateInventoryLoading] = useState(false);

    const handleUpdateInventory = () => {

        if(isUpdateInventoryLoading) return;
        setisUpdateInventoryLoading(true);
        setTimeout(() => {setisUpdateInventoryLoading(false);}, 1055) // double click prevent;

        const data = {
            "model": updateInventoryObj.model,
            "serialNumber": updateInventoryObj.serialNumber,
            "barcodeNumber": updateInventoryObj.barcodeNumber,
            "name": updateInventoryObj.name,
            "description": updateInventoryObj.description,
            "amount": updateInventoryObj?.amount,
            "currencyId": updateInventoryObj?.currency?.id,
            "manufacturerName": "",
            "statusId": updateInventoryObj.status?.id,
            "inventoryTypeId": updateInventoryObj.inventoryType?.setInventoryType?.id,
            "brandId": updateInventoryObj.brand?.id,
            "sku": "",
            "mpn": "",
            "nsn": "",
            "isAccessory": false,
            "inventoryValueSets": updateInventoryObj.inventoryValueSets,
            "deviceExpirationDate": updateInventoryObj.deviceExpirationDate && updateInventoryObj.deviceExpirationDate !== 'Invalid date' ? moment(updateInventoryObj.deviceExpirationDate).format(dateFormat) : null
        }
        checkRequiredFields(() => updateInventory({ "data": data, "resourceId": selectedId }))
    }
    return (
        inventoryDetailsLoading ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (

            <div className="table-details organization-drawer">
                <DynamicRequestModal
                    key={'PARTNER_DO_PASSIVE-INVENTORY'}
                    modalVisible={doPassiveModalData.isVisible}
                    setStateData={setDoPassiveModalData}
                    postAction={updateStatusInventory}
                    confirmData={selectedId}
                    isMultipleData={'passive'}
                    iconName='UilBan'
                    modalTitle={t('PARTNER_DO_PASSIVE')}
                    description={t('ARE_YOU_SURE_DO_PASSIVE_DESC', { "serialNumber": inventoryDetailsInfo?.serialNumber, "inventoryType": inventoryDetailsInfo?.inventoryType?.setInventoryType?.displayValue })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('MAKE_IT_PASSIVE')}
                />

                <DynamicRequestModal
                    key={'DO_ACTIVE-1'}
                    modalVisible={doActiveModalData.isVisible}
                    setStateData={setDoActiveModalData}
                    postAction={updateStatusInventory}
                    confirmData={selectedId}
                    isMultipleData={'active'}
                    iconName='UilQuestionCircle'
                    modalTitle={t('DO_ACTIVE')}
                    description={t('ARE_YOU_SURE_DO_ACTIVE_DESC', { "serialNumber": inventoryDetailsInfo?.serialNumber, "inventoryType": inventoryDetailsInfo?.inventoryType?.setInventoryType?.displayValue })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('MAKE_IT_ACTIVE')}
                />
                <DynamicRequestModal
                    key={'PARTNER_DELETE-1'}
                    modalVisible={deleteModalData.isVisible}
                    setStateData={setDeleteModalData}
                    postAction={deleteInventory}
                    confirmData={selectedId}
                    iconName='UilTrash'
                    modalTitle={t('PARTNER_DELETE')}
                    description={t('ARE_YOU_SURE_DELETE_INVENTORY', { "serialNumber": inventoryDetailsInfo?.serialNumber, "inventoryName": inventoryDetailsInfo?.name })}
                    cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                    successBtnTxt={t('PARTNER_YES_DELETE')}
                />
                <div className="close-icon" onClick={() => close()}>
                    <CloseOutlined />
                </div>
                <Col span={24}>
                    <Row>
                        <Col span={17}>
                            <Row>
                                <Col span={6}>
                                    {inventoryDetailsInfo?.isActive ?
                                        <div className="custom-icon-logo">
                                            {getIcon(inventoryDetailsInfo?.inventoryType?.setInventoryType?.id)}
                                        </div>
                                        :

                                        <div className='inventory-passive-class'>
                                            <h1>{t('PARTNER_PASSIVE')}</h1>
                                            {
                                                <div className="custom-icon-logo">
                                                    {getIcon(inventoryDetailsInfo?.inventoryType?.setInventoryType?.id)}
                                                </div>
                                            }
                                        </div>
                                    }
                                </Col>
                                <Col span={18}>
                                    <div className="user-details">
                                        <h1>{t('INVENTORY_DETAILS_TITLE', { 'name': inventoryDetailsInfo?.name, 'inventoryType': inventoryDetailsInfo?.inventoryType?.setInventoryType?.displayValue })}</h1>
                                        <div className="info-alt">
                                            <h3> {inventoryDetailsInfo?.brand?.displayValue !== undefined && inventoryDetailsInfo?.brand?.displayValue + ' '} {inventoryDetailsInfo?.model !== null ? inventoryDetailsInfo.model : ''} </h3>
                                            <h3>{inventoryDetailsInfo?.serialNumber}</h3>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col offset={2} span={5}>
                            <div className="changepage">
                                <Row gutter={[0, 15]}>
                                    <div className="procces pad-0">
                                        {(permissions.includes('inventory.delete') || permissions.includes('inventory.canpassive') || permissions.includes('asset.return')) &&
                                            <Dropdown overlay={inventoryDetailsInfo?.isActive ?
                                                inventoryDetailsList(t, canPassive, canDelete, setOwnerShipReturnDrawer, inventoryDetailsInfo?.assigmentStatus?.id, permissions)
                                                :
                                                inventoryDetailsPassiveList(t, setDoActiveModalData, canDelete, inventoryDetailsInfo?.assigmentStatus?.id, permissions)
                                            } trigger={['click']}>
                                                <Button className="primary-btn w-180 h-32"> <SettingOutlined />{t('PARTNER_PROCESS')} </Button>
                                            </Dropdown>
                                        }
                                    </div>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                    <div className='flex-end'>
                    </div>
                </Col>
                <div className="hr-bottom"></div>
                <Tabs
                    className="custom-tab w-100 drawer-scrollable-tab-inventory"
                    onChange={callback}
                    activeKey={currentTab}
                    defaultActiveKey={currentTab}
                >
                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key="inventoryBasicInfo">
                        <InventoryBasicInfo requiredArray={requiredArray} typeChange={(s) => typeChange(s)} inventoryDetailsInfo={inventoryDetailsInfo} setInventoryUpdateObj={(o) => setInventoryUpdateObj("basic", o)} />
                    </TabPane>
                    <TabPane tab={`${t('INVENTORY_DETAILS_INFORMATION')}`} key="inventoryDetails">
                        <InventoryDetailsPage inventoryDetailsInfo={inventoryDetailsInfo} dynamicAttributeData={dynamicAttributeData} dynamicAttributeList={dynamicAttributeList} setInventoryUpdateObj={(o) => setInventoryUpdateObj("dynamic", o)} />
                    </TabPane>
                    <TabPane tab={`${t('EMBEZZLEMENT_INFORMATION')}`} key="InventoryDetailsOwnerShipx">
                        <InventoryDetailsOwnerShip inventoryDetailsInfo={inventoryDetailsInfo} />
                    </TabPane>
                </Tabs>
                {inventoryDetailsInfo?.isActive ?
                    <div className="fixed-btn">
                        <div className="page-end">
                            <div className="hr-end"></div>
                        </div>

                        <div className="form-submit-space-between">
                            <Button onClick={() => close()} className="transparent-btn pad-0 font-w-700 w-120 h-40 f-14">{t('PARTNER_CANCEL')}</Button>

                            <div className="btn-padding" />
                            <Can I="inventory.update">
                                <Button onClick={handleUpdateInventory} className="primary-btn w-180 h-40 f-14">{t('PARTNER_SAVE_CHANGES')}</Button>
                            </Can>
                        </div>
                    </div>
                    : ''
                }
            </div >
        )
    )
}

const mapStateToProps = (state) => {
    return {
        inventoryDetailsLoading: state.inventory.inventoryDetailsLoading,
        inventoryDetailsInfo: state.inventory.inventoryDetailsInfo,
        updateInventoryResponse: state.inventory.updateInventoryResponse,
        inventoryStatusResponse: state.inventory.inventoryStatusResponse,
        inventoryAttribute: state.inventory.inventoryAttribute.inventoryAttributeResult,
        returnOwnerShipResponse: state.ownership.returnOwnerShipResponse,
        permissions: state.profile.permissions
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setInventoryDetailsDrawer: (props) => { dispatch(setInventoryDetailsDrawer(props)) },
        datasetReset: () => { dispatch(datasetReset()) },
        updateInventory: (updateData) => { dispatch(updateInventory(updateData)) },
        resetUpdateInventory: () => { dispatch(resetUpdateInventory()) },
        updateStatusInventory: (status, resourceId) => { dispatch(updateStatusInventory(status, resourceId)) },
        getInventoryDetails: (resourceId) => { dispatch(getInventoryDetails(resourceId)) },
        deleteInventory: (resourceId) => { dispatch(deleteInventory(resourceId)) },
        setOwnerShipReturnDrawer: (props) => { dispatch(setOwnerShipReturnDrawer(props)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailTabs);