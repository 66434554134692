import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { getPersons } from "../../../../redux/actions/organization.actions";
import { getAssignedTasksStepList } from "../../../../redux/actions/task.actions";
import { useTranslation } from 'react-i18next';

import { Form, ConfigProvider, Input, Spin } from 'antd';
import moment from 'moment';
import { dateFormatConvert } from "../../../../utils/config";

import '../../../../styles/person.scss';
import '../../../../styles/advances.scss';

function MyTasksDetailDynamicTab(props) {
    const { t } = useTranslation();
    const inputRef = useRef([]);
    const [form] = Form.useForm()
    const [myTaskRequestDetailState, setMyTaskRequestDetailState] = useState({ data: {}, label: {}, attribute: {} });
    const [taskDescriptionLabelState, setTaskDescriptionLabelState] = useState([]);
    const { setCreateTaskObj, currentTab,
        personTaskInformation, personTaskInformationLoading, assignedTasksListState } = props;

    const handleValuesChange = (event, item) => {
        const newData = { ...myTaskRequestDetailState.data };
        const resourceId = assignedTasksListState[currentTab]?.resourceId;
        const targetItem = newData[resourceId].find(i => i.resourceId === item.resourceId);
        if (targetItem) {
            targetItem.value = event.target.value;
        }
        setMyTaskRequestDetailState({ ...myTaskRequestDetailState, data: newData });
    }
    const mapLabelItem = (item, index) => {
        if (item?.taskResourceId)
            return (
                <Form.Item className="ant-form-label" key={index} name={item?.taskResourceId} label={item?.label}>
                    <p className="ant-form-label-item">{item?.description}</p>
                </Form.Item>
            );
    };
    useEffect(() => {
        setCreateTaskObj(myTaskRequestDetailState);
    }, [myTaskRequestDetailState]);
    useEffect(() => {
        if (!personTaskInformationLoading) {
            setTaskDescriptionLabelState({ ...taskDescriptionLabelState, [assignedTasksListState[currentTab]?.resourceId]: personTaskInformation?.taskDescription });
            if (!Object.keys(myTaskRequestDetailState.data).includes(assignedTasksListState[currentTab]?.resourceId)) {
                var formList = {};
                var labelList = {};
                var attributeList = {};
                let initialValues = {};
                personTaskInformation?.personTasks.map((data) => {
                    if (!labelList[data.taskResourceId]) {
                        labelList[data.taskResourceId] = []; // Initialize as an empty array if undefined
                    }
                    if (!formList[data.taskResourceId]) {
                        formList[data.taskResourceId] = []; // Initialize as an empty array if undefined
                    }
                    if (Array.isArray(labelList[data.taskResourceId])) {
                        labelList[data.taskResourceId].push({
                            "taskResourceId": "assignedDate_" + data.taskResourceId,
                            "label": t('ASSIGNMENT_DATE'),
                            "description": data?.assignedInformation?.assignedDate ? moment(data?.assignedInformation?.assignedDate).format(dateFormatConvert) : null,
                        });
                    } else {
                        console.error("labelList[data.taskResourceId] is not an array.");
                    }
                    if (data?.assignedInformation?.lastProcessDatetime) {
                        if (Array.isArray(labelList[data.taskResourceId])) {
                            labelList[data.taskResourceId].push(
                                {
                                    "taskResourceId": "lastProcessDatetime_" + data.taskResourceId,
                                    "label": t('LAST_UPDATE_DATE'),
                                    "description": moment(data?.assignedInformation?.lastProcessDatetime).format(dateFormatConvert),
                                });
                        } else {
                            console.error("labelList[data.taskResourceId] is not an array.");
                        }
                    }
                    if (Array.isArray(labelList[data.taskResourceId])) {
                        labelList[data.taskResourceId].push({
                            "taskResourceId": "personAssign_" + data?.appointeeResourceId,
                            "label": t('PERSON_WHO_APPOINTED'),
                            "description": data?.appointeeName
                        });
                    } else {
                        console.error("labelList[data.taskResourceId] is not an array.");
                    }
                    if (data?.assignedInformation?.attributes?.length > 0) {
                        data?.assignedInformation?.attributes?.map((datax) => {
                            if (!attributeList[data.taskResourceId]) {
                                attributeList[data.taskResourceId] = []; // Initialize as an empty array if undefined
                            }
                            if (Array.isArray(attributeList[data.taskResourceId])) {
                                attributeList[data.taskResourceId].push({
                                    "taskResourceId": datax.attributeValueResourceId,
                                    "label": datax.taskAttributeName,
                                    "description": datax.taskAttributeValue,
                                });
                            } else {
                                console.error("attributeList[datax.attributeValueResourceId] is not an array.");
                            }
                        });
                    }
                    if (Array.isArray(labelList[data.taskResourceId])) {
                        labelList[data.taskResourceId].push({
                            "taskResourceId": "assignment_description)" + data.taskResourceId,
                            "label": t('TASK_ASSIGNMENT_DESCRIPTION'),
                            "description": data?.assignedInformation?.description
                        });
                    } else {
                        console.error("labelList[data.taskResourceId] is not an array.");
                    }
                    if (Array.isArray(labelList[data.taskResourceId])) {
                        labelList[data.taskResourceId].push({
                            "taskResourceId": "missionDescription_" + data?.taskResourceId,
                            "label": t('MISSION_DESCRIPTION'),
                            "description": data?.taskDescription
                        });
                    } else {
                        console.error("labelList[data.taskResourceId] is not an array.");
                    }
                    if (Array.isArray(formList[data.taskResourceId])) {
                        formList[data.taskResourceId].push({
                            "resourceId": "description_" + data.taskResourceId,
                            "attributeName": t('ADVANCE_DESCRIPTION'),
                            "value": data.assignedInformation.completedDescription,
                        });
                        initialValues["description_" + data.taskResourceId] = data.assignedInformation.completedDescription;
                    } else {
                        console.error("attributeList[datax.attributeValueResourceId] is not an array.");
                    }
                });
                form.setFieldsValue(initialValues);
                setMyTaskRequestDetailState({ ...myTaskRequestDetailState, data: formList, label: labelList, attribute: attributeList });
            } else {
                return false;
            }
        }
    }, [personTaskInformation, personTaskInformationLoading])
    return (
        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
            <div className="drawer-scrollable my-task-drawer pl-3 pt-2">
                <Form layout="horizontal">
                    {personTaskInformationLoading ?
                        <Spin className="tab-loading" />
                        :
                        <>
                            {myTaskRequestDetailState?.label[assignedTasksListState[currentTab]?.resourceId] &&
                                myTaskRequestDetailState?.label[assignedTasksListState[currentTab]?.resourceId].map(mapLabelItem)}
                            <div className="pt-5">
                                {
                                    myTaskRequestDetailState?.attribute[assignedTasksListState[currentTab]?.resourceId] &&
                                    myTaskRequestDetailState?.attribute[assignedTasksListState[currentTab]?.resourceId].map(mapLabelItem)
                                }
                            </div>
                        </>
                    }
                </Form>
                {!personTaskInformationLoading &&
                    <Form
                        layout="vertical pt-5"
                        form={form}
                    >
                        <Form.Item name={"description_" + assignedTasksListState[currentTab]?.resourceId} label={t('PARTNER_DESCRIPTION')}>
                            <Input.TextArea
                                onChange={(e) => handleValuesChange(e, { 'resourceId': "description_" + assignedTasksListState[currentTab]?.resourceId, 'value': e.target.value })}
                            />

                        </Form.Item>
                    </Form>
                }
            </div>
        </ConfigProvider >
    )
}
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        persons: state.organization.persons,
        personTaskInformation: state.taskReducer.personTaskInformation,
        personTaskInformationLoading: state.taskReducer.personTaskInformationLoading,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPersons: (id) => { dispatch(getPersons(id)) },
        getAssignedTasksStepList: (resourceId) => { dispatch(getAssignedTasksStepList(resourceId)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyTasksDetailDynamicTab);